import './index.css';
import React, { Suspense, useState, useEffect, useRef } from 'react'; 
import { Canvas} from '@react-three/fiber';
import {OrbitControls, SpotLight, useGLTF, TransformControls, StandardEffects, Html, useProgress} from '@react-three/drei';
import { useControl } from "react-three-gui"
import { Physics } from "@react-three/cannon";
import { Plane } from "./Plane";
import { WideWall } from "./WideWall";
import { Floor } from "./Floor";
import { SideWalls } from "./SideWalls";
import { Pointer } from "./Pointer";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { useLoader } from '@react-three/fiber';
import { render } from 'react-dom';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import FileSaver, { saveAs } from 'file-saver';
import emailjs from '@emailjs/browser';
import html2canvas from 'html2canvas';
import axios from 'axios';
import Modal from 'react-modal';

function getCurrentDate() {
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString();
  return `${day}${month}${year}`;
}

let priceX = 0;
let weightX = 0;
let flodepth = 0;
let num = Math.floor(Math.random() * 100000000);

let designID = getCurrentDate() + "-" + num.toLocaleString('en-US', {minimumIntegerDigits: 8, useGrouping: false});

let product_name = "FLO Design ID:" + designID;
let slugtext = "flo_design_id_" + designID;
const baseUrl = "https://shop.phoenix.lk/product/";
let urlPath = baseUrl + slugtext;
let designCompleted = false;

let wallshowhideimg = "./images/remove-walls.png";
let fixRoomHideShow = "./images/arrow-down.png";
let navigatorUpArrow ="./images/up-arrow.png";
let navigatorLeftArrow = "./images/left-arrow.png";
let navigatorRightArrow =  "./images/arrow-orange.png";
let navigatorDownArrow = "./images/down-arrow.png";
let showHideModules = "./images/arrow.png";
let showHideCustomize = "./images/arrow.png";
let selectColorShowHide = "./images/arrow.png";
let checkoutshowHide = "./images/arrow.png";
let expandAddRemoveBox ="./images/arrow-down.png"
let expandRemSepBox = "./images/arrow.png"; 
let expandRemHandleBox = "./images/arrow.png"; 

let addSmallDoorsBtn = "./images/smallModule.png";
let addMediumDoorsBtn ="./images/mediumModule.png";
let addLargeDoorsBtn ="./images/largeModule.png";
let removeDoorsBtn ="./images/Remove-Modules.png";

let backPaneladdImage ="./images/Back-Panel-Grey.png";
let leftPaneladdImage ="./images/Left-Panel-Grey.png";

let noHandleCount = 0;

let topPlates = 0;
let bottomPlates = 0;
let columns = 0;
let sideConnector1 = 0;
let plateLateralTopCover = 0;
let backPlateTopCover = 0;
let foot = 0;
let knowb1TopColCover = 0;
let knob2TopDoorPin = 0;
let rubberPads = 0;
let backplates = 0;
let leftSidePlates = 0;
let rightSidePlates = 0;
let smallDoors = 0;

let doorTopCover = 0;
let doorBottomCover = 0;
let roundHandles = 0;
let screws = 0;
let doorStopper = 0;
let longHandles = 0;
let mediumDoors = 0;
let middleSeperators = 0;

let bodyColor = "Wood";
let BaseColor = "Fog";
let leftDoorColor = "Wood";
let rightDoorCOlor= "Wood";
let leftHandleColor = "Fog";
let rightHandleColor = "Fog";

let RCol1Row4 = 0;
let RCol1Row3 = 0;
let RCol1Row2 = 0;
let RCol1Row1 = 0;

let RCol2Row4 = 0;
let RCol2Row3 = 0;
let RCol2Row2 = 0;
let RCol2Row1 = 0;

let RCol3Row4 = 0;
let RCol3Row3 = 0;
let RCol3Row2 = 0;
let RCol3Row1 = 0;

let RCol4Row4 = 0;
let RCol4Row3 = 0;
let RCol4Row2 = 0;
let RCol4Row1 = 0;

let RCol5Row4 = 0;
let RCol5Row3 = 0;
let RCol5Row2 = 0;
let RCol5Row1 = 0;

let RCol6Row4 = 0;
let RCol6Row3 = 0;
let RCol6Row2 = 0;
let RCol6Row1 = 0;

let RCol7Row4 = 0;
let RCol7Row3 = 0;
let RCol7Row2 = 0;
let RCol7Row1 = 0;

let RCol8Row4 = 0;
let RCol8Row3 = 0;
let RCol8Row2 = 0;
let RCol8Row1 = 0;

let RCol9Row4 = 0;
let RCol9Row3 = 0;
let RCol9Row2 = 0;
let RCol9Row1 = 0;

let RCol10Row4 = 0;
let RCol10Row3 = 0;
let RCol10Row2 = 0;
let RCol10Row1 = 0;

let RCol11Row4 = 0;
let RCol11Row3 = 0;
let RCol11Row2 = 0;
let RCol11Row1 = 0;

let RCol12Row4 = 0;
let RCol12Row3 = 0;
let RCol12Row2 = 0;
let RCol12Row1 = 0;

let RCol13Row4 = 0;
let RCol13Row3 = 0;
let RCol13Row2 = 0;
let RCol13Row1 = 0;

let RCol14Row4 = 0;
let RCol14Row3 = 0;
let RCol14Row2 = 0;
let RCol14Row1 = 0;

let doorTypec1r1 = 0; 
  let doorTypec2r1 = 0; 
  let doorTypec3r1 = 0; 
  let doorTypec4r1 = 0; 
  let doorTypec5r1 = 0; 
  let doorTypec6r1 = 0; 
  let doorTypec7r1 = 0; 
  let doorTypec8r1 = 0; 
  let doorTypec9r1 = 0; 
  let doorTypec10r1 = 0; 
  let doorTypec11r1 = 0; 
  let doorTypec12r1 = 0; 
  let doorTypec13r1 = 0; 
  let doorTypec14r1 = 0; 

  let doorTypec1r2 = 0; 
  let doorTypec2r2 = 0; 
  let doorTypec3r2 = 0; 
  let doorTypec4r2 = 0; 
  let doorTypec5r2 = 0; 
  let doorTypec6r2 = 0; 
  let doorTypec7r2 = 0; 
  let doorTypec8r2 = 0; 
  let doorTypec9r2 = 0; 
  let doorTypec10r2 = 0; 
  let doorTypec11r2 = 0; 
  let doorTypec12r2 = 0; 
  let doorTypec13r2 = 0; 
  let doorTypec14r2 = 0; 

  let doorTypec1r3 = 0; 
  let doorTypec2r3 = 0; 
  let doorTypec3r3 = 0; 
  let doorTypec4r3 = 0; 
  let doorTypec5r3 = 0; 
  let doorTypec6r3 = 0; 
  let doorTypec7r3 = 0; 
  let doorTypec8r3 = 0; 
  let doorTypec9r3 = 0; 
  let doorTypec10r3 = 0; 
  let doorTypec11r3 = 0; 
  let doorTypec12r3 = 0; 
  let doorTypec13r3 = 0;
  let doorTypec14r3 = 0;

  let doorTypec1r4 = 0;
  let doorTypec2r4 = 0;
  let doorTypec3r4 = 0;
  let doorTypec4r4 = 0;
  let doorTypec5r4 = 0;
  let doorTypec6r4 = 0;
  let doorTypec7r4 = 0;
  let doorTypec8r4 = 0;
  let doorTypec9r4 = 0;
  let doorTypec10r4 = 0;
  let doorTypec11r4 = 0;
  let doorTypec12r4 = 0;
  let doorTypec13r4 = 0;
  let doorTypec14r4 = 0;

  let doorRemoved = false;

//let dataSaved = false;

function Loader() {
  const { progress } = useProgress()
  return <Html> <div className="loadingContainer" >Loading... {progress.toFixed(2)}% Completed</div></Html>
}

function Model10(props) {
  const { nodes, materials } = useGLTF('/Small.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes['Long_Handle-R'].geometry} material={materials['Long_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-R'].geometry} material={materials['Round_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral001.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={false}/>
      <mesh geometry={nodes.Back_Panel.geometry} material={materials.Back_Panel} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Lateral.geometry} material={materials.Left_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Long_Handle-L'].geometry} material={materials['Long_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-L'].geometry} material={materials['Round_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral.geometry} material={materials.Right_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]}  material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn} />
      <mesh geometry={nodes.Base_with_4_legs.geometry} material={materials.Base_with_4_legs} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={true} />
      <mesh geometry={nodes.Rack_with_top_plate.geometry} material={materials.Rack_with_top_plate} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}/>
    </group>
  )
}


function Model10M(props) {
  const { nodes, materials } = useGLTF('/medium.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Base.geometry} material={materials.Base} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={true}/>
      <mesh geometry={nodes.Left_Long_Handle.geometry} material={materials.Left_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Long_Handle.geometry} material={materials.Right_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Round_Handle.geometry} material={materials.Right_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Left_Round_Handle.geometry} material={materials.Left_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Panel_Bottom.geometry} material={materials.Right_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Back_Panel_Bottom.geometry} material={materials.Back_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Panel_Bottom.geometry} material={materials.Left_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Rack.geometry} material={materials.Rack}rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}  />
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Middle_Separator.geometry} material={materials.Middle_Separator} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn}/>
      <mesh geometry={nodes.Right_Panel_Top.geometry} material={materials.Right_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Panel_Top.geometry} material={materials.Left_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Back_Panel_Top.geometry} material={materials.Back_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
    </group>
  )
}


function Model10L(props) {
  const { nodes, materials } = useGLTF('/Large.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes['Long_Handle-R'].geometry} material={materials['Long Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Long_Handle-L'].geometry} material={materials['Long Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Left_Door.geometry} material={materials['Left Door']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Right_Door.geometry} material={materials['Right Door.009']}rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes['Shelf_Separator-3'].geometry} material={materials['Shelf Separator-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn3}/>
      <mesh geometry={nodes['Shelf_Separator-2'].geometry} material={materials['Shelf Separator-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn2}/>
      <mesh geometry={nodes['Shelf_Separator-1'].geometry} material={materials['Shelf Separator-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn}/>
      <mesh geometry={nodes['Right_Lateral-4'].geometry} material={materials['Right Lateral-4']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Back_Panel-4'].geometry} material={materials['Back Panel-4']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes['Left_Lateral-4'].geometry} material={materials['Left Lateral-4']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Right_Lateral-3'].geometry} material={materials['Right Lateral-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Back_Panel-3'].geometry} material={materials['Back Panel-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes['Left_Lateral-3'].geometry} material={materials['Left Lateral-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Right_Lateral-1'].geometry} material={materials['Right Lateral-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Right_Lateral-2'].geometry} material={materials['Right Lateral-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Left_Lateral-1'].geometry} material={materials['Left Lateral-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Left_Lateral-2'].geometry} material={materials['Left Lateral-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Back_Panel-2'].geometry} material={materials['Back Panel-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes['Back_Panel-1'].geometry} material={materials['Back Panel-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Base_with_4_legs.geometry} material={materials['Base with 4 legs']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={true}/>
      <mesh geometry={nodes.Rack_with_top_plate.geometry} material={materials['Rack with top plate']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}/>
    </group>
  )
}


function Model10Up(props) {
  const { nodes, materials } = useGLTF('/Small.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]}material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes['Long_Handle-R'].geometry} material={materials['Long_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-R'].geometry} material={materials['Round_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral001.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={false}/>
      <mesh geometry={nodes.Back_Panel.geometry} material={materials.Back_Panel} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Lateral.geometry} material={materials.Left_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Long_Handle-L'].geometry} material={materials['Long_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-L'].geometry} material={materials['Round_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral.geometry} material={materials.Right_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn} />
      <mesh geometry={nodes.Base_with_4_legs.geometry} material={materials.Base_with_4_legs} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={false} />
      <mesh geometry={nodes.Rack_with_top_plate.geometry} material={materials.Rack_with_top_plate} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}/>
    </group>
  )
}


function Model10MUp(props) {
  const { nodes, materials } = useGLTF('/medium.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Base.geometry} material={materials.Base} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={false}/>
      <mesh geometry={nodes.Left_Long_Handle.geometry} material={materials.Left_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Long_Handle.geometry} material={materials.Right_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Round_Handle.geometry} material={materials.Right_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Left_Round_Handle.geometry} material={materials.Left_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Panel_Bottom.geometry} material={materials.Right_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Back_Panel_Bottom.geometry} material={materials.Back_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Panel_Bottom.geometry} material={materials.Left_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Rack.geometry} material={materials.Rack}rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}  />
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Middle_Separator.geometry} material={materials.Middle_Separator} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn}/>
      <mesh geometry={nodes.Right_Panel_Top.geometry} material={materials.Right_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Panel_Top.geometry} material={materials.Left_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Back_Panel_Top.geometry} material={materials.Back_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
    </group>
  )
}




function App() {
  // pricing
  const PRICE_SHELF_SEPERATOR = 1508;
  const WEIGHT_SHELF_SEPERATOR = 400.58;

  const PRICE_SMALL_V1 = 4934;
  const PRICE_SMALL_V2 = 6712;
  const PRICE_SMALL_V3 = 5722;
  const PRICE_SMALL_V4 = 5722;
  const PRICE_SMALL_V5 = 6746;
  const PRICE_SMALL_V6 = 7620;
  const PRICE_SMALL_V7 = 7620;
  const PRICE_SMALL_V8 = 8644;
  const PRICE_SMALL_V9 = 9961;
  const PRICE_SMALL_V10 = 10019;
  const PRICE_SMALL_V11 = 9935;

  const PRICE_MEDIUM_V1 = 5949;
  const PRICE_MEDIUM_V2 = 9505;
  const PRICE_MEDIUM_V3 = 7641;
  const PRICE_MEDIUM_V4 = 7641;
  const PRICE_MEDIUM_V5 = 9813;
  const PRICE_MEDIUM_V6 = 11437;
  const PRICE_MEDIUM_V7 = 11437;
  const PRICE_MEDIUM_V8 = 13369;
  const PRICE_MEDIUM_V9 = 15758;
  const PRICE_MEDIUM_V10 = 15816;
  const PRICE_MEDIUM_V11 = 15732;

  const PRICE_LARGE_V1 = 7979;
  const PRICE_LARGE_V2 = 15091;
  const PRICE_LARGE_V3 = 11785;
  const PRICE_LARGE_V4 = 11785;
  const PRICE_LARGE_V5 = 15707;
  const PRICE_LARGE_V6 = 18897;
  const PRICE_LARGE_V7 = 18897;
  const PRICE_LARGE_V8 = 22819;
  const PRICE_LARGE_V9 = 27620;
  const PRICE_LARGE_V10 = 27694;
  const PRICE_LARGE_V11 = 27610;


  const PRICE_SMALL_UP_V1 = 2515;
  const PRICE_SMALL_UP_V2 = 4293;
  const PRICE_SMALL_UP_V3 = 3303;
  const PRICE_SMALL_UP_V4 = 3303;
  const PRICE_SMALL_UP_V5 = 4327;
  const PRICE_SMALL_UP_V6 = 5201;
  const PRICE_SMALL_UP_V7 = 5201;
  const PRICE_SMALL_UP_V8 = 6225;
  const PRICE_SMALL_UP_V9 = 7542;
  const PRICE_SMALL_UP_V10 = 7600;
  const PRICE_SMALL_UP_V11 = 7516;

  const PRICE_MEDIUM_UP_V1 = 3530;
  const PRICE_MEDIUM_UP_V2 = 7086;
  const PRICE_MEDIUM_UP_V3 = 5222;
  const PRICE_MEDIUM_UP_V4 = 5222;
  const PRICE_MEDIUM_UP_V5 = 7394;
  const PRICE_MEDIUM_UP_V6 = 9018;
  const PRICE_MEDIUM_UP_V7 = 9018;
  const PRICE_MEDIUM_UP_V8 = 10950;
  const PRICE_MEDIUM_UP_V9 = 13339;
  const PRICE_MEDIUM_UP_V10 = 13397;
  const PRICE_MEDIUM_UP_V11 = 13313;

  const PRICE_SMALL_BASE_RIGHT_V1 = 4145;
  const PRICE_SMALL_BASE_RIGHT_V2 = 5923;
  const PRICE_SMALL_BASE_RIGHT_V3 = 3909;
  const PRICE_SMALL_BASE_RIGHT_V4 = 4933;
  const PRICE_SMALL_BASE_RIGHT_V5 = 4933;
  const PRICE_SMALL_BASE_RIGHT_V6 = 5807;
  const PRICE_SMALL_BASE_RIGHT_V7 = 6831;
  const PRICE_SMALL_BASE_RIGHT_V8 = 6831;
  const PRICE_SMALL_BASE_RIGHT_V9 = 8148;
  const PRICE_SMALL_BASE_RIGHT_V10 = 8206;
  const PRICE_SMALL_BASE_RIGHT_V11 = 8122;

 
  const PRICE_MEDIUM_BASE_RIGHT_V1 = 4661;
  const PRICE_MEDIUM_BASE_RIGHT_V2 = 8217;
  const PRICE_MEDIUM_BASE_RIGHT_V3 = 4305;
  const PRICE_MEDIUM_BASE_RIGHT_V4 = 6353;
  const PRICE_MEDIUM_BASE_RIGHT_V5 = 6477;
  const PRICE_MEDIUM_BASE_RIGHT_V6 = 8101;
  const PRICE_MEDIUM_BASE_RIGHT_V7 = 10149;
  const PRICE_MEDIUM_BASE_RIGHT_V8 = 10033;
  const PRICE_MEDIUM_BASE_RIGHT_V9 = 12422;
  const PRICE_MEDIUM_BASE_RIGHT_V10 = 12480;
  const PRICE_MEDIUM_BASE_RIGHT_V11 = 12396;

  const PRICE_LARGE_BASE_RIGHT_V1 = 5751;
  const PRICE_LARGE_BASE_RIGHT_V2 = 12863;
  const PRICE_LARGE_BASE_RIGHT_V3 = 5461;
  const PRICE_LARGE_BASE_RIGHT_V4 = 9557;
  const PRICE_LARGE_BASE_RIGHT_V5 = 9383;
  const PRICE_LARGE_BASE_RIGHT_V6 = 12573;
  const PRICE_LARGE_BASE_RIGHT_V7 = 16669;
  const PRICE_LARGE_BASE_RIGHT_V8 = 16495;
  const PRICE_LARGE_BASE_RIGHT_V9 = 21296;
  const PRICE_LARGE_BASE_RIGHT_V10 = 21370;
  const PRICE_LARGE_BASE_RIGHT_V11 = 21286;

  const PRICE_SMALL_UP_SIDE_V1 = 1726;
  const PRICE_SMALL_UP_SIDE_V2 = 3504;
  const PRICE_SMALL_UP_SIDE_V3 = 1490;
  const PRICE_SMALL_UP_SIDE_V4 = 2514;
  const PRICE_SMALL_UP_SIDE_V5 = 2514;
  const PRICE_SMALL_UP_SIDE_V6 = 3388;
  const PRICE_SMALL_UP_SIDE_V7 = 4412;
  const PRICE_SMALL_UP_SIDE_V8 = 4412;
  const PRICE_SMALL_UP_SIDE_V9 = 5729;
  const PRICE_SMALL_UP_SIDE_V10 = 5787;
  const PRICE_SMALL_UP_SIDE_V11 = 5703;

  const PRICE_MEDIUM_UP_SIDE_V1 = 2242;
  const PRICE_MEDIUM_UP_SIDE_V2 = 5798;
  const PRICE_MEDIUM_UP_SIDE_V3 = 1886;
  const PRICE_MEDIUM_UP_SIDE_V4 = 3934;
  const PRICE_MEDIUM_UP_SIDE_V5 = 4058;
  const PRICE_MEDIUM_UP_SIDE_V6 = 5682;
  const PRICE_MEDIUM_UP_SIDE_V7 = 7730;
  const PRICE_MEDIUM_UP_SIDE_V8 = 7614;
  const PRICE_MEDIUM_UP_SIDE_V9 = 10003;
  const PRICE_MEDIUM_UP_SIDE_V10 = 10061;
  const PRICE_MEDIUM_UP_SIDE_V11 = 9977;

  
  const WEIGHT_SMALL_V1 = 1701.59;
  const WEIGHT_SMALL_V2 = 2451.29;
  const WEIGHT_SMALL_V3 = 2087.47;
  const WEIGHT_SMALL_V4 = 2087.47;
  const WEIGHT_SMALL_V5 = 2473.35;
  const WEIGHT_SMALL_V6 = 2837.17;
  const WEIGHT_SMALL_V7 = 2837.17;
  const WEIGHT_SMALL_V8 = 3223.05;
  const WEIGHT_SMALL_V9 = 3456.13;
  const WEIGHT_SMALL_V10 = 3481.63;
  const WEIGHT_SMALL_V11 = 3454.13;

  const WEIGHT_MEDIUM_V1 = 1928.68;
  const WEIGHT_MEDIUM_V2 = 3428.08;
  const WEIGHT_MEDIUM_V3 = 2700.44;
  const WEIGHT_MEDIUM_V4 = 2700.44;
  const WEIGHT_MEDIUM_V5 = 3472.20;
  const WEIGHT_MEDIUM_V6 = 4199.84;
  const WEIGHT_MEDIUM_V7 = 4199.84;
  const WEIGHT_MEDIUM_V8 = 4971.60;
  const WEIGHT_MEDIUM_V9 = 5412.72;
  const WEIGHT_MEDIUM_V10 = 5438.22;
  const WEIGHT_MEDIUM_V11 = 5410.72;

  const WEIGHT_LARGE_V1 = 2448.46;
  const WEIGHT_LARGE_V2 = 5446.26;
  const WEIGHT_LARGE_V3 = 3991.98;
  const WEIGHT_LARGE_V4 = 3991.98;
  const WEIGHT_LARGE_V5 = 5535.50;
  const WEIGHT_LARGE_V6 = 6990.78;
  const WEIGHT_LARGE_V7 = 6990.78;
  const WEIGHT_LARGE_V8 = 8534.30;
  const WEIGHT_LARGE_V9 = 9387.42;
  const WEIGHT_LARGE_V10 = 9412.92;
  const WEIGHT_LARGE_V11 = 9385.42;

  const WEIGHT_SMALL_UP_V1 = 1061.20;	
  const WEIGHT_SMALL_UP_V2 = 1810.90;	
  const WEIGHT_SMALL_UP_V3 = 1447.08;	
  const WEIGHT_SMALL_UP_V4 = 1447.08;	
  const WEIGHT_SMALL_UP_V5 = 1832.96;	
  const WEIGHT_SMALL_UP_V6 = 2196.78;	
  const WEIGHT_SMALL_UP_V7 = 2196.78;	
  const WEIGHT_SMALL_UP_V8 = 2582.66;	
  const WEIGHT_SMALL_UP_V9 = 2815.74;	
  const WEIGHT_SMALL_UP_V10 = 2841.24;	
  const WEIGHT_SMALL_UP_V11 = 2813.74;	
	
  const WEIGHT_MEDIUML_UP_V1 = 1288.29;	
  const WEIGHT_MEDIUML_UP_V2 = 2787.69;	
  const WEIGHT_MEDIUML_UP_V3 = 2060.05;	
  const WEIGHT_MEDIUML_UP_V4 = 2060.05;	
  const WEIGHT_MEDIUML_UP_V5 = 2831.81;	
  const WEIGHT_MEDIUML_UP_V6 = 3559.45;	
  const WEIGHT_MEDIUML_UP_V7 = 3559.45;	
  const WEIGHT_MEDIUML_UP_V8 = 4331.21;	
  const WEIGHT_MEDIUML_UP_V9 = 4772.33;	
  const WEIGHT_MEDIUML_UP_V10 = 4797.83;	
  const WEIGHT_MEDIUML_UP_V11 = 4770.33;	

  const WEIGHT_SMALL_BASE_RIGHT_V1 = 1659.78;
  const WEIGHT_SMALL_BASE_RIGHT_V2 = 2409.48;
  const WEIGHT_SMALL_BASE_RIGHT_V3 = 1659.78;
  const WEIGHT_SMALL_BASE_RIGHT_V4 = 2045.66;
  const WEIGHT_SMALL_BASE_RIGHT_V5 = 2045.66;
  const WEIGHT_SMALL_BASE_RIGHT_V6 = 2409.48;
  const WEIGHT_SMALL_BASE_RIGHT_V7 = 2795.36;
  const WEIGHT_SMALL_BASE_RIGHT_V8 = 2795.36;
  const WEIGHT_SMALL_BASE_RIGHT_V9 = 3028.44;
  const WEIGHT_SMALL_BASE_RIGHT_V10 = 3053.94;
  const WEIGHT_SMALL_BASE_RIGHT_V11 = 3026.44;

  const WEIGHT_MEDIUM_BASE_RIGHT_V1 = 1848.28;
  const WEIGHT_MEDIUM_BASE_RIGHT_V2 = 3347.68;
  const WEIGHT_MEDIUM_BASE_RIGHT_V3 = 1848.28;
  const WEIGHT_MEDIUM_BASE_RIGHT_V4 = 2620.04;
  const WEIGHT_MEDIUM_BASE_RIGHT_V5 = 2620.04;
  const WEIGHT_MEDIUM_BASE_RIGHT_V6 = 3347.68;
  const WEIGHT_MEDIUM_BASE_RIGHT_V7 = 4119.44;
  const WEIGHT_MEDIUM_BASE_RIGHT_V8 = 4119.44;
  const WEIGHT_MEDIUM_BASE_RIGHT_V9 = 10876.4;
  const WEIGHT_MEDIUM_BASE_RIGHT_V10 = 4560.56;
  const WEIGHT_MEDIUM_BASE_RIGHT_V11 = 4586.06;

  const WEIGHT_LARGE_BASE_RIGHT_V1 = 2288.79;
  const WEIGHT_LARGE_BASE_RIGHT_V2 = 5287.59;
  const WEIGHT_LARGE_BASE_RIGHT_V3 = 2288.79;
  const WEIGHT_LARGE_BASE_RIGHT_V4 = 3832.31;
  const WEIGHT_LARGE_BASE_RIGHT_V5 = 3832.31;
  const WEIGHT_LARGE_BASE_RIGHT_V6 = 5287.59;
  const WEIGHT_LARGE_BASE_RIGHT_V7 = 6831.11;
  const WEIGHT_LARGE_BASE_RIGHT_V8 = 6831.11;
  const WEIGHT_LARGE_BASE_RIGHT_V9 = 7684.23;
  const WEIGHT_LARGE_BASE_RIGHT_V10 = 7709.73;
  const WEIGHT_LARGE_BASE_RIGHT_V11 = 7682.23;
	

  const WEIGHT_SMALL_UP_SIDE_V1 = 1019.39;
  const WEIGHT_SMALL_UP_SIDE_V2 = 1769.09;
  const WEIGHT_SMALL_UP_SIDE_V3 = 1019.39;
  const WEIGHT_SMALL_UP_SIDE_V4 = 1405.27;
  const WEIGHT_SMALL_UP_SIDE_V5 = 1405.27;
  const WEIGHT_SMALL_UP_SIDE_V6 = 1769.09;
  const WEIGHT_SMALL_UP_SIDE_V7 = 2154.97;
  const WEIGHT_SMALL_UP_SIDE_V8 = 2154.97;
  const WEIGHT_SMALL_UP_SIDE_V9 = 2388.05;
  const WEIGHT_SMALL_UP_SIDE_V10 = 2413.55;
  const WEIGHT_SMALL_UP_SIDE_V11 = 2386.05;

  const WEIGHT_MEDIUM_UP_SIDE_V1 = 1207.89;
  const WEIGHT_MEDIUM_UP_SIDE_V2 = 2707.29;
  const WEIGHT_MEDIUM_UP_SIDE_V3 = 1207.89;
  const WEIGHT_MEDIUM_UP_SIDE_V4 = 1979.65;
  const WEIGHT_MEDIUM_UP_SIDE_V5 = 1979.65;
  const WEIGHT_MEDIUM_UP_SIDE_V6 = 2707.29;
  const WEIGHT_MEDIUM_UP_SIDE_V7 = 3479.05;
  const WEIGHT_MEDIUM_UP_SIDE_V8 = 3479.05;
  const WEIGHT_MEDIUM_UP_SIDE_V9 = 3920.17;
  const WEIGHT_MEDIUM_UP_SIDE_V10 = 3945.67;
  const WEIGHT_MEDIUM_UP_SIDE_V11 = 3918.17;

  
 
  const [isWallFixed, setIsWallFixed] = useState(false); 
  const [dimentionBox, setDimentionBox] = useState(false);  
  const [modulesBox, setModulesBox] = useState(true);  
  const [customizeBox, setCustomizeBox] = useState(true);  
  const [colorBox, setColorBox] = useState(true); 
  const [removeBox, setRemoveBox] = useState(true); 
  const [addBox, setAddBox] = useState(false); 
  const [addRemoveBox, setAddRemoveBox] = useState(false); 
  const [addRemSepBox, setAddRemSepBox] = useState(true); 
  const [addRemHandleBox, setAddRemHandleBox] = useState(true); 
  const [openCloseDoors, setOpenCloseDoors] = useState(true); 
  const [showNightBox, setShowNightBox] = useState(false); 

  const [checkoutBox, setCheckoutBox] = useState(true); 
  //const [isselectorVisible, serIsSelectorVVisible] = useState(false);
  const [inputWidth, setInputWidth] = useState(5);
  const [inputHeight, setInputHeight] = useState(3);
  const [inputDepth, setInputDepth] = useState(4);
  const [wallBtnCaption, setwallBtnCaption] = useState("HIDE WALLS");

  const [posx, setPosx] = useState(2.5);
  const [posz, setPosz] = useState(2);
  const [posy, setPosy] = useState(1.5);
  const [wallvisible, setWallVisible] = useState(true);
  //const [selModule, setSelModule] = useState("S");
  const [matColor, setMatColor] = useState("#af8a6f");
  const [selColorName, setSelColorName] = useState("Wood");
  const [startX, setStartX] = useState(-2);
  const [startY, setStartY] = useState(0.1);
  const [colCount, setColCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [selectedMat, setSelectedMat] = useState("back")
  const [basefeet, setBasefeet] = useState("#a6a6a6");
  const [leftDoor,setLeftDoor] = useState("#af8a6f");
  const [rightdoor,setRightDoor] = useState("#af8a6f");
  const [back,setBack] = useState("#af8a6f");
  const [lhandle,setLHandle] = useState("#a6a6a6");
  const [rhandle,setRHandle] = useState("#a6a6a6");
  const [backZone, setBackZone] = useState(true);
  const [baseZone, setBaseZone] = useState(false);
  const [ldoorZone, setLDoorZone] = useState(false);
  const [rdoorZone, setRDoorZone] = useState(false);

  const [lhandleZone, setLHandleZone] = useState(false);
  const [rhandleZone, setRHandleZone] = useState(false);
  const [curZone, setCurZone] = useState("Rack & Panels");
  const [backOn, setBackOn] = useState(false);
  const [doorsOn, setDoorOn] = useState(false);
  const [seperatorOn, setSeperatorOn] = useState(false);
  const [seperatorOn2, setSeperatorOn2] = useState(false);
  const [seperatorOn3, setSeperatorOn3] = useState(false);

  const [longHandleOn, setLongHandleOn] = useState(false);
  const [RoundHandleOn, setRoundHandleOn] = useState(false);
  const [leftOn, setLeftOn] = useState(false);
  const [rightOn, setRightOn] = useState(false);

  const [showHideDoors, setShowHideDoors] = useState("HIDE DOORS");
  const [placeUpRight, setPlaceUpRight] = useState("LANDSCAPE");
  const [addremoveextention, setAddremoveextention] = useState("ADD"); 

  const [maxRightCount, setMaxRightCount] = useState(7);
  const [floWidth, setFloWidth] = useState(0);
  const [floHeight, setFloHeight] = useState(0);
  const [maxUpCount, setMaxUpCount] = useState(8);

  const [heightinFeet, setHeightinFeet] = useState(0);
  const [widthinFeet, setWidthinFeet] = useState(0);
  const [lengthinFeet, setLengthinFeet] = useState(0);


  const [sepOnM1C1, setSepOnM1C1] = useState(false);
  const [sepOnM1C2, setSepOnM1C2] = useState(false);
  const [sepOnM1C3, setSepOnM1C3] = useState(false);
  const [sepOnM1C4, setSepOnM1C4] = useState(false);
  const [sepOnM1C5, setSepOnM1C5] = useState(false);
  const [sepOnM1C6, setSepOnM1C6] = useState(false);
  const [sepOnM1C7, setSepOnM1C7] = useState(false);
  const [sepOnM1C8, setSepOnM1C8] = useState(false);
  const [sepOnM1C9, setSepOnM1C9] = useState(false);
  const [sepOnM1C10, setSepOnM1C10] = useState(false);
  const [sepOnM1C11, setSepOnM1C11] = useState(false);
  const [sepOnM1C12, setSepOnM1C12] = useState(false);
  const [sepOnM1C13, setSepOnM1C13] = useState(false);
  const [sepOnM1C14, setSepOnM1C14] = useState(false);

  const [sepOnM2C1, setSepOnM2C1] = useState(false);
  const [sepOnM2C2, setSepOnM2C2] = useState(false);
  const [sepOnM2C3, setSepOnM2C3] = useState(false);
  const [sepOnM2C4, setSepOnM2C4] = useState(false);
  const [sepOnM2C5, setSepOnM2C5] = useState(false);
  const [sepOnM2C6, setSepOnM2C6] = useState(false);
  const [sepOnM2C7, setSepOnM2C7] = useState(false);
  const [sepOnM2C8, setSepOnM2C8] = useState(false);
  const [sepOnM2C9, setSepOnM2C9] = useState(false);
  const [sepOnM2C10, setSepOnM2C10] = useState(false);
  const [sepOnM2C11, setSepOnM2C11] = useState(false);
  const [sepOnM2C12, setSepOnM2C12] = useState(false);
  const [sepOnM2C13, setSepOnM2C13] = useState(false);
  const [sepOnM2C14, setSepOnM2C14] = useState(false);

  const [sepOnM3C1, setSepOnM3C1] = useState(false);
  const [sepOnM3C2, setSepOnM3C2] = useState(false);
  const [sepOnM3C3, setSepOnM3C3] = useState(false);
  const [sepOnM3C4, setSepOnM3C4] = useState(false);
  const [sepOnM3C5, setSepOnM3C5] = useState(false);
  const [sepOnM3C6, setSepOnM3C6] = useState(false);
  const [sepOnM3C7, setSepOnM3C7] = useState(false);
  const [sepOnM3C8, setSepOnM3C8] = useState(false);
  const [sepOnM3C9, setSepOnM3C9] = useState(false);
  const [sepOnM3C10, setSepOnM3C10] = useState(false);
  const [sepOnM3C11, setSepOnM3C11] = useState(false);
  const [sepOnM3C12, setSepOnM3C12] = useState(false);
  const [sepOnM3C13, setSepOnM3C13] = useState(false);
  const [sepOnM3C14, setSepOnM3C14] = useState(false);

  const [sepOnLC1, setSepOnLC1] = useState(false);
  const [sepOnLC2, setSepOnLC2] = useState(false);
  const [sepOnLC3, setSepOnLC3] = useState(false);
  const [sepOnLC4, setSepOnLC4] = useState(false);
  const [sepOnLC5, setSepOnLC5] = useState(false);
  const [sepOnLC6, setSepOnLC6] = useState(false);
  const [sepOnLC7, setSepOnLC7] = useState(false);
  const [sepOnLC8, setSepOnLC8] = useState(false);
  const [sepOnLC9, setSepOnLC9] = useState(false);
  const [sepOnLC10, setSepOnLC10] = useState(false);
  const [sepOnLC11, setSepOnLC11] = useState(false);
  const [sepOnLC12, setSepOnLC12] = useState(false);
  const [sepOnLC13, setSepOnLC13] = useState(false);
  const [sepOnLC14, setSepOnLC14] = useState(false);

  const [sepOn2LC1, setSepOn2LC1] = useState(false);
  const [sepOn2LC2, setSepOn2LC2] = useState(false);
  const [sepOn2LC3, setSepOn2LC3] = useState(false);
  const [sepOn2LC4, setSepOn2LC4] = useState(false);
  const [sepOn2LC5, setSepOn2LC5] = useState(false);
  const [sepOn2LC6, setSepOn2LC6] = useState(false);
  const [sepOn2LC7, setSepOn2LC7] = useState(false);
  const [sepOn2LC8, setSepOn2LC8] = useState(false);
  const [sepOn2LC9, setSepOn2LC9] = useState(false);
  const [sepOn2LC10, setSepOn2LC10] = useState(false);
  const [sepOn2LC11, setSepOn2LC11] = useState(false);
  const [sepOn2LC12, setSepOn2LC12] = useState(false);
  const [sepOn2LC13, setSepOn2LC13] = useState(false);
  const [sepOn2LC14, setSepOn2LC14] = useState(false);

  const [sepOn3LC1, setSepOn3LC1] = useState(false);
  const [sepOn3LC2, setSepOn3LC2] = useState(false);
  const [sepOn3LC3, setSepOn3LC3] = useState(false);
  const [sepOn3LC4, setSepOn3LC4] = useState(false);
  const [sepOn3LC5, setSepOn3LC5] = useState(false);
  const [sepOn3LC6, setSepOn3LC6] = useState(false);
  const [sepOn3LC7, setSepOn3LC7] = useState(false);
  const [sepOn3LC8, setSepOn3LC8] = useState(false);
  const [sepOn3LC9, setSepOn3LC9] = useState(false);
  const [sepOn3LC10, setSepOn3LC10] = useState(false);
  const [sepOn3LC11, setSepOn3LC11] = useState(false);
  const [sepOn3LC12, setSepOn3LC12] = useState(false);
  const [sepOn3LC13, setSepOn3LC13] = useState(false);
  const [sepOn3LC14, setSepOn3LC14] = useState(false);

  const [upArrrow, setUpArrow] = useState(false);
  const [downArrrow, setDownArrow] = useState(false);
  const [leftArrrow, setLeftArrow] = useState(false);
  const [rightArrrow, setRightArrow] = useState(false);

  const [price, setPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);


  const [col01X, setCol01X] = useState(startX);
  let newX = startX + 0.768;
  const [col02X, setCol02X] = useState(newX);
  newX = newX + 0.768;
  const [col03X, setCol03X] = useState(newX);
  newX = newX + 0.768;
  const [col04X, setCol04X] = useState(newX);
  newX = newX + 0.768;
  const [col05X, setCol05X] = useState(newX);
  newX = newX + 0.768;
  const [col06X, setCol06X] = useState(newX);
  newX = newX + 0.768;
  const [col07X, setCol07X] = useState(newX);
  newX = newX + 0.768;
  const [col08X, setCol08X] = useState(newX);
  newX = newX + 0.768;
  const [col09X, setCol09X] = useState(newX);
  newX = newX + 0.768;
  const [col10X, setCol10X] = useState(newX);
  newX = newX + 0.768;
  const [col11X, setCol11X] = useState(newX);
  newX = newX + 0.768;
  const [col12X, setCol12X] = useState(newX);
  newX = newX + 0.768;
  const [col13X, setCol13X] = useState(newX);
  newX = newX + 0.768;
  const [col14X, setCol14X] = useState(newX);

  const [row01X, setRow01X] = useState(startY);
  let newY = startY + 0.419;
  const [row02X, setRow02X] = useState(newY);
  newY = newY + 0.419;
  const [row03X, setRow03X] = useState(newY);
  newY = newY + 0.419;
  const [row04X, setRow04X] = useState(newY);
  newY = newY + 0.419;
  const [row05X, setRow05X] = useState(newY);
  newY = newY + 0.419;
  const [row06X, setRow06X] = useState(newY);
  newY = newY + 0.419;
  const [row07X, setRow07X] = useState(newY);
  newY = newY + 0.419;
  const [row08X, setRow08X] = useState(newY);
  newY = newY + 0.419;
  const [row09X, setRow09X] = useState(newY);
  newY = newY + 0.419;
  const [row10X, setRow10X] = useState(newY);
  newY = newY + 0.419;
  const [row11X, setRow11X] = useState(newY);
  newY = newY + 0.419;
  const [row12X, setRow12X] = useState(newY);
  newY = newY + 0.419;
  const [row13X, setRow13X] = useState(newY);

  const [curX, setCurX] = useState(0);
  const [curY, setCurY] = useState(0);
  const [curVNo, setCurVNo]= useState(1);

const [c1r1MT, setC1r1MT] = useState("");
const [c2r1MT, setC2r1MT] = useState("");
const [c3r1MT, setC3r1MT] = useState("");
const [c4r1MT, setC4r1MT] = useState("");
const [c5r1MT, setC5r1MT] = useState("");
const [c6r1MT, setC6r1MT] = useState("");
const [c7r1MT, setC7r1MT] = useState("");
const [c8r1MT, setC8r1MT] = useState("");
const [c9r1MT, setC9r1MT] = useState("");
const [c10r1MT, setC10r1MT] = useState("");
const [c11r1MT, setC11r1MT] = useState("");
const [c12r1MT, setC12r1MT] = useState("");
const [c13r1MT, setC13r1MT] = useState("");
const [c14r1MT, setC14r1MT] = useState("");

const [c1r2MT, setC1r2MT] = useState("");
const [c2r2MT, setC2r2MT] = useState("");
const [c3r2MT, setC3r2MT] = useState("");
const [c4r2MT, setC4r2MT] = useState("");
const [c5r2MT, setC5r2MT] = useState("");
const [c6r2MT, setC6r2MT] = useState("");
const [c7r2MT, setC7r2MT] = useState("");
const [c8r2MT, setC8r2MT] = useState("");
const [c9r2MT, setC9r2MT] = useState("");
const [c10r2MT, setC10r2MT] = useState("");
const [c11r2MT, setC11r2MT] = useState("");
const [c12r2MT, setC12r2MT] = useState("");
const [c13r2MT, setC13r2MT] = useState("");
const [c14r2MT, setC14r2MT] = useState("");

const [c1r3MT, setC1r3MT] = useState("");
const [c2r3MT, setC2r3MT] = useState("");
const [c3r3MT, setC3r3MT] = useState("");
const [c4r3MT, setC4r3MT] = useState("");
const [c5r3MT, setC5r3MT] = useState("");
const [c6r3MT, setC6r3MT] = useState("");
const [c7r3MT, setC7r3MT] = useState("");
const [c8r3MT, setC8r3MT] = useState("");
const [c9r3MT, setC9r3MT] = useState("");
const [c10r3MT, setC10r3MT] = useState("");
const [c11r3MT, setC11r3MT] = useState("");
const [c12r3MT, setC12r3MT] = useState("");
const [c13r3MT, setC13r3MT] = useState("");
const [c14r3MT, setC14r3MT] = useState("");

const [c1r4MT, setC1r4MT] = useState("");
const [c2r4MT, setC2r4MT] = useState("");
const [c3r4MT, setC3r4MT] = useState("");
const [c4r4MT, setC4r4MT] = useState("");
const [c5r4MT, setC5r4MT] = useState("");
const [c6r4MT, setC6r4MT] = useState("");
const [c7r4MT, setC7r4MT] = useState("");
const [c8r4MT, setC8r4MT] = useState("");
const [c9r4MT, setC9r4MT] = useState("");
const [c10r4MT, setC10r4MT] = useState("");
const [c11r4MT, setC11r4MT] = useState("");
const [c12r4MT, setC12r4MT] = useState("");
const [c13r4MT, setC13r4MT] = useState("");
const [c14r4MT, setC14r4MT] = useState("");

  const [col01Row01, setCol01Row01] = useState(false);
  const [col02Row01, setCol02Row01] = useState(false);
  const [col03Row01, setCol03Row01] = useState(false);
  const [col04Row01, setCol04Row01] = useState(false);
  const [col05Row01, setCol05Row01] = useState(false);
  const [col06Row01, setCol06Row01] = useState(false);
  const [col07Row01, setCol07Row01] = useState(false);
  const [col08Row01, setCol08Row01] = useState(false);
  const [col09Row01, setCol09Row01] = useState(false);
  const [col10Row01, setCol10Row01] = useState(false);
  const [col11Row01, setCol11Row01] = useState(false);
  const [col12Row01, setCol12Row01] = useState(false);
  const [col13Row01, setCol13Row01] = useState(false);
  const [col14Row01, setCol14Row01] = useState(false);
  
  const [col01Row02, setCol01Row02] = useState(false);
  const [col02Row02, setCol02Row02] = useState(false);
  const [col03Row02, setCol03Row02] = useState(false);
  const [col04Row02, setCol04Row02] = useState(false);
  const [col05Row02, setCol05Row02] = useState(false);
  const [col06Row02, setCol06Row02] = useState(false);
  const [col07Row02, setCol07Row02] = useState(false);
  const [col08Row02, setCol08Row02] = useState(false);
  const [col09Row02, setCol09Row02] = useState(false);
  const [col10Row02, setCol10Row02] = useState(false);
  const [col11Row02, setCol11Row02] = useState(false);
  const [col12Row02, setCol12Row02] = useState(false);
  const [col13Row02, setCol13Row02] = useState(false);
  const [col14Row02, setCol14Row02] = useState(false);
  
  const [col01Row03, setCol01Row03] = useState(false);
  const [col02Row03, setCol02Row03] = useState(false);
  const [col03Row03, setCol03Row03] = useState(false);
  const [col04Row03, setCol04Row03] = useState(false);
  const [col05Row03, setCol05Row03] = useState(false);
  const [col06Row03, setCol06Row03] = useState(false);
  const [col07Row03, setCol07Row03] = useState(false);
  const [col08Row03, setCol08Row03] = useState(false);
  const [col09Row03, setCol09Row03] = useState(false);
  const [col10Row03, setCol10Row03] = useState(false);
  const [col11Row03, setCol11Row03] = useState(false);
  const [col12Row03, setCol12Row03] = useState(false);
  const [col13Row03, setCol13Row03] = useState(false);
  const [col14Row03, setCol14Row03] = useState(false);
  
  const [col01Row04, setCol01Row04] = useState(false);
  const [col02Row04, setCol02Row04] = useState(false);
  const [col03Row04, setCol03Row04] = useState(false);
  const [col04Row04, setCol04Row04] = useState(false);
  const [col05Row04, setCol05Row04] = useState(false);
  const [col06Row04, setCol06Row04] = useState(false);
  const [col07Row04, setCol07Row04] = useState(false);
  const [col08Row04, setCol08Row04] = useState(false);
  const [col09Row04, setCol09Row04] = useState(false);
  const [col10Row04, setCol10Row04] = useState(false);
  const [col11Row04, setCol11Row04] = useState(false);
  const [col12Row04, setCol12Row04] = useState(false);
  const [col13Row04, setCol13Row04] = useState(false);
  const [col14Row04, setCol14Row04] = useState(false);

const [c1R1VNo, setC1R1VNo] = useState(0);
const [c2R1VNo, setC2R1VNo] = useState(0);
const [c3R1VNo, setC3R1VNo] = useState(0);
const [c4R1VNo, setC4R1VNo] = useState(0);
const [c5R1VNo, setC5R1VNo] = useState(0);
const [c6R1VNo, setC6R1VNo] = useState(0);
const [c7R1VNo, setC7R1VNo] = useState(0);
const [c8R1VNo, setC8R1VNo] = useState(0);
const [c9R1VNo, setC9R1VNo] = useState(0);
const [c10R1VNo, setC10R1VNo] = useState(0);
const [c11R1VNo, setC11R1VNo] = useState(0);
const [c12R1VNo, setC12R1VNo] = useState(0);
const [c13R1VNo, setC13R1VNo] = useState(0);
const [c14R1VNo, setC14R1VNo] = useState(0);

const [c1R2VNo, setC1R2VNo] = useState(0);
const [c2R2VNo, setC2R2VNo] = useState(0);
const [c3R2VNo, setC3R2VNo] = useState(0);
const [c4R2VNo, setC4R2VNo] = useState(0);
const [c5R2VNo, setC5R2VNo] = useState(0);
const [c6R2VNo, setC6R2VNo] = useState(0);
const [c7R2VNo, setC7R2VNo] = useState(0);
const [c8R2VNo, setC8R2VNo] = useState(0);
const [c9R2VNo, setC9R2VNo] = useState(0);
const [c10R2VNo, setC10R2VNo] = useState(0);
const [c11R2VNo, setC11R2VNo] = useState(0);
const [c12R2VNo, setC12R2VNo] = useState(0);
const [c13R2VNo, setC13R2VNo] = useState(0);
const [c14R2VNo, setC14R2VNo] = useState(0);

const [c1R3VNo, setC1R3VNo] = useState(0);
const [c2R3VNo, setC2R3VNo] = useState(0);
const [c3R3VNo, setC3R3VNo] = useState(0);
const [c4R3VNo, setC4R3VNo] = useState(0);
const [c5R3VNo, setC5R3VNo] = useState(0);
const [c6R3VNo, setC6R3VNo] = useState(0);
const [c7R3VNo, setC7R3VNo] = useState(0);
const [c8R3VNo, setC8R3VNo] = useState(0);
const [c9R3VNo, setC9R3VNo] = useState(0);
const [c10R3VNo, setC10R3VNo] = useState(0);
const [c11R3VNo, setC11R3VNo] = useState(0);
const [c12R3VNo, setC12R3VNo] = useState(0);
const [c13R3VNo, setC13R3VNo] = useState(0);
const [c14R3VNo, setC14R3VNo] = useState(0);

const [c1R4VNo, setC1R4VNo] = useState(0);
const [c2R4VNo, setC2R4VNo] = useState(0);
const [c3R4VNo, setC3R4VNo] = useState(0);
const [c4R4VNo, setC4R4VNo] = useState(0);
const [c5R4VNo, setC5R4VNo] = useState(0);
const [c6R4VNo, setC6R4VNo] = useState(0);
const [c7R4VNo, setC7R4VNo] = useState(0);
const [c8R4VNo, setC8R4VNo] = useState(0);
const [c9R4VNo, setC9R4VNo] = useState(0);
const [c10R4VNo, setC10R4VNo] = useState(0);
const [c11R4VNo, setC11R4VNo] = useState(0);
const [c12R4VNo, setC12R4VNo] = useState(0);
const [c13R4VNo, setC13R4VNo] = useState(0);
const [c14R4VNo, setC14R4VNo] = useState(0);

const resetAllMiddlePlates = () => {
  setSepOnM1C1(false);
  setSepOnM1C2(false);
  setSepOnM1C3(false);
  setSepOnM1C4(false);
  setSepOnM1C5(false);
  setSepOnM1C6(false);
  setSepOnM1C7(false);
  setSepOnM1C8(false);
  setSepOnM1C9(false);
  setSepOnM1C10(false);
  setSepOnM1C11(false);
  setSepOnM1C12(false);
  setSepOnM1C13(false);
  setSepOnM1C14(false);

  setSepOnM2C1(false);
  setSepOnM2C2(false);
  setSepOnM2C3(false);
  setSepOnM2C4(false);
  setSepOnM2C5(false);
  setSepOnM2C6(false);
  setSepOnM2C7(false);
  setSepOnM2C8(false);
  setSepOnM2C9(false);
  setSepOnM2C10(false);
  setSepOnM2C11(false);
  setSepOnM2C12(false);
  setSepOnM2C13(false);
  setSepOnM2C14(false);

  setSepOnM3C1(false);
  setSepOnM3C2(false);
  setSepOnM3C3(false);
  setSepOnM3C4(false);
  setSepOnM3C5(false);
  setSepOnM3C6(false);
  setSepOnM3C7(false);
  setSepOnM3C8(false);
  setSepOnM3C9(false);
  setSepOnM3C10(false);
  setSepOnM3C11(false);
  setSepOnM3C12(false);
  setSepOnM3C13(false);
  setSepOnM3C14(false);

  setSepOnLC1(false);
  setSepOnLC2(false);
  setSepOnLC3(false);
  setSepOnLC4(false);
  setSepOnLC5(false);
  setSepOnLC6(false);
  setSepOnLC7(false);
  setSepOnLC8(false);
  setSepOnLC9(false);
  setSepOnLC10(false);
  setSepOnLC11(false);
  setSepOnLC12(false);
  setSepOnLC13(false);
  setSepOnLC14(false);

  setSepOn2LC1(false);
  setSepOn2LC2(false);
  setSepOn2LC3(false);
  setSepOn2LC4(false);
  setSepOn2LC5(false);
  setSepOn2LC6(false);
  setSepOn2LC7(false);
  setSepOn2LC8(false);
  setSepOn2LC9(false);
  setSepOn2LC10(false);
  setSepOn2LC11(false);
  setSepOn2LC12(false);
  setSepOn2LC13(false);
  setSepOn2LC14(false);

  setSepOn3LC1(false);
  setSepOn3LC2(false);
  setSepOn3LC3(false);
  setSepOn3LC4(false);
  setSepOn3LC5(false);
  setSepOn3LC6(false);
  setSepOn3LC7(false);
  setSepOn3LC8(false);
  setSepOn3LC9(false);
  setSepOn3LC10(false);
  setSepOn3LC11(false);
  setSepOn3LC13(false);
  setSepOn3LC14(false);
}


const [second4ColorBox, setSecond4Color] = useState(true); 

const selectBackZone = (event) => {
  setSelectedMat("back");
  setBackZone(true);
  setBaseZone(false);
  setLDoorZone(false);
  setRDoorZone(false);
  setRHandleZone(false);
  setLHandleZone(false);
  setCurZone("Rack & Panels");
  setSecond4Color(true);
  setShowNightBox(false);
}


const selectBaseZone = (event) => {
    setSelectedMat("base");
    setBackZone(true);
    setBaseZone(true);
    setLDoorZone(false);
    setRDoorZone(false);
    setRHandleZone(false);
    setLHandleZone(false);
    setCurZone("Base & Feet");
    setSecond4Color(true);
    setShowNightBox(true);
    selectWood();
}

const selectLDoorZone = (event) => {
  setSelectedMat("leftdoor");
  setBackZone(false);
  setBaseZone(false);
  setLDoorZone(true);
  setRDoorZone(false);
  setRHandleZone(false);
  setLHandleZone(false);
  setCurZone("Left Doors");
  setSecond4Color(false);
  setShowNightBox(false);
}

const selectRDoorZone = (event) => {
  setSelectedMat("rightdoor");
  setBackZone(false);
  setBaseZone(false);
  setLDoorZone(false);
  setRDoorZone(true);
  setRHandleZone(false);
  setLHandleZone(false);
  setCurZone("Right Doors");
  setSecond4Color(false);
  setShowNightBox(false);
}

const selectLHandleZone = (event) => {
  setSelectedMat("lhandle");
  setBackZone(false);
  setBaseZone(false);
  setLDoorZone(false);
  setRDoorZone(false);
  setRHandleZone(false);
  setLHandleZone(true);
  setCurZone("Left Handle or Knobs");
  setSecond4Color(false);
  setShowNightBox(false);
}

const selectRHandleZone = (event) => {
  setSelectedMat("rhandle");
  setBackZone(false);
  setBaseZone(false);
  setLDoorZone(false);
  setRDoorZone(false);
  setLHandleZone(false);
  setRHandleZone(true);
  setCurZone("Right Handle or Knobs");
  setSecond4Color(false);
  setShowNightBox(false);
}

const selectWood = (e) => {
  setMatColor("#af8a6f");
  setSelColorName("Wood");
}

const selectFog = (e) => {
  setMatColor("#a6a6a6");
  setSelColorName("Fog");
}

const selectNight = (e) => {
  setMatColor("#0d0d0d");
  setSelColorName("Night");
}

const selectSnow = (e) => {
  setMatColor("#f8f8f8");
  setSelColorName("Snow");
}

const selectGrass = (e) => {
  setMatColor("#a4b60c");
  setSelColorName("Grass");
}

const selectChili = (e) => {
  setMatColor("#ff635a");
  setSelColorName("Chili");
}

const selectOBlue = (e) => {
  setMatColor("#4a5a73");
  setSelColorName("Ocean Blue");
}

const selectGreenG = (e) => {
  setMatColor("#8d9e94");
  setSelColorName("Green Galle");
}

const selectColor = (event) => {
  const colorCode = event.target.id
  setMatColor(colorCode);
  if (colorCode === "#af8a6f") {
    setSelColorName("Wood");
  } else if (colorCode === "#a6a6a6") {
    setSelColorName("Fog");
  } else if (colorCode === "#0d0d0d") {
    setSelColorName("Night");
  } else if (colorCode === "#f8f8f8") {
    setSelColorName("Snow");
  } else if (colorCode === "#a4b60c") {
    setSelColorName("Grass");
  } else if (colorCode === "#ff635a") {
    setSelColorName("Chili");
  } else if (colorCode === "#4a5a73") {
    setSelColorName("Ocean Blue");
  } else if (colorCode === "#8d9e94") {
    setSelColorName("Green Galle");
  }
  
}

let changeColor = (event) => {
 
    if (selectedMat === "base") {
      setBasefeet(matColor);
      BaseColor = selColorName;
    } else if (selectedMat === "back") {
      setBack(matColor);
      bodyColor = selColorName;
    } else if (selectedMat === "lhandle") {
      setLHandle(matColor);
      leftHandleColor = selColorName;
    } else if (selectedMat === "rhandle") {
      setRHandle(matColor);
      rightHandleColor = selColorName;
    } else if (selectedMat === "leftdoor") {
      setLeftDoor(matColor);
      leftDoorColor = selColorName;
    }  else if (selectedMat === "rightdoor") {
      setRightDoor(matColor);
      rightDoorCOlor = selColorName;
    }
  } 


  let fixRoom = (event) => {
    
    if (inputWidth < 2 || inputHeight < 2 || inputDepth < 2)  {
        alert("The Room dimentions are too small. Please re-define the space.");
      
    } else if (inputWidth > 11 || inputHeight > 5 || inputDepth > 10)  {
        alert("The Room dimentions are too large. Please re-define the space.");
    
    } else if (floWidth > inputWidth) {
        alert("No space is sufficient for the added Modules. Please re-define the space.")
    
    } else {
        const getposx = inputWidth/2;
        setPosx(getposx);
        const getposy = inputHeight/2;
        setPosy(getposy);
        const getposz = inputDepth/2;
        setPosz(getposz);
        const getInitialX = -getposx + 0.5;
        setStartX(getInitialX);
        const hCount = Math.floor((inputWidth - 0.5)/0.768);
        setMaxRightCount(hCount);
        const vCount = Math.floor((inputHeight - 0.2)/0.419);
        setMaxUpCount(vCount);
      

        const feetWidth = inputWidth * 3.28084;
        setWidthinFeet(feetWidth);
        const feetHeight = inputHeight * 3.28084;
        setHeightinFeet(feetHeight);
        const feetLength = inputDepth * 3.28084;
        setLengthinFeet(feetLength);

        setCol01X(getInitialX);
        let newX = getInitialX + 0.768;
        setCol02X(newX);
        newX = newX + 0.768;
        setCol03X(newX);
        newX = newX + 0.768;
        setCol04X(newX);
        newX = newX + 0.768;
        setCol05X(newX);
        newX = newX + 0.768;
        setCol06X(newX);
        newX = newX + 0.768;
        setCol07X(newX);
        newX = newX + 0.768;
        setCol08X(newX);
        newX = newX + 0.768;
        setCol09X(newX);
        newX = newX + 0.768;
        setCol10X(newX);
        newX = newX + 0.768;
        setCol11X(newX);
        newX = newX + 0.768;
        setCol12X(newX);
        newX = newX + 0.768;
        setCol13X(newX);
        newX = newX + 0.768;
        setCol14X(newX);

        setDimentionBox(true);
        setModulesBox(false);
        setIsWallFixed(true);
        setCustomizeBox(true);
        setColorBox(true);
        setCheckoutBox(true);
        fixRoomHideShow = "./images/arrow.png";
        showHideModules ="./images/arrow-down.png";
        showHideCustomize = "./images/arrow.png";
        selectColorShowHide = "./images/arrow.png";
        checkoutshowHide = "./images/arrow.png";
    }

  }


  let onOffWalls = (event) => {
    if (wallBtnCaption === "HIDE WALLS") {
      setWallVisible(false);
      setwallBtnCaption("SHOW WALLS");
      wallshowhideimg = "./images/show-walls.png";
    } else {
      setWallVisible(true);
      setwallBtnCaption("HIDE WALLS");
      wallshowhideimg = "./images/remove-walls.png";
    }
  }

  let moveUp = (event) => {
    
    if (curY === row01X) {
      if (curX === col01X && col01Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col02X && col02Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col03X && col03Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col04X && col04Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col05X && col05Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col06X && col06Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col07X && col07Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col08X && col08Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col09X && col09Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col10X && col10Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col11X && col11Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col12X && col12Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col13X && col13Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col14X && col14Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } 
    
    } else if (curY === row02X) {
     
      if (curX === col01X && col01Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col02X && col02Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col03X && col03Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col04X && col04Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col05X && col06Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col06X && col06Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col07X && col07Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col08X && col08Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col09X && col09Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col10X && col10Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col11X && col11Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col12X && col12Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col13X && col13Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col14X && col14Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      }     
      
    } else if (curY === row03X) {
      if (curX === col01X && col01Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col02X && col02Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col03X && col03Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col04X && col04Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col05X && col05Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col06X && col06Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col07X && col07Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col08X && col08Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col09X && col09Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col10X && col10Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col11X && col11Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col12X && col12Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col13X && col13Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col14X && col14Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } 
  
    } else  {
      alert("No more Upward movement")
    }
    navigatorUpArrow ="./images/arrow-orange.png";
    navigatorLeftArrow = "./images/left-arrow.png";
    navigatorRightArrow =  "./images/right-arrow.png"
    navigatorDownArrow = "./images/down-arrow.png";
   
  }

  let moveDown = (event) => {
    setPlaceUpRight("PORTRAIT");
    setUpArrow(false);
    setDownArrow(true);
    setLeftArrow(false);
    setRightArrow(false);
    if (curY === row13X) {
        setCurY(row12X);
    } else if (curY === row12X) {
      setCurY(row11X);
    } else if (curY === row11X) {
      setCurY(row10X);
    } else if (curY === row10X) {
      setCurY(row09X);
    } else if (curY === row09X) {
      setCurY(row08X);
    } else if (curY === row08X) {
      setCurY(row07X);
    } else if (curY === row07X) {
      setCurY(row06X);
    } else if (curY === row06X) {
      setCurY(row05X);
    } else if (curY === row05X) {
      setCurY(row04X);
    } else if (curY === row04X) {
      setCurY(row03X);
    } else if (curY === row03X) {
      setCurY(row02X);
    } else if (curY === row02X) {
      setCurY(row01X);
      setPlaceUpRight("LANDSCAPE");
    } else  {
      alert("No more Downward movement")
    }
    navigatorUpArrow ="./images/up-arrow.png";
    navigatorLeftArrow = "./images/left-arrow.png";
    navigatorRightArrow =  "./images/right-arrow.png"
    navigatorDownArrow = "./images/arrow-orange.png";
  }

  let moveLeft = (event) => {
    if (curX === col14X) {
      if (curY === row01X &&  col13Row01 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col13Row02 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col13Row03 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col13Row04 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col13X) {
      if (curY === row01X &&  col12Row01 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col12Row02 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col12Row03 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col12Row04 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col12X) {
      if (curY === row01X &&  col11Row01 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col11Row02 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col11Row03 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col11Row04 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col11X) {
      if (curY === row01X &&  col10Row01 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col10Row02 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col10Row03 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col10Row04 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col10X) {
      if (curY === row01X &&  col09Row01 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col09Row02 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col09Row03 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col09Row04 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col09X) {
      if (curY === row01X &&  col08Row01 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col08Row02 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col08Row03 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col08Row04 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col08X) {
      if (curY === row01X &&  col07Row01 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col07Row02 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col07Row03 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col07Row04 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col07X) {
      if (curY === row01X &&  col06Row01 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col06Row02 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col06Row03 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col06Row04 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col06X) {
      if (curY === row01X &&  col05Row01 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col05Row02 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col05Row03 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col05Row04 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col05X) {
      if (curY === row01X &&  col04Row01 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col04Row02 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col04Row03 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col04Row04 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col04X) {
      if (curY === row01X &&  col03Row01 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col03Row02 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col03Row03 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col03Row04 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col03X) {
      if (curY === row01X &&  col02Row01 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col02Row02 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col02Row03 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col02Row04 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col02X) {
      if (curY === row01X &&  col01Row01 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col01Row02 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col01Row03 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col01Row04 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else  {
      alert("No more left movement");
    }
    navigatorUpArrow ="./images/up-arrow.png";
    navigatorLeftArrow = "./images/arrow-orange.png";
    navigatorRightArrow =  "./images/right-arrow.png"
    navigatorDownArrow = "./images/down-arrow.png";

  }

  let moveRight = (event) => {
    
    if (curX === col01X) {
      if (curY === row01X &&  col02Row01 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col02Row02 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col02Row03 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col02Row04 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
    } else if (curX === col02X) {
      if (curY === row01X &&  col03Row01 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col03Row02 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col03Row03 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col03Row04 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }

    } else if (curX === col03X) {
      if (curY === row01X &&  col04Row01 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col04Row02 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col04Row03 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col04Row04 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col04X) {
      if (curY === row01X &&  col05Row01 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col05Row02 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col05Row03 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col05Row04 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col05X) {
      if (curY === row01X &&  col06Row01 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col06Row02 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col06Row03 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col06Row04 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col06X) {
      if (curY === row01X &&  col07Row01 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col07Row02 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col07Row03 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col07Row04 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col07X) {
      if (curY === row01X &&  col08Row01 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else  if (curY === row02X &&  col08Row02 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col08Row03 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col08Row04 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col08X) {
      if (curY === row01X &&  col09Row01 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col09Row02 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col09Row03 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col09Row04 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col09X) {
      if (curY === row01X &&  col10Row01 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col10Row02 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col10Row03 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col10Row04 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col10X) {
      if (curY === row01X &&  col11Row01 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col11Row02 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col11Row03 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col11Row04 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col11X) {
      if (curY === row01X &&  col12Row01 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col12Row02 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col12Row03 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col12Row04 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }

    } else if (curX === col12X) {
      if (curY === row01X &&  col13Row01 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col13Row02 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col13Row03 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col13Row04=== true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }

    } else if (curX === col13X) {
      if (curY === row01X &&  col14Row01=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col14Row02=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col14Row03=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col14Row04=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else  {
      alert("No more right movement")
    }
    navigatorUpArrow ="./images/up-arrow.png";
    navigatorLeftArrow ="./images/left-arrow.png";
    navigatorRightArrow =  "./images/arrow-orange.png";
    navigatorDownArrow = "./images/down-arrow.png";
  }
 
  const handleKeyDown = (event) => {
    event.preventDefault();
    const key = event.code;
    switch (key) {
        case 'ArrowLeft':
            // left key pressed
            moveLeft();
            break;
        case 'ArrowUp':
            // up key pressed
            moveUp();
            break;
        case 'ArrowRight':
            // right key pressed
            moveRight();
            break;
        case 'ArrowDown':
            // down key pressed
            moveDown();
            break;  
        case 'Space':
          // down key pressed
          setPlacement();
              break;  
    }   
}       
 
   //First- base Row Small Module
   const [baseNaked01, setBaseNaked01] = useState([]);
   const [baseNaked02, setBaseNaked02] = useState([]);
   const [baseNaked03, setBaseNaked03] = useState([]);
   const [baseNaked04, setBaseNaked04] = useState([]);
   const [baseNaked05, setBaseNaked05] = useState([]);
   const [baseNaked06, setBaseNaked06] = useState([]);
   const [baseNaked07, setBaseNaked07] = useState([]);
   const [baseNaked08, setBaseNaked08] = useState([]);
   const [baseNaked09, setBaseNaked09] = useState([]);
   const [baseNaked10, setBaseNaked10] = useState([]);
   const [baseNaked11, setBaseNaked11] = useState([]);
   const [baseNaked12, setBaseNaked12] = useState([]);
   const [baseNaked13, setBaseNaked13] = useState([]);
   const [baseNaked14, setBaseNaked14] = useState([]);

    // 2nd row small modules

  const [row2Naked01, setRow2Naked01] = useState([]);
  const [row2Naked02, setRow2Naked02] = useState([]);
  const [row2Naked03, setRow2Naked03] = useState([]);
  const [row2Naked04, setRow2Naked04] = useState([]);
  const [row2Naked05, setRow2Naked05] = useState([]);
  const [row2Naked06, setRow2Naked06] = useState([]);
  const [row2Naked07, setRow2Naked07] = useState([]);
  const [row2Naked08, setRow2Naked08] = useState([]);
  const [row2Naked09, setRow2Naked09] = useState([]);
  const [row2Naked10, setRow2Naked10] = useState([]);
  const [row2Naked11, setRow2Naked11] = useState([]);
  const [row2Naked12, setRow2Naked12] = useState([]);
  const [row2Naked13, setRow2Naked13] = useState([]);
  const [row2Naked14, setRow2Naked14] = useState([]);

  const [row3Naked01, setRow3Naked01] = useState([]);
  const [row3Naked02, setRow3Naked02] = useState([]);
  const [row3Naked03, setRow3Naked03] = useState([]);
  const [row3Naked04, setRow3Naked04] = useState([]);
  const [row3Naked05, setRow3Naked05] = useState([]);
  const [row3Naked06, setRow3Naked06] = useState([]);
  const [row3Naked07, setRow3Naked07] = useState([]);
  const [row3Naked08, setRow3Naked08] = useState([]);
  const [row3Naked09, setRow3Naked09] = useState([]);
  const [row3Naked10, setRow3Naked10] = useState([]);
  const [row3Naked11, setRow3Naked11] = useState([]);
  const [row3Naked12, setRow3Naked12] = useState([]);
  const [row3Naked13, setRow3Naked13] = useState([]);
  const [row3Naked14, setRow3Naked14] = useState([]);

  const [row4Naked01, setRow4Naked01] = useState([]);
  const [row4Naked02, setRow4Naked02] = useState([]);
  const [row4Naked03, setRow4Naked03] = useState([]);
  const [row4Naked04, setRow4Naked04] = useState([]);
  const [row4Naked05, setRow4Naked05] = useState([]);
  const [row4Naked06, setRow4Naked06] = useState([]);
  const [row4Naked07, setRow4Naked07] = useState([]);
  const [row4Naked08, setRow4Naked08] = useState([]);
  const [row4Naked09, setRow4Naked09] = useState([]);
  const [row4Naked10, setRow4Naked10] = useState([]);
  const [row4Naked11, setRow4Naked11] = useState([]);
  const [row4Naked12, setRow4Naked12] = useState([]);
  const [row4Naked13, setRow4Naked13] = useState([]);
  const [row4Naked14, setRow4Naked14] = useState([]);
  

  // First Row Medium Modules
  const [baseNakedM01, setBaseNakedM01] = useState([]);
  const [baseNakedM02, setBaseNakedM02] = useState([]);
  const [baseNakedM03, setBaseNakedM03] = useState([]);
  const [baseNakedM04, setBaseNakedM04] = useState([]);
  const [baseNakedM05, setBaseNakedM05] = useState([]);
  const [baseNakedM06, setBaseNakedM06] = useState([]);
  const [baseNakedM07, setBaseNakedM07] = useState([]);
  const [baseNakedM08, setBaseNakedM08] = useState([]);
  const [baseNakedM09, setBaseNakedM09] = useState([]);
  const [baseNakedM10, setBaseNakedM10] = useState([]);
  const [baseNakedM11, setBaseNakedM11] = useState([]);
  const [baseNakedM12, setBaseNakedM12] = useState([]);
  const [baseNakedM13, setBaseNakedM13] = useState([]);
  const [baseNakedM14, setBaseNakedM14] = useState([]);

// 2nd row Medium Module

const [row2NakedM01, setRow2NakedM01] = useState([]);
const [row2NakedM02, setRow2NakedM02] = useState([]);
const [row2NakedM03, setRow2NakedM03] = useState([]);
const [row2NakedM04, setRow2NakedM04] = useState([]);
const [row2NakedM05, setRow2NakedM05] = useState([]);
const [row2NakedM06, setRow2NakedM06] = useState([]);
const [row2NakedM07, setRow2NakedM07] = useState([]);
const [row2NakedM08, setRow2NakedM08] = useState([]);
const [row2NakedM09, setRow2NakedM09] = useState([]);
const [row2NakedM10, setRow2NakedM10] = useState([]);
const [row2NakedM11, setRow2NakedM11] = useState([]);
const [row2NakedM12, setRow2NakedM12] = useState([]);
const [row2NakedM13, setRow2NakedM13] = useState([]);
const [row2NakedM14, setRow2NakedM14] = useState([]);

//3rd row Medium

const [row3NakedM01, setRow3NakedM01] = useState([]);
const [row3NakedM02, setRow3NakedM02] = useState([]);
const [row3NakedM03, setRow3NakedM03] = useState([]);
const [row3NakedM04, setRow3NakedM04] = useState([]);
const [row3NakedM05, setRow3NakedM05] = useState([]);
const [row3NakedM06, setRow3NakedM06] = useState([]);
const [row3NakedM07, setRow3NakedM07] = useState([]);
const [row3NakedM08, setRow3NakedM08] = useState([]);
const [row3NakedM09, setRow3NakedM09] = useState([]);
const [row3NakedM10, setRow3NakedM10] = useState([]);
const [row3NakedM11, setRow3NakedM11] = useState([]);
const [row3NakedM12, setRow3NakedM12] = useState([]);
const [row3NakedM13, setRow3NakedM13] = useState([]);
const [row3NakedM14, setRow3NakedM14] = useState([]);

const [baseNakedL01, setBaseNakedL01] = useState([]);
const [baseNakedL02, setBaseNakedL02] = useState([]);
const [baseNakedL03, setBaseNakedL03] = useState([]);
const [baseNakedL04, setBaseNakedL04] = useState([]);
const [baseNakedL05, setBaseNakedL05] = useState([]);
const [baseNakedL06, setBaseNakedL06] = useState([]);
const [baseNakedL07, setBaseNakedL07] = useState([]);
const [baseNakedL08, setBaseNakedL08] = useState([]);
const [baseNakedL09, setBaseNakedL09] = useState([]);
const [baseNakedL10, setBaseNakedL10] = useState([]);
const [baseNakedL11, setBaseNakedL11] = useState([]);
const [baseNakedL12, setBaseNakedL12] = useState([]);
const [baseNakedL13, setBaseNakedL13] = useState([]);
const [baseNakedL14, setBaseNakedL14] = useState([]);

let setPlacement = (event) => {
  if (placeUpRight === "LANDSCAPE") {
    setPlaceUpRight("PORTRAIT");
    setUpArrow(true);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(false);
  } else {
    setPlaceUpRight("LANDSCAPE");
    setUpArrow(false);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(true);
  }

}

const removeLargeDoors = (e)=> {

  if (curX === col01X && curY === row01X) {
    if (c1R1VNo === 9 || c1R1VNo === 10 || c1R1VNo === 11) {
      if (c1r1MT === "L") {  
        setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(8);
                    setC1R2VNo(8);
                    setC1R3VNo(8);
                    setC1R4VNo(8);
                    if (c1R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
  } else if (curX === col02X && curY === row01X) {
    if (c2R1VNo === 9 || c2R1VNo === 10 || c2R1VNo === 11) {
       if (c2r1MT === "L") {  
        setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(8);
                    setC2R2VNo(8);
                    setC2R3VNo(8);
                    setC2R4VNo(8);
                    if (c2R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
 } else if (curX === col03X && curY === row01X) {
    if (c3R1VNo === 9 || c3R1VNo === 10 || c3R1VNo === 11) {
      if (c3r1MT === "L") {  
        setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(8);
                    setC3R2VNo(8);
                    setC3R3VNo(8);
                    setC3R4VNo(8);
                    if (c3R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
       
 } else if (curX === col04X && curY === row01X) {
    if (c4R1VNo === 9 || c4R1VNo === 10 || c4R1VNo === 11) {
       if (c4r1MT === "L") {  
        setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(8);
                    setC4R2VNo(8);
                    setC4R3VNo(8);
                    setC4R4VNo(8);
                    if (c4R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
       
  } else if (curX === col05X && curY === row01X) {
    if (c5R1VNo === 9 || c5R1VNo === 10 || c5R1VNo === 11) {
       if (c5r1MT === "L") {  
        setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(8);
                    setC5R2VNo(8);
                    setC5R3VNo(8);
                    setC5R4VNo(8);
                    if (c5R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
  } else if (curX === col06X && curY === row01X) {
          if (c6R1VNo === 9 || c6R1VNo === 10 || c6R1VNo === 11) {
               if (c6r1MT === "L") {  
            setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(8);
                    setC6R2VNo(8);
                    setC6R3VNo(8);
                    setC6R4VNo(8);
                    if (c6R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col07X && curY === row01X) {
      if (c7R1VNo === 9 || c7R1VNo === 10 || c7R1VNo === 11) {
         if (c7r1MT === "L") {  
          setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(8);
                    setC7R2VNo(8);
                    setC7R3VNo(8);
                    setC7R4VNo(8);
                    if (c7R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col08X && curY === row01X) {
    if (c8R1VNo === 9 || c8R1VNo === 10 || c8R1VNo === 11) {
       if (c8r1MT === "L") {  
          setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(8);
                    setC8R2VNo(8);
                    setC8R3VNo(8);
                    setC8R4VNo(8);
                    if (c8R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col09X && curY === row01X) {
        if (c9R1VNo === 9 || c9R1VNo === 10 || c9R1VNo === 11) {
           if (c9r1MT === "L") {  
          setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(8);
                    setC9R2VNo(8);
                    setC9R3VNo(8);
                    setC9R4VNo(8);
                    if (c9R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col10X && curY === row01X) {
        if (c10R1VNo === 9 || c10R1VNo === 10 || c10R1VNo === 11) {
           if (c10r1MT === "L") {  
          setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(8);
                    setC10R2VNo(8);
                    setC10R3VNo(8);
                    setC10R4VNo(8);
                    if (c10R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col11X && curY === row01X) {
        if (c11R1VNo === 9 || c11R1VNo === 10 || c11R1VNo === 11) {
           if (c11r1MT === "L") {  
            setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(8);
                    setC11R2VNo(8);
                    setC11R3VNo(8);
                    setC11R4VNo(8);
                    if (c11R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col12X && curY === row01X) {
        if (c12R1VNo === 9 || c12R1VNo === 10 || c12R1VNo === 11) {
          if (c12r1MT === "L") {  
          setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(8);
                    setC12R2VNo(8);
                    setC12R3VNo(8);
                    setC12R4VNo(8);
                    if (c12R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col13X && curY === row01X) {
          if (c13R1VNo === 9 || c13R1VNo === 10 || c13R1VNo === 11) {
            if (c13r1MT === "L") {  
            setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(8);
                    setC13R2VNo(8);
                    setC13R3VNo(8);
                    setC13R4VNo(8);
                    if (c13R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }
      
    } else if (curX === col14X && curY === row01X) {
        if (c14R1VNo === 9 || c14R1VNo === 10 || c14R1VNo === 11) {
           if (c14r1MT === "L") {  
          setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(8);
                    setC14R2VNo(8);
                    setC14R3VNo(8);
                    setC14R4VNo(8);
                    if (c14R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

      } else {
         alert("This is not a large door");
      }
     } else {
      alert("Already Removed");
     }

}  else if (curX === col01X && curY === row02X) {
            if (c1R2VNo === 9 || c1R2VNo === 10 || c1R2VNo === 11) {
              if (c1r2MT === "L") {  
            setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(8);
                    setC1R2VNo(8);
                    setC1R3VNo(8);
                    setC1R4VNo(8);
                    if (c1R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
        
     } else if (curX === col02X && curY === row02X) {
              if (c2R2VNo === 9 || c2R2VNo === 10 || c2R2VNo === 11) {
                if (c2r2MT === "L") {  
            setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(8);
                    setC2R2VNo(8);
                    setC2R3VNo(8);
                    setC2R4VNo(8);
                    if (c2R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
      } else if (curX === col03X && curY === row02X) {
        if (c3R2VNo === 9 || c3R2VNo === 10 || c3R2VNo === 11) {
          if (c3r2MT === "L") {  
            setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(8);
                    setC3R2VNo(8);
                    setC3R3VNo(8);
                    setC3R4VNo(8);
                    if (c3R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
      } else if (curX === col04X && curY === row02X) {
                if (c4R2VNo === 9 || c4R2VNo === 10 || c4R2VNo === 11) {
                   if (c4r2MT === "L") {  
            setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(8);
                    setC4R2VNo(8);
                    setC4R3VNo(8);
                    setC4R4VNo(8);
                    if (c4R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
           
          } else if (curX === col05X && curY === row02X) {
            if (c5R2VNo === 9 || c5R2VNo === 10 || c5R2VNo === 11) {
               if (c5r2MT === "L") {  
            setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(8);
                    setC5R2VNo(8);
                    setC5R3VNo(8);
                    setC5R4VNo(8);
                    if (c5R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
          } else if (curX === col06X && curY === row02X) {
              if (c6R2VNo === 9 || c6R2VNo === 10 || c6R2VNo === 11) {
                if (c6r2MT === "L") {  
            setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(8);
                    setC6R2VNo(8);
                    setC6R3VNo(8);
                    setC6R4VNo(8);
                    if (c6R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
          }
       } else {
        alert("Already Removed");
       }    
          
        } else if (curX === col07X && curY === row02X) {
                if (c7R2VNo === 9 || c7R2VNo === 10 || c7R2VNo === 11) {
                   if (c7r2MT === "L") {  
            setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(8);
                    setC7R2VNo(8);
                    setC7R3VNo(8);
                    setC7R4VNo(8);
                    if (c7R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
        } else if (curX === col08X && curY === row02X) {
            if (c8R2VNo === 9 || c8R2VNo === 10 || c8R2VNo === 11) {
               if (c8r2MT === "L") {  
            setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(8);
                    setC8R2VNo(8);
                    setC8R3VNo(8);
                    setC8R4VNo(8);
                    if (c8R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
        } else if (curX === col09X && curY === row02X) {
            if (c9R2VNo === 9 || c9R2VNo === 10 || c9R2VNo === 11) {
              if (c9r2MT === "L") {  
            setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(8);
                    setC9R2VNo(8);
                    setC9R3VNo(8);
                    setC9R4VNo(8);
                    if (c9R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
        } else if (curX === col10X && curY === row02X) {
                if (c10R2VNo === 9 || c10R2VNo === 10 || c10R2VNo === 11) {
                  if (c10r2MT === "L") {  
            setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(8);
                    setC10R2VNo(8);
                    setC10R3VNo(8);
                    setC10R4VNo(8);
                    if (c10R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
        } else if (curX === col11X && curY === row02X) {
            if (c11R2VNo === 9 || c11R2VNo === 10 || c11R2VNo === 11) {
               if (c11r2MT === "L") {  
            setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(8);
                    setC11R2VNo(8);
                    setC11R3VNo(8);
                    setC11R4VNo(8);
                    if (c11R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
        } else if (curX === col12X && curY === row02X) {
            if (c12R2VNo === 9 || c12R2VNo === 10 || c12R2VNo === 11) {
               if (c12r2MT === "L") {  
            setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(8);
                    setC12R2VNo(8);
                    setC12R3VNo(8);
                    setC12R4VNo(8);
                    if (c12R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
        } else if (curX === col13X && curY === row02X) {
              if (c13R2VNo === 9 || c13R2VNo === 10 || c13R2VNo === 11) {
                if (c13r2MT === "L") {  
            setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(8);
                    setC13R2VNo(8);
                    setC13R3VNo(8);
                    setC13R4VNo(8);
                    if (c13R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
          
        } else if (curX === col14X && curY === row02X) {
            if (c14R2VNo === 9 || c14R2VNo === 10 || c14R2VNo === 11) {
             if (c14r2MT === "L") {  
            setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(8);
                    setC14R2VNo(8);
                    setC14R3VNo(8);
                    setC14R4VNo(8);
                    if (c14R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
            
          } else if (curX === col01X && curY === row03X) {
                if (c1R3VNo === 9 || c1R3VNo === 10 || c1R3VNo === 11) {
                  if (c1r3MT === "L") {  
            setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(8);
                    setC1R2VNo(8);
                    setC1R3VNo(8);
                    setC1R4VNo(8);
                    if (c1R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
            
              } else if (curX === col02X && curY === row03X) {
                  if (c2R3VNo === 9 || c2R3VNo === 10 || c2R3VNo === 11) {
                     if (c2r3MT === "L") {  
            setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(8);
                    setC2R2VNo(8);
                    setC2R3VNo(8);
                    setC2R4VNo(8);
                    if (c2R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
              } else if (curX === col03X && curY === row03X) {
                if (c3R3VNo === 9 || c3R3VNo === 10 || c3R3VNo === 11) {
                   if (c3r3MT === "L") {  
            setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(8);
                    setC3R2VNo(8);
                    setC3R3VNo(8);
                    setC3R4VNo(8);
                    if (c3R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
               
              } else if (curX === col04X && curY === row03X) {
                  if (c4R3VNo === 9 || c4R3VNo === 10 || c4R3VNo === 11) {
                    if (c4r3MT === "L") {  
            setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(8);
                    setC4R2VNo(8);
                    setC4R3VNo(8);
                    setC4R4VNo(8);
                    if (c4R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
               
              } else if (curX === col05X && curY === row03X) {
                  if (c5R3VNo === 9 || c5R3VNo === 10 || c5R3VNo === 11) {
                     if (c5r3MT === "L") {  
            setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(8);
                    setC5R2VNo(8);
                    setC5R3VNo(8);
                    setC5R4VNo(8);
                    if (c5R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
      } else if (curX === col06X && curY === row03X) {
                    if (c6R3VNo === 9 || c6R3VNo === 10 || c6R3VNo === 11) {
                      if (c6r3MT === "L") {  
            setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(8);
                    setC6R2VNo(8);
                    setC6R3VNo(8);
                    setC6R4VNo(8);
                    if (c6R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col07X && curY === row03X) {
                if (c7R3VNo === 9 || c7R3VNo === 10 || c7R3VNo === 11) {
                   if (c7r3MT === "L") {  
            setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(8);
                    setC7R2VNo(8);
                    setC7R3VNo(8);
                    setC7R4VNo(8);
                    if (c7R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
                  
            } else if (curX === col08X && curY === row03X) {
                if (c8R3VNo === 9 || c8R3VNo === 10 || c8R3VNo === 11) {
                   if (c8r3MT === "L") {  
            setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(8);
                    setC8R2VNo(8);
                    setC8R3VNo(8);
                    setC8R4VNo(8);
                    if (c8R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col09X && curY === row03X) {
                  if (c9R3VNo === 9 || c9R3VNo === 10 || c9R3VNo === 11) {
                     if (c9r3MT === "L") {  
            setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(8);
                    setC9R2VNo(8);
                    setC9R3VNo(8);
                    setC9R4VNo(8);
                    if (c9R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col10X && curY === row03X) {
                if (c10R3VNo === 9 || c10R3VNo === 10 || c10R3VNo === 11) {
                   if (c10r3MT === "L") {  
            setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(8);
                    setC10R2VNo(8);
                    setC10R3VNo(8);
                    setC10R4VNo(8);
                    if (c10R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col11X && curY === row03X) {
                if (c11R3VNo === 9 || c11R3VNo === 10 || c11R3VNo === 11) {
                   if (c11r3MT === "L") {  
            setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(8);
                    setC11R2VNo(8);
                    setC11R3VNo(8);
                    setC11R4VNo(8);
                    if (c11R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col12X && curY === row03X) {
                if (c12R3VNo === 9 || c12R3VNo === 10 || c12R3VNo === 11) {
                  if (c12r3MT === "L") {  
            setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(8);
                    setC12R2VNo(8);
                    setC12R3VNo(8);
                    setC12R4VNo(8);
                    if (c12R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col13X && curY === row03X) {
                  if (c13R3VNo === 9 || c13R3VNo === 10 || c13R3VNo === 11) {
                     if (c13r3MT === "L") {  
            setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(8);
                    setC13R2VNo(8);
                    setC13R3VNo(8);
                    setC13R4VNo(8);
                    if (c13R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col14X && curY === row03X) {
                  if (c14R3VNo === 9 || c14R3VNo === 10 || c14R3VNo === 11) {
                     if (c14r3MT === "L") {  
            setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(8);
                    setC14R2VNo(8);
                    setC14R3VNo(8);
                    setC14R4VNo(8);
                    if (c14R3VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
                
        } else if (curX === col01X && curY === row04X) {
                if (c1R4VNo === 9 || c1R4VNo === 10 || c1R4VNo === 11) {
                  if (c1r4MT === "L") {  
            setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(8);
                    setC1R2VNo(8);
                    setC1R3VNo(8);
                    setC1R4VNo(8);
                    if (c1R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
            
              } else if (curX === col02X && curY === row04X) {
                  if (c2R4VNo === 9 || c2R4VNo === 10 || c2R4VNo === 11) {
                     if (c2r4MT === "L") {  
            setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(8);
                    setC2R2VNo(8);
                    setC2R3VNo(8);
                    setC2R4VNo(8);
                    if (c2R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
              } else if (curX === col03X && curY === row04X) {
                if (c3R4VNo === 9 || c3R4VNo === 10 || c3R4VNo === 11) {
                   if (c3r4MT === "L") {  
            setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(8);
                    setC3R2VNo(8);
                    setC3R3VNo(8);
                    setC3R4VNo(8);
                    if (c3R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
               
              } else if (curX === col04X && curY === row04X) {
                  if (c4R4VNo === 9 || c4R4VNo === 10 || c4R4VNo === 11) {
                    if (c4r4MT === "L") {  
            setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(8);
                    setC4R2VNo(8);
                    setC4R3VNo(8);
                    setC4R4VNo(8);
                    if (c4R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
               
              } else if (curX === col05X && curY === row04X) {
                  if (c5R4VNo === 9 || c5R4VNo === 10 || c5R4VNo === 11) {
                     if (c5r3MT === "L") {  
            setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(8);
                    setC5R2VNo(8);
                    setC5R3VNo(8);
                    setC5R4VNo(8);
                    if (c5R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
              } else if (curX === col06X && curY === row04X) {
                    if (c6R4VNo === 9 || c6R4VNo === 10 || c6R4VNo === 11) {
                      if (c6r4MT === "L") {  
            setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(8);
                    setC6R2VNo(8);
                    setC6R3VNo(8);
                    setC6R4VNo(8);
                    if (c6R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col07X && curY === row04X) {
                if (c7R4VNo === 9 || c7R4VNo === 10 || c7R4VNo === 11) {
                   if (c7r4MT === "L") {  
            setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(8);
                    setC7R2VNo(8);
                    setC7R3VNo(8);
                    setC7R4VNo(8);
                    if (c7R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
                  
            } else if (curX === col08X && curY === row04X) {
                if (c8R4VNo === 9 || c8R4VNo === 10 || c8R4VNo === 11) {
                   if (c8r4MT === "L") {  
            setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(8);
                    setC8R2VNo(8);
                    setC8R3VNo(8);
                    setC8R4VNo(8);
                    if (c8R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col09X && curY === row04X) {
                  if (c9R4VNo === 9 || c9R4VNo === 10 || c9R4VNo === 11) {
                     if (c9r4MT === "L") {  
            setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(8);
                    setC9R2VNo(8);
                    setC9R3VNo(8);
                    setC9R4VNo(8);
                    if (c9R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col10X && curY === row04X) {
                if (c10R4VNo === 9 || c10R4VNo === 10 || c10R4VNo === 11) {
                   if (c10r4MT === "L") {  
            setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(8);
                    setC10R2VNo(8);
                    setC10R3VNo(8);
                    setC10R4VNo(8);
                    if (c10R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

        } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col11X && curY === row04X) {
                if (c11R4VNo === 9 || c11R4VNo === 10 || c11R4VNo === 11) {
                   if (c11r4MT === "L") {  
            setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(8);
                    setC11R2VNo(8);
                    setC11R3VNo(8);
                    setC11R4VNo(8);
                    if (c11R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

          } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col12X && curY === row04X) {
                if (c12R4VNo === 9 || c12R4VNo === 10 || c12R4VNo === 11) {
                   if (c12r4MT === "L") {  
            setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(8);
                    setC12R2VNo(8);
                    setC12R3VNo(8);
                    setC12R4VNo(8);
                    if (c12R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

         } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col13X && curY === row04X) {
                  if (c13R4VNo === 9 || c13R4VNo === 10 || c13R4VNo === 11) {
                     if (c13r4MT === "L") {  
            setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(8);
                    setC13R2VNo(8);
                    setC13R3VNo(8);
                    setC13R4VNo(8);
                    if (c13R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

         } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
              
            } else if (curX === col14X && curY === row04X) {
                  if (c14R4VNo === 9 || c14R4VNo === 10 || c14R4VNo === 11) {
                     if (c14r4MT === "L") {  
            setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(8);
                    setC14R2VNo(8);
                    setC14R3VNo(8);
                    setC14R4VNo(8);
                    if (c14R4VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }

         } else {
             alert("This is not a large door");
           }
       } else {
        alert("Already Removed");
       }    
                
      }
}



const addLargeodules = (e) => {
  addSmallDoorsBtn = "./images/smallModule.png";
  addMediumDoorsBtn ="./images/mediumModule.png";
  addLargeDoorsBtn ="./images/largeModule-hover.png";
  removeDoorsBtn ="./images/Remove-Modules.png";
  if (colCount < maxRightCount) {
    setUpArrow(false);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(true);
    if (curX === 0 && curY === 0) {
      if (col01Row01 === false) {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col01X,row01X,0.5]} />
          ]
        )
        
        setCol01Row01(true);
        setCol01Row02(true);
        setCol01Row03(true);
        setCol01Row04(true)
        setC1r1MT("L")
        setC1r2MT("L")
        setC1r3MT("L")
        setC1r4MT("L")
        setC1R1VNo(1);
        setC1R2VNo(1);
        setC1R3VNo(1);
        setC1R4VNo(1);
        setColCount(1);
        setRowCount(4);
        setCurX(col01X);
        setCurY(row01X);
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);

       
        const newwidth = floWidth + 0.768;
        setFloWidth(newwidth);
        heightCalculate();
      }
    } else if (curX === col01X && curY === row01X) {
      if (col01Row01 === true && col02Row01 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col02X,row01X,0.5]} />
            ]
          )
          setCol02Row01(true);
          setCol02Row02(true);
          setCol02Row03(true);
          setCol02Row04(true);
          setC2r1MT("L")
          setC2r2MT("L")
          setC2r3MT("L")
          setC2r4MT("L")
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setColCount(2);
          setRowCount(4);
          setCurX(col02X);
          setCurY(row01X);
      
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
        }
    } else if (curX === col02X && curY === row01X)  {
      if (col02Row01 === true && col03Row01 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col03X,row01X,0.5]} />
            ]
          )
          setCol03Row01(true);
          setCol03Row02(true);
          setCol03Row03(true);
          setCol03Row04(true);
          setC3r1MT("L")
          setC3r2MT("L")
          setC3r3MT("L")
          setC3r4MT("L")
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setColCount(3);
          setRowCount(4);
          setCurX(col03X);
          setCurY(row01X);
          
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
      
        }
    } else if (curX === col03X && curY === row01X)  {
      if (col03Row01 === true && col04Row01 === false) {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col04X,row01X,0.5]} />
          ]
        )
        setCol04Row01(true);
        setCol04Row02(true);
        setCol04Row03(true);
        setCol04Row04(true);
        setC4r1MT("L")
        setC4r2MT("L")
        setC4r3MT("L")
        setC4r4MT("L")
        setC4R1VNo(1);
        setC4R2VNo(1);
        setC4R3VNo(1);
        setC4R4VNo(1);
        setColCount(4);
        setRowCount(4);
        setCurX(col04X);
        setCurY(row01X);


        const newwidth = floWidth + 0.768;
        setFloWidth(newwidth);
        heightCalculate();
       
     }
    } else if (curX === col04X && curY === row01X)  {
      if (col04Row01 === true && col05Row01 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col05X,row01X,0.5]} />
            ]
          )
          
          setCol05Row01(true);
          setCol05Row02(true);
    setCol05Row03(true);
          setCol05Row04(true);
          setC5r1MT("L")
          setC5r2MT("L")
          setC5r3MT("L")
          setC5r4MT("L")
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setColCount(5);
          setRowCount(4);
          setCurX(col05X);
          setCurY(row01X);
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
         
        }
    } else if (curX === col05X && curY === row01X)  {
      if (col05Row01 === true && col06Row01 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col06X,row01X,0.5]} />
            ]
          )
          setCol06Row01(true);
          setCol06Row02(true);
          setCol06Row03(true);
          setCol06Row04(true);
          setC6r1MT("L")
          setC6r2MT("L")
          setC6r3MT("L")
          setC6r4MT("L")
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setColCount(6);
          setRowCount(4);
          setCurX(col06X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
        }
    } else if (curX === col06X && curY === row01X)  {
      if (col06Row01 === true && col07Row01 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col07X,row01X,0.5]} />
            ]
          )
          setCol07Row01(true);
          setCol07Row02(true);
          setCol07Row03(true);
          setCol07Row04(true);
          setC7r1MT("L")
          setC7r2MT("L")
          setC7r3MT("L")
          setC7r4MT("L")
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setColCount(7);
          setRowCount(4);
          setCurX(col07X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
      }
    } else if (curX === col07X && curY === row01X)  {
      if (col07Row01 === true && col08Row01 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col08X,row01X,0.5]} />
            ]
          )
          setCol08Row01(true);
          setCol08Row02(true);
          setCol08Row03(true);
          setCol08Row04(true);
          setC8r1MT("L")
          setC8r2MT("L")
          setC8r3MT("L")
          setC8r4MT("L")
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setColCount(8);
          setRowCount(4);
          setCurX(col08X);
          setCurY(row01X);
  
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
      }
    } else if (curX === col08X && curY === row01X)  {
      if (col08Row01 === true && col09Row01 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col09X,row01X,0.5]} />
            ]
          )
          setCol09Row01(true);
          setCol09Row02(true);
          setCol09Row03(true);
          setCol09Row04(true);
          setC9r1MT("L")
          setC9r2MT("L")
          setC9r3MT("L")
          setC9r4MT("L")
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setColCount(9);
          setRowCount(4);
          setCurX(col09X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
      }
    } else if (curX === col09X && curY === row01X)  {
      if (col09Row01 === true && col10Row01 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col10X,row01X,0.5]} />
            ]
          )
          setCol10Row01(true);
          setCol10Row02(true);
          setCol10Row03(true);
          setCol10Row04(true);
          setC10r1MT("L")
          setC10r2MT("L")
          setC10r3MT("L")
          setC10r4MT("L")
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setColCount(10);
          setRowCount(4);
          setCurX(col10X);
          setCurY(row01X);
  
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
         
      }
    } else if (curX === col10X && curY === row01X)  {
      if (col10Row01 === true && col11Row01 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col11X,row01X,0.5]} />
            ]
          )
  
          setCol11Row01(true);
          setCol11Row02(true);
          setCol11Row03(true);
          setCol11Row04(true);
          setC11r1MT("L")
          setC11r2MT("L")
          setC11r3MT("L")
          setC11r4MT("L")
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setColCount(11);
          setRowCount(4);
          setCurX(col11X);
          setCurY(row01X);
    
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
      }

    } else if (curX === col11X && curY === row01X)  {
      if (col11Row01 === true && col12Row01 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col12X,row01X,0.5]} />
            ]
          )
  
          setCol12Row01(true);
          setCol12Row02(true);
          setCol12Row03(true);
          setCol12Row04(true);
          setC12r1MT("L")
          setC12r2MT("L")
          setC12r3MT("L")
          setC12r4MT("L")
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setColCount(12);
          setRowCount(4);
          setCurX(col12X);
          setCurY(row01X);
      
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
        }
    } else if (curX === col12X && curY === row01X) {
      if (col12Row01 === true && col13Row01 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col13X,row01X,0.5]} />
            ]
          )
          setCol13Row01(true);
          setCol13Row02(true);
          setCol13Row03(true);
          setCol13Row04(true);
          setC13r1MT("L")
          setC13r2MT("L")
          setC13r3MT("L")
          setC13r4MT("L")
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setColCount(13);
          setRowCount(4);
          setCurX(col13X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
      }
    } else if (curX === col13X && curY === row01X)  {
      if (col13Row01 === true && col14Row01 === false){
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3}} position={[col14X,row01X,0.5]} />
            ]
          )
          setCol14Row01(true);
          setCol14Row02(true);
          setCol14Row03(true);
          setCol14Row04(true);
          setC14r1MT("L")
          setC14r2MT("L")
          setC14r3MT("L")
          setC14r4MT("L")
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setColCount(14);
          setRowCount(4);
          setCurX(col14X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          
      }
    } 
  } else {
    alert("No more modules can be added horizontally.")
  }
  //heightCalculate();
  updatePriceWieght();

}

const addMediumModules = (e) => {
  addSmallDoorsBtn = "./images/smallModule.png";
  addMediumDoorsBtn ="./images/mediumModule-hover.png";
  addLargeDoorsBtn ="./images/largeModule.png";
  removeDoorsBtn ="./images/Remove-Modules.png";
  if (placeUpRight === "LANDSCAPE") {
    setUpArrow(false);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(true);
    if (colCount < maxRightCount) {
      if (curX === 0 && curY === 0) {
        if (col01Row01 === false) {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          
          setCol01Row01(true);
          setCol01Row02(true);
          setC1r1MT("M")
          setC1r2MT("M")
          setC1R1VNo(1);
          setC1R2VNo(1);
          setColCount(1);
          setRowCount(2);
          setCurX(col01X);
          setCurY(row01X);
          setUpArrow(false);
          setDownArrow(false);
          setLeftArrow(false);
          setRightArrow(true);
      

          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col01X && curY === row01X) {
        if (col01Row01 === true && col02Row01 === false) {
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setCol02Row01(true);
            setCol02Row02(true);
            setC2r1MT("M")
            setC2r2MT("M")
            setC2R1VNo(1);
            setC2R2VNo(1);
            setColCount(2);
            setRowCount(2);
            setCurX(col02X);
            setCurY(row01X);

        
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col02X && curY === row01X)  {
        if (col02Row01 === true && col03Row01 === false) {
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
              ]
            )
            setCol03Row01(true);
            setCol03Row02(true);
            setC3r1MT("M")
            setC3r2MT("M")
            
            setC3R1VNo(1);
            setC3R2VNo(1);
            setColCount(3);
            setRowCount(2);
            setCurX(col03X);
            setCurY(row01X);
            
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col03X && curY === row01X)  {
        if (col03Row01 === true && col04Row01 === false) {
          setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setCol04Row01(true);
          setCol04Row02(true);
          setC4r1MT("M")
          setC4r2MT("M")
          setC4R1VNo(1);
          setC4R2VNo(1);
          setColCount(4);
          setRowCount(2);
          setCurX(col04X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
       }
      } else if (curX === col04X && curY === row01X)  {
        if (col04Row01 === true && col05Row01 === false) {
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            
            setCol05Row01(true);
            setCol05Row02(true);
            setC5r1MT("M")
            setC5r2MT("M")
            setC5R1VNo(1);
            setC5R2VNo(1);
            setColCount(5);
            setRowCount(2);
            setCurX(col05X);
            setCurY(row01X);
          
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col05X && curY === row01X)  {
        if (col05Row01 === true && col06Row01 === false) {
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setCol06Row01(true);
            setCol06Row02(true);
            setC6r1MT("M")
            setC6r2MT("M")
            setC6R1VNo(1);
            setC6R2VNo(1);
            setColCount(6);
            setRowCount(2);
            setCurX(col06X);
            setCurY(row01X);

    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col06X && curY === row01X)  {
        if (col06Row01 === true && col07Row01 === false) {
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setCol07Row01(true);
            setCol07Row02(true);
            setC7r1MT("M")
            setC7r2MT("M")
            setC7R1VNo(1);
            setC7R2VNo(1);
            setColCount(7);
            setRowCount(2);
            setCurX(col07X);
            setCurY(row01X);
            
    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } else if (curX === col07X && curY === row01X)  {
        if (col07Row01 === true && col08Row01 === false) {
            setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setCol08Row01(true);
            setCol08Row02(true);
            setC8r1MT("M")
            setC8r2MT("M")
            setC8R1VNo(1);
            setC8R2VNo(1);
            setColCount(8);
            setRowCount(2);
            setCurX(col08X);
            setCurY(row01X);
    
            
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } else if (curX === col08X && curY === row01X)  {
        if (col08Row01 === true && col09Row01 === false) {
            setBaseNakedM09(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setCol09Row01(true);
            setCol09Row02(true);
            setC9r1MT("M")
            setC9r2MT("M")
            setC9R1VNo(1);
            setC9R2VNo(1);
            setColCount(9);
            setRowCount(2);
            setCurX(col09X);
            setCurY(row01X);
            
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } else if (curX === col09X && curY === row01X)  {
        if (col09Row01 === true && col10Row01 === false) {
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setCol10Row01(true);
            setCol10Row02(true);
            setC10r1MT("M")
            setC10r2MT("M")
            setC10R1VNo(1);
            setC10R2VNo(1);
            setColCount(10);
            setRowCount(2);
            setCurX(col10X);
            setCurY(row01X);
    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } else if (curX === col10X && curY === row01X)  {
        if (col10Row01 === true && col11Row01 === false) {
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
    
            setCol11Row01(true);
            setCol11Row02(true);
            setC11r1MT("M")
            setC11r2MT("M")
            setC11R1VNo(1);
            setC11R2VNo(1);
            setColCount(11);
            setRowCount(2);
            setCurX(col11X);
            setCurY(row01X);
            
      
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }

      } else if (curX === col11X && curY === row01X)  {
        if (col11Row01 === true && col12Row01 === false) {
            setBaseNakedM12(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
              ]
            )
    
            setCol12Row01(true);
            setCol12Row02(true);
            setC12r1MT("M")
            setC12r2MT("M")
            setC12R1VNo(1);
            setC12R2VNo(1);
            setColCount(12);
            setRowCount(2);
            setCurX(col12X);
            setCurY(row01X);
        
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col12X && curY === row01X) {
        if (col12Row01 === true && col13Row01 === false) {
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setCol13Row01(true);
            setCol13Row02(true);
            setC13r1MT("M")
            setC13r2MT("M")
            setC13R1VNo(1);
            setC13R2VNo(1);
            setColCount(13);
            setRowCount(2);
            setCurX(col13X);
            setCurY(row01X);
    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } else if (curX === col13X && curY === row01X)  {
        if (col13Row01 === true && col14Row01 === false){
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
              ]
            )
            setCol14Row01(true);
            setCol14Row02(true);
            setC14r1MT("M")
            setC14r2MT("M")
            setC14R1VNo(1);
            setC14R2VNo(1);
            setColCount(14);
            setRowCount(2);
            setCurX(col14X);
            setCurY(row01X);
    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } 
    } else {
      alert("No more modules can be added horizontally.")
    }
  } else {
    setUpArrow(true);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(false);
      if (curX === 0 && curY === 0) {
        if (col01Row01 === false) {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          
          setCol01Row01(true);
          setCol01Row02(true);
          setC1r1MT("M")
          setC1r2MT("M")
          setC1R1VNo(1);
          setC1R2VNo(1);
          setColCount(1);
          setRowCount(2);
          setCurX(col01X);
          setCurY(row02X);
          setUpArrow(true);
          setDownArrow(false);
          setLeftArrow(false);
          setRightArrow(false);
      
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
    } else  if (curX === col01X && curY === row01X)  {
          if (col01Row01 === true && col01Row02 === false) {

            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
              ]
            )
            setCol01Row02(true);
            setCol01Row03(true);
            setC1r2MT("M")
            setC1r3MT("M")
            setC1R2VNo(1);
            setC1R3VNo(1);
            setColCount(1);
            setRowCount(3);
            setCurX(col01X);
            setCurY(row03X);
            }

    } else if (curX === col02X && curY === row01X)  {
        if (col02Row01 === true && col02Row02 === false) {
  
          setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
  
          setCol02Row02(true);
          setCol02Row03(true);
          setC2r2MT("M")
          setC2r3MT("M")
          setC2R2VNo(1);
          setC2R3VNo(1);
          setColCount(2);
          setRowCount(3);
          setCurX(col02X);
          setCurY(row03X);
         
        }

    } else if (curX === col03X && curY === row01X)  {
      if (col03Row01 === true && col03Row02 === false) {

        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setCol03Row02(true);
        setCol03Row03(true);
        setC3r2MT("M")
        setC3r3MT("M")
        setC3R2VNo(1);
        setC3R3VNo(1);
        setColCount(3);
        setRowCount(3);
        setCurX(col03X);
        setCurY(row03X);
        
      }

    } else if (curX === col04X && curY === row01X)  {
        if (col04Row01 === true && col04Row02 === false) {
  
          setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setCol04Row02(true);
          setCol04Row03(true);
          setC4r2MT("M")
          setC4r3MT("M")
          setC4R2VNo(1);
          setC4R3VNo(1);
          setColCount(4);
          setRowCount(3);
          setCurX(col04X);
          setCurY(row03X);
          
  
          }
    } else if (curX === col05X && curY === row01X)  {
        if (col05Row01 === true && col05Row02 === false) {
  
          setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setCol05Row02(true);
          setCol05Row03(true);
          setC5r2MT("M")
          setC5r3MT("M")
          setC5R2VNo(1);
          setC5R3VNo(1);
          setColCount(5);
          setRowCount(3);
          setCurX(col05X);
          setCurY(row03X);
          
        }

    } else if (curX === col06X && curY === row01X)  {
      if (col06Row01 === true && col06Row02 === false) {

        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setCol06Row02(true);
        setCol06Row03(true);
        setC6r2MT("M")
        setC6r3MT("M")
        setC6R2VNo(1);
        setC6R3VNo(1);
        setColCount(6);
        setRowCount(3);
        setCurX(col06X);
        setCurY(row03X);
       
      }

    } else if (curX === col07X && curY === row01X)  {
        if (col07Row01 === true && col07Row02 === false) {
  
          setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setCol07Row02(true);
          setCol07Row03(true);
          setC7r2MT("M")
          setC7r3MT("M")
          setC7R2VNo(1);
          setC7R3VNo(1);
          setColCount(7);
          setRowCount(3);
          setCurX(col07X);
          setCurY(row03X);
        
        }

    } else if (curX === col08X && curY === row01X)  {
        if (col08Row01 === true && col08Row02 === false)  {
  
          setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setCol08Row02(true);
          setCol08Row03(true);
          setC8r2MT("M")
          setC8r3MT("M")

          setC8R2VNo(1);
          setC8R3VNo(1);
          setColCount(8);
          setRowCount(3);
          setCurX(col08X);
          setCurY(row03X);
         
        }

    } else if (curX === col09X && curY === row01X)  {
        if (col09Row01 === true && col09Row02 === false) {
  
          setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setCol09Row02(true);
          setCol09Row03(true);
          setC9r2MT("M")
          setC9r3MT("M")
          setC9R2VNo(1);
          setC9R3VNo(1);
          setColCount(9);
          setRowCount(3);
          setCurX(col09X);
          setCurY(row03X);
        }

    } else if (curX === col10X && curY === row01X)  {
      if (col10Row01 === true && col10Row02 === false) {

        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setCol10Row02(true);
        setCol10Row03(true);
        setC10r2MT("M")
        setC10r3MT("M")
        setC10R2VNo(1);
        setC10R3VNo(1);
        setColCount(10);
        setRowCount(3);
        setCurX(col10X);
        setCurY(row03X);
        
      }

    } else if (curX === col11X && curY === row01X)  {
        if (col11Row01 === true && col11Row02 === false)  {
    
          setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setCol11Row02(true);
          setCol11Row03(true);
          setC11r2MT("M")
          setC11r3MT("M")
          setC11R2VNo(1);
          setC11R3VNo(1);
          setColCount(11);
          setRowCount(3);
          setCurX(col11X);
          setCurY(row03X);
          
        }


    } else if (curX === col12X && curY === row01X)  {
        if (col12Row01 === true && col12Row02 === false)  {
  
          setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setCol12Row02(true);
          setCol12Row03(true);
          setC12r2MT("M")
          setC12r3MT("M")

          setC12R2VNo(1);
          setC12R3VNo(1);
          setColCount(12);
          setRowCount(3);
          setCurX(col12X);
          setCurY(row03X);
          
        }


    } else if (curX === col13X && curY === row01X)  {
      if (col13Row01 === true && col13Row02 === false) {

        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setCol13Row02(true);
        setCol13Row03(true);
        setC13r2MT("M")
        setC13r3MT("M")
        setC13R2VNo(1);
        setC13R3VNo(1);
        setColCount(13);
        setRowCount(3);
        setCurX(col13X);
        setCurY(row03X);
       
      }


    } else if (curX === col14X && curY === row01X)  {
        if (col14Row01 === true && col14Row02 === false) {
  
          setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setCol14Row02(true);
          setCol14Row03(true);
          setC14r2MT("M")
          setC14r3MT("M")
          setC14R2VNo(1);
          setC14R3VNo(1);
          setColCount(14);
          setRowCount(3);
          setCurX(col14X);
          setCurY(row03X);
        
  
          }
    }  else if (curX === col01X && curY === row02X)  {
      if (col01Row02 === true && col01Row03 === false) {

        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setCol01Row03(true);
        setCol01Row04(true);
        setC1r3MT("M")
        setC1r4MT("M")
        setC1R3VNo(1);
        setC1R4VNo(1);
        setColCount(1);
        setRowCount(4);
        setCurX(col01X);
        setCurY(row04X);
        
        }

} else if (curX === col02X && curY === row02X)  {
    if (col02Row02 === true && col02Row03 === false) {

      setRow3NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
        ]
      )

      setCol02Row03(true);
      setCol02Row04(true);
      setC2r3MT("M")
      setC2r4MT("M")
      setC2R3VNo(1);
      setC2R4VNo(1);
      setColCount(2);
      setRowCount(4);
      setCurX(col02X);
      setCurY(row04X);
     
    }

} else if (curX === col03X && curY === row02X)  {
  if (col03Row02 === true && col03Row03 === false) {

    setRow3NakedM03(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
      ]
    )
    setCol03Row03(true);
    setCol03Row04(true);
    setC3r3MT("M")
    setC3r4MT("M")
    setC3R3VNo(1);
    setC3R4VNo(1);
    setColCount(3);
    setRowCount(4);
    setCurX(col03X);
    setCurY(row04X);
   
  }

} else if (curX === col04X && curY === row02X)  {
    if (col04Row02 === true && col04Row03 === false) {

      setRow3NakedM04(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
        ]
      )
      setCol04Row03(true);
      setCol04Row04(true);
      setC4r3MT("M")
      setC4r4MT("M")
      setC4R3VNo(1);
      setC4R4VNo(1);
      setColCount(4);
      setRowCount(4);
      setCurX(col04X);
      setCurY(row04X);
   

      }
} else if (curX === col05X && curY === row02X)  {
    if (col05Row02 === true && col05Row03 === false) {

      setRow3NakedM05(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
        ]
      )
      setCol05Row03(true);
      setCol05Row04(true);
      setC5r3MT("M")
      setC5r4MT("M")
      setC5R3VNo(1);
      setC5R4VNo(1);
      setColCount(5);
      setRowCount(4);
      setCurX(col05X);
      setCurY(row04X);
  
    }

} else if (curX === col06X && curY === row02X)  {
  if (col06Row02 === true && col06Row03 === false) {

    setRow3NakedM06(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
      ]
    )
    setCol06Row03(true);
    setCol06Row04(true);
    setC6r3MT("M")
    setC6r4MT("M")
    setC6R3VNo(1);
    setC6R4VNo(1);
    setColCount(6);
    setRowCount(4);
    setCurX(col06X);
    setCurY(row04X);

  }

} else if (curX === col07X && curY === row02X)  {
    if (col07Row02 === true && col07Row03 === false) {

      setRow3NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
        ]
      )
      setCol07Row03(true);
      setCol07Row04(true);
      setC7r3MT("M")
      setC7r4MT("M")
      setC7R3VNo(1);
      setC7R4VNo(1);
      setColCount(7);
      setRowCount(4);
      setCurX(col07X);
      setCurY(row04X);
  
    }

} else if (curX === col08X && curY === row02X)  {
    if (col08Row02 === true && col08Row03 === false)  {

      setRow3NakedM08(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
        ]
      )
      setCol08Row03(true);
      setCol08Row04(true);
      setC8r3MT("M")
      setC8r4MT("M")
      setC8R3VNo(1);
      setC8R4VNo(1);
      setColCount(8);
      setRowCount(4);
      setCurX(col08X);
      setCurY(row04X);
     
    }

} else if (curX === col09X && curY === row02X)  {
    if (col09Row02 === true && col09Row03 === false) {

      setRow3NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
        ]
      )
      setCol09Row03(true);
      setCol09Row04(true);
      setC9r3MT("M")
      setC9r4MT("M")
      setC9R3VNo(1);
      setC9R4VNo(1);
      setColCount(9);
      setRowCount(4);
      setCurX(col09X);
      setCurY(row04X);
      
    }

} else if (curX === col10X && curY === row02X)  {
  if (col10Row02 === true && col10Row03 === false) {

    setRow3NakedM10(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
      ]
    )
    setCol10Row03(true);
    setCol10Row04(true);
    setC10r3MT("M")
    setC10r4MT("M")
    setC10R3VNo(1);
    setC10R4VNo(1);
    setColCount(10);
    setRowCount(4);
    setCurX(col10X);
    setCurY(row04X);
    
  }

} else if (curX === col11X && curY === row02X)  {
    if (col11Row02 === true && col11Row03 === false)  {

      setRow3NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
        ]
      )
      setCol11Row03(true);
      setCol11Row04(true);
      setC11r3MT("M")
      setC11r4MT("M")
      setC11R3VNo(1);
      setC11R4VNo(1);
      setColCount(11);
      setRowCount(4);
      setCurX(col11X);
      setCurY(row04X);
      
    }


} else if (curX === col12X && curY === row02X)  {
    if (col12Row02 === true && col12Row03 === false)  {

      setRow3NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
        ]
      )
      setCol12Row03(true);
      setCol12Row04(true);
      setC12r3MT("M")
      setC12r4MT("M")
      setC12R3VNo(1);
      setC12R4VNo(1);
      setColCount(12);
      setRowCount(4);
      setCurX(col12X);
      setCurY(row04X);
    
    }

} else if (curX === col13X && curY === row02X)  {
  if (col13Row02 === true && col13Row03 === false) {

    setRow3NakedM13(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
      ]
    )
    setCol13Row03(true);
    setCol13Row04(true);
    setC13r3MT("M")
    setC13r4MT("M")
    setC13R3VNo(1);
    setC13R4VNo(1);
    setColCount(13);
    setRowCount(4);
    setCurX(col13X);
    setCurY(row04X);
 
  }

} else if (curX === col14X && curY === row02X)  {
    if (col14Row02 === true && col14Row03 === false) {

      setRow3NakedM14(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
        ]
      )
      setCol14Row03(true);
      setCol14Row04(true);
      setC14r3MT("M")
      setC14r4MT("M")
      setC14R3VNo(1);
      setC14R4VNo(1);
      setColCount(14);
      setRowCount(4);
      setCurX(col14X);
      setCurY(row04X);
    

      }
  } else {
    alert("No more module can be added vertically");
  }
  }
  //heightCalculate();
  updatePriceWieght();
 
}

const addSmallModules = (e) => {
  addSmallDoorsBtn = "./images/smallModule-hover.png";
  addMediumDoorsBtn ="./images/mediumModule.png";
  addLargeDoorsBtn ="./images/largeModule.png";
  removeDoorsBtn ="./images/Remove-Modules.png";

  if (placeUpRight === "LANDSCAPE") {
    setUpArrow(false);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(true);
    if (colCount < maxRightCount) {
      if (curX === 0 && curY === 0) {
        if (col01Row01 === false) {
          setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
      
          setCol01Row01(true);
          setC1r1MT("S")

          setC1R1VNo(1);
          setColCount(1);
          setRowCount(1);
          setCurX(col01X);
          setCurY(row01X);
          setUpArrow(false);
          setDownArrow(false);
          setLeftArrow(false);
          setRightArrow(true);

      
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col01X && curY === row01X){
        if (col01Row01 === true && col02Row01 === false) {
          setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setCol02Row01(true);
          setC2r1MT("S")
          setC2R1VNo(1);
          setColCount(2);
          setRowCount(1);
          setCurX(col02X);
          setCurY(row01X);

          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        } 
      } else if (curX === col02X && curY === row01X){
        if (col02Row01 === true && col03Row01 === false){
          setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setCol03Row01(true);
          setC3r1MT("S")
          setC3R1VNo(1);
          setColCount(3);
          setRowCount(1);
          setCurX(col03X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          }
      } else if (curX === col03X && curY === row01X) {
        if (col03Row01 === true && col04Row01 === false) {
          setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setCol04Row01(true);
          setC4r1MT("S")
          setC4R1VNo(1);
          setColCount(4);
          setRowCount(1);
          setCurX(col04X);
          setCurY(row01X);

  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
          }
      } else if (curX === col04X && curY === row01X){
        if (col04Row01 === true && col05Row01 === false) {
          setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setCol05Row01(true);
          setC5r1MT("S")
          setC5R1VNo(1);
          setColCount(5);
          setRowCount(1);
          setCurX(col05X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col05X && curY === row01X) {
        if (col05Row01 === true && col06Row01 === false) {
          setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setCol06Row01(true);
          setC6r1MT("S")
          setC6R1VNo(1);
          setColCount(6);
          setRowCount(1);
          setCurX(col06X);
          setCurY(row01X);

          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col06X && curY === row01X) {
        if (col06Row01 === true && col07Row01 === false) {
          setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setCol07Row01(true);
          setC7r1MT("S")
          setC7R1VNo(1);
          setColCount(7);
          setRowCount(1);
          setCurX(col07X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col07X && curY === row01X) {
        if (col07Row01 === true && col08Row01 === false) {
          setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setCol08Row01(true);
          setC8r1MT("S")
          setC8R1VNo(1);
          setColCount(8);
          setRowCount(1);
          setCurX(col08X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col08X && curY === row01X) {
        if (col08Row01 === true && col09Row01 === false) {
          setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setCol09Row01(true);
          setC9r1MT("S")
          setC9R1VNo(1);
          setColCount(9);
          setRowCount(1);
          setCurX(col09X);
          setCurY(row01X);
  
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col09X && curY === row01X) {
        if (col09Row01 === true && col10Row01 === false) {
            setBaseNaked10(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setCol10Row01(true);
            setC10r1MT("S")
            setC10R1VNo(1);
            setColCount(10);
            setRowCount(1);
            setCurX(col10X);
            setCurY(row01X);
    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col10X && curY === row01X) {
        if (col10Row01 === true && col11Row01 === false) {
            setBaseNaked11(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setCol11Row01(true);
            setC11r1MT("S")
            setC11R1VNo(1);
            setColCount(11);
            setRowCount(1);
            setCurX(col11X);
            setCurY(row01X);
    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col11X && curY === row01X) {
        if (col11Row01 === true && col12Row01 === false) {
            setBaseNaked12(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setCol12Row01(true);
            setC12r1MT("S")
            setC12R1VNo(1);
            setColCount(12);
            setRowCount(1);
            setCurX(col12X);
            setCurY(row01X);
    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
      } else if (curX === col12X && curY === row01X) {
        if (col12Row01 === true && col13Row01 === false) {
            setBaseNaked13(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setCol13Row01(true);
            setC13r1MT("S")
            setC13R1VNo(1);
            setColCount(13);
            setRowCount(1);
            setCurX(col13X);
            setCurY(row01X);

            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } else if (curX === col13X && curY === row01X) {
        if (col13Row01 === true && col14Row01 === false) {
            setBaseNaked14(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
              ]
            )
            setCol14Row01(true);
            setC14r1MT("S")
            setC14R1VNo(1);
            setColCount(14);
            setRowCount(1);
            setCurX(col14X);
            setCurY(row01X);

    
            const newwidth = floWidth + 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
      } 
    } else {
      alert("No more modules can be added horizontally.")
    }
  } else {
    setUpArrow(true);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(false);
      if (curX === 0 && curY === 0) {
        if (col01Row01 === false) {
          setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
      
          setCol01Row01(true);
          setCol01Row02(false);
          setC1r1MT("S")
          setC1R1VNo(1);
          setColCount(1);
          setRowCount(1);
          setCurX(col01X);
          setCurY(row01X);
          setUpArrow(true);
          setDownArrow(false);
          setLeftArrow(false);
          setRightArrow(false);
      
          const newwidth = floWidth + 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        }
      } else if (curX === col01X && curY === row01X)  {
       
        if (col01Row01 === true && col01Row02 === false) {

          setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setCol01Row02(true);
          setC1r2MT("S")
          setC1R2VNo(1);
          setRowCount(2);
          setCurX(col01X);
          setCurY(row02X);

        }

    } else if (curX === col02X && curY === row01X)  {
      if (col02Row01 === true  && col02Row02 === false)  {

        setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setCol02Row02(true);
        setC2r2MT("S")
        setC2R2VNo(1);
        setRowCount(2);
        setCurX(col02X);
        setCurY(row02X);


        }

    } else if (curX === col03X && curY === row01X)  {
        if (col03Row01 === true  && col03Row02 === false)  {
  
          setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setCol03Row02(true);
          setC3r2MT("S")
          setC3R2VNo(1);
          setRowCount(2);
          setCurX(col03X);
          setCurY(row02X);

        }

    } else if (curX === col04X && curY === row01X)  {
        if (col04Row01 === true  && col04Row02 === false)  {
  
          setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setCol04Row02(true);
          setC4r2MT("S")
          setC4R2VNo(1);

          setRowCount(2);
          setCurX(col04X);
          setCurY(row02X);

   
        }

    } else if (curX === col05X && curY === row01X)  {
        if (col05Row01 === true  && col05Row02 === false)  {
  
          setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setCol05Row02(true);
          setC5r2MT("S")
          setC5R2VNo(1);
          setRowCount(2);
          setCurX(col05X);
          setCurY(row02X);

          
        }
    } else if (curX === col06X && curY === row01X)  {
        if (col06Row01 === true  && col06Row02 === false)  {
  
          setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setCol06Row02(true);
          setC6r2MT("S")
          setC6R2VNo(1);
          setRowCount(2);
          setCurX(col06X);
          setCurY(row02X);

       
        }

    } else if (curX === col07X && curY === row01X)  {
        if (col07Row01 === true  && col07Row02 === false)  {
  
          setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setCol07Row02(true);
          setC7r2MT("S")
          setC7R2VNo(1);
          setRowCount(2);
          setCurX(col07X);
          setCurY(row02X);

   
        }

    } else if (curX === col08X && curY === row01X)  {
      if (col08Row01 === true  && col08Row02 === false)  {

        setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setCol08Row02(true);
        setC8r2MT("S")
        setC8R2VNo(1);
        setRowCount(2);
        setCurX(col08X);
        setCurY(row02X);

      }


    } else if (curX === col09X && curY === row01X)  {
    
      if (col09Row01 === true  && col09Row02 === false)  {

        setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setCol09Row02(true);
        setC9r2MT("S")
        setC9R2VNo(1);
        setRowCount(2);
        setCurX(col09X);
        setCurY(row02X);

      }

    } else if (curX === col10X && curY === row01X)  {
        if (col10Row01 === true  && col10Row02 === false)  {
  
          setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setCol10Row02(true);
          setC10r2MT("S")
          setC10R2VNo(1);
          setRowCount(2);
          setCurX(col10X);
          setCurY(row02X);

        }

    } else if (curX === col11X && curY === row01X)  {
        if (col11Row01 === true  && col11Row02 === false)  {
    
          setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setCol11Row02(true);
          setC11r2MT("S")
          setC11R2VNo(1);
          setRowCount(2);
          setCurX(col11X);
          setCurY(row02X);

        }

    } else if (curX === col12X && curY === row01X)  {
        if (col12Row01 === true  && col12Row02 === false)  {
  
          setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setCol12Row02(true);
          setC12r2MT("S")
          setC12R2VNo(1);
          setRowCount(2);
          setCurX(col12X);
          setCurY(row02X);

        }

    } else if (curX === col13X && curY === row01X)  {
      if (col13Row01 === true  && col13Row02 === false)  {

        setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setCol13Row02(true);
        setC13r2MT("S")
        setC13R2VNo(1);
        setRowCount(2);
        setCurX(col13X);
        setCurY(row02X);

      }

    } else if (curX === col14X && curY === row01X)  {
        if (col14Row01 === true  && col14Row02 === false)  {
  
          setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setCol14Row02(true);
          setC14r2MT("S")
          setC14R2VNo(1);
          setRowCount(2);
          setCurX(col14X);
          setCurY(row02X);

        }

    } else if (curX === col01X && curY === row02X)  {
      if (col01Row02 === true && col01Row03 === false) {

        setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setCol01Row03(true);
        setC1r3MT("S")
        setC1R3VNo(1);
        setRowCount(3);
        setCurX(col01X);
        setCurY(row03X);

      }

  } else if (curX === col02X && curY === row02X)  {
    if (col02Row02 === true  && col02Row03 === false)  {

      setRow3Naked02(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
        ]
      )
      setCol02Row03(true);
      setC2r3MT("S")
      setC2R3VNo(1);
      setRowCount(3);
      setCurX(col02X);
      setCurY(row03X);


      }

  } else if (curX === col03X && curY === row02X)  {
      if (col03Row02 === true  && col03Row03 === false)  {

        setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setCol03Row03(true);
        setC3r3MT("S")
        setC3R3VNo(1);
        setRowCount(3);
        setCurX(col03X);
        setCurY(row03X);

      }

  } else if (curX === col04X && curY === row02X)  {
      if (col04Row02 === true  && col04Row03 === false)  {

        setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setCol04Row03(true);
        setC4r3MT("S")
        setC4R3VNo(1);
        setRowCount(3);
        setCurX(col04X);
        setCurY(row03X);

      }

  } else if (curX === col05X && curY === row02X)  {
      if (col05Row02 === true  && col05Row03 === false)  {

        setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setCol05Row03(true);
        setC5r3MT("S")
        setC5R3VNo(1);
        setRowCount(3);
        setCurX(col05X);
        setCurY(row03X);


      }
  } else if (curX === col06X && curY === row02X)  {
      if (col06Row02 === true  && col06Row03 === false)  {

        setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setCol06Row03(true);
        setC6r3MT("S")
        setC6R3VNo(1);
        setRowCount(3);
        setCurX(col06X);
        setCurY(row03X);

      }

  } else if (curX === col07X && curY === row02X)  {
      if (col07Row02 === true  && col07Row03 === false)  {

        setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setCol07Row03(true);
        setC7r3MT("S")
        setC7R3VNo(1);
        setRowCount(3);
        setCurX(col07X);
        setCurY(row03X);

      
      }

  } else if (curX === col08X && curY === row02X)  {
    if (col08Row02 === true  && col08Row03 === false)  {

      setRow3Naked08(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
        ]
      )
      setCol08Row03(true);
      setC8r3MT("S")
      setC8R3VNo(1);
      setRowCount(3);
      setCurX(col08X);
      setCurY(row03X);


    }


  } else if (curX === col09X && curY === row02X)  {
  
    if (col09Row02 === true  && col09Row03 === false)  {

      setRow3Naked09(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
        ]
      )
      setCol09Row03(true);
      setC9r3MT("S")
      setC9R3VNo(1);
      setRowCount(3);
      setCurX(col09X);
	setCurY(row03X);

    }

  } else if (curX === col10X && curY === row02X)  {
      if (col10Row02 === true  && col10Row03 === false)  {

        setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setCol10Row03(true);
        setC10r3MT("S")
        setC10R3VNo(1);
        setRowCount(3);
        setCurX(col10X);
        setCurY(row03X);

      }

  } else if (curX === col11X && curY === row02X)  {
      if (col11Row02 === true  && col11Row03 === false)  {
  
        setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setCol11Row03(true);
        setC11r3MT("S")
        setC11R3VNo(1);
        setRowCount(3);
        setCurX(col11X);
        setCurY(row03X);

      }

  } else if (curX === col12X && curY === row02X)  {
      if (col12Row02 === true  && col12Row03 === false)  {

        setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setCol12Row03(true);
        setC12r3MT("S")
        setC12R3VNo(1);
        setRowCount(3);
        setCurX(col12X);
        setCurY(row03X);

      }

  } else if (curX === col13X && curY === row02X)  {
    if (col13Row02 === true  && col13Row03 === false)  {

      setRow3Naked13(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
        ]
      )
      setCol13Row03(true);
      setC13r3MT("S")
      setC13R3VNo(1);
      setRowCount(3);
      setCurX(col13X);
      setCurY(row03X);


    }

  } else if (curX === col14X && curY === row02X)  {
      if (col14Row02 === true  && col14Row03 === false)  {

        setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setCol14Row03(true);
        setC14r3MT("S")
        setC14R3VNo(1);
        setRowCount(3);
        setCurX(col14X);
	  setCurY(row03X);
      }

    } else if (curX === col01X && curY === row03X)  {
      if (col01Row03 === true && col01Row04 === false) {

        setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setCol01Row04(true);
        setC1r4MT("S")
        setC1R4VNo(1);
        setRowCount(4);
        setCurX(col01X);
        setCurY(row04X);

      }

  } else if (curX === col02X && curY === row03X)  {
    if (col02Row03 === true  && col02Row04 === false)  {

      setRow4Naked02(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
        ]
      )
      setCol02Row04(true);
      setC2r4MT("S")
      setC2R4VNo(1);
      setRowCount(4);
      setCurX(col02X);
      setCurY(row04X);


      }

  } else if (curX === col03X && curY === row03X)  {
      if (col03Row03 === true  && col03Row04 === false)  {

        setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setCol03Row04(true);
        setC3r4MT("S")
        setC3R4VNo(1);
        setRowCount(4);
        setCurX(col03X);
        setCurY(row04X);

      }

  } else if (curX === col04X && curY === row03X)  {
      if (col04Row03 === true  && col04Row04 === false)  {

        setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setCol04Row04(true);
        setC4r4MT("S")
        setC4R4VNo(1);
        setRowCount(4);
        setCurX(col04X);
        setCurY(row03X);

      }

  } else if (curX === col05X && curY === row03X)  {
      if (col05Row03 === true  && col05Row04 === false)  {

        setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setCol05Row04(true);
        setC5r4MT("S")
        setC5R4VNo(1);
        setRowCount(4);
        setCurX(col05X);
        setCurY(row04X);

      }
  } else if (curX === col06X && curY === row03X)  {
      if (col06Row03 === true  && col06Row04 === false)  {

        setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setCol06Row04(true);
        setC6r4MT("S")
        setC6R4VNo(1);
        setRowCount(4);
        setCurX(col06X);
        setCurY(row04X);

      }

  } else if (curX === col07X && curY === row03X)  {
      if (col07Row03 === true  && col07Row04 === false)  {

        setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setCol07Row04(true);
        setC7r4MT("S")
        setC7R4VNo(1);
        setRowCount(4);
        setCurX(col07X);
        setCurY(row04X);

      }

  } else if (curX === col08X && curY === row03X)  {
    if (col08Row03 === true  && col08Row04 === false)  {

      setRow4Naked08(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
        ]
      )
      setCol08Row04(true);
      setC8r4MT("S")
      setC8R4VNo(1);
      setRowCount(4);
      setCurX(col08X);
      setCurY(row04X);

    }


  } else if (curX === col09X && curY === row03X)  {
  
    if (col09Row03 === true  && col09Row04 === false)  {

      setRow4Naked09(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
        ]
      )
      setCol09Row04(true);
      setC9r4MT("S")
      setC9R4VNo(1);
      setRowCount(4);
      setCurX(col09X);
      setCurY(row04X);

    }

  } else if (curX === col10X && curY === row03X)  {
      if (col10Row03 === true  && col10Row04 === false)  {

        setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setCol10Row04(true);
        setC10r4MT("S")
        setC10R4VNo(1);
        setRowCount(4);
        setCurX(col10X);
	setCurY(row04X);

      }

  } else if (curX === col11X && curY === row03X)  {
      if (col11Row03 === true  && col11Row04 === false)  {
  
        setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setCol11Row04(true);
        setC11r4MT("S")
        setC11R4VNo(1);
        setRowCount(4);
        setCurX(col11X);
        setCurY(row04X);

      }

  } else if (curX === col12X && curY === row03X)  {
      if (col12Row03 === true  && col12Row04 === false)  {

        setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setCol12Row04(true);
        setC12r4MT("S")
        setC12R4VNo(1);
        setRowCount(4);
        setCurX(col12X);
        setCurY(row03X);

      }

  } else if (curX === col13X && curY === row03X)  {
    if (col13Row03 === true  && col13Row04 === false)  {

      setRow4Naked13(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
        ]
      )
      setCol13Row04(true);
      setC13r4MT("S")
      setC13R4VNo(1);
      setRowCount(4);
      setCurX(col13X);
      setCurY(row03X);
    }

  } else if (curX === col14X && curY === row03X)  {
      if (col14Row03 === true  && col14Row04 === false)  {

        setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setCol14Row04(true);
        setC14r4MT("S")
        setC14R4VNo(1);
        setRowCount(4);
        setCurX(col14X);
        setCurY(row04X);

      }

  } else {
    alert("No more module can be added vertically");
  }
   
  }
  //heightCalculate();
  updatePriceWieght();
 
}




const removeBaseUp = (e)=> {
  addSmallDoorsBtn = "./images/smallModule.png";
  addMediumDoorsBtn ="./images/mediumModule.png";
  addLargeDoorsBtn ="./images/largeModule.png";
  removeDoorsBtn ="./images/Remove-Modules-hover.png";
  //resetAllMiddlePlates();
    if (curX === col01X && curY === row04X)  {
      if (col01Row04 === true) {
        if (c1r4MT === "S") {
            setRow4Naked01([]);
            setCol01Row04(false);
            setC1r4MT("");
            setC1R4VNo(0);
            if (col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }

            setCurX(col01X);
            setCurY(row03X);


        } else if (c1r4MT === "M") {
          setRow3NakedM01([]);
          setCol01Row04(false);
          setCol01Row03(false);
          setC1r4MT("");
          setC1r3MT("");
          setC1R4VNo(0);
          setC1R3VNo(0);
          if (col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col01X);
          setCurY(row02X);
        } 
      }
    } else if (curX === col02X && curY === row04X)  {
      if (col02Row04 === true) {
        if (c2r4MT === "S") {
            setRow4Naked02([]);
            setCol02Row04(false);
            setC2r4MT("");
            setC2R4VNo(0);

            if (col01Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col02X);
            setCurY(row03X);


        } else if (c2r4MT === "M") {
          setRow3NakedM02([]);
          setCol02Row04(false);
          setCol02Row03(false);
          setC2r4MT("");
          setC2r3MT("");
          setC2R4VNo(0);
          setC2R3VNo(0);
          if (col01Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);
 
          }
          if (col01Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col02X);
          setCurY(row02X);
        } 
      }
    } else if (curX === col03X && curY === row04X)  {
      if (col03Row04 === true) {
        if (c3r4MT === "S") {
            setRow4Naked03([]);
            setCol03Row04(false);
            setC3r4MT("");
            setC3R4VNo(0);

            if (col01Row04 === false && col02Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col03X);
            setCurY(row03X);


        } else if (c3r4MT === "M") {
          setRow3NakedM03([]);
          setCol03Row04(false);
          setCol03Row03(false);
          setC3r4MT("");
          setC3r3MT("");
          setC3R4VNo(0);
          setC3R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col03X);
          setCurY(row02X);
        } 
      }
    } else if (curX === col04X && curY === row04X)  {
      if (col04Row04 === true) {
        if (c4r4MT === "S") {
            setRow4Naked04([]);
            setCol04Row04(false);
            setC4r4MT("");
            setC4R4VNo(0);

            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col04X);
            setCurY(row03X);

        } else if (c4r4MT === "M") {
          setRow3NakedM04([]);
          setCol04Row04(false);
          setCol04Row03(false);
          setC4r4MT("");
          setC4r3MT("");
          setC4R4VNo(0);
          setC4R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);
 
          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col04X);
          setCurY(row02X);
        }
      }
    } else if (curX === col05X && curY === row04X)  {
        if (col05Row04 === true) {
          if (c5r4MT === "S") {
              setRow4Naked05([]);
              setCol05Row04(false);
              setC5r4MT("");
              setC5R4VNo(0);

              if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
                setRowCount(3);

              }
              setCurX(col05X);
              setCurY(row03X);


          } else if (c5r4MT === "M") {
            setRow3NakedM05([]);
            setCol05Row04(false);
            setCol05Row03(false);
            setC5r4MT("");
            setC5r3MT("");
            setC5R4VNo(0);
            setC5R3VNo(0);
            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col05X);
            setCurY(row02X);
          }
        }
    } else if (curX === col06X && curY === row04X)  {
      if (col06Row04 === true) {
        if (c6r4MT === "S") {
            setRow4Naked06([]);
            setCol06Row04(false);
            setC6r4MT("");
            setC6R4VNo(0);
   
            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {

            }
            setCurX(col06X);
            setCurY(row03X);


        } else if (c6r4MT === "M") {
          setRow3NakedM06([]);
          setCol06Row04(false);
          setCol06Row03(false);
          setC6r4MT("");
          setC6r3MT("");
          setC6R4VNo(0);
          setC6R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);
 
          }
          setCurX(col06X);
          setCurY(row02X);
        }
      }
    } else if (curX === col07X && curY === row04X)  {
      if (col07Row04 === true) {
        if (c7r4MT === "S") {
            setRow4Naked07([]);
            setCol07Row04(false);
            setC7r4MT("");
            setC7R4VNo(0);

            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col07X);
            setCurY(row03X);


        } else if (c7r4MT === "M") {
          setRow3NakedM07([]);
          setCol07Row04(false);
          setCol07Row03(false);
          setC7r4MT("");
          setC7r3MT("");
          setC7R4VNo(0);
          setC7R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col07X);
          setCurY(row02X);
        }
      }
    } else if (curX === col08X && curY === row04X)  {
      if (col08Row04 === true) {
        if (c8r4MT === "S") {
            setRow4Naked08([]);
            setCol08Row04(false);
            setC8r4MT("");
            setC8R4VNo(0);

            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col08X);
            setCurY(row03X);


        } else if (c8r4MT === "M") {
          setRow3NakedM08([]);
          setCol08Row04(false);
          setCol08Row03(false);
          setC8r4MT("");
          setC8r3MT("");
          setC8R4VNo(0);
          setC8R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col08X);
          setCurY(row02X);
        }
      }
    } else if (curX === col09X && curY === row04X)  {
      if (col09Row04 === true) {
        if (c9r4MT === "S") {
            setRow4Naked09([]);
            setCol09Row04(false);
            setC9r4MT("");
            setC9R4VNo(0);
            
            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col09X);
            setCurY(row03X);


        } else if (c9r4MT === "M") {
          setRow3NakedM09([]);
          setCol09Row04(false);
          setCol09Row03(false);
          setC9r4MT("");
          setC9r3MT("");
          setC9R4VNo(0);
          setC9R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col09X);
          setCurY(row02X);
        }
      }
    } else if (curX === col10X && curY === row04X)  {
      if (col10Row04 === true) {
        if (c10r4MT === "S") {
            setRow4Naked10([]);
            setCol10Row04(false);
            setC10r4MT("");
            setC10R4VNo(0);
 
            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {

            }
            setCurX(col10X);
            setCurY(row03X);


        } else if (c10r4MT === "M") {
          setRow3NakedM10([]);
          setCol10Row04(false);
          setCol10Row03(false);
          setC10r4MT("");
          setC10r3MT("");
          setC10R4VNo(0);
          setC10R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col10X);
          setCurY(row02X);
        }
      }
    } else if (curX === col11X && curY === row04X)  {
      if (col11Row04 === true) {
        if (c11r4MT === "S") {
            setRow4Naked11([]);
            setCol11Row04(false);
            setC11r4MT("");
            setC11R4VNo(0);

            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col11X);
            setCurY(row03X);
  

        } else if (c11r4MT === "M") {
          setRow3NakedM11([]);
          setCol11Row04(false);
          setCol11Row03(false);
          setC11r4MT("");
          setC11r3MT("");
          setC11R4VNo(0);
          setC11R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col12Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col11X);
          setCurY(row02X);
        }
      }
    } else if (curX === col12X && curY === row04X)  {
      if (col12Row04 === true) {
        if (c12r4MT === "S") {
            setRow4Naked12([]);
            setCol11Row04(false);
            setC12r4MT("");
            setC12R4VNo(0);
   
            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col13Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col12X);
            setCurY(row03X);


        } else if (c12r4MT === "M") {
          setRow3NakedM12([]);
          setCol12Row04(false);
          setCol12Row03(false);
          setC12r4MT("");
          setC12r3MT("");
          setC12R4VNo(0);
          setC12R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col13Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col12X);
          setCurY(row02X);

        }
      }
    } else if (curX === col13X && curY === row04X)  {
      if (col13Row04 === true) {
        if (c13r4MT === "S") {
            setRow4Naked13([]);
            setCol13Row04(false);
            setC13r4MT("");
            setC13R4VNo(0);
  
            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col14Row04 === false) {
              setRowCount(3);

            }
            setCurX(col13X);


        } else if (c13r4MT === "M") {
          setRow3NakedM13([]);
          setCol13Row04(false);
          setCol13Row03(false);
          setC13r4MT("");
          setC13r3MT("");
          setC13R4VNo(0);
          setC13R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col14Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          setCurX(col13X);
          setCurY(row02X);
        }
      }
    } else if (curX === col14X && curY === row04X)  {
      if (col14Row04 === true) {
        if (c14r4MT === "S") {
            setRow4Naked14([]);
            setCol14Row04(false);
            setC14r4MT("");
            setC14R4VNo(0);

            if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false) {
              setRowCount(3);

            }
            setCurX(col14X);
            setCurY(row03X);


        } else if (c14r4MT === "M") {
          setRow3NakedM14([]);
          setCol14Row04(false);
          setCol14Row03(false);
          setC14r4MT("");
          setC14r3MT("");
          setC14R4VNo(0);
          setC14R3VNo(0);
          if (col01Row04 === false && col02Row04 === false && col03Row04 === false && col04Row04 === false && col05Row04 === false && col06Row04 === false && col07Row04 === false && col08Row04 === false && col09Row04 === false && col10Row04 === false && col11Row04 === false && col12Row04 === false && col13Row04 === false) {
            setRowCount(3);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false) {
            setRowCount(2);

          }
          setCurX(col14X);
          setCurY(row02X);
        }
      }
    } else  if (curX === col01X && curY === row03X)  {
      if (col01Row03 === true && col01Row04 === false) {
        if (c1r3MT === "S") {
            setRow3Naked01([]);
            setCol01Row03(false);
            setC1r3MT("");
            setC1R3VNo(0);
            if (col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col01X);
            setCurY(row02X);


        } else if (c1r3MT === "M") {
          setRow2NakedM01([]);
          setCol01Row03(false);
          setCol01Row02(false);
          setC1r3MT("");
          setC1r2MT("");
          setC1R3VNo(0);
          setC1R2VNo(0);
          if (col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col01X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col02X && curY === row03X)  {
      if (col02Row03 === true && col02Row04 === false) {
        if (c2r3MT === "S") {
            setRow3Naked02([]);
            setCol02Row03(false);
            setC2r3MT("");
            setC2R3VNo(0);

            if (col01Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col02X);
            setCurY(row02X);


        } else if (c2r3MT === "M") {
          setRow2NakedM02([]);
          setCol02Row03(false);
          setCol02Row02(false);
          setC2r3MT("");
          setC2r2MT("");
          setC2R3VNo(0);
          setC2R2VNo(0);
          if (col01Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col02X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col03X && curY === row03X)  {
      if (col03Row03 === true && col03Row04 === false) {
        if (c3r3MT === "S") {
            setRow3Naked03([]);
            setCol03Row03(false);
            setC3r3MT("");
            setC3R3VNo(0);

            if (col01Row03 === false && col02Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col03X);
            setCurY(row02X);


        } else if (c3r3MT === "M") {
          setRow2NakedM03([]);
          setCol03Row03(false);
          setCol03Row02(false);
          setC3r3MT("");
          setC3r2MT("");
          setC3R3VNo(0);
          setC3R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col03X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col04X && curY === row03X)  {
      if (col04Row03 === true && col04Row04 === false) {
        if (c4r3MT === "S") {
            setRow3Naked04([]);
            setCol04Row03(false);
            setC4r3MT("");
            setC4R3VNo(0);
  
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col04X);
            setCurY(row02X);


        } else if (c3r3MT === "M") {
          setRow2NakedM04([]);
          setCol04Row03(false);
          setCol04Row02(false);
          setC4r3MT("");
          setC4r2MT("");
          setC4R3VNo(0);
          setC4R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);
 
          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col04X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col05X && curY === row03X)  {
        if (col05Row03 === true  && col05Row04 === false) {
          if (c5r3MT === "S") {
              setRow3Naked05([]);
              setCol05Row03(false);
              setC5r3MT("");
              setC5R3VNo(0);
     
              if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
                setRowCount(2);

              }
              setCurX(col05X);
              setCurY(row02X);


          } else if (c5r3MT === "M") {
            setRow2NakedM05([]);
            setCol05Row03(false);
            setCol05Row02(false);
            setC5r3MT("");
            setC5r2MT("");
            setC5R3VNo(0);
            setC5R2VNo(0);
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(1);

            }
            setCurX(col05X);
            setCurY(row01X);
          }
        } else {
          alert("You can remove modules only from top to bottom.")
        }
    } else if (curX === col06X && curY === row03X)  {
      if (col06Row03 === true  && col06Row04 === false) {
        if (c6r3MT === "S") {
            setRow3Naked06([]);
            setCol06Row03(false);
            setC6r3MT("");
            setC6R3VNo(0);

            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col06X);


        } else if (c6r3MT === "M") {
          setRow2NakedM06([]);
          setCol06Row03(false);
          setCol06Row02(false);
          setC6r3MT("");
          setC6r2MT("");
          setC6R3VNo(0);
          setC6R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col06X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col07X && curY === row03X)  {
      if (col07Row03 === true  && col07Row04 === false) {
        if (c7r3MT === "S") {
            setRow3Naked07([]);
            setCol07Row03(false);
            setC7r3MT("");
            setC7R3VNo(0);

            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col07X);


        } else if (c7r3MT === "M") {
          setRow2NakedM07([]);
          setCol07Row03(false);
          setCol07Row02(false);
          setC7r3MT("");
          setC7r2MT("");
          setC7R3VNo(0);
          setC7R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col07X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col08X && curY === row03X)  {
      if (col08Row03 === true && col08Row04 === false) {
        if (c8r3MT === "S") {
            setRow3Naked08([]);
            setCol08Row03(false);
            setC8r3MT("");
            setC8R3VNo(0);
 
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col08X);
            setCurY(row02X);


        } else if (c8r3MT === "M") {
          setRow2NakedM08([]);
          setCol08Row03(false);
          setCol08Row02(false);
          setC8r3MT("");
          setC8r2MT("");
          setC8R3VNo(0);
          setC8R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);
 
          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col08X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col09X && curY === row03X)  {
      if (col09Row03 === true && col09Row04 === false) {
        if (c9r3MT === "S") {
            setRow3Naked09([]);
            setCol09Row03(false);
            setC9r3MT("");
            setC9R3VNo(0);


            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);

            }
            setCurX(col09X);
            setCurY(row02X);


        } else if (c9r3MT === "M") {
          setRow2NakedM09([]);
          setCol09Row03(false);
          setCol09Row02(false);
          setC9r3MT("");
          setC9r2MT("");
          setC9R3VNo(0);
          setC9R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col09X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col10X && curY === row03X)  {
      if (col10Row03 === true && col09Row04 === false) {
        if (c10r3MT === "S") {
            setRow3Naked10([]);
            setCol10Row03(false);
            setC10r3MT("");
            setC10R3VNo(0);

            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);
            }
            setCurX(col10X);
            setCurY(row02X);


        } else if (c10r3MT === "M") {
          setRow2NakedM10([]);
          setCol10Row03(false);
          setCol10Row02(false);
          setC10r3MT("");
          setC10r2MT("");
          setC10R3VNo(0);
          setC10R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);
      
          }
          setCurX(col10X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col11X && curY === row03X)  {
      if (col11Row03 === true && col11Row04 === false) {
        if (c11r3MT === "S") {
            setRow3Naked11([]);
            setCol11Row03(false);
            setC11r3MT("");
            setC11R3VNo(0);

            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);
            }
            setCurX(col11X);
            setCurY(row02X);
   

        } else if (c11r3MT === "M") {
          setRow2NakedM11([]);
          setCol11Row03(false);
          setCol11Row02(false);
          setC11r3MT("");
          setC11r2MT("");
          setC11R3VNo(0);
          setC11R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col12Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col11X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col12X && curY === row03X)  {
      if (col12Row03 === true && col12Row04 === false) {
        if (c12r3MT === "S") {
            setRow3Naked12([]);
            setCol11Row03(false);
            setC12r3MT("");
            setC12R3VNo(0);
      
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col13Row03 === false && col14Row03 === false) {
              setRowCount(2);
  
            }
            setCurX(col12X);
            setCurY(row02X);


        } else if (c12r3MT === "M") {
          setRow2NakedM12([]);
          setCol12Row03(false);
          setCol12Row02(false);
          setC12r3MT("");
          setC12r2MT("");
          setC12R3VNo(0);
          setC12R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col13Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col12X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col13X && curY === row03X)  {
      if (col13Row03 === true && col13Row04 === false) {
        if (c13r3MT === "S") {
            setRow3Naked13([]);
            setCol13Row03(false);
            setC13r3MT("");
            setC13R3VNo(0);
   
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col14Row03 === false) {
              setRowCount(2);
            }
            setCurX(col13X);
            setCurY(row02X);


        } else if (c13r3MT === "M") {
          setRow2NakedM13([]);
          setCol13Row03(false);
          setCol13Row02(false);
          setC13r3MT("");
          setC13r2MT("");
          setC13R3VNo(0);
          setC13R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col14Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col14Row03 === false) {
            setRowCount(1);

          }
          setCurX(col13X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col14X && curY === row03X)  {
      if (col14Row03 === true && col14Row04 === false) {
        if (c14r3MT === "S") {
            setRow3Naked14([]);
            setCol14Row03(false);
            setC14r3MT("");
            setC14R3VNo(0);
    
            if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false) {
              setRowCount(2);

            }
            setCurX(col14X);
            setCurY(row02X);


        } else if (c14r3MT === "M") {
          setRow2NakedM14([]);
          setCol14Row03(false);
          setCol14Row02(false);
          setC14r3MT("");
          setC14r2MT("");
          setC14R3VNo(0);
          setC14R2VNo(0);
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false) {
            setRowCount(2);

          }
          if (col01Row03 === false && col02Row03 === false && col03Row03 === false && col04Row03 === false && col05Row03 === false && col06Row03 === false && col07Row03 === false && col08Row03 === false && col09Row03 === false && col10Row03 === false && col11Row03 === false && col12Row03 === false && col13Row03 === false) {
            setRowCount(1);
  
          }
          setCurX(col14X);
          setCurY(row01X);
        }
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    }    else if (curX === col01X && curY === row02X)  {
      if (col01Row02 === true && col01Row03 === false) {
        if (c1r2MT === "S") {
            setRow2Naked01([]);
            setCol01Row02(false);
            setC1r2MT("");
            setC1R2VNo(0);

            if (col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);
   
            }
            setCurX(col01X);
            setCurY(row01X);
        } else if (c1r2MT === "M") {
          if (col02Row01 === false) {

            setBaseNakedM01([]);

            setCol01Row01(false);
            setCol01Row02(false);
            
            setC1r2MT("");
            setC1r1MT("");
            setC1R2VNo(0);
            setC1R1VNo(0);

            setCurX(0);
            setCurY(0);
            setColCount(0);
            if (col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
             
            }
            setUpArrow(false);
            setDownArrow(false);
            setLeftArrow(false);
            setRightArrow(false);

            setSepOn2LC1(false);
            setSepOnLC1(false);
            setSepOn3LC1(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
          }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col02X && curY === row02X)  {
      if (col02Row02 === true && col02Row03 === false) {
        if (c2r2MT === "S") {
            setRow2Naked02([]);
            setCol02Row02(false);
            setC2r2MT("");
            setC2R2VNo(0);
          
            if (col01Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
  
            }
            setCurX(col02X);
            setCurY(row01X);
        } else if (c2r2MT === "M") {
          if (col03Row01 === false) {
           
            setBaseNakedM02([]);
            
		        setCol02Row01(false);
            setCol02Row02(false);
          
            setC2r2MT("");
            setC2r1MT("");
            setC2R2VNo(0);
            setC2R1VNo(0);
          
            setCurX(col01X);
            setCurY(row01X);
            setColCount(1);
            if (col01Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
            }
            setSepOn2LC2(false);
            setSepOnLC2(false);
            setSepOn3LC2(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } 
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col03X && curY === row02X)  {
      if (col03Row02 === true && col03Row03 === false) {
        if (c3r2MT === "S") {
            setRow2Naked03([]);
            setCol03Row02(false);
            setC3r2MT("");
            setC3R2VNo(0);
     
            if (col01Row02 === false && col02Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);

            }
            setCurX(col03X);
            setCurY(row01X);
        } else if (c3r2MT === "M") {
          if (col04Row01 === false) {
             
            setBaseNakedM03([]);
      
            setCol03Row01(false);
            setCol03Row02(false);
     
            setC3r2MT("");
            setC3r1MT("");
            setC3R2VNo(0);
            setC3R1VNo(0);

            setCurX(col02X);
            setCurY(row01X);
            setColCount(2);
            if (col01Row02 === false && col02Row02 === false && col04Row02 === false && col05Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
              
            } 
            setSepOn2LC3(false);
            setSepOnLC3(false);
            setSepOn3LC3(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
      }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col04X && curY === row02X)  {
      if (col04Row02 === true && col04Row03 === false) {
        if (c4r2MT === "S") {
            setRow2Naked04([]);
            setCol04Row02(false);
            setC4r2MT("");
            setC4R2VNo(0);
  
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);

            }
            setCurX(col04X);
            setCurY(row01X);
        } else if (c4r2MT === "M") {
          if (col05Row01 === false) {
            
            setBaseNakedM04([]);
           
            setCol04Row01(false);
            setCol04Row02(false);
    
            setC4r2MT("");
            setC4r1MT("");
            setC4R2VNo(0);
            setC4R1VNo(0);

            setCurX(col03X);
            setCurY(row01X);
            setColCount(3);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
             
            }
            setSepOn2LC4(false);
            setSepOnLC4(false);
            setSepOn3LC4(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col05X && curY === row02X)  {
        if (col05Row02 === true  && col05Row03 === false) {
          if (c5r2MT === "S") {
              setRow2Naked05([]);
              setCol05Row02(false);
              setC5r2MT("");
              setC5R2VNo(0);
     
              if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
                setRowCount(1);
 
              }
              setCurX(col05X);
              setCurY(row01X);
          } else if (c5r2MT === "M") {
            if (col06Row01 === false) {
           
              setBaseNakedM05([]);
  
              setCol05Row01(false);
              setCol05Row02(false);
  
              setC5r2MT("");
              setC5r1MT("");
              setC5R2VNo(0);
              setC5R1VNo(0);
  
              setCurX(col04X);
              setCurY(row01X);
              setColCount(4);
              if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
                setRowCount(0);
                
              }
              setSepOn2LC5(false);
              setSepOnLC5(false);
              setSepOn3LC5(false)
  
              const newwidth = floWidth - 0.768;
              setFloWidth(newwidth);
              heightCalculate();
          } 
          } 
        } else {
          alert("You can remove modules only from top to bottom.")
        }
    } else if (curX === col06X && curY === row02X)  {
      if (col06Row02 === true  && col06Row03 === false) {
        if (c6r2MT === "S") {
            setRow2Naked06([]);
            setCol06Row02(false);
            setC6r2MT("");
            setC6R2VNo(0);
   
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);

            }
            setCurX(col06X);
            setCurY(row01X);
        } else if (c6r2MT === "M") {
          if (col07Row01 === false) {
           
            setBaseNakedM06([]);

            setCol06Row01(false);
            setCol06Row02(false);

            setC6r2MT("");
            setC6r1MT("");
            setC6R2VNo(0);
            setC6R1VNo(0);

            setCurX(col05X);
            setCurY(row01X);
            setColCount(5);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0); 
            }
            setSepOn2LC6(false);
            setSepOnLC6(false);
            setSepOn3LC6(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col07X && curY === row02X)  {
      if (col07Row02 === true  && col07Row03 === false) {
        if (c7r2MT === "S") {
            setRow2Naked07([]);
            setCol07Row02(false);
            setC7r2MT("");
            setC7R2VNo(0);
         
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);

            }
            setCurX(col07X);
            setCurY(row01X);
        } else if (c7r2MT === "M") {
          if (col08Row01 === false) {
            
            setBaseNakedM07([]);

            setCol07Row01(false);
            setCol07Row02(false);
        
            setC7r2MT("");
            setC7r1MT("");
            setC7R2VNo(0);
            setC7R1VNo(0);

            setCurX(col06X);
            setCurY(row01X);
            setColCount(6);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
    
            }
            setSepOn2LC7(false);
            setSepOnLC7(false);
            setSepOn3LC7(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } 
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col08X && curY === row02X)  {
      if (col08Row02 === true && col08Row03 === false) {
        if (c8r2MT === "S") {
            setRow2Naked08([]);
            setCol08Row02(false);
            setC8r2MT("");
            setC8R2VNo(0);
  
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);
        
            }
            setCurX(col08X);
            setCurY(row01X);
         } else if (c8r2MT === "M") {
          if (col09Row01 === false) {
            
            setBaseNakedM08([]);

            setCol08Row01(false);
            setCol08Row02(false);

            setC8r2MT("");
            setC8r1MT("");
            setC8R2VNo(0);
            setC8R1VNo(0);

            setCurX(col07X);
            setCurY(row01X);
            setColCount(7);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
       
            }
            setSepOn2LC8(false);
            setSepOnLC8(false);
            setSepOn3LC8(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col09X && curY === row02X)  {
      if (col09Row02 === true && col09Row03 === false) {
        if (c9r2MT === "S") {
            setRow2Naked09([]);
            setCol09Row02(false);
            setC9r2MT("");
            setC9R2VNo(0);
   
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);
            }
            setCurX(col09X);
            setCurY(row01X);
        } else if (c9r2MT === "M") {
          if (col10Row01 === false) {
           
            setBaseNakedM09([]);

            setCol09Row01(false);
            setCol09Row02(false);

            setC9r2MT("");
            setC9r1MT("");
            setC9R2VNo(0);
            setC9R1VNo(0);

            setCurX(col08X);
            setCurY(row01X);
            setColCount(8);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
            }
            setSepOn2LC9(false);
            setSepOnLC9(false);
            setSepOn3LC9(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col10X && curY === row02X)  {
      if (col10Row02 === true && col09Row03 === false) {
        if (c10r2MT === "S") {
            setRow2Naked10([]);
            setCol10Row02(false);
            setC10r2MT("");
            setC10R2VNo(0);
       
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);
   
            }
            setCurX(col10X);
            setCurY(row01X);
        } else if (c10r2MT === "M") {
          if (col11Row01 === false) {
             
            setBaseNakedM10([]);

            setCol10Row01(false);
            setCol10Row02(false);

            setC10r2MT("");
            setC10r1MT("");
            setC10R2VNo(0);
            setC10R1VNo(0);

            setCurX(col09X);
            setCurY(row01X);
            setColCount(9);
            
          if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
            setRowCount(0);
            
          }
            setSepOn2LC10(false);
            setSepOnLC10(false);
            setSepOn3LC10(false)
        

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col11X && curY === row02X)  {
      if (col11Row02 === true && col11Row03 === false) {
        if (c11r2MT === "S") {
            setRow2Naked11([]);
            setCol11Row02(false);
            setC11r2MT("");
            setC11R2VNo(0);
      
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);
              
            }
            setCurX(col11X);
            setCurY(row01X);
        } else if (c11r2MT === "M") {
          if (col12Row01 === false) {
          
            setBaseNakedM11([]);
            
            setCol11Row01(false);
            setCol11Row02(false);
       
            setC11r2MT("");
            setC11r1MT("");
            setC11R2VNo(0);
            setC11R1VNo(0);

            setCurX(col10X);
            setCurY(row01X);
            setColCount(10);

            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0); 
            }

            setSepOn2LC11(false);
            setSepOnLC11(false);
            setSepOn3LC11(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col12X && curY === row02X)  {
      if (col12Row02 === true && col12Row03 === false) {
        if (c12r2MT === "S") {
            setRow2Naked12([]);
            setCol11Row02(false);
            setC12r2MT("");
            setC12R2VNo(0);
           
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(1);
            }
            setCurX(col12X);
            setCurY(row01X);
        } else if (c12r2MT === "M") {
          if (col13Row01 === false) {
       
            setBaseNakedM12([]);

            setCol12Row01(false);
            setCol12Row02(false);
            
            setC12r2MT("");
            setC12r1MT("");
            setC12R2VNo(0);
            setC12R1VNo(0);

            setCurX(col11X);
            setCurY(row01X);
            setColCount(11);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
           
            }

            setSepOn2LC12(false);
            setSepOnLC12(false);
            setSepOn3LC12(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      }
    } else if (curX === col13X && curY === row02X)  {
      if (col13Row02 === true && col13Row03 === false) {
        if (c13r2MT === "S") {
            setRow2Naked13([]);
            setCol13Row02(false);
            setC13r2MT("");
            setC13R2VNo(0);
          
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col14Row02 === false) {
              setRowCount(1);
            }
            setCurX(col13X);
            setCurY(row01X);
        } else if (c13r2MT === "M") {
          if (col14Row01 === false) {
           
            setBaseNakedM13([]);
           

            setCol13Row01(false);
            setCol13Row02(false);
 
	          setC13r2MT("");
	          setC13r1MT("");
            setC13R2VNo(0);
            setC13R1VNo(0);

            setCurX(col12X);
            setCurY(row01X);
            setColCount(12);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col14Row02 === false) {
              setRowCount(0);
            }
            
            setSepOn2LC13(false);
            setSepOnLC13(false);
            setSepOn3LC13(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col14X && curY === row02X)  {
      if (col14Row02 === true && col14Row03 === false) {
        if (c14r2MT === "S") {
            setRow2Naked14([]);
            setCol14Row02(false);
            setC14r2MT("");
            setC14R2VNo(0);
     
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false) {
              setRowCount(1);
   
            }
            setCurX(col14X);
            setCurY(row01X);
        } else if (c14r2MT === "M") {
          if (col14Row01 === true) {
        
         
            setBaseNakedM14([]);
           
            setCol14Row01(false);
            setCol14Row02(false);
           
             setC14r2MT("");
            setC14r1MT("");
            setC14R2VNo(0);
            setC14R1VNo(0);
  
            setCurX(col13X);
            setCurY(row01X);
            setColCount(13);
    
            setSepOn2LC14(false);
            setSepOnLC14(false);
            setSepOn3LC14(false)
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false) {
              setRowCount(0);
   
            }
  
            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        }
        } 
      } else {
        alert("You can remove modules only from top to bottom.")
      }
    } else if (curX === col14X && curY === row01X)  {
      if (col14Row01 === true) {
        
          setBaseNakedL14([]);
          setBaseNaked14([]);
          setBaseNakedM14([]);
          setRow2Naked14([]);
          setRow2NakedM14([]);
          setRow3Naked14([]);
          setRow3NakedM14([]);
          setRow4Naked14([]);

          setCol14Row01(false);
          setCol14Row02(false);
          setCol14Row03(false);
          setCol14Row04(false);

          setC14r4MT("");
          setC14r3MT("");
	        setC14r2MT("");
	        setC14r1MT("");
          setC14R1VNo(0);
          setC14R2VNo(0);
          setC14R3VNo(0);
          setC14R4VNo(0);

          setCurX(col13X);
          setCurY(row01X);
          setColCount(13);
  
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
          if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false) {
            setRowCount(0);
 
          }

          const newwidth = floWidth - 0.768;
          setFloWidth(newwidth);
          heightCalculate();
        
      } else {
        alert("Base modules can be removed only from right to left.");
      }
    } else if (curX === col13X && curY === row01X)  {
        if (col14Row01 === false) {
            setBaseNakedL13([]);
            setBaseNaked13([]);
            setBaseNakedM13([]);
            setRow2Naked13([]);
            setRow2NakedM13([]);
            setRow3Naked13([]);
            setRow3NakedM13([]);
            setRow4Naked13([]);

            setCol13Row01(false);
            setCol13Row02(false);
            setCol13Row03(false);
            setCol13Row04(false);

            setC13r4MT("");
            setC13r3MT("");
	          setC13r2MT("");
	          setC13r1MT("");
            setC13R1VNo(0);
            setC13R2VNo(0);
            setC13R3VNo(0);
            setC13R4VNo(0);

            setCurX(col12X);
            setCurY(row01X);
            setColCount(12);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col14Row02 === false) {
              setRowCount(0);
            }
            
            setSepOn2LC13(false);
            setSepOnLC13(false);
            setSepOn3LC13(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col12X && curY === row01X)  {
        if (col13Row01 === false) {
            setBaseNakedL12([]);
            setBaseNaked12([]);
            setBaseNakedM12([]);
            setRow2Naked12([]);
            setRow2NakedM12([]);
            setRow3Naked12([]);
            setRow3NakedM12([]);
            setRow4Naked12([]);

            setCol12Row01(false);
            setCol12Row02(false);
            setCol12Row03(false);
            setCol12Row04(false);

            setC12r4MT("");
            setC12r3MT("");
            setC12r2MT("");
            setC12r1MT("");
            setC12R1VNo(0);
            setC12R2VNo(0);
            setC12R3VNo(0);
            setC12R4VNo(0);

            setCurX(col11X);
            setCurY(row01X);
            setColCount(11);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
           
            }

            setSepOn2LC12(false);
            setSepOnLC12(false);
            setSepOn3LC12(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col11X && curY === row01X)  {
      if (col12Row01 === false) {
            setBaseNakedL11([]);
            setBaseNaked11([]);
            setBaseNakedM11([]);
            setRow2Naked11([]);
            setRow2NakedM11([]);
            setRow3Naked11([]);
            setRow3NakedM11([]);
            setRow4Naked11([]);

            setCol11Row01(false);
            setCol11Row02(false);
            setCol11Row03(false);
            setCol11Row04(false);

            setC11r4MT("");
            setC11r3MT("");
            setC11r2MT("");
            setC11r1MT("");
            setC11R1VNo(0);
            setC11R2VNo(0);
            setC11R3VNo(0);
            setC11R4VNo(0);

            setCurX(col10X);
            setCurY(row01X);
            setColCount(10);

            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0); 
            }

            setSepOn2LC11(false);
            setSepOnLC11(false);
            setSepOn3LC11(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col10X && curY === row01X)  {
          if (col11Row01 === false) {
              setBaseNakedL10([]);
              setBaseNaked10([]);
              setBaseNakedM10([]);
              setRow2Naked10([]);
              setRow2NakedM10([]);
              setRow3Naked10([]);
              setRow3NakedM10([]);
              setRow4Naked10([]);

              setCol10Row01(false);
              setCol10Row02(false);
              setCol10Row03(false);
              setCol10Row04(false);

              setC10r4MT("");
              setC10r3MT("");
              setC10r2MT("");
              setC10r1MT("");
              setC10R1VNo(0);
              setC10R2VNo(0);
              setC10R3VNo(0);
              setC10R4VNo(0);

              setCurX(col09X);
              setCurY(row01X);
              setColCount(9);
              
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
              
            }
              setSepOn2LC10(false);
              setSepOnLC10(false);
              setSepOn3LC10(false)
          

              const newwidth = floWidth - 0.768;
              setFloWidth(newwidth);
              heightCalculate();
          } else {
            alert("Base modules can be removed only from right to left.");
          }
    } else if (curX === col09X && curY === row01X)  {
        if (col10Row01 === false) {
            setBaseNakedL09([]);
            setBaseNaked09([]);
            setBaseNakedM09([]);
            setRow2Naked09([]);
            setRow2NakedM09([]);
            setRow3Naked09([]);
            setRow3NakedM09([]);
            setRow4Naked09([]);

            setCol09Row01(false);
            setCol09Row02(false);
            setCol09Row03(false);
            setCol09Row04(false);

            setC9r4MT("");
            setC9r3MT("");
            setC9r2MT("");
            setC9r1MT("");
            setC9R1VNo(0);
            setC9R2VNo(0);
            setC9R3VNo(0);
            setC9R4VNo(0);

            setCurX(col08X);
            setCurY(row01X);
            setColCount(8);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
            }
            setSepOn2LC9(false);
            setSepOnLC9(false);
            setSepOn3LC9(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col08X && curY === row01X)  {
        if (col09Row01 === false) {
            setBaseNakedL08([]);
            setBaseNaked08([]);
            setBaseNakedM08([]);
            setRow2Naked08([]);
            setRow2NakedM08([]);
            setRow3Naked08([]);
            setRow3NakedM08([]);
            setRow4Naked08([]);

            setCol08Row01(false);
            setCol08Row02(false);
            setCol08Row03(false);
            setCol08Row04(false);

            setC8r4MT("");
            setC8r3MT("");
            setC8r2MT("");
            setC8r1MT("");
            setC8R1VNo(0);
            setC8R2VNo(0);
            setC8R3VNo(0);
            setC8R4VNo(0);

            setCurX(col07X);
            setCurY(row01X);
            setColCount(7);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
       
            }
            setSepOn2LC8(false);
            setSepOnLC8(false);
            setSepOn3LC8(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col07X && curY === row01X)  {
        if (col08Row01 === false) {
            setBaseNakedL07([]);
            setBaseNaked07([]);
            setBaseNakedM07([]);
            setRow2Naked07([]);
            setRow2NakedM07([]);
            setRow3Naked07([]);
            setRow3NakedM07([]);
            setRow4Naked07([]);

            setCol07Row01(false);
            setCol07Row02(false);
            setCol07Row03(false);
            setCol07Row04(false);

            setC7r4MT("");
            setC7r3MT("");
            setC7r2MT("");
            setC7r1MT("");
            setC7R1VNo(0);
            setC7R2VNo(0);
            setC7R3VNo(0);
            setC7R4VNo(0);

            setCurX(col06X);
            setCurY(row01X);
            setColCount(6);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col06Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
    
            }
            setSepOn2LC7(false);
            setSepOnLC7(false);
            setSepOn3LC7(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col06X && curY === row01X)  {
        if (col07Row01 === false) {
            setBaseNakedL06([]);
            setBaseNaked06([]);
            setBaseNakedM06([]);
            setRow2Naked06([]);
            setRow2NakedM06([]);
            setRow3Naked06([]);
            setRow3NakedM06([]);
            setRow4Naked06([]);

            setCol06Row01(false);
            setCol06Row02(false);
            setCol06Row03(false);
            setCol06Row04(false);

            setC6r4MT("");
            setC6r3MT("");
            setC6r2MT("");
            setC6r1MT("");
            setC1R1VNo(0);
            setC1R2VNo(0);
            setC1R3VNo(0);
            setC1R4VNo(0);

            setCurX(col05X);
            setCurY(row01X);
            setColCount(5);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0); 
            }
            setSepOn2LC6(false);
            setSepOnLC6(false);
            setSepOn3LC6(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col05X && curY === row01X)  {
        if (col06Row01 === false) {
            setBaseNakedL05([]);
            setBaseNaked05([]);
            setBaseNakedM05([]);
            setRow2Naked05([]);
            setRow2NakedM05([]);
            setRow3Naked05([]);
            setRow3NakedM05([]);
            setRow4Naked05([]);

            setCol05Row01(false);
            setCol05Row02(false);
            setCol05Row03(false);
            setCol05Row04(false);

            setC5r4MT("");
            setC5r3MT("");
            setC5r2MT("");
            setC5r1MT("");
            setC5R1VNo(0);
            setC5R2VNo(0);
            setC5R3VNo(0);
            setC5R4VNo(0);

            setCurX(col04X);
            setCurY(row01X);
            setColCount(4);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col04Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
              
            }
            setSepOn2LC5(false);
            setSepOnLC5(false);
            setSepOn3LC5(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col04X && curY === row01X)  {
        if (col05Row01 === false) {
            setBaseNakedL04([]);
            setBaseNaked04([]);
            setBaseNakedM04([]);
            setRow2Naked04([]);
            setRow2NakedM04([]);
            setRow3Naked04([]);
            setRow3NakedM04([]);
            setRow4Naked04([]);

            setCol04Row01(false);
            setCol04Row02(false);
            setCol04Row03(false);
            setCol04Row04(false);

            setC4r4MT("");
            setC4r3MT("");
            setC4r2MT("");
            setC4r1MT("");
            setC4R1VNo(0);
            setC4R2VNo(0);
            setC4R3VNo(0);
            setC4R4VNo(0);

            setCurX(col03X);
            setCurY(row01X);
            setColCount(3);
            if (col01Row02 === false && col02Row02 === false && col03Row02 === false && col05Row02 === false && col06Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
             
            }
            setSepOn2LC4(false);
            setSepOnLC4(false);
            setSepOn3LC4(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col03X && curY === row01X)  {
          if (col04Row01 === false) {
              setBaseNakedL03([]);
              setBaseNaked03([]);
              setBaseNakedM03([]);
              setRow2Naked03([]);
              setRow2NakedM03([]);
              setRow3Naked03([]);
              setRow3NakedM03([]);
              setRow4Naked03([]);

              setCol03Row01(false);
              setCol03Row02(false);
              setCol03Row03(false);
              setCol03Row04(false);

              setC3r4MT("");
              setC3r3MT("");
              setC3r2MT("");
              setC3r1MT("");
              setC3R1VNo(0);
              setC3R2VNo(0);
              setC3R3VNo(0);
              setC3R4VNo(0);

              setCurX(col02X);
              setCurY(row01X);
              setColCount(2);
              if (col01Row02 === false && col02Row02 === false && col04Row02 === false && col05Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
                setRowCount(0);
                
              } 
              setSepOn2LC3(false);
              setSepOnLC3(false);
              setSepOn3LC3(false)

              const newwidth = floWidth - 0.768;
              setFloWidth(newwidth);
              heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col02X && curY === row01X)  {
        if (col03Row01 === false) {
            setBaseNakedL02([]);
            setBaseNaked02([]);
            setBaseNakedM02([]);
            setRow2Naked02([]);
            setRow2NakedM02([]);
            setRow3Naked02([]);
            setRow3NakedM02([]);
            setRow4Naked02([]);

            setCol02Row01(false);
            setCol02Row02(false);
            setCol02Row03(false);
            setCol02Row04(false);

            setC2r4MT("");
            setC2r3MT("");
            setC2r2MT("");
            setC2r1MT("");
            setC2R1VNo(0);
            setC2R2VNo(0);
            setC2R3VNo(0);
            setC2R4VNo(0);
          
            setCurX(col01X);
            setCurY(row01X);
            setColCount(1);
            if (col01Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
            }
            setSepOn2LC2(false);
            setSepOnLC2(false);
            setSepOn3LC2(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
    } else if (curX === col01X && curY === row01X)  {
     
        if (col02Row01 === false) {
            setBaseNakedL01([]);
            setBaseNaked01([]);
            setBaseNakedM01([]);
            setRow2Naked01([]);
            setRow2NakedM01([]);
            setRow3Naked01([]);
            setRow3NakedM01([]);
            setRow4Naked01([]);

            setCol01Row01(false);
            setCol01Row02(false);
            setCol01Row03(false);
            setCol01Row04(false);

            setC1r4MT("");
            setC1r3MT("");
            setC1r2MT("");
            setC1r1MT("");
            setC1R1VNo(0);
            setC1R2VNo(0);
            setC1R3VNo(0);
            setC1R4VNo(0);

            setCurX(0);
            setCurY(0);
            setColCount(0);
            if (col02Row02 === false && col03Row02 === false && col04Row02 === false && col05Row02 === false && col05Row02 === false && col07Row02 === false && col08Row02 === false && col09Row02 === false && col10Row02 === false && col11Row02 === false && col12Row02 === false && col13Row02 === false && col14Row02 === false) {
              setRowCount(0);
             
            }
            setUpArrow(false);
            setDownArrow(false);
            setLeftArrow(false);
            setRightArrow(false);

            setSepOn2LC1(false);
            setSepOnLC1(false);
            setSepOn3LC1(false)

            const newwidth = floWidth - 0.768;
            setFloWidth(newwidth);
            heightCalculate();
        } else {
          alert("Base modules can be removed only from right to left.");
        }
      }
    
    //heightCalculate();
    updatePriceWieght();
   
}

//Adding Back Panel
// adding Back X
const addBackPanel = (e)=> {
   
    if (curX === col01X && curY === row01X && c1R1VNo === 1) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
                 ]
               )
               setC1R1VNo(2);
       } else if (c1r1MT === "M") {      
              setBaseNakedM01(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(2);
           setC1R2VNo(2);
       } else if (c1r1MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(2);
        setC1R2VNo(2);
        setC1R3VNo(2);
        setC1R4VNo(2);
       }
       
      } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
        alert("Already Added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
        if (c1r1MT === "S") {  
          setBaseNaked01(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
                 ]
               )
               setC1R1VNo(6);
       } else if (c1r1MT === "M") {      
           setBaseNakedM01(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(6);
           setC1R2VNo(6);
       } else if (c1r1MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
       }	
      } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
        if (c1r1MT === "S") {  
          setBaseNaked01(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(7);
        } else if (c1r1MT === "M") {      
             setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(7);
            setC1R2VNo(7);
        } else if (c1r1MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {  
        if (c1r1MT === "S") {  
          setBaseNaked01(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(8);
        } else if (c1r1MT === "M") {      
             setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(8);
            setC1R2VNo(8);
        } else if (c1r1MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
        }	
      } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {
        alert("Already Added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 7) {
        alert("Already Added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 8) {
        alert("Already Added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 9) {
        alert("Already Added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 10) {
        alert("Already Added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {
        alert("Already Added");
      // 2nd col
      } else if (curX === col02X && curY === row01X && c2R1VNo === 1) {
        if (c2r1MT === "S") {  
          setBaseNaked02(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(2);
        } else if (c2r1MT === "M") {      
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(2);
            setC2R2VNo(2);
        } else if (c2r1MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
        }	  
        
         
      } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
        alert("Already Added");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
        if (c2r1MT === "S") {  
          setBaseNaked02(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
                 ]
               )
               setC2R1VNo(6);
       } else if (c2r1MT === "M") {      
           setBaseNakedM02(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(6);
           setC2R2VNo(6);
       } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
  setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
       }	
      } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
        if (c2r1MT === "S") {  
          setBaseNaked02(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
                 ]
               )
               setC2R1VNo(7);
       } else if (c2r1MT === "M") {      
           setBaseNakedM02(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(7);
           setC2R2VNo(7);
       } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
  setC2R1VNo(7);
        setC2R2VNo(7);
        setC2R3VNo(7);
        setC2R4VNo(7);
       }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {  
        if (c2r1MT === "S") {  
          setBaseNaked02(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                  ]
                )
                setC2R1VNo(8);
        } else if (c2r1MT === "M") {      
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(8);
            setC2R2VNo(8);
        } else if (c2r1MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
        }	  
        
      } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {
        alert("Already Added");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 7) {
        alert("Already Added");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 8) {
        alert("Already Added");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
        alert("Already Added");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
        alert("Already Added");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
        alert("Already Added");
        // 3rd col
      } else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
        if (c3r1MT === "S") {  
          setBaseNaked03(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(2);
         } else if (c3r1MT === "M") {      
             setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(2);
             setC3R2VNo(2);
         } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
         }	  
         
         
      } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
        alert("Already Added");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
        if (c3r1MT === "S") {  
          setBaseNaked03(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(6);
         } else if (c3r1MT === "M") {      
            setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(6);
             setC3R2VNo(6);
         } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
    setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
         }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
        if (c3r1MT === "S") {  
          setBaseNaked03(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(7);
        } else if (c3r1MT === "M") {      
           setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(7);
            setC3R2VNo(7);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
        }	  
                
      } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {  
        if (c3r1MT === "S") {  
          setBaseNaked03(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(8);
        } else if (c3r1MT === "M") {      
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(8);
            setC3R2VNo(8);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
        }	          
      } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {
        alert("Already Added");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 7) {
        alert("Already Added");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 8) {
        alert("Already Added");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 9) {
        alert("Already Added");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
        alert("Already Added");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
        alert("Already Added");
        //4th Col
      }  else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
        if (c4r1MT === "S") {  
          setBaseNaked04(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(2);
         } else if (c4r1MT === "M") {      
             setBaseNakedM04(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(2);
             setC4R2VNo(2);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
         }	
      } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
        alert("Already Added");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
        if (c4r1MT === "S") {  
          setBaseNaked04(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(6);
         } else if (c4r1MT === "M") {      
              setBaseNakedM04(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(6);
             setC4R2VNo(6);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
    setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
         }	  
         
      } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
        if (c4r1MT === "S") {  
          setBaseNaked04(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(7);
         } else if (c4r1MT === "M") {      
             setBaseNakedM04(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(7);
             setC4R2VNo(7);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
    setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
         }	  
         
      } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {  
        if (c4r1MT === "S") {  
          setBaseNaked04(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(8);
         } else if (c4r1MT === "M") {      
             setBaseNakedM04(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(8);
             setC4R2VNo(8);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
         }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {
        alert("Already Added");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 7) {
        alert("Already Added");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 8) {
        alert("Already Added");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 9) {
        alert("Already Added");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
        alert("Already Added");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
        alert("Already Added");
        //5th Col
      }  else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
        if (c5r1MT === "S") {  
          setBaseNaked05(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(2);
         } else if (c5r1MT === "M") {      
             setBaseNakedM05(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
               ]
             )
             setC5R1VNo(2);
             setC5R2VNo(2);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
         }
          
      } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
        alert("Already Added");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
        if (c5r1MT === "S") {  
          setBaseNaked05(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(6);
        } else if (c5r1MT === "M") {      
             setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(6);
            setC5R2VNo(6);
        } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
    setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
        }	  
        
      } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
        if (c5r1MT === "S") {  
          setBaseNaked05(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(7);
        } else if (c5r1MT === "M") {      
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(7);
            setC5R2VNo(7);
        } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {  
        if (c5r1MT === "S") {  
          setBaseNaked05(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(8);
        } else if (c5r1MT === "M") {      
             setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(8);
            setC5R2VNo(8);
        } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
        }	  
        
      } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {
        alert("Already Added");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 7) {
        alert("Already Added");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 8) {
        alert("Already Added");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
        alert("Already Added");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
        alert("Already Added");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
        alert("Already Added");
        //6th Col
      } else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
        if (c6r1MT === "S") {  
          setBaseNaked06(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(2);
       } else if (c6r1MT === "M") {      
           setBaseNakedM06(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(2);
           setC6R2VNo(2);
       } else if (c6r1MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
        setC6R3VNo(2);
        setC6R4VNo(2);
       }
         
      } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
        alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
        if (c6r1MT === "S") {  
          setBaseNaked06(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
                 ]
               )
               setC6R1VNo(6);
       } else if (c6r1MT === "M") {      
          setBaseNakedM06(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(6);
           setC6R2VNo(6);
       } else if (c6r1MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
  setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
       }	  
       
      } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
        if (c6r1MT === "S") {  
          setBaseNaked06(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
                  ]
                )
                setC6R1VNo(7);
        } else if (c6r1MT === "M") {      
           setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(7);
            setC6R2VNo(7);
        } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
        }	  
          
      } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {  
        if (c6r1MT === "S") {  
          setBaseNaked06(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                  ]
                )
                setC6R1VNo(8);
        } else if (c6r1MT === "M") {      
           setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(8);
            setC6R2VNo(8);
        } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
        }	
      } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {
        alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 7) {
        alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 8) {
        alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 9) {
        alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
        alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
        alert("Already Added");
        //7th Col
      } else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
        if (c7r1MT === "S") {  
          setBaseNaked07(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
                  ]
                )
                setC7R1VNo(2);
        } else if (c7r1MT === "M") {      
          setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(2);
            setC7R2VNo(2);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
        }	          
       
      } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
        if (c7r1MT === "S") {  
          setBaseNaked07(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(6);
        } else if (c7r1MT === "M") {      
           setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(6);
            setC7R2VNo(6);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
    setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
        }	  
        
      } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
        if (c7r1MT === "S") {  
          setBaseNaked07(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
                 ]
               )
               setC7R1VNo(7);
         } else if (c7r1MT === "M") {      
           setBaseNakedM07(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(7);
             setC7R2VNo(7);
         } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
         }	  
         
      } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {  
        if (c7r1MT === "S") {  
          setBaseNaked07(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(8);
        } else if (c7r1MT === "M") {      
           setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(8);
            setC7R2VNo(8);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
        }	
      } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
      alert("Already Added");
      //8th Col
      } else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
        if (c8r1MT === "S") {  
          setBaseNaked08(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(2);
         } else if (c8r1MT === "M") {      
           setBaseNakedM08(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(2);
             setC8R2VNo(2);
         } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
         }	  
         
       
      } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
        if (c8r1MT === "S") {  
          setBaseNaked08(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(6);
        } else if (c8r1MT === "M") {      
          setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(6);
            setC8R2VNo(6);
        } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
    setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
        }	  
        
      } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
        if (c8r1MT === "S") {  
          setBaseNaked08(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
                 ]
               )
               setC8R1VNo(7);
         } else if (c8r1MT === "M") {      
            setBaseNakedM08(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(7);
             setC8R2VNo(7);
         } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
         }	  
                  
      } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {  
        if (c8r1MT === "S") {  
          setBaseNaked08(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                 ]
               )
               setC8R1VNo(8);
         } else if (c8r1MT === "M") {      
            setBaseNakedM08(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(8);
             setC8R2VNo(8);
         } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
         }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
      alert("Already Added");
      //9th Col
      } else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
        if (c9r1MT === "S") {  
          setBaseNaked09(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(2);
         } else if (c9r1MT === "M") {      
             setBaseNakedM09(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
               ]
             )
             setC9R1VNo(2);
             setC9R2VNo(2);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
    setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
         }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
        if (c9r1MT === "S") {  
          setBaseNaked09(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(6);
        } else if (c9r1MT === "M") {      
            setBaseNakedM09(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(6);
            setC9R2VNo(6);
        } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
    setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
        }	  
                
      } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
        if (c9r1MT === "S") {  
          setBaseNaked09(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
                 ]
               )
               setC9R1VNo(7);
         } else if (c9r1MT === "M") {      
             setBaseNakedM09(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
               ]
             )
             setC9R1VNo(7);
             setC9R2VNo(7);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
         }	  
      } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {  
        if (c9r1MT === "S") {  
          setBaseNaked09(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                 ]
               )
               setC9R1VNo(8);
         } else if (c9r1MT === "M") {      
             setBaseNakedM09(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
               ]
             )
             setC9R1VNo(8);
             setC9R2VNo(8);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
         }	  
         
      } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col09X && curY === row01X && c10R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col09X && curY === row01X && c10R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col09X && curY === row01X && c10R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col09X && curY === row01X && c10R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col09X && curY === row01X && c10R1VNo === 11) {
      alert("Already Added");
      //10th Col
      } else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
        if (c10r1MT === "S") {  
          setBaseNaked10(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(2);
         } else if (c10r1MT === "M") {      
            setBaseNakedM10(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
               ]
             )
             setC10R1VNo(2);
             setC10R2VNo(2);
         } else if (c10r1MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
         }	                
      } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
        if (c10r1MT === "S") {  
          setBaseNaked10(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(6);
        } else if (c10r1MT === "M") {      
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(6);
            setC10R2VNo(6);
        } else if (c10r1MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
    setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
        if (c10r1MT === "S") {  
          setBaseNaked10(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(7);
        } else if (c10r1MT === "M") {      
             setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(7);
            setC10R2VNo(7);
        } else if (c10r1MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
        }	
      } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {  
        if (c10r1MT === "S") {  
          setBaseNaked10(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(8);
        } else if (c10r1MT === "M") {      
             setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(8);
            setC10R2VNo(8);
        } else if (c10r1MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
        }	  
        
      } else if (curX === col10X && curY === row01X && c11R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col10X && curY === row01X && c11R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col10X && curY === row01X && c11R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col05X && curY === row01X && c11R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col10X && curY === row01X && c11R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col10X && curY === row01X && c11R1VNo === 11) {
      alert("Already Added");
      //11th Col
      } else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
        if (c11r1MT === "S") {  
          setBaseNaked11(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
                 ]
               )
               setC11R1VNo(2);
       } else if (c11r1MT === "M") {      
            setBaseNakedM11(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(2);
           setC11R2VNo(2);
       } else if (c11r1MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setC11R3VNo(2);
        setC11R4VNo(2);
       }
       
      } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
        if (c11r1MT === "S") {  
          setBaseNaked11(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(6);
      } else if (c11r1MT === "M") {      
           setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
      } else if (c11r1MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
  setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }	
      } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
        if (c11r1MT === "S") {  
          setBaseNaked11(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(7);
      } else if (c11r1MT === "M") {      
          setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
      } else if (c11r1MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(7);
        setC11R2VNo(7);
        setC11R3VNo(7);
        setC11R4VNo(7);
      }	  
      
      } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {  
                if (c11r1MT === "S") {  
                  setBaseNaked11(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(8);
            } else if (c11r1MT === "M") {      
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(8);
                setC11R2VNo(8);
            } else if (c11r1MT === "L") {  
              setBaseNakedL11(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(8);
              setC11R2VNo(8);
              setC11R3VNo(8);
              setC11R4VNo(8);
            }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
      alert("Already Added");
      //12th Col
      } else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
              if (c12r1MT === "S") {  
                setBaseNaked12(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(2);
          } else if (c12r1MT === "M") {      
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(2);
              setC12R2VNo(2);
          } else if (c12r1MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
      setC12R1VNo(2);
            setC12R2VNo(2);
            setC12R3VNo(2);
            setC12R4VNo(2);
          }	  
     
      } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
              if (c12r1MT === "S") {  
                setBaseNaked12(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
                  ]
                )
                setC12R1VNo(6);
          } else if (c12r1MT === "M") {      
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(6);
              setC12R2VNo(6);
          } else if (c12r1MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
      setC12R1VNo(6);
            setC12R2VNo(6);
            setC12R3VNo(6);
            setC12R4VNo(6);
          }	  
     
      } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
              if (c12r1MT === "S") {  
                setBaseNaked12(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
                  ]
                )
                setC12R1VNo(7);
          } else if (c12r1MT === "M") {      
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(7);
              setC12R2VNo(7);
          } else if (c12r1MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(7);
            setC12R2VNo(7);
            setC12R3VNo(7);
            setC12R4VNo(7);
          }	  
     
      } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {  
              if (c12r1MT === "S") {  
                setBaseNaked12(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                  ]
                )
                setC12R1VNo(8);
          } else if (c12r1MT === "M") {      
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(8);
              setC12R2VNo(8);
          } else if (c12r1MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(8);
            setC12R2VNo(8);
            setC12R3VNo(8);
            setC12R4VNo(8);
          }	  
     
      } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
      alert("Already Added");
      //13th Col
      } else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
              if (c13r1MT === "S") {  
                setBaseNaked13(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(2);
          } else if (c13r1MT === "M") {      
            setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(2);
              setC13R2VNo(2);
          } else if (c13r1MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
      setC13R1VNo(2);
            setC13R2VNo(2);
            setC13R3VNo(2);
            setC13R4VNo(2);
          }	
      
      } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
            if (c13r1MT === "S") {  
              setBaseNaked13(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
                  ]
                )
                setC13R1VNo(6);
          } else if (c13r1MT === "M") {      
            setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(6);
              setC13R2VNo(6);
          } else if (c13r1MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
      setC13R1VNo(6);
            setC13R2VNo(6);
            setC13R3VNo(6);
            setC13R4VNo(6);
          }	  
      
      } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
        if (c13r1MT === "S") {  
              setBaseNaked13(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(7);
            } else if (c13r1MT === "M") {      
              setBaseNakedM13(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                  ]
                )
                setC13R1VNo(7);
                setC13R2VNo(7);
            } else if (c13r1MT === "L") {  
              setBaseNakedL13(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(7);
              setC13R2VNo(7);
              setC13R3VNo(7);
              setC13R4VNo(7);
            }	  
        
      } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {  
            if (c13r1MT === "S") {  
              setBaseNaked13(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                  ]
                )
                setC13R1VNo(8);
          } else if (c13r1MT === "M") {      
              setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(8);
              setC13R2VNo(8);
          } else if (c13r1MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(8);
            setC13R2VNo(8);
            setC13R3VNo(8);
            setC13R4VNo(8);
          }	 
      } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
      alert("Already Added");
      //14tt Col
      } else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
                if (c14r1MT === "S") {  
                  setBaseNaked14(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(2);
              } else if (c14r1MT === "M") {      
                  setBaseNakedM14(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(2);
                  setC14R2VNo(2);
              } else if (c14r1MT === "L") {  
                setBaseNakedL14(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
          setC14R1VNo(2);
                setC14R2VNo(2);
                setC14R3VNo(2);
                setC14R4VNo(2);
              }	  
      } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
      alert("Already Added");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
        if (c14r1MT === "S") {  
          setBaseNaked14(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
               ]
             )
             setC14R1VNo(6);
       } else if (c14r1MT === "M") {      
          setBaseNakedM14(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(6);
           setC14R2VNo(6);
       } else if (c14r1MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
  setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
       }	 
      } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
              if (c14r1MT === "S") {  
                setBaseNaked14(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(7);
            } else if (c14r1MT === "M") {      
                setBaseNakedM14(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(7);
                setC14R2VNo(7);
            } else if (c14r1MT === "L") {  
              setBaseNakedL14(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(7);
              setC14R2VNo(7);
              setC14R3VNo(7);
              setC14R4VNo(7);
            }	  
            
      } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {  
            if (c14r1MT === "S") {  
              setBaseNaked14(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(8);
            } else if (c14r1MT === "M") {      
              setBaseNakedM14(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(8);
                setC14R2VNo(8);
            } else if (c14r1MT === "L") {  
              setBaseNakedL14(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(8);
              setC14R2VNo(8);
              setC14R3VNo(8);
              setC14R4VNo(8);
            }	
      } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {
      alert("Already Added");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 7) {
      alert("Already Added");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 8) {
      alert("Already Added");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 9) {
      alert("Already Added");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
      alert("Already Added");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
      alert("Already Added");
      }  else if (curX === col01X && curY === row02X && c1R2VNo === 1) {
              if (c1r2MT === "S") {  
                setRow2Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
                      ]
                    )
                    setC1R2VNo(2);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(2);
              setC1R2VNo(2);
            } else if (c1r2MT === "M" && c1r3MT === "M") {      
                setRow2NakedM01(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                  ]
                )
                setC1R2VNo(2);
                setC1R3VNo(2);
            } else if (c1r2MT === "L") {  
              setBaseNakedL01(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(2);
              setC1R2VNo(2);
              setC1R3VNo(2);
              setC1R4VNo(2);
            }	 
       
      } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
        alert("Already Added");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
              if (c1r2MT === "S") {  
                setRow2Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
                      ]
                    )
                    setC1R2VNo(6);
              } else if (c1r1MT === "M" && c1r2MT === "M") { 
                setBaseNakedM01(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(6);
                setC1R2VNo(6);
              } else if (c1r2MT === "M" && c1r3MT === "M") {     
                setRow2NakedM01(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                  ]
                )
                setC1R2VNo(6);
                setC1R3VNo(6);
            } else if (c1r2MT === "L") {  
              setBaseNakedL01(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                ]
              )
        setC1R1VNo(6);
              setC1R2VNo(6);
              setC1R3VNo(6);
              setC1R4VNo(6);
            }	  
       
      } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
              if (c1r2MT === "S") {  
                setRow2Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
                      ]
                    )
                    setC1R2VNo(7);
              } else if (c1r1MT === "M" && c1r2MT === "M") { 
                setBaseNakedM01(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(7);
                setC1R2VNo(7);
              } else if (c1r2MT === "M" && c1r3MT === "M") {      
                setRow2NakedM01(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                  ]
                )
                setC1R2VNo(7);
                setC1R3VNo(7);
            } else if (c1r2MT === "L") {  
              setBaseNakedL01(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(7);
              setC1R2VNo(7);
              setC1R3VNo(7);
              setC1R4VNo(7);
            }	  
       
      } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {  
        if (c1r2MT === "S") {  
          setRow2Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(8);
          } else if (c1r1MT === "M" && c1r2MT === "M") { 
            setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(8);
            setC1R2VNo(8);
          } else if (c1r2MT === "M" && c1r3MT === "M") {      
         setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(8);
          setC1R3VNo(8);
      } else if (c1r2MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(8);
        setC1R2VNo(8);
        setC1R3VNo(8);
        setC1R4VNo(8);
      }	
      } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {
        alert("Already Added");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 7) {
        alert("Already Added");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 8) {
        alert("Already Added");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 9) {
        alert("Already Added");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
        alert("Already Added");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
        alert("Already Added");
      // 2nd col
      } else if (curX === col02X && curY === row02X && c2R2VNo === 1) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(2);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(2);
              setC2R2VNo(2);
            } else if (c2r2MT === "M" && c2r3MT === "M") {        
           setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(2);
            setC2R3VNo(2);
        } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
        }	
          
      } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
        alert("Already Added");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
                  ]
                )
                setC2R2VNo(6);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(6);
              setC2R2VNo(6);
            } else if (c2r2MT === "M" && c2r3MT === "M") {        
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(6);
            setC2R3VNo(6);
        } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
    setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
        }	  
      } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
                   ]
                 )
                 setC2R2VNo(7);
                } else if (c2r1MT === "M" && c2r2MT === "M") { 
                  setBaseNakedM02(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(7);
                  setC2R2VNo(7);
                } else if (c2r2MT === "M" && c2r3MT === "M") {         
            setRow2NakedM02(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(7);
             setC2R3VNo(7);
         } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
         }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {  
          if (c2r2MT === "S") {  
            setRow2Naked02(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(8);
                } else if (c2r1MT === "M" && c2r2MT === "M") { 
                  setBaseNakedM02(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(8);
                  setC2R2VNo(8);
                } else if (c2r2MT === "M" && c2r3MT === "M") {        
            setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(8);
              setC2R3VNo(8);
          } else if (c2r2MT === "L") {  
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(8);
            setC2R2VNo(8);
            setC2R3VNo(8);
            setC2R4VNo(8);
          }	 
      } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {
        alert("Already Added");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 7) {
        alert("Already Added");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 8) {
        alert("Already Added");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
        alert("Already Added");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
        alert("Already Added");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
        alert("Already Added");
        // 3rd col
      } else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
                   ]
                 )
                 setC3R2VNo(2);
                } else if (c3r1MT === "M" && c3r2MT === "M") { 
                  setBaseNakedM03(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(2);
                  setC3R2VNo(2);
                } else if (c3r2MT === "M" && c3r3MT === "M") {       
          setRow2NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
             ]
           )
           setC3R2VNo(2);
           setC3R3VNo(2);
       } else if (c3r2MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
        setC3R3VNo(2);
        setC3R4VNo(2);
       }
         
      } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
        alert("Already Added");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
              if (c3r2MT === "S") {  
                setRow2Naked03(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
                      ]
                    )
                    setC3R2VNo(6);
                  } else if (c3r1MT === "M" && c3r2MT === "M") { 
                    setBaseNakedM03(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(6);
                    setC3R2VNo(6);
                  } else if (c3r2MT === "M" && c3r3MT === "M") {       
                setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                  ]
                )
                setC3R2VNo(6);
                setC3R3VNo(6);
            } else if (c3r2MT === "L") {  
              setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
        setC3R1VNo(6);
              setC3R2VNo(6);
              setC3R3VNo(6);
              setC3R4VNo(6);
            }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                 ]
               )
               setC3R2VNo(7);
              } else if (c3r1MT === "M" && c3r2MT === "M") { 
                setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(7);
                setC3R2VNo(7);
              } else if (c3r2MT === "M" && c3r3MT === "M") {     
          setRow2NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
             ]
           )
           setC3R2VNo(7);
           setC3R3VNo(7);
       } else if (c3r2MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(7);
        setC3R2VNo(7);
        setC3R3VNo(7);
        setC3R4VNo(7);
       }	
      } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {  
        if (c3r2MT === "S") {  
          setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(8);
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(8);
              setC3R2VNo(8);
            } else if (c3r2MT === "M" && c3r3MT === "M") {       
        setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(8);
          setC2R3VNo(8);
      } else if (c3r2MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(8);
        setC3R2VNo(8);
        setC3R3VNo(8);
        setC3R4VNo(8);
      }	  
      
      } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {
        alert("Already Added");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 7) {
        alert("Already Added");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 8) {
        alert("Already Added");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 9) {
        alert("Already Added");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
        alert("Already Added");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
        alert("Already Added");
        //4th Col
      }  else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
            if (c4r2MT === "S") {  
              setRow2Naked04(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
                      ]
                    )
                    setC4R2VNo(2);
                  } else if (c4r1MT === "M" && c4r2MT === "M") { 
                    setBaseNakedM04(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(2);
                    setC4R2VNo(2);
                  } else if (c4r2MT === "M" && c4r3MT === "M") {        
            setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(2);
              setC4R3VNo(2);
          } else if (c4r2MT === "L") {  
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(2);
            setC4R2VNo(2);
            setC4R3VNo(2);
            setC4R4VNo(2);
          }	
          
      } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
        alert("Already Added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
              if (c4r2MT === "S") {  
                setRow2Naked04(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
                      ]
                    )
                    setC4R2VNo(6);
                  } else if (c4r1MT === "M" && c4r2MT === "M") { 
                    setBaseNakedM04(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(6);
                    setC4R2VNo(6);
                  } else if (c4r2MT === "M" && c4r3MT === "M") {      
                setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                  ]
                )
                setC4R2VNo(6);
                setC4R3VNo(6);
            } else if (c4r2MT === "L") {  
              setBaseNakedL04(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(6);
              setC4R2VNo(6);
              setC4R3VNo(6);
              setC4R4VNo(6);
            }	  
      } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
            if (c4r2MT === "S") {  
              setRow2Naked04(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                    ]
                  )
                  setC4R2VNo(7);
                } else if (c4r1MT === "M" && c4r2MT === "M") { 
                  setBaseNakedM04(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(7);
                  setC4R2VNo(7);
                } else if (c4r2MT === "M" && c4r3MT === "M") {        
              setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(7);
              setC4R3VNo(7);
          } else if (c4r2MT === "L") {  

            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(7);
            setC4R2VNo(7);
            setC4R3VNo(7);
            setC4R4VNo(7);
          }	  
      } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {  
        if (c4r2MT === "S") {  
          setRow2Naked04(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                  ]
                )
                setC4R2VNo(8);
              } else if (c4r1MT === "M" && c4r2MT === "M") { 
                setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(8);
                setC4R2VNo(8);
              } else if (c4r2MT === "M" && c4r3MT === "M") {       
            setRow2NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(8);
            setC4R3VNo(8);
        } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
        }	
      } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {
        alert("Already Added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 7) {
        alert("Already Added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 8) {
        alert("Already Added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 9) {
        alert("Already Added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
        alert("Already Added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
        alert("Already Added");
        //5th Col
      }  else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(2);
                } else if (c5r1MT === "M" && c5r2MT === "M") { 
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(2);
                  setC5R2VNo(2);
                } else if (c5r2MT === "M" && c5r3MT === "M") {        
           setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(2);
            setC5R3VNo(2);
        } else if (c5r2MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
        }	  
         
      } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
        alert("Already Added");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
                 ]
               )
               setC5R2VNo(6);
              } else if (c5r1MT === "M" && c5r2MT === "M") { 
                setBaseNakedM05(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(6);
                setC5R2VNo(6);
              } else if (c5r2MT === "M" && c5r3MT === "M") {       
            setRow2NakedM05(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
             ]
           )
           setC5R2VNo(6);
           setC5R3VNo(6);
       } else if (c5r2MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
       }	  
       
      } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
            if (c5r2MT === "S") {  
              setRow2Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(7);
                } else if (c5r1MT === "M" && c5r2MT === "M") { 
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(7);
                  setC5R2VNo(7);
                } else if (c5r2MT === "M" && c5r3MT === "M") {       
                  setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(7);
              setC5R3VNo(7);
          } else if (c5r2MT === "L") {  
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(7);
            setC5R2VNo(7);
            setC5R3VNo(7);
            setC5R4VNo(7);
          }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {  
        if (c5r2MT === "S") {  
          setRow2Naked05(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                  ]
                )
                setC5R2VNo(8);
              } else if (c5r1MT === "M" && c5r2MT === "M") { 
                setBaseNakedM05(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(8);
                setC5R2VNo(8);
              } else if (c5r2MT === "M" && c5r3MT === "M") {      
              setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(8);
            setC5R3VNo(8);
        } else if (c5r2MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
        }	  
        
      } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {
        alert("Already Added");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 7) {
        alert("Already Added");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 8) {
        alert("Already Added");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
        alert("Already Added");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
        alert("Already Added");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
        alert("Already Added");
        //6th Col
      } else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
          if (c6r2MT === "S") {  
                    setRow2Naked06(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
                      ]
                    )
                    setC6R2VNo(2);
                  } else if (c6r1MT === "M" && c6r2MT === "M") { 
                    setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(2);
                    setC6R2VNo(2);
                  } else if (c6r2MT === "M" && c6r3MT === "M") {       
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(2);
              setC6R3VNo(2);
          } else if (c6r2MT === "L") {  
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(2);
            setC6R2VNo(2);
            setC6R3VNo(2);
            setC6R4VNo(2);
          }	
      } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
        alert("Already Added");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
                if (c6r2MT === "S") {  
                  setRow2Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
                    ]
                  )
                  setC6R2VNo(6);
                } else if (c6r1MT === "M" && c6r2MT === "M") { 
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(6);
                  setC6R2VNo(6);
                } else if (c6r2MT === "M" && c6r3MT === "M") {        
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(6);
              setC6R3VNo(6);
          } else if (c6r2MT === "L") {  
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(6);
            setC6R2VNo(6);
            setC6R3VNo(6);
            setC6R4VNo(6);
          }	  
  
      } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
              if (c6r2MT === "S") {  
                setRow2Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
                    ]
                  )
                  setC6R2VNo(7);
                } else if (c6r1MT === "M" && c6r2MT === "M") { 
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(7);
                  setC6R2VNo(7);
                } else if (c6r2MT === "M" && c6r3MT === "M") {       
                setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(7);
              setC6R3VNo(7);
          } else if (c6r2MT === "L") {  
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(7);
            setC6R2VNo(7);
            setC6R3VNo(7);
            setC6R4VNo(7);
          }	
      } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {  
              if (c6r2MT === "S") {  
                setRow2Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                    ]
                  )
                  setC6R2VNo(8);
                } else if (c6r1MT === "M" && c6r2MT === "M") { 
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(8);
                  setC6R2VNo(8);
                } else if (c6r2MT === "M" && c6r3MT === "M") {       
                setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(8);
              setC6R3VNo(8);
          } else if (c6r2MT === "L") {  
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(8);
            setC6R2VNo(8);
            setC6R3VNo(8);
            setC6R4VNo(8);
          }	 
      } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {
        alert("Already Added");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 7) {
        alert("Already Added");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 8) {
        alert("Already Added");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 9) {
        alert("Already Added");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
        alert("Already Added");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
        alert("Already Added");
        //7th Col
      } else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
                 ]
               )
               setC7R2VNo(2);
              } else if (c7r1MT === "M" && c7r2MT === "M") { 
                setBaseNakedM07(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                  ]
                )
                setC7R1VNo(2);
                setC7R2VNo(2);
              } else if (c7r2MT === "M" && c7r3MT === "M") {       
           setRow2NakedM07(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
             ]
           )
           setC7R2VNo(2);
           setC7R3VNo(2);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
        setC7R3VNo(2);
        setC7R4VNo(2);
       }	         
       
      } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(6);
            } else if (c7r1MT === "M" && c7r2MT === "M") { 
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(6);
              setC7R2VNo(6);
            } else if (c7r2MT === "M" && c7r3MT === "M") {       
           setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(6);
            setC7R3VNo(6);
        } else if (c7r2MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
    setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
        }	 
      } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
               ]
             )
             setC7R2VNo(7);
            } else if (c7r1MT === "M" && c7r2MT === "M") { 
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(7);
              setC7R2VNo(7);
            } else if (c7r2MT === "M" && c7r3MT === "M") {       
          setRow2NakedM07(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
             ]
           )
           setC7R2VNo(7);
           setC7R3VNo(7);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(7);
        setC7R2VNo(7);
        setC7R3VNo(7);
        setC7R4VNo(7);
       }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {  
        if (c7r2MT === "S") {  
          setRow2Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
               ]
             )
             setC7R2VNo(8);
            } else if (c7r1MT === "M" && c7r2MT === "M") { 
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(8);
              setC7R2VNo(8);
            } else if (c7r2MT === "M" && c7r3MT === "M") {       
         setRow2NakedM07(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
             ]
           )
           setC7R2VNo(8);
           setC7R3VNo(8);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(8);
        setC7R2VNo(8);
        setC7R3VNo(8);
        setC7R4VNo(8);
       }	
      } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
      alert("Already Added");
      //8th Col
      } else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
                 ]
               )
               setC8R2VNo(2);  
         
          } else if (c8r1MT === "M" && c8r2MT === "M") { 
            setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(2);
            setC8R2VNo(2);
          } else if (c8r2MT === "M" && c8r3MT === "M") {   
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(2);
            setC8R3VNo(2);
          } else if (c8r2MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setC8R3VNo(2);
        setC8R4VNo(2);
       }	
       
      } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(6);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(6);
              setC8R2VNo(6);
            } else if (c8r2MT === "M" && c8r3MT === "M") {        
          setRow2NakedM08(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
             ]
           )
           setC8R2VNo(6);
           setC8R3VNo(6);
       } else if (c8r2MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
  setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
       }	  
       
      } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(7);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(7);
              setC8R2VNo(7);
            } else if (c8r2MT === "M" && c8r3MT === "M") {       
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(7);
            setC8R3VNo(7);
        } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {  
        if (c8r2MT === "S") {  
          setRow2Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(8);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(8);
              setC8R2VNo(8);
            } else if (c8r2MT === "M" && c8r3MT === "M") {       
           setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(8);
            setC8R3VNo(8);
        } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
        }	  
        
      } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
      alert("Already Added");
      //9th Col
      } else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
                   ]
                 )
                 setC9R2VNo(2);
                } else if (c9r1MT === "M" && c9r2MT === "M") { 
                  setBaseNakedM09(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(2);
                  setC9R2VNo(2);
                } else if (c9r2MT === "M" && c9r3MT === "M") {       
            setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(2);
             setC9R3VNo(2);
         } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
    setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
         }	
        
      } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
                 ]
               )
               setC9R2VNo(6);
              } else if (c9r1MT === "M" && c9r2MT === "M") { 
                setBaseNakedM09(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(6);
                setC9R2VNo(6);
              } else if (c9r2MT === "M" && c9r3MT === "M") {     
           setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(6);
             setC9R3VNo(6);
         } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
    setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
         }	  
         
      } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
                 ]
               )
               setC9R2VNo(7);
              } else if (c9r1MT === "M" && c9r2MT === "M") { 
                setBaseNakedM09(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(7);
                setC9R2VNo(7);
              } else if (c9r2MT === "M" && c9r3MT === "M") {     
           setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(7);
             setC9R3VNo(7);
         } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
         }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {  
        if (c9r2MT === "S") {  
          setRow2Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                 ]
               )
               setC9R2VNo(8);
              } else if (c9r1MT === "M" && c9r2MT === "M") { 
                setBaseNakedM09(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(8);
                setC9R2VNo(8);
              } else if (c9r2MT === "M" && c9r3MT === "M") {      
            setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(8);
             setC9R3VNo(8);
         } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
         }	  
         
      } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col09X && curY === row02X && c10R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col09X && curY === row02X && c10R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col09X && curY === row02X && c10R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col09X && curY === row02X && c10R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col09X && curY === row02X && c10R2VNo === 11) {
      alert("Already Added");
      //10th Col
      } else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(2);
              } else if (c10r1MT === "M" && c10r2MT === "M") { 
                setBaseNakedM10(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(2);
                setC10R2VNo(2);
              } else if (c10r2MT === "M" && c10r3MT === "M") {        
            setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(2);
            setC10R3VNo(2);
        } else if (c10r2MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
        }	          
       
      } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(6);
            } else if (c10r1MT === "M" && c10r2MT === "M") { 
              setBaseNakedM10(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(6);
              setC10R2VNo(6);
            } else if (c10r2MT === "M" && c10r3MT === "M") {      
            setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(6);
            setC10R3VNo(6);
        } else if (c10r2MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
        }	  
        
      } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                 ]
               )
               setC10R2VNo(7);
              } else if (c10r1MT === "M" && c10r2MT === "M") { 
                setBaseNakedM10(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(7);
                setC10R2VNo(7);
              } else if (c10r2MT === "M" && c10r3MT === "M") {       
             setRow2NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
               ]
             )
             setC10R2VNo(7);
             setC10R3VNo(7);
         } else if (c10r2MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
         }	  
         
      } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {  
        if (c10r2MT === "S") {  
          setRow2Naked10(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                 ]
               )
               setC10R2VNo(8);
              } else if (c10r1MT === "M" && c10r2MT === "M") { 
                setBaseNakedM10(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(8);
                setC10R2VNo(8);
              } else if (c10r2MT === "M" && c10r3MT === "M") {      
             setRow2NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
               ]
             )
             setC10R2VNo(8);
             setC10R3VNo(8);
         } else if (c10r2MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
         }	  
         
      } else if (curX === col10X && curY === row02X && c11R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col10X && curY === row02X && c11R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col10X && curY === row02X && c11R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col05X && curY === row02X && c11R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col10X && curY === row02X && c11R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col10X && curY === row02X && c11R2VNo === 11) {
      alert("Already Added");
      //11th Col
      } else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
                 ]
               )
               setC11R2VNo(2);
              } else if (c11r1MT === "M" && c11r2MT === "M") { 
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(2);
                setC11R2VNo(2);
              } else if (c11r2MT === "M" && c11r3MT === "M") {      
           setRow2NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
             ]
           )
           setC11R2VNo(2);
           setC11R3VNo(2);
       } else if (c11r2MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setC11R3VNo(2);
        setC11R4VNo(2);
       }	         
      
      } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(6);
            } else if (c11r1MT === "M" && c11r2MT === "M") { 
              setBaseNakedM11(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(6);
              setC11R2VNo(6);
            } else if (c11r2MT === "M" && c11r3MT === "M") {    
           setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(6);
            setC11R3VNo(6);
        } else if (c11r2MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
    setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
        }	  
        
      } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                 ]
               )
               setC11R2VNo(7);
              } else if (c11r1MT === "M" && c11r2MT === "M") { 
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(7);
                setC11R2VNo(7);
              } else if (c11r2MT === "M" && c11r3MT === "M") {   
            setRow2NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
               ]
             )
             setC11R2VNo(7);
             setC11R3VNo(7);
         } else if (c11r2MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
         }	  
         
      } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {  
        if (c11r2MT === "S") {  
          setRow2Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(8);
            } else if (c11r1MT === "M" && c11r2MT === "M") { 
              setBaseNakedM11(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(8);
              setC11R2VNo(8);
            } else if (c11r2MT === "M" && c11r3MT === "M") {      
           setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(8);
            setC11R3VNo(8);
        } else if (c11r2MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
        }	 
      } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
      alert("Already Added");
      //12th Col
      } else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(2);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(2);
              setC12R2VNo(2);
            } else if (c12r2MT === "M" && c12r3MT === "M") {     
           setRow2NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(2);
            setC12R3VNo(2);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
        }	
       
      } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
                 ]
               )
               setC12R2VNo(6);
              } else if (c12r1MT === "M" && c12r2MT === "M") { 
                setBaseNakedM12(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                  ]
                )
                setC12R1VNo(2);
                setC12R2VNo(2);
              } else if (c12r2MT === "M" && c12r3MT === "M") {     
           setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(6);
             setC12R3VNo(6);
         } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
    setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
         }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                 ]
               )
               setC12R2VNo(7);
              } else if (c12r1MT === "M" && c12r2MT === "M") { 
                setBaseNakedM12(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                  ]
                )
                setC12R1VNo(7);
                setC12R2VNo(7);
              } else if (c12r2MT === "M" && c12r3MT === "M") {       
           setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(7);
             setC12R3VNo(7);
         } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
         }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {  
        if (c12r2MT === "S") {  
          setRow2Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(8);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(8);
              setC12R2VNo(8);
            } else if (c12r2MT === "M" && c12r3MT === "M") {      
           setRow2NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(8);
            setC12R3VNo(8);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
        }	  
        
        
      } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
      alert("Already Added");
      //13th Col
      } else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(2);
              } else if (c13r1MT === "M" && c13r2MT === "M") { 
                setBaseNakedM13(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                  ]
                )
                setC13R1VNo(2);
                setC13R2VNo(2);
              } else if (c13r2MT === "M" && c13r3MT === "M") {        
           setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(2);
            setC13R3VNo(2);
        } else if (c13r2MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
    setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
        }
        
      } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(6);
            } else if (c13r1MT === "M" && c13r2MT === "M") { 
              setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(6);
              setC13R2VNo(6);
            } else if (c13r2MT === "M" && c13r3MT === "M") {       
           setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(6);
            setC13R3VNo(6);
        } else if (c13r2MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
        }	  
        
      } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(7);
            } else if (c13r1MT === "M" && c13r2MT === "M") { 
              setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(7);
              setC13R2VNo(7);
            } else if (c13r2MT === "M" && c13r3MT === "M") {      
            setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(7);
            setC13R3VNo(7);
        } else if (c13r2MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
        }	
      } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {  
        if (c13r2MT === "S") {  
          setRow2Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(8);
            } else if (c13r1MT === "M" && c13r2MT === "M") { 
              setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(8);
              setC13R2VNo(8);
            } else if (c13r2MT === "M" && c13r3MT === "M") {       
             setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(8);
            setC13R3VNo(8);
        } else if (c13r2MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
        }	
      } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
      alert("Already Added");
      //14tt Col
      } else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
                  ]
                )
                setC14R2VNo(2);
              } else if (c14r1MT === "M" && c14r2MT === "M") { 
                setBaseNakedM14(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(2);
                setC14R2VNo(2);
              } else if (c14r2MT === "M" && c14r3MT === "M") {      
             setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(2);
            setC14R3VNo(2);
        } else if (c14r2MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
    setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
        }	          
        
      } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
      alert("Already Added");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(6);
            } else if (c14r1MT === "M" && c14r2MT === "M") { 
              setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(6);
              setC14R2VNo(6);
            } else if (c14r2MT === "M" && c14r3MT === "M") {    
             setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(6);
            setC14R3VNo(6);
        } else if (c14r2MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
    setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
        }	  
        
      } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(7);
            } else if (c14r1MT === "M" && c14r2MT === "M") { 
              setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(7);
              setC14R2VNo(7);
            } else if (c14r2MT === "M" && c14r3MT === "M") {   
             setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(7);
            setC14R3VNo(7);
        } else if (c14r2MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
        }	 
      } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {  
        if (c14r2MT === "S") {  
          setRow2Naked14(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(8);
            } else if (c14r1MT === "M" && c14r2MT === "M") { 
              setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(8);
              setC14R2VNo(8);
            } else if (c14r2MT === "M" && c14r3MT === "M") {     
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(8);
            setC14R3VNo(8);
        } else if (c14r2MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
        }	 
      } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {
      alert("Already Added");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 7) {
      alert("Already Added");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 8) {
      alert("Already Added");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 9) {
      alert("Already Added");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
      alert("Already Added");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
      alert("Already Added");
      }  else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
        if (c1r3MT === "S") {  
          setRow3Naked01(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
                  ]
                )
                setC1R3VNo(2);
              } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                setRow2NakedM01(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                  ]
                )
                setC1R2VNo(2);
                setC1R3VNo(2);
              } else if (c1r3MT === "M" && c1r4MT === "M") {       
           setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(2);
            setC1R4VNo(2);
        } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
        }	
        
      } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
        alert("Already Added");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
        if (c1r3MT === "S") {  
          setRow3Naked01(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
                 ]
               )
               setC1R3VNo(6);
              } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                setRow2NakedM01(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                  ]
                )
                setC1R2VNo(6);
                setC1R3VNo(6);
              } else if (c1r3MT === "M" && c1r4MT === "M") {    
          setRow3NakedM01(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(6);
           setC1R4VNo(6);
       } else if (c1r3MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
  setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
       }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
        if (c1r3MT === "S") {  
          setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(7);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(7);
              setC1R3VNo(7);
            } else if (c1r3MT === "M" && c1r4MT === "M") {     
          setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(7);
          setC1R4VNo(7);
      } else if (c1r3MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(7);
        setC1R2VNo(7);
        setC1R3VNo(7);
        setC1R4VNo(7);
      }	
      } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {  
        if (c1r3MT === "S") {  
          setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(8);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(8);
              setC1R3VNo(8);
            } else if (c1r3MT === "M" && c1r4MT === "M") {     
           setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(8);
          setC1R4VNo(8);
      } else if (c1r3MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(8);
        setC1R2VNo(8);
        setC1R3VNo(8);
        setC1R4VNo(8);
      }	  
      
      } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {
        alert("Already Added");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 7) {
        alert("Already Added");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 8) {
        alert("Already Added");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 9) {
        alert("Already Added");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
        alert("Already Added");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
        alert("Already Added");
      // 2nd col
      } else if (curX === col02X && curY === row03X && c2R3VNo === 1) {
        if (c2r3MT === "S") {  
                  setRow3Naked02(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
                    ]
                  )
                  setC2R3VNo(2);
                } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                  setRow2NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(2);
                  setC2R3VNo(2);
                } else if (c2r3MT === "M" && c2r4MT === "M") {    
            setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(2);
            setC2R4VNo(2);
        } else if (c2r3MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
        alert("Already Added");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
              if (c2r3MT === "S") {  
                setRow3Naked02(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
                    ]
                  )
                  setC2R3VNo(6);
                } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                  setRow2NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(6);
                  setC2R3VNo(6);
                } else if (c2r3MT === "M" && c2r4MT === "M") {   
            setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(6);
              setC2R4VNo(6);
          } else if (c2r3MT === "L") {  
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
      setC2R1VNo(6);
            setC2R2VNo(6);
            setC2R3VNo(6);
            setC2R4VNo(6);
          }	  
    
      } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
              if (c2r3MT === "S") {  
                setRow3Naked02(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
                    ]
                  )
                  setC2R3VNo(7);
                } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                  setRow2NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(7);
                  setC2R3VNo(7);
                } else if (c2r3MT === "M" && c2r4MT === "M") {     
            setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(7);
              setC2R4VNo(7);
          } else if (c2r3MT === "L") {  
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
      setC2R1VNo(7);
            setC2R2VNo(7);
            setC2R3VNo(7);
            setC2R4VNo(7);
          }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {  
            if (c2r3MT === "S") {  
                  setRow3Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                      ]
                    )
                    setC2R3VNo(8);
                  } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                    setRow2NakedM02(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(8);
                    setC2R3VNo(8);
                  } else if (c2r3MT === "M" && c2r4MT === "M") {     
                setRow3NakedM02(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                  ]
                )
                setC2R3VNo(8);
                setC2R4VNo(8);
            } else if (c2r3MT === "L") {  
              setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(8);
              setC2R2VNo(8);
              setC2R3VNo(8);
              setC2R4VNo(8);
            }	  
     
      } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {
        alert("Already Added");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 7) {
        alert("Already Added");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 8) {
        alert("Already Added");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
        alert("Already Added");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
        alert("Already Added");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
        alert("Already Added");
        // 3rd col
      } else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
              if (c3r3MT === "S") {  
                setRow3Naked03(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
                      ]
                    )
                    setC3R3VNo(2);
                  } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                    setRow2NakedM03(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                      ]
                    )
                    setC3R2VNo(2);
                    setC3R3VNo(2);
                  } else if (c3r3MT === "M" && c3r4MT === "M") {        
              setRow3NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(2);
              setC3R4VNo(2);
          } else if (c3r3MT === "L") {  
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(2);
            setC3R2VNo(2);
            setC3R3VNo(2);
            setC3R4VNo(2);
          }	  
      } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
        alert("Already Added");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
        if (c3r3MT === "S") {  
          setRow3Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(6);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(6);
              setC3R3VNo(6);
            } else if (c3r3MT === "M" && c3r4MT === "M") {        
          setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(6);
          setC3R4VNo(6);
      } else if (c3r3MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
  setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
        if (c3r3MT === "S") {  
          setRow3Naked03(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                  ]
                )
                setC3R3VNo(7);
              } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                  ]
                )
                setC3R2VNo(7);
                setC3R3VNo(7);
              } else if (c3r3MT === "M" && c3r4MT === "M") {        
             setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(7);
            setC3R4VNo(7);
        } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
        }	  
        
      } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {  
        if (c3r3MT === "S") {  
          setRow3Naked03(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                   ]
                 )
                 setC3R3VNo(8);
                } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                  setRow2NakedM03(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                    ]
                  )
                  setC3R2VNo(8);
                  setC2R3VNo(8);
                } else if (c3r3MT === "M" && c3r4MT === "M") {       
              setRow3NakedM03(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(8);
             setC2R4VNo(8);
         } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
         }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {
        alert("Already Added");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 7) {
        alert("Already Added");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 8) {
        alert("Already Added");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 9) {
        alert("Already Added");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
        alert("Already Added");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
        alert("Already Added");
        //4th Col
      }  else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
        if (c4r3MT === "S") {  
          setRow3Naked04(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
                    ]
                  )
                  setC4R3VNo(2);
                } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                  setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                    ]
                  )
                  setC4R2VNo(2);
                  setC4R3VNo(2);
                } else if (c4r3MT === "M" && c4r4MT === "M") {        
            setRow3NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(2);
            setC4R4VNo(2);
        } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
        }	          
         
      } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
        alert("Already Added");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
        if (c4r3MT === "S") {  
          setRow3Naked04(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
                    ]
                  )
                  setC4R3VNo(6);
                } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                  setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                    ]
                  )
                  setC4R2VNo(6);
                  setC4R3VNo(6);
                } else if (c4r3MT === "M" && c4r4MT === "M") {       
             setRow3NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(6);
              setC4R4VNo(6);
          } else if (c4r3MT === "L") {  
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
              ]
            )
      setC4R1VNo(6);
            setC4R2VNo(6);
            setC4R3VNo(6);
            setC4R4VNo(6);
          }	
      } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
        if (c4r3MT === "S") {  
          setRow3Naked04(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
                    ]
                  )
                  setC4R3VNo(7);
                } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                  setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                    ]
                  )
                  setC4R2VNo(7);
                  setC4R3VNo(7);
                } else if (c4r3MT === "M" && c4r4MT === "M") {       
             setRow3NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(7);
              setC4R4VNo(7);
          } else if (c4r3MT === "L") {  
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(7);
            setC4R2VNo(7);
            setC4R3VNo(7);
            setC4R4VNo(7);
          }	
      } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {  
        if (c4r3MT === "S") {  
          setRow3Naked04(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                   ]
                 )
                 setC4R3VNo(8);
                } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                  setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                    ]
                  )
                  setC4R2VNo(8);
                  setC4R3VNo(8);
                } else if (c4r3MT === "M" && c4r4MT === "M") {       
             setRow3NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
               ]
             )
             setC4R3VNo(8);
             setC4R4VNo(8);
         } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
         }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {
        alert("Already Added");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 7) {
        alert("Already Added");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 8) {
        alert("Already Added");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 9) {
        alert("Already Added");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
        alert("Already Added");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
        alert("Already Added");
        //5th Col
      }  else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
        if (c5r3MT === "S") {  
          setRow3Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
                    ]
                  )
                  setC5R3VNo(2);
                } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                  setRow2NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(2);
                  setC5R3VNo(2);
                } else if (c5r3MT === "M" && c5r4MT === "M") {       
             setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(2);
            setC5R4VNo(2);
        } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
        }	          
         
      } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
        alert("Already Added");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
        if (c5r3MT === "S") {  
          setRow3Naked05(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
                  ]
                )
                setC5R3VNo(6);
              } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                setRow2NakedM05(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                  ]
                )
                setC5R2VNo(6);
                setC5R3VNo(6);
              } else if (c5r3MT === "M" && c5r4MT === "M") {       
            setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(6);
            setC5R4VNo(6);
        } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
    setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
        if (c5r3MT === "S") {  
          setRow3Naked05(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
                  ]
                )
                setC5R3VNo(7);
              } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                setRow2NakedM05(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                  ]
                )
                setC5R2VNo(7);
                setC5R3VNo(7);
              } else if (c5r3MT === "M" && c5r4MT === "M") {      
             setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(7);
            setC5R4VNo(7);
        } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {  
        if (c5r3MT === "S") {  
          setRow3Naked05(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                 ]
               )
               setC5R3VNo(8);
              } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                setRow2NakedM05(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                  ]
                )
                setC5R2VNo(8);
                setC5R3VNo(8);
              } else if (c5r3MT === "M" && c5r4MT === "M") {       
            setRow3NakedM05(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
             ]
           )
           setC5R3VNo(8);
           setC5R4VNo(8);
       } else if (c5r3MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(8);
        setC5R2VNo(8);
        setC5R3VNo(8);
        setC5R4VNo(8);
       }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {
        alert("Already Added");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 7) {
        alert("Already Added");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 8) {
        alert("Already Added");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
        alert("Already Added");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
        alert("Already Added");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
        alert("Already Added");
        //6th Col
      } else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
        if (c6r3MT === "S") {  
          setRow3Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
                    ]
                  )
                  setC6R3VNo(2);
                } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                  setRow2NakedM06(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                    ]
                  )
                  setC6R2VNo(2);
                  setC6R3VNo(2);
                } else if (c6r3MT === "M" && c6r4MT === "M") {       
             setRow3NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(2);
            setC6R4VNo(2);
        } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
        }	
          
      } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
        alert("Already Added");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
        if (c6r3MT === "S") {  
          setRow3Naked06(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
                  ]
                )
                setC6R3VNo(6);
              } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                setRow2NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                  ]
                )
                setC6R2VNo(6);
                setC6R3VNo(6);
              } else if (c6r3MT === "M" && c6r4MT === "M") {     
             setRow3NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(6);
            setC6R4VNo(6);
        } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
    setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
        }	  
        
      } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
        if (c6r3MT === "S") {  
          setRow3Naked06(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
                 ]
               )
               setC6R3VNo(7);
              } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                setRow2NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                  ]
                )
                setC6R2VNo(7);
                setC6R3VNo(7);
              } else if (c6r3MT === "M" && c6r4MT === "M") {       
           setRow3NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
             ]
           )
           setC6R3VNo(7);
           setC6R4VNo(7);
       } else if (c6r3MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(7);
        setC6R2VNo(7);
        setC6R3VNo(7);
        setC6R4VNo(7);
       }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {  
        if (c6r3MT === "S") {  
          setRow3Naked06(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                 ]
               )
               setC6R3VNo(8);
              } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                setRow2NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                  ]
                )
                setC6R2VNo(8);
                setC6R3VNo(8);
              } else if (c6r3MT === "M" && c6r4MT === "M") {       
            setRow3NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
             ]
           )
           setC6R3VNo(8);
           setC6R4VNo(8);
       } else if (c6r3MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(8);
        setC6R2VNo(8);
        setC6R3VNo(8);
        setC6R4VNo(8);
       }	  
       
      } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {
        alert("Already Added");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 7) {
        alert("Already Added");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 8) {
        alert("Already Added");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 9) {
        alert("Already Added");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
        alert("Already Added");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
        alert("Already Added");
        //7th Col
      } else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
              if (c7r3MT === "S") {  
                setRow3Naked07(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
                      ]
                    )
                    setC7R3VNo(2);
                  } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                    setRow2NakedM07(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                      ]
                    )
                    setC7R2VNo(2);
                    setC7R3VNo(2);
                  } else if (c7r3MT === "M" && c7r4MT === "M") {     
                setRow3NakedM07(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                  ]
                )
                setC7R3VNo(2);
                setC7R4VNo(2);
            } else if (c7r3MT === "L") {  
              setBaseNakedL07(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(2);
              setC7R2VNo(2);
              setC7R3VNo(2);
              setC7R4VNo(2);
            }	  
      
      } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
      alert("Already Added");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
        if (c7r3MT === "S") {  
          setRow3Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(6);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(6);
              setC7R3VNo(6);
            } else if (c7r3MT === "M" && c7r4MT === "M") {      
          setRow3NakedM07(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
             ]
           )
           setC7R3VNo(6);
           setC7R4VNo(6);
       } else if (c7r3MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
  setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
       }	
      } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
        if (c7r3MT === "S") {  
          setRow3Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(7);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(7);
              setC7R3VNo(7);
            } else if (c7r3MT === "M" && c7r4MT === "M") {     
            setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(7);
            setC7R4VNo(7);
        } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {  
        if (c7r3MT === "S") {  
          setRow3Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(8);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(8);
              setC7R3VNo(8);
            } else if (c7r3MT === "M" && c7r4MT === "M") {    
          setRow3NakedM07(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
             ]
           )
           setC7R3VNo(8);
           setC7R4VNo(8);
       } else if (c7r3MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(8);
        setC7R2VNo(8);
        setC7R3VNo(8);
        setC7R4VNo(8);
       }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
      alert("Already Added");
      //8th Col
      } else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
        if (c8r3MT === "S") {  
          setRow3Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(2);
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(2);
              setC8R3VNo(2);
            } else if (c8r3MT === "M" && c8r4MT === "M") {     
         setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(2);
          setC8R4VNo(2);
      } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setC8R3VNo(2);
        setC8R4VNo(2);
      }   
    } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
      alert("Already Added");
    } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
              if (c8r3MT === "S") {  
                setRow3Naked08(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
                    ]
                  )
                  setC8R3VNo(6);
                } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                  setRow2NakedM08(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                    ]
                  )
                  setC8R2VNo(6);
                  setC8R3VNo(6);
                } else if (c8r3MT === "M" && c8r4MT === "M") {    
              setRow3NakedM08(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                  ]
                )
                setC8R3VNo(6);
                setC8R4VNo(6);
            } else if (c8r3MT === "L") {  
              setBaseNakedL08(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                ]
              )
        setC8R1VNo(6);
              setC8R2VNo(6);
              setC8R3VNo(6);
              setC8R4VNo(6);
            }	  
      
      } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
        if (c8r3MT === "S") {  
          setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(7);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(7);
            setC8R3VNo(7);
          } else if (c8r3MT === "M" && c8r4MT === "M") {     
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(7);
        setC8R4VNo(7);
      } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(7);
        setC8R2VNo(7);
        setC8R3VNo(7);
        setC8R4VNo(7);
      }	  
      
      } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {  
            if (c8r3MT === "S") {  
              setRow3Naked08(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                  ]
                )
                setC8R3VNo(8);
              } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                setRow2NakedM08(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                  ]
                )
                setC8R2VNo(8);
                setC8R3VNo(8);
              } else if (c8r3MT === "M" && c8r4MT === "M") {       
              setRow3NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(8);
              setC8R4VNo(8);
          } else if (c8r3MT === "L") {  
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(8);
            setC8R2VNo(8);
            setC8R3VNo(8);
            setC8R4VNo(8);
          }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col08X && curY === row03X && c8R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col08X && curY === row03X && c8R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col08X && curY === row03X && c8R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
      alert("Already Added");
      //9th Col
      } else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
            if (c9r3MT === "S") {  
              setRow3Naked09(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
                    ]
                  )
                  setC9R3VNo(2);
                } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                  setRow2NakedM09(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                    ]
                  )
                  setC9R2VNo(2);
                  setC9R3VNo(2);
                } else if (c9r3MT === "M" && c9r4MT === "M") {     
              setRow3NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(2);
              setC9R4VNo(2);
          } else if (c9r3MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(2);
            setC9R2VNo(2);
            setC9R3VNo(2);
            setC9R4VNo(2);
          }
        
      } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
      alert("Already Added");
      } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
        if (c9r3MT === "S") {  
          setRow3Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(6);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(6);
              setC9R3VNo(6);
            } else if (c9r3MT === "M" && c9r4MT === "M") {      
            setRow3NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(6);
            setC9R4VNo(6);
        } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
        }	  
        
      } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
        if (c9r3MT === "S") {  
          setRow3Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(7);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(7);
              setC9R3VNo(7);
            } else if (c9r3MT === "M" && c9r4MT === "M") {     
             setRow3NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(7);
            setC9R4VNo(7);
        } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {  
        if (c9r3MT === "S") {  
          setRow3Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                 ]
               )
               setC9R3VNo(8);
              } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                setRow2NakedM09(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                  ]
                )
                setC9R2VNo(8);
                setC9R3VNo(8);
              } else if (c9r3MT === "M" && c9r4MT === "M") {     
              setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(8);
             setC9R4VNo(8);
         } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
         }	  
         
      } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col09X && curY === row03X && c10R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col09X && curY === row03X && c10R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col09X && curY === row03X && c10R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col09X && curY === row03X && c10R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col09X && curY === row03X && c10R3VNo === 11) {
      alert("Already Added");
      //10th Col
      } else if (curX === col10X && curY === row03X && c10R3VNo === 1) {

        if (c10r3MT === "S") {  
          setRow3Naked10(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
                   ]
                 )
                 setC10R3VNo(2);
                } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                  setRow2NakedM10(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                    ]
                  )
                  setC10R2VNo(2);
                  setC10R3VNo(2);
                } else if (c10r3MT === "M" && c10r4MT === "M") {       
                setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(2);
             setC10R4VNo(2);
         } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
         }	  
         
      } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
      alert("Already Added");
      } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
        if (c10r3MT === "S") {  
          setRow3Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(6);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(6);
              setC10R3VNo(6);
            } else if (c10r3MT === "M" && c10r4MT === "M") {       
             setRow3NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(6);
            setC10R4VNo(6);
        } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
    setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
        if (c10r3MT === "S") {  
          setRow3Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(7);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(7);
              setC11R3VNo(7);
            } else if (c10r3MT === "M" && c10r4MT === "M") {      
           setRow3NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
             ]
           )
           setC10R3VNo(7);
           setC10R4VNo(7);
       } else if (c10r3MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(7);
        setC10R2VNo(7);
        setC10R3VNo(7);
        setC10R4VNo(7);
       }	  
       
      } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {  
        if (c10r3MT === "S") {  
          setRow3Naked10(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                 ]
               )
               setC10R3VNo(8);
              } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                setRow2NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(8);
                setC10R3VNo(8);
              } else if (c10r3MT === "M" && c10r4MT === "M") {      
             setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(8);
             setC10R4VNo(8);
         } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
         }	 
      } else if (curX === col10X && curY === row03X && c11R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col10X && curY === row03X && c11R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col10X && curY === row03X && c11R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col05X && curY === row03X && c11R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col10X && curY === row03X && c11R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col10X && curY === row03X && c11R3VNo === 11) {
      alert("Already Added");
      //11th Col
      } else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
        if (c11r3MT === "S") {  
          setRow3Naked11(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
                   ]
                 )
                 setC11R3VNo(2);
                } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                  setRow2NakedM11(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                    ]
                  )
                  setC11R2VNo(2);
                  setC11R3VNo(2);
                } else if (c11r3MT === "M" && c11r4MT === "M") {      
            setRow3NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(2);
             setC11R4VNo(2);
         } else if (c11r3MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
         }	           
       
      } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
      alert("Already Added");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
        if (c11r3MT === "S") {  
          setRow3Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(6);
            } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
              setRow2NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(6);
              setC11R3VNo(6);
            } else if (c11r3MT === "M" && c11r4MT === "M") {     
           setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(6);
            setC11R4VNo(6);
        } else if (c11r3MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
    setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
        if (c11r3MT === "S") {  
          setRow3Naked11(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(7);
            } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
              setRow2NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(7);
              setC11R3VNo(7);
            } else if (c11r3MT === "M" && c11r4MT === "M") {     
          setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(7);
           setC11R4VNo(7);
       } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(7);
        setC11R2VNo(7);
        setC11R3VNo(7);
        setC11R4VNo(7);
       }	  
       
      } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {  
        if (c11r3MT === "S") {  
          setRow3Naked11(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(8);
            } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
              setRow2NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(8);
              setC11R3VNo(8);
            } else if (c11r3MT === "M" && c11r4MT === "M") {      
          setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(8);
           setC11R4VNo(8);
       } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(8);
        setC11R2VNo(8);
        setC11R3VNo(8);
        setC11R4VNo(8);
       }	  
       
      } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
      alert("Already Added");
      //12th Col
      } else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
        if (c12r3MT === "S") {  
          setRow3Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
                 ]
               )
               setC12R3VNo(2);
              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(2);
                setC12R3VNo(2);
              } else if (c12r3MT === "M" && c12r4MT === "M") {        
          setRow3NakedM12(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
             ]
           )
           setC12R3VNo(2);
           setC12R4VNo(2);
       } else if (c12r3MT === "L") {  
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
  setC12R1VNo(2);
        setC12R2VNo(2);
        setC12R3VNo(2);
        setC12R4VNo(2);
       }	  
       
      } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
      alert("Already Added");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
        if (c12r3MT === "S") {  
          setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(6);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(6);
              setC12R3VNo(6);
            } else if (c12r3MT === "M" && c12r4MT === "M") {       
            setRow3NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(6);
            setC12R4VNo(6);
        } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
    setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
        }	 
      } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
        if (c12r3MT === "S") {  
          setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(7);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(7);
              setC12R3VNo(7);
            } else if (c12r3MT === "M" && c12r4MT === "M") {       
             setRow3NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(7);
            setC12R4VNo(7);
        } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {  
        if (c12r3MT === "S") {  
          setRow3Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                 ]
               )
               setC12R3VNo(8);
              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(8);
                setC12R3VNo(8);
              } else if (c12r3MT === "M" && c12r4MT === "M") {       
             setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(8);
             setC12R4VNo(8);
         } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
         }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
      alert("Already Added");
      //13th Col
      } else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
        if (c13r3MT === "S") {  
          setRow3Naked13(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
                   ]
                 )
                 setC13R3VNo(2);
                } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                  setRow2NakedM13(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                    ]
                  )
                  setC13R2VNo(2);
                  setC13R3VNo(2);
                } else if (c13r3MT === "M" && c13r4MT === "M") {       
              setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(2);
             setC13R4VNo(2);
         } else if (c13r3MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
    setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
         }	  
       
      } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
      alert("Already Added");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
        if (c13r3MT === "S") {  
          setRow3Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
                 ]
               )
               setC13R3VNo(6);
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(6);
                setC13R3VNo(6);
              } else if (c13r3MT === "M" && c13r4MT === "M") {        
              setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(6);
             setC13R4VNo(6);
         } else if (c13r3MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
    setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
         }	  
         
      } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
        if (c13r3MT === "S") {  
          setRow3Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                 ]
               )
               setC13R3VNo(7);
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(7);
                setC13R3VNo(7);
              } else if (c13r3MT === "M" && c13r4MT === "M") {       
             setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(7);
             setC13R4VNo(7);
         } else if (c13r3MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
         }	  
         
      } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {  
        if (c13r3MT === "S") {  
          setRow3Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                  ]
                )
                setC13R3VNo(8);
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(8);
                setC13R3VNo(8);
              } else if (c13r3MT === "M" && c13r4MT === "M") {       
             setRow3NakedM13(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(8);
              setC13R4VNo(8);
          } else if (c13r3MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(8);
            setC13R2VNo(8);
            setC13R3VNo(8);
            setC13R4VNo(8);
          }	
      } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
      alert("Already Added");
      //14tt Col
      } else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
        if (c14r3MT === "S") {  
          setRow3Naked14(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
                    ]
                  )
                  setC14R3VNo(2);
                } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                  setRow2NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                    ]
                  )
                  setC14R2VNo(2);
                  setC14R3VNo(2);
                } else if (c14r3MT === "M" && c14r4MT === "M") {      
              setRow3NakedM14(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(2);
              setC14R4VNo(2);
          } else if (c14r3MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
      setC14R1VNo(2);
            setC14R2VNo(2);
            setC14R3VNo(2);
            setC14R4VNo(2);
          }	            
        
      } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
      alert("Already Added");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
        if (c14r3MT === "S") {  
          setRow3Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
                  ]
                )
                setC14R3VNo(6);
              } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                setRow2NakedM14(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                  ]
                )
                setC14R2VNo(6);
                setC14R3VNo(6);
              } else if (c14r3MT === "M" && c14r4MT === "M") {      
              setRow3NakedM14(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(6);
              setC14R4VNo(6);
          } else if (c14r3MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
      setC14R1VNo(6);
            setC14R2VNo(6);
            setC14R3VNo(6);
            setC14R4VNo(6);
          }	            
      } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
        if (c14r3MT === "S") {  
          setRow3Naked14(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                 ]
               )
               setC14R3VNo(7);
              } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                setRow2NakedM14(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                  ]
                )
                setC14R2VNo(7);
                setC14R3VNo(7);
              } else if (c14r3MT === "M" && c14r4MT === "M") {     
            setRow3NakedM14(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
               ]
             )
             setC14R3VNo(7);
             setC14R4VNo(7);
         } else if (c14r3MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
         }	           
      } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {  
        if (c14r3MT === "S") {  
          setRow3Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                  ]
                )
                setC14R3VNo(8);
              } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                setRow2NakedM14(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                  ]
                )
                setC14R2VNo(8);
                setC14R3VNo(8);
              } else if (c14r3MT === "M" && c14r4MT === "M") {     
            setRow3NakedM14(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(8);
              setC14R4VNo(8);
          } else if (c14r3MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(8);
            setC14R2VNo(8);
            setC14R3VNo(8);
            setC14R4VNo(8);
          }	
      } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {
      alert("Already Added");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 7) {
      alert("Already Added");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 8) {
      alert("Already Added");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 9) {
      alert("Already Added");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
      alert("Already Added");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
      alert("Already Added");
      } 	else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
        if (c1r4MT === "S") {  
          setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
            ]
          )
          setC1R4VNo(2);
          } else if (c1r4MT === "M") {      
            setRow3NakedM01(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(2);
              setC1R4VNo(2);
          } else if (c1r4MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
            setC1R2VNo(2);
            setC1R3VNo(2);
            setC1R4VNo(2);
          }
       
      } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
        alert("Already Added");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
        if (c1r4MT === "S") {  
          setRow4Naked01(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
                   ]
                 )
                 setC1R4VNo(6);
         } else if (c1r4MT === "M") {      
           setRow3NakedM01(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
               ]
             )
             setC1R3VNo(6);
             setC1R4VNo(6);
         } else if (c1r4MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
    setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
         }	
      } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
        if (c1r4MT === "S") {  
          setRow4Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
              ]
            )
            setC1R4VNo(7);
                 } else if (c1r4MT === "M") {      
                   setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(7);
            setC1R4VNo(7);
         } else if (c1r4MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
         }	
      } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {  
          if (c1r4MT === "S") {  
            setRow4Naked01(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                  ]
                )
                setC1R4VNo(8);
          } else if (c1r4MT === "M") {      
              setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(8);
            setC1R4VNo(8);
          } else if (c1r4MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(8);
            setC1R2VNo(8);
            setC1R3VNo(8);
            setC1R4VNo(8);
          }	
      } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {
        alert("Already Added");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 7) {
        alert("Already Added");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 8) {
        alert("Already Added");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 9) {
        alert("Already Added");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 10) {
        alert("Already Added");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
        alert("Already Added");
      // 2nd col
      } else if (curX === col02X && curY === row04X && c2R4VNo === 1) {
        if (c2r4MT === "S") {  
          setRow4Naked02(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
                   ]
                 )
                 setC2R4VNo(2);
         } else if (c2r4MT === "M") {      
             setRow3NakedM02(
                 [
                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                 ]
               )
               setC2R3VNo(2);
               setC2R4VNo(2);
        } else if (c2r4MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
        }	
         
      } else if (curX === col02X && curY === row04X && c2R4VNo === 2) {
        alert("Already Added");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 3) {
        if (c2r4MT === "S") {  
          setRow4Naked02(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
                  ]
                )
                setC2R4VNo(6);
          } else if (c2r4MT === "M") {      
              setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(6);
            setC2R4VNo(6);
         } else if (c2r4MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
    setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
         }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 4) {
        if (c2r4MT === "S") {  
          setRow4Naked02(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                  ]
                )
                setC2R4VNo(7);
          } else if (c2r4MT === "M") {      
             setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(7);
            setC2R4VNo(7);
         } else if (c2r4MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
    setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
         }	
      } else if (curX === col02X && curY === row04X && c2R4VNo === 5) {  
        if (c2r4MT === "S") {  
          setRow4Naked02(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                 ]
               )
               setC2R4VNo(8);
         } else if (c2r4MT === "M") {      
           setRow3NakedM02(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
             ]
           )
           setC2R3VNo(8);
           setC2R4VNo(8);
        } else if (c2r4MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
        }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 6) {
        alert("Already Added");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 7) {
        alert("Already Added");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 8) {
        alert("Already Added");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 9) {
        alert("Already Added");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 10) {
        alert("Already Added");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 11) {
        alert("Already Added");
        // 3rd col
      } else if (curX === col03X && curY === row04X && c3R4VNo === 1) {
        if (c3r4MT === "S") {  
          setRow4Naked03(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
                    ]
                  )
                  setC3R4VNo(2);
          } else if (c3r4MT === "M") {      
            setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(2);
            setC3R4VNo(2);
         } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
         }
          
      } else if (curX === col03X && curY === row04X && c3R4VNo === 2) {
        alert("Already Added");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 3) {
        if (c3r4MT === "S") {  
          setRow4Naked03(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
                  ]
                )
                setC3R4VNo(6);
          } else if (c3r4MT === "M") {      
           setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(6);
            setC3R4VNo(6);
         } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
    setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
         }	
      } else if (curX === col03X && curY === row04X && c3R4VNo === 4) {
        if (c3r4MT === "S") {  
          setRow4Naked03(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                  ]
                )
                setC3R4VNo(7);
          } else if (c3r4MT === "M") {      
           setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(7);
            setC3R4VNo(7);
         } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
         }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 5) {  
        if (c3r4MT === "S") {  
          setRow4Naked03(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                  ]
                )
                setC3R4VNo(8);
          } else if (c3r4MT === "M") {      
            setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(8);
            setC2R4VNo(8);
         } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
         }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 6) {
        alert("Already Added");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 7) {
        alert("Already Added");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 8) {
        alert("Already Added");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 9) {
        alert("Already Added");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 10) {
        alert("Already Added");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {
        alert("Already Added");
        //4th Col
      }  else if (curX === col04X && curY === row04X && c4R4VNo === 1) {
        if (c4r4MT === "S") {  
          setRow4Naked04(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
                    ]
                  )
                  setC4R4VNo(2);
          } else if (c4r4MT === "M") {      
            setRow3NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(2);
            setC4R4VNo(2);
         } else if (c4r4MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
         }
          
      } else if (curX === col04X && curY === row04X && c4R4VNo === 2) {
        alert("Already Added");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 3) {
        if (c4r4MT === "S") {  
          setRow4Naked04(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
                  ]
                )
                setC4R4VNo(6);
          } else if (c4r4MT === "M") {      
            setRow3NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(6);
            setC4R4VNo(6);
         } else if (c4r4MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
    setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
         }	
      } else if (curX === col04X && curY === row04X && c4R4VNo === 4) {
        if (c4r4MT === "S") {  
          setRow4Naked04(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
                 ]
               )
               setC4R4VNo(7);
         } else if (c4r4MT === "M") {      
         setRow3NakedM04(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(7);
           setC4R4VNo(7);
        } else if (c4r4MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
        }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 5) {  
        if (c4r4MT === "S") {  
          setRow4Naked04(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                  ]
                )
                setC4R4VNo(8);
          } else if (c4r4MT === "M") {      
           setRow3NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(8);
            setC4R4VNo(8);
         } else if (c4r4MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
         }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 6) {
        alert("Already Added");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 7) {
        alert("Already Added");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 8) {
        alert("Already Added");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 9) {
        alert("Already Added");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 10) {
        alert("Already Added");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {
        alert("Already Added");
        //5th Col
      }  else if (curX === col05X && curY === row04X && c5R4VNo === 1) {
        if (c5r4MT === "S") {  
          setRow4Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
                    ]
                  )
                  setC5R4VNo(2);
          } else if (c5r4MT === "M") {      
            setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(2);
            setC5R4VNo(2);
         } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
         }
          
      } else if (curX === col05X && curY === row04X && c5R4VNo === 2) {
        alert("Already Added");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 3) {
        if (c5r4MT === "S") {  
          setRow4Naked05(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
                  ]
                )
                setC5R4VNo(6);
          } else if (c5r4MT === "M") {      
           setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(6);
            setC5R4VNo(6);
         } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
    setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
         }
      } else if (curX === col05X && curY === row04X && c5R4VNo === 4) {
        if (c5r4MT === "S") {  
          setRow4Naked05(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
                  ]
                )
                setC5R4VNo(7);
          } else if (c5r4MT === "M") {      
           setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(7);
            setC5R4VNo(7);
         } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
         }	
      } else if (curX === col05X && curY === row04X && c5R4VNo === 5) {  
        if (c5r4MT === "S") {  
          setRow4Naked05(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                   ]
                 )
                 setC5R4VNo(8);
           } else if (c5r4MT === "M") {      
          setRow3NakedM05(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
               ]
             )
             setC5R3VNo(8);
             setC5R4VNo(8);
          } else if (c5r4MT === "L") {  
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(8);
            setC5R2VNo(8);
            setC5R3VNo(8);
            setC5R4VNo(8);
          }	
      } else if (curX === col05X && curY === row04X && c5R4VNo === 6) {
        alert("Already Added");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 7) {
        alert("Already Added");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 8) {
        alert("Already Added");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 9) {
        alert("Already Added");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 10) {
        alert("Already Added");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {
        alert("Already Added");
        //6th Col
      } else if (curX === col06X && curY === row04X && c6R4VNo === 1) {
        if (c6r4MT === "S") {  
          setRow4Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
                    ]
                  )
                  setC6R4VNo(2);
          } else if (c6r4MT === "M") {      
          setRow3NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(2);
            setC6R4VNo(2);
         } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
         }	
         
      } else if (curX === col06X && curY === row04X && c6R4VNo === 2) {
        alert("Already Added");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 3) {
        if (c6r4MT === "S") {  
          setRow4Naked06(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
                 ]
               )
               setC6R4VNo(6);
         } else if (c6r4MT === "M") {      
         setRow3NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
             ]
           )
           setC6R3VNo(6);
           setC6R4VNo(6);
        } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
    setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
        }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 4) {
        if (c6r4MT === "S") {  
          setRow4Naked06(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
                 ]
               )
               setC6R4VNo(7);
         } else if (c6r4MT === "M") {      
          setRow3NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
             ]
           )
           setC6R3VNo(7);
           setC6R4VNo(7);
        } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
        }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 5) {  
        if (c6r4MT === "S") {  
          setRow4Naked06(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                 ]
               )
               setC6R4VNo(8);
         } else if (c6r4MT === "M") {      
           setRow3NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
             ]
           )
           setC6R3VNo(8);
           setC6R4VNo(8);
        } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
        }	
      } else if (curX === col06X && curY === row04X && c6R4VNo === 6) {
        alert("Already Added");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 7) {
        alert("Already Added");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 8) {
        alert("Already Added");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 9) {
        alert("Already Added");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 10) {
        alert("Already Added");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {
        alert("Already Added");
        //7th Col
      } else if (curX === col07X && curY === row04X && c7R4VNo === 1) {
        if (c7r4MT === "S") {  
          setRow4Naked07(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
                 ]
               )
               setC7R4VNo(2);
         } else if (c7r4MT === "M") {      
           setRow3NakedM07(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
             ]
           )
           setC7R3VNo(2);
           setC7R4VNo(2);
        } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
        }
       
      } else if (curX === col07X && curY === row04X && c7R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 3) {
        if (c7r4MT === "S") {  
          setRow4Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
                ]
              )
              setC7R4VNo(6);
          } else if (c7r4MT === "M") {      
           setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(6);
            setC7R4VNo(6);
         } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
    setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
         }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 4) {
        if (c7r4MT === "S") {  
          setRow4Naked07(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                 ]
               )
               setC7R4VNo(7);
           } else if (c7r4MT === "M") {      
           setRow3NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(7);
             setC7R4VNo(7);
          } else if (c7r4MT === "L") {  
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
            setC7R2VNo(7);
            setC7R3VNo(7);
            setC7R4VNo(7);
          }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 5) {  
        if (c7r4MT === "S") {  
          setRow4Naked07(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                 ]
               )
               setC7R4VNo(8);
           } else if (c7r4MT === "M") {      
           setRow3NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(8);
             setC7R4VNo(8);
          } else if (c7r4MT === "L") {  
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(8);
            setC7R2VNo(8);
            setC7R3VNo(8);
            setC7R4VNo(8);
          }	
      } else if (curX === col07X && curY === row04X && c7R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {
      alert("Already Added");
      //8th Col
      } else if (curX === col08X && curY === row04X && c8R4VNo === 1) {
        if (c8r4MT === "S") {  
          setRow4Naked08(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
                   ]
                 )
                 setC8R4VNo(2);
           } else if (c8r4MT === "M") {      
           setRow3NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(2);
             setC8R4VNo(2);
          } else if (c8r4MT === "L") {  
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(2);
            setC8R2VNo(2);
            setC8R3VNo(2);
            setC8R4VNo(2);
          }
       
      } else if (curX === col08X && curY === row04X && c8R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 3) {
        if (c8r4MT === "S") {  
          setRow4Naked08(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
                 ]
               )
               setC8R4VNo(6);
           } else if (c8r4MT === "M") {      
           setRow3NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(6);
             setC8R4VNo(6);
          } else if (c8r4MT === "L") {  
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
      setC8R1VNo(6);
            setC8R2VNo(6);
            setC8R3VNo(6);
            setC8R4VNo(6);
          }	
      } else if (curX === col08X && curY === row04X && c8R4VNo === 4) {
        if (c8r4MT === "S") {  
          setRow4Naked08(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
                 ]
               )
               setC8R4VNo(7);
           } else if (c8r4MT === "M") {      
           setRow3NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(7);
             setC8R4VNo(7);
          } else if (c8r4MT === "L") {  
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(7);
            setC8R2VNo(7);
            setC8R3VNo(7);
            setC8R4VNo(7);
          }	
      } else if (curX === col08X && curY === row04X && c8R4VNo === 5) {  
        if (c8r4MT === "S") {  
          setRow4Naked08(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                 ]
               )
               setC8R4VNo(8);
           } else if (c8r4MT === "M") {      
           setRow3NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(8);
             setC8R4VNo(8);
          } else if (c8r4MT === "L") {  
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(8);
            setC8R2VNo(8);
            setC8R3VNo(8);
            setC8R4VNo(8);
          }	
      } else if (curX === col08X && curY === row04X && c8R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {
      alert("Already Added");
      //9th Col
      } else if (curX === col09X && curY === row04X && c9R4VNo === 1) {
                if (c9r4MT === "S") {  
                  setRow4Naked09(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
                    ]
                  )
                  setC9R4VNo(2);
            } else if (c9r4MT === "M") {      
            setRow3NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(2);
              setC9R4VNo(2);
          } else if (c9r4MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(2);
            setC9R2VNo(2);
            setC9R3VNo(2);
            setC9R4VNo(2);
          }	
      } else if (curX === col09X && curY === row04X && c9R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col09X && curY === row04X && c9R4VNo === 3) {
        if (c9r4MT === "S") {  
          setRow4Naked09(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
             ]
           )
           setC9R4VNo(6);
       } else if (c9r4MT === "M") {      
       setRow3NakedM09(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
           ]
         )
         setC9R3VNo(6);
         setC9R4VNo(6);
      } else if (c9r4MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }	
      } else if (curX === col09X && curY === row04X && c9R4VNo === 4) {
        if (c9r4MT === "S") {  
          setRow4Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
               ]
             )
             setC9R4VNo(7);
         } else if (c9r4MT === "M") {      
         setRow3NakedM09(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
             ]
           )
           setC9R3VNo(7);
           setC9R4VNo(7);
        } else if (c9r4MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
        }	
      } else if (curX === col09X && curY === row04X && c9R4VNo === 5) {  
        if (c9r4MT === "S") {  
          setRow4Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
              ]
            )
            setC9R4VNo(8);
        } else if (c9r4MT === "M") {      
         setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(8);
          setC9R4VNo(8);
       } else if (c9r4MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(8);
        setC9R2VNo(8);
        setC9R3VNo(8);
        setC9R4VNo(8);
       }	
      } else if (curX === col09X && curY === row04X && c9R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col09X && curY === row04X && c10R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col09X && curY === row04X && c10R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col09X && curY === row04X && c10R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col09X && curY === row04X && c10R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col09X && curY === row04X && c10R4VNo === 11) {
      alert("Already Added");
      //10th Col
      } else if (curX === col10X && curY === row04X && c10R4VNo === 1) {
        if (c10r4MT === "S") {  
          setRow4Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
                ]
              )
              setC10R4VNo(2);
        } else if (c10r4MT === "M") {      
         setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(2);
          setC10R4VNo(2);
       } else if (c10r4MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
        setC10R3VNo(2);
        setC10R4VNo(2);
       }
       
      } else if (curX === col10X && curY === row04X && c10R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col10X && curY === row04X && c10R4VNo === 3) {
        if (c10r4MT === "S") {  
          setRow4Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
              ]
            )
            setC10R4VNo(6);
        } else if (c10r4MT === "M") {      
         setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(6);
          setC10R4VNo(6);
       } else if (c10r4MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
  setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
       }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 4) {
        if (c10r4MT === "S") {  
          setRow4Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
               ]
             )
             setC10R4VNo(7);
         } else if (c10r4MT === "M") {      
          setRow3NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
             ]
           )
           setC10R3VNo(7);
           setC10R4VNo(7);
        } else if (c10r4MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
        }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 5) {  
        if (c10r4MT === "S") {  
          setRow4Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                ]
              )
              setC10R4VNo(8);
          } else if (c10r4MT === "M") {      
           setRow3NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(8);
            setC10R4VNo(8);
         } else if (c10r4MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
         }	
      } else if (curX === col10X && curY === row04X && c11R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col10X && curY === row04X && c11R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col10X && curY === row04X && c11R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col05X && curY === row04X && c11R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col10X && curY === row04X && c11R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col10X && curY === row04X && c11R4VNo === 11) {
      alert("Already Added");
      //11th Col
      } else if (curX === col11X && curY === row04X && c11R4VNo === 1) {

        if (c11r4MT === "S") {  
          setRow4Naked11(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
                  ]
                )
                setC11R4VNo(2);
          } else if (c11r4MT === "M") {      
           setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(2);
            setC11R4VNo(2);
         } else if (c11r4MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
         }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 3) {
        if (c11r4MT === "S") {  
          setRow4Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
                 ]
               )
               setC11R4VNo(6);
           } else if (c11r4MT === "M") {      
           setRow3NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(6);
             setC11R4VNo(6);
          } else if (c11r4MT === "L") {  
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
              ]
            )
      setC11R1VNo(6);
            setC11R2VNo(6);
            setC11R3VNo(6);
            setC11R4VNo(6);
          }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 4) {
        if (c11r4MT === "S") {  
          setRow4Naked11(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
                  ]
                )
                setC11R4VNo(7);
            } else if (c11r4MT === "M") {      
             setRow3NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(7);
              setC11R4VNo(7);
           } else if (c11r4MT === "L") {  
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(7);
            setC11R2VNo(7);
            setC11R3VNo(7);
            setC11R4VNo(7);
           }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 5) {  
        if (c11r4MT === "S") {  
          setRow4Naked11(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                  ]
                )
                setC11R4VNo(8);
            } else if (c11r4MT === "M") {      
             setRow3NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(8);
              setC11R4VNo(8);
           } else if (c11r4MT === "L") {  
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(8);
            setC11R2VNo(8);
            setC11R3VNo(8);
            setC11R4VNo(8);
           }	
      } else if (curX === col11X && curY === row04X && c11R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {
      alert("Already Added");
      //12th Col
      } else if (curX === col12X && curY === row04X && c12R4VNo === 1) {
        if (c12r4MT === "S") {  
          setRow4Naked12(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
                   ]
                 )
                 setC12R4VNo(2);
           } else if (c12r4MT === "M") {      
            setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(2);
             setC12R4VNo(2);
          } else if (c12r4MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
      setC12R1VNo(2);
            setC12R2VNo(2);
            setC12R3VNo(2);
            setC12R4VNo(2);
          }	
       
      } else if (curX === col12X && curY === row04X && c12R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 3) {
        if (c12r4MT === "S") {  
          setRow4Naked12(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
                  ]
                )
                setC12R4VNo(6);
            } else if (c12r4MT === "M") {      
             setRow3NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(6);
              setC12R4VNo(6);
           } else if (c12r4MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
      setC12R1VNo(6);
            setC12R2VNo(6);
            setC12R3VNo(6);
            setC12R4VNo(6);
           }	
      } else if (curX === col12X && curY === row04X && c12R4VNo === 4) {
        if (c12r4MT === "S") {  
          setRow4Naked12(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                  ]
                )
                setC12R4VNo(7);
            } else if (c12r4MT === "M") {      
             setRow3NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(7);
              setC12R4VNo(7);
           } else if (c12r4MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(7);
            setC12R2VNo(7);
            setC12R3VNo(7);
            setC12R4VNo(7);
           }	
      } else if (curX === col12X && curY === row04X && c12R4VNo === 5) {  
        if (c12r4MT === "S") {  
          setRow4Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                 ]
               )
               setC12R4VNo(8);
           } else if (c12r4MT === "M") {      
             setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(8);
             setC12R4VNo(8);
          } else if (c12r4MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(8);
            setC12R2VNo(8);
            setC12R3VNo(8);
            setC12R4VNo(8);
          }	
      } else if (curX === col12X && curY === row04X && c12R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {
      alert("Already Added");
      //13th Col
      } else if (curX === col13X && curY === row04X && c13R4VNo === 1) {
        if (c13r4MT === "S") {  
          setRow4Naked13(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
                   ]
                 )
                 setC13R4VNo(2);
           } else if (c13r4MT === "M") {      
             setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(2);
             setC13R4VNo(2);
          } else if (c13r4MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
      setC13R1VNo(2);
            setC13R2VNo(2);
            setC13R3VNo(2);
            setC13R4VNo(2);
          }	
       
      } else if (curX === col13X && curY === row04X && c13R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 3) {
        if (c13r4MT === "S") {  
          setRow4Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
                ]
              )
              setC13R4VNo(6);
          } else if (c13r4MT === "M") {      
             setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(6);
            setC13R4VNo(6);
         } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
    setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
         }	
      } else if (curX === col13X && curY === row04X && c13R4VNo === 4) {
        if (c13r4MT === "S") {  
          setRow4Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                 ]
               )
               setC13R4VNo(7);
           } else if (c13r4MT === "M") {      
              setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(7);
             setC13R4VNo(7);
          } else if (c13r4MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(7);
            setC13R2VNo(7);
            setC13R3VNo(7);
            setC13R4VNo(7);
          }	
      } else if (curX === col13X && curY === row04X && c13R4VNo === 5) {  
        if (c13r4MT === "S") {  
          setRow4Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                ]
              )
              setC13R4VNo(8);
          } else if (c13r4MT === "M") {      
             setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(8);
            setC13R4VNo(8);
         } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
         }	
      } else if (curX === col13X && curY === row04X && c13R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {
      alert("Already Added");
      //14tt Col
      } else if (curX === col14X && curY === row04X && c14R4VNo === 1) {

        if (c14r4MT === "S") {  
          setRow4Naked14(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
                   ]
                 )
                 setC14R4VNo(2);
           } else if (c14r4MT === "M") {      
                setRow3NakedM14(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
               ]
             )
             setC14R3VNo(2);
             setC14R4VNo(2);
          } else if (c14r4MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
      setC14R1VNo(2);
            setC14R2VNo(2);
            setC14R3VNo(2);
            setC14R4VNo(2);
          }	
      } else if (curX === col14X && curY === row04X && c14R4VNo === 2) {
      alert("Already Added");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 3) {
        if (c14r4MT === "S") {  
          setRow4Naked14(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
                 ]
               )
               setC14R4VNo(6);
           } else if (c14r4MT === "M") {      
            setRow3NakedM14(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
               ]
             )
             setC14R3VNo(6);
             setC14R4VNo(6);
          } else if (c14r4MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
      setC14R1VNo(6);
            setC14R2VNo(6);
            setC14R3VNo(6);
            setC14R4VNo(6);
          }	
      } else if (curX === col14X && curY === row04X && c14R4VNo === 4) {
        if (c14r4MT === "S") {  
          setRow4Naked14(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
                ]
              )
              setC14R4VNo(7);
          } else if (c14r4MT === "M") {      
           setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(7);
            setC14R4VNo(7);
         } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
         }	
      } else if (curX === col14X && curY === row04X && c14R4VNo === 5) {  
        if (c14r4MT === "S") {  
          setRow4Naked14(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                ]
              )
              setC14R4VNo(8);
          } else if (c14r4MT === "M") {      
           setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(8);
            setC14R4VNo(8);
         } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
         }	
      } else if (curX === col14X && curY === row04X && c14R4VNo === 6) {
      alert("Already Added");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 7) {
      alert("Already Added");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 8) {
      alert("Already Added");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 9) {
      alert("Already Added");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 10) {
      alert("Already Added");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
      alert("Already Added");
      }
    
}

const removeBackPanel = (e)=> {

    if (curX === col01X && curY === row01X && c1R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(1);
        } else if (c1r1MT === "M") {      
         setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
              setC1R1VNo(1);
          setC1R2VNo(1);
       } else if (c1r1MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(1);
        setC1R2VNo(1);
        setC1R3VNo(1);
        setC1R4VNo(1);
       }	
    } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {  
      if (c1r1MT === "S") {  
        setBaseNaked01(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(3);
       } else if (c1r1MT === "M") {      
         setBaseNakedM01(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(3);
         setC1R2VNo(3);
      } else if (c1r1MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setC1R3VNo(3);
        setC1R4VNo(3);
      }	
    } else if (curX === col01X && curY === row01X && c1R1VNo === 7) { 
      if (c1r1MT === "S") {  
        setBaseNaked01(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(4);
        } else if (c1r1MT === "M") {      
          setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(4);
          setC1R2VNo(4);
       } else if (c1r1MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(4);
        setC1R2VNo(4);
        setC1R3VNo(4);
        setC1R4VNo(4);
       }
    } else if (curX === col01X && curY === row01X && c1R1VNo === 8) { 
      if (c1r1MT === "S") {  
        setBaseNaked01(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
         } else if (c1r1MT === "M") {      
           setBaseNakedM01(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
           setC1R2VNo(5);
        } else if (c1r1MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }
    } else if (curX === col01X && curY === row01X && c1R1VNo === 9) { 
      if (c1r1MT === "S") {  
        setBaseNaked01(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
         } else if (c1r1MT === "M") {      
            setBaseNakedM01(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
           setC1R2VNo(5);
        } else if (c1r1MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }
    } else if (curX === col01X && curY === row01X && c1R1VNo === 10) { 
      if (c1r1MT === "S") {  
        setBaseNaked01(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
         } else if (c1r1MT === "M") {      
           setBaseNakedM01(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
           setC1R2VNo(5);
        } else if (c1r1MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }	
    } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {  
      if (c1r1MT === "S") {  
        setBaseNaked01(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
         } else if (c1r1MT === "M") {      
               setBaseNakedM01(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
               ]
             )
             setC1R1VNo(5);
           setC1R2VNo(5);
        } else if (c1r1MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }
  
    } else  if (curX === col02X && curY === row01X && c2R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(1);
        } else if (c2r1MT === "M") {      
                setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(1);
              setC2R2VNo(1);
       } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(1);
        setC2R2VNo(1);
        setC2R3VNo(1);
        setC2R4VNo(1);
       }	
    } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {  
      if (c2r1MT === "S") {  
        setBaseNaked02(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(3);
        } else if (c2r1MT === "M") {      
           setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(3);
          setC2R2VNo(3);
       } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setC2R3VNo(3);
        setC2R4VNo(3);
       }	
    } else if (curX === col02X && curY === row01X && c2R1VNo === 7) { 
      if (c2r1MT === "S") {  
        setBaseNaked02(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(4);
        } else if (c2r1MT === "M") {      
          setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(4);
        setC2R2VNo(4);
       } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(4);
        setC2R2VNo(4);
        setC2R3VNo(4);
        setC2R4VNo(4);
       }	
    } else if (curX === col02X && curY === row01X && c2R1VNo === 8) { 
      if (c2r1MT === "S") {  
        setBaseNaked02(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
               ]
             )
             setC2R1VNo(5);
         } else if (c2r1MT === "M") {      
           setBaseNakedM02(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
               ]
             )
             setC2R1VNo(5);
         setC2R2VNo(5);
        } else if (c2r1MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
        }	
    } else if (curX === col02X && curY === row01X && c2R1VNo === 9) { 
      if (c2r1MT === "S") {  
        setBaseNaked02(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
               ]
             )
             setC2R1VNo(5);
         } else if (c2r1MT === "M") {      
            setBaseNakedM02(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
               ]
             )
             setC2R1VNo(5);
           setC2R2VNo(5);
        } else if (c2r1MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
        }	
    } else if (curX === col02X && curY === row01X && c2R1VNo === 10) { 
      if (c2r1MT === "S") {  
        setBaseNaked02(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
               ]
             )
             setC2R1VNo(5);
         } else if (c2r1MT === "M") {      
             setBaseNakedM02(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
               ]
             )
             setC2R1VNo(5);
           setC2R2VNo(5);
        } else if (c2r1MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
        }
    } else if (curX === col02X && curY === row01X && c2R1VNo=== 11) {  
      if (c2r1MT === "S") {  
        setBaseNaked02(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(5);
        } else if (c2r1MT === "M") {      
           setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(5);
          setC2R2VNo(5);
       } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5);
       }	
    } else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(1);
         } else if (c3r1MT === "M") {      
            setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(1);
             setC3R2VNo(1);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
        }	
    } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {  
      if (c3r1MT === "S") {  
        setBaseNaked03(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(3);
         } else if (c3r1MT === "M") {      
           setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(3);
           setC3R2VNo(3);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
        }
    } else if (curX === col03X && curY === row01X && c3R1VNo === 7) { 
      if (c3r1MT === "S") {  
        setBaseNaked03(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(4);
         } else if (c3r1MT === "M") {      
           setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(4);
             setC3R2VNo(4);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
        }
    } else if (curX === col03X && curY === row01X && c3R1VNo === 8) { 
      if (c3r1MT === "S") {  
        setBaseNaked03(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(5);
         } else if (c3r1MT === "M") {      
           setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(5);
           setC3R2VNo(5);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
        }	
    } else if (curX === col03X && curY === row01X && c3R1VNo === 9) { 
      if (c3r1MT === "S") {  
        setBaseNaked03(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(5);
         } else if (c3r1MT === "M") {      
           setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(5);
             setC3R2VNo(5);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
        }	
    } else if (curX === col03X && curY === row01X && c3R1VNo === 10) { 
      if (c3r1MT === "S") {  
        setBaseNaked03(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
          } else if (c3r1MT === "M") {      
             setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
              setC3R2VNo(5);
         } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
         }	
    } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {  
      if (c3r1MT === "S") {  
        setBaseNaked03(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(5);
         } else if (c3r1MT === "M") {      
           setBaseNakedM03(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
               ]
             )
             setC3R1VNo(5);
             setC3R2VNo(5);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
        }	
    } else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(1);
         } else if (c4r1MT === "M") {      
            setBaseNakedM04(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(1);
             setC4R2VNo(1);
        } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
        }	
    } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {  
      if (c4r1MT === "S") {  
        setBaseNaked04(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(3);
         } else if (c4r1MT === "M") {      
            setBaseNakedM04(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
               ]
             )
             setC4R1VNo(3);
             setC4R2VNo(3);
        } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
        }	
    } else if (curX === col04X && curY === row01X && c4R1VNo === 7) { 
      if (c4r1MT === "S") {  
        setBaseNaked04(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(4);
          } else if (c4r1MT === "M") {      
             setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(4);
              setC4R2VNo(4);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
         }
    } else if (curX === col04X && curY === row01X && c4R1VNo === 8) { 
      if (c4r1MT === "S") {  
        setBaseNaked04(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
          } else if (c4r1MT === "M") {      
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
         }
    } else if (curX === col04X && curY === row01X && c4R1VNo === 9) { 
      if (c4r1MT === "S") {  
        setBaseNaked04(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
          } else if (c4r1MT === "M") {      
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
         }	
    } else if (curX === col04X && curY === row01X && c4R1VNo === 10) { 
      if (c4r1MT === "S") {  
        setBaseNaked04(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
          } else if (c4r1MT === "M") {      
             setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
         }	
    } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {  
      if (c4r1MT === "S") {  
        setBaseNaked04(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
          } else if (c4r1MT === "M") {      
            setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
         } else if (c4r1MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
         }	
      //5th col
    } else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
      if (c5r1MT === "S") {  
        setBaseNaked05(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(1);
          } else if (c5r1MT === "M") {      
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(1);
              setC5R2VNo(1);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
         }	
    } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {  
      if (c5r1MT === "S") {  
        setBaseNaked05(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(3);
          } else if (c5r1MT === "M") {      
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(3);
              setC5R2VNo(3);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC4R4VNo(3);
         }	
    } else if (curX === col05X && curY === row01X && c5R1VNo === 7) { 
      if (c5r1MT === "S") {  
        setBaseNaked05(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(4);
          } else if (c5r1MT === "M") {      
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(4);
              setC5R2VNo(4);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
         }	
    } else if (curX === col05X && curY === row01X && c5R1VNo === 8) { 
      if (c5r1MT === "S") {  
        setBaseNaked05(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
          } else if (c5r1MT === "M") {      
             setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
              setC5R2VNo(5);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
         }
    } else if (curX === col05X && curY === row01X && c5R1VNo === 9) { 
      if (c5r1MT === "S") {  
        setBaseNaked05(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
               ]
             )
             setC5R1VNo(5);
         } else if (c5r1MT === "M") {      
            setBaseNakedM05(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
               ]
             )
             setC5R1VNo(5);
             setC5R2VNo(5);
        } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }	
    } else if (curX === col05X && curY === row01X && c5R1VNo === 10) { 
      if (c5r1MT === "S") {  
        setBaseNaked05(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
          } else if (c5r1MT === "M") {      
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
              setC5R2VNo(5);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
         }
    } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {  
      if (c5r1MT === "S") {  
        setBaseNaked05(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
          } else if (c5r1MT === "M") {      
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
              setC5R2VNo(5);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
         }	
      //6th  col
    } else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
      if (c6r1MT === "S") {  
        setBaseNaked06(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(1);
          } else if (c6r1MT === "M") {      
            setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(1);
              setC6R2VNo(1);
         } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
         }
    } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {  
      if (c6r1MT === "S") {  
        setBaseNaked06(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
               ]
             )
             setC6R1VNo(3);
         } else if (c6r1MT === "M") {      
           setBaseNakedM06(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
               ]
             )
             setC6R1VNo(3);
             setC6R2VNo(3);
        } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
        }	
    } else if (curX === col06X && curY === row01X && c6R1VNo === 7) { 
      if (c6r1MT === "S") {  
        setBaseNaked06(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
               ]
             )
             setC6R1VNo(4);
         } else if (c6r1MT === "M") {      
            setBaseNakedM06(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
               ]
             )
             setC6R1VNo(4);
             setC6R2VNo(4);
        } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
        }
    } else if (curX === col06X && curY === row01X && c6R1VNo === 8) { 
      if (c6r1MT === "S") {  
        setBaseNaked06(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
               ]
             )
             setC6R1VNo(5);
         } else if (c6r1MT === "M") {      
            setBaseNakedM06(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
               ]
             )
             setC6R1VNo(5);
             setC6R2VNo(5);
        } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
        }
    } else if (curX === col06X && curY === row01X && c6R1VNo === 9) { 
      if (c6r1MT === "S") {  
        setBaseNaked06(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
        } else if (c6r1MT === "M") {      
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
       } else if (c6r1MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(5);
        setC6R2VNo(5);
        setC6R3VNo(5);
        setC6R4VNo(5);
       }	
    } else if (curX === col06X && curY === row01X && c6R1VNo === 10) { 
      if (c6r1MT === "S") {  
        setBaseNaked06(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(5);
       } else if (c6r1MT === "M") {      
           setBaseNakedM06(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(5);
           setC6R2VNo(5);
      } else if (c6r1MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(5);
        setC6R2VNo(5);
        setC6R3VNo(5);
        setC6R4VNo(5);
      }
    } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {  
      if (c6r1MT === "S") {  
        setBaseNaked06(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(5);
       } else if (c6r1MT === "M") {      
          setBaseNakedM06(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(5);
           setC6R2VNo(5);
      } else if (c6r1MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(5);
        setC6R2VNo(5);
        setC6R3VNo(5);
        setC6R4VNo(5);
      }	
      //7th col
    } else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
      if (c7r1MT === "S") {  
        setBaseNaked07(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(1);
         } else if (c7r1MT === "M") {      
             setBaseNakedM07(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(1);
             setC7R2VNo(1);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
        }	
    } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {  
      if (c7r1MT === "S") {  
        setBaseNaked07(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(3);
         } else if (c7r1MT === "M") {      
             setBaseNakedM07(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(3);
             setC7R2VNo(3);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
        }	
    } else if (curX === col07X && curY === row01X && c7R1VNo === 7) { 
      if (c7r1MT === "S") {  
        setBaseNaked07(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(4);
          } else if (c7r1MT === "M") {      
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(4);
              setC7R2VNo(4);
         } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
         }
    } else if (curX === col07X && curY === row01X && c7R1VNo === 8) { 
      if (c7r1MT === "S") {  
        setBaseNaked07(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(5);
         } else if (c7r1MT === "M") {      
             setBaseNakedM07(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(5);
             setC7R2VNo(5);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
    } else if (curX === col07X && curY === row01X && c7R1VNo === 9) { 
      if (c7r1MT === "S") {  
        setBaseNaked07(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
        } else if (c7r1MT === "M") {      
          setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
            setC7R2VNo(5);
       } else if (c7r1MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(5);
        setC7R2VNo(5);
        setC7R3VNo(5);
        setC7R4VNo(5);
       }	
    } else if (curX === col07X && curY === row01X && c7R1VNo === 10) { 
      if (c7r1MT === "S") {  
        setBaseNaked07(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(5);
         } else if (c7r1MT === "M") {      
            setBaseNakedM07(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(5);
             setC7R2VNo(5);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
    } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {  
      if (c7r1MT === "S") {  
        setBaseNaked07(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(5);
         } else if (c7r1MT === "M") {      
            setBaseNakedM07(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
               ]
             )
             setC7R1VNo(5);
             setC7R2VNo(5);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
      //8th col
    } else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
      if (c8r1MT === "S") {  
        setBaseNaked08(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(1);
         } else if (c8r1MT === "M") {      
            setBaseNakedM08(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(1);
             setC8R2VNo(1);
        } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
        }
    } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {  
      if (c8r1MT === "S") {  
        setBaseNaked08(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(3);
         } else if (c8r1MT === "M") {      
             setBaseNakedM08(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(3);
             setC8R2VNo(3);
        } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
        }	
    } else if (curX === col08X && curY === row01X && c8R1VNo === 7) { 
      if (c8r1MT === "S") {  
        setBaseNaked08(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
        } else if (c8r1MT === "M") {      
             setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
            setC8R2VNo(4);
       } else if (c8r1MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(4);
        setC8R2VNo(4);
        setC8R3VNo(4);
        setC8R4VNo(4);
       }	
    } else if (curX === col08X && curY === row01X && c8R1VNo === 8) { 
      if (c8r1MT === "S") {  
        setBaseNaked08(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(5);
        } else if (c8r1MT === "M") {      
             setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(5);
            setC8R2VNo(5);
       } else if (c8r1MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }	
    } else if (curX === col08X && curY === row01X && c8R1VNo === 9) { 
      if (c8r1MT === "S") {  
        setBaseNaked08(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(5);
        } else if (c8r1MT === "M") {      
             setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(5);
            setC8R2VNo(5);
       } else if (c8r1MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }
    } else if (curX === col08X && curY === row01X && c8R1VNo === 10) { 
      if (c8r1MT === "S") {  
        setBaseNaked08(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(5);
         } else if (c8r1MT === "M") {      
             setBaseNakedM08(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(5);
             setC8R2VNo(5);
        } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
        }	
    } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {  
      if (c8r1MT === "S") {  
        setBaseNaked08(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(5);
         } else if (c8r1MT === "M") {      
             setBaseNakedM08(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
               ]
             )
             setC8R1VNo(5);
             setC8R2VNo(5);
        } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
        }
      //9th col
    } else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
      if (c9r1MT === "S") {  
        setBaseNaked09(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(1);
          } else if (c9r1MT === "M") {      
             setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(1);
              setC9R2VNo(1);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
         }
    } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {  
      if (c9r1MT === "S") {  
        setBaseNaked09(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
               ]
             )
             setC9R1VNo(3);
         } else if (c9r1MT === "M") {      
           setBaseNakedM09(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
               ]
             )
             setC9R1VNo(3);
             setC9R2VNo(3);
        } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
        }
    } else if (curX === col09X && curY === row01X && c9R1VNo === 7) { 
      if (c9r1MT === "S") {  
        setBaseNaked09(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(4);
          } else if (c9r1MT === "M") {      
           setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(4);
              setC9R2VNo(4);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
         }
    } else if (curX === col09X && curY === row01X && c9R1VNo === 8) { 
      if (c9r1MT === "S") {  
        setBaseNaked09(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
          } else if (c9r1MT === "M") {      
           setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
         }	
    } else if (curX === col09X && curY === row01X && c9R1VNo === 9) { 
      if (c9r1MT === "S") {  
        setBaseNaked09(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
          } else if (c9r1MT === "M") {      
            setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
         }
    } else if (curX === col09X && curY === row01X && c9R1VNo === 10) { 
      if (c9r1MT === "S") {  
        setBaseNaked09(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
          } else if (c9r1MT === "M") {      
          setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
         }
    } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {  
      if (c9r1MT === "S") {  
        setBaseNaked09(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
          } else if (c9r1MT === "M") {      
           setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
         } else if (c9r1MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
         }
      // 10th col
    } else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
      if (c10r1MT === "S") {  
        setBaseNaked10(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(1);
          } else if (c10r1MT === "M") {      
           setBaseNakedM10(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(1);
              setC10R2VNo(1);
         } else if (c10r1MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
         }
    } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col10X && curY === row01X && c10R1VNo === 6) {  
      if (c10r1MT === "S") {  
        setBaseNaked10(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
               ]
             )
             setC10R1VNo(3);
         } else if (c10r1MT === "M") {      
         setBaseNakedM10(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
               ]
             )
             setC10R1VNo(3);
             setC10R2VNo(3);
        } else if (c10r1MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
        }	
    } else if (curX === col10X && curY === row01X && c10R1VNo === 7) { 
      if (c10r1MT === "S") {  
        setBaseNaked10(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(4);
        } else if (c10r1MT === "M") {      
        setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(4);
            setC10R2VNo(4);
       } else if (c10r1MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(4);
        setC10R2VNo(4);
        setC10R3VNo(4);
        setC10R4VNo(4);
       }	
    } else if (curX === col10X && curY === row01X && c10R1VNo === 8) { 
      if (c10r1MT === "S") {  
        setBaseNaked10(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(5);
       } else if (c10r1MT === "M") {      
      setBaseNakedM10(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(5);
           setC10R2VNo(5);
      } else if (c10r1MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
      }	
    } else if (curX === col10X && curY === row01X && c10R1VNo === 9) { 
      if (c10r1MT === "S") {  
        setBaseNaked10(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(5);
       } else if (c10r1MT === "M") {      
       setBaseNakedM10(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(5);
           setC10R2VNo(5);
      } else if (c10r1MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
      }	
    } else if (curX === col10X && curY === row01X && c10R1VNo === 10) { 
      if (c10r1MT === "S") {  
        setBaseNaked10(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
        } else if (c10r1MT === "M") {      
         setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
       } else if (c10r1MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
       }	
    } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {  
      if (c10r1MT === "S") {  
        setBaseNaked10(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
        } else if (c10r1MT === "M") {      
         setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
       } else if (c10r1MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
       }	
      
      //11th col
    } else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
      if (c11r1MT === "S") {  
        setBaseNaked11(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(1);
        } else if (c11r1MT === "M") {      
         setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(1);
            setC11R2VNo(1);
       } else if (c11r1MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
        setC11R3VNo(1);
        setC11R4VNo(1);
       }	
    } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {  
      if (c11r1MT === "S") {  
        setBaseNaked11(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(3);
        } else if (c11r1MT === "M") {      
         setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(3);
            setC11R2VNo(3);
       } else if (c11r1MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
       }	
    } else if (curX === col11X && curY === row01X && c11R1VNo === 7) { 
      if (c11r1MT === "S") {  
        setBaseNaked11(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(4);
        } else if (c11r1MT === "M") {      
         setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(4);
            setC11R2VNo(4);
       } else if (c11r1MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(4);
        setC11R2VNo(4);
        setC11R3VNo(4);
        setC11R4VNo(4);
       }
    } else if (curX === col11X && curY === row01X && c11R1VNo === 8) { 
      if (c11r1MT === "S") {  
        setBaseNaked11(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
        } else if (c11r1MT === "M") {      
         setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
            setC11R2VNo(5);
       } else if (c11r1MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
       }
    } else if (curX === col11X && curY === row01X && c11R1VNo === 9) { 
      if (c11r1MT === "S") {  
        setBaseNaked11(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
               ]
             )
             setC11R1VNo(5);
         } else if (c11r1MT === "M") {      
          setBaseNakedM11(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
               ]
             )
             setC11R1VNo(5);
             setC11R2VNo(5);
        } else if (c11r1MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
        }
    } else if (curX === col11X && curY === row01X && c11R1VNo === 10) { 
      if (c11r1MT === "S") {  
        setBaseNaked11(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(5);
          } else if (c11r1MT === "M") {      
           setBaseNakedM11(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(5);
              setC11R2VNo(5);
         } else if (c11r1MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
         }	
    } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {  
      if (c11r1MT === "S") {  
        setBaseNaked11(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
               ]
             )
             setC11R1VNo(5);
         } else if (c11r1MT === "M") {      
           setBaseNakedM11(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
               ]
             )
             setC11R1VNo(5);
             setC11R2VNo(5);
        } else if (c11r1MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
        }	
      //12th col
    } else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
      if (c12r1MT === "S") {  
        setBaseNaked12(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
               ]
             )
             setC12R1VNo(1);
         } else if (c12r1MT === "M") {      
          setBaseNakedM12(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
               ]
             )
             setC12R1VNo(1);
             setC12R2VNo(1);
        } else if (c12r1MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
        }	
    } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {  
      if (c12r1MT === "S") {  
        setBaseNaked12(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(3);
          } else if (c12r1MT === "M") {      
            setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(3);
              setC12R2VNo(3);
         } else if (c12r1MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
         }	
    } else if (curX === col12X && curY === row01X && c12R1VNo === 7) { 
      if (c12r1MT === "S") {  
        setBaseNaked12(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(4);
          } else if (c12r1MT === "M") {      
             setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(4);
              setC12R2VNo(4);
         } else if (c12r1MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
         }	
    } else if (curX === col12X && curY === row01X && c12R1VNo === 8) { 
      if (c12r1MT === "S") {  
        setBaseNaked12(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
          } else if (c12r1MT === "M") {      
             setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
         } else if (c12r1MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }	
    } else if (curX === col12X && curY === row01X && c12R1VNo === 9) { 
      if (c12r1MT === "S") {  
        setBaseNaked12(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
          } else if (c12r1MT === "M") {      
            setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
         } else if (c12r1MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }
    } else if (curX === col12X && curY === row01X && c12R1VNo === 10) { 
      if (c12r1MT === "S") {  
        setBaseNaked12(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
          } else if (c12r1MT === "M") {      
           setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
         } else if (c12r1MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }	
    } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {  
      if (c12r1MT === "S") {  
        setBaseNaked12(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
          } else if (c12r1MT === "M") {      
           setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
         } else if (c12r1MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }
      //13th col
    } else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
      if (c13r1MT === "S") {  
        setBaseNaked13(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(1);
         } else if (c13r1MT === "M") {      
           setBaseNakedM13(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(1);
             setC13R2VNo(1);
        } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
        }	
    } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {  
      if (c13r1MT === "S") {  
        setBaseNaked13(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(3);
         } else if (c13r1MT === "M") {      
          setBaseNakedM13(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(3);
             setC13R2VNo(3);
        } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
        }	
    } else if (curX === col03X && curY === row01X && c13R1VNo === 7) { 
      if (c13r1MT === "S") {  
        setBaseNaked13(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(4);
         } else if (c13r1MT === "M") {      
          setBaseNakedM13(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(4);
             setC13R2VNo(4);
        } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
        }	
    } else if (curX === col13X && curY === row01X && c13R1VNo === 8) { 
      if (c13r1MT === "S") {  
        setBaseNaked13(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(5);
         } else if (c13r1MT === "M") {      
          setBaseNakedM13(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(5);
             setC13R2VNo(5);
        } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
        }	
    } else if (curX === col13X && curY === row01X && c13R1VNo === 9) { 
      if (c13r1MT === "S") {  
        setBaseNaked13(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(5);
          } else if (c13r1MT === "M") {      
           setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(5);
              setC13R2VNo(5);
         } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
         }
    } else if (curX === col13X && curY === row01X && c13R1VNo === 10) { 
      if (c13r1MT === "S") {  
        setBaseNaked13(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(5);
         } else if (c13r1MT === "M") {      
        setBaseNakedM13(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
               ]
             )
             setC13R1VNo(5);
             setC13R2VNo(5);
        } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
        }
    } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {  
      if (c13r1MT === "S") {  
        setBaseNaked13(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(5);
          } else if (c13r1MT === "M") {      
         setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(5);
              setC13R2VNo(5);
         } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
         }
      // 14th col
    } else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
      alert("Already removed");
    } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
      if (c14r1MT === "S") {  
        setBaseNaked14(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(1);
          } else if (c14r1MT === "M") {      
         setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(1);
              setC14R2VNo(1);
         } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
         }
    } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
      alert("Already removed");
    } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
      alert("Already removed");  
    } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {
      alert("Already removed");
    } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {  
      if (c14r1MT === "S") {  
        setBaseNaked14(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(3);
          } else if (c14r1MT === "M") {      
         setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(3);
              setC14R2VNo(3);
         } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
         }
    } else if (curX === col14X && curY === row01X && c14R1VNo === 7) { 
      if (c14r1MT === "S") {  
        setBaseNaked14(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(4);
          } else if (c14r1MT === "M") {      
          setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(4);
              setC14R2VNo(4);
         } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
         }	
    } else if (curX === col14X && curY === row01X && c14R1VNo === 8) { 
      if (c14r1MT === "S") {  
        setBaseNaked14(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
          } else if (c14r1MT === "M") {      
           setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
         } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
         }
    } else if (curX === col14X && curY === row01X && c14R1VNo === 9) { 
      if (c14r1MT === "S") {  
        setBaseNaked14(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
          } else if (c14r1MT === "M") {      
            setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
         } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
         }
    } else if (curX === col14X && curY === row01X && c14R1VNo === 10) { 
      if (c14r1MT === "S") {  
        setBaseNaked14(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
          } else if (c14r1MT === "M") {      
           setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
         } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
         }
    } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {  
      if (c14r1MT === "S") {  
        setBaseNaked14(
               [
                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
               ]
             )
             setC14R1VNo(5);
         } else if (c14r1MT === "M") {      
          setBaseNakedM14(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
               ]
             )
             setC14R1VNo(5);
             setC14R2VNo(5);
        } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
        }
    } else   if (curX === col01X && curY === row02X && c1R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
      if (c1r2MT === "S") {  
        setRow2Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(1);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                ]
              )
                setC1R1VNo(1);
            setC1R2VNo(1);
            } else if (c1r2MT === "M" && c1r3MT === "M") {      
           setRow2NakedM01 (
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
               ]
             )
               setC1R2VNo(1);
           setC1R3VNo(1);
        } else if (c1r2MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
        }
    } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {  
      if (c1r2MT === "S") {  
        setRow2Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(3);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(3);
            setC1R2VNo(3);
            } else if (c1r2MT === "M" && c1r3MT === "M") {      
            setRow2NakedM01 (
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(3);
           setC1R3VNo(3);
        } else if (c1r2MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
        }	
    } else if (curX === col01X && curY === row02X && c1R2VNo === 7) { 
      if (c1r2MT === "S") {  
        setRow2Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(4);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(4);
            setC1R2VNo(4);
            } else if (c1r2MT === "M" && c1r3MT === "M") {     
           setRow2NakedM01 (
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(4);
           setC1R3VNo(4);
        } else if (c1r2MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
        }
    } else if (curX === col01X && curY === row02X && c1R2VNo === 8) { 
      if (c1r2MT === "S") {  
        setRow2Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(5);
            setC1R2VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M") {      
            setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            setC1R3VNo(5);
         } else if (c1r2MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
         }
    } else if (curX === col01X && curY === row02X && c1R2VNo === 9) { 
      if (c1r2MT === "S") {  
        setRow2Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(5);
            setC1R2VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M") {     
            setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            setC1R3VNo(5);
         } else if (c1r2MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
         }	
    } else if (curX === col01X && curY === row02X && c1R2VNo === 10) { 
      if (c1r2MT === "S") {  
        setRow2Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(5);
            setC1R2VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M") {      
           setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            setC1R3VNo(5);
         } else if (c1r2MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
         }
    } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {  
      if (c1r2MT === "S") {  
        setRow2Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(5);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(5);
            setC1R2VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M") {      
         setRow2NakedM01 (
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(5);
           setC1R3VNo(5);
        } else if (c1r2MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }
  
    } else  if (curX === col02X && curY === row02X && c2R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
      if (c2r2MT === "S") {  
        setRow2Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(1);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(1);
                setC2R2VNo(1);
            } else if (c2r2MT === "M" && c2r3MT === "M") {      
          setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(1);
            setC2R3VNo(1);
        } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
        }	
    } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {  
      if (c2r2MT === "S") {  
        setRow2Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(3);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(3);
            setC2R2VNo(3);
            } else if (c2r2MT === "M" && c2r3MT === "M") {        
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(3);
          setC2R3VNo(3);
         } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
         }
    } else if (curX === col02X && curY === row02X && c2R2VNo === 7) { 
      if (c2r2MT === "S") {  
        setRow2Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(4);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(4);
          setC2R2VNo(4);
            } else if (c2r2MT === "M" && c2r3MT === "M") {     
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(4);
        setC2R3VNo(4);
         } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
         }	
    } else if (curX === col02X && curY === row02X && c2R2VNo === 8) { 
      if (c2r2MT === "S") {  
        setRow2Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
          setC2R2VNo(5);
            } else if (c2r2MT === "M" && c2r3MT === "M") {      
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(5);
        setC2R3VNo(5);
         } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
         }	
    } else if (curX === col02X && curY === row02X && c2R2VNo === 9) { 
      if (c2r2MT === "S") {  
        setRow2Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
            setC2R2VNo(5);
            } else if (c2r2MT === "M" && c2r3MT === "M") {      
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(5);
        setC2R3VNo(5);
         } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
         }
    } else if (curX === col02X && curY === row02X && c2R2VNo === 10) { 
      if (c2r2MT === "S") {  
        setRow2Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
            setC2R2VNo(5);
            } else if (c2r2MT === "M" && c2r3MT === "M") {       
             setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
            setC2R3VNo(5);
         } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
         }
    } else if (curX === col02X && curY === row02X && c2R2VNo=== 11) {  
      if (c2r2MT === "S") {  
        setRow2Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(5);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
            setC2R2VNo(5);
            } else if (c2r2MT === "M" && c2r3MT === "M") {       
           setRow2NakedM02(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(5);
           setC2R3VNo(5);
        } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
        }	
    } else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
      if (c3r2MT === "S") {  
        setRow2Naked03(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(1);
          } else if (c3r1MT === "M" && c3r2MT === "M") { 
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(1);
            setC3R2VNo(1);
          } else if (c3r2MT === "M" && c3r3MT === "M") {       
          setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(1);
          setC3R3VNo(1);
       } else if (c3r2MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(1);
        setC3R2VNo(1);
        setC3R3VNo(1);
        setC3R4VNo(1);
       }	
    } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {  
      if (c3r2MT === "S") {  
        setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(3);
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(3);
            setC3R2VNo(3);
            } else if (c3r2MT === "M" && c3r3MT === "M") {        
             setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(3);
            setC3R3VNo(3);
         } else if (c3r2MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
         }
    } else if (curX === col03X && curY === row02X && c3R2VNo === 7) { 
      if (c3r2MT === "S") {  
        setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(4);
            } else if (c3r1MT === "M" && c3r2MT === "M") {     
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(4);
            setC3R2VNo(4);
          } else if (c3r2MT === "M" && c3r3MT === "M") { 
            setRow2NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(4);
        setC3R3VNo(4);
         } else if (c3r2MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
         }
    } else if (curX === col03X && curY === row02X && c3R2VNo === 8) { 
      if (c3r2MT === "S") {  
        setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
            setC3R2VNo(5);
            } else if (c3r2MT === "M" && c3r3MT === "M") {        
            setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            setC3R3VNo(5);
         } else if (c3r2MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
         }
    } else if (curX === col03X && curY === row02X && c3R2VNo === 9) { 
      if (c3r2MT === "S") {  
        setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
            setC3R2VNo(5);
            } else if (c3r2MT === "M" && c3r3MT === "M") {       
             setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
              setC3R3VNo(5);
         } else if (c3r2MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
         }
    } else if (curX === col03X && curY === row02X && c3R2VNo === 10) { 
      if (c3r2MT === "S") {  
        setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
            setC3R2VNo(5);
            } else if (c3r2MT === "M" && c3r3MT === "M") {       
             setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
              setC3R3VNo(5);
         } else if (c3r2MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
         }
    } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {  
      if (c3r2MT === "S") {  
        setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
            setC3R2VNo(5);
            } else if (c3r2MT === "M" && c3r3MT === "M") {       
             setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
              setC3R3VNo(5);
         } else if (c3r2MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
         }
    } else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
      if (c4r2MT === "S") {  
        setRow2Naked04(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(1);
          } else if (c4r2MT === "M") {      
             setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(1);
              setC4R3VNo(1);
         } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
         }
    } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {  
      if (c4r2MT === "S") {  
        setRow2Naked04(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(3);
            } else if (c4r1MT === "M" && c4r2MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(3);
              setC4R2VNo(3);
            } else if (c4r2MT === "M" && c4r3MT === "M") {        
             setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(3);
              setC4R3VNo(3);
         } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
         }
    } else if (curX === col04X && curY === row02X && c4R2VNo === 7) { 
      if (c4r2MT === "S") {  
        setRow2Naked04(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(4);
            } else if (c4r1MT === "M" && c4r2MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(4);
              setC4R2VNo(4);
            } else if (c4r2MT === "M" && c4r3MT === "M") {        
            setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(4);
              setC4R3VNo(4);
         } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
         }
    } else if (curX === col04X && curY === row02X && c4R2VNo === 8) { 
      if (c4r2MT === "S") {  
        setRow2Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
            } else if (c4r1MT === "M" && c4r2MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
            } else if (c4r2MT === "M" && c4r3MT === "M") {       
             setRow2NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
             setC4R3VNo(5);
        } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
        }	
    } else if (curX === col04X && curY === row02X && c4R2VNo === 9) { 
      if (c4r2MT === "S") {  
        setRow2Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
            } else if (c4r1MT === "M" && c4r2MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
            } else if (c4r2MT === "M" && c4r3MT === "M") {       
             setRow2NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
             setC4R3VNo(5);
        } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
        }
    } else if (curX === col04X && curY === row02X && c4R2VNo === 10) { 
      if (c4r2MT === "S") {  
        setRow2Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
            } else if (c4r1MT === "M" && c4r2MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
            } else if (c4r2MT === "M" && c4r3MT === "M") {      
            setRow2NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
             setC4R3VNo(5);
        } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
        }	
    } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {  
      if (c4r2MT === "S") {  
        setRow2Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
            } else if (c4r1MT === "M" && c4r2MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
            } else if (c4r2MT === "M" && c4r3MT === "M") {        
              setRow2NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
               ]
             )
             setC4R2VNo(5);
             setC4R3VNo(5);
        } else if (c4r2MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
        }
      //5th col
    } else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
      if (c5r2MT === "S") {  
        setRow2Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
               ]
             )
             setC5R2VNo(1);
            } else if (c5r1MT === "M" && c5r2MT === "M") { 
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(1);
              setC5R2VNo(1);
            } else if (c5r2MT === "M" && c5r3MT === "M") {   
              setRow2NakedM05(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
               ]
             )
             setC5R2VNo(1);
             setC5R3VNo(1);
        } else if (c5r2MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
        }
    } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {  
      if (c5r2MT === "S") {  
        setRow2Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
               ]
             )
             setC5R2VNo(3);
            } else if (c5r1MT === "M" && c5r2MT === "M") { 
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(3);
              setC5R2VNo(3);
            } else if (c5r2MT === "M" && c5r3MT === "M") {      
              setRow2NakedM05(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
               ]
             )
             setC5R2VNo(3);
             setC5R3VNo(3);
        } else if (c5r2MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC4R4VNo(3);
        }
    } else if (curX === col05X && curY === row02X && c5R2VNo === 7) { 
      if (c5r2MT === "S") {  
        setRow2Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(4);
          } else if (c5r1MT === "M" && c5r2MT === "M") { 
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(4);
            setC5R2VNo(4);
          } else if (c5r2MT === "M" && c5r3MT === "M") {      
           setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(4);
            setC5R3VNo(4);
       } else if (c5r2MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(4);
        setC5R2VNo(4);
        setC5R3VNo(4);
        setC5R4VNo(4);
       }
    } else if (curX === col05X && curY === row02X && c5R2VNo === 8) { 
      if (c5r2MT === "S") {  
        setRow2Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(5);
          } else if (c5r1MT === "M" && c5r2MT === "M") { 
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(5);
            setC5R2VNo(5);
          } else if (c5r2MT === "M" && c5r3MT === "M") {      
           setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(5);
            setC5R3VNo(5);
       } else if (c5r2MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(5);
        setC5R2VNo(5);
        setC5R3VNo(5);
        setC5R4VNo(5);
       }
    } else if (curX === col05X && curY === row02X && c5R2VNo === 9) { 
      if (c5r2MT === "S") {  
        setRow2Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
               ]
             )
             setC5R2VNo(5);
            } else if (c5r1MT === "M" && c5r2MT === "M") { 
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
              setC5R2VNo(5);
            } else if (c5r2MT === "M" && c5r3MT === "M") {      
            setRow2NakedM05(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
               ]
             )
             setC5R2VNo(5);
             setC5R3VNo(5);
        } else if (c5r2MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }
    } else if (curX === col05X && curY === row02X && c5R2VNo === 10) { 
      if (c5r2MT === "S") {  
        setRow2Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(5);
          } else if (c5r1MT === "M" && c5r2MT === "M") { 
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(5);
            setC5R2VNo(5);
          } else if (c5r2MT === "M" && c5r3MT === "M") {      
           setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(5);
            setC5R3VNo(5);
       } else if (c5r2MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(5);
        setC5R2VNo(5);
        setC5R3VNo(5);
        setC5R4VNo(5);
       }
    } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {  
      if (c5r2MT === "S") {  
        setRow2Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(5);
          } else if (c5r1MT === "M" && c5r2MT === "M") { 
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(5);
            setC5R2VNo(5);
          } else if (c5r2MT === "M" && c5r3MT === "M") {      
            setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(5);
            setC5R3VNo(5);
       } else if (c5r2MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(5);
        setC5R2VNo(5);
        setC5R3VNo(5);
        setC5R4VNo(5);
       }	
      //6th  col
    } else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
      if (c6r2MT === "S") {  
        setRow2Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(1);
          } else if (c6r1MT === "M" && c6r2MT === "M") { 
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(1);
            setC6R2VNo(1);
          } else if (c6r2MT === "M" && c6r3MT === "M") {       
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(1);
            setC6R3VNo(1);
       } else if (c6r2MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(1);
        setC6R2VNo(1);
        setC6R3VNo(1);
        setC6R4VNo(1);
       }
    } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {  
      if (c6r2MT === "S") {  
        setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(3);
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(3);
              setC6R2VNo(3);
            } else if (c6r2MT === "M" && c6r3MT === "M") {       
            setRow2NakedM06(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(3);
             setC6R3VNo(3);
        } else if (c6r2MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
        }
    } else if (curX === col06X && curY === row02X && c6R2VNo === 7) { 
      if (c6r2MT === "S") {  
        setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(4);
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(4);
              setC6R2VNo(4);
            } else if (c6r2MT === "M" && c6r3MT === "M") {       
            setRow2NakedM06(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(4);
             setC6R3VNo(4);
        } else if (c6r2MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
        }
    } else if (curX === col06X && curY === row02X && c6R2VNo === 8) { 
      if (c6r2MT === "S") {  
        setRow2Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(5);
          } else if (c6r1MT === "M" && c6r2MT === "M") { 
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
          } else if (c6r2MT === "M" && c6r3MT === "M") {       
           setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(5);
            setC6R3VNo(5);
       } else if (c6r2MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(5);
        setC6R2VNo(5);
        setC6R3VNo(5);
        setC6R4VNo(5);
       }
    } else if (curX === col06X && curY === row02X && c6R2VNo === 9) { 
      if (c6r2MT === "S") {  
        setRow2Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(5);
          } else if (c6r1MT === "M" && c6r2MT === "M") { 
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
          } else if (c6r2MT === "M" && c6r3MT === "M") {       
           setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(5);
            setC6R3VNo(5);
       } else if (c6r2MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(5);
        setC6R2VNo(5);
        setC6R3VNo(5);
        setC6R4VNo(5);
       }
    } else if (curX === col06X && curY === row02X && c6R2VNo === 10) { 
      if (c6r2MT === "S") {  
        setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(5);
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(5);
              setC6R2VNo(5);
            } else if (c6r2MT === "M" && c6r3MT === "M") {       
            setRow2NakedM06(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(5);
             setC6R3VNo(5);
        } else if (c6r2MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
        }
    } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {  
      if (c6r2MT === "S") {  
        setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(5);
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(5);
              setC6R2VNo(5);
            } else if (c6r2MT === "M" && c6r3MT === "M") {       
            setRow2NakedM06(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(5);
             setC6R3VNo(5);
        } else if (c6r2MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
        }
      //7th col
    } else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
      if (c7r2MT === "S") {  
        setRow2Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(1);
            } else if (c7r1MT === "M" && c7r2MT === "M") { 
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(1);
              setC7R2VNo(1);
            } else if (c7r2MT === "M" && c7r3MT === "M") {       
             setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(1);
              setC7R3VNo(1);
         } else if (c7r2MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
         }
    } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {  
      if (c7r2MT === "S") {  
        setRow2Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
               ]
             )
             setC7R2VNo(3);
            } else if (c7r1MT === "M" && c7r2MT === "M") { 
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(3);
              setC7R2VNo(3);
            } else if (c7r2MT === "M" && c7r3MT === "M") {      
           setRow2NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
               ]
             )
             setC7R2VNo(3);
             setC7R3VNo(3);
        } else if (c7r2MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
        }
    } else if (curX === col07X && curY === row02X && c7R2VNo === 7) { 
      if (c7r2MT === "S") {  
        setRow2Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(4);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
            setC7R2VNo(4);
          } else if (c7r2MT === "M" && c7r3MT === "M") {       
         setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(4);
            setC7R3VNo(4);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(4);
        setC7R2VNo(4);
        setC7R3VNo(4);
        setC7R4VNo(4);
       }	
    } else if (curX === col07X && curY === row02X && c7R2VNo === 8) { 
      if (c7r2MT === "S") {  
        setRow2Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
            setC7R2VNo(5);
          } else if (c7r2MT === "M" && c7r3MT === "M") {      
         setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
            setC7R3VNo(5);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(5);
        setC7R2VNo(5);
        setC7R3VNo(5);
        setC7R4VNo(5);
       }
    } else if (curX === col07X && curY === row02X && c7R2VNo === 9) { 
      if (c7r2MT === "S") {  
        setRow2Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
            setC7R2VNo(5);
          } else if (c7r2MT === "M" && c7r3MT === "M") {    
          setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
            setC7R3VNo(5);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(5);
        setC7R2VNo(5);
        setC7R3VNo(5);
        setC7R4VNo(5);
       }
    } else if (curX === col07X && curY === row02X && c7R2VNo === 10) { 
      if (c7r2MT === "S") {  
        setRow2Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
            setC7R2VNo(5);
          } else if (c7r2MT === "M" && c7r3MT === "M") {       
         setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
            setC7R3VNo(5);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(5);
        setC7R2VNo(5);
        setC7R3VNo(5);
        setC7R4VNo(5);
       }
    } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {  
      if (c7r2MT === "S") {  
        setRow2Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
            setC7R2VNo(5);
          } else if (c7r2MT === "M" && c7r3MT === "M") {       
         setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
            setC7R3VNo(5);
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(5);
        setC7R2VNo(5);
        setC7R3VNo(5);
        setC7R4VNo(5);
       }	
      //8th col
    } else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
      if (c8r2MT === "S") {  
        setRow2Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(1);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(1);
              setC8R2VNo(1);
            } else if (c8r2MT === "M" && c8r3MT === "M") {     
          setRow2NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(1);
             setC8R3VNo(1);
        } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
        }
    } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {  
      if (c8r2MT === "S") {  
        setRow2Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(3);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(3);
              setC8R2VNo(3);
            } else if (c8r2MT === "M" && c8r3MT === "M") {    
         setRow2NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(3);
             setC8R3VNo(3);
        } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
        }
    } else if (curX === col08X && curY === row02X && c8R2VNo === 7) { 
      if (c8r2MT === "S") {  
        setRow2Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(4);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(4);
              setC8R2VNo(4);
            } else if (c8r2MT === "M" && c8r3MT === "M") {     
           setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(4);
              setC8R3VNo(4);
         } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
         }
    } else if (curX === col08X && curY === row02X && c8R2VNo === 8) { 
      if (c8r2MT === "S") {  
        setRow2Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(5);
              setC8R2VNo(5);
            } else if (c8r2MT === "M" && c8r3MT === "M") {     
           setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC8R3VNo(5);
         } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
         }
    } else if (curX === col08X && curY === row02X && c8R2VNo === 9) { 
      if (c8r2MT === "S") {  
        setRow2Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(5);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(5);
              setC8R2VNo(5);
            } else if (c8r2MT === "M" && c8r3MT === "M") {      
          setRow2NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(5);
             setC8R3VNo(5);
        } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
        }	
    } else if (curX === col08X && curY === row02X && c8R2VNo === 10) { 
      if (c8r2MT === "S") {  
        setRow2Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(5);
              setC8R2VNo(5);
            } else if (c8r2MT === "M" && c8r3MT === "M") {      
           setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC8R3VNo(5);
         } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
         }	
    } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {  
      if (c8r2MT === "S") {  
        setRow2Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(5);
              setC8R2VNo(5);
            } else if (c8r2MT === "M" && c8r3MT === "M") {      
           setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC8R3VNo(5);
         } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
         }	
      //9th col
    } else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
      if (c9r2MT === "S") {  
        setRow2Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(1);
            } else if (c9r1MT === "M" && c9r2MT === "M") { 
              setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(1);
              setC9R2VNo(1);
            } else if (c9r2MT === "M" && c9r3MT === "M") {      
          setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(1);
             setC9R3VNo(1);
        } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
        }
    } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {  
      if (c9r2MT === "S") {  
        setRow2Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(3);
            } else if (c9r1MT === "M" && c9r2MT === "M") { 
              setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(3);
              setC9R2VNo(3);
            } else if (c9r2MT === "M" && c9r3MT === "M") {      
           setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(3);
             setC9R3VNo(3);
        } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
        }
    } else if (curX === col09X && curY === row02X && c9R2VNo === 7) { 
      if (c9r2MT === "S") {  
        setRow2Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(4);
          } else if (c9r1MT === "M" && c9r2MT === "M") { 
            setBaseNakedM09(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(4);
            setC9R2VNo(4);
          } else if (c9r2MT === "M" && c9r3MT === "M") {        
          setRow2NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(4);
            setC9R3VNo(4);
       } else if (c9r2MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(4);
        setC9R2VNo(4);
        setC9R3VNo(4);
        setC9R4VNo(4);
       }
    } else if (curX === col09X && curY === row02X && c9R2VNo === 8) { 
      if (c9r2MT === "S") {  
        setRow2Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(5);
            } else if (c9r1MT === "M" && c9r2MT === "M") { 
              setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M") {      
           setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(5);
             setC9R3VNo(5);
        } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }
    } else if (curX === col09X && curY === row02X && c9R2VNo === 9) { 
      if (c9r2MT === "S") {  
        setRow2Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(5);
            } else if (c9r1MT === "M" && c9r2MT === "M") { 
              setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M") {       
           setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(5);
             setC9R3VNo(5);
        } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }	
    } else if (curX === col09X && curY === row02X && c9R2VNo === 10) { 
      if (c9r2MT === "S") {  
        setRow2Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(5);
            } else if (c9r1MT === "M" && c9r2MT === "M") { 
              setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M") {      
           setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(5);
             setC9R3VNo(5);
        } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }
    } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {  
      if (c9r2MT === "S") {  
        setRow2Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(5);
            } else if (c9r1MT === "M" && c9r2MT === "M") { 
              setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M") {       
            setRow2NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(5);
             setC9R3VNo(5);
        } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }
      // 10th col
    } else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
      if (c10r2MT === "S") {  
        setRow2Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(1);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(1);
            setC10R2VNo(1);
          } else if (c10r2MT === "M" && c10r3MT === "M") {       
          setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(1);
            setC10R3VNo(1);
       } else if (c10r2MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(1);
        setC10R2VNo(1);
        setC10R3VNo(1);
        setC10R4VNo(1);
       }
    } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col10X && curY === row02X && c10R2VNo === 6) {  
      if (c10r2MT === "S") {  
        setRow2Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(3);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(3);
            setC10R2VNo(3);
          } else if (c10r2MT === "M" && c10r3MT === "M") {     
          setRow2NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(3);
           setC10R3VNo(3);
      } else if (c10r2MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setC10R3VNo(3);
        setC10R4VNo(3);
      }
    } else if (curX === col10X && curY === row02X && c10R2VNo === 7) { 
      if (c10r2MT === "S") {  
        setRow2Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(4);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(4);
            setC10R2VNo(4);
          } else if (c10r2MT === "M" && c10r3MT === "M") {     
          setRow2NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(4);
           setC10R3VNo(4);
      } else if (c10r2MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(4);
        setC10R2VNo(4);
        setC10R3VNo(4);
        setC10R4VNo(4);
      }	
    } else if (curX === col10X && curY === row02X && c10R2VNo === 8) { 
      if (c10r2MT === "S") {  
        setRow2Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(5);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
          } else if (c10r2MT === "M" && c10r3MT === "M") {      
          setRow2NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(5);
           setC10R3VNo(5);
      } else if (c10r2MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
      }
    } else if (curX === col10X && curY === row02X && c10R2VNo === 9) { 
      if (c10r2MT === "S") {  
        setRow2Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(5);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
          } else if (c10r2MT === "M" && c10r3MT === "M") {      
           setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(5);
            setC10R3VNo(5);
       } else if (c10r2MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
       }	
    } else if (curX === col10X && curY === row02X && c10R2VNo === 10) { 
      if (c10r2MT === "S") {  
        setRow2Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
               ]
             )
             setC10R2VNo(5);
            } else if (c10r1MT === "M" && c10r2MT === "M") { 
              setBaseNakedM10(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(5);
              setC10R2VNo(5);
            } else if (c10r2MT === "M" && c10r3MT === "M") {      
            setRow2NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
               ]
             )
             setC10R2VNo(5);
             setC10R3VNo(5);
        } else if (c10r2MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
        }
    } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {  
      if (c10r2MT === "S") {  
        setRow2Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(5);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
          } else if (c10r2MT === "M" && c10r3MT === "M") {       
           setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(5);
            setC10R3VNo(5);
       } else if (c10r2MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
       }
      //11th col
    } else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
      if (c11r2MT === "S") {  
        setRow2Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(1);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(1);
            setC11R2VNo(1);
          } else if (c11r2MT === "M" && c11r3MT === "M") {       
           setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(1);
            setC11R3VNo(1);
       } else if (c11r2MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
        setC11R3VNo(1);
        setC11R4VNo(1);
       }
    } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {  
      if (c11r2MT === "S") {  
        setRow2Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(3);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(3);
            setC11R2VNo(3);
          } else if (c11r2MT === "M" && c11r3MT === "M") {         
           setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(3);
            setC11R3VNo(3);
       } else if (c11r2MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
       }	
    } else if (curX === col11X && curY === row02X && c11R2VNo === 7) { 
      if (c11r2MT === "S") {  
        setRow2Naked11(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
               ]
             )
             setC11R2VNo(4);
            } else if (c11r1MT === "M" && c11r2MT === "M") { 
              setBaseNakedM11(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(4);
              setC11R2VNo(4);
            } else if (c11r2MT === "M" && c11r3MT === "M") {       
            setRow2NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
               ]
             )
             setC11R2VNo(4);
             setC11R3VNo(4);
        } else if (c11r2MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
        }
    } else if (curX === col11X && curY === row02X && c11R2VNo === 8) { 
      if (c11r2MT === "S") {  
        setRow2Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
            setC11R2VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M") {        
           setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
            setC11R3VNo(5);
       } else if (c11r2MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
       }	
    } else if (curX === col11X && curY === row02X && c11R2VNo === 9) { 
      if (c11r2MT === "S") {  
        setRow2Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
            setC11R2VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M") {         
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
            setC11R3VNo(5);
       } else if (c11r2MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
       }
    } else if (curX === col11X && curY === row02X && c11R2VNo === 10) { 
      if (c11r2MT === "S") {  
        setRow2Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
             ]
           )
           setC11R2VNo(5);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
            setC11R2VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M") {        
          setRow2NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
             ]
           )
           setC11R2VNo(5);
           setC11R3VNo(5);
      } else if (c11r2MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
      }	
    } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {  
      if (c11r2MT === "S") {  
        setRow2Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
            setC11R2VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M") {         
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
            setC11R3VNo(5);
       } else if (c11r2MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
       }
      //12th col
    } else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
      if (c12r2MT === "S") {  
        setRow2Naked12(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(1);
          } else if (c12r1MT === "M" && c12r2MT === "M") { 
            setBaseNakedM12(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(1);
            setC12R2VNo(1);
          } else if (c12r2MT === "M" && c12r3MT === "M") {    
           setRow2NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(1);
            setC12R3VNo(1);
       } else if (c12r2MT === "L") {  
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
        setC12R2VNo(1);
        setC12R3VNo(1);
        setC12R4VNo(1);
       }
    } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {  
      if (c12r2MT === "S") {  
        setRow2Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(3);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(3);
              setC12R2VNo(3);
            } else if (c12r2MT === "M" && c12r3MT === "M") {     
            setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(3);
             setC12R3VNo(3);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
        }
    } else if (curX === col12X && curY === row02X && c12R2VNo === 7) { 
      if (c12r2MT === "S") {  
        setRow2Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(4);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(4);
              setC12R2VNo(4);
            } else if (c12r2MT === "M" && c12r3MT === "M") {      
            setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(4);
             setC12R3VNo(4);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
        }	
    } else if (curX === col12X && curY === row02X && c12R2VNo === 8) { 
      if (c12r2MT === "S") {  
        setRow2Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M") {     
           setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
             setC12R3VNo(5);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
        }	
    } else if (curX === col12X && curY === row02X && c12R2VNo === 9) { 
      if (c12r2MT === "S") {  
        setRow2Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M") {      
           setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
             setC12R3VNo(5);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
        }
    } else if (curX === col12X && curY === row02X && c12R2VNo === 10) { 
      if (c12r2MT === "S") {  
        setRow2Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M") {      
           setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
             setC12R3VNo(5);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
        }
    } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {  
      if (c12r2MT === "S") {  
        setRow2Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M") {      
            setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
             setC12R2VNo(5);
             setC12R3VNo(5);
        } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
        }
      //13th col
    } else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
      if (c13r2MT === "S") {  
        setRow2Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
               ]
             )
             setC13R2VNo(1);
            } else if (c13r1MT === "M" && c13r2MT === "M") { 
              setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(1);
              setC13R2VNo(1);
            } else if (c13r2MT === "M" && c13r3MT === "M") {       
             setRow2NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
               ]
             )
             setC13R2VNo(1);
             setC13R3VNo(1);
        } else if (c13r2MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
        }
    } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {  
      if (c13r2MT === "S") {  
        setRow2Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(3);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(3);
            setC13R2VNo(3);
          } else if (c13r2MT === "M" && c13r3MT === "M") {       
            setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(3);
            setC13R3VNo(3);
       } else if (c13r2MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
        setC13R3VNo(3);
        setC13R4VNo(3);
       }	
    } else if (curX === col03X && curY === row02X && c13R2VNo === 7) { 
      if (c13r2MT === "S") {  
        setRow2Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(4);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(4);
            setC13R2VNo(4);
          } else if (c13r2MT === "M" && c13r3MT === "M") {      
            setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(4);
            setC13R3VNo(4);
       } else if (c13r2MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(4);
        setC13R2VNo(4);
        setC13R3VNo(4);
        setC13R4VNo(4);
       }
    } else if (curX === col13X && curY === row02X && c13R2VNo === 8) { 
      if (c13r2MT === "S") {  
        setRow2Naked13(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R2VNo(5);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(5);
            setC13R2VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M") {       
           setRow2NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R2VNo(5);
           setC13R3VNo(5);
      } else if (c13r2MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
      }
    } else if (curX === col13X && curY === row02X && c13R2VNo === 9) { 
      if (c13r2MT === "S") {  
        setRow2Naked13(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R2VNo(5);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(5);
            setC13R2VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M") {    
          setRow2NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R2VNo(5);
           setC13R3VNo(5);
      } else if (c13r2MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
      }	
    } else if (curX === col13X && curY === row02X && c13R2VNo === 10) { 
      if (c13r2MT === "S") {  
        setRow2Naked13(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R2VNo(5);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(5);
            setC13R2VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M") {      
         setRow2NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R2VNo(5);
           setC13R3VNo(5);
      } else if (c13r2MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
      }	
    } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {  
      if (c13r2MT === "S") {  
        setRow2Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(5);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(5);
            setC13R2VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M") {      
           setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(5);
            setC13R3VNo(5);
       } else if (c13r2MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
       }	
      // 14th col
    } else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
      alert("Already removed");
    } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
      if (c14r2MT === "S") {  
        setRow2Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(1);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(1);
            setC14R2VNo(1);
          } else if (c14r2MT === "M" && c14r3MT === "M") {     
          setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(1);
            setC14R3VNo(1);
       } else if (c14r2MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
        setC14R3VNo(1);
        setC14R4VNo(1);
       }	
    } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
      alert("Already removed");
    } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
      alert("Already removed");  
    } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {
      alert("Already removed");
    } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {  
      if (c14r2MT === "S") {  
        setRow2Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(3);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(3);
            setC14R2VNo(3);
          } else if (c14r2MT === "M" && c14r3MT === "M") {     
          setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(3);
            setC14R3VNo(3);
       } else if (c14r2MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setC14R3VNo(3);
        setC14R4VNo(3);
       }	
    } else if (curX === col14X && curY === row02X && c14R2VNo === 7) { 
      if (c14r2MT === "S") {  
        setRow2Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
             ]
           )
           setC14R2VNo(4);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(4);
            setC14R2VNo(4);
          } else if (c14r2MT === "M" && c14r3MT === "M") {      
        setRow2NakedM14(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
             ]
           )
           setC14R2VNo(4);
           setC14R3VNo(4);
      } else if (c14r2MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(4);
        setC14R2VNo(4);
        setC14R3VNo(4);
        setC14R4VNo(4);
      }	
    } else if (curX === col14X && curY === row02X && c14R2VNo === 8) { 
      if (c14r2MT === "S") {  
        setRow2Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(5);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(5);
            setC14R2VNo(5);
          } else if (c14r2MT === "M" && c14r3MT === "M") {    
          setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(5);
            setC14R3VNo(5);
       } else if (c14r2MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(5);
        setC14R2VNo(5);
        setC14R3VNo(5);
        setC14R4VNo(5);
       }
    } else if (curX === col14X && curY === row02X && c14R2VNo === 9) { 
      if (c14r2MT === "S") {  
        setRow2Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(5);
            } else if (c14r1MT === "M" && c14r2MT === "M") { 
              setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
            } else if (c14r2MT === "M" && c14r3MT === "M") {     
            setRow2NakedM14(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(5);
             setC14R3VNo(5);
        } else if (c14r2MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
        }
    } else if (curX === col14X && curY === row02X && c14R2VNo === 10) { 
      if (c14r2MT === "S") {  
        setRow2Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(5);
            } else if (c14r1MT === "M" && c14r2MT === "M") { 
              setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
            } else if (c14r2MT === "M" && c14r3MT === "M") {     
             setRow2NakedM14(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(5);
             setC14R3VNo(5);
        } else if (c14r2MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
        }	
    } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {  
      if (c14r2MT === "S") {  
        setRow2Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(5);
            } else if (c14r1MT === "M" && c14r2MT === "M") { 
              setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
            } else if (c14r2MT === "M" && c14r3MT === "M") {     
             setRow2NakedM14(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(5);
             setC14R3VNo(5);
        } else if (c14r2MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
        }	
    }   else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
      if (c1r3MT === "S") {  
        setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(1);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                ]
              )
                setC1R2VNo(1);
            setC1R3VNo(1);
            } else if (c1r3MT === "M" && c1r4MT === "M") {      
              setRow3NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                ]
              )
                setC1R3VNo(1);
            setC1R4VNo(1);
         } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
         }	
    } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {  
      if (c1r3MT === "S") {  
        setRow3Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
               ]
             )
             setC1R3VNo(3);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(3);
            setC1R3VNo(3);
            } else if (c1r3MT === "M" && c1r4MT === "M") {      
             setRow3NakedM01 (
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
               ]
             )
             setC1R3VNo(3);
           setC1R4VNo(3);
        } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
        }
    } else if (curX === col01X && curY === row03X && c1R3VNo === 7) { 
      if (c1r3MT === "S") {  
        setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(4);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(4);
            setC1R3VNo(4);
            } else if (c1r3MT === "M" && c1r4MT === "M") {       
              setRow3NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(4);
            setC1R4VNo(4);
         } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
         }	
    } else if (curX === col01X && curY === row03X && c1R3VNo === 8) { 
      if (c1r3MT === "S") {  
        setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            setC1R3VNo(5);
            } else if (c1r3MT === "M" && c1r4MT === "M") {       
            setRow3NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            setC1R4VNo(5);
         } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
         }
    } else if (curX === col01X && curY === row03X && c1R3VNo === 9) { 
      if (c1r3MT === "S") {  
        setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            setC1R3VNo(5);
            } else if (c1r3MT === "M" && c1r4MT === "M") {       
             setRow3NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            setC1R4VNo(5);
         } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
         }	
    } else if (curX === col01X && curY === row03X && c1R3VNo === 10) { 
      if (c1r3MT === "S") {  
        setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            setC1R3VNo(5);
            } else if (c1r3MT === "M" && c1r4MT === "M") {       
             setRow3NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            setC1R4VNo(5);
         } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
         }	
    } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {  
      if (c1r3MT === "S") {  
        setRow3Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
            setC1R3VNo(5);
            } else if (c1r3MT === "M" && c1r4MT === "M") {     
            setRow3NakedM01 (
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
            setC1R3VNo(5);
         } else if (c1r3MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
         }	
  
    } else  if (curX === col02X && curY === row03X && c2R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
      if (c2r3MT === "S") {  
        setRow3Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
               ]
             )
             setC2R3VNo(1);
            } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
              setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(1);
                setC2R3VNo(1);
            } else if (c2r3MT === "M" && c2r4MT === "M") {      
            setRow3NakedM02(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
               ]
             )
             setC2R3VNo(1);
               setC2R4VNo(1);
        } else if (c2r3MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
        }	
    } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {  
      if (c2r3MT === "S") {  
        setRow3Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(3);
          } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(3);
          setC2R3VNo(3);
          } else if (c2r3MT === "M" && c2r4MT === "M") {       
             setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(3);
          setC2R4VNo(3);
       } else if (c2r3MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setC2R3VNo(3);
        setC2R4VNo(3);
       }	
    } else if (curX === col02X && curY === row03X && c2R3VNo === 7) { 
      if (c2r3MT === "S") {  
        setRow3Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(4);
          } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(4);
        setC2R3VNo(4);
          } else if (c2r3MT === "M" && c2r4MT === "M") {       
            setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(4);
        setC2R4VNo(4);
       } else if (c2r3MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(4);
        setC2R2VNo(4);
        setC2R3VNo(4);
        setC2R4VNo(4);
       }	
    } else if (curX === col02X && curY === row03X && c2R3VNo === 8) { 
      if (c2r3MT === "S") {  
        setRow3Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(5);
          } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(5);
        setC2R3VNo(5);
          } else if (c2r3MT === "M" && c2r4MT === "M") {      
           setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(5);
        setC2R4VNo(5);
       } else if (c2r3MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5);
       }	
    } else if (curX === col02X && curY === row03X && c2R3VNo === 9) { 
      if (c2r3MT === "S") {  
        setRow3Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(5);
          } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(5);
        setC2R3VNo(5);
          } else if (c2r3MT === "M" && c2r4MT === "M") {    
            setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(5);
          setC2R4VNo(5);
       } else if (c2r3MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5);
       }	
    } else if (curX === col02X && curY === row03X && c2R3VNo === 10) { 
      if (c2r3MT === "S") {  
        setRow3Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
               ]
             )
             setC2R3VNo(5);
            } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
              setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
          setC2R3VNo(5);
            } else if (c2r3MT === "M" && c2r4MT === "M") {       
            setRow3NakedM02(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
               ]
             )
             setC2R3VNo(5);
           setC2R4VNo(5);
        } else if (c2r3MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
        }	
    } else if (curX === col02X && curY === row03X && c2R3VNo=== 11) {  
      if (c2r3MT === "S") {  
        setRow3Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
               ]
             )
             setC2R3VNo(5);
            } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
              setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
          setC2R3VNo(5);
            } else if (c2r3MT === "M" && c2r4MT === "M") {     
            setRow3NakedM02(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
               ]
             )
             setC2R3VNo(5);
           setC2R4VNo(5);
        } else if (c2r3MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
        }	
    } else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
      if (c3r3MT === "S") {  
        setRow3Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(1);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(1);
              setC3R3VNo(1);
            } else if (c3r3MT === "M" && c3r4MT === "M") {      
             setRow3NakedM03(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(1);
             setC3R4VNo(1);
        } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
        }	
    } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {  
      if (c3r3MT === "S") {  
        setRow3Naked03(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(3);
          } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
            setRow2NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(3);
          setC3R3VNo(3);
          } else if (c3r3MT === "M" && c3r4MT === "M") {     
           setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(3);
          setC3R4VNo(3);
       } else if (c3r3MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
        setC3R3VNo(3);
        setC3R4VNo(3);
       }	
    } else if (curX === col03X && curY === row03X && c3R3VNo === 7) { 
      if (c3r3MT === "S") {  
        setRow3Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(4);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(4);
          setC3R3VNo(4);
            } else if (c3r3MT === "M" && c3r4MT === "M") {      
            setRow3NakedM03(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(4);
             setC3R4VNo(4);
        } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
        }	
    } else if (curX === col03X && curY === row03X && c3R3VNo === 8) { 
      if (c3r3MT === "S") {  
        setRow3Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(5);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            setC3R3VNo(5);
            } else if (c3r3MT === "M" && c3r4MT === "M") {     
             setRow3NakedM03(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(5);
           setC3R4VNo(5);
        } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
        }	
    } else if (curX === col03X && curY === row03X && c3R3VNo === 9) { 
      if (c3r3MT === "S") {  
        setRow3Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(5);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            setC3R3VNo(5);
            } else if (c3r3MT === "M" && c3r4MT === "M") {     
            setRow3NakedM03(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(5);
             setC3R4VNo(5);
        } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
        }
    } else if (curX === col03X && curY === row03X && c3R3VNo === 10) { 
      if (c3r3MT === "S") {  
        setRow3Naked03(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(5);
          } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
            setRow2NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(5);
          setC3R3VNo(5);
          } else if (c3r3MT === "M" && c3r4MT === "M") {      
            setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(5);
            setC3R4VNo(5);
       } else if (c3r3MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(5);
        setC3R2VNo(5);
        setC3R3VNo(5);
        setC3R4VNo(5);
       }	
    } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {  
      if (c3r3MT === "S") {  
        setRow3Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(5);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
            setC3R3VNo(5);
            } else if (c3r3MT === "M" && c3r4MT === "M") {     
            setRow3NakedM03(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(5);
             setC3R4VNo(5);
        } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
        }	
    } else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
      if (c4r3MT === "S") {  
        setRow3Naked04(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(1);
            } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
              setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(1);
              setC4R3VNo(1);
            } else if (c4r3MT === "M" && c4r4MT === "M") {       
             setRow3NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(1);
              setC4R4VNo(1);
         } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
         }	
    } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {  
      if (c4r3MT === "S") {  
        setRow3Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
               ]
             )
             setC4R3VNo(3);
            } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
              setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(3);
              setC4R3VNo(3);
            } else if (c4r3MT === "M" && c4r4MT === "M") {        
            setRow3NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
               ]
             )
             setC4R3VNo(3);
             setC4R4VNo(3);
        } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
        }	
    } else if (curX === col04X && curY === row03X && c4R3VNo === 7) { 
      if (c4r3MT === "S") {  
        setRow3Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
               ]
             )
             setC4R3VNo(4);
            } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
              setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(4);
              setC4R3VNo(4);
            } else if (c4r3MT === "M" && c4r4MT === "M") {       
            setRow3NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
               ]
             )
             setC4R3VNo(4);
             setC4R4VNo(4);
        } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
        }
    } else if (curX === col04X && curY === row03X && c4R3VNo === 8) { 
      if (c4r3MT === "S") {  
        setRow3Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
               ]
             )
             setC4R3VNo(5);
            } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
              setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(5);
              setC4R3VNo(5);
            } else if (c4r3MT === "M" && c4r4MT === "M") {      
           setRow3NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
               ]
             )
             setC4R3VNo(5);
             setC4R4VNo(5);
        } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
        }	
    } else if (curX === col04X && curY === row03X && c4R3VNo === 9) { 
      if (c4r3MT === "S") {  
        setRow3Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(5);
          } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
            setRow2NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(5);
            setC4R3VNo(5);
          } else if (c4r3MT === "M" && c4r4MT === "M") {       
            setRow3NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(5);
            setC4R3VNo(5);
       } else if (c4r3MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
       }
    } else if (curX === col04X && curY === row03X && c4R3VNo === 10) { 
      if (c4r3MT === "S") {  
        setRow3Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(5);
          } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
            setRow2NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(5);
            setC4R3VNo(5);
          } else if (c4r3MT === "M" && c4r4MT === "M") {      
             setRow3NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(5);
            setC4R4VNo(5);
       } else if (c4r3MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
       }	
    } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {  
      if (c4r3MT === "S") {  
        setRow3Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(5);
          } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
            setRow2NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(5);
            setC4R3VNo(5);
          } else if (c4r3MT === "M" && c4r4MT === "M") {       
            setRow3NakedM04(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(5);
           setC4R4VNo(5);
      } else if (c4r3MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
      }	
      //5th col
    } else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
      if (c5r3MT === "S") {  
        setRow3Naked05(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
             ]
           )
           setC5R3VNo(1);
          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
            setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(1);
            setC5R3VNo(1);
          } else if (c5r3MT === "M" && c5r4MT === "M") {     
            setRow3NakedM05(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
             ]
           )
           setC5R3VNo(1);
           setC5R4VNo(1);
      } else if (c5r3MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(1);
        setC5R2VNo(1);
        setC5R3VNo(1);
        setC5R4VNo(1);
      }	
    } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {  
      if (c5r3MT === "S") {  
        setRow3Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(3);
          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
            setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(3);
            setC5R3VNo(3);
          } else if (c5r3MT === "M" && c5r4MT === "M") {   
           setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(3);
            setC5R4VNo(3);
       } else if (c5r3MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
        setC5R3VNo(3);
        setC4R4VNo(3);
       }
    } else if (curX === col05X && curY === row03X && c5R3VNo === 7) { 
      if (c5r3MT === "S") {  
        setRow3Naked05(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
             ]
           )
           setC5R3VNo(4);
          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
            setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(4);
            setC5R3VNo(4);
          } else if (c5r3MT === "M" && c5r4MT === "M") {      
           setRow3NakedM05(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
             ]
           )
           setC5R3VNo(4);
           setC5R4VNo(4);
      } else if (c5r3MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(4);
        setC5R2VNo(4);
        setC5R3VNo(4);
        setC5R4VNo(4);
      }	
    } else if (curX === col05X && curY === row03X && c5R3VNo === 8) { 
      if (c5r3MT === "S") {  
        setRow3Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(5);
          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
            setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(5);
            setC5R3VNo(5);
          } else if (c5r3MT === "M" && c5r4MT === "M") {      
            setRow3NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(5);
            setC5R4VNo(5);
       } else if (c5r3MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(5);
        setC5R2VNo(5);
        setC5R3VNo(5);
        setC5R4VNo(5);
       }	
    } else if (curX === col05X && curY === row03X && c5R3VNo === 9) { 
      if (c5r3MT === "S") {  
        setRow3Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
               ]
             )
             setC5R3VNo(5);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(5);
              setC5R3VNo(5);
            } else if (c5r3MT === "M" && c5r4MT === "M") {    
             setRow3NakedM05(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
               ]
             )
             setC5R3VNo(5);
             setC5R4VNo(5);
        } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }	
    } else if (curX === col05X && curY === row03X && c5R3VNo === 10) { 
      if (c5r3MT === "S") {  
        setRow3Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(5);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(5);
              setC5R3VNo(5);
            } else if (c5r3MT === "M" && c5r4MT === "M") {      
             setRow3NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(5);
              setC5R4VNo(5);
         } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
         }	
    } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {  
      if (c5r3MT === "S") {  
        setRow3Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(5);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(5);
              setC5R3VNo(5);
            } else if (c5r3MT === "M" && c5r4MT === "M") {      
            setRow3NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(5);
              setC5R4VNo(5);
         } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
         }
      //6th  col
    } else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
      if (c6r3MT === "S") {  
        setRow3Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
               ]
             )
             setC6R3VNo(1);
            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(1);
              setC6R3VNo(1);
            } else if (c6r3MT === "M" && c6r4MT === "M") {      
           setRow3NakedM06(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
               ]
             )
             setC6R3VNo(1);
             setC6R4VNo(1);
        } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
        }
    } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {  
      if (c6r3MT === "S") {  
        setRow3Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
               ]
             )
             setC6R3VNo(3);
            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(3);
              setC6R3VNo(3);
            } else if (c6r3MT === "M" && c6r4MT === "M") {    
             setRow3NakedM06(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
               ]
             )
             setC6R3VNo(3);
             setC6R4VNo(3);
        } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
        }
    } else if (curX === col06X && curY === row03X && c6R3VNo === 7) { 
      if (c6r3MT === "S") {  
        setRow3Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(4);
          } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(4);
            setC6R3VNo(4);
          } else if (c6r3MT === "M" && c6r4MT === "M") {     
           setRow3NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(4);
            setC6R4VNo(4);
       } else if (c6r3MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(4);
        setC6R2VNo(4);
        setC6R3VNo(4);
        setC6R4VNo(4);
       }
    } else if (curX === col06X && curY === row03X && c6R3VNo === 8) { 
      if (c6r3MT === "S") {  
        setRow3Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(5);
          } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(5);
            setC6R3VNo(5);
          } else if (c6r3MT === "M" && c6r4MT === "M") {   
          setRow3NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(5);
            setC6R4VNo(5);
       } else if (c6r3MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(5);
        setC6R2VNo(5);
        setC6R3VNo(5);
        setC6R4VNo(5);
       }
    } else if (curX === col06X && curY === row03X && c6R3VNo === 9) { 
      if (c6r3MT === "S") {  
        setRow3Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
               ]
             )
             setC6R3VNo(5);
            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(5);
              setC6R3VNo(5);
            } else if (c6r3MT === "M" && c6r4MT === "M") {     
           setRow3NakedM06(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
               ]
             )
             setC6R3VNo(5);
             setC6R4VNo(5);
        } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
        }	
    } else if (curX === col06X && curY === row03X && c6R3VNo === 10) { 
      if (c6r3MT === "S") {  
        setRow3Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(5);
            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(5);
              setC6R3VNo(5);
            } else if (c6r3MT === "M" && c6r4MT === "M") {      
            setRow3NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(5);
              setC6R4VNo(5);
         } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
         }	
    } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {  
      if (c6r3MT === "S") {  
        setRow3Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(5);
            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(5);
              setC6R3VNo(5);
            } else if (c6r3MT === "M" && c6r4MT === "M") {     
           setRow3NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(5);
              setC6R4VNo(5);
         } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
         }
      //7th col
    } else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
      if (c7r3MT === "S") {  
        setRow3Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(1);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(1);
              setC7R3VNo(1);
            } else if (c7r3MT === "M" && c7r4MT === "M") {     
            setRow3NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(1);
              setC6R4VNo(1);
         } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
         }	
    } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {  
      if (c7r3MT === "S") {  
        setRow3Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(3);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(3);
              setC7R3VNo(3);
            } else if (c7r3MT === "M" && c7r4MT === "M") {     
           setRow3NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(3);
              setC6R4VNo(3);
         } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
         }	
    } else if (curX === col07X && curY === row03X && c7R3VNo === 7) { 
      if (c7r3MT === "S") {  
        setRow3Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(4);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(4);
            setC7R3VNo(4);
            } else if (c7r3MT === "M" && c7r4MT === "M") {      
            setRow3NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(4);
              setC6R4VNo(4);
         } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
         }	
    } else if (curX === col07X && curY === row03X && c7R3VNo === 8) { 
      if (c7r3MT === "S") {  
        setRow3Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(5);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(5);
              setC7R3VNo(5);
            } else if (c7r3MT === "M" && c7r4MT === "M") {       
           setRow3NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(5);
             setC6R4VNo(5);
        } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }	
    } else if (curX === col07X && curY === row03X && c7R3VNo === 9) { 
      if (c7r3MT === "S") {  
        setRow3Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(5);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(5);
              setC7R3VNo(5);
            } else if (c7r3MT === "M" && c7r4MT === "M") {       
           setRow3NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(5);
             setC6R4VNo(5);
        } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
    } else if (curX === col07X && curY === row03X && c7R3VNo === 10) { 
      if (c7r3MT === "S") {  
        setRow3Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(5);
          } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
            setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(5);
            setC7R3VNo(5);
          } else if (c7r3MT === "M" && c7r4MT === "M") {      
          setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(5);
            setC6R4VNo(5);
       } else if (c7r3MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(5);
        setC7R2VNo(5);
        setC7R3VNo(5);
        setC7R4VNo(5);
       }	
    } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {  
      if (c7r3MT === "S") {  
        setRow3Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(5);
            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(5);
              setC7R3VNo(5);
            } else if (c7r3MT === "M" && c7r4MT === "M") {       
            setRow3NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(5);
             setC6R4VNo(5);
        } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }	
      //8th col
    } else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
      if (c8r3MT === "S") {  
        setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(1);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(1);
            setC8R3VNo(1);
          } else if (c8r3MT === "M" && c8r4MT === "M") {      
            setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(1);
            setC8R4VNo(1);
       } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
        setC8R2VNo(1);
        setC8R3VNo(1);
        setC8R4VNo(1);
       }	
    } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {  
      if (c8r3MT === "S") {  
        setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(3);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(3);
            setC8R3VNo(3);
          } else if (c8r3MT === "M" && c8r4MT === "M") {    
            setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(3);
            setC8R4VNo(3);
       } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setC8R3VNo(3);
        setC8R4VNo(3);
       }
    } else if (curX === col08X && curY === row03X && c8R3VNo === 7) { 
      if (c8r3MT === "S") {  
        setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(4);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(4);
            setC8R3VNo(4);
          } else if (c8r3MT === "M" && c8r4MT === "M") {      
          setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(4);
            setC8R4VNo(4);
       } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(4);
        setC8R2VNo(4);
        setC8R3VNo(4);
        setC8R4VNo(4);
       }	
    } else if (curX === col08X && curY === row03X && c8R3VNo === 8) { 
      if (c8r3MT === "S") {  
        setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(5);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(5);
            setC8R3VNo(5);
          } else if (c8r3MT === "M" && c8r4MT === "M") {      
          setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(5);
            setC8R4VNo(5);
       } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }	
    } else if (curX === col08X && curY === row03X && c8R3VNo === 9) { 
      if (c8r3MT === "S") {  
        setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(5);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(5);
            setC8R3VNo(5);
          } else if (c8r3MT === "M" && c8r4MT === "M") {      
           setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(5);
            setC8R4VNo(5);
       } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }	
    } else if (curX === col08X && curY === row03X && c8R3VNo === 10) { 
      if (c8r3MT === "S") {  
        setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(5);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(5);
            setC8R3VNo(5);
          } else if (c8r3MT === "M" && c8r4MT === "M") {      
            setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(5);
            setC8R4VNo(5);
       } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }
    } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {  
      if (c8r3MT === "S") {  
        setRow3Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(5);
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC8R3VNo(5);
            } else if (c8r3MT === "M" && c8r4MT === "M") {      
            setRow3NakedM08(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(5);
             setC8R4VNo(5);
        } else if (c8r3MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
        }	
      //9th col
    } else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
      if (c9r3MT === "S") {  
        setRow3Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(1);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(1);
              setC9R3VNo(1);
            } else if (c9r3MT === "M" && c9r4MT === "M") {     
            setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(1);
             setC9R4VNo(1);
        } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
        }	
    } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {  
      if (c9r3MT === "S") {  
        setRow3Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(3);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(3);
              setC9R3VNo(3);
            } else if (c9r3MT === "M" && c9r4MT === "M") {       
            setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(3);
             setC9R4VNo(3);
        } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
        }
    } else if (curX === col09X && curY === row03X && c9R3VNo === 7) { 
      if (c9r3MT === "S") {  
        setRow3Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(4);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(4);
              setC9R3VNo(4);
            } else if (c9r3MT === "M" && c9r4MT === "M") {     
             setRow3NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(4);
              setC9R4VNo(4);
         } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
         }
    } else if (curX === col09X && curY === row03X && c9R3VNo === 8) { 
      if (c9r3MT === "S") {  
        setRow3Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC9R3VNo(5);
            } else if (c9r3MT === "M" && c9r4MT === "M") {      
             setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(5);
             setC9R4VNo(5);
        } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }	
    } else if (curX === col09X && curY === row03X && c9R3VNo === 9) { 
      if (c9r3MT === "S") {  
        setRow3Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC9R3VNo(5);
            } else if (c9r3MT === "M" && c9r4MT === "M") {     
             setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(5);
             setC9R4VNo(5);
        } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }	
    } else if (curX === col09X && curY === row03X && c9R3VNo === 10) { 
      if (c9r3MT === "S") {  
        setRow3Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC9R3VNo(5);
            } else if (c9r3MT === "M" && c9r4MT === "M") {      
            setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(5);
             setC9R4VNo(5);
        } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }
    } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {  
      if (c9r3MT === "S") {  
        setRow3Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(5);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC9R3VNo(5);
            } else if (c9r3MT === "M" && c9r4MT === "M") {      
             setRow3NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(5);
              setC9R4VNo(5);
         } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
         }	
      // 10th col
    } else if (curX === col10X && curY === row03X && c10R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
      if (c10r3MT === "S") {  
        setRow3Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(1);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(1);
              setC10R3VNo(1);
            } else if (c10r3MT === "M" && c10r4MT === "M") {    
            setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(1);
             setC10R4VNo(1);
        } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
        }
    } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col10X && curY === row03X && c10R3VNo === 6) {  
      if (c10r3MT === "S") {  
        setRow3Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(3);
          } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
            setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(3);
            setC10R3VNo(3);
          } else if (c10r3MT === "M" && c10r4MT === "M") {     
           setRow3NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(3);
            setC10R4VNo(3);
       } else if (c10r3MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setC10R3VNo(3);
        setC10R4VNo(3);
       }	
    } else if (curX === col10X && curY === row03X && c10R3VNo === 7) { 
      if (c10r3MT === "S") {  
        setRow3Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(4);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(4);
              setC10R3VNo(4);
            } else if (c10r3MT === "M" && c10r4MT === "M") {    
            setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(4);
             setC10R4VNo(4);
        } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
        }	
    } else if (curX === col10X && curY === row03X && c10R3VNo === 8) { 
      if (c10r3MT === "S") {  
        setRow3Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(5);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(5);
              setC10R3VNo(5);
            } else if (c10r3MT === "M" && c10r4MT === "M") {      
           setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(5);
             setC10R4VNo(5);
        } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
        }	
    } else if (curX === col10X && curY === row03X && c10R3VNo === 9) { 
      if (c10r3MT === "S") {  
        setRow3Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(5);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(5);
              setC10R3VNo(5);
            } else if (c10r3MT === "M" && c10r4MT === "M") {   
            setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(5);
             setC10R4VNo(5);
        } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
        }	
    } else if (curX === col10X && curY === row03X && c10R3VNo === 10) { 
      if (c10r3MT === "S") {  
        setRow3Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(5);
          } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
            setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(5);
            setC10R3VNo(5);
          } else if (c10r3MT === "M" && c10r4MT === "M") {      
            setRow3NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(5);
            setC10R4VNo(5);
       } else if (c10r3MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
       }	
    } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {  
      if (c10r3MT === "S") {  
        setRow3Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
             ]
           )
           setC10R3VNo(5);
          } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
            setRow2NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(5);
            setC10R3VNo(5);
          } else if (c10r3MT === "M" && c10r4MT === "M") {    
           setRow3NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
             ]
           )
           setC10R3VNo(5);
           setC10R4VNo(5);
      } else if (c10r3MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
      }	
      //11th col
    } else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
      if (c11r3MT === "S") {  
        setRow3Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(1);
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(1);
            setC11R3VNo(1);
          } else if (c11r3MT === "M" && c11r4MT === "M") {      
          setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(1);
           setC11R4VNo(1);
      } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
        setC11R3VNo(1);
        setC11R4VNo(1);
      }	
    } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {  
      if (c11r3MT === "S") {  
        setRow3Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(3);
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(3);
            setC11R3VNo(3);
          } else if (c11r3MT === "M" && c11r4MT === "M") {     
           setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(3);
           setC11R4VNo(3);
      } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
      }	
    } else if (curX === col11X && curY === row03X && c11R3VNo === 7) { 
      if (c11r3MT === "S") {  
        setRow3Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(4);
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(4);
            setC11R3VNo(4);
          } else if (c11r3MT === "M" && c11r4MT === "M") {     
           setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(4);
           setC11R4VNo(4);
      } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(4);
        setC11R2VNo(4);
        setC11R3VNo(4);
        setC11R4VNo(4);
      }
    } else if (curX === col11X && curY === row03X && c11R3VNo === 8) { 
      if (c11r3MT === "S") {  
        setRow3Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
            setC11R3VNo(5);
          } else if (c11r3MT === "M" && c11r4MT === "M") {      
           setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(5);
           setC11R4VNo(5);
      } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
      }	
    } else if (curX === col11X && curY === row03X && c11R3VNo === 9) { 
      if (c11r3MT === "S") {  
        setRow3Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
            setC11R3VNo(5);
          } else if (c11r3MT === "M" && c11r4MT === "M") {      
           setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(5);
           setC11R4VNo(5);
      } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
      }	
    } else if (curX === col11X && curY === row03X && c11R3VNo === 10) { 
      if (c11r3MT === "S") {  
        setRow3Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
            setC11R3VNo(5);
          } else if (c11r3MT === "M" && c11r4MT === "M") {     
            setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(5);
           setC11R4VNo(5);
      } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
      }	
    } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {  
      if (c11r3MT === "S") {  
        setRow3Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(5);
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(5);
            setC11R3VNo(5);
          } else if (c11r3MT === "M" && c11r4MT === "M") {      
              setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(5);
            setC11R4VNo(5);
       } else if (c11r3MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
       }	
      //12th col
    } else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
      if (c12r3MT === "S") {  
        setRow3Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(1);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(1);
              setC12R3VNo(1);
            } else if (c12r3MT === "M" && c12r4MT === "M") {       
               setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(1);
             setC12R4VNo(1);
        } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
        }	
    } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {  
      if (c12r3MT === "S") {  
        setRow3Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(3);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(3);
              setC12R3VNo(3);
            } else if (c12r3MT === "M" && c12r4MT === "M") {      
             setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(3);
             setC12R4VNo(3);
        } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
        }	
    } else if (curX === col12X && curY === row03X && c12R3VNo === 7) { 
      if (c12r3MT === "S") {  
        setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(4);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(4);
              setC12R3VNo(4);
            } else if (c12r3MT === "M" && c12r4MT === "M") {     
             setRow3NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(4);
              setC12R4VNo(4);
         } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
         }	
    } else if (curX === col12X && curY === row03X && c12R3VNo === 8) { 
      if (c12r3MT === "S") {  
        setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(5);
              setC12R3VNo(5);
            } else if (c12r3MT === "M" && c12r4MT === "M") {       
             setRow3NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(5);
              setC12R4VNo(5);
         } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }	
    } else if (curX === col12X && curY === row03X && c12R3VNo === 9) { 
      if (c12r3MT === "S") {  
        setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(5);
              setC12R3VNo(5);
            } else if (c12r3MT === "M" && c12r4MT === "M") {    
           setRow3NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(5);
              setC12R4VNo(5);
         } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }	
    } else if (curX === col12X && curY === row03X && c12R3VNo === 10) { 
      if (c12r3MT === "S") {  
        setRow3Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(5);
              setC12R3VNo(5);
            } else if (c12r3MT === "M" && c12r4MT === "M") {       
          setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(5);
             setC12R4VNo(5);
        } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
        }	
    } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {  
      if (c12r3MT === "S") {  
        setRow3Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(5);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(5);
              setC12R3VNo(5);
            } else if (c12r3MT === "M" && c12r4MT === "M") {       
          setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(5);
             setC12R4VNo(5);
        } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
        }	
      //13th col
    } else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
      if (c13r3MT === "S") {  
        setRow3Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(1);
            } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
              setRow2NakedM13(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(1);
              setC13R3VNo(1);
            } else if (c13r3MT === "M" && c13r4MT === "M") { 
          setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(1);
             setC13R4VNo(1);
        } else if (c13r3MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
        }	
    } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {  
      if (c13r3MT === "S") {  
        setRow3Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(3);
            } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
              setRow2NakedM13(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(3);
              setC13R3VNo(3);
            } else if (c13r3MT === "M" && c13r4MT === "M") {  
         setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(3);
             setC13R4VNo(3);
        
        } else if (c13r3MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
        }	
    } else if (curX === col13X && curY === row03X && c13R3VNo === 7) { 
      if (c13r3MT === "S") {  
        setRow3Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(4);
            } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
              setRow2NakedM13(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(4);
              setC13R3VNo(4);
            } else if (c13r3MT === "M" && c13r4MT === "M") {   
        setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(4);
             setC13R4VNo(4);
        } else if (c13r3MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
        }	
    } else if (curX === col13X && curY === row03X && c13R3VNo === 8) { 
      if (c13r3MT === "S") {  
        setRow3Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
            setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(5);
            setC13R3VNo(5);
          } else if (c13r3MT === "M" && c13r4MT === "M") {   
        setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
            setC13R4VNo(5);
       } else if (c13r3MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
       }	
    } else if (curX === col13X && curY === row03X && c13R3VNo === 9) { 
      if (c13r3MT === "S") {  
        setRow3Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
            setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(5);
            setC13R3VNo(5);
          } else if (c13r3MT === "M" && c13r4MT === "M") {    
        setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
            setC13R4VNo(5);
       } else if (c13r3MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
       }	
    } else if (curX === col13X && curY === row03X && c13R3VNo === 10) { 
      if (c13r3MT === "S") {  
        setRow3Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
            setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(5);
            setC13R3VNo(5);
          } else if (c13r3MT === "M" && c13r4MT === "M") {     
         setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
            setC13R4VNo(5);
       } else if (c13r3MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
       }	
    } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {  
      if (c13r3MT === "S") {  
        setRow3Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
          } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
            setRow2NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(5);
            setC13R3VNo(5);
          } else if (c13r3MT === "M" && c13r4MT === "M") {    
        setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(5);
            setC13R4VNo(5);
       } else if (c13r3MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(5);
        setC13R2VNo(5);
        setC13R3VNo(5);
        setC13R4VNo(5);
       }
      // 14th col
    } else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
      alert("Already removed");
    } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
      if (c14r3MT === "S") {  
        setRow3Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(1);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(1);
            setC14R3VNo(1);
          } else if (c14r3MT === "M" && c14r4MT === "M") {    
         setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(1);
            setC14R3VNo(1);
       } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
        setC14R3VNo(1);
        setC14R4VNo(1);
       }
    } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
      alert("Already removed");
    } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
      alert("Already removed");  
    } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {
      alert("Already removed");
    } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {  
      if (c14r3MT === "S") {  
        setRow3Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(3);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(3);
            setC14R3VNo(3);
          } else if (c14r3MT === "M" && c14r4MT === "M") {      
         setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(3);
            setC14R4VNo(3);
       } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setC14R3VNo(3);
        setC14R4VNo(3);
       }	
    } else if (curX === col14X && curY === row03X && c14R3VNo === 7) { 
      if (c14r3MT === "S") {  
        setRow3Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(4);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(4);
            setC14R3VNo(4);
          } else if (c14r3MT === "M" && c14r4MT === "M") {       
        setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(4);
            setC14R4VNo(4);
       } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(4);
        setC14R2VNo(4);
        setC14R3VNo(4);
        setC14R4VNo(4);
       }
    } else if (curX === col14X && curY === row03X && c14R3VNo === 8) { 
      if (c14r3MT === "S") {  
        setRow3Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(5);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(5);
            setC14R3VNo(5);
          } else if (c14r3MT === "M" && c14r4MT === "M") {       
        setRow3NakedM14(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(5);
           setC14R4VNo(5);
      } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(5);
        setC14R2VNo(5);
        setC14R3VNo(5);
        setC14R4VNo(5);
      }	
    } else if (curX === col14X && curY === row03X && c14R3VNo === 9) { 
      if (c14r3MT === "S") {  
        setRow3Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(5);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(5);
            setC14R3VNo(5);
          } else if (c14r3MT === "M" && c14r4MT === "M") {       
         setRow3NakedM14(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(5);
           setC14R4VNo(5);
      } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(5);
        setC14R2VNo(5);
        setC14R3VNo(5);
        setC14R4VNo(5);
      }	
    } else if (curX === col14X && curY === row03X && c14R3VNo === 10) { 
      if (c14r3MT === "S") {  
        setRow3Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(5);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(5);
            setC14R3VNo(5);
          } else if (c14r3MT === "M" && c14r4MT === "M") {       
         setRow3NakedM14(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(5);
           setC14R4VNo(5);
      } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(5);
        setC14R2VNo(5);
        setC14R3VNo(5);
        setC14R4VNo(5);
      }
    } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {  
      if (c14r3MT === "S") {  
        setRow3Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(5);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(5);
            setC14R3VNo(5);
          } else if (c14r3MT === "M" && c14r4MT === "M") {       
           setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(5);
            setC14R3VNo(5);
       } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(5);
        setC14R2VNo(5);
        setC14R3VNo(5);
        setC14R4VNo(5);
       }
    }   else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
      if (c1r4MT === "S") {  
        setRow4Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
               ]
             )
             setC1R4VNo(1);
         } else if (c1r4MT === "M") {      
            setRow3NakedM01 (
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                       ]
                     )
                       setC1R3VNo(1);
                   setC1R4VNo(1);
        } else if (c1r4MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
        }
    } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {  
      if (c1r4MT === "S") {  
        setRow4Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
              ]
            )
            setC1R4VNo(3);
        } else if (c1r4MT === "M") {      
           setRow3NakedM01 (
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                     ]
                   )
                   setC1R3VNo(3);
                 setC1R4VNo(3);
       } else if (c1r4MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setC1R3VNo(3);
        setC1R4VNo(3);
       }	
    } else if (curX === col01X && curY === row04X && c1R4VNo === 7) { 
      if (c1r4MT === "S") {  
        setRow4Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
              ]
            )
            setC1R4VNo(4);
        } else if (c1r4MT === "M") {      
           setRow3NakedM01 (
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
                    setC1R3VNo(4);
                  setC1R4VNo(4);
       } else if (c1r4MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(4);
        setC1R2VNo(4);
        setC1R3VNo(4);
        setC1R4VNo(4);
       }	
    } else if (curX === col01X && curY === row04X && c1R4VNo === 8) { 
      if (c1r4MT === "S") {  
        setRow4Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
              ]
            )
            setC1R4VNo(5);
        } else if (c1r4MT === "M") {      
            setRow3NakedM01 (
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
                    setC1R3VNo(5);
                  setC1R4VNo(5);
       } else if (c1r4MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(5);
        setC1R2VNo(5);
        setC1R3VNo(5);
        setC1R4VNo(5);
       }	
    } else if (curX === col01X && curY === row04X && c1R4VNo === 9) { 
      if (c1r4MT === "S") {  
        setRow4Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
               ]
             )
             setC1R4VNo(5);
         } else if (c1r4MT === "M") {      
            setRow3NakedM01 (
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                       ]
                     )
                     setC1R3VNo(5);
                   setC1R4VNo(5);
        } else if (c1r4MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }	
    } else if (curX === col01X && curY === row04X && c1R4VNo === 10) { 
      if (c1r4MT === "S") {  
        setRow4Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
               ]
             )
             setC1R4VNo(5);
         } else if (c1r4MT === "M") {      
           setRow3NakedM01 (
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                       ]
                     )
                     setC1R3VNo(5);
                   setC1R4VNo(5);
        } else if (c1r4MT === "L") {  
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }
    } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {  
      if (c1r4MT === "S") {  
        setRow4Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
              ]
            )
            setC1R4VNo(5);
        } else if (c1r4MT === "M") {      
          setRow3NakedM01 (
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
                    setC1R3VNo(5);
                  setC1R3VNo(5);
       } else if (c1r4MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(5);
        setC1R2VNo(5);
        setC1R3VNo(5);
        setC1R4VNo(5);
       }	
  
    } else  if (curX === col02X && curY === row04X && c2R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col02X && curY === row04X && c2R4VNo === 2) {
      if (c2r4MT === "S") {  
        setRow4Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
               ]
             )
             setC2R4VNo(1);
         } else if (c2r4MT === "M") {      
           setRow3NakedM02(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                      ]
                    )
                    setC2R3VNo(1);
                      setC2R4VNo(1);
        } else if (c2r4MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
        }
    } else if (curX === col02X && curY === row04X && c2R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col02X && curY === row04X && c2R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col02X && curY === row04X && c2R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col02X && curY === row04X && c2R4VNo === 6) {  
      if (c2r4MT === "S") {  
        setRow4Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
               ]
             )
             setC2R4VNo(3);
         } else if (c2r4MT === "M") {      
           setRow3NakedM02(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                     ]
                   )
                   setC2R3VNo(3);
                 setC2R4VNo(3);
        } else if (c2r4MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
        }	
    } else if (curX === col02X && curY === row04X && c2R4VNo === 7) { 
      if (c2r4MT === "S") {  
        setRow4Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
               ]
             )
             setC2R4VNo(4);
         } else if (c2r4MT === "M") {      
           setRow3NakedM02(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
                     ]
                   )
                   setC2R3VNo(4);
               setC2R4VNo(4);
        } else if (c2r4MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
        }	
    } else if (curX === col02X && curY === row04X && c2R4VNo === 8) { 
      if (c2r4MT === "S") {  
        setRow4Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
              ]
            )
            setC2R4VNo(5);
        } else if (c2r4MT === "M") {      
          setRow3NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                    ]
                  )
                  setC2R3VNo(5);
              setC2R4VNo(5);
       } else if (c2r4MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5);
       }	
    } else if (curX === col02X && curY === row04X && c2R4VNo === 9) { 
      if (c2r4MT === "S") {  
        setRow4Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
              ]
            )
            setC2R4VNo(5);
        } else if (c2r4MT === "M") {      
          setRow3NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                    ]
                  )
                  setC2R3VNo(5);
                setC2R4VNo(5);
       } else if (c2r4MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5);
       }
    } else if (curX === col02X && curY === row04X && c2R4VNo === 10) { 
      if (c2r4MT === "S") {  
        setRow4Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
              ]
            )
            setC2R4VNo(5);
        } else if (c2r4MT === "M") {      
          setRow3NakedM02(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                     ]
                   )
                   setC2R3VNo(5);
                 setC2R4VNo(5);
       } else if (c2r4MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5);
       }
    } else if (curX === col02X && curY === row04X && c2R4VNo=== 11) {  
      if (c2r4MT === "S") {  
        setRow4Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
              ]
            )
            setC2R4VNo(5);
        } else if (c2r4MT === "M") {      
         setRow3NakedM02(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                     ]
                   )
                   setC2R3VNo(5);
                 setC2R4VNo(5);
       } else if (c2r4MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5);
       }	
    } else if (curX === col03X && curY === row04X && c3R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col03X && curY === row04X && c3R4VNo === 2) {
      if (c3r4MT === "S") {  
        setRow4Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
                ]
              )
              setC3R4VNo(1);
          } else if (c3r4MT === "M") {      
           setRow3NakedM03(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                       ]
                     )
                     setC3R3VNo(1);
                     setC3R4VNo(1);
         } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
         }	
    } else if (curX === col03X && curY === row04X && c3R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col03X && curY === row04X && c3R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col03X && curY === row04X && c3R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col03X && curY === row04X && c3R4VNo === 6) {  
      if (c3r4MT === "S") {  
        setRow4Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
               ]
             )
             setC3R4VNo(3);
         } else if (c3r4MT === "M") {      
           setRow3NakedM03(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                     ]
                   )
                   setC3R3VNo(3);
                 setC3R4VNo(3);
        } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
        }
    } else if (curX === col03X && curY === row04X && c3R4VNo === 7) { 
      if (c3r4MT === "S") {  
        setRow4Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
               ]
             )
             setC3R4VNo(4);
         } else if (c3r4MT === "M") {      
           setRow3NakedM03(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                      ]
                    )
                    setC3R3VNo(4);
                    setC3R4VNo(4);
        } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
        }	
    } else if (curX === col03X && curY === row04X && c3R4VNo === 8) { 
      if (c3r4MT === "S") {  
        setRow4Naked03(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
              ]
            )
            setC3R4VNo(5);
        } else if (c3r4MT === "M") {      
          setRow3NakedM03(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                     ]
                   )
                   setC3R3VNo(5);
                 setC3R4VNo(5);
       } else if (c3r4MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(5);
        setC3R2VNo(5);
        setC3R3VNo(5);
        setC3R4VNo(5);
       }	
    } else if (curX === col03X && curY === row04X && c3R4VNo === 9) { 
      if (c3r4MT === "S") {  
        setRow4Naked03(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
             ]
           )
           setC3R4VNo(5);
       } else if (c3r4MT === "M") {      
        setRow3NakedM03(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                    ]
                  )
                  setC3R3VNo(5);
                  setC3R4VNo(5);
      } else if (c3r4MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(5);
        setC3R2VNo(5);
        setC3R3VNo(5);
        setC3R4VNo(5);
      }	
    } else if (curX === col03X && curY === row04X && c3R4VNo === 10) { 
      if (c3r4MT === "S") {  
        setRow4Naked03(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
             ]
           )
           setC3R4VNo(5);
       } else if (c3r4MT === "M") {      
       setRow3NakedM03(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                   ]
                 )
                 setC3R3VNo(5);
                 setC3R4VNo(5);
      } else if (c3r4MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(5);
        setC3R2VNo(5);
        setC3R3VNo(5);
        setC3R4VNo(5);
      }
    } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {  
      if (c3r4MT === "S") {  
        setRow4Naked03(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
              ]
            )
            setC3R4VNo(5);
        } else if (c3r4MT === "M") {      
         setRow3NakedM03(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                     ]
                   )
                   setC3R3VNo(5);
                   setC3R4VNo(5);
       } else if (c3r4MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(5);
        setC3R2VNo(5);
        setC3R3VNo(5);
        setC3R4VNo(5);
       }	
    } else if (curX === col04X && curY === row04X && c4R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col04X && curY === row04X && c4R4VNo === 2) {
      if (c4r4MT === "S") {  
        setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(1);
        } else if (c4r4MT === "M") {      
          setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(1);
          setC3R4VNo(1);
       } else if (c4r4MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
        setC4R2VNo(1);
        setC4R3VNo(1);
        setC4R4VNo(1);
       }	
    } else if (curX === col04X && curY === row04X && c4R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col04X && curY === row04X && c4R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col04X && curY === row04X && c4R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col04X && curY === row04X && c4R4VNo === 6) {  
      if (c4r4MT === "S") {  
        setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(3);
        } else if (c4r4MT === "M") {      
          setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(3);
        setC3R4VNo(3);
       } else if (c4r4MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
        setC4R3VNo(3);
        setC4R4VNo(3);
       }	
    } else if (curX === col04X && curY === row04X && c4R4VNo === 7) { 
      if (c4r4MT === "S") {  
        setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(4);
        } else if (c4r4MT === "M") {      
          setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(4);
          setC3R4VNo(4);
       } else if (c4r4MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(4);
        setC4R2VNo(4);
        setC4R3VNo(4);
        setC4R4VNo(4);
       }
    } else if (curX === col04X && curY === row04X && c4R4VNo === 8) { 
      if (c4r4MT === "S") {  
        setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(5);
        } else if (c4r4MT === "M") {      
           setRow3NakedM04(
                           [
                             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                           ]
                         )
                         setC3R3VNo(5);
                       setC3R4VNo(5);
       } else if (c4r4MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
       }	
    } else if (curX === col04X && curY === row04X && c4R4VNo === 9) { 
      if (c4r4MT === "S") {  
        setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(5);
        } else if (c4r4MT === "M") {      
           setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                    ]
                  )
                  setC4R3VNo(5);
                  setC4R3VNo(5);
       } else if (c4r4MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
       }
    } else if (curX === col04X && curY === row04X && c4R4VNo === 10) { 
      if (c4r4MT === "S") {  
        setRow4Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
               ]
             )
             setC4R4VNo(5);
         } else if (c4r4MT === "M") {      
                       setRow3NakedM04(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                     ]
                   )
                   setC4R3VNo(5);
                   setC4R4VNo(5);
        } else if (c4r4MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
        }
    } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {  
      if (c4r4MT === "S") {  
        setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(5);
        } else if (c4r4MT === "M") {      
           setRow3NakedM04(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                   ]
                 )
                 setC4R3VNo(5);
                 setC4R4VNo(5);
       } else if (c4r4MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
       }
      //5th col
    } else if (curX === col05X && curY === row04X && c5R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col05X && curY === row04X && c5R4VNo === 2) {
      if (c5r4MT === "S") {  
        setRow4Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
               ]
             )
             setC5R4VNo(1);
         } else if (c5r4MT === "M") {      
              setRow3NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                    ]
                  )
                  setC5R3VNo(1);
                  setC5R4VNo(1);
        } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
        }	
    } else if (curX === col05X && curY === row04X && c5R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col05X && curY === row04X && c5R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col05X && curY === row04X && c5R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col05X && curY === row04X && c5R4VNo === 6) {  
      if (c5r4MT === "S") {  
        setRow4Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
               ]
             )
             setC5R4VNo(3);
         } else if (c5r4MT === "M") {      
           setRow3NakedM05(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                     ]
                   )
                   setC5R3VNo(3);
                   setC5R4VNo(3);
        } else if (c5r4MT === "L") {  
          alert("Large Module not yet added.");
        }	
    } else if (curX === col05X && curY === row04X && c5R4VNo === 7) { 
      if (c5r4MT === "S") {  
        setRow4Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
               ]
             )
             setC5R4VNo(4);
         } else if (c5r4MT === "M") {      
           setRow3NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
                    ]
                  )
                  setC5R3VNo(4);
                  setC5R4VNo(4);
        } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC4R4VNo(3);
        }	
    } else if (curX === col05X && curY === row04X && c5R4VNo === 8) { 
      if (c5r4MT === "S") {  
        setRow4Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                ]
              )
              setC5R4VNo(5);
          } else if (c5r4MT === "M") {      
            setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                      ]
                    )
                    setC5R3VNo(5);
                    setC5R4VNo(5);
         } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
         }
    } else if (curX === col05X && curY === row04X && c5R4VNo === 9) { 
      if (c5r4MT === "S") {  
        setRow4Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
               ]
             )
             setC5R4VNo(5);
         } else if (c5r4MT === "M") {      
          setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                      ]
                    )
                    setC5R3VNo(5);
                    setC5R4VNo(5);
        } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }	
    } else if (curX === col05X && curY === row04X && c5R4VNo === 10) { 
      if (c5r4MT === "S") {  
        setRow4Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
               ]
             )
             setC5R4VNo(5);
         } else if (c5r4MT === "M") {      
          setRow3NakedM05(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                       ]
                     )
                     setC5R3VNo(5);
                     setC5R4VNo(5);
        } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }	
    } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {  
      if (c5r4MT === "S") {  
        setRow4Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                ]
              )
              setC5R4VNo(5);
          } else if (c5r4MT === "M") {      
           setRow3NakedM05(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                        ]
                      )
                      setC5R3VNo(5);
                      setC5R4VNo(5);
         } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
         }	
      //6th  col
    } else if (curX === col06X && curY === row04X && c6R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col06X && curY === row04X && c6R4VNo === 2) {
      if (c6r4MT === "S") {  
        setRow4Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
               ]
             )
             setC6R4VNo(1);
         } else if (c6r4MT === "M") {      
          setRow3NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                      ]
                    )
                    setC6R3VNo(1);
                    setC6R4VNo(1);
        } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
        }
    } else if (curX === col06X && curY === row04X && c6R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col06X && curY === row04X && c6R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col06X && curY === row04X && c6R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col06X && curY === row04X && c6R4VNo === 6) {  
      if (c6r4MT === "S") {  
        setRow4Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
                ]
              )
              setC6R4VNo(3);
          } else if (c6r4MT === "M") {      
            setRow3NakedM06(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                       ]
                     )
                     setC6R3VNo(3);
                     setC6R4VNo(3);
         } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
         }	
    } else if (curX === col06X && curY === row04X && c6R4VNo === 7) { 
      if (c6r4MT === "S") {  
        setRow4Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
                ]
              )
              setC6R4VNo(4);
          } else if (c6r4MT === "M") {      
           setRow3NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
                      ]
                    )
                    setC6R3VNo(4);
                    setC6R4VNo(4);
         } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
         }	
    } else if (curX === col06X && curY === row04X && c6R4VNo === 8) { 
      if (c6r4MT === "S") {  
        setRow4Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
               ]
             )
             setC6R4VNo(5);
         } else if (c6r4MT === "M") {      
           setRow3NakedM06(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                     ]
                   )
                   setC6R3VNo(5);
                   setC6R4VNo(5);
        } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
        }
    } else if (curX === col06X && curY === row04X && c6R4VNo === 9) { 
      if (c6r4MT === "S") {  
        setRow4Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
               ]
             )
             setC6R4VNo(5);
         } else if (c6r4MT === "M") {      
            setRow3NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                      ]
                    )
                    setC6R3VNo(5);
                    setC6R4VNo(5);
        } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
        }
    } else if (curX === col06X && curY === row04X && c6R4VNo === 10) { 
      if (c6r4MT === "S") {  
        setRow4Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                ]
              )
              setC6R4VNo(5);
          } else if (c6r4MT === "M") {      
             setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                        ]
                      )
                      setC6R3VNo(5);
                      setC6R4VNo(5);
         } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
         }	
    } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {  
      if (c6r4MT === "S") {  
        setRow4Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
               ]
             )
             setC6R4VNo(5);
         } else if (c6r4MT === "M") {      
           setRow3NakedM06(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                       ]
                     )
                     setC6R3VNo(5);
                     setC6R4VNo(5);
        } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
        }	
      //7th col
    } else if (curX === col07X && curY === row04X && c7R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col07X && curY === row04X && c7R4VNo === 2) {
      if (c7r4MT === "S") {  
        setRow4Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
                ]
              )
              setC7R4VNo(1);
          } else if (c7r4MT === "M") {      
            setRow3NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                        ]
                      )
                      setC7R3VNo(1);
                      setC6R4VNo(1);
         } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
         }	
    } else if (curX === col07X && curY === row04X && c7R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col07X && curY === row04X && c7R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col07X && curY === row04X && c7R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col07X && curY === row04X && c7R4VNo === 6) {  
      if (c7r4MT === "S") {  
        setRow4Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
                ]
              )
              setC7R4VNo(3);
          } else if (c7r4MT === "M") {      
           setRow3NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                        ]
                      )
                      setC7R3VNo(3);
                      setC6R4VNo(3);
         } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
         }
    } else if (curX === col07X && curY === row04X && c7R4VNo === 7) { 
      if (c7r4MT === "S") {  
        setRow4Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                ]
              )
              setC7R4VNo(4);
          } else if (c7r4MT === "M") {      
           setRow3NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
                        ]
                      )
                      setC7R3VNo(4);
                      setC6R4VNo(4);
         } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
         }
    } else if (curX === col07X && curY === row04X && c7R4VNo === 8) { 
      if (c7r4MT === "S") {  
        setRow4Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                ]
              )
              setC7R4VNo(5);
          } else if (c7r4MT === "M") {      
           setRow3NakedM07(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                       ]
                     )
                     setC7R3VNo(5);
                     setC6R4VNo(5);
         } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
         }
    } else if (curX === col07X && curY === row04X && c7R4VNo === 9) { 
      if (c7r4MT === "S") {  
        setRow4Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
               ]
             )
             setC7R4VNo(5);
         } else if (c7r4MT === "M") {      
          setRow3NakedM07(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                      ]
                    )
                    setC7R3VNo(5);
                    setC6R4VNo(5);
        } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
    } else if (curX === col07X && curY === row04X && c7R4VNo === 10) { 
      if (c7r4MT === "S") {  
        setRow4Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
               ]
             )
             setC7R4VNo(5);
         } else if (c7r4MT === "M") {      
           setRow3NakedM07(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                     ]
                   )
                   setC7R3VNo(5);
                   setC6R4VNo(5);
        } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }	
    } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {  
      if (c7r4MT === "S") {  
        setRow4Naked07(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
               ]
             )
             setC7R4VNo(5);
         } else if (c7r4MT === "M") {      
           setRow3NakedM07(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                      ]
                    )
                    setC7R3VNo(5);
                    setC6R4VNo(5);
        } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
      //8th col
    } else if (curX === col08X && curY === row04X && c8R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col08X && curY === row04X && c8R4VNo === 2) {
      if (c8r4MT === "S") {  
        setRow4Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
                ]
              )
              setC8R4VNo(1);
          } else if (c8r4MT === "M") {      
            setRow3NakedM08(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                      ]
                    )
                    setC8R3VNo(1);
                    setC8R4VNo(1);
         } else if (c8r4MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
         }
    } else if (curX === col08X && curY === row04X && c8R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col08X && curY === row04X && c8R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col08X && curY === row04X && c8R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col08X && curY === row04X && c8R4VNo === 6) {  
      if (c8r4MT === "S") {  
        setRow4Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
               ]
             )
             setC8R4VNo(3);
         } else if (c8r4MT === "M") {      
           setRow3NakedM08(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                     ]
                   )
                   setC8R3VNo(3);
                   setC8R4VNo(3);
        } else if (c8r4MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
        }	
    } else if (curX === col08X && curY === row04X && c8R4VNo === 7) { 
      if (c8r4MT === "S") {  
        setRow4Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
               ]
             )
             setC8R4VNo(4);
         } else if (c8r4MT === "M") {      
          setRow3NakedM08(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
                     ]
                   )
                   setC8R3VNo(4);
                   setC8R4VNo(4);
        } else if (c8r4MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
        }	
    } else if (curX === col08X && curY === row04X && c8R4VNo === 8) { 
      if (c8r4MT === "S") {  
        setRow4Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
               ]
             )
             setC8R4VNo(5);
         } else if (c8r4MT === "M") {      
         setRow3NakedM08(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                     ]
                   )
                   setC8R3VNo(5);
                   setC8R4VNo(5);
        } else if (c8r4MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
        }
    } else if (curX === col08X && curY === row04X && c8R4VNo === 9) { 
      if (c8r4MT === "S") {  
        setRow4Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
              ]
            )
            setC8R4VNo(5);
        } else if (c8r4MT === "M") {      
         setRow3NakedM08(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                    ]
                  )
                  setC8R3VNo(5);
                  setC8R4VNo(5);
       } else if (c8r4MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }
    } else if (curX === col08X && curY === row04X && c8R4VNo === 10) { 
      if (c8r4MT === "S") {  
        setRow4Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
              ]
            )
            setC8R4VNo(5);
        } else if (c8r4MT === "M") {      
          setRow3NakedM08(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                    ]
                  )
                  setC8R3VNo(5);
                  setC8R4VNo(5);
       } else if (c8r4MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }
    } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {  
      if (c8r4MT === "S") {  
        setRow4Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
              ]
            )
            setC8R4VNo(5);
        } else if (c8r4MT === "M") {      
          setRow3NakedM08(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                     ]
                   )
                   setC8R3VNo(5);
                   setC8R4VNo(5);
       } else if (c8r4MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(5);
        setC8R2VNo(5);
        setC8R3VNo(5);
        setC8R4VNo(5);
       }	
      //9th col
    } else if (curX === col09X && curY === row04X && c9R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col09X && curY === row04X && c9R4VNo === 2) {
      if (c9r4MT === "S") {  
        setRow4Naked09(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
             ]
           )
           setC9R4VNo(1);
       } else if (c9r4MT === "M") {      
        setRow3NakedM09(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                    ]
                  )
                  setC8R3VNo(1);
                  setC9R4VNo(1);
      } else if (c9r4MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
        setC9R2VNo(1);
        setC9R3VNo(1);
        setC9R4VNo(1);
      }
    } else if (curX === col09X && curY === row04X && c9R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col09X && curY === row04X && c9R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col09X && curY === row04X && c9R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col09X && curY === row04X && c9R4VNo === 6) {  
      if (c9r4MT === "S") {  
        setRow4Naked09(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
             ]
           )
           setC9R4VNo(3);
       } else if (c9r4MT === "M") {      
        setRow3NakedM09(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                    ]
                  )
                  setC8R3VNo(3);
                  setC9R4VNo(3);
      } else if (c9r4MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
        setC9R3VNo(3);
        setC9R4VNo(3);
      }	
    } else if (curX === col09X && curY === row04X && c9R4VNo === 7) { 
      if (c9r4MT === "S") {  
        setRow4Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
              ]
            )
            setC9R4VNo(4);
        } else if (c9r4MT === "M") {      
         setRow3NakedM09(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                      ]
                    )
                    setC8R3VNo(4);
                    setC9R4VNo(4);
       } else if (c9r4MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(4);
        setC9R2VNo(4);
        setC9R3VNo(4);
        setC9R4VNo(4);
       }	
    } else if (curX === col09X && curY === row04X && c9R4VNo === 8) { 
      if (c9r4MT === "S") {  
        setRow4Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
              ]
            )
            setC9R4VNo(5);
        } else if (c9r4MT === "M") {      
         setRow3NakedM09(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                     ]
                   )
                   setC8R3VNo(5);
                   setC9R4VNo(5);
       } else if (c9r4MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(5);
        setC9R2VNo(5);
        setC9R3VNo(5);
        setC9R4VNo(5);
       }
    } else if (curX === col09X && curY === row04X && c9R4VNo === 9) { 
      if (c9r4MT === "S") {  
        setRow4Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
               ]
             )
             setC9R4VNo(5);
         } else if (c9r4MT === "M") {      
           setRow3NakedM09(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                      ]
                    )
                    setC8R3VNo(5);
                    setC9R4VNo(5);
        } else if (c9r4MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }
    } else if (curX === col09X && curY === row04X && c9R4VNo === 10) { 
      if (c9r4MT === "S") {  
        setRow4Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
               ]
             )
             setC9R4VNo(5);
         } else if (c9r4MT === "M") {      
           setRow3NakedM09(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                      ]
                    )
                    setC8R3VNo(5);
                    setC9R4VNo(5);
        } else if (c9r4MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
        }
    } else if (curX === col09X && curY === row04X && c9R4VNo === 11) {  
      if (c9r4MT === "S") {  
        setRow4Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                ]
              )
              setC9R4VNo(5);
          } else if (c9r4MT === "M") {      
            setRow3NakedM09(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                        ]
                      )
                      setC8R3VNo(5);
                      setC9R4VNo(5);
         } else if (c9r4MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
         }	
      // 10th col
    } else if (curX === col10X && curY === row04X && c10R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col10X && curY === row04X && c10R4VNo === 2) {
      if (c10r4MT === "S") {  
        setRow4Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
               ]
             )
             setC10R4VNo(1);
         } else if (c10r4MT === "M") {      
            setRow3NakedM10(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                      ]
                    )
                    setC10R3VNo(1);
                    setC10R4VNo(1);
        } else if (c10r4MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
        }	
    } else if (curX === col10X && curY === row04X && c10R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col10X && curY === row04X && c10R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col10X && curY === row04X && c10R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col10X && curY === row04X && c10R4VNo === 6) {  
      if (c10r4MT === "S") {  
        setRow4Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
               ]
             )
             setC10R4VNo(3);
         } else if (c10r4MT === "M") {      
            setRow3NakedM10(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                     ]
                   )
                   setC10R3VNo(3);
                   setC10R4VNo(3);
        } else if (c10r4MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
        }	
    } else if (curX === col10X && curY === row04X && c10R4VNo === 7) { 
      if (c10r4MT === "S") {  
        setRow4Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
                ]
              )
              setC10R4VNo(4);
          } else if (c10r4MT === "M") {      
             setRow3NakedM10(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                       ]
                     )
                     setC10R3VNo(4);
                     setC10R4VNo(4);
         } else if (c10r4MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
         }	
    } else if (curX === col10X && curY === row04X && c10R4VNo === 8) { 
      if (c10r4MT === "S") {  
        setRow4Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
               ]
             )
             setC10R4VNo(5);
         } else if (c10r4MT === "M") {      
            setRow3NakedM10(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                      ]
                    )
                    setC10R3VNo(5);
                    setC10R4VNo(5);
        } else if (c10r4MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
        }
    } else if (curX === col10X && curY === row04X && c10R4VNo === 9) { 
      if (c10r4MT === "S") {  
        setRow4Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
              ]
            )
            setC10R4VNo(5);
        } else if (c10r4MT === "M") {      
            setRow3NakedM10(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                     ]
                   )
                   setC10R3VNo(5);
                   setC10R4VNo(5);
       } else if (c10r4MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
       }
    } else if (curX === col10X && curY === row04X && c10R4VNo === 10) { 
      if (c10r4MT === "S") {  
        setRow4Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
             ]
           )
           setC10R4VNo(5);
       } else if (c10r4MT === "M") {      
           setRow3NakedM10(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                   ]
                 )
                 setC10R3VNo(5);
                 setC10R4VNo(5);
      } else if (c10r4MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
      }	
    } else if (curX === col10X && curY === row04X && c10R4VNo === 11) {  
      if (c10r4MT === "S") {  
        setRow4Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
             ]
           )
           setC10R4VNo(5);
       } else if (c10r4MT === "M") {      
           setRow3NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                  ]
                )
                setC10R3VNo(5);
                setC10R4VNo(5);
      } else if (c10r4MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(5);
        setC10R2VNo(5);
        setC10R3VNo(5);
        setC10R4VNo(5);
      }	
      //11th col
    } else if (curX === col11X && curY === row04X && c11R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col11X && curY === row04X && c11R4VNo === 2) {
      if (c11r4MT === "S") {  
        setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
            ]
          )
          setC11R4VNo(1);
      } else if (c11r4MT === "M") {      
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(1);
        setC11R4VNo(1);
     } else if (c11r4MT === "L") {  
      setBaseNakedL11(
        [
          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
        ]
      )
      setC11R1VNo(1);
      setC11R2VNo(1);
      setC11R3VNo(1);
      setC11R4VNo(1);
     }	
    } else if (curX === col11X && curY === row04X && c11R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col11X && curY === row04X && c11R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col11X && curY === row04X && c11R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col11X && curY === row04X && c11R4VNo === 6) {  
      if (c11r4MT === "S") {  
        setRow4Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
             ]
           )
           setC11R4VNo(3);
       } else if (c11r4MT === "M") {      
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(3);
        setC11R4VNo(3);
      } else if (c11r4MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
      }	
    } else if (curX === col11X && curY === row04X && c11R4VNo === 7) { 
      if (c11r4MT === "S") {  
        setRow4Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
             ]
           )
           setC11R4VNo(4);
       } else if (c11r4MT === "M") {      
           setRow3NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                  ]
                )
                setC11R3VNo(4);
                setC11R4VNo(4);
      } else if (c11r4MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(4);
        setC11R2VNo(4);
        setC11R3VNo(4);
        setC11R4VNo(4);
      }	
    } else if (curX === col11X && curY === row04X && c11R4VNo === 8) { 
      if (c11r4MT === "S") {  
        setRow4Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
             ]
           )
           setC11R4VNo(5);
       } else if (c11r4MT === "M") {      
          setRow3NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                  ]
                )
                setC11R3VNo(5);
                setC11R4VNo(5);
      } else if (c11r4MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
      }	
    } else if (curX === col11X && curY === row04X && c11R4VNo === 9) { 
      if (c11r4MT === "S") {  
        setRow4Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
              ]
            )
            setC11R4VNo(5);
        } else if (c11r4MT === "M") {      
            setRow3NakedM11(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                   ]
                 )
                 setC11R3VNo(5);
                 setC11R4VNo(5);
       } else if (c11r4MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(5);
        setC11R2VNo(5);
        setC11R3VNo(5);
        setC11R4VNo(5);
       }
    } else if (curX === col11X && curY === row04X && c11R4VNo === 10) { 
      if (c11r4MT === "S") {  
        setRow4Naked11(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
               ]
             )
             setC11R4VNo(5);
         } else if (c11r4MT === "M") {      
             setRow3NakedM11(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                    ]
                  )
                  setC11R3VNo(5);
                  setC11R4VNo(5);
        } else if (c11r4MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
        }	
    } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {  
      if (c11r4MT === "S") {  
        setRow4Naked11(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
               ]
             )
             setC11R4VNo(5);
         } else if (c11r4MT === "M") {      
          setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(5);
          setC11R4VNo(5);
        } else if (c11r4MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
        }	
      //12th col
    } else if (curX === col12X && curY === row04X && c12R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col12X && curY === row04X && c12R4VNo === 2) {
      if (c12r4MT === "S") {  
        setRow4Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
               ]
             )
             setC12R4VNo(1);
         } else if (c12r4MT === "M") {      
              setRow3NakedM12(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                      ]
                    )
                    setC12R3VNo(1);
                    setC12R4VNo(1);
        } else if (c12r4MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
        }	
    } else if (curX === col12X && curY === row04X && c12R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col12X && curY === row04X && c12R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col12X && curY === row04X && c12R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col12X && curY === row04X && c12R4VNo === 6) {  
      if (c12r4MT === "S") {  
        setRow4Naked12(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
               ]
             )
             setC12R4VNo(3);
         } else if (c12r4MT === "M") {      
             setRow3NakedM12(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                      ]
                    )
                    setC12R3VNo(3);
                    setC12R4VNo(3);
        } else if (c12r4MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
        }
    } else if (curX === col12X && curY === row04X && c12R4VNo === 7) { 
      if (c12r4MT === "S") {  
        setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(4);
          } else if (c12r4MT === "M") {      
              setRow3NakedM12(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                        ]
                      )
                      setC12R3VNo(4);
                      setC12R4VNo(4);
         } else if (c12r4MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
         }	
    } else if (curX === col12X && curY === row04X && c12R4VNo === 8) { 
      if (c12r4MT === "S") {  
        setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(5);
          } else if (c12r4MT === "M") {      
               setRow3NakedM12(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                        ]
                      )
                      setC12R3VNo(5);
                      setC12R4VNo(5);
         } else if (c12r4MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }	
    } else if (curX === col12X && curY === row04X && c12R4VNo === 9) { 
      if (c12r4MT === "S") {  
        setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(5);
          } else if (c12r4MT === "M") {      
              setRow3NakedM12(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                        ]
                      )
                      setC12R3VNo(5);
                      setC12R4VNo(5);
         } else if (c12r4MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }	
    } else if (curX === col12X && curY === row04X && c12R4VNo === 10) { 
      if (c12r4MT === "S") {  
        setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(5);
          } else if (c12r4MT === "M") {      
               setRow3NakedM12(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                       ]
                     )
                     setC12R3VNo(5);
                     setC12R4VNo(5);
         } else if (c12r4MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }
    } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {  
      if (c12r4MT === "S") {  
        setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(5);
          } else if (c12r4MT === "M") {      
              setRow3NakedM12(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                       ]
                     )
                     setC12R3VNo(5);
                     setC12R4VNo(5);
         } else if (c12r4MT === "L") {  
           setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
         }	
      //13th col
    } else if (curX === col13X && curY === row04X && c13R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col13X && curY === row04X && c13R4VNo === 2) {
      if (c13r4MT === "S") {  
        setRow4Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
               ]
             )
             setC13R4VNo(1);
         } else if (c13r4MT === "M") {      
              setRow3NakedM13(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                      ]
                    )
                    setC13R3VNo(1);
                    setC13R4VNo(1);
        } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
        }
    } else if (curX === col13X && curY === row04X && c13R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col13X && curY === row04X && c13R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col13X && curY === row04X && c13R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col13X && curY === row04X && c13R4VNo === 6) {  
      if (c13r4MT === "S") {  
        setRow4Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
               ]
             )
             setC13R4VNo(3);
         } else if (c13r4MT === "M") {      
              setRow3NakedM13(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                      ]
                    )
                    setC13R3VNo(3);
                    setC13R4VNo(3);
        } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
        }	
    } else if (curX === col03X && curY === row04X && c13R4VNo === 7) { 
      if (c13r4MT === "S") {  
        setRow4Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
               ]
             )
             setC13R4VNo(4);
         } else if (c13r4MT === "M") {      
             setRow3NakedM13(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                      ]
                    )
                    setC13R3VNo(4);
                    setC13R4VNo(4);
        } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
        }	
    } else if (curX === col13X && curY === row04X && c13R4VNo === 8) { 
      if (c13r4MT === "S") {  
        setRow4Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
               ]
             )
             setC13R4VNo(5);
         } else if (c13r4MT === "M") {      
             setRow3NakedM13(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                     ]
                   )
                   setC13R3VNo(5);
                   setC13R4VNo(5);
        } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
        }	
    } else if (curX === col13X && curY === row04X && c13R4VNo === 9) { 
      if (c13r4MT === "S") {  
        setRow4Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                ]
              )
              setC13R4VNo(5);
          } else if (c13r4MT === "M") {      
              setRow3NakedM13(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                      ]
                    )
                    setC13R3VNo(5);
                    setC13R4VNo(5);
         } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
         }	
    } else if (curX === col13X && curY === row04X && c13R4VNo === 10) { 
      if (c13r4MT === "S") {  
        setRow4Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                ]
              )
              setC13R4VNo(5);
          } else if (c13r4MT === "M") {      
             setRow3NakedM13(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                      ]
                    )
                    setC13R3VNo(5);
                    setC13R4VNo(5);
         } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
         }	
    } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {  
      if (c13r4MT === "S") {  
        setRow4Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
               ]
             )
             setC13R4VNo(5);
         } else if (c13r4MT === "M") {      
            setRow3NakedM13(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                     ]
                   )
                   setC13R3VNo(5);
                   setC13R4VNo(5);
        } else if (c13r4MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
        }	
      // 14th col
    } else if (curX === col14X && curY === row04X && c14R4VNo === 1) {
      alert("Already removed");
    } else if (curX === col14X && curY === row04X && c14R4VNo === 2) {
      if (c14r4MT === "S") {  
        setRow4Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
               ]
             )
             setC14R4VNo(1);
         } else if (c14r4MT === "M") {      
           setRow3NakedM14(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                     ]
                   )
                   setC14R3VNo(1);
                   setC14R3VNo(1);
        } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
        }	
    } else if (curX === col14X && curY === row04X && c14R4VNo === 3) {
      alert("Already removed");
    } else if (curX === col14X && curY === row04X && c14R4VNo === 4) {
      alert("Already removed");  
    } else if (curX === col14X && curY === row04X && c14R4VNo === 5) {
      alert("Already removed");
    } else if (curX === col14X && curY === row04X && c14R4VNo === 6) {  
      if (c14r4MT === "S") {  
        setRow4Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
               ]
             )
             setC14R4VNo(3);
         } else if (c14r4MT === "M") {      
            setRow3NakedM14(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                     ]
                   )
                   setC14R3VNo(3);
                   setC14R4VNo(3);
        } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
        }	
    } else if (curX === col14X && curY === row04X && c14R4VNo === 7) { 
      if (c14r4MT === "S") {  
        setRow4Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
              ]
            )
            setC14R4VNo(4);
        } else if (c14r4MT === "M") {      
            setRow3NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                    ]
                  )
                  setC14R3VNo(4);
                  setC14R4VNo(4);
       } else if (c14r4MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(4);
        setC14R2VNo(4);
        setC14R3VNo(4);
        setC14R4VNo(4);
       }	
    } else if (curX === col14X && curY === row04X && c14R4VNo === 8) { 
      if (c14r4MT === "S") {  
        setRow4Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
               ]
             )
             setC14R4VNo(5);
         } else if (c14r4MT === "M") {      
             setRow3NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                    ]
                  )
                  setC14R3VNo(5);
                  setC14R4VNo(5);
        } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
        }	
    } else if (curX === col14X && curY === row04X && c14R4VNo === 9) { 
      if (c14r4MT === "S") {  
        setRow4Naked14(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                ]
              )
              setC14R4VNo(5);
          } else if (c14r4MT === "M") {      
              setRow3NakedM14(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                     ]
                   )
                   setC14R3VNo(5);
                   setC14R4VNo(5);
         } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
         }
    } else if (curX === col14X && curY === row04X && c14R4VNo === 10) { 
      if (c14r4MT === "S") {  
        setRow4Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
               ]
             )
             setC14R4VNo(5);
         } else if (c14r4MT === "M") {      
             setRow3NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                    ]
                  )
                  setC14R3VNo(5);
                  setC14R4VNo(5);
        } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
        }	
    } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {  
      if (c14r4MT === "S") {  
        setRow4Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
               ]
             )
             setC14R4VNo(5);
         } else if (c14r4MT === "M") {      
              setRow3NakedM14(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                     ]
                   )
                   setC14R3VNo(5);
                   setC14R3VNo(5);
        } else if (c14r4MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
        }
    } 
  
}

// Adding Left Panel
const addLeftPanel = (e)=> {

    if (curX === col01X && curY === row01X && c1R1VNo === 1) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
                  ]
                )
              
                  setC1R1VNo(3);
        } else if (c1r1MT === "M") {      
            setBaseNakedM01(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                  ]
                )
              
                  setC1R1VNo(3);
                  setC1R2VNo(3);
       } else if (c1r1MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setC1R3VNo(3);
        setC1R4VNo(3);
       }
    } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
                 ]
               )
         
               setC1R1VNo(6);
       } else if (c1r1MT === "M") {      
             setBaseNakedM01(
                 [
                   <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                 ]
               )
         
           setC1R1VNo(6);
           setC1R2VNo(6);
      } else if (c1r1MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }	
    } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
             alert("Already Added");
            //setC1R1VNo(6);
    } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
                setC1R1VNo(5);
      } else if (c1r1MT === "M") {      
           setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                ]
              )
                setC1R1VNo(5);
                setC1R2VNo(5);
     } else if (c1r1MT === "L") {  
      setBaseNakedL01(
        [
          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
        ]
      )
      setC1R1VNo(5);
      setC1R2VNo(5);
      setC1R3VNo(5);
      setC1R4VNo(5);
     }	
    } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {  
            alert("Already Added");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {
            alert("Already Added");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 7) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
        
                setC1R1VNo(8);
      } else if (c1r1MT === "M") {      
          setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                ]
              )
        
                setC1R1VNo(8);
                setC1R2VNo(8);
     } else if (c1r1MT === "L") {  
      setBaseNakedL01(
        [
          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
        ]
      )
      setC1R1VNo(8);
      setC1R2VNo(8);
      setC1R3VNo(8);
      setC1R4VNo(8);
     }	
    } else if (curX === col01X && curY === row01X && c1R1VNo === 8) {
            alert("Already Added");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 9) {
      alert("Already Added");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 10) {
      alert("Already Added");
    } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {
      alert("Already Added");
      //2nd col
    } else if (curX === col02X && curY === row01X && c2R1VNo === 1) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
                  ]
                )
              
                  setC2R1VNo(3);
        } else if (c2r1MT === "M") {      
            setBaseNakedM02(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                    
                        setC2R1VNo(3);
                  setC2R2VNo(3);
       } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setC2R3VNo(3);
        setC2R4VNo(3);
       }	
    } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
                 ]
               )
         
           setC2R1VNo(6);
       } else if (c2r1MT === "M") {      
           setBaseNakedM02(
                       [
                         <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                       ]
                     )
               
                 setC2R1VNo(6);
                 setC2R2VNo(6);
      } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
    } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
              alert("Already Added");
            //setC1R1VNo(6);
    } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                 ]
               )
                 setC2R1VNo(5);
       } else if (c2r1MT === "M") {      
            setBaseNakedM02(
                       [
                         <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                       ]
                     )
                       setC2R1VNo(5);
                       setC2R2VNo(5);
      } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(5);
        setC2R2VNo(5);
        setC2R3VNo(5);
        setC2R4VNo(5)
      }	
    } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {  
            alert("Already Added");
    } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {
            alert("Already Added");
    } else if (curX === col02X && curY === row01X && c2R1VNo === 7) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
                 [
                   <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                 ]
               )
           
                   setC2R1VNo(8);
       } else if (c2r1MT === "M") {      
            setBaseNakedM02(
                       [
                         <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                       ]
                     )
               
                       setC2R1VNo(8);
                       setC2R2VNo(8);
      } else if (c2r1MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(8);
        setC2R2VNo(8);
        setC2R3VNo(8);
        setC2R4VNo(8);
      }
    } else if (curX === col02X && curY === row01X && c2R1VNo === 8) {
              alert("Already Added");
            } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
              alert("Already Added");
            } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
              alert("Already Added");
            } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
              alert("Already Added");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
        if (c3r1MT === "S") {  
          setBaseNaked03(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
               
                   setC3R1VNo(3);
         } else if (c3r1MT === "M") {      
             setBaseNakedM03(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
               
                   setC3R1VNo(3);
             setC3R2VNo(3);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
        if (c3r1MT === "S") {  
          setBaseNaked03(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
          
            setC3R1VNo(6);
        } else if (c3r1MT === "M") {      
            setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
          
            setC3R1VNo(6);
            setC3R2VNo(6);
       } else if (c3r1MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
       }	
      } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
              alert("Already Added");

      } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
        if (c3r1MT === "S") {  
          setBaseNaked03(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                   setC3R1VNo(5);
         } else if (c3r1MT === "M") {      
             setBaseNakedM03(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                   setC3R1VNo(5);
                   setC3R2VNo(5);
        } else if (c3r1MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
        }	
    } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {  
            alert("Already Added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {
            alert("Already Added");
    } else if (curX === col03X && curY === row01X && c3R1VNo === 7) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
        
                setC3R1VNo(8);
      } else if (c3r1MT === "M") {      
         setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
        
                setC3R1VNo(8);
                setC3R2VNo(8);
     } else if (c3r1MT === "L") {  
      setBaseNakedL03(
        [
          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
        ]
      )
      setC3R1VNo(8);
      setC3R2VNo(8);
      setC3R3VNo(8);
      setC3R4VNo(8);
     }	 
    } else if (curX === col03X && curY === row01X && c3R1VNo === 8) {
            alert("Already Added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 9) {
            alert("Already Added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
            alert("Already Added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
            alert("Already Added");
    } else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
      
          setC4R1VNo(3);
      } else if (c4r1MT === "M") {   


       setBaseNakedM04(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
        ]
      )
    
      setC4R1VNo(3);
      setC4R2VNo(3);
    } else if (c4r1MT === "L") {  
      setBaseNakedL04(
        [
          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
        ]
      )
      setC4R1VNo(3);
      setC4R2VNo(3);
      setC4R3VNo(3);
      setC3R4VNo(3);
    }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
        if (c4r1MT === "S") {  
          setBaseNaked04(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
            
              setC4R1VNo(6);
        } else if (c4r1MT === "M") {      
            setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
          
            setC4R1VNo(6);
            setC4R2VNo(6);
       } else if (c4r1MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
       }	
      } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
                alert("Already Added");
              //setC1R1VNo(6);
      } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
        if (c4r1MT === "S") {  
          setBaseNaked04(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                   setC4R1VNo(5);
       } else if (c4r1MT === "M") {      
          setBaseNakedM04(
                 [
                   <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                 ]
               )
                 setC4R1VNo(5);
                 setC4R2VNo(5);
      } else if (c4r1MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
      }	
      } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {  
              alert("Already Added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {
              alert("Already Added");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 7) {
        if (c4r1MT === "S") {  
          setBaseNaked04(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
          
                  setC4R1VNo(8);
      } else if (c4r1MT === "M") {      
          setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                ]
              )
        
                setC4R1VNo(8);
                setC4R2VNo(8);
     } else if (c4r1MT === "L") {  
      setBaseNakedL04(
        [
          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
        ]
      )
      setC4R1VNo(8);
      setC4R2VNo(8);
      setC4R3VNo(8);
      setC4R4VNo(8);
     }	
      } else if (curX === col04X && curY === row01X && c4R1VNo === 8) {
              alert("Already Added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 9) {
              alert("Already Added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
              alert("Already Added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
              alert("Already Added");
        } else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
          if (c5r1MT === "S") {  
            setBaseNaked05(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
                          ]
                        )
                      
                          setC5R1VNo(3);
          } else if (c5r1MT === "M") {      
               setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                
                    setC5R1VNo(3);
              setC5R2VNo(3);
         } else if (c5r1MT === "L") {  
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(3);
            setC5R2VNo(3);
            setC5R3VNo(3);
            setC5R4VNo(3);
         }	
        } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
          if (c5r1MT === "S") {  
            setBaseNaked05(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
                          ]
                        )
                  
                    setC5R1VNo(6);
          } else if (c5r1MT === "M") {      
              setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
            
              setC5R1VNo(6);
              setC5R2VNo(6);
         } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
         }	
        } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
                    alert("Already Added");
                  //setC1R1VNo(6);
        } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
          if (c5r1MT === "S") {  
            setBaseNaked05(
                         [
                           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                         ]
                       )
                         setC5R1VNo(5);
         } else if (c5r1MT === "M") {      
             setBaseNakedM05(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                   setC5R1VNo(5);
                   setC5R2VNo(5);
        } else if (c5r1MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }	
        } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {  
                  alert("Already Added");
        } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {
                  alert("Already Added");
        } else if (curX === col05X && curY === row01X && c5R1VNo === 7) {
                if (c5r1MT === "S") {  
                  setBaseNaked05(
                              [
                                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                      
                              setC5R1VNo(8);
              } else if (c5r1MT === "M") {      
                setBaseNakedM05(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                        ]
                      )
                
                        setC5R1VNo(8);
                        setC5R2VNo(8);
            } else if (c5r1MT === "L") {  
              setBaseNakedL05(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(8);
              setC5R2VNo(8);
              setC5R3VNo(8);
              setC5R4VNo(8);
            }	
        } else if (curX === col05X && curY === row01X && c5R1VNo === 8) {
                  alert("Already Added");
                } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
                  alert("Already Added");
                } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
                  alert("Already Added");
                } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
                  alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
        if (c6r1MT === "S") {  
          setBaseNaked06(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
                    
                        setC6R1VNo(3);
            } else if (c6r1MT === "M") {      
                setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                  
                      setC6R1VNo(3);
                setC6R2VNo(3);
           } else if (c6r1MT === "L") {  
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(3);
            setC6R2VNo(3);
            setC6R3VNo(3);
            setC6R4VNo(3);
           }	
      } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
        if (c6r1MT === "S") {  
          setBaseNaked06(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
                       ]
                     )
               
                 setC6R1VNo(6);
           } else if (c6r1MT === "M") {      
               setBaseNakedM06(
                     [
                       <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                     ]
                   )
             
               setC6R1VNo(6);
               setC6R2VNo(6);
          } else if (c6r1MT === "L") {  
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(6);
            setC6R2VNo(6);
            setC6R3VNo(6);
            setC6R4VNo(6);
          }	
      } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
                alert("Already Added");
              //setC1R1VNo(6);
      } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
        if (c6r1MT === "S") {  
          setBaseNaked06(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                      setC6R1VNo(5);
          } else if (c6r1MT === "M") {      
              setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                    setC6R1VNo(5);
                    setC6R2VNo(5);
         } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
  
         }	
      } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {  
              alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {
              alert("Already Added");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 7) {
        if (c6r1MT === "S") {  
          setBaseNaked06(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
              
                      setC6R1VNo(8);
          } else if (c6r1MT === "M") {      
              setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
            
                    setC6R1VNo(8);
                    setC6R2VNo(8);
         } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
         }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 8) {
              alert("Already Added");
            } else if (curX === col06X && curY === row01X && c6R1VNo === 9) {
              alert("Already Added");
            } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
              alert("Already Added");
            } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
              alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
        if (c7r1MT === "S") {  
          setBaseNaked07(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                  
                      setC7R1VNo(3);
          } else if (c7r1MT === "M") {      
             setBaseNakedM07(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                
                    setC7R1VNo(3);
              setC7R2VNo(3);
         } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
         }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
        if (c7r1MT === "S") {  
          setBaseNaked07(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                       
                 setC7R1VNo(6);
         } else if (c7r1MT === "M") {      
             setBaseNakedM07(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
           
             setC7R1VNo(6);
             setC7R2VNo(6);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
        }	
      } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
                  alert("Already Added");
                //setC1R1VNo(6);
      } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
        if (c7r1MT === "S") {  
          setBaseNaked07(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                       ]
                     )
                       setC7R1VNo(5);
         } else if (c7r1MT === "M") {      
             setBaseNakedM07(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                   setC7R1VNo(5);
                   setC7R2VNo(5);
        } else if (c7r1MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {  
                alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {
                alert("Already Added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 7) {
        if (c7r1MT === "S") {  
          setBaseNaked07(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
              
                      setC7R1VNo(8);
        } else if (c7r1MT === "M") {      
            setBaseNakedM07(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                  ]
                )
          
                  setC7R1VNo(8);
                  setC7R2VNo(8);
       } else if (c7r1MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(8);
        setC7R2VNo(8);
        setC7R3VNo(8);
        setC7R4VNo(8);
       }	
      } else if (curX === col07X && curY === row01X && c7R1VNo === 8) {
                alert("Already Added");
              } else if (curX === col07X && curY === row01X && c7R1VNo === 9) {
                alert("Already Added");
              } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
                alert("Already Added");
              } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
                alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
        if (c8r1MT === "S") {  
          setBaseNaked08(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                  
                      setC8R1VNo(3);
        } else if (c8r1MT === "M") {      
            setBaseNakedM08(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                  ]
                )
              
                  setC8R1VNo(3);
            setC8R2VNo(3);
       } else if (c8r1MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setC8R3VNo(3);
        setC8R4VNo(3);
       }	
      } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
        if (c8r1MT === "S") {  
          setBaseNaked08(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
                       ]
                     )
               
                 setC8R1VNo(6);
         } else if (c8r1MT === "M") {      
             setBaseNakedM08(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
           
             setC8R1VNo(6);
             setC8R2VNo(6);
        } else if (c8r1MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
        }	
      } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
                  alert("Already Added");
                //setC1R1VNo(6);
      } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
              if (c8r1MT === "S") {  
                setBaseNaked08(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                        ]
                      )
                        setC8R1VNo(5);
          } else if (c8r1MT === "M") {      
              setBaseNakedM08(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                    setC8R1VNo(5);
                    setC8R2VNo(5);
          } else if (c8r1MT === "L") {  
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(5);
            setC8R2VNo(5);
            setC8R3VNo(5);
            setC8R4VNo(5);
          }	
     } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {  
                alert("Already Added");
              } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {
                alert("Already Added");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 7) {
        if (c8r1MT === "S") {  
          setBaseNaked08(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                  ]
                )
          
                  setC8R1VNo(8);
    } else if (c8r1MT === "M") {      
        setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
              ]
            )
      
              setC8R1VNo(8);
              setC8R2VNo(8);
   } else if (c8r1MT === "L") {  
    setBaseNakedL08(
      [
        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
      ]
    )
    setC8R1VNo(8);
    setC8R2VNo(8);
    setC8R3VNo(8);
    setC8R4VNo(8);
   }	
      } else if (curX === col08X && curY === row01X && c8R1VNo === 8) {
                alert("Already Added");
              } else if (curX === col08X && curY === row01X && c8R1VNo === 9) {
                alert("Already Added");
              } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
                alert("Already Added");
              } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
                alert("Already Added");
      } else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
            if (c9r1MT === "S") {  
                setBaseNaked09(
                              [
                                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                          
                              setC9R1VNo(3);
            } else if (c9r1MT === "M") {      
                setBaseNakedM09(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                  
                      setC9R1VNo(3);
                setC9R2VNo(3);
            } else if (c9r1MT === "L") {  
              setBaseNakedL09(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(3);
              setC9R2VNo(3);
              setC9R3VNo(3);
              setC9R4VNo(3);
            }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
          if (c9r1MT === "S") {  
            setBaseNaked09(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
              ]
            )
      
              setC9R1VNo(6);
            } else if (c9r1MT === "M") {      
                setBaseNakedM09(
              [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
              ]
              )
              setC9R1VNo(6);
              setC9R2VNo(6);
            } else if (c9r1MT === "L") {  
              setBaseNakedL09(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(6);
              setC9R2VNo(6);
              setC9R3VNo(6);
              setC9R4VNo(6);
            }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
                      alert("Already Added");
                    //setC1R1VNo(6);
      } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
        if (c9r1MT === "S") {  
          setBaseNaked09(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                  ]
                )
                  setC9R1VNo(5);
          } else if (c9r1MT === "M") {      
              setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
            )
          setC9R1VNo(5);
          setC9R2VNo(5);
          } else if (c9r1MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(5);
            setC9R2VNo(5);
            setC9R3VNo(5);
            setC9R4VNo(5);
          }	 
        } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {  
                    alert("Already Added");
                  } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {
                    alert("Already Added");
        } else if (curX === col09X && curY === row01X && c9R1VNo === 7) {
          if (c9r1MT === "S") {  
            setBaseNaked09(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                    ]
                  )
            
                    setC9R1VNo(8);
            } else if (c9r1MT === "M") {      
                setBaseNakedM09(
            	[
              	<Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            	]
          		)
    
            setC9R1VNo(8);
            setC9R2VNo(8);
            } else if (c9r1MT === "L") {  
              setBaseNakedL09(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(8);
              setC9R2VNo(8);
              setC9R3VNo(8);
              setC9R4VNo(8);
            }
        } else if (curX === col09X && curY === row01X && c9R1VNo === 8) {
                    alert("Already Added");
                  } else if (curX === col09X && curY === row01X && c9R1VNo === 9) {
                    alert("Already Added");
                  } else if (curX === col09X && curY === row01X && c9R1VNo === 10) {
                    alert("Already Added");
                  } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
                    alert("Already Added");
          }  else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
            if (c10r1MT === "S") {  
              setBaseNaked10(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                  
                      setC10R1VNo(3);
              } else if (c10r1MT === "M") {      
                   setBaseNakedM10(
                [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                ]
                )
          
                  setC10R1VNo(3);
              setC10R2VNo(3);
              } else if (c10r1MT === "L") {  
                setBaseNakedL10(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(3);
                setC10R2VNo(3);
                setC10R3VNo(3);
                setC10R4VNo(3);
              }	
        } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
          if (c10r1MT === "S") {  
            setBaseNaked10(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
            
              setC10R1VNo(6);
           } else if (c10r1MT === "M") {      
                      setBaseNakedM10(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
          
            setC10R1VNo(6);
            setC10R2VNo(6);
            } else if (c10r1MT === "L") {  
              setBaseNakedL10(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(6);
              setC10R2VNo(6);
              setC10R3VNo(6);
              setC10R4VNo(6);
            }	
        } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
                      alert("Already Added");
            
      } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
        if (c10r1MT === "S") {  
          setBaseNaked10(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                  ]
                )
                  setC10R1VNo(5);
          } else if (c10r1MT === "M") {      
                setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          } else if (c10r1MT === "L") {  
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
            setC10R3VNo(5);
            setC10R4VNo(5);
          }	
      } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {  
                    alert("Already Added");
                  } else if (curX === col10X && curY === row01X && c10R1VNo === 6) {
                    alert("Already Added");
        } else if (curX === col10X && curY === row01X && c10R1VNo === 7) {
          if (c10r1MT === "S") {  
            setBaseNaked10(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
             
                     setC10R1VNo(8);
             } else if (c10r1MT === "M") {      
                   setBaseNakedM10(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
             ]
           )
     
             setC10R1VNo(8);
             setC10R2VNo(8);
             } else if (c10r1MT === "L") {  
              setBaseNakedL10(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(8);
              setC10R2VNo(8);
              setC10R3VNo(8);
              setC10R4VNo(8);
      
             }	
        } else if (curX === col10X && curY === row01X && c10R1VNo === 8) {
                    alert("Already Added");
                  } else if (curX === col10X && curY === row01X && c10R1VNo === 9) {
                    alert("Already Added");
                  } else if (curX === col10X && curY === row01X && c10R1VNo === 10) {
                    alert("Already Added");
                  } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
                    alert("Already Added");
          } else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
            if (c11r1MT === "S") {  
              setBaseNaked11(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                  
                      setC11R1VNo(3);
              } else if (c11r1MT === "M") {      
                    setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
                )
          
              setC11R1VNo(3);
              setC11R2VNo(3);
              } else if (c11r1MT === "L") {  
                setBaseNakedL11(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(3);
                setC11R2VNo(3);
                setC11R3VNo(3);
                setC11R4VNo(3);
              }	
          } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
            if (c11r1MT === "S") {  
              setBaseNaked11(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
                     ]
                   )
             
               setC11R1VNo(6);
             } else if (c11r1MT === "M") {      
                 setBaseNakedM11(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
             ]
           )
     
           setC11R1VNo(6);
           setC11R2VNo(6);
             } else if (c11r1MT === "L") {  
              setBaseNakedL11(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(6);
              setC11R2VNo(6);
              setC11R3VNo(6);
              setC11R4VNo(6);
             }	
          } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
                      alert("Already Added");
                    //setC1R1VNo(6);
          } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
            if (c11r1MT === "S") {  
              setBaseNaked11(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                      setC11R1VNo(5);
              } else if (c11r1MT === "M") {      
                   setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
              setC11R1VNo(5);
              setC11R2VNo(5);
              } else if (c11r1MT === "L") {  
                setBaseNakedL11(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(5);
                setC11R2VNo(5);
                setC11R3VNo(5);
                setC11R4VNo(5);
              }	
            } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {  
                    alert("Already Added");
            } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {
                    alert("Already Added");
          } else if (curX === col11X && curY === row01X && c11R1VNo === 7) {
            if (c11r1MT === "S") {  
              setBaseNaked11(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
             
                     setC11R1VNo(8);
             } else if (c11r1MT === "M") {      
                  setBaseNakedM11(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
             ]
           )
     
             setC11R1VNo(8);
             setC11R2VNo(8);
             } else if (c11r1MT === "L") {  
              setBaseNakedL11(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(8);
              setC11R2VNo(8);
              setC11R3VNo(8);
              setC11R4VNo(8);
             }	
          } else if (curX === col11X && curY === row01X && c11R1VNo === 8) {
                    alert("Already Added");
                  } else if (curX === col11X && curY === row01X && c11R1VNo === 9) {
                    alert("Already Added");
                  } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
                    alert("Already Added");
                  } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
                    alert("Already Added");
        } else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
          if (c12r1MT === "S") {  
            setBaseNaked12(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                
                    setC12R1VNo(3);
            } else if (c12r1MT === "M") {      
                setBaseNakedM12(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
              ]
            )
          
              setC12R1VNo(3);
            setC12R2VNo(3);
            } else if (c12r1MT === "L") {  
              setBaseNakedL12(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(3);
              setC12R2VNo(3);
              setC12R3VNo(3);
              setC12R4VNo(3);
            }	
        } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
          if (c12r1MT === "S") {  
            setBaseNaked12(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
            
                      setC12R1VNo(6);
                    } else if (c12r1MT === "M") {      
                        setBaseNakedM12(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
            
              setC12R1VNo(6);
              setC12R2VNo(6);
                    } else if (c12r1MT === "L") {  
                      setBaseNakedL12(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(6);
                      setC12R2VNo(6);
                      setC12R3VNo(6);
                      setC12R4VNo(6);
                    }	
        } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
                      alert("Already Added");
                    //setC1R1VNo(6);
        } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
          if (c12r1MT === "S") {  
            setBaseNaked12(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                   setC12R1VNo(5);
           } else if (c12r1MT === "M") {      
                setBaseNakedM12(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
           ]
         )
           setC12R1VNo(5);
           setC12R2VNo(5);
           } else if (c12r1MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(5);
            setC12R2VNo(5);
            setC12R3VNo(5);
            setC12R4VNo(5);
           }	
         } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {  
                    alert("Already Added");
                  } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {
                    alert("Already Added");
          } else if (curX === col12X && curY === row01X && c12R1VNo === 7) {
            if (c12r1MT === "S") {  
              setBaseNaked12(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
             
                     setC12R1VNo(8);
             } else if (c12r1MT === "M") {      
                   setBaseNakedM12(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
             ]
           )
     
             setC12R1VNo(8);
             setC12R2VNo(8);
             } else if (c12r1MT === "L") {  
              setBaseNakedL12(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(8);
              setC12R2VNo(8);
              setC12R3VNo(8);
              setC12R4VNo(8);
             }
          } else if (curX === col12X && curY === row01X && c12R1VNo === 8) {
                    alert("Already Added");
          } else if (curX === col12X && curY === row01X && c12R1VNo === 9) {
                    alert("Already Added");
          } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
                    alert("Already Added");
          } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
                    alert("Already Added");
          } else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
            if (c13r1MT === "S") {  
              setBaseNaked13(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                 
                     setC13R1VNo(3);
             } else if (c13r1MT === "M") {      
                   setBaseNakedM13(
                [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
                )
         
             setC13R1VNo(3);
              setC13R2VNo(3);
             } else if (c13r1MT === "L") {  
              setBaseNakedL13(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(3);
              setC13R2VNo(3);
              setC13R3VNo(3);
              setC13R4VNo(3);
             }	
          } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
            if (c13r1MT === "S") {  
              setBaseNaked13(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
                     ]
                   )
             
               setC13R1VNo(6);
             } else if (c13r1MT === "M") {      
                    setBaseNakedM13(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                  ]
                )
          
            setC13R1VNo(6);
            setC13R2VNo(6);
             } else if (c13r1MT === "L") {  
              setBaseNakedL13(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(6);
              setC13R2VNo(6);
              setC13R3VNo(6);
              setC13R4VNo(6);
             }	
          } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
                      alert("Already Added");
                    //setC1R1VNo(6);
          } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
            if (c13r1MT === "S") {  
              setBaseNaked13(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                     setC13R1VNo(5);
             } else if (c13r1MT === "M") {      
                     setBaseNakedM13(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
             ]
           )
             setC13R1VNo(5);
             setC13R2VNo(5);
             } else if (c13r1MT === "L") {  
              setBaseNakedL13(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(5);
              setC13R2VNo(5);
              setC13R3VNo(5);
              setC13R4VNo(5);
             }	
            } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {  
                    alert("Already Added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {
                    alert("Already Added");
          } else if (curX === col13X && curY === row01X && c13R1VNo === 7) {
            if (c13r1MT === "S") {  
              setBaseNaked13(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
              
                      setC13R1VNo(8);
              } else if (c13r1MT === "M") {      
                      setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
              ]
            )
      
              setC13R1VNo(8);
              setC13R2VNo(8);
              } else if (c13r1MT === "L") {  
                setBaseNakedL13(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                  ]
                )
                setC13R1VNo(8);
                setC13R2VNo(8);
                setC13R3VNo(8);
                setC13R4VNo(8);
              }	
            } else if (curX === col13X && curY === row01X && c13R1VNo === 8) {
                    alert("Already Added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 9) {
                    alert("Already Added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
                    alert("Already Added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
                    alert("Already Added");
            } else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
              if (c14r1MT === "S") {  
                setBaseNaked14(
                                [
                                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                            
                                setC14R1VNo(3);
                } else if (c14r1MT === "M") {      
                     setBaseNakedM14(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
                )
            
                  setC14R1VNo(3);
              setC14R2VNo(3);
                } else if (c14r1MT === "L") {  
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(3);
                  setC14R2VNo(3);
                  setC14R3VNo(3);
                  setC14R4VNo(3);
                }	
            } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
              if (c14r1MT === "S") {  
                setBaseNaked14(
                               [
                                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
                               ]
                             )
                       
                         setC14R1VNo(6);
               } else if (c14r1MT === "M") {      
                    setBaseNakedM14(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
               ]
               )
       
             setC14R1VNo(6);
             setC14R2VNo(6);
               } else if (c14r1MT === "L") {  
                setBaseNakedL14(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(6);
                setC14R2VNo(6);
                setC14R3VNo(6);
                setC14R4VNo(6);
               }	
            } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
                              alert("Already Added");
                            //setC1R1VNo(6);
            } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
              if (c14r1MT === "S") {  
                setBaseNaked14(
                             [
                               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                             ]
                           )
                             setC14R1VNo(5);
             } else if (c14r1MT === "M") {      
                  setBaseNakedM14(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
             ]
           )
             setC14R1VNo(5);
             setC14R2VNo(5);
             } else if (c14r1MT === "L") {  
              setBaseNakedL14(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
              setC14R3VNo(5);
              setC14R4VNo(5);
             }	
              } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {  
                            alert("Already Added");
                          } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {
                            alert("Already Added");
              } else if (curX === col14X && curY === row01X && c14R1VNo === 7) {
                if (c14r1MT === "S") {  
                  setBaseNaked14(
                              [
                                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                      
                                        setC14R1VNo(8);
                        } else if (c14r1MT === "M") {      
                            setBaseNakedM14(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                
                        setC14R1VNo(8);
                        setC14R2VNo(8);
                        } else if (c14r1MT === "L") {  
                          setBaseNakedL14(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(8);
                          setC14R2VNo(8);
                          setC14R3VNo(8);
                          setC14R4VNo(8);
                        }	
                } else if (curX === col14X && curY === row01X && c14R1VNo === 8) {
                            alert("Already Added");
                          } else if (curX === col14X && curY === row01X && c14R1VNo === 9) {
                            alert("Already Added");
                          } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
                            alert("Already Added");
                          } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
                            alert("Already Added");
                }  	else if (curX === col01X && curY === row02X && c1R2VNo === 1) {
                    if (c1r2MT === "S") {  
                      setRow2Naked01(
                                   [
                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
                                   ]
                                 )
                               
                                   setC1R2VNo(3);
                      } else if (c2r2MT === "M" && c2r1MT === "M") { 
                        setBaseNakedM01(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                      
                          setC1R1VNo(3);
                          setC1R2VNo(3);

                        } else if (c2r2MT === "M" && c2r3MT === "M") {     
                            setRow2NakedM01(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                            ]
                          )
                        
                            setC1R2VNo(3);
                      setC1R3VNo(3);
                      } else if (c1r2MT === "L") {  
                        setBaseNakedL01(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(3);
                        setC1R2VNo(3);
                        setC1R3VNo(3);
                        setC1R4VNo(3);
                      }	
                } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
                  if (c1r2MT === "S") {  
                    setRow2Naked01(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
                                  ]
                                )
                          
                                      setC1R2VNo(6);
                                    } else if (c1r1MT === "M" && c1r2MT === "M") { 
                                      setBaseNakedM01(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                                        ]
                                      )
                                
                                  setC1R1VNo(6);
                                  setC1R2VNo(6);
                                    } else if (c1r2MT === "M" && c1r3MT === "M") {     
                          setRow2NakedM01(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                            ]
                          )
                    
                      setC1R2VNo(6);
                      setC1R3VNo(6);
                      } else if (c1r2MT === "L") {  
                        setBaseNakedL01(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(6);
                        setC1R2VNo(6);
                        setC1R3VNo(6);
                        setC1R4VNo(6);
                      }
                  } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
                               alert("Already Added");
                              //setC1R2VNo(6);
                  } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
                    if (c1r2MT === "S") {  
                      setRow2Naked01(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                                    ]
                                  )
                                    setC1R2VNo(5);
                                  } else if (c1r1MT === "M" && c1r2MT === "M") { 
                                    setBaseNakedM01(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                      setC1R1VNo(5);
                                      setC1R2VNo(5);
                                  } else if (c1r2MT === "M" && c1r3MT === "M") {      
                            setRow2NakedM01(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                              ]
                            )
                              setC1R2VNo(5);
                              setC1R3VNo(5);
                        } else if (c1r2MT === "L") {  
                          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(5);
                          setC1R2VNo(5);
                          setC1R3VNo(5);
                          setC1R4VNo(5);
                        }	
                  } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {  
                              alert("Already Added");
                            } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {
                              alert("Already Added");
                  } else if (curX === col01X && curY === row02X && c1R2VNo === 7) {
                    if (c1r2MT === "S") {  
                      setRow2Naked01(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                                    ]
                                  )
                            
                                    setC1R2VNo(8);
                                  } else if (c1r1MT === "M" && c1r2MT === "M") { 
                                    setBaseNakedM01(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                              
                                      setC1R1VNo(8);
                                      setC1R2VNo(8);
                                  } else if (c1r2MT === "M" && c1r3MT === "M") {      
                            setRow2NakedM01(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                              ]
                            )
                      
                              setC1R2VNo(8);
                              setC1R3VNo(8);
                        } else if (c1r2MT === "L") {  
                          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);
                        }	
                } else if (curX === col01X && curY === row02X && c1R2VNo === 8) {
                              alert("Already Added");
                            } else if (curX === col01X && curY === row02X && c1R2VNo === 9) {
                              alert("Already Added");
                            } else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
                              alert("Already Added");
                            } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
                              alert("Already Added");
                              //2nd col
                  } else if (curX === col02X && curY === row02X && c2R2VNo === 1) {
                    if (c2r2MT === "S") {  
                      setRow2Naked02(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
                                     ]
                                   )
                                 
                                     setC2R2VNo(3);
                                    } else if (c2r1MT === "M" && c2r2MT === "M") { 
                                      setBaseNakedM02(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                    
                                        setC2R1VNo(3);
                                  setC2R2VNo(3);
                                    } else if (c2r2MT === "M" && c2r3MT === "M") {     
                            setRow2NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                                
                                    setC2R2VNo(3);
                              setC2R3VNo(3);
                        } else if (c2r2MT === "L") {  
                          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(3);
                          setC2R2VNo(3);
                          setC2R3VNo(3);
                          setC2R4VNo(3);
                        }
                  } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
                    if (c2r2MT === "S") {  
                      setRow2Naked02(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
                                     ]
                                   )
                             
                               setC2R2VNo(6);
                              } else if (c2r1MT === "M" && c2r2MT === "M") { 
                                setBaseNakedM02(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                                  ]
                                )
                          
                            setC2R1VNo(6);
                            setC2R2VNo(6);
                              } else if (c2r2MT === "M" && c2r3MT === "M") {       
                            setRow2NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                            
                              setC2R2VNo(6);
                              setC2R3VNo(6);
                        } else if (c2r2MT === "L") {  
                          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(6);
                          setC2R2VNo(6);
                          setC2R3VNo(6);
                          setC2R4VNo(6);
                        }	
                  } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
                                alert("Already Added");
                              //setC1R2VNo(6);
                  } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
                    if (c2r2MT === "S") {  
                      setRow2Naked02(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                                     ]
                                   )
                                     setC2R2VNo(5);
                                    } else if (c2r1MT === "M" && c2r2MT === "M") { 
                                      setBaseNakedM02(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                        setC2R1VNo(5);
                                        setC2R2VNo(5);
                                    } else if (c2r2MT === "M" && c2r3MT === "M") {       
                            setRow2NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                                    setC2R2VNo(5);
                                    setC2R3VNo(5);
                        } else if (c2r2MT === "L") {  
                          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(5);
                          setC2R2VNo(5);
                          setC2R3VNo(5);
                          setC2R4VNo(5);
                        }
                    } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {  
                              alert("Already Added");
                            } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {
                              alert("Already Added");
                    } else if (curX === col02X && curY === row02X && c2R2VNo === 7) {
                      if (c2r2MT === "S") {  
                        setRow2Naked02(
                                       [
                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                                       ]
                                     )
                                 
                                         setC2R2VNo(8);
                                        } else if (c2r1MT === "M" && c2r2MT === "M") { 
                                          setBaseNakedM02(
                                            [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                                            ]
                                          )
                                    
                                            setC2R1VNo(8);
                                            setC2R2VNo(8);
                                        } else if (c2r2MT === "M" && c2r3MT === "M") {        
                                setRow2NakedM02(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                        ]
                                      )
                                
                                        setC2R2VNo(8);
                                        setC2R3VNo(8);
                            } else if (c2r2MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(8);
                              setC2R2VNo(8);
                              setC2R3VNo(8);
                              setC2R4VNo(8);
                            }
                      } else if (curX === col02X && curY === row02X && c2R2VNo === 8) {
                                alert("Already Added");
                              } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
                                alert("Already Added");
                              } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
                                alert("Already Added");
                              } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
                                alert("Already Added");
                      } else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
                        if (c3r2MT === "S") {  
                          setRow2Naked03(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
                                        ]
                                      )
                                    
                                        setC3R2VNo(3);
                                      } else if (c3r1MT === "M" && c3r2MT === "M") { 
                                        setBaseNakedM03(
                                          [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                      
                                          setC3R1VNo(3);
                                    setC3R2VNo(3);
                                      } else if (c3r2MT === "M" && c3r3MT === "M") {      
                                setRow2NakedM03(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                  ]
                                )
                              
                                  setC3R2VNo(3);
                                  setC3R3VNo(3);
                            setC3R2VNo(3);
                            } else if (c3r2MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(3);
                              setC3R2VNo(3);
                              setC3R3VNo(3);
                              setC3R4VNo(3);
                            }	
                    } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
                      if (c3r2MT === "S") {  
                        setRow2Naked03(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
                                     ]
                                   )
                             
                                    setC3R2VNo(6);
                                  } else if (c3r1MT === "M" && c3r2MT === "M") { 
                                    setBaseNakedM03(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                      ]
                                    )
                              
                                setC3R1VNo(6);
                                setC3R2VNo(6);
                                  } else if (c3r2MT === "M" && c3r3MT === "M") {      
                              setRow2NakedM03(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                              ]
                            )
                      
                        setC3R2VNo(6);
                        setC3R3VNo(6);
                        } else if (c3r2MT === "L") {  
                          setBaseNakedL03(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                            ]
                          )
                          setC3R1VNo(6);
                          setC3R2VNo(6);
                          setC3R3VNo(6);
                          setC3R4VNo(6);
                        }	
                    } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
                                alert("Already Added");
                  
                    } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
                      if (c3r2MT === "S") {  
                        setRow2Naked03(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                                    ]
                                  )
                                    setC3R2VNo(5);
                                  } else if (c3r1MT === "M" && c3r2MT === "M") { 
                                    setBaseNakedM03(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                      ]
                                    )
                                      setC3R1VNo(5);
                                      setC3R2VNo(5);
                                  } else if (c3r2MT === "M" && c3r3MT === "M") {       
                        setRow2NakedM03(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                          ]
                        )
                          setC3R2VNo(5);
                          setC3R3VNo(5);
            
                      } else if (c3r2MT === "L") {  
                        setBaseNakedL03(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                          ]
                        )
                        setC3R1VNo(5);
                        setC3R2VNo(5);
                        setC3R3VNo(5);
                        setC3R4VNo(5);
                      }
                  } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {  
                              alert("Already Added");
                            } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {
                              alert("Already Added");
                  } else if (curX === col03X && curY === row02X && c3R2VNo === 7) {
                    if (c3r2MT === "S") {  
                      setRow2Naked03(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                                     ]
                                   )
                             
                                     setC3R2VNo(8);
                                    } else if (c3r1MT === "M" && c3r2MT === "M") { 
                                      setBaseNakedM03(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                        ]
                                      )
                                
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                    } else if (c3r2MT === "M" && c3r3MT === "M") {      
                        setRow2NakedM03(
                         [
                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                         ]
                       )
                 
                         setC3R2VNo(8);
                         setC3R3VNo(8);
                   } else if (c3r2MT === "L") {  
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(8);
                    setC3R2VNo(8);
                    setC3R3VNo(8);
                    setC3R4VNo(8);
               }
                } else if (curX === col03X && curY === row02X && c3R2VNo === 8) {
                              alert("Already Added");
                            } else if (curX === col03X && curY === row02X && c3R2VNo === 9) {
                              alert("Already Added");
                            } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
                              alert("Already Added");
                            } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
                              alert("Already Added");
                    } else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
                      if (c4r2MT === "S") {  
                        setRow2Naked04(
                                         [
                                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
                                         ]
                                       )
                                     
                                                setC4R2VNo(3);
                                              } else if (c4r1MT === "M" && c4r2MT === "M") { 
                                                setBaseNakedM04(
                                                  [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                                                  ]
                                                )
                                              
                                                setC4R1VNo(3);
                                                setC4R2VNo(3);
                                              } else if (c4r2MT === "M" && c4r3MT === "M") {      
                                setRow2NakedM04(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                  ]
                                )
                              
                                  setC4R2VNo(3);
                                setC4R3VNo(3);
                            } else if (c4r2MT === "L") {  
                              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(3);
                              setC4R2VNo(3);
                              setC4R3VNo(3);
                              setC3R4VNo(3);
                            }
                    } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
                      if (c4r2MT === "S") {  
                        setRow2Naked04(
                                         [
                                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
                                         ]
                                       )
                                 
                                   setC4R2VNo(6);
                                  } else if (c4r1MT === "M" && c4r2MT === "M") { 
                                    setBaseNakedM04(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                                      ]
                                    )
                              
                                setC4R1VNo(6);
                                setC4R2VNo(6);
                                  } else if (c4r2MT === "M" && c4r3MT === "M") {       
                                setRow2NakedM04(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                ]
                              )
                        
                          setC4R2VNo(6);
                          setC4R3VNo(6);
                          } else if (c4r2MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(6);
                            setC4R2VNo(6);
                            setC4R3VNo(6);
                            setC4R4VNo(6);
                          }
                     } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
                                  alert("Already Added");
                                //setC1R2VNo(6);
                      } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
                        if (c4r2MT === "S") {  
                          setRow2Naked04(
                                           [
                                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                                           ]
                                         )
                                           setC4R2VNo(5);
                                          } else if (c4r1MT === "M" && c4r2MT === "M") { 
                                            setBaseNakedM04(
                                              [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                                              ]
                                            )
                                              setC4R1VNo(5);
                                              setC4R2VNo(5);
                                          } else if (c4r2MT === "M" && c4r3MT === "M") {        
                                    setRow2NakedM04(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                            ]
                                          )
                                            setC4R2VNo(5);
                                            setC4R3VNo(5);
                              } else if (c4r2MT === "L") {  
                                setBaseNakedL04(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                  ]
                                )
                                setC4R1VNo(5);
                                setC4R2VNo(5);
                                setC4R3VNo(5);
                                setC4R4VNo(5);
                                }
                      } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {  
                                alert("Already Added");
                              } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {
                                alert("Already Added");
                      } else if (curX === col04X && curY === row02X && c4R2VNo === 7) {
                        if (c4r2MT === "S") {  
                          setRow2Naked04(
                                           [
                                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                                           ]
                                         )
                                   
                                           setC4R2VNo(8);
                                          } else if (c4r1MT === "M" && c4r2MT === "M") { 
                                            setBaseNakedM04(
                                              [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                                              ]
                                            )
                                      
                                              setC4R1VNo(8);
                                              setC4R2VNo(8);
                                          } else if (c4r2MT === "M" && c4r3MT === "M") {     
                                    setRow2NakedM04(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                    ]
                                  )
                            
                                    setC4R2VNo(8);
                                    setC4R3VNo(8);
                                } else if (c4r2MT === "L") {  
                                  setBaseNakedL04(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                    ]
                                  )
                                  setC4R1VNo(8);
                                  setC4R2VNo(8);
                                  setC4R3VNo(8);
                                  setC4R4VNo(8);
                                }
                      } else if (curX === col04X && curY === row02X && c4R2VNo === 8) {
                                alert("Already Added");
                              } else if (curX === col04X && curY === row02X && c4R2VNo === 9) {
                                alert("Already Added");
                              } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
                                alert("Already Added");
                              } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
                                alert("Already Added");
                      } else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
                        if (c5r2MT === "S") {  
                          setRow2Naked05(
                                               [
                                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
                                               ]
                                             )
                                           
                                               setC5R2VNo(3);
                                              } else if (c5r1MT === "M" && c5r2MT === "M") { 
                                                setBaseNakedM05(
                                                  [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                              
                                                  setC5R1VNo(3);
                                            setC5R2VNo(3);
                                              } else if (c5r2MT === "M" && c5r3MT === "M") {      
                           setRow2NakedM05(
                             [
                               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                             ]
                           )
                         
                             setC5R2VNo(3);
                         setC5R3VNo(3);
                         } else if (c5r2MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(3);
                          setC5R2VNo(3);
                          setC5R3VNo(3);
                          setC5R4VNo(3);
                        }
                    } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
                      if (c5r2MT === "S") {  
                        setRow2Naked05(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
                                            ]
                                          )
                                    
                                            setC5R2VNo(6);
                                          } else if (c5r1MT === "M" && c5r2MT === "M") { 
                                            setBaseNakedM05(
                                              [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                      
                                        setC5R1VNo(6);
                                        setC5R2VNo(6);
                                          } else if (c5r2MT === "M" && c5r3MT === "M") {     
                              setRow2NakedM05(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                ]
                              )
                        
                          setC5R2VNo(6);
                          setC5R3VNo(6);
                        } else if (c5r1MT === "M" && c5r2MT === "M") { 

                        } else if (c5r2MT === "M" && c5r3MT === "M") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(6);
                            setC5R2VNo(6);
                            setC5R3VNo(6);
                            setC5R4VNo(6);
                          }
                    } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
                                      alert("Already Added");
                                    //setC1R2VNo(6);
                    } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
                      if (c5r2MT === "S") {  
                        setRow2Naked05(
                                           [
                                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                                           ]
                                         )
                                           setC5R2VNo(5);
                                          } else if (c5r1MT === "M" && c5r2MT === "M") { 
                                            setBaseNakedM05(
                                              [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                              setC5R1VNo(5);
                                              setC5R2VNo(5);
                                          } else if (c5r2MT === "M" && c5r3MT === "M") {    
                              setRow2NakedM05(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                              ]
                            )
                              setC5R2VNo(5);
                              setC5R3VNo(5);
                        } else if (c5r2MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(5);
                          setC5R2VNo(5);
                          setC5R3VNo(5);
                          setC5R4VNo(5);
                        }
                    } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {  
                                    alert("Already Added");
                                  } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {
                                    alert("Already Added");
                    } else if (curX === col05X && curY === row02X && c5R2VNo === 7) {
                      if (c5r2MT === "S") {  
                        setRow2Naked05(
                                         [
                                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                                         ]
                                       )
                                 
                                         setC5R2VNo(8);
                                        } else if (c5r1MT === "M" && c5r2MT === "M") { 
                                          setBaseNakedM05(
                                            [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                                            ]
                                          )
                                    
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                        } else if (c5r2MT === "M" && c5r3MT === "M") {       
                                  setRow2NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                  ]
                                )
                          
                                  setC5R2VNo(8);
                                  setC5R3VNo(8);
                              } else if (c5r2MT === "L") {  
                                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
                              }
                   } else if (curX === col05X && curY === row02X && c5R2VNo === 8) {
                                    alert("Already Added");
                                  } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
                                    alert("Already Added");
                                  } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
                                    alert("Already Added");
                                  } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
                                    alert("Already Added");
                    } else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
                      if (c6r2MT === "S") {  
                        setRow2Naked06(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
                                ]
                              )
                            
                                setC6R2VNo(3);
                              } else if (c6r1MT === "M" && c6r2MT === "M") { 
                                setBaseNakedM06(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                  ]
                                )
                              
                                  setC6R1VNo(3);
                            setC6R2VNo(3);
                              } else if (c6r2MT === "M" && c6r3MT === "M") {      
                                    setRow2NakedM06(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                      ]
                                    )
                                  
                                      setC6R2VNo(3);
                                setC6R3VNo(3);
                        } else if (c6r2MT === "L") {  
                          setBaseNakedL06(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                            ]
                          )
                          setC6R1VNo(3);
                          setC6R2VNo(3);
                          setC6R3VNo(3);
                          setC6R4VNo(3);
                        }
                    } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
                      if (c6r2MT === "S") {  
                        setRow2Naked06(
                               [
                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
                               ]
                             )
                       
                              setC6R2VNo(6);
                            } else if (c6r1MT === "M" && c6r2MT === "M") { 
                              setBaseNakedM06(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                        
                          setC6R1VNo(6);
                          setC6R2VNo(6);
                            } else if (c6r2MT === "M" && c6r3MT === "M") {      
                                                setRow2NakedM06(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                ]
                              )
                        
                          setC6R2VNo(6);
                          setC6R3VNo(6);
                       } else if (c6r2MT === "L") {  
                        setBaseNakedL06(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                          ]
                        )
                        setC6R1VNo(6);
                        setC6R2VNo(6);
                        setC6R3VNo(6);
                        setC6R4VNo(6);
                       } 
                    } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
                                  alert("Already Added");
                                //setC1R2VNo(6);
                    } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
                      if (c6r2MT === "S") {  
                        setRow2Naked06(
                                                   [
                                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                                                   ]
                                                 )
                                                   setC6R2VNo(5);
                                                  } else if (c6r1MT === "M" && c6r2MT === "M") { 
                                                    setBaseNakedM06(
                                                      [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                                      ]
                                                    )
                                                      setC6R1VNo(5);
                                                      setC6R2VNo(5);
                                                  } else if (c6r2MT === "M" && c6r3MT === "M") {     
                                           setRow2NakedM06(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                     ]
                                   )
                                     setC6R2VNo(5);
                                     setC6R3VNo(5);
                                           } else if (c6r2MT === "L") {  
                                            setBaseNakedL06(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                              ]
                                            )
                                            setC6R1VNo(5);
                                            setC6R2VNo(5);
                                            setC6R3VNo(5);
                                            setC6R4VNo(5);
                                           }
                    } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {  
                                alert("Already Added");
                              } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {
                                alert("Already Added");
                    } else if (curX === col06X && curY === row02X && c6R2VNo === 7) {
                      if (c6r2MT === "S") {  
                        setRow2Naked06(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                                                    ]
                                                  )
                                            
                                                    setC6R2VNo(8);
                                                  } else if (c6r1MT === "M" && c6r2MT === "M") { 
                                                    setBaseNakedM06(
                                                      [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                                      ]
                                                    )
                                              
                                                      setC6R1VNo(8);
                                                      setC6R2VNo(8);
                                                  } else if (c6r2MT === "M" && c6r3MT === "M") {      
                                             setRow2NakedM06(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                      ]
                                    )
                              
                                      setC6R2VNo(8);
                                      setC6R3VNo(8);
                                            } else if (c6r2MT === "L") {  
                                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                                            }
                    } else if (curX === col06X && curY === row02X && c6R2VNo === 8) {
                                alert("Already Added");
                              } else if (curX === col06X && curY === row02X && c6R2VNo === 9) {
                                alert("Already Added");
                              } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
                                alert("Already Added");
                              } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
                                alert("Already Added");
                    } else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
                      if (c7r2MT === "S") {  
                        setRow2Naked07(
                                                     [
                                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
                                                     ]
                                                   )
                                                 
                                                     setC7R2VNo(3);
                                                    } else if (c7r1MT === "M" && c7r2MT === "M") { 
                                                      setBaseNakedM07(
                                                        [
                                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                                        ]
                                                      )
                                                    
                                                        setC7R1VNo(3);
                                                  setC7R2VNo(3);
                                                    } else if (c7r2MT === "M" && c7r3MT === "M") {        
                                             setRow2NakedM07(
                                       [
                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                       ]
                                     )
                                   
                                       setC7R2VNo(3);
                                 setC7R3VNo(3);
                                             } else if (c7r2MT === "L") {  
                                              setBaseNakedL07(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                ]
                                              )
                                              setC7R1VNo(3);
                                              setC7R2VNo(3);
                                              setC7R3VNo(3);
                                              setC7R4VNo(3);
                                             }
                    } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
                      if (c7r2MT === "S") {  
                        setRow2Naked07(
                                                   [
                                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
                                                   ]
                                                 )
                                                     
                                               setC7R2VNo(6);
                                              } else if (c7r1MT === "M" && c7r2MT === "M") { 
                                                setBaseNakedM07(
                                                  [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                          
                                            setC7R1VNo(6);
                                            setC7R2VNo(6);
                                              } else if (c7r2MT === "M" && c7r3MT === "M") {        
                                             
                                                    setRow2NakedM07(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                    ]
                                  )
                            
                              setC7R2VNo(6);
                              setC7R3VNo(6);
                                           } else if (c7r2MT === "L") {  
                                            setBaseNakedL07(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                              ]
                                            )
                                            setC7R1VNo(6);
                                            setC7R2VNo(6);
                                            setC7R3VNo(6);
                                            setC7R4VNo(6);
                                           }
                    } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
                                    alert("Already Added");
                                  //setC1R2VNo(6);
                    } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
                      if (c7r2MT === "S") {  
                        setRow2Naked07(
                                                     [
                                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                                                     ]
                                                   )
                                                     setC7R2VNo(5);
                                                    } else if (c7r1MT === "M" && c7r2MT === "M") { 
                                                      setBaseNakedM07(
                                                        [
                                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                                        ]
                                                      )
                                                        setC7R1VNo(5);
                                                        setC7R2VNo(5);
                                                    } else if (c7r2MT === "M" && c7r3MT === "M") {       
                                            setRow2NakedM07(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                     ]
                                   )
                                     setC7R2VNo(5);
                                     setC7R3VNo(5);
                                           } else if (c7r2MT === "L") {  
                                            setBaseNakedL07(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                              ]
                                            )
                                            setC7R1VNo(5);
                                            setC7R2VNo(5);
                                            setC7R3VNo(5);
                                            setC7R4VNo(5);
                                           }
                    } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {  
                                  alert("Already Added");
                                } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {
                                  alert("Already Added");
                    } else if (curX === col07X && curY === row02X && c7R2VNo === 7) {
                      if (c7r2MT === "S") {  
                        setRow2Naked07(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                                                    ]
                                                  )
                                            
                                                    setC7R2VNo(8);
                                                  } else if (c7r1MT === "M" && c7r2MT === "M") { 
                                                    setBaseNakedM07(
                                                      [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                                      ]
                                                    )
                                              
                                                      setC7R1VNo(8);
                                                      setC7R2VNo(8);
                                                  } else if (c7r2MT === "M" && c7r3MT === "M") {     
                                           setRow2NakedM07(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                    ]
                                  )
                            
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                            } else if (c7r2MT === "L") {  
                              setBaseNakedL07(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                ]
                              )
                              setC7R1VNo(8);
                              setC7R2VNo(8);
                              setC7R3VNo(8);
                              setC7R4VNo(8);
                            }
                    } else if (curX === col07X && curY === row02X && c7R2VNo === 8) {
                                  alert("Already Added");
                                } else if (curX === col07X && curY === row02X && c7R2VNo === 9) {
                                  alert("Already Added");
                                } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
                                  alert("Already Added");
                                } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
                                  alert("Already Added");
                    } else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
                      if (c8r2MT === "S") {  
                        setRow2Naked08(
                                                     [
                                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
                                                     ]
                                                   )
                                                 
                                                     setC8R2VNo(3);
                                                    } else if (c8r1MT === "M" && c8r2MT === "M") { 
                                                      setBaseNakedM08(
                                                        [
                                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                                        ]
                                                      )
                                                    
                                                        setC8R1VNo(3);
                                                  setC8R2VNo(3);
                                                    } else if (c8r2MT === "M" && c8r3MT === "M") {        
                                            setRow2NakedM08(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                     ]
                                   )
                                 
                                     setC8R2VNo(3);
                               setC8R3VNo(3);
                                } else if (c8r2MT === "L") {  
                                  setBaseNakedL08(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                    ]
                                  )
                                  setC8R1VNo(3);
                                  setC8R2VNo(3);
                                  setC8R3VNo(3);
                                  setC8R4VNo(3);
                                }
                    } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
                      if (c8r2MT === "S") {  
                        setRow2Naked08(
                                                     [
                                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
                                                     ]
                                                   )
                                             
                                               setC8R2VNo(6);
                                              } else if (c8r1MT === "M" && c8r2MT === "M") { 
                                                setBaseNakedM08(
                                                  [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                                  ]
                                                )
                                          
                                            setC8R1VNo(6);
                                            setC8R2VNo(6);
                                              } else if (c8r2MT === "M" && c8r3MT === "M") {         
                                         setRow2NakedM08(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                     ]
                                   )
                             
                               setC8R2VNo(6);
                               setC8R3VNo(6);
                                           } else if (c8r2MT === "L") {  
                                            setBaseNakedL08(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                              ]
                                            )
                                            setC8R1VNo(6);
                                            setC8R2VNo(6);
                                            setC8R3VNo(6);
                                            setC8R4VNo(6);
                                           }
                    } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
                                    alert("Already Added");
                                  //setC1R2VNo(6);
                    } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
                      if (c8r2MT === "S") {  
                        setRow2Naked08(
                                                   [
                                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                                                   ]
                                                 )
                                                   setC8R2VNo(5);
                                                  } else if (c8r1MT === "M" && c8r2MT === "M") { 
                                                    setBaseNakedM08(
                                                      [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                                      ]
                                                    )
                                                      setC8R1VNo(5);
                                                  } else if (c8r2MT === "M" && c8r3MT === "M") {        
                                       setRow2NakedM08(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                   ]
                                 )
                                   setC8R2VNo(5);
                                   setC8R3VNo(5);
                                         } else if (c8r2MT === "L") {  
                                          setBaseNakedL08(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                            ]
                                          )
                                          setC8R1VNo(5);
                                          setC8R2VNo(5);
                                          setC8R3VNo(5);
                                          setC8R4VNo(5);
                                         }
                    } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {  
                                  alert("Already Added");
                                } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {
                                  alert("Already Added");
                    } else if (curX === col08X && curY === row02X && c8R2VNo === 7) {
                      if (c8r2MT === "S") {  
                        setRow2Naked08(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                                                  ]
                                                )
                                          
                                                  setC8R2VNo(8);
                                                } else if (c8r1MT === "M" && c8r2MT === "M") { 
                                                  setBaseNakedM08(
                                                    [
                                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                            
                                                    setC8R1VNo(8);
                                                    setC8R2VNo(8);
                                                } else if (c8r2MT === "M" && c8r3MT === "M") {         
                                        setRow2NakedM08(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                  ]
                                )
                          
                                  setC8R2VNo(8);
                                  setC8R3VNo(8);
                                        } else if (c8r2MT === "L") {  
                                          setBaseNakedL08(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                            ]
                                          )
                                          setC8R1VNo(8);
                                          setC8R2VNo(8);
                                          setC8R3VNo(8);
                                          setC8R4VNo(8);
                                        }
                    } else if (curX === col08X && curY === row02X && c8R2VNo === 8) {
                                  alert("Already Added");
                                } else if (curX === col08X && curY === row02X && c8R2VNo === 9) {
                                  alert("Already Added");
                                } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
                                  alert("Already Added");
                                } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
                                  alert("Already Added");
          } else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
            if (c9r2MT === "S") {  
              setRow2Naked09(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
                      ]
                    )
                  
                      setC9R2VNo(3);
                    } else if (c9r1MT === "M" && c9r2MT === "M") { 
                      setBaseNakedM09(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                        ]
                      )
                    
                        setC9R1VNo(3);
                  setC9R2VNo(3);
                    } else if (c9r2MT === "M" && c9r3MT === "M") {      
                             setRow2NakedM09(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                        ]
                      )
                    
                        setC9R2VNo(3);
                  setC9R3VNo(3);
                              } else if (c9r2MT === "L") {  
                                setBaseNakedL09(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                  ]
                                )
                                setC9R1VNo(3);
                                setC9R2VNo(3);
                                setC9R3VNo(3);
                                setC9R4VNo(3);
                              }
          } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
            if (c9r2MT === "S") {  
              setRow2Naked09(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
                     ]
                   )
             
               setC9R2VNo(6);
              } else if (c9r1MT === "M" && c9r2MT === "M") { 
                setBaseNakedM09(
                  [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                  ]
                  )
                  setC9R1VNo(6);
                  setC9R2VNo(6);
              } else if (c9r2MT === "M" && c9r3MT === "M") {          
                        setRow2NakedM09(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                       ]
                     )
               
                 setC9R2VNo(6);
                 setC9R3VNo(6);
                             } else if (c9r2MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(6);
                              setC9R2VNo(6);
                              setC9R3VNo(6);
                              setC9R4VNo(6);
                             }
          } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
                  alert("Already Added");
                //setC1R2VNo(6);
          } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
            if (c9r2MT === "S") {  
              setRow2Naked09(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                      ]
                    )
                      setC9R2VNo(5);
                    } else if (c9r1MT === "M" && c9r2MT === "M") { 
                      setBaseNakedM09(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                        ]
                          )
                        setC9R1VNo(5);
                        setC9R2VNo(5);
                    } else if (c9r2MT === "M" && c9r3MT === "M") {        
                         setRow2NakedM09(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                        ]
                      )
                        setC9R2VNo(5);
                        setC9R3VNo(5);
                              } else if (c9r2MT === "L") {  
                                setBaseNakedL09(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                  ]
                                )
                                setC9R1VNo(5);
                                setC9R2VNo(5);
                                setC9R3VNo(5);
                                setC9R4VNo(5);
                              }
          } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {  
                alert("Already Added");
              } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {
                alert("Already Added");
        } else if (curX === col09X && curY === row02X && c9R2VNo === 7) {
          if (c9r2MT === "S") {  
            setRow2Naked09(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                    ]
                  )
            
                    setC9R2VNo(8);
                  } else if (c9r1MT === "M" && c9r2MT === "M") { 
                    setBaseNakedM09(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                      ]
                      )
            
                    setC9R1VNo(8);
                    setC9R2VNo(8);
                  } else if (c9r2MT === "M" && c9r3MT === "M") {   
                      setRow2NakedM09(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                      ]
                    )
              
                      setC9R2VNo(8);
                      setC9R3VNo(8);
                            } else if (c9r2MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(8);
                              setC9R2VNo(8);
                              setC9R3VNo(8);
                              setC9R4VNo(8);
                            }
          } else if (curX === col09X && curY === row02X && c9R2VNo === 8) {
                alert("Already Added");
              } else if (curX === col09X && curY === row02X && c9R2VNo === 9) {
                alert("Already Added");
              } else if (curX === col09X && curY === row02X && c9R2VNo === 10) {
                alert("Already Added");
              } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
                alert("Already Added");
        } else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
          if (c10r2MT === "S") {  
            setRow2Naked10(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
                  ]
                )
              
                  setC10R2VNo(3);
                } else if (c10r1MT === "M" && c10r2MT === "M") { 
                  setBaseNakedM10(
                    [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                    ]
                    )
              
                      setC10R1VNo(3);
                  setC10R2VNo(3);
                } else if (c10r2MT === "M" && c10r3MT === "M") {        
                                setRow2NakedM10(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                    ]
                  )
                
                    setC10R2VNo(3);
              setC10R3VNo(3);
             } else if (c10r2MT === "L") {  
              setBaseNakedL10(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(3);
              setC10R2VNo(3);
              setC10R3VNo(3);
              setC10R4VNo(3);
             }
        } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
          if (c10r2MT === "S") {  
            setRow2Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
              ]
            )
      
            setC10R2VNo(6);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
      
        setC10R1VNo(6);
        setC10R2VNo(6);
          } else if (c10r2MT === "M" && c10r3MT === "M") {       
                      setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                ]
              )
        
          setC10R2VNo(6);
          setC10R3VNo(6);
          } else if (c10r2MT === "L") {  
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(6);
            setC10R2VNo(6);
            setC10R3VNo(6);
            setC10R4VNo(6);
          }
        } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
                  alert("Already Added");
        
        } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
          if (c10r2MT === "S") {  
            setRow2Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
              setC10R2VNo(5);
            } else if (c10r1MT === "M" && c10r2MT === "M") { 
              setBaseNakedM10(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                ]
                )
                setC10R1VNo(5);
                setC10R2VNo(5);
            } else if (c10r2MT === "M" && c10r3MT === "M") {       
                      setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                ]
              )
                setC10R2VNo(5);
                setC10R3VNo(5);
                      } else if (c10r2MT === "L") {  
                        setBaseNakedL10(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                          ]
                        )
                        setC10R1VNo(5);
                        setC10R2VNo(5);
                        setC10R3VNo(5);
                        setC10R4VNo(5);
                      }
        } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {  
                alert("Already Added");
              } else if (curX === col10X && curY === row02X && c10R2VNo === 6) {
                alert("Already Added");
        } else if (curX === col10X && curY === row02X && c10R2VNo === 7) {
          if (c10r2MT === "S") {  
            setRow2Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
               ]
             )
       
               setC10R2VNo(8);
              } else if (c10r1MT === "M" && c10r2MT === "M") { 
                setBaseNakedM10(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
          
                  setC10R1VNo(8);
                  setC10R2VNo(8);
              } else if (c10r2MT === "M" && c10r3MT === "M") {      
                      setRow2NakedM10(
                 [
                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                 ]
               )
         
                 setC10R2VNo(8);
                 setC10R3VNo(8);
                       } else if (c10r2MT === "L") {  
                        setBaseNakedL10(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                          ]
                        )
                        setC10R1VNo(8);
                        setC10R2VNo(8);
                        setC10R3VNo(8);
                        setC10R4VNo(8);
                       }
        } else if (curX === col10X && curY === row02X && c10R2VNo === 8) {
                alert("Already Added");
              } else if (curX === col10X && curY === row02X && c10R2VNo === 9) {
                alert("Already Added");
              } else if (curX === col10X && curY === row02X && c10R2VNo === 10) {
                alert("Already Added");
              } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
                alert("Already Added");
        }  else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
          if (c11r2MT === "S") {  
            setRow2Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
                ]
              )
            
                setC11R2VNo(3);
              } else if (c11r1MT === "M" && c11r2MT === "M") { 
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                  ]
                    )
              
                  setC11R1VNo(3);
                  setC11R2VNo(3);
              } else if (c11r2MT === "M" && c11r3MT === "M") {     
                       setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                  ]
                )
              
                  setC11R2VNo(3);
            setC11R3VNo(3);
                        } else if (c11r2MT === "L") {  
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(3);
                          setC11R2VNo(3);
                          setC11R3VNo(3);
                          setC11R4VNo(3);
                        }
        } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
          if (c11r2MT === "S") {  
            setRow2Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
                 ]
               )
         
           setC11R2VNo(6);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
      
            setC11R1VNo(6);
            setC11R2VNo(6);
          } else if (c11r2MT === "M" && c11r3MT === "M") {     
                       setRow2NakedM11(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                   ]
                 )
           
             setC11R2VNo(6);
             setC11R3VNo(6);
                         } else if (c11r2MT === "L") {  
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(6);
                          setC11R2VNo(6);
                          setC11R3VNo(6);
                          setC11R4VNo(6);
                         }
        } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
                  alert("Already Added");
                //setC1R2VNo(6);
        } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
          if (c11r2MT === "S") {  
            setRow2Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
                setC11R2VNo(5);
              } else if (c11r1MT === "M" && c11r2MT === "M") { 
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                  setC11R1VNo(5);
                  setC11R2VNo(5);
              } else if (c11r2MT === "M" && c11r3MT === "M") {      
                      setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                  ]
                )
                  setC11R2VNo(5);
                  setC11R3VNo(5);
                        } else if (c11r2MT === "L") {  
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(5);
                          setC11R2VNo(5);
                          setC11R3VNo(5);
                          setC11R4VNo(5);
                        }
        } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {  
                alert("Already Added");
        } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {
                alert("Already Added");
        } else if (curX === col11X && curY === row02X && c11R2VNo === 7) {
          if (c11r2MT === "S") {  
            setRow2Naked11(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
               ]
             )
       
               setC11R2VNo(8);
              } else if (c11r1MT === "M" && c11r2MT === "M") { 
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
          
                  setC11R1VNo(8);
                  setC11R2VNo(8);
              } else if (c11r2MT === "M" && c11r3MT === "M") {       
                    setRow2NakedM11(
                 [
                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                 ]
               )
         
                 setC11R2VNo(8);
                 setC11R3VNo(8);
                       } else if (c11r2MT === "L") {  
                        setBaseNakedL11(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                          ]
                        )
                        setC11R1VNo(8);
                        setC11R2VNo(8);
                        setC11R3VNo(8);
                        setC11R4VNo(8);
                       }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 8) {
                alert("Already Added");
              } else if (curX === col11X && curY === row02X && c11R2VNo === 9) {
                alert("Already Added");
              } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
                alert("Already Added");
              } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
                alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
             ]
           )
         
             setC12R2VNo(3);
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
            
                setC12R1VNo(3);
              setC12R2VNo(3);
            } else if (c12r2MT === "M" && c12r3MT === "M") {      
                setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
           
               setC12R2VNo(3);
         setC12R3VNo(3);
                     } else if (c12r2MT === "L") {  
                      setBaseNakedL12(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(3);
                      setC12R2VNo(3);
                      setC12R3VNo(3);
                      setC12R4VNo(3);
                     }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
             ]
           )
     
       setC12R2VNo(6);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
  
    setC12R1VNo(6);
    setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r3MT === "M") {     
                setRow2NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
               ]
             )
       
         setC12R2VNo(6);
         setC12R3VNo(6);
                     } else if (c12r2MT === "L") {  
                      setBaseNakedL12(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(6);
                      setC12R2VNo(6);
                      setC12R3VNo(6);
                      setC12R4VNo(6);
                     }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
                  alert("Already Added");

      } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
                setC12R2VNo(5);
              } else if (c12r1MT === "M" && c12r2MT === "M") { 
                setBaseNakedM12(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                  ]
                )
                  setC12R1VNo(5);
                  setC12R2VNo(5);
              } else if (c12r2MT === "M" && c12r3MT === "M") {      
                   setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                  ]
                )
                  setC12R2VNo(5);
                  setC12R3VNo(5);
                        } else if (c12r2MT === "L") {  
                          setBaseNakedL12(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(5);
                          setC12R2VNo(5);
                          setC12R3VNo(5);
                          setC12R4VNo(5);
                        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {  
                alert("Already Added");
              } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {
                alert("Already Added");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 7) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
        
                setC12R2VNo(8);
              } else if (c12r1MT === "M" && c12r2MT === "M") { 
                setBaseNakedM12(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                  ]
                )
          
                  setC12R1VNo(8);
                  setC12R2VNo(8);
              } else if (c12r2MT === "M" && c12r3MT === "M") {     
                  setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                  ]
                )
          
                  setC12R2VNo(8);
                  setC12R3VNo(8);
                        } else if (c12r2MT === "L") {  
                          setBaseNakedL12(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(8);
                          setC12R2VNo(8);
                          setC12R3VNo(8);
                          setC12R4VNo(8);
                        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 8) {
                alert("Already Added");
              } else if (curX === col12X && curY === row02X && c12R2VNo === 9) {
                alert("Already Added");
              } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
                alert("Already Added");
              } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
                alert("Already Added");
        }  else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
          if (c13r2MT === "S") {  
            setRow2Naked13(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
                   ]
                 )
               
                   setC13R2VNo(3);
                  } else if (c13r1MT === "M" && c13r2MT === "M") { 
                    setBaseNakedM13(
                      [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                      ]
                      )
               
                   setC13R1VNo(3);
                    setC13R2VNo(3);
                  } else if (c13r2MT === "M" && c13r3MT === "M") {       
                      setRow2NakedM13(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                     ]
                   )
                 
                     setC13R2VNo(3);
               setC13R3VNo(3);
                           } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(3);
                            setC13R2VNo(3);
                            setC13R3VNo(3);
                            setC13R4VNo(3);
                           }
        } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
          if (c13r2MT === "S") {  
            setRow2Naked13(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
                   ]
                 )
           
             setC13R2VNo(6);
            } else if (c13r1MT === "M" && c13r2MT === "M") { 
              setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                     ]
                   )
             
               setC13R1VNo(6);
               setC13R2VNo(6);
            } else if (c13r2MT === "M" && c13r3MT === "M") {    
                                      setRow2NakedM13(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                       ]
                     )
               
                 setC13R2VNo(6);
                 setC13R3VNo(6);
                           } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(6);
                            setC13R2VNo(6);
                            setC13R3VNo(6);
                            setC13R4VNo(6);
                           }
        } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
                  alert("Already Added");
                //setC1R2VNo(6);
        } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
          if (c13r2MT === "S") {  
            setRow2Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                  ]
                )
                  setC13R2VNo(5);
                } else if (c13r1MT === "M" && c13r2MT === "M") { 
                  setBaseNakedM13(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                    setC13R1VNo(5);
                    setC13R2VNo(5);
                } else if (c13r2MT === "M" && c13r3MT === "M") {       
                       setRow2NakedM13(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                    ]
                  )
                    setC13R2VNo(5);
                    setC13R3VNo(5);
                          } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(5);
                            setC13R2VNo(5);
                            setC13R3VNo(5);
                            setC13R4VNo(5);
                          }
        } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {  
                alert("Already Added");
              } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {
                alert("Already Added");
        } else if (curX === col13X && curY === row02X && c13R2VNo === 7) {
          if (c13r2MT === "S") {  
            setRow2Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                  ]
                )
          
                  setC13R2VNo(8);
                } else if (c13r1MT === "M" && c13r2MT === "M") { 
                  setBaseNakedM13(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
            
                    setC13R1VNo(8);
                    setC13R2VNo(8);
                } else if (c13r2MT === "M" && c13r3MT === "M") {      
                       setRow2NakedM13(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                    ]
                  )
            
                    setC13R2VNo(8);
                    setC13R3VNo(8);
                          } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(8);
                            setC13R2VNo(8);
                            setC13R3VNo(8);
                            setC13R4VNo(8);
                          }
        } else if (curX === col13X && curY === row02X && c13R2VNo === 8) {
                alert("Already Added");
              } else if (curX === col13X && curY === row02X && c13R2VNo === 9) {
                alert("Already Added");
              } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
                alert("Already Added");
              } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
                alert("Already Added");
        } else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
          if (c14r2MT === "S") {  
            setRow2Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
                  ]
                )
              
                  setC14R2VNo(3);
                } else if (c14r1MT === "M" && c14r2MT === "M") { 
                  setBaseNakedM14(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                    ]
                    )
                
                      setC14R1VNo(3);
                  setC14R2VNo(3);
                } else if (c14r2MT === "M" && c14r3MT === "M") {        
                 setRow2NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                    ]
                  )
                
                    setC14R2VNo(3);
              setC14R3VNo(3);
                          } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(3);
                            setC14R2VNo(3);
                            setC14R3VNo(3);
                            setC14R4VNo(3);
                          }
        } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
          if (c14r2MT === "S") {  
            setRow2Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
                  ]
                )
          
            setC14R2VNo(6);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
              ]
              )
      
            setC14R1VNo(6);
            setC14R2VNo(6);
          } else if (c14r2MT === "M" && c14r3MT === "M") {     
                 setRow2NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                    ]
                  )
            
              setC14R2VNo(6);
              setC14R3VNo(6);
                          } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(6);
                            setC14R2VNo(6);
                            setC14R3VNo(6);
                            setC14R4VNo(6);
                          }
        } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
                  alert("Already Added");
                //setC1R2VNo(6);
        } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
          if (c14r2MT === "S") {  
            setRow2Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                  ]
                )
                  setC14R2VNo(5);
                } else if (c14r1MT === "M" && c14r2MT === "M") { 
                  setBaseNakedM14(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                    setC14R1VNo(5);
                    setC14R2VNo(5);
                } else if (c14r2MT === "M" && c14r3MT === "M") {      
                  setRow2NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                    ]
                  )
                    setC14R2VNo(5);
                    setC14R3VNo(5);
                          } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(5);
                            setC14R2VNo(5);
                            setC14R3VNo(5);
                            setC14R4VNo(5);
                          }
        } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {  
                alert("Already Added");
              } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {
                alert("Already Added");
        } else if (curX === col14X && curY === row02X && c14R2VNo === 7) {
          if (c14r2MT === "S") {  
            setRow2Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                  ]
                )
          
                  setC14R2VNo(8);
                } else if (c14r1MT === "M" && c14r2MT === "M") { 
                  setBaseNakedM14(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
            
                    setC14R1VNo(8);
                    setC14R2VNo(8);
                } else if (c14r2MT === "M" && c14r3MT === "M") {     
                  setRow2NakedM14(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                    ]
                  )
            
                    setC14R2VNo(8);
                    setC14R3VNo(8);
                          } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(8);
                            setC14R2VNo(8);
                            setC14R3VNo(8);
                            setC14R4VNo(8);
                          }
        } else if (curX === col14X && curY === row02X && c14R2VNo === 8) {
                alert("Already Added");
              } else if (curX === col14X && curY === row02X && c14R2VNo === 9) {
                alert("Already Added");
              } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
                alert("Already Added");
              } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
                alert("Already Added");
        } else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
                    ]
                  )
                
                    setC1R3VNo(3);
                  } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                    setRow2NakedM01(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                      ]
                    )
                  
                      setC1R2VNo(3);
                setC1R3VNo(3);
                  } else if (c1r3MT === "M" && c1r4MT === "M") {       
                  setRow3NakedM01(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                          ]
                        )
                      
                          setC1R3VNo(3);
                    setC1R4VNo(3);
                          } else if (c1r3MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(3);
                            setC1R2VNo(3);
                            setC1R3VNo(3);
                            setC1R4VNo(3);
                          }
        } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
                    ]
                  )
            
                  setC1R3VNo(6);
                } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                  setRow2NakedM01(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                    ]
                  )
            
              setC1R2VNo(6);
              setC1R3VNo(6);
                } else if (c1r3MT === "M" && c1r4MT === "M") {     
                   setRow3NakedM01(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                          ]
                        )
                  
                    setC1R3VNo(6);
                    setC1R4VNo(6);
                          } else if (c1r3MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(6);
                            setC1R2VNo(6);
                            setC1R3VNo(6);
                            setC1R4VNo(6);
                          }
        } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
                   alert("Already Added");
                  //setC1R3VNo(6);
          } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
            if (c1r3MT === "S") {  
              setRow3Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
                      setC1R3VNo(5);
                    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                      setRow2NakedM01(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                        ]
                      )
                        setC1R2VNo(5);
                        setC1R3VNo(5);
                    } else if (c1r3MT === "M" && c1r4MT === "M") {     
                     setRow3NakedM01(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                            ]
                          )
                            setC1R3VNo(5);
                            setC1R4VNo(5);
                            } else if (c1r3MT === "L") {  
                              setBaseNakedL01(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                ]
                              )
                              setC1R1VNo(5);
                              setC1R2VNo(5);
                              setC1R3VNo(5);
                              setC1R4VNo(5);
                            }
          } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {  
                  alert("Already Added");
                } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {
                  alert("Already Added");
          } else if (curX === col01X && curY === row03X && c1R3VNo === 7) {
            if (c1r3MT === "S") {  
              setRow3Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
              
                      setC1R3VNo(8);
                    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                      setRow2NakedM01(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                        ]
                      )
                
                        setC1R2VNo(8);
                        setC1R3VNo(8);
                    } else if (c1r3MT === "M" && c1r4MT === "M") {     
                      setRow3NakedM01(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                            ]
                          )
                    
                            setC1R3VNo(8);
                            setC1R4VNo(8);
                            } else if (c1r3MT === "L") {  
                              setBaseNakedL01(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC1R1VNo(8);
                              setC1R2VNo(8);
                              setC1R3VNo(8);
                              setC1R4VNo(8);
                            }
          } else if (curX === col01X && curY === row03X && c1R3VNo === 8) {
                  alert("Already Added");
                } else if (curX === col01X && curY === row03X && c1R3VNo === 9) {
                  alert("Already Added");
                } else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
                  alert("Already Added");
                } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
                  alert("Already Added");
                  //2nd col
          } else if (curX === col02X && curY === row03X && c2R3VNo === 1) {
            if (c2r3MT === "S") {  
              setRow3Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
                      ]
                    )
                  
                      setC2R3VNo(3);
                    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                      setRow2NakedM02(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                        ]
                      )
                    
                        setC2R2VNo(3);
                  setC2R3VNo(3);
                    } else if (c2r3MT === "M" && c2r4MT === "M") {       
                     setRow3NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                  ]
                                )
                              
                                  setC2R3VNo(3);
                            setC2R4VNo(3);
                            } else if (c2r3MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(3);
                              setC2R2VNo(3);
                              setC2R3VNo(3);
                              setC2R4VNo(3);
                            }
          } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
            if (c2r3MT === "S") {  
              setRow3Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
                      ]
                    )
              
                setC2R3VNo(6);
              } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                setRow2NakedM02(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                  ]
                )
          
            setC2R2VNo(6);
            setC2R3VNo(6);
              } else if (c2r3MT === "M" && c2r4MT === "M") {      
                     setRow3NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                  ]
                                )
                          
                            setC2R3VNo(6);
                            setC2R4VNo(6);
                            } else if (c2r3MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(6);
                              setC2R2VNo(6);
                              setC2R3VNo(6);
                              setC2R4VNo(6);
                            }
          } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
                    alert("Already Added");
          } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
            if (c2r3MT === "S") {  
              setRow3Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                      ]
                    )
                      setC2R3VNo(5);
                    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                      setRow2NakedM02(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                        ]
                      )
                        setC2R2VNo(5);
                        setC2R3VNo(5);
                    } else if (c2r3MT === "M" && c2r4MT === "M") {    
                     setRow3NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                  ]
                                )
                                  setC2R3VNo(5);
                                  setC2R4VNo(5);
                            } else if (c2r3MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(5);
                              setC2R2VNo(5);
                              setC2R3VNo(5);
                              setC2R4VNo(5);
                            }
          } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {  
                  alert("Already Added");
                } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {
                  alert("Already Added");
          } else if (curX === col02X && curY === row03X && c2R3VNo === 7) {
            if (c2r3MT === "S") {  
              setRow3Naked02(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                     ]
                   )
               
                       setC2R3VNo(8);
                      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                        setRow2NakedM02(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                          ]
                        )
                  
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                      } else if (c2r3MT === "M" && c2r4MT === "M") {    
                   setRow3NakedM02(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                 ]
                               )
                         
                                 setC2R3VNo(8);
                                 setC2R4VNo(8);
                           } else if (c2r3MT === "L") {  
                            setBaseNakedL02(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(8);
                            setC2R2VNo(8);
                            setC2R3VNo(8);
                            setC2R4VNo(8);
                           }
            } else if (curX === col02X && curY === row03X && c2R3VNo === 8) {
                    alert("Already Added");
                  } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
                    alert("Already Added");
                  } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
                    alert("Already Added");
                  } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
                    alert("Already Added");
        } else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
                     ]
                   )
                 
                     setC3R3VNo(3);
                    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                      setRow2NakedM03(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                        ]
                      )
                    
                        setC3R2VNo(3);
                        setC3R3VNo(3);
                    } else if (c3r3MT === "M" && c3r4MT === "M") {      
                   setRow3NakedM03(
                           [
                             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                           ]
                         )
                       
                           setC3R3VNo(3);
                     setC3R4VNo(4);
                           } else if (c3r3MT === "L") {  
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(3);
                            setC3R2VNo(3);
                            setC3R3VNo(3);
                            setC3R4VNo(3);
                           }
        } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
                     ]
                   )
             
               setC3R3VNo(6);
              } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                  ]
                )
          
            setC3R2VNo(6);
            setC3R3VNo(6);
              } else if (c3r3MT === "M" && c3r4MT === "M") {       
                   setRow3NakedM03(
                           [
                             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                           ]
                         )
                   
                     setC3R3VNo(6);
                     setC3R4VNo(6);
                           } else if (c3r3MT === "L") {  
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(6);
                            setC3R2VNo(6);
                            setC3R3VNo(6);
                            setC3R4VNo(6);
                           }
        } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
                    alert("Already Added");
      
        } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                      ]
                    )
                      setC3R3VNo(5);
                    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                      setRow2NakedM03(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                        ]
                      )
                        setC3R2VNo(5);
                        setC3R3VNo(5);
                    } else if (c3r3MT === "M" && c3r4MT === "M") {      
                   setRow3NakedM03(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                            ]
                          )
                            setC3R3VNo(5);
                            setC3R4VNo(5);
                            } else if (c3r3MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(5);
                              setC3R2VNo(5);
                              setC3R3VNo(5);
                              setC3R4VNo(5);
                            }
        } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {  
                  alert("Already Added");
                } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {
                  alert("Already Added");
        } else if (curX === col03X && curY === row03X && c3R3VNo === 7) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                     ]
                   )
             
                     setC3R3VNo(8);
                    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                      setRow2NakedM03(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                        ]
                      )
                
                        setC3R2VNo(8);
                        setC3R3VNo(8);
                    } else if (c3r3MT === "M" && c3r4MT === "M") {       
                  setRow3NakedM03(
                           [
                             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                           ]
                         )
                   
                           setC3R3VNo(8);
                           setC3R4VNo(8);
                           } else if (c3r3MT === "L") {  
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);
                           }
        } else if (curX === col03X && curY === row03X && c3R3VNo === 8) {
                  alert("Already Added");
                } else if (curX === col03X && curY === row03X && c3R3VNo === 9) {
                  alert("Already Added");
                } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
                  alert("Already Added");
                } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
                  alert("Already Added");
        } else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
                         [
                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
                         ]
                       )
                     
                         setC4R3VNo(3);
                        } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                          setRow2NakedM04(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                            ]
                          )
                        
                            setC4R2VNo(3);
                          setC4R3VNo(3);
                        } else if (c4r3MT === "M" && c4r4MT === "M") {    
                     setRow3NakedM04(
                             [
                               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                             ]
                           )
                         
                             setC4R3VNo(3);
                       setC4R4VNo(3);
                             } else if (c4r3MT === "L") {  
                              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(3);
                              setC4R2VNo(3);
                              setC4R3VNo(3);
                              setC3R4VNo(3);
                             }
        } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
                             ]
                           )
                     
                       setC4R3VNo(6);
                      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                        setRow2NakedM04(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                          ]
                        )
                  
                    setC4R2VNo(6);
                    setC4R3VNo(6);
                      } else if (c4r3MT === "M" && c4r4MT === "M") {      
              setRow3NakedM04(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                 ]
                               )
                         
                           setC4R3VNo(6);
                           setC4R4VNo(6);
         } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
         }
        } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
                      alert("Already Added");
                    //setC1R3VNo(6);
        } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                            ]
                          )
                        } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                          setRow2NakedM04(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                            ]
                          )
                            setC4R2VNo(5);
                            setC4R3VNo(5);
                        } else if (c4r3MT === "M" && c4r4MT === "M") {       
             setRow3NakedM04(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                ]
                              )
                                setC4R3VNo(5);
                                setC4R4VNo(5);
        } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
        }
        } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {  
                    alert("Already Added");
                  } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {
                    alert("Already Added");
         } else if (curX === col04X && curY === row03X && c4R3VNo === 7) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
                           [
                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                           ]
                         )
                   
                           setC4R3VNo(8);
                          } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                            setRow2NakedM04(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                              ]
                            )
                      
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                          } else if (c4r3MT === "M" && c4r4MT === "M") {       
            setRow3NakedM04(
                               [
                                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                               ]
                             )
                       
                               setC4R3VNo(8);
                               setC4R4VNo(8);
       } else if (c4r3MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(8);
        setC4R2VNo(8);
        setC4R3VNo(8);
        setC4R4VNo(8);
       }
        } else if (curX === col04X && curY === row03X && c4R3VNo === 8) {
                    alert("Already Added");
                  } else if (curX === col04X && curY === row03X && c4R3VNo === 9) {
                    alert("Already Added");
                  } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
                    alert("Already Added");
                  } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
                    alert("Already Added");
        } else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
                               [
                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
                               ]
                             )
                           
                               setC5R3VNo(3);
                              } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                                setRow2NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                  ]
                                )
                              
                                  setC5R2VNo(3);
                              setC5R3VNo(3);
                              } else if (c5r3MT === "M" && c5r4MT === "M") {       
            setRow3NakedM05(
                               [
                                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                               ]
                             )
                           
                               setC5R3VNo(3);
                         setC5R4VNo(3);
       } else if (c5r3MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
        setC5R3VNo(3);
        setC5R4VNo(3);
       }
        } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
                                ]
                              )
                        
                          setC5R3VNo(6);
                        } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                        } else if (c5r2MT === "M" && c5r3MT === "M") {     
                          setRow2NakedM05(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                            ]
                          )
                    
                      setC5R2VNo(6);
                      setC5R3VNo(6);
                        } else if (c5r3MT === "M" && c5r4MT === "M") {    
               setRow3NakedM05(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                ]
                              )
                        
                          setC5R3VNo(6);
                          setC5R4VNo(6);
        } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
        }
        } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
                          alert("Already Added");
                        //setC1R3VNo(6);
        } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                                ]
                              )
                                setC5R3VNo(5);
                              } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                                setRow2NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                  ]
                                )
                                  setC5R2VNo(5);
                                  setC5R3VNo(5);
                              } else if (c5r3MT === "M" && c5r4MT === "M") {   
                setRow3NakedM05(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                ]
                              )
                                setC5R3VNo(5);
                                setC5R4VNo(5);
        } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }
        } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {  
                        alert("Already Added");
                      } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {
                        alert("Already Added");
        } else if (curX === col05X && curY === row03X && c5R3VNo === 7) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                                ]
                              )
                        
                                setC5R3VNo(8);
                              } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                                setRow2NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                  ]
                                )
                          
                                  setC5R2VNo(8);
                                  setC5R3VNo(8);
                              } else if (c5r3MT === "M" && c5r4MT === "M") {      
                 setRow3NakedM05(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                ]
                              )
                        
                                setC5R3VNo(8);
                                setC5R4VNo(8);
        } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
        }
         } else if (curX === col05X && curY === row03X && c5R3VNo === 8) {
                        alert("Already Added");
                      } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
                        alert("Already Added");
                      } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
                        alert("Already Added");
                      } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
                        alert("Already Added");
        } else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
                             ]
                           )
                         
                             setC6R3VNo(3);
                            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                              setRow2NakedM06(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                ]
                              )
                            
                                setC6R2VNo(3);
                          setC6R3VNo(3);
                            } else if (c6r3MT === "M" && c6r4MT === "M") {     
                  setRow3NakedM06(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                 ]
                               )
                             
                                 setC6R3VNo(3);
                           setC6R4VNo(3);
         } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
         }
        } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
                             ]
                           )
                     
                       setC6R3VNo(6);
                      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                        setRow2NakedM06(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                          ]
                        )
                  
                    setC6R2VNo(6);
                    setC6R3VNo(6);
                      } else if (c6r3MT === "M" && c6r4MT === "M") {      
                   setRow3NakedM06(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                 ]
                               )
                         
                           setC6R3VNo(6);
                           setC6R4VNo(6);
         } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
         }
         } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
                      alert("Already Added");
                    //setC1R3VNo(6);
        } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                             ]
                           )
                             setC6R3VNo(5);
                            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                              setRow2NakedM06(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                ]
                              )
                                setC6R2VNo(5);
                                setC6R3VNo(5);
                            } else if (c6r3MT === "M" && c6r4MT === "M") {   
                 setRow3NakedM06(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                 ]
                               )
                                 setC6R3VNo(5);
                                 setC6R4VNo(5);
         } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
         }
        } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {  
                    alert("Already Added");
                  } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {
                    alert("Already Added");
        } else if (curX === col06X && curY === row03X && c6R3VNo === 7) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                             ]
                           )
                     
                             setC6R3VNo(8);
                            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                            } else if (c6r2MT === "M" && c6r3MT === "M") {      
                              setRow2NakedM06(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                       ]
                     )
               
                       setC6R2VNo(8);
                       setC6R3VNo(8);
                            } else if (c6r3MT === "M" && c6r4MT === "M") {     
                setRow3NakedM06(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                 ]
                               )
                         
                                 setC6R3VNo(8);
                                 setC6R4VNo(8);
         } else if (c6r3MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
         }
        } else if (curX === col06X && curY === row03X && c6R3VNo === 8) {
                    alert("Already Added");
                  } else if (curX === col06X && curY === row03X && c6R3VNo === 9) {
                    alert("Already Added");
                  } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
                    alert("Already Added");
                  } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
                    alert("Already Added");
        } else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
                              ]
                            )
                          
                              setC7R3VNo(3);
                            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                              setRow2NakedM07(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                ]
                              )
                            
                                setC7R2VNo(3);
                          setC7R3VNo(3);
                            } else if (c7r3MT === "M" && c7r4MT === "M") {      
           setRow3NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                  ]
                                )
                              
                                  setC7R3VNo(3);
                            setC7R4VNo(3);
          } else if (c7r3MT === "L") {  
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(3);
            setC7R2VNo(3);
            setC7R3VNo(3);
            setC7R4VNo(3);
          }
        } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
                            ]
                          )
                              
                        setC7R3VNo(6);
                      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                        setRow2NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                          ]
                        )
                  
                    setC7R2VNo(6);
                    setC7R3VNo(6);
                      } else if (c7r3MT === "M" && c7r4MT === "M") {    
         setRow3NakedM07(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                ]
                              )
                        
                          setC7R3VNo(6);
                          setC7R4VNo(6);
        } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
        }
        } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
                        alert("Already Added");
                      //setC1R3VNo(6);
        } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                             ]
                           )
                             setC7R3VNo(5);
                            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                              setRow2NakedM07(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                ]
                              )
                                setC7R2VNo(5);
                                setC7R3VNo(5);
                            } else if (c7r3MT === "M" && c7r4MT === "M") {     
        setRow3NakedM07(
                               [
                                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                               ]
                             )
                               setC7R3VNo(5);
                               setC7R4VNo(5);
       } else if (c7r3MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(5);
        setC7R2VNo(5);
        setC7R3VNo(5);
        setC7R4VNo(5);
       }
        } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {  
                      alert("Already Added");
                    } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {
                      alert("Already Added");
        } else if (curX === col07X && curY === row03X && c7R3VNo === 7) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                             ]
                           )
                     
                             setC7R3VNo(8);
                            } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                              setRow2NakedM07(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                ]
                              )
                        
                                setC7R2VNo(8);
                                setC7R3VNo(8);
                            } else if (c7r3MT === "M" && c7r4MT === "M") {    
         setRow3NakedM07(
                               [
                                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                               ]
                             )
                       
                               setC7R3VNo(8);
                               setC7R4VNo(8);
       } else if (c7r3MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(8);
        setC7R2VNo(8);
        setC7R3VNo(8);
        setC7R4VNo(8);
       }
        } else if (curX === col07X && curY === row03X && c7R3VNo === 8) {
                      alert("Already Added");
                    } else if (curX === col07X && curY === row03X && c7R3VNo === 9) {
                      alert("Already Added");
                    } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
                      alert("Already Added");
                    } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
                      alert("Already Added");
          } else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
            if (c8r3MT === "S") {  
              setRow3Naked08(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
                              ]
                            )
                          
                              setC8R3VNo(3);
                            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                              setBaseNakedM08(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                            
                                setC8R1VNo(3);
                          setC8R2VNo(3);
                            } else if (c8r3MT === "M" && c8r4MT === "M") {       
          setRow3NakedM08(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                ]
                              )
                            
                                setC8R3VNo(3);
                          setC8R4VNo(3);
        } else if (c8r3MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
        }
          } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
            if (c8r3MT === "S") {  
              setRow3Naked08(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
                              ]
                            )
                      
                        setC8R3VNo(6);
                      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                        setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                          ]
                        )
                  
                    setC8R2VNo(6);
                    setC8R3VNo(6);
                      } else if (c8r3MT === "M" && c8r4MT === "M") {    
          setRow3NakedM08(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                ]
                              )
                        
                          setC8R3VNo(6);
                          setC8R4VNo(6);
        } else if (c8r3MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
        }
          } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
                        alert("Already Added");
                      //setC1R3VNo(6);
          } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
            if (c8r3MT === "S") {  
              setRow3Naked08(
                               [
                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                               ]
                             )
                               setC8R3VNo(5);
                              } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                                setRow2NakedM08(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                  ]
                                )
                                  setC8R2VNo(5);
                                  setC8R3VNo(5);
                              } else if (c8r3MT === "M" && c8r4MT === "M") {      
          setRow3NakedM08(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                 ]
                               )
                                 setC8R3VNo(5);
                                 setC8R4VNo(5);
         } else if (c8r3MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
         }
        } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {  
                      alert("Already Added");
                    } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {
                      alert("Already Added");
         } else if (curX === col08X && curY === row03X && c8R3VNo === 7) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                             ]
                           )
                     
                             setC8R3VNo(8);
                            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                              setRow2NakedM08(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                ]
                              )
                        
                                setC8R2VNo(8);
                                setC8R3VNo(8);
                            } else if (c8r3MT === "M" && c8r4MT === "M") {     
         setRow3NakedM08(
                               [
                                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                               ]
                             )
                       
                               setC8R3VNo(8);
                               setC8R4VNo(8);
       } else if (c8r3MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(8);
        setC8R2VNo(8);
        setC8R3VNo(8);
        setC8R4VNo(8);
       }
          } else if (curX === col08X && curY === row03X && c8R3VNo === 8) {
                      alert("Already Added");
                    } else if (curX === col08X && curY === row03X && c8R3VNo === 9) {
                      alert("Already Added");
                    } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
                      alert("Already Added");
                    } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
                      alert("Already Added");
        } else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
            if (c9r3MT === "S") {  
              setRow3Naked09(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
                                        ]
                                      )
                                    
                                        setC9R3VNo(3);
                                      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                                        setRow2NakedM09(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                          ]
                                        )
                                      
                                          setC9R2VNo(3);
                                    setC9R3VNo(3);
                                      } else if (c9r3MT === "M" && c9r4MT === "M") {      
                setRow3NakedM09(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                      ]
                                    )
                                  
                                      setC9R3VNo(3);
                                setC9R4VNo(3);
              } else if (c9r3MT === "L") {  
                setBaseNakedL09(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(3);
                setC9R2VNo(3);
                setC9R3VNo(3);
                setC9R4VNo(3);
              }
        } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
                                     ]
                                   )
                             
                               setC9R3VNo(6);
                              } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                                setRow2NakedM09(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                  ]
                                )
                          
                            setC9R2VNo(6);
                            setC9R3VNo(6);
                              } else if (c9r3MT === "M" && c9r4MT === "M") {      
              setRow3NakedM09(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                   ]
                                 )
                           
                             setC9R3VNo(6);
                             setC9R4VNo(6);
           } else if (c9r3MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(6);
            setC9R2VNo(6);
            setC9R3VNo(6);
            setC9R4VNo(6);
           } 
        } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
                            alert("Already Added");
                          //setC1R3VNo(6);
        } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                                    ]
                                  )
                                    setC9R3VNo(5);
                                  } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                                    setRow2NakedM09(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                      ]
                                    )
                                      setC9R2VNo(5);
                                      setC9R3VNo(5);
                                  } else if (c9r3MT === "M" && c9r4MT === "M") {     
              setRow3NakedM09(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                  ]
                                )
                                  setC9R3VNo(5);
                                  setC9R4VNo(5);
          } else if (c9r3MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(5);
            setC9R2VNo(5);
            setC9R3VNo(5);
            setC9R4VNo(5);
          }
          } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {  
                          alert("Already Added");
                        } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {
                          alert("Already Added");
          } else if (curX === col09X && curY === row03X && c9R3VNo === 7) {
            if (c9r3MT === "S") {  
              setRow3Naked09(
                                       [
                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                                       ]
                                     )
                               
                                       setC9R3VNo(8);
                                      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                                        setRow2NakedM09(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                          ]
                                        )
                                  
                                          setC9R2VNo(8);
                                          setC9R3VNo(8);
                                      } else if (c9r3MT === "M" && c9r4MT === "M") {       
                 setRow3NakedM09(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                     ]
                                   )
                             
                                     setC9R3VNo(8);
                                     setC9R4VNo(8);
             } else if (c9r3MT === "L") {  
              setBaseNakedL09(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(8);
              setC9R2VNo(8);
              setC9R3VNo(8);
              setC9R4VNo(8);
             }
          } else if (curX === col09X && curY === row03X && c9R3VNo === 8) {
                          alert("Already Added");
                        } else if (curX === col09X && curY === row03X && c9R3VNo === 9) {
                          alert("Already Added");
                        } else if (curX === col09X && curY === row03X && c9R3VNo === 10) {
                          alert("Already Added");
                        } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
                          alert("Already Added");
            } else if (curX === col10X && curY === row03X && c10R3VNo === 1) {
              if (c10r3MT === "S") {  
                setRow3Naked10(
                                         [
                                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
                                         ]
                                       )
                                     
                                         setC10R3VNo(3);
                                        } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                                          setRow2NakedM10(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                            ]
                                          )
                                        
                                            setC10R2VNo(3);
                                      setC10R3VNo(3);
                                        } else if (c10r3MT === "M" && c10r4MT === "M") {    
                  setRow3NakedM10(
                                       [
                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                       ]
                                     )
                                   
                                       setC10R3VNo(3);
                                 setC10R4VNo(3);
               } else if (c10r3MT === "L") {  
                setBaseNakedL10(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(3);
                setC10R2VNo(3);
                setC10R3VNo(3);
                setC10R4VNo(3);
               }
            } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
              if (c10r3MT === "S") {  
                setRow3Naked10(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
                                        ]
                                      )
                                
                                  setC10R3VNo(6);
                                } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                                  setRow2NakedM10(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                    ]
                                  )
                            
                              setC10R2VNo(6);
                              setC10R3VNo(6);
                                } else if (c10r3MT === "M" && c10r4MT === "M") {      
                  setRow3NakedM10(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                      ]
                                    )
                              
                                setC10R3VNo(6);
                                setC10R4VNo(6);
              } else if (c10r3MT === "L") {  
                setBaseNakedL10(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(6);
                setC10R2VNo(6);
                setC10R3VNo(6);
                setC10R4VNo(6);
              }
            } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
                            alert("Already Added");
                  
            } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
              if (c10r3MT === "S") {  
                setRow3Naked10(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                                        ]
                                      )
                                        setC10R3VNo(5);
                                      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                                        setRow2NakedM10(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                          ]
                                        )
                                          setC10R2VNo(5);
                                          setC10R3VNo(5);
                                      } else if (c10r3MT === "M" && c10r4MT === "M") {     
                  setRow3NakedM10(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                      ]
                                    )
                                      setC10R3VNo(5);
                                      setC10R4VNo(5);
              } else if (c10r3MT === "L") {  
                setBaseNakedL10(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(5);
                setC10R2VNo(5);
                setC10R3VNo(5);
                setC10R4VNo(5);
              } 

            } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {  
                          alert("Already Added");
                        } else if (curX === col10X && curY === row03X && c10R3VNo === 6) {
                          alert("Already Added");
            } else if (curX === col10X && curY === row03X && c10R3VNo === 7) {
              if (c10r3MT === "S") {  
                setRow3Naked10(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                                      ]
                                    )
                              
                                      setC10R3VNo(8);
                                    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                                      setRow2NakedM10(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                        ]
                                      )
                                
                                        setC10R2VNo(8);
                                        setC10R3VNo(8);
                                    } else if (c10r3MT === "M" && c10r4MT === "M") {   
                 setRow3NakedM10(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                    ]
                                  )
                            
                                    setC10R3VNo(8);
                                    setC10R4VNo(8);
            } else if (c10r3MT === "L") {  
              setBaseNakedL10(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(8);
              setC10R2VNo(8);
              setC10R3VNo(8);
              setC10R4VNo(8);
            }
            } else if (curX === col10X && curY === row03X && c10R3VNo === 8) {
                          alert("Already Added");
                        } else if (curX === col10X && curY === row03X && c10R3VNo === 9) {
                          alert("Already Added");
                        } else if (curX === col10X && curY === row03X && c10R3VNo === 10) {
                          alert("Already Added");
                        } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {
                          alert("Already Added");
             }  else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
                          if (c11r3MT === "S") {  
                            setRow3Naked11(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
                                                  ]
                                                )
                                              
                                                  setC11R3VNo(3);
                                                } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                                                  setRow2NakedM11(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                                    ]
                                                  )
                                                
                                                    setC11R2VNo(3);
                                              setC11R3VNo(3);
                                                } else if (c11r3MT === "M" && c11r4MT === "M") {     
                            setRow3NakedM11(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                ]
                                              )
                                            
                                                setC11R3VNo(3);
                                          setC11R4VNo(3);
                        } else if (c11r3MT === "L") {  
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(3);
                          setC11R2VNo(3);
                          setC11R3VNo(3);
                          setC11R4VNo(3);
                        }
              } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
                      if (c11r3MT === "S") {  
                        setRow3Naked11(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
                                              ]
                                            )
                                      
                                        setC11R3VNo(6);
                                      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                                        setRow2NakedM11(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                          ]
                                        )
                                  
                                    setC11R2VNo(6);
                                    setC11R3VNo(6);
                                      } else if (c11r3MT === "M" && c11r4MT === "M") {      
                          setRow3NakedM11(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                            ]
                                          )
                                    
                                      setC11R3VNo(6);
                                      setC11R4VNo(6);
                    } else if (c11r3MT === "L") {  
                      setBaseNakedL11(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                        ]
                      )
                      setC11R1VNo(6);
                      setC11R2VNo(6);
                      setC11R3VNo(6);
                      setC11R4VNo(6);
                    }
              } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
                            alert("Already Added");
                          //setC1R3VNo(6);
              } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
                        if (c11r3MT === "S") {  
                          setRow3Naked11(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                                                ]
                                              )
                                                setC11R3VNo(5);
                                              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                                                setRow2NakedM11(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                                  ]
                                                )
                                                  setC11R2VNo(5);
                                                  setC11R3VNo(5);
                                              } else if (c11r3MT === "M" && c11r4MT === "M") {       
                            setRow3NakedM11(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                              ]
                                            )
                                              setC11R3VNo(5);
                                              setC11R4VNo(5);
                      } else if (c11r3MT === "L") {  
                        setBaseNakedL11(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                          ]
                        )
                        setC11R1VNo(5);
                        setC11R2VNo(5);
                        setC11R3VNo(5);
                        setC11R4VNo(5);
                      }
              } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {  
                          alert("Already Added");
                        } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {
                          alert("Already Added");
              } else if (curX === col11X && curY === row03X && c11R3VNo === 7) {
                        if (c11r3MT === "S") {  
                          setRow3Naked11(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                                              ]
                                            )
                                      
                                              setC11R3VNo(8);
                                            } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                                              setRow2NakedM11(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                                ]
                                              )
                                        
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                            } else if (c11r3MT === "M" && c11r4MT === "M") {       
                          setRow3NakedM11(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                            ]
                                          )
                                    
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                    } else if (c11r3MT === "L") {  
                      setBaseNakedL11(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                        ]
                      )
                      setC11R1VNo(8);
                      setC11R2VNo(8);
                      setC11R3VNo(8);
                      setC11R4VNo(8);
                    }
              } else if (curX === col11X && curY === row03X && c11R3VNo === 8) {
                          alert("Already Added");
                        } else if (curX === col11X && curY === row03X && c11R3VNo === 9) {
                          alert("Already Added");
                        } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
                          alert("Already Added");
                        } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
                          alert("Already Added");
              } else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
                if (c12r3MT === "S") {  
                  setRow3Naked12(
                                       [
                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
                                       ]
                                     )
                                   
                                       setC12R3VNo(3);
                                      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                                        setRow2NakedM12(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                          ]
                                        )
                                      
                                          setC12R2VNo(3);
                                    setC12R3VNo(3);
                                      } else if (c12r3MT === "M" && c12r4MT === "M") {     
                  setRow3NakedM12(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                     ]
                                   )
                                 
                                     setC12R3VNo(3);
                               setC12R4VNo(3);
             } else if (c12r3MT === "L") {  
              setBaseNakedL12(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(3);
              setC12R2VNo(3);
              setC12R3VNo(3);
              setC12R4VNo(3);
             }
              } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
                          if (c12r3MT === "S") {  
                            setRow3Naked12(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
                                              ]
                                            )
                                      
                                        setC12R3VNo(6);
                                      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                                        setRow2NakedM12(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                          ]
                                        )
                                  
                                    setC12R2VNo(6);
                                    setC12R3VNo(6);
                                      } else if (c12r3MT === "M" && c12r4MT === "M") {     
                          setRow3NakedM12(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                            ]
                                          )
                                    
                                      setC12R3VNo(6);
                                      setC12R4VNo(6);
                    } else if (c12r3MT === "L") {  
                      setBaseNakedL12(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(6);
                      setC12R2VNo(6);
                      setC12R3VNo(6);
                      setC12R4VNo(6);
                    }
              } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
                            alert("Already Added");
                          //setC1R3VNo(6);
              } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
                      if (c12r3MT === "S") {  
                        setRow3Naked12(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                                            ]
                                          )
                                            setC12R3VNo(5);
                                          } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                                            setRow2NakedM12(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                              ]
                                            )
                                              setC12R2VNo(5);
                                              setC12R3VNo(5);
                                          } else if (c12r3MT === "M" && c12r4MT === "M") {     
                      setRow3NakedM12(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                          ]
                                        )
                                          setC12R3VNo(5);
                                          setC12R4VNo(5);
                  } else if (c12r3MT === "L") {  
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(5);
                    setC12R2VNo(5);
                    setC12R3VNo(5);
                    setC12R4VNo(5);
                  }
              } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {  
                          alert("Already Added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {
                          alert("Already Added");
              } else if (curX === col12X && curY === row03X && c12R3VNo === 7) {
                          if (c12r3MT === "S") {  
                            setRow3Naked12(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                                                ]
                                              )
                                        
                                                setC12R3VNo(8);
                                              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                                                setRow2NakedM12(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                                  ]
                                                )
                                          
                                                  setC12R2VNo(8);
                                                  setC12R3VNo(8);
                                              } else if (c12r3MT === "M" && c12r4MT === "M") {      
                          setRow3NakedM12(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                              ]
                                            )
                                      
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                      } else if (c12r3MT === "L") {  
                        setBaseNakedL12(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                          ]
                        )
                        setC12R1VNo(8);
                        setC12R2VNo(8);
                        setC12R3VNo(8);
                        setC12R4VNo(8);
                      }
              } else if (curX === col12X && curY === row03X && c12R3VNo === 8) {
                          alert("Already Added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 9) {
                          alert("Already Added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
                          alert("Already Added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
                          alert("Already Added");
            }  else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
                      if (c13r3MT === "S") {  
                        setRow3Naked13(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
                                            ]
                                          )
                                        
                                            setC13R3VNo(3);
                                          } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                                            setRow2NakedM13(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                              ]
                                            )
                                          
                                              setC13R2VNo(3);
                                        setC13R3VNo(3);
                                          } else if (c13r3MT === "M" && c13r4MT === "M") {       
                      setRow3NakedM13(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                          ]
                                        )
                                      
                                          setC13R3VNo(3);
                                    setC13R4VNo(3);
                  } else if (c13r3MT === "L") {  
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(3);
                    setC13R2VNo(3);
                    setC13R3VNo(3);
                    setC13R4VNo(3);
                  }
            } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
                      if (c13r3MT === "S") {  
                        setRow3Naked13(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
                                            ]
                                          )
                                    
                                      setC13R3VNo(6);
                                    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                                      setRow2NakedM13(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                        ]
                                      )
                                
                                  setC13R2VNo(6);
                                  setC13R3VNo(6);
                                    } else if (c13r3MT === "M" && c13r4MT === "M") {       
                      setRow3NakedM13(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC13R3VNo(6);
                                    setC13R4VNo(6);
                  } else if (c13r3MT === "L") {  
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(6);
                    setC13R2VNo(6);
                    setC13R3VNo(6);
                    setC13R4VNo(6);
                  }
            } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
                            alert("Already Added");
                          //setC1R3VNo(6);
            } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
                          if (c13r3MT === "S") {  
                            setRow3Naked13(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                                                ]
                                              )
                                                setC13R3VNo(5);
                                              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                                                setRow2NakedM13(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                                  ]
                                                )
                                                  setC13R2VNo(5);
                                                  setC13R3VNo(5);
                                              } else if (c13r3MT === "M" && c13r4MT === "M") {      
                          setRow3NakedM13(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                              ]
                                            )
                                              setC13R3VNo(5);
                                              setC13R4VNo(5);
                      } else if (c13r3MT === "L") {  
                        setBaseNakedL13(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(5);
                        setC13R2VNo(5);
                        setC13R3VNo(5);
                        setC13R4VNo(5);
                      }
            } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {  
                          alert("Already Added");
                        } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {
                          alert("Already Added");
            } else if (curX === col13X && curY === row03X && c13R3VNo === 7) {
                          if (c13r3MT === "S") {  
                            setRow3Naked13(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                                                ]
                                              )
                                        
                                                setC13R3VNo(8);
                                              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                                                setRow2NakedM13(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                                  ]
                                                )
                                          
                                                  setC13R2VNo(8);
                                                  setC13R3VNo(8);
                                              } else if (c13r3MT === "M" && c13r4MT === "M") {      
                            setRow3NakedM13(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                              ]
                                            )
                                      
                                              setC13R3VNo(8);
                                              setC13R4VNo(8);
                      } else if (c13r3MT === "L") {  
                        setBaseNakedL13(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(8);
                        setC13R2VNo(8);
                        setC13R3VNo(8);
                        setC13R4VNo(8);
                      }
            } else if (curX === col13X && curY === row03X && c13R3VNo === 8) {
                          alert("Already Added");
                        } else if (curX === col13X && curY === row03X && c13R3VNo === 9) {
                          alert("Already Added");
                        } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
                          alert("Already Added");
                        } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
                          alert("Already Added");
            } else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
                      if (c14r3MT === "S") {  
                        setRow3Naked14(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
                                                    ]
                                                  )
                                                
                                                    setC14R3VNo(3);
                                                  } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                                                    setRow2NakedM14(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                      ]
                                                    )
                                                  
                                                      setC14R2VNo(3);
                                                setC14R3VNo(3);
                                                  } else if (c14r3MT === "M" && c14r4MT === "M") {      
                        setRow3NakedM14(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                          ]
                                        )
                                      
                                          setC14R3VNo(3);
                                    setC14R4VNo(3);
                  } else if (c14r3MT === "L") {  
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(3);
                    setC14R2VNo(3);
                    setC14R3VNo(3);
                    setC14R4VNo(3);
                  }
            } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
                      if (c14r3MT === "S") {  
                        setRow3Naked14(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
                                                    ]
                                                  )
                                            
                                              setC14R3VNo(6);
                                            } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                                              setRow2NakedM14(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                ]
                                              )
                                        
                                          setC14R2VNo(6);
                                          setC14R3VNo(6);
                                            } else if (c14r3MT === "M" && c14r4MT === "M") {      
                    setRow3NakedM14(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC14R3VNo(6);
                                    setC14R4VNo(6);
                  } else if (c14r3MT === "L") {  
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(6);
                    setC14R2VNo(6);
                    setC14R3VNo(6);
                    setC14R4VNo(6);
                  }    
            } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
                                    alert("Already Added");
                                  //setC1R3VNo(6);
            } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
                    if (c14r3MT === "S") {  
                            setRow3Naked14(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                                                      ]
                                                    )
                                                      setC14R3VNo(5);
                                                    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                                                      setRow2NakedM14(
                                                        [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                        ]
                                                      )
                                                        setC14R2VNo(5);
                                                        setC14R3VNo(5);
                                                    } else if (c14r3MT === "M" && c14r4MT === "M") {      
                      setRow3NakedM14(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                            ]
                                          )
                                            setC14R3VNo(5);
                                            setC14R4VNo(5);
                    } else if (c14r3MT === "L") {  
                      setBaseNakedL14(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(5);
                      setC14R2VNo(5);
                      setC14R3VNo(5);
                      setC14R4VNo(5);
                    }
            } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {  
                                  alert("Already Added");
                                } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {
                                  alert("Already Added");
            } else if (curX === col14X && curY === row03X && c14R3VNo === 7) {
              if (c14r3MT === "S") {  
                setRow3Naked14(
                                                 [
                                                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                                                 ]
                                               )
                                         
                                                 setC14R3VNo(8);
                                                } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                                                  setRow2NakedM14(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                    ]
                                                  )
                                            
                                                    setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                } else if (c14r3MT === "M" && c14r4MT === "M") {      
                   setRow3NakedM14(
                                       [
                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                       ]
                                     )
                               
                                       setC14R3VNo(8);
                                       setC14R4VNo(8);
               } else if (c14r3MT === "L") {  
                setBaseNakedL14(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(8);
                setC14R2VNo(8);
                setC14R3VNo(8);
                setC14R4VNo(8);
               }
            } else if (curX === col14X && curY === row03X && c14R3VNo === 8) {
                                  alert("Already Added");
                                } else if (curX === col14X && curY === row03X && c14R3VNo === 9) {
                                  alert("Already Added");
                                } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
                                  alert("Already Added");
                                } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
                                  alert("Already Added");
            }  else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
              if (c1r4MT === "S") {  
                setRow4Naked01(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
                                                  ]
                                                )
                                              
                                                  setC1R4VNo(3);
              } else if (c1r4MT === "M") {      
                  setRow3NakedM01(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                      ]
                                    )
                                  
                                      setC1R3VNo(3);
                                setC1R4VNo(3);
              } else if (c1r4MT === "L") {  
                setBaseNakedL01(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(3);
                setC1R2VNo(3);
                setC1R3VNo(3);
                setC1R4VNo(3);
              }
            } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
                      if (c1r4MT === "S") {  
                        setRow4Naked01(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
                                                        ]
                                                      )
                                                
                                                      setC1R4VNo(6);
                    } else if (c1r4MT === "M") {      
                          setRow3NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                            
                              setC1R3VNo(6);
                              setC1R4VNo(6);
                    } else if (c1r4MT === "L") {  
                      setBaseNakedL01(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                        ]
                      )
                      setC1R1VNo(6);
                      setC1R2VNo(6);
                      setC1R3VNo(6);
                      setC1R4VNo(6);
                    }
            } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
                                     alert("Already Added");
                                    //setC1R4VNo(6);
            } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
              if (c1r4MT === "S") {  
                setRow4Naked01(
                                                 [
                                                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                                                 ]
                                               )
                                                 setC1R4VNo(5);
             } else if (c1r4MT === "M") {      
                   setRow3NakedM01(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                     ]
                                   )
                                     setC1R3VNo(5);
                                     setC1R4VNo(5);
             } else if (c1r4MT === "L") {  
              setBaseNakedL01(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(5);
              setC1R2VNo(5);
              setC1R3VNo(5);
              setC1R4VNo(5);
             } 
            } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {  
                                    alert("Already Added");
                                  } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {
                                    alert("Already Added");
            } else if (curX === col01X && curY === row04X && c1R4VNo === 7) {
              if (c1r4MT === "S") {  
                setRow4Naked01(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                                                  ]
                                                )
                                          
                                                  setC1R4VNo(8);
              } else if (c1r4MT === "M") {      
                     setRow3NakedM01(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                      ]
                                    )
                              
                                      setC1R3VNo(8);
                                      setC1R4VNo(8);
              } else if (c1r4MT === "L") {  
                setBaseNakedL01(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(8);
                setC1R2VNo(8);
                setC1R3VNo(8);
                setC1R4VNo(8);
              }
            } else if (curX === col01X && curY === row04X && c1R4VNo === 8) {
                                    alert("Already Added");
                                  } else if (curX === col01X && curY === row04X && c1R4VNo === 9) {
                                    alert("Already Added");
                                  } else if (curX === col01X && curY === row04X && c1R4VNo === 10) {
                                    alert("Already Added");
                                  } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
                                    alert("Already Added");
                                    //2nd col
            } else if (curX === col02X && curY === row04X && c2R4VNo === 1) {
              if (c2r4MT === "S") {  
                setRow4Naked02(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
                                                  ]
                                                )
                                              
                                                  setC2R4VNo(3);
              } else if (c2r4MT === "M") {      
                   setRow3NakedM02(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                              ]
                            )
                          
                              setC2R3VNo(3);
                        setC2R4VNo(3);
              } else if (c2r4MT === "L") {  
                setBaseNakedL02(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                  ]
                )
                setC2R1VNo(3);
                setC2R2VNo(3);
                setC2R3VNo(3);
                setC2R4VNo(3);
              }
            } else if (curX === col02X && curY === row04X && c2R4VNo === 2) {
              if (c2r4MT === "S") {  
                setRow4Naked02(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
                                                ]
                                              )
                                        
                                              setC2R4VNo(6);
                } else if (c2r4MT === "M") {      
                    setRow3NakedM02(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                              ]
                                            )
                                      
                                        setC2R3VNo(6);
                                        setC2R4VNo(6);
                } else if (c2r4MT === "L") {  
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(6);
                  setC2R2VNo(6);
                  setC2R3VNo(6);
                  setC2R4VNo(6);
                }
            } else if (curX === col02X && curY === row04X && c2R4VNo === 3) {
                                      alert("Already Added");
                                    //setC1R4VNo(6);
            } else if (curX === col02X && curY === row04X && c2R4VNo === 4) {
                          if (c2r4MT === "S") {  
                            setRow4Naked02(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                                                          ]
                                                        )
                                                          setC2R4VNo(5);
                      } else if (c2r4MT === "M") {      
                          setRow3NakedM02(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                                    ]
                                                  )
                                                    setC2R3VNo(5);
                                                    setC2R4VNo(5);
                      } else if (c2r4MT === "L") {  
                        setBaseNakedL02(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(5);
                        setC2R2VNo(5);
                        setC2R3VNo(5);
                        setC2R4VNo(5)
                      }
              } else if (curX === col02X && curY === row04X && c2R4VNo === 5) {  
                                    alert("Already Added");
                                  } else if (curX === col02X && curY === row04X && c2R4VNo === 6) {
                                    alert("Already Added");
              } else if (curX === col02X && curY === row04X && c2R4VNo === 7) {
                if (c2r4MT === "S") {  
                  setRow4Naked02(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                                              ]
                                            )
                                        
                                                          setC2R4VNo(8);
                    } else if (c2r4MT === "M") {      
                        setRow3NakedM02(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                                  ]
                                                )
                                          
                                                  setC2R3VNo(8);
                                                  setC2R4VNo(8);
                    } else if (c2r4MT === "L") {  
                      setBaseNakedL02(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                      setC2R1VNo(8);
                      setC2R2VNo(8);
                      setC2R3VNo(8);
                      setC2R4VNo(8);
                    }
            } else if (curX === col02X && curY === row04X && c2R4VNo === 8) {
                                      alert("Already Added");
                                    } else if (curX === col02X && curY === row04X && c2R4VNo === 9) {
                                      alert("Already Added");
                                    } else if (curX === col02X && curY === row04X && c2R4VNo === 10) {
                                      alert("Already Added");
                                    } else if (curX === col02X && curY === row04X && c2R4VNo === 11) {
                                      alert("Already Added");
            } else if (curX === col03X && curY === row04X && c3R4VNo === 1) {
                  if (c3r4MT === "S") {  
                        setRow4Naked03(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
                                                      ]
                                                    )
                                                  
                                                      setC3R4VNo(3);
                  } else if (c3r4MT === "M") {      
                    setRow3NakedM03(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                          ]
                                        )
                                      
                                          setC3R3VNo(3);
                                    setC3R4VNo(3);
                  } else if (c3r4MT === "L") {  
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(3);
                    setC3R2VNo(3);
                    setC3R3VNo(3);
                    setC3R4VNo(3);
                  }
            } else if (curX === col03X && curY === row04X && c3R4VNo === 2) {
              if (c3r4MT === "S") {  
                setRow4Naked03(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
                                            ]
                                          )
                                    
                                                setC3R4VNo(6);
                  } else if (c3r4MT === "M") {      
                    setRow3NakedM03(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC3R3VNo(6);
                                    setC3R4VNo(6);
                  } else if (c3r4MT === "L") {  
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(6);
                    setC3R2VNo(6);
                    setC3R3VNo(6);
                    setC3R4VNo(6);
                  }
            } else if (curX === col03X && curY === row04X && c3R4VNo === 3) {
                                      alert("Already Added");
                        
            } else if (curX === col03X && curY === row04X && c3R4VNo === 4) {
              if (c3r4MT === "S") {  
                setRow4Naked03(
                                           [
                                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                                           ]
                                         )
                                                  setC3R4VNo(5);
              } else if (c3r4MT === "M") {      
                setRow3NakedM03(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                      ]
                                    )
                                      setC3R3VNo(5);
                                      setC3R4VNo(5);
              } else if (c3r4MT === "L") {  
                setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(5);
                setC3R2VNo(5);
                setC3R3VNo(5);
                setC3R4VNo(5);
              }
          } else if (curX === col03X && curY === row04X && c3R4VNo === 5) {  
                                    alert("Already Added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 6) {
                                    alert("Already Added");
          } else if (curX === col03X && curY === row04X && c3R4VNo === 7) {
            if (c3r4MT === "S") {  
              setRow4Naked03(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                                                    ]
                                                  )
                                            
                                                    setC3R4VNo(8);
                } else if (c3r4MT === "M") {      
                  setRow3NakedM03(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                        ]
                                      )
                                
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                } else if (c3r4MT === "L") {  
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(8);
                  setC3R2VNo(8);
                  setC3R3VNo(8);
                  setC3R4VNo(8);
                }
          } else if (curX === col03X && curY === row04X && c3R4VNo === 8) {
                                    alert("Already Added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 9) {
                                    alert("Already Added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 10) {
                                    alert("Already Added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {
                                    alert("Already Added");
          } else if (curX === col04X && curY === row04X && c4R4VNo === 1) {
            if (c4r4MT === "S") {  
              setRow4Naked04(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
                                            ]
                                          )
                                        
                                                      setC4R4VNo(3);
                } else if (c4r4MT === "M") {      
                  setRow3NakedM03(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                        ]
                                      )
                                
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                } else if (c4r4MT === "L") {  
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(3);
                  setC4R2VNo(3);
                  setC4R3VNo(3);
                  setC3R4VNo(3);
                }
          } else if (curX === col04X && curY === row04X && c4R4VNo === 2) {
                      if (c4r4MT === "S") {  
                        setRow4Naked04(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
                                                      ]
                                                    )
                                              
                                                setC4R4VNo(6);
                } else if (c4r4MT === "M") {      
                  setRow3NakedM04(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                        ]
                                      )
                                
                                  setC4R3VNo(6);
                                  setC4R4VNo(6);
                } else if (c4r4MT === "L") {  
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(6);
                  setC4R2VNo(6);
                  setC4R3VNo(6);
                  setC4R4VNo(6);
                }
          } else if (curX === col04X && curY === row04X && c4R4VNo === 3) {
                                        alert("Already Added");
                                      //setC1R4VNo(6);
        } else if (curX === col04X && curY === row04X && c4R4VNo === 4) {
                    if (c4r4MT === "S") {  
                      setRow4Naked04(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                                                    ]
                                                  )
                                                    setC4R4VNo(5);
              } else if (c4r4MT === "M") {      
                setRow3NakedM04(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                      ]
                                    )
                                      setC4R3VNo(5);
                                      setC4R4VNo(5);
              } else if (c4r4MT === "L") {  
                setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(5);
                setC4R2VNo(5);
                setC4R3VNo(5);
                setC4R4VNo(5);
              }
          } else if (curX === col04X && curY === row04X && c4R4VNo === 5) {  
                                      alert("Already Added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 6) {
                                      alert("Already Added");
            } else if (curX === col04X && curY === row04X && c4R4VNo === 7) {
                        if (c4r4MT === "S") {  
                          setRow4Naked04(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                                                        ]
                                                      )
                                                
                                                        setC4R4VNo(8);
                  } else if (c4r4MT === "M") {      
                    setRow3NakedM04(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
                  } else if (c4r4MT === "L") {  
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(8);
                    setC4R2VNo(8);
                    setC4R3VNo(8);
                    setC4R4VNo(8);
                  }
          } else if (curX === col04X && curY === row04X && c4R4VNo === 8) {
                                      alert("Already Added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 9) {
                                      alert("Already Added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 10) {
                                      alert("Already Added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {
                                      alert("Already Added");
            } else if (curX === col05X && curY === row04X && c5R4VNo === 1) {
                          if (c5r4MT === "S") {  
                            setRow4Naked05(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
                                                              ]
                                                            )
                                                          
                                                              setC5R4VNo(3);
                    } else if (c5r4MT === "M") {      
                      setRow3NakedM05(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                            ]
                                          )
                                        
                                            setC5R3VNo(3);
                                      setC5R4VNo(3);
                    } else if (c5r4MT === "L") {  
                      setBaseNakedL05(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                        ]
                      )
                      setC5R1VNo(3);
                      setC5R2VNo(3);
                      setC5R3VNo(3);
                      setC5R4VNo(3);
                    }
            } else if (curX === col05X && curY === row04X && c5R4VNo === 2) {
                            if (c5r4MT === "S") {  
                              setRow4Naked05(
                                                                [
                                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
                                                                ]
                                                              )
                                                        
                                                          setC5R4VNo(6);
                      } else if (c5r4MT === "M") {      
                        setRow3NakedM05(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                              ]
                                            )
                                      
                                        setC5R3VNo(6);
                                        setC5R4VNo(6);
                      } else if (c5r4MT === "L") {  
                        setBaseNakedL05(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                          ]
                        )
                        setC5R1VNo(6);
                        setC5R2VNo(6);
                        setC5R3VNo(6);
                        setC5R4VNo(6);
                      }
              } else if (curX === col05X && curY === row04X && c5R4VNo === 3) {
                                            alert("Already Added");
                                          //setC1R4VNo(6);
              } else if (curX === col05X && curY === row04X && c5R4VNo === 4) {
                    if (c5r4MT === "S") {  
                            setRow4Naked05(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                                                              ]
                                                            )
                                                              setC5R4VNo(5);
                    } else if (c5r4MT === "M") {      
                      setRow3NakedM05(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                            ]
                                          )
                                            setC5R3VNo(5);
                                            setC5R4VNo(5);
                    } else if (c5r4MT === "L") {  
                      setBaseNakedL05(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                        ]
                      )
                      setC5R1VNo(5);
                      setC5R2VNo(5);
                      setC5R3VNo(5);
                      setC5R4VNo(5);
                    }
              } else if (curX === col05X && curY === row04X && c5R4VNo === 5) {  
                                          alert("Already Added");
                                        } else if (curX === col05X && curY === row04X && c5R4VNo === 6) {
                                          alert("Already Added");
              } else if (curX === col05X && curY === row04X && c5R4VNo === 7) {
                            if (c5r4MT === "S") {  
                              setRow4Naked05(
                                                                  [
                                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                                                                  ]
                                                                )
                                                          
                                                                  setC5R4VNo(8);
                        } else if (c5r4MT === "M") {      
                        setRow3NakedM05(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                ]
                                              )
                                        
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                        } else if (c5r4MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(8);
                          setC5R2VNo(8);
                          setC5R3VNo(8);
                          setC5R4VNo(8);
                        }
              } else if (curX === col05X && curY === row04X && c5R4VNo === 8) {
                                          alert("Already Added");
                                        } else if (curX === col05X && curY === row04X && c5R4VNo === 9) {
                                          alert("Already Added");
                                        } else if (curX === col05X && curY === row04X && c5R4VNo === 10) {
                                          alert("Already Added");
                                        } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {
                                          alert("Already Added");
              } else if (curX === col06X && curY === row04X && c6R4VNo === 1) {
                          if (c6r4MT === "S") {  
                            setRow4Naked06(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
                                                            ]
                                                          )
                                                        
                                                            setC6R4VNo(3);
                      } else if (c6r4MT === "M") {      
                        setRow3NakedM06(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                              ]
                                            )
                                          
                                              setC6R3VNo(3);
                                        setC6R4VNo(3);
                      } else if (c6r4MT === "L") {  
                        setBaseNakedL06(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                          ]
                        )
                        setC6R1VNo(3);
                        setC6R2VNo(3);
                        setC6R3VNo(3);
                        setC6R4VNo(3);
                      }
            } else if (curX === col06X && curY === row04X && c6R4VNo === 2) {
                        if (c6r4MT === "S") {  
                          setRow4Naked06(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
                                                          ]
                                                        )
                                                  
                                                    setC6R4VNo(6);
                    } else if (c6r4MT === "M") {      
                        setRow3NakedM06(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                            ]
                                          )
                                    
                                      setC6R3VNo(6);
                                      setC6R4VNo(6);
                    } else if (c6r4MT === "L") {  
                      setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
                      setC6R1VNo(6);
                      setC6R2VNo(6);
                      setC6R3VNo(6);
                      setC6R4VNo(6);
                    }
          } else if (curX === col06X && curY === row04X && c6R4VNo === 3) {
                                        alert("Already Added");
                                      //setC1R4VNo(6);
          } else if (curX === col06X && curY === row04X && c6R4VNo === 4) {
                      if (c6r4MT === "S") {  
                        setRow4Naked06(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                                                        ]
                                                      )
                                                        setC6R4VNo(5);
                  } else if (c6r4MT === "M") {      
                      setRow3NakedM06(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                          ]
                                        )
                                          setC6R3VNo(5);
                                          setC6R4VNo(5);
                  } else if (c6r4MT === "L") {  
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(5);
                    setC6R2VNo(5);
                    setC6R3VNo(5);
                    setC6R4VNo(5);
                  }
          } else if (curX === col06X && curY === row04X && c6R4VNo === 5) {  
                                      alert("Already Added");
                                    } else if (curX === col06X && curY === row04X && c6R4VNo === 6) {
                                      alert("Already Added");
          } else if (curX === col06X && curY === row04X && c6R4VNo === 7) {
                        if (c6r4MT === "S") {  
                          setRow4Naked06(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                                                          ]
                                                        )
                                                  
                                                          setC6R4VNo(8);
                    } else if (c6r4MT === "M") {      
                      setRow3NakedM06(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                            ]
                                          )
                                    
                                            setC6R3VNo(8);
                                            setC6R4VNo(8);
                    } else if (c6r4MT === "L") {  
                      setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
                      setC6R1VNo(8);
                      setC6R2VNo(8);
                      setC6R3VNo(8);
                      setC6R4VNo(8);
                    }
          } else if (curX === col06X && curY === row04X && c6R4VNo === 8) {
                                      alert("Already Added");
                                    } else if (curX === col06X && curY === row04X && c6R4VNo === 9) {
                                      alert("Already Added");
                                    } else if (curX === col06X && curY === row04X && c6R4VNo === 10) {
                                      alert("Already Added");
                                    } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {
                                      alert("Already Added");
        } else if (curX === col07X && curY === row04X && c7R4VNo === 1) {
                  if (c7r4MT === "S") {  
                    setRow4Naked07(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
                                                    ]
                                                  )
                                                
                                                    setC7R4VNo(3);
              } else if (c7r4MT === "M") {      
                  setRow3NakedM07(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                      ]
                                    )
                                  
                                      setC7R3VNo(3);
                                setC7R4VNo(3);
              } else if (c7r4MT === "L") {  
                setBaseNakedL07(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                  ]
                )
                setC7R1VNo(3);
                setC7R2VNo(3);
                setC7R3VNo(3);
                setC7R4VNo(3);
              }
        } else if (curX === col07X && curY === row04X && c7R4VNo === 2) {
                        if (c7r4MT === "S") {  
                          setRow4Naked07(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
                                                        ]
                                                      )
                                                          
                                                    setC7R4VNo(6);
                  } else if (c7r4MT === "M") {      
                        setRow3NakedM07(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC7R3VNo(6);
                                    setC7R4VNo(6);
                  } else if (c7r4MT === "L") {  
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(6);
                    setC7R2VNo(6);
                    setC7R3VNo(6);
                    setC7R4VNo(6);
                  }
        } else if (curX === col07X && curY === row04X && c7R4VNo === 3) {
                                          alert("Already Added");
                                        //setC1R4VNo(6);
         } else if (curX === col07X && curY === row04X && c7R4VNo === 4) {
            if (c7r4MT === "S") {  
                    setRow4Naked07(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                                                    ]
                                                  )
                                                    setC7R4VNo(5);
            } else if (c7r4MT === "M") {      
                setRow3NakedM07(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                    ]
                                  )
                                    setC7R3VNo(5);
                                    setC7R4VNo(5);
            } else if (c7r4MT === "L") {  
              setBaseNakedL07(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(5);
              setC7R2VNo(5);
              setC7R3VNo(5);
              setC7R4VNo(5);
            }
         } else if (curX === col07X && curY === row04X && c7R4VNo === 5) {  
                                        alert("Already Added");
                                      } else if (curX === col07X && curY === row04X && c7R4VNo === 6) {
                                        alert("Already Added");
          } else if (curX === col07X && curY === row04X && c7R4VNo === 7) {
                if (c7r4MT === "S") {  
                        setRow4Naked07(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                                                        ]
                                                      )
                                                
                                                        setC7R4VNo(8);
                } else if (c7r4MT === "M") {      
                    setRow3NakedM07(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                        ]
                                      )
                                
                                        setC7R3VNo(8);
                                        setC7R4VNo(8);
                } else if (c7r4MT === "L") {  
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(8);
                  setC7R2VNo(8);
                  setC7R3VNo(8);
                  setC7R4VNo(8);
                }
            } else if (curX === col07X && curY === row04X && c7R4VNo === 8) {
                                        alert("Already Added");
                                      } else if (curX === col07X && curY === row04X && c7R4VNo === 9) {
                                        alert("Already Added");
                                      } else if (curX === col07X && curY === row04X && c7R4VNo === 10) {
                                        alert("Already Added");
                                      } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {
                                        alert("Already Added");
            } else if (curX === col08X && curY === row04X && c8R4VNo === 1) {
                        if (c8r4MT === "S") {  
                          setRow4Naked08(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
                                                          ]
                                                        )
                                                      
                                                          setC8R4VNo(3);
                  } else if (c8r4MT === "M") {      
                    setRow3NakedM08(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                          ]
                                        )
                                      
                                          setC8R3VNo(3);
                                    setC8R4VNo(3);
                  } else if (c8r4MT === "L") {  
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(3);
                    setC8R2VNo(3);
                    setC8R3VNo(3);
                    setC8R4VNo(3);
                  }
            } else if (curX === col08X && curY === row04X && c8R4VNo === 2) {
                          if (c8r4MT === "S") {  
                            setRow4Naked08(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
                                                          ]
                                                        )
                                                  
                                                    setC8R4VNo(6);
                  } else if (c8r4MT === "M") {      
                    setRow3NakedM08(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC8R3VNo(6);
                                    setC8R4VNo(6);
                  } else if (c8r4MT === "L") {  
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(6);
                    setC8R2VNo(6);
                    setC8R3VNo(6);
                    setC8R4VNo(6);
                  }
            } else if (curX === col08X && curY === row04X && c8R4VNo === 3) {
                                          alert("Already Added");
                                        //setC1R4VNo(6);
            } else if (curX === col08X && curY === row04X && c8R4VNo === 4) {
                              if (c8r4MT === "S") {  
                                setRow4Naked08(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                                                              ]
                                                            )
                                                              setC8R4VNo(5);
                      } else if (c8r4MT === "M") {      
                          setRow3NakedM08(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                              ]
                                            )
                                              setC8R3VNo(5);
                                              setC8R4VNo(5);
                      } else if (c8r4MT === "L") {  
                        setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                        setC8R1VNo(5);
                        setC8R2VNo(5);
                        setC8R3VNo(5);
                        setC8R4VNo(5);
                      }
          } else if (curX === col08X && curY === row04X && c8R4VNo === 5) {  
                                        alert("Already Added");
                                      } else if (curX === col08X && curY === row04X && c8R4VNo === 6) {
                                        alert("Already Added");
          } else if (curX === col08X && curY === row04X && c8R4VNo === 7) {
                  if (c8r4MT === "S") {  
                              setRow4Naked08(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                                                          ]
                                                        )
                                                  
                                                          setC8R4VNo(8);
                  } else if (c8r4MT === "M") {      
                        setRow3NakedM08(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                          setC8R3VNo(8);
                                          setC8R4VNo(8);
                  } else if (c8r4MT === "L") {  
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(8);
                    setC8R2VNo(8);
                    setC8R3VNo(8);
                    setC8R4VNo(8);
                  }
          } else if (curX === col08X && curY === row04X && c8R4VNo === 8) {
                                        alert("Already Added");
                                      } else if (curX === col08X && curY === row04X && c8R4VNo === 9) {
                                        alert("Already Added");
                                      } else if (curX === col08X && curY === row04X && c8R4VNo === 10) {
                                        alert("Already Added");
                                      } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {
                                        alert("Already Added");
          } else if (curX === col09X && curY === row04X && c9R4VNo === 1) {
                          if (c9r4MT === "S") {  
                            setRow4Naked09(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
                                                            ]
                                                          )
                                                        
                                                            setC9R4VNo(3);
                } else if (c9r4MT === "M") {      
                      setRow3NakedM09(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                        ]
                                      )
                                    
                                        setC9R3VNo(3);
                                  setC9R4VNo(3);
                } else if (c9r4MT === "L") {  
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(3);
                  setC9R2VNo(3);
                  setC9R3VNo(3);
                  setC9R4VNo(3);
                }
          } else if (curX === col09X && curY === row04X && c9R4VNo === 2) {
                  if (c9r4MT === "S") {  
                              setRow4Naked09(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
                                                              ]
                                                            )
                                                      
                                                        setC9R4VNo(6);
                  } else if (c9r4MT === "M") {      
                        setRow3NakedM09(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC9R3VNo(6);
                                    setC9R4VNo(6);
                  } else if (c9r4MT === "L") {  
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(6);
                    setC9R2VNo(6);
                    setC9R3VNo(6);
                    setC9R4VNo(6);
                  }
           } else if (curX === col09X && curY === row04X && c9R4VNo === 3) {
                                              alert("Already Added");
                                            //setC1R4VNo(6);
          } else if (curX === col09X && curY === row04X && c9R4VNo === 4) {
                              if (c9r4MT === "S") {  
                                setRow4Naked09(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                                                              ]
                                                            )
                                                              setC9R4VNo(5);
                  } else if (c9r4MT === "M") {      
                        setRow3NakedM09(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                          ]
                                        )
                                          setC9R3VNo(5);
                                          setC9R4VNo(5);
                  } else if (c9r4MT === "L") {  
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(5);
                    setC9R2VNo(5);
                    setC9R3VNo(5);
                    setC9R4VNo(5);
                  }
          } else if (curX === col09X && curY === row04X && c9R4VNo === 5) {  
                                            alert("Already Added");
                                          } else if (curX === col09X && curY === row04X && c9R4VNo === 6) {
                                            alert("Already Added");
          } else if (curX === col09X && curY === row04X && c9R4VNo === 7) {
                if (c9r4MT === "S") {  
                            setRow4Naked09(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                                                            ]
                                                          )
                                                    
                                                            setC9R4VNo(8);
                } else if (c9r4MT === "M") {      
                    setRow3NakedM09(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                        ]
                                      )
                                
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
                } else if (c9r4MT === "L") {  
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(8);
                  setC9R2VNo(8);
                  setC9R3VNo(8);
                  setC9R4VNo(8);
                }
          } else if (curX === col09X && curY === row04X && c9R4VNo === 8) {
                                            alert("Already Added");
                                          } else if (curX === col09X && curY === row04X && c9R4VNo === 9) {
                                            alert("Already Added");
                                          } else if (curX === col09X && curY === row04X && c9R4VNo === 10) {
                                            alert("Already Added");
                                          } else if (curX === col09X && curY === row04X && c9R4VNo === 11) {
                                            alert("Already Added");
          } else if (curX === col10X && curY === row04X && c10R4VNo === 1) {
                    if (c10r4MT === "S") {  
                                setRow4Naked10(
                                                                [
                                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
                                                                ]
                                                              )
                                                            
                                                                setC10R4VNo(3);
                    } else if (c10r4MT === "M") {      
                        setRow3NakedM10(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                            ]
                                          )
                                        
                                            setC10R3VNo(3);
                                      setC10R4VNo(3);
                    } else if (c10r4MT === "L") {  
                      setBaseNakedL10(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                        ]
                      )
                      setC10R1VNo(3);
                      setC10R2VNo(3);
                      setC10R3VNo(3);
                      setC10R4VNo(3);
                    }
          } else if (curX === col10X && curY === row04X && c10R4VNo === 2) {
                            if (c10r4MT === "S") {  
                              setRow4Naked10(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
                                                              ]
                                                            )
                                                      
                                                        setC10R4VNo(6);
                  } else if (c10r4MT === "M") {      
                      setRow3NakedM10(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC10R3VNo(6);
                                    setC10R4VNo(6);
                  } else if (c10r4MT === "L") {  
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(6);
                    setC10R2VNo(6);
                    setC10R3VNo(6);
                    setC10R4VNo(6);
                  }     
          } else if (curX === col10X && curY === row04X && c10R4VNo === 3) {
                                              alert("Already Added");
                                    
          } else if (curX === col10X && curY === row04X && c10R4VNo === 4) {
                    if (c10r4MT === "S") {  
                                setRow4Naked10(
                                                                [
                                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                                                                ]
                                                              )
                                                                setC10R4VNo(5);
                    } else if (c10r4MT === "M") {      
                        setRow3NakedM10(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                            ]
                                          )
                                            setC10R3VNo(5);
                                            setC10R4VNo(5);
                    } else if (c10r4MT === "L") {  
                      setBaseNakedL10(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                        ]
                      )
                      setC10R1VNo(5);
                      setC10R2VNo(5);
                      setC10R3VNo(5);
                      setC10R4VNo(5);
                    }
          } else if (curX === col10X && curY === row04X && c10R4VNo === 5) {  
                                            alert("Already Added");
                                          } else if (curX === col10X && curY === row04X && c10R4VNo === 6) {
                                            alert("Already Added");
           } else if (curX === col10X && curY === row04X && c10R4VNo === 7) {
                    if (c10r4MT === "S") {  
                              setRow4Naked10(
                                                                [
                                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                                                                ]
                                                              )
                                                        
                                                                setC10R4VNo(8);
                    } else if (c10r4MT === "M") {      
                      setRow3NakedM10(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                            ]
                                          )
                                    
                                            setC10R3VNo(8);
                                            setC10R4VNo(8);
                    } else if (c10r4MT === "L") {  
                      setBaseNakedL10(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                        ]
                      )
                      setC10R1VNo(8);
                      setC10R2VNo(8);
                      setC10R3VNo(8);
                      setC10R4VNo(8);
                    }
            } else if (curX === col10X && curY === row04X && c10R4VNo === 8) {
                                            alert("Already Added");
                                          } else if (curX === col10X && curY === row04X && c10R4VNo === 9) {
                                            alert("Already Added");
                                          } else if (curX === col10X && curY === row04X && c10R4VNo === 10) {
                                            alert("Already Added");
                                          } else if (curX === col10X && curY === row04X && c10R4VNo === 11) {
                                            alert("Already Added");
            }  else if (curX === col11X && curY === row04X && c11R4VNo === 1) {
                    if (c11r4MT === "S") {  
                                setRow4Naked11(
                                                                [
                                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
                                                                ]
                                                              )
                                                            
                                                                setC11R4VNo(3);
                    } else if (c11r4MT === "M") {      
                        setRow3NakedM11(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                            ]
                                          )
                                        
                                            setC11R3VNo(3);
                                      setC11R4VNo(3);
                    } else if (c11r4MT === "L") {  
                      setBaseNakedL11(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                        ]
                      )
                      setC11R1VNo(3);
                      setC11R2VNo(3);
                      setC11R3VNo(3);
                      setC11R4VNo(3);
                    }
            } else if (curX === col11X && curY === row04X && c11R4VNo === 2) {
                          if (c11r4MT === "S") {  
                            setRow4Naked11(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
                                                            ]
                                                          )
                                                    
                                                      setC11R4VNo(6);
                } else if (c11r4MT === "M") {      
                    setRow3NakedM11(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                        ]
                                      )
                                
                                  setC11R3VNo(6);
                                  setC11R4VNo(6);
                } else if (c11r4MT === "L") {  
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(6);
                  setC11R2VNo(6);
                  setC11R3VNo(6);
                  setC11R4VNo(6);
                }
            } else if (curX === col11X && curY === row04X && c11R4VNo === 3) {
                                              alert("Already Added");
                                            //setC1R4VNo(6);
            } else if (curX === col11X && curY === row04X && c11R4VNo === 4) {
                          if (c11r4MT === "S") {  
                            setRow4Naked11(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                                                              ]
                                                            )
                                                              setC11R4VNo(5);
                  } else if (c11r4MT === "M") {      
                      setRow3NakedM11(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                          ]
                                        )
                                          setC11R3VNo(5);
                                          setC11R4VNo(5);
                  } else if (c11r4MT === "L") {  
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(5);
                    setC11R2VNo(5);
                    setC11R3VNo(5);
                    setC11R4VNo(5);
                  }
            } else if (curX === col11X && curY === row04X && c11R4VNo === 5) {  
                                            alert("Already Added");
                                          } else if (curX === col11X && curY === row04X && c11R4VNo === 6) {
                                            alert("Already Added");
            } else if (curX === col11X && curY === row04X && c11R4VNo === 7) {
                          if (c11r4MT === "S") {  
                            setRow4Naked11(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                                                            ]
                                                          )
                                                    
                                                            setC11R4VNo(8);
                } else if (c11r4MT === "M") {      
                    setRow3NakedM11(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                        ]
                                      )
                                
                                        setC11R3VNo(8);
                                        setC11R4VNo(8);
                } else if (c11r4MT === "L") {  
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(8);
                  setC11R2VNo(8);
                  setC11R3VNo(8);
                  setC11R4VNo(8);
                }
            } else if (curX === col11X && curY === row04X && c11R4VNo === 8) {
                                            alert("Already Added");
                                          } else if (curX === col11X && curY === row04X && c11R4VNo === 9) {
                                            alert("Already Added");
                                          } else if (curX === col11X && curY === row04X && c11R4VNo === 10) {
                                            alert("Already Added");
                                          } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {
                                            alert("Already Added");
            } else if (curX === col12X && curY === row04X && c12R4VNo === 1) {
                  if (c12r4MT === "S") {  
                              setRow4Naked12(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
                                                              ]
                                                            )
                                                          
                                                              setC12R4VNo(3);
                  } else if (c12r4MT === "M") {      
                      setRow3NakedM12(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                          ]
                                        )
                                      
                                          setC12R3VNo(3);
                                    setC12R4VNo(3);
                  } else if (c12r4MT === "L") {  
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(3);
                    setC12R2VNo(3);
                    setC12R3VNo(3);
                    setC12R4VNo(3);
                  }
            } else if (curX === col12X && curY === row04X && c12R4VNo === 2) {
                  if (c12r4MT === "S") {  
                              setRow4Naked12(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
                                                            ]
                                                          )
                                                    
                                                      setC12R4VNo(6);
                } else if (c12r4MT === "M") {      
                    setRow3NakedM12(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                        ]
                                      )
                                
                                  setC12R3VNo(6);
                                  setC12R4VNo(6);
                } else if (c12r4MT === "L") {  
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(6);
                  setC12R2VNo(6);
                  setC12R3VNo(6);
                  setC12R4VNo(6);
                }
             } else if (curX === col12X && curY === row04X && c12R4VNo === 3) {
                                              alert("Already Added");
                                            //setC1R4VNo(6);
            } else if (curX === col12X && curY === row04X && c12R4VNo === 4) {
                    if (c12r4MT === "S") {  
                                setRow4Naked12(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                                                              ]
                                                            )
                                                              setC12R4VNo(5);
                  } else if (c12r4MT === "M") {      
                      setRow3NakedM12(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                          ]
                                        )
                                          setC12R3VNo(5);
                                          setC12R4VNo(5);
                  } else if (c12r4MT === "L") {  
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(5);
                    setC12R2VNo(5);
                    setC12R3VNo(5);
                    setC12R4VNo(5);
                  }
            } else if (curX === col12X && curY === row04X && c12R4VNo === 5) {  
                                            alert("Already Added");
                                          } else if (curX === col12X && curY === row04X && c12R4VNo === 6) {
                                            alert("Already Added");
            } else if (curX === col12X && curY === row04X && c12R4VNo === 7) {
              if (c12r4MT === "S") {  
                setRow2Naked12(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                      ]
                    )
              
                      setC12R4VNo(8);
                              } else if (c12r4MT === "M") {      
                        setRow2NakedM12(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                        ]
                      )
                
                        setC12R3VNo(8);
                        setC12R4VNo(8);
                              } else if (c12r4MT === "L") {  
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(8);
                                setC12R2VNo(8);
                                setC12R3VNo(8);
                                setC12R4VNo(8);
                              }       
            } else if (curX === col12X && curY === row04X && c12R4VNo === 8) {
                                            alert("Already Added");
                                          } else if (curX === col12X && curY === row04X && c12R4VNo === 9) {
                                            alert("Already Added");
                                          } else if (curX === col12X && curY === row04X && c12R4VNo === 10) {
                                            alert("Already Added");
                                          } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {
                                            alert("Already Added");
            }  else if (curX === col13X && curY === row04X && c13R4VNo === 1) {
                if (c13r4MT === "S") {  
                              setRow4Naked13(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
                                                            ]
                                                          )
                                                        
                                                            setC13R4VNo(3);
                } else if (c13r4MT === "M") {      
                    setRow3NakedM13(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                        ]
                                      )
                                    
                                        setC13R3VNo(3);
                                  setC13R4VNo(3);
                } else if (c13r4MT === "L") {  
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(3);
                  setC13R2VNo(3);
                  setC13R3VNo(3);
                  setC13R4VNo(3);
                }
          } else if (curX === col13X && curY === row04X && c13R4VNo === 2) {
                          if (c13r4MT === "S") {  
                            setRow4Naked13(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
                                                          ]
                                                        )
                                                  
                                                    setC13R4VNo(6);
              } else if (c13r4MT === "M") {      
                  setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                              
                                setC13R3VNo(6);
                                setC13R4VNo(6);
              } else if (c13r4MT === "L") {  
                setBaseNakedL13(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                  ]
                )
                setC13R1VNo(6);
                setC13R2VNo(6);
                setC13R3VNo(6);
                setC13R4VNo(6);
              }
          } else if (curX === col13X && curY === row04X && c13R4VNo === 3) {
                                              alert("Already Added");
                                            //setC1R4VNo(6);
          } else if (curX === col13X && curY === row04X && c13R4VNo === 4) {
                          if (c13r4MT === "S") {  
                            setRow4Naked13(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                                                            ]
                                                          )
                                                            setC13R4VNo(5);
                } else if (c13r4MT === "M") {      
                    setRow3NakedM13(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                        ]
                                      )
                                        setC13R3VNo(5);
                                        setC13R4VNo(5);
                } else if (c13r4MT === "L") {  
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(5);
                  setC13R2VNo(5);
                  setC13R3VNo(5);
                  setC13R4VNo(5);
                }
            } else if (curX === col13X && curY === row04X && c13R4VNo === 5) {  
                                            alert("Already Added");
                                          } else if (curX === col13X && curY === row04X && c13R4VNo === 6) {
                                            alert("Already Added");
          } else if (curX === col13X && curY === row04X && c13R4VNo === 7) {
                          if (c13r4MT === "S") {  
                            setRow4Naked13(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                                                            ]
                                                          )
                                                    
                                                            setC13R4VNo(8);
                } else if (c13r4MT === "M") {      
                    setRow3NakedM13(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                        ]
                                      )
                                
                                        setC13R3VNo(8);
                                        setC13R4VNo(8);
                } else if (c13r4MT === "L") {  
                  setC13R1VNo(8);
                            setC13R2VNo(8);
                            setC13R3VNo(8);
                            setC13R4VNo(8);
                }                      
          } else if (curX === col13X && curY === row04X && c13R4VNo === 8) {
                                            alert("Already Added");
                                          } else if (curX === col13X && curY === row04X && c13R4VNo === 9) {
                                            alert("Already Added");
                                          } else if (curX === col13X && curY === row04X && c13R4VNo === 10) {
                                            alert("Already Added");
                                          } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {
                                            alert("Already Added");
          } else if (curX === col14X && curY === row04X && c14R4VNo === 1) {
                  if (c14r4MT === "S") {  
                              setRow4Naked14(
                                                                      [
                                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
                                                                      ]
                                                                    )
                                                                  
                                                                      setC14R4VNo(3);
                  } else if (c14r4MT === "M") {      
                        setRow3NakedM14(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                          ]
                                        )
                                      
                                          setC14R3VNo(3);
                                    setC14R4VNo(3);
                  } else if (c14r4MT === "L") {  
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(3);
                    setC14R2VNo(3);
                    setC14R3VNo(3);
                    setC14R4VNo(3);
                  }
        } else if (curX === col14X && curY === row04X && c14R4VNo === 2) {
                   if (c14r4MT === "S") {  
                              setRow4Naked14(
                                                                      [
                                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
                                                                      ]
                                                                    )
                                                              
                                                                setC14R4VNo(6);
                  } else if (c14r4MT === "M") {      
                      setRow3NakedM14(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                          ]
                                        )
                                  
                                    setC14R3VNo(6);
                                    setC14R4VNo(6);
                  } else if (c14r4MT === "L") {  
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(6);
                    setC14R2VNo(6);
                    setC14R3VNo(6);
                    setC14R4VNo(6);
                  }                    
        } else if (curX === col14X && curY === row04X && c14R4VNo === 3) {
                                                      alert("Already Added");
                                                    //setC1R4VNo(6);
        } else if (curX === col14X && curY === row04X && c14R4VNo === 4) {
                          if (c14r4MT === "S") {  
                            setRow4Naked14(
                                                                    [
                                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                                                                    ]
                                                                  )
                                                                    setC14R4VNo(5);
                } else if (c14r4MT === "M") {      
                    setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                                        setC14R3VNo(5);
                                        setC14R4VNo(5);
                } else if (c14r4MT === "L") {  
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(5);
                  setC14R2VNo(5);
                  setC14R3VNo(5);
                  setC14R4VNo(5);
                }
        } else if (curX === col14X && curY === row04X && c14R4VNo === 5) {  
                                                    alert("Already Added");
                                                  } else if (curX === col14X && curY === row04X && c14R4VNo === 6) {
                                                    alert("Already Added");
        } else if (curX === col14X && curY === row04X && c14R4VNo === 7) {
              if (c14r4MT === "S") {  
                      setRow4Naked14(
                                                                  [
                                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                                                                  ]
                                                                )
                                                          
                                                                  setC14R4VNo(8);
              } else if (c14r4MT === "M") {      
                  setRow3NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                      ]
                                    )
                              
                                      setC14R3VNo(8);
                                      setC14R4VNo(8);
              } else if (c14r4MT === "L") {  
                setBaseNakedL14(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(8);
                setC14R2VNo(8);
                setC14R3VNo(8);
                setC14R4VNo(8);
              }
        } else if (curX === col14X && curY === row04X && c14R4VNo === 8) {
                                                    alert("Already Added");
                                                  } else if (curX === col14X && curY === row04X && c14R4VNo === 9) {
                                                    alert("Already Added");
                                                  } else if (curX === col14X && curY === row04X && c14R4VNo === 10) {
                                                    alert("Already Added");
                                                  } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
                                                    alert("Already Added");
                                                  } 
                                                

  }

const removeLeftPanel = (e)=> {

    if (curX === col01X && curY === row01X && c1R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
        [
        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
        ]
        )
        setC1R1VNo(1);
          } else if (c1r1MT === "M") {      
          setBaseNakedM01(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
        ]
        )
        setC1R1VNo(1);
        setC1R2VNo(1);
          } else if (c1r1MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(1);
            setC1R2VNo(1);
            setC1R3VNo(1);
            setC1R4VNo(1);
          }

     } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {  
      if (c1r1MT === "S") {  
        setBaseNaked01(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
        ]
      )
      setC1R1VNo(4);
                 } else if (c1r1MT === "M") {      
                 setBaseNaked01(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
        ]
      )
        setC1R1VNo(4);
        setC1R2VNo(4);
                 } else if (c1r1MT === "L") {  
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(4);
                  setC1R2VNo(4);
                  setC1R3VNo(4);
                  setC1R4VNo(4);
                 }
     } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
         ]
       )
       setC1R1VNo(2);
                  } else if (c1r1MT === "M") {      
                         setBaseNakedM01(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
         ]
       )
       setC1R1VNo(2);
        setC1R2VNo(2);
                  } else if (c1r1MT === "L") {  
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(2);
                    setC1R2VNo(2);
                    setC1R3VNo(2);
                    setC1R4VNo(2);
                  }
     } else if (curX === col01X && curY === row01X && c1R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col01X && curY === row01X && c1R1VNo === 8) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
         ]
       )
       setC1R1VNo(7);
                  } else if (c1r1MT === "M") {      
                        setBaseNakedM01(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
         ]
       )
       setC1R1VNo(7);
        setC1R2VNo(7);
                  } else if (c1r1MT === "L") {  
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(7);
                    setC1R2VNo(7);
                    setC1R3VNo(7);
                    setC1R4VNo(7);
                  }
     } else if (curX === col01X && curY === row01X && c1R1VNo === 9) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
         ]
       )
       setC1R1VNo(7);
                  } else if (c1r1MT === "M") {      
                        setBaseNakedM01(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
         ]
       )
       setC1R1VNo(7);
      setC1R2VNo(7);
                  } else if (c1r1MT === "L") {  
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(7);
                    setC1R2VNo(7);
                    setC1R3VNo(7);
                    setC1R4VNo(7);
                  }
     } else if (curX === col01X && curY === row01X && c1R1VNo === 10) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(7);
                   } else if (c1r1MT === "M") {      
                         setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(7);
      setC1R2VNo(7);
                   } else if (c1r1MT === "L") {  
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(7);
                    setC1R2VNo(7);
                    setC1R3VNo(7);
                    setC1R4VNo(7);
                   }
     } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {
      if (c1r1MT === "S") {  
        setBaseNaked01(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
           ]
         )
         setC1R1VNo(7);
                    } else if (c1r1MT === "M") {      
      setBaseNakedM01(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
           ]
         )
         setC1R1VNo(7);
        setC1R2VNo(7);
                    } else if (c1r1MT === "L") {  
                      setBaseNakedL01(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                        ]
                      )
                      setC1R1VNo(7);
                      setC1R2VNo(7);
                      setC1R3VNo(7);
                      setC1R4VNo(7);
                    }
       //2nd col
     }  else if (curX === col02X && curY === row01X && c2R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(1);
                      } else if (c2r1MT === "M") {      
        setBaseNakedM02(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(1);
           setC2R2VNo(1);
                      } else if (c2r1MT === "L") {  
                        setBaseNakedL02(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(1);
                        setC2R2VNo(1);
                        setC2R3VNo(1);
                        setC2R4VNo(1);
                      }

     } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {  
      if (c2r1MT === "S") {  
        setBaseNaked02(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
           ]
         )
         setC2R1VNo(4);
                    } else if (c2r1MT === "M") {      
            setBaseNakedM02(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
           ]
         )
         setC2R1VNo(4);
        setC2R2VNo(4);
                    } else if (c2r1MT === "L") {  
                      setBaseNakedL02(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                      setC2R1VNo(4);
                      setC2R2VNo(4);
                      setC2R3VNo(4);
                      setC2R4VNo(4);
                    }
     } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
                   } else if (c2r1MT === "M") {      
              setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
                   } else if (c2r1MT === "L") {  
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(2);
                    setC2R2VNo(2);
                    setC2R3VNo(2);
                    setC2R4VNo(2);
                   }
     } else if (curX === col02X && curY === row01X && c2R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col02X && curY === row01X && c2R1VNo === 8) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
           ]
         )
         setC2R1VNo(7);
                    } else if (c2r1MT === "M") {      
        setBaseNakedM02(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
           ]
         )
         setC2R1VNo(7);
      setC2R2VNo(7);
                    } else if (c2r1MT === "L") {  
                      setBaseNakedL02(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                      setC2R1VNo(7);
                      setC2R2VNo(7);
                      setC2R3VNo(7);
                      setC2R4VNo(7);
                    }
     } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
           ]
         )
         setC2R1VNo(7);
                    } else if (c2r1MT === "M") {      
       setBaseNakedM02(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
           ]
         )
         setC2R1VNo(7);
      setC2R2VNo(7);
                    } else if (c2r1MT === "L") {  
                      setBaseNakedL02(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                      setC2R1VNo(7);
                      setC2R2VNo(7);
                      setC2R3VNo(7);
                      setC2R4VNo(7);
                    }
     } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
                     } else if (c2r1MT === "M") {      
        setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
         setC2R2VNo(7);
                     } else if (c2r1MT === "L") {  
                      setBaseNakedL02(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                      setC2R1VNo(7);
                      setC2R2VNo(7);
                      setC2R3VNo(7);
                      setC2R4VNo(7);
                     }
     } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
      if (c2r1MT === "S") {  
        setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
                     } else if (c2r1MT === "M") {      
        setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
         setC2R2VNo(7);
                     } else if (c2r1MT === "L") {  
                      setBaseNakedL02(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                      setC2R1VNo(7);
                      setC2R2VNo(7);
                      setC2R3VNo(7);
                      setC2R4VNo(7);
                     }
       //3rd  col
     }  else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(1);
                      } else if (c3r1MT === "M") {      
        setBaseNakedM03(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(1);
          setC3R2VNo(1);
                      } else if (c3r1MT === "L") {  
                        setBaseNakedL03(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                          ]
                        )
                        setC3R1VNo(1);
                        setC3R2VNo(1);
                        setC3R3VNo(1);
                        setC3R4VNo(1);
                      }

     } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {  
      if (c3r1MT === "S") {  
        setBaseNaked03(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
           ]
         )
         setC3R1VNo(4);
                    } else if (c3r1MT === "M") {      
        setBaseNakedM03(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
           ]
         )
         setC3R1VNo(4);
        setC3R2VNo(4);
                    } else if (c3r1MT === "L") {  
                      setBaseNakedL03(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                        ]
                      )
                      setC3R1VNo(4);
                      setC3R2VNo(4);
                      setC3R3VNo(4);
                      setC3R4VNo(4);
                    }
     } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
        ]
      )
      setC3R1VNo(2);
                 } else if (c3r1MT === "M") {      
        setBaseNakedM03(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
        ]
      )
      setC3R1VNo(2);
      setC3R2VNo(2);
                 } else if (c3r1MT === "L") {  
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(2);
                  setC3R2VNo(2);
                  setC3R3VNo(2);
                  setC3R4VNo(2);
                 }
     } else if (curX === col03X && curY === row01X && c3R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col03X && curY === row01X && c3R1VNo === 8) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
         ]
       )
       setC3R1VNo(7);
                  } else if (c3r1MT === "M") {      
        setBaseNakedM03(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
         ]
       )
       setC3R1VNo(7);
      setC3R2VNo(7);
                  } else if (c3r1MT === "L") {  
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(7);
                    setC3R2VNo(7);
                    setC3R3VNo(7);
                    setC3R4VNo(7);
                  }
     } else if (curX === col03X && curY === row01X && c3R1VNo === 9) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(7);
                   } else if (c3r1MT === "M") {      
     setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(7);
      setC3R2VNo(7);
                   } else if (c3r1MT === "L") {  
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(7);
                    setC3R2VNo(7);
                    setC3R3VNo(7);
                    setC3R4VNo(7);
                   }
     } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
           ]
         )
         setC3R1VNo(7);
                    } else if (c3r1MT === "M") {      
      setBaseNakedM03(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
           ]
         )
         setC3R1VNo(7);
        setC3R2VNo(7);
                    } else if (c3r1MT === "L") {  
                      setBaseNakedL03(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                        ]
                      )
                      setC3R1VNo(7);
                      setC3R2VNo(7);
                      setC3R3VNo(7);
                      setC3R4VNo(7);
                    }
     } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
      if (c3r1MT === "S") {  
        setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
                     } else if (c3r1MT === "M") {      
       setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
       setC3R2VNo(7);
                     } else if (c3r1MT === "L") {  
                      setBaseNakedL03(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                        ]
                      )
                      setC3R1VNo(7);
                      setC3R2VNo(7);
                      setC3R3VNo(7);
                      setC3R4VNo(7);
                     }
       //4th col
     }  else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(1);
                      } else if (c4r1MT === "M") {      
        setBaseNakedM04(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(1);
          setC4R2VNo(1);
                      } else if (c4r1MT === "L") {  
                        setBaseNakedL04(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                          ]
                        )
                        setC4R1VNo(1);
                        setC4R2VNo(1);
                        setC4R3VNo(1);
                        setC4R4VNo(1);
                      }

     } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {  
      if (c4r1MT === "S") {  
        setBaseNaked04(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
           ]
         )
         setC4R1VNo(4);
                    } else if (c4r1MT === "M") {      
         setBaseNakedM04(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
           ]
         )
         setC4R1VNo(4);
      setC4R2VNo(4);
                    } else if (c4r1MT === "L") {  
                       setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(4);
            setC4R2VNo(4);
            setC4R3VNo(4);
            setC4R4VNo(4);
                    }
     } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
         ]
       )
       setC4R1VNo(2);
                  } else if (c4r1MT === "M") {      
        setBaseNakedM04(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
         ]
       )
       setC4R1VNo(2);
      setC4R2VNo(2);
                  } else if (c4r1MT === "L") {  
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(2);
                    setC4R2VNo(2);
                    setC4R3VNo(2);
                    setC4R4VNo(2);
                  }
     } else if (curX === col04X && curY === row01X && c4R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col04X && curY === row01X && c4R1VNo === 8) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(7);
                   } else if (c4r1MT === "M") {      
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(7);
        setC4R2VNo(7);
                   } else if (c4r1MT === "L") {  
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(7);
                    setC4R2VNo(7);
                    setC4R3VNo(7);
                    setC4R4VNo(7);
                   }
     } else if (curX === col04X && curY === row01X && c4R1VNo === 9) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
           ]
         )
         setC4R1VNo(7);
                    } else if (c4r1MT === "M") {      
         setBaseNakedM04(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
           ]
         )
         setC4R1VNo(7);
      setC4R2VNo(7);
                    } else if (c4r1MT === "L") {  
                      setBaseNakedL04(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                        ]
                      )
                      setC4R1VNo(7);
                      setC4R2VNo(7);
                      setC4R3VNo(7);
                      setC4R4VNo(7);
                    }
     } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
                     } else if (c4r1MT === "M") {      
          setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
         setC4R2VNo(7);
                     } else if (c4r1MT === "L") {  
                      setBaseNakedL04(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                        ]
                      )
                      setC4R1VNo(7);
                      setC4R2VNo(7);
                      setC4R3VNo(7);
                      setC4R4VNo(7);
                     }
     } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
      if (c4r1MT === "S") {  
        setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
                     } else if (c4r1MT === "M") {      
          setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
         setC4R2VNo(7);
                     } else if (c4r1MT === "L") {  
                      setBaseNakedL04(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                        ]
                      )
                      setC4R1VNo(7);
                      setC4R2VNo(7);
                      setC4R3VNo(7);
                      setC4R4VNo(7);
                     }
       //5th col
     }  else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
      if (c5r1MT === "S") {  
        setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
                     } else if (c5r1MT === "M") {      
         setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
       setC5R2VNo(1);
                     } else if (c5r1MT === "L") {  
                      setBaseNakedL05(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                        ]
                      )
                      setC5R1VNo(1);
                      setC5R2VNo(1);
                      setC5R3VNo(1);
                      setC5R4VNo(1);
                     }

     } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {  
      if (c5r1MT === "S") {  
        setBaseNaked05(
       [
         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
       ]
     )
     setC5R1VNo(4);
                       } else if (c5r1MT === "M") {      
       setBaseNakedM05(
       [
         <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
       ]
     )
     setC5R1VNo(4);
     setC5R2VNo(4);
                       } else if (c5r1MT === "L") {  
                          setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(4);
            setC5R2VNo(4);
            setC5R3VNo(4);
            setC5R4VNo(4);
                       }
     } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {
      if (c5r1MT === "S") {  
        setBaseNaked05(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
        ]
      )
      setC5R1VNo(2);
                        } else if (c5r1MT === "M") {      
          setBaseNakedM05(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
        ]
      )
      setC5R1VNo(2);
   setC5R2VNo(2);
                        } else if (c5r1MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(2);
                          setC5R2VNo(2);
                          setC5R3VNo(2);
                          setC5R4VNo(2);
                        }
     } else if (curX === col05X && curY === row01X && c5R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col05X && curY === row01X && c5R1VNo === 8) {
      if (c5r1MT === "S") {  
        setBaseNaked05(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
         ]
       )
       setC5R1VNo(7);
        } else if (c5r1MT === "M") {      
        setBaseNakedM05(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
         ]
       )
       setC5R1VNo(7);
      setC5R2VNo(7);
                         } else if (c5r1MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(7);
                          setC5R2VNo(7);
                          setC5R3VNo(7);
                          setC5R4VNo(7);
                         }
     } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
      if (c5r1MT === "S") {  
        setBaseNaked05(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
         ]
       )
       setC5R1VNo(7);
        } else if (c5r1MT === "M") {      
       setBaseNakedM05(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
         ]
       )
       setC5R1VNo(7);
       setC5R2VNo(7);
                         } else if (c5r1MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(7);
                          setC5R2VNo(7);
                          setC5R3VNo(7);
                          setC5R4VNo(7);
                         }
     } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
      if (c5r1MT === "S") {  
        setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(7);
       } else if (c5r1MT === "M") {      
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(7);
       setC5R2VNo(7);
                          } else if (c5r1MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                          }
     } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
      if (c5r1MT === "S") {  
        setBaseNaked05(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
           ]
         )
         setC5R1VNo(7);
        } else if (c5r1MT === "M") {      
        setBaseNakedM05(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
           ]
         )
         setC5R1VNo(7);
        setC5R2VNo(7);
                           } else if (c5r1MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                           }
       //6th col
     }  else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
      if (c6r1MT === "S") {  
        setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
         } else if (c6r1MT === "M") {      
         setBaseNakedM06(
               [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
               ]
             )
             setC6R1VNo(1);
         setC6R2VNo(1);
                            } else if (c6r1MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(1);
                              setC6R2VNo(1);
                              setC6R3VNo(1);
                              setC6R4VNo(1);
                            }

     } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {  
      if (c6r1MT === "S") {  
        setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(4);
       } else if (c6r1MT === "M") {      
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(4);
       setC6R2VNo(4);
                          } else if (c6r1MT === "L") {  
                             setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(4);
            setC6R2VNo(4);
            setC6R3VNo(4);
            setC6R4VNo(4);
                          }
     } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {
      if (c6r1MT === "S") {  
        setBaseNaked06(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
        ]
      )
      setC6R1VNo(2);
     } else if (c6r1MT === "M") {      
        setBaseNakedM06(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
        ]
      )
      setC6R1VNo(2);
     setC6R2VNo(2);
                        } else if (c6r1MT === "L") {  
                          setBaseNakedL06(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                            ]
                          )
                          setC6R1VNo(2);
                          setC6R2VNo(2);
                          setC6R3VNo(2);
                          setC6R4VNo(2);
              
                        }
     } else if (curX === col06X && curY === row01X && c6R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col06X && curY === row01X && c6R1VNo === 8) {
      if (c6r1MT === "S") {  
        setBaseNaked06(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
        ]
      )
      setC6R1VNo(7);
     } else if (c6r1MT === "M") {      
        setBaseNakedM06(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
        ]
      )
      setC6R1VNo(7);
     setC6R2VNo(7);
                        } else if (c6r1MT === "L") {  
                          setBaseNakedL06(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                            ]
                          )
                          setC6R1VNo(7);
                          setC6R2VNo(7);
                          setC6R3VNo(7);
                          setC6R4VNo(7);
                        }
     } else if (curX === col06X && curY === row01X && c6R1VNo === 9) {
      if (c6r1MT === "S") {  
        setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(7);
       } else if (c6r1MT === "M") {      
         setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(7);
       setC6R2VNo(7);
                          } else if (c6r1MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(7);
                            setC6R2VNo(7);
                            setC6R3VNo(7);
                            setC6R4VNo(7);
                          }
     } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
      if (c6r1MT === "S") {  
        setBaseNaked06(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
           ]
         )
         setC6R1VNo(7);
        } else if (c6r1MT === "M") {      
         setBaseNakedM06(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
           ]
         )
         setC6R1VNo(7);
        setC6R2VNo(7);
                           } else if (c6r1MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(7);
                            setC6R2VNo(7);
                            setC6R3VNo(7);
                            setC6R4VNo(7);
                           }
     } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
      if (c6r1MT === "S") {  
        setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
         } else if (c6r1MT === "M") {      
          setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
         setC6R2VNo(7);
                            } else if (c6r1MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(7);
                              setC6R2VNo(7);
                              setC6R3VNo(7);
                              setC6R4VNo(7);
                            }
       //7th col
     }  else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
      if (c7r1MT === "S") {  
        setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
         } else if (c7r1MT === "M") {      
         setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
         setC7R2VNo(1);
                            } else if (c7r1MT === "L") {  
                              setBaseNakedL07(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                ]
                              )
                              setC7R1VNo(1);
                              setC7R2VNo(1);
                              setC7R3VNo(1);
                              setC7R4VNo(1);
                            }

     } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {  
      if (c7r1MT === "S") {  
        setBaseNaked07(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(4);
        } else if (c7r1MT === "M") {      
          setBaseNakedM07(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(4);
        setC7R2VNo(4);
                           } else if (c7r1MT === "L") {  
                              setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
            setC7R2VNo(4);
            setC7R3VNo(4);
            setC7R4VNo(4);
                           }
     } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {
      if (c7r1MT === "S") {  
        setBaseNaked07(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
         ]
       )
       setC7R1VNo(2);
      } else if (c7r1MT === "M") {      
         setBaseNakedM07(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
         ]
       )
       setC7R1VNo(2);
      setC7R2VNo(2);
                         } else if (c7r1MT === "L") {  
                          setBaseNakedL07(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                            ]
                          )
                          setC7R1VNo(2);
                          setC7R2VNo(2);
                          setC7R3VNo(2);
                          setC7R4VNo(2);
                         }
     } else if (curX === col07X && curY === row01X && c7R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col07X && curY === row01X && c7R1VNo === 8) {
      if (c7r1MT === "S") {  
        setBaseNaked07(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(7);
        } else if (c7r1MT === "M") {      
         setBaseNakedM07(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(7);
        setC7R2VNo(7);
                           } else if (c7r1MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                           }
     } else if (curX === col07X && curY === row01X && c7R1VNo === 9) {
      if (c7r1MT === "S") {  
        setBaseNaked07(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(7);
        } else if (c7r1MT === "M") {      
       setBaseNakedM07(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(7);
        setC7R2VNo(7);
                           } else if (c7r1MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                           }
     } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
      if (c7r1MT === "S") {  
        setBaseNaked07(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(7);
        } else if (c7r1MT === "M") {      
       setBaseNakedM07(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
           ]
         )
         setC7R1VNo(7);
        setC7R2VNo(7);
                           } else if (c7r1MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                           }
     } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
      if (c7r1MT === "S") {  
        setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
         } else if (c7r1MT === "M") {      
       setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
         setC7R2VNo(7);
                            } else if (c7r1MT === "L") {  
                              setBaseNakedL07(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                ]
                              )
                              setC7R1VNo(7);
                              setC7R2VNo(7);
                              setC7R3VNo(7);
                              setC7R4VNo(7);
                            }
       //8th col
     }  else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
      if (c8r1MT === "S") {  
        setBaseNaked08(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(1);
          } else if (c8r1MT === "M") {      
        setBaseNakedM08(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(1);
          setC8R2VNo(1);
                             } else if (c8r1MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(1);
                              setC8R2VNo(1);
                              setC8R3VNo(1);
                              setC8R4VNo(1);
                             }

     } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {  
      if (c8r1MT === "S") {  
        setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(4);
       } else if (c8r1MT === "M") {      
       setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(4);
       setC8R2VNo(4);
                          } else if (c8r1MT === "L") {  
                             setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
            setC8R2VNo(4);
            setC8R3VNo(4);
            setC8R4VNo(4);
                          }
     } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {
      if (c8r1MT === "S") {  
        setBaseNaked08(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
        ]
      )
      setC8R1VNo(2);
     } else if (c8r1MT === "M") {      
        setBaseNakedM08(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
        ]
      )
      setC8R1VNo(2);
     setC8R2VNo(2);
                        } else if (c8r1MT === "L") {  
                          setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                          setC8R1VNo(2);
                          setC8R2VNo(2);
                          setC8R3VNo(2);
                          setC8R4VNo(2);
                        }
     } else if (curX === col08X && curY === row01X && c8R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col08X && curY === row01X && c8R1VNo === 8) {
      if (c8r1MT === "S") {  
        setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(7);
       } else if (c8r1MT === "M") {      
       setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(7);
       setC8R2VNo(7);
                          } else if (c8r1MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                          }
     } else if (curX === col08X && curY === row01X && c8R1VNo === 9) {
      if (c8r1MT === "S") {  
        setBaseNaked08(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
           ]
         )
         setC8R1VNo(7);
        } else if (c8r1MT === "M") {      
       setBaseNakedM08(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
           ]
         )
         setC8R1VNo(7);
        setC8R2VNo(7);
                           } else if (c8r1MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                           }
     } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
      if (c8r1MT === "S") {  
        setBaseNaked08(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
           ]
         )
         setC1R1VNo(7);
        } else if (c8r1MT === "M") {      
       setBaseNakedM08(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
           ]
         )
         setC8R1VNo(7);
        setC8R2VNo(7);
                           } else if (c8r1MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                           }
     } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
      if (c8r1MT === "S") {  
        setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
         } else if (c8r1MT === "M") {      
        setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
         setC8R2VNo(7);
                            } else if (c8r1MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(7);
                              setC8R2VNo(7);
                              setC8R3VNo(7);
                              setC8R4VNo(7);
                            }
       //9th col
     }  else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
      if (c9r1MT === "S") {  
        setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
         } else if (c9r1MT === "M") {      
        setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
         setC9R2VNo(1);
                            } else if (c9r1MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(1);
                              setC9R2VNo(1);
                              setC9R3VNo(1);
                              setC9R4VNo(1);
                            }

     } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {  
      if (c9r1MT === "S") {  
        setBaseNaked09(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
         ]
       )
       setC9R1VNo(4);
      } else if (c9r1MT === "M") {      
        setBaseNakedM09(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
         ]
       )
       setC9R1VNo(4);
      setC9R2VNo(4);
                         } else if (c9r1MT === "L") {  
                            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(4);
            setC9R2VNo(4);
            setC9R3VNo(4);
            setC9R4VNo(4);
                         }
     } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {
      if (c9r1MT === "S") {  
        setBaseNaked09(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
         ]
       )
       setC9R1VNo(2);
      } else if (c9r1MT === "M") {      
         setBaseNakedM09(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
         ]
       )
       setC9R1VNo(2);
      setC9R2VNo(2);
                         } else if (c9r1MT === "L") {  
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(2);
                          setC9R2VNo(2);
                          setC9R3VNo(2);
                          setC9R4VNo(2);
                         }
     } else if (curX === col09X && curY === row01X && c9R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col09X && curY === row01X && c9R1VNo === 8) {
      if (c9r1MT === "S") {  
        setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(7);
       } else if (c9r1MT === "M") {      
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(7);
       setC9R2VNo(7);
                          } else if (c9r1MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(7);
                            setC9R2VNo(7);
                            setC9R3VNo(7);
                            setC9R4VNo(7);
                          }
     } else if (curX === col09X && curY === row01X && c9R1VNo === 9) {
      if (c9r1MT === "S") {  
        setBaseNaked09(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
           ]
         )
         setC9R1VNo(7);
        } else if (c9r1MT === "M") {      
       setBaseNakedM09(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
           ]
         )
         setC9R1VNo(7);
        setC9R2VNo(7);
                           } else if (c9r1MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(7);
                            setC9R2VNo(7);
                            setC9R3VNo(7);
                            setC9R4VNo(7);
                           }
     } else if (curX === col09X && curY === row01X && c9R1VNo === 10) {
      if (c9r1MT === "S") {  
        setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
         } else if (c9r1MT === "M") {      
        setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
         setC9R2VNo(7);
                            } else if (c9r1MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(7);
                              setC9R2VNo(7);
                              setC9R3VNo(7);
                              setC9R4VNo(7);
                            }
     } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
      if (c9r1MT === "S") {  
        setBaseNaked09(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(7);
          } else if (c9r1MT === "M") {      
         setBaseNakedM09(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(7);
          setC9R2VNo(7);
                             } else if (c9r1MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(7);
                              setC9R2VNo(7);
                              setC9R3VNo(7);
                              setC9R4VNo(7);
                             }
       //10th col
     }  else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
      if (c10r1MT === "S") {  
        setBaseNaked10(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(1);
          } else if (c10r1MT === "M") {      
        setBaseNakedM10(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(1);
          setC10R2VNo(1);
                             } else if (c10r1MT === "L") {  
                              setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                              setC10R1VNo(1);
                              setC10R2VNo(1);
                              setC10R3VNo(1);
                              setC10R4VNo(1);
                             }

     } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {  
      if (c10r1MT === "S") {  
        setBaseNaked10(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
           ]
         )
         setC10R1VNo(4);
        } else if (c10r1MT === "M") {      
        setBaseNakedM10(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
           ]
         )
         setC10R1VNo(4);
        setC10R2VNo(4);
                           } else if (c10r1MT === "L") {  
                              setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(4);
            setC10R2VNo(4);
            setC10R3VNo(4);
            setC10R4VNo(4);
                           }
     } else if (curX === col10X && curY === row01X && c10R1VNo === 6) {
      if (c10r1MT === "S") {  
        setBaseNaked10(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
        ]
      )
      setC10R1VNo(2);
     } else if (c10r1MT === "M") {      
        setBaseNakedM10(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
        ]
      )
      setC10R1VNo(2);
     setC10R2VNo(2);
                        } else if (c10r1MT === "L") {  
                          setBaseNakedL10(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(2);
                          setC10R2VNo(2);
                          setC10R3VNo(2);
                          setC10R4VNo(2);
                        }
     } else if (curX === col10X && curY === row01X && c10R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col10X && curY === row01X && c10R1VNo === 8) {
      if (c10r1MT === "S") {  
        setBaseNaked10(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
         ]
       )
       setC10R1VNo(7);
      } else if (c10r1MT === "M") {      
      setBaseNakedM10(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
         ]
       )
       setC10R1VNo(7);
      setC10R2VNo(7);
                         } else if (c10r1MT === "L") {  
                          setBaseNakedL10(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(7);
                          setC10R2VNo(7);
                          setC10R3VNo(7);
                          setC10R4VNo(7);
                         }
     } else if (curX === col10X && curY === row01X && c10R1VNo === 9) {
      if (c10r1MT === "S") {  
        setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(7);
       } else if (c10r1MT === "M") {      
       setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(7);
       setC10R2VNo(7);
                          } else if (c10r1MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                          }
     } else if (curX === col10X && curY === row01X && c10R1VNo === 10) {
      if (c10r1MT === "S") {  
        setBaseNaked10(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
           ]
         )
         setC10R1VNo(7);
        } else if (c10r1MT === "M") {      
        setBaseNakedM10(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
           ]
         )
         setC10R1VNo(7);
        setC10R2VNo(7);
                           } else if (c10r1MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                           }
     } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
      if (c10r1MT === "S") {  
        setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
         } else if (c10r1MT === "M") {      
        setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
         setC10R2VNo(7);
                            } else if (c10r1MT === "L") {  
                              setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                              setC10R1VNo(7);
                              setC10R2VNo(7);
                              setC10R3VNo(7);
                              setC10R4VNo(7);
                            }
    }  else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
      if (c11r1MT === "S") {  
        setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
         } else if (c11r1MT === "M") {      
        setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
         setC11R2VNo(1);
                            } else if (c11r1MT === "L") {  
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(1);
                              setC11R2VNo(1);
                              setC11R3VNo(1);
                              setC11R4VNo(1);
                            }

     } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {  
      if (c11r1MT === "S") {  
        setBaseNaked11(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
         ]
       )
       setC11R1VNo(4);
      } else if (c11r1MT === "M") {      
         setBaseNakedM11(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
         ]
       )
       setC11R1VNo(4);
      setC11R2VNo(4);
                         } else if (c11r1MT === "L") {  
                            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(4);
            setC11R2VNo(4);
            setC11R3VNo(4);
            setC11R4VNo(4);

                         }
     } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {
      if (c11r1MT === "S") {  
        setBaseNaked11(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
         ]
       )
       setC11R1VNo(2);
      } else if (c11r1MT === "M") {      
         setBaseNakedM11(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
         ]
       )
       setC11R1VNo(2);
      setC11R2VNo(2);
                         } else if (c11r1MT === "L") {  
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(2);
                          setC11R2VNo(2);
                          setC11R3VNo(2);
                          setC11R4VNo(2);
                         }
     } else if (curX === col11X && curY === row01X && c11R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col11X && curY === row01X && c11R1VNo === 8) {
      if (c11r1MT === "S") {  
        setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(7);
       } else if (c11r1MT === "M") {      
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(7);
       setC11R2VNo(7);
                          } else if (c11r1MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(7);
                            setC11R2VNo(7);
                            setC11R3VNo(7);
                            setC11R4VNo(7);
                          }
     } else if (curX === col11X && curY === row01X && c11R1VNo === 9) {
      if (c11r1MT === "S") {  
        setBaseNaked11(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
           ]
         )
         setC11R1VNo(7);
        } else if (c11r1MT === "M") {      
        setBaseNakedM11(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
           ]
         )
         setC11R1VNo(7);
        setC11R2VNo(7);
                           } else if (c11r1MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(7);
                            setC11R2VNo(7);
                            setC11R3VNo(7);
                            setC11R4VNo(7);
                           }
     } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
      if (c11r1MT === "S") {  
        setBaseNaked11(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
           ]
         )
         setC11R1VNo(7);
        } else if (c11r1MT === "M") {      
        setBaseNakedM11(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
           ]
         )
         setC11R1VNo(7);
        setC11R2VNo(7);
                           } else if (c11r1MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(7);
                            setC11R2VNo(7);
                            setC11R3VNo(7);
                            setC11R4VNo(7);
                           }
     } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
      if (c11r1MT === "S") {  
        setBaseNaked11(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
           ]
         )
         setC11R1VNo(7);
        } else if (c11r1MT === "M") {      
       setBaseNakedM11(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
           ]
         )
         setC11R1VNo(7);
        setC11R2VNo(7);
                           } else if (c11r1MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(7);
                            setC11R2VNo(7);
                            setC11R3VNo(7);
                            setC11R4VNo(7);
                           }
       //12th col
     }  else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
      if (c12r1MT === "S") {  
        setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
         } else if (c12r1MT === "M") {      
       setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
         setC12R2VNo(1);
                            } else if (c12r1MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(1);
                              setC12R2VNo(1);
                              setC12R3VNo(1);
                              setC12R4VNo(1);
                            }

     } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {  
      if (c12r1MT === "S") {  
        setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(4);
       } else if (c12r1MT === "M") {      
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(4);
       setC12R2VNo(4);
                          } else if (c12r1MT === "L") {  
                             setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(4);
            setC12R2VNo(4);
            setC12R3VNo(4);
            setC12R4VNo(4);
                          }
     } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {
      if (c12r1MT === "S") {  
        setBaseNaked12(
         [
           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
         ]
       )
       setC12R1VNo(2);
      } else if (c12r1MT === "M") {      
          setBaseNakedM12(
         [
           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
         ]
       )
       setC12R1VNo(2);
      setC12R2VNo(2);
                         } else if (c12r1MT === "L") {  
                          setBaseNakedL12(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(2);
                          setC12R2VNo(2);
                          setC12R3VNo(2);
                          setC12R4VNo(2);
                         }
     } else if (curX === col12X && curY === row01X && c12R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col12X && curY === row01X && c12R1VNo === 8) {
      if (c12r1MT === "S") {  
        setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(7);
       } else if (c12r1MT === "M") {      
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(7);
       setC12R2VNo(7);
                          } else if (c12r1MT === "L") {  
                            setBaseNakedL12(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(7);
                            setC12R2VNo(7);
                            setC12R3VNo(7);
                            setC12R4VNo(7);
                          }
     } else if (curX === col12X && curY === row01X && c12R1VNo === 9) {
      if (c12r1MT === "S") {  
        setBaseNaked12(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
           ]
         )
         setC12R1VNo(7);
        } else if (c12r1MT === "M") {      
        setBaseNakedM12(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
           ]
         )
         setC12R1VNo(7);
        setC12R2VNo(7);
                           } else if (c12r1MT === "L") {  
                            setBaseNakedL12(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(7);
                            setC12R2VNo(7);
                            setC12R3VNo(7);
                            setC12R4VNo(7);
                           }
     } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
      if (c12r1MT === "S") {  
        setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
         } else if (c12r1MT === "M") {      
        setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
         setC12R2VNo(7);
                            } else if (c12r1MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(7);
                              setC12R2VNo(7);
                              setC12R3VNo(7);
                              setC12R4VNo(7);
                            }
     } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
      if (c12r1MT === "S") {  
        setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
         } else if (c12r1MT === "M") {      
       setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
         setC12R2VNo(7);
                            } else if (c12r1MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(7);
                              setC12R2VNo(7);
                              setC12R3VNo(7);
                              setC12R4VNo(7);
                            }
       //13th col
     }  else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
      if (c13r1MT === "S") {  
        setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
         } else if (c13r1MT === "M") {      
       setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
         setC13R2VNo(1);
                            } else if (c13r1MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(1);
                              setC13R2VNo(1);
                              setC13R3VNo(1);
                              setC13R4VNo(1);
                            }

     } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {  
      if (c13r1MT === "S") {  
        setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(4);
       } else if (c13r1MT === "M") {      
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(4);
       setC13R2VNo(4);
                          } else if (c13r1MT === "L") {  
                             setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(4);
            setC13R2VNo(4);
            setC13R3VNo(4);
            setC13R4VNo(4);
                          }
     } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {
      if (c13r1MT === "S") {  
        setBaseNaked13(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
        ]
      )
      setC13R1VNo(2);
     } else if (c13r1MT === "M") {      
         setBaseNakedM13(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
        ]
      )
      setC13R1VNo(2);
     setC13R2VNo(2);
                        } else if (c13r1MT === "L") {  
                          setBaseNakedL13(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                            ]
                          )
                          setC13R1VNo(2);
                          setC13R2VNo(2);
                          setC13R3VNo(2);
                          setC13R4VNo(2);
                        }
     } else if (curX === col13X && curY === row01X && c13R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col13X && curY === row01X && c13R1VNo === 8) {
      if (c13r1MT === "S") {  
        setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(7);
       } else if (c13r1MT === "M") {      
         setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(7);
       setC13R2VNo(7);
                          } else if (c13r1MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(7);
                            setC13R2VNo(7);
                            setC13R3VNo(7);
                            setC13R4VNo(7);
                          }
     } else if (curX === col13X && curY === row01X && c13R1VNo === 9) {
      if (c13r1MT === "S") {  
        setBaseNaked13(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
           ]
         )
         setC13R1VNo(7);
        } else if (c13r1MT === "M") {      
         setBaseNakedM13(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
           ]
         )
         setC13R1VNo(7);
        setC13R2VNo(7);
                           } else if (c13r1MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(7);
                            setC13R2VNo(7);
                            setC13R3VNo(7);
                            setC13R4VNo(7);
                           }
     } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
      if (c13r1MT === "S") {  
        setBaseNaked13(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
           ]
         )
         setC13R1VNo(7);
        } else if (c13r1MT === "M") {      
        setBaseNakedM13(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
           ]
         )
         setC13R1VNo(7);
        setC13R2VNo(7);
                           } else if (c13r1MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(7);
                            setC13R2VNo(7);
                            setC13R3VNo(7);
                            setC13R4VNo(7);
                           }
     } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
      if (c13r1MT === "S") {  
        setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
         } else if (c13r1MT === "M") {      
         setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
         setC13R2VNo(7);
                            } else if (c13r1MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(7);
                              setC13R2VNo(7);
                              setC13R3VNo(7);
                              setC13R4VNo(7);
                            }
       //14th col
     }  else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
      alert("Already removed");
     } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
          alert("Already removed");      
     } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
      if (c14r1MT === "S") {  
        setBaseNaked14(
             [
               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(1);
          } else if (c14r1MT === "M") {      
         setBaseNakedM14(
             [
               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(1);
          setC14R2VNo(1);
                             } else if (c14r1MT === "L") {  
                                setBaseNakedL14(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                          ]
                        )
                        setC14R1VNo(1);
                        setC14R2VNo(1);
                        setC14R3VNo(1);
                        setC14R4VNo(1);
                             }

     } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
      alert("Already removed");
     } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {  
      if (c14r1MT === "S") {  
        setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(4);
       } else if (c14r1MT === "M") {      
         setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(4);
       setC14R2VNo(4);
                          } else if (c14r1MT === "L") {  
                             setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(4);
            setC14R2VNo(4);
            setC14R3VNo(4);
            setC14R4VNo(4);
                          }
     } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {
      if (c14r1MT === "S") {  
        setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
       } else if (c14r1MT === "M") {      
           setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
       setC14R2VNo(2);
                          } else if (c14r1MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(2);
                            setC14R2VNo(2);
                            setC14R3VNo(2);
                            setC14R4VNo(2);
                          }
     } else if (curX === col14X && curY === row01X && c14R1VNo === 7) {
      alert("Already removed");
      
     } else if (curX === col14X && curY === row01X && c14R1VNo === 8) {
      if (c14r1MT === "S") {  
        setBaseNaked14(
           [
             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
           ]
         )
         setC14R1VNo(7);
        } else if (c14r1MT === "M") {      
         setBaseNakedM14(
           [
             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
           ]
         )
         setC14R1VNo(7);
        setC14R2VNo(7);
                           } else if (c14r1MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(7);
                            setC14R2VNo(7);
                            setC14R3VNo(7);
                            setC14R4VNo(7);
                           }
     } else if (curX === col14X && curY === row01X && c14R1VNo === 9) {
      if (c14r1MT === "S") {  
        setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
         } else if (c14r1MT === "M") {      
          setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
         setC14R2VNo(7);
                            } else if (c14r1MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(7);
                              setC14R2VNo(7);
                              setC14R3VNo(7);
                              setC14R4VNo(7);
                            }
     } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
      if (c14r1MT === "S") {  
        setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
         } else if (c14r1MT === "M") {      
         setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
         setC14R2VNo(7);
                            } else if (c14r1MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(7);
                              setC14R2VNo(7);
                              setC14R3VNo(7);
                              setC14R4VNo(7);
                            }
     } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
      if (c14r1MT === "S") {  
        setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
         } else if (c14r1MT === "M") {      
         setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
         setC14R2VNo(7);
                            } else if (c14r1MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(7);
                              setC14R2VNo(7);
                              setC14R3VNo(7);
                              setC14R4VNo(7);
                            }
       
     } else if (curX === col01X && curY === row02X && c1R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
                 ]
               )
               setC1R2VNo(1);
              } else if (c1r1MT === "M" && c1r2MT === "M") { 
                setBaseNakedM01(
                  [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                  ]
                  )
                  setC1R1VNo(1);
                  setC1R2VNo(1);
              } else if (c1r2MT === "M" && c1r3MT === "M") {     
           setRow2NakedM01(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(1);
            setC1R3VNo(1);
                               } else if (c1r2MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(1);
                                setC1R2VNo(1);
                                setC1R3VNo(1);
                                setC1R4VNo(1);
                               }
  
       } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {  
        if (c1r2MT === "S") {  
          setRow2Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(4);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNaked01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
                setC1R1VNo(4);
                setC1R2VNo(4);
            } else if (c1r2MT === "M" && c1r3MT === "M") {      
              setRow2NakedM01(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(4);
             setC1R3VNo(4);
                             } else if (c1r2MT === "L") {  
                              setBaseNakedL01(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                ]
                              )
                              setC1R1VNo(4);
                              setC1R2VNo(4);
                              setC1R3VNo(4);
                              setC1R4VNo(4);
                             }
       } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
             ]
           )
           setC1R2VNo(2);
          } else if (c1r1MT === "M" && c1r2MT === "M") { 
            setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
             setC1R2VNo(2);
          } else if (c1r2MT === "M" && c1r3MT === "M") {      
             setRow2NakedM01(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
           ]
         )
         setC1R2VNo(2);
        setC1R3VNo(2);
                           } else if (c1r2MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(2);
                            setC1R2VNo(2);
                            setC1R3VNo(2);
                            setC1R4VNo(2);
                           }
       } else if (curX === col01X && curY === row02X && c1R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col01X && curY === row02X && c1R2VNo === 8) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(7);
          } else if (c1r1MT === "M" && c1r2MT === "M") { 
            setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(7);
             setC1R2VNo(7);
          } else if (c1r2MT === "M" && c1r3MT === "M") {     
          setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(7);
         setC1R3VNo(7);
                            } else if (c1r2MT === "L") {  
                              setBaseNakedL01(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                ]
                              )
                              setC1R1VNo(7);
                              setC1R2VNo(7);
                              setC1R3VNo(7);
                              setC1R4VNo(7);
                            }
       } else if (curX === col01X && curY === row02X && c1R2VNo === 9) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(7);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(7);
             setC1R2VNo(7);
            } else if (c1r2MT === "M" && c1r3MT === "M") {     
          setRow2NakedM01(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
             ]
           )
           setC1R2VNo(7);
          setC1R3VNo(7);
                             } else if (c1r2MT === "L") {  
                              setBaseNakedL01(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                ]
                              )
                              setC1R1VNo(7);
                              setC1R2VNo(7);
                              setC1R3VNo(7);
                              setC1R4VNo(7);
                             }
       } else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(7);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(7);
             setC1R2VNo(7);
            } else if (c1r2MT === "M" && c1r3MT === "M") {     
           setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(7);
           setC1R3VNo(7);
                              } else if (c1r2MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);
                                setC1R2VNo(7);
                                setC1R3VNo(7);
                                setC1R4VNo(7);
                              }
       } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(7);
            } else if (c1r1MT === "M" && c1r2MT === "M") { 
              setBaseNakedM01(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(7);
             setC1R2VNo(7);
            } else if (c1r2MT === "M" && c1r3MT === "M") {       
           setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(7);
           setC1R3VNo(7);
                              } else if (c1r2MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);
                                setC1R2VNo(7);
                                setC1R3VNo(7);
                                setC1R4VNo(7);
                              }
         //2nd col
       }  else if (curX === col02X && curY === row02X && c2R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(1);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(1);
              setC2R2VNo(1);
            } else if (c2r2MT === "M" && c2r3MT === "M") {     
          setRow2Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(1);
           setC2R3VNo(1);
                              } else if (c2r2MT === "L") {  
                                setBaseNakedL02(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                  ]
                                )
                                setC2R1VNo(1);
                                setC2R2VNo(1);
                                setC2R3VNo(1);
                                setC2R4VNo(1);
                              }
  
       } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {  
        if (c2r2MT === "S") {  
          setRow2Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(4);
          } else if (c2r1MT === "M" && c2r2MT === "M") { 
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(4);
           setC2R2VNo(4);
          } else if (c2r2MT === "M" && c2r3MT === "M") {         
          setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(4);
         setC2R3VNo(4);
                            } else if (c2r2MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(4);
                              setC2R2VNo(4);
                              setC2R3VNo(4);
                              setC2R4VNo(4);
                            }
       } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(2);
          } else if (c2r1MT === "M" && c2r2MT === "M") { 
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(2);
            setC2R2VNo(2);
          } else if (c2r2MT === "M" && c2r3MT === "M") {        
           setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(2);
         setC2R3VNo(2);
                            } else if (c2r2MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(2);
                              setC2R2VNo(2);
                              setC2R3VNo(2);
                              setC2R4VNo(2);
                            }
       } else if (curX === col02X && curY === row02X && c2R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col02X && curY === row02X && c2R2VNo === 8) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(7);
          } else if (c2r1MT === "M" && c2r2MT === "M") { 
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(7);
         setC2R2VNo(7);
          } else if (c2r2MT === "M" && c2r3MT === "M") {       
          setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(7);
         setC2R3VNo(7);
                            } else if (c2r2MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                            }
       } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(7);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(7);
           setC2R2VNo(7);
            } else if (c2r2MT === "M" && c2r3MT === "M") {       
         setRow2NakedM02(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
             ]
           )
           setC2R2VNo(7);
          setC2R3VNo(7);
                             } else if (c2r2MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                             }
       } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(7);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(7);
           setC2R2VNo(7);
            } else if (c2r2MT === "M" && c2r3MT === "M") {       
         setRow2NakedM02(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
             ]
           )
           setC2R2VNo(7);
          setC2R3VNo(7);
                             } else if (c2r2MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                             }
       } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(7);
            } else if (c2r1MT === "M" && c2r2MT === "M") { 
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(7);
           setC2R2VNo(7);
            } else if (c2r2MT === "M" && c2r3MT === "M") {       
        setRow2NakedM02(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
             ]
           )
           setC2R2VNo(7);
          setC2R3VNo(7);
                             } else if (c2r2MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                             }
         //3rd  col
       }  else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
               ]
             )
             setC3R2VNo(1);
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(1);
             setC3R2VNo(1);
            } else if (c3r2MT === "M" && c3r3MT === "M") {       
        setRow2NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
             ]
           )
           setC3R2VNo(1);
          setC3R3VNo(1);
                             } else if (c3r2MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(1);
                              setC3R2VNo(1);
                              setC3R3VNo(1);
                              setC3R4VNo(1);
                             }
  
       } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {  
        if (c3r2MT === "S") {  
          setRow2Naked03(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
             ]
           )
           setC3R2VNo(4);
          } else if (c3r1MT === "M" && c3r2MT === "M") { 
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(4);
           setC3R2VNo(4);
          } else if (c3r2MT === "M" && c3r3MT === "M") {        
         setRow2NakedM03(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
           ]
         )
         setC3R2VNo(4);
        setC3R3VNo(4);
                           } else if (c3r2MT === "L") {  
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(4);
                            setC3R2VNo(4);
                            setC3R3VNo(4);
                            setC3R4VNo(4);
                           }
       } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
           ]
         )
         setC3R2VNo(2);
        } else if (c3r1MT === "M" && c3r2MT === "M") { 
          setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
        } else if (c3r2MT === "M" && c3r3MT === "M") {       
        setRow2NakedM03(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
         ]
       )
       setC3R2VNo(2);
      setC3R3VNo(2);
                         } else if (c3r2MT === "L") {  
                          setBaseNakedL03(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                            ]
                          )
                          setC3R1VNo(2);
                          setC3R2VNo(2);
                          setC3R3VNo(2);
                          setC3R4VNo(2);
                         }
       } else if (curX === col03X && curY === row02X && c3R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col03X && curY === row02X && c3R2VNo === 8) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
             ]
           )
           setC3R2VNo(7);
          } else if (c3r1MT === "M" && c3r2MT === "M") { 
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(7);
           setC3R2VNo(7);
          } else if (c3r2MT === "M" && c3r3MT === "M") {       
         setRow2NakedM03(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
           ]
         )
         setC3R2VNo(7);
        setC3R3VNo(7);
                           } else if (c3r2MT === "L") {  
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(7);
                            setC3R2VNo(7);
                            setC3R3VNo(7);
                            setC3R4VNo(7);
                           }
       } else if (curX === col03X && curY === row02X && c3R2VNo === 9) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
             ]
           )
           setC3R2VNo(7);
          } else if (c3r1MT === "M" && c3r2MT === "M") { 
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(7);
           setC3R2VNo(7);
          } else if (c3r2MT === "M" && c3r3MT === "M") {        
         setRow2NakedM03(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
           ]
         )
         setC3R2VNo(7);
        setC3R3VNo(7);
                           } else if (c3r2MT === "L") {  
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(7);
                            setC3R2VNo(7);
                            setC3R3VNo(7);
                            setC3R4VNo(7);
                           }
       } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
             ]
           )
           setC3R2VNo(7);
          } else if (c3r1MT === "M" && c3r2MT === "M") { 
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(7);
           setC3R2VNo(7);
          } else if (c3r2MT === "M" && c3r3MT === "M") {     
        setRow2NakedM03(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
           ]
         )
         setC3R2VNo(7);
        setC3R3VNo(7);
                           } else if (c3r2MT === "L") {  
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(7);
                            setC3R2VNo(7);
                            setC3R3VNo(7);
                            setC3R4VNo(7);
                           }
       } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(7);
          } else if (c3r1MT === "M" && c3r2MT === "M") { 
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(7);
           setC3R2VNo(7);
          } else if (c3r2MT === "M" && c3r3MT === "M") {        
         setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(7);
         setC3R3VNo(7);
                            } else if (c3r2MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                            }
         //4th col
       }  else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
        if (c4r2MT === "S") {  
          setRow2Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(1);
          } else if (c4r1MT === "M" && c4r2MT === "M") { 
            setBaseNakedM04(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(1);
           setC4R2VNo(1);
          } else if (c4r2MT === "M" && c4r3MT === "M") {    
        setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(1);
         setC4R3VNo(1);
                            } else if (c4r2MT === "L") {  
                              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(1);
                              setC4R2VNo(1);
                              setC4R3VNo(1);
                              setC4R4VNo(1);
                            }
  
       } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {  
        if (c4r2MT === "S") {  
          setRow2Naked04(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
           ]
         )
         setC4R2VNo(4);
        } else if (c4r1MT === "M" && c4r2MT === "M") { 
          setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
       setC4R2VNo(4);
        } else if (c4r2MT === "M" && c4r3MT === "M") {     
       setRow2NakedM04(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
         ]
       )
       setC4R2VNo(4);
      setC4R3VNo(4);
                         } else if (c4r2MT === "L") {  
                          setBaseNakedL04(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                            ]
                          )
                          setC4R1VNo(4);
                          setC4R2VNo(4);
                          setC4R3VNo(4);
                          setC4R4VNo(4);
                         }
       } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {
        if (c4r2MT === "S") {  
          setRow2Naked04(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
           ]
         )
         setC4R2VNo(2);
        } else if (c4r1MT === "M" && c4r2MT === "M") { 
          setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
         setC4R2VNo(2);
        } else if (c4r2MT === "M" && c4r3MT === "M") {      
         setRow2NakedM04(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
         ]
       )
       setC4R2VNo(2);
      setC4R3VNo(2);
                         } else if (c4r2MT === "L") {  
                          setBaseNakedL04(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                            ]
                          )
                          setC4R1VNo(2);
                          setC4R2VNo(2);
                          setC4R3VNo(2);
                          setC4R4VNo(2);
                         }
       } else if (curX === col04X && curY === row02X && c4R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col04X && curY === row02X && c4R2VNo === 8) {
        if (c4r2MT === "S") {  
          setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(7);
        } else if (c4r1MT === "M" && c4r2MT === "M") { 
          setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
        } else if (c4r2MT === "M" && c4r3MT === "M") {    
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(7);
       setC4R3VNo(7);
                          } else if (c4r2MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                          }
       } else if (curX === col04X && curY === row02X && c4R2VNo === 9) {
        if (c4r2MT === "S") {  
          setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(7);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(7);
        setC4R2VNo(7);
} else if (c4r2MT === "M" && c4r3MT === "M") {   
     setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(7);
       setC4R3VNo(7);
                          } else if (c4r2MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                          }
       } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
        if (c4r2MT === "S") {  
          setRow2Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
             ]
           )
           setC4R2VNo(7);
          } else if (c4r1MT === "M" && c4r2MT === "M") { 
            setBaseNakedM04(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(7);
            setC4R2VNo(7);
          } else if (c4r2MT === "M" && c4r3MT === "M") {     
      setRow2NakedM04(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
           ]
         )
         setC4R2VNo(7);
        setC4R3VNo(7);
                           } else if (c4r2MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                           }
       } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
        if (c4r2MT === "S") {  
          setRow2Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
             ]
           )
           setC4R2VNo(7);
          } else if (c4r1MT === "M" && c4r2MT === "M") { 
            setBaseNakedM04(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(7);
            setC4R2VNo(7);
          } else if (c4r2MT === "M" && c4r3MT === "M") {    
      setRow2NakedM04(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
           ]
         )
         setC4R2VNo(7);
        setC4R3VNo(7);
                           } else if (c4r2MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                           }
         //5th col
       }  else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(1);
          } else if (c5r1MT === "M" && c5r2MT === "M") { 
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(1);
         setC5R2VNo(1);
          } else if (c5r2MT === "M" && c5r3MT === "M") {      
       setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(1);
         setC5R3VNo(1);
                            } else if (c5r2MT === "L") {  
                              setBaseNakedL05(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                ]
                              )
                              setC5R1VNo(1);
                              setC5R2VNo(1);
                              setC5R3VNo(1);
                              setC5R4VNo(1);
                            }
  
       } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {  
        if (c5r2MT === "S") {  
          setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(4);
        } else if (c5r1MT === "M" && c5r2MT === "M") { 
          setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
        } else if (c5r2MT === "M" && c5r3MT === "M") {        
         setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(4);
       setC5R3VNo(4);
                          } else if (c5r2MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(4);
                            setC5R2VNo(4);
                            setC5R3VNo(4);
                            setC5R4VNo(4);
                          }
       } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
           ]
         )
         setC5R2VNo(2);
        } else if (c5r1MT === "M" && c5r2MT === "M") { 
          setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
       setC5R2VNo(2);
        } else if (c5r2MT === "M" && c5r3MT === "M") {       
        setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(2);
      setC5R3VNo(2);
                         } else if (c5r2MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(2);
                          setC5R2VNo(2);
                          setC5R3VNo(2);
                          setC5R4VNo(2);
                         }
       } else if (curX === col05X && curY === row02X && c5R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col05X && curY === row02X && c5R2VNo === 8) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
           ]
         )
         setC5R2VNo(7);
        } else if (c5r1MT === "M" && c5r2MT === "M") { 
          setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
         setC5R2VNo(7);
        } else if (c5r2MT === "M" && c5r3MT === "M") {       
       setRow2NakedM05(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
         ]
       )
       setC5R2VNo(7);
      setC5R3VNo(7);
                         } else if (c5r2MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(7);
                          setC5R2VNo(7);
                          setC5R3VNo(7);
                          setC5R4VNo(7);
                         }
       } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(7);
        } else if (c5r1MT === "M" && c5r2MT === "M") { 
          setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
         setC5R2VNo(7);
        } else if (c5r2MT === "M" && c5r3MT === "M") {      
       setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(7);
       setC5R3VNo(7);
                          } else if (c5r2MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                          }
       } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
             ]
           )
           setC5R2VNo(7);
          } else if (c5r1MT === "M" && c5r2MT === "M") { 
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(7);
           setC5R2VNo(7);
          } else if (c5r2MT === "M" && c5r3MT === "M") {      
       setRow2NakedM05(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
           ]
         )
         setC5R2VNo(7);
        setC5R3VNo(7);
                           } else if (c5r2MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                           }
       } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
        if (c5r2MT === "S") {  
          setRow2Naked05(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
             ]
           )
           setC5R2VNo(7);
          } else if (c5r1MT === "M" && c5r2MT === "M") { 
            setBaseNakedM05(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(7);
           setC5R2VNo(7);
          } else if (c5r2MT === "M" && c5r3MT === "M") {       
      setRow2NakedM05(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
           ]
         )
         setC5R2VNo(7);
        setC5R3VNo(7);
                           } else if (c5r2MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                           }
         //6th col
       }  else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
        if (c6r2MT === "S") {  
          setRow2Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(1);
          } else if (c6r1MT === "M" && c6r2MT === "M") { 
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(1);
          setC6R2VNo(1);
          } else if (c6r2MT === "M" && c6r3MT === "M") {        
       setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(1);
         setC6R3VNo(1);
                            } else if (c6r2MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(1);
                              setC6R2VNo(1);
                              setC6R3VNo(1);
                              setC6R4VNo(1);
                            }
  
       } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {  
        if (c6r2MT === "S") {  
          setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(4);
        } else if (c6r1MT === "M" && c6r2MT === "M") { 
          setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
         setC6R2VNo(4);
        } else if (c6r2MT === "M" && c6r3MT === "M") {       
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(4);
       setC6R3VNo(4);
                          } else if (c6r2MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(4);
                            setC6R2VNo(4);
                            setC6R3VNo(4);
                            setC6R4VNo(4);
                          }
       } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {
        if (c6r2MT === "S") {  
          setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(2);
      } else if (c6r1MT === "M" && c6r2MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
       setC6R2VNo(2);
      } else if (c6r2MT === "M" && c6r3MT === "M") {        
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(2);
       setC6R3VNo(2);
                          } else if (c6r2MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(2);
                            setC6R2VNo(2);
                            setC6R3VNo(2);
                            setC6R4VNo(2);
                
                          }
       } else if (curX === col06X && curY === row02X && c6R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col06X && curY === row02X && c6R2VNo === 8) {
        if (c6r2MT === "S") {  
          setRow2Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(7);
          } else if (c6r1MT === "M" && c6r2MT === "M") { 
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(7);
           setC6R2VNo(7);
          } else if (c6r2MT === "M" && c6r3MT === "M") {      
         setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(7);
         setC6R3VNo(7);
                            } else if (c6r2MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(7);
                              setC6R2VNo(7);
                              setC6R3VNo(7);
                              setC6R4VNo(7);
                            }
       } else if (curX === col06X && curY === row02X && c6R2VNo === 9) {
        if (c6r2MT === "S") {  
          setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(7);
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(7);
             setC6R2VNo(7);
            } else if (c6r2MT === "M" && c6r3MT === "M") {      
         setRow2NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
             ]
           )
           setC6R2VNo(7);
          setC6R3VNo(7);
                             } else if (c6r2MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(7);
                              setC6R2VNo(7);
                              setC6R3VNo(7);
                              setC6R4VNo(7);
                             }
       } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
        if (c6r2MT === "S") {  
          setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(7);
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(7);
             setC6R2VNo(7);
            } else if (c6r2MT === "M" && c6r3MT === "M") {    
        setRow2NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
             ]
           )
           setC6R2VNo(7);
          setC6R3VNo(7);
                             } else if (c6r2MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(7);
                              setC6R2VNo(7);
                              setC6R3VNo(7);
                              setC6R4VNo(7);
                             }
        
       } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
        if (c6r2MT === "S") {  
          setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
               ]
             )
             setC6R2VNo(7);
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(7);
             setC6R2VNo(7);
            } else if (c6r2MT === "M" && c6r3MT === "M") {       
        setRow2NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
             ]
           )
           setC6R2VNo(7);
          setC6R3VNo(7);
                             } else if (c6r2MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(7);
                              setC6R2VNo(7);
                              setC6R3VNo(7);
                              setC6R4VNo(7);
                             }
         //7th col
       }  else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(1);
            } else if (c7r1MT === "M" && c7r2MT === "M") { 
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(1);
             setC7R2VNo(1);
            } else if (c7r2MT === "M" && c7r3MT === "M") {         
         setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(1);
           setC7R3VNo(1);
                              } else if (c7r2MT === "L") {  
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(1);
                                setC7R2VNo(1);
                                setC7R3VNo(1);
                                setC7R4VNo(1);
                              }
         
  
       } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {  
        if (c7r2MT === "S") {  
          setRow2Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
             ]
           )
           setC7R2VNo(4);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
           setC7R2VNo(4);
          } else if (c7r2MT === "M" && c7r3MT === "M") {       
          setRow2NakedM07(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
           ]
         )
         setC7R2VNo(4);
        setC7R3VNo(4);
                           } else if (c7r2MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(4);
                            setC7R2VNo(4);
                            setC7R3VNo(4);
                            setC7R4VNo(4);
                           }
       } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(2);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
       setC7R2VNo(2);
      } else if (c7r2MT === "M" && c7r3MT === "M") {       
       setRow2NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
        ]
      )
      setC7R2VNo(2);
     setC7R3VNo(2);
                        } else if (c7r2MT === "L") {  
                          setBaseNakedL07(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                            ]
                          )
                          setC7R1VNo(2);
                          setC7R2VNo(2);
                          setC7R3VNo(2);
                          setC7R4VNo(2);
                        }
       } else if (curX === col07X && curY === row02X && c7R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col07X && curY === row02X && c7R2VNo === 8) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
             ]
           )
           setC7R2VNo(7);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
           setC7R2VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M") {        
         setRow2NakedM07(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
           ]
         )
         setC7R2VNo(7);
        setC7R3VNo(7);
                           } else if (c7r2MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                           }
      
       } else if (curX === col07X && curY === row02X && c7R2VNo === 9) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
             ]
           )
           setC7R2VNo(7);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
           setC7R2VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M") {        
        setRow2NakedM07(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
           ]
         )
         setC7R2VNo(7);
        setC7R3VNo(7);
                           } else if (c7r2MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                           }
      
      
       } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
             ]
           )
           setC7R2VNo(7);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
           setC7R2VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M") {        
       setRow2NakedM07(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
           ]
         )
         setC7R2VNo(7);
        setC7R3VNo(7);
                           } else if (c7r2MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                           }
       } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
        if (c7r2MT === "S") {  
          setRow2Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(7);
          } else if (c7r1MT === "M" && c7r2MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
           setC7R2VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M") {         
        setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(7);
         setC7R3VNo(7);
                            } else if (c7r2MT === "L") {  
                              setBaseNakedL07(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                ]
                              )
                              setC7R1VNo(7);
                              setC7R2VNo(7);
                              setC7R3VNo(7);
                              setC7R4VNo(7);
                            }
       
         //8th col
       }  else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
  
        if (c8r2MT === "S") {  
          setRow2Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(1);
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(1);
             setC8R2VNo(1);
            } else if (c8r2MT === "M" && c8r3MT === "M") {      
         setRow2NakedM08(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
             ]
           )
           setC8R2VNo(1);
          setC8R3VNo(1);
                             } else if (c8r2MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(1);
                              setC8R2VNo(1);
                              setC8R3VNo(1);
                              setC8R4VNo(1);
                             }
       } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {  
        if (c8r2MT === "S") {  
          setRow2Naked08(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
             ]
           )
           setC8R2VNo(4);
          } else if (c8r1MT === "M" && c8r2MT === "M") { 
            setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
           setC8R2VNo(4);
          } else if (c8r2MT === "M" && c8r3MT === "M") {        
           setRow2NakedM08(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
           ]
         )
         setC8R2VNo(4);
        setC8R3VNo(4);
                           } else if (c8r2MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(4);
                            setC8R2VNo(4);
                            setC8R3VNo(4);
                            setC8R4VNo(4);
                           }
       } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(2);
        } else if (c8r1MT === "M" && c8r2MT === "M") { 
          setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
         setC8R2VNo(2);
        } else if (c8r2MT === "M" && c8r3MT === "M") {        
            setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(2);
       setC8R3VNo(2);
                          } else if (c8r2MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(2);
                            setC8R2VNo(2);
                            setC8R3VNo(2);
                            setC8R4VNo(2);
                          }
       } else if (curX === col08X && curY === row02X && c8R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col08X && curY === row02X && c8R2VNo === 8) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
             ]
           )
           setC8R2VNo(7);
          } else if (c8r1MT === "M" && c8r2MT === "M") { 
            setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(7);
           setC8R2VNo(7);
          } else if (c8r2MT === "M" && c8r3MT === "M") {       
          setRow2NakedM08(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
           ]
         )
         setC8R2VNo(7);
        setC8R3VNo(7);
                           } else if (c8r2MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                           }
       } else if (curX === col08X && curY === row02X && c8R2VNo === 9) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
             ]
           )
           setC8R2VNo(7);
          } else if (c8r1MT === "M" && c8r2MT === "M") { 
            setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(7);
           setC8R2VNo(7);
          } else if (c8r2MT === "M" && c8r3MT === "M") {       
         setRow2NakedM08(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
           ]
         )
         setC8R2VNo(7);
        setC8R3VNo(7);
                           } else if (c8r2MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                           }
      
       } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(7);
        } else if (c8r1MT === "M" && c8r2MT === "M") { 
          setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
         setC8R2VNo(7);
        } else if (c8r2MT === "M" && c8r3MT === "M") {         
       setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(7);
       setC8R3VNo(7);
                          } else if (c8r2MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                          }
     
       } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
        if (c8r2MT === "S") {  
          setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(7);
        } else if (c8r1MT === "M" && c8r2MT === "M") { 
          setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
         setC8R2VNo(7);
        } else if (c8r2MT === "M" && c8r3MT === "M") {         
     setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(7);
       setC8R3VNo(7);
                          } else if (c8r2MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                          }
         //9th col
       }  else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(1);
        } else if (c9r1MT === "M" && c9r2MT === "M") { 
          setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
         setC9R2VNo(1);
        } else if (c9r2MT === "M" && c9r3MT === "M") {       
     setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(1);
       setC9R3VNo(1);
                          } else if (c9r2MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(1);
                            setC9R2VNo(1);
                            setC9R3VNo(1);
                            setC9R4VNo(1);
                          }
  
       } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {  
        if (c9r2MT === "S") {  
          setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(4);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(4);
       setC9R2VNo(4);
      } else if (c9r2MT === "M" && c9r3MT === "M") {       
       setRow2NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
        ]
      )
      setC9R2VNo(4);
     setC9R3VNo(4);
                        } else if (c9r2MT === "L") {  
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(4);
                          setC9R2VNo(4);
                          setC9R3VNo(4);
                          setC9R4VNo(4);
                        }
       } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
           ]
         )
         setC9R2VNo(2);
        } else if (c9r1MT === "M" && c9r2MT === "M") { 
          setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
         setC9R2VNo(2);
        } else if (c9r2MT === "M" && c9r3MT === "M") {      
          setRow2NakedM09(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
         ]
       )
       setC9R2VNo(2);
      setC9R3VNo(2);
                         } else if (c9r2MT === "L") {  
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(2);
                          setC9R2VNo(2);
                          setC9R3VNo(2);
                          setC9R4VNo(2);
                         }
       } else if (curX === col09X && curY === row02X && c9R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col09X && curY === row02X && c9R2VNo === 8) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(7);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(7);
       setC9R2VNo(7);
      } else if (c9r2MT === "M" && c9r3MT === "M") {        
     setRow2NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
        ]
      )
      setC9R2VNo(7);
     setC9R3VNo(7);
                        } else if (c9r2MT === "L") {  
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(7);
                          setC9R2VNo(7);
                          setC9R3VNo(7);
                          setC9R4VNo(7);
                        }
       } else if (curX === col09X && curY === row02X && c9R2VNo === 9) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
           ]
         )
         setC9R2VNo(7);
        } else if (c9r1MT === "M" && c9r2MT === "M") { 
          setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
         setC9R2VNo(7);
        } else if (c9r2MT === "M" && c9r3MT === "M") {        
     setRow2NakedM09(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
         ]
       )
       setC9R2VNo(7);
      setC9R3VNo(7);
                         } else if (c9r2MT === "L") {  
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(7);
                          setC9R2VNo(7);
                          setC9R3VNo(7);
                          setC9R4VNo(7);
                         }
       } else if (curX === col09X && curY === row02X && c9R2VNo === 10) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
           ]
         )
         setC9R2VNo(7);
        } else if (c9r1MT === "M" && c9r2MT === "M") { 
          setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
         setC9R2VNo(7);
        } else if (c9r2MT === "M" && c9r3MT === "M") {       
    setRow2NakedM09(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
         ]
       )
       setC9R2VNo(7);
      setC9R3VNo(7);
                         } else if (c9r2MT === "L") {  
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(7);
                          setC9R2VNo(7);
                          setC9R3VNo(7);
                          setC9R4VNo(7);
                         }
    
       } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(7);
        } else if (c9r1MT === "M" && c9r2MT === "M") { 
          setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
         setC9R2VNo(7);
        } else if (c9r2MT === "M" && c9r3MT === "M") {       
     setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(7);
       setC9R3VNo(7);
                          } else if (c9r2MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(7);
                            setC9R2VNo(7);
                            setC9R3VNo(7);
                            setC9R4VNo(7);
                          }
         //10th col
       } else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(1);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(1);
           setC10R2VNo(1);
          } else if (c10r2MT === "M" && c10r3MT === "M") {     
      setRow2NakedM10(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
           ]
         )
         setC10R2VNo(1);
        setC10R3VNo(1);
                           } else if (c10r2MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(1);
                            setC10R2VNo(1);
                            setC10R3VNo(1);
                            setC10R4VNo(1);
                           }
  
       } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {  
        if (c10r2MT === "S") {  
          setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(4);
        } else if (c10r1MT === "M" && c10r2MT === "M") { 
          setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
         setC10R2VNo(4);
        } else if (c10r2MT === "M" && c10r3MT === "M") {   
       setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(4);
       setC10R3VNo(4);
                          } else if (c10r2MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(4);
                            setC10R2VNo(4);
                            setC10R3VNo(4);
                            setC10R4VNo(4);
                          }
     
       } else if (curX === col10X && curY === row02X && c10R2VNo === 6) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
           ]
         )
         setC10R2VNo(2);
        } else if (c10r1MT === "M" && c10r2MT === "M") { 
          setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
         setC10R2VNo(2);
        } else if (c10r2MT === "M" && c10r3MT === "M") {      
        setRow2NakedM10(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
         ]
       )
       setC10R2VNo(2);
      setC10R3VNo(2);
                         } else if (c10r2MT === "L") {  
                          setBaseNakedL10(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(2);
                          setC10R2VNo(2);
                          setC10R3VNo(2);
                          setC10R4VNo(2);
                         }
    
    
       } else if (curX === col10X && curY === row02X && c10R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col10X && curY === row02X && c10R2VNo === 8) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(7);
        } else if (c10r1MT === "M" && c10r2MT === "M") { 
          setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
         setC10R2VNo(7);
        } else if (c10r2MT === "M" && c10r3MT === "M") {      
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(7);
       setC10R3VNo(7);
                          } else if (c10r2MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                          }
     
       } else if (curX === col10X && curY === row02X && c10R2VNo === 9) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(7);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(7);
           setC10R2VNo(7);
          } else if (c10r2MT === "M" && c10r3MT === "M") {      
        setRow2NakedM10(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
           ]
         )
         setC10R2VNo(7);
        setC10R3VNo(7);
                           } else if (c10r2MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                           }
      
       } else if (curX === col10X && curY === row02X && c10R2VNo === 10) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(7);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(7);
           setC10R2VNo(7);
          } else if (c10r2MT === "M" && c10r3MT === "M") {     
       setRow2NakedM10(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
           ]
         )
         setC10R2VNo(7);
        setC10R3VNo(7);
                           } else if (c10r2MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                           }
       } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
        if (c10r2MT === "S") {  
          setRow2Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(7);
          } else if (c10r1MT === "M" && c10r2MT === "M") { 
            setBaseNakedM10(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(7);
           setC10R2VNo(7);
          } else if (c10r2MT === "M" && c10r3MT === "M") {    
       setRow2NakedM10(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
           ]
         )
         setC10R2VNo(7);
        setC10R3VNo(7);
                           } else if (c10r2MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                           }
      
      }  else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
             ]
           )
           setC11R2VNo(1);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(1);
           setC11R2VNo(1);
          } else if (c11r2MT === "M" && c11r3MT === "M") {       
      setRow2NakedM11(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
           ]
         )
         setC11R2VNo(1);
        setC11R3VNo(1);
                           } else if (c11r2MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(1);
                            setC11R2VNo(1);
                            setC11R3VNo(1);
                            setC11R4VNo(1);
                           }
  
       } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {  
        if (c11r2MT === "S") {  
          setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(4);
        } else if (c11r1MT === "M" && c11r2MT === "M") { 
          setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
         setC11R2VNo(4);
        } else if (c11r2MT === "M" && c11r3MT === "M") {          
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(4);
       setC11R3VNo(4);
                          } else if (c11r2MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(4);
                            setC11R2VNo(4);
                            setC11R3VNo(4);
                            setC11R4VNo(4);
                          }
       } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
           ]
         )
         setC11R2VNo(2);
        } else if (c11r1MT === "M" && c11r2MT === "M") { 
          setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
         setC11R2VNo(2);
        } else if (c11r2MT === "M" && c11r3MT === "M") {         
        setRow2NakedM11(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
         ]
       )
       setC11R2VNo(2);
      setC11R3VNo(2);
                         } else if (c11r2MT === "L") {  
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(2);
                          setC11R2VNo(2);
                          setC11R3VNo(2);
                          setC11R4VNo(2);
                         }
       } else if (curX === col11X && curY === row02X && c11R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col11X && curY === row02X && c11R2VNo === 8) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(7);
        } else if (c11r1MT === "M" && c11r2MT === "M") { 
          setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
         setC11R2VNo(7);
        } else if (c11r2MT === "M" && c11r3MT === "M") {         
       setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(7);
       setC11R3VNo(7);
                          } else if (c11r2MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(7);
                            setC11R2VNo(7);
                            setC11R3VNo(7);
                            setC11R4VNo(7);
                          }
     
       } else if (curX === col11X && curY === row02X && c11R2VNo === 9) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
             ]
           )
           setC11R2VNo(7);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(7);
           setC11R2VNo(7);
          } else if (c11r2MT === "M" && c11r3MT === "M") {       
       setRow2NakedM11(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
           ]
         )
         setC11R2VNo(7);
        setC11R3VNo(7);
                           } else if (c11r2MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(7);
                            setC11R2VNo(7);
                            setC11R3VNo(7);
                            setC11R4VNo(7);
                           }
      
       } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(7);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(7);
           setC11R2VNo(7);
          } else if (c11r2MT === "M" && c11r3MT === "M") {          
        setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(7);
         setC11R3VNo(7);
                            } else if (c11r2MT === "L") {  
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(7);
                              setC11R2VNo(7);
                              setC11R3VNo(7);
                              setC11R4VNo(7);
                            }
       } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
        if (c11r2MT === "S") {  
          setRow2Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(7);
          } else if (c11r1MT === "M" && c11r2MT === "M") { 
            setBaseNakedM11(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(7);
           setC11R2VNo(7);
          } else if (c11r2MT === "M" && c11r3MT === "M") {         
       setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(7);
         setC11R3VNo(7);
                            } else if (c11r2MT === "L") {  
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(7);
                              setC11R2VNo(7);
                              setC11R3VNo(7);
                              setC11R4VNo(7);
                            }
         //12th col
       }  else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(1);
          } else if (c12r1MT === "M" && c12r2MT === "M") { 
            setBaseNakedM12(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(1);
           setC12R2VNo(1);
          } else if (c12r2MT === "M" && c12r3MT === "M") {         
       setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(1);
         setC12R3VNo(1);
                            } else if (c12r2MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(1);
                              setC12R2VNo(1);
                              setC12R3VNo(1);
                              setC12R4VNo(1);
                            }
  
       } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {  
        if (c12r2MT === "S") {  
          setRow2Naked12(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
           ]
         )
         setC12R2VNo(4);
        } else if (c12r1MT === "M" && c12r2MT === "M") { 
          setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
         setC12R2VNo(4);
        } else if (c12r2MT === "M" && c12r3MT === "M") {      
       setRow2NakedM12(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
         ]
       )
       setC12R2VNo(4);
      setC12R3VNo(4);
                         } else if (c12r2MT === "L") {  
                          setBaseNakedL12(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(4);
                          setC12R2VNo(4);
                          setC12R3VNo(4);
                          setC12R4VNo(4);
                         }
    
       } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(2);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
       setC12R2VNo(2);
      } else if (c12r2MT === "M" && c12r3MT === "M") {       
      setRow2NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
        ]
      )
      setC12R2VNo(2);
     setC12R3VNo(2);
                        } else if (c12r2MT === "L") {  
                          setBaseNakedL12(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(2);
                          setC12R2VNo(2);
                          setC12R3VNo(2);
                          setC12R4VNo(2);
                        }
       } else if (curX === col12X && curY === row02X && c12R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col12X && curY === row02X && c12R2VNo === 8) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
           ]
         )
         setC12R2VNo(7);
        } else if (c12r1MT === "M" && c12r2MT === "M") { 
          setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
         setC12R2VNo(7);
        } else if (c12r2MT === "M" && c12r3MT === "M") {         
     setRow2NakedM12(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
         ]
       )
       setC12R2VNo(7);
      setC12R3VNo(7);
                         } else if (c12r2MT === "L") {  
                          setBaseNakedL12(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(7);
                          setC12R2VNo(7);
                          setC12R3VNo(7);
                          setC12R4VNo(7);
                         }
       } else if (curX === col12X && curY === row02X && c12R2VNo === 9) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(7);
        } else if (c12r1MT === "M" && c12r2MT === "M") { 
          setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
         setC12R2VNo(7);
        } else if (c12r2MT === "M" && c12r3MT === "M") {         
     setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(7);
       setC12R3VNo(7);
                          } else if (c12r2MT === "L") {  
                            setBaseNakedL12(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(7);
                            setC12R2VNo(7);
                            setC12R3VNo(7);
                            setC12R4VNo(7);
                          }
     
       } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
             ]
           )
           setC12R2VNo(7);
          } else if (c12r1MT === "M" && c12r2MT === "M") { 
            setBaseNakedM12(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(7);
           setC12R2VNo(7);
          } else if (c12r2MT === "M" && c12r3MT === "M") {       
      setRow2NakedM12(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
           ]
         )
         setC12R2VNo(7);
        setC12R3VNo(7);
                           } else if (c12r2MT === "L") {  
                            setBaseNakedL12(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(7);
                            setC12R2VNo(7);
                            setC12R3VNo(7);
                            setC12R4VNo(7);
                           }
      
      
       } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(7);
          } else if (c12r1MT === "M" && c12r2MT === "M") { 
            setBaseNakedM12(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(7);
           setC12R2VNo(7);
          } else if (c12r2MT === "M" && c12r3MT === "M") {       
       setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(7);
         setC12R3VNo(7);
                            } else if (c12r2MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(7);
                              setC12R2VNo(7);
                              setC12R3VNo(7);
                              setC12R4VNo(7);
                            }
       
         //13th col
       }  else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
               ]
             )
             setC13R1VNo(1);;
            } else if (c13r1MT === "M" && c13r2MT === "M") { 
              setBaseNakedM13(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(1);
             setC13R2VNo(1);
            } else if (c13r2MT === "M" && c13r3MT === "M") {      
        setRow2NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R2VNo(1);
          setC13R3VNo(1);
                             } else if (c13r2MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(1);
                              setC13R2VNo(1);
                              setC13R3VNo(1);
                              setC13R4VNo(1);
                             }
  
       } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {  
        if (c13r2MT === "S") {  
          setRow2Naked13(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R1VNo(4);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(4);
           setC13R2VNo(4);
          } else if (c13r2MT === "M" && c13r3MT === "M") {     
          setRow2NakedM13(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
           ]
         )
         setC13R2VNo(4);
        setC13R3VNo(4);
                           } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(4);
                            setC13R2VNo(4);
                            setC13R3VNo(4);
                            setC13R4VNo(4);
                           }
       } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R1VNo(2);
        } else if (c13r1MT === "M" && c13r2MT === "M") { 
          setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
         setC13R2VNo(2);
        } else if (c13r2MT === "M" && c13r3MT === "M") {       
           setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(2);
       setC13R3VNo(2);
                          } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(2);
                            setC13R2VNo(2);
                            setC13R3VNo(2);
                            setC13R4VNo(2);
                          }
     
     
       } else if (curX === col13X && curY === row02X && c13R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col13X && curY === row02X && c13R2VNo === 8) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R1VNo(7);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(7);
           setC13R2VNo(7);
          } else if (c13r2MT === "M" && c13r3MT === "M") {       
        setRow2NakedM13(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
           ]
         )
         setC13R2VNo(7);
        setC13R3VNo(7);
                           } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(7);
                            setC13R2VNo(7);
                            setC13R3VNo(7);
                            setC13R4VNo(7);
                           }
      
      
       } else if (curX === col13X && curY === row02X && c13R2VNo === 9) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R1VNo(7);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(7);
           setC13R2VNo(7);
          } else if (c13r2MT === "M" && c13r3MT === "M") {      
       setRow2NakedM13(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
           ]
         )
         setC13R2VNo(7);
        setC13R3VNo(7);
                           } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(7);
                            setC13R2VNo(7);
                            setC13R3VNo(7);
                            setC13R4VNo(7);
                           }
       } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R1VNo(7);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(7);
           setC13R2VNo(7);
          } else if (c13r2MT === "M" && c13r3MT === "M") {        
        setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(7);
         setC13R3VNo(7);
                            } else if (c13r2MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(7);
                              setC13R2VNo(7);
                              setC13R3VNo(7);
                              setC13R4VNo(7);
                            }
       
       } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
        if (c13r2MT === "S") {  
          setRow2Naked13(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
             ]
           )
           setC13R1VNo(7);
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(7);
           setC13R2VNo(7);
          } else if (c13r2MT === "M" && c13r3MT === "M") {      
      setRow2NakedM13(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
           ]
         )
         setC13R2VNo(7);
        setC13R3VNo(7);
                           } else if (c13r2MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(7);
                            setC13R2VNo(7);
                            setC13R3VNo(7);
                            setC13R4VNo(7);
                           }
         //14th col
       }  else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
        alert("Already removed");
       } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
            alert("Already removed");      
       } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(1);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(1);
           setC14R2VNo(1);
          } else if (c14r2MT === "M" && c14r3MT === "M") {      
       setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(1);
         setC14R3VNo(1);
                            } else if (c14r2MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(1);
                              setC14R2VNo(1);
                              setC14R3VNo(1);
                              setC14R4VNo(1);
                            }
       
  
       } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
        alert("Already removed");
       } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {  
        if (c14r2MT === "S") {  
          setRow2Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
             ]
           )
           setC14R2VNo(4);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(4);
           setC14R2VNo(4);
          } else if (c14r2MT === "M" && c14r3MT === "M") {       
        setRow2NakedM14(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
           ]
         )
         setC14R2VNo(4);
        setC14R3VNo(4);
                           } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(4);
                            setC14R2VNo(4);
                            setC14R3VNo(4);
                            setC14R4VNo(4);
                           }
       } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(2);
        } else if (c14r1MT === "M" && c14r2MT === "M") { 
          setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
         setC14R2VNo(2);
        } else if (c14r2MT === "M" && c14r3MT === "M") {      
         setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(2);
       setC14R3VNo(2);
                          } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(2);
                            setC14R2VNo(2);
                            setC14R3VNo(2);
                            setC14R4VNo(2);
                          }
       } else if (curX === col14X && curY === row02X && c14R2VNo === 7) {
        alert("Already removed");
        
       } else if (curX === col14X && curY === row02X && c14R2VNo === 8) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(7);
        } else if (c14r1MT === "M" && c14r2MT === "M") { 
          setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
         setC14R2VNo(7);
        } else if (c14r2MT === "M" && c14r3MT === "M") {      
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(7);
       setC14R3VNo(7);
                          } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(7);
                            setC14R2VNo(7);
                            setC14R3VNo(7);
                            setC14R4VNo(7);
                          }
     
       } else if (curX === col14X && curY === row02X && c14R2VNo === 9) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
             ]
           )
           setC14R2VNo(7);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(7);
           setC14R2VNo(7);
          } else if (c14r2MT === "M" && c14r3MT === "M") {      
       setRow2NakedM14(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
           ]
         )
         setC14R2VNo(7);
        setC14R3VNo(7);
                           } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(7);
                            setC14R2VNo(7);
                            setC14R3VNo(7);
                            setC14R4VNo(7);
                           }
       } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(7);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(7);
           setC14R2VNo(7);
          } else if (c14r2MT === "M" && c14r3MT === "M") {     
        setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(7);
         setC14R3VNo(7);
                            } else if (c14r2MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(7);
                              setC14R2VNo(7);
                              setC14R3VNo(7);
                              setC14R4VNo(7);
                            }
       
       } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
             ]
           )
           setC14R2VNo(7);
          } else if (c14r1MT === "M" && c14r2MT === "M") { 
            setBaseNakedM14(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(7);
           setC14R2VNo(7);
          } else if (c14r2MT === "M" && c14r3MT === "M") {      
      setRow2NakedM14(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
           ]
         )
         setC14R2VNo(7);
        setC14R3VNo(7);
                           } else if (c14r2MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(7);
                            setC14R2VNo(7);
                            setC14R3VNo(7);
                            setC14R4VNo(7);
                           }
         
       } 
       else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
               ]
             )
             setC1R3VNo(1);
            } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
              setRow2NakedM01(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(1);
             setC1R3VNo(1);
            } else if (c1r3MT === "M" && c1r4MT === "M") {       
       setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(1);
         setC1R4VNo(1);
                            } else if (c1r3MT === "L") {  
                              setBaseNakedL01(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                ]
                              )
                              setC1R1VNo(1);
                              setC1R2VNo(1);
                              setC1R3VNo(1);
                              setC1R4VNo(1);
                            }
   
        } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {  
          if (c1r3MT === "S") {  
            setRow3Naked01(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(4);
          } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(4);
           setC1R3VNo(4);
          } else if (c1r3MT === "M" && c1r4MT === "M") {     
            setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(4);
           setC1R4VNo(4);
                          } else if (c1r3MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(4);
                            setC1R2VNo(4);
                            setC1R3VNo(4);
                            setC1R4VNo(4);
                          }
        } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(2);
        } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
          setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(2);
         setC1R3VNo(2);
        } else if (c1r3MT === "M" && c1r4MT === "M") {     
        setRow3NakedM01(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
         ]
       )
         setC1R3VNo(2);
      setC1R4VNo(2);
                         } else if (c1r3MT === "L") {  
                          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(2);
                          setC1R2VNo(2);
                          setC1R3VNo(2);
                          setC1R4VNo(2);
                         }
        } else if (curX === col01X && curY === row03X && c1R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col01X && curY === row03X && c1R3VNo === 8) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(7);
          } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(7);
           setC1R3VNo(7);
          } else if (c1r3MT === "M" && c1r4MT === "M") {      
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(7);
       setC1R4VNo(7);
                          } else if (c1r3MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(7);
                            setC1R2VNo(7);
                            setC1R3VNo(7);
                            setC1R4VNo(7);
                          }
     
        } else if (curX === col01X && curY === row03X && c1R3VNo === 9) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(7);
          } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(7);
           setC1R3VNo(7);
          } else if (c1r3MT === "M" && c1r4MT === "M") {      
        setRow3NakedM01(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
           ]
         )
         setC1R3VNo(7);
        setC1R4VNo(7);
                           } else if (c1r3MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(7);
                            setC1R2VNo(7);
                            setC1R3VNo(7);
                            setC1R4VNo(7);
                           }
        } else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(7);
          } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(7);
           setC1R3VNo(7);
          } else if (c1r3MT === "M" && c1r4MT === "M") {      
      setRow3NakedM01(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
           ]
         )
         setC1R3VNo(7);
        setC1R4VNo(7);
                           } else if (c1r3MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(7);
                            setC1R2VNo(7);
                            setC1R3VNo(7);
                            setC1R4VNo(7);
                           }
      
        } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
          if (c1r3MT === "S") {  
            setRow3Naked01(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(7);
          } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(7);
           setC1R3VNo(7);
          } else if (c1r3MT === "M" && c1r4MT === "M") {      
     setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(7);
       setC1R4VNo(7);
                          } else if (c1r3MT === "L") {  
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(7);
                            setC1R2VNo(7);
                            setC1R3VNo(7);
                            setC1R4VNo(7);
                          }
     
     
          //2nd col
        }  else if (curX === col02X && curY === row03X && c2R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
          if (c2r3MT === "S") {  
            setRow3Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(1);
          } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
            setRow2Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(1);
         setC2R3VNo(1);
          } else if (c2r3MT === "M" && c2r4MT === "M") {      
      setRow3NakedM02(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
           ]
         )
         setC2R3VNo(1);
        setC2R4VNo(1);
                           } else if (c2r3MT === "L") {  
                            setBaseNakedL02(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(1);
                            setC2R2VNo(1);
                            setC2R3VNo(1);
                            setC2R4VNo(1);
                           }
      
      
   
        } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {  
          if (c2r3MT === "S") {  
            setRow3Naked02(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
           ]
         )
         setC2R3VNo(4);
        } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
          setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(4);
         setC2R3VNo(4);
        } else if (c2r3MT === "M" && c2r4MT === "M") {      
       setRow3NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
        ]
      )
      setC2R3VNo(4);
     setC2R4VNo(4);
                        } else if (c2r3MT === "L") {  
                          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(4);
                          setC2R2VNo(4);
                          setC2R3VNo(4);
                          setC2R4VNo(4);
                        }
   
   
        } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {
          if (c2r3MT === "S") {  
            setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(2);
       setC2R3VNo(2);
      } else if (c2r3MT === "M" && c2r4MT === "M") {      
        setRow3NakedM02(
       [
         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
       ]
     )
     setC2R3VNo(2);
    setC2R4VNo(2);
                       } else if (c2r3MT === "L") {  
                        setBaseNakedL02(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(2);
                        setC2R2VNo(2);
                        setC2R3VNo(2);
                        setC2R4VNo(2);
                       }
        } else if (curX === col02X && curY === row03X && c2R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col02X && curY === row03X && c2R3VNo === 8) {
          if (c2r3MT === "S") {  
            setRow3Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(7);
          } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
            setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(7);
           setC2R3VNo(7);
          } else if (c2r3MT === "M" && c2r4MT === "M") {       
          setRow3NakedM02(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
           ]
         )
         setC2R3VNo(7);
        setC2R4VNo(7);
                           } else if (c2r3MT === "L") {  
                            setBaseNakedL02(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(7);
                            setC2R2VNo(7);
                            setC2R3VNo(7);
                            setC2R4VNo(7);
                           }
        } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
          if (c2r3MT === "S") {  
            setRow3Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(7);
            } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
              setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(7);
             setC2R3VNo(7);
            } else if (c2r3MT === "M" && c2r4MT === "M") {       
           setRow3NakedM02(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
             ]
           )
           setC2R3VNo(7);
          setC2R4VNo(7);
                             } else if (c2r3MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                             }
        
        
        } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
          if (c2r3MT === "S") {  
            setRow3Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(7);
            } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
              setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(7);
             setC2R3VNo(7);
            } else if (c2r3MT === "M" && c2r4MT === "M") {      
         setRow3NakedM02(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
             ]
           )
           setC2R3VNo(7);
          setC2R4VNo(7);
                             } else if (c2r3MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                             }
        } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
          if (c2r3MT === "S") {  
            setRow3Naked02(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
                  ]
                )
                setC2R3VNo(7);
              } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                setRow2NakedM02(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
                  ]
                )
                setC2R2VNo(7);
               setC2R3VNo(7);
              } else if (c2r3MT === "M" && c2r4MT === "M") {      
           setRow3NakedM02(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
               ]
             )
             setC2R3VNo(7);
            setC2R4VNo(7);
                               } else if (c2r3MT === "L") {  
                                setBaseNakedL02(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                  ]
                                )
                                setC2R1VNo(7);
                                setC2R2VNo(7);
                                setC2R3VNo(7);
                                setC2R4VNo(7);
                               }
          //3rd  col
        }  else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
                   ]
                 )
                 setC3R3VNo(1);
                } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                  setRow2NakedM03(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                    ]
                  )
                  setC3R2VNo(1);
                 setC3R3VNo(1);
                } else if (c3r3MT === "M" && c3r4MT === "M") {      
            setRow3NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(1);
             setC3R4VNo(1);
                                } else if (c3r3MT === "L") {  
                                  setBaseNakedL03(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                    ]
                                  )
                                  setC3R1VNo(1);
                                  setC3R2VNo(1);
                                  setC3R3VNo(1);
                                  setC3R4VNo(1);
                                }
   
        } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {  
          if (c3r3MT === "S") {  
            setRow3Naked03(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                 ]
               )
               setC3R3VNo(4);
              } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                  ]
                )
                setC3R2VNo(4);
               setC3R3VNo(4);
              } else if (c3r3MT === "M" && c3r4MT === "M") {      
             setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(4);
           setC3R4VNo(4);
                              } else if (c3r3MT === "L") {  
                                setBaseNakedL03(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                  ]
                                )
                                setC3R1VNo(4);
                                setC3R2VNo(4);
                                setC3R3VNo(4);
                                setC3R4VNo(4);
                              }
         
        } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(2);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(2);
              setC3R2VNo(2);
            } else if (c3r3MT === "M" && c3r4MT === "M") {       
              setRow3NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
             ]
           )
           setC3R3VNo(2);
          setC3R4VNo(2);
                             } else if (c3r3MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(2);
                              setC3R2VNo(2);
                              setC3R3VNo(2);
                              setC3R4VNo(2);
                             }
        
        
        } else if (curX === col03X && curY === row03X && c3R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col03X && curY === row03X && c3R3VNo === 8) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(7);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(7);
             setC3R3VNo(7);
            } else if (c3r3MT === "M" && c3r4MT === "M") {        
            setRow3NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
             ]
           )
           setC3R3VNo(7);
          setC3R4VNo(7);
                             } else if (c3r3MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                             }
        
        } else if (curX === col03X && curY === row03X && c3R3VNo === 9) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(7);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(7);
             setC3R3VNo(7);
            } else if (c3r3MT === "M" && c3r4MT === "M") {      
            setRow3NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
             ]
           )
           setC3R3VNo(7);
          setC3R4VNo(7);
                             } else if (c3r3MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                             }
        } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(7);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(7);
             setC3R3VNo(7);
            } else if (c3r3MT === "M" && c3r4MT === "M") {      
          setRow3NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
             ]
           )
           setC3R3VNo(7);
          setC3R4VNo(7);
                             } else if (c3r3MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                             }
        
        
        } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
               ]
             )
             setC3R3VNo(7);
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(7);
             setC3R3VNo(7);
            } else if (c3r3MT === "M" && c3r4MT === "M") {       
        setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(7);
         setC3R4VNo(7);
                            } else if (c3r3MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                            }
       
          //4th col
        }  else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(1);
            } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
              setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(1);
             setC4R3VNo(1);
            } else if (c4r3MT === "M" && c4r4MT === "M") {      
        setRow3NakedM04(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(1);
          setC4R4VNo(1);
                             } else if (c4r3MT === "L") {  
                              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(1);
                              setC4R2VNo(1);
                              setC4R3VNo(1);
                              setC4R4VNo(1);
                             }
   
        } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {  
          if (c4r3MT === "S") {  
            setRow3Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(4);
          } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
            setRow2NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(4);
           setC4R3VNo(4);
          } else if (c4r3MT === "M" && c4r4MT === "M") {     
         setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(4);
       setC4R4VNo(4);
                          } else if (c4r3MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(4);
                            setC4R2VNo(4);
                            setC4R3VNo(4);
                            setC4R4VNo(4);
                          }
        } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(2);
        } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
          setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(2);
         setC4R3VNo(2);
        } else if (c4r3MT === "M" && c4r4MT === "M") {       
           setRow3NakedM04(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
         ]
       )
       setC4R3VNo(2);
      setC4R4VNo(2);
                         } else if (c4r3MT === "L") {  
                          setBaseNakedL04(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                            ]
                          )
                          setC4R1VNo(2);
                          setC4R2VNo(2);
                          setC4R3VNo(2);
                          setC4R4VNo(2);
                         }
    
    
        } else if (curX === col04X && curY === row03X && c4R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col04X && curY === row03X && c4R3VNo === 8) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(7);
          } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
            setRow2NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(7);
           setC4R3VNo(7);
          } else if (c4r3MT === "M" && c4r4MT === "M") {      
          setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(7);
       setC4R4VNo(7);
                          } else if (c4r3MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                          }
     
        } else if (curX === col04X && curY === row03X && c4R3VNo === 9) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(7);
          } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
            setRow2NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(7);
           setC4R3VNo(7);
          } else if (c4r3MT === "M" && c4r4MT === "M") {      
         setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(7);
       setC4R4VNo(7);
                          } else if (c4r3MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                          }
        } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(7);
            } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
              setRow2NakedM04(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(7);
             setC4R3VNo(7);
            } else if (c4r3MT === "M" && c4r4MT === "M") {      
           setRow3NakedM04(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(7);
          setC4R4VNo(7);
                             } else if (c4r3MT === "L") {  
                              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(7);
                              setC4R2VNo(7);
                              setC4R3VNo(7);
                              setC4R4VNo(7);
                             }
        
        
        } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
          if (c4r3MT === "S") {  
            setRow3Naked04(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
                 ]
               )
               setC4R3VNo(7);
              } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                  ]
                )
                setC4R2VNo(7);
               setC4R3VNo(7);
              } else if (c4r3MT === "M" && c4r4MT === "M") {     
           setRow3NakedM04(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(7);
           setC4R4VNo(7);
                              } else if (c4r3MT === "L") {  
                                setBaseNakedL04(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                  ]
                                )
                                setC4R1VNo(7);
                                setC4R2VNo(7);
                                setC4R3VNo(7);
                                setC4R4VNo(7);
                              }
         
         
          //5th col
        }  else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(1);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(1);
             setC5R3VNo(1);
            } else if (c5r3MT === "M" && c5r4MT === "M") {     
        setRow3NakedM05(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
             ]
           )
           setC5R3VNo(1);
          setC5R4VNo(1);
                             } else if (c5r3MT === "L") {  
                              setBaseNakedL05(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                ]
                              )
                              setC5R1VNo(1);
                              setC5R2VNo(1);
                              setC5R3VNo(1);
                              setC5R4VNo(1);
                             }
        
        
   
        } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {  
          if (c5r3MT === "S") {  
            setRow3Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
               ]
             )
             setC5R3VNo(4);
         } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
          setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(4);
         setC5R3VNo(4);
         } else if (c5r3MT === "M" && c5r4MT === "M") {      
          setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(4);
         setC5R4VNo(4);
                            } else if (c5r3MT === "L") {  
                              setBaseNakedL05(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                ]
                              )
                              setC5R1VNo(4);
                              setC5R2VNo(4);
                              setC5R3VNo(4);
                              setC5R4VNo(4);
                            }
       
       
        } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(2);
          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
            setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(2);
      setC5R3VNo(2);
          } else if (c5r3MT === "M" && c5r4MT === "M") {      
           setRow3NakedM05(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
           ]
         )
         setC5R3VNo(2);
        setC5R4VNo(2);
                           } else if (c5r3MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(2);
                            setC5R2VNo(2);
                            setC5R3VNo(2);
                            setC5R4VNo(2);
                           }
        } else if (curX === col05X && curY === row03X && c5R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col05X && curY === row03X && c5R3VNo === 8) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
               ]
             )
             setC5R3VNo(7);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(7);
             setC5R3VNo(7);
            } else if (c5r3MT === "M" && c5r4MT === "M") {        
         setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(7);
         setC5R4VNo(7);
                            } else if (c5r3MT === "L") {  
                              setBaseNakedL05(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                ]
                              )
                              setC5R1VNo(7);
                              setC5R2VNo(7);
                              setC5R3VNo(7);
                              setC5R4VNo(7);
                            }
              
        } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
               ]
             )
             setC5R3VNo(7);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(7);
             setC5R3VNo(7);
            } else if (c5r3MT === "M" && c5r4MT === "M") {  
        setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(7);
         setC5R4VNo(7);
                            } else if (c5r3MT === "L") {  
                              setBaseNakedL05(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                ]
                              )
                              setC5R1VNo(7);
                              setC5R2VNo(7);
                              setC5R3VNo(7);
                              setC5R4VNo(7);
                            }
        } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
               ]
             )
             setC5R3VNo(7);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(7);
             setC5R3VNo(7);
            } else if (c5r3MT === "M" && c5r4MT === "M") {       
        setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(7);
         setC5R4VNo(7);
                            } else if (c5r3MT === "L") {  
                              setBaseNakedL05(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                ]
                              )
                              setC5R1VNo(7);
                              setC5R2VNo(7);
                              setC5R3VNo(7);
                              setC5R4VNo(7);
                            }       
        } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
          if (c5r3MT === "S") {  
            setRow3Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(7);
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(7);
             setC5R3VNo(7);
            } else if (c5r3MT === "M" && c5r4MT === "M") {       
        setRow3NakedM05(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
             ]
           )
           setC5R3VNo(7);
          setC5R4VNo(7);
                             } else if (c5r3MT === "L") {  
                              setBaseNakedL05(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                ]
                              )
                              setC5R1VNo(7);
                              setC5R2VNo(7);
                              setC5R3VNo(7);
                              setC5R4VNo(7);
                             }
          //6th col
        }  else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
                 ]
               )
              } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                setRow2NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                  ]
                )
                setC6R2VNo(1);
               setC6R3VNo(1);
              } else if (c6r3MT === "M" && c6r4MT === "M") {      
         setRow3NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(1);
           setC6R4VNo(1);
                              } else if (c6r3MT === "L") {  
                                setBaseNakedL06(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                  ]
                                )
                                setC6R1VNo(1);
                                setC6R2VNo(1);
                                setC6R3VNo(1);
                                setC6R4VNo(1);
                              }
        } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {  
          if (c6r3MT === "S") {  
            setRow3Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
          } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(4);
           setC6R3VNo(4);
          } else if (c6r3MT === "M" && c6r4MT === "M") {    
         setRow3NakedM06(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
           ]
         )
         setC6R3VNo(4);
        setC6R4VNo(4);
                           } else if (c6r3MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(4);
                            setC6R2VNo(4);
                            setC6R3VNo(4);
                            setC6R4VNo(4);
                           }
        } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(2);
        } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
          setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(2);
         setC6R3VNo(2);
        } else if (c6r3MT === "M" && c6r4MT === "M") {      
         setRow3NakedM06(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
         ]
       )
       setC6R3VNo(2);
      setC6R4VNo(2);
                         } else if (c6r3MT === "L") {  
                          setBaseNakedL06(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                            ]
                          )
                          setC6R1VNo(2);
                          setC6R2VNo(2);
                          setC6R3VNo(2);
                          setC6R4VNo(2);
                         }    
        } else if (curX === col06X && curY === row03X && c6R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col06X && curY === row03X && c6R3VNo === 8) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
          } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(7);
           setC6R3VNo(7);
          } else if (c6r3MT === "M" && c6r4MT === "M") {       
          setRow3NakedM06(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
           ]
         )
         setC6R3VNo(7);
        setC6R4VNo(7);
                           } else if (c6r3MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(7);
                            setC6R2VNo(7);
                            setC6R3VNo(7);
                            setC6R4VNo(7);
                           }      
        } else if (curX === col06X && curY === row03X && c6R3VNo === 9) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
          } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(7);
           setC6R3VNo(7);
          } else if (c6r3MT === "M" && c6r4MT === "M") {     
         setRow3NakedM06(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
           ]
         )
         setC6R3VNo(7);
        setC6R4VNo(7);
                           } else if (c6r3MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(7);
                            setC6R2VNo(7);
                            setC6R3VNo(7);
                            setC6R4VNo(7);
                           }
        } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(7);
          } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(7);
           setC6R3VNo(7);
          } else if (c6r3MT === "M" && c6r4MT === "M") {    
        setRow3NakedM06(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
           ]
         )
         setC6R3VNo(7);
        setC6R4VNo(7);
                           } else if (c6r3MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(7);
                            setC6R2VNo(7);
                            setC6R3VNo(7);
                            setC6R4VNo(7);
                           }
        } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
          if (c6r3MT === "S") {  
            setRow3Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
               ]
             )
             setC6R3VNo(7);
            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
              setRow2NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(7);
             setC6R3VNo(7);
            } else if (c6r3MT === "M" && c6r4MT === "M") {      
         setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(7);
         setC6R4VNo(7);
                            } else if (c6r3MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(7);
                              setC6R2VNo(7);
                              setC6R3VNo(7);
                              setC6R4VNo(7);
                            }
          //7th col
        }  else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(1);
          } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
            setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(1);
           setC7R3VNo(1);
          } else if (c7r3MT === "M" && c7r4MT === "M") {     
       setRow3NakedM07(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
           ]
         )
         setC7R3VNo(1);
        setC7R4VNo(1);
                           } else if (c7r3MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(1);
                            setC7R2VNo(1);
                            setC7R3VNo(1);
                            setC7R4VNo(1);
                           }
   
        } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {  
          if (c7r3MT === "S") {  
            setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(4);
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(4);
       setC7R3VNo(4);
      } else if (c7r3MT === "M" && c7r4MT === "M") {      
      setRow3NakedM07(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
         ]
       )
       setC7R3VNo(4);
      setC7R4VNo(4);
                         } else if (c7r3MT === "L") {  
                          setBaseNakedL07(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                            ]
                          )
                          setC7R1VNo(4);
                          setC7R2VNo(4);
                          setC7R3VNo(4);
                          setC7R4VNo(4);
                         }
    
        } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
             ]
           )
          } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
            setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(2);
           setC7R3VNo(2);
          } else if (c7r3MT === "M" && c7r4MT === "M") {     
           setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(2);
       setC7R4VNo(2);
                          } else if (c7r3MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(2);
                            setC7R2VNo(2);
                            setC7R3VNo(2);
                            setC7R4VNo(2);
                          }
        } else if (curX === col07X && curY === row03X && c7R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col07X && curY === row03X && c7R3VNo === 8) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
             ]
           )
           setC7R3VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
            setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(7);
           setC7R3VNo(7);
          } else if (c7r3MT === "M" && c7r4MT === "M") {  
       setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(7);
       setC7R4VNo(7);
                          } else if (c7r3MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                          }     
        } else if (curX === col07X && curY === row03X && c7R3VNo === 9) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
             ]
           )
           setC7R3VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
            setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(7);
           setC7R3VNo(7);
          } else if (c7r3MT === "M" && c7r4MT === "M") {      
      setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(7);
       setC7R4VNo(7);
                          } else if (c7r3MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                          }
        } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
             ]
           )
           setC7R3VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
            setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(7);
           setC7R3VNo(7);
          } else if (c7r3MT === "M" && c7r4MT === "M") {     
      setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(7);
       setC7R4VNo(7);
                          } else if (c7r3MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                          }
     
        } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
          if (c7r3MT === "S") {  
            setRow3Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(7);
          } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
            setRow2NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(7);
           setC7R3VNo(7);
          } else if (c7r3MT === "M" && c7r4MT === "M") {    
       setRow3NakedM07(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
           ]
         )
         setC7R3VNo(7);
        setC7R4VNo(7);
                           } else if (c7r3MT === "L") {  
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(7);
                            setC7R2VNo(7);
                            setC7R3VNo(7);
                            setC7R4VNo(7);
                           }      
          //8th col
        }  else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(1);
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(1);
             setC8R3VNo(1);
            } else if (c8r3MT === "M" && c8r4MT === "M") {      
        setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(1);
         setC8R4VNo(1);
                            } else if (c8r3MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(1);
                              setC8R2VNo(1);
                              setC8R3VNo(1);
                              setC8R4VNo(1);
                            }       
   
        } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {  
          if (c8r3MT === "S") {  
            setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(4);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(4);
           setC8R3VNo(4);
          } else if (c8r3MT === "M" && c8r4MT === "M") {       
        setRow3NakedM08(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
           ]
         )
         setC8R3VNo(4);
        setC8R4VNo(4);
                           } else if (c8r3MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(4);
                            setC8R2VNo(4);
                            setC8R3VNo(4);
                            setC8R4VNo(4);
                           }
        } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(2);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(2);
           setC8R3VNo(2);
          } else if (c8r3MT === "M" && c8r4MT === "M") {       
          setRow3NakedM08(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
           ]
         )
         setC8R3VNo(2);
        setC8R4VNo(2);
                           } else if (c8r3MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(2);
                            setC8R2VNo(2);
                            setC8R3VNo(2);
                            setC8R4VNo(2);
                           }      
        } else if (curX === col08X && curY === row03X && c8R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col08X && curY === row03X && c8R3VNo === 8) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(7);
          } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
            setRow2NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(7);
           setC8R3VNo(7);
          } else if (c8r3MT === "M" && c8r4MT === "M") {      
          setRow3NakedM08(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
           ]
         )
         setC8R3VNo(7);
        setC8R4VNo(7);
                           } else if (c8r3MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                           }      
        } else if (curX === col08X && curY === row03X && c8R3VNo === 9) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
               ]
             )
             setC8R3VNo(7);
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(7);
             setC8R3VNo(7);
            } else if (c8r3MT === "M" && c8r4MT === "M") {       
          setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(7);
         setC8R4VNo(7);
                            } else if (c8r3MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(7);
                              setC8R2VNo(7);
                              setC8R3VNo(7);
                              setC8R4VNo(7);
                            }
       
        } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(7);
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(7);
             setC8R3VNo(7);
            } else if (c8r3MT === "M" && c8r4MT === "M") {       
         setRow3NakedM08(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(7);
          setC8R4VNo(7);
                             } else if (c8r3MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(7);
                              setC8R2VNo(7);
                              setC8R3VNo(7);
                              setC8R4VNo(7);
                             }
        } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(7);
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(7);
             setC8R3VNo(7);
            } else if (c8r3MT === "M" && c8r4MT === "M") {       
         setRow3NakedM08(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
             ]
           )
           setC8R3VNo(7);
          setC8R4VNo(7);
                             } else if (c8r3MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(7);
                              setC8R2VNo(7);
                              setC8R3VNo(7);
                              setC8R4VNo(7);
                             }
        
          //9th col
        }  else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(1);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(1);
             setC9R3VNo(1);
            } else if (c9r3MT === "M" && c9r4MT === "M") {       
         setRow3NakedM09(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
             ]
           )
           setC9R3VNo(1);
          setC9R4VNo(1);
                             } else if (c9r3MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(1);
                              setC9R2VNo(1);
                              setC9R3VNo(1);
                              setC9R4VNo(1);
                             }
   
        } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {  
          if (c9r3MT === "S") {  
            setRow3Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(4);
          } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
            setRow2NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(4);
           setC9R3VNo(4);
          } else if (c9r3MT === "M" && c9r4MT === "M") {       
            setRow3NakedM09(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
           ]
         )
         setC9R3VNo(4);
        setC9R4VNo(4);
                           } else if (c9r3MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(4);
                            setC9R2VNo(4);
                            setC9R3VNo(4);
                            setC9R4VNo(4);
                           }
        } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(2);
        } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
          setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(2);
         setC9R3VNo(2);
        } else if (c9r3MT === "M" && c9r4MT === "M") {     
          setRow3NakedM09(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
         ]
       )
       setC9R3VNo(2);
      setC9R4VNo(2);
                         } else if (c9r3MT === "L") {  
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(2);
                          setC9R2VNo(2);
                          setC9R3VNo(2);
                          setC9R4VNo(2);
                         }
        } else if (curX === col09X && curY === row03X && c9R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col09X && curY === row03X && c9R3VNo === 8) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(7);
          } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
            setRow2NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(7);
           setC9R3VNo(7);
          } else if (c9r3MT === "M" && c9r4MT === "M") {      
         setRow3NakedM09(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
           ]
         )
         setC9R3VNo(7);
        setC9R4VNo(7);
                           } else if (c9r3MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(7);
                            setC9R2VNo(7);
                            setC9R3VNo(7);
                            setC9R4VNo(7);
                           }
        } else if (curX === col09X && curY === row03X && c9R3VNo === 9) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(7);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(7);
             setC9R3VNo(7);
            } else if (c9r3MT === "M" && c9r4MT === "M") {      
         setRow3NakedM09(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
             ]
           )
           setC9R3VNo(7);
          setC9R4VNo(7);
                             } else if (c9r3MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(7);
                              setC9R2VNo(7);
                              setC9R3VNo(7);
                              setC9R4VNo(7);
                             }
        
        } else if (curX === col09X && curY === row03X && c9R3VNo === 10) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(7);
            } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
              setRow2NakedM09(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(7);
             setC9R3VNo(7);
            } else if (c9r3MT === "M" && c9r4MT === "M") {       
         setRow3NakedM09(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
             ]
           )
           setC9R3VNo(7);
          setC9R4VNo(7);
                             } else if (c9r3MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(7);
                              setC9R2VNo(7);
                              setC9R3VNo(7);
                              setC9R4VNo(7);
                             }
        
        } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                 ]
               )
               setC9R3VNo(7);
              } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                setRow2NakedM09(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
                  ]
                )
                setC9R2VNo(7);
               setC9R3VNo(7);
              } else if (c9r3MT === "M" && c9r4MT === "M") {      
          setRow3NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(7);
           setC9R4VNo(7);
                              } else if (c9r3MT === "L") {  
                                setBaseNakedL09(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                  ]
                                )
                                setC9R1VNo(7);
                                setC9R2VNo(7);
                                setC9R3VNo(7);
                                setC9R4VNo(7);
                              }
         
          //10th col
        }  else if (curX === col10X && curY === row03X && c10R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
          if (c10r3MT === "S") {  
            setRow3Naked10(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
                  ]
                )
                setC10R3VNo(1);
              } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                setRow2NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(1);
               setC10R3VNo(1);
              } else if (c10r3MT === "M" && c10r4MT === "M") {    
           setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(1);
            setC10R4VNo(1);
                               } else if (c10r3MT === "L") {  
                                setBaseNakedL10(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                  ]
                                )
                                setC10R1VNo(1);
                                setC10R2VNo(1);
                                setC10R3VNo(1);
                                setC10R4VNo(1);
                               }
   
        } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {  
          if (c10r3MT === "S") {  
            setRow3Naked10(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                 ]
               )
               setC10R3VNo(4);
              } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                setRow2NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(4);
               setC10R3VNo(4);
              } else if (c10r3MT === "M" && c10r4MT === "M") {       
            setRow3NakedM10(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(4);
           setC10R4VNo(4);
                              } else if (c10r3MT === "L") {  
                                setBaseNakedL10(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                  ]
                                )
                                setC10R1VNo(4);
                                setC10R2VNo(4);
                                setC10R3VNo(4);
                                setC10R4VNo(4);
                              }
         
        } else if (curX === col10X && curY === row03X && c10R3VNo === 6) {
          if (c10r3MT === "S") {  
            setRow3Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(2);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(2);
             setC10R3VNo(2);
            } else if (c10r3MT === "M" && c10r4MT === "M") {        
              setRow3NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
             ]
           )
           setC10R3VNo(2);
          setC10R4VNo(2);
                             } else if (c10r3MT === "L") {  
                              setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                              setC10R1VNo(2);
                              setC10R2VNo(2);
                              setC10R3VNo(2);
                              setC10R4VNo(2);
                             }
        } else if (curX === col10X && curY === row03X && c10R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col10X && curY === row03X && c10R3VNo === 8) {
          if (c10r3MT === "S") {  
            setRow3Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(7);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(7);
             setC10R3VNo(7);
            } else if (c10r3MT === "M" && c10r4MT === "M") {      
           setRow3NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
             ]
           )
           setC10R3VNo(7);
          setC10R4VNo(7);
                             } else if (c10r3MT === "L") {  
                              setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                              setC10R1VNo(7);
                              setC10R2VNo(7);
                              setC10R3VNo(7);
                              setC10R4VNo(7);
                             }        
        } else if (curX === col10X && curY === row03X && c10R3VNo === 9) {
          if (c10r3MT === "S") {  
            setRow3NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(7);
            } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
              setRow2NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(7);
             setC10R3VNo(7);
            } else if (c10r3MT === "M" && c10r4MT === "M") {      
             setRow3NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(7);
             setC10R4VNo(7);
                                } else if (c10r3MT === "L") {  
                                  setBaseNakedL10(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                    ]
                                  )
                                  setC10R1VNo(7);
                                  setC10R2VNo(7);
                                  setC10R3VNo(7);
                                  setC10R4VNo(7);
                                }
        } else if (curX === col10X && curY === row03X && c10R3VNo === 10) {
          if (c10r3MT === "S") {  
            setRow3Naked10(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                  ]
                )
                setC10R3VNo(7);
              } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                setRow2NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(7);
               setC10R3VNo(7);
              } else if (c10r3MT === "M" && c10r4MT === "M") {      
           setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(7);
            setC10R4VNo(7);
                               } else if (c10r3MT === "L") {  
                                setBaseNakedL10(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                  ]
                                )
                                setC10R1VNo(7);
                                setC10R2VNo(7);
                                setC10R3VNo(7);
                                setC10R4VNo(7);
                               }
        } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {
          if (c10r3MT === "S") {  
            setRow3Naked10(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                   ]
                 )
                 setC10R3VNo(7);
                } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                  setRow2NakedM10(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                    ]
                  )
                  setC10R2VNo(7);
                 setC10R3VNo(7);
                } else if (c10r3MT === "M" && c10r4MT === "M") {       
            setRow3NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(7);
             setC10R4VNo(7);
                                } else if (c10r3MT === "L") {  
                                  setBaseNakedL10(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                    ]
                                  )
                                  setC10R1VNo(7);
                                  setC10R2VNo(7);
                                  setC10R3VNo(7);
                                  setC10R4VNo(7);
                                }
       }  else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
          if (c11r3MT === "S") {  
            setRow3Naked11(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
                  ]
                )
                setC11R3VNo(1);
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(1);
               setC11R3VNo(1);
              } else if (c11r3MT === "M" && c11r4MT === "M") {      
          setRow3NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(1);
            setC11R4VNo(1);
                               } else if (c11r3MT === "L") {  
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(1);
                                setC11R2VNo(1);
                                setC11R3VNo(1);
                                setC11R4VNo(1);
                               }
   
        } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {  
          if (c11r3MT === "S") {  
            setRow3Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                 ]
               )
               setC11R3VNo(4);
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(4);
               setC11R3VNo(4);
              } else if (c11r3MT === "M" && c11r4MT === "M") {       
            setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(4);
           setC11R4VNo(4);
                              } else if (c11r3MT === "L") {  
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(4);
                                setC11R2VNo(4);
                                setC11R3VNo(4);
                                setC11R4VNo(4);
                              }
        } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {
          if (c11r3MT === "S") {  
            setRow3Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(2);
            } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
              setRow2NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(2);
             setC11R3VNo(2);
            } else if (c11r3MT === "M" && c11r4MT === "M") {      
            setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(2);
          setC11R4VNo(2);
                             } else if (c11r3MT === "L") {  
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(2);
                              setC11R2VNo(2);
                              setC11R3VNo(2);
                              setC11R4VNo(2);
                             }
        } else if (curX === col11X && curY === row03X && c11R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col11X && curY === row03X && c11R3VNo === 8) {
          if (c11r3MT === "S") {  
            setRow3Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                 ]
               )
               setC11R3VNo(7);
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(7);
               setC11R3VNo(7);
              } else if (c11r3MT === "M" && c11r4MT === "M") {          
            setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(7);
           setC11R4VNo(7);
                              } else if (c11r3MT === "L") {  
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(7);
                                setC11R2VNo(7);
                                setC11R3VNo(7);
                                setC11R4VNo(7);
                              }
        } else if (curX === col11X && curY === row03X && c11R3VNo === 9) {
          if (c11r3MT === "S") {  
            setRow3Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                 ]
               )
               setC11R3VNo(7);
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(7);
               setC11R3VNo(7);
              } else if (c11r3MT === "M" && c11r4MT === "M") {         
            setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(7);
           setC11R4VNo(7);
                              } else if (c11r3MT === "L") {  
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(7);
                                setC11R2VNo(7);
                                setC11R3VNo(7);
                                setC11R4VNo(7);
                              }
        } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
          if (c11r3MT === "S") {  
            setRow3Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                 ]
               )
               setC11R3VNo(7);
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(7);
               setC11R3VNo(7);
              } else if (c11r3MT === "M" && c11r4MT === "M") {          
           setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(7);
           setC11R4VNo(7);
                              } else if (c11r3MT === "L") {  
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(7);
                                setC11R2VNo(7);
                                setC11R3VNo(7);
                                setC11R4VNo(7);
                              }
        } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
          if (c11r3MT === "S") {  
            setRow3Naked11(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                  ]
                )
                setC11R3VNo(7);
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(7);
               setC11R3VNo(7);
              } else if (c11r3MT === "M" && c11r4MT === "M") {         
            setRow3NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(7);
            setC11R4VNo(7);
                               } else if (c11r3MT === "L") {  
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(7);
                                setC11R2VNo(7);
                                setC11R3VNo(7);
                                setC11R4VNo(7);
                               }
          //12th col
        }  else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
          if (c12r3MT === "S") {  
            setRow3Naked12(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
                  ]
                )
                setC12R3VNo(1);
              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(1);
               setC12R3VNo(1);
              } else if (c12r3MT === "M" && c12r4MT === "M") {     
           setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(1);
            setC12R4VNo(1);
                               } else if (c12r3MT === "L") {  
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(1);
                                setC12R2VNo(1);
                                setC12R3VNo(1);
                                setC12R4VNo(1);
                               }
   
        } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {  
          if (c12r3MT === "S") {  
            setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(4);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(4);
             setC12R3VNo(4);
            } else if (c12r3MT === "M" && c12r4MT === "M") {      
           setRow3NakedM12(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
             ]
           )
           setC12R3VNo(4);
          setC12R4VNo(4);
                             } else if (c12r3MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(4);
                              setC12R2VNo(4);
                              setC12R3VNo(4);
                              setC12R4VNo(4);
                             }
        } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {
          if (c12r3MT === "S") {  
            setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(2);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(2);
             setC12R3VNo(2);
            } else if (c12r3MT === "M" && c12r4MT === "M") {      
             setRow3NakedM12(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
             ]
           )
           setC12R3VNo(2);
          setC12R4VNo(2);
                             } else if (c12r3MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(2);
                              setC12R2VNo(2);
                              setC12R3VNo(2);
                              setC12R4VNo(2);
                             }
        } else if (curX === col12X && curY === row03X && c12R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col12X && curY === row03X && c12R3VNo === 8) {
          if (c12r3MT === "S") {  
            setRow3Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                 ]
               )
               setC12R3VNo(7);
              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(7);
               setC12R3VNo(7);
              } else if (c12r3MT === "M" && c12r4MT === "M") {       
             setRow3NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(7);
           setC12R4VNo(7);
                              } else if (c12r3MT === "L") {  
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(7);
                                setC12R2VNo(7);
                                setC12R3VNo(7);
                                setC12R4VNo(7);
                              }
         
        } else if (curX === col12X && curY === row03X && c12R3VNo === 9) {
          if (c12r3MT === "S") {  
            setRow3Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(7);
            } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
              setRow2NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(7);
             setC12R3VNo(7);
            } else if (c12r3MT === "M" && c12r4MT === "M") {    
            setRow3NakedM12(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
             ]
           )
           setC12R3VNo(7);
          setC12R4VNo(7);
                             } else if (c12r3MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(7);
                              setC12R2VNo(7);
                              setC12R3VNo(7);
                              setC12R4VNo(7);
                             }
        } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
          if (c12r3MT === "S") {  
            setRow3Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                 ]
               )
               setC12R3VNo(7);
              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(7);
               setC12R3VNo(7);
              } else if (c12r3MT === "M" && c12r4MT === "M") {      
             setRow3NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(7);
           setC12R4VNo(7);
                              } else if (c12r3MT === "L") {  
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(7);
                                setC12R2VNo(7);
                                setC12R3VNo(7);
                                setC12R4VNo(7);
                              }
        } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
          if (c12r3MT === "S") {  
            setRow3Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                 ]
               )
               setC12R3VNo(7);
              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(7);
               setC12R3VNo(7);
              } else if (c12r3MT === "M" && c12r4MT === "M") {      
            setRow3NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(7);
           setC12R4VNo(7);
                              } else if (c12r3MT === "L") {  
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(7);
                                setC12R2VNo(7);
                                setC12R3VNo(7);
                                setC12R4VNo(7);
                              }
          //13th col
        }  else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
          if (c13r3MT === "S") {  
            setRow3Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
                 ]
               )
               setC13R1VNo(1);
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(1);
               setC13R3VNo(1);
              } else if (c13r3MT === "M" && c13r4MT === "M") {       
            setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(1);
           setC13R4VNo(1);
                              } else if (c13r3MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(1);
                                setC13R2VNo(1);
                                setC13R3VNo(1);
                                setC13R4VNo(1);
                              }
   
        } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {  
          if (c13r3MT === "S") {  
            setRow3Naked13(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                    ]
                  )
                  setC13R1VNo(4);
                } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                  setRow2NakedM13(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                    ]
                  )
                  setC13R2VNo(4);
                 setC13R3VNo(4);
                } else if (c13r3MT === "M" && c13r4MT === "M") {      
             setRow3NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
             ]
           )
           setC13R3VNo(4);
          setC13R4VNo(4);
                             } else if (c13r3MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(4);
                              setC13R2VNo(4);
                              setC13R3VNo(4);
                              setC13R4VNo(4);
                             }
        } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {
          if (c13r3MT === "S") {  
            setRow3Naked13(
                         [
                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
                         ]
                       )
                       setC13R3VNo(2);
                      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                        setRow2NakedM13(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                          ]
                        )
                        setC13R2VNo(2);
                       setC13R3VNo(2);
                      } else if (c13r3MT === "M" && c13r4MT === "M") {       
                setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(2);
            setC13R4VNo(2);
                              } else if (c13r3MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(2);
                                setC13R2VNo(2);
                                setC13R3VNo(2);
                                setC13R4VNo(2);
                              }
         
        } else if (curX === col13X && curY === row03X && c13R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col13X && curY === row03X && c13R3VNo === 8) {
          if (c13r3MT === "S") {  
            setRow3Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                  ]
                )
                setC13R1VNo(7);
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(7);
               setC13R3VNo(7);
              } else if (c13r3MT === "M" && c13r4MT === "M") {       
             setRow3NakedM13(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(7);
            setC13R4VNo(7);
                               } else if (c13r3MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(7);
                                setC13R2VNo(7);
                                setC13R3VNo(7);
                                setC13R4VNo(7);
                               }
        } else if (curX === col13X && curY === row03X && c13R3VNo === 9) {
          if (c13r3MT === "S") {  
            setRow3Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                 ]
               )
               setC13R1VNo(7);
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(7);
               setC13R3VNo(7);
              } else if (c13r3MT === "M" && c13r4MT === "M") {      
           setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(7);
           setC13R4VNo(7);
                              } else if (c13r3MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(7);
                                setC13R2VNo(7);
                                setC13R3VNo(7);
                                setC13R4VNo(7);
                              }
         
        } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
          if (c13r3MT === "S") {  
            setRow3Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                 ]
               )
               setC13R1VNo(7);
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(7);
               setC13R3VNo(7);
              } else if (c13r3MT === "M" && c13r4MT === "M") {       
         setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(7);
           setC13R4VNo(7);
                              } else if (c13r3MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(7);
                                setC13R2VNo(7);
                                setC13R3VNo(7);
                                setC13R4VNo(7);
                              }
         
        } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
          if (c13r3MT === "S") {  
            setRow3Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R1VNo(7);
            } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
              setRow2NakedM13(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(7);
             setC13R3VNo(7);
            } else if (c13r3MT === "M" && c13r4MT === "M") {    
        setRow3NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
             ]
           )
           setC13R3VNo(7);
          setC13R4VNo(7);
                             } else if (c13r3MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(7);
                              setC13R2VNo(7);
                              setC13R3VNo(7);
                              setC13R4VNo(7);
                             }
        
          //14th col
        }  else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
         alert("Already removed");
        } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
             alert("Already removed");      
        } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
          if (c14r3MT === "S") {  
            setRow3Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
               ]
             )
             setC14R3VNo(1);
            } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
              setRow2NakedM14(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(1);
             setC14R3VNo(1);
            } else if (c14r3MT === "M" && c14r4MT === "M") {     
       setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(1);
         setC14R4VNo(1);
                            } else if (c14r3MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(1);
                              setC14R2VNo(1);
                              setC14R3VNo(1);
                              setC14R4VNo(1);
                            }
   
        } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
         alert("Already removed");
        } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {  
          if (c14r3MT === "S") {  
            setRow3Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(4);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(4);
           setC14R3VNo(4);
          } else if (c14r3MT === "M" && c14r4MT === "M") {       
       setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(4);
       setC14R4VNo(4);
                          } else if (c14r3MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(4);
                            setC14R2VNo(4);
                            setC14R3VNo(4);
                            setC14R4VNo(4);
                          }
        } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {
          if (c14r3MT === "S") {  
            setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(2);
        } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
          setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(2);
         setC14R3VNo(2);
        } else if (c14r3MT === "M" && c14r4MT === "M") {       
          setRow3NakedM14(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
         ]
       )
       setC14R3VNo(2);
      setC14R4VNo(2);
                         } else if (c14r3MT === "L") {  
                          setBaseNakedL14(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(2);
                          setC14R2VNo(2);
                          setC14R3VNo(2);
                          setC14R4VNo(2);
                         }
        } else if (curX === col14X && curY === row03X && c14R3VNo === 7) {
         alert("Already removed");
         
        } else if (curX === col14X && curY === row03X && c14R3VNo === 8) {
          if (c14r3MT === "S") {  
            setRow3Naked14(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(7);
          } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
            setRow2NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(7);
           setC14R3VNo(7);
          } else if (c14r3MT === "M" && c14r4MT === "M") {       
       setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(7);
       setC14R4VNo(7);
                          } else if (c14r3MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(7);
                            setC14R2VNo(7);
                            setC14R3VNo(7);
                            setC14R4VNo(7);
                          }
        } else if (curX === col14X && curY === row03X && c14R3VNo === 9) {
          if (c14r3MT === "S") {  
            setRow3Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
               ]
             )
             setC14R3VNo(7);
            } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
              setRow2NakedM14(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(7);
             setC14R3VNo(7);
            } else if (c14r3MT === "M" && c14r4MT === "M") {        
        setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(7);
         setC14R4VNo(7);
                            } else if (c14r3MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(7);
                              setC14R2VNo(7);
                              setC14R3VNo(7);
                              setC14R4VNo(7);
                            }
        } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
          if (c14r3MT === "S") {  
            setRow3Naked14(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                 ]
               )
               setC14R3VNo(7);
              } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                setRow2NakedM14(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
                  ]
                )
                setC14R2VNo(7);
               setC14R3VNo(7);
              } else if (c14r3MT === "M" && c14r4MT === "M") {      
         setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(7);
           setC14R4VNo(7);
                              } else if (c14r3MT === "L") {  
                                setBaseNakedL14(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                  ]
                                )
                                setC14R1VNo(7);
                                setC14R2VNo(7);
                                setC14R3VNo(7);
                                setC14R4VNo(7);
                              }
        } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
          if (c14r3MT === "S") {  
            setRow3Naked14(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                 ]
               )
               setC14R3VNo(7);
              } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                setRow2NakedM14(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
                  ]
                )
                setC14R2VNo(7);
               setC14R3VNo(7);
              } else if (c14r3MT === "M" && c14r4MT === "M") {      
         setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(7);
           setC14R4VNo(7);
                              } else if (c14r3MT === "L") {  
                                setBaseNakedL14(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                  ]
                                )
                                setC14R1VNo(7);
                                setC14R2VNo(7);
                                setC14R3VNo(7);
                                setC14R4VNo(7);
                              }         
          
        }     else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
          if (c1r4MT === "S") {  
            setRow4Naked01(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
                  ]
                )
                setC1R3VNo(1);
           } else if (c1r4MT === "M") {      
         setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(1);
           setC1R4VNo(1);
                              } else if (c1r4MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(1);
                                setC1R2VNo(1);
                                setC1R3VNo(1);
                                setC1R4VNo(1);
                              }
    
         } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {  
          if (c1r4MT === "S") {  
            setRow4Naked01(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
                ]
              )
              setC1R3VNo(4);
         } else if (c1r4MT === "M") {      
          setBaseNaked01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(4);
         setC1R4VNo(4);
                            } else if (c1r4MT === "L") {  
                              setBaseNakedL01(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                ]
                              )
                              setC1R1VNo(4);
                              setC1R2VNo(4);
                              setC1R3VNo(4);
                              setC1R4VNo(4);
                            }
       
         } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {
          if (c1r4MT === "S") {  
            setRow4Naked01(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
                  ]
                )
                setC1R3VNo(2);
           } else if (c1r4MT === "M") {      
              setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(2);
           setC1R4VNo(2);
                              } else if (c1r4MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(2);
                                setC1R2VNo(2);
                                setC1R3VNo(2);
                                setC1R4VNo(2);
                              }
         } else if (curX === col01X && curY === row04X && c1R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col01X && curY === row04X && c1R4VNo === 8) {
          if (c1r4MT === "S") {  
            setRow4Naked01(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
                   ]
                 )
                 setC1R3VNo(7);
            } else if (c1r4MT === "M") {      
            setRow3NakedM01(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
               ]
             )
             setC1R3VNo(7);
            setC1R4VNo(7);
                               } else if (c1r4MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);
                                setC1R2VNo(7);
                                setC1R3VNo(7);
                                setC1R4VNo(7);
                               }
         } else if (curX === col01X && curY === row04X && c1R4VNo === 9) {
          if (c1r4MT === "S") {  
            setRow4Naked01(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
                   ]
                 )
                 setC1R3VNo(7);
            } else if (c1r4MT === "M") {      
          setRow3NakedM01(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
               ]
             )
             setC1R3VNo(7);
            setC1R4VNo(7);
                               } else if (c1r4MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);
                                setC1R2VNo(7);
                                setC1R3VNo(7);
                                setC1R4VNo(7);
                               }
         } else if (curX === col01X && curY === row04X && c1R4VNo === 10) {
          if (c1r4MT === "S") {  
            setRow4Naked01(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
                   ]
                 )
                 setC1R3VNo(7);
            } else if (c1r4MT === "M") {      
          setRow3NakedM01(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
               ]
             )
             setC1R3VNo(7);
            setC1R4VNo(7);
                               } else if (c1r4MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);
                                setC1R2VNo(7);
                                setC1R3VNo(7);
                                setC1R4VNo(7);
                               }
          
         } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
          if (c1r4MT === "S") {  
            setRow4Naked01(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
                  ]
                )
                setC1R3VNo(7);
           } else if (c1r4MT === "M") {      
         setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(7);
           setC1R4VNo(7);
                              } else if (c1r4MT === "L") {  
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);
                                setC1R2VNo(7);
                                setC1R3VNo(7);
                                setC1R4VNo(7);
                              }
           //2nd col
         }  else if (curX === col02X && curY === row04X && c2R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col02X && curY === row04X && c2R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col02X && curY === row04X && c2R4VNo === 3) {
          if (c2r4MT === "S") {  
            setRow4Naked02(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
                  ]
                )
                setC2R4VNo(1);
           } else if (c2r4MT === "M") {      
         setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(1);
           setC2R4VNo(1);
                              } else if (c2r4MT === "L") {  
                                setBaseNakedL02(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                  ]
                                )
                                setC2R1VNo(1);
                                setC2R2VNo(1);
                                setC2R3VNo(1);
                                setC2R4VNo(1);
                              }
    
         } else if (curX === col02X && curY === row04X && c2R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col02X && curY === row04X && c2R4VNo === 5) {  
          if (c2r4MT === "S") {  
            setRow4Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                ]
              )
              setC2R4VNo(4);
         } else if (c2r4MT === "M") {      
         setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(4);
         setC2R4VNo(4);
                            } else if (c2r4MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(4);
                              setC2R2VNo(4);
                              setC2R3VNo(4);
                              setC2R4VNo(4);
                            }
         } else if (curX === col02X && curY === row04X && c2R4VNo === 6) {
          if (c2r4MT === "S") {  
            setRow4Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
              ]
            )
            setC2R4VNo(2);
       } else if (c2r4MT === "M") {      
         setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(2);
       setC2R4VNo(2);
                          } else if (c2r4MT === "L") {  
                            setBaseNakedL02(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(2);
                            setC2R2VNo(2);
                            setC2R3VNo(2);
                            setC2R4VNo(2);
                          }
         } else if (curX === col02X && curY === row04X && c2R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col02X && curY === row04X && c2R4VNo === 8) {
          if (c2r4MT === "S") {  
            setRow4Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                ]
              )
              setC2R4VNo(7);
         } else if (c2r4MT === "M") {      
          setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(7);
         setC2R4VNo(7);
                            } else if (c2r4MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                            }
         } else if (curX === col02X && curY === row04X && c2R4VNo === 9) {
          if (c2r4MT === "S") {  
            setRow4Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                ]
              )
              setC2R4VNo(7);
         } else if (c2r4MT === "M") {      
         setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(7);
         setC2R4VNo(7);
                            } else if (c2r4MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                            }
         } else if (curX === col02X && curY === row04X && c2R4VNo === 10) {
          if (c2r4MT === "S") {  
            setRow4Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                ]
              )
              setC2R4VNo(7);
         } else if (c2r4MT === "M") {      
         setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(7);
         setC2R4VNo(7);
                            } else if (c2r4MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                            }
         } else if (curX === col02X && curY === row04X && c2R4VNo === 11) {
          if (c2r4MT === "S") {  
            setRow4Naked02(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                 ]
               )
               setC2R4VNo(7);
          } else if (c2r4MT === "M") {      
        setRow3NakedM02(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
             ]
           )
           setC2R3VNo(7);
          setC2R4VNo(7);
                             } else if (c2r4MT === "L") {  
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(7);
                              setC2R2VNo(7);
                              setC2R3VNo(7);
                              setC2R4VNo(7);
                             }        
           //3rd  col
         }  else if (curX === col03X && curY === row04X && c3R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col03X && curY === row04X && c3R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col03X && curY === row04X && c3R4VNo === 3) {
          if (c3r4MT === "S") {  
            setRow4Naked03(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
                 ]
               )
               setC3R4VNo(1);
          } else if (c3r4MT === "M") {      
        setRow3NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
             ]
           )
           setC3R3VNo(1);
          setC3R4VNo(1);
                             } else if (c3r4MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(1);
                              setC3R2VNo(1);
                              setC3R3VNo(1);
                              setC3R4VNo(1);
                             }
    
         } else if (curX === col03X && curY === row04X && c3R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col03X && curY === row04X && c3R4VNo === 5) {  
          if (c3r4MT === "S") {  
            setRow4Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                ]
              )
              setC3R4VNo(4);
         } else if (c3r4MT === "M") {      
         setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(4);
         setC3R4VNo(4);
                            } else if (c3r4MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(4);
                              setC3R2VNo(4);
                              setC3R3VNo(4);
                              setC3R4VNo(4);
                            }
         } else if (curX === col03X && curY === row04X && c3R4VNo === 6) {
          if (c3r4MT === "S") {  
            setRow4Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
                ]
              )
              setC3R4VNo(2);
         } else if (c3r4MT === "M") {      
           setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(2);
         setC3R4VNo(2);
                            } else if (c3r4MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(2);
                              setC3R2VNo(2);
                              setC3R3VNo(2);
                              setC3R4VNo(2);
                            }
       
         } else if (curX === col03X && curY === row04X && c3R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col03X && curY === row04X && c3R4VNo === 8) {
          if (c3r4MT === "S") {  
            setRow4Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                ]
              )
              setC3R4VNo(7);
         } else if (c3r4MT === "M") {      
          setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(7);
         setC3R4VNo(7);
                            } else if (c3r4MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                            }
         } else if (curX === col03X && curY === row04X && c3R4VNo === 9) {
          if (c3r4MT === "S") {  
            setRow4Naked03(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                 ]
               )
               setC3R4VNo(7);
          } else if (c3r4MT === "M") {      
         setRow3NakedM03(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
             ]
           )
           setC3R3VNo(7);
          setC3R4VNo(7);
                             } else if (c3r4MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                             }
        
        
        
         } else if (curX === col03X && curY === row04X && c3R4VNo === 10) {
          if (c3r4MT === "S") {  
            setRow4Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                ]
              )
              setC3R4VNo(7);
         } else if (c3r4MT === "M") {      
        setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(7);
         setC3R4VNo(7);
                            } else if (c3r4MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                            }
              
         } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {
          if (c3r4MT === "S") {  
            setRow4Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                ]
              )
              setC3R4VNo(7);
         } else if (c3r4MT === "M") {      
        setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(7);
         setC3R4VNo(7);
                            } else if (c3r4MT === "L") {  
                              setBaseNakedL03(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(7);
                              setC3R2VNo(7);
                              setC3R3VNo(7);
                              setC3R4VNo(7);
                            }
           //4th col
         }  else if (curX === col04X && curY === row04X && c4R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col04X && curY === row04X && c4R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col04X && curY === row04X && c4R4VNo === 3) {
          if (c4r4MT === "S") {  
            setRow4Naked04(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
                 ]
               )
               setC4R4VNo(1);
          } else if (c4r4MT === "M") {      
         setRow3NakedM04(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
             ]
           )
           setC4R3VNo(1);
          setC4R4VNo(1);
                             } else if (c4r4MT === "L") {  
                              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(1);
                              setC4R2VNo(1);
                              setC4R3VNo(1);
                              setC4R4VNo(1);
                             }
    
         } else if (curX === col04X && curY === row04X && c4R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col04X && curY === row04X && c4R4VNo === 5) {  
          if (c4r4MT === "S") {  
            setRow4Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
               ]
             )
             setC4R4VNo(4);
        } else if (c4r4MT === "M") {      
          setRow3NakedM04(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
           ]
         )
         setC4R3VNo(4);
        setC4R4VNo(4);
                           } else if (c4r4MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(4);
                            setC4R2VNo(4);
                            setC4R3VNo(4);
                            setC4R4VNo(4);
                           }
            
         } else if (curX === col04X && curY === row04X && c4R4VNo === 6) {
          if (c4r4MT === "S") {  
            setRow4Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
             ]
           )
           setC4R4VNo(2);
      } else if (c4r4MT === "M") {      
           setRow3NakedM04(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
         ]
       )
       setC4R3VNo(2);
      setC4R4VNo(2);
                         } else if (c4r4MT === "L") {  
                          setBaseNakedL04(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                            ]
                          )
                          setC4R1VNo(2);
                          setC4R2VNo(2);
                          setC4R3VNo(2);
                          setC4R4VNo(2);
                         }
        
         } else if (curX === col04X && curY === row04X && c4R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col04X && curY === row04X && c4R4VNo === 8) {
          if (c4r4MT === "S") {  
            setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
            ]
          )
          setC4R4VNo(7);
     } else if (c4r4MT === "M") {      
      setRow3NakedM04(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
        ]
      )
      setC4R3VNo(7);
     setC4R4VNo(7);
                        } else if (c4r4MT === "L") {  
                          setBaseNakedL04(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                            ]
                          )
                          setC4R1VNo(7);
                          setC4R2VNo(7);
                          setC4R3VNo(7);
                          setC4R4VNo(7);
                        }
         } else if (curX === col04X && curY === row04X && c4R4VNo === 9) {
          if (c4r4MT === "S") {  
            setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(7);
       } else if (c4r4MT === "M") {      
      setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(7);
       setC4R4VNo(7);
                          } else if (c4r4MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                          }
         } else if (curX === col04X && curY === row04X && c4R4VNo === 10) {
          if (c4r4MT === "S") {  
            setRow4Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
               ]
             )
             setC4R4VNo(7);
        } else if (c4r4MT === "M") {      
       setRow3NakedM04(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
           ]
         )
         setC4R3VNo(7);
        setC4R4VNo(7);
                           } else if (c4r4MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                           }
         } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {
          if (c4r4MT === "S") {  
            setRow4Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
               ]
             )
             setC4R4VNo(7);
        } else if (c4r4MT === "M") {      
       setRow3NakedM04(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
           ]
         )
         setC4R3VNo(7);
        setC4R4VNo(7);
                           } else if (c4r4MT === "L") {  
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(7);
                            setC4R2VNo(7);
                            setC4R3VNo(7);
                            setC4R4VNo(7);
                           }
           //5th col
         }  else if (curX === col05X && curY === row04X && c5R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col05X && curY === row04X && c5R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col05X && curY === row04X && c5R4VNo === 3) {
          if (c5r4MT === "S") {  
            setRow4Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
              ]
            )
            setC5R4VNo(1);
       } else if (c5r4MT === "M") {      
     setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(1);
       setC5R4VNo(1);
                          } else if (c5r4MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(1);
                            setC5R2VNo(1);
                            setC5R3VNo(1);
                            setC5R4VNo(1);
                          }
    
         } else if (curX === col05X && curY === row04X && c5R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col05X && curY === row04X && c5R4VNo === 5) {  
          if (c5r4MT === "S") {  
            setRow4Naked05(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
             ]
           )
           setC5R4VNo(4);
      } else if (c5r4MT === "M") {      
        setRow3NakedM05(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
         ]
       )
       setC5R3VNo(4);
      setC5R4VNo(4);
                         } else if (c5r4MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(4);
                          setC5R2VNo(4);
                          setC5R3VNo(4);
                          setC5R4VNo(4);
                         }
         } else if (curX === col05X && curY === row04X && c5R4VNo === 6) {
          if (c5r4MT === "S") {  
            setRow4Naked05(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
           ]
         )
         setC5R4VNo(2);
    } else if (c5r4MT === "M") {      
         setRow3NakedM05(
       [
         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
       ]
     )
     setC5R3VNo(2);
    setC5R4VNo(2);
                       } else if (c5r4MT === "L") {  
                        setBaseNakedL05(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                          ]
                        )
                        setC5R1VNo(2);
                        setC5R2VNo(2);
                        setC5R3VNo(2);
                        setC5R4VNo(2);
                       }
         } else if (curX === col05X && curY === row04X && c5R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col05X && curY === row04X && c5R4VNo === 8) {
          if (c5r4MT === "S") {  
            setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
            ]
          )
          setC5R4VNo(7);
     } else if (c5r4MT === "M") {      
     setRow3NakedM05(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
        ]
      )
      setC5R3VNo(7);
     setC5R4VNo(7);
                        } else if (c5r4MT === "L") {  
                          setBaseNakedL05(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                            ]
                          )
                          setC5R1VNo(7);
                          setC5R2VNo(7);
                          setC5R3VNo(7);
                          setC5R4VNo(7);
                        }
         } else if (curX === col05X && curY === row04X && c5R4VNo === 9) {
          if (c5r4MT === "S") {  
            setRow4Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
              ]
            )
            setC5R4VNo(7);
       } else if (c5r4MT === "M") {      
       setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(7);
       setC5R4VNo(7);
                          } else if (c5r4MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                          }
         } else if (curX === col05X && curY === row04X && c5R4VNo === 10) {
          if (c5r4MT === "S") {  
            setRow4Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
               ]
             )
             setC5R4VNo(7);
        } else if (c5r4MT === "M") {      
        setRow3NakedM05(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
           ]
         )
         setC5R3VNo(7);
        setC5R4VNo(7);
                           } else if (c5r4MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                           }
         } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {
          if (c5r4MT === "S") {  
            setRow4Naked05(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
               ]
             )
             setC5R4VNo(7);
        } else if (c5r4MT === "M") {      
       setRow3NakedM05(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
           ]
         )
         setC5R3VNo(7);
        setC5R4VNo(7);
                           } else if (c5r4MT === "L") {  
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(7);
                            setC5R2VNo(7);
                            setC5R3VNo(7);
                            setC5R4VNo(7);
                           }
           //6th col
         }  else if (curX === col06X && curY === row04X && c6R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col06X && curY === row04X && c6R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col06X && curY === row04X && c6R4VNo === 3) {
          if (c6r4MT === "S") {  
            setRow4Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
               ]
             )
             setC3R4VNo(1);
        } else if (c6r4MT === "M") {      
      setRow3NakedM06(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
           ]
         )
         setC3R3VNo(1);
        setC6R4VNo(1);
                           } else if (c6r4MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(1);
                            setC6R2VNo(1);
                            setC6R3VNo(1);
                            setC6R4VNo(1);
                           }
    
         } else if (curX === col06X && curY === row04X && c6R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col06X && curY === row04X && c6R4VNo === 5) {  
          if (c6r4MT === "S") {  
            setRow4Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
               ]
             )
             setC6R4VNo(4);
        } else if (c6r4MT === "M") {      
         setRow3NakedM06(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
           ]
         )
         setC6R3VNo(4);
        setC6R4VNo(4);
                           } else if (c6r4MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(4);
                            setC6R2VNo(4);
                            setC6R3VNo(4);
                            setC6R4VNo(4);
                           }
         } else if (curX === col06X && curY === row04X && c6R4VNo === 6) {
          if (c6r4MT === "S") {  
            setRow4Naked06(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
           ]
         )
         setC6R4VNo(2);
    } else if (c6r4MT === "M") {      
         setRow3NakedM06(
       [
         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
       ]
     )
     setC6R3VNo(2);
    setC6R4VNo(2);
                       } else if (c6r4MT === "L") {  
                        setBaseNakedL06(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                          ]
                        )
                        setC6R1VNo(2);
                        setC6R2VNo(2);
                        setC6R3VNo(2);
                        setC6R4VNo(2);
                       }
         } else if (curX === col06X && curY === row04X && c6R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col06X && curY === row04X && c6R4VNo === 8) {
          if (c6r4MT === "S") {  
            setRow4Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
               ]
             )
             setC6R4VNo(7);
        } else if (c6r4MT === "M") {      
          setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(7);
         setC6R4VNo(7);
                           } else if (c6r4MT === "L") {  
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(7);
                            setC6R2VNo(7);
                            setC6R3VNo(7);
                            setC6R4VNo(7);
                           }
         } else if (curX === col06X && curY === row04X && c6R4VNo === 9) {
          if (c6r4MT === "S") {  
            setRow4Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
                ]
              )
              setC6R4VNo(7);
         } else if (c6r4MT === "M") {      
        setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(7);
         setC6R4VNo(7);
                            } else if (c6r4MT === "L") {  
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(7);
                              setC6R2VNo(7);
                              setC6R3VNo(7);
                              setC6R4VNo(7);
                            }
         } else if (curX === col06X && curY === row04X && c6R4VNo === 10) {
          if (c6r4MT === "S") {  
                            setRow4Naked06(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
                          ]
                        )
                        setC6R4VNo(7);
                } else if (c6r4MT === "M") {      
                setRow3NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
                  ]
                )
                setC6R3VNo(7);
                setC6R4VNo(7);
                   } else if (c6r4MT === "L") {  
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(7);
                    setC6R2VNo(7);
                    setC6R3VNo(7);
                    setC6R4VNo(7);
                   }
         } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {
          if (c6r4MT === "S") {  
            setRow4Naked06(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
                  ]
                )
                setC6R4VNo(7);
           } else if (c6r4MT === "M") {      
         setRow3NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(7);
           setC6R4VNo(7);
                              } else if (c6r4MT === "L") {  
                                setBaseNakedL06(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                  ]
                                )
                                setC6R1VNo(7);
                                setC6R2VNo(7);
                                setC6R3VNo(7);
                                setC6R4VNo(7);
                              }
           //7th col
         }  else if (curX === col07X && curY === row04X && c7R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col07X && curY === row04X && c7R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col07X && curY === row04X && c7R4VNo === 3) {
          if (c7r4MT === "S") {  
            setRow4Naked07(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
                  ]
                )
                setC7R4VNo(1);
           } else if (c7r4MT === "M") {      
         setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(1);
           setC7R4VNo(1);
                              } else if (c7r4MT === "L") {  
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(1);
                                setC7R2VNo(1);
                                setC7R3VNo(1);
                                setC7R4VNo(1);
                              }
    
         } else if (curX === col07X && curY === row04X && c7R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col07X && curY === row04X && c7R4VNo === 5) {  
          if (c7r4MT === "S") {  
            setRow4Naked07(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                 ]
               )
               setC7R4VNo(4);
          } else if (c7r4MT === "M") {      
           setRow3NakedM07(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
             ]
           )
           setC7R3VNo(4);
          setC7R4VNo(4);
                             } else if (c7r4MT === "L") {  
                              setBaseNakedL07(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                ]
                              )
                              setC7R1VNo(4);
                              setC7R2VNo(4);
                              setC7R3VNo(4);
                              setC7R4VNo(4);
                             }
         } else if (curX === col07X && curY === row04X && c7R4VNo === 6) {
          if (c7r4MT === "S") {  
            setRow4Naked07(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
                  ]
                )
                setC7R4VNo(2);
           } else if (c7r4MT === "M") {      
              setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(2);
           setC7R4VNo(2);
                              } else if (c7r4MT === "L") {  
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(2);
                                setC7R2VNo(2);
                                setC7R3VNo(2);
                                setC7R4VNo(2);
                              }
         } else if (curX === col07X && curY === row04X && c7R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col07X && curY === row04X && c7R4VNo === 8) {
          if (c7r4MT === "S") {  
            setRow4Naked07(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                   ]
                 )
                 setC7R4VNo(7);
            } else if (c7r4MT === "M") {      
            setRow3NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(7);
            setC7R4VNo(7);
                               } else if (c7r4MT === "L") {  
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(7);
                                setC7R2VNo(7);
                                setC7R3VNo(7);
                                setC7R4VNo(7);
                               }
         } else if (curX === col07X && curY === row04X && c7R4VNo === 9) {
          if (c7r4MT === "S") {  
            setRow4Naked07(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                  ]
                )
                setC7R4VNo(7);
           } else if (c7r4MT === "M") {      
          setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(7);
           setC7R4VNo(7);
                              } else if (c7r4MT === "L") {  
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(7);
                                setC7R2VNo(7);
                                setC7R3VNo(7);
                                setC7R4VNo(7);
                              }
         } else if (curX === col07X && curY === row04X && c7R4VNo === 10) {
          if (c7r4MT === "S") {  
            setRow4Naked07(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                  ]
                )
                setC7R4VNo(7);
           } else if (c7r4MT === "M") {      
         setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(7);
           setC7R4VNo(7);
                              } else if (c7r4MT === "L") {  
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(7);
                                setC7R2VNo(7);
                                setC7R3VNo(7);
                                setC7R4VNo(7);
                              }
         } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {
          if (c7r4MT === "S") {  
            setRow4Naked07(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                  ]
                )
                setC7R4VNo(7);
           } else if (c7r4MT === "M") {      
         setRow3NakedM07(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(7);
           setC7R4VNo(7);
                              } else if (c7r4MT === "L") {  
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(7);
                                setC7R2VNo(7);
                                setC7R3VNo(7);
                                setC7R4VNo(7);
                              }
           //8th col
         }  else if (curX === col08X && curY === row04X && c8R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col08X && curY === row04X && c8R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col08X && curY === row04X && c8R4VNo === 3) {
          if (c8r4MT === "S") {  
            setRow4Naked08(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
                  ]
                )
                setC8R4VNo(1);
           } else if (c8r4MT === "M") {      
         setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(1);
           setC8R4VNo(1);
                              } else if (c8r4MT === "L") {  
                                setBaseNakedL08(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                  ]
                                )
                                setC8R1VNo(1);
                                setC8R2VNo(1);
                                setC8R3VNo(1);
                                setC8R4VNo(1);
                              }
    
         } else if (curX === col08X && curY === row04X && c8R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col08X && curY === row04X && c8R4VNo === 5) {  
          if (c8r4MT === "S") {  
            setRow4Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
                ]
              )
              setC8R4VNo(4);
         } else if (c8r4MT === "M") {      
         setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(4);
         setC8R4VNo(4);
                            } else if (c8r4MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(4);
                              setC8R2VNo(4);
                              setC8R3VNo(4);
                              setC8R4VNo(4);
                            }
         } else if (curX === col08X && curY === row04X && c8R4VNo === 6) {
          if (c8r4MT === "S") {  
            setRow4Naked08(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
             ]
           )
           setC8R4VNo(2);
      } else if (c8r4MT === "M") {      
        setRow3NakedM08(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
         ]
       )
       setC8R3VNo(2);
      setC8R4VNo(2);
                         } else if (c8r4MT === "L") {  
                          setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                          setC8R1VNo(2);
                          setC8R2VNo(2);
                          setC8R3VNo(2);
                          setC8R4VNo(2);
                         }
         } else if (curX === col08X && curY === row04X && c8R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col08X && curY === row04X && c8R4VNo === 8) {
          if (c8r4MT === "S") {  
            setRow4Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
               ]
             )
             setC8R4VNo(7);
        } else if (c8r4MT === "M") {      
      setRow3NakedM08(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
           ]
         )
         setC8R3VNo(7);
        setC8R4VNo(7);
                           } else if (c8r4MT === "L") {  
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(7);
                            setC8R2VNo(7);
                            setC8R3VNo(7);
                            setC8R4VNo(7);
                           }
         } else if (curX === col08X && curY === row04X && c8R4VNo === 9) {
          if (c8r4MT === "S") {  
            setRow4Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
                ]
              )
              setC8R4VNo(7);
         } else if (c8r4MT === "M") {      
       setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(7);
         setC8R4VNo(7);
                            } else if (c8r4MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(7);
                              setC8R2VNo(7);
                              setC8R3VNo(7);
                              setC8R4VNo(7);
                            }
         } else if (curX === col08X && curY === row04X && c8R4VNo === 10) {
          if (c8r4MT === "S") {  
            setRow4Naked08(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
                 ]
               )
               setC1R3VNo(7);
          } else if (c8r4MT === "M") {      
        setRow3NakedM08(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(7);
          setC8R4VNo(7);
                             } else if (c8r4MT === "L") {  
                              setBaseNakedL08(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                ]
                              )
                              setC8R1VNo(7);
                              setC8R2VNo(7);
                              setC8R3VNo(7);
                              setC8R4VNo(7);
                             }
         } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {
          if (c8r4MT === "S") {  
            setRow4Naked08(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
                  ]
                )
                setC8R4VNo(7);
           } else if (c8r4MT === "M") {      
         setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(7);
           setC8R4VNo(7);
                              } else if (c8r4MT === "L") {  
                                setBaseNakedL08(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                  ]
                                )
                                setC8R1VNo(7);
                                setC8R2VNo(7);
                                setC8R3VNo(7);
                                setC8R4VNo(7);
                              }
           //9th col
         }  else if (curX === col09X && curY === row04X && c9R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col09X && curY === row04X && c9R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col09X && curY === row04X && c9R4VNo === 3) {
          if (c9r4MT === "S") {  
            setRow4Naked09(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
                   ]
                 )
                 setC9R4VNo(1);
            } else if (c9r4MT === "M") {      
          setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(1);
            setC9R4VNo(1);
                               } else if (c9r4MT === "L") {  
                                setBaseNakedL09(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                  ]
                                )
                                setC9R1VNo(1);
                                setC9R2VNo(1);
                                setC9R3VNo(1);
                                setC9R4VNo(1);
                               }
    
         } else if (curX === col09X && curY === row04X && c9R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col09X && curY === row04X && c9R4VNo === 5) {  
          if (c9r4MT === "S") {  
            setRow4Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
                 ]
               )
               setC9R4VNo(4);
          } else if (c9r4MT === "M") {      
           setRow3NakedM09(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
             ]
           )
           setC9R3VNo(4);
          setC9R4VNo(4);
                             } else if (c9r4MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(4);
                              setC9R2VNo(4);
                              setC9R3VNo(4);
                              setC9R4VNo(4);
                             }
         } else if (curX === col09X && curY === row04X && c9R4VNo === 6) {
          if (c9r4MT === "S") {  
            setRow4Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
               ]
             )
             setC9R4VNo(2);
        } else if (c9r4MT === "M") {      
           setRow3NakedM09(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
           ]
         )
         setC9R3VNo(2);
        setC9R4VNo(2);
                           } else if (c9r4MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(2);
                            setC9R2VNo(2);
                            setC9R3VNo(2);
                            setC9R4VNo(2);
                           }
         } else if (curX === col09X && curY === row04X && c9R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col09X && curY === row04X && c9R4VNo === 8) {
          if (c9r4MT === "S") {  
            setRow4Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
              ]
            )
            setC9R4VNo(7);
       } else if (c9r4MT === "M") {      
     setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(7);
       setC9R4VNo(7);
                          } else if (c9r4MT === "L") {  
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(7);
                            setC9R2VNo(7);
                            setC9R3VNo(7);
                            setC9R4VNo(7);
                          }
         } else if (curX === col09X && curY === row04X && c9R4VNo === 9) {
          if (c9r4MT === "S") {  
            setRow4Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
                ]
              )
              setC9R4VNo(7);
         } else if (c9r4MT === "M") {      
       setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(7);
         setC9R4VNo(7);
                            } else if (c9r4MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(7);
                              setC9R2VNo(7);
                              setC9R3VNo(7);
                              setC9R4VNo(7);
                            }
         } else if (curX === col09X && curY === row04X && c9R4VNo === 10) {
          if (c9r4MT === "S") {  
            setRow4Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
                ]
              )
              setC9R4VNo(7);
         } else if (c9r4MT === "M") {      
       setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(7);
         setC9R4VNo(7);
                            } else if (c9r4MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(7);
                              setC9R2VNo(7);
                              setC9R3VNo(7);
                              setC9R4VNo(7);
                            }
         } else if (curX === col09X && curY === row04X && c9R4VNo === 11) {
          if (c9r4MT === "S") {  
            setRow4Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
                ]
              )
              setC9R4VNo(7);
         } else if (c9r4MT === "M") {      
       setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(7);
         setC9R4VNo(7);
                            } else if (c9r4MT === "L") {  
                              setBaseNakedL09(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                              )
                              setC9R1VNo(7);
                              setC9R2VNo(7);
                              setC9R3VNo(7);
                              setC9R4VNo(7);
                            }
           //10th col
         }  else if (curX === col10X && curY === row04X && c10R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col10X && curY === row04X && c10R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col10X && curY === row04X && c10R4VNo === 3) {
          if (c10r4MT === "S") {  
            setRow4Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
               ]
             )
             setC10R4VNo(1);
        } else if (c10r4MT === "M") {      
        setRow3NakedM10(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
           ]
         )
         setC10R3VNo(1);
        setC10R4VNo(1);
                           } else if (c10r4MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(1);
                            setC10R2VNo(1);
                            setC10R3VNo(1);
                            setC10R4VNo(1);
                           }
    
         } else if (curX === col10X && curY === row04X && c10R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col10X && curY === row04X && c10R4VNo === 5) {  
          if (c10r4MT === "S") {  
            setRow4Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
               ]
             )
             setC10R4VNo(4);
        } else if (c10r4MT === "M") {      
         setRow3NakedM10(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
           ]
         )
         setC10R3VNo(4);
        setC10R4VNo(4);
                           } else if (c10r4MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(4);
                            setC10R2VNo(4);
                            setC10R3VNo(4);
                            setC10R4VNo(4);
                           }
         } else if (curX === col10X && curY === row04X && c10R4VNo === 6) {
          if (c10r4MT === "S") {  
            setRow4Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
              ]
            )
            setC10R4VNo(2);
       } else if (c10r4MT === "M") {      
           setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(2);
       setC10R4VNo(2);
                          } else if (c10r4MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(2);
                            setC10R2VNo(2);
                            setC10R3VNo(2);
                            setC10R4VNo(2);
                          }
         } else if (curX === col10X && curY === row04X && c10R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col10X && curY === row04X && c10R4VNo === 8) {
          if (c10r4MT === "S") {  
            setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
            ]
          )
          setC10R4VNo(7);
     } else if (c10r4MT === "M") {      
   setRow3NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
        ]
      )
      setC10R3VNo(7);
     setC10R4VNo(7);
                        } else if (c10r4MT === "L") {  
                          setBaseNakedL10(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(7);
                          setC10R2VNo(7);
                          setC10R3VNo(7);
                          setC10R4VNo(7);
                        }
         } else if (curX === col10X && curY === row04X && c10R4VNo === 9) {
          if (c10r4MT === "S") {  
            setRow4Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
              ]
            )
            setC10R4VNo(7);
       } else if (c10r4MT === "M") {      
     setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(7);
       setC10R4VNo(7);
                          } else if (c10r4MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                          }
         } else if (curX === col10X && curY === row04X && c10R4VNo === 10) {
          if (c10r4MT === "S") {  
            setRow4Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
              ]
            )
            setC10R4VNo(7);
       } else if (c10r4MT === "M") {      
     setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(7);
       setC10R4VNo(7);
                          } else if (c10r4MT === "L") {  
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                          }
         } else if (curX === col10X && curY === row04X && c10R4VNo === 11) {
          if (c10r4MT === "S") {  
            setRow4Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
                ]
              )
              setC10R4VNo(7);
         } else if (c10r4MT === "M") {      
       setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(7);
         setC10R4VNo(7);
                            } else if (c10r4MT === "L") {  
                              setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                              setC10R1VNo(7);
                              setC10R2VNo(7);
                              setC10R3VNo(7);
                              setC10R4VNo(7);
                            }
        }  else if (curX === col11X && curY === row04X && c11R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col11X && curY === row04X && c11R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col11X && curY === row04X && c11R4VNo === 3) {
          if (c11r4MT === "S") {  
            setRow4Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
                ]
              )
              setC11R4VNo(1);
         } else if (c11r4MT === "M") {      
       setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(1);
         setC11R4VNo(1);
                            } else if (c11r4MT === "L") {  
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(1);
                              setC11R2VNo(1);
                              setC11R3VNo(1);
                              setC11R4VNo(1);
                            }
    
         } else if (curX === col11X && curY === row04X && c11R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col11X && curY === row04X && c11R4VNo === 5) {  
          if (c11r4MT === "S") {  
            setRow4Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
              ]
            )
            setC11R4VNo(4);
       } else if (c11r4MT === "M") {      
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(4);
       setC11R4VNo(4);
                          } else if (c11r4MT === "L") {  
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(4);
                            setC11R2VNo(4);
                            setC11R3VNo(4);
                            setC11R4VNo(4);
                          }
         } else if (curX === col11X && curY === row04X && c11R4VNo === 6) {
          if (c11r4MT === "S") {  
            setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
            ]
          )
          setC11R4VNo(2);
     } else if (c11r4MT === "M") {      
       setRow3NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
        ]
      )
      setC11R3VNo(2);
     setC11R4VNo(2);
                        } else if (c11r4MT === "L") {  
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(2);
                          setC11R2VNo(2);
                          setC11R3VNo(2);
                          setC11R4VNo(2);
                        }
         } else if (curX === col11X && curY === row04X && c11R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col11X && curY === row04X && c11R4VNo === 8) {
          if (c11r4MT === "S") {  
            setRow4Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
                ]
              )
              setC11R4VNo(7);
         } else if (c11r4MT === "M") {      
       setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(7);
         setC11R4VNo(7);
                            } else if (c11r4MT === "L") {  
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(7);
                              setC11R2VNo(7);
                              setC11R3VNo(7);
                              setC11R4VNo(7);
                            }
         } else if (curX === col11X && curY === row04X && c11R4VNo === 9) {
          if (c11r4MT === "S") {  
            setRow4Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
                 ]
               )
               setC11R4VNo(7);
          } else if (c11r4MT === "M") {      
        setRow3NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
             ]
           )
           setC11R3VNo(7);
          setC11R4VNo(7);
                             } else if (c11r4MT === "L") {  
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(7);
                              setC11R2VNo(7);
                              setC11R3VNo(7);
                              setC11R4VNo(7);
                             }
         } else if (curX === col11X && curY === row04X && c11R4VNo === 10) {
          if (c11r4MT === "S") {  
            setRow4Naked11(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
                   ]
                 )
                 setC11R4VNo(7);
            } else if (c11r4MT === "M") {      
          setRow3NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(7);
            setC11R4VNo(7);
                               } else if (c11r4MT === "L") {  
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(7);
                                setC11R2VNo(7);
                                setC11R3VNo(7);
                                setC11R4VNo(7);
                               }
         } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {
          if (c11r4MT === "S") {  
            setRow4Naked11(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
                    ]
                  )
                  setC11R4VNo(7);
             } else if (c11r4MT === "M") {      
           setRow3NakedM11(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(7);
             setC11R4VNo(7);
                                } else if (c11r4MT === "L") {  
                                  setBaseNakedL11(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                    ]
                                  )
                                  setC11R1VNo(7);
                                  setC11R2VNo(7);
                                  setC11R3VNo(7);
                                  setC11R4VNo(7);
                                }
           //12th col
         }  else if (curX === col12X && curY === row04X && c12R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col12X && curY === row04X && c12R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col12X && curY === row04X && c12R4VNo === 3) {
          if (c12r4MT === "S") {  
            setRow4Naked12(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
                    ]
                  )
                  setC12R4VNo(1);
             } else if (c12r4MT === "M") {      
           setRow3NakedM12(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(1);
             setC12R4VNo(1);
                                } else if (c12r4MT === "L") {  
                                  setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                                  setC12R1VNo(1);
                                  setC12R2VNo(1);
                                  setC12R3VNo(1);
                                  setC12R4VNo(1);
                                }
    
         } else if (curX === col12X && curY === row04X && c12R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col12X && curY === row04X && c12R4VNo === 5) {  
          if (c12r4MT === "S") {  
            setRow4Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                 ]
               )
               setC12R4VNo(4);
          } else if (c12r4MT === "M") {      
            setRow3NakedM12(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
             ]
           )
           setC12R3VNo(4);
          setC12R4VNo(4);
                             } else if (c12r4MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(4);
                              setC12R2VNo(4);
                              setC12R3VNo(4);
                              setC12R4VNo(4);
                             }
         } else if (curX === col12X && curY === row04X && c12R4VNo === 6) {
          if (c12r4MT === "S") {  
            setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(2);
         } else if (c12r4MT === "M") {      
               setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(2);
         setC12R4VNo(2);
                            } else if (c12r4MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(2);
                              setC12R2VNo(2);
                              setC12R3VNo(2);
                              setC12R4VNo(2);
                            }
         } else if (curX === col12X && curY === row04X && c12R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col12X && curY === row04X && c12R4VNo === 8) {
          if (c12r4MT === "S") {  
            setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(7);
         } else if (c12r4MT === "M") {      
       setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(7);
         setC12R4VNo(7);
                            } else if (c12r4MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(7);
                              setC12R2VNo(7);
                              setC12R3VNo(7);
                              setC12R4VNo(7);
                            }
         } else if (curX === col12X && curY === row04X && c12R4VNo === 9) {
          if (c12r4MT === "S") {  
            setRow4Naked12(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                 ]
               )
               setC12R4VNo(7);
          } else if (c12r4MT === "M") {      
        setRow3NakedM12(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
             ]
           )
           setC12R3VNo(7);
          setC12R4VNo(7);
                             } else if (c12r4MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(7);
                              setC12R2VNo(7);
                              setC12R3VNo(7);
                              setC12R4VNo(7);
                             }
         } else if (curX === col12X && curY === row04X && c12R4VNo === 10) {
          if (c12r4MT === "S") {  
            setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(7);
         } else if (c12r4MT === "M") {      
       setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(7);
         setC12R4VNo(7);
                            } else if (c12r4MT === "L") {  
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(7);
                              setC12R2VNo(7);
                              setC12R3VNo(7);
                              setC12R4VNo(7);
                            }
         } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {
          if (c12r4MT === "S") {  
            setRow4Naked12(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                  ]
                )
                setC12R4VNo(7);
           } else if (c12r4MT === "M") {      
         setRow3NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(7);
           setC12R4VNo(7);
                              } else if (c12r4MT === "L") {  
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(7);
                                setC12R2VNo(7);
                                setC12R3VNo(7);
                                setC12R4VNo(7);
                              }
           //13th col
         }  else if (curX === col13X && curY === row04X && c13R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col13X && curY === row04X && c13R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col13X && curY === row04X && c13R4VNo === 3) {
          if (c13r4MT === "S") {  
            setRow4Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
                  ]
                )
                setC13R1VNo(1);
           } else if (c13r4MT === "M") {      
         setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(1);;
           setC13R4VNo(1);
                              } else if (c13r4MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(1);
                                setC13R2VNo(1);
                                setC13R3VNo(1);
                                setC13R4VNo(1);
                              }
    
         } else if (curX === col13X && curY === row04X && c13R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col13X && curY === row04X && c13R4VNo === 5) {  
          if (c13r4MT === "S") {  
            setRow4Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                 ]
               )
               setC13R1VNo(4);
          } else if (c13r4MT === "M") {      
               setRow3NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
             ]
           )
           setC13R3VNo(4);
          setC13R4VNo(4);
                             } else if (c13r4MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(4);
                              setC13R2VNo(4);
                              setC13R3VNo(4);
                              setC13R4VNo(4);
                             }
         } else if (curX === col13X && curY === row04X && c13R4VNo === 6) {
          if (c13r4MT === "S") {  
            setRow4Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
              ]
            )
            setC13R1VNo(2);
       } else if (c13r4MT === "M") {      
            setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(2);
       setC13R4VNo(2);
                          } else if (c13r4MT === "L") {  
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(2);
                            setC13R2VNo(2);
                            setC13R3VNo(2);
                            setC13R4VNo(2);
                          }
         } else if (curX === col13X && curY === row04X && c13R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col13X && curY === row04X && c13R4VNo === 8) {
          if (c13r4MT === "S") {  
            setRow4Naked13(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                 ]
               )
               setC13R1VNo(7);
          } else if (c13r4MT === "M") {      
        setRow3NakedM13(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
             ]
           )
           setC13R3VNo(7);
          setC13R4VNo(7);
                             } else if (c13r4MT === "L") {  
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(7);
                              setC13R2VNo(7);
                              setC13R3VNo(7);
                              setC13R4VNo(7);
                             }
         } else if (curX === col13X && curY === row04X && c13R4VNo === 9) {
          if (c13r4MT === "S") {  
            setRow4Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                  ]
                )
                setC13R1VNo(7);
           } else if (c13r4MT === "M") {      
         setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(7);
           setC13R4VNo(7);
                              } else if (c13r4MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(7);
                                setC13R2VNo(7);
                                setC13R3VNo(7);
                                setC13R4VNo(7);
                              }
         } else if (curX === col13X && curY === row04X && c13R4VNo === 10) {
          if (c13r4MT === "S") {  
            setRow4Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                  ]
                )
                setC13R1VNo(7);
           } else if (c13r4MT === "M") {      
         setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(7);
           setC13R4VNo(7);
                              } else if (c13r4MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(7);
                                setC13R2VNo(7);
                                setC13R3VNo(7);
                                setC13R4VNo(7);
                              }
         } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {
          if (c13r4MT === "S") {  
            setRow4Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                  ]
                )
                setC13R1VNo(7);
           } else if (c13r4MT === "M") {      
         setRow3NakedM13(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(7);
           setC13R4VNo(7);
                              } else if (c13r4MT === "L") {  
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(7);
                                setC13R2VNo(7);
                                setC13R3VNo(7);
                                setC13R4VNo(7);
                              }
           //14th col
         }  else if (curX === col14X && curY === row04X && c14R4VNo === 1) {
          alert("Already removed");
         } else if (curX === col14X && curY === row04X && c14R4VNo === 2) {
              alert("Already removed");      
         } else if (curX === col14X && curY === row04X && c14R4VNo === 3) {
          if (c14r4MT === "S") {  
            setRow4Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
                  ]
                )
                setC14R4VNo(1);
           } else if (c14r4MT === "M") {      
         setRow3NakedM14(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(1);
           setC14R4VNo(1);
                              } else if (c14r4MT === "L") {  
                                setBaseNakedL14(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                  ]
                                )
                                setC14R1VNo(1);
                                setC14R2VNo(1);
                                setC14R3VNo(1);
                                setC14R4VNo(1);
                              }
    
         } else if (curX === col14X && curY === row04X && c14R4VNo === 4) {
          alert("Already removed");
         } else if (curX === col14X && curY === row04X && c14R4VNo === 5) {  
          if (c14r4MT === "S") {  
            setRow4Naked14(
         [
           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
         ]
       )
       setC14R4VNo(4);
	} else if (c14r4MT === "M") {      
       setRow3NakedM14(
     [
       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
     ]
   )
   setC14R3VNo(4);
	setC14R4VNo(4);
                     } else if (c14r4MT === "L") {  
                      setBaseNakedL14(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(4);
                      setC14R2VNo(4);
                      setC14R3VNo(4);
                      setC14R4VNo(4);
                     }
         } else if (curX === col14X && curY === row04X && c14R4VNo === 6) {
          if (c14r4MT === "S") {  
            setRow4Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
              ]
            )
            setC14R4VNo(2);
       } else if (c14r4MT === "M") {      
            setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(2);
       setC14R4VNo(2);
                          } else if (c14r4MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(2);
                            setC14R2VNo(2);
                            setC14R3VNo(2);
                            setC14R4VNo(2);
                          }
         } else if (curX === col14X && curY === row04X && c14R4VNo === 7) {
          alert("Already removed");
          
         } else if (curX === col14X && curY === row04X && c14R4VNo === 8) {
          if (c14r4MT === "S") {  
            setRow4Naked14(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
               ]
             )
             setC14R4VNo(7);
        } else if (c14r4MT === "M") {      
      setRow3NakedM14(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
           ]
         )
         setC14R3VNo(7);
        setC14R4VNo(7);
                           } else if (c14r4MT === "L") {  
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(7);
                            setC14R2VNo(7);
                            setC14R3VNo(7);
                            setC14R4VNo(7);
                           }
         } else if (curX === col14X && curY === row04X && c14R4VNo === 9) {
          if (c14r4MT === "S") {      
            setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          )
          setC14R4VNo(7);
            } else if (c14r4MT === "M") {      
          setRow3NakedM14(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(7);
            setC14R4VNo(7);
                        } else if (c14r4MT === "L") {  
                          setBaseNakedL14(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(7);
                          setC14R2VNo(7);
                          setC14R3VNo(7);
                          setC14R4VNo(7);
                        }
         } else if (curX === col14X && curY === row04X && c14R4VNo === 10) {
          if (c14r4MT === "S") {      
            setRow4Naked14(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
                 ]
               )
               setC14R4VNo(7);
          } else if (c14r4MT === "M") {      
        setRow3NakedM14(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
             ]
           )
           setC14R3VNo(7);
          setC14R4VNo(7);
                             } else if (c14r4MT === "L") {  
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(7);
                              setC14R2VNo(7);
                              setC14R3VNo(7);
                              setC14R4VNo(7);
                             }
         } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
          if (c14r4MT === "S") {      
            setRow4Naked14(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
                    ]
                  )
                  setC14R4VNo(7);    
           setRow3NakedM14(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(7);
             setC14R4VNo(7);
                                } else if (c14r4MT === "L") {  
                                  setBaseNakedL14(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                    ]
                                  )
                                  setC14R1VNo(7);
                                  setC14R2VNo(7);
                                  setC14R3VNo(7);
                                  setC14R4VNo(7);
                                }
           
         }
}

// Adding Doors

// Adding Small Doors

const addSmallDoors = (e)=> {
  
  if (curX === col01X && curY === row01X) {
    if (c1r1MT === "S") {
        if (c1R1VNo === 11) {
          return;
        }
      setBaseNaked01(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
        ]
      )
      setC1R1VNo(11);
      setC1r1MT("S");
      noHandleCount = noHandleCount + 1;

    } else if (c1r1MT === "M") {
      setBaseNakedM01([]);
      if (c1R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked01(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
        ]
      )
      setC1R1VNo(11);
      setC1r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked01(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
        ]
      )
      setC1R2VNo(11);
      setC1r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c1r1MT === "L") {
      setBaseNakedL01([]);
      if (c1R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked01(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
        ]
      )
      setC1R1VNo(11);
      setC1r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked01(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
        ]
      )
      setC1R2VNo(11);
      setC1r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(11);
        setC1r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked01(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
           ]
         )
         setC1R4VNo(11);
         setC1r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
    
  } else if (curX === col02X && curY === row01X) {
    if (c2r1MT === "S") {
      if (c2R1VNo === 11) {
        return;
      }
      setBaseNaked02(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
        ]
      )
      setC2R1VNo(11);
      setC2r1MT("S");
      noHandleCount = noHandleCount + 1;

    } else if (c2r1MT === "M") {
      setBaseNakedM02([]);
      if (c2R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked02(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
        ]
      )
      setC2R1VNo(11);
      setC2r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked02(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
        ]
      )
      setC2R2VNo(11);
      setC2r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c2r1MT === "L") {
      setBaseNakedL02([]);
      if (c2R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked02(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
        ]
      )
      setC2R1VNo(11);
      setC2r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked02(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
        ]
      )
      setC2R2VNo(11);
      setC2r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(11);
        setC2r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked02(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
           ]
         )
         setC2R4VNo(11);
         setC2r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  
  } else if (curX === col03X && curY === row01X) {
    if (c3r1MT === "S") {
      if (c3R1VNo === 11) {
        return;
      }
      setBaseNaked03(
        [
         < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />        
        ]
      )
      setC3R1VNo(11);
      setC3r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c3r1MT === "M") {
      setBaseNakedM03([]);
      if (c3R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked03(
        [
         < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />        
        ]
      )
      setC3R1VNo(11);
      setC3r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked03(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
        ]
      )
      setC3R2VNo(11);
      setC3r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c3r1MT === "L") {
      setBaseNakedL03([]);
      if (c3R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked03(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
        ]
      )
      setC3R1VNo(11);
      setC3r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked03(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
        ]
      )
      setC3R2VNo(11);
      setC3r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(11);
        setC3r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked03(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
           ]
         )
         setC3R4VNo(11);
         setC3r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  } else if (curX === col04X && curY === row01X) {
    if (c4r1MT === "S") {
      if (c4R1VNo === 11) {
        return;
      }
      setBaseNaked04(
        [
        < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />          
      ]
      )
      setC4R1VNo(11);
      setC4r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c4r1MT === "M") {
      setBaseNakedM04([]);
      if (c4R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked04(
        [
        < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />          
      ]
      )
      setC4R1VNo(11);
      setC4r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked04(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
        ]
      )
      setC4R2VNo(11);
      setC4r2MT("S");
      noHandleCount = noHandleCount + 1;

    } else if (c4r1MT === "L") {
      setBaseNakedL04([]);
      if (c4R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked04(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
        ]
      )
      setC4R1VNo(11);
      setC4r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked04(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
        ]
      )
      setC4R2VNo(11);
      setC4r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(11);
        setC4r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked04(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
           ]
         )
         setC4R4VNo(11);
         setC4r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
      
  } else if (curX === col05X && curY === row01X) {
    if (c5r1MT === "S") {
      if (c5R1VNo === 11) {
        return;
      }
      setBaseNaked05(
        [
        < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />         
       ]
      )
      setC5R1VNo(11);
      setC5r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c5r1MT === "M") {
      setBaseNakedM05([]);
      if (c5R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked05(
        [
        < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />         
       ]
      )
      setC5R1VNo(11);
      setC5r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked05(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
        ]
      )
      setC5R2VNo(11);
      setC5r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c5r1MT === "L") {
      setBaseNakedL05([]);
      if (c5R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked05(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
        ]
      )
      setC5R1VNo(11);
      setC5r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked05(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
        ]
      )
      setC5R2VNo(11);
      setC5r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(11);
        setC5r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked05(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
           ]
         )
         setC5R4VNo(11);
         setC5r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
    
  } else if (curX === col06X && curY === row01X) {
    if (c6r1MT === "S") {
      if (c6R1VNo === 11) {
        return;
      }
      setBaseNaked06(
        [
        < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />        
        ]
      )
      setC6R1VNo(11);
      setC6r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c6r1MT === "M") {
      setBaseNakedM06([]);
      if (c6R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked06(
        [
        < Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />        
        ]
      )
      setC6R1VNo(11);
      setC6r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked06(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
        ]
      )
      setC6R2VNo(11);
      setC6r2MT("S");
      noHandleCount = noHandleCount + 1;

    } else if (c6r1MT === "L") {
      setBaseNakedL06([]);
      if (c6R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked06(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
        ]
      )
      setC6R1VNo(11);
      setC6r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked06(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
        ]
      )
      setC6R2VNo(11);
      setC6r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(11);
        setC6r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked06(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
           ]
         )
         setC6R4VNo(11);
         setC6r4MT("S");
         noHandleCount = noHandleCount + 1;
    }

  } else if (curX === col07X && curY === row01X) {
    if (c7r1MT === "S") {
      if (c7R1VNo === 11) {
        return;
      }
      setBaseNaked07(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
        ]
      )
    setC7R1VNo(11);
    setC7r1MT("S");
    noHandleCount = noHandleCount + 1;

    } else if (c7r1MT === "M") {
      setBaseNakedM07([]);
      if (c7R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked07(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
        ]
      )
    setC7R1VNo(11);
    setC7r1MT("S");
    noHandleCount = noHandleCount + 1;
    setRow2Naked07(
      [
        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
      ]
    )
    setC7R2VNo(11);
    setC7r2MT("S");
    noHandleCount = noHandleCount + 1;
    } else if (c7r1MT === "L") {
      setBaseNakedL07([]);
      if (c7R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked07(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
        ]
      )
      setC7R1VNo(11);
      setC7r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked07(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
        ]
      )
      setC7R2VNo(11);
      setC7r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(11);
        setC7r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked07(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
           ]
         )
         setC7R4VNo(11);
         setC7r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
   
  } else if (curX === col08X && curY === row01X) {
    if (c8r1MT === "S") {
      if (c8R1VNo === 11) {
        return;
      }
      setBaseNaked08(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
        ]
      )
      setC8R1VNo(11);
      setC8r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c8r1MT === "M") {
      setBaseNakedM08([]);
      if (c8R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked08(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
        ]
      )
      setC8R1VNo(11);
      setC8r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked08(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
        ]
      )
      setC8R2VNo(11);
      setC8r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c8r1MT === "L") {
      setBaseNakedL08([]);
      if (c8R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked08(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
        ]
      )
      setC8R1VNo(11);
      setC8r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked08(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
        ]
      )
      setC8R2VNo(11);
      setC8r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(11);
        setC8r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked08(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
           ]
         )
         setC8R4VNo(11);
         setC8r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  
  } else if (curX === col09X && curY === row01X) {
    if (c9r1MT === "S") {
      if (c9R1VNo === 11) {
        return;
      }
      setBaseNaked09(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
        ]
      )
      setC9R1VNo(11);
      setC9r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c9r1MT === "M") {
      setBaseNakedM09([]);
      if (c9R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked09(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
        ]
      )
      setC9R1VNo(11);
      setC9r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked09(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
        ]
      )
      setC9R2VNo(11);
      setC9r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c9r1MT === "L") {
      setBaseNakedL09([]);
      if (c9R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked09(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
        ]
      )
      setC9R1VNo(11);
      setC9r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked09(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
        ]
      )
      setC9R2VNo(11);
      setC9r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(11);
        setC9r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked09(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
           ]
         )
         setC9R4VNo(11);
         setC9r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  } else if (curX === col10X && curY === row01X) {
    if (c10r1MT === "S") {
      if (c10R1VNo === 11) {
        return;
      }
      setBaseNaked10(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
        ]
      )
      setC10R1VNo(11);
      setC10r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c10r1MT === "M") {
      setBaseNakedM10([]);
      if (c10R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked10(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
        ]
      )
      setC10R1VNo(11);
      setC10r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked10(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
        ]
      )
      setC10R2VNo(11);
      setC10r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c10r1MT === "L") {
      setBaseNakedL10([]);
      if (c10R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked10(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
        ]
      )
      setC10R1VNo(11);
      setC10r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked10(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
        ]
      )
      setC10R2VNo(11);
      setC10r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(11);
        setC10r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked10(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
           ]
         )
         setC10R4VNo(11);
         setC10r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  } else if (curX === col11X && curY === row01X) {
    if (c11r1MT === "S") {
      if (c11R1VNo === 11) {
        return;
      }
      setBaseNaked11(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
        ]
        )
      setC11R1VNo(11);
      setC11r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c11r1MT === "M") {
      setBaseNakedM11([]);
      if (c11R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked11(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
        ]
        )
      setC11R1VNo(11);
      setC11r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked11(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
        ]
      )
      setC11R2VNo(11);
      setC11r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c11r1MT === "L") {
      setBaseNakedL11([]);
      if (c11R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked11(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
        ]
      )
      setC11R1VNo(11);
      setC11r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked11(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
        ]
      )
      setC11R2VNo(11);
      setC11r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(11);
        setC11r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked11(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
           ]
         )
         setC11R4VNo(11);
         setC11r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  } else if (curX === col12X && curY === row01X) {
    if (c12r1MT === "S") {
      if (c12R1VNo === 11) {
        return;
      }
      setBaseNaked12(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />  
        ]
      )
      setC12R1VNo(11);
      setC12r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c12r1MT === "M") {
      setBaseNakedM12([]);
      if (c12R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked12(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />  
        ]
      )
      setC12R1VNo(11);
      setC12r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked12(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
        ]
      )
      setC12R2VNo(11);
      setC12r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c12r1MT === "L") {
      setBaseNakedL12([]);
      if (c12R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked12(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
        ]
      )
      setC12R1VNo(11);
      setC12r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked12(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
        ]
      )
      setC12R2VNo(11);
      setC12r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(11);
        setC12r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked12(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
           ]
         )
         setC12R4VNo(11);
         setC12r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  } else if  (curX === col13X && curY === row01X) {
    if (c13r1MT === "S") {
      if (c13R1VNo === 11) {
        return;
      }
      setBaseNaked13(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />  
        ]
      )
      setC13R1VNo(11);
      setC13r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c13r1MT === "M") {
      setBaseNakedM13([]);
      if (c13R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked13(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />  
        ]
      )
      setC13R1VNo(11);
      setC13r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked13(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
        ]
      )
      setC13R2VNo(11);
      setC13r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c13r1MT === "L") {
      setBaseNakedL13([]);
      if (c13R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked13(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
        ]
      )
      setC13R1VNo(11);
      setC13r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked13(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
        ]
      )
      setC13R2VNo(11);
      setC13r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(11);
        setC13r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked13(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
           ]
         )
         setC13R4VNo(11);
         setC13r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
    
  } else if  (curX === col14X && curY === row01X)  {
    if (c14r1MT === "S") {
      if (c14R1VNo === 11) {
        return;
      }
      setBaseNaked14(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />  
        ]
      )
      setC14R1VNo(11);
      setC14r1MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c14r1MT === "M") {
      setBaseNakedM14([]);
      if (c14R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked14(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />  
        ]
      )
      setC14R1VNo(11);
      setC14r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked14(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
        ]
      )
      setC14R2VNo(11);
      setC14r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c14r1MT === "L") {
      setBaseNakedL14([]);
      if (c14R1VNo === 11) {
      noHandleCount = noHandleCount - 1;
      }
      setBaseNaked14(
        [
          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
        ]
      )
      setC14R1VNo(11);
      setC14r1MT("S");
      noHandleCount = noHandleCount + 1;
      setRow2Naked14(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
        ]
      )
      setC14R2VNo(11);
      setC14r2MT("S");
      noHandleCount = noHandleCount + 1;
      setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(11);
        setC14r3MT("S");
        noHandleCount = noHandleCount + 1;
        setRow4Naked14(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
           ]
         )
         setC14R4VNo(11);
         setC14r4MT("S");
         noHandleCount = noHandleCount + 1;
    }
  } else if (curX === col01X && curY === row02X) {
    if (c1r2MT === "S") {
      if (c1R2VNo === 11) {
        return;
      }
      setRow2Naked01(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
        ]
      )
      setC1R2VNo(11);
      setC1r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c1r2MT === "M") {
      if (c1r2MT === "M" && c1r3MT === "M") {
        setRow2NakedM01([]);
        if (c1R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(11);
        setC1r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(11);
        setC1r3MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c1r1MT === "M" && c1r2MT === "M") {
        setBaseNakedM01([]);
        if (c1R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
        setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />  
          ]
        )
        setC1R1VNo(11);
        setC1r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(11);
        setC1r2MT("S");
        noHandleCount = noHandleCount + 1;
      }
    } else if (c1r2MT === "L") {
        setBaseNakedL01([]);
        if (c1R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(11);
        setC1r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(11);
        setC1r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(11);
          setC1r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked01(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
             ]
           )
           setC1R4VNo(11);
           setC1r4MT("S");
           noHandleCount = noHandleCount + 1;
      
    }
  } else if (curX === col02X && curY === row02X) {
    if (c2r2MT === "S") {
      if (c2R2VNo === 11) {
        return;
      }
      setRow2Naked02(
        [
          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
        ]
      )
      setC2R2VNo(11);
      setC2r2MT("S");
      noHandleCount = noHandleCount + 1;
    } else if (c2r2MT === "M") {
      if (c2r2MT === "M" && c2r3MT === "M") {
        setRow2NakedM02([]);
        if (c2R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(11);
        setC2r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(11);
        setC2r3MT("S");
        noHandleCount = noHandleCount + 1;
      } else  if (c2r1MT === "M" && c2r2MT === "M") {
        setBaseNakedM02([]);
        if (c2R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />  
          ]
        )
        setC2R1VNo(11);
        setC2r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(11);
        setC2r2MT("S");
        noHandleCount = noHandleCount + 1;
      }
    } else if (c2r2MT === "L") {
        setBaseNakedL02([]);
        if (c2R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(11);
        setC2r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(11);
        setC2r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(11);
          setC2r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked02(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
             ]
           )
           setC2R4VNo(11);
           setC2r4MT("S");
           noHandleCount = noHandleCount + 1;
      
    }
    
    } else if (curX === col03X && curY === row02X) {
      if (c3r2MT === "S") {
        if (c3R2VNo === 11) {
          return;
        }
        setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(11);
        setC3r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c3r2MT === "M") {
        if (c3r2MT === "M" && c3r3MT === "M") {
          setRow2NakedM03([]);
          if (c3R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(11);
          setC3r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(11);
          setC3r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c3r1MT === "M" && c3r2MT === "M") {
          setBaseNakedM03([]);
          if (c3R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />  
            ]
          )
          setC3R1VNo(11);
          setC3r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(11);
          setC3r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c3r2MT === "L") {
        setBaseNakedL03([]);
        if (c3R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(11);
        setC3r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(11);
        setC3r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(11);
          setC3r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked03(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
             ]
           )
           setC3R4VNo(11);
           setC3r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
  
    } else if (curX === col04X && curY === row02X) {
      if (c4r2MT === "S") {
        if (c4R2VNo === 11) {
          return;
        }
        setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(11);
        setC4r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c4r2MT === "M") {
        if (c4r2MT === "M" && c4r3MT === "M") {
          setRow2NakedM04([]);
          if (c4R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(11);
          setC4r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(11);
          setC4r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else if (c4r1MT === "M" && c4r2MT === "M") {
          setBaseNakedM04([]);
          if (c4R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />  
            ]
          )
          setC4R1VNo(11);
          setC4r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(11);
          setC4r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c4r2MT === "L") {
        setBaseNakedL04([]);
        if (c4R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(11);
        setC4r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(11);
        setC4r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(11);
          setC4r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked04(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
             ]
           )
           setC4R4VNo(11);
           setC4r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
    } else if (curX === col05X && curY === row02X) {
      if (c5r2MT === "S") {
        if (c5R2VNo === 11) {
          return;
        }
        setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(11);
        setC5r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c5r2MT === "M") {
        if (c5r2MT === "M" && c5r3MT === "M") {
          setRow2NakedM05([]);
          if (c5R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(11);
          setC5r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(11);
          setC5r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c5r1MT === "M" && c5r2MT === "M") {
          setBaseNakedM05([]);
          if (c5R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />  
            ]
          )
          setC5R1VNo(11);
          setC5r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(11);
          setC5r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c5r2MT === "L") {
        setBaseNakedL05([]);
        if (c5R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(11);
        setC5r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(11);
        setC5r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(11);
          setC5r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked05(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
             ]
           )
           setC5R4VNo(11);
           setC5r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      
    } else if (curX === col06X && curY === row02X) {
      if (c6r2MT === "S") {
        if (c6R2VNo === 11) {
          return;
        }
        setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(11);
        setC6r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c6r2MT === "M") {
        if (c6r2MT === "M" && c6r3MT === "M") {
          setRow2NakedM06([]);
          if (c6R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(11);
          setC6r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(11);
          setC6r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c6r1MT === "M" && c6r2MT === "M") {
          setBaseNakedM06([]);
          if (c6R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />  
            ]
          )
          setC6R1VNo(11);
          setC6r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(11);
          setC6r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c6r2MT === "L") {
        setBaseNakedL06([]);
        if (c6R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(11);
        setC6r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(11);
        setC6r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(11);
          setC6r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked06(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
             ]
           )
           setC6R4VNo(11);
           setC6r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
     
    } else if (curX === col07X && curY === row02X) {
      if (c7r2MT === "S") {
        if (c7R2VNo === 11) {
          return;
        }
        setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(11);
        setC7r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c7r2MT === "M") {
        if (c7r2MT === "M" && c7r3MT === "M") {
          setRow2NakedM07([]);
          if (c7R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(11);
          setC7r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(11);
          setC7r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c7r1MT === "M" && c7r2MT === "M") {
          setBaseNakedM07([]);
          if (c7R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />  
            ]
          )
          setC7R1VNo(11);
          setC7r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(11);
          setC7r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c7r2MT === "L") {
        setBaseNakedL07([]);
        if (c7R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(11);
        setC7r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(11);
        setC7r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(11);
          setC7r3MT("S");
  
          setRow4Naked07(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
             ]
           )
           setC7R4VNo(11);
           setC7r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
    
    } else if (curX === col08X && curY === row02X) {
      if (c8r2MT === "S") {
        if (c8R2VNo === 11) {
          return;
        }
        setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(11);
        setC8r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c8r2MT === "M") {
        if (c8r2MT === "M" && c8r3MT === "M") {
          setRow2NakedM08([]);
          if (c8R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(11);
          setC8r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(11);
          setC8r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c8r1MT === "M" && c8r2MT === "M") {
          setBaseNakedM08([]);
          if (c8R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />  
            ]
          )
          setC8R1VNo(11);
          setC8r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(11);
          setC8r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c8r2MT === "L") {
        setBaseNakedL08([]);
        if (c8R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(11);
        setC8r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(11);
        setC8r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(11);
          setC8r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked08(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
             ]
           )
           setC8R4VNo(11);
           setC8r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
     
    } else if (curX === col09X && curY === row02X) {
      if (c9r2MT === "S") {
        if (c9R2VNo === 11) {
          return;
        }
        setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(11);
        setC9r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c9r2MT === "M") {
        if (c9r2MT === "M" && c9r3MT === "M") {
          setRow2NakedM09([]);
          if (c9R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(11);
          setC9r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(11);
          setC9r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c9r1MT === "M" && c1r2MT === "M") {
          setBaseNakedM09([]);
          if (c9R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />  
            ]
          )
          setC9R1VNo(11);
          setC9r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(11);
          setC9r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      }  else if (c9r2MT === "L") {
        setBaseNakedL09([]);
        if (c9R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(11);
        setC9r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(11);
        setC9r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(11);
          setC9r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked09(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
             ]
           )
           setC9R4VNo(11);
           setC9r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      
    } else if (curX === col10X && curY === row02X) {
      if (c10r2MT === "S") {
        if (c10R2VNo === 11) {
          return;
        }
        setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(11);
        setC10r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c10r2MT === "M") {
        if (c10r2MT === "M" && c10r3MT === "M") {
          setRow2NakedM10([]);
          if (c10R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(11);
          setC10r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(11);
          setC10r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c10r1MT === "M" && c10r2MT === "M") {
          setBaseNakedM10([]);
          if (c10R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />  
            ]
          )
          setC10R1VNo(11);
          setC10r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(11);
          setC10r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c10r2MT === "L") {
        setBaseNakedL10([]);
        if (c10R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(11);
        setC10r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(11);
        setC10r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(11);
          setC10r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked10(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
             ]
           )
           setC10R4VNo(11);
           setC10r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
    
    } else if (curX === col11X && curY === row02X) {
      if (c11r2MT === "S") {
        if (c11R2VNo === 11) {
          return;
        }
        setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(11);
        setC11r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c11r2MT === "M") {
        if (c11r2MT === "M" && c11r3MT === "M") {
          setRow2NakedM11([]);
          if (c11R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(11);
          setC11r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(11);
          setC11r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c11r1MT === "M" && c11r2MT === "M") {
          setBaseNakedM11([]);
          if (c11R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />  
            ]
          )
          setC11R1VNo(11);
          setC11r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(11);
          setC11r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c11r2MT === "L") {
        setBaseNakedL11([]);
        if (c11R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(11);
        setC11r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(11);
        setC11r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(11);
          setC11r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked11(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
             ]
           )
           setC11R4VNo(11);
           setC11r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
    
    } else if (curX === col12X && curY === row02X) {
      if (c12r2MT === "S") {
        if (c12R2VNo === 11) {
          return;
        }
        setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(11);
        setC12r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c12r2MT === "M") {
        if (c12r2MT === "M" && c12r3MT === "M") {
          setRow2NakedM12([]);
          if (c12R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(11);
          setC12r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(11);
          setC12r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c12r1MT === "M" && c12r2MT === "M") {
          setBaseNakedM12([]);
          if (c12R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />  
            ]
          )
          setC12R1VNo(11);
          setC12r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(11);
          setC12r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c12r2MT === "L") {
        setBaseNakedL12([]);
        if (c12R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(11);
        setC12r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(11);
        setC12r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(11);
          setC12r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked12(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
             ]
           )
           setC12R4VNo(11);
           setC12r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
  
    } else if  (curX === col13X && curY === row02X) {
      if (c13r2MT === "S") {
        if (c13R2VNo === 11) {
          return;
        }
        setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(11);
        setC13r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c13r2MT === "M") {
        if (c13r2MT === "M" && c13r3MT === "M") {
          setRow2NakedM13([]);
          if (c13R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(11);
          setC13r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(11);
          setC13r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else  if (c13r1MT === "M" && c13r2MT === "M") {
          setBaseNakedM13([]);
          if (c13R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />  
            ]
          )
          setC13R1VNo(11);
          setC13r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(11);
          setC13r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c13r2MT === "L") {
        setBaseNakedL13([]);
        if (c13R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(11);
        setC13r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(11);
        setC13r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(11);
          setC13r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked13(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
             ]
           )
           setC13R4VNo(11);
           setC13r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
  
    } else if  (curX === col14X && curY === row02X)  {
      if (c14r2MT === "S") {
        if (c14R2VNo === 11) {
          return;
        }
        setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(11);
        setC14r2MT("S");
        noHandleCount = noHandleCount + 1;
      } else if (c14r2MT === "M") {
        if (c14r2MT === "M" && c14r3MT === "M") {
          setRow2NakedM14([]);
          if (c14R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(11);
          setC14r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(11);
          setC14r3MT("S");
          noHandleCount = noHandleCount + 1;
        } else if (c14r1MT === "M" && c14r2MT === "M") {
          setBaseNakedM14([]);
          if (c14R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />  
            ]
          )
          setC14R1VNo(11);
          setC14r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(11);
          setC14r2MT("S");
          noHandleCount = noHandleCount + 1;
        } 
      } else if (c14r2MT === "L") {
        setBaseNakedL14([]);
        if (c14R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(11);
        setC14r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(11);
        setC14r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(11);
          setC14r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked14(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
             ]
           )
           setC14R4VNo(11);
           setC14r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
    }  else if (curX === col01X && curY === row03X) {
              if (c1r3MT === "S") {
                if (c1R3VNo === 11) {
                  return;
                }
                    setRow3Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
                    setC1R3VNo(11);
                    setC1r3MT("S");
                    noHandleCount = noHandleCount + 1;
              } else if (c1r3MT === "M") {
                if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                    setRow3NakedM01([]);
                    if (c1R3VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked01(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                        ]
                      )
                      setC1R3VNo(11);
                      setC1r3MT("S");
                      noHandleCount = noHandleCount + 1;
                      setRow2Naked01(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                        ]
                      )
                      setC1R2VNo(11);
                      setC1r2MT("S");
                      noHandleCount = noHandleCount + 1;
            
                  } else if (c1r3MT === "M" && c1r4MT === "M") {
                    setRow3NakedM01([]);
                    if (c1R3VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked01(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                        ]
                      )
                      setC1R3VNo(11);
                      setC1r3MT("S");
                      noHandleCount = noHandleCount + 1;
                      setRow4Naked01(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                        ]
                      )
                      setC1R4VNo(11);
                      setC1r4MT("S");
                      noHandleCount = noHandleCount + 1;

                  }

                } else if (c1r3MT === "L") {
                  setBaseNakedL01([]);
                  if (c1R3VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                  }
                  setBaseNaked01(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(11);
                  setC1r1MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow2Naked01(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                    ]
                  )
                  setC1R2VNo(11);
                  setC1r2MT("S");
            
                  setRow3Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
                    setC1R3VNo(11);
                    setC1r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked01(
                       [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                       ]
                     )
                     setC1R4VNo(11);
                     setC1r4MT("S");
                     noHandleCount = noHandleCount + 1;
                }

      } else if (curX === col02X && curY === row03X) {
        if (c2r3MT === "S") {
          if (c2R3VNo === 11) {
            return;
          }
              setRow3Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(11);
              setC2r3MT("S");
              noHandleCount = noHandleCount + 1;
        } else if (c2r3MT === "M") {
          if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
              setRow3NakedM02([]);
              if (c2R3VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setRow3Naked02(
                    [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                  ]
                )
                setC2R3VNo(11);
                setC2r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked02(
                    [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                  ]
                )
                setC2R2VNo(11);
                setC2r2MT("S");
                noHandleCount = noHandleCount + 1;
      
            } else if (c2r3MT === "M" && c2r4MT === "M") {
              setRow3NakedM02([]);
              if (c2R3VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setRow3Naked02(
                    [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                  ]
                )
                setC2R3VNo(11);
                setC2r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow4Naked02(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                  ]
                )
                setC2R4VNo(11);
                setC2r4MT("S");
                noHandleCount = noHandleCount + 1;
            }	
          } else if (c2r3MT === "L") {
            setBaseNakedL02([]);
            if (c2R3VNo === 11) {
            noHandleCount = noHandleCount - 1;
            }
            setBaseNaked02(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(11);
            setC2r1MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked02(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(11);
            setC2r2MT("S");
            noHandleCount = noHandleCount + 1;
            setRow3Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(11);
              setC2r3MT("S");
              noHandleCount = noHandleCount + 1;
              setRow4Naked02(
                 [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                 ]
               )
               setC2R4VNo(11);
               setC2r4MT("S");
               noHandleCount = noHandleCount + 1;
          }

      } else if (curX === col03X && curY === row03X) {
        if (c3r3MT === "S") {
          if (c3R3VNo === 11) {
            return;
          }
          setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(11);
          setC3r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c3r3MT === "M") {
      if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          setRow3NakedM03([]);
          if (c3R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked03(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(11);
            setC3r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked03(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(11);
            setC3r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c3r3MT === "M" && c3r4MT === "M") {
          setRow3NakedM03([]);
          if (c3R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked03(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(11);
            setC3r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked03(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
              ]
            )
            setC3R4VNo(11);
            setC3r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c3r3MT === "L") {
        setBaseNakedL03([]);
        if (c3R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(11);
        setC3r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(11);
        setC3r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(11);
          setC3r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked03(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
             ]
           )
           setC3R4VNo(11);
           setC3r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
    
      } else if (curX === col04X && curY === row03X) {
        if (c4r3MT === "S") {
          if (c4R3VNo === 11) {
            return;
          }
          setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(11);
          setC4r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c4r3MT === "M") {
      if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          setRow3NakedM04([]);
          if (c4R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked04(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(11);
            setC4r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked04(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(11);
            setC4r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c4r3MT === "M" && c4r4MT === "M") {
          setRow3NakedM04([]);
          if (c4R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked04(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(11);
            setC4r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked04(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
              ]
            )
            setC4R4VNo(11);
            setC4r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c4r3MT === "L") {
        setBaseNakedL04([]);
        if (c4R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(11);
        setC4r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(11);
        setC4r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(11);
          setC4r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked04(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
             ]
           )
           setC4R4VNo(11);
           setC4r4MT("S");
      }
       
      } else if (curX === col05X && curY === row03X) {
        if (c5r3MT === "S") {
          if (c5R3VNo === 11) {
            return;
          }
          setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(11);
          setC5r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c5r3MT === "M") {
      if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          setRow3NakedM05([]);
          if (c5R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked05(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(11);
            setC5r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked05(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(11);
            setC5r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c5r3MT === "M" && c5r4MT === "M") {
          setRow3NakedM05([]);
          if (c5R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked05(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(11);
            setC5r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked05(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
              ]
            )
            setC5R4VNo(11);
            setC5r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c5r3MT === "L") {
        setBaseNakedL05([]);
        if (c5R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(11);
        setC5r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(11);
        setC5r2MT("S");
  
        setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(11);
          setC5r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked05(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
             ]
           )
           setC5R4VNo(11);
           setC5r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
        
      } else if (curX === col06X && curY === row03X) {
        if (c6r3MT === "S") {
          if (c6R3VNo === 11) {
            return;
          }
          setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(11);
          setC6r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c6r3MT === "M") {
      if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          setRow3NakedM06([]);
          if (c6R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked06(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(11);
            setC6r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked06(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(11);
            setC6r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c6r3MT === "M" && c6r4MT === "M") {
          setRow3NakedM06([]);
          if (c6R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked06(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(11);
            setC6r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
              ]
            )
            setC6R4VNo(11);
            setC6r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c6r3MT === "L") {
        setBaseNakedL06([]);
        if (c6R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(11);
        setC6r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(11);
        setC6r2MT("S");
  
        setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(11);
          setC6r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked06(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
             ]
           )
           setC6R4VNo(11);
           setC6r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
          
      } else if (curX === col07X && curY === row03X) {
        if (c7r3MT === "S") {
          if (c7R3VNo === 11) {
            return;
          }
          setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(11);
          setC7r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c7r3MT === "M") {
      if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          setRow3NakedM07([]);
          if (c7R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked07(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(11);
            setC7r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked07(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(11);
            setC7r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c7r3MT === "M" && c7r4MT === "M") {
          setRow3NakedM07([]);
          if (c7R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked07(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(11);
            setC7r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
              ]
            )
            setC7R4VNo(11);
            setC7r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c7r3MT === "L") {
        setBaseNakedL07([]);
        if (c7R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
           }
        setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(11);
        setC7r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(11);
        setC7r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(11);
          setC7r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked07(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
             ]
           )
           setC7R4VNo(11);
           setC7r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      } else if (curX === col08X && curY === row03X) {
        if (c8r3MT === "S") {
          if (c8R3VNo === 11) {
            return;
          }
          setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(11);
          setC8r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c8r3MT === "M") {
      if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          setRow3NakedM08([]);
          if (c8R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked08(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(11);
            setC8r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked08(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(11);
            setC8r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c8r3MT === "M" && c8r4MT === "M") {
          setRow3NakedM08([]);
          if (c8R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked08(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(11);
            setC8r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
              ]
            )
            setC8R4VNo(11);
            setC8r4MT("S");
            noHandleCount = noHandleCount + 1;
          }	
        } else if (c8r3MT === "L") {
          setBaseNakedL08([]);
          if (c8R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(11);
          setC8r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked08(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(11);
            setC8r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked08(
               [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
               ]
             )
             setC8R4VNo(11);
             setC8r4MT("S");
             noHandleCount = noHandleCount + 1;
        }
      } else if (curX === col09X && curY === row03X) {
        if (c9r3MT === "S") {
          if (c9R3VNo === 11) {
            return;
          }
          setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(11);
          setC9r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c9r3MT === "M") {
      if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          setRow3NakedM09([]);
          if (c9R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked09(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(11);
            setC9r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked09(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(11);
            setC9r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c9r3MT === "M" && c9r4MT === "M") {
          setRow3NakedM09([]);
          if (c9R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked09(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(11);
            setC9r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
              ]
            )
            setC9R4VNo(11);
            setC9r4MT("S");
            noHandleCount = noHandleCount + 1;
          }	
        } else if (c9r3MT === "L") {
          setBaseNakedL09([]);
          if (c9R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9r1MT("S");
          noHandleCount = noHandleCount + 1;
          setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(11);
          setC9r2MT("S");
          noHandleCount = noHandleCount + 1;
          setRow3Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(11);
            setC9r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked09(
               [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
               ]
             )
             setC9R4VNo(11);
             setC9r4MT("S");
             noHandleCount = noHandleCount + 1;
        }
      } else if (curX === col10X && curY === row03X) {
        if (c10r3MT === "S") {
          if (c10R3VNo === 11) {
            return;
          }
          setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(11);
          setC10r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c10r3MT === "M") {
      if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          setRow3NakedM10([]);
          if (c10R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked10(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(11);
            setC10r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked10(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(11);
            setC10r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c10r3MT === "M" && c10r4MT === "M") {
          setRow3NakedM10([]);
          if (c10R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked10(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(11);
            setC10r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked10(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
              ]
            )
            setC10R4VNo(11);
            setC10r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c10r3MT === "L") {
        setBaseNakedL10([]);
        if (c10R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(11);
        setC10r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(11);
        setC10r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(11);
          setC10r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked10(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
             ]
           )
           setC10R4VNo(11);
           setC10r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      } else if (curX === col11X && curY === row03X) {
        if (c11r3MT === "S") {
          if (c11R3VNo === 11) {
            return;
          }
          setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(11);
          setC11r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c11r3MT === "M") {
      if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          setRow3NakedM11([]);
          if (c11R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked11(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(11);
            setC11r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked11(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(11);
            setC11r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c11r3MT === "M" && c11r4MT === "M") {
          setRow3NakedM11([]);
          if (c11R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked11(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(11);
            setC11r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
              ]
            )
            setC11R4VNo(11);
            setC11r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c11r3MT === "L") {
        setBaseNakedL11([]);
        if (c11R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(11);
        setC11r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(11);
        setC11r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(11);
          setC11r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked11(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
             ]
           )
           setC11R4VNo(11);
           setC11r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      } else if (curX === col12X && curY === row03X) {
        if (c12r3MT === "S") {
          if (c12R3VNo === 11) {
            return;
          }
          setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(11);
          setC12r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c12r3MT === "M") {
      if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          setRow3NakedM12([]);
          if (c12R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked12(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(11);
            setC12r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked12(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(11);
            setC12r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c12r3MT === "M" && c12r4MT === "M") {
          setRow3NakedM12([]);
          if (c12R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked12(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(11);
            setC12r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked12(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
              ]
            )
            setC12R4VNo(11);
            setC12r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c12r3MT === "L") {
        setBaseNakedL12([]);
        if (c12R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(11);
        setC12r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(11);
        setC12r2MT("S");
        noHandleCount = noHandleCount + 1;
        setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(11);
          setC12r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked12(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
             ]
           )
           setC12R4VNo(11);
           setC12r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      } else if  (curX === col13X && curY === row03X) {
        if (c13r3MT === "S") {
          if (c13R3VNo === 11) {
            return;
          }
          setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(11);
          setC13r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c13r3MT === "M") {
      if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          setRow3NakedM13([]);
          if (c13R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked13(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(11);
            setC13r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked13(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(11);
            setC13r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c13r3MT === "M" && c13r4MT === "M") {
          setRow3NakedM13([]);
          if (c13R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked13(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(11);
            setC13r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked13(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
              ]
            )
            setC13R4VNo(11);
            setC13r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c13r3MT === "L") {
        setBaseNakedL13([]);
        if (c13R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(11);
        setC13r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(11);
        setC13r2MT("S");
  
        setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(11);
          setC13r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked13(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
             ]
           )
           setC13R4VNo(11);
           setC13r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      } else if  (curX === col14X && curY === row03X)  {
        if (c14r3MT === "S") {
          if (c14R3VNo === 11) {
            return;
          }
          setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(11);
          setC14r3MT("S");
          noHandleCount = noHandleCount + 1;
    } else if (c14r3MT === "M") {
      if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          setRow3NakedM14([]);
          if (c14R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked14(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(11);
            setC14r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow2Naked14(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(11);
            setC14r2MT("S");
            noHandleCount = noHandleCount + 1;
  
        } else if (c14r3MT === "M" && c14r4MT === "M") {
          setRow3NakedM14([]);
          if (c14R3VNo === 11) {
          noHandleCount = noHandleCount - 1;
          }
          setRow3Naked14(
                [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(11);
            setC14r3MT("S");
            noHandleCount = noHandleCount + 1;
            setRow4Naked14(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
              ]
            )
            setC14R4VNo(11);
            setC14r4MT("S");
            noHandleCount = noHandleCount + 1;
        }	
      } else if (c14r3MT === "L") {
        setBaseNakedL14([]);
        if (c14R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
        }
        setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(11);
        setC14r1MT("S");
        noHandleCount = noHandleCount + 1;
        setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(11);
        setC14r2MT("S");
  
        setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(11);
          setC14r3MT("S");
          noHandleCount = noHandleCount + 1;
          setRow4Naked14(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
             ]
           )
           setC14R4VNo(11);
           setC14r4MT("S");
           noHandleCount = noHandleCount + 1;
      }
      }  else if (curX === col01X && curY === row04X) {
              if (c1r4MT === "S") {
                if (c1R4VNo === 11) {
                  return;
                }
                    setRow4Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                      ]
                    )
                    setC1R4VNo(11);
                    setC1r4MT("S");
                    noHandleCount = noHandleCount + 1;
              } else if (c1r4MT === "M") {
                    setRow3NakedM01([]);
                    if (c1R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked01(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                        ]
                    )
                    setC1R3VNo(11);
                    setC1r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked01(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                      ]
                    )
                    setC1R4VNo(11);
                    setC1r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c1r4MT === "L") {
                setBaseNakedL01([]);
                if (c1R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked01(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(11);
                setC1r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked01(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                  ]
                )
                setC1R2VNo(11);
                setC1r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked01(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                    ]
                  )
                  setC1R3VNo(11);
                  setC1r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked01(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                     ]
                   )
                   setC1R4VNo(11);
                   setC1r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
              
        } else if (curX === col02X && curY === row04X) {
            if (c2r4MT === "S") {
              if (c2R4VNo === 11) {
                return;
              }
                    setRow4Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                      ]
                    )
                    setC2R4VNo(11);
                    setC2r4MT("S");
                    noHandleCount = noHandleCount + 1;
            } else if (c2r4MT === "M") {
                    setRow3NakedM02([]);
                    if (c2R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked02(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                        ]
                    )
                    setC2R3VNo(11);
                    setC2r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked02(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                      ]
                    )
                    setC2R4VNo(11);
                    setC2r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c2r4MT === "L") {
                setBaseNakedL02([]);
                if (c2R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked02(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                  ]
                )
                setC2R1VNo(11);
                setC2r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked02(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                  ]
                )
                setC2R2VNo(11);
                setC2r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked02(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                    ]
                  )
                  setC2R3VNo(11);
                  setC2r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked02(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                     ]
                   )
                   setC2R4VNo(11);
                   setC2r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
        
        } else if (curX === col03X && curY === row04X) {
          if (c3r4MT === "S") {
            if (c3R4VNo === 11) {
              return;
            }
                  setRow4Naked03(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                    ]
                  )
                  setC3R4VNo(11);
                  setC3r4MT("S");
                  noHandleCount = noHandleCount + 1;
          } else if (c3r4MT === "M") {
                  setRow3NakedM03([]);
                  if (c3R4VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                  }
                  setRow3Naked03(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                      ]
                  )
                  setC3R3VNo(11);
                  setC3r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked03(
                      [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                    ]
                  )
                  setC3R4VNo(11);
                  setC3r4MT("S"); 
                  noHandleCount = noHandleCount + 1;
            } else if (c3r4MT === "L") {
              setBaseNakedL03([]);
              if (c3R4VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setBaseNaked03(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(11);
              setC3r1MT("S");
              noHandleCount = noHandleCount + 1;
              setRow2Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(11);
              setC3r2MT("S");
              noHandleCount = noHandleCount + 1;
              setRow3Naked03(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                  ]
                )
                setC3R3VNo(11);
                setC3r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow4Naked03(
                   [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                   ]
                 )
                 setC3R4VNo(11);
                 setC3r4MT("S");
                 noHandleCount = noHandleCount + 1;
            }
      
        } else if (curX === col04X && curY === row04X) {
            if (c4r4MT === "S") {
              if (c4R4VNo === 11) {
                return;
              }
                    setRow4Naked04(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                      ]
                    )
                    setC4R4VNo(11);
                    setC4r4MT("S");
                    noHandleCount = noHandleCount + 1;
            } else if (c4r4MT === "M") {
                    setRow3NakedM04([]);
                    if (c4R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked04(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                        ]
                    )
                    setC4R3VNo(11);
                    setC4r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked04(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                      ]
                    )
                    setC4R4VNo(11);
                    setC4r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c4r4MT === "L") {
                setBaseNakedL04([]);
                if (c4R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked04(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(11);
                setC4r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked04(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                  ]
                )
                setC4R2VNo(11);
                setC4r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked04(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                    ]
                  )
                  setC4R3VNo(11);
                  setC4r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked04(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                     ]
                   )
                   setC4R4VNo(11);
                   setC4r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
         
        } else if (curX === col05X && curY === row04X) {
          if (c5r4MT === "S") {
            if (c5R4VNo === 11) {
              return;
            }
                    setRow4Naked05(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                      ]
                    )
                    setC5R4VNo(11);
                    setC5r4MT("S");
                    noHandleCount = noHandleCount + 1;
            } else if (c5r4MT === "M") {
                    setRow3NakedM05([]);
                    if (c5R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked05(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                        ]
                    )
                    setC5R3VNo(11);
                    setC5r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked05(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                      ]
                    )
                    setC5R4VNo(11);
                    setC5r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c5r4MT === "L") {
                setBaseNakedL05([]);
                if (c5R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked05(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(11);
                setC5r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked05(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                  ]
                )
                setC5R2VNo(11);
                setC5r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                    ]
                  )
                  setC5R3VNo(11);
                  setC5r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked05(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                     ]
                   )
                   setC5R4VNo(11);
                   setC5r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
        } else if (curX === col06X && curY === row04X) {
          if (c6r4MT === "S") {
            if (c6R4VNo === 11) {
              return;
            }
                  setRow4Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                    ]
                  )
                  setC6R4VNo(11);
                  setC6r4MT("S");
                  noHandleCount = noHandleCount + 1;
          } else if (c6r4MT === "M") {
                  setRow3NakedM06([]);
                  if (c6R4VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                  }
                  setRow3Naked06(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                      ]
                  )
                  setC6R3VNo(11);
                  setC6r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked06(
                      [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                    ]
                  )
                  setC6R4VNo(11);
                  setC6r4MT("S"); 
                  noHandleCount = noHandleCount + 1;
            } else if (c6r4MT === "L") {
              setBaseNakedL06([]);
              if (c6R4VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setBaseNaked06(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(11);
              setC6r1MT("S");
              noHandleCount = noHandleCount + 1;
              setRow2Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(11);
              setC6r2MT("S");
              noHandleCount = noHandleCount + 1;
              setRow3Naked06(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                  ]
                )
                setC6R3VNo(11);
                setC6r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow4Naked06(
                   [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                   ]
                 )
                 setC6R4VNo(11);
                 setC6r4MT("S");
                 noHandleCount = noHandleCount + 1;
            }
        } else if (curX === col07X && curY === row04X) {
          if (c7r4MT === "S") {
            if (c7R4VNo === 11) {
              return;
            }
                    setRow4Naked07(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                      ]
                    )
                    setC7R4VNo(11);
                    setC7r4MT("S");
                    noHandleCount = noHandleCount + 1;
            } else if (c7r4MT === "M") {
                    setRow3NakedM07([]);
                    if (c7R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked07(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                        ]
                    )
                    setC7R3VNo(11);
                    setC7r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked07(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                      ]
                    )
                    setC7R4VNo(11);
                    setC7r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c7r4MT === "L") {
                setBaseNakedL07([]);
                if (c7R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked07(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                  ]
                )
                setC7R1VNo(11);
                setC7r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked07(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                  ]
                )
                setC7R2VNo(11);
                setC7r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked07(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                    ]
                  )
                  setC7R3VNo(11);
                  setC7r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked07(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                     ]
                   )
                   setC7R4VNo(11);
                   setC7r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
        } else if (curX === col08X && curY === row04X) {
          if (c8r4MT === "S") {
            if (c8R4VNo === 11) {
              return;
            }
                  setRow4Naked08(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                    ]
                  )
                  setC8R4VNo(11);
                  setC8r4MT("S");
                  noHandleCount = noHandleCount + 1;
          } else if (c8r4MT === "M") {
                  setRow3NakedM08([]);
                  if (c8R4VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                  }
                  setRow3Naked08(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                      ]
                  )
                  setC8R3VNo(11);
                  setC8r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked08(
                      [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                    ]
                  )
                  setC8R4VNo(11);
                  setC8r4MT("S"); 
                  noHandleCount = noHandleCount + 1;
            } else if (c8r4MT === "L") {
              setBaseNakedL08([]);
              if (c8R4VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setBaseNaked08(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(11);
              setC8r1MT("S");
              noHandleCount = noHandleCount + 1;
              setRow2Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(11);
              setC8r2MT("S");
              noHandleCount = noHandleCount + 1;
              setRow3Naked08(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                  ]
                )
                setC8R3VNo(11);
                setC8r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow4Naked08(
                   [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                   ]
                 )
                 setC8R4VNo(11);
                 setC8r4MT("S");
                 noHandleCount = noHandleCount + 1;
            }
        } else if (curX === col09X && curY === row04X) {
          if (c9r4MT === "S") {
            if (c9R4VNo === 11) {
              return;
            }
                    setRow4Naked09(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                      ]
                    )
                    setC9R4VNo(11);
                    setC9r4MT("S");
                    noHandleCount = noHandleCount + 1;
            } else if (c9r4MT === "M") {
                    setRow3NakedM09([]);
                    if (c9R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked09(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                        ]
                    )
                    setC9R3VNo(11);
                    setC9r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked09(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                      ]
                    )
                    setC9R4VNo(11);
                    setC9r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c9r4MT === "L") {
                setBaseNakedL09([]);
                if (c9R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked09(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(11);
                setC9r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked09(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                  ]
                )
                setC9R2VNo(11);
                setC9r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked09(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                    ]
                  )
                  setC9R3VNo(11);
                  setC9r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked09(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                     ]
                   )
                   setC9R4VNo(11);
                   setC9r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
        } else if (curX === col10X && curY === row04X) {
          if (c10r4MT === "S") {
            if (c10R4VNo === 11) {
              return;
            }
                    setRow4Naked10(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                      ]
                    )
                    setC10R4VNo(11);
                    setC10r4MT("S");
                    noHandleCount = noHandleCount + 1;
            } else if (c10r4MT === "M") {
                    setRow3NakedM10([]);
                    if (c10R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked10(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                        ]
                    )
                    setC10R3VNo(11);
                    setC10r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked10(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                      ]
                    )
                    setC10R4VNo(11);
                    setC10r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c10r4MT === "L") {
                setBaseNakedL10([]);
                if (c10R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked10(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                  ]
                )
                setC10R1VNo(11);
                setC10r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked10(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(11);
                setC10r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked10(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                    ]
                  )
                  setC10R3VNo(11);
                  setC10r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked10(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                     ]
                   )
                   setC10R4VNo(11);
                   setC10r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
        } else if (curX === col11X && curY === row04X) {
          if (c11r4MT === "S") {
            if (c11R4VNo === 11) {
              return;
            }
                  setRow4Naked11(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                    ]
                  )
                  setC11R4VNo(11);
                  setC11r4MT("S");
                  noHandleCount = noHandleCount + 1;
          } else if (c11r4MT === "M") {
                  setRow3NakedM11([]);
                  if (c11R4VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                  }
                  setRow3Naked11(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                      ]
                  )
                  setC11R3VNo(11);
                  setC11r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked11(
                      [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                    ]
                  )
                  setC11R4VNo(11);
                  setC11r4MT("S"); 
                  noHandleCount = noHandleCount + 1;
            } else if (c11r4MT === "L") {
              setBaseNakedL11([]);
              if (c11R4VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setBaseNaked11(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(11);
              setC11r1MT("S");
              noHandleCount = noHandleCount + 1;
              setRow2Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(11);
              setC11r2MT("S");
              noHandleCount = noHandleCount + 1;
              setRow3Naked11(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                  ]
                )
                setC11R3VNo(11);
                setC11r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow4Naked11(
                   [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                   ]
                 )
                 setC11R4VNo(11);
                 setC11r4MT("S");
                 noHandleCount = noHandleCount + 1;
            }
        } else if (curX === col12X && curY === row04X) {
          if (c12r4MT === "S") {
            if (c12R4VNo === 11) {
              return;
            }
                  setRow4Naked12(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                    ]
                  )
                  setC12R4VNo(11);
                  setC12r4MT("S");
                  noHandleCount = noHandleCount + 1;
          } else if (c12r4MT === "M") {
                  setRow3NakedM12([]);
                  if (c12R4VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                  }
                  setRow3Naked12(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                      ]
                  )
                  setC12R3VNo(11);
                  setC12r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked12(
                      [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                    ]
                  )
                  setC12R4VNo(11);
                  setC12r4MT("S"); 
                  noHandleCount = noHandleCount + 1;
            } else if (c12r4MT === "L") {
              setBaseNakedL12([]);
              if (c12R4VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setBaseNaked12(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(11);
              setC12r1MT("S");
              noHandleCount = noHandleCount + 1;
              setRow2Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(11);
              setC12r2MT("S");
              noHandleCount = noHandleCount + 1;
              setRow3Naked12(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                  ]
                )
                setC12R3VNo(11);
                setC12r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow4Naked12(
                   [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                   ]
                 )
                 setC12R4VNo(11);
                 setC12r4MT("S");
                 noHandleCount = noHandleCount + 1;
            }
        } else if  (curX === col13X && curY === row04X) {
          if (c13r4MT === "S") {
            if (c13R4VNo === 11) {
              return;
            }
                  setRow4Naked13(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                    ]
                  )
                  setC13R4VNo(11);
                  setC13r4MT("S");
                  noHandleCount = noHandleCount + 1;
          } else if (c13r4MT === "M") {
                  setRow3NakedM13([]);
                  if (c13R4VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                  }
                  setRow3Naked13(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                      ]
                  )
                  setC13R3VNo(11);
                  setC13r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked13(
                      [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                    ]
                  )
                  setC13R4VNo(11);
                  setC13r4MT("S"); 
                  noHandleCount = noHandleCount + 1;
            } else if (c13r4MT === "L") {
              setBaseNakedL13([]);
              if (c13R4VNo === 11) {
              noHandleCount = noHandleCount - 1;
              }
              setBaseNaked13(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(11);
              setC13r1MT("S");
              noHandleCount = noHandleCount + 1;
              setRow2Naked13(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(11);
              setC13r2MT("S");
              noHandleCount = noHandleCount + 1;
              setRow3Naked13(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                  ]
                )
                setC13R3VNo(11);
                setC13r3MT("S");
                noHandleCount = noHandleCount + 1;
                setRow4Naked13(
                   [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                   ]
                 )
                 setC13R4VNo(11);
                 setC13r4MT("S");
                 noHandleCount = noHandleCount + 1;
            }
        } else if  (curX === col14X && curY === row04X)  {
          if (c14r4MT === "S") {
            if (c14R4VNo === 11) {
              return;
            }
                    setRow4Naked14(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                      ]
                    )
                    setC14R4VNo(11);
                    setC14r4MT("S");
                    noHandleCount = noHandleCount + 1;
            } else if (c14r4MT === "M") {
                    setRow3NakedM14([]);
                    if (c14R4VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                    }
                    setRow3Naked14(
                          [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                        ]
                    )
                    setC14R3VNo(11);
                    setC14r3MT("S");
                    noHandleCount = noHandleCount + 1;
                    setRow4Naked14(
                        [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                      ]
                    )
                    setC14R4VNo(11);
                    setC14r4MT("S"); 
                    noHandleCount = noHandleCount + 1;
              } else if (c14r4MT === "L") {
                setBaseNakedL14([]);
                if (c14R4VNo === 11) {
                noHandleCount = noHandleCount - 1;
                }
                setBaseNaked14(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(11);
                setC14r1MT("S");
                noHandleCount = noHandleCount + 1;
                setRow2Naked14(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                  ]
                )
                setC14R2VNo(11);
                setC14r2MT("S");
                noHandleCount = noHandleCount + 1;
                setRow3Naked14(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                    ]
                  )
                  setC14R3VNo(11);
                  setC14r3MT("S");
                  noHandleCount = noHandleCount + 1;
                  setRow4Naked14(
                     [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                     ]
                   )
                   setC14R4VNo(11);
                   setC14r4MT("S");
                   noHandleCount = noHandleCount + 1;
              }
        }

}

const addMediumDoors = (e)=> {

  if  (curX === col01X && curY === row01X) {
    if (c1r1MT === "M") {
      
        setBaseNaked01([]);
        setRow2Naked01([]);
        if (c1R1VNo === 11 && c1R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(11);
        setC1R2VNo(11);
        setC1r1MT("M");
        setC1r2MT("M");
        noHandleCount = noHandleCount + 1;
    } else if (c1r1MT === "S") {
      if (col01Row02 === true && c1r2MT === "S") {
        setBaseNaked01([]);
        setRow2Naked01([]);
        if (c1R1VNo === 11 && c1R2VNo === 11) {
          noHandleCount = noHandleCount - 2;
        }
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(11);
        setC1R2VNo(11);
        setC1r1MT("M");
        setC1r2MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
    } else if (c1r1MT === "L") {
      setBaseNakedL01([]);
      if (c1R1VNo === 11 && c1R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM01(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
        ]
      )
      setC1R1VNo(11);
      setC1R2VNo(11);
      setC1r1MT("M");
      setC1r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM01(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
        ]
      )
      setC1R3VNo(11);
      setC1R4VNo(11);
      setC1r3MT("M");
      setC1r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (curX === col02X && curY === row01X) {
  if (c2r1MT === "M") {
    setBaseNaked02([]);
    setRow2Naked02([]);
    if (c2R1VNo === 11 && c2R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
  
      setBaseNakedM02(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
        ]
        )
    setC2R1VNo(11);
    setC2R2VNo(11);
    setC2r1MT("M");
    setC2r2MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c2r1MT === "S") {
  if (col02Row02 === true && c2r2MT === "S") {
    setBaseNaked02([]);
    setRow2Naked02([]);
    if (c2R1VNo === 11 && c2R2VNo === 11) {
      noHandleCount = noHandleCount - 2;
    }
  
      setBaseNakedM02(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
        ]
      )
   setC2R1VNo(11);
   setC2R2VNo(11);
   setC2r1MT("M");
   setC2r2MT("M");
   noHandleCount = noHandleCount + 1;
  } else {
    alert("Medium door cannot be added to just one small unit.")
  }
} else if (c2r1MT === "L") {
  setBaseNakedL02([]);
  if (c2R1VNo === 11 && c2R2VNo === 11) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM02(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
    ]
  )
  setC2R1VNo(11);
  setC2R2VNo(11);
  setC2r1MT("M");
  setC2r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM02(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
    ]
  )
  setC2R3VNo(11);
  setC2R4VNo(11);
  setC2r3MT("M");
  setC2r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col03X && curY === row01X) {
  if (c3r1MT === "M") {
      setBaseNaked03([]);
      setRow2Naked03([]);
      if (c3R1VNo === 11 && c3R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM03(
          [
          < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />        
          ]
        )
        setC3R1VNo(11);
        setC3R2VNo(11);
        setC3r1MT("M");
        setC3r2MT("M");
        noHandleCount = noHandleCount + 1;
  } else if (c3r1MT === "S") {
    if (col03Row02 === true && c3r2MT === "S") {
      setBaseNaked03([]);
        setRow2Naked03([]);
        if (c3R1VNo === 11 && c3R2VNo === 11) {
          noHandleCount = noHandleCount - 2;
        }
        setBaseNakedM03(
          [
           < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />        
          ]
        )
        setC3R1VNo(11);
        setC3R2VNo(11);
        setC3r1MT("M");
        setC3r2MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
  } else if (c3r1MT === "L") {
    setBaseNakedL03([]);
    if (c3R1VNo === 11 && c3R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM03(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
      ]
    )
    setC3R1VNo(11);
    setC3R2VNo(11);
    setC3r1MT("M");
    setC3r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM03(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
      ]
    )
    setC3R3VNo(11);
    setC3R4VNo(11);
    setC3r3MT("M");
    setC3r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (curX === col04X && curY === row01X) {
  if (c4r1MT === "M") {
        setBaseNaked04([]);
        setRow2Naked04([]);
        if (c4R1VNo === 11 && c4R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
          setBaseNakedM04(
            [
            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />          
          ]
          )
      setC4R1VNo(11);
      setC4R2VNo(11);
      setC4r1MT("M");
      setC4r2MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c4r1MT === "S") {
    if (col04Row02 === true && c4r2MT === "S") {
      setBaseNaked04([]);
        setRow2Naked04([]);
        if (c4R1VNo === 11 && c4R2VNo === 11) {
          noHandleCount = noHandleCount -2;
        }
        setBaseNakedM04(
          [
          < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />          
        ]
        )
        setC4R1VNo(11);
      setC4R2VNo(11);
      setC4r1MT("M");
      setC4r2MT("M");
      noHandleCount = noHandleCount + 1;
    } else {
      alert("Medium door cannot be added to just one small unit.")
    }
  } else if (c4r1MT === "L") {
    setBaseNakedL04([]);
    if (c4R1VNo === 11 && c4R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM04(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
      ]
    )
    setC4R1VNo(11);
    setC4R2VNo(11);
    setC4r1MT("M");
    setC4r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM04(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
      ]
    )
    setC4R3VNo(11);
    setC4R4VNo(11);
    setC4r3MT("M");
    setC4r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (curX === col05X && curY === row01X) {
  if (c5r1MT === "M") {   
        setBaseNaked05([]);
        setRow2Naked05([]);
        if (c5R1VNo === 11 && c5R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
          setBaseNakedM05(
            [
            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />         
          ]
          )
      setC5R1VNo(11);
      setC5R2VNo(11);
      setC5r1MT("M");
      setC5r2MT("M");
      noHandleCount = noHandleCount + 1;
    } else if (c5r1MT === "S") {
      if (col05Row02 === true && c5r2MT === "S") {
        setBaseNaked05([]);
        setRow2Naked05([]);
        if (c5R1VNo === 11 && c5R2VNo === 11) {
          noHandleCount = noHandleCount - 2;
        }
          setBaseNakedM05(
            [
            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />         
           ]
          )
      setC5R1VNo(11);
      setC5R2VNo(11);
      setC5r1MT("M");
      setC5r2MT("M");
      noHandleCount = noHandleCount + 1;
        } else {
          alert("Medium door cannot be added to just one small unit.")
        }
    } else if (c5r1MT === "L") {
      setBaseNakedL05([]);
      if (c5R1VNo === 11 && c5R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM05(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
        ]
      )
      setC5R1VNo(11);
      setC5R2VNo(11);
      setC5r1MT("M");
      setC5r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM05(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
        ]
      )
      setC5R3VNo(11);
      setC5R4VNo(11);
      setC5r3MT("M");
      setC5r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (curX === col06X && curY === row01X) {
  if (c6r1MT === "M") {   
        setBaseNaked06([]);
        setRow2Naked06([]);
        if (c6R1VNo === 11 && c6R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
          setBaseNakedM06(
            [
            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />        
            ]
          )
      setC6R1VNo(11);
      setC6R2VNo(11);
      setC6r1MT("M");
      setC6r2MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c6r1MT === "S") {
    if (col06Row02 === true && c6r2MT === "S") {
      setBaseNaked06([]);
        setRow2Naked06([]);
        if (c6R1VNo === 11 && c6R2VNo === 11) {
          noHandleCount = noHandleCount - 2;
        }
        setBaseNakedM06(
          [
          < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />        
          ]
        )
      setC6R1VNo(11);
      setC6R2VNo(11);
      setC6r1MT("M");
      setC6r2MT("M");
      noHandleCount = noHandleCount + 1;
     } else {
      alert("Medium door cannot be added to just one small unit.")
    }
  } else if (c6r1MT === "L") {
    setBaseNakedL06([]);
    if (c6R1VNo === 11 && c6R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM06(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
      ]
    )
    setC6R1VNo(11);
    setC6R2VNo(11);
    setC6r1MT("M");
    setC6r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM06(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
      ]
    )
    setC6R3VNo(11);
    setC6R4VNo(11);
    setC6r3MT("M");
    setC6r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (curX === col07X && curY === row01X) {
  if (c7r1MT === "M") { 
        setBaseNaked07([]);
        setRow2Naked07([]);
        if (c7R1VNo === 11 && c7R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(11);
        setC7R2VNo(11);
        setC7r1MT("M");
        setC7r2MT("M");
        noHandleCount = noHandleCount + 1;
    } else if (c7r1MT === "S") {
      if (col07Row02 === true && c7r2MT === "S") {
        setBaseNaked07([]);
        setRow2Naked07([]);
        if (c7R1VNo === 11 && c7R2VNo === 11) {
          noHandleCount = noHandleCount - 2;
        }
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(11);
        setC7R2VNo(11);
        setC7r1MT("M");
        setC7r2MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
    } else if (c7r1MT === "L") {
      setBaseNakedL07([]);
      if (c7R1VNo === 11 && c7R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM07(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
        ]
      )
      setC7R1VNo(11);
      setC7R2VNo(11);
      setC7r1MT("M");
      setC7r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
        ]
      )
      setC7R3VNo(11);
      setC7R4VNo(11);
      setC7r3MT("M");
      setC7r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
    
} else if (curX === col08X && curY === row01X) {
  if (c8r1MT === "M") { 
      setBaseNaked08([]);
      setRow2Naked08([]);
      if (c8R1VNo === 11 && c8R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
    setC8R1VNo(11);
    setC8R2VNo(11);
    setC8r1MT("M");
    setC8r2MT("M");
    noHandleCount = noHandleCount + 1;
  } else if (c8r1MT === "S") {
    if (col08Row02 === true && c8r2MT === "S") {
      setBaseNaked08([]);
      setRow2Naked08([]);
      if (c8R1VNo === 11 && c8R2VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
    setC8R1VNo(11);
    setC8R2VNo(11);
    setC8r1MT("M");
    setC8r2MT("M");
    noHandleCount = noHandleCount + 1;
    } else {
      alert("Medium door cannot be added to just one small unit.")
    }
  }  else if (c8r1MT === "L") {
    setBaseNakedL08([]);
    if (c8R1VNo === 11 && c8R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM08(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
      ]
    )
    setC8R1VNo(11);
    setC8R2VNo(11);
    setC8r1MT("M");
    setC8r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM08(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
      ]
    )
    setC8R3VNo(11);
    setC8R4VNo(11);
    setC8r3MT("M");
    setC8r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (curX === col09X && curY === row01X) {
  if (c9r1MT === "M") { 
      setBaseNaked09([]);
      setRow2Naked09([]);
      if (c9R1VNo === 11 && c9R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
      setC9R1VNo(11);
      setC9R2VNo(11);
      setC9r1MT("M");
      setC9r2MT("M");
      noHandleCount = noHandleCount + 1;
    } else if (c9r1MT === "S") {
      if (col09Row02 === true && c9r2MT === "S") {
        setBaseNaked09([]);
        setRow2Naked09([]);
        if (c9R1VNo === 11 && c9R2VNo === 11) {
          noHandleCount = noHandleCount - 2;
        }
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
      setC9R1VNo(11);
      setC9R2VNo(11);
      setC9r1MT("M");
      setC9r2MT("M");
      noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
    } else if (c9r1MT === "L") {
      setBaseNakedL09([]);
      if (c9R1VNo === 11 && c9R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM09(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
        ]
      )
      setC9R1VNo(11);
      setC9R2VNo(11);
      setC9r1MT("M");
      setC9r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
        ]
      )
      setC9R3VNo(11);
      setC9R4VNo(11);
      setC9r3MT("M");
      setC9r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (curX === col10X && curY === row01X) {
  if (c10r1MT === "M") { 
      setBaseNaked10([]);
      setRow2Naked10([]);
      if (c10R1VNo === 11 && c10R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
          )
      setC10R1VNo(11);
      setC10R2VNo(11);
      setC10r1MT("M");
      setC10r2MT("M");
      noHandleCount = noHandleCount + 1;
    } else if (c10r1MT === "S") {
      if (col10Row02 === true && c10r2MT === "S") {
          setBaseNaked10([]);
          setRow2Naked10([]);
          if (c10R1VNo === 11 && c10R2VNo === 11) {
            noHandleCount = noHandleCount - 2;
          }
          setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
        setC10R1VNo(11);
        setC10R2VNo(11);
        setC10r1MT("M");
        setC10r2MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
    } else if (c10r1MT === "L") {
      setBaseNakedL10([]);
      if (c10R1VNo === 11 && c10R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM10(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
        ]
      )
      setC10R1VNo(11);
      setC10R2VNo(11);
      setC10r1MT("M");
      setC10r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
        ]
      )
      setC10R3VNo(11);
      setC10R4VNo(11);
      setC10r3MT("M");
      setC10r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (curX === col11X && curY === row01X) {
  if (c11r1MT === "M") { 
        setBaseNaked11([]);
        setRow2Naked11([]);
        if (c11R1VNo === 11 && c11R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(11);
        setC11R2VNo(11);
        setC11r1MT("M");
        setC11r2MT("M");
        noHandleCount = noHandleCount + 1;
    } else if (c11r1MT === "S") {
      if (col11Row02 === true && c11r2MT === "S") {
        setBaseNaked11([]);
          setRow2Naked11([]);
          if (c11R1VNo === 11 && c11R2VNo === 11) {
            noHandleCount = noHandleCount - 2;
          }
          setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
        setC11R1VNo(11);
        setC11R2VNo(11);
        setC11r1MT("M");
        setC11r2MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
    } else if (c11r1MT === "L") {
      setBaseNakedL11([]);
      if (c11R1VNo === 11 && c11R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM11(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
        ]
      )
      setC11R1VNo(11);
      setC11R2VNo(11);
      setC11r1MT("M");
      setC11r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
        ]
      )
      setC11R3VNo(11);
      setC11R4VNo(11);
      setC11r3MT("M");
      setC11r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
          
} else if (curX === col12X && curY === row01X) {
  if (c12r1MT === "M") { 
        setBaseNaked12([]);
        setRow2Naked12([]);
        if (c12R1VNo === 11 && c12R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />  
          ]
        )
        setC12R1VNo(11);
        setC12R2VNo(11);
        setC12r1MT("M");
        setC12r2MT("M");
        noHandleCount = noHandleCount + 1;
    } else if (c12r1MT === "S") {
      if (col12Row02 === true && c12r2MT === "S") {
        setBaseNaked12([]);
        setRow2Naked12([]);
        if (c12R1VNo === 11 && c12R2VNo === 11) {
          noHandleCount = noHandleCount - 2;
        }
          setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />  
            ]
          )
        setC12R1VNo(11);
        setC12R2VNo(11);
        setC12r1MT("M");
        setC12r2MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
    } else if (c12r1MT === "L") {
      setBaseNakedL12([]);
      if (c12R1VNo === 11 && c12R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM12(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
        ]
      )
      setC12R1VNo(11);
      setC12R2VNo(11);
      setC12r1MT("M");
      setC12r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
        ]
      )
      setC12R3VNo(11);
      setC12R4VNo(11);
      setC12r3MT("M");
      setC12r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (curX === col13X && curY === row01X)  {
  if (c13r1MT === "M") { 
      setBaseNaked13([]);
      setRow2Naked13([]);
      if (c13R1VNo === 11 && c13R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM13(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />  
        ]
      )
    setC13R1VNo(11);
    setC13R2VNo(11);
    setC13r1MT("M");
    setC13r2MT("M");
    noHandleCount = noHandleCount + 1;
  } else if (c13r1MT === "S") {
    if (col13Row02 === true && c13r2MT === "S") {
      setBaseNaked13([]);
      setRow2Naked13([]);
      if (c13R1VNo === 11 && c13R2VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
      setBaseNakedM13(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />  
        ]
      )
      setC13R1VNo(11);
      setC13R2VNo(11);
      setC13r1MT("M");
      setC13r2MT("M");
      noHandleCount = noHandleCount + 1;
    } else {
      alert("Medium door cannot be added to just one small unit.")
    }
  } else if (c13r1MT === "L") {
    setBaseNakedL13([]);
    if (c13R1VNo === 11 && c13R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM13(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
      ]
    )
    setC13R1VNo(11);
    setC13R2VNo(11);
    setC13r1MT("M");
    setC13r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM13(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
      ]
    )
    setC13R3VNo(11);
    setC13R4VNo(11);
    setC13r3MT("M");
    setC13r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (curX === col14X && curY === row01X) {
  if (c14r1MT === "M") { 
      setBaseNaked14([]);
      setRow2Naked14([]);
      if (c14R1VNo === 11 && c14R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM14(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />  
        ]
      )
      setC14R1VNo(11);
      setC14R2VNo(11);
      setC14r1MT("M");
      setC14r2MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c14r1MT === "S") {
    if (col01Row03 === true && c14r2MT === "S") {
      setRow2Naked01([]);
      setRow3Naked01([]);
      if (c14R1VNo === 11 && c14R2VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
          setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
       setC1R2VNo(11);
      setC1R3VNo(11);
        setC1r2MT("M");
        setC1r3MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added to just one small unit.")
      }
  } else if (c14r1MT === "L") {
    setBaseNakedL14([]);
    if (c14R1VNo === 11 && c14R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM14(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
      ]
    )
    setC14R1VNo(11);
    setC14R2VNo(11);
    setC14r1MT("M");
    setC14r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM14(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
      ]
    )
    setC14R3VNo(11);
    setC14R4VNo(11);
    setC14r3MT("M");
    setC14r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
}  else if  (curX === col01X && curY === row02X) {
  if (c1r2MT === "M") { 
    if (c1r1MT === "S" && col01Row03 === true) { 
        setRow2Naked01([]);
        setRow3Naked01([]);
        if (c1R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
              ]
            )
        setC1R2VNo(11);
        setC1R3VNo(11);
        setC1r2MT("M");
        setC1r3MT("M");
        noHandleCount = noHandleCount + 1;
    } else if (c1r1MT === "M") {
          setBaseNaked01([]);
          setRow2Naked01([]);
          if (c1R2VNo === 11) {
            noHandleCount = noHandleCount - 1;
          }
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1r1MT("M");
          setC1r2MT("M");
          noHandleCount = noHandleCount + 1;
      }
  } else if (c1r2MT === "S") {
          setBaseNaked01([]);
          setRow2Naked01([]);
          if (c1R2VNo === 11 && c1R3VNo === 11 ) {
            noHandleCount = noHandleCount - 2;
          }
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1r1MT("M");
          setC1r2MT("M");
          noHandleCount = noHandleCount + 1;
  } else if (c1r2MT === "L") {
    setBaseNakedL01([]);
    if (c1R2VNo === 11 && c1R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM01(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
      ]
    )
    setC1R1VNo(11);
    setC1R2VNo(11);
    setC1r1MT("M");
    setC1r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM01(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
      ]
    )
    setC1R3VNo(11);
    setC1R4VNo(11);
    setC1r3MT("M");
    setC1r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (curX === col02X && curY === row02X) {
if (c2r2MT === "M") { 
    if (c2r1MT === "S" && col02Row03 === true) { 
      setRow3Naked02([]);
      setRow2Naked02([]);
      if (c2R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
      setC2R2VNo(11);
      setC2R3VNo(11);
      setC2r2MT("M");
      setC2r3MT("M");
      noHandleCount = noHandleCount + 1;
    } else if (c2r1MT === "M") {
      setBaseNaked02([]);
      setRow2Naked02([]);
      if (c2R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
          )
      setC2R1VNo(11);
      setC2R2VNo(11);
      setC2r1MT("M");
      setC2r2MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c2r2MT === "S") {
      setRow2Naked02([]);
      setRow3Naked02([]);
      if (c2R2VNo === 11 && c2R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
      setBaseNakedM02(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
        ]
        )
        setC2R1VNo(11);
        setC2R2VNo(11);
        setC2r1MT("M");
        setC2r2MT("M");
        noHandleCount = noHandleCount + 1;
  } else if (c2r2MT === "L") {
    setBaseNakedL02([]);
    if (c2R2VNo === 11 && c2R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM02(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
      ]
    )
    setC2R1VNo(11);
    setC2R2VNo(11);
    setC2r1MT("M");
    setC2r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM02(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
      ]
    )
    setC2R3VNo(11);
    setC2R4VNo(11);
    setC2r3MT("M");
    setC2r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col03X && curY === row02X) {
if (c3r2MT === "M") { 
    if (c3r1MT === "S" && col03Row03 === true) { 
        setRow3Naked03([]);
        setRow2Naked03([]);
        if (c3R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setRow2NakedM03(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />        
          ]
        )
        setC3R2VNo(11);
        setC3R3VNo(11);
        setC3r2MT("M");
        setC3r3MT("M");
        noHandleCount = noHandleCount + 1;
    } else if (c3r1MT === "M") { 
        setBaseNaked03([]);
        setRow2Naked03([]);
        if (c3R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setBaseNakedM03(
          [
          < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />        
          ]
        )
        setC3R1VNo(11);
        setC3R2VNo(11);
        setC3r1MT("M");
        setC3r2MT("M");
        noHandleCount = noHandleCount + 1;
    }
} else if (c3r2MT === "S") {
    setBaseNaked03([]);
    setRow2Naked03([]);
    if (c3R2VNo === 11 && c3R3VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
      setBaseNakedM03(
        [
        < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />        
        ]
      )
    setC3R1VNo(11);
    setC3R2VNo(11);
    setC3r1MT("M");
    setC3r2MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c3r2MT === "L") {
  setBaseNakedL03([]);
  if (c3R2VNo === 11 && c3R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM03(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
    ]
  )
  setC3R1VNo(11);
  setC3R2VNo(11);
  setC3r1MT("M");
  setC3r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM03(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
    ]
  )
  setC3R3VNo(11);
  setC3R4VNo(11);
  setC3r3MT("M");
  setC3r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col04X && curY === row02X) {
if (c4r2MT === "M") { 
  if (c4r1MT === "S" && col04Row03 === true) { 
        setRow3Naked04([]);
        setRow2Naked04([]);
        if (c4R2VNo === 11 && c4R3VNo === 11 ) {
          noHandleCount = noHandleCount - 1;
        }
        setRow2NakedM04(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />          
        ]
        )
        setC4R2VNo(11);
        setC4R3VNo(11);
        setC4r2MT("M");
        setC4r3MT("M");
        noHandleCount = noHandleCount + 1;
    } else if (c4r1MT === "M") { 
      setBaseNaked04([]);
      setRow2Naked04([]);
      if (c4R2VNo === 11 && c4R3VNo === 11 ) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM04(
          [
          < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />          
        ]
        )
      setC4R1VNo(11);
      setC4R2VNo(11);
      setC4r1MT("M");
      setC4r2MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (c4r2MT === "S") {
      setBaseNaked04([]);
        setRow2Naked04([]);
        if (c4R2VNo === 11 && c4R3VNo === 11 ) {
          noHandleCount = noHandleCount - 2;
        }
          setBaseNakedM04(
            [
            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />          
          ]
          )
      setC4R1VNo(11);
      setC4R2VNo(11);
      setC4r1MT("M");
      setC4r2MT("M");
      noHandleCount = noHandleCount + 1;
} else if (c4r2MT === "L") {
  setBaseNakedL04([]);
  if (c4R2VNo === 11 && c4R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM04(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
    ]
  )
  setC4R1VNo(11);
  setC4R2VNo(11);
  setC4r1MT("M");
  setC4r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM04(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
    ]
  )
  setC4R3VNo(11);
  setC4R4VNo(11);
  setC4r3MT("M");
  setC4r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col05X && curY === row02X) {
if (c5r2MT === "M") { 
  if (c5r1MT === "S" && col05Row03 === true) { 
      setRow3Naked05([]);
      setRow2Naked05([]);
      if (c5R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow2NakedM05(
        [
        < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />         
      ]
      )
      setC5R2VNo(11);
      setC5R3VNo(11);
      setC5r2MT("M");
      setC5r3MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c5r1MT === "M") { 
      setBaseNaked05([]);
      setRow2Naked05([]);
      if (c5R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM05(
          [
          < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />         
        ]
        )
      setC5R1VNo(11);
      setC5R2VNo(11);
      setC5r1MT("M");
      setC5r2MT("M");
      noHandleCount = noHandleCount + 1;
  }
} else if (c5r2MT === "S") {
    setBaseNaked05([]);
    setRow2Naked05([]);
    if (c5R2VNo === 11 && c5R3VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
      setBaseNakedM05(
        [
        < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />         
      ]
      )
    setC5R1VNo(11);
    setC5R2VNo(11);
    setC5r1MT("M");
    setC5r2MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c5r2MT === "L") {
  setBaseNakedL05([]);
  if (c5R2VNo === 11 && c5R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM05(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
    ]
  )
  setC5R1VNo(11);
  setC5R2VNo(11);
  setC5r1MT("M");
  setC5r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM05(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
    ]
  )
  setC5R3VNo(11);
  setC5R4VNo(11);
  setC5r3MT("M");
  setC5r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col06X && curY === row02X) {
if (c6r2MT === "M") { 
  if (c6r1MT === "S" && col06Row03 === true) { 
      setRow3Naked06([]);
      setRow2Naked06([]);
      if (c6R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow2NakedM06(
        [
        < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />        
        ]
      )
      setC6R2VNo(11);
      setC6R3VNo(11);
      setC6r2MT("M");
      setC6r3MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c6r1MT === "M") { 
    setBaseNaked06([]);
        setRow2Naked06([]);
        if (c6R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
          setBaseNakedM06(
            [
            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />        
            ]
          )
      setC6R1VNo(11);
      setC6R2VNo(11);
      setC6r1MT("M");
      setC6r2MT("M");
      noHandleCount = noHandleCount + 1;
  }
} else if (c6r2MT === "S") {
      setBaseNaked06([]);
        setRow2Naked06([]);
        if (c6R2VNo === 11 && c6R3VNo === 11 ) {
          noHandleCount = noHandleCount - 2;
        }
          setBaseNakedM06(
            [
            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />        
            ]
          )
      setC6R1VNo(11);
      setC6R2VNo(11);
      setC6r1MT("M");
      setC6r2MT("M");
      noHandleCount = noHandleCount + 1;
} else if (c6r2MT === "L") {
  setBaseNakedL06([]);
  if (c6R2VNo === 11 && c6R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM06(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
    ]
  )
  setC6R1VNo(11);
  setC6R2VNo(11);
  setC6r1MT("M");
  setC6r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM06(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
    ]
  )
  setC6R3VNo(11);
  setC6R4VNo(11);
  setC6r3MT("M");
  setC6r4MT("M");
  noHandleCount = noHandleCount + 1;
}
  
} else if (curX === col07X && curY === row02X) {
if (c7r2MT === "M") { 
  if (c7r1MT === "S" && col07Row03 === true) { 
      setRow3Naked07([]);
      setRow2Naked07([]);
      if (c7R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow2NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
        ]
        )
      setC7R2VNo(11);
      setC7R3VNo(11);
      setC7r2MT("M");
      setC7r3MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c7r1MT === "M") { 
      setBaseNaked07([]);
      setRow2Naked07([]);
      if (c7R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM07(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
        ]
      )
      setC7R1VNo(11);
      setC7R2VNo(11);
      setC7r1MT("M");
      setC7r2MT("M");
      noHandleCount = noHandleCount + 1;
  }
} else if (c7r2MT === "S") {   
  setBaseNaked07([]);
  setRow2Naked07([]);
  if (c7R2VNo === 11 && c7R3VNo === 11 ) {
    noHandleCount = noHandleCount - 2;
  }
  setBaseNakedM07(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
    ]
  )
  setC7R1VNo(11);
  setC7R2VNo(11);
  setC7r1MT("M");
  setC7r2MT("M");
  noHandleCount = noHandleCount + 1;
} else if (c7r2MT === "L") {
  setBaseNakedL07([]);
  if (c7R2VNo === 11 && c7R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM07(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
    ]
  )
  setC7R1VNo(11);
  setC7R2VNo(11);
  setC7r1MT("M");
  setC7r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM07(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
    ]
  )
  setC7R3VNo(11);
  setC7R4VNo(11);
  setC7r3MT("M");
  setC7r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col08X && curY === row02X) {
if (c8r2MT === "M") { 
  if (c8r1MT === "S" && col08Row03 === true) { 
      setRow3Naked08([]);
      setRow2Naked08([]);
      if (c8R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
      setC8R2VNo(11);
      setC8R3VNo(11);
      setC8r2MT("M");
      setC8r3MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c8r1MT === "M") { 
    setBaseNaked08([]);
    setRow2Naked08([]);
    if (c8R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setBaseNakedM08(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
        ]
      )
    setC8R1VNo(11);
    setC8R2VNo(11);
    setC8r1MT("M");
    setC8r2MT("M");
    noHandleCount = noHandleCount + 1;
  }
    
} else if (c8r2MT === "S") {  
  setBaseNaked08([]);
      setRow2Naked08([]);
      if (c8R2VNo === 11 && c8R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
    setC8R1VNo(11);
    setC8R2VNo(11);
    setC8r1MT("M");
    setC8r2MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c8r2MT === "L") {
  setBaseNakedL08([]);
  if (c8R2VNo === 11 && c8R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM08(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
    ]
  )
  setC8R1VNo(11);
  setC8R2VNo(11);
  setC8r1MT("M");
  setC8r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM08(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
    ]
  )
  setC8R3VNo(11);
  setC8R4VNo(11);
  setC8r3MT("M");
  setC8r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col09X && curY === row02X) {
if (c9r2MT === "M") { 
  if (c9r1MT === "S" && col09Row03 === true) { 
      setRow3Naked09([]);
      setRow2Naked09([]);
      if (c9R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
          )
      setC9R2VNo(11);
      setC9R3VNo(11);
      setC9r2MT("M");
      setC9r3MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c9r1MT === "M") { 
    setBaseNaked09([]);
    setRow2Naked09([]);
    if (c9R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setBaseNakedM09(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
        ]
      )
    setC9R1VNo(11);
    setC9R2VNo(11);
    setC9r1MT("M");
    setC9r2MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c9r2MT === "S") { 
      setBaseNaked09([]);
      setRow2Naked09([]);
      if (c9R2VNo === 11 && c9R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
      setC9R1VNo(11);
      setC9R2VNo(11);
      setC9r1MT("M");
      setC9r2MT("M");
      noHandleCount = noHandleCount + 1;
} else if (c9r2MT === "L") {
  setBaseNakedL09([]);
  if (c9R2VNo === 11 && c9R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM09(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
    ]
  )
  setC9R1VNo(11);
  setC9R2VNo(11);
  setC9r1MT("M");
  setC9r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM09(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
    ]
  )
  setC9R3VNo(11);
  setC9R4VNo(11);
  setC9r3MT("M");
  setC9r4MT("M");
}

} else if (curX === col10X && curY === row02X) {
if (c10r2MT === "M") { 
  if (c10r1MT === "S" && col10Row03 === true) { 
      setRow3Naked10([]);
      setRow2Naked10([]);
      if (c10R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow2NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
        ]
      )
      setC10R2VNo(11);
      setC10R3VNo(11);
      setC10r2MT("M");
      setC10r3MT("M");
      noHandleCount = noHandleCount + 1;
    } else if (c10r1MT === "M") { 
      setBaseNaked10([]);
      setRow2Naked10([]);
      if (c10R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
          )
      setC10R1VNo(11);
      setC10R2VNo(11);
      setC10r1MT("M");
      setC10r2MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (c10r2MT === "S") { 
    setBaseNaked10([]);
      setRow2Naked10([]);
      if (c10R2VNo === 11 && c10R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
          )
      setC10R1VNo(11);
      setC10R2VNo(11);
      setC10r1MT("M");
      setC10r2MT("M");
      noHandleCount = noHandleCount + 1;
} else if (c10r2MT === "L") {
  setBaseNakedL10([]);
  if (c10R2VNo === 11 && c10R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM10(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
    ]
  )
  setC10R1VNo(11);
  setC10R2VNo(11);
  setC10r1MT("M");
  setC10r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM10(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
    ]
  )
  setC10R3VNo(11);
  setC10R4VNo(11);
  setC10r3MT("M");
  setC10r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col11X && curY === row02X) {
if (c11r2MT === "M") { 
  if (c11r1MT === "S" && col11Row03 === true) { 
    setRow3Naked11([]);
    setRow2Naked11([]);
    if (c11R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow2NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
        ]
      )
    setC11R2VNo(11);
    setC11R3VNo(11);
    setC11r2MT("M");
    setC11r3MT("M");
    noHandleCount = noHandleCount + 1;
  } else if (c11r1MT === "M") { 
    setBaseNaked11([]);
    setRow2Naked11([]);
    if (c11R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM11(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
      ]
    )
    setC11R1VNo(11);
    setC11R2VNo(11);
    setC11r1MT("M");
    setC11r2MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c11r2MT === "S") { 
  setBaseNaked11([]);
  setRow2Naked11([]);
  if (c11R2VNo === 11 && c11R3VNo === 11 ) {
    noHandleCount = noHandleCount - 2;
  }
  setBaseNakedM11(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
    ]
  )
  setC11R1VNo(11);
  setC11R2VNo(11);
  setC11r1MT("M");
  setC11r2MT("M");
  noHandleCount = noHandleCount + 1;
} else if (c11r2MT === "L") {
  setBaseNakedL11([]);
  if (c11R2VNo === 11 && c11R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM11(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
    ]
  )
  setC11R1VNo(11);
  setC11R2VNo(11);
  setC11r1MT("M");
  setC11r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM11(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
    ]
  )
  setC11R3VNo(11);
  setC11R4VNo(11);
  setC11r3MT("M");
  setC11r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col12X && curY === row02X) {
if (c12r2MT === "M") { 
  if (c12r1MT === "S" && col12Row03 === true) { 
    setRow3Naked12([]);
    setRow2Naked12([]);
    if (c12R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow2NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />  
        ]
      )
    setC12R2VNo(11);
    setC12R3VNo(11);
    setC12r2MT("M");
    setC12r3MT("M");
    noHandleCount = noHandleCount + 1;
  } else if (c12r1MT === "M") { 
      setBaseNaked12([]);
        setRow2Naked12([]);
        if (c12R2VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />  
          ]
        )
        setC12R1VNo(11);
        setC12R2VNo(11);
        setC12r1MT("M");
        setC12r2MT("M");
        noHandleCount = noHandleCount + 1;
  }
} else if (c12r2MT === "S") { 
    setBaseNaked12([]);
    setRow2Naked12([]);
    if (c12R2VNo === 11 && c12R3VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />  
          ]
        )
        setC12R1VNo(11);
        setC12R2VNo(11);
        setC12r1MT("M");
        setC12r2MT("M");
        noHandleCount = noHandleCount + 1;
} else if (c12r2MT === "L") {
  setBaseNakedL12([]);
  if (c12R2VNo === 11 && c12R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM12(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
    ]
  )
  setC12R1VNo(11);
  setC12R2VNo(11);
  setC12r1MT("M");
  setC12r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM12(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
    ]
  )
  setC12R3VNo(11);
  setC12R4VNo(11);
  setC12r3MT("M");
  setC12r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col13X && curY === row02X)  {
if (c13r2MT === "M") { 
  if (c13r1MT === "S" && col13Row03 === true) { 
      setRow3Naked13([]);
      setRow2Naked13([]);
      if (c13R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow2NakedM13(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />  
        ]
      )
      setC13R1VNo(11);
      setC13R3VNo(11);
      setC13r2MT("M");
      setC13r3MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c13r1MT === "M") { 
    setBaseNaked13([]);
      setRow2Naked13([]);
      if (c13R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM13(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />  
        ]
      )
    setC13R1VNo(11);
    setC13R2VNo(11);
    setC13r1MT("M");
    setC13r2MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c13r2MT === "S") { 
    setBaseNaked13([]);
    setRow2Naked13([]);
    if (c13R2VNo === 11 && c13R3VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
    setBaseNakedM13(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />  
      ]
    )
    setC13R1VNo(11);
    setC13R2VNo(11);
    setC13r1MT("M");
    setC13r2MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c13r2MT === "L") {
  setBaseNakedL13([]);
  if (c13R2VNo === 11 && c13R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM13(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
    ]
  )
  setC13R1VNo(11);
  setC13R2VNo(11);
  setC13r1MT("M");
  setC13r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM13(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
    ]
  )
  setC13R3VNo(11);
  setC13R4VNo(11);
  setC13r3MT("M");
  setC13r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col14X && curY === row02X) {
if (c14r2MT === "M") { 
  if (c14r1MT === "S" && col14Row03 === true) { 
    setRow3Naked14([]);
    setRow2Naked14([]);
    if (c14R2VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow2NakedM14(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />  
      ]
    )
    setC14R2VNo(11);
    setC14R3VNo(11);
    setC14r2MT("M");
    setC14r3MT("M");
    noHandleCount = noHandleCount + 1;
  } else if (c14r1MT === "M") { 
    setBaseNaked14([]);
      setRow2Naked14([]);
      if (c14R2VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM14(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />  
        ]
      )
      setC14R1VNo(11);
      setC14R2VNo(11);
      setC14r1MT("M");
      setC14r2MT("M");
      noHandleCount = noHandleCount + 1;
  }
} else if (c14r2MT === "S") { 
    setBaseNaked14([]);
    setRow2Naked14([]);
    if (c14R2VNo === 11 && c14R3VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
    setBaseNakedM14(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />  
      ]
    )
    setC14R1VNo(11);
    setC14R2VNo(11);
    setC14r1MT("M");
    setC14r2MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c14r2MT === "L") {
  setBaseNakedL14([]);
  if (c14R2VNo === 11 && c14R3VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM14(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
    ]
  )
  setC14R1VNo(11);
  setC14R2VNo(11);
  setC14r1MT("M");
  setC14r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM14(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
    ]
  )
  setC14R3VNo(11);
  setC14R4VNo(11);
  setC14r3MT("M");
  setC14r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if  (curX === col01X && curY === row03X) {
if (c1r3MT === "M") { 
  if (c1r2MT === "S" && col01Row04 === true) { 
      setRow3Naked01([]);
      setRow4Naked01([]);
      if (c1R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow3NakedM01(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
        ]
      )
      setC1R3VNo(11);
      setC1R4VNo(11);
      setC1r3MT("M");
      setC1r4MT("M");
      noHandleCount = noHandleCount + 1;
       
  } else if (c1r2MT === "M") { 
      setRow2Naked01([]);
      setRow3Naked01([]);
      if (c1R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
          setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
      setC1R2VNo(11);
      setC1R3VNo(11);
      setC1r2MT("M");
      setC1r3MT("M");
      noHandleCount = noHandleCount + 1;
  }
} else if (c1r3MT === "S" && col01Row04 === true) { 
      setRow3Naked01([]);
      setRow4Naked01([]);
      if (c1R3VNo === 11 && c1R4VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
      setRow3NakedM01(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
        ]
      )
      setC1R3VNo(11);
      setC1R4VNo(11);
      setC1r3MT("M");
      setC1r4MT("M");
      noHandleCount = noHandleCount + 1;
} else if (c1r3MT === "L") {
  setBaseNakedL01([]);
  if (c1R3VNo === 11 && c1R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM01(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
    ]
  )
  setC1R1VNo(11);
  setC1R2VNo(11);
  setC1r1MT("M");
  setC1r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM01(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
    ]
  )
  setC1R3VNo(11);
  setC1R4VNo(11);
  setC1r3MT("M");
  setC1r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col02X && curY === row03X) {
if (c2r3MT === "M") { 
  if (c2r2MT === "S" && col02Row04 === true) { 
    setRow3Naked02([]);
    setRow4Naked02([]);
    if (c2R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow3NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
        ]
      )
    setC2R3VNo(11);
    setC2R4VNo(11);
    setC2r3MT("M");
    setC2r4MT("M");
    noHandleCount = noHandleCount + 1;

  } else if (c2r2MT === "M") { 
    setRow3Naked02([]);
    setRow2Naked02([]);
    if (c2R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow2NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
        ]
      )
    setC2R2VNo(11);
    setC2R3VNo(11);
    setC2r2MT("M");
    setC2r3MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c2r3MT === "S"  && col02Row04 === true) { 
  setRow3Naked02([]);
    setRow4Naked02([]);
    if (c2R3VNo === 11 && c2R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
      setRow3NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
        ]
      )
    setC2R3VNo(11);
    setC2R4VNo(11);
    setC2r3MT("M");
    setC2r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c2r3MT === "L") {
  setBaseNakedL02([]);
  if (c2R3VNo === 11 && c2R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM02(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
    ]
  )
  setC2R1VNo(11);
  setC2R2VNo(11);
  setC2r1MT("M");
  setC2r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM02(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
    ]
  )
  setC2R3VNo(11);
  setC2R4VNo(11);
  setC2r3MT("M");
  setC2r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col03X && curY === row03X) {
if (c3r3MT === "M") { 
  if (c3r2MT === "S" && col03Row04 === true) { 
    setRow3Naked03([]);
    setRow4Naked03([]);
    if (c3R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow3NakedM03(
      [
      < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />        
      ]
    )
    setC3R3VNo(11);
    setC3R4VNo(11);
    setC3r3MT("M");
    setC3r4MT("M");
    noHandleCount = noHandleCount + 1;
        
} else if (c3r2MT === "M") { 
    setRow2Naked03([]);
    if (c3R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow2NakedM03(
      [
      < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />        
      ]
    )
    setC3R2VNo(11);
    setC3R3VNo(11);
    setC3r2MT("M");
    setC3r3MT("M");
    noHandleCount = noHandleCount + 1;
    }
} else if (c3r3MT === "S" && col03Row04 === true) { 
  setRow3Naked03([]);
        setRow4Naked03([]);
        if (c3R3VNo === 11 && c3R4VNo === 11 ) {
          noHandleCount = noHandleCount - 2;
        }
        setRow3NakedM03(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />        
          ]
        )
        setC3R3VNo(11);
        setC3R4VNo(11);
        setC3r3MT("M");
        setC3r4MT("M");
        noHandleCount = noHandleCount + 1;
} else if (c3r3MT === "L") {
  setBaseNakedL03([]);
  if (c3R3VNo === 11 && c3R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM03(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
    ]
  )
  setC3R1VNo(11);
  setC3R2VNo(11);
  setC3r1MT("M");
  setC3r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM03(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
    ]
  )
  setC3R3VNo(11);
  setC3R4VNo(11);
  setC3r3MT("M");
  setC3r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col04X && curY === row03X) {
if (c4r3MT === "M") { 
  if (c4r2MT === "S" && col04Row04 === true) { 
    setRow3Naked04([]);
    setRow4Naked04([]);
    if (c4R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow3NakedM04(
        [
        < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />          
      ]
      )
    setC4R3VNo(11);
    setC4R4VNo(11);
    setC4r3MT("M");
    setC4r4MT("M");
    noHandleCount = noHandleCount + 1;

  } else if (c4r2MT === "M") { 
    setRow3Naked04([]);
    setRow2Naked04([]);
    if (c4R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow2NakedM04(
      [
      < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />          
    ]
    )
    setC4R2VNo(11);
    setC4R3VNo(11);
    setC4r2MT("M");
    setC4r3MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c4r3MT === "S"  && col04Row04 === true) { 
  setRow3Naked04([]);
    setRow4Naked04([]);
    if (c4R3VNo === 11 && c4R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
      setRow3NakedM04(
        [
        < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />          
      ]
      )
    setC4R3VNo(11);
    setC4R4VNo(11);
    setC4r3MT("M");
    setC4r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c4r3MT === "L") {
  setBaseNakedL04([]);
  if (c4R3VNo === 11 && c4R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM04(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
    ]
  )
  setC4R1VNo(11);
  setC4R2VNo(11);
  setC4r1MT("M");
  setC4r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM04(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
    ]
  )
  setC4R3VNo(11);
  setC4R4VNo(11);
  setC4r3MT("M");
  setC4r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col05X && curY === row03X) {
if (c5r3MT === "M") { 
  if (c5r2MT === "S" && col05Row04 === true) { 
    setRow3Naked05([]);
    setRow4Naked05([]);
    if (c5R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow3NakedM05(
        [
        < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />         
      ]
      )
    setC5R3VNo(11);
    setC5R4VNo(11);
    setC5r3MT("M");
    setC5r4MT("M");
    noHandleCount = noHandleCount + 1;
    
  } else if (c5r2MT === "M") { 
    setRow3Naked05([]);
    setRow2Naked05([]);
    if (c5R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow2NakedM05(
      [
      < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />         
    ]
    )
    setC5R2VNo(11);
    setC5R3VNo(11);
    setC5r2MT("M");
    setC5r3MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c5r3MT === "S" && col05Row04 === true) { 
  setRow3Naked05([]);
  setRow4Naked05([]);
  if (c5R3VNo === 11 && c5R4VNo === 11 ) {
    noHandleCount = noHandleCount - 2;
  }
    setRow3NakedM05(
      [
      < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />         
    ]
    )
  setC5R3VNo(11);
  setC5R4VNo(11);
  setC5r3MT("M");
  setC5r4MT("M");
  noHandleCount = noHandleCount + 1;
} else if (c5r3MT === "L") {
  setBaseNakedL05([]);
  if (c5R3VNo === 11 && c5R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM05(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
    ]
  )
  setC5R1VNo(11);
  setC5R2VNo(11);
  setC5r1MT("M");
  setC5r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM05(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
    ]
  )
  setC5R3VNo(11);
  setC5R4VNo(11);
  setC5r3MT("M");
  setC5r4MT("M");
  noHandleCount = noHandleCount + 1;
}
} else if (curX === col06X && curY === row03X) {
if (c6r3MT === "M") { 
  if (c6r2MT === "S" && col06Row04 === true) { 
    setRow3Naked06([]);
    setRow4Naked06([]);
    if (c6R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow3NakedM06(
        [
        < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />        
        ]
      )
    setC6R3VNo(11);
    setC6R4VNo(11);
    setC6r3MT("M");
    setC6r4MT("M");
    noHandleCount = noHandleCount + 1;
   
  } else if (c6r2MT === "M") { 
    setRow3Naked06([]);
    setRow2Naked06([]);
    if (c6R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow2NakedM06(
      [
      < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />         
    ]
    )
    setC6R2VNo(11);
    setC6R3VNo(11);
    setC6r2MT("M");
    setC6r3MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c6r3MT === "S" && col06Row04 === true) { 
  setRow3Naked06([]);
  setRow4Naked06([]);
  if (c6R3VNo === 11 && c6R4VNo === 11 ) {
    noHandleCount = noHandleCount - 2;
  }
    setRow3NakedM06(
      [
      < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />        
      ]
    )
  setC6R3VNo(11);
  setC6R4VNo(11);
  setC6r3MT("M");
  setC6r4MT("M");
  noHandleCount = noHandleCount + 1;
} else if (c6r3MT === "L") {
  setBaseNakedL06([]);
  if (c6R3VNo === 11 && c6R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM06(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
    ]
  )
  setC6R1VNo(11);
  setC6R2VNo(11);
  setC6r1MT("M");
  setC6r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM06(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
    ]
  )
  setC6R3VNo(11);
  setC6R4VNo(11);
  setC6r3MT("M");
  setC6r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col07X && curY === row03X) {
  if (c7r3MT === "M") { 
    if (c7r2MT === "S" && col07Row04 === true) { 
      setRow3Naked07([]);
      setRow4Naked07([]);
      if (c7R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow3NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
        ]
      )
      setC7R3VNo(11);
      setC7R4VNo(11);
      setC7r3MT("M");
      setC7r4MT("M");
      noHandleCount = noHandleCount + 1;
     
    } else if (c7r2MT === "M") { 
      setRow3Naked07([]);
      setRow2Naked07([]);
      if (c7R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow2NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
        ]
        )
      setC7R2VNo(11);
      setC7R3VNo(11);
      setC7r2MT("M");
      setC7r3MT("M");
      noHandleCount = noHandleCount + 1;
    }
} else if (c7r3MT === "S" && col07Row04 === true) { 
    setRow3Naked07([]);
    setRow4Naked07([]);
    if (c7R3VNo === 11 && c7R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
    setRow3NakedM07(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
      ]
    )
    setC7R3VNo(11);
    setC7R4VNo(11);
    setC7r3MT("M");
    setC7r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c7r3MT === "L") {
  setBaseNakedL07([]);
  if (c7R3VNo === 11 && c7R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM07(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
    ]
  )
  setC7R1VNo(11);
  setC7R2VNo(11);
  setC7r1MT("M");
  setC7r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM07(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
    ]
  )
  setC7R3VNo(11);
  setC7R4VNo(11);
  setC7r3MT("M");
  setC7r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col08X && curY === row03X) {
if (c8r3MT === "M") { 
  if (c8r2MT === "S" && col08Row04 === true) { 
    setRow3Naked08([]);
    setRow4Naked08([]);
    if (c8R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow3NakedM08(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
        ]
      )
    setC8R3VNo(11);
    setC8R4VNo(11);
    setC8r3MT("M");
    setC8r4MT("M");
    noHandleCount = noHandleCount + 1;
    
  } else if (c8r2MT === "M") { 
    setRow3Naked08([]);
      setRow2Naked08([]);
      if (c8R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
      setC8R2VNo(11);
      setC8R3VNo(11);
      setC8r2MT("M");
      setC8r3MT("M");
      noHandleCount = noHandleCount + 1;
  }
} else if (c8r3MT === "S" && col08Row04 === true) { 
  setRow3Naked08([]);
    setRow4Naked08([]);
    if (c8R3VNo === 11 && c8R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
      setRow3NakedM08(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
        ]
      )
    setC8R3VNo(11);
    setC8R4VNo(11);
    setC8r3MT("M");
    setC8r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c8r3MT === "L") {
  setBaseNakedL08([]);
  if (c8R3VNo === 11 && c8R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM08(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
    ]
  )
  setC8R1VNo(11);
  setC8R2VNo(11);
  setC8r1MT("M");
  setC8r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM08(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
    ]
  )
  setC8R3VNo(11);
  setC8R4VNo(11);
  setC8r3MT("M");
  setC8r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col09X && curY === row03X) {
if (c9r3MT === "M") { 
  if (c9r2MT === "S" && col09Row04 === true) { 
    setRow3Naked09([]);
    setRow4Naked09([]);
    if (c9R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow3NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
        ]
      )
    setC9R3VNo(11);
    setC9R4VNo(11);
    setC9r3MT("M");
    setC9r4MT("M");
    noHandleCount = noHandleCount + 1;
    
  } else if (c9r2MT === "M") { 
    setRow3Naked09([]);
      setRow2Naked09([]);
      if (c9R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
          )
      setC9R2VNo(11);
      setC9R3VNo(11);
      setC9r2MT("M");
      setC9r3MT("M");
      noHandleCount = noHandleCount + 1;
  } 
} else if (c9r3MT === "S" && col09Row04 === true) { 
    setRow3Naked09([]);
    setRow4Naked09([]);
    if (c9R3VNo === 11 && c9R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
      setRow3NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
        ]
      )
    setC9R3VNo(11);
    setC9R4VNo(11);
    setC9r3MT("M");
    setC9r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c9r3MT === "L") {
  setBaseNakedL09([]);
  if (c9R3VNo === 11 && c9R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM09(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
    ]
  )
  setC9R1VNo(11);
  setC9R2VNo(11);
  setC9r1MT("M");
  setC9r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM09(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
    ]
  )
  setC9R3VNo(11);
  setC9R4VNo(11);
  setC9r3MT("M");
  setC9r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col10X && curY === row03X) {
if (c10r3MT === "M") { 
 if (c10r2MT === "S" && col10Row04 === true) { 
  setRow3Naked10([]);
  setRow4Naked10([]);
  if (c10R3VNo === 11) {
    noHandleCount = noHandleCount - 1;
  }
  setRow3NakedM10(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
    ]
  )
  setC10R3VNo(11);
  setC10R4VNo(11);
  setC10r3MT("M");
  setC10r4MT("M");
  noHandleCount = noHandleCount + 1;
 } else if (c10r2MT === "M") { 
  setRow3Naked10([]);
  setRow2Naked10([]);
  if (c10R3VNo === 11) {
    noHandleCount = noHandleCount - 1;
  }
  setRow2NakedM10(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
    ]
  )
  setC10R2VNo(11);
  setC10R3VNo(11);
  setC10r2MT("M");
  setC10r3MT("M");
  noHandleCount = noHandleCount + 1;
   
  }
} else if (c10r3MT === "S" && col10Row04 === true) { 
    setRow3Naked10([]);
    setRow4Naked10([]);
    if (c10R3VNo === 11 && c10R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
    setRow3NakedM10(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
      ]
    )
    setC10R3VNo(11);
    setC10R4VNo(11);
    setC10r3MT("M");
    setC10r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c10r3MT === "L") {
  setBaseNakedL10([]);
  if (c10R3VNo === 11 && c10R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM10(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
    ]
  )
  setC10R1VNo(11);
  setC10R2VNo(11);
  setC10r1MT("M");
  setC10r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM10(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
    ]
  )
  setC10R3VNo(11);
  setC10R4VNo(11);
  setC10r3MT("M");
  setC10r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col11X && curY === row03X) {
if (c11r3MT === "M") { 
  if (c11r2MT === "S" && col11Row04 === true) { 
    setRow3Naked11([]);
      setRow4Naked11([]);
      if (c11R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
      setC11R3VNo(11);
      setC11R4VNo(11);
      setC11r3MT("M");
      setC11r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c11r2MT === "M") { 
    setRow3Naked11([]);
    setRow2Naked11([]);
    if (c11R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow2NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
        ]
      )
    setC11R2VNo(11);
    setC11R3VNo(11);
    setC11r2MT("M");
    setC11r3MT("M");
    noHandleCount = noHandleCount + 1;
      
    }
} else if (c11r3MT === "S" && col11Row04 === true) { 
    setRow3Naked11([]);
    setRow4Naked11([]);
    if (c11R3VNo === 11 && c11R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
      setRow3NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
        ]
      )
    setC11R3VNo(11);
    setC11R4VNo(11);
    setC11r3MT("M");
    setC11r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c11r3MT === "L") {
  setBaseNakedL11([]);
  if (c11R3VNo === 11 && c11R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM11(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
    ]
  )
  setC11R1VNo(11);
  setC11R2VNo(11);
  setC11r1MT("M");
  setC11r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM11(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
    ]
  )
  setC11R3VNo(11);
  setC11R4VNo(11);
  setC11r3MT("M");
  setC11r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col12X && curY === row03X) {
if (c12r3MT === "M") { 
  if (c12r2MT === "S" && col12Row04 === true) { 
    setRow3Naked12([]);
    setRow4Naked12([]);
    if (c12R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow3NakedM12(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />  
      ]
    )
    setC12R3VNo(11);
    setC12R4VNo(11);
    setC12r3MT("M");
    setC12r4MT("M");
    noHandleCount = noHandleCount + 1;
  } else if (c12r4MT === "M") { 

    setRow3Naked12([]);
    setRow2Naked12([]);
    if (c12R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
      setRow2NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />  
        ]
      )
    setC12R2VNo(11);
    setC12R3VNo(11);
    setC12r2MT("M");
    setC12r3MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c12r3MT === "S" && col12Row04 === true) { 
      setRow3Naked12([]);
      setRow4Naked12([]);
      if (c12R3VNo === 11 && c12R4VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
      setRow3NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />  
        ]
      )
    setC12R3VNo(11);
    setC12R4VNo(11);
    setC12r3MT("M");
    setC12r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c12r3MT === "L") {
  setBaseNakedL12([]);
  if (c12R3VNo === 11 && c12R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM12(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
    ]
  )
  setC12R1VNo(11);
  setC12R2VNo(11);
  setC12r1MT("M");
  setC12r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM12(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
    ]
  )
  setC12R3VNo(11);
  setC12R4VNo(11);
  setC12r3MT("M");
  setC12r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col13X && curY === row03X)  {
if (c13r3MT === "M") { 
  if (c13r2MT === "S" && col13Row04 === true) { 
    setRow3Naked13([]);
    setRow4Naked13([]);
    if (c13R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow3NakedM13(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />  
      ]
    )
    setC13R3VNo(11);
    setC13R4VNo(11);
    setC13r3MT("M");
    setC13r4MT("M");
    noHandleCount = noHandleCount + 1;

  } else if (c13r2MT === "M") { 
    setRow3Naked13([]);
    setRow2Naked13([]);
    if (c13R3VNo === 11) {
      noHandleCount = noHandleCount - 1;
    }
    setRow2NakedM13(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />  
      ]
    )
    setC13R1VNo(11);
    setC13R3VNo(11);
    setC13r2MT("M");
    setC13r3MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (c13r3MT === "S" && col13Row04 === true) { 
    setRow3Naked13([]);
    setRow4Naked13([]);
    if (c13R3VNo === 11 && c13R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
    setRow3NakedM13(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />  
      ]
    )
    setC13R3VNo(11);
    setC13R4VNo(11);
    setC13r3MT("M");
    setC13r4MT("M");
    noHandleCount = noHandleCount + 1;
} else if (c13r3MT === "L") {
  setBaseNakedL13([]);
  if (c13R3VNo === 11 && c13R4VNo === 11 ) {
    noHandleCount = noHandleCount - 1;
  }
  setBaseNakedM13(
    [
      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
    ]
  )
  setC13R1VNo(11);
  setC13R2VNo(11);
  setC13r1MT("M");
  setC13r2MT("M");
  noHandleCount = noHandleCount + 1;
  setRow3NakedM13(
    [
      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
    ]
  )
  setC13R3VNo(11);
  setC13R4VNo(11);
  setC13r3MT("M");
  setC13r4MT("M");
  noHandleCount = noHandleCount + 1;
}

} else if (curX === col14X && curY === row03X) {
if (c14r3MT === "M") { 
  if (c14r2MT === "S" && col14Row04 === true) { 
      setRow3Naked14([]);
      setRow4Naked14([]);
      if (c14R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow3NakedM14(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />  
        ]
      )
      setC14R3VNo(11);
      setC14R4VNo(11);
      setC14r3MT("M");
      setC14r4MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c14r2MT === "M") { 
     
      setRow3Naked14([]);
      setRow2Naked14([]);
      if (c14R3VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow2NakedM14(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />  
        ]
      )
      setC14R2VNo(11);
      setC14R3VNo(11);
      setC14r2MT("M");
      setC14r3MT("M");
      noHandleCount = noHandleCount + 1;
  } else if (c14r3MT === "L") {
    setBaseNakedL14([]);
    if (c14R3VNo === 11 && c14R4VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM14(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
      ]
    )
    setC14R1VNo(11);
    setC14R2VNo(11);
    setC14r1MT("M");
    setC14r2MT("M");
    noHandleCount = noHandleCount + 1;
    
    setRow3NakedM14(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
      ]
    )
    setC14R3VNo(11);
    setC14R4VNo(11);
    setC14r3MT("M");
    setC14r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

  } else if (c14r3MT === "S" && col14Row04 === true) { 
    setRow3Naked14([]);
    setRow4Naked14([]);
    if (c14R3VNo === 11 && c14R4VNo === 11 ) {
      noHandleCount = noHandleCount - 2;
    }
    setRow3NakedM14(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />  
      ]
    )
    setC14R3VNo(11);
    setC14R4VNo(11);
    setC14r3MT("M");
    setC14r4MT("M");
    noHandleCount = noHandleCount + 1;
  
  }
} else if  (curX === col01X && curY === row04X) {
  if (c1r4MT === "M") { 
        setRow3Naked01([]);
        setRow4Naked01([]);
        if (c1R4VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(11);
        setC1R4VNo(11);
        setC1r3MT("M");
        setC1r4MT("M");
        noHandleCount = noHandleCount + 1;
  } else if (c1r4MT === "S") { 
      if (c1r3MT === "S") { 
        setRow3Naked01([]);
        setRow4Naked01([]);
        if (c1R4VNo === 11 && c1R3VNo === 11 ) {
          noHandleCount = noHandleCount - 2;
        }
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(11);
        setC1R4VNo(11);
        setC1r3MT("M");
        setC1r4MT("M");
        noHandleCount = noHandleCount + 1;
      } else {
        alert("Medium door cannot be added here.");
      }

  } else if (c1r4MT === "L") {
    setBaseNakedL01([]);
    if (c1R4VNo === 11 && c1R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM01(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
      ]
    )
    setC1R1VNo(11);
    setC1R2VNo(11);
    setC1r1MT("M");
    setC1r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM01(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
      ]
    )
    setC1R3VNo(11);
    setC1R4VNo(11);
    setC1r3MT("M");
    setC1r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col02X && curY === row04X) {
  if (c2r4MT === "M") { 
      setRow3Naked02([]);
      setRow4Naked02([]);
      if (c2R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
      setC2R3VNo(11);
      setC2R4VNo(11);
      setC2r3MT("M");
      setC2r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c2r4MT === "S") { 
    if (c2r3MT === "S") { 
      setRow3Naked02([]);
      setRow4Naked02([]);
      if (c2R4VNo === 11 && c2R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }

        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
      setC2R3VNo(11);
      setC2R4VNo(11);
      setC2r3MT("M");
      setC2r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c2r4MT === "L") {
    setBaseNakedL02([]);
    if (c2R4VNo === 11 && c2R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM02(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
      ]
    )
    setC2R1VNo(11);
    setC2R2VNo(11);
    setC2r1MT("M");
    setC2r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM02(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
      ]
    )
    setC2R3VNo(11);
    setC2R4VNo(11);
    setC2r3MT("M");
    setC2r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col03X && curY === row04X) {
  if (c3r4MT === "M") { 
          setRow3Naked03([]);
          setRow4Naked03([]);
          if (c3R4VNo === 11) {
            noHandleCount = noHandleCount - 1;
          }
          setRow3NakedM03(
            [
            < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />        
            ]
          )
          setC3R3VNo(11);
          setC3R4VNo(11);
          setC3r3MT("M");
          setC3r4MT("M");
          noHandleCount = noHandleCount + 1;
  } else if (c3r4MT === "S") { 
    if (c3r3MT === "S") { 
          setRow3Naked03([]);
          setRow4Naked03([]);
          if (c3R4VNo === 11 && c3R3VNo === 11 ) {
            noHandleCount = noHandleCount - 2;
          }
          setRow3NakedM03(
            [
            < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />        
            ]
          )
          setC3R3VNo(11);
          setC3R4VNo(11);
          setC3r3MT("M");
          setC3r4MT("M");
          noHandleCount = noHandleCount + 1;
    }
  } else if (c3r4MT === "L") {
    setBaseNakedL03([]);
    if (c3R4VNo === 11 && c3R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM03(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
      ]
    )
    setC3R1VNo(11);
    setC3R2VNo(11);
    setC3r1MT("M");
    setC3r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM03(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
      ]
    )
    setC3R3VNo(11);
    setC3R4VNo(11);
    setC3r3MT("M");
    setC3r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col04X && curY === row04X) {
  if (c4r4MT === "M") { 
          setRow3Naked04([]);
        setRow4Naked04([]);
        if (c4R4VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
          setRow3NakedM04(
            [
            < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />          
          ]
          )
        setC4R3VNo(11);
        setC4R4VNo(11);
        setC4r3MT("M");
        setC4r4MT("M");
        noHandleCount = noHandleCount + 1;
    
  } else if (c4r4MT === "S") { 
    if (c4r3MT === "S") { 
      setRow3Naked04([]);
      setRow4Naked04([]);
      if (c4R4VNo === 11 && c4R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setRow3NakedM04(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />          
        ]
        )
      setC4R3VNo(11);
      setC4R4VNo(11);
      setC4r3MT("M");
      setC4r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c4r4MT === "L") {
    setBaseNakedL04([]);
    if (c4R4VNo === 11 && c4R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM04(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
      ]
    )
    setC4R1VNo(11);
    setC4R2VNo(11);
    setC4r1MT("M");
    setC4r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM04(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
      ]
    )
    setC4R3VNo(11);
    setC4R4VNo(11);
    setC4r3MT("M");
    setC4r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col05X && curY === row04X) {
  if (c5r4MT === "M") { 
      setRow3Naked05([]);
      setRow4Naked05([]);
      if (c5R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow3NakedM05(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />         
        ]
        )
      setC5R3VNo(11);
      setC5R4VNo(11);
      setC5r3MT("M");
      setC5r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c5r4MT === "S") { 
    if (c5r3MT === "S") { 
      setRow3Naked05([]);
      setRow4Naked05([]);
      if (c5R4VNo === 11 && c5R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setRow3NakedM05(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />         
        ]
        )
      setC5R3VNo(11);
      setC5R4VNo(11);
      setC5r3MT("M");
      setC5r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c5r4MT === "L") {
    setBaseNakedL05([]);
    if (c5R4VNo === 11 && c5R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM05(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
      ]
    )
    setC5R1VNo(11);
    setC5R2VNo(11);
    setC5r1MT("M");
    setC5r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM05(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
      ]
    )
    setC5R3VNo(11);
    setC5R4VNo(11);
    setC5r3MT("M");
    setC5r4MT("M");
    noHandleCount = noHandleCount + 1;
  }
} else if (curX === col06X && curY === row04X) {
  if (c6r4MT === "M") { 
      setRow3Naked06([]);
      setRow4Naked06([]);
      if (c6R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow3NakedM06(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />        
          ]
        )
      setC6R3VNo(11);
      setC6R4VNo(11);
      setC6r3MT("M");
      setC6r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c6r4MT === "S") { 
    if (c6r3MT === "S") { 
      setRow3Naked06([]);
      setRow4Naked06([]);
      if (c6R4VNo === 11 && c6R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setRow3NakedM06(
          [
          < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />        
          ]
        )
      setC6R3VNo(11);
      setC6R4VNo(11);
      setC6r3MT("M");
      setC6r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c6r4MT === "L") {
    setBaseNakedL06([]);
    if (c6R4VNo === 11 && c6R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM06(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
      ]
    )
    setC6R1VNo(11);
    setC6R2VNo(11);
    setC6r1MT("M");
    setC6r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM06(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
      ]
    )
    setC6R3VNo(11);
    setC6R4VNo(11);
    setC6r3MT("M");
    setC6r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col07X && curY === row04X) {
    if (c7r4MT === "M") { 
          setRow3Naked07([]);
          setRow4Naked07([]);
          if (c7R4VNo === 11) {
            noHandleCount = noHandleCount - 1;
          }
          setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(11);
          setC7R4VNo(11);
          setC7r3MT("M");
          setC7r4MT("M");
          noHandleCount = noHandleCount + 1;
      
  } else if (c7r4MT === "S") { 
    if (c7r3MT === "S") { 
      setRow3Naked07([]);
      setRow4Naked07([]);
      if (c7R4VNo === 11 && c7R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
      setRow3NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
        ]
      )
      setC7R3VNo(11);
      setC7R4VNo(11);
      setC7r3MT("M");
      setC7r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c7r4MT === "L") {
    setBaseNakedL07([]);
    if (c7R4VNo === 11 && c7R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM07(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
      ]
    )
    setC7R1VNo(11);
    setC7R2VNo(11);
    setC7r1MT("M");
    setC7r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM07(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
      ]
    )
    setC7R3VNo(11);
    setC7R4VNo(11);
    setC7r3MT("M");
    setC7r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col08X && curY === row04X) {
  if (c8r4MT === "M") { 
      setRow3Naked08([]);
      setRow4Naked08([]);
      if (c8R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
      setC8R3VNo(11);
      setC8R4VNo(11);
      setC8r3MT("M");
      setC8r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c8r4MT === "S") { 
    if (c8r3MT === "S") { 
      setRow3Naked08([]);
      setRow4Naked08([]);
      if (c8R4VNo === 11 && c8R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
      setC8R3VNo(11);
      setC8R4VNo(11);
      setC8r3MT("M");
      setC8r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c8r4MT === "L") {
    setBaseNakedL08([]);
    if (c8R4VNo === 11 && c8R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM08(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
      ]
    )
    setC8R1VNo(11);
    setC8R2VNo(11);
    setC8r1MT("M");
    setC8r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM08(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
      ]
    )
    setC8R3VNo(11);
    setC8R4VNo(11);
    setC8r3MT("M");
    setC8r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col09X && curY === row04X) {
  if (c9r4MT === "M") { 
      setRow3Naked09([]);
      setRow4Naked09([]);
      if (c9R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
      setC9R3VNo(11);
      setC9R4VNo(11);
      setC9r3MT("M");
      setC9r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c9r4MT === "S") { 
    if (c9r3MT === "S") { 
      setRow3Naked09([]);
      setRow4Naked09([]);
      if (c9R4VNo === 11 && c9R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
      setC9R3VNo(11);
      setC9R4VNo(11);
      setC9r3MT("M");
      setC9r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c9r4MT === "L") {
    setBaseNakedL09([]);
    if (c9R4VNo === 11 && c9R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM09(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
      ]
    )
    setC9R1VNo(11);
    setC9R2VNo(11);
    setC9r1MT("M");
    setC9r2MT("M");
    noHandleCount = noHandleCount + 1;
    
    setRow3NakedM09(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
      ]
    )
    setC9R3VNo(11);
    setC9R4VNo(11);
    setC9r3MT("M");
    setC9r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col10X && curY === row04X) {
  if (c10r4MT === "M") { 
      setRow3Naked10([]);
      setRow4Naked10([]);
      if (c10R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow3NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
        ]
      )
      setC10R3VNo(11);
      setC10R4VNo(11);
      setC10r3MT("M");
      setC10r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c10r4MT === "S") { 
    if (c10r3MT === "S") { 
      setRow3Naked10([]);
      setRow4Naked10([]);
      if (c10R4VNo === 11 && c10R3VNo === 11 ) {
        noHandleCount = noHandleCount - 2;
      }
      setRow3NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
        ]
      )
      setC10R3VNo(11);
      setC10R4VNo(11);
      setC10r3MT("M");
      setC10r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c10r4MT === "L") {
    setBaseNakedL10([]);
    if (c10R4VNo === 11 && c10R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM10(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
      ]
    )
    setC10R1VNo(11);
    setC10R2VNo(11);
    setC10r1MT("M");
    setC10r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM10(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
      ]
    )
    setC10R3VNo(11);
    setC10R4VNo(11);
    setC10r3MT("M");
    setC10r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col11X && curY === row04X) {
  if (c11r4MT === "M") { 
        setRow3Naked11([]);
        setRow4Naked11([]);
        if (c11R4VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
          setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
        setC11R3VNo(11);
        setC11R4VNo(11);
        setC11r3MT("M");
        setC11r4MT("M");
        noHandleCount = noHandleCount + 1;
      
  } else if (c11r4MT === "S") { 
        if (c11r3MT === "S") { 
          setRow3Naked11([]);
          setRow4Naked11([]);
          if (c11R4VNo === 11 && c11R3VNo === 11 ) {
            noHandleCount = noHandleCount - 2;
          }
            setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
              ]
            )
          setC11R3VNo(11);
          setC11R4VNo(11);
          setC11r3MT("M");
          setC11r4MT("M");
          noHandleCount = noHandleCount + 1;
        }
  } else if (c11r4MT === "L") {
    setBaseNakedL11([]);
    if (c11R4VNo === 11 && c11R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM11(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
      ]
    )
    setC11R1VNo(11);
    setC11R2VNo(11);
    setC11r1MT("M");
    setC11r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM11(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
      ]
    )
    setC11R3VNo(11);
    setC11R4VNo(11);
    setC11r3MT("M");
    setC11r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col12X && curY === row04X) {
  if (c12r4MT === "M") { 
      setRow3Naked12([]);
      setRow4Naked12([]);
      if (c12R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow3NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />  
        ]
      )
      setC12R3VNo(11);
      setC12R4VNo(11);
      setC12r3MT("M");
      setC12r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c12r4MT === "S") { 
    if (c12r3MT === "S") { 
        setRow3Naked12([]);
        setRow4Naked12([]);
        if (c12R4VNo === 11 && c12R3VNo === 11 ) {
          noHandleCount = noHandleCount - 2;
        }
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />  
          ]
        )
      setC12R3VNo(11);
      setC12R4VNo(11);
      setC12r3MT("M");
      setC12r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  } else if (c12r4MT === "L") {
    setBaseNakedL12([]);
    if (c12R4VNo === 11 && c12R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM12(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
      ]
    )
    setC12R1VNo(11);
    setC12R2VNo(11);
    setC12r1MT("M");
    setC12r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM12(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
      ]
    )
    setC12R3VNo(11);
    setC12R4VNo(11);
    setC12r3MT("M");
    setC12r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col13X && curY === row04X)  {
  if (c13r4MT === "M") { 
      setRow3Naked13([]);
      setRow4Naked13([]);
      if (c13R4VNo === 11) {
        noHandleCount = noHandleCount - 1;
      }
      setRow3NakedM13(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />  
        ]
      )
      setC13R3VNo(11);
      setC13R4VNo(11);
      setC13r3MT("M");
      setC13r4MT("M");
      noHandleCount = noHandleCount + 1;
    
  } else if (c13r4MT === "S") { 
      if (c13r3MT === "S") { 
        setRow3Naked13([]);
        setRow4Naked13([]);
        if (c13R4VNo === 11 && c13R3VNo === 11 ) {
          noHandleCount = noHandleCount - 2;
        }
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />  
          ]
        )
        setC13R3VNo(11);
        setC13R4VNo(11);
        setC13r3MT("M");
        setC13r4MT("M");
        noHandleCount = noHandleCount + 1;
      }
  } else if (c13r4MT === "L") {
    setBaseNakedL13([]);
    if (c13R4VNo === 11 && c13R3VNo === 11 ) {
      noHandleCount = noHandleCount - 1;
    }
    setBaseNakedM13(
      [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
      ]
    )
    setC13R1VNo(11);
    setC13R2VNo(11);
    setC13r1MT("M");
    setC13r2MT("M");
    noHandleCount = noHandleCount + 1;
    setRow3NakedM13(
      [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
      ]
    )
    setC13R3VNo(11);
    setC13R4VNo(11);
    setC13r3MT("M");
    setC13r4MT("M");
    noHandleCount = noHandleCount + 1;
  }

} else if (curX === col14X && curY === row04X) {
  if (c14r4MT === "M") { 
        setRow3Naked14([]);
        setRow4Naked14([]);
        if (c14R4VNo === 11) {
          noHandleCount = noHandleCount - 1;
        }
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />  
          ]
        )
        setC14R3VNo(11);
        setC14R4VNo(11);
        setC14r3MT("M");
        setC14r4MT("M");
        noHandleCount = noHandleCount + 1;

    } else if (c14r3MT === "S") { 
        if (c14r3MT === "S") { 
          setRow3Naked14([]);
          setRow4Naked14([]);
          if (c14R4VNo === 11 && c14R3VNo === 11 ) {
            noHandleCount = noHandleCount - 2;
          }
          setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />  
            ]
          )
          setC14R3VNo(11);
          setC14R4VNo(11);
          setC14r3MT("M");
          setC14r4MT("M");
          noHandleCount = noHandleCount + 1;
        }
      
    } else if (c14r4MT === "L") {
      setBaseNakedL14([]);
      if (c14R4VNo === 11 && c14R3VNo === 11 ) {
        noHandleCount = noHandleCount - 1;
      }
      setBaseNakedM14(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
        ]
      )
      setC14R1VNo(11);
      setC14R2VNo(11);
      setC14r1MT("M");
      setC14r2MT("M");
      noHandleCount = noHandleCount + 1;
      setRow3NakedM14(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
        ]
      )
      setC14R3VNo(11);
      setC14R4VNo(11);
      setC14r3MT("M");
      setC14r4MT("M");
      noHandleCount = noHandleCount + 1;
    }
  }
}

//Adding Large door
const addLargeDoors = (e)=> {
  if  (curX === col01X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
   
     if (col01Row01 === true && col01Row02 === true && col01Row03 === true && col01Row04 === true) {
      if (c1r1MT === "L") {
        if (c1R1VNo === 11) {
          return;
        }
      }
        setBaseNaked01([]);
        setRow2Naked01([]);
        setRow3Naked01([]);
        setRow4Naked01([]);
        if (c1r1MT === "S" && c1r2MT === "S" && c1r3MT === "S" && c1r4MT === "S") {
          if (c1R1VNo === 11 && c1R2VNo === 11 && c1R3VNo === 11 && c1R4VNo === 11) {
            noHandleCount = noHandleCount - 4;
          }
        }
        
        setBaseNakedM01([]);
        setRow2NakedM01([]);
        setRow3NakedM01([]);
        if (c1r1MT === "M" && c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "M") {
          if (c1R1VNo === 11 && c1R2VNo === 11 && c1R3VNo === 11 && c1R4VNo === 11) {
            noHandleCount = noHandleCount - 2;
          }
        }
          setBaseNakedL01(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                 ]
             )
           setC1R1VNo(11);
           setC1R2VNo(11);
           setC1R3VNo(11);
           setC1R4VNo(11);
           setC1r1MT("L")
           setC1r2MT("L")
           setC1r3MT("L")
           setC1r4MT("L")
           noHandleCount = noHandleCount + 1;
        }
 
 } else if (curX === col02X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col02Row01 === true && col02Row02 === true && col02Row03 === true && col02Row04 === true) {
    if (c2r1MT === "L") {
      if (c2R1VNo === 11) {
        return;
      }
    }
    setBaseNaked02([]);
    setRow2Naked02([]);
    setRow3Naked02([]);
    setRow4Naked02([]);
    if (c2r1MT === "S" && c2r2MT === "S" && c2r3MT === "S" && c2r4MT === "S") {
      if (c2R1VNo === 11 && c2R2VNo === 11 && c2R3VNo === 11 && c2R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM02([]);
    setRow2NakedM02([]);
    setRow3NakedM02([]);
    if (c2r1MT === "M" && c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "M") {
      if (c2R1VNo === 11 && c2R2VNo === 11 && c2R3VNo === 11 && c2R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL02(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                 ]
             )
           setC2R1VNo(11);
           setC2R2VNo(11);
           setC2R3VNo(11);
           setC2R4VNo(11);
           setC2r1MT("L")
           setC2r2MT("L")
           setC2r3MT("L")
           setC2r4MT("L")
           noHandleCount = noHandleCount + 1;
    }
 } else if (curX === col03X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col03Row01 === true && col03Row02 === true && col03Row03 === true && col03Row04 === true) {
    if (c3r1MT === "L") {
      if (c3R1VNo === 11) {
        return;
      }
    }
    setBaseNaked03([]);
    setRow2Naked03([]);
    setRow3Naked03([]);
    setRow4Naked03([]);
    if (c3r1MT === "S" && c3r2MT === "S" && c3r3MT === "S" && c3r4MT === "S") {
      if (c3R1VNo === 11 && c3R2VNo === 11 && c3R3VNo === 11 && c3R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM03([]);
    setRow2NakedM03([]);
    setRow3NakedM03([]);
    if (c3r1MT === "M" && c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "M") {
      if (c3R1VNo === 11 && c3R2VNo === 11 && c3R3VNo === 11 && c3R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL03(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                 ]
             )
           setC3R1VNo(11);
           setC3R2VNo(11);
           setC3R3VNo(11);
           setC3R4VNo(11);
           setC3r1MT("L")
           setC3r2MT("L")
           setC3r3MT("L")
           setC3r4MT("L")
           noHandleCount = noHandleCount + 1;
     }
 } else if (curX === col04X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col04Row01 === true && col04Row02 === true && col04Row03 === true && col04Row04 === true) {
    if (c4r1MT === "L") {
      if (c4R1VNo === 11) {
        return;
      }
    }
    setBaseNaked04([]);
    setRow2Naked04([]);
    setRow3Naked04([]);
    setRow4Naked04([]);
    if (c4r1MT === "S" && c4r2MT === "S" && c4r3MT === "S" && c4r4MT === "S") {
      if (c4R1VNo === 11 && c4R2VNo === 11 && c4R3VNo === 11 && c4R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM04([]);
    setRow2NakedM04([]);
    setRow3NakedM04([]);
    if (c4r1MT === "M" && c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "M") {
      if (c4R1VNo === 11 && c4R2VNo === 11 && c4R3VNo === 11 && c4R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL04(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                 ]
             )
           setC4R1VNo(11);
           setC4R2VNo(11);
           setC4R3VNo(11);
           setC4R4VNo(11);
           setC4r1MT("L")
           setC4r2MT("L")
           setC4r3MT("L")
           setC4r4MT("L")
           noHandleCount = noHandleCount + 1;
      }
 } else if (curX === col05X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col05Row01 === true && col05Row02 === true && col05Row03 === true && col05Row04 === true) {
    if (c5r1MT === "L") {
      if (c5R1VNo === 11) {
        return;
      }
    }
    setBaseNaked05([]);
    setRow2Naked05([]);
    setRow3Naked05([]);
    setRow4Naked05([]);
    if (c5r1MT === "S" && c5r2MT === "S" && c5r3MT === "S" && c5r4MT === "S") {
      if (c5R1VNo === 11 && c5R2VNo === 11 && c5R3VNo === 11 && c5R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM05([]);
    setRow2NakedM05([]);
    setRow3NakedM05([]);
    if (c5r1MT === "M" && c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "M") {
      if (c5R1VNo === 11 && c5R2VNo === 11 && c5R3VNo === 11 && c5R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL05(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                 ]
             )
           setC5R1VNo(11);
           setC5R2VNo(11);
           setC5R3VNo(11);
           setC5R4VNo(11);
           setC5r1MT("L")
           setC5r2MT("L")
           setC5r3MT("L")
           setC5r4MT("L")
           noHandleCount = noHandleCount + 1;
    }
 } else if (curX === col06X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col06Row01 === true && col06Row02 === true && col06Row03 === true && col06Row04 === true) {
    if (c6r1MT === "L") {
      if (c6R1VNo === 11) {
        return;
      }
    }
    setBaseNaked06([]);
    setRow2Naked06([]);
    setRow3Naked06([]);
    setRow4Naked06([]);
    if (c6r1MT === "S" && c6r2MT === "S" && c6r3MT === "S" && c6r4MT === "S") {
      if (c6R1VNo === 11 && c6R2VNo === 11 && c6R3VNo === 11 && c6R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM06([]);
    setRow2NakedM06([]);
    setRow3NakedM06([]);
    if (c6r1MT === "M" && c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "M") {
      if (c6R1VNo === 11 && c6R2VNo === 11 && c6R3VNo === 11 && c6R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL06(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                 ]
             )
           setC6R1VNo(11);
           setC6R2VNo(11);
           setC6R3VNo(11);
           setC6R4VNo(11);
           setC6r1MT("L")
           setC6r2MT("L")
           setC6r3MT("L")
           setC6r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col07X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col07Row01 === true && col07Row02 === true && col07Row03 === true && col07Row04 === true) {
    if (c7r1MT === "L") {
      if (c7R1VNo === 11) {
        return;
      }
    }
    setBaseNaked07([]);
    setRow2Naked07([]);
    setRow3Naked07([]);
    setRow4Naked07([]);
    if (c7r1MT === "S" && c7r2MT === "S" && c7r3MT === "S" && c7r4MT === "S") {
      if (c7R1VNo === 11 && c7R2VNo === 11 && c7R3VNo === 11 && c7R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM07([]);
    setRow2NakedM07([]);
    setRow3NakedM07([]);
    if (c7r1MT === "M" && c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "M") {
      if (c7R1VNo === 11 && c7R2VNo === 11 && c7R3VNo === 11 && c7R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL07(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                 ]
             )
           setC7R1VNo(11);
           setC7R2VNo(11);
           setC7R3VNo(11);
           setC7R4VNo(11);
           setC7r1MT("L")
           setC7r2MT("L")
           setC7r3MT("L")
           setC7r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col08X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col08Row01 === true && col08Row02 === true && col08Row03 === true && col08Row04 === true) {
    if (c8r1MT === "L") {
      if (c8R1VNo === 11) {
        return;
      }
    }
    setBaseNaked08([]);
    setRow2Naked08([]);
    setRow3Naked08([]);
    setRow4Naked08([]);
    if (c8r1MT === "S" && c8r2MT === "S" && c8r3MT === "S" && c8r4MT === "S") {
      if (c8R1VNo === 11 && c8R2VNo === 11 && c8R3VNo === 11 && c8R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM08([]);
    setRow2NakedM08([]);
    setRow3NakedM08([]);
    if (c8r1MT === "M" && c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "M") {
      if (c8R1VNo === 11 && c8R2VNo === 11 && c8R3VNo === 11 && c8R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL08(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                 ]
             )
           setC8R1VNo(11);
           setC8R2VNo(11);
           setC8R3VNo(11);
           setC8R4VNo(11);
           setC8r1MT("L")
           setC8r2MT("L")
           setC8r3MT("L")
           setC8r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col09X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col09Row01 === true && col09Row02 === true && col09Row03 === true && col09Row04 === true) {
    if (c9r1MT === "L") {
      if (c9R1VNo === 11) {
        return;
      }
    }
    setBaseNaked09([]);
    setRow2Naked09([]);
    setRow3Naked09([]);
    setRow4Naked09([]);
    if (c9r1MT === "S" && c9r2MT === "S" && c9r3MT === "S" && c9r4MT === "S") {
      if (c9R1VNo === 11 && c9R2VNo === 11 && c9R3VNo === 11 && c9R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM09([]);
    setRow2NakedM09([]);
    setRow3NakedM09([]);
    if (c9r1MT === "M" && c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "M") {
      if (c9R1VNo === 11 && c9R2VNo === 11 && c9R3VNo === 11 && c9R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL09(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                 ]
             )
           setC9R1VNo(11);
           setC9R2VNo(11);
           setC9R3VNo(11);
           setC9R4VNo(11);
           setC9r1MT("L")
           setC9r2MT("L")
           setC9r3MT("L")
           setC9r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col10X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col10Row01 === true && col10Row02 === true && col10Row03 === true && col10Row04 === true) {
    if (c10r1MT === "L") {
      if (c10R1VNo === 11) {
        return;
      }
    }
    setBaseNaked10([]);
    setRow2Naked10([]);
    setRow3Naked10([]);
    setRow4Naked10([]);
    if (c10r1MT === "S" && c10r2MT === "S" && c10r3MT === "S" && c10r4MT === "S") {
      if (c10R1VNo === 11 && c10R2VNo === 11 && c10R3VNo === 11 && c10R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM10([]);
    setRow2NakedM10([]);
    setRow3NakedM10([]);
    if (c10r1MT === "M" && c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "M") {
      if (c10R1VNo === 11 && c10R2VNo === 11 && c10R3VNo === 11 && c10R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL10(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                 ]
             )
           setC10R1VNo(11);
           setC10R2VNo(11);
           setC10R3VNo(11);
           setC10R4VNo(11);

           setC10r1MT("L")
           setC10r2MT("L")
           setC10r3MT("L")
           setC10r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col11X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col11Row01 === true && col11Row02 === true && col11Row03 === true && col11Row04 === true) {
    if (c11r1MT === "L") {
      if (c11R1VNo === 11) {
        return;
      }
    }
    setBaseNaked11([]);
    setRow2Naked11([]);
    setRow3Naked11([]);
    setRow4Naked11([]);
    if (c11r1MT === "S" && c11r2MT === "S" && c11r3MT === "S" && c11r4MT === "S") {
      if (c11R1VNo === 11 && c11R2VNo === 11 && c11R3VNo === 11 && c11R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM11([]);
    setRow2NakedM11([]);
    setRow3NakedM11([]);
    if (c11r1MT === "M" && c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "M") {
      if (c11R1VNo === 11 && c11R2VNo === 11 && c11R3VNo === 11 && c11R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL11(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                 ]
             )
           setC11R1VNo(11);
           setC11R2VNo(11);
           setC11R3VNo(11);
           setC11R4VNo(11);
           setC11r1MT("L")
           setC11r2MT("L")
           setC11r3MT("L")
           setC11r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col12X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col12Row01 === true && col12Row02 === true && col12Row03 === true && col12Row04 === true) {
    if (c12r1MT === "L") {
      if (c12R1VNo === 11) {
        return;
      }
    }
    setBaseNaked12([]);
    setRow2Naked12([]);
    setRow3Naked12([]);
    setRow4Naked12([]);
    if (c12r1MT === "S" && c12r2MT === "S" && c12r3MT === "S" && c12r4MT === "S") {
      if (c12R1VNo === 11 && c12R2VNo === 11 && c12R3VNo === 11 && c12R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM12([]);
    setRow2NakedM12([]);
    setRow3NakedM12([]);
    if (c12r1MT === "M" && c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "M") {
      if (c12R1VNo === 11 && c12R2VNo === 11 && c12R3VNo === 11 && c12R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL12(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                 ]
             )
           setC12R1VNo(11);
           setC12R2VNo(11);
           setC12R3VNo(11);
           setC12R4VNo(11);
           setC12r1MT("L")
           setC12r2MT("L")
           setC12r3MT("L")
           setC12r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col13X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X))  {
  if (col13Row01 === true && col13Row02 === true && col13Row03 === true && col13Row04 === true) {
    if (c13r1MT === "L") {
      if (c13R1VNo === 11) {
        return;
      }
    }
    setBaseNaked13([]);
    setRow2Naked13([]);
    setRow3Naked13([]);
    setRow4Naked13([]);
    if (c13r1MT === "S" && c13r2MT === "S" && c13r3MT === "S" && c13r4MT === "S") {
      if (c13R1VNo === 11 && c13R2VNo === 11 && c13R3VNo === 11 && c13R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM13([]);
    setRow2NakedM13([]);
    setRow3NakedM13([]);
    if (c13r1MT === "M" && c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "M") {
      if (c13R1VNo === 11 && c13R2VNo === 11 && c13R3VNo === 11 && c13R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL13(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                 ]
             )
           setC13R1VNo(11);
           setC13R2VNo(11);
           setC13R3VNo(11);
           setC13R4VNo(11);

           setC13r1MT("L")
           setC13r2MT("L")
           setC13r3MT("L")
           setC13r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
 } else if (curX === col14X && (curY === row01X || curY === row02X || curY === row03X || curY === row04X)) {
  if (col14Row01 === true && col14Row02 === true && col14Row03 === true && col14Row04 === true) {
    if (c14r1MT === "L") {
      if (c14R1VNo === 11) {
        return;
      }
    }
    setBaseNaked14([]);
    setRow2Naked14([]);
    setRow3Naked14([]);
    setRow4Naked14([]);
    if (c14r1MT === "S" && c14r2MT === "S" && c14r3MT === "S" && c14r4MT === "S") {
      if (c14R1VNo === 11 && c14R2VNo === 11 && c14R3VNo === 11 && c14R4VNo === 11) {
        noHandleCount = noHandleCount - 4;
      }
    }
    setBaseNakedM14([]);
    setRow2NakedM14([]);
    setRow3NakedM14([]);
    if (c14r1MT === "M" && c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "M") {
      if (c14R1VNo === 11 && c14R2VNo === 11 && c14R3VNo === 11 && c14R4VNo === 11) {
        noHandleCount = noHandleCount - 2;
      }
    }
     setBaseNakedL14(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                 ]
             )
           setC14R1VNo(11);
           setC14R2VNo(11);
           setC14R3VNo(11);
           setC14R4VNo(11);

           setC14r1MT("L")
           setC14r2MT("L")
           setC14r3MT("L")
           setC14r4MT("L")
           noHandleCount = noHandleCount + 1;
                }
  } 
} 

  // removing Doors
  const removeSmallDoors = (e)=> {
    //if (selModule === "S") {
        if (curX === col01X && curY === row01X) {
          if (c1R1VNo === 9 || c1R1VNo === 10 || c1R1VNo === 11) {
            if (c1r1MT === "S") {  
              setBaseNaked01(
                        [
                           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                         ]
                     )
                     setC1R1VNo(8);
                    if (c1R1VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
                     
            } else {
               alert("This is not a small door");
            }
          } else {
          alert("Already Removed");
          }
        } else if (curX === col02X && curY === row01X) {
    
          if (c2R1VNo === 9 || c2R1VNo === 10 || c2R1VNo === 11) {
            if (c2r1MT === "S") {  
              setBaseNaked02(
                              [
                                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(8);
                            if (c2R1VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
            } else {
               alert("This is not a small door");
            }
            } else {
            alert("Already Removed");
            }
      
      } else if (curX === col03X && curY === row01X) {
    
        if (c3R1VNo === 9 || c3R1VNo === 10 || c3R1VNo === 11) {
          if (c3r1MT === "S") {  
            setBaseNaked03(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                        ]
                      )
                      setC3R1VNo(8);
                      if (c3R1VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
          } else {
             alert("This is not a small door");
          }
        } else {
        alert("Already Removed");
        }
      
      } else if (curX === col04X && curY === row01X) {
           if (c4R1VNo === 9 || c4R1VNo === 10 || c4R1VNo === 11) {
              if (c4r1MT === "S") {  
                setBaseNaked04(
                              [
                                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(8);
                            if (c4R1VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
              } else {
                alert("This is not a small door");
              }
          } else {
          alert("Already Removed");
          }
      
       
      } else if (curX === col05X && curY === row01X) {
    
            if (c5R1VNo === 9 || c5R1VNo === 10 || c5R1VNo === 11) {
              if (c5r1MT === "S") {  
                setBaseNaked05(
                             [
                               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                             ]
                           )
                           setC5R1VNo(8);
                           if (c5R1VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
             } else {
                alert("This is not a small door");
             }	
          } else {
          alert("Already Removed");
          }
      
      } else if (curX === col06X && curY === row01X) {
    
           if (c6R1VNo === 9 || c6R1VNo === 10 || c6R1VNo === 11) {
            if (c6r1MT === "S") {  
              setBaseNaked06(
                           [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                           ]
                         )
                         setC6R1VNo(8);
                         if (c6R1VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
           } else {
              alert("This is not a small door");
           }
          } else {
          alert("Already Removed");
          }
      
      } else if (curX === col07X && curY === row01X) {
    
           if (c7R1VNo === 9 || c7R1VNo === 10 || c7R1VNo === 11) {
            if (c7r1MT === "S") {  
              setBaseNaked07(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                        setC7R1VNo(8);
                        if (c7R1VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
          } else {
             alert("This is not a small door");
          }
          } else {
          alert("Already Removed");
          }
      
      
      } else if (curX === col08X && curY === row01X) {
           if (c8R1VNo === 9 || c8R1VNo === 10 || c8R1VNo === 11) {
            if (c8r1MT === "S") {  
              setBaseNaked08(
                           [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                           ]
                         )
                         setC8R1VNo(8);
                         if (c8R1VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
           } else {
              alert("This is not a small door");
           }
          } else {
          alert("Already Removed");
          }
      
       
      } else if (curX === col09X && curY === row01X) {
    
           if (c9R1VNo === 9 || c9R1VNo === 10 || c9R1VNo === 11)  {
            if (c9r1MT === "S") {  
              setBaseNaked09(
                             [
                               <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                             ]
                           )
                           setC9R1VNo(8);
                           if (c9R1VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
           } else {
              alert("This is not a small door");
           }	  
           
            } else {
            alert("Already Removed");
            }
      
      } else if (curX === col10X && curY === row01X) {
    
       if (c10R1VNo === 9 || c10R1VNo === 10 || c10R1VNo === 11) {
              if (c10r1MT === "S") {  
                        setBaseNaked10(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                          ]
                        )
                        setC10R1VNo(8);
                        if (c10R1VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
              } else {
              alert("This is not a small door");
              }
            } else {
            alert("Already Removed");
            }
      } else if (curX === col11X && curY === row01X) {
    
    
           if (c11R1VNo === 9 || c11R1VNo === 10 || c11R1VNo === 11) {
            if (c11r1MT === "S") {  
              setBaseNaked11(
                               [
                                 <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                               ]
                             )
                             setC11R1VNo(8);
                             if (c11R1VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
             } else {
                alert("This is not a small door");
             }
            } else {
            alert("Already Removed");
            }

      } else if (curX === col12X && curY === row01X) {
    
      if (c12R1VNo === 9 || c12R1VNo === 10 || c12R1VNo === 11) {
              if (c12r1MT === "S") {  
                setBaseNaked12(
                                [
                                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(8);
                              if (c12R1VNo === 11) {
                                noHandleCount = noHandleCount - 1;
                              }
              } else {
                alert("This is not a small door");
              }	
            } else {
            alert("Already Removed");
            }
      
      } else if (curX === col13X && curY === row01X) {
          if (c13R1VNo === 9 || c13R1VNo === 10 || c13R1VNo === 11) {
            if (c13r1MT === "S") {  
              setBaseNaked13(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                            ]
                          )
                          setC13R1VNo(8);
                          if (c13R1VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
            } else {
               alert("This is not a small door");
            }	  
            
          } else {
          alert("Already Removed");
          }
      
      } else if (curX === col14X && curY === row01X) {
      if (c14R1VNo === 9 || c14R1VNo === 10 || c14R1VNo === 11) {
                  if (c14r1MT === "S") {  
                    setBaseNaked14(
                                  [
                                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                                  ]
                                )
                                setC14R1VNo(8);
                                if (c14R1VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
                } else {
                    alert("This is not a small door");
                }	  
       
            } else {
            alert("Already Removed");
            }
        
      } else if (curX === col01X && curY === row02X) {
        if (c1R2VNo === 9 || c1R2VNo === 10 || c1R2VNo === 11) {
          if (c1r2MT === "S") {  
            setRow2Naked01(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                       ]
                     )
                     setC1R2VNo(8);
                     if (c1R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
         } else {
            alert("This is not a small door");
         }	
        } else {
        alert("Already Removed");
        }

      } else if (curX === col02X && curY === row02X) {
      
      if (c2R2VNo === 9 || c2R2VNo === 10 || c2R2VNo === 11) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(8);
                    if (c2R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
        } else {
           alert("This is not a small door");
        }	  
        
        } else {
        alert("Already Removed");
        }
        
      } else if (curX === col03X && curY === row02X) {
      
         if (c3R2VNo === 9 || c3R2VNo === 10 || c3R2VNo === 11) {
          if (c3r2MT === "S") {  
            setRow2Naked03(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                       ]
                     )
                     setC3R2VNo(8);
                     if (c3R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
         } else {
            alert("This is not a small door");
         }	  
         
        } else {
        alert("Already Removed");
        }
        
        } else if (curX === col04X && curY === row02X) {
             if (c4R2VNo === 9 || c4R2VNo === 10 || c4R2VNo === 11) {
              if (c4r2MT === "S") {  
                setRow2Naked04(
                           [
                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                           ]
                         )
                         setC4R2VNo(8);
                         if (c4R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
             } else {
                alert("This is not a small door");
             }	  
             
        } else {
        alert("Already Removed");
        }

        } else if (curX === col05X && curY === row02X) {
      
          if (c5R2VNo === 9 || c5R2VNo === 10 || c5R2VNo === 11) {
            if (c5r2MT === "S") {  
              setRow2Naked05(
                         [
                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                         ]
                       )
                       setC5R2VNo(8);
                       if (c5R2VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
           } else {
              alert("This is not a small door");
           }
        } else {
        alert("Already Removed");
        }

        } else if (curX === col06X && curY === row02X) {
      
          if (c6R2VNo === 9 || c6R2VNo === 10 || c6R2VNo === 11) {
            if (c6r2MT === "S") {  
              setRow2Naked06(
                         [
                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                         ]
                       )
                       setC6R2VNo(8);
                       if (c6R2VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
           } else {
              alert("This is not a small door");
           }
        } else {
        alert("Already Removed");
        }
        } else if (curX === col07X && curY === row02X) {
      
             if (c7R2VNo === 9 || c7R2VNo === 10 || c7R2VNo === 11) {
              if (c7r2MT === "S") {  
                setRow2Naked07(
                           [
                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                           ]
                         )
                         setC7R2VNo(8);
                         if (c7R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
             } else {
                alert("This is not a small door");
             }
        } else {
        alert("Already Removed");
        }

        } else if (curX === col08X && curY === row02X) {
             if (c8R2VNo === 9 || c8R2VNo === 10 || c8R2VNo === 11) {
              if (c8r2MT === "S") {  
                setRow2Naked08(
                           [
                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                           ]
                         )
                         setC8R2VNo(8);
                         if (c8R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
             } else {
                alert("This is not a small door");
             }	  
             
            } else {
            alert("Already Removed");
            }
        } else if (curX === col09X && curY === row02X) {
      
             if (c9R2VNo === 9 || c9R2VNo === 10 || c9R2VNo === 11)  {
              if (c9r2MT === "S") {  
                setRow2Naked09(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                                  ]
                                )
                                setC9R2VNo(8);
                                if (c9R2VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
              } else {
                 alert("This is not a small door");
              }
              } else {
              alert("Already Removed");
              }
        
        } else if (curX === col10X && curY === row02X) {
      
         if (c10R2VNo === 9 || c10R2VNo === 10 || c10R2VNo === 11) {
          if (c10r2MT === "S") {  
            setRow2Naked10(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                        ]
                      )
                      setC10R2VNo(8);
                      if (c10R2VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
          } else {
             alert("This is not a small door");
          }
        } else {
        alert("Already Removed");
        }
        
        } else if (curX === col11X && curY === row02X) {

             if (c11R2VNo === 9 || c11R2VNo === 10 || c11R2VNo === 11) {
              if (c11r2MT === "S") {  
                setRow2Naked11(
                           [
                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                           ]
                         )
                         setC11R2VNo(8);
                         if (c11R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
             } else {
                alert("This is not a small door");
             }
            } else {
            alert("Already Removed");
            }
              
        } else if (curX === col12X && curY === row02X) {
      
        if (c12R2VNo === 9 || c12R2VNo === 10 || c12R2VNo === 11) {
          if (c12r2MT === "S") {  
            setRow2Naked12(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                       ]
                     )
                     setC11R2VNo(8);
                     if (c12R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
         } else {
            alert("This is not a small door");
         }
        } else {
        alert("Already Removed");
        }
        
        } else if (curX === col13X && curY === row02X) {
            if (c13R2VNo === 9 || c13R2VNo === 10 || c13R2VNo === 11) {
              if (c13r2MT === "S") {  
                setRow2Naked13(
                           [
                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                           ]
                         )
                         setC13R2VNo(8);
                         if (c13R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
             } else {
                alert("This is not a small door");
             }
            } else {
            alert("Already Removed");
            }
        
        } else if (curX === col14X && curY === row02X) {
              if (c14R2VNo === 9 || c14R2VNo === 10 || c14R2VNo === 11) {
                if (c14r2MT === "S") {  
                  setRow2Naked14(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                              ]
                            )
                            setC14R2VNo(8);
                            if (c14R2VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
                } else {
                   alert("This is not a small door");
                }
              } else {
              alert("Already Removed");
              }
          
        } else if (curX === col01X && curY === row03X) {
              if (c1R3VNo === 9 || c1R3VNo === 10 || c1R3VNo === 11) {
                if (c1r3MT === "S") {  
                  setRow3Naked01(
                                   [
                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                                   ]
                                 )
                                 setC1R3VNo(8);
                                 if (c1R3VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
               } else {
                  alert("This is not a small door");
               }	  
               
              } else {
              alert("Already Removed");
              }
                 
       } else if (curX === col02X && curY === row03X) {
        
        if (c2R3VNo === 9 || c2R3VNo === 10 || c2R3VNo === 11) {
          if (c2r3MT === "S") {  
            setRow3Naked02(
                         [
                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                         ]
                       )
                       setC2R3VNo(8);
                       if (c2R3VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
         } else {
            alert("This is not a small door");
         }
          } else {
          alert("Already Removed");
          }
          
      } else if (curX === col03X && curY === row03X) {
        
           if (c3R3VNo === 9 || c3R3VNo === 10 || c3R3VNo === 11) {
            if (c3r3MT === "S") {  
              setRow3Naked03(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                          ]
                        )
                        setC3R3VNo(8);
                        if (c3R3VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
          } else {
             alert("This is not a small door");
          }
          } else {
          alert("Already Removed");
          }
          
      } else if (curX === col04X && curY === row03X) {
            if (c4R3VNo === 9 || c4R3VNo === 10 || c4R3VNo === 11) {
              if (c4r3MT === "S") {  
                setRow3Naked04(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                            ]
                          )
                          setC4R3VNo(8);
                          if (c4R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
            } else {
               alert("This is not a small door");
            }	  
            
          } else {
          alert("Already Removed");
          }
          
           
      } else if (curX === col05X && curY === row03X) {
        
            if (c5R3VNo === 9 || c5R3VNo === 10 || c5R3VNo === 11) {
              if (c5r3MT === "S") {  
                setRow3Naked05(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                            ]
                          )
                          setC5R3VNo(8);
                          if (c5R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
            } else {
               alert("This is not a small door");
            }	  
            
          } else {
          alert("Already Removed");
          }
          } else if (curX === col06X && curY === row03X) {
        
               if (c6R3VNo === 9 || c6R3VNo === 10 || c6R3VNo === 11) {
                if (c6r3MT === "S") {  
                  setRow3Naked06(
                                   [
                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                                   ]
                                 )
                                 setC6R3VNo(8);
                                 if (c6R3VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
               } else {
                  alert("This is not a small door");
               }
              } else {
              alert("Already Removed");
              }
          
          } else if (curX === col07X && curY === row03X) {
        
               if (c7R3VNo === 9 || c7R3VNo === 10 || c7R3VNo === 11) {
                if (c7r3MT === "S") {  
                  setRow3Naked07(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                                ]
                              )
                              setC7R3VNo(8);
                              if (c7R3VNo === 11) {
                                noHandleCount = noHandleCount - 1;
                              }
                } else {
                   alert("This is not a small door");
                }	  
                
              } else {
              alert("Already Removed");
              }
          } else if (curX === col08X && curY === row03X) {
               if (c8R3VNo === 9 || c8R3VNo === 10 || c8R3VNo === 11) {
                if (c8r3MT === "S") {  
                  setRow3Naked08(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                                      ]
                                    )
                                    setC8R3VNo(8);
                                    if (c8R3VNo === 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
                } else {
                   alert("This is not a small door");
                }
                } else {
                alert("Already Removed");
                }

          } else if (curX === col09X && curY === row03X) {
        
            if (c9R3VNo === 9 || c9R3VNo === 10 || c9R3VNo === 11)  {
              if (c9r3MT === "S") {  
                setRow3Naked09(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                             ]
                           )
                           setC9R3VNo(8);
                           if (c9R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
             } else {
                alert("This is not a small door");
             }
                } else {
                alert("Already Removed");
                }
          
          } else if (curX === col10X && curY === row03X) {
        
           if (c10R3VNo === 9 || c10R3VNo === 10 || c10R3VNo === 11) {
            if (c10r3MT === "S") {  
              setRow3Naked10(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                            ]
                          )
                          setC10R3VNo(8);
                          if (c10R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
            } else {
               alert("This is not a small door");
            }
              } else {
              alert("Already Removed");
              }
          
          } else if (curX === col11X && curY === row03X) {
               if (c11R3VNo === 9 || c11R3VNo === 10 || c11R3VNo === 11) {
                if (c11r3MT === "S") {  
                  setRow2Naked11(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                                      ]
                                    )
                                    setC11R3VNo(8);
                                    if (c11R3VNo === 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
                } else {
                   alert("This is not a small door");
                }
                } else {
                alert("Already Removed");
                }
          
          
          } else if (curX === col12X && curY === row03X) {
        
              if (c12R3VNo === 9 || c12R3VNo === 10 || c12R3VNo === 11) {
                if (c12r3MT === "S") {  
                  setRow3Naked12(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                                    ]
                                  )
                                  setC11R3VNo(8);
                                  if (c12R3VNo === 11) {
                                    noHandleCount = noHandleCount - 1;
                                  }
                } else {
                   alert("This is not a small door");
                }	  
                
              } else {
              alert("Already Removed");
              }
          
          } else if (curX === col13X && curY === row03X) {
              if (c13R3VNo === 9 || c13R3VNo === 10 || c13R3VNo === 11) {
                if (c13r3MT === "S") {  
                  setRow3Naked13(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                                    ]
                                  )
                                  setC13R3VNo(8);
                                  if (c13R3VNo === 11) {
                                    noHandleCount = noHandleCount - 1;
                                  }
                } else {
                   alert("This is not a small door");
                }
              } else {
              alert("Already Removed");
              }
          
          } else if (curX === col14X && curY === row03X) {
              if (c14R3VNo === 9 || c14R3VNo === 10 || c14R3VNo === 11) {
                if (c14r3MT === "S") {  
                  setRow3Naked14(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                                ]
                              )
                              setC14R3VNo(8);
                              if (c14R3VNo === 11) {
                                noHandleCount = noHandleCount - 1;
                              }
                } else {
                   alert("This is not a small door");
                }	  
                
              } else {
              alert("Already Removed");
              }
            
          } else if (curX === col01X && curY === row04X) {
                if (c1R4VNo === 9 || c1R4VNo === 10 || c1R4VNo === 11) {
                  if (c1r4MT === "S") {  
                    setRow4Naked01(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                                    ]
                                  )
                                  setC1R4VNo(8);
                                  if (c1R4VNo === 11) {
                                    noHandleCount = noHandleCount - 1;
                                  }
                  } else {
                     alert("This is not a small door");
                  }
                } else {
                alert("Already Removed");
                }
            
          
            } else if (curX === col02X && curY === row04X) {
          
          if (c2R4VNo === 9 || c2R4VNo === 10 || c2R4VNo === 11) {
            if (c2r4MT === "S") {  
              setRow4Naked02(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                              ]
                            )
                            setC2R4VNo(8);
                            if (c2R4VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
            } else {
               alert("This is not a small door");
            }	  
            
            } else {
            alert("Already Removed");
            }
            
            } else if (curX === col03X && curY === row04X) {
          
                  if (c3R4VNo === 9 || c3R4VNo === 10 || c3R4VNo === 11) {
                    if (c3r4MT === "S") {  
                      setRow4Naked03(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                                      ]
                                    )
                                    setC3R4VNo(8);
                                    if (c3R4VNo === 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
                    } else {
                       alert("This is not a small door");
                    }
                  } else {
                  alert("Already Removed");
                  }
            
            } else if (curX === col04X && curY === row04X) {
                 if (c4R4VNo === 9 || c4R4VNo === 10 || c4R4VNo === 11) {
                  if (c4r4MT === "S") {  
                    setRow4Naked04(
                                       [
                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                                       ]
                                     )
                                     setC4R4VNo(8);
                                     if (c4R4VNo=== 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
                 } else {
                    alert("This is not a small door");
                 }	  
                 
                } else {
                alert("Already Removed");
                }
                         
            } else if (curX === col05X && curY === row04X) {
          
              if (c5R4VNo === 9 || c5R4VNo === 10 || c5R4VNo === 11) {
                if (c5r4MT === "S") {  
                  setRow4Naked05(
                                       [
                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                                       ]
                                     )
                                     setC5R4VNo(8);
                                     if (c5R4VNo === 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
               } else {
                  alert("This is not a small door");
               }
                  } else {
                  alert("Already Removed");
                  }

            } else if (curX === col06X && curY === row04X) {
          
                 if (c6R4VNo === 9 || c6R4VNo === 10 || c6R4VNo === 11) {
                  if (c6r4MT === "S") {  
                    setRow4Naked06(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                                          ]
                                        )
                                        setC6R4VNo(8);
                                        if (c6R4VNo === 11) {
                                          noHandleCount = noHandleCount - 1;
                                        }
                  } else {
                     alert("This is not a small door");
                  }	  
                  
                  } else {
                  alert("Already Removed");
                  }

            } else if (curX === col07X && curY === row04X) {
          
                 if (c7R4VNo === 9 || c7R4VNo === 10 || c7R4VNo === 11) {
                  if (c7r4MT === "S") {  
                    setRow4Naked07(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                                        ]
                                      )
                                      setC7R4VNo(8);
                                      if (c7R4VNo === 11) {
                                        noHandleCount = noHandleCount - 1;
                                      }
                  } else {
                     alert("This is not a small door");
                  }	  
                  
                    } else {
                    alert("Already Removed");
                    }

            } else if (curX === col08X && curY === row04X) {
                 if (c8R4VNo === 9 || c8R4VNo === 10 || c8R4VNo === 11) {
                  if (c8r4MT === "S") {  
                    setRow4Naked08(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                                          ]
                                        )
                                        setC8R4VNo(8);
                                        if (c8R4VNo === 11) {
                                          noHandleCount = noHandleCount - 1;
                                        }
                  } else {
                     alert("This is not a small door");
                  }	
                  } else {
                  alert("Already Removed");
                  }

            } else if (curX === col09X && curY === row04X) {
          
                 if (c9R4VNo === 9 || c9R4VNo === 10 || c9R4VNo === 11)  {
                  if (c9r4MT === "S") {  
                    setRow4Naked09(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                                    ]
                                  )
                                  setC9R4VNo(8);
                                  if (c9R4VNo === 11) {
                                    noHandleCount = noHandleCount - 1;
                                  }
                  } else {
                     alert("This is not a small door");
                  }	
                  } else {
                  alert("Already Removed");
                  }
            
            } else if (curX === col10X && curY === row04X) {
          
                  if (c10R4VNo === 9 || c10R4VNo === 10 || c10R4VNo === 11) {
                    if (c10r4MT === "S") {  
                      setRow4Naked10(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                                            ]
                                          )
                                          setC10R4VNo(8);
                                          if (c10R4VNo=== 11) {
                                            noHandleCount = noHandleCount - 1;
                                          }
                    } else {
                       alert("This is not a small door");
                    }	  
                    
                  } else {
                  alert("Already Removed");
                  }
            
            } else if (curX === col11X && curY === row04X) {

                 if (c11R4VNo === 9 || c11R4VNo === 10 || c11R4VNo === 11) {
                  if (c11r4MT === "S") {  
                    setRow4Naked11(
                                   [
                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                                   ]
                                 )
                                 setC11R4VNo(8);
                                 if (c11R4VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
                 } else {
                    alert("This is not a small door");
                 }
                  } else {
                  alert("Already Removed");
                  }
            
            } else if (curX === col12X && curY === row04X) {
          
                if (c12R4VNo === 9 || c12R4VNo === 10 || c12R4VNo === 11) {
                  if (c12r4MT === "S") {  
                    setRow4Naked12(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                                  ]
                                )
                                setC11R4VNo(8);
                                if (c12R4VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
                } else {
                    alert("This is not a small door");
                }	
                } else {
                alert("Already Removed");
                }
            
         } else if (curX === col13X && curY === row04X) {
                if (c13R4VNo === 9 || c13R4VNo === 10 || c13R4VNo === 11) {
                  if (c13r4MT === "S") {  
                    setRow4Naked13(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                                        ]
                                      )
                                      setC13R4VNo(8);
                                      if (c13R4VNo === 11) {
                                        noHandleCount = noHandleCount - 1;
                                      }
                  } else {
                     alert("This is not a small door");
                  }	
                } else {
                alert("Already Removed");
                }
                
           } else if (curX === col14X && curY === row04X) {
              if (c14R4VNo === 9 || c14R4VNo === 10 || c14R4VNo === 11) {
                if (c14r4MT === "S") {  
                  setRow4Naked14(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                                  ]
                                )
                                setC14R4VNo(8);
                                if (c14R4VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
                } else {
                   alert("This is not a small door");
                }
              } else {
              alert("Already Removed");
              }

          }
  }

  const removeMediumDoors = (e)=> {
    //if (selModule === "M") {
      if (curX === col01X && curY === row01X) {
        if (c1R1VNo === 9 || c1R1VNo === 10 || c1R1VNo === 11) {
          if (c1r1MT === "M") {  
            setBaseNakedM01(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(8);
                        setC1R2VNo(8);
                        if (c1R1VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
          } else {
             alert("This is not a medium door");
          }
          } else {
          alert("Already Removed");
          }
      } else if (curX === col02X && curY === row01X) {
        if (c2R1VNo === 9 || c2R1VNo === 10 || c2R1VNo === 11) {
          if (c2r1MT === "M") {  
            setBaseNakedM02(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(8);
                        setC2R2VNo(8);
                        if (c2R1VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
          } else {
             alert("This is not a medium door");
          }
          } else {
          alert("Already Removed");
          }
          
          } else if (curX === col03X && curY === row01X) {
        if (c3R1VNo === 9 || c3R1VNo === 10 || c3R1VNo === 11) {
          if (c3r1MT === "M") {  
            setBaseNakedM03(
                         [
                           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                         ]
                       )
                       setC3R1VNo(8);
                       setC3R2VNo(8);
                       if (c3R1VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
          } else {
            alert("This is not a medium door");
          }
          } else {
          alert("Already Removed");
          }
          
           
          } else if (curX === col04X && curY === row01X) {
        if (c4R1VNo === 9 || c4R1VNo === 10 || c4R1VNo === 11) {
          if (c4r1MT === "M") {  
            setBaseNakedM04(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                        ]
                      )
                      setC4R1VNo(8);
                      setC4R2VNo(8);
                      if (c4R1VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
        } else {
           alert("This is not a medium door");
        }
          } else {
          alert("Already Removed");
          }
          
           
          } else if (curX === col05X && curY === row01X) {
        if (c5R1VNo === 9 || c5R1VNo === 10 || c5R1VNo === 11) {
          if (c5r1MT === "M") {  
            setBaseNakedM05(
                         [
                           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                         ]
                       )
                       setC5R1VNo(8);
                       setC5R2VNo(8);
                       if (c5R1VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
         } else {
            alert("This is not a medium door");
         }	  
         
          } else {
          alert("Already Removed");
          }
          
          } else if (curX === col06X && curY === row01X) {
              if (c6R1VNo === 9 || c6R1VNo === 10 || c6R1VNo === 11) {
                    if (c6r1MT === "M") {  
                      setBaseNakedM06(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                  ]
                                )
                                setC6R1VNo(8);
                                setC6R2VNo(8);
                                if (c6R1VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
                  } else {
                      alert("This is not a medium door");
                  }
                } else {
                alert("Already Removed");
                }
          
        } else if (curX === col07X && curY === row01X) {
          if (c7R1VNo === 9 || c7R1VNo === 10 || c7R1VNo === 11) {
            if (c7r1MT === "M") {  
              setBaseNakedM07(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(8);
                            setC7R2VNo(8);
                            if (c7R1VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
            } else {
               alert("This is not a medium door");
            }	  
            
            } else {
            alert("Already Removed");
            }
          
        } else if (curX === col08X && curY === row01X) {
        if (c8R1VNo === 9 || c8R1VNo === 10 || c8R1VNo === 11) {
          if (c8r1MT === "M") {  
            setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                        setC8R1VNo(8);
                        setC8R2VNo(8);
                        if (c8R1VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
          } else {
             alert("This is not a medium door");
          }	
          } else {
          alert("Already Removed");
          }
          
        } else if (curX === col09X && curY === row01X) {
            if (c9R1VNo === 9 || c9R1VNo === 10 || c9R1VNo === 11) {
              if (c9r1MT === "M") {  
                setBaseNakedM09(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(8);
                            setC9R2VNo(8);
                            if (c9R1VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
              } else {
                 alert("This is not a medium door");
              }
              } else {
              alert("Already Removed");
              }
          
        } else if (curX === col10X && curY === row01X) {
            if (c10R1VNo === 9 || c10R1VNo === 10 || c10R1VNo === 11) {
              if (c10r1MT === "M") {  
                setBaseNakedM10(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                  ]
                                )
                                setC10R1VNo(8);
                                setC10R2VNo(8);
                                if (c10R1VNo === 11) {
                                  noHandleCount = noHandleCount - 1;
                                }
              } else {
                 alert("This is not a medium door");
              }
              } else {
              alert("Already Removed");
              }
          
        } else if (curX === col11X && curY === row01X) {
            if (c11R1VNo === 9 || c11R1VNo === 10 || c11R1VNo === 11) {
              if (c11r1MT === "M") {  
                setBaseNakedM11(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(8);
                            setC11R2VNo(8);
                            if (c11R1VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
              } else {
                 alert("This is not a medium door");
              }	  
              
              } else {
              alert("Already Removed");
              }
          
        } else if (curX === col12X && curY === row01X) {
            if (c12R1VNo === 9 || c12R1VNo === 10 || c12R1VNo === 11) {
              if (c12r1MT === "M") {  
                setBaseNakedM12(
                             [
                               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                             ]
                           )
                           setC12R1VNo(8);
                           setC12R2VNo(8);
                           if (c12R1VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
             } else {
                alert("This is not a medium door");
             }
              } else {
              alert("Already Removed");
              }
          
        } else if (curX === col13X && curY === row01X) {
              if (c13R1VNo === 9 || c13R1VNo === 10 || c13R1VNo === 11) {
                if (c13r1MT === "M") {  
                  setBaseNakedM13(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                                    setC13R1VNo(8);
                                    setC13R2VNo(8);
                                    if (c13R1VNo === 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
                } else {
                   alert("This is not a medium door");
                }
                } else {
                alert("Already Removed");
                }
          
        } else if (curX === col14X && curY === row01X) {
            if (c14R1VNo === 9 || c14R1VNo === 10 || c14R1VNo === 11) {
              if (c14r1MT === "M") {  
                setBaseNakedM14(
                             [
                               <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                             ]
                           )
                           setC14R1VNo(8);
                           setC14R2VNo(8);
                           if (c14R1VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
             } else {
                alert("This is not a medium door");
             }
              } else {
              alert("Already Removed");
              }
            
          }  else if (curX === col01X && curY === row02X) {
                if (c1R2VNo === 9 || c1R2VNo === 10 || c1R2VNo === 11) {
                  if (c1r2MT === "M" && c1r3MT === "M") {  
                    setRow2NakedM01(
                                         [
                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                         ]
                                       )
                                       setC1R2VNo(8);
                                       setC1R3VNo(8);
                                       if (c1R2VNo === 11) {
                                        noHandleCount = noHandleCount - 1;
                                      }
                  } else if (c1r2MT === "M" && c1r1MT === "M") { 
                      setBaseNakedM01(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                        ]
                      )
                      setC1R1VNo(8);
                      setC1R2VNo(8);
                      if (c1R2VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
                  } else {
                      alert("This is not a medium door");
                  }	  
                 
                  } else {
                  alert("Already Removed");
                  }
              
            
              } else if (curX === col02X && curY === row02X) {
                  if (c2R2VNo === 9 || c2R2VNo === 10 || c2R2VNo === 11) {
                    if (c2r2MT === "M" && c2r3MT === "M") {  
                      setRow2NakedM02(
                                             [
                                               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                             ]
                                           )
                                           setC2R2VNo(8);
                                           setC2R3VNo(8);
                                           if (c2R2VNo === 11) {
                                            noHandleCount = noHandleCount - 1;
                                          }
                    } else if (c2r2MT === "M" && c2r1MT === "M") {  
                        setBaseNakedM02(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(8);
                        setC2R2VNo(8);
                        if (c2R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                    } else {
                        alert("This is not a medium door");
                    }	 
                    } else {
                    alert("Already Removed");
                    }
              
              } else if (curX === col03X && curY === row02X) {
            if (c3R2VNo === 9 || c3R2VNo === 10 || c3R2VNo === 11) {
              if (c3r2MT === "M" && c3r3MT === "M") {  
                setRow2NakedM03(
                                 [
                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                 ]
                               )
                               setC3R2VNo(8);
                               setC3R3VNo(8);
                               if (c3R2VNo === 11) {
                                noHandleCount = noHandleCount - 1;
                              }
            } else if (c3r2MT === "M" && c3r1MT === "M") {  
                setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(8);
                setC3R2VNo(8);
                if (c3R2VNo === 11) {
                  noHandleCount = noHandleCount - 1;
                }
             } else {
                alert("This is not a medium door");
             }
              } else {
              alert("Already Removed");
              }
                  
          } else if (curX === col04X && curY === row02X) {
                    if (c4R2VNo === 9 || c4R2VNo === 10 || c4R2VNo === 11) {
                      if (c4r2MT === "M" && c4r3MT === "M") {  
                        setRow2NakedM04(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                          ]
                                        )
                                        setC4R2VNo(8);
                                        setC4R3VNo(8);
                                        if (c4R2VNo === 11) {
                                          noHandleCount = noHandleCount - 1;
                                        }
                      } else  if (c4r2MT === "M" && c4r1MT === "M") {  
                          setBaseNakedM04(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                            ]
                          )
                          setC4R1VNo(8);
                          setC4R2VNo(8);
                          if (c4R2VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
                      } else {
                         alert("This is not a medium door");
                      }
                      } else {
                      alert("Already Removed");
                      }
              
               
              } else if (curX === col05X && curY === row02X) {
                if (c5R2VNo === 9 || c5R2VNo === 10 || c5R2VNo === 11) {
                  if (c5r2MT === "M" && c5r3MT === "M") {  
                    setRow2NakedM05(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                      ]
                                    )
                                    setC5R2VNo(8);
                                    setC5R3VNo(8);
                                    if (c5R2VNo === 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
                  } else  if (c5r2MT === "M" && c5r1MT === "M") {  
                      setBaseNakedM05(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                        ]
                      )
                      setC5R1VNo(8);
                      setC5R2VNo(8);
                      if (c5R2VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
                  } else {
                    alert("This is not a medium door");
                  }	  
                  
                  } else {
                  alert("Already Removed");
                  }
              
              } else if (curX === col06X && curY === row02X) {
                  if (c6R2VNo === 9 || c6R2VNo === 10 || c6R2VNo === 11) {
                    if (c6r2MT === "M" && c6r3MT === "M") {  
                      setRow2NakedM06(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                              ]
                                            )
                                            setC6R2VNo(8);
                                            setC6R3VNo(8);
                                            if (c6R2VNo === 11) {
                                              noHandleCount = noHandleCount - 1;
                                            }
                    } else if (c6r2MT === "M" && c6r1MT === "M") {  
                      setBaseNakedM06(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
                      setC6R1VNo(8);
                      setC6R2VNo(8);
                      if (c6R2VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
                    } else {
                       alert("This is not a medium door");
                    }	  
                    
                    } else {
                    alert("Already Removed");
                    }
              
            } else if (curX === col07X && curY === row02X) {
                    if (c7R2VNo === 9 || c7R2VNo === 10 || c7R2VNo === 11) {
                      if (c7r2MT === "M" && c7r3MT === "M") {  
                        setRow2NakedM07(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                          ]
                                        )
                                        setC7R2VNo(8);
                                        setC7R3VNo(8);
                                        if (c7R2VNo === 11) {
                                          noHandleCount = noHandleCount - 1;
                                        }
                      } else if (c7r2MT === "M" && c7r1MT === "M") {  
                        setBaseNakedM07(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                        setC7R1VNo(8);
                        setC7R2VNo(8);
                        if (c7R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
              
            } else if (curX === col08X && curY === row02X) {
                if (c8R2VNo === 9 || c8R2VNo === 10 || c8R2VNo === 11) {
                  if (c8r2MT === "M" && c8r3MT === "M") {  
                    setRow2NakedM08(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                          ]
                                        )
                                        setC8R2VNo(8);
                                        setC8R3VNo(8);
                                        if (c8R2VNo === 11) {
                                          noHandleCount = noHandleCount - 1;
                                        }
                  } else if (c8r2MT === "M" && c8r1MT === "M") {  
                    setBaseNakedM08(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(8);
                    setC8R2VNo(8);
                    if (c8R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
                  } else {
                     alert("This is not a medium door");
                  }
                  } else {
                  alert("Already Removed");
                  }
              
            } else if (curX === col09X && curY === row02X) {
                if (c9R2VNo === 9 || c9R2VNo === 10 || c9R2VNo === 11) {
                  if (c9r2MT === "M" && c9r3MT === "M") {  
                    setRow2NakedM09(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                          ]
                                        )
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        if (c9R2VNo === 11) {
                                          noHandleCount = noHandleCount - 1;
                                        }
                  } else  if (c9r2MT === "M" && c9r1MT === "M") {  
                    setBaseNakedM09(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(8);
                    setC9R2VNo(8);
                    if (c9R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
                  } else {
                     alert("This is not a medium door");
                  }
                  } else {
                  alert("Already Removed");
                  }
              
            } else if (curX === col10X && curY === row02X) {
                    if (c10R2VNo === 9 || c10R2VNo === 10 || c10R2VNo === 11) {
                      if (c10r2MT === "M" && c10r3MT === "M") {  
                        setRow2NakedM10(
                                         [
                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                         ]
                                       )
                                       setC10R1VNo(8);
                                       setC10R2VNo(8);
                                       if (c10R2VNo === 11) {
                                        noHandleCount = noHandleCount - 1;
                                      }
                      } else  if (c10r2MT === "M" && c10r1MT === "M") {  
                        setBaseNakedM10(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                          ]
                        )
                        setC10R1VNo(8);
                        setC10R2VNo(8);
                        if (c10R2VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                     } else {
                        alert("This is not a medium door");
                     }	  
                     
                      } else {
                      alert("Already Removed");
                      }
              
            } else if (curX === col11X && curY === row02X) {
                if (c11R2VNo === 9 || c11R2VNo === 10 || c11R2VNo === 11) {
                  if (c11r2MT === "M" && c11r3MT === "M") {  
                    setRow2NakedM11(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                     ]
                                   )
                                   setC11R2VNo(8);
                                   setC11R3VNo(8);
                                   if (c11R2VNo === 11) {
                                    noHandleCount = noHandleCount - 1;
                                  }
                } else  if (c11r2MT === "M" && c11r1MT === "M") {   
                  setBaseNakedM11(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(8);
                  setC11R2VNo(8);
                  if (c11R2VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                  }
                 } else {
                    alert("This is not a medium door");
                 }
                  } else {
                  alert("Already Removed");
                  }
              
            } else if (curX === col12X && curY === row02X) {
                if (c12R2VNo === 9 || c12R2VNo === 10 || c12R2VNo === 11) {
                  if (c12r2MT === "M" && c12r3MT === "M") {  
                    setRow2NakedM12(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                      ]
                                    )
                                    setC12R2VNo(8);
                                    setC12R3VNo(8);
                                    if (c12R2VNo === 11) {
                                      noHandleCount = noHandleCount - 1;
                                    }
                  } else if (c12r2MT === "M" && c12r1MT === "M") { 
                    setBaseNakedM12(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(8);
                    setC12R2VNo(8);
                    if (c12R2VNo === 11) {
                      noHandleCount = noHandleCount - 1;
                    }
                  } else {
                     alert("This is not a medium door");
                  }	
                  } else {
                  alert("Already Removed");
                  }
                  
            } else if (curX === col13X && curY === row02X) {
                  if (c13R2VNo === 9 || c13R2VNo === 10 || c13R2VNo === 11) {
                    if (c13r2MT === "M" && c13r3MT === "M") {  
                      setRow2NakedM13(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                              ]
                                            )
                                            setC13R2VNo(8);
                                            setC13R3VNo(8);
                                            if (c13R2VNo === 11) {
                                              noHandleCount = noHandleCount - 1;
                                            }
                    } else if (c13r2MT === "M" && c13r1MT === "M") {  
                      setBaseNakedM13(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                        ]
                      )
                      setC13R1VNo(8);
                      setC13R2VNo(8);
                      if (c13R2VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
                    } else {
                       alert("This is not a medium door");
                    }	  
                    
                    } else {
                    alert("Already Removed");
                    }
              
            } else if (curX === col14X && curY === row02X) {
                if (c14R2VNo === 9 || c14R2VNo === 10 || c14R2VNo === 11) {
                  if (c14r2MT === "M" && c14r3MT === "M") {  
                    setRow2NakedM14(
                                         [
                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                         ]
                                       )
                                       setC14R2VNo(8);
                                       setC14R3VNo(8);
                                       if (c14R2VNo === 11) {
                                        noHandleCount = noHandleCount - 1;
                                      }
                } else if (c14r2MT === "M" && c14r1MT === "M") { 
                  setBaseNakedM14(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(8);
                  setC14R2VNo(8);
                  if (c14R2VNo === 11) {
                    noHandleCount = noHandleCount - 1;
                  }
                 } else {
                    alert("This is not a medium door");
                 }	
                  } else {
                  alert("Already Removed");
                  }
                
              } else if (curX === col01X && curY === row03X) {
                    if (c1R3VNo === 9 || c1R3VNo === 10 || c1R3VNo === 11) {
                      if (c1r3MT === "M" && c1r4MT === "M") {  
                        setRow3NakedM01(
                                                 [
                                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                                 ]
                                               )
                                               setC1R3VNo(8);
                                               setC1R4VNo(8);
                                               if (c1R3VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                    } else  if (c1r3MT === "M" && c1r2MT === "M") {  
                      setRow2NakedM01(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                        ]
                      )
                      setC1R2VNo(8);
                      setC1R3VNo(8);
                      if (c1R3VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
                     } else {
                        alert("This is not a medium door");
                     }
                      } else {
                      alert("Already Removed");
                      }
                  
                
                  } else if (curX === col02X && curY === row03X) {
                      if (c2R3VNo === 9 || c2R3VNo === 10 || c2R3VNo === 11) {
                        if (c2r3MT === "M" && c2r4MT === "M") {  
                          setRow3NakedM02(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                                ]
                                              )
                                              setC2R3VNo(8);
                                              setC2R4VNo(8);
                                              if (c2R3VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                        } else  if (c2r3MT === "M" && c2r2MT === "M") {
                          setRow2NakedM02(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                            ]
                          )
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          if (c2R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
                        } else {
                           alert("This is not a medium door");
                        }	  
                        
                        } else {
                        alert("Already Removed");
                        }
                  
                  } else if (curX === col03X && curY === row03X) {
                    if (c3R3VNo === 9 || c3R3VNo === 10 || c3R3VNo === 11) {
                      if (c3r3MT === "M" && c3r4MT === "M") {  
                        setRow3NakedM03(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC3R3VNo(8);
                                                setC3R4VNo(8);
                                                if (c3R3VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else  if (c3r3MT === "M" && c3r2MT === "M") {  
                        setRow2NakedM03(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                          ]
                        )
                        setC3R2VNo(8);
                        setC3R3VNo(8);
                        if (c3R3VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                   
                  } else if (curX === col04X && curY === row03X) {
                      if (c4R3VNo === 9 || c4R3VNo === 10 || c4R3VNo === 11) {
                        if (c4r3MT === "M" && c4r4MT === "M") {  
                          setRow3NakedM04(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                                ]
                                              )
                                              setC4R3VNo(8);
                                              setC4R4VNo(8);
                                              if (c4R3VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                        } else  if (c4r3MT === "M" && c4r2MT === "M") { 
                          setRow2NakedM04(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                            ]
                          )
                          setC4R2VNo(8);
                          setC4R3VNo(8);
                          if (c4R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
                        } else {
                           alert("This is not a medium door");
                        }
                        } else {
                        alert("Already Removed");
                        }
                  
                   
                  } else if (curX === col05X && curY === row03X) {
                      if (c5R3VNo === 9 || c5R3VNo === 10 || c5R3VNo === 11) {
                        if (c5r3MT === "M" && c5r4MT === "M") {  
                          setRow3NakedM05(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                ]
                                              )
                                              setC5R3VNo(8);
                                              setC5R4VNo(8);
                                              if (c5R3VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                        } else  if (c5r3MT === "M" && c5r2MT === "M") {     
                          setRow2NakedM05(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                            ]
                          )
                          setC5R2VNo(8);
                          setC5R3VNo(8);
                          if (c5R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }                  
                        } else {
                           alert("This is not a medium door");
                        }
                        } else {
                        alert("Already Removed");
                        }
                  
                  } else if (curX === col06X && curY === row03X) {
                        if (c6R3VNo === 9 || c6R3VNo === 10 || c6R3VNo === 11) {
                          if (c6r3MT === "M" && c6r4MT === "M") {  
                            setRow3NakedM06(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC6R3VNo(8);
                                                setC6R4VNo(8);
                                                if (c6R3VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                          } else if (c6r3MT === "M" && c6r2MT === "M") { 
                            setRow2NakedM06(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                              ]
                            )
                            setC6R2VNo(8);
                            setC6R3VNo(8);
                            if (c6R3VNo === 11) {
                              noHandleCount = noHandleCount - 1;
                            }
                          } else {
                             alert("This is not a medium door");
                          }	  
                          
                          } else {
                          alert("Already Removed");
                          }
                  
                } else if (curX === col07X && curY === row03X) {
                    if (c7R3VNo === 9 || c7R3VNo === 10 || c7R3VNo === 11) {
                      if (c7r3MT === "M" && c7r4MT === "M") {  
                        setRow3NakedM07(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                              ]
                                            )
                                            setC7R3VNo(8);
                                            setC7R4VNo(8);
                                            if (c7R3VNo === 11) {
                                              noHandleCount = noHandleCount - 1;
                                            }
                      } else if (c7r3MT === "M" && c7r2MT === "M") {  
                        setRow2NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                          ]
                        )
                        setC7R2VNo(8);
                        setC7R3VNo(8);
                        if (c7R3VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                      
                } else if (curX === col08X && curY === row03X) {
                    if (c8R3VNo === 9 || c8R3VNo === 10 || c8R3VNo === 11) {
                      if (c8r3MT === "M" && c8r4MT === "M") {  
                        setRow3NakedM08(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC8R3VNo(8);
                                                setC8R4VNo(8);
                                                if (c8R3VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else  if (c8r3MT === "M" && c8r2MT === "M") {  
                        setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                          ]
                        )
                        setC8R2VNo(8);
                        setC8R3VNo(8);
                        if (c8R3VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col09X && curY === row03X) {
                      if (c9R3VNo === 9 || c9R3VNo === 10 || c9R3VNo === 11) {
                        if (c9r3MT === "M" && c9r4MT === "M") {  
                          setRow3NakedM09(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                                    if (c9R3VNo === 11) {
                                                      noHandleCount = noHandleCount - 1;
                                                    }
                        } else if (c9r3MT === "M" && c9r2MT === "M") {  
                          setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                          setC9R2VNo(8);
                          setC9R3VNo(8);
                          if (c9R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
                        } else {
                           alert("This is not a medium door");
                        }
                        } else {
                        alert("Already Removed");
                        }
                  
                } else if (curX === col10X && curY === row03X) {
                    if (c10R3VNo === 9 || c10R3VNo === 10 || c10R3VNo === 11) {
                      if (c10r3MT === "M" && c10r4MT === "M") {  
                        setRow3NakedM10(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC10R3VNo(8);
                                                setC10R4VNo(8);
                                                if (c10R3VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else if (c10r3MT === "M" && c10r2MT === "M") {  
                        setRow2NakedM10(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                          ]
                        )
                        setC10R1VNo(8);
                        setC10R2VNo(8);
                        if (c10R3VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col11X && curY === row03X) {
                    if (c11R3VNo === 9 || c11R3VNo === 10 || c11R3VNo === 11) {
                      if (c11r3MT === "M" && c11r4MT === "M") {  
                        setRow3NakedM11(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                            ]
                                          )
                                          setC11R3VNo(8);
                                          setC11R4VNo(8);
                                          if (c11R3VNo === 11) {
                                            noHandleCount = noHandleCount - 1;
                                          }
                    } else if (c11r3MT === "M" && c11r2MT === "M") {  
                      setRow2NakedM11(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                        ]
                      )
                      setC11R2VNo(8);
                      setC11R3VNo(8);
                      if (c11R3VNo === 11) {
                        noHandleCount = noHandleCount - 1;
                      }
                    } else {
                        alert("This is not a medium door");
                    }
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col12X && curY === row03X) {
                    if (c12R3VNo === 9 || c12R3VNo === 10 || c12R3VNo === 11) {
                      if (c12r3MT === "M" && c12r4MT === "M") {  
                        setRow3NakedM12(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC12R3VNo(8);
                                                setC12R4VNo(8);
                                                if (c12R3VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else if (c12r3MT === "M" && c12r2MT === "M") {  
                        setRow2NakedM12(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                          ]
                        )
                        setC12R2VNo(8);
                        setC12R3VNo(8);
                        if (c12R3VNo === 11) {
                          noHandleCount = noHandleCount - 1;
                        }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col13X && curY === row03X) {
                      if (c13R3VNo === 9 || c13R3VNo === 10 || c13R3VNo === 11) {
                        if (c13r3MT === "M" && c13r4MT === "M") {  
                          setRow3NakedM13(
                                                     [
                                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                                   if (c13R3VNo === 11) {
                                                    noHandleCount = noHandleCount - 1;
                                                  }
                        } else if (c13r3MT === "M" && c13r2MT === "M") { 
                          setRow2NakedM13(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                            ]
                          )
                          setC13R2VNo(8);
                          setC13R3VNo(8);
                          if (c13R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
                        } else {
                            alert("This is not a medium door");
                        }	  
                       
                        } else {
                        alert("Already Removed");
                        }
                  
                } else if (curX === col14X && curY === row03X) {
                      if (c14R3VNo === 9 || c14R3VNo === 10 || c14R3VNo === 11) {
                        if (c14r3MT === "M" && c14r4MT === "M") {  
                          setRow3NakedM14(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                                                    if (c14R3VNo === 11) {
                                                      noHandleCount = noHandleCount - 1;
                                                    }
                        } else  if (c14r3MT === "M" && c14r2MT === "M") { 
                          setRow2NakedM14(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                            ]
                          )
                          setC14R2VNo(8);
                          setC14R3VNo(8);
                          if (c14R3VNo === 11) {
                            noHandleCount = noHandleCount - 1;
                          }
                        } else {
                           alert("This is not a medium door");
                        }	
                        } else {
                        alert("Already Removed");
                        }
                    
                  } else if (curX === col01X && curY === row04X) {
                    if (c1R4VNo === 9 || c1R4VNo === 10 || c1R4VNo === 11) {
                      if (c1r4MT === "M") {  
                        setRow3NakedM01(
                                                 [
                                                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                                 ]
                                               )
                                               setC1R3VNo(8);
                                               setC1R4VNo(8);
                                               if (c1R4VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                     } else {
                        alert("This is not a medium door");
                     }
                      } else {
                      alert("Already Removed");
                      }
                  
                
                  } else if (curX === col02X && curY === row04X) {
                      if (c2R4VNo === 9 || c2R4VNo === 10 || c2R4VNo === 11) {
                        if (c2r4MT === "M") {  
                          setRow3NakedM02(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                                ]
                                              )
                                              setC2R3VNo(8);
                                              setC2R4VNo(8);
                                              if (c2R4VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                        } else {
                           alert("This is not a medium door");
                        }	  
                        
                        } else {
                        alert("Already Removed");
                        }
                  
                  } else if (curX === col03X && curY === row04X) {
                    if (c3R4VNo === 9 || c3R4VNo === 10 || c3R4VNo === 11) {
                      if (c3r4MT === "M") {  
                        setRow3NakedM03(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC3R3VNo(8);
                                                setC3R4VNo(8);
                                                if (c3R4VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                   
                  } else if (curX === col04X && curY === row04X) {
                      if (c4R4VNo === 9 || c4R4VNo === 10 || c4R4VNo === 11) {
                        if (c4r4MT === "M") {  
                          setRow3NakedM04(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                                ]
                                              )
                                              setC4R3VNo(8);
                                              setC4R4VNo(8);
                                              if (c4R4VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                        } else {
                           alert("This is not a medium door");
                        }
                        } else {
                        alert("Already Removed");
                        }

                  } else if (curX === col05X && curY === row04X) {
                      if (c5R4VNo === 9 || c5R4VNo === 10 || c5R4VNo === 11) {
                        if (c5r4MT === "M") {  
                          setRow3NakedM05(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                ]
                                              )
                                              setC5R3VNo(8);
                                              setC5R4VNo(8);
                                              if (c5R4VNo === 11) {
                                                noHandleCount = noHandleCount - 1;
                                              }
                        } else {
                           alert("This is not a medium door");
                        }
                        } else {
                        alert("Already Removed");
                        }
                  
                  } else if (curX === col06X && curY === row04X) {
                        if (c6R4VNo === 9 || c6R4VNo === 10 || c6R4VNo === 11) {
                          if (c6r4MT === "M") {  
                            setRow3NakedM06(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC6R3VNo(8);
                                                setC6R4VNo(8);
                                                if (c6R4VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                          } else {
                             alert("This is not a medium door");
                          }	  
                          
                          } else {
                          alert("Already Removed");
                          }
                  
                } else if (curX === col07X && curY === row04X) {
                    if (c7R4VNo === 9 || c7R4VNo === 10 || c7R4VNo === 11) {
                      if (c7r4MT === "M") {  
                        setRow3NakedM07(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                              ]
                                            )
                                            setC7R3VNo(8);
                                            setC7R4VNo(8);
                                            if (c7R4VNo === 11) {
                                              noHandleCount = noHandleCount - 1;
                                            }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                      
                } else if (curX === col08X && curY === row04X) {
                    if (c8R4VNo === 9 || c8R4VNo === 10 || c8R4VNo === 11) {
                      if (c8r4MT === "M") {  
                        setRow3NakedM08(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC8R3VNo(8);
                                                setC8R4VNo(8);
                                                if (c8R4VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col09X && curY === row04X) {
                      if (c9R4VNo === 9 || c9R4VNo === 10 || c9R4VNo === 11) {
                        if (c9r4MT === "M") {  
                          setRow3NakedM09(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                                    if (c9R4VNo === 11) {
                                                      noHandleCount = noHandleCount - 1;
                                                    }
                        } else {
                           alert("This is not a medium door");
                        }
                        } else {
                        alert("Already Removed");
                        }
                  
                } else if (curX === col10X && curY === row04X) {
                    if (c10R4VNo === 9 || c10R4VNo === 10 || c10R4VNo === 11) {
                      if (c10r4MT === "M") {  
                        setRow3NakedM10(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC10R3VNo(8);
                                                setC10R4VNo(8);
                                                if (c10R4VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col11X && curY === row04X) {
                    if (c11R4VNo === 9 || c11R4VNo === 10 || c11R4VNo === 11) {
                      if (c11r4MT === "M") {  
                        setRow3NakedM11(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                            ]
                                          )
                                          setC11R3VNo(8);
                                          setC11R4VNo(8);
                                          if (c11R4VNo === 11) {
                                            noHandleCount = noHandleCount - 1;
                                          }
                    } else {
                        alert("This is not a medium door");
                    }
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col12X && curY === row04X) {
                    if (c12R4VNo === 9 || c12R4VNo === 10 || c12R4VNo === 11) {
                      if (c12r4MT === "M") {  
                        setRow3NakedM12(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC12R3VNo(8);
                                                setC12R4VNo(8);
                                                if (c12R4VNo === 11) {
                                                  noHandleCount = noHandleCount - 1;
                                                }
                      } else {
                         alert("This is not a medium door");
                      }	  
                      
                      } else {
                      alert("Already Removed");
                      }
                  
                } else if (curX === col13X && curY === row04X) {
                      if (c13R4VNo === 9 || c13R4VNo === 10 || c13R4VNo === 11) {
                        if (c13r4MT === "M") {  
                          setRow3NakedM13(
                                                     [
                                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                                   if (c13R4VNo === 11) {
                                                    noHandleCount = noHandleCount - 1;
                                                  }
                        } else {
                            alert("This is not a medium door");
                        }	  
                       
                        } else {
                        alert("Already Removed");
                        }
                  
                } else if (curX === col14X && curY === row04X) {
                      if (c14R4VNo === 9 || c14R4VNo === 10 || c14R4VNo === 11) {
                        if (c14r4MT === "M") {  
                          setRow3NakedM14(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                                                    if (c14R4VNo === 11) {
                                                      noHandleCount = noHandleCount - 1;
                                                    }
                        } else {
                           alert("This is not a medium door");
                        }	
                        } else {
                        alert("Already Removed");
                        }
                    
                  }
  }

  // Seperator Removal
const addSeperatorBottom = (e)=> {
  if ((curX === col01X && curY === row01X) || (curX === col01X && curY === row02X) || (curX === col01X && curY === row03X) || (curX === col01X && curY === row04X)) {
    if (c1r1MT === "L" || c1r2MT === "L" || c1r3MT === "L" || c1r4MT === "L") {
      if (c1R1VNo === 1 || c1R2VNo === 1 || c1R3VNo === 1 || c1R4VNo === 1){
        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(1);
            setC1R2VNo(1);
            setC1R3VNo(1);
            setC1R4VNo(1);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
      } else if (c1R1VNo === 2 || c1R2VNo === 2 || c1R3VNo === 2 || c1R4VNo === 2){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
            setC1R2VNo(2);
            setC1R3VNo(2);
            setC1R4VNo(2);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 3 || c1R2VNo === 3 || c1R3VNo === 3 || c1R4VNo === 3){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(3);
            setC1R2VNo(3);
            setC1R3VNo(3);
            setC1R4VNo(3);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 4 || c1R2VNo === 4 || c1R3VNo === 4 || c1R4VNo === 4){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(4);
            setC1R2VNo(4);
            setC1R3VNo(4);
            setC1R4VNo(4);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 5 || c1R2VNo === 5 || c1R3VNo === 5 || c1R4VNo === 5){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(5);
            setC1R2VNo(5);
            setC1R3VNo(5);
            setC1R4VNo(5);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 6 || c1R2VNo === 6 || c1R3VNo === 6 || c1R4VNo === 6){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(6);
            setC1R2VNo(6);
            setC1R3VNo(6);
            setC1R4VNo(6);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 7 || c1R2VNo === 7 || c1R3VNo === 7 || c1R4VNo === 7){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(7);
            setC1R2VNo(7);
            setC1R3VNo(7);
            setC1R4VNo(7);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 8 || c1R2VNo === 8 || c1R3VNo === 8 || c1R4VNo === 8){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(8);
            setC1R2VNo(8);
            setC1R3VNo(8);
            setC1R4VNo(8);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 9 || c1R2VNo === 9 || c1R3VNo === 9 || c1R4VNo === 9){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(9);
            setC1R2VNo(9);
            setC1R3VNo(9);
            setC1R4VNo(9);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 10 || c1R2VNo === 10 || c1R3VNo === 10 || c1R4VNo === 10){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(10);
            setC1R2VNo(10);
            setC1R3VNo(10);
            setC1R4VNo(10);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c1R1VNo === 11 || c1R2VNo === 11 || c1R3VNo === 11 || c1R4VNo === 11){

        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnLC1(true);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnLC1(true);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(11);
            setC1R2VNo(11);
            setC1R3VNo(11);
            setC1R4VNo(11);
            setSepOnLC1(true);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed");
    }
  } else if ((curX === col02X && curY === row01X) || (curX === col02X && curY === row02X) || (curX === col02X && curY === row03X) || (curX === col02X && curY === row04X)) {
    if (c2r1MT === "L" || c2r2MT === "L" || c2r3MT === "L" || c2r4MT === "L") {
      if (c2R1VNo === 1 || c2R2VNo === 1 || c2R3VNo === 1 || c2R4VNo === 1){
        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(1);
            setC2R2VNo(1);
            setC2R3VNo(1);
            setC2R4VNo(1);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
      } else if (c2R1VNo === 2 || c2R2VNo === 2 || c2R3VNo === 2 || c2R4VNo === 2){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(2);
            setC2R2VNo(2);
            setC2R3VNo(2);
            setC2R4VNo(2);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 3 || c2R2VNo === 3 || c2R3VNo === 3 || c2R4VNo === 3){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(3);
            setC2R2VNo(3);
            setC2R3VNo(3);
            setC2R4VNo(3);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 4 || c2R2VNo === 4 || c2R3VNo === 4 || c2R4VNo === 4){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(4);
            setC2R2VNo(4);
            setC2R3VNo(4);
            setC2R4VNo(4);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 5 || c2R2VNo === 5 || c2R3VNo === 5 || c2R4VNo === 5){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(5);
            setC2R2VNo(5);
            setC2R3VNo(5);
            setC2R4VNo(5);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 6 || c2R2VNo === 6 || c2R3VNo === 6 || c2R4VNo === 6){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(6);
            setC2R2VNo(6);
            setC2R3VNo(6);
            setC2R4VNo(6);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 7 || c2R2VNo === 7 || c2R3VNo === 7 || c2R4VNo === 7){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(7);
            setC2R2VNo(7);
            setC2R3VNo(7);
            setC2R4VNo(7);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 8 || c2R2VNo === 8 || c2R3VNo === 8 || c2R4VNo === 8){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(8);
            setC2R2VNo(8);
            setC2R3VNo(8);
            setC2R4VNo(8);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 9 || c2R2VNo === 9 || c2R3VNo === 9 || c2R4VNo === 9){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(9);
            setC2R2VNo(9);
            setC2R3VNo(9);
            setC2R4VNo(9);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 10 || c2R2VNo === 10 || c2R3VNo === 10 || c2R4VNo === 10){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(10);
            setC2R2VNo(10);
            setC2R3VNo(10);
            setC2R4VNo(10);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c2R1VNo === 11 || c2R2VNo === 11 || c2R3VNo === 11 || c2R4VNo === 11){

        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnLC2(true);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnLC2(true);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(11);
            setC2R2VNo(11);
            setC2R3VNo(11);
            setC2R4VNo(11);
            setSepOnLC2(true);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col03X && curY === row01X) || (curX === col03X && curY === row02X) || (curX === col03X && curY === row03X) || (curX === col03X && curY === row04X)) {
    if (c3r1MT === "L" || c3r2MT === "L" || c3r3MT === "L" || c3r4MT === "L") {
      if (c3R1VNo === 1 || c3R2VNo === 1 || c3R3VNo === 1 || c3R4VNo === 1){
        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(1);
            setC3R2VNo(1);
            setC3R3VNo(1);
            setC3R4VNo(1);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
      } else if (c3R1VNo === 2 || c3R2VNo === 2 || c3R3VNo === 2 || c3R4VNo === 2){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(2);
            setC3R2VNo(2);
            setC3R3VNo(2);
            setC3R4VNo(2);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 3 || c3R2VNo === 3 || c3R3VNo === 3 || c3R4VNo === 3){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(3);
            setC3R2VNo(3);
            setC3R3VNo(3);
            setC3R4VNo(3);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 4 || c3R2VNo === 4 || c3R3VNo === 4 || c3R4VNo === 4){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(4);
            setC3R2VNo(4);
            setC3R3VNo(4);
            setC3R4VNo(4);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 5 || c3R2VNo === 5 || c3R3VNo === 5 || c3R4VNo === 5){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(5);
            setC3R2VNo(5);
            setC3R3VNo(5);
            setC3R4VNo(5);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 6 || c3R2VNo === 6 || c3R3VNo === 6 || c3R4VNo === 6){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(6);
            setC3R2VNo(6);
            setC3R3VNo(6);
            setC3R4VNo(6);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 7 || c3R2VNo === 7 || c3R3VNo === 7 || c3R4VNo === 7){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(7);
            setC3R2VNo(7);
            setC3R3VNo(7);
            setC3R4VNo(7);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 8 || c3R2VNo === 8 || c3R3VNo === 8 || c3R4VNo === 8){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(8);
            setC3R2VNo(8);
            setC3R3VNo(8);
            setC3R4VNo(8);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 9 || c3R2VNo === 9 || c3R3VNo === 9 || c3R4VNo === 9){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(9);
            setC3R2VNo(9);
            setC3R3VNo(9);
            setC3R4VNo(9);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 10 || c3R2VNo === 10 || c3R3VNo === 10 || c3R4VNo === 10){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(10);
            setC3R2VNo(10);
            setC3R3VNo(10);
            setC3R4VNo(10);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c3R1VNo === 11 || c3R2VNo === 11 || c3R3VNo === 11 || c3R4VNo === 11){

        if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnLC3(true);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnLC3(true);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(11);
            setC3R2VNo(11);
            setC3R3VNo(11);
            setC3R4VNo(11);
            setSepOnLC3(true);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col04X && curY === row01X) || (curX === col04X && curY === row02X) || (curX === col04X && curY === row03X) || (curX === col04X && curY === row04X)) {
    if (c4r1MT === "L" || c4r2MT === "L" || c4r3MT === "L" || c4r4MT === "L") {
      if (c4R1VNo === 1 || c4R2VNo === 1 || c4R3VNo === 1 || c4R4VNo === 1){
        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(1);
            setC4R2VNo(1);
            setC4R3VNo(1);
            setC4R4VNo(1);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
      } else if (c4R1VNo === 2 || c4R2VNo === 2 || c4R3VNo === 2 || c4R4VNo === 2){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(2);
            setC4R2VNo(2);
            setC4R3VNo(2);
            setC4R4VNo(2);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 3 || c4R2VNo === 3 || c4R3VNo === 3 || c4R4VNo === 3){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(3);
            setC4R2VNo(3);
            setC4R3VNo(3);
            setC4R4VNo(3);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 4 || c4R2VNo === 4 || c4R3VNo === 4 || c4R4VNo === 4){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(4);
            setC4R2VNo(4);
            setC4R3VNo(4);
            setC4R4VNo(4);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 5 || c4R2VNo === 5 || c4R3VNo === 5 || c4R4VNo === 5){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(5);
            setC4R2VNo(5);
            setC4R3VNo(5);
            setC4R4VNo(5);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 6 || c4R2VNo === 6 || c4R3VNo === 6 || c4R4VNo === 6){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(6);
            setC4R2VNo(6);
            setC4R3VNo(6);
            setC4R4VNo(6);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 7 || c4R2VNo === 7 || c4R3VNo === 7 || c4R4VNo === 7){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(7);
            setC4R2VNo(7);
            setC4R3VNo(7);
            setC4R4VNo(7);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 8 || c4R2VNo === 8 || c4R3VNo === 8 || c4R4VNo === 8){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(8);
            setC4R2VNo(8);
            setC4R3VNo(8);
            setC4R4VNo(8);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 9 || c4R2VNo === 9 || c4R3VNo === 9 || c4R4VNo === 9){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(9);
            setC4R2VNo(9);
            setC4R3VNo(9);
            setC4R4VNo(9);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 10 || c4R2VNo === 10 || c4R3VNo === 10 || c4R4VNo === 10){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(10);
            setC4R2VNo(10);
            setC4R3VNo(10);
            setC4R4VNo(10);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c4R1VNo === 11 || c4R2VNo === 11 || c4R3VNo === 11 || c4R4VNo === 11){

        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnLC4(true);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnLC4(true);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(11);
            setC4R2VNo(11);
            setC4R3VNo(11);
            setC4R4VNo(11);
            setSepOnLC4(true);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col05X && curY === row01X) || (curX === col05X && curY === row02X) || (curX === col05X && curY === row03X) || (curX === col05X && curY === row04X)) {
    if (c5r1MT === "L" || c5r2MT === "L" || c5r3MT === "L" || c5r4MT === "L") {
      if (c5R1VNo === 1 || c5R2VNo === 1 || c5R3VNo === 1 || c5R4VNo === 1){
        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(1);
            setC5R2VNo(1);
            setC5R3VNo(1);
            setC5R4VNo(1);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
      } else if (c5R1VNo === 2 || c5R2VNo === 2 || c5R3VNo === 2 || c5R4VNo === 2){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(2);
            setC5R2VNo(2);
            setC5R3VNo(2);
            setC5R4VNo(2);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 3 || c5R2VNo === 3 || c5R3VNo === 3 || c5R4VNo === 3){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(3);
            setC5R2VNo(3);
            setC5R3VNo(3);
            setC5R4VNo(3);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 4 || c5R2VNo === 4 || c5R3VNo === 4 || c5R4VNo === 4){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(4);
            setC5R2VNo(4);
            setC5R3VNo(4);
            setC5R4VNo(4);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 5 || c5R2VNo === 5 || c5R3VNo === 5 || c5R4VNo === 5){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(5);
            setC5R2VNo(5);
            setC5R3VNo(5);
            setC5R4VNo(5);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 6 || c5R2VNo === 6 || c5R3VNo === 6 || c5R4VNo === 6){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(6);
            setC5R2VNo(6);
            setC5R3VNo(6);
            setC5R4VNo(6);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 7 || c5R2VNo === 7 || c5R3VNo === 7 || c5R4VNo === 7){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(7);
            setC5R2VNo(7);
            setC5R3VNo(7);
            setC5R4VNo(7);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 8 || c5R2VNo === 8 || c5R3VNo === 8 || c5R4VNo === 8){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(8);
            setC5R2VNo(8);
            setC5R3VNo(8);
            setC5R4VNo(8);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 9 || c5R2VNo === 9 || c5R3VNo === 9 || c5R4VNo === 9){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(9);
            setC5R2VNo(9);
            setC5R3VNo(9);
            setC5R4VNo(9);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 10 || c5R2VNo === 10 || c5R3VNo === 10 || c5R4VNo === 10){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(10);
            setC5R2VNo(10);
            setC5R3VNo(10);
            setC5R4VNo(10);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c5R1VNo === 11 || c5R2VNo === 11 || c5R3VNo === 11 || c5R4VNo === 11){

        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnLC5(true);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnLC5(true);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(11);
            setC5R2VNo(11);
            setC5R3VNo(11);
            setC5R4VNo(11);
            setSepOnLC5(true);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col06X && curY === row01X) || (curX === col06X && curY === row02X) || (curX === col06X && curY === row03X) || (curX === col06X && curY === row04X)) {
    if (c6r1MT === "L" || c6r2MT === "L" || c6r3MT === "L" || c6r4MT === "L") {
      if (c6R1VNo === 1 || c6R2VNo === 1 || c6R3VNo === 1 || c6R4VNo === 1){
        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(1);
            setC6R2VNo(1);
            setC6R3VNo(1);
            setC6R4VNo(1);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
      } else if (c6R1VNo === 2 || c6R2VNo === 2 || c6R3VNo === 2 || c6R4VNo === 2){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(2);
            setC6R2VNo(2);
            setC6R3VNo(2);
            setC6R4VNo(2);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 3 || c6R2VNo === 3 || c6R3VNo === 3 || c6R4VNo === 3){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(3);
            setC6R2VNo(3);
            setC6R3VNo(3);
            setC6R4VNo(3);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 4 || c6R2VNo === 4 || c6R3VNo === 4 || c6R4VNo === 4){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(4);
            setC6R2VNo(4);
            setC6R3VNo(4);
            setC6R4VNo(4);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 5 || c6R2VNo === 5 || c6R3VNo === 5 || c6R4VNo === 5){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
            setC6R3VNo(5);
            setC6R4VNo(5);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 6 || c6R2VNo === 6 || c6R3VNo === 6 || c6R4VNo === 6){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(6);
            setC6R2VNo(6);
            setC6R3VNo(6);
            setC6R4VNo(6);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 7 || c6R2VNo === 7 || c6R3VNo === 7 || c6R4VNo === 7){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(7);
            setC6R2VNo(7);
            setC6R3VNo(7);
            setC6R4VNo(7);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 8 || c6R2VNo === 8 || c6R3VNo === 8 || c6R4VNo === 8){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(8);
            setC6R2VNo(8);
            setC6R3VNo(8);
            setC6R4VNo(8);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 9 || c6R2VNo === 9 || c6R3VNo === 9 || c6R4VNo === 9){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(9);
            setC6R2VNo(9);
            setC6R3VNo(9);
            setC6R4VNo(9);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 10 || c6R2VNo === 10 || c6R3VNo === 10 || c6R4VNo === 10){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(10);
            setC6R2VNo(10);
            setC6R3VNo(10);
            setC6R4VNo(10);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c6R1VNo === 11 || c6R2VNo === 11 || c6R3VNo === 11 || c6R4VNo === 11){

        if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnLC6(true);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnLC6(true);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(11);
            setC6R2VNo(11);
            setC6R3VNo(11);
            setC6R4VNo(11);
            setSepOnLC6(true);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col07X && curY === row01X) || (curX === col07X && curY === row02X) || (curX === col07X && curY === row03X) || (curX === col07X && curY === row04X)) {
    if (c7r1MT === "L" || c7r2MT === "L" || c7r3MT === "L" || c7r4MT === "L") {
      if (c7R1VNo === 1 || c7R2VNo === 1 || c7R3VNo === 1 || c7R4VNo === 1){
        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(1);
            setC7R2VNo(1);
            setC7R3VNo(1);
            setC7R4VNo(1);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
      } else if (c7R1VNo === 2 || c7R2VNo === 2 || c7R3VNo === 2 || c7R4VNo === 2){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(2);
            setC7R2VNo(2);
            setC7R3VNo(2);
            setC7R4VNo(2);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 3 || c7R2VNo === 3 || c7R3VNo === 3 || c7R4VNo === 3){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(3);
            setC7R2VNo(3);
            setC7R3VNo(3);
            setC7R4VNo(3);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 4 || c7R2VNo === 4 || c7R3VNo === 4 || c7R4VNo === 4){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
            setC7R2VNo(4);
            setC7R3VNo(4);
            setC7R4VNo(4);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 5 || c7R2VNo === 5 || c7R3VNo === 5 || c7R4VNo === 5){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
            setC7R2VNo(5);
            setC7R3VNo(5);
            setC7R4VNo(5);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 6 || c7R2VNo === 6 || c7R3VNo === 6 || c7R4VNo === 6){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(6);
            setC7R2VNo(6);
            setC7R3VNo(6);
            setC7R4VNo(6);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 7 || c7R2VNo === 7 || c7R3VNo === 7 || c7R4VNo === 7){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
            setC7R2VNo(7);
            setC7R3VNo(7);
            setC7R4VNo(7);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 8 || c7R2VNo === 8 || c7R3VNo === 8 || c7R4VNo === 8){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(8);
            setC7R2VNo(8);
            setC7R3VNo(8);
            setC7R4VNo(8);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 9 || c7R2VNo === 9 || c7R3VNo === 9 || c7R4VNo === 9){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(9);
            setC7R2VNo(9);
            setC7R3VNo(9);
            setC7R4VNo(9);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 10 || c7R2VNo === 10 || c7R3VNo === 10 || c7R4VNo === 10){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(10);
            setC7R2VNo(10);
            setC7R3VNo(10);
            setC7R4VNo(10);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c7R1VNo === 11 || c7R2VNo === 11 || c7R3VNo === 11 || c7R4VNo === 11){

        if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnLC7(true);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnLC7(true);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(11);
            setC7R2VNo(11);
            setC7R3VNo(11);
            setC7R4VNo(11);
            setSepOnLC7(true);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col08X && curY === row01X) || (curX === col08X && curY === row02X) || (curX === col08X && curY === row03X) || (curX === col08X && curY === row04X)) {
    if (c8r1MT === "L" || c8r2MT === "L" || c8r3MT === "L" || c8r4MT === "L") {
      if (c8R1VNo === 1 || c8R2VNo === 1 || c8R3VNo === 1 || c8R4VNo === 1){
        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(1);
            setC8R2VNo(1);
            setC8R3VNo(1);
            setC8R4VNo(1);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
      } else if (c8R1VNo === 2 || c8R2VNo === 2 || c8R3VNo === 2 || c8R4VNo === 2){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(2);
            setC8R2VNo(2);
            setC8R3VNo(2);
            setC8R4VNo(2);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 3 || c8R2VNo === 3 || c8R3VNo === 3 || c8R4VNo === 3){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(3);
            setC8R2VNo(3);
            setC8R3VNo(3);
            setC8R4VNo(3);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 4 || c8R2VNo === 4 || c8R3VNo === 4 || c8R4VNo === 4){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
            setC8R2VNo(4);
            setC8R3VNo(4);
            setC8R4VNo(4);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 5 || c8R2VNo === 5 || c8R3VNo === 5 || c8R4VNo === 5){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(5);
            setC8R2VNo(5);
            setC8R3VNo(5);
            setC8R4VNo(5);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 6 || c8R2VNo === 6 || c8R3VNo === 6 || c8R4VNo === 6){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(6);
            setC8R2VNo(6);
            setC8R3VNo(6);
            setC8R4VNo(6);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 7 || c8R2VNo === 7 || c8R3VNo === 7 || c8R4VNo === 7){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(7);
            setC8R2VNo(7);
            setC8R3VNo(7);
            setC8R4VNo(7);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 8 || c8R2VNo === 8 || c8R3VNo === 8 || c8R4VNo === 8){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(8);
            setC8R2VNo(8);
            setC8R3VNo(8);
            setC8R4VNo(8);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 9 || c8R2VNo === 9 || c8R3VNo === 9 || c8R4VNo === 9){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(9);
            setC8R2VNo(9);
            setC8R3VNo(9);
            setC8R4VNo(9);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 10 || c8R2VNo === 10 || c8R3VNo === 10 || c8R4VNo === 10){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(10);
            setC8R2VNo(10);
            setC8R3VNo(10);
            setC8R4VNo(10);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c8R1VNo === 11 || c8R2VNo === 11 || c8R3VNo === 11 || c8R4VNo === 11){

        if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnLC8(true);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnLC8(true);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(11);
            setC8R2VNo(11);
            setC8R3VNo(11);
            setC8R4VNo(11);
            setSepOnLC8(true);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col09X && curY === row01X) || (curX === col09X && curY === row02X) || (curX === col09X && curY === row03X) || (curX === col09X && curY === row04X)) {
    if (c9r1MT === "L" || c9r2MT === "L" || c9r3MT === "L" || c9r4MT === "L") {
      if (c9R1VNo === 1 || c9R2VNo === 1 || c9R3VNo === 1 || c9R4VNo === 1){
        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(1);
            setC9R2VNo(1);
            setC9R3VNo(1);
            setC9R4VNo(1);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
      } else if (c9R1VNo === 2 || c9R2VNo === 2 || c9R3VNo === 2 || c9R4VNo === 2){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(2);
            setC9R2VNo(2);
            setC9R3VNo(2);
            setC9R4VNo(2);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 3 || c9R2VNo === 3 || c9R3VNo === 3 || c9R4VNo === 3){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(3);
            setC9R2VNo(3);
            setC9R3VNo(3);
            setC9R4VNo(3);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 4 || c9R2VNo === 4 || c9R3VNo === 4 || c9R4VNo === 4){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(4);
            setC9R2VNo(4);
            setC9R3VNo(4);
            setC9R4VNo(4);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 5 || c9R2VNo === 5 || c9R3VNo === 5 || c9R4VNo === 5){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(5);
            setC9R2VNo(5);
            setC9R3VNo(5);
            setC9R4VNo(5);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 6 || c9R2VNo === 6 || c9R3VNo === 6 || c9R4VNo === 6){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(6);
            setC9R2VNo(6);
            setC9R3VNo(6);
            setC9R4VNo(6);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 7 || c9R2VNo === 7 || c9R3VNo === 7 || c9R4VNo === 7){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(7);
            setC9R2VNo(7);
            setC9R3VNo(7);
            setC9R4VNo(7);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 8 || c9R2VNo === 8 || c9R3VNo === 8 || c9R4VNo === 8){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(8);
            setC9R2VNo(8);
            setC9R3VNo(8);
            setC9R4VNo(8);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 9 || c9R2VNo === 9 || c9R3VNo === 9 || c9R4VNo === 9){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(9);
            setC9R2VNo(9);
            setC9R3VNo(9);
            setC9R4VNo(9);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 10 || c9R2VNo === 10 || c9R3VNo === 10 || c9R4VNo === 10){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(10);
            setC9R2VNo(10);
            setC9R3VNo(10);
            setC9R4VNo(10);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c9R1VNo === 11 || c9R2VNo === 11 || c9R3VNo === 11 || c9R4VNo === 11){

        if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnLC9(true);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnLC9(true);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(11);
            setC9R2VNo(11);
            setC9R3VNo(11);
            setC9R4VNo(11);
            setSepOnLC9(true);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col10X && curY === row01X) || (curX === col10X && curY === row02X) || (curX === col10X && curY === row03X) || (curX === col10X && curY === row04X)) {
    if (c10r1MT === "L" || c10r2MT === "L" || c10r3MT === "L" || c10r4MT === "L") {
      if (c10R1VNo === 1 || c10R2VNo === 1 || c10R3VNo === 1 || c10R4VNo === 1){
        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(1);
            setC10R2VNo(1);
            setC10R3VNo(1);
            setC10R4VNo(1);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
      } else if (c10R1VNo === 2 || c10R2VNo === 2 || c10R3VNo === 2 || c10R4VNo === 2){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(2);
            setC10R2VNo(2);
            setC10R3VNo(2);
            setC10R4VNo(2);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 3 || c10R2VNo === 3 || c10R3VNo === 3 || c10R4VNo === 3){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(3);
            setC10R2VNo(3);
            setC10R3VNo(3);
            setC10R4VNo(3);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 4 || c10R2VNo === 4 || c10R3VNo === 4 || c10R4VNo === 4){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(4);
            setC10R2VNo(4);
            setC10R3VNo(4);
            setC10R4VNo(4);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 5 || c10R2VNo === 5 || c10R3VNo === 5 || c10R4VNo === 5){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
            setC10R3VNo(5);
            setC10R4VNo(5);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 6 || c10R2VNo === 6 || c10R3VNo === 6 || c10R4VNo === 6){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(6);
            setC10R2VNo(6);
            setC10R3VNo(6);
            setC10R4VNo(6);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 7 || c10R2VNo === 7 || c10R3VNo === 7 || c10R4VNo === 7){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(7);
            setC10R2VNo(7);
            setC10R3VNo(7);
            setC10R4VNo(7);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 8 || c10R2VNo === 8 || c10R3VNo === 8 || c10R4VNo === 8){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(8);
            setC10R2VNo(8);
            setC10R3VNo(8);
            setC10R4VNo(8);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 9 || c10R2VNo === 9 || c10R3VNo === 9 || c10R4VNo === 9){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(9);
            setC10R2VNo(9);
            setC10R3VNo(9);
            setC10R4VNo(9);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 10 || c10R2VNo === 10 || c10R3VNo === 10 || c10R4VNo === 10){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(10);
            setC10R2VNo(10);
            setC10R3VNo(10);
            setC10R4VNo(10);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c10R1VNo === 11 || c10R2VNo === 11 || c10R3VNo === 11 || c10R4VNo === 11){

        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnLC10(true);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnLC10(true);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(11);
            setC10R2VNo(11);
            setC10R3VNo(11);
            setC10R4VNo(11);
            setSepOnLC10(true);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col11X && curY === row01X) || (curX === col11X && curY === row02X) || (curX === col11X && curY === row03X) || (curX === col11X && curY === row04X)) {
    if (c11r1MT === "L" || c11r2MT === "L" || c11r3MT === "L" || c11r4MT === "L") {
      if (c11R1VNo === 1 || c11R2VNo === 1 || c11R3VNo === 1 || c11R4VNo === 1){
        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(setBaseNakedL11
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(1);
            setC11R2VNo(1);
            setC11R3VNo(1);
            setC11R4VNo(1);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
      } else if (c11R1VNo === 2 || c11R2VNo === 2 || c11R3VNo === 2 || c11R4VNo === 2){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(2);
            setC11R2VNo(2);
            setC11R3VNo(2);
            setC11R4VNo(2);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 3 || c11R2VNo === 3 || c11R3VNo === 3 || c11R4VNo === 3){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(3);
            setC11R2VNo(3);
            setC11R3VNo(3);
            setC11R4VNo(3);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 4 || c11R2VNo === 4 || c11R3VNo === 4 || c11R4VNo === 4){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(4);
            setC11R2VNo(4);
            setC11R3VNo(4);
            setC11R4VNo(4);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 5 || c11R2VNo === 5 || c11R3VNo === 5 || c11R4VNo === 5){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
            setC11R2VNo(5);
            setC11R3VNo(5);
            setC11R4VNo(5);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 6 || c11R2VNo === 6 || c11R3VNo === 6 || c11R4VNo === 6){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(6);
            setC11R2VNo(6);
            setC11R3VNo(6);
            setC11R4VNo(6);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 7 || c11R2VNo === 7 || c11R3VNo === 7 || c11R4VNo === 7){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(7);
            setC11R2VNo(7);
            setC11R3VNo(7);
            setC11R4VNo(7);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 8 || c11R2VNo === 8 || c11R3VNo === 8 || c11R4VNo === 8){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(8);
            setC11R2VNo(8);
            setC11R3VNo(8);
            setC11R4VNo(8);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 9 || c11R2VNo === 9 || c11R3VNo === 9 || c11R4VNo === 9){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(9);
            setC11R2VNo(9);
            setC11R3VNo(9);
            setC11R4VNo(9);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 10 || c11R2VNo === 10 || c11R3VNo === 10 || c11R4VNo === 10){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(10);
            setC11R2VNo(10);
            setC11R3VNo(10);
            setC11R4VNo(10);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c11R1VNo === 11 || c11R2VNo === 11 || c11R3VNo === 11 || c11R4VNo === 11){

        if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnLC11(true);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnLC11(true);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(11);
            setC11R2VNo(11);
            setC11R3VNo(11);
            setC11R4VNo(11);
            setSepOnLC11(true);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col12X && curY === row01X) || (curX === col12X && curY === row02X) || (curX === col12X && curY === row03X) || (curX === col12X && curY === row04X)) {
    if (c12r1MT === "L" || c12r2MT === "L" || c12r3MT === "L" || c12r4MT === "L") {
      if (c12R1VNo === 1 || c12R2VNo === 1 || c12R3VNo === 1 || c12R4VNo === 1){
        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(setBaseNakedL12
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(1);
            setC12R2VNo(1);
            setC12R3VNo(1);
            setC12R4VNo(1);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
      } else if (c12R1VNo === 2 || c12R2VNo === 2 || c12R3VNo === 2 || c12R4VNo === 2){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(2);
            setC12R2VNo(2);
            setC12R3VNo(2);
            setC12R4VNo(2);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 3 || c12R2VNo === 3 || c12R3VNo === 3 || c12R4VNo === 3){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(3);
            setC12R2VNo(3);
            setC12R3VNo(3);
            setC12R4VNo(3);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 4 || c12R2VNo === 4 || c12R3VNo === 4 || c12R4VNo === 4){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(4);
            setC12R2VNo(4);
            setC12R3VNo(4);
            setC12R4VNo(4);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 5 || c12R2VNo === 5 || c12R3VNo === 5 || c12R4VNo === 5){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(5);
            setC12R2VNo(5);
            setC12R3VNo(5);
            setC12R4VNo(5);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 6 || c12R2VNo === 6 || c12R3VNo === 6 || c12R4VNo === 6){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(6);
            setC12R2VNo(6);
            setC12R3VNo(6);
            setC12R4VNo(6);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 7 || c12R2VNo === 7 || c12R3VNo === 7 || c12R4VNo === 7){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(7);
            setC12R2VNo(7);
            setC12R3VNo(7);
            setC12R4VNo(7);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 8 || c12R2VNo === 8 || c12R3VNo === 8 || c12R4VNo === 8){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(8);
            setC12R2VNo(8);
            setC12R3VNo(8);
            setC12R4VNo(8);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 9 || c12R2VNo === 9 || c12R3VNo === 9 || c12R4VNo === 9){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(9);
            setC12R2VNo(9);
            setC12R3VNo(9);
            setC12R4VNo(9);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 10 || c12R2VNo === 10 || c12R3VNo === 10 || c12R4VNo === 10){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(10);
            setC12R2VNo(10);
            setC12R3VNo(10);
            setC12R4VNo(10);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c12R1VNo === 11 || c12R2VNo === 11 || c12R3VNo === 11 || c12R4VNo === 11){

        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnLC12(true);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnLC12(true);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(11);
            setC12R2VNo(11);
            setC12R3VNo(11);
            setC12R4VNo(11);
            setSepOnLC12(true);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col13X && curY === row01X) || (curX === col13X && curY === row02X) || (curX === col13X && curY === row03X) || (curX === col13X && curY === row04X)) {
    if (c13r1MT === "L" || c13r2MT === "L" || c13r3MT === "L" || c13r4MT === "L") {
      if (c13R1VNo === 1 || c13R2VNo === 1 || c13R3VNo === 1 || c13R4VNo === 1){
        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(setBaseNakedL13
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(1);
            setC13R2VNo(1);
            setC13R3VNo(1);
            setC13R4VNo(1);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
      } else if (c13R1VNo === 2 || c13R2VNo === 2 || c13R3VNo === 2 || c13R4VNo === 2){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(2);
            setC13R2VNo(2);
            setC13R3VNo(2);
            setC13R4VNo(2);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 3 || c13R2VNo === 3 || c13R3VNo === 3 || c13R4VNo === 3){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(3);
            setC13R2VNo(3);
            setC13R3VNo(3);
            setC13R4VNo(3);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 4 || c13R2VNo === 4 || c13R3VNo === 4 || c13R4VNo === 4){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(4);
            setC13R2VNo(4);
            setC13R3VNo(4);
            setC13R4VNo(4);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 5 || c13R2VNo === 5 || c13R3VNo === 5 || c13R4VNo === 5){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(5);
            setC13R2VNo(5);
            setC13R3VNo(5);
            setC13R4VNo(5);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 6 || c13R2VNo === 6 || c13R3VNo === 6 || c13R4VNo === 6){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(6);
            setC13R2VNo(6);
            setC13R3VNo(6);
            setC13R4VNo(6);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 7 || c13R2VNo === 7 || c13R3VNo === 7 || c13R4VNo === 7){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(7);
            setC13R2VNo(7);
            setC13R3VNo(7);
            setC13R4VNo(7);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 8 || c13R2VNo === 8 || c13R3VNo === 8 || c13R4VNo === 8){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(8);
            setC13R2VNo(8);
            setC13R3VNo(8);
            setC13R4VNo(8);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 9 || c13R2VNo === 9 || c13R3VNo === 9 || c13R4VNo === 9){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(9);
            setC13R2VNo(9);
            setC13R3VNo(9);
            setC13R4VNo(9);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 10 || c13R2VNo === 10 || c13R3VNo === 10 || c13R4VNo === 10){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(10);
            setC13R2VNo(10);
            setC13R3VNo(10);
            setC13R4VNo(10);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c13R1VNo === 11 || c13R2VNo === 11 || c13R3VNo === 11 || c13R4VNo === 11){

        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnLC13(true);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnLC13(true);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(11);
            setC13R2VNo(11);
            setC13R3VNo(11);
            setC13R4VNo(11);
            setSepOnLC13(true);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col14X && curY === row01X) || (curX === col14X && curY === row02X) || (curX === col14X && curY === row03X) || (curX === col14X && curY === row04X)) {
    if (c14r1MT === "L" || c14r2MT === "L" || c14r3MT === "L" || c14r4MT === "L") {
      if (c14R1VNo === 1 || c14R2VNo === 1 || c14R3VNo === 1 || c14R4VNo === 1){
        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(setBaseNakedL14
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(1);
            setC14R2VNo(1);
            setC14R3VNo(1);
            setC14R4VNo(1);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
      } else if (c14R1VNo === 2 || c14R2VNo === 2 || c14R3VNo === 2 || c14R4VNo === 2){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(2);
            setC14R2VNo(2);
            setC14R3VNo(2);
            setC14R4VNo(2);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 3 || c14R2VNo === 3 || c14R3VNo === 3 || c14R4VNo === 3){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(3);
            setC14R2VNo(3);
            setC14R3VNo(3);
            setC14R4VNo(3);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 4 || c14R2VNo === 4 || c14R3VNo === 4 || c14R4VNo === 4){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(4);
            setC14R2VNo(4);
            setC14R3VNo(4);
            setC14R4VNo(4);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 5 || c14R2VNo === 5 || c14R3VNo === 5 || c14R4VNo === 5){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(5);
            setC14R2VNo(5);
            setC14R3VNo(5);
            setC14R4VNo(5);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 6 || c14R2VNo === 6 || c14R3VNo === 6 || c14R4VNo === 6){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(6);
            setC14R2VNo(6);
            setC14R3VNo(6);
            setC14R4VNo(6);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 7 || c14R2VNo === 7 || c14R3VNo === 7 || c14R4VNo === 7){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(7);
            setC14R2VNo(7);
            setC14R3VNo(7);
            setC14R4VNo(7);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 8 || c14R2VNo === 8 || c14R3VNo === 8 || c14R4VNo === 8){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(8);
            setC14R2VNo(8);
            setC14R3VNo(8);
            setC14R4VNo(8);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 9 || c14R2VNo === 9 || c14R3VNo === 9 || c14R4VNo === 9){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(9);
            setC14R2VNo(9);
            setC14R3VNo(9);
            setC14R4VNo(9);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 10 || c14R2VNo === 10 || c14R3VNo === 10 || c14R4VNo === 10){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(10);
            setC14R2VNo(10);
            setC14R3VNo(10);
            setC14R4VNo(10);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      } else if (c14R1VNo === 11 || c14R2VNo === 11 || c14R3VNo === 11 || c14R4VNo === 11){

        if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnLC14(true);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnLC14(true);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(11);
            setC14R2VNo(11);
            setC14R3VNo(11);
            setC14R4VNo(11);
            setSepOnLC14(true);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already added")
        }
       
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } 

}

const removeSeperatorBottom = (e)=> {
  if ((curX === col01X && curY === row01X) || (curX === col01X && curY === row02X) || (curX === col01X && curY === row03X) || (curX === col01X && curY === row04X)) {
    if (c1r1MT === "L" || c1r2MT === "L" || c1r3MT === "L" || c1r4MT === "L") {
      if (c1R1VNo === 1 || c1R2VNo === 1 || c1R3VNo === 1 || c1R4VNo === 1){
        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(1);
            setC1R2VNo(1);
            setC1R3VNo(1);
            setC1R4VNo(1);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c1R1VNo === 2 || c1R2VNo === 2 || c1R3VNo === 2 || c1R4VNo === 2){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
            setC1R2VNo(2);
            setC1R3VNo(2);
            setC1R4VNo(2);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 3 || c1R2VNo === 3 || c1R3VNo === 3 || c1R4VNo === 3){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(3);
            setC1R2VNo(3);
            setC1R3VNo(3);
            setC1R4VNo(3);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 4 || c1R2VNo === 4 || c1R3VNo === 4 || c1R4VNo === 4){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(4);
            setC1R2VNo(4);
            setC1R3VNo(4);
            setC1R4VNo(4);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 5 || c1R2VNo === 5 || c1R3VNo === 5 || c1R4VNo === 5){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(5);
            setC1R2VNo(5);
            setC1R3VNo(5);
            setC1R4VNo(5);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 6 || c1R2VNo === 6 || c1R3VNo === 6 || c1R4VNo === 6){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(6);
            setC1R2VNo(6);
            setC1R3VNo(6);
            setC1R4VNo(6);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 7 || c1R2VNo === 7 || c1R3VNo === 7 || c1R4VNo === 7){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(7);
            setC1R2VNo(7);
            setC1R3VNo(7);
            setC1R4VNo(7);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 8 || c1R2VNo === 8 || c1R3VNo === 8 || c1R4VNo === 8){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(8);
            setC1R2VNo(8);
            setC1R3VNo(8);
            setC1R4VNo(8);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 9 || c1R2VNo === 9 || c1R3VNo === 9 || c1R4VNo === 9){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(9);
            setC1R2VNo(9);
            setC1R3VNo(9);
            setC1R4VNo(9);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 10 || c1R2VNo === 10 || c1R3VNo === 10 || c1R4VNo === 10){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(10);
            setC1R2VNo(10);
            setC1R3VNo(10);
            setC1R4VNo(10);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c1R1VNo === 11 || c1R2VNo === 11 || c1R3VNo === 11 || c1R4VNo === 11){

        if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(false)
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnLC1(false);
          setSepOn2LC1(false);
          setSepOn3LC1(true)
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnLC1(false);
          setSepOn2LC1(true);
          setSepOn3LC1(false)
        }  else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(11);
            setC1R2VNo(11);
            setC1R3VNo(11);
            setC1R4VNo(11);
            setSepOnLC1(false);
            setSepOn2LC1(true);
            setSepOn3LC1(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col02X && curY === row01X) || (curX === col02X && curY === row02X) || (curX === col02X && curY === row03X) || (curX === col02X && curY === row04X)) {
    if (c2r1MT === "L" || c2r2MT === "L" || c2r3MT === "L" || c2r4MT === "L") {
      if (c2R1VNo === 1 || c2R2VNo === 1 || c2R3VNo === 1 || c2R4VNo === 1){
        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(1);
            setC2R2VNo(1);
            setC2R3VNo(1);
            setC2R4VNo(1);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c2R1VNo === 2 || c2R2VNo === 2 || c2R3VNo === 2 || c2R4VNo === 2){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(2);
            setC2R2VNo(2);
            setC2R3VNo(2);
            setC2R4VNo(2);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 3 || c2R2VNo === 3 || c2R3VNo === 3 || c2R4VNo === 3){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(3);
            setC2R2VNo(3);
            setC2R3VNo(3);
            setC2R4VNo(3);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 4 || c2R2VNo === 4 || c2R3VNo === 4 || c2R4VNo === 4){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(4);
            setC2R2VNo(4);
            setC2R3VNo(4);
            setC2R4VNo(4);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 5 || c2R2VNo === 5 || c2R3VNo === 5 || c2R4VNo === 5){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(5);
            setC2R2VNo(5);
            setC2R3VNo(5);
            setC2R4VNo(5);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 6 || c2R2VNo === 6 || c2R3VNo === 6 || c2R4VNo === 6){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(6);
            setC2R2VNo(6);
            setC2R3VNo(6);
            setC2R4VNo(6);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 7 || c2R2VNo === 7 || c2R3VNo === 7 || c2R4VNo === 7){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(7);
            setC2R2VNo(7);
            setC2R3VNo(7);
            setC2R4VNo(7);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 8 || c2R2VNo === 8 || c2R3VNo === 8 || c2R4VNo === 8){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(8);
            setC2R2VNo(8);
            setC2R3VNo(8);
            setC2R4VNo(8);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 9 || c2R2VNo === 9 || c2R3VNo === 9 || c2R4VNo === 9){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(9);
            setC2R2VNo(9);
            setC2R3VNo(9);
            setC2R4VNo(9);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 10 || c2R2VNo === 10 || c2R3VNo === 10 || c2R4VNo === 10){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(10);
            setC2R2VNo(10);
            setC2R3VNo(10);
            setC2R4VNo(10);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c2R1VNo === 11 || c2R2VNo === 11 || c2R3VNo === 11 || c2R4VNo === 11){

        if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(false)
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnLC2(false);
          setSepOn2LC2(false);
          setSepOn3LC2(true)
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnLC2(false);
          setSepOn2LC2(true);
          setSepOn3LC2(false)
        }  else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(11);
            setC2R2VNo(11);
            setC2R3VNo(11);
            setC2R4VNo(11);
            setSepOnLC2(false);
            setSepOn2LC2(true);
            setSepOn3LC2(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col03X && curY === row01X) || (curX === col03X && curY === row02X) || (curX === col03X && curY === row03X) || (curX === col03X && curY === row04X)) {
    if (c3r1MT === "L" || c3r2MT === "L" || c3r3MT === "L" || c3r4MT === "L") {
      if (c3R1VNo === 1 || c3R2VNo === 1 || c3R3VNo === 1 || c3R4VNo === 1){
        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(1);
            setC3R2VNo(1);
            setC3R3VNo(1);
            setC3R4VNo(1);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c3R1VNo === 2 || c3R2VNo === 2 || c3R3VNo === 2 || c3R4VNo === 2){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(2);
            setC3R2VNo(2);
            setC3R3VNo(2);
            setC3R4VNo(2);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 3 || c3R2VNo === 3 || c3R3VNo === 3 || c3R4VNo === 3){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(3);
            setC3R2VNo(3);
            setC3R3VNo(3);
            setC3R4VNo(3);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 4 || c3R2VNo === 4 || c3R3VNo === 4 || c3R4VNo === 4){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(4);
            setC3R2VNo(4);
            setC3R3VNo(4);
            setC3R4VNo(4);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 5 || c3R2VNo === 5 || c3R3VNo === 5 || c3R4VNo === 5){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(5);
            setC3R2VNo(5);
            setC3R3VNo(5);
            setC3R4VNo(5);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 6 || c3R2VNo === 6 || c3R3VNo === 6 || c3R4VNo === 6){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(6);
            setC3R2VNo(6);
            setC3R3VNo(6);
            setC3R4VNo(6);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 7 || c3R2VNo === 7 || c3R3VNo === 7 || c3R4VNo === 7){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(7);
            setC3R2VNo(7);
            setC3R3VNo(7);
            setC3R4VNo(7);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 8 || c3R2VNo === 8 || c3R3VNo === 8 || c3R4VNo === 8){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(8);
            setC3R2VNo(8);
            setC3R3VNo(8);
            setC3R4VNo(8);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 9 || c3R2VNo === 9 || c3R3VNo === 9 || c3R4VNo === 9){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(9);
            setC3R2VNo(9);
            setC3R3VNo(9);
            setC3R4VNo(9);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 10 || c3R2VNo === 10 || c3R3VNo === 10 || c3R4VNo === 10){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(10);
            setC3R2VNo(10);
            setC3R3VNo(10);
            setC3R4VNo(10);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c3R1VNo === 11 || c3R2VNo === 11 || c3R3VNo === 11 || c3R4VNo === 11){

        if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(false)
        } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnLC3(false);
          setSepOn2LC3(false);
          setSepOn3LC3(true)
        } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnLC3(false);
          setSepOn2LC3(true);
          setSepOn3LC3(false)
        }  else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(11);
            setC3R2VNo(11);
            setC3R3VNo(11);
            setC3R4VNo(11);
            setSepOnLC3(false);
            setSepOn2LC3(true);
            setSepOn3LC3(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col04X && curY === row01X) || (curX === col04X && curY === row02X) || (curX === col04X && curY === row03X) || (curX === col04X && curY === row04X)) {
    if (c4r1MT === "L" || c4r2MT === "L" || c4r3MT === "L" || c4r4MT === "L") {
      if (c4R1VNo === 1 || c4R2VNo === 1 || c4R3VNo === 1 || c4R4VNo === 1){
        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(1);
            setC4R2VNo(1);
            setC4R3VNo(1);
            setC4R4VNo(1);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c4R1VNo === 2 || c4R2VNo === 2 || c4R3VNo === 2 || c4R4VNo === 2){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(2);
            setC4R2VNo(2);
            setC4R3VNo(2);
            setC4R4VNo(2);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 3 || c4R2VNo === 3 || c4R3VNo === 3 || c4R4VNo === 3){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(3);
            setC4R2VNo(3);
            setC4R3VNo(3);
            setC4R4VNo(3);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 4 || c4R2VNo === 4 || c4R3VNo === 4 || c4R4VNo === 4){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(4);
            setC4R2VNo(4);
            setC4R3VNo(4);
            setC4R4VNo(4);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 5 || c4R2VNo === 5 || c4R3VNo === 5 || c4R4VNo === 5){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(5);
            setC4R2VNo(5);
            setC4R3VNo(5);
            setC4R4VNo(5);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 6 || c4R2VNo === 6 || c4R3VNo === 6 || c4R4VNo === 6){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(6);
            setC4R2VNo(6);
            setC4R3VNo(6);
            setC4R4VNo(6);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 7 || c4R2VNo === 7 || c4R3VNo === 7 || c4R4VNo === 7){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(7);
            setC4R2VNo(7);
            setC4R3VNo(7);
            setC4R4VNo(7);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 8 || c4R2VNo === 8 || c4R3VNo === 8 || c4R4VNo === 8){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(8);
            setC4R2VNo(8);
            setC4R3VNo(8);
            setC4R4VNo(8);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 9 || c4R2VNo === 9 || c4R3VNo === 9 || c4R4VNo === 9){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(9);
            setC4R2VNo(9);
            setC4R3VNo(9);
            setC4R4VNo(9);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 10 || c4R2VNo === 10 || c4R3VNo === 10 || c4R4VNo === 10){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(10);
            setC4R2VNo(10);
            setC4R3VNo(10);
            setC4R4VNo(10);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c4R1VNo === 11 || c4R2VNo === 11 || c4R3VNo === 11 || c4R4VNo === 11){

        if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(false)
        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnLC4(false);
          setSepOn2LC4(false);
          setSepOn3LC4(true)
        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnLC4(false);
          setSepOn2LC4(true);
          setSepOn3LC4(false)
        }  else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(11);
            setC4R2VNo(11);
            setC4R3VNo(11);
            setC4R4VNo(11);
            setSepOnLC4(false);
            setSepOn2LC4(true);
            setSepOn3LC4(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col05X && curY === row01X) || (curX === col05X && curY === row02X) || (curX === col05X && curY === row03X) || (curX === col05X && curY === row04X)) {
    if (c5r1MT === "L" || c5r2MT === "L" || c5r3MT === "L" || c5r4MT === "L") {
      if (c5R1VNo === 1 || c5R2VNo === 1 || c5R3VNo === 1 || c5R4VNo === 1){
        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(1);
            setC5R2VNo(1);
            setC5R3VNo(1);
            setC5R4VNo(1);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c5R1VNo === 2 || c5R2VNo === 2 || c5R3VNo === 2 || c5R4VNo === 2){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(2);
            setC5R2VNo(2);
            setC5R3VNo(2);
            setC5R4VNo(2);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 3 || c5R2VNo === 3 || c5R3VNo === 3 || c5R4VNo === 3){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(3);
            setC5R2VNo(3);
            setC5R3VNo(3);
            setC5R4VNo(3);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 4 || c5R2VNo === 4 || c5R3VNo === 4 || c5R4VNo === 4){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(4);
            setC5R2VNo(4);
            setC5R3VNo(4);
            setC5R4VNo(4);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 5 || c5R2VNo === 5 || c5R3VNo === 5 || c5R4VNo === 5){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(5);
            setC5R2VNo(5);
            setC5R3VNo(5);
            setC5R4VNo(5);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 6 || c5R2VNo === 6 || c5R3VNo === 6 || c5R4VNo === 6){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(6);
            setC5R2VNo(6);
            setC5R3VNo(6);
            setC5R4VNo(6);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 7 || c5R2VNo === 7 || c5R3VNo === 7 || c5R4VNo === 7){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(7);
            setC5R2VNo(7);
            setC5R3VNo(7);
            setC5R4VNo(7);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 8 || c5R2VNo === 8 || c5R3VNo === 8 || c5R4VNo === 8){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(8);
            setC5R2VNo(8);
            setC5R3VNo(8);
            setC5R4VNo(8);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 9 || c5R2VNo === 9 || c5R3VNo === 9 || c5R4VNo === 9){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(9);
            setC5R2VNo(9);
            setC5R3VNo(9);
            setC5R4VNo(9);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 10 || c5R2VNo === 10 || c5R3VNo === 10 || c5R4VNo === 10){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(10);
            setC5R2VNo(10);
            setC5R3VNo(10);
            setC5R4VNo(10);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c5R1VNo === 11 || c5R2VNo === 11 || c5R3VNo === 11 || c5R4VNo === 11){

        if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(false)
        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnLC5(false);
          setSepOn2LC5(false);
          setSepOn3LC5(true)
        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnLC5(false);
          setSepOn2LC5(true);
          setSepOn3LC5(false)
        }  else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(11);
            setC5R2VNo(11);
            setC5R3VNo(11);
            setC5R4VNo(11);
            setSepOnLC5(false);
            setSepOn2LC5(true);
            setSepOn3LC5(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col06X && curY === row01X) || (curX === col06X && curY === row02X) || (curX === col06X && curY === row03X) || (curX === col06X && curY === row04X)) {
    if (c6r1MT === "L" || c6r2MT === "L" || c6r3MT === "L" || c6r4MT === "L") {
      if (c6R1VNo === 1 || c6R2VNo === 1 || c6R3VNo === 1 || c6R4VNo === 1){
        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(1);
            setC6R2VNo(1);
            setC6R3VNo(1);
            setC6R4VNo(1);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c6R1VNo === 2 || c6R2VNo === 2 || c6R3VNo === 2 || c6R4VNo === 2){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(2);
            setC6R2VNo(2);
            setC6R3VNo(2);
            setC6R4VNo(2);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 3 || c6R2VNo === 3 || c6R3VNo === 3 || c6R4VNo === 3){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(3);
            setC6R2VNo(3);
            setC6R3VNo(3);
            setC6R4VNo(3);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 4 || c6R2VNo === 4 || c6R3VNo === 4 || c6R4VNo === 4){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(4);
            setC6R2VNo(4);
            setC6R3VNo(4);
            setC6R4VNo(4);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 5 || c6R2VNo === 5 || c6R3VNo === 5 || c6R4VNo === 5){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
            setC6R3VNo(5);
            setC6R4VNo(5);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 6 || c6R2VNo === 6 || c6R3VNo === 6 || c6R4VNo === 6){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(6);
            setC6R2VNo(6);
            setC6R3VNo(6);
            setC6R4VNo(6);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 7 || c6R2VNo === 7 || c6R3VNo === 7 || c6R4VNo === 7){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(7);
            setC6R2VNo(7);
            setC6R3VNo(7);
            setC6R4VNo(7);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 8 || c6R2VNo === 8 || c6R3VNo === 8 || c6R4VNo === 8){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(8);
            setC6R2VNo(8);
            setC6R3VNo(8);
            setC6R4VNo(8);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 9 || c6R2VNo === 9 || c6R3VNo === 9 || c6R4VNo === 9){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(9);
            setC6R2VNo(9);
            setC6R3VNo(9);
            setC6R4VNo(9);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 10 || c6R2VNo === 10 || c6R3VNo === 10 || c6R4VNo === 10){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(10);
            setC6R2VNo(10);
            setC6R3VNo(10);
            setC6R4VNo(10);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c6R1VNo === 11 || c6R2VNo === 11 || c6R3VNo === 11 || c6R4VNo === 11){

        if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(false)
        } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnLC6(false);
          setSepOn2LC6(false);
          setSepOn3LC6(true)
        } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnLC6(false);
          setSepOn2LC6(true);
          setSepOn3LC6(false)
        }  else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(11);
            setC6R2VNo(11);
            setC6R3VNo(11);
            setC6R4VNo(11);
            setSepOnLC6(false);
            setSepOn2LC6(true);
            setSepOn3LC6(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col07X && curY === row01X) || (curX === col07X && curY === row02X) || (curX === col07X && curY === row03X) || (curX === col07X && curY === row04X)) {
    if (c7r1MT === "L" || c7r2MT === "L" || c7r3MT === "L" || c7r4MT === "L") {
      if (c7R1VNo === 1 || c7R2VNo === 1 || c7R3VNo === 1 || c7R4VNo === 1){
        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(1);
            setC7R2VNo(1);
            setC7R3VNo(1);
            setC7R4VNo(1);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c7R1VNo === 2 || c7R2VNo === 2 || c7R3VNo === 2 || c7R4VNo === 2){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(2);
            setC7R2VNo(2);
            setC7R3VNo(2);
            setC7R4VNo(2);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 3 || c7R2VNo === 3 || c7R3VNo === 3 || c7R4VNo === 3){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(3);
            setC7R2VNo(3);
            setC7R3VNo(3);
            setC7R4VNo(3);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 4 || c7R2VNo === 4 || c7R3VNo === 4 || c7R4VNo === 4){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
            setC7R2VNo(4);
            setC7R3VNo(4);
            setC7R4VNo(4);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 5 || c7R2VNo === 5 || c7R3VNo === 5 || c7R4VNo === 5){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(5);
            setC7R2VNo(5);
            setC7R3VNo(5);
            setC7R4VNo(5);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 6 || c7R2VNo === 6 || c7R3VNo === 6 || c7R4VNo === 6){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(6);
            setC7R2VNo(6);
            setC7R3VNo(6);
            setC7R4VNo(6);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 7 || c7R2VNo === 7 || c7R3VNo === 7 || c7R4VNo === 7){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
            setC7R2VNo(7);
            setC7R3VNo(7);
            setC7R4VNo(7);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 8 || c7R2VNo === 8 || c7R3VNo === 8 || c7R4VNo === 8){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(8);
            setC7R2VNo(8);
            setC7R3VNo(8);
            setC7R4VNo(8);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 9 || c7R2VNo === 9 || c7R3VNo === 9 || c7R4VNo === 9){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(9);
            setC7R2VNo(9);
            setC7R3VNo(9);
            setC7R4VNo(9);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 10 || c7R2VNo === 10 || c7R3VNo === 10 || c7R4VNo === 10){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(10);
            setC7R2VNo(10);
            setC7R3VNo(10);
            setC7R4VNo(10);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c7R1VNo === 11 || c7R2VNo === 11 || c7R3VNo === 11 || c7R4VNo === 11){

        if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(false)
        } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnLC7(false);
          setSepOn2LC7(false);
          setSepOn3LC7(true)
        } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnLC7(false);
          setSepOn2LC7(true);
          setSepOn3LC7(false)
        }  else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(11);
            setC7R2VNo(11);
            setC7R3VNo(11);
            setC7R4VNo(11);
            setSepOnLC7(false);
            setSepOn2LC7(true);
            setSepOn3LC7(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col08X && curY === row01X) || (curX === col08X && curY === row02X) || (curX === col08X && curY === row03X) || (curX === col08X && curY === row04X)) {
    if (c8r1MT === "L" || c8r2MT === "L" || c8r3MT === "L" || c8r4MT === "L") {
      if (c8R1VNo === 1 || c8R2VNo === 1 || c8R3VNo === 1 || c8R4VNo === 1){
        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(1);
            setC8R2VNo(1);
            setC8R3VNo(1);
            setC8R4VNo(1);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c8R1VNo === 2 || c8R2VNo === 2 || c8R3VNo === 2 || c8R4VNo === 2){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(2);
            setC8R2VNo(2);
            setC8R3VNo(2);
            setC8R4VNo(2);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 3 || c8R2VNo === 3 || c8R3VNo === 3 || c8R4VNo === 3){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(3);
            setC8R2VNo(3);
            setC8R3VNo(3);
            setC8R4VNo(3);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 4 || c8R2VNo === 4 || c8R3VNo === 4 || c8R4VNo === 4){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
            setC8R2VNo(4);
            setC8R3VNo(4);
            setC8R4VNo(4);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 5 || c8R2VNo === 5 || c8R3VNo === 5 || c8R4VNo === 5){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(5);
            setC8R2VNo(5);
            setC8R3VNo(5);
            setC8R4VNo(5);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 6 || c8R2VNo === 6 || c8R3VNo === 6 || c8R4VNo === 6){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(6);
            setC8R2VNo(6);
            setC8R3VNo(6);
            setC8R4VNo(6);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 7 || c8R2VNo === 7 || c8R3VNo === 7 || c8R4VNo === 7){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(7);
            setC8R2VNo(7);
            setC8R3VNo(7);
            setC8R4VNo(7);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 8 || c8R2VNo === 8 || c8R3VNo === 8 || c8R4VNo === 8){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(8);
            setC8R2VNo(8);
            setC8R3VNo(8);
            setC8R4VNo(8);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 9 || c8R2VNo === 9 || c8R3VNo === 9 || c8R4VNo === 9){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(9);
            setC8R2VNo(9);
            setC8R3VNo(9);
            setC8R4VNo(9);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 10 || c8R2VNo === 10 || c8R3VNo === 10 || c8R4VNo === 10){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(10);
            setC8R2VNo(10);
            setC8R3VNo(10);
            setC8R4VNo(10);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c8R1VNo === 11 || c8R2VNo === 11 || c8R3VNo === 11 || c8R4VNo === 11){

        if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(false)
        } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnLC8(false);
          setSepOn2LC8(false);
          setSepOn3LC8(true)
        } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnLC8(false);
          setSepOn2LC8(true);
          setSepOn3LC8(false)
        }  else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(11);
            setC8R2VNo(11);
            setC8R3VNo(11);
            setC8R4VNo(11);
            setSepOnLC8(false);
            setSepOn2LC8(true);
            setSepOn3LC8(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col09X && curY === row01X) || (curX === col09X && curY === row02X) || (curX === col09X && curY === row03X) || (curX === col09X && curY === row04X)) {
    if (c9r1MT === "L" || c9r2MT === "L" || c9r3MT === "L" || c9r4MT === "L") {
      if (c9R1VNo === 1 || c9R2VNo === 1 || c9R3VNo === 1 || c9R4VNo === 1){
        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(1);
            setC9R2VNo(1);
            setC9R3VNo(1);
            setC9R4VNo(1);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c9R1VNo === 2 || c9R2VNo === 2 || c9R3VNo === 2 || c9R4VNo === 2){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(2);
            setC9R2VNo(2);
            setC9R3VNo(2);
            setC9R4VNo(2);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 3 || c9R2VNo === 3 || c9R3VNo === 3 || c9R4VNo === 3){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(3);
            setC9R2VNo(3);
            setC9R3VNo(3);
            setC9R4VNo(3);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 4 || c9R2VNo === 4 || c9R3VNo === 4 || c9R4VNo === 4){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(4);
            setC9R2VNo(4);
            setC9R3VNo(4);
            setC9R4VNo(4);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 5 || c9R2VNo === 5 || c9R3VNo === 5 || c9R4VNo === 5){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(5);
            setC9R2VNo(5);
            setC9R3VNo(5);
            setC9R4VNo(5);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 6 || c9R2VNo === 6 || c9R3VNo === 6 || c9R4VNo === 6){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(6);
            setC9R2VNo(6);
            setC9R3VNo(6);
            setC9R4VNo(6);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 7 || c9R2VNo === 7 || c9R3VNo === 7 || c9R4VNo === 7){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(7);
            setC9R2VNo(7);
            setC9R3VNo(7);
            setC9R4VNo(7);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 8 || c9R2VNo === 8 || c9R3VNo === 8 || c9R4VNo === 8){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(8);
            setC9R2VNo(8);
            setC9R3VNo(8);
            setC9R4VNo(8);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 9 || c9R2VNo === 9 || c9R3VNo === 9 || c9R4VNo === 9){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(9);
            setC9R2VNo(9);
            setC9R3VNo(9);
            setC9R4VNo(9);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 10 || c9R2VNo === 10 || c9R3VNo === 10 || c9R4VNo === 10){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(10);
            setC9R2VNo(10);
            setC9R3VNo(10);
            setC9R4VNo(10);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c9R1VNo === 11 || c9R2VNo === 11 || c9R3VNo === 11 || c9R4VNo === 11){

        if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(false)
        } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnLC9(false);
          setSepOn2LC9(false);
          setSepOn3LC9(true)
        } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnLC9(false);
          setSepOn2LC9(true);
          setSepOn3LC9(false)
        }  else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(11);
            setC9R2VNo(11);
            setC9R3VNo(11);
            setC9R4VNo(11);
            setSepOnLC9(false);
            setSepOn2LC9(true);
            setSepOn3LC9(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col10X && curY === row01X) || (curX === col10X && curY === row02X) || (curX === col10X && curY === row03X) || (curX === col10X && curY === row04X)) {
    if (c10r1MT === "L" || c10r2MT === "L" || c10r3MT === "L" || c10r4MT === "L") {
      if (c10R1VNo === 1 || c10R2VNo === 1 || c10R3VNo === 1 || c10R4VNo === 1){
        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(1);
            setC10R2VNo(1);
            setC10R3VNo(1);
            setC10R4VNo(1);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c10R1VNo === 2 || c10R2VNo === 2 || c10R3VNo === 2 || c10R4VNo === 2){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(2);
            setC10R2VNo(2);
            setC10R3VNo(2);
            setC10R4VNo(2);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 3 || c10R2VNo === 3 || c10R3VNo === 3 || c10R4VNo === 3){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(3);
            setC10R2VNo(3);
            setC10R3VNo(3);
            setC10R4VNo(3);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 4 || c10R2VNo === 4 || c10R3VNo === 4 || c10R4VNo === 4){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(4);
            setC10R2VNo(4);
            setC10R3VNo(4);
            setC10R4VNo(4);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 5 || c10R2VNo === 5 || c10R3VNo === 5 || c10R4VNo === 5){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(5);
            setC10R2VNo(5);
            setC10R3VNo(5);
            setC10R4VNo(5);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 6 || c10R2VNo === 6 || c10R3VNo === 6 || c10R4VNo === 6){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(6);
            setC10R2VNo(6);
            setC10R3VNo(6);
            setC10R4VNo(6);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 7 || c10R2VNo === 7 || c10R3VNo === 7 || c10R4VNo === 7){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(7);
            setC10R2VNo(7);
            setC10R3VNo(7);
            setC10R4VNo(7);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 8 || c10R2VNo === 8 || c10R3VNo === 8 || c10R4VNo === 8){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(8);
            setC10R2VNo(8);
            setC10R3VNo(8);
            setC10R4VNo(8);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 9 || c10R2VNo === 9 || c10R3VNo === 9 || c10R4VNo === 9){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(9);
            setC10R2VNo(9);
            setC10R3VNo(9);
            setC10R4VNo(9);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 10 || c10R2VNo === 10 || c10R3VNo === 10 || c10R4VNo === 10){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(10);
            setC10R2VNo(10);
            setC10R3VNo(10);
            setC10R4VNo(10);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c10R1VNo === 11 || c10R2VNo === 11 || c10R3VNo === 11 || c10R4VNo === 11){

        if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(false)
        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnLC10(false);
          setSepOn2LC10(false);
          setSepOn3LC10(true)
        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnLC10(false);
          setSepOn2LC10(true);
          setSepOn3LC10(false)
        }  else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(11);
            setC10R2VNo(11);
            setC10R3VNo(11);
            setC10R4VNo(11);
            setSepOnLC10(false);
            setSepOn2LC10(true);
            setSepOn3LC10(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col11X && curY === row01X) || (curX === col11X && curY === row02X) || (curX === col11X && curY === row03X) || (curX === col11X && curY === row04X)) {
    if (c11r1MT === "L" || c11r2MT === "L" || c11r3MT === "L" || c11r4MT === "L") {
      if (c11R1VNo === 1 || c11R2VNo === 1 || c11R3VNo === 1 || c11R4VNo === 1){
        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(1);
            setC11R2VNo(1);
            setC11R3VNo(1);
            setC11R4VNo(1);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c11R1VNo === 2 || c11R2VNo === 2 || c11R3VNo === 2 || c11R4VNo === 2){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(2);
            setC11R2VNo(2);
            setC11R3VNo(2);
            setC11R4VNo(2);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 3 || c11R2VNo === 3 || c11R3VNo === 3 || c11R4VNo === 3){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(3);
            setC11R2VNo(3);
            setC11R3VNo(3);
            setC11R4VNo(3);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 4 || c11R2VNo === 4 || c11R3VNo === 4 || c11R4VNo === 4){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(4);
            setC11R2VNo(4);
            setC11R3VNo(4);
            setC11R4VNo(4);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 5 || c11R2VNo === 5 || c11R3VNo === 5 || c11R4VNo === 5){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(5);
            setC11R2VNo(5);
            setC11R3VNo(5);
            setC11R4VNo(5);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 6 || c11R2VNo === 6 || c11R3VNo === 6 || c11R4VNo === 6){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(6);
            setC11R2VNo(6);
            setC11R3VNo(6);
            setC11R4VNo(6);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 7 || c11R2VNo === 7 || c11R3VNo === 7 || c11R4VNo === 7){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(7);
            setC11R2VNo(7);
            setC11R3VNo(7);
            setC11R4VNo(7);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 8 || c11R2VNo === 8 || c11R3VNo === 8 || c11R4VNo === 8){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(8);
            setC11R2VNo(8);
            setC11R3VNo(8);
            setC11R4VNo(8);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 9 || c11R2VNo === 9 || c11R3VNo === 9 || c11R4VNo === 9){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(9);
            setC11R2VNo(9);
            setC11R3VNo(9);
            setC11R4VNo(9);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 10 || c11R2VNo === 10 || c11R3VNo === 10 || c11R4VNo === 10){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(10);
            setC11R2VNo(10);
            setC11R3VNo(10);
            setC11R4VNo(10);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c11R1VNo === 11 || c11R2VNo === 11 || c11R3VNo === 11 || c11R4VNo === 11){

        if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(false)
        } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnLC11(false);
          setSepOn2LC11(false);
          setSepOn3LC11(true)
        } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnLC11(false);
          setSepOn2LC11(true);
          setSepOn3LC11(false)
        }  else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(11);
            setC11R2VNo(11);
            setC11R3VNo(11);
            setC11R4VNo(11);
            setSepOnLC11(false);
            setSepOn2LC11(true);
            setSepOn3LC11(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col12X && curY === row01X) || (curX === col12X && curY === row02X) || (curX === col12X && curY === row03X) || (curX === col12X && curY === row04X)) {
    if (c12r1MT === "L" || c12r2MT === "L" || c12r3MT === "L" || c12r4MT === "L") {
      if (c12R1VNo === 1 || c12R2VNo === 1 || c12R3VNo === 1 || c12R4VNo === 1){
        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(1);
            setC12R2VNo(1);
            setC12R3VNo(1);
            setC12R4VNo(1);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c12R1VNo === 2 || c12R2VNo === 2 || c12R3VNo === 2 || c12R4VNo === 2){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(2);
            setC12R2VNo(2);
            setC12R3VNo(2);
            setC12R4VNo(2);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 3 || c12R2VNo === 3 || c12R3VNo === 3 || c12R4VNo === 3){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(3);
            setC12R2VNo(3);
            setC12R3VNo(3);
            setC12R4VNo(3);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 4 || c12R2VNo === 4 || c12R3VNo === 4 || c12R4VNo === 4){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(4);
            setC12R2VNo(4);
            setC12R3VNo(4);
            setC12R4VNo(4);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 5 || c12R2VNo === 5 || c12R3VNo === 5 || c12R4VNo === 5){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(5);
            setC12R2VNo(5);
            setC12R3VNo(5);
            setC12R4VNo(5);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 6 || c12R2VNo === 6 || c12R3VNo === 6 || c12R4VNo === 6){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(6);
            setC12R2VNo(6);
            setC12R3VNo(6);
            setC12R4VNo(6);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 7 || c12R2VNo === 7 || c12R3VNo === 7 || c12R4VNo === 7){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(7);
            setC12R2VNo(7);
            setC12R3VNo(7);
            setC12R4VNo(7);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 8 || c12R2VNo === 8 || c12R3VNo === 8 || c12R4VNo === 8){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(8);
            setC12R2VNo(8);
            setC12R3VNo(8);
            setC12R4VNo(8);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 9 || c12R2VNo === 9 || c12R3VNo === 9 || c12R4VNo === 9){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(9);
            setC12R2VNo(9);
            setC12R3VNo(9);
            setC12R4VNo(9);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 10 || c12R2VNo === 10 || c12R3VNo === 10 || c12R4VNo === 10){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(10);
            setC12R2VNo(10);
            setC12R3VNo(10);
            setC12R4VNo(10);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c12R1VNo === 11 || c12R2VNo === 11 || c12R3VNo === 11 || c12R4VNo === 11){

        if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(false)
        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnLC12(false);
          setSepOn2LC12(false);
          setSepOn3LC12(true)
        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnLC12(false);
          setSepOn2LC12(true);
          setSepOn3LC12(false)
        }  else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(11);
            setC12R2VNo(11);
            setC12R3VNo(11);
            setC12R4VNo(11);
            setSepOnLC12(false);
            setSepOn2LC12(true);
            setSepOn3LC12(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col13X && curY === row01X) || (curX === col13X && curY === row02X) || (curX === col13X && curY === row03X) || (curX === col13X && curY === row04X)) {
    if (c13r1MT === "L" || c13r2MT === "L" || c13r3MT === "L" || c13r4MT === "L") {
      if (c13R1VNo === 1 || c13R2VNo === 1 || c13R3VNo === 1 || c13R4VNo === 1){
        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(1);
            setC13R2VNo(1);
            setC13R3VNo(1);
            setC13R4VNo(1);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c13R1VNo === 2 || c13R2VNo === 2 || c13R3VNo === 2 || c13R4VNo === 2){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(2);
            setC13R2VNo(2);
            setC13R3VNo(2);
            setC13R4VNo(2);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 3 || c13R2VNo === 3 || c13R3VNo === 3 || c13R4VNo === 3){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(3);
            setC13R2VNo(3);
            setC13R3VNo(3);
            setC13R4VNo(3);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 4 || c13R2VNo === 4 || c13R3VNo === 4 || c13R4VNo === 4){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(4);
            setC13R2VNo(4);
            setC13R3VNo(4);
            setC13R4VNo(4);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 5 || c13R2VNo === 5 || c13R3VNo === 5 || c13R4VNo === 5){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(5);
            setC13R2VNo(5);
            setC13R3VNo(5);
            setC13R4VNo(5);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 6 || c13R2VNo === 6 || c13R3VNo === 6 || c13R4VNo === 6){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(6);
            setC13R2VNo(6);
            setC13R3VNo(6);
            setC13R4VNo(6);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 7 || c13R2VNo === 7 || c13R3VNo === 7 || c13R4VNo === 7){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(7);
            setC13R2VNo(7);
            setC13R3VNo(7);
            setC13R4VNo(7);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 8 || c13R2VNo === 8 || c13R3VNo === 8 || c13R4VNo === 8){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(8);
            setC13R2VNo(8);
            setC13R3VNo(8);
            setC13R4VNo(8);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 9 || c13R2VNo === 9 || c13R3VNo === 9 || c13R4VNo === 9){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(9);
            setC13R2VNo(9);
            setC13R3VNo(9);
            setC13R4VNo(9);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 10 || c13R2VNo === 10 || c13R3VNo === 10 || c13R4VNo === 10){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(10);
            setC13R2VNo(10);
            setC13R3VNo(10);
            setC13R4VNo(10);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c13R1VNo === 11 || c13R2VNo === 11 || c13R3VNo === 11 || c13R4VNo === 11){

        if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(false)
        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnLC13(false);
          setSepOn2LC13(false);
          setSepOn3LC13(true)
        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnLC13(false);
          setSepOn2LC13(true);
          setSepOn3LC13(false)
        }  else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(11);
            setC13R2VNo(11);
            setC13R3VNo(11);
            setC13R4VNo(11);
            setSepOnLC13(false);
            setSepOn2LC13(true);
            setSepOn3LC13(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col14X && curY === row01X) || (curX === col14X && curY === row02X) || (curX === col14X && curY === row03X) || (curX === col14X && curY === row04X)) {
    if (c14r1MT === "L" || c14r2MT === "L" || c14r3MT === "L" || c14r4MT === "L") {
      if (c14R1VNo === 1 || c14R2VNo === 1 || c14R3VNo === 1 || c14R4VNo === 1){
        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(1);
            setC14R2VNo(1);
            setC14R3VNo(1);
            setC14R4VNo(1);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      } else if (c14R1VNo === 2 || c14R2VNo === 2 || c14R3VNo === 2 || c14R4VNo === 2){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(2);
            setC14R2VNo(2);
            setC14R3VNo(2);
            setC14R4VNo(2);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 3 || c14R2VNo === 3 || c14R3VNo === 3 || c14R4VNo === 3){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(3);
            setC14R2VNo(3);
            setC14R3VNo(3);
            setC14R4VNo(3);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 4 || c14R2VNo === 4 || c14R3VNo === 4 || c14R4VNo === 4){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(4);
            setC14R2VNo(4);
            setC14R3VNo(4);
            setC14R4VNo(4);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 5 || c14R2VNo === 5 || c14R3VNo === 5 || c14R4VNo === 5){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(5);
            setC14R2VNo(5);
            setC14R3VNo(5);
            setC14R4VNo(5);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 6 || c14R2VNo === 6 || c14R3VNo === 6 || c14R4VNo === 6){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(6);
            setC14R2VNo(6);
            setC14R3VNo(6);
            setC14R4VNo(6);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 7 || c14R2VNo === 7 || c14R3VNo === 7 || c14R4VNo === 7){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(7);
            setC14R2VNo(7);
            setC14R3VNo(7);
            setC14R4VNo(7);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 8 || c14R2VNo === 8 || c14R3VNo === 8 || c14R4VNo === 8){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(8);
            setC14R2VNo(8);
            setC14R3VNo(8);
            setC14R4VNo(8);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 9 || c14R2VNo === 9 || c14R3VNo === 9 || c14R4VNo === 9){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(9);
            setC14R2VNo(9);
            setC14R3VNo(9);
            setC14R4VNo(9);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 10 || c14R2VNo === 10 || c14R3VNo === 10 || c14R4VNo === 10){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(10);
            setC14R2VNo(10);
            setC14R3VNo(10);
            setC14R4VNo(10);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      } else if (c14R1VNo === 11 || c14R2VNo === 11 || c14R3VNo === 11 || c14R4VNo === 11){

        if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(false)
        } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnLC14(false);
          setSepOn2LC14(false);
          setSepOn3LC14(true)
        } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnLC14(false);
          setSepOn2LC14(true);
          setSepOn3LC14(false)
        }  else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(11);
            setC14R2VNo(11);
            setC14R3VNo(11);
            setC14R4VNo(11);
            setSepOnLC14(false);
            setSepOn2LC14(true);
            setSepOn3LC14(true)
          
        } else {
          alert("Already removed.")
        }
      
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } 

}

const addSeperatorMiddle = (e)=> {
  if ((curX === col01X && curY === row01X) || (curX === col01X && curY === row02X) || (curX === col01X && curY === row03X) || (curX === col01X && curY === row04X)) {
    if (c1r1MT === "L" || c1r2MT === "L" || c1r3MT === "L" || c1r4MT === "L") {
      if (c1R1VNo === 1 || c1R2VNo === 1 || c1R3VNo === 1 || c1R4VNo === 1){
        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(1);
           setC1R2VNo(1);
           setC1R3VNo(1);
           setC1R4VNo(1);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
      } else if (c1R1VNo === 2 || c1R2VNo === 2 || c1R3VNo === 2 || c1R4VNo === 2){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(2);
           setC1R2VNo(2);
           setC1R3VNo(2);
           setC1R4VNo(2);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 3 || c1R2VNo === 3 || c1R3VNo === 3 || c1R4VNo === 3){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(3);
           setC1R2VNo(3);
           setC1R3VNo(3);
           setC1R4VNo(3);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 4 || c1R2VNo === 4 || c1R3VNo === 4 || c1R4VNo === 4){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(4);
           setC1R2VNo(4);
           setC1R3VNo(4);
           setC1R4VNo(4);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 5 || c1R2VNo === 5 || c1R3VNo === 5 || c1R4VNo === 5){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(5);
           setC1R2VNo(5);
           setC1R3VNo(5);
           setC1R4VNo(5);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 6 || c1R2VNo === 6 || c1R3VNo === 6 || c1R4VNo === 6){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(6);
           setC1R2VNo(6);
           setC1R3VNo(6);
           setC1R4VNo(6);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 7 || c1R2VNo === 7 || c1R3VNo === 7 || c1R4VNo === 7){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(7);
           setC1R2VNo(7);
           setC1R3VNo(7);
           setC1R4VNo(7);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 8 || c1R2VNo === 8 || c1R3VNo === 8 || c1R4VNo === 8){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(8);
           setC1R2VNo(8);
           setC1R3VNo(8);
           setC1R4VNo(8);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 9 || c1R2VNo === 9 || c1R3VNo === 9 || c1R4VNo === 9){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(9);
           setC1R2VNo(9);
           setC1R3VNo(9);
           setC1R4VNo(9);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 10 || c1R2VNo === 10 || c1R3VNo === 10 || c1R4VNo === 10){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(10);
           setC1R2VNo(10);
           setC1R3VNo(10);
           setC1R4VNo(10);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      } else if (c1R1VNo === 11 || c1R2VNo === 11 || c1R3VNo === 11 || c1R4VNo === 11){

        if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === false && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOn2LC1(true);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOn2LC1(true);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === false && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(11);
           setC1R2VNo(11);
           setC1R3VNo(11);
           setC1R4VNo(11);
           setSepOn2LC1(true);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col02X && curY === row01X) || (curX === col02X && curY === row02X) || (curX === col02X && curY === row03X) || (curX === col02X && curY === row04X)) {
    if (c2r1MT === "L" || c2r2MT === "L" || c2r3MT === "L" || c2r4MT === "L") {
      if (c2R1VNo === 1 || c2R2VNo === 1 || c2R3VNo === 1 || c2R4VNo === 1){
        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(1);
           setC2R2VNo(1);
           setC2R3VNo(1);
           setC2R4VNo(1);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
      } else if (c2R1VNo === 2 || c2R2VNo === 2 || c2R3VNo === 2 || c2R4VNo === 2){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(2);
           setC2R2VNo(2);
           setC2R3VNo(2);
           setC2R4VNo(2);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 3 || c2R2VNo === 3 || c2R3VNo === 3 || c2R4VNo === 3){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(3);
           setC2R2VNo(3);
           setC2R3VNo(3);
           setC2R4VNo(3);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 4 || c2R2VNo === 4 || c2R3VNo === 4 || c2R4VNo === 4){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(4);
           setC2R2VNo(4);
           setC2R3VNo(4);
           setC2R4VNo(4);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 5 || c2R2VNo === 5 || c2R3VNo === 5 || c2R4VNo === 5){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(5);
           setC2R2VNo(5);
           setC2R3VNo(5);
           setC2R4VNo(5);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 6 || c2R2VNo === 6 || c2R3VNo === 6 || c2R4VNo === 6){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(6);
           setC2R2VNo(6);
           setC2R3VNo(6);
           setC2R4VNo(6);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 7 || c2R2VNo === 7 || c2R3VNo === 7 || c2R4VNo === 7){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(7);
           setC2R2VNo(7);
           setC2R3VNo(7);
           setC2R4VNo(7);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 8 || c2R2VNo === 8 || c2R3VNo === 8 || c2R4VNo === 8){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(8);
           setC2R2VNo(8);
           setC2R3VNo(8);
           setC2R4VNo(8);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 9 || c2R2VNo === 9 || c2R3VNo === 9 || c2R4VNo === 9){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(9);
           setC2R2VNo(9);
           setC2R3VNo(9);
           setC2R4VNo(9);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 10 || c2R2VNo === 10 || c2R3VNo === 10 || c2R4VNo === 10){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(10);
           setC2R2VNo(10);
           setC2R3VNo(10);
           setC2R4VNo(10);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      } else if (c2R1VNo === 11 || c2R2VNo === 11 || c2R3VNo === 11 || c2R4VNo === 11){

        if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === false && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOn2LC2(true);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOn2LC2(true);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === false && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(11);
           setC2R2VNo(11);
           setC2R3VNo(11);
           setC2R4VNo(11);
           setSepOn2LC2(true);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col03X && curY === row01X) || (curX === col03X && curY === row02X) || (curX === col03X && curY === row03X) || (curX === col03X && curY === row04X)) {
    if (c3r1MT === "L" || c3r2MT === "L" || c3r3MT === "L" || c3r4MT === "L") {
      if (c3R1VNo === 1 || c3R2VNo === 1 || c3R3VNo === 1 || c3R4VNo === 1){
        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(1);
           setC3R2VNo(1);
           setC3R3VNo(1);
           setC3R4VNo(1);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
      } else if (c3R1VNo === 2 || c3R2VNo === 2 || c3R3VNo === 2 || c3R4VNo === 2){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(2);
           setC3R2VNo(2);
           setC3R3VNo(2);
           setC3R4VNo(2);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 3 || c3R2VNo === 3 || c3R3VNo === 3 || c3R4VNo === 3){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(3);
           setC3R2VNo(3);
           setC3R3VNo(3);
           setC3R4VNo(3);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 4 || c3R2VNo === 4 || c3R3VNo === 4 || c3R4VNo === 4){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(4);
           setC3R2VNo(4);
           setC3R3VNo(4);
           setC3R4VNo(4);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 5 || c3R2VNo === 5 || c3R3VNo === 5 || c3R4VNo === 5){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(5);
           setC3R2VNo(5);
           setC3R3VNo(5);
           setC3R4VNo(5);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 6 || c3R2VNo === 6 || c3R3VNo === 6 || c3R4VNo === 6){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(6);
           setC3R2VNo(6);
           setC3R3VNo(6);
           setC3R4VNo(6);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 7 || c3R2VNo === 7 || c3R3VNo === 7 || c3R4VNo === 7){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(7);
           setC3R2VNo(7);
           setC3R3VNo(7);
           setC3R4VNo(7);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 8 || c3R2VNo === 8 || c3R3VNo === 8 || c3R4VNo === 8){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(8);
           setC3R2VNo(8);
           setC3R3VNo(8);
           setC3R4VNo(8);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 9 || c3R2VNo === 9 || c3R3VNo === 9 || c3R4VNo === 9){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(9);
           setC3R2VNo(9);
           setC3R3VNo(9);
           setC3R4VNo(9);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 10 || c3R2VNo === 10 || c3R3VNo === 10 || c3R4VNo === 10){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(10);
           setC3R2VNo(10);
           setC3R3VNo(10);
           setC3R4VNo(10);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      } else if (c3R1VNo === 11 || c3R2VNo === 11 || c3R3VNo === 11 || c3R4VNo === 11){

        if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === false && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOn2LC3(true);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOn2LC3(true);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === false && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(11);
           setC3R2VNo(11);
           setC3R3VNo(11);
           setC3R4VNo(11);
           setSepOn2LC3(true);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col04X && curY === row01X) || (curX === col04X && curY === row02X) || (curX === col04X && curY === row03X) || (curX === col04X && curY === row04X)) {
    if (c4r1MT === "L" || c4r2MT === "L" || c4r3MT === "L" || c4r4MT === "L") {
      if (c4R1VNo === 1 || c4R2VNo === 1 || c4R3VNo === 1 || c4R4VNo === 1){
        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(1);
           setC4R2VNo(1);
           setC4R3VNo(1);
           setC4R4VNo(1);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
      } else if (c4R1VNo === 2 || c4R2VNo === 2 || c4R3VNo === 2 || c4R4VNo === 2){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(2);
           setC4R2VNo(2);
           setC4R3VNo(2);
           setC4R4VNo(2);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 3 || c4R2VNo === 3 || c4R3VNo === 3 || c4R4VNo === 3){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(3);
           setC4R2VNo(3);
           setC4R3VNo(3);
           setC4R4VNo(3);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 4 || c4R2VNo === 4 || c4R3VNo === 4 || c4R4VNo === 4){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(4);
           setC4R2VNo(4);
           setC4R3VNo(4);
           setC4R4VNo(4);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 5 || c4R2VNo === 5 || c4R3VNo === 5 || c4R4VNo === 5){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(5);
           setC4R2VNo(5);
           setC4R3VNo(5);
           setC4R4VNo(5);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 6 || c4R2VNo === 6 || c4R3VNo === 6 || c4R4VNo === 6){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(6);
           setC4R2VNo(6);
           setC4R3VNo(6);
           setC4R4VNo(6);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 7 || c4R2VNo === 7 || c4R3VNo === 7 || c4R4VNo === 7){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(7);
           setC4R2VNo(7);
           setC4R3VNo(7);
           setC4R4VNo(7);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 8 || c4R2VNo === 8 || c4R3VNo === 8 || c4R4VNo === 8){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(8);
           setC4R2VNo(8);
           setC4R3VNo(8);
           setC4R4VNo(8);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 9 || c4R2VNo === 9 || c4R3VNo === 9 || c4R4VNo === 9){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(9);
           setC4R2VNo(9);
           setC4R3VNo(9);
           setC4R4VNo(9);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 10 || c4R2VNo === 10 || c4R3VNo === 10 || c4R4VNo === 10){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(10);
           setC4R2VNo(10);
           setC4R3VNo(10);
           setC4R4VNo(10);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      } else if (c4R1VNo === 11 || c4R2VNo === 11 || c4R3VNo === 11 || c4R4VNo === 11){

        if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === false && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOn2LC4(true);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOn2LC4(true);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === false && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(11);
           setC4R2VNo(11);
           setC4R3VNo(11);
           setC4R4VNo(11);
           setSepOn2LC4(true);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col05X && curY === row01X) || (curX === col05X && curY === row02X) || (curX === col05X && curY === row03X) || (curX === col05X && curY === row04X)) {
    if (c5r1MT === "L" || c5r2MT === "L" || c5r3MT === "L" || c5r4MT === "L") {
      if (c5R1VNo === 1 || c5R2VNo === 1 || c5R3VNo === 1 || c5R4VNo === 1){
        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(1);
           setC5R2VNo(1);
           setC5R3VNo(1);
           setC5R4VNo(1);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
      } else if (c5R1VNo === 2 || c5R2VNo === 2 || c5R3VNo === 2 || c5R4VNo === 2){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(2);
           setC5R2VNo(2);
           setC5R3VNo(2);
           setC5R4VNo(2);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 3 || c5R2VNo === 3 || c5R3VNo === 3 || c5R4VNo === 3){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(3);
           setC5R2VNo(3);
           setC5R3VNo(3);
           setC5R4VNo(3);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 4 || c5R2VNo === 4 || c5R3VNo === 4 || c5R4VNo === 4){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(4);
           setC5R2VNo(4);
           setC5R3VNo(4);
           setC5R4VNo(4);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 5 || c5R2VNo === 5 || c5R3VNo === 5 || c5R4VNo === 5){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(5);
           setC5R2VNo(5);
           setC5R3VNo(5);
           setC5R4VNo(5);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 6 || c5R2VNo === 6 || c5R3VNo === 6 || c5R4VNo === 6){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(6);
           setC5R2VNo(6);
           setC5R3VNo(6);
           setC5R4VNo(6);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 7 || c5R2VNo === 7 || c5R3VNo === 7 || c5R4VNo === 7){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(7);
           setC5R2VNo(7);
           setC5R3VNo(7);
           setC5R4VNo(7);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 8 || c5R2VNo === 8 || c5R3VNo === 8 || c5R4VNo === 8){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(8);
           setC5R2VNo(8);
           setC5R3VNo(8);
           setC5R4VNo(8);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 9 || c5R2VNo === 9 || c5R3VNo === 9 || c5R4VNo === 9){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(9);
           setC5R2VNo(9);
           setC5R3VNo(9);
           setC5R4VNo(9);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 10 || c5R2VNo === 10 || c5R3VNo === 10 || c5R4VNo === 10){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(10);
           setC5R2VNo(10);
           setC5R3VNo(10);
           setC5R4VNo(10);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      } else if (c5R1VNo === 11 || c5R2VNo === 11 || c5R3VNo === 11 || c5R4VNo === 11){

        if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === false && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOn2LC5(true);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOn2LC5(true);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === false && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(11);
           setC5R2VNo(11);
           setC5R3VNo(11);
           setC5R4VNo(11);
           setSepOn2LC5(true);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col06X && curY === row01X) || (curX === col06X && curY === row02X) || (curX === col06X && curY === row03X) || (curX === col06X && curY === row04X)) {
    if (c6r1MT === "L" || c6r2MT === "L" || c6r3MT === "L" || c6r4MT === "L") {
      if (c6R1VNo === 1 || c6R2VNo === 1 || c6R3VNo === 1 || c6R4VNo === 1){
        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(1);
           setC6R2VNo(1);
           setC6R3VNo(1);
           setC6R4VNo(1);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
      } else if (c6R1VNo === 2 || c6R2VNo === 2 || c6R3VNo === 2 || c6R4VNo === 2){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(2);
           setC6R2VNo(2);
           setC6R3VNo(2);
           setC6R4VNo(2);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 3 || c6R2VNo === 3 || c6R3VNo === 3 || c6R4VNo === 3){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(3);
           setC6R2VNo(3);
           setC6R3VNo(3);
           setC6R4VNo(3);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 4 || c6R2VNo === 4 || c6R3VNo === 4 || c6R4VNo === 4){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(4);
           setC6R2VNo(4);
           setC6R3VNo(4);
           setC6R4VNo(4);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 5 || c6R2VNo === 5 || c6R3VNo === 5 || c6R4VNo === 5){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(5);
           setC6R2VNo(5);
           setC6R3VNo(5);
           setC6R4VNo(5);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 6 || c6R2VNo === 6 || c6R3VNo === 6 || c6R4VNo === 6){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(6);
           setC6R2VNo(6);
           setC6R3VNo(6);
           setC6R4VNo(6);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 7 || c6R2VNo === 7 || c6R3VNo === 7 || c6R4VNo === 7){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(7);
           setC6R2VNo(7);
           setC6R3VNo(7);
           setC6R4VNo(7);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 8 || c6R2VNo === 8 || c6R3VNo === 8 || c6R4VNo === 8){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(8);
           setC6R2VNo(8);
           setC6R3VNo(8);
           setC6R4VNo(8);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 9 || c6R2VNo === 9 || c6R3VNo === 9 || c6R4VNo === 9){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(9);
           setC6R2VNo(9);
           setC6R3VNo(9);
           setC6R4VNo(9);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 10 || c6R2VNo === 10 || c6R3VNo === 10 || c6R4VNo === 10){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(10);
           setC6R2VNo(10);
           setC6R3VNo(10);
           setC6R4VNo(10);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      } else if (c6R1VNo === 11 || c6R2VNo === 11 || c6R3VNo === 11 || c6R4VNo === 11){

        if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === false && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOn2LC6(true);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOn2LC6(true);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === false && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(11);
           setC6R2VNo(11);
           setC6R3VNo(11);
           setC6R4VNo(11);
           setSepOn2LC6(true);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col07X && curY === row01X) || (curX === col07X && curY === row02X) || (curX === col07X && curY === row03X) || (curX === col07X && curY === row04X)) {
    if (c7r1MT === "L" || c7r2MT === "L" || c7r3MT === "L" || c7r4MT === "L") {
      if (c7R1VNo === 1 || c7R2VNo === 1 || c7R3VNo === 1 || c7R4VNo === 1){
        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(1);
           setC7R2VNo(1);
           setC7R3VNo(1);
           setC7R4VNo(1);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
      } else if (c7R1VNo === 2 || c7R2VNo === 2 || c7R3VNo === 2 || c7R4VNo === 2){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(2);
           setC7R2VNo(2);
           setC7R3VNo(2);
           setC7R4VNo(2);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 3 || c7R2VNo === 3 || c7R3VNo === 3 || c7R4VNo === 3){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(3);
           setC7R2VNo(3);
           setC7R3VNo(3);
           setC7R4VNo(3);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 4 || c7R2VNo === 4 || c7R3VNo === 4 || c7R4VNo === 4){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(4);
           setC7R2VNo(4);
           setC7R3VNo(4);
           setC7R4VNo(4);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 5 || c7R2VNo === 5 || c7R3VNo === 5 || c7R4VNo === 5){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(5);
           setC7R2VNo(5);
           setC7R3VNo(5);
           setC7R4VNo(5);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 6 || c7R2VNo === 6 || c7R3VNo === 6 || c7R4VNo === 6){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(6);
           setC7R2VNo(6);
           setC7R3VNo(6);
           setC7R4VNo(6);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 7 || c7R2VNo === 7 || c7R3VNo === 7 || c7R4VNo === 7){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(7);
           setC7R2VNo(7);
           setC7R3VNo(7);
           setC7R4VNo(7);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 8 || c7R2VNo === 8 || c7R3VNo === 8 || c7R4VNo === 8){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(8);
           setC7R2VNo(8);
           setC7R3VNo(8);
           setC7R4VNo(8);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 9 || c7R2VNo === 9 || c7R3VNo === 9 || c7R4VNo === 9){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(9);
           setC7R2VNo(9);
           setC7R3VNo(9);
           setC7R4VNo(9);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 10 || c7R2VNo === 10 || c7R3VNo === 10 || c7R4VNo === 10){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(10);
           setC7R2VNo(10);
           setC7R3VNo(10);
           setC7R4VNo(10);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      } else if (c7R1VNo === 11 || c7R2VNo === 11 || c7R3VNo === 11 || c7R4VNo === 11){

        if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === false && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOn2LC7(true);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOn2LC7(true);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === false && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(11);
           setC7R2VNo(11);
           setC7R3VNo(11);
           setC7R4VNo(11);
           setSepOn2LC7(true);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col08X && curY === row01X) || (curX === col08X && curY === row02X) || (curX === col08X && curY === row03X) || (curX === col08X && curY === row04X)) {
    if (c8r1MT === "L" || c8r2MT === "L" || c8r3MT === "L" || c8r4MT === "L") {
      if (c8R1VNo === 1 || c8R2VNo === 1 || c8R3VNo === 1 || c8R4VNo === 1){
        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(1);
           setC8R2VNo(1);
           setC8R3VNo(1);
           setC8R4VNo(1);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
      } else if (c8R1VNo === 2 || c8R2VNo === 2 || c8R3VNo === 2 || c8R4VNo === 2){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(2);
           setC8R2VNo(2);
           setC8R3VNo(2);
           setC8R4VNo(2);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 3 || c8R2VNo === 3 || c8R3VNo === 3 || c8R4VNo === 3){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(3);
           setC8R2VNo(3);
           setC8R3VNo(3);
           setC8R4VNo(3);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 4 || c8R2VNo === 4 || c8R3VNo === 4 || c8R4VNo === 4){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(4);
           setC8R2VNo(4);
           setC8R3VNo(4);
           setC8R4VNo(4);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 5 || c8R2VNo === 5 || c8R3VNo === 5 || c8R4VNo === 5){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(5);
           setC8R2VNo(5);
           setC8R3VNo(5);
           setC8R4VNo(5);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 6 || c8R2VNo === 6 || c8R3VNo === 6 || c8R4VNo === 6){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(6);
           setC8R2VNo(6);
           setC8R3VNo(6);
           setC8R4VNo(6);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 7 || c8R2VNo === 7 || c8R3VNo === 7 || c8R4VNo === 7){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(7);
           setC8R2VNo(7);
           setC8R3VNo(7);
           setC8R4VNo(7);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 8 || c8R2VNo === 8 || c8R3VNo === 8 || c8R4VNo === 8){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(8);
           setC8R2VNo(8);
           setC8R3VNo(8);
           setC8R4VNo(8);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 9 || c8R2VNo === 9 || c8R3VNo === 9 || c8R4VNo === 9){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(9);
           setC8R2VNo(9);
           setC8R3VNo(9);
           setC8R4VNo(9);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 10 || c8R2VNo === 10 || c8R3VNo === 10 || c8R4VNo === 10){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(10);
           setC8R2VNo(10);
           setC8R3VNo(10);
           setC8R4VNo(10);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      } else if (c8R1VNo === 11 || c8R2VNo === 11 || c8R3VNo === 11 || c8R4VNo === 11){

        if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === false && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOn2LC8(true);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOn2LC8(true);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === false && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(11);
           setC8R2VNo(11);
           setC8R3VNo(11);
           setC8R4VNo(11);
           setSepOn2LC8(true);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col09X && curY === row01X) || (curX === col09X && curY === row02X) || (curX === col09X && curY === row03X) || (curX === col09X && curY === row04X)) {
    if (c9r1MT === "L" || c9r2MT === "L" || c9r3MT === "L" || c9r4MT === "L") {
      if (c9R1VNo === 1 || c9R2VNo === 1 || c9R3VNo === 1 || c9R4VNo === 1){
        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(1);
           setC9R2VNo(1);
           setC9R3VNo(1);
           setC9R4VNo(1);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
      } else if (c9R1VNo === 2 || c9R2VNo === 2 || c9R3VNo === 2 || c9R4VNo === 2){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(2);
           setC9R2VNo(2);
           setC9R3VNo(2);
           setC9R4VNo(2);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 3 || c9R2VNo === 3 || c9R3VNo === 3 || c9R4VNo === 3){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(3);
           setC9R2VNo(3);
           setC9R3VNo(3);
           setC9R4VNo(3);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 4 || c9R2VNo === 4 || c9R3VNo === 4 || c9R4VNo === 4){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(4);
           setC9R2VNo(4);
           setC9R3VNo(4);
           setC9R4VNo(4);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 5 || c9R2VNo === 5 || c9R3VNo === 5 || c9R4VNo === 5){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(5);
           setC9R2VNo(5);
           setC9R3VNo(5);
           setC9R4VNo(5);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 6 || c9R2VNo === 6 || c9R3VNo === 6 || c9R4VNo === 6){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(6);
           setC9R2VNo(6);
           setC9R3VNo(6);
           setC9R4VNo(6);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 7 || c9R2VNo === 7 || c9R3VNo === 7 || c9R4VNo === 7){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(7);
           setC9R2VNo(7);
           setC9R3VNo(7);
           setC9R4VNo(7);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 8 || c9R2VNo === 8 || c9R3VNo === 8 || c9R4VNo === 8){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(8);
           setC9R2VNo(8);
           setC9R3VNo(8);
           setC9R4VNo(8);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 9 || c9R2VNo === 9 || c9R3VNo === 9 || c9R4VNo === 9){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(9);
           setC9R2VNo(9);
           setC9R3VNo(9);
           setC9R4VNo(9);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 10 || c9R2VNo === 10 || c9R3VNo === 10 || c9R4VNo === 10){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(10);
           setC9R2VNo(10);
           setC9R3VNo(10);
           setC9R4VNo(10);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      } else if (c9R1VNo === 11 || c9R2VNo === 11 || c9R3VNo === 11 || c9R4VNo === 11){

        if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === false && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOn2LC9(true);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOn2LC9(true);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === false && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(11);
           setC9R2VNo(11);
           setC9R3VNo(11);
           setC9R4VNo(11);
           setSepOn2LC9(true);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col10X && curY === row01X) || (curX === col10X && curY === row02X) || (curX === col10X && curY === row03X) || (curX === col10X && curY === row04X)) {
    if (c10r1MT === "L" || c10r2MT === "L" || c10r3MT === "L" || c10r4MT === "L") {
      if (c10R1VNo === 1 || c10R2VNo === 1 || c10R3VNo === 1 || c10R4VNo === 1){
        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(1);
           setC10R2VNo(1);
           setC10R3VNo(1);
           setC10R4VNo(1);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
      } else if (c10R1VNo === 2 || c10R2VNo === 2 || c10R3VNo === 2 || c10R4VNo === 2){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(2);
           setC10R2VNo(2);
           setC10R3VNo(2);
           setC10R4VNo(2);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 3 || c10R2VNo === 3 || c10R3VNo === 3 || c10R4VNo === 3){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(3);
           setC10R2VNo(3);
           setC10R3VNo(3);
           setC10R4VNo(3);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 4 || c10R2VNo === 4 || c10R3VNo === 4 || c10R4VNo === 4){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(4);
           setC10R2VNo(4);
           setC10R3VNo(4);
           setC10R4VNo(4);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 5 || c10R2VNo === 5 || c10R3VNo === 5 || c10R4VNo === 5){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(5);
           setC10R2VNo(5);
           setC10R3VNo(5);
           setC10R4VNo(5);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 6 || c10R2VNo === 6 || c10R3VNo === 6 || c10R4VNo === 6){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(6);
           setC10R2VNo(6);
           setC10R3VNo(6);
           setC10R4VNo(6);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 7 || c10R2VNo === 7 || c10R3VNo === 7 || c10R4VNo === 7){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(7);
           setC10R2VNo(7);
           setC10R3VNo(7);
           setC10R4VNo(7);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 8 || c10R2VNo === 8 || c10R3VNo === 8 || c10R4VNo === 8){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(8);
           setC10R2VNo(8);
           setC10R3VNo(8);
           setC10R4VNo(8);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 9 || c10R2VNo === 9 || c10R3VNo === 9 || c10R4VNo === 9){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(9);
           setC10R2VNo(9);
           setC10R3VNo(9);
           setC10R4VNo(9);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 10 || c10R2VNo === 10 || c10R3VNo === 10 || c10R4VNo === 10){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(10);
           setC10R2VNo(10);
           setC10R3VNo(10);
           setC10R4VNo(10);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      } else if (c10R1VNo === 11 || c10R2VNo === 11 || c10R3VNo === 11 || c10R4VNo === 11){

        if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === false && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOn2LC10(true);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOn2LC10(true);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === false && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(11);
           setC10R2VNo(11);
           setC10R3VNo(11);
           setC10R4VNo(11);
           setSepOn2LC10(true);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col11X && curY === row01X) || (curX === col11X && curY === row02X) || (curX === col11X && curY === row03X) || (curX === col11X && curY === row04X)) {
    if (c11r1MT === "L" || c11r2MT === "L" || c11r3MT === "L" || c11r4MT === "L") {
      if (c11R1VNo === 1 || c11R2VNo === 1 || c11R3VNo === 1 || c11R4VNo === 1){
        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(1);
           setC11R2VNo(1);
           setC11R3VNo(1);
           setC11R4VNo(1);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
      } else if (c11R1VNo === 2 || c11R2VNo === 2 || c11R3VNo === 2 || c11R4VNo === 2){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(2);
           setC11R2VNo(2);
           setC11R3VNo(2);
           setC11R4VNo(2);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 3 || c11R2VNo === 3 || c11R3VNo === 3 || c11R4VNo === 3){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(3);
           setC11R2VNo(3);
           setC11R3VNo(3);
           setC11R4VNo(3);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 4 || c11R2VNo === 4 || c11R3VNo === 4 || c11R4VNo === 4){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(4);
           setC11R2VNo(4);
           setC11R3VNo(4);
           setC11R4VNo(4);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 5 || c11R2VNo === 5 || c11R3VNo === 5 || c11R4VNo === 5){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(5);
           setC11R2VNo(5);
           setC11R3VNo(5);
           setC11R4VNo(5);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 6 || c11R2VNo === 6 || c11R3VNo === 6 || c11R4VNo === 6){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(6);
           setC11R2VNo(6);
           setC11R3VNo(6);
           setC11R4VNo(6);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 7 || c11R2VNo === 7 || c11R3VNo === 7 || c11R4VNo === 7){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(7);
           setC11R2VNo(7);
           setC11R3VNo(7);
           setC11R4VNo(7);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 8 || c11R2VNo === 8 || c11R3VNo === 8 || c11R4VNo === 8){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(8);
           setC11R2VNo(8);
           setC11R3VNo(8);
           setC11R4VNo(8);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 9 || c11R2VNo === 9 || c11R3VNo === 9 || c11R4VNo === 9){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(9);
           setC11R2VNo(9);
           setC11R3VNo(9);
           setC11R4VNo(9);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 10 || c11R2VNo === 10 || c11R3VNo === 10 || c11R4VNo === 10){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(10);
           setC11R2VNo(10);
           setC11R3VNo(10);
           setC11R4VNo(10);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      } else if (c11R1VNo === 11 || c11R2VNo === 11 || c11R3VNo === 11 || c11R4VNo === 11){

        if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === false && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOn2LC11(true);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOn2LC11(true);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === false && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(11);
           setC11R2VNo(11);
           setC11R3VNo(11);
           setC11R4VNo(11);
           setSepOn2LC11(true);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col12X && curY === row01X) || (curX === col12X && curY === row02X) || (curX === col12X && curY === row03X) || (curX === col12X && curY === row04X)) {
    if (c12r1MT === "L" || c12r2MT === "L" || c12r3MT === "L" || c12r4MT === "L") {
      if (c12R1VNo === 1 || c12R2VNo === 1 || c12R3VNo === 1 || c12R4VNo === 1){
        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(1);
           setC12R2VNo(1);
           setC12R3VNo(1);
           setC12R4VNo(1);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
      } else if (c12R1VNo === 2 || c12R2VNo === 2 || c12R3VNo === 2 || c12R4VNo === 2){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(2);
           setC12R2VNo(2);
           setC12R3VNo(2);
           setC12R4VNo(2);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 3 || c12R2VNo === 3 || c12R3VNo === 3 || c12R4VNo === 3){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(3);
           setC12R2VNo(3);
           setC12R3VNo(3);
           setC12R4VNo(3);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 4 || c12R2VNo === 4 || c12R3VNo === 4 || c12R4VNo === 4){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(4);
           setC12R2VNo(4);
           setC12R3VNo(4);
           setC12R4VNo(4);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 5 || c12R2VNo === 5 || c12R3VNo === 5 || c12R4VNo === 5){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(5);
           setC12R2VNo(5);
           setC12R3VNo(5);
           setC12R4VNo(5);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 6 || c12R2VNo === 6 || c12R3VNo === 6 || c12R4VNo === 6){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(6);
           setC12R2VNo(6);
           setC12R3VNo(6);
           setC12R4VNo(6);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 7 || c12R2VNo === 7 || c12R3VNo === 7 || c12R4VNo === 7){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(7);
           setC12R2VNo(7);
           setC12R3VNo(7);
           setC12R4VNo(7);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 8 || c12R2VNo === 8 || c12R3VNo === 8 || c12R4VNo === 8){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(8);
           setC12R2VNo(8);
           setC12R3VNo(8);
           setC12R4VNo(8);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 9 || c12R2VNo === 9 || c12R3VNo === 9 || c12R4VNo === 9){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(9);
           setC12R2VNo(9);
           setC12R3VNo(9);
           setC12R4VNo(9);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 10 || c12R2VNo === 10 || c12R3VNo === 10 || c12R4VNo === 10){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(10);
           setC12R2VNo(10);
           setC12R3VNo(10);
           setC12R4VNo(10);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      } else if (c12R1VNo === 11 || c12R2VNo === 11 || c12R3VNo === 11 || c12R4VNo === 11){

        if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === false && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOn2LC12(true);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOn2LC12(true);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === false && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(11);
           setC12R2VNo(11);
           setC12R3VNo(11);
           setC12R4VNo(11);
           setSepOn2LC12(true);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col13X && curY === row01X) || (curX === col13X && curY === row02X) || (curX === col13X && curY === row03X) || (curX === col13X && curY === row04X)) {
    if (c13r1MT === "L" || c13r2MT === "L" || c13r3MT === "L" || c13r4MT === "L") {
      if (c13R1VNo === 1 || c13R2VNo === 1 || c13R3VNo === 1 || c13R4VNo === 1){
        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(1);
           setC13R2VNo(1);
           setC13R3VNo(1);
           setC13R4VNo(1);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
      } else if (c13R1VNo === 2 || c13R2VNo === 2 || c13R3VNo === 2 || c13R4VNo === 2){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(2);
           setC13R2VNo(2);
           setC13R3VNo(2);
           setC13R4VNo(2);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 3 || c13R2VNo === 3 || c13R3VNo === 3 || c13R4VNo === 3){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(3);
           setC13R2VNo(3);
           setC13R3VNo(3);
           setC13R4VNo(3);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 4 || c13R2VNo === 4 || c13R3VNo === 4 || c13R4VNo === 4){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(4);
           setC13R2VNo(4);
           setC13R3VNo(4);
           setC13R4VNo(4);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 5 || c13R2VNo === 5 || c13R3VNo === 5 || c13R4VNo === 5){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(5);
           setC13R2VNo(5);
           setC13R3VNo(5);
           setC13R4VNo(5);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 6 || c13R2VNo === 6 || c13R3VNo === 6 || c13R4VNo === 6){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(6);
           setC13R2VNo(6);
           setC13R3VNo(6);
           setC13R4VNo(6);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 7 || c13R2VNo === 7 || c13R3VNo === 7 || c13R4VNo === 7){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(7);
           setC13R2VNo(7);
           setC13R3VNo(7);
           setC13R4VNo(7);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 8 || c13R2VNo === 8 || c13R3VNo === 8 || c13R4VNo === 8){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(8);
           setC13R2VNo(8);
           setC13R3VNo(8);
           setC13R4VNo(8);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 9 || c13R2VNo === 9 || c13R3VNo === 9 || c13R4VNo === 9){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(9);
           setC13R2VNo(9);
           setC13R3VNo(9);
           setC13R4VNo(9);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 10 || c13R2VNo === 10 || c13R3VNo === 10 || c13R4VNo === 10){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(10);
           setC13R2VNo(10);
           setC13R3VNo(10);
           setC13R4VNo(10);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      } else if (c13R1VNo === 11 || c13R2VNo === 11 || c13R3VNo === 11 || c13R4VNo === 11){

        if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === false && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOn2LC13(true);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOn2LC13(true);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === false && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(11);
           setC13R2VNo(11);
           setC13R3VNo(11);
           setC13R4VNo(11);
           setSepOn2LC13(true);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col14X && curY === row01X) || (curX === col14X && curY === row02X) || (curX === col14X && curY === row03X) || (curX === col14X && curY === row04X)) {
    if (c14r1MT === "L" || c14r2MT === "L" || c14r3MT === "L" || c14r4MT === "L") {
      if (c14R1VNo === 1 || c14R2VNo === 1 || c14R3VNo === 1 || c14R4VNo === 1){
        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(1);
           setC14R2VNo(1);
           setC14R3VNo(1);
           setC14R4VNo(1);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
      } else if (c14R1VNo === 2 || c14R2VNo === 2 || c14R3VNo === 2 || c14R4VNo === 2){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(2);
           setC14R2VNo(2);
           setC14R3VNo(2);
           setC14R4VNo(2);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 3 || c14R2VNo === 3 || c14R3VNo === 3 || c14R4VNo === 3){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(3);
           setC14R2VNo(3);
           setC14R3VNo(3);
           setC14R4VNo(3);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 4 || c14R2VNo === 4 || c14R3VNo === 4 || c14R4VNo === 4){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(4);
           setC14R2VNo(4);
           setC14R3VNo(4);
           setC14R4VNo(4);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 5 || c14R2VNo === 5 || c14R3VNo === 5 || c14R4VNo === 5){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(5);
           setC14R2VNo(5);
           setC14R3VNo(5);
           setC14R4VNo(5);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 6 || c14R2VNo === 6 || c14R3VNo === 6 || c14R4VNo === 6){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(6);
           setC14R2VNo(6);
           setC14R3VNo(6);
           setC14R4VNo(6);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 7 || c14R2VNo === 7 || c14R3VNo === 7 || c14R4VNo === 7){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(7);
           setC14R2VNo(7);
           setC14R3VNo(7);
           setC14R4VNo(7);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 8 || c14R2VNo === 8 || c14R3VNo === 8 || c14R4VNo === 8){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(8);
           setC14R2VNo(8);
           setC14R3VNo(8);
           setC14R4VNo(8);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 9 || c14R2VNo === 9 || c14R3VNo === 9 || c14R4VNo === 9){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(9);
           setC14R2VNo(9);
           setC14R3VNo(9);
           setC14R4VNo(9);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 10 || c14R2VNo === 10 || c14R3VNo === 10 || c14R4VNo === 10){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(10);
           setC14R2VNo(10);
           setC14R3VNo(10);
           setC14R4VNo(10);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      } else if (c14R1VNo === 11 || c14R2VNo === 11 || c14R3VNo === 11 || c14R4VNo === 11){

        if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === false && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOn2LC14(true);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOn2LC14(true);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === false && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(11);
           setC14R2VNo(11);
           setC14R3VNo(11);
           setC14R4VNo(11);
           setSepOn2LC14(true);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already added")
         }
         
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } 

}

const removeSeperatorMiddle = (e)=> {
  if ((curX === col01X && curY === row01X) || (curX === col01X && curY === row02X) || (curX === col01X && curY === row03X) || (curX === col01X && curY === row04X)) {
    if (c1r1MT === "L" || c1r2MT === "L" || c1r3MT === "L" || c1r4MT === "L") {
      if (c1R1VNo === 1 || c1R2VNo === 1 || c1R3VNo === 1 || c1R4VNo === 1){
        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(1);
           setC1R2VNo(1);
           setC1R3VNo(1);
           setC1R4VNo(1);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
      } else if (c1R1VNo === 2 || c1R2VNo === 2 || c1R3VNo === 2 || c1R4VNo === 2){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          setC1R2VNo(2);
          setC1R3VNo(2);
          setC1R4VNo(2);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(2);
           setC1R2VNo(2);
           setC1R3VNo(2);
           setC1R4VNo(2);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 3 || c1R2VNo === 3 || c1R3VNo === 3 || c1R4VNo === 3){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(3);
          setC1R2VNo(3);
          setC1R3VNo(3);
          setC1R4VNo(3);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(3);
           setC1R2VNo(3);
           setC1R3VNo(3);
           setC1R4VNo(3);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 4 || c1R2VNo === 4 || c1R3VNo === 4 || c1R4VNo === 4){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(4);
           setC1R2VNo(4);
           setC1R3VNo(4);
           setC1R4VNo(4);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 5 || c1R2VNo === 5 || c1R3VNo === 5 || c1R4VNo === 5){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(5);
           setC1R2VNo(5);
           setC1R3VNo(5);
           setC1R4VNo(5);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 6 || c1R2VNo === 6 || c1R3VNo === 6 || c1R4VNo === 6){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6);
          setC1R2VNo(6);
          setC1R3VNo(6);
          setC1R4VNo(6);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(6);
           setC1R2VNo(6);
           setC1R3VNo(6);
           setC1R4VNo(6);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 7 || c1R2VNo === 7 || c1R3VNo === 7 || c1R4VNo === 7){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(7);
           setC1R2VNo(7);
           setC1R3VNo(7);
           setC1R4VNo(7);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 8 || c1R2VNo === 8 || c1R3VNo === 8 || c1R4VNo === 8){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(8);
           setC1R2VNo(8);
           setC1R3VNo(8);
           setC1R4VNo(8);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 9 || c1R2VNo === 9 || c1R3VNo === 9 || c1R4VNo === 9){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(9);
           setC1R2VNo(9);
           setC1R3VNo(9);
           setC1R4VNo(9);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 10 || c1R2VNo === 10 || c1R3VNo === 10 || c1R4VNo === 10){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(10);
           setC1R2VNo(10);
           setC1R3VNo(10);
           setC1R4VNo(10);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c1R1VNo === 11 || c1R2VNo === 11 || c1R3VNo === 11 || c1R4VNo === 11){

        if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(false)
       
        } else if (sepOn2LC1 === true && sepOnLC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOn2LC1(false);
          setSepOnLC1(false);
          setSepOn3LC1(true)
        } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOn2LC1(false);
          setSepOnLC1(true);
          setSepOn3LC1(false)
         } else if (sepOn2LC1 === true && sepOnLC1 === true && sepOn3LC1 === true) {
           setBaseNakedL01(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
             ]
           )
           setC1R1VNo(11);
           setC1R2VNo(11);
           setC1R3VNo(11);
           setC1R4VNo(11);
           setSepOn2LC1(false);
           setSepOnLC1(true);
           setSepOn3LC1(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col02X && curY === row01X) || (curX === col02X && curY === row02X) || (curX === col02X && curY === row03X) || (curX === col02X && curY === row04X)) {
    if (c2r1MT === "L" || c2r2MT === "L" || c2r3MT === "L" || c2r4MT === "L") {
      if (c2R1VNo === 1 || c2R2VNo === 1 || c2R3VNo === 1 || c2R4VNo === 1){
        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(1);
           setC2R2VNo(1);
           setC2R3VNo(1);
           setC2R4VNo(1);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
      } else if (c2R1VNo === 2 || c2R2VNo === 2 || c2R3VNo === 2 || c2R4VNo === 2){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(2);
          setC2R2VNo(2);
          setC2R3VNo(2);
          setC2R4VNo(2);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(2);
           setC2R2VNo(2);
           setC2R3VNo(2);
           setC2R4VNo(2);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 3 || c2R2VNo === 3 || c2R3VNo === 3 || c2R4VNo === 3){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(3);
          setC2R2VNo(3);
          setC2R3VNo(3);
          setC2R4VNo(3);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(3);
           setC2R2VNo(3);
           setC2R3VNo(3);
           setC2R4VNo(3);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 4 || c2R2VNo === 4 || c2R3VNo === 4 || c2R4VNo === 4){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(4);
           setC2R2VNo(4);
           setC2R3VNo(4);
           setC2R4VNo(4);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 5 || c2R2VNo === 5 || c2R3VNo === 5 || c2R4VNo === 5){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(5);
           setC2R2VNo(5);
           setC2R3VNo(5);
           setC2R4VNo(5);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 6 || c2R2VNo === 6 || c2R3VNo === 6 || c2R4VNo === 6){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6);
          setC2R2VNo(6);
          setC2R3VNo(6);
          setC2R4VNo(6);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(6);
           setC2R2VNo(6);
           setC2R3VNo(6);
           setC2R4VNo(6);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 7 || c2R2VNo === 7 || c2R3VNo === 7 || c2R4VNo === 7){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(7);
           setC2R2VNo(7);
           setC2R3VNo(7);
           setC2R4VNo(7);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 8 || c2R2VNo === 8 || c2R3VNo === 8 || c2R4VNo === 8){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(8);
           setC2R2VNo(8);
           setC2R3VNo(8);
           setC2R4VNo(8);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 9 || c2R2VNo === 9 || c2R3VNo === 9 || c2R4VNo === 9){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(9);
           setC2R2VNo(9);
           setC2R3VNo(9);
           setC2R4VNo(9);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 10 || c2R2VNo === 10 || c2R3VNo === 10 || c2R4VNo === 10){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(10);
           setC2R2VNo(10);
           setC2R3VNo(10);
           setC2R4VNo(10);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c2R1VNo === 11 || c2R2VNo === 11 || c2R3VNo === 11 || c2R4VNo === 11){

        if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(false)
       
        } else if (sepOn2LC2 === true && sepOnLC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOn2LC2(false);
          setSepOnLC2(false);
          setSepOn3LC2(true)
        } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOn2LC2(false);
          setSepOnLC2(true);
          setSepOn3LC2(false)
         } else if (sepOn2LC2 === true && sepOnLC2 === true && sepOn3LC2 === true) {
           setBaseNakedL02(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
             ]
           )
           setC2R1VNo(11);
           setC2R2VNo(11);
           setC2R3VNo(11);
           setC2R4VNo(11);
           setSepOn2LC2(false);
           setSepOnLC2(true);
           setSepOn3LC2(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col03X && curY === row01X) || (curX === col03X && curY === row02X) || (curX === col03X && curY === row03X) || (curX === col03X && curY === row04X)) {
    if (c3r1MT === "L" || c3r2MT === "L" || c3r3MT === "L" || c3r4MT === "L") {
      if (c3R1VNo === 1 || c3R2VNo === 1 || c3R3VNo === 1 || c3R4VNo === 1){
        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(1);
           setC3R2VNo(1);
           setC3R3VNo(1);
           setC3R4VNo(1);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
      } else if (c3R1VNo === 2 || c3R2VNo === 2 || c3R3VNo === 2 || c3R4VNo === 2){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(2);
          setC3R2VNo(2);
          setC3R3VNo(2);
          setC3R4VNo(2);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(2);
           setC3R2VNo(2);
           setC3R3VNo(2);
           setC3R4VNo(2);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 3 || c3R2VNo === 3 || c3R3VNo === 3 || c3R4VNo === 3){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(3);
          setC3R2VNo(3);
          setC3R3VNo(3);
          setC3R4VNo(3);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(3);
           setC3R2VNo(3);
           setC3R3VNo(3);
           setC3R4VNo(3);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 4 || c3R2VNo === 4 || c3R3VNo === 4 || c3R4VNo === 4){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(4);
           setC3R2VNo(4);
           setC3R3VNo(4);
           setC3R4VNo(4);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 5 || c3R2VNo === 5 || c3R3VNo === 5 || c3R4VNo === 5){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(5);
           setC3R2VNo(5);
           setC3R3VNo(5);
           setC3R4VNo(5);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 6 || c3R2VNo === 6 || c3R3VNo === 6 || c3R4VNo === 6){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6);
          setC3R2VNo(6);
          setC3R3VNo(6);
          setC3R4VNo(6);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(6);
           setC3R2VNo(6);
           setC3R3VNo(6);
           setC3R4VNo(6);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 7 || c3R2VNo === 7 || c3R3VNo === 7 || c3R4VNo === 7){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(7);
           setC3R2VNo(7);
           setC3R3VNo(7);
           setC3R4VNo(7);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 8 || c3R2VNo === 8 || c3R3VNo === 8 || c3R4VNo === 8){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(8);
           setC3R2VNo(8);
           setC3R3VNo(8);
           setC3R4VNo(8);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 9 || c3R2VNo === 9 || c3R3VNo === 9 || c3R4VNo === 9){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(9);
           setC3R2VNo(9);
           setC3R3VNo(9);
           setC3R4VNo(9);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 10 || c3R2VNo === 10 || c3R3VNo === 10 || c3R4VNo === 10){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(10);
           setC3R2VNo(10);
           setC3R3VNo(10);
           setC3R4VNo(10);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c3R1VNo === 11 || c3R2VNo === 11 || c3R3VNo === 11 || c3R4VNo === 11){

        if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(false)
       
        } else if (sepOn2LC3 === true && sepOnLC3 === false && sepOn3LC3 === true) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOn2LC3(false);
          setSepOnLC3(false);
          setSepOn3LC3(true)
        } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === false) {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOn2LC3(false);
          setSepOnLC3(true);
          setSepOn3LC3(false)
         } else if (sepOn2LC3 === true && sepOnLC3 === true && sepOn3LC3 === true) {
           setBaseNakedL03(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
             ]
           )
           setC3R1VNo(11);
           setC3R2VNo(11);
           setC3R3VNo(11);
           setC3R4VNo(11);
           setSepOn2LC3(false);
           setSepOnLC3(true);
           setSepOn3LC3(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col04X && curY === row01X) || (curX === col04X && curY === row02X) || (curX === col04X && curY === row03X) || (curX === col04X && curY === row04X)) {
    if (c4r1MT === "L" || c4r2MT === "L" || c4r3MT === "L" || c4r4MT === "L") {
      if (c4R1VNo === 1 || c4R2VNo === 1 || c4R3VNo === 1 || c4R4VNo === 1){
        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(1);
           setC4R2VNo(1);
           setC4R3VNo(1);
           setC4R4VNo(1);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
      } else if (c4R1VNo === 2 || c4R2VNo === 2 || c4R3VNo === 2 || c4R4VNo === 2){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(2);
          setC4R2VNo(2);
          setC4R3VNo(2);
          setC4R4VNo(2);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(2);
           setC4R2VNo(2);
           setC4R3VNo(2);
           setC4R4VNo(2);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 3 || c4R2VNo === 3 || c4R3VNo === 3 || c4R4VNo === 3){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(3);
          setC4R2VNo(3);
          setC4R3VNo(3);
          setC4R4VNo(3);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(3);
           setC4R2VNo(3);
           setC4R3VNo(3);
           setC4R4VNo(3);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 4 || c4R2VNo === 4 || c4R3VNo === 4 || c4R4VNo === 4){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(4);
           setC4R2VNo(4);
           setC4R3VNo(4);
           setC4R4VNo(4);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 5 || c4R2VNo === 5 || c4R3VNo === 5 || c4R4VNo === 5){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(5);
           setC4R2VNo(5);
           setC4R3VNo(5);
           setC4R4VNo(5);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 6 || c4R2VNo === 6 || c4R3VNo === 6 || c4R4VNo === 6){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6);
          setC4R2VNo(6);
          setC4R3VNo(6);
          setC4R4VNo(6);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(6);
           setC4R2VNo(6);
           setC4R3VNo(6);
           setC4R4VNo(6);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 7 || c4R2VNo === 7 || c4R3VNo === 7 || c4R4VNo === 7){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(7);
           setC4R2VNo(7);
           setC4R3VNo(7);
           setC4R4VNo(7);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 8 || c4R2VNo === 8 || c4R3VNo === 8 || c4R4VNo === 8){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(8);
           setC4R2VNo(8);
           setC4R3VNo(8);
           setC4R4VNo(8);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 9 || c4R2VNo === 9 || c4R3VNo === 9 || c4R4VNo === 9){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(9);
           setC4R2VNo(9);
           setC4R3VNo(9);
           setC4R4VNo(9);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 10 || c4R2VNo === 10 || c4R3VNo === 10 || c4R4VNo === 10){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(10);
           setC4R2VNo(10);
           setC4R3VNo(10);
           setC4R4VNo(10);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c4R1VNo === 11 || c4R2VNo === 11 || c4R3VNo === 11 || c4R4VNo === 11){

        if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(false)
       
        } else if (sepOn2LC4 === true && sepOnLC4 === false && sepOn3LC4 === true) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOn2LC4(false);
          setSepOnLC4(false);
          setSepOn3LC4(true)
        } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === false) {
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOn2LC4(false);
          setSepOnLC4(true);
          setSepOn3LC4(false)
         } else if (sepOn2LC4 === true && sepOnLC4 === true && sepOn3LC4 === true) {
           setBaseNakedL04(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
             ]
           )
           setC4R1VNo(11);
           setC4R2VNo(11);
           setC4R3VNo(11);
           setC4R4VNo(11);
           setSepOn2LC4(false);
           setSepOnLC4(true);
           setSepOn3LC4(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col05X && curY === row01X) || (curX === col05X && curY === row02X) || (curX === col05X && curY === row03X) || (curX === col05X && curY === row04X)) {
    if (c5r1MT === "L" || c5r2MT === "L" || c5r3MT === "L" || c5r4MT === "L") {
      if (c5R1VNo === 1 || c5R2VNo === 1 || c5R3VNo === 1 || c5R4VNo === 1){
        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(1);
           setC5R2VNo(1);
           setC5R3VNo(1);
           setC5R4VNo(1);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
      } else if (c5R1VNo === 2 || c5R2VNo === 2 || c5R3VNo === 2 || c5R4VNo === 2){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(2);
          setC5R2VNo(2);
          setC5R3VNo(2);
          setC5R4VNo(2);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(2);
           setC5R2VNo(2);
           setC5R3VNo(2);
           setC5R4VNo(2);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 3 || c5R2VNo === 3 || c5R3VNo === 3 || c5R4VNo === 3){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(3);
           setC5R2VNo(3);
           setC5R3VNo(3);
           setC5R4VNo(3);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 4 || c5R2VNo === 4 || c5R3VNo === 4 || c5R4VNo === 4){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(4);
           setC5R2VNo(4);
           setC5R3VNo(4);
           setC5R4VNo(4);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 5 || c5R2VNo === 5 || c5R3VNo === 5 || c5R4VNo === 5){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(5);
           setC5R2VNo(5);
           setC5R3VNo(5);
           setC5R4VNo(5);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 6 || c5R2VNo === 6 || c5R3VNo === 6 || c5R4VNo === 6){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6);
          setC5R2VNo(6);
          setC5R3VNo(6);
          setC5R4VNo(6);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(6);
           setC5R2VNo(6);
           setC5R3VNo(6);
           setC5R4VNo(6);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 7 || c5R2VNo === 7 || c5R3VNo === 7 || c5R4VNo === 7){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(7);
           setC5R2VNo(7);
           setC5R3VNo(7);
           setC5R4VNo(7);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 8 || c5R2VNo === 8 || c5R3VNo === 8 || c5R4VNo === 8){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(8);
           setC5R2VNo(8);
           setC5R3VNo(8);
           setC5R4VNo(8);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 9 || c5R2VNo === 9 || c5R3VNo === 9 || c5R4VNo === 9){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(9);
           setC5R2VNo(9);
           setC5R3VNo(9);
           setC5R4VNo(9);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 10 || c5R2VNo === 10 || c5R3VNo === 10 || c5R4VNo === 10){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(10);
           setC5R2VNo(10);
           setC5R3VNo(10);
           setC5R4VNo(10);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c5R1VNo === 11 || c5R2VNo === 11 || c5R3VNo === 11 || c5R4VNo === 11){

        if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(false)
       
        } else if (sepOn2LC5 === true && sepOnLC5 === false && sepOn3LC5 === true) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOn2LC5(false);
          setSepOnLC5(false);
          setSepOn3LC5(true)
        } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === false) {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOn2LC5(false);
          setSepOnLC5(true);
          setSepOn3LC5(false)
         } else if (sepOn2LC5 === true && sepOnLC5 === true && sepOn3LC5 === true) {
           setBaseNakedL05(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
             ]
           )
           setC5R1VNo(11);
           setC5R2VNo(11);
           setC5R3VNo(11);
           setC5R4VNo(11);
           setSepOn2LC5(false);
           setSepOnLC5(true);
           setSepOn3LC5(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col06X && curY === row01X) || (curX === col06X && curY === row02X) || (curX === col06X && curY === row03X) || (curX === col06X && curY === row04X)) {
    if (c6r1MT === "L" || c6r2MT === "L" || c6r3MT === "L" || c6r4MT === "L") {
      if (c6R1VNo === 1 || c6R2VNo === 1 || c6R3VNo === 1 || c6R4VNo === 1){
        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(1);
           setC6R2VNo(1);
           setC6R3VNo(1);
           setC6R4VNo(1);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
      } else if (c6R1VNo === 2 || c6R2VNo === 2 || c6R3VNo === 2 || c6R4VNo === 2){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(2);
          setC6R2VNo(2);
          setC6R3VNo(2);
          setC6R4VNo(2);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(2);
           setC6R2VNo(2);
           setC6R3VNo(2);
           setC6R4VNo(2);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 3 || c6R2VNo === 3 || c6R3VNo === 3 || c6R4VNo === 3){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(3);
          setC6R2VNo(3);
          setC6R3VNo(3);
          setC6R4VNo(3);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(3);
           setC6R2VNo(3);
           setC6R3VNo(3);
           setC6R4VNo(3);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 4 || c6R2VNo === 4 || c6R3VNo === 4 || c6R4VNo === 4){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(4);
           setC6R2VNo(4);
           setC6R3VNo(4);
           setC6R4VNo(4);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 5 || c6R2VNo === 5 || c6R3VNo === 5 || c6R4VNo === 5){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(5);
           setC6R2VNo(5);
           setC6R3VNo(5);
           setC6R4VNo(5);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 6 || c6R2VNo === 6 || c6R3VNo === 6 || c6R4VNo === 6){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6);
          setC6R2VNo(6);
          setC6R3VNo(6);
          setC6R4VNo(6);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(6);
           setC6R2VNo(6);
           setC6R3VNo(6);
           setC6R4VNo(6);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 7 || c6R2VNo === 7 || c6R3VNo === 7 || c6R4VNo === 7){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(7);
           setC6R2VNo(7);
           setC6R3VNo(7);
           setC6R4VNo(7);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 8 || c6R2VNo === 8 || c6R3VNo === 8 || c6R4VNo === 8){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(8);
           setC6R2VNo(8);
           setC6R3VNo(8);
           setC6R4VNo(8);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 9 || c6R2VNo === 9 || c6R3VNo === 9 || c6R4VNo === 9){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(9);
           setC6R2VNo(9);
           setC6R3VNo(9);
           setC6R4VNo(9);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 10 || c6R2VNo === 10 || c6R3VNo === 10 || c6R4VNo === 10){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(10);
           setC6R2VNo(10);
           setC6R3VNo(10);
           setC6R4VNo(10);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c6R1VNo === 11 || c6R2VNo === 11 || c6R3VNo === 11 || c6R4VNo === 11){

        if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(false)
       
        } else if (sepOn2LC6 === true && sepOnLC6 === false && sepOn3LC6 === true) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOn2LC6(false);
          setSepOnLC6(false);
          setSepOn3LC6(true)
        } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === false) {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOn2LC6(false);
          setSepOnLC6(true);
          setSepOn3LC6(false)
         } else if (sepOn2LC6 === true && sepOnLC6 === true && sepOn3LC6 === true) {
           setBaseNakedL06(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
             ]
           )
           setC6R1VNo(11);
           setC6R2VNo(11);
           setC6R3VNo(11);
           setC6R4VNo(11);
           setSepOn2LC6(false);
           setSepOnLC6(true);
           setSepOn3LC6(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col07X && curY === row01X) || (curX === col07X && curY === row02X) || (curX === col07X && curY === row03X) || (curX === col07X && curY === row04X)) {
    if (c7r1MT === "L" || c7r2MT === "L" || c7r3MT === "L" || c7r4MT === "L") {
      if (c7R1VNo === 1 || c7R2VNo === 1 || c7R3VNo === 1 || c7R4VNo === 1){
        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(1);
           setC7R2VNo(1);
           setC7R3VNo(1);
           setC7R4VNo(1);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
      } else if (c7R1VNo === 2 || c7R2VNo === 2 || c7R3VNo === 2 || c7R4VNo === 2){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(2);
          setC7R2VNo(2);
          setC7R3VNo(2);
          setC7R4VNo(2);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(2);
           setC7R2VNo(2);
           setC7R3VNo(2);
           setC7R4VNo(2);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 3 || c7R2VNo === 3 || c7R3VNo === 3 || c7R4VNo === 3){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(3);
          setC7R2VNo(3);
          setC7R3VNo(3);
          setC7R4VNo(3);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(3);
           setC7R2VNo(3);
           setC7R3VNo(3);
           setC7R4VNo(3);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 4 || c7R2VNo === 4 || c7R3VNo === 4 || c7R4VNo === 4){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(4);
           setC7R2VNo(4);
           setC7R3VNo(4);
           setC7R4VNo(4);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 5 || c7R2VNo === 5 || c7R3VNo === 5 || c7R4VNo === 5){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(5);
           setC7R2VNo(5);
           setC7R3VNo(5);
           setC7R4VNo(5);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 6 || c7R2VNo === 6 || c7R3VNo === 6 || c7R4VNo === 6){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6);
          setC7R2VNo(6);
          setC7R3VNo(6);
          setC7R4VNo(6);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(6);
           setC7R2VNo(6);
           setC7R3VNo(6);
           setC7R4VNo(6);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 7 || c7R2VNo === 7 || c7R3VNo === 7 || c7R4VNo === 7){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(7);
           setC7R2VNo(7);
           setC7R3VNo(7);
           setC7R4VNo(7);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 8 || c7R2VNo === 8 || c7R3VNo === 8 || c7R4VNo === 8){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(8);
           setC7R2VNo(8);
           setC7R3VNo(8);
           setC7R4VNo(8);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 9 || c7R2VNo === 9 || c7R3VNo === 9 || c7R4VNo === 9){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(9);
           setC7R2VNo(9);
           setC7R3VNo(9);
           setC7R4VNo(9);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 10 || c7R2VNo === 10 || c7R3VNo === 10 || c7R4VNo === 10){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(10);
           setC7R2VNo(10);
           setC7R3VNo(10);
           setC7R4VNo(10);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c7R1VNo === 11 || c7R2VNo === 11 || c7R3VNo === 11 || c7R4VNo === 11){

        if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(false)
       
        } else if (sepOn2LC7 === true && sepOnLC7 === false && sepOn3LC7 === true) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOn2LC7(false);
          setSepOnLC7(false);
          setSepOn3LC7(true)
        } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === false) {
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOn2LC7(false);
          setSepOnLC7(true);
          setSepOn3LC7(false)
         } else if (sepOn2LC7 === true && sepOnLC7 === true && sepOn3LC7 === true) {
           setBaseNakedL07(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
             ]
           )
           setC7R1VNo(11);
           setC7R2VNo(11);
           setC7R3VNo(11);
           setC7R4VNo(11);
           setSepOn2LC7(false);
           setSepOnLC7(true);
           setSepOn3LC7(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col08X && curY === row01X) || (curX === col08X && curY === row02X) || (curX === col08X && curY === row03X) || (curX === col08X && curY === row04X)) {
    if (c8r1MT === "L" || c8r2MT === "L" || c8r3MT === "L" || c8r4MT === "L") {
      if (c8R1VNo === 1 || c8R2VNo === 1 || c8R3VNo === 1 || c8R4VNo === 1){
        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(1);
           setC8R2VNo(1);
           setC8R3VNo(1);
           setC8R4VNo(1);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
      } else if (c8R1VNo === 2 || c8R2VNo === 2 || c8R3VNo === 2 || c8R4VNo === 2){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(2);
          setC8R2VNo(2);
          setC8R3VNo(2);
          setC8R4VNo(2);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(2);
           setC8R2VNo(2);
           setC8R3VNo(2);
           setC8R4VNo(2);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 3 || c8R2VNo === 3 || c8R3VNo === 3 || c8R4VNo === 3){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(3);
          setC8R2VNo(3);
          setC8R3VNo(3);
          setC8R4VNo(3);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(3);
           setC8R2VNo(3);
           setC8R3VNo(3);
           setC8R4VNo(3);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 4 || c8R2VNo === 4 || c8R3VNo === 4 || c8R4VNo === 4){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(4);
           setC8R2VNo(4);
           setC8R3VNo(4);
           setC8R4VNo(4);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 5 || c8R2VNo === 5 || c8R3VNo === 5 || c8R4VNo === 5){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(5);
           setC8R2VNo(5);
           setC8R3VNo(5);
           setC8R4VNo(5);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 6 || c8R2VNo === 6 || c8R3VNo === 6 || c8R4VNo === 6){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6);
          setC8R2VNo(6);
          setC8R3VNo(6);
          setC8R4VNo(6);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(6);
           setC8R2VNo(6);
           setC8R3VNo(6);
           setC8R4VNo(6);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 7 || c8R2VNo === 7 || c8R3VNo === 7 || c8R4VNo === 7){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(7);
           setC8R2VNo(7);
           setC8R3VNo(7);
           setC8R4VNo(7);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 8 || c8R2VNo === 8 || c8R3VNo === 8 || c8R4VNo === 8){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(8);
           setC8R2VNo(8);
           setC8R3VNo(8);
           setC8R4VNo(8);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 9 || c8R2VNo === 9 || c8R3VNo === 9 || c8R4VNo === 9){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(9);
           setC8R2VNo(9);
           setC8R3VNo(9);
           setC8R4VNo(9);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 10 || c8R2VNo === 10 || c8R3VNo === 10 || c8R4VNo === 10){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(10);
           setC8R2VNo(10);
           setC8R3VNo(10);
           setC8R4VNo(10);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c8R1VNo === 11 || c8R2VNo === 11 || c8R3VNo === 11 || c8R4VNo === 11){

        if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(false)
       
        } else if (sepOn2LC8 === true && sepOnLC8 === false && sepOn3LC8 === true) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOn2LC8(false);
          setSepOnLC8(false);
          setSepOn3LC8(true)
        } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === false) {
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOn2LC8(false);
          setSepOnLC8(true);
          setSepOn3LC8(false)
         } else if (sepOn2LC8 === true && sepOnLC8 === true && sepOn3LC8 === true) {
           setBaseNakedL08(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
             ]
           )
           setC8R1VNo(11);
           setC8R2VNo(11);
           setC8R3VNo(11);
           setC8R4VNo(11);
           setSepOn2LC8(false);
           setSepOnLC8(true);
           setSepOn3LC8(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col09X && curY === row01X) || (curX === col09X && curY === row02X) || (curX === col09X && curY === row03X) || (curX === col09X && curY === row04X)) {
    if (c9r1MT === "L" || c9r2MT === "L" || c9r3MT === "L" || c9r4MT === "L") {
      if (c9R1VNo === 1 || c9R2VNo === 1 || c9R3VNo === 1 || c9R4VNo === 1){
        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(1);
           setC9R2VNo(1);
           setC9R3VNo(1);
           setC9R4VNo(1);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
      } else if (c9R1VNo === 2 || c9R2VNo === 2 || c9R3VNo === 2 || c9R4VNo === 2){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(2);
          setC9R2VNo(2);
          setC9R3VNo(2);
          setC9R4VNo(2);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(2);
           setC9R2VNo(2);
           setC9R3VNo(2);
           setC9R4VNo(2);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 3 || c9R2VNo === 3 || c9R3VNo === 3 || c9R4VNo === 3){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(3);
          setC9R2VNo(3);
          setC9R3VNo(3);
          setC9R4VNo(3);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(3);
           setC9R2VNo(3);
           setC9R3VNo(3);
           setC9R4VNo(3);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 4 || c9R2VNo === 4 || c9R3VNo === 4 || c9R4VNo === 4){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(4);
           setC9R2VNo(4);
           setC9R3VNo(4);
           setC9R4VNo(4);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 5 || c9R2VNo === 5 || c9R3VNo === 5 || c9R4VNo === 5){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(5);
           setC9R2VNo(5);
           setC9R3VNo(5);
           setC9R4VNo(5);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 6 || c9R2VNo === 6 || c9R3VNo === 6 || c9R4VNo === 6){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6);
          setC9R2VNo(6);
          setC9R3VNo(6);
          setC9R4VNo(6);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(6);
           setC9R2VNo(6);
           setC9R3VNo(6);
           setC9R4VNo(6);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 7 || c9R2VNo === 7 || c9R3VNo === 7 || c9R4VNo === 7){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(7);
           setC9R2VNo(7);
           setC9R3VNo(7);
           setC9R4VNo(7);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 8 || c9R2VNo === 8 || c9R3VNo === 8 || c9R4VNo === 8){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(8);
           setC9R2VNo(8);
           setC9R3VNo(8);
           setC9R4VNo(8);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 9 || c9R2VNo === 9 || c9R3VNo === 9 || c9R4VNo === 9){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(9);
           setC9R2VNo(9);
           setC9R3VNo(9);
           setC9R4VNo(9);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 10 || c9R2VNo === 10 || c9R3VNo === 10 || c9R4VNo === 10){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(10);
           setC9R2VNo(10);
           setC9R3VNo(10);
           setC9R4VNo(10);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c9R1VNo === 11 || c9R2VNo === 11 || c9R3VNo === 11 || c9R4VNo === 11){

        if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(false)
       
        } else if (sepOn2LC9 === true && sepOnLC9 === false && sepOn3LC9 === true) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOn2LC9(false);
          setSepOnLC9(false);
          setSepOn3LC9(true)
        } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === false) {
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOn2LC9(false);
          setSepOnLC9(true);
          setSepOn3LC9(false)
         } else if (sepOn2LC9 === true && sepOnLC9 === true && sepOn3LC9 === true) {
           setBaseNakedL09(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
             ]
           )
           setC9R1VNo(11);
           setC9R2VNo(11);
           setC9R3VNo(11);
           setC9R4VNo(11);
           setSepOn2LC9(false);
           setSepOnLC9(true);
           setSepOn3LC9(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col10X && curY === row01X) || (curX === col10X && curY === row02X) || (curX === col10X && curY === row03X) || (curX === col10X && curY === row04X)) {
    if (c10r1MT === "L" || c10r2MT === "L" || c10r3MT === "L" || c10r4MT === "L") {
      if (c10R1VNo === 1 || c10R2VNo === 1 || c10R3VNo === 1 || c10R4VNo === 1){
        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(1);
           setC10R2VNo(1);
           setC10R3VNo(1);
           setC10R4VNo(1);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
      } else if (c10R1VNo === 2 || c10R2VNo === 2 || c10R3VNo === 2 || c10R4VNo === 2){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(2);
          setC10R2VNo(2);
          setC10R3VNo(2);
          setC10R4VNo(2);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(2);
           setC10R2VNo(2);
           setC10R3VNo(2);
           setC10R4VNo(2);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 3 || c10R2VNo === 3 || c10R3VNo === 3 || c10R4VNo === 3){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(3);
          setC10R2VNo(3);
          setC10R3VNo(3);
          setC10R4VNo(3);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(3);
           setC10R2VNo(3);
           setC10R3VNo(3);
           setC10R4VNo(3);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 4 || c10R2VNo === 4 || c10R3VNo === 4 || c10R4VNo === 4){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(4);
           setC10R2VNo(4);
           setC10R3VNo(4);
           setC10R4VNo(4);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 5 || c10R2VNo === 5 || c10R3VNo === 5 || c10R4VNo === 5){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(5);
           setC10R2VNo(5);
           setC10R3VNo(5);
           setC10R4VNo(5);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 6 || c10R2VNo === 6 || c10R3VNo === 6 || c10R4VNo === 6){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6);
          setC10R2VNo(6);
          setC10R3VNo(6);
          setC10R4VNo(6);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(6);
           setC10R2VNo(6);
           setC10R3VNo(6);
           setC10R4VNo(6);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 7 || c10R2VNo === 7 || c10R3VNo === 7 || c10R4VNo === 7){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(7);
           setC10R2VNo(7);
           setC10R3VNo(7);
           setC10R4VNo(7);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 8 || c10R2VNo === 8 || c10R3VNo === 8 || c10R4VNo === 8){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(8);
           setC10R2VNo(8);
           setC10R3VNo(8);
           setC10R4VNo(8);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 9 || c10R2VNo === 9 || c10R3VNo === 9 || c10R4VNo === 9){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(9);
           setC10R2VNo(9);
           setC10R3VNo(9);
           setC10R4VNo(9);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 10 || c10R2VNo === 10 || c10R3VNo === 10 || c10R4VNo === 10){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(10);
           setC10R2VNo(10);
           setC10R3VNo(10);
           setC10R4VNo(10);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c10R1VNo === 11 || c10R2VNo === 11 || c10R3VNo === 11 || c10R4VNo === 11){

        if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(false)
       
        } else if (sepOn2LC10 === true && sepOnLC10 === false && sepOn3LC10 === true) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOn2LC10(false);
          setSepOnLC10(false);
          setSepOn3LC10(true)
        } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === false) {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOn2LC10(false);
          setSepOnLC10(true);
          setSepOn3LC10(false)
         } else if (sepOn2LC10 === true && sepOnLC10 === true && sepOn3LC10 === true) {
           setBaseNakedL10(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
             ]
           )
           setC10R1VNo(11);
           setC10R2VNo(11);
           setC10R3VNo(11);
           setC10R4VNo(11);
           setSepOn2LC10(false);
           setSepOnLC10(true);
           setSepOn3LC10(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col11X && curY === row01X) || (curX === col11X && curY === row02X) || (curX === col11X && curY === row03X) || (curX === col11X && curY === row04X)) {
    if (c11r1MT === "L" || c11r2MT === "L" || c11r3MT === "L" || c11r4MT === "L") {
      if (c11R1VNo === 1 || c11R2VNo === 1 || c11R3VNo === 1 || c11R4VNo === 1){
        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(1);
           setC11R2VNo(1);
           setC11R3VNo(1);
           setC11R4VNo(1);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
      } else if (c11R1VNo === 2 || c11R2VNo === 2 || c11R3VNo === 2 || c11R4VNo === 2){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(2);
          setC11R2VNo(2);
          setC11R3VNo(2);
          setC11R4VNo(2);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(2);
           setC11R2VNo(2);
           setC11R3VNo(2);
           setC11R4VNo(2);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 3 || c11R2VNo === 3 || c11R3VNo === 3 || c11R4VNo === 3){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(3);
          setC11R2VNo(3);
          setC11R3VNo(3);
          setC11R4VNo(3);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(3);
           setC11R2VNo(3);
           setC11R3VNo(3);
           setC11R4VNo(3);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 4 || c11R2VNo === 4 || c11R3VNo === 4 || c11R4VNo === 4){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(4);
           setC11R2VNo(4);
           setC11R3VNo(4);
           setC11R4VNo(4);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 5 || c11R2VNo === 5 || c11R3VNo === 5 || c11R4VNo === 5){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(5);
           setC11R2VNo(5);
           setC11R3VNo(5);
           setC11R4VNo(5);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 6 || c11R2VNo === 6 || c11R3VNo === 6 || c11R4VNo === 6){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6);
          setC11R2VNo(6);
          setC11R3VNo(6);
          setC11R4VNo(6);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(6);
           setC11R2VNo(6);
           setC11R3VNo(6);
           setC11R4VNo(6);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 7 || c11R2VNo === 7 || c11R3VNo === 7 || c11R4VNo === 7){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(7);
           setC11R2VNo(7);
           setC11R3VNo(7);
           setC11R4VNo(7);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 8 || c11R2VNo === 8 || c11R3VNo === 8 || c11R4VNo === 8){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(8);
           setC11R2VNo(8);
           setC11R3VNo(8);
           setC11R4VNo(8);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 9 || c11R2VNo === 9 || c11R3VNo === 9 || c11R4VNo === 9){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(9);
           setC11R2VNo(9);
           setC11R3VNo(9);
           setC11R4VNo(9);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 10 || c11R2VNo === 10 || c11R3VNo === 10 || c11R4VNo === 10){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(10);
           setC11R2VNo(10);
           setC11R3VNo(10);
           setC11R4VNo(10);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c11R1VNo === 11 || c11R2VNo === 11 || c11R3VNo === 11 || c11R4VNo === 11){

        if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(false)
       
        } else if (sepOn2LC11 === true && sepOnLC11 === false && sepOn3LC11 === true) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOn2LC11(false);
          setSepOnLC11(false);
          setSepOn3LC11(true)
        } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === false) {
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOn2LC11(false);
          setSepOnLC11(true);
          setSepOn3LC11(false)
         } else if (sepOn2LC11 === true && sepOnLC11 === true && sepOn3LC11 === true) {
           setBaseNakedL11(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
             ]
           )
           setC11R1VNo(11);
           setC11R2VNo(11);
           setC11R3VNo(11);
           setC11R4VNo(11);
           setSepOn2LC11(false);
           setSepOnLC11(true);
           setSepOn3LC11(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col12X && curY === row01X) || (curX === col12X && curY === row02X) || (curX === col12X && curY === row03X) || (curX === col12X && curY === row04X)) {
    if (c12r1MT === "L" || c12r2MT === "L" || c12r3MT === "L" || c12r4MT === "L") {
      if (c12R1VNo === 1 || c12R2VNo === 1 || c12R3VNo === 1 || c12R4VNo === 1){
        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(1);
           setC12R2VNo(1);
           setC12R3VNo(1);
           setC12R4VNo(1);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
      } else if (c12R1VNo === 2 || c12R2VNo === 2 || c12R3VNo === 2 || c12R4VNo === 2){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(2);
          setC12R2VNo(2);
          setC12R3VNo(2);
          setC12R4VNo(2);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(2);
           setC12R2VNo(2);
           setC12R3VNo(2);
           setC12R4VNo(2);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 3 || c12R2VNo === 3 || c12R3VNo === 3 || c12R4VNo === 3){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(3);
          setC12R2VNo(3);
          setC12R3VNo(3);
          setC12R4VNo(3);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(3);
           setC12R2VNo(3);
           setC12R3VNo(3);
           setC12R4VNo(3);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 4 || c12R2VNo === 4 || c12R3VNo === 4 || c12R4VNo === 4){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(4);
           setC12R2VNo(4);
           setC12R3VNo(4);
           setC12R4VNo(4);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 5 || c12R2VNo === 5 || c12R3VNo === 5 || c12R4VNo === 5){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(5);
           setC12R2VNo(5);
           setC12R3VNo(5);
           setC12R4VNo(5);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 6 || c12R2VNo === 6 || c12R3VNo === 6 || c12R4VNo === 6){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6);
          setC12R2VNo(6);
          setC12R3VNo(6);
          setC12R4VNo(6);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(6);
           setC12R2VNo(6);
           setC12R3VNo(6);
           setC12R4VNo(6);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 7 || c12R2VNo === 7 || c12R3VNo === 7 || c12R4VNo === 7){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(7);
           setC12R2VNo(7);
           setC12R3VNo(7);
           setC12R4VNo(7);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 8 || c12R2VNo === 8 || c12R3VNo === 8 || c12R4VNo === 8){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(8);
           setC12R2VNo(8);
           setC12R3VNo(8);
           setC12R4VNo(8);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 9 || c12R2VNo === 9 || c12R3VNo === 9 || c12R4VNo === 9){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(9);
           setC12R2VNo(9);
           setC12R3VNo(9);
           setC12R4VNo(9);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 10 || c12R2VNo === 10 || c12R3VNo === 10 || c12R4VNo === 10){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(10);
           setC12R2VNo(10);
           setC12R3VNo(10);
           setC12R4VNo(10);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c12R1VNo === 11 || c12R2VNo === 11 || c12R3VNo === 11 || c12R4VNo === 11){

        if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(false)
       
        } else if (sepOn2LC12 === true && sepOnLC12 === false && sepOn3LC12 === true) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOn2LC12(false);
          setSepOnLC12(false);
          setSepOn3LC12(true)
        } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === false) {
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOn2LC12(false);
          setSepOnLC12(true);
          setSepOn3LC12(false)
         } else if (sepOn2LC12 === true && sepOnLC12 === true && sepOn3LC12 === true) {
           setBaseNakedL12(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
             ]
           )
           setC12R1VNo(11);
           setC12R2VNo(11);
           setC12R3VNo(11);
           setC12R4VNo(11);
           setSepOn2LC12(false);
           setSepOnLC12(true);
           setSepOn3LC12(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col13X && curY === row01X) || (curX === col13X && curY === row02X) || (curX === col13X && curY === row03X) || (curX === col13X && curY === row04X)) {
    if (c13r1MT === "L" || c13r2MT === "L" || c13r3MT === "L" || c13r4MT === "L") {
      if (c13R1VNo === 1 || c13R2VNo === 1 || c13R3VNo === 1 || c13R4VNo === 1){
        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(1);
           setC13R2VNo(1);
           setC13R3VNo(1);
           setC13R4VNo(1);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
      } else if (c13R1VNo === 2 || c13R2VNo === 2 || c13R3VNo === 2 || c13R4VNo === 2){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(2);
          setC13R2VNo(2);
          setC13R3VNo(2);
          setC13R4VNo(2);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(2);
           setC13R2VNo(2);
           setC13R3VNo(2);
           setC13R4VNo(2);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 3 || c13R2VNo === 3 || c13R3VNo === 3 || c13R4VNo === 3){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(3);
          setC13R2VNo(3);
          setC13R3VNo(3);
          setC13R4VNo(3);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(3);
           setC13R2VNo(3);
           setC13R3VNo(3);
           setC13R4VNo(3);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 4 || c13R2VNo === 4 || c13R3VNo === 4 || c13R4VNo === 4){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(4);
           setC13R2VNo(4);
           setC13R3VNo(4);
           setC13R4VNo(4);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 5 || c13R2VNo === 5 || c13R3VNo === 5 || c13R4VNo === 5){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(5);
           setC13R2VNo(5);
           setC13R3VNo(5);
           setC13R4VNo(5);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 6 || c13R2VNo === 6 || c13R3VNo === 6 || c13R4VNo === 6){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6);
          setC13R2VNo(6);
          setC13R3VNo(6);
          setC13R4VNo(6);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(6);
           setC13R2VNo(6);
           setC13R3VNo(6);
           setC13R4VNo(6);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 7 || c13R2VNo === 7 || c13R3VNo === 7 || c13R4VNo === 7){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(7);
           setC13R2VNo(7);
           setC13R3VNo(7);
           setC13R4VNo(7);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 8 || c13R2VNo === 8 || c13R3VNo === 8 || c13R4VNo === 8){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(8);
           setC13R2VNo(8);
           setC13R3VNo(8);
           setC13R4VNo(8);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 9 || c13R2VNo === 9 || c13R3VNo === 9 || c13R4VNo === 9){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(9);
           setC13R2VNo(9);
           setC13R3VNo(9);
           setC13R4VNo(9);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 10 || c13R2VNo === 10 || c13R3VNo === 10 || c13R4VNo === 10){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(10);
           setC13R2VNo(10);
           setC13R3VNo(10);
           setC13R4VNo(10);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c13R1VNo === 11 || c13R2VNo === 11 || c13R3VNo === 11 || c13R4VNo === 11){

        if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(false)
       
        } else if (sepOn2LC13 === true && sepOnLC13 === false && sepOn3LC13 === true) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOn2LC13(false);
          setSepOnLC13(false);
          setSepOn3LC13(true)
        } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === false) {
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOn2LC13(false);
          setSepOnLC13(true);
          setSepOn3LC13(false)
         } else if (sepOn2LC13 === true && sepOnLC13 === true && sepOn3LC13 === true) {
           setBaseNakedL13(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
             ]
           )
           setC13R1VNo(11);
           setC13R2VNo(11);
           setC13R3VNo(11);
           setC13R4VNo(11);
           setSepOn2LC13(false);
           setSepOnLC13(true);
           setSepOn3LC13(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } else if ((curX === col14X && curY === row01X) || (curX === col14X && curY === row02X) || (curX === col14X && curY === row03X) || (curX === col14X && curY === row04X)) {
    if (c14r1MT === "L" || c14r2MT === "L" || c14r3MT === "L" || c14r4MT === "L") {
      if (c14R1VNo === 1 || c14R2VNo === 1 || c14R3VNo === 1 || c14R4VNo === 1){
        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(1);
           setC14R2VNo(1);
           setC14R3VNo(1);
           setC14R4VNo(1);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
      } else if (c14R1VNo === 2 || c14R2VNo === 2 || c14R3VNo === 2 || c14R4VNo === 2){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(2);
          setC14R2VNo(2);
          setC14R3VNo(2);
          setC14R4VNo(2);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(2);
           setC14R2VNo(2);
           setC14R3VNo(2);
           setC14R4VNo(2);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 3 || c14R2VNo === 3 || c14R3VNo === 3 || c14R4VNo === 3){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(3);
          setC14R2VNo(3);
          setC14R3VNo(3);
          setC14R4VNo(3);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(3);
           setC14R2VNo(3);
           setC14R3VNo(3);
           setC14R4VNo(3);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 4 || c14R2VNo === 4 || c14R3VNo === 4 || c14R4VNo === 4){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(4);
           setC14R2VNo(4);
           setC14R3VNo(4);
           setC14R4VNo(4);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 5 || c14R2VNo === 5 || c14R3VNo === 5 || c14R4VNo === 5){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(5);
           setC14R2VNo(5);
           setC14R3VNo(5);
           setC14R4VNo(5);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 6 || c14R2VNo === 6 || c14R3VNo === 6 || c14R4VNo === 6){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6);
          setC14R2VNo(6);
          setC14R3VNo(6);
          setC14R4VNo(6);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(6);
           setC14R2VNo(6);
           setC14R3VNo(6);
           setC14R4VNo(6);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 7 || c14R2VNo === 7 || c14R3VNo === 7 || c14R4VNo === 7){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(7);
           setC14R2VNo(7);
           setC14R3VNo(7);
           setC14R4VNo(7);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 8 || c14R2VNo === 8 || c14R3VNo === 8 || c14R4VNo === 8){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(8);
           setC14R2VNo(8);
           setC14R3VNo(8);
           setC14R4VNo(8);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 9 || c14R2VNo === 9 || c14R3VNo === 9 || c14R4VNo === 9){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(9);
           setC14R2VNo(9);
           setC14R3VNo(9);
           setC14R4VNo(9);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 10 || c14R2VNo === 10 || c14R3VNo === 10 || c14R4VNo === 10){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(10);
           setC14R2VNo(10);
           setC14R3VNo(10);
           setC14R4VNo(10);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      } else if (c14R1VNo === 11 || c14R2VNo === 11 || c14R3VNo === 11 || c14R4VNo === 11){

        if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(false)
       
        } else if (sepOn2LC14 === true && sepOnLC14 === false && sepOn3LC14 === true) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOn2LC14(false);
          setSepOnLC14(false);
          setSepOn3LC14(true)
        } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === false) {
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOn2LC14(false);
          setSepOnLC14(true);
          setSepOn3LC14(false)
         } else if (sepOn2LC14 === true && sepOnLC14 === true && sepOn3LC14 === true) {
           setBaseNakedL14(
             [
               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
             ]
           )
           setC14R1VNo(11);
           setC14R2VNo(11);
           setC14R3VNo(11);
           setC14R4VNo(11);
           setSepOn2LC14(false);
           setSepOnLC14(true);
           setSepOn3LC14(true)
         } else {
           alert("Already removed.")
         }
        
      }
      
    } else {
      alert("Select Large Module & Proceed")
    }
  } 

}

  //  Top Seperator - Large only No -(2)
  const addSeperatorTop = (e)=> {
    if ((curX === col01X && curY === row01X) || (curX === col01X && curY === row02X) || (curX === col01X && curY === row03X) || (curX === col01X && curY === row04X)) {
      if (c1r1MT === "L" || c1r2MT === "L" || c1r3MT === "L" || c1r4MT === "L") {
        if (c1R1VNo === 1 || c1R2VNo === 1 || c1R3VNo === 1 || c1R4VNo === 1){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(1);
                   setC1R2VNo(1);
                   setC1R3VNo(1);
                   setC1R4VNo(1);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(1);
                   setC1R2VNo(1);
                   setC1R3VNo(1);
                   setC1R4VNo(1);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(1);
                   setC1R2VNo(1);
                   setC1R3VNo(1);
                   setC1R4VNo(1);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(1);
                    setC1R2VNo(1);
                    setC1R3VNo(1);
                    setC1R4VNo(1);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 2 || c1R2VNo === 2 || c1R3VNo === 2 || c1R4VNo === 2){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(2);
                   setC1R2VNo(2);
                   setC1R3VNo(2);
                   setC1R4VNo(2);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(2);
                   setC1R2VNo(2);
                   setC1R3VNo(2);
                   setC1R4VNo(2);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(2);
                   setC1R2VNo(2);
                   setC1R3VNo(2);
                   setC1R4VNo(2);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(2);
                    setC1R2VNo(2);
                    setC1R3VNo(2);
                    setC1R4VNo(2);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 3 || c1R2VNo === 3 || c1R3VNo === 3 || c1R4VNo === 3){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(3);
                   setC1R2VNo(3);
                   setC1R3VNo(3);
                   setC1R4VNo(3);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(3);
                   setC1R2VNo(3);
                   setC1R3VNo(3);
                   setC1R4VNo(3);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(3);
                   setC1R2VNo(3);
                   setC1R3VNo(3);
                   setC1R4VNo(3);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(3);
                    setC1R2VNo(3);
                    setC1R3VNo(3);
                    setC1R4VNo(3);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 4 || c1R2VNo === 4 || c1R3VNo === 4 || c1R4VNo === 4){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(4);
                   setC1R2VNo(4);
                   setC1R3VNo(4);
                   setC1R4VNo(4);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(4);
                   setC1R2VNo(4);
                   setC1R3VNo(4);
                   setC1R4VNo(4);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(4);
                   setC1R2VNo(4);
                   setC1R3VNo(4);
                   setC1R4VNo(4);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(4);
                    setC1R2VNo(4);
                    setC1R3VNo(4);
                    setC1R4VNo(4);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 5 || c1R2VNo === 5 || c1R3VNo === 5 || c1R4VNo === 5){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(5);
                   setC1R2VNo(5);
                   setC1R3VNo(5);
                   setC1R4VNo(5);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(5);
                   setC1R2VNo(5);
                   setC1R3VNo(5);
                   setC1R4VNo(5);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(5);
                   setC1R2VNo(5);
                   setC1R3VNo(5);
                   setC1R4VNo(5);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(5);
                    setC1R2VNo(5);
                    setC1R3VNo(5);
                    setC1R4VNo(5);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 6 || c1R2VNo === 6 || c1R3VNo === 6 || c1R4VNo === 6){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(6);
                   setC1R2VNo(6);
                   setC1R3VNo(6);
                   setC1R4VNo(6);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(6);
                   setC1R2VNo(6);
                   setC1R3VNo(6);
                   setC1R4VNo(6);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(6);
                   setC1R2VNo(6);
                   setC1R3VNo(6);
                   setC1R4VNo(6);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(6);
                    setC1R2VNo(6);
                    setC1R3VNo(6);
                    setC1R4VNo(6);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 7 || c1R2VNo === 7 || c1R3VNo === 7 || c1R4VNo === 7){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(7);
                   setC1R2VNo(7);
                   setC1R3VNo(7);
                   setC1R4VNo(7);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(7);
                   setC1R2VNo(7);
                   setC1R3VNo(7);
                   setC1R4VNo(7);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(7);
                   setC1R2VNo(7);
                   setC1R3VNo(7);
                   setC1R4VNo(7);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(7);
                    setC1R2VNo(7);
                    setC1R3VNo(7);
                    setC1R4VNo(7);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 8 || c1R2VNo === 8 || c1R3VNo === 8 || c1R4VNo === 8){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(8);
                   setC1R2VNo(8);
                   setC1R3VNo(8);
                   setC1R4VNo(8);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(8);
                   setC1R2VNo(8);
                   setC1R3VNo(8);
                   setC1R4VNo(8);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(8);
                   setC1R2VNo(8);
                   setC1R3VNo(8);
                   setC1R4VNo(8);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(8);
                    setC1R2VNo(8);
                    setC1R3VNo(8);
                    setC1R4VNo(8);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 9 || c1R2VNo === 9 || c1R3VNo === 9 || c1R4VNo === 9){
          if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(9);
                   setC1R2VNo(9);
                   setC1R3VNo(9);
                   setC1R4VNo(9);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                
                 } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(9);
                   setC1R2VNo(9);
                   setC1R3VNo(9);
                   setC1R4VNo(9);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(true)
                 } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(9);
                   setC1R2VNo(9);
                   setC1R3VNo(9);
                   setC1R4VNo(9);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(true)
                  } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(9);
                    setC1R2VNo(9);
                    setC1R3VNo(9);
                    setC1R4VNo(9);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c1R1VNo === 10 || c1R2VNo === 10 || c1R3VNo === 10 || c1R4VNo === 10){

         if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
          setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(10);
                 setC1R2VNo(10);
                 setC1R3VNo(10);
                 setC1R4VNo(10);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(true)
              
               } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(10);
                 setC1R2VNo(10);
                 setC1R3VNo(10);
                 setC1R4VNo(10);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(true)
               } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(10);
                 setC1R2VNo(10);
                 setC1R3VNo(10);
                 setC1R4VNo(10);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(true)
                } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(10);
                  setC1R2VNo(10);
                  setC1R3VNo(10);
                  setC1R4VNo(10);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(true)
                } else {
                  alert("Already added")
                } 
                
              } else if (c1R1VNo === 11 || c1R2VNo === 11 || c1R3VNo === 11 || c1R4VNo === 11){
                  if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === false) {
       
                    setBaseNakedL01(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                             ]
                           )
                           setC1R1VNo(11);
                           setC1R2VNo(11);
                           setC1R3VNo(11);
                           setC1R4VNo(11);
                           setSepOn2LC1(false);
                           setSepOnLC1(false);
                           setSepOn3LC1(true)
                        
                         } else if (sepOn3LC1 === false && sepOnLC1 === false && sepOn2LC1 === true) {
                           setBaseNakedL01(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                             ]
                           )
                           setC1R1VNo(11);
                           setC1R2VNo(11);
                           setC1R3VNo(11);
                           setC1R4VNo(11);
                           setSepOn2LC1(true);
                           setSepOnLC1(false);
                           setSepOn3LC1(true)
                         } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === false) {
                           setBaseNakedL01(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                             ]
                           )
                           setC1R1VNo(11);
                           setC1R2VNo(11);
                           setC1R3VNo(11);
                           setC1R4VNo(11);
                           setSepOn2LC1(false);
                           setSepOnLC1(true);
                           setSepOn3LC1(true)
                          } else if (sepOn3LC1 === false && sepOnLC1 === true && sepOn2LC1 === true) {
                            setBaseNakedL01(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(11);
                            setC1R2VNo(11);
                            setC1R3VNo(11);
                            setC1R4VNo(11);
                            setSepOn2LC1(true);
                            setSepOnLC1(true);
                            setSepOn3LC1(true)
                          } else {
                            alert("Already added")
                          }
        } 
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col02X && curY === row01X) || (curX === col02X && curY === row02X) || (curX === col02X && curY === row03X) || (curX === col02X && curY === row04X)) {
      if (c2r1MT === "L" || c2r2MT === "L" || c2r3MT === "L" || c2r4MT === "L") {
        if (c2R1VNo === 1 || c2R2VNo === 1 || c2R3VNo === 1 || c2R4VNo === 1){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(1);
                   setC2R2VNo(1);
                   setC2R3VNo(1);
                   setC2R4VNo(1);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(1);
                   setC2R2VNo(1);
                   setC2R3VNo(1);
                   setC2R4VNo(1);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(1);
                   setC2R2VNo(1);
                   setC2R3VNo(1);
                   setC2R4VNo(1);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(1);
                    setC2R2VNo(1);
                    setC2R3VNo(1);
                    setC2R4VNo(1);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 2 || c2R2VNo === 2 || c2R3VNo === 2 || c2R4VNo === 2){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(2);
                   setC2R2VNo(2);
                   setC2R3VNo(2);
                   setC2R4VNo(2);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(2);
                   setC2R2VNo(2);
                   setC2R3VNo(2);
                   setC2R4VNo(2);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(2);
                   setC2R2VNo(2);
                   setC2R3VNo(2);
                   setC2R4VNo(2);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(2);
                    setC2R2VNo(2);
                    setC2R3VNo(2);
                    setC2R4VNo(2);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 3 || c2R2VNo === 3 || c2R3VNo === 3 || c2R4VNo === 3){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(3);
                   setC2R2VNo(3);
                   setC2R3VNo(3);
                   setC2R4VNo(3);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(3);
                   setC2R2VNo(3);
                   setC2R3VNo(3);
                   setC2R4VNo(3);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(3);
                   setC2R2VNo(3);
                   setC2R3VNo(3);
                   setC2R4VNo(3);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(3);
                    setC2R2VNo(3);
                    setC2R3VNo(3);
                    setC2R4VNo(3);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 4 || c2R2VNo === 4 || c2R3VNo === 4 || c2R4VNo === 4){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(4);
                   setC2R2VNo(4);
                   setC2R3VNo(4);
                   setC2R4VNo(4);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(4);
                   setC2R2VNo(4);
                   setC2R3VNo(4);
                   setC2R4VNo(4);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(4);
                   setC2R2VNo(4);
                   setC2R3VNo(4);
                   setC2R4VNo(4);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(4);
                    setC2R2VNo(4);
                    setC2R3VNo(4);
                    setC2R4VNo(4);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 5 || c2R2VNo === 5 || c2R3VNo === 5 || c2R4VNo === 5){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(5);
                   setC2R2VNo(5);
                   setC2R3VNo(5);
                   setC2R4VNo(5);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(5);
                   setC2R2VNo(5);
                   setC2R3VNo(5);
                   setC2R4VNo(5);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(5);
                   setC2R2VNo(5);
                   setC2R3VNo(5);
                   setC2R4VNo(5);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(5);
                    setC2R2VNo(5);
                    setC2R3VNo(5);
                    setC2R4VNo(5);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 6 || c2R2VNo === 6 || c2R3VNo === 6 || c2R4VNo === 6){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(6);
                   setC2R2VNo(6);
                   setC2R3VNo(6);
                   setC2R4VNo(6);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(6);
                   setC2R2VNo(6);
                   setC2R3VNo(6);
                   setC2R4VNo(6);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(6);
                   setC2R2VNo(6);
                   setC2R3VNo(6);
                   setC2R4VNo(6);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(6);
                    setC2R2VNo(6);
                    setC2R3VNo(6);
                    setC2R4VNo(6);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 7 || c2R2VNo === 7 || c2R3VNo === 7 || c2R4VNo === 7){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(7);
                   setC2R2VNo(7);
                   setC2R3VNo(7);
                   setC2R4VNo(7);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(7);
                   setC2R2VNo(7);
                   setC2R3VNo(7);
                   setC2R4VNo(7);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(7);
                   setC2R2VNo(7);
                   setC2R3VNo(7);
                   setC2R4VNo(7);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(7);
                    setC2R2VNo(7);
                    setC2R3VNo(7);
                    setC2R4VNo(7);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 8 || c2R2VNo === 8 || c2R3VNo === 8 || c2R4VNo === 8){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(8);
                   setC2R2VNo(8);
                   setC2R3VNo(8);
                   setC2R4VNo(8);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(8);
                   setC2R2VNo(8);
                   setC2R3VNo(8);
                   setC2R4VNo(8);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(8);
                   setC2R2VNo(8);
                   setC2R3VNo(8);
                   setC2R4VNo(8);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(8);
                    setC2R2VNo(8);
                    setC2R3VNo(8);
                    setC2R4VNo(8);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 9 || c2R2VNo === 9 || c2R3VNo === 9 || c2R4VNo === 9){
          if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(9);
                   setC2R2VNo(9);
                   setC2R3VNo(9);
                   setC2R4VNo(9);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                
                 } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(9);
                   setC2R2VNo(9);
                   setC2R3VNo(9);
                   setC2R4VNo(9);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(true)
                 } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(9);
                   setC2R2VNo(9);
                   setC2R3VNo(9);
                   setC2R4VNo(9);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(true)
                  } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(9);
                    setC2R2VNo(9);
                    setC2R3VNo(9);
                    setC2R4VNo(9);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c2R1VNo === 10 || c2R2VNo === 10 || c2R3VNo === 10 || c2R4VNo === 10){

        if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
          setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(10);
                 setC2R2VNo(10);
                 setC2R3VNo(10);
                 setC2R4VNo(10);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(true)
              
               } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(10);
                 setC2R2VNo(10);
                 setC2R3VNo(10);
                 setC2R4VNo(10);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(true)
               } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(10);
                 setC2R2VNo(10);
                 setC2R3VNo(10);
                 setC2R4VNo(10);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(true)
                } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(10);
                  setC2R2VNo(10);
                  setC2R3VNo(10);
                  setC2R4VNo(10);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(true)
                } else {
                  alert("Already added")
                }
		} else if (c2R1VNo === 11 || c2R2VNo === 11 || c2R3VNo === 11 || c2R4VNo === 11){
                  if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === false) {
       
                    setBaseNakedL02(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                             ]
                           )
                           setC2R1VNo(11);
                           setC2R2VNo(11);
                           setC2R3VNo(11);
                           setC2R4VNo(11);
                           setSepOn2LC2(false);
                           setSepOnLC2(false);
                           setSepOn3LC2(true)
                        
                         } else if (sepOn3LC2 === false && sepOnLC2 === false && sepOn2LC2 === true) {
                           setBaseNakedL02(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                             ]
                           )
                           setC2R1VNo(11);
                           setC2R2VNo(11);
                           setC2R3VNo(11);
                           setC2R4VNo(11);
                           setSepOn2LC2(true);
                           setSepOnLC2(false);
                           setSepOn3LC2(true)
                         } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === false) {
                           setBaseNakedL02(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                             ]
                           )
                           setC2R1VNo(11);
                           setC2R2VNo(11);
                           setC2R3VNo(11);
                           setC2R4VNo(11);
                           setSepOn2LC2(false);
                           setSepOnLC2(true);
                           setSepOn3LC2(true)
                          } else if (sepOn3LC2 === false && sepOnLC2 === true && sepOn2LC2 === true) {
                            setBaseNakedL02(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(11);
                            setC2R2VNo(11);
                            setC2R3VNo(11);
                            setC2R4VNo(11);
                            setSepOn2LC2(true);
                            setSepOnLC2(true);
                            setSepOn3LC2(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col03X && curY === row01X) || (curX === col03X && curY === row02X) || (curX === col03X && curY === row03X) || (curX === col03X && curY === row04X)) {
      if (c3r1MT === "L" || c3r2MT === "L" || c3r3MT === "L" || c3r4MT === "L") {
        if (c3R1VNo === 1 || c3R2VNo === 1 || c3R3VNo === 1 || c3R4VNo === 1){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(1);
                   setC3R2VNo(1);
                   setC3R3VNo(1);
                   setC3R4VNo(1);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(1);
                   setC3R2VNo(1);
                   setC3R3VNo(1);
                   setC3R4VNo(1);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(1);
                   setC3R2VNo(1);
                   setC3R3VNo(1);
                   setC3R4VNo(1);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(1);
                    setC3R2VNo(1);
                    setC3R3VNo(1);
                    setC3R4VNo(1);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 2 || c3R2VNo === 2 || c3R3VNo === 2 || c3R4VNo === 2){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(2);
                   setC3R2VNo(2);
                   setC3R3VNo(2);
                   setC3R4VNo(2);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(2);
                   setC3R2VNo(2);
                   setC3R3VNo(2);
                   setC3R4VNo(2);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(2);
                   setC3R2VNo(2);
                   setC3R3VNo(2);
                   setC3R4VNo(2);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(2);
                    setC3R2VNo(2);
                    setC3R3VNo(2);
                    setC3R4VNo(2);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 3 || c3R2VNo === 3 || c3R3VNo === 3 || c3R4VNo === 3){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(3);
                   setC3R2VNo(3);
                   setC3R3VNo(3);
                   setC3R4VNo(3);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(3);
                   setC3R2VNo(3);
                   setC3R3VNo(3);
                   setC3R4VNo(3);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(3);
                   setC3R2VNo(3);
                   setC3R3VNo(3);
                   setC3R4VNo(3);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(3);
                    setC3R2VNo(3);
                    setC3R3VNo(3);
                    setC3R4VNo(3);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 4 || c3R2VNo === 4 || c3R3VNo === 4 || c3R4VNo === 4){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(4);
                   setC3R2VNo(4);
                   setC3R3VNo(4);
                   setC3R4VNo(4);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(4);
                   setC3R2VNo(4);
                   setC3R3VNo(4);
                   setC3R4VNo(4);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(4);
                   setC3R2VNo(4);
                   setC3R3VNo(4);
                   setC3R4VNo(4);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(4);
                    setC3R2VNo(4);
                    setC3R3VNo(4);
                    setC3R4VNo(4);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 5 || c3R2VNo === 5 || c3R3VNo === 5 || c3R4VNo === 5){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(5);
                   setC3R2VNo(5);
                   setC3R3VNo(5);
                   setC3R4VNo(5);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(5);
                   setC3R2VNo(5);
                   setC3R3VNo(5);
                   setC3R4VNo(5);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(5);
                   setC3R2VNo(5);
                   setC3R3VNo(5);
                   setC3R4VNo(5);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(5);
                    setC3R2VNo(5);
                    setC3R3VNo(5);
                    setC3R4VNo(5);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 6 || c3R2VNo === 6 || c3R3VNo === 6 || c3R4VNo === 6){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(6);
                   setC3R2VNo(6);
                   setC3R3VNo(6);
                   setC3R4VNo(6);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(6);
                   setC3R2VNo(6);
                   setC3R3VNo(6);
                   setC3R4VNo(6);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(6);
                   setC3R2VNo(6);
                   setC3R3VNo(6);
                   setC3R4VNo(6);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(6);
                    setC3R2VNo(6);
                    setC3R3VNo(6);
                    setC3R4VNo(6);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 7 || c3R2VNo === 7 || c3R3VNo === 7 || c3R4VNo === 7){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(7);
                   setC3R2VNo(7);
                   setC3R3VNo(7);
                   setC3R4VNo(7);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(7);
                   setC3R2VNo(7);
                   setC3R3VNo(7);
                   setC3R4VNo(7);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(7);
                   setC3R2VNo(7);
                   setC3R3VNo(7);
                   setC3R4VNo(7);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(7);
                    setC3R2VNo(7);
                    setC3R3VNo(7);
                    setC3R4VNo(7);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 8 || c3R2VNo === 8 || c3R3VNo === 8 || c3R4VNo === 8){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(8);
                   setC3R2VNo(8);
                   setC3R3VNo(8);
                   setC3R4VNo(8);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(8);
                   setC3R2VNo(8);
                   setC3R3VNo(8);
                   setC3R4VNo(8);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(8);
                   setC3R2VNo(8);
                   setC3R3VNo(8);
                   setC3R4VNo(8);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(8);
                    setC3R2VNo(8);
                    setC3R3VNo(8);
                    setC3R4VNo(8);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 9 || c3R2VNo === 9 || c3R3VNo === 9 || c3R4VNo === 9){
          if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(9);
                   setC3R2VNo(9);
                   setC3R3VNo(9);
                   setC3R4VNo(9);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                
                 } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(9);
                   setC3R2VNo(9);
                   setC3R3VNo(9);
                   setC3R4VNo(9);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(true)
                 } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(9);
                   setC3R2VNo(9);
                   setC3R3VNo(9);
                   setC3R4VNo(9);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(true)
                  } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(9);
                    setC3R2VNo(9);
                    setC3R3VNo(9);
                    setC3R4VNo(9);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c3R1VNo === 10 || c3R2VNo === 10 || c3R3VNo === 10 || c3R4VNo === 10){

        if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
          setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(10);
                 setC3R2VNo(10);
                 setC3R3VNo(10);
                 setC3R4VNo(10);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(true)
              
               } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(10);
                 setC3R2VNo(10);
                 setC3R3VNo(10);
                 setC3R4VNo(10);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(true)
               } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(10);
                 setC3R2VNo(10);
                 setC3R3VNo(10);
                 setC3R4VNo(10);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(true)
                } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(10);
                  setC3R2VNo(10);
                  setC3R3VNo(10);
                  setC3R4VNo(10);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(true)
                } else {
                  alert("Already added")
                }
		} else if (c3R1VNo === 11 || c3R2VNo === 11 || c3R3VNo === 11 || c3R4VNo === 11){
                  if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === false) {
       
                    setBaseNakedL03(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                             ]
                           )
                           setC3R1VNo(11);
                           setC3R2VNo(11);
                           setC3R3VNo(11);
                           setC3R4VNo(11);
                           setSepOn2LC3(false);
                           setSepOnLC3(false);
                           setSepOn3LC3(true)
                        
                         } else if (sepOn3LC3 === false && sepOnLC3 === false && sepOn2LC3 === true) {
                           setBaseNakedL03(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                             ]
                           )
                           setC3R1VNo(11);
                           setC3R2VNo(11);
                           setC3R3VNo(11);
                           setC3R4VNo(11);
                           setSepOn2LC3(true);
                           setSepOnLC3(false);
                           setSepOn3LC3(true)
                         } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === false) {
                           setBaseNakedL03(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                             ]
                           )
                           setC3R1VNo(11);
                           setC3R2VNo(11);
                           setC3R3VNo(11);
                           setC3R4VNo(11);
                           setSepOn2LC3(false);
                           setSepOnLC3(true);
                           setSepOn3LC3(true)
                          } else if (sepOn3LC3 === false && sepOnLC3 === true && sepOn2LC3 === true) {
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(11);
                            setC3R2VNo(11);
                            setC3R3VNo(11);
                            setC3R4VNo(11);
                            setSepOn2LC3(true);
                            setSepOnLC3(true);
                            setSepOn3LC3(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col04X && curY === row01X) || (curX === col04X && curY === row02X) || (curX === col04X && curY === row03X) || (curX === col04X && curY === row04X)) {
      if (c4r1MT === "L" || c4r2MT === "L" || c4r3MT === "L" || c4r4MT === "L") {
        if (c4R1VNo === 1 || c4R2VNo === 1 || c4R3VNo === 1 || c4R4VNo === 1){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(1);
                   setC4R2VNo(1);
                   setC4R3VNo(1);
                   setC4R4VNo(1);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(1);
                   setC4R2VNo(1);
                   setC4R3VNo(1);
                   setC4R4VNo(1);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(1);
                   setC4R2VNo(1);
                   setC4R3VNo(1);
                   setC4R4VNo(1);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(1);
                    setC4R2VNo(1);
                    setC4R3VNo(1);
                    setC4R4VNo(1);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 2 || c4R2VNo === 2 || c4R3VNo === 2 || c4R4VNo === 2){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(2);
                   setC4R2VNo(2);
                   setC4R3VNo(2);
                   setC4R4VNo(2);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(2);
                   setC4R2VNo(2);
                   setC4R3VNo(2);
                   setC4R4VNo(2);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(2);
                   setC4R2VNo(2);
                   setC4R3VNo(2);
                   setC4R4VNo(2);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(2);
                    setC4R2VNo(2);
                    setC4R3VNo(2);
                    setC4R4VNo(2);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 3 || c4R2VNo === 3 || c4R3VNo === 3 || c4R4VNo === 3){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(3);
                   setC4R2VNo(3);
                   setC4R3VNo(3);
                   setC4R4VNo(3);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(3);
                   setC4R2VNo(3);
                   setC4R3VNo(3);
                   setC4R4VNo(3);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(3);
                   setC4R2VNo(3);
                   setC4R3VNo(3);
                   setC4R4VNo(3);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(3);
                    setC4R2VNo(3);
                    setC4R3VNo(3);
                    setC4R4VNo(3);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 4 || c4R2VNo === 4 || c4R3VNo === 4 || c4R4VNo === 4){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(4);
                   setC4R2VNo(4);
                   setC4R3VNo(4);
                   setC4R4VNo(4);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(4);
                   setC4R2VNo(4);
                   setC4R3VNo(4);
                   setC4R4VNo(4);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(4);
                   setC4R2VNo(4);
                   setC4R3VNo(4);
                   setC4R4VNo(4);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(4);
                    setC4R2VNo(4);
                    setC4R3VNo(4);
                    setC4R4VNo(4);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 5 || c4R2VNo === 5 || c4R3VNo === 5 || c4R4VNo === 5){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(5);
                   setC4R2VNo(5);
                   setC4R3VNo(5);
                   setC4R4VNo(5);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(5);
                   setC4R2VNo(5);
                   setC4R3VNo(5);
                   setC4R4VNo(5);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(5);
                   setC4R2VNo(5);
                   setC4R3VNo(5);
                   setC4R4VNo(5);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(5);
                    setC4R2VNo(5);
                    setC4R3VNo(5);
                    setC4R4VNo(5);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 6 || c4R2VNo === 6 || c4R3VNo === 6 || c4R4VNo === 6){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(6);
                   setC4R2VNo(6);
                   setC4R3VNo(6);
                   setC4R4VNo(6);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(6);
                   setC4R2VNo(6);
                   setC4R3VNo(6);
                   setC4R4VNo(6);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(6);
                   setC4R2VNo(6);
                   setC4R3VNo(6);
                   setC4R4VNo(6);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(6);
                    setC4R2VNo(6);
                    setC4R3VNo(6);
                    setC4R4VNo(6);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 7 || c4R2VNo === 7 || c4R3VNo === 7 || c4R4VNo === 7){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(7);
                   setC4R2VNo(7);
                   setC4R3VNo(7);
                   setC4R4VNo(7);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(7);
                   setC4R2VNo(7);
                   setC4R3VNo(7);
                   setC4R4VNo(7);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(7);
                   setC4R2VNo(7);
                   setC4R3VNo(7);
                   setC4R4VNo(7);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(7);
                    setC4R2VNo(7);
                    setC4R3VNo(7);
                    setC4R4VNo(7);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 8 || c4R2VNo === 8 || c4R3VNo === 8 || c4R4VNo === 8){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(8);
                   setC4R2VNo(8);
                   setC4R3VNo(8);
                   setC4R4VNo(8);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(8);
                   setC4R2VNo(8);
                   setC4R3VNo(8);
                   setC4R4VNo(8);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(8);
                   setC4R2VNo(8);
                   setC4R3VNo(8);
                   setC4R4VNo(8);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(8);
                    setC4R2VNo(8);
                    setC4R3VNo(8);
                    setC4R4VNo(8);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 9 || c4R2VNo === 9 || c4R3VNo === 9 || c4R4VNo === 9){
          if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(9);
                   setC4R2VNo(9);
                   setC4R3VNo(9);
                   setC4R4VNo(9);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                
                 } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(9);
                   setC4R2VNo(9);
                   setC4R3VNo(9);
                   setC4R4VNo(9);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(true)
                 } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(9);
                   setC4R2VNo(9);
                   setC4R3VNo(9);
                   setC4R4VNo(9);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(true)
                  } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(9);
                    setC4R2VNo(9);
                    setC4R3VNo(9);
                    setC4R4VNo(9);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c4R1VNo === 10 || c4R2VNo === 10 || c4R3VNo === 10 || c4R4VNo === 10){

        if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
          setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(10);
                 setC4R2VNo(10);
                 setC4R3VNo(10);
                 setC4R4VNo(10);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(true)
              
               } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(10);
                 setC4R2VNo(10);
                 setC4R3VNo(10);
                 setC4R4VNo(10);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(true)
               } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(10);
                 setC4R2VNo(10);
                 setC4R3VNo(10);
                 setC4R4VNo(10);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(true)
                } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(10);
                  setC4R2VNo(10);
                  setC4R3VNo(10);
                  setC4R4VNo(10);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(true)
                } else {
                  alert("Already added")
                }
		} else if (c4R1VNo === 11 || c4R2VNo === 11 || c4R3VNo === 11 || c4R4VNo === 11){
                  if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === false) {
       
                    setBaseNakedL04(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                             ]
                           )
                           setC4R1VNo(11);
                           setC4R2VNo(11);
                           setC4R3VNo(11);
                           setC4R4VNo(11);
                           setSepOn2LC4(false);
                           setSepOnLC4(false);
                           setSepOn3LC4(true)
                        
                         } else if (sepOn3LC4 === false && sepOnLC4 === false && sepOn2LC4 === true) {
                           setBaseNakedL04(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                             ]
                           )
                           setC4R1VNo(11);
                           setC4R2VNo(11);
                           setC4R3VNo(11);
                           setC4R4VNo(11);
                           setSepOn2LC4(true);
                           setSepOnLC4(false);
                           setSepOn3LC4(true)
                         } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === false) {
                           setBaseNakedL04(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                             ]
                           )
                           setC4R1VNo(11);
                           setC4R2VNo(11);
                           setC4R3VNo(11);
                           setC4R4VNo(11);
                           setSepOn2LC4(false);
                           setSepOnLC4(true);
                           setSepOn3LC4(true)
                          } else if (sepOn3LC4 === false && sepOnLC4 === true && sepOn2LC4 === true) {
                            setBaseNakedL04(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                              ]
                            )
                            setC4R1VNo(11);
                            setC4R2VNo(11);
                            setC4R3VNo(11);
                            setC4R4VNo(11);
                            setSepOn2LC4(true);
                            setSepOnLC4(true);
                            setSepOn3LC4(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col05X && curY === row01X) || (curX === col05X && curY === row02X) || (curX === col05X && curY === row03X) || (curX === col05X && curY === row04X)) {
      if (c5r1MT === "L" || c5r2MT === "L" || c5r3MT === "L" || c5r4MT === "L") {
        if (c5R1VNo === 1 || c5R2VNo === 1 || c5R3VNo === 1 || c5R4VNo === 1){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(1);
                   setC5R2VNo(1);
                   setC5R3VNo(1);
                   setC5R4VNo(1);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(1);
                   setC5R2VNo(1);
                   setC5R3VNo(1);
                   setC5R4VNo(1);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(1);
                   setC5R2VNo(1);
                   setC5R3VNo(1);
                   setC5R4VNo(1);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(1);
                    setC5R2VNo(1);
                    setC5R3VNo(1);
                    setC5R4VNo(1);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 2 || c5R2VNo === 2 || c5R3VNo === 2 || c5R4VNo === 2){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(2);
                   setC5R2VNo(2);
                   setC5R3VNo(2);
                   setC5R4VNo(2);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(2);
                   setC5R2VNo(2);
                   setC5R3VNo(2);
                   setC5R4VNo(2);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(2);
                   setC5R2VNo(2);
                   setC5R3VNo(2);
                   setC5R4VNo(2);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(2);
                    setC5R2VNo(2);
                    setC5R3VNo(2);
                    setC5R4VNo(2);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 3 || c5R2VNo === 3 || c5R3VNo === 3 || c5R4VNo === 3){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(3);
                   setC5R2VNo(3);
                   setC5R3VNo(3);
                   setC5R4VNo(3);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(3);
                   setC5R2VNo(3);
                   setC5R3VNo(3);
                   setC5R4VNo(3);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(3);
                   setC5R2VNo(3);
                   setC5R3VNo(3);
                   setC5R4VNo(3);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(3);
                    setC5R2VNo(3);
                    setC5R3VNo(3);
                    setC5R4VNo(3);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 4 || c5R2VNo === 4 || c5R3VNo === 4 || c5R4VNo === 4){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(4);
                   setC5R2VNo(4);
                   setC5R3VNo(4);
                   setC5R4VNo(4);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(4);
                   setC5R2VNo(4);
                   setC5R3VNo(4);
                   setC5R4VNo(4);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(4);
                   setC5R2VNo(4);
                   setC5R3VNo(4);
                   setC5R4VNo(4);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(4);
                    setC5R2VNo(4);
                    setC5R3VNo(4);
                    setC5R4VNo(4);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 5 || c5R2VNo === 5 || c5R3VNo === 5 || c5R4VNo === 5){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(5);
                   setC5R2VNo(5);
                   setC5R3VNo(5);
                   setC5R4VNo(5);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(5);
                   setC5R2VNo(5);
                   setC5R3VNo(5);
                   setC5R4VNo(5);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(5);
                   setC5R2VNo(5);
                   setC5R3VNo(5);
                   setC5R4VNo(5);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(5);
                    setC5R2VNo(5);
                    setC5R3VNo(5);
                    setC5R4VNo(5);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 6 || c5R2VNo === 6 || c5R3VNo === 6 || c5R4VNo === 6){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(6);
                   setC5R2VNo(6);
                   setC5R3VNo(6);
                   setC5R4VNo(6);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(6);
                   setC5R2VNo(6);
                   setC5R3VNo(6);
                   setC5R4VNo(6);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(6);
                   setC5R2VNo(6);
                   setC5R3VNo(6);
                   setC5R4VNo(6);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(6);
                    setC5R2VNo(6);
                    setC5R3VNo(6);
                    setC5R4VNo(6);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 7 || c5R2VNo === 7 || c5R3VNo === 7 || c5R4VNo === 7){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(7);
                   setC5R2VNo(7);
                   setC5R3VNo(7);
                   setC5R4VNo(7);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(7);
                   setC5R2VNo(7);
                   setC5R3VNo(7);
                   setC5R4VNo(7);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(7);
                   setC5R2VNo(7);
                   setC5R3VNo(7);
                   setC5R4VNo(7);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(7);
                    setC5R2VNo(7);
                    setC5R3VNo(7);
                    setC5R4VNo(7);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 8 || c5R2VNo === 8 || c5R3VNo === 8 || c5R4VNo === 8){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(8);
                   setC5R2VNo(8);
                   setC5R3VNo(8);
                   setC5R4VNo(8);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(8);
                   setC5R2VNo(8);
                   setC5R3VNo(8);
                   setC5R4VNo(8);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(8);
                   setC5R2VNo(8);
                   setC5R3VNo(8);
                   setC5R4VNo(8);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(8);
                    setC5R2VNo(8);
                    setC5R3VNo(8);
                    setC5R4VNo(8);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 9 || c5R2VNo === 9 || c5R3VNo === 9 || c5R4VNo === 9){
          if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(9);
                   setC5R2VNo(9);
                   setC5R3VNo(9);
                   setC5R4VNo(9);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                
                 } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(9);
                   setC5R2VNo(9);
                   setC5R3VNo(9);
                   setC5R4VNo(9);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(true)
                 } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(9);
                   setC5R2VNo(9);
                   setC5R3VNo(9);
                   setC5R4VNo(9);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(true)
                  } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(9);
                    setC5R2VNo(9);
                    setC5R3VNo(9);
                    setC5R4VNo(9);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c5R1VNo === 10 || c5R2VNo === 10 || c5R3VNo === 10 || c5R4VNo === 10){

        if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
          setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(10);
                 setC5R2VNo(10);
                 setC5R3VNo(10);
                 setC5R4VNo(10);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(true)
              
               } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(10);
                 setC5R2VNo(10);
                 setC5R3VNo(10);
                 setC5R4VNo(10);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(true)
               } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(10);
                 setC5R2VNo(10);
                 setC5R3VNo(10);
                 setC5R4VNo(10);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(true)
                } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(10);
                  setC5R2VNo(10);
                  setC5R3VNo(10);
                  setC5R4VNo(10);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(true)
                } else {
                  alert("Already added")
                }
		} else if (c5R1VNo === 11 || c5R2VNo === 11 || c5R3VNo === 11 || c5R4VNo === 11){
                  if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === false) {
       
                    setBaseNakedL05(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                             ]
                           )
                           setC5R1VNo(11);
                           setC5R2VNo(11);
                           setC5R3VNo(11);
                           setC5R4VNo(11);
                           setSepOn2LC5(false);
                           setSepOnLC5(false);
                           setSepOn3LC5(true)
                        
                         } else if (sepOn3LC5 === false && sepOnLC5 === false && sepOn2LC5 === true) {
                           setBaseNakedL05(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                             ]
                           )
                           setC5R1VNo(11);
                           setC5R2VNo(11);
                           setC5R3VNo(11);
                           setC5R4VNo(11);
                           setSepOn2LC5(true);
                           setSepOnLC5(false);
                           setSepOn3LC5(true)
                         } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === false) {
                           setBaseNakedL05(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                             ]
                           )
                           setC5R1VNo(11);
                           setC5R2VNo(11);
                           setC5R3VNo(11);
                           setC5R4VNo(11);
                           setSepOn2LC5(false);
                           setSepOnLC5(true);
                           setSepOn3LC5(true)
                          } else if (sepOn3LC5 === false && sepOnLC5 === true && sepOn2LC5 === true) {
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                            setC5R1VNo(11);
                            setC5R2VNo(11);
                            setC5R3VNo(11);
                            setC5R4VNo(11);
                            setSepOn2LC5(true);
                            setSepOnLC5(true);
                            setSepOn3LC5(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col06X && curY === row01X) || (curX === col06X && curY === row02X) || (curX === col06X && curY === row03X) || (curX === col06X && curY === row04X)) {
      if (c6r1MT === "L" || c6r2MT === "L" || c6r3MT === "L" || c6r4MT === "L") {
        if (c6R1VNo === 1 || c6R2VNo === 1 || c6R3VNo === 1 || c6R4VNo === 1){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(1);
                   setC6R2VNo(1);
                   setC6R3VNo(1);
                   setC6R4VNo(1);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(1);
                   setC6R2VNo(1);
                   setC6R3VNo(1);
                   setC6R4VNo(1);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(1);
                   setC6R2VNo(1);
                   setC6R3VNo(1);
                   setC6R4VNo(1);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(1);
                    setC6R2VNo(1);
                    setC6R3VNo(1);
                    setC6R4VNo(1);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 2 || c6R2VNo === 2 || c6R3VNo === 2 || c6R4VNo === 2){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(2);
                   setC6R2VNo(2);
                   setC6R3VNo(2);
                   setC6R4VNo(2);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(2);
                   setC6R2VNo(2);
                   setC6R3VNo(2);
                   setC6R4VNo(2);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(2);
                   setC6R2VNo(2);
                   setC6R3VNo(2);
                   setC6R4VNo(2);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(2);
                    setC6R2VNo(2);
                    setC6R3VNo(2);
                    setC6R4VNo(2);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 3 || c6R2VNo === 3 || c6R3VNo === 3 || c6R4VNo === 3){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(3);
                   setC6R2VNo(3);
                   setC6R3VNo(3);
                   setC6R4VNo(3);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(3);
                   setC6R2VNo(3);
                   setC6R3VNo(3);
                   setC6R4VNo(3);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(3);
                   setC6R2VNo(3);
                   setC6R3VNo(3);
                   setC6R4VNo(3);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(3);
                    setC6R2VNo(3);
                    setC6R3VNo(3);
                    setC6R4VNo(3);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 4 || c6R2VNo === 4 || c6R3VNo === 4 || c6R4VNo === 4){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(4);
                   setC6R2VNo(4);
                   setC6R3VNo(4);
                   setC6R4VNo(4);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(4);
                   setC6R2VNo(4);
                   setC6R3VNo(4);
                   setC6R4VNo(4);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(4);
                   setC6R2VNo(4);
                   setC6R3VNo(4);
                   setC6R4VNo(4);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(4);
                    setC6R2VNo(4);
                    setC6R3VNo(4);
                    setC6R4VNo(4);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 5 || c6R2VNo === 5 || c6R3VNo === 5 || c6R4VNo === 5){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(5);
                   setC6R2VNo(5);
                   setC6R3VNo(5);
                   setC6R4VNo(5);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(5);
                   setC6R2VNo(5);
                   setC6R3VNo(5);
                   setC6R4VNo(5);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(5);
                   setC6R2VNo(5);
                   setC6R3VNo(5);
                   setC6R4VNo(5);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(5);
                    setC6R2VNo(5);
                    setC6R3VNo(5);
                    setC6R4VNo(5);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 6 || c6R2VNo === 6 || c6R3VNo === 6 || c6R4VNo === 6){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(6);
                   setC6R2VNo(6);
                   setC6R3VNo(6);
                   setC6R4VNo(6);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(6);
                   setC6R2VNo(6);
                   setC6R3VNo(6);
                   setC6R4VNo(6);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(6);
                   setC6R2VNo(6);
                   setC6R3VNo(6);
                   setC6R4VNo(6);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(6);
                    setC6R2VNo(6);
                    setC6R3VNo(6);
                    setC6R4VNo(6);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 7 || c6R2VNo === 7 || c6R3VNo === 7 || c6R4VNo === 7){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(7);
                   setC6R2VNo(7);
                   setC6R3VNo(7);
                   setC6R4VNo(7);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(7);
                   setC6R2VNo(7);
                   setC6R3VNo(7);
                   setC6R4VNo(7);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(7);
                   setC6R2VNo(7);
                   setC6R3VNo(7);
                   setC6R4VNo(7);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(7);
                    setC6R2VNo(7);
                    setC6R3VNo(7);
                    setC6R4VNo(7);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 8 || c6R2VNo === 8 || c6R3VNo === 8 || c6R4VNo === 8){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(8);
                   setC6R2VNo(8);
                   setC6R3VNo(8);
                   setC6R4VNo(8);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(8);
                   setC6R2VNo(8);
                   setC6R3VNo(8);
                   setC6R4VNo(8);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(8);
                   setC6R2VNo(8);
                   setC6R3VNo(8);
                   setC6R4VNo(8);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(8);
                    setC6R2VNo(8);
                    setC6R3VNo(8);
                    setC6R4VNo(8);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 9 || c6R2VNo === 9 || c6R3VNo === 9 || c6R4VNo === 9){
          if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(9);
                   setC6R2VNo(9);
                   setC6R3VNo(9);
                   setC6R4VNo(9);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                
                 } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(9);
                   setC6R2VNo(9);
                   setC6R3VNo(9);
                   setC6R4VNo(9);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(true)
                 } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(9);
                   setC6R2VNo(9);
                   setC6R3VNo(9);
                   setC6R4VNo(9);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(true)
                  } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(9);
                    setC6R2VNo(9);
                    setC6R3VNo(9);
                    setC6R4VNo(9);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c6R1VNo === 10 || c6R2VNo === 10 || c6R3VNo === 10 || c6R4VNo === 10){

        if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
          setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(10);
                 setC6R2VNo(10);
                 setC6R3VNo(10);
                 setC6R4VNo(10);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(true)
              
               } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(10);
                 setC6R2VNo(10);
                 setC6R3VNo(10);
                 setC6R4VNo(10);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(true)
               } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(10);
                 setC6R2VNo(10);
                 setC6R3VNo(10);
                 setC6R4VNo(10);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(true)
                } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(10);
                  setC6R2VNo(10);
                  setC6R3VNo(10);
                  setC6R4VNo(10);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(true)
                } else {
                  alert("Already added")
                }
		} else if (c6R1VNo === 11 || c6R2VNo === 11 || c6R3VNo === 11 || c6R4VNo === 11){
                  if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === false) {
       
                    setBaseNakedL06(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                             ]
                           )
                           setC6R1VNo(11);
                           setC6R2VNo(11);
                           setC6R3VNo(11);
                           setC6R4VNo(11);
                           setSepOn2LC6(false);
                           setSepOnLC6(false);
                           setSepOn3LC6(true)
                        
                         } else if (sepOn3LC6 === false && sepOnLC6 === false && sepOn2LC6 === true) {
                           setBaseNakedL06(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                             ]
                           )
                           setC6R1VNo(11);
                           setC6R2VNo(11);
                           setC6R3VNo(11);
                           setC6R4VNo(11);
                           setSepOn2LC6(true);
                           setSepOnLC6(false);
                           setSepOn3LC6(true)
                         } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === false) {
                           setBaseNakedL06(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                             ]
                           )
                           setC6R1VNo(11);
                           setC6R2VNo(11);
                           setC6R3VNo(11);
                           setC6R4VNo(11);
                           setSepOn2LC6(false);
                           setSepOnLC6(true);
                           setSepOn3LC6(true)
                          } else if (sepOn3LC6 === false && sepOnLC6 === true && sepOn2LC6 === true) {
                            setBaseNakedL06(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                              ]
                            )
                            setC6R1VNo(11);
                            setC6R2VNo(11);
                            setC6R3VNo(11);
                            setC6R4VNo(11);
                            setSepOn2LC6(true);
                            setSepOnLC6(true);
                            setSepOn3LC6(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col07X && curY === row01X) || (curX === col07X && curY === row02X) || (curX === col07X && curY === row03X) || (curX === col07X && curY === row04X)) {
      if (c7r1MT === "L" || c7r2MT === "L" || c7r3MT === "L" || c7r4MT === "L") {
        if (c7R1VNo === 1 || c7R2VNo === 1 || c7R3VNo === 1 || c7R4VNo === 1){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(1);
                   setC7R2VNo(1);
                   setC7R3VNo(1);
                   setC7R4VNo(1);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(1);
                   setC7R2VNo(1);
                   setC7R3VNo(1);
                   setC7R4VNo(1);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(1);
                   setC7R2VNo(1);
                   setC7R3VNo(1);
                   setC7R4VNo(1);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(1);
                    setC7R2VNo(1);
                    setC7R3VNo(1);
                    setC7R4VNo(1);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 2 || c7R2VNo === 2 || c7R3VNo === 2 || c7R4VNo === 2){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(2);
                   setC7R2VNo(2);
                   setC7R3VNo(2);
                   setC7R4VNo(2);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(2);
                   setC7R2VNo(2);
                   setC7R3VNo(2);
                   setC7R4VNo(2);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(2);
                   setC7R2VNo(2);
                   setC7R3VNo(2);
                   setC7R4VNo(2);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(2);
                    setC7R2VNo(2);
                    setC7R3VNo(2);
                    setC7R4VNo(2);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 3 || c7R2VNo === 3 || c7R3VNo === 3 || c7R4VNo === 3){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(3);
                   setC7R2VNo(3);
                   setC7R3VNo(3);
                   setC7R4VNo(3);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(3);
                   setC7R2VNo(3);
                   setC7R3VNo(3);
                   setC7R4VNo(3);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(3);
                   setC7R2VNo(3);
                   setC7R3VNo(3);
                   setC7R4VNo(3);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(3);
                    setC7R2VNo(3);
                    setC7R3VNo(3);
                    setC7R4VNo(3);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 4 || c7R2VNo === 4 || c7R3VNo === 4 || c7R4VNo === 4){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(4);
                   setC7R2VNo(4);
                   setC7R3VNo(4);
                   setC7R4VNo(4);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(4);
                   setC7R2VNo(4);
                   setC7R3VNo(4);
                   setC7R4VNo(4);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(4);
                   setC7R2VNo(4);
                   setC7R3VNo(4);
                   setC7R4VNo(4);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(4);
                    setC7R2VNo(4);
                    setC7R3VNo(4);
                    setC7R4VNo(4);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 5 || c7R2VNo === 5 || c7R3VNo === 5 || c7R4VNo === 5){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(5);
                   setC7R2VNo(5);
                   setC7R3VNo(5);
                   setC7R4VNo(5);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(5);
                   setC7R2VNo(5);
                   setC7R3VNo(5);
                   setC7R4VNo(5);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(5);
                   setC7R2VNo(5);
                   setC7R3VNo(5);
                   setC7R4VNo(5);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(5);
                    setC7R2VNo(5);
                    setC7R3VNo(5);
                    setC7R4VNo(5);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 6 || c7R2VNo === 6 || c7R3VNo === 6 || c7R4VNo === 6){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(6);
                   setC7R2VNo(6);
                   setC7R3VNo(6);
                   setC7R4VNo(6);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(6);
                   setC7R2VNo(6);
                   setC7R3VNo(6);
                   setC7R4VNo(6);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(6);
                   setC7R2VNo(6);
                   setC7R3VNo(6);
                   setC7R4VNo(6);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(6);
                    setC7R2VNo(6);
                    setC7R3VNo(6);
                    setC7R4VNo(6);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 7 || c7R2VNo === 7 || c7R3VNo === 7 || c7R4VNo === 7){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(7);
                   setC7R2VNo(7);
                   setC7R3VNo(7);
                   setC7R4VNo(7);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(7);
                   setC7R2VNo(7);
                   setC7R3VNo(7);
                   setC7R4VNo(7);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(7);
                   setC7R2VNo(7);
                   setC7R3VNo(7);
                   setC7R4VNo(7);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(7);
                    setC7R2VNo(7);
                    setC7R3VNo(7);
                    setC7R4VNo(7);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 8 || c7R2VNo === 8 || c7R3VNo === 8 || c7R4VNo === 8){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(8);
                   setC7R2VNo(8);
                   setC7R3VNo(8);
                   setC7R4VNo(8);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(8);
                   setC7R2VNo(8);
                   setC7R3VNo(8);
                   setC7R4VNo(8);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(8);
                   setC7R2VNo(8);
                   setC7R3VNo(8);
                   setC7R4VNo(8);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(8);
                    setC7R2VNo(8);
                    setC7R3VNo(8);
                    setC7R4VNo(8);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 9 || c7R2VNo === 9 || c7R3VNo === 9 || c7R4VNo === 9){
          if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(9);
                   setC7R2VNo(9);
                   setC7R3VNo(9);
                   setC7R4VNo(9);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                
                 } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(9);
                   setC7R2VNo(9);
                   setC7R3VNo(9);
                   setC7R4VNo(9);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(true)
                 } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(9);
                   setC7R2VNo(9);
                   setC7R3VNo(9);
                   setC7R4VNo(9);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(true)
                  } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(9);
                    setC7R2VNo(9);
                    setC7R3VNo(9);
                    setC7R4VNo(9);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c7R1VNo === 10 || c7R2VNo === 10 || c7R3VNo === 10 || c7R4VNo === 10){

        if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
          setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(10);
                 setC7R2VNo(10);
                 setC7R3VNo(10);
                 setC7R4VNo(10);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(true)
              
               } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(10);
                 setC7R2VNo(10);
                 setC7R3VNo(10);
                 setC7R4VNo(10);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(true)
               } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(10);
                 setC7R2VNo(10);
                 setC7R3VNo(10);
                 setC7R4VNo(10);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(true)
                } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(10);
                  setC7R2VNo(10);
                  setC7R3VNo(10);
                  setC7R4VNo(10);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(true)
                } else {
                  alert("Already added")
                }
		} else if (c7R1VNo === 11 || c7R2VNo === 11 || c7R3VNo === 11 || c7R4VNo === 11){
                  if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === false) {
       
                    setBaseNakedL07(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                             ]
                           )
                           setC7R1VNo(11);
                           setC7R2VNo(11);
                           setC7R3VNo(11);
                           setC7R4VNo(11);
                           setSepOn2LC7(false);
                           setSepOnLC7(false);
                           setSepOn3LC7(true)
                        
                         } else if (sepOn3LC7 === false && sepOnLC7 === false && sepOn2LC7 === true) {
                           setBaseNakedL07(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                             ]
                           )
                           setC7R1VNo(11);
                           setC7R2VNo(11);
                           setC7R3VNo(11);
                           setC7R4VNo(11);
                           setSepOn2LC7(true);
                           setSepOnLC7(false);
                           setSepOn3LC7(true)
                         } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === false) {
                           setBaseNakedL07(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                             ]
                           )
                           setC7R1VNo(11);
                           setC7R2VNo(11);
                           setC7R3VNo(11);
                           setC7R4VNo(11);
                           setSepOn2LC7(false);
                           setSepOnLC7(true);
                           setSepOn3LC7(true)
                          } else if (sepOn3LC7 === false && sepOnLC7 === true && sepOn2LC7 === true) {
                            setBaseNakedL07(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                              ]
                            )
                            setC7R1VNo(11);
                            setC7R2VNo(11);
                            setC7R3VNo(11);
                            setC7R4VNo(11);
                            setSepOn2LC7(true);
                            setSepOnLC7(true);
                            setSepOn3LC7(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col08X && curY === row01X) || (curX === col08X && curY === row02X) || (curX === col08X && curY === row03X) || (curX === col08X && curY === row04X)) {
      if (c8r1MT === "L" || c8r2MT === "L" || c8r3MT === "L" || c8r4MT === "L") {
        if (c8R1VNo === 1 || c8R2VNo === 1 || c8R3VNo === 1 || c8R4VNo === 1){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(1);
                   setC8R2VNo(1);
                   setC8R3VNo(1);
                   setC8R4VNo(1);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(1);
                   setC8R2VNo(1);
                   setC8R3VNo(1);
                   setC8R4VNo(1);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(1);
                   setC8R2VNo(1);
                   setC8R3VNo(1);
                   setC8R4VNo(1);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(1);
                    setC8R2VNo(1);
                    setC8R3VNo(1);
                    setC8R4VNo(1);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 2 || c8R2VNo === 2 || c8R3VNo === 2 || c8R4VNo === 2){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(2);
                   setC8R2VNo(2);
                   setC8R3VNo(2);
                   setC8R4VNo(2);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(2);
                   setC8R2VNo(2);
                   setC8R3VNo(2);
                   setC8R4VNo(2);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(2);
                   setC8R2VNo(2);
                   setC8R3VNo(2);
                   setC8R4VNo(2);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(2);
                    setC8R2VNo(2);
                    setC8R3VNo(2);
                    setC8R4VNo(2);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 3 || c8R2VNo === 3 || c8R3VNo === 3 || c8R4VNo === 3){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(3);
                   setC8R2VNo(3);
                   setC8R3VNo(3);
                   setC8R4VNo(3);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(3);
                   setC8R2VNo(3);
                   setC8R3VNo(3);
                   setC8R4VNo(3);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(3);
                   setC8R2VNo(3);
                   setC8R3VNo(3);
                   setC8R4VNo(3);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(3);
                    setC8R2VNo(3);
                    setC8R3VNo(3);
                    setC8R4VNo(3);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 4 || c8R2VNo === 4 || c8R3VNo === 4 || c8R4VNo === 4){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(4);
                   setC8R2VNo(4);
                   setC8R3VNo(4);
                   setC8R4VNo(4);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(4);
                   setC8R2VNo(4);
                   setC8R3VNo(4);
                   setC8R4VNo(4);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(4);
                   setC8R2VNo(4);
                   setC8R3VNo(4);
                   setC8R4VNo(4);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(4);
                    setC8R2VNo(4);
                    setC8R3VNo(4);
                    setC8R4VNo(4);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 5 || c8R2VNo === 5 || c8R3VNo === 5 || c8R4VNo === 5){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(5);
                   setC8R2VNo(5);
                   setC8R3VNo(5);
                   setC8R4VNo(5);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(5);
                   setC8R2VNo(5);
                   setC8R3VNo(5);
                   setC8R4VNo(5);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(5);
                   setC8R2VNo(5);
                   setC8R3VNo(5);
                   setC8R4VNo(5);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(5);
                    setC8R2VNo(5);
                    setC8R3VNo(5);
                    setC8R4VNo(5);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 6 || c8R2VNo === 6 || c8R3VNo === 6 || c8R4VNo === 6){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(6);
                   setC8R2VNo(6);
                   setC8R3VNo(6);
                   setC8R4VNo(6);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(6);
                   setC8R2VNo(6);
                   setC8R3VNo(6);
                   setC8R4VNo(6);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(6);
                   setC8R2VNo(6);
                   setC8R3VNo(6);
                   setC8R4VNo(6);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(6);
                    setC8R2VNo(6);
                    setC8R3VNo(6);
                    setC8R4VNo(6);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 7 || c8R2VNo === 7 || c8R3VNo === 7 || c8R4VNo === 7){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(7);
                   setC8R2VNo(7);
                   setC8R3VNo(7);
                   setC8R4VNo(7);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(7);
                   setC8R2VNo(7);
                   setC8R3VNo(7);
                   setC8R4VNo(7);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(7);
                   setC8R2VNo(7);
                   setC8R3VNo(7);
                   setC8R4VNo(7);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(7);
                    setC8R2VNo(7);
                    setC8R3VNo(7);
                    setC8R4VNo(7);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 8 || c8R2VNo === 8 || c8R3VNo === 8 || c8R4VNo === 8){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(8);
                   setC8R2VNo(8);
                   setC8R3VNo(8);
                   setC8R4VNo(8);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(8);
                   setC8R2VNo(8);
                   setC8R3VNo(8);
                   setC8R4VNo(8);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(8);
                   setC8R2VNo(8);
                   setC8R3VNo(8);
                   setC8R4VNo(8);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(8);
                    setC8R2VNo(8);
                    setC8R3VNo(8);
                    setC8R4VNo(8);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 9 || c8R2VNo === 9 || c8R3VNo === 9 || c8R4VNo === 9){
          if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(9);
                   setC8R2VNo(9);
                   setC8R3VNo(9);
                   setC8R4VNo(9);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                
                 } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(9);
                   setC8R2VNo(9);
                   setC8R3VNo(9);
                   setC8R4VNo(9);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(true)
                 } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(9);
                   setC8R2VNo(9);
                   setC8R3VNo(9);
                   setC8R4VNo(9);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(true)
                  } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(9);
                    setC8R2VNo(9);
                    setC8R3VNo(9);
                    setC8R4VNo(9);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c8R1VNo === 10 || c8R2VNo === 10 || c8R3VNo === 10 || c8R4VNo === 10){

        if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
          setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(10);
                 setC8R2VNo(10);
                 setC8R3VNo(10);
                 setC8R4VNo(10);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(true)
              
               } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(10);
                 setC8R2VNo(10);
                 setC8R3VNo(10);
                 setC8R4VNo(10);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(true)
               } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(10);
                 setC8R2VNo(10);
                 setC8R3VNo(10);
                 setC8R4VNo(10);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(true)
                } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(10);
                  setC8R2VNo(10);
                  setC8R3VNo(10);
                  setC8R4VNo(10);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(true)
                } else {
                  alert("Already added")
                }
		} else if (c8R1VNo === 11 || c8R2VNo === 11 || c8R3VNo === 11 || c8R4VNo === 11){
                  if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === false) {
       
                    setBaseNakedL08(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                             ]
                           )
                           setC8R1VNo(11);
                           setC8R2VNo(11);
                           setC8R3VNo(11);
                           setC8R4VNo(11);
                           setSepOn2LC8(false);
                           setSepOnLC8(false);
                           setSepOn3LC8(true)
                        
                         } else if (sepOn3LC8 === false && sepOnLC8 === false && sepOn2LC8 === true) {
                           setBaseNakedL08(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                             ]
                           )
                           setC8R1VNo(11);
                           setC8R2VNo(11);
                           setC8R3VNo(11);
                           setC8R4VNo(11);
                           setSepOn2LC8(true);
                           setSepOnLC8(false);
                           setSepOn3LC8(true)
                         } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === false) {
                           setBaseNakedL08(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                             ]
                           )
                           setC8R1VNo(11);
                           setC8R2VNo(11);
                           setC8R3VNo(11);
                           setC8R4VNo(11);
                           setSepOn2LC8(false);
                           setSepOnLC8(true);
                           setSepOn3LC8(true)
                          } else if (sepOn3LC8 === false && sepOnLC8 === true && sepOn2LC8 === true) {
                            setBaseNakedL08(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(11);
                            setC8R2VNo(11);
                            setC8R3VNo(11);
                            setC8R4VNo(11);
                            setSepOn2LC8(true);
                            setSepOnLC8(true);
                            setSepOn3LC8(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col09X && curY === row01X) || (curX === col09X && curY === row02X) || (curX === col09X && curY === row03X) || (curX === col09X && curY === row04X)) {
      if (c9r1MT === "L" || c9r2MT === "L" || c9r3MT === "L" || c9r4MT === "L") {
        if (c9R1VNo === 1 || c9R2VNo === 1 || c9R3VNo === 1 || c9R4VNo === 1){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(1);
                   setC9R2VNo(1);
                   setC9R3VNo(1);
                   setC9R4VNo(1);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(1);
                   setC9R2VNo(1);
                   setC9R3VNo(1);
                   setC9R4VNo(1);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(1);
                   setC9R2VNo(1);
                   setC9R3VNo(1);
                   setC9R4VNo(1);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(1);
                    setC9R2VNo(1);
                    setC9R3VNo(1);
                    setC9R4VNo(1);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 2 || c9R2VNo === 2 || c9R3VNo === 2 || c9R4VNo === 2){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(2);
                   setC9R2VNo(2);
                   setC9R3VNo(2);
                   setC9R4VNo(2);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(2);
                   setC9R2VNo(2);
                   setC9R3VNo(2);
                   setC9R4VNo(2);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(2);
                   setC9R2VNo(2);
                   setC9R3VNo(2);
                   setC9R4VNo(2);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(2);
                    setC9R2VNo(2);
                    setC9R3VNo(2);
                    setC9R4VNo(2);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 3 || c9R2VNo === 3 || c9R3VNo === 3 || c9R4VNo === 3){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(3);
                   setC9R2VNo(3);
                   setC9R3VNo(3);
                   setC9R4VNo(3);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(3);
                   setC9R2VNo(3);
                   setC9R3VNo(3);
                   setC9R4VNo(3);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(3);
                   setC9R2VNo(3);
                   setC9R3VNo(3);
                   setC9R4VNo(3);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(3);
                    setC9R2VNo(3);
                    setC9R3VNo(3);
                    setC9R4VNo(3);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 4 || c9R2VNo === 4 || c9R3VNo === 4 || c9R4VNo === 4){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(4);
                   setC9R2VNo(4);
                   setC9R3VNo(4);
                   setC9R4VNo(4);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(4);
                   setC9R2VNo(4);
                   setC9R3VNo(4);
                   setC9R4VNo(4);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(4);
                   setC9R2VNo(4);
                   setC9R3VNo(4);
                   setC9R4VNo(4);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(4);
                    setC9R2VNo(4);
                    setC9R3VNo(4);
                    setC9R4VNo(4);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 5 || c9R2VNo === 5 || c9R3VNo === 5 || c9R4VNo === 5){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(5);
                   setC9R2VNo(5);
                   setC9R3VNo(5);
                   setC9R4VNo(5);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(5);
                   setC9R2VNo(5);
                   setC9R3VNo(5);
                   setC9R4VNo(5);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(5);
                   setC9R2VNo(5);
                   setC9R3VNo(5);
                   setC9R4VNo(5);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(5);
                    setC9R2VNo(5);
                    setC9R3VNo(5);
                    setC9R4VNo(5);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 6 || c9R2VNo === 6 || c9R3VNo === 6 || c9R4VNo === 6){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(6);
                   setC9R2VNo(6);
                   setC9R3VNo(6);
                   setC9R4VNo(6);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(6);
                   setC9R2VNo(6);
                   setC9R3VNo(6);
                   setC9R4VNo(6);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(6);
                   setC9R2VNo(6);
                   setC9R3VNo(6);
                   setC9R4VNo(6);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(6);
                    setC9R2VNo(6);
                    setC9R3VNo(6);
                    setC9R4VNo(6);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 7 || c9R2VNo === 7 || c9R3VNo === 7 || c9R4VNo === 7){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(7);
                   setC9R2VNo(7);
                   setC9R3VNo(7);
                   setC9R4VNo(7);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(7);
                   setC9R2VNo(7);
                   setC9R3VNo(7);
                   setC9R4VNo(7);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(7);
                   setC9R2VNo(7);
                   setC9R3VNo(7);
                   setC9R4VNo(7);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(7);
                    setC9R2VNo(7);
                    setC9R3VNo(7);
                    setC9R4VNo(7);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 8 || c9R2VNo === 8 || c9R3VNo === 8 || c9R4VNo === 8){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(8);
                   setC9R2VNo(8);
                   setC9R3VNo(8);
                   setC9R4VNo(8);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(8);
                   setC9R2VNo(8);
                   setC9R3VNo(8);
                   setC9R4VNo(8);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(8);
                   setC9R2VNo(8);
                   setC9R3VNo(8);
                   setC9R4VNo(8);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(8);
                    setC9R2VNo(8);
                    setC9R3VNo(8);
                    setC9R4VNo(8);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 9 || c9R2VNo === 9 || c9R3VNo === 9 || c9R4VNo === 9){
          if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(9);
                   setC9R2VNo(9);
                   setC9R3VNo(9);
                   setC9R4VNo(9);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                
                 } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(9);
                   setC9R2VNo(9);
                   setC9R3VNo(9);
                   setC9R4VNo(9);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(true)
                 } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(9);
                   setC9R2VNo(9);
                   setC9R3VNo(9);
                   setC9R4VNo(9);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(true)
                  } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(9);
                    setC9R2VNo(9);
                    setC9R3VNo(9);
                    setC9R4VNo(9);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c9R1VNo === 10 || c9R2VNo === 10 || c9R3VNo === 10 || c9R4VNo === 10){

        if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
          setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(10);
                 setC9R2VNo(10);
                 setC9R3VNo(10);
                 setC9R4VNo(10);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(true)
              
               } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(10);
                 setC9R2VNo(10);
                 setC9R3VNo(10);
                 setC9R4VNo(10);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(true)
               } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(10);
                 setC9R2VNo(10);
                 setC9R3VNo(10);
                 setC9R4VNo(10);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(true)
                } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(10);
                  setC9R2VNo(10);
                  setC9R3VNo(10);
                  setC9R4VNo(10);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(true)
                } else {
                  alert("Already added")
                }
		} else if (c9R1VNo === 11 || c9R2VNo === 11 || c9R3VNo === 11 || c9R4VNo === 11){
                  if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === false) {
       
                    setBaseNakedL09(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                             ]
                           )
                           setC9R1VNo(11);
                           setC9R2VNo(11);
                           setC9R3VNo(11);
                           setC9R4VNo(11);
                           setSepOn2LC9(false);
                           setSepOnLC9(false);
                           setSepOn3LC9(true)
                        
                         } else if (sepOn3LC9 === false && sepOnLC9 === false && sepOn2LC9 === true) {
                           setBaseNakedL09(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                             ]
                           )
                           setC9R1VNo(11);
                           setC9R2VNo(11);
                           setC9R3VNo(11);
                           setC9R4VNo(11);
                           setSepOn2LC9(true);
                           setSepOnLC9(false);
                           setSepOn3LC9(true)
                         } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === false) {
                           setBaseNakedL09(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                             ]
                           )
                           setC9R1VNo(11);
                           setC9R2VNo(11);
                           setC9R3VNo(11);
                           setC9R4VNo(11);
                           setSepOn2LC9(false);
                           setSepOnLC9(true);
                           setSepOn3LC9(true)
                          } else if (sepOn3LC9 === false && sepOnLC9 === true && sepOn2LC9 === true) {
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(11);
                            setC9R2VNo(11);
                            setC9R3VNo(11);
                            setC9R4VNo(11);
                            setSepOn2LC9(true);
                            setSepOnLC9(true);
                            setSepOn3LC9(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col10X && curY === row01X) || (curX === col10X && curY === row02X) || (curX === col10X && curY === row03X) || (curX === col10X && curY === row04X)) {
      if (c10r1MT === "L" || c10r2MT === "L" || c10r3MT === "L" || c10r4MT === "L") {
        if (c10R1VNo === 1 || c10R2VNo === 1 || c10R3VNo === 1 || c10R4VNo === 1){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(1);
                   setC10R2VNo(1);
                   setC10R3VNo(1);
                   setC10R4VNo(1);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(1);
                   setC10R2VNo(1);
                   setC10R3VNo(1);
                   setC10R4VNo(1);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(1);
                   setC10R2VNo(1);
                   setC10R3VNo(1);
                   setC10R4VNo(1);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(1);
                    setC10R2VNo(1);
                    setC10R3VNo(1);
                    setC10R4VNo(1);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 2 || c10R2VNo === 2 || c10R3VNo === 2 || c10R4VNo === 2){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(2);
                   setC10R2VNo(2);
                   setC10R3VNo(2);
                   setC10R4VNo(2);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(2);
                   setC10R2VNo(2);
                   setC10R3VNo(2);
                   setC10R4VNo(2);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(2);
                   setC10R2VNo(2);
                   setC10R3VNo(2);
                   setC10R4VNo(2);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(2);
                    setC10R2VNo(2);
                    setC10R3VNo(2);
                    setC10R4VNo(2);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 3 || c10R2VNo === 3 || c10R3VNo === 3 || c10R4VNo === 3){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(3);
                   setC10R2VNo(3);
                   setC10R3VNo(3);
                   setC10R4VNo(3);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(3);
                   setC10R2VNo(3);
                   setC10R3VNo(3);
                   setC10R4VNo(3);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(3);
                   setC10R2VNo(3);
                   setC10R3VNo(3);
                   setC10R4VNo(3);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(3);
                    setC10R2VNo(3);
                    setC10R3VNo(3);
                    setC10R4VNo(3);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 4 || c10R2VNo === 4 || c10R3VNo === 4 || c10R4VNo === 4){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(4);
                   setC10R2VNo(4);
                   setC10R3VNo(4);
                   setC10R4VNo(4);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(4);
                   setC10R2VNo(4);
                   setC10R3VNo(4);
                   setC10R4VNo(4);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(4);
                   setC10R2VNo(4);
                   setC10R3VNo(4);
                   setC10R4VNo(4);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(4);
                    setC10R2VNo(4);
                    setC10R3VNo(4);
                    setC10R4VNo(4);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 5 || c10R2VNo === 5 || c10R3VNo === 5 || c10R4VNo === 5){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(5);
                   setC10R2VNo(5);
                   setC10R3VNo(5);
                   setC10R4VNo(5);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(5);
                   setC10R2VNo(5);
                   setC10R3VNo(5);
                   setC10R4VNo(5);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(5);
                   setC10R2VNo(5);
                   setC10R3VNo(5);
                   setC10R4VNo(5);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(5);
                    setC10R2VNo(5);
                    setC10R3VNo(5);
                    setC10R4VNo(5);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 6 || c10R2VNo === 6 || c10R3VNo === 6 || c10R4VNo === 6){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(6);
                   setC10R2VNo(6);
                   setC10R3VNo(6);
                   setC10R4VNo(6);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(6);
                   setC10R2VNo(6);
                   setC10R3VNo(6);
                   setC10R4VNo(6);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(6);
                   setC10R2VNo(6);
                   setC10R3VNo(6);
                   setC10R4VNo(6);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(6);
                    setC10R2VNo(6);
                    setC10R3VNo(6);
                    setC10R4VNo(6);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 7 || c10R2VNo === 7 || c10R3VNo === 7 || c10R4VNo === 7){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(7);
                   setC10R2VNo(7);
                   setC10R3VNo(7);
                   setC10R4VNo(7);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(7);
                   setC10R2VNo(7);
                   setC10R3VNo(7);
                   setC10R4VNo(7);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(7);
                   setC10R2VNo(7);
                   setC10R3VNo(7);
                   setC10R4VNo(7);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(7);
                    setC10R2VNo(7);
                    setC10R3VNo(7);
                    setC10R4VNo(7);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 8 || c10R2VNo === 8 || c10R3VNo === 8 || c10R4VNo === 8){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(8);
                   setC10R2VNo(8);
                   setC10R3VNo(8);
                   setC10R4VNo(8);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(8);
                   setC10R2VNo(8);
                   setC10R3VNo(8);
                   setC10R4VNo(8);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(8);
                   setC10R2VNo(8);
                   setC10R3VNo(8);
                   setC10R4VNo(8);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(8);
                    setC10R2VNo(8);
                    setC10R3VNo(8);
                    setC10R4VNo(8);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 9 || c10R2VNo === 9 || c10R3VNo === 9 || c10R4VNo === 9){
          if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(9);
                   setC10R2VNo(9);
                   setC10R3VNo(9);
                   setC10R4VNo(9);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                
                 } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(9);
                   setC10R2VNo(9);
                   setC10R3VNo(9);
                   setC10R4VNo(9);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(true)
                 } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(9);
                   setC10R2VNo(9);
                   setC10R3VNo(9);
                   setC10R4VNo(9);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(true)
                  } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(9);
                    setC10R2VNo(9);
                    setC10R3VNo(9);
                    setC10R4VNo(9);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c10R1VNo === 10 || c10R2VNo === 10 || c10R3VNo === 10 || c10R4VNo === 10){

        if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
          setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(10);
                 setC10R2VNo(10);
                 setC10R3VNo(10);
                 setC10R4VNo(10);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(true)
              
               } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(10);
                 setC10R2VNo(10);
                 setC10R3VNo(10);
                 setC10R4VNo(10);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(true)
               } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(10);
                 setC10R2VNo(10);
                 setC10R3VNo(10);
                 setC10R4VNo(10);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(true)
                } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(10);
                  setC10R2VNo(10);
                  setC10R3VNo(10);
                  setC10R4VNo(10);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(true)
                } else {
                  alert("Already added")
                }
		} else if (c10R1VNo === 11 || c10R2VNo === 11 || c10R3VNo === 11 || c10R4VNo === 11){
                  if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === false) {
       
                    setBaseNakedL10(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                             ]
                           )
                           setC10R1VNo(11);
                           setC10R2VNo(11);
                           setC10R3VNo(11);
                           setC10R4VNo(11);
                           setSepOn2LC10(false);
                           setSepOnLC10(false);
                           setSepOn3LC10(true)
                        
                         } else if (sepOn3LC10 === false && sepOnLC10 === false && sepOn2LC10 === true) {
                           setBaseNakedL10(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                             ]
                           )
                           setC10R1VNo(11);
                           setC10R2VNo(11);
                           setC10R3VNo(11);
                           setC10R4VNo(11);
                           setSepOn2LC10(true);
                           setSepOnLC10(false);
                           setSepOn3LC10(true)
                         } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === false) {
                           setBaseNakedL10(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                             ]
                           )
                           setC10R1VNo(11);
                           setC10R2VNo(11);
                           setC10R3VNo(11);
                           setC10R4VNo(11);
                           setSepOn2LC10(false);
                           setSepOnLC10(true);
                           setSepOn3LC10(true)
                          } else if (sepOn3LC10 === false && sepOnLC10 === true && sepOn2LC10 === true) {
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(11);
                            setC10R2VNo(11);
                            setC10R3VNo(11);
                            setC10R4VNo(11);
                            setSepOn2LC10(true);
                            setSepOnLC10(true);
                            setSepOn3LC10(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col11X && curY === row01X) || (curX === col11X && curY === row02X) || (curX === col11X && curY === row03X) || (curX === col11X && curY === row04X)) {
      if (c11r1MT === "L" || c11r2MT === "L" || c11r3MT === "L" || c11r4MT === "L") {
        if (c11R1VNo === 1 || c11R2VNo === 1 || c11R3VNo === 1 || c11R4VNo === 1){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(1);
                   setC11R2VNo(1);
                   setC11R3VNo(1);
                   setC11R4VNo(1);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(1);
                   setC11R2VNo(1);
                   setC11R3VNo(1);
                   setC11R4VNo(1);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(1);
                   setC11R2VNo(1);
                   setC11R3VNo(1);
                   setC11R4VNo(1);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(1);
                    setC11R2VNo(1);
                    setC11R3VNo(1);
                    setC11R4VNo(1);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 2 || c11R2VNo === 2 || c11R3VNo === 2 || c11R4VNo === 2){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(2);
                   setC11R2VNo(2);
                   setC11R3VNo(2);
                   setC11R4VNo(2);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(2);
                   setC11R2VNo(2);
                   setC11R3VNo(2);
                   setC11R4VNo(2);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(2);
                   setC11R2VNo(2);
                   setC11R3VNo(2);
                   setC11R4VNo(2);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(2);
                    setC11R2VNo(2);
                    setC11R3VNo(2);
                    setC11R4VNo(2);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 3 || c11R2VNo === 3 || c11R3VNo === 3 || c11R4VNo === 3){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(3);
                   setC11R2VNo(3);
                   setC11R3VNo(3);
                   setC11R4VNo(3);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(3);
                   setC11R2VNo(3);
                   setC11R3VNo(3);
                   setC11R4VNo(3);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(3);
                   setC11R2VNo(3);
                   setC11R3VNo(3);
                   setC11R4VNo(3);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(3);
                    setC11R2VNo(3);
                    setC11R3VNo(3);
                    setC11R4VNo(3);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 4 || c11R2VNo === 4 || c11R3VNo === 4 || c11R4VNo === 4){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(4);
                   setC11R2VNo(4);
                   setC11R3VNo(4);
                   setC11R4VNo(4);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(4);
                   setC11R2VNo(4);
                   setC11R3VNo(4);
                   setC11R4VNo(4);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(4);
                   setC11R2VNo(4);
                   setC11R3VNo(4);
                   setC11R4VNo(4);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(4);
                    setC11R2VNo(4);
                    setC11R3VNo(4);
                    setC11R4VNo(4);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 5 || c11R2VNo === 5 || c11R3VNo === 5 || c11R4VNo === 5){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(5);
                   setC11R2VNo(5);
                   setC11R3VNo(5);
                   setC11R4VNo(5);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(5);
                   setC11R2VNo(5);
                   setC11R3VNo(5);
                   setC11R4VNo(5);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(5);
                   setC11R2VNo(5);
                   setC11R3VNo(5);
                   setC11R4VNo(5);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(5);
                    setC11R2VNo(5);
                    setC11R3VNo(5);
                    setC11R4VNo(5);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 6 || c11R2VNo === 6 || c11R3VNo === 6 || c11R4VNo === 6){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(6);
                   setC11R2VNo(6);
                   setC11R3VNo(6);
                   setC11R4VNo(6);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(6);
                   setC11R2VNo(6);
                   setC11R3VNo(6);
                   setC11R4VNo(6);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(6);
                   setC11R2VNo(6);
                   setC11R3VNo(6);
                   setC11R4VNo(6);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(6);
                    setC11R2VNo(6);
                    setC11R3VNo(6);
                    setC11R4VNo(6);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 7 || c11R2VNo === 7 || c11R3VNo === 7 || c11R4VNo === 7){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(7);
                   setC11R2VNo(7);
                   setC11R3VNo(7);
                   setC11R4VNo(7);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(7);
                   setC11R2VNo(7);
                   setC11R3VNo(7);
                   setC11R4VNo(7);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(7);
                   setC11R2VNo(7);
                   setC11R3VNo(7);
                   setC11R4VNo(7);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(7);
                    setC11R2VNo(7);
                    setC11R3VNo(7);
                    setC11R4VNo(7);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 8 || c11R2VNo === 8 || c11R3VNo === 8 || c11R4VNo === 8){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(8);
                   setC11R2VNo(8);
                   setC11R3VNo(8);
                   setC11R4VNo(8);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(8);
                   setC11R2VNo(8);
                   setC11R3VNo(8);
                   setC11R4VNo(8);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(8);
                   setC11R2VNo(8);
                   setC11R3VNo(8);
                   setC11R4VNo(8);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(8);
                    setC11R2VNo(8);
                    setC11R3VNo(8);
                    setC11R4VNo(8);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 9 || c11R2VNo === 9 || c11R3VNo === 9 || c11R4VNo === 9){
          if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(9);
                   setC11R2VNo(9);
                   setC11R3VNo(9);
                   setC11R4VNo(9);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                
                 } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(9);
                   setC11R2VNo(9);
                   setC11R3VNo(9);
                   setC11R4VNo(9);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(true)
                 } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(9);
                   setC11R2VNo(9);
                   setC11R3VNo(9);
                   setC11R4VNo(9);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(true)
                  } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(9);
                    setC11R2VNo(9);
                    setC11R3VNo(9);
                    setC11R4VNo(9);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c11R1VNo === 10 || c11R2VNo === 10 || c11R3VNo === 10 || c11R4VNo === 10){

        if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
          setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(10);
                 setC11R2VNo(10);
                 setC11R3VNo(10);
                 setC11R4VNo(10);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(true)
              
               } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(10);
                 setC11R2VNo(10);
                 setC11R3VNo(10);
                 setC11R4VNo(10);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(true)
               } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(10);
                 setC11R2VNo(10);
                 setC11R3VNo(10);
                 setC11R4VNo(10);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(true)
                } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(10);
                  setC11R2VNo(10);
                  setC11R3VNo(10);
                  setC11R4VNo(10);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(true)
                } else {
                  alert("Already added")
                }
		} else if (c11R1VNo === 11 || c11R2VNo === 11 || c11R3VNo === 11 || c11R4VNo === 11){
                  if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === false) {
       
                    setBaseNakedL11(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                             ]
                           )
                           setC11R1VNo(11);
                           setC11R2VNo(11);
                           setC11R3VNo(11);
                           setC11R4VNo(11);
                           setSepOn2LC11(false);
                           setSepOnLC11(false);
                           setSepOn3LC11(true)
                        
                         } else if (sepOn3LC11 === false && sepOnLC11 === false && sepOn2LC11 === true) {
                           setBaseNakedL11(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                             ]
                           )
                           setC11R1VNo(11);
                           setC11R2VNo(11);
                           setC11R3VNo(11);
                           setC11R4VNo(11);
                           setSepOn2LC11(true);
                           setSepOnLC11(false);
                           setSepOn3LC11(true)
                         } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === false) {
                           setBaseNakedL11(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                             ]
                           )
                           setC11R1VNo(11);
                           setC11R2VNo(11);
                           setC11R3VNo(11);
                           setC11R4VNo(11);
                           setSepOn2LC11(false);
                           setSepOnLC11(true);
                           setSepOn3LC11(true)
                          } else if (sepOn3LC11 === false && sepOnLC11 === true && sepOn2LC11 === true) {
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(11);
                            setC11R2VNo(11);
                            setC11R3VNo(11);
                            setC11R4VNo(11);
                            setSepOn2LC11(true);
                            setSepOnLC11(true);
                            setSepOn3LC11(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col12X && curY === row01X) || (curX === col12X && curY === row02X) || (curX === col12X && curY === row03X) || (curX === col12X && curY === row04X)) {
      if (c12r1MT === "L" || c12r2MT === "L" || c12r3MT === "L" || c12r4MT === "L") {
        if (c12R1VNo === 1 || c12R2VNo === 1 || c12R3VNo === 1 || c12R4VNo === 1){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(1);
                   setC12R2VNo(1);
                   setC12R3VNo(1);
                   setC12R4VNo(1);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(1);
                   setC12R2VNo(1);
                   setC12R3VNo(1);
                   setC12R4VNo(1);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(1);
                   setC12R2VNo(1);
                   setC12R3VNo(1);
                   setC12R4VNo(1);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(1);
                    setC12R2VNo(1);
                    setC12R3VNo(1);
                    setC12R4VNo(1);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 2 || c12R2VNo === 2 || c12R3VNo === 2 || c12R4VNo === 2){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(2);
                   setC12R2VNo(2);
                   setC12R3VNo(2);
                   setC12R4VNo(2);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(2);
                   setC12R2VNo(2);
                   setC12R3VNo(2);
                   setC12R4VNo(2);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(2);
                   setC12R2VNo(2);
                   setC12R3VNo(2);
                   setC12R4VNo(2);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(2);
                    setC12R2VNo(2);
                    setC12R3VNo(2);
                    setC12R4VNo(2);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 3 || c12R2VNo === 3 || c12R3VNo === 3 || c12R4VNo === 3){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(3);
                   setC12R2VNo(3);
                   setC12R3VNo(3);
                   setC12R4VNo(3);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(3);
                   setC12R2VNo(3);
                   setC12R3VNo(3);
                   setC12R4VNo(3);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(3);
                   setC12R2VNo(3);
                   setC12R3VNo(3);
                   setC12R4VNo(3);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(3);
                    setC12R2VNo(3);
                    setC12R3VNo(3);
                    setC12R4VNo(3);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 4 || c12R2VNo === 4 || c12R3VNo === 4 || c12R4VNo === 4){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(4);
                   setC12R2VNo(4);
                   setC12R3VNo(4);
                   setC12R4VNo(4);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(4);
                   setC12R2VNo(4);
                   setC12R3VNo(4);
                   setC12R4VNo(4);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(4);
                   setC12R2VNo(4);
                   setC12R3VNo(4);
                   setC12R4VNo(4);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(4);
                    setC12R2VNo(4);
                    setC12R3VNo(4);
                    setC12R4VNo(4);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 5 || c12R2VNo === 5 || c12R3VNo === 5 || c12R4VNo === 5){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(5);
                   setC12R2VNo(5);
                   setC12R3VNo(5);
                   setC12R4VNo(5);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(5);
                   setC12R2VNo(5);
                   setC12R3VNo(5);
                   setC12R4VNo(5);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(5);
                   setC12R2VNo(5);
                   setC12R3VNo(5);
                   setC12R4VNo(5);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(5);
                    setC12R2VNo(5);
                    setC12R3VNo(5);
                    setC12R4VNo(5);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 6 || c12R2VNo === 6 || c12R3VNo === 6 || c12R4VNo === 6){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(6);
                   setC12R2VNo(6);
                   setC12R3VNo(6);
                   setC12R4VNo(6);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(6);
                   setC12R2VNo(6);
                   setC12R3VNo(6);
                   setC12R4VNo(6);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(6);
                   setC12R2VNo(6);
                   setC12R3VNo(6);
                   setC12R4VNo(6);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(6);
                    setC12R2VNo(6);
                    setC12R3VNo(6);
                    setC12R4VNo(6);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 7 || c12R2VNo === 7 || c12R3VNo === 7 || c12R4VNo === 7){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(7);
                   setC12R2VNo(7);
                   setC12R3VNo(7);
                   setC12R4VNo(7);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(7);
                   setC12R2VNo(7);
                   setC12R3VNo(7);
                   setC12R4VNo(7);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(7);
                   setC12R2VNo(7);
                   setC12R3VNo(7);
                   setC12R4VNo(7);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(7);
                    setC12R2VNo(7);
                    setC12R3VNo(7);
                    setC12R4VNo(7);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 8 || c12R2VNo === 8 || c12R3VNo === 8 || c12R4VNo === 8){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(8);
                   setC12R2VNo(8);
                   setC12R3VNo(8);
                   setC12R4VNo(8);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(8);
                   setC12R2VNo(8);
                   setC12R3VNo(8);
                   setC12R4VNo(8);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(8);
                   setC12R2VNo(8);
                   setC12R3VNo(8);
                   setC12R4VNo(8);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(8);
                    setC12R2VNo(8);
                    setC12R3VNo(8);
                    setC12R4VNo(8);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 9 || c12R2VNo === 9 || c12R3VNo === 9 || c12R4VNo === 9){
          if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(9);
                   setC12R2VNo(9);
                   setC12R3VNo(9);
                   setC12R4VNo(9);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                
                 } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(9);
                   setC12R2VNo(9);
                   setC12R3VNo(9);
                   setC12R4VNo(9);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(true)
                 } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(9);
                   setC12R2VNo(9);
                   setC12R3VNo(9);
                   setC12R4VNo(9);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(true)
                  } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(9);
                    setC12R2VNo(9);
                    setC12R3VNo(9);
                    setC12R4VNo(9);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c12R1VNo === 10 || c12R2VNo === 10 || c12R3VNo === 10 || c12R4VNo === 10){

        if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
          setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(10);
                 setC12R2VNo(10);
                 setC12R3VNo(10);
                 setC12R4VNo(10);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(true)
              
               } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(10);
                 setC12R2VNo(10);
                 setC12R3VNo(10);
                 setC12R4VNo(10);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(true)
               } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(10);
                 setC12R2VNo(10);
                 setC12R3VNo(10);
                 setC12R4VNo(10);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(true)
                } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(10);
                  setC12R2VNo(10);
                  setC12R3VNo(10);
                  setC12R4VNo(10);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(true)
                } else {
                  alert("Already added")
                }
		} else if (c12R1VNo === 11 || c12R2VNo === 11 || c12R3VNo === 11 || c12R4VNo === 11){
                  if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === false) {
       
                    setBaseNakedL12(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                             ]
                           )
                           setC12R1VNo(11);
                           setC12R2VNo(11);
                           setC12R3VNo(11);
                           setC12R4VNo(11);
                           setSepOn2LC12(false);
                           setSepOnLC12(false);
                           setSepOn3LC12(true)
                        
                         } else if (sepOn3LC12 === false && sepOnLC12 === false && sepOn2LC12 === true) {
                           setBaseNakedL12(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                             ]
                           )
                           setC12R1VNo(11);
                           setC12R2VNo(11);
                           setC12R3VNo(11);
                           setC12R4VNo(11);
                           setSepOn2LC12(true);
                           setSepOnLC12(false);
                           setSepOn3LC12(true)
                         } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === false) {
                           setBaseNakedL12(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                             ]
                           )
                           setC12R1VNo(11);
                           setC12R2VNo(11);
                           setC12R3VNo(11);
                           setC12R4VNo(11);
                           setSepOn2LC12(false);
                           setSepOnLC12(true);
                           setSepOn3LC12(true)
                          } else if (sepOn3LC12 === false && sepOnLC12 === true && sepOn2LC12 === true) {
                            setBaseNakedL12(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(11);
                            setC12R2VNo(11);
                            setC12R3VNo(11);
                            setC12R4VNo(11);
                            setSepOn2LC12(true);
                            setSepOnLC12(true);
                            setSepOn3LC12(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col13X && curY === row01X) || (curX === col13X && curY === row02X) || (curX === col13X && curY === row03X) || (curX === col13X && curY === row04X)) {
      if (c13r1MT === "L" || c13r2MT === "L" || c13r3MT === "L" || c13r4MT === "L") {
        if (c13R1VNo === 1 || c13R2VNo === 1 || c13R3VNo === 1 || c13R4VNo === 1){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(1);
                   setC13R2VNo(1);
                   setC13R3VNo(1);
                   setC13R4VNo(1);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(1);
                   setC13R2VNo(1);
                   setC13R3VNo(1);
                   setC13R4VNo(1);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(1);
                   setC13R2VNo(1);
                   setC13R3VNo(1);
                   setC13R4VNo(1);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(1);
                    setC13R2VNo(1);
                    setC13R3VNo(1);
                    setC13R4VNo(1);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 2 || c13R2VNo === 2 || c13R3VNo === 2 || c13R4VNo === 2){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(2);
                   setC13R2VNo(2);
                   setC13R3VNo(2);
                   setC13R4VNo(2);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(2);
                   setC13R2VNo(2);
                   setC13R3VNo(2);
                   setC13R4VNo(2);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(2);
                   setC13R2VNo(2);
                   setC13R3VNo(2);
                   setC13R4VNo(2);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(2);
                    setC13R2VNo(2);
                    setC13R3VNo(2);
                    setC13R4VNo(2);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 3 || c13R2VNo === 3 || c13R3VNo === 3 || c13R4VNo === 3){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(3);
                   setC13R2VNo(3);
                   setC13R3VNo(3);
                   setC13R4VNo(3);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(3);
                   setC13R2VNo(3);
                   setC13R3VNo(3);
                   setC13R4VNo(3);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(3);
                   setC13R2VNo(3);
                   setC13R3VNo(3);
                   setC13R4VNo(3);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(3);
                    setC13R2VNo(3);
                    setC13R3VNo(3);
                    setC13R4VNo(3);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 4 || c13R2VNo === 4 || c13R3VNo === 4 || c13R4VNo === 4){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(4);
                   setC13R2VNo(4);
                   setC13R3VNo(4);
                   setC13R4VNo(4);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(4);
                   setC13R2VNo(4);
                   setC13R3VNo(4);
                   setC13R4VNo(4);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(4);
                   setC13R2VNo(4);
                   setC13R3VNo(4);
                   setC13R4VNo(4);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(4);
                    setC13R2VNo(4);
                    setC13R3VNo(4);
                    setC13R4VNo(4);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 5 || c13R2VNo === 5 || c13R3VNo === 5 || c13R4VNo === 5){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(5);
                   setC13R2VNo(5);
                   setC13R3VNo(5);
                   setC13R4VNo(5);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(5);
                   setC13R2VNo(5);
                   setC13R3VNo(5);
                   setC13R4VNo(5);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(5);
                   setC13R2VNo(5);
                   setC13R3VNo(5);
                   setC13R4VNo(5);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(5);
                    setC13R2VNo(5);
                    setC13R3VNo(5);
                    setC13R4VNo(5);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 6 || c13R2VNo === 6 || c13R3VNo === 6 || c13R4VNo === 6){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(6);
                   setC13R2VNo(6);
                   setC13R3VNo(6);
                   setC13R4VNo(6);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(6);
                   setC13R2VNo(6);
                   setC13R3VNo(6);
                   setC13R4VNo(6);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(6);
                   setC13R2VNo(6);
                   setC13R3VNo(6);
                   setC13R4VNo(6);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(6);
                    setC13R2VNo(6);
                    setC13R3VNo(6);
                    setC13R4VNo(6);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 7 || c13R2VNo === 7 || c13R3VNo === 7 || c13R4VNo === 7){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(7);
                   setC13R2VNo(7);
                   setC13R3VNo(7);
                   setC13R4VNo(7);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(7);
                   setC13R2VNo(7);
                   setC13R3VNo(7);
                   setC13R4VNo(7);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(7);
                   setC13R2VNo(7);
                   setC13R3VNo(7);
                   setC13R4VNo(7);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(7);
                    setC13R2VNo(7);
                    setC13R3VNo(7);
                    setC13R4VNo(7);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 8 || c13R2VNo === 8 || c13R3VNo === 8 || c13R4VNo === 8){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(8);
                   setC13R2VNo(8);
                   setC13R3VNo(8);
                   setC13R4VNo(8);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(8);
                   setC13R2VNo(8);
                   setC13R3VNo(8);
                   setC13R4VNo(8);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(8);
                   setC13R2VNo(8);
                   setC13R3VNo(8);
                   setC13R4VNo(8);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(8);
                    setC13R2VNo(8);
                    setC13R3VNo(8);
                    setC13R4VNo(8);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 9 || c13R2VNo === 9 || c13R3VNo === 9 || c13R4VNo === 9){
          if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(9);
                   setC13R2VNo(9);
                   setC13R3VNo(9);
                   setC13R4VNo(9);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                
                 } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(9);
                   setC13R2VNo(9);
                   setC13R3VNo(9);
                   setC13R4VNo(9);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(true)
                 } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(9);
                   setC13R2VNo(9);
                   setC13R3VNo(9);
                   setC13R4VNo(9);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(true)
                  } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(9);
                    setC13R2VNo(9);
                    setC13R3VNo(9);
                    setC13R4VNo(9);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c13R1VNo === 10 || c13R2VNo === 10 || c13R3VNo === 10 || c13R4VNo === 10){

        if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
          setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(10);
                 setC13R2VNo(10);
                 setC13R3VNo(10);
                 setC13R4VNo(10);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(true)
              
               } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(10);
                 setC13R2VNo(10);
                 setC13R3VNo(10);
                 setC13R4VNo(10);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(true)
               } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(10);
                 setC13R2VNo(10);
                 setC13R3VNo(10);
                 setC13R4VNo(10);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(true)
                } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(10);
                  setC13R2VNo(10);
                  setC13R3VNo(10);
                  setC13R4VNo(10);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(true)
                } else {
                  alert("Already added")
                }
		} else if (c13R1VNo === 11 || c13R2VNo === 11 || c13R3VNo === 11 || c13R4VNo === 11){
                  if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === false) {
       
                    setBaseNakedL13(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                             ]
                           )
                           setC13R1VNo(11);
                           setC13R2VNo(11);
                           setC13R3VNo(11);
                           setC13R4VNo(11);
                           setSepOn2LC13(false);
                           setSepOnLC13(false);
                           setSepOn3LC13(true)
                        
                         } else if (sepOn3LC13 === false && sepOnLC13 === false && sepOn2LC13 === true) {
                           setBaseNakedL13(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                             ]
                           )
                           setC13R1VNo(11);
                           setC13R2VNo(11);
                           setC13R3VNo(11);
                           setC13R4VNo(11);
                           setSepOn2LC13(true);
                           setSepOnLC13(false);
                           setSepOn3LC13(true)
                         } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === false) {
                           setBaseNakedL13(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                             ]
                           )
                           setC13R1VNo(11);
                           setC13R2VNo(11);
                           setC13R3VNo(11);
                           setC13R4VNo(11);
                           setSepOn2LC13(false);
                           setSepOnLC13(true);
                           setSepOn3LC13(true)
                          } else if (sepOn3LC13 === false && sepOnLC13 === true && sepOn2LC13 === true) {
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(11);
                            setC13R2VNo(11);
                            setC13R3VNo(11);
                            setC13R4VNo(11);
                            setSepOn2LC13(true);
                            setSepOnLC13(true);
                            setSepOn3LC13(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    }  else if ((curX === col14X && curY === row01X) || (curX === col14X && curY === row02X) || (curX === col14X && curY === row03X) || (curX === col14X && curY === row04X)) {
      if (c14r1MT === "L" || c14r2MT === "L" || c14r3MT === "L" || c14r4MT === "L") {
        if (c14R1VNo === 1 || c14R2VNo === 1 || c14R3VNo === 1 || c14R4VNo === 1){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(1);
                   setC14R2VNo(1);
                   setC14R3VNo(1);
                   setC14R4VNo(1);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(1);
                   setC14R2VNo(1);
                   setC14R3VNo(1);
                   setC14R4VNo(1);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(1);
                   setC14R2VNo(1);
                   setC14R3VNo(1);
                   setC14R4VNo(1);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(1);
                    setC14R2VNo(1);
                    setC14R3VNo(1);
                    setC14R4VNo(1);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 2 || c14R2VNo === 2 || c14R3VNo === 2 || c14R4VNo === 2){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(2);
                   setC14R2VNo(2);
                   setC14R3VNo(2);
                   setC14R4VNo(2);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(2);
                   setC14R2VNo(2);
                   setC14R3VNo(2);
                   setC14R4VNo(2);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(2);
                   setC14R2VNo(2);
                   setC14R3VNo(2);
                   setC14R4VNo(2);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(2);
                    setC14R2VNo(2);
                    setC14R3VNo(2);
                    setC14R4VNo(2);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 3 || c14R2VNo === 3 || c14R3VNo === 3 || c14R4VNo === 3){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(3);
                   setC14R2VNo(3);
                   setC14R3VNo(3);
                   setC14R4VNo(3);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(3);
                   setC14R2VNo(3);
                   setC14R3VNo(3);
                   setC14R4VNo(3);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(3);
                   setC14R2VNo(3);
                   setC14R3VNo(3);
                   setC14R4VNo(3);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(3);
                    setC14R2VNo(3);
                    setC14R3VNo(3);
                    setC14R4VNo(3);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 4 || c14R2VNo === 4 || c14R3VNo === 4 || c14R4VNo === 4){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(4);
                   setC14R2VNo(4);
                   setC14R3VNo(4);
                   setC14R4VNo(4);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(4);
                   setC14R2VNo(4);
                   setC14R3VNo(4);
                   setC14R4VNo(4);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(4);
                   setC14R2VNo(4);
                   setC14R3VNo(4);
                   setC14R4VNo(4);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(4);
                    setC14R2VNo(4);
                    setC14R3VNo(4);
                    setC14R4VNo(4);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 5 || c14R2VNo === 5 || c14R3VNo === 5 || c14R4VNo === 5){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(5);
                   setC14R2VNo(5);
                   setC14R3VNo(5);
                   setC14R4VNo(5);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(5);
                   setC14R2VNo(5);
                   setC14R3VNo(5);
                   setC14R4VNo(5);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(5);
                   setC14R2VNo(5);
                   setC14R3VNo(5);
                   setC14R4VNo(5);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(5);
                    setC14R2VNo(5);
                    setC14R3VNo(5);
                    setC14R4VNo(5);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 6 || c14R2VNo === 6 || c14R3VNo === 6 || c14R4VNo === 6){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(6);
                   setC14R2VNo(6);
                   setC14R3VNo(6);
                   setC14R4VNo(6);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(6);
                   setC14R2VNo(6);
                   setC14R3VNo(6);
                   setC14R4VNo(6);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(6);
                   setC14R2VNo(6);
                   setC14R3VNo(6);
                   setC14R4VNo(6);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(6);
                    setC14R2VNo(6);
                    setC14R3VNo(6);
                    setC14R4VNo(6);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 7 || c14R2VNo === 7 || c14R3VNo === 7 || c14R4VNo === 7){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(7);
                   setC14R2VNo(7);
                   setC14R3VNo(7);
                   setC14R4VNo(7);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(7);
                   setC14R2VNo(7);
                   setC14R3VNo(7);
                   setC14R4VNo(7);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(7);
                   setC14R2VNo(7);
                   setC14R3VNo(7);
                   setC14R4VNo(7);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(7);
                    setC14R2VNo(7);
                    setC14R3VNo(7);
                    setC14R4VNo(7);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 8 || c14R2VNo === 8 || c14R3VNo === 8 || c14R4VNo === 8){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(8);
                   setC14R2VNo(8);
                   setC14R3VNo(8);
                   setC14R4VNo(8);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(8);
                   setC14R2VNo(8);
                   setC14R3VNo(8);
                   setC14R4VNo(8);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(8);
                   setC14R2VNo(8);
                   setC14R3VNo(8);
                   setC14R4VNo(8);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(8);
                    setC14R2VNo(8);
                    setC14R3VNo(8);
                    setC14R4VNo(8);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 9 || c14R2VNo === 9 || c14R3VNo === 9 || c14R4VNo === 9){
          if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(9);
                   setC14R2VNo(9);
                   setC14R3VNo(9);
                   setC14R4VNo(9);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                
                 } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(9);
                   setC14R2VNo(9);
                   setC14R3VNo(9);
                   setC14R4VNo(9);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(true)
                 } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(9);
                   setC14R2VNo(9);
                   setC14R3VNo(9);
                   setC14R4VNo(9);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(true)
                  } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(9);
                    setC14R2VNo(9);
                    setC14R3VNo(9);
                    setC14R4VNo(9);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(true)
                  } else {
                    alert("Already added")
                  }
        } else if (c14R1VNo === 10 || c14R2VNo === 10 || c14R3VNo === 10 || c14R4VNo === 10){

        if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
          setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(10);
                 setC14R2VNo(10);
                 setC14R3VNo(10);
                 setC14R4VNo(10);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(true)
              
               } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(10);
                 setC14R2VNo(10);
                 setC14R3VNo(10);
                 setC14R4VNo(10);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(true)
               } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(10);
                 setC14R2VNo(10);
                 setC14R3VNo(10);
                 setC14R4VNo(10);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(true)
                } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(10);
                  setC14R2VNo(10);
                  setC14R3VNo(10);
                  setC14R4VNo(10);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(true)
                } else {
                  alert("Already added")
                }
		} else if (c14R1VNo === 11 || c14R2VNo === 11 || c14R3VNo === 11 || c14R4VNo === 11){
                  if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === false) {
       
                    setBaseNakedL14(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                             ]
                           )
                           setC14R1VNo(11);
                           setC14R2VNo(11);
                           setC14R3VNo(11);
                           setC14R4VNo(11);
                           setSepOn2LC14(false);
                           setSepOnLC14(false);
                           setSepOn3LC14(true)
                        
                         } else if (sepOn3LC14 === false && sepOnLC14 === false && sepOn2LC14 === true) {
                           setBaseNakedL14(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                             ]
                           )
                           setC14R1VNo(11);
                           setC14R2VNo(11);
                           setC14R3VNo(11);
                           setC14R4VNo(11);
                           setSepOn2LC14(true);
                           setSepOnLC14(false);
                           setSepOn3LC14(true)
                         } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === false) {
                           setBaseNakedL14(
                             [
                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                             ]
                           )
                           setC14R1VNo(11);
                           setC14R2VNo(11);
                           setC14R3VNo(11);
                           setC14R4VNo(11);
                           setSepOn2LC14(false);
                           setSepOnLC14(true);
                           setSepOn3LC14(true)
                          } else if (sepOn3LC14 === false && sepOnLC14 === true && sepOn2LC14 === true) {
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(11);
                            setC14R2VNo(11);
                            setC14R3VNo(11);
                            setC14R4VNo(11);
                            setSepOn2LC14(true);
                            setSepOnLC14(true);
                            setSepOn3LC14(true)
                          } else {
                            alert("Already added")
                          }
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } 
  } 

  const removeeperatorTop = (e)=> {
    if ((curX === col01X && curY === row01X) || (curX === col01X && curY === row02X) || (curX === col01X && curY === row03X) || (curX === col01X && curY === row04X)) {
      if (c1r1MT === "L" || c1r2MT === "L" || c1r3MT === "L" || c1r4MT === "L") {
        if (c1R1VNo === 1 || c1R2VNo === 1 || c1R3VNo === 1 || c1R4VNo === 1){
          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(1);
                   setC1R2VNo(1);
                   setC1R3VNo(1);
                   setC1R4VNo(1);
                   setSepOn2LC1(false);
                   setSepOnLC1(false);
                   setSepOn3LC1(false)
                
                 } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(1);
                   setC1R2VNo(1);
                   setC1R3VNo(1);
                   setC1R4VNo(1);
                   setSepOn2LC1(true);
                   setSepOnLC1(false);
                   setSepOn3LC1(false)
                 } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                   setBaseNakedL01(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(1);
                   setC1R2VNo(1);
                   setC1R3VNo(1);
                   setC1R4VNo(1);
                   setSepOn2LC1(false);
                   setSepOnLC1(true);
                   setSepOn3LC1(false)
                  } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(1);
                    setC1R2VNo(1);
                    setC1R3VNo(1);
                    setC1R4VNo(1);
                    setSepOn2LC1(true);
                    setSepOnLC1(true);
                    setSepOn3LC1(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c1R1VNo === 2 || c1R2VNo === 2 || c1R3VNo === 2 || c1R4VNo === 2){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(2);
                 setC1R2VNo(2);
                 setC1R3VNo(2);
                 setC1R4VNo(2);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(2);
                 setC1R2VNo(2);
                 setC1R3VNo(2);
                 setC1R4VNo(2);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(2);
                 setC1R2VNo(2);
                 setC1R3VNo(2);
                 setC1R4VNo(2);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(2);
                  setC1R2VNo(2);
                  setC1R3VNo(2);
                  setC1R4VNo(2);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 3 || c1R2VNo === 3 || c1R3VNo === 3 || c1R4VNo === 3){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(3);
                 setC1R2VNo(3);
                 setC1R3VNo(3);
                 setC1R4VNo(3);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(3);
                 setC1R2VNo(3);
                 setC1R3VNo(3);
                 setC1R4VNo(3);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(3);
                 setC1R2VNo(3);
                 setC1R3VNo(3);
                 setC1R4VNo(3);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(3);
                  setC1R2VNo(3);
                  setC1R3VNo(3);
                  setC1R4VNo(3);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 4 || c1R2VNo === 4 || c1R3VNo === 4 || c1R4VNo === 4){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(4);
                 setC1R2VNo(4);
                 setC1R3VNo(4);
                 setC1R4VNo(4);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(4);
                 setC1R2VNo(4);
                 setC1R3VNo(4);
                 setC1R4VNo(4);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(4);
                 setC1R2VNo(4);
                 setC1R3VNo(4);
                 setC1R4VNo(4);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(4);
                  setC1R2VNo(4);
                  setC1R3VNo(4);
                  setC1R4VNo(4);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 5 || c1R2VNo === 5 || c1R3VNo === 5 || c1R4VNo === 5){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(5);
                 setC1R2VNo(5);
                 setC1R3VNo(5);
                 setC1R4VNo(5);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(5);
                 setC1R2VNo(5);
                 setC1R3VNo(5);
                 setC1R4VNo(5);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(5);
                 setC1R2VNo(5);
                 setC1R3VNo(5);
                 setC1R4VNo(5);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(5);
                  setC1R2VNo(5);
                  setC1R3VNo(5);
                  setC1R4VNo(5);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 6 || c1R2VNo === 6 || c1R3VNo === 6 || c1R4VNo === 6){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(6);
                 setC1R2VNo(6);
                 setC1R3VNo(6);
                 setC1R4VNo(6);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(6);
                 setC1R2VNo(6);
                 setC1R3VNo(6);
                 setC1R4VNo(6);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(6);
                 setC1R2VNo(6);
                 setC1R3VNo(6);
                 setC1R4VNo(6);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(6);
                  setC1R2VNo(6);
                  setC1R3VNo(6);
                  setC1R4VNo(6);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 7 || c1R2VNo === 7 || c1R3VNo === 7 || c1R4VNo === 7){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(7);
                 setC1R2VNo(7);
                 setC1R3VNo(7);
                 setC1R4VNo(7);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(7);
                 setC1R2VNo(7);
                 setC1R3VNo(7);
                 setC1R4VNo(7);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(7);
                 setC1R2VNo(7);
                 setC1R3VNo(7);
                 setC1R4VNo(7);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(7);
                  setC1R2VNo(7);
                  setC1R3VNo(7);
                  setC1R4VNo(7);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 8 || c1R2VNo === 8 || c1R3VNo === 8 || c1R4VNo === 8){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(8);
                 setC1R2VNo(8);
                 setC1R3VNo(8);
                 setC1R4VNo(8);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(8);
                 setC1R2VNo(8);
                 setC1R3VNo(8);
                 setC1R4VNo(8);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(8);
                 setC1R2VNo(8);
                 setC1R3VNo(8);
                 setC1R4VNo(8);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(8);
                  setC1R2VNo(8);
                  setC1R3VNo(8);
                  setC1R4VNo(8);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 9 || c1R2VNo === 9 || c1R3VNo === 9 || c1R4VNo === 9){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(9);
                 setC1R2VNo(9);
                 setC1R3VNo(9);
                 setC1R4VNo(9);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(9);
                 setC1R2VNo(9);
                 setC1R3VNo(9);
                 setC1R4VNo(9);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(9);
                 setC1R2VNo(9);
                 setC1R3VNo(9);
                 setC1R4VNo(9);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(9);
                  setC1R2VNo(9);
                  setC1R3VNo(9);
                  setC1R4VNo(9);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 10 || c1R2VNo === 10 || c1R3VNo === 10 || c1R4VNo === 10){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(10);
                 setC1R2VNo(10);
                 setC1R3VNo(10);
                 setC1R4VNo(10);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(10);
                 setC1R2VNo(10);
                 setC1R3VNo(10);
                 setC1R4VNo(10);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(10);
                 setC1R2VNo(10);
                 setC1R3VNo(10);
                 setC1R4VNo(10);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(10);
                  setC1R2VNo(10);
                  setC1R3VNo(10);
                  setC1R4VNo(10);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c1R1VNo === 11 || c1R2VNo === 11 || c1R3VNo === 11 || c1R4VNo === 11){

          if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === false) {
       
            setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(11);
                 setC1R2VNo(11);
                 setC1R3VNo(11);
                 setC1R4VNo(11);
                 setSepOn2LC1(false);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
              
               } else if (sepOn3LC1 === true && sepOnLC1 === false && sepOn2LC1 === true) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(11);
                 setC1R2VNo(11);
                 setC1R3VNo(11);
                 setC1R4VNo(11);
                 setSepOn2LC1(true);
                 setSepOnLC1(false);
                 setSepOn3LC1(false)
               } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === false) {
                 setBaseNakedL01(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
                 )
                 setC1R1VNo(11);
                 setC1R2VNo(11);
                 setC1R3VNo(11);
                 setC1R4VNo(11);
                 setSepOn2LC1(false);
                 setSepOnLC1(true);
                 setSepOn3LC1(false)
                } else if (sepOn3LC1 === true && sepOnLC1 === true && sepOn2LC1 === true) {
                  setBaseNakedL01(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(11);
                  setC1R2VNo(11);
                  setC1R3VNo(11);
                  setC1R4VNo(11);
                  setSepOn2LC1(true);
                  setSepOnLC1(true);
                  setSepOn3LC1(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col02X && curY === row01X) || (curX === col02X && curY === row02X) || (curX === col02X && curY === row03X) || (curX === col02X && curY === row04X)) {
      if (c2r1MT === "L" || c2r2MT === "L" || c2r3MT === "L" || c2r4MT === "L") {
        if (c2R1VNo === 1 || c2R2VNo === 1 || c2R3VNo === 1 || c2R4VNo === 1){
          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(1);
                   setC2R2VNo(1);
                   setC2R3VNo(1);
                   setC2R4VNo(1);
                   setSepOn2LC2(false);
                   setSepOnLC2(false);
                   setSepOn3LC2(false)
                
                 } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(1);
                   setC2R2VNo(1);
                   setC2R3VNo(1);
                   setC2R4VNo(1);
                   setSepOn2LC2(true);
                   setSepOnLC2(false);
                   setSepOn3LC2(false)
                 } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                   setBaseNakedL02(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(1);
                   setC2R2VNo(1);
                   setC2R3VNo(1);
                   setC2R4VNo(1);
                   setSepOn2LC2(false);
                   setSepOnLC2(true);
                   setSepOn3LC2(false)
                  } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(1);
                    setC2R2VNo(1);
                    setC2R3VNo(1);
                    setC2R4VNo(1);
                    setSepOn2LC2(true);
                    setSepOnLC2(true);
                    setSepOn3LC2(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c2R1VNo === 2 || c2R2VNo === 2 || c2R3VNo === 2 || c2R4VNo === 2){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(2);
                 setC2R2VNo(2);
                 setC2R3VNo(2);
                 setC2R4VNo(2);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(2);
                 setC2R2VNo(2);
                 setC2R3VNo(2);
                 setC2R4VNo(2);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(2);
                 setC2R2VNo(2);
                 setC2R3VNo(2);
                 setC2R4VNo(2);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(2);
                  setC2R2VNo(2);
                  setC2R3VNo(2);
                  setC2R4VNo(2);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 3 || c2R2VNo === 3 || c2R3VNo === 3 || c2R4VNo === 3){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(3);
                 setC2R2VNo(3);
                 setC2R3VNo(3);
                 setC2R4VNo(3);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(3);
                 setC2R2VNo(3);
                 setC2R3VNo(3);
                 setC2R4VNo(3);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(3);
                 setC2R2VNo(3);
                 setC2R3VNo(3);
                 setC2R4VNo(3);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(3);
                  setC2R2VNo(3);
                  setC2R3VNo(3);
                  setC2R4VNo(3);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 4 || c2R2VNo === 4 || c2R3VNo === 4 || c2R4VNo === 4){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(4);
                 setC2R2VNo(4);
                 setC2R3VNo(4);
                 setC2R4VNo(4);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(4);
                 setC2R2VNo(4);
                 setC2R3VNo(4);
                 setC2R4VNo(4);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(4);
                 setC2R2VNo(4);
                 setC2R3VNo(4);
                 setC2R4VNo(4);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(4);
                  setC2R2VNo(4);
                  setC2R3VNo(4);
                  setC2R4VNo(4);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 5 || c2R2VNo === 5 || c2R3VNo === 5 || c2R4VNo === 5){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(5);
                 setC2R2VNo(5);
                 setC2R3VNo(5);
                 setC2R4VNo(5);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(5);
                 setC2R2VNo(5);
                 setC2R3VNo(5);
                 setC2R4VNo(5);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(5);
                 setC2R2VNo(5);
                 setC2R3VNo(5);
                 setC2R4VNo(5);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(5);
                  setC2R2VNo(5);
                  setC2R3VNo(5);
                  setC2R4VNo(5);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 6 || c2R2VNo === 6 || c2R3VNo === 6 || c2R4VNo === 6){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(6);
                 setC2R2VNo(6);
                 setC2R3VNo(6);
                 setC2R4VNo(6);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(6);
                 setC2R2VNo(6);
                 setC2R3VNo(6);
                 setC2R4VNo(6);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(6);
                 setC2R2VNo(6);
                 setC2R3VNo(6);
                 setC2R4VNo(6);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(6);
                  setC2R2VNo(6);
                  setC2R3VNo(6);
                  setC2R4VNo(6);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 7 || c2R2VNo === 7 || c2R3VNo === 7 || c2R4VNo === 7){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(7);
                 setC2R2VNo(7);
                 setC2R3VNo(7);
                 setC2R4VNo(7);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(7);
                 setC2R2VNo(7);
                 setC2R3VNo(7);
                 setC2R4VNo(7);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(7);
                 setC2R2VNo(7);
                 setC2R3VNo(7);
                 setC2R4VNo(7);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(7);
                  setC2R2VNo(7);
                  setC2R3VNo(7);
                  setC2R4VNo(7);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 8 || c2R2VNo === 8 || c2R3VNo === 8 || c2R4VNo === 8){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(8);
                 setC2R2VNo(8);
                 setC2R3VNo(8);
                 setC2R4VNo(8);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(8);
                 setC2R2VNo(8);
                 setC2R3VNo(8);
                 setC2R4VNo(8);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(8);
                 setC2R2VNo(8);
                 setC2R3VNo(8);
                 setC2R4VNo(8);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(8);
                  setC2R2VNo(8);
                  setC2R3VNo(8);
                  setC2R4VNo(8);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 9 || c2R2VNo === 9 || c2R3VNo === 9 || c2R4VNo === 9){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(9);
                 setC2R2VNo(9);
                 setC2R3VNo(9);
                 setC2R4VNo(9);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(9);
                 setC2R2VNo(9);
                 setC2R3VNo(9);
                 setC2R4VNo(9);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(9);
                 setC2R2VNo(9);
                 setC2R3VNo(9);
                 setC2R4VNo(9);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(9);
                  setC2R2VNo(9);
                  setC2R3VNo(9);
                  setC2R4VNo(9);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 10 || c2R2VNo === 10 || c2R3VNo === 10 || c2R4VNo === 10){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(10);
                 setC2R2VNo(10);
                 setC2R3VNo(10);
                 setC2R4VNo(10);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(10);
                 setC2R2VNo(10);
                 setC2R3VNo(10);
                 setC2R4VNo(10);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(10);
                 setC2R2VNo(10);
                 setC2R3VNo(10);
                 setC2R4VNo(10);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(10);
                  setC2R2VNo(10);
                  setC2R3VNo(10);
                  setC2R4VNo(10);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c2R1VNo === 11 || c2R2VNo === 11 || c2R3VNo === 11 || c2R4VNo === 11){

          if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === false) {
       
            setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(11);
                 setC2R2VNo(11);
                 setC2R3VNo(11);
                 setC2R4VNo(11);
                 setSepOn2LC2(false);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
              
               } else if (sepOn3LC2 === true && sepOnLC2 === false && sepOn2LC2 === true) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(11);
                 setC2R2VNo(11);
                 setC2R3VNo(11);
                 setC2R4VNo(11);
                 setSepOn2LC2(true);
                 setSepOnLC2(false);
                 setSepOn3LC2(false)
               } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === false) {
                 setBaseNakedL02(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(11);
                 setC2R2VNo(11);
                 setC2R3VNo(11);
                 setC2R4VNo(11);
                 setSepOn2LC2(false);
                 setSepOnLC2(true);
                 setSepOn3LC2(false)
                } else if (sepOn3LC2 === true && sepOnLC2 === true && sepOn2LC2 === true) {
                  setBaseNakedL02(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(11);
                  setC2R2VNo(11);
                  setC2R3VNo(11);
                  setC2R4VNo(11);
                  setSepOn2LC2(true);
                  setSepOnLC2(true);
                  setSepOn3LC2(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col03X && curY === row01X) || (curX === col03X && curY === row02X) || (curX === col03X && curY === row03X) || (curX === col03X && curY === row04X)) {
      if (c3r1MT === "L" || c3r2MT === "L" || c3r3MT === "L" || c3r4MT === "L") {
        if (c3R1VNo === 1 || c3R2VNo === 1 || c3R3VNo === 1 || c3R4VNo === 1){
          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(1);
                   setC3R2VNo(1);
                   setC3R3VNo(1);
                   setC3R4VNo(1);
                   setSepOn2LC3(false);
                   setSepOnLC3(false);
                   setSepOn3LC3(false)
                
                 } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(1);
                   setC3R2VNo(1);
                   setC3R3VNo(1);
                   setC3R4VNo(1);
                   setSepOn2LC3(true);
                   setSepOnLC3(false);
                   setSepOn3LC3(false)
                 } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                   setBaseNakedL03(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                     ]
                   )
                   setC3R1VNo(1);
                   setC3R2VNo(1);
                   setC3R3VNo(1);
                   setC3R4VNo(1);
                   setSepOn2LC3(false);
                   setSepOnLC3(true);
                   setSepOn3LC3(false)
                  } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                    setBaseNakedL03(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(1);
                    setC3R2VNo(1);
                    setC3R3VNo(1);
                    setC3R4VNo(1);
                    setSepOn2LC3(true);
                    setSepOnLC3(true);
                    setSepOn3LC3(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c3R1VNo === 2 || c3R2VNo === 2 || c3R3VNo === 2 || c3R4VNo === 2){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(2);
                 setC3R2VNo(2);
                 setC3R3VNo(2);
                 setC3R4VNo(2);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(2);
                 setC3R2VNo(2);
                 setC3R3VNo(2);
                 setC3R4VNo(2);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(2);
                 setC3R2VNo(2);
                 setC3R3VNo(2);
                 setC3R4VNo(2);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(2);
                  setC3R2VNo(2);
                  setC3R3VNo(2);
                  setC3R4VNo(2);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 3 || c3R2VNo === 3 || c3R3VNo === 3 || c3R4VNo === 3){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(3);
                 setC3R2VNo(3);
                 setC3R3VNo(3);
                 setC3R4VNo(3);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(3);
                 setC3R2VNo(3);
                 setC3R3VNo(3);
                 setC3R4VNo(3);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(3);
                 setC3R2VNo(3);
                 setC3R3VNo(3);
                 setC3R4VNo(3);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(3);
                  setC3R2VNo(3);
                  setC3R3VNo(3);
                  setC3R4VNo(3);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 4 || c3R2VNo === 4 || c3R3VNo === 4 || c3R4VNo === 4){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(4);
                 setC3R2VNo(4);
                 setC3R3VNo(4);
                 setC3R4VNo(4);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(4);
                 setC3R2VNo(4);
                 setC3R3VNo(4);
                 setC3R4VNo(4);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(4);
                 setC3R2VNo(4);
                 setC3R3VNo(4);
                 setC3R4VNo(4);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(4);
                  setC3R2VNo(4);
                  setC3R3VNo(4);
                  setC3R4VNo(4);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 5 || c3R2VNo === 5 || c3R3VNo === 5 || c3R4VNo === 5){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(5);
                 setC3R2VNo(5);
                 setC3R3VNo(5);
                 setC3R4VNo(5);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(5);
                 setC3R2VNo(5);
                 setC3R3VNo(5);
                 setC3R4VNo(5);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(5);
                 setC3R2VNo(5);
                 setC3R3VNo(5);
                 setC3R4VNo(5);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(5);
                  setC3R2VNo(5);
                  setC3R3VNo(5);
                  setC3R4VNo(5);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 6 || c3R2VNo === 6 || c3R3VNo === 6 || c3R4VNo === 6){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(6);
                 setC3R2VNo(6);
                 setC3R3VNo(6);
                 setC3R4VNo(6);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(6);
                 setC3R2VNo(6);
                 setC3R3VNo(6);
                 setC3R4VNo(6);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(6);
                 setC3R2VNo(6);
                 setC3R3VNo(6);
                 setC3R4VNo(6);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(6);
                  setC3R2VNo(6);
                  setC3R3VNo(6);
                  setC3R4VNo(6);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 7 || c3R2VNo === 7 || c3R3VNo === 7 || c3R4VNo === 7){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(7);
                 setC3R2VNo(7);
                 setC3R3VNo(7);
                 setC3R4VNo(7);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(7);
                 setC3R2VNo(7);
                 setC3R3VNo(7);
                 setC3R4VNo(7);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(7);
                 setC3R2VNo(7);
                 setC3R3VNo(7);
                 setC3R4VNo(7);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(7);
                  setC3R2VNo(7);
                  setC3R3VNo(7);
                  setC3R4VNo(7);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 8 || c3R2VNo === 8 || c3R3VNo === 8 || c3R4VNo === 8){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(8);
                 setC3R2VNo(8);
                 setC3R3VNo(8);
                 setC3R4VNo(8);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(8);
                 setC3R2VNo(8);
                 setC3R3VNo(8);
                 setC3R4VNo(8);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(8);
                 setC3R2VNo(8);
                 setC3R3VNo(8);
                 setC3R4VNo(8);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(8);
                  setC3R2VNo(8);
                  setC3R3VNo(8);
                  setC3R4VNo(8);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 9 || c3R2VNo === 9 || c3R3VNo === 9 || c3R4VNo === 9){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(9);
                 setC3R2VNo(9);
                 setC3R3VNo(9);
                 setC3R4VNo(9);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(9);
                 setC3R2VNo(9);
                 setC3R3VNo(9);
                 setC3R4VNo(9);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(9);
                 setC3R2VNo(9);
                 setC3R3VNo(9);
                 setC3R4VNo(9);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(9);
                  setC3R2VNo(9);
                  setC3R3VNo(9);
                  setC3R4VNo(9);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 10 || c3R2VNo === 10 || c3R3VNo === 10 || c3R4VNo === 10){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(10);
                 setC3R2VNo(10);
                 setC3R3VNo(10);
                 setC3R4VNo(10);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(10);
                 setC3R2VNo(10);
                 setC3R3VNo(10);
                 setC3R4VNo(10);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(10);
                 setC3R2VNo(10);
                 setC3R3VNo(10);
                 setC3R4VNo(10);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(10);
                  setC3R2VNo(10);
                  setC3R3VNo(10);
                  setC3R4VNo(10);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c3R1VNo === 11 || c3R2VNo === 11 || c3R3VNo === 11 || c3R4VNo === 11){

          if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === false) {
       
            setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(11);
                 setC3R2VNo(11);
                 setC3R3VNo(11);
                 setC3R4VNo(11);
                 setSepOn2LC3(false);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
              
               } else if (sepOn3LC3 === true && sepOnLC3 === false && sepOn2LC3 === true) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(11);
                 setC3R2VNo(11);
                 setC3R3VNo(11);
                 setC3R4VNo(11);
                 setSepOn2LC3(true);
                 setSepOnLC3(false);
                 setSepOn3LC3(false)
               } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === false) {
                 setBaseNakedL03(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                   ]
                 )
                 setC3R1VNo(11);
                 setC3R2VNo(11);
                 setC3R3VNo(11);
                 setC3R4VNo(11);
                 setSepOn2LC3(false);
                 setSepOnLC3(true);
                 setSepOn3LC3(false)
                } else if (sepOn3LC3 === true && sepOnLC3 === true && sepOn2LC3 === true) {
                  setBaseNakedL03(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                    ]
                  )
                  setC3R1VNo(11);
                  setC3R2VNo(11);
                  setC3R3VNo(11);
                  setC3R4VNo(11);
                  setSepOn2LC3(true);
                  setSepOnLC3(true);
                  setSepOn3LC3(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col04X && curY === row01X) || (curX === col04X && curY === row02X) || (curX === col04X && curY === row03X) || (curX === col04X && curY === row04X)) {
      if (c4r1MT === "L" || c4r2MT === "L" || c4r3MT === "L" || c4r4MT === "L") {
        if (c4R1VNo === 1 || c4R2VNo === 1 || c4R3VNo === 1 || c4R4VNo === 1){
          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(1);
                   setC4R2VNo(1);
                   setC4R3VNo(1);
                   setC4R4VNo(1);
                   setSepOn2LC4(false);
                   setSepOnLC4(false);
                   setSepOn3LC4(false)
                
                 } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(1);
                   setC4R2VNo(1);
                   setC4R3VNo(1);
                   setC4R4VNo(1);
                   setSepOn2LC4(true);
                   setSepOnLC4(false);
                   setSepOn3LC4(false)
                 } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                   setBaseNakedL04(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                     ]
                   )
                   setC4R1VNo(1);
                   setC4R2VNo(1);
                   setC4R3VNo(1);
                   setC4R4VNo(1);
                   setSepOn2LC4(false);
                   setSepOnLC4(true);
                   setSepOn3LC4(false)
                  } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                    setBaseNakedL04(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                    )
                    setC4R1VNo(1);
                    setC4R2VNo(1);
                    setC4R3VNo(1);
                    setC4R4VNo(1);
                    setSepOn2LC4(true);
                    setSepOnLC4(true);
                    setSepOn3LC4(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c4R1VNo === 2 || c4R2VNo === 2 || c4R3VNo === 2 || c4R4VNo === 2){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(2);
                 setC4R2VNo(2);
                 setC4R3VNo(2);
                 setC4R4VNo(2);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(2);
                 setC4R2VNo(2);
                 setC4R3VNo(2);
                 setC4R4VNo(2);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(2);
                 setC4R2VNo(2);
                 setC4R3VNo(2);
                 setC4R4VNo(2);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(2);
                  setC4R2VNo(2);
                  setC4R3VNo(2);
                  setC4R4VNo(2);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 3 || c4R2VNo === 3 || c4R3VNo === 3 || c4R4VNo === 3){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(3);
                 setC4R2VNo(3);
                 setC4R3VNo(3);
                 setC4R4VNo(3);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(3);
                 setC4R2VNo(3);
                 setC4R3VNo(3);
                 setC4R4VNo(3);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(3);
                 setC4R2VNo(3);
                 setC4R3VNo(3);
                 setC4R4VNo(3);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(3);
                  setC4R2VNo(3);
                  setC4R3VNo(3);
                  setC4R4VNo(3);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 4 || c4R2VNo === 4 || c4R3VNo === 4 || c4R4VNo === 4){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(4);
                 setC4R2VNo(4);
                 setC4R3VNo(4);
                 setC4R4VNo(4);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(4);
                 setC4R2VNo(4);
                 setC4R3VNo(4);
                 setC4R4VNo(4);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(4);
                 setC4R2VNo(4);
                 setC4R3VNo(4);
                 setC4R4VNo(4);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(4);
                  setC4R2VNo(4);
                  setC4R3VNo(4);
                  setC4R4VNo(4);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 5 || c4R2VNo === 5 || c4R3VNo === 5 || c4R4VNo === 5){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(5);
                 setC4R2VNo(5);
                 setC4R3VNo(5);
                 setC4R4VNo(5);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(5);
                 setC4R2VNo(5);
                 setC4R3VNo(5);
                 setC4R4VNo(5);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(5);
                 setC4R2VNo(5);
                 setC4R3VNo(5);
                 setC4R4VNo(5);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(5);
                  setC4R2VNo(5);
                  setC4R3VNo(5);
                  setC4R4VNo(5);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 6 || c4R2VNo === 6 || c4R3VNo === 6 || c4R4VNo === 6){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(6);
                 setC4R2VNo(6);
                 setC4R3VNo(6);
                 setC4R4VNo(6);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(6);
                 setC4R2VNo(6);
                 setC4R3VNo(6);
                 setC4R4VNo(6);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(6);
                 setC4R2VNo(6);
                 setC4R3VNo(6);
                 setC4R4VNo(6);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(6);
                  setC4R2VNo(6);
                  setC4R3VNo(6);
                  setC4R4VNo(6);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 7 || c4R2VNo === 7 || c4R3VNo === 7 || c4R4VNo === 7){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(7);
                 setC4R2VNo(7);
                 setC4R3VNo(7);
                 setC4R4VNo(7);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(7);
                 setC4R2VNo(7);
                 setC4R3VNo(7);
                 setC4R4VNo(7);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(7);
                 setC4R2VNo(7);
                 setC4R3VNo(7);
                 setC4R4VNo(7);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(7);
                  setC4R2VNo(7);
                  setC4R3VNo(7);
                  setC4R4VNo(7);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 8 || c4R2VNo === 8 || c4R3VNo === 8 || c4R4VNo === 8){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(8);
                 setC4R2VNo(8);
                 setC4R3VNo(8);
                 setC4R4VNo(8);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(8);
                 setC4R2VNo(8);
                 setC4R3VNo(8);
                 setC4R4VNo(8);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(8);
                 setC4R2VNo(8);
                 setC4R3VNo(8);
                 setC4R4VNo(8);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(8);
                  setC4R2VNo(8);
                  setC4R3VNo(8);
                  setC4R4VNo(8);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 9 || c4R2VNo === 9 || c4R3VNo === 9 || c4R4VNo === 9){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(9);
                 setC4R2VNo(9);
                 setC4R3VNo(9);
                 setC4R4VNo(9);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(9);
                 setC4R2VNo(9);
                 setC4R3VNo(9);
                 setC4R4VNo(9);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(9);
                 setC4R2VNo(9);
                 setC4R3VNo(9);
                 setC4R4VNo(9);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(9);
                  setC4R2VNo(9);
                  setC4R3VNo(9);
                  setC4R4VNo(9);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 10 || c4R2VNo === 10 || c4R3VNo === 10 || c4R4VNo === 10){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(10);
                 setC4R2VNo(10);
                 setC4R3VNo(10);
                 setC4R4VNo(10);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(10);
                 setC4R2VNo(10);
                 setC4R3VNo(10);
                 setC4R4VNo(10);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(10);
                 setC4R2VNo(10);
                 setC4R3VNo(10);
                 setC4R4VNo(10);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(10);
                  setC4R2VNo(10);
                  setC4R3VNo(10);
                  setC4R4VNo(10);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c4R1VNo === 11 || c4R2VNo === 11 || c4R3VNo === 11 || c4R4VNo === 11){

          if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === false) {
       
            setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(11);
                 setC4R2VNo(11);
                 setC4R3VNo(11);
                 setC4R4VNo(11);
                 setSepOn2LC4(false);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
              
               } else if (sepOn3LC4 === true && sepOnLC4 === false && sepOn2LC4 === true) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(11);
                 setC4R2VNo(11);
                 setC4R3VNo(11);
                 setC4R4VNo(11);
                 setSepOn2LC4(true);
                 setSepOnLC4(false);
                 setSepOn3LC4(false)
               } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === false) {
                 setBaseNakedL04(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                   ]
                 )
                 setC4R1VNo(11);
                 setC4R2VNo(11);
                 setC4R3VNo(11);
                 setC4R4VNo(11);
                 setSepOn2LC4(false);
                 setSepOnLC4(true);
                 setSepOn3LC4(false)
                } else if (sepOn3LC4 === true && sepOnLC4 === true && sepOn2LC4 === true) {
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(11);
                  setC4R2VNo(11);
                  setC4R3VNo(11);
                  setC4R4VNo(11);
                  setSepOn2LC4(true);
                  setSepOnLC4(true);
                  setSepOn3LC4(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col05X && curY === row01X) || (curX === col05X && curY === row02X) || (curX === col05X && curY === row03X) || (curX === col05X && curY === row04X)) {
      if (c5r1MT === "L" || c5r2MT === "L" || c5r3MT === "L" || c5r4MT === "L") {
        if (c5R1VNo === 1 || c5R2VNo === 1 || c5R3VNo === 1 || c5R4VNo === 1){
          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(1);
                   setC5R2VNo(1);
                   setC5R3VNo(1);
                   setC5R4VNo(1);
                   setSepOn2LC5(false);
                   setSepOnLC5(false);
                   setSepOn3LC5(false)
                
                 } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(1);
                   setC5R2VNo(1);
                   setC5R3VNo(1);
                   setC5R4VNo(1);
                   setSepOn2LC5(true);
                   setSepOnLC5(false);
                   setSepOn3LC5(false)
                 } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                   setBaseNakedL05(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                     ]
                   )
                   setC5R1VNo(1);
                   setC5R2VNo(1);
                   setC5R3VNo(1);
                   setC5R4VNo(1);
                   setSepOn2LC5(false);
                   setSepOnLC5(true);
                   setSepOn3LC5(false)
                  } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(1);
                    setC5R2VNo(1);
                    setC5R3VNo(1);
                    setC5R4VNo(1);
                    setSepOn2LC5(true);
                    setSepOnLC5(true);
                    setSepOn3LC5(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c5R1VNo === 2 || c5R2VNo === 2 || c5R3VNo === 2 || c5R4VNo === 2){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(2);
                 setC5R2VNo(2);
                 setC5R3VNo(2);
                 setC5R4VNo(2);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(2);
                 setC5R2VNo(2);
                 setC5R3VNo(2);
                 setC5R4VNo(2);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(2);
                 setC5R2VNo(2);
                 setC5R3VNo(2);
                 setC5R4VNo(2);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(2);
                  setC5R2VNo(2);
                  setC5R3VNo(2);
                  setC5R4VNo(2);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 3 || c5R2VNo === 3 || c5R3VNo === 3 || c5R4VNo === 3){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(3);
                 setC5R2VNo(3);
                 setC5R3VNo(3);
                 setC5R4VNo(3);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(3);
                 setC5R2VNo(3);
                 setC5R3VNo(3);
                 setC5R4VNo(3);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(3);
                 setC5R2VNo(3);
                 setC5R3VNo(3);
                 setC5R4VNo(3);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(3);
                  setC5R2VNo(3);
                  setC5R3VNo(3);
                  setC5R4VNo(3);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 4 || c5R2VNo === 4 || c5R3VNo === 4 || c5R4VNo === 4){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(4);
                 setC5R2VNo(4);
                 setC5R3VNo(4);
                 setC5R4VNo(4);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(4);
                 setC5R2VNo(4);
                 setC5R3VNo(4);
                 setC5R4VNo(4);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(4);
                 setC5R2VNo(4);
                 setC5R3VNo(4);
                 setC5R4VNo(4);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(4);
                  setC5R2VNo(4);
                  setC5R3VNo(4);
                  setC5R4VNo(4);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 5 || c5R2VNo === 5 || c5R3VNo === 5 || c5R4VNo === 5){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(5);
                 setC5R2VNo(5);
                 setC5R3VNo(5);
                 setC5R4VNo(5);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(5);
                 setC5R2VNo(5);
                 setC5R3VNo(5);
                 setC5R4VNo(5);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(5);
                 setC5R2VNo(5);
                 setC5R3VNo(5);
                 setC5R4VNo(5);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(5);
                  setC5R2VNo(5);
                  setC5R3VNo(5);
                  setC5R4VNo(5);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 6 || c5R2VNo === 6 || c5R3VNo === 6 || c5R4VNo === 6){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(6);
                 setC5R2VNo(6);
                 setC5R3VNo(6);
                 setC5R4VNo(6);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(6);
                 setC5R2VNo(6);
                 setC5R3VNo(6);
                 setC5R4VNo(6);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(6);
                 setC5R2VNo(6);
                 setC5R3VNo(6);
                 setC5R4VNo(6);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(6);
                  setC5R2VNo(6);
                  setC5R3VNo(6);
                  setC5R4VNo(6);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 7 || c5R2VNo === 7 || c5R3VNo === 7 || c5R4VNo === 7){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(7);
                 setC5R2VNo(7);
                 setC5R3VNo(7);
                 setC5R4VNo(7);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(7);
                 setC5R2VNo(7);
                 setC5R3VNo(7);
                 setC5R4VNo(7);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(7);
                 setC5R2VNo(7);
                 setC5R3VNo(7);
                 setC5R4VNo(7);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(7);
                  setC5R2VNo(7);
                  setC5R3VNo(7);
                  setC5R4VNo(7);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 8 || c5R2VNo === 8 || c5R3VNo === 8 || c5R4VNo === 8){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(8);
                 setC5R2VNo(8);
                 setC5R3VNo(8);
                 setC5R4VNo(8);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(8);
                 setC5R2VNo(8);
                 setC5R3VNo(8);
                 setC5R4VNo(8);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(8);
                 setC5R2VNo(8);
                 setC5R3VNo(8);
                 setC5R4VNo(8);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(8);
                  setC5R2VNo(8);
                  setC5R3VNo(8);
                  setC5R4VNo(8);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 9 || c5R2VNo === 9 || c5R3VNo === 9 || c5R4VNo === 9){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(9);
                 setC5R2VNo(9);
                 setC5R3VNo(9);
                 setC5R4VNo(9);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(9);
                 setC5R2VNo(9);
                 setC5R3VNo(9);
                 setC5R4VNo(9);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(9);
                 setC5R2VNo(9);
                 setC5R3VNo(9);
                 setC5R4VNo(9);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(9);
                  setC5R2VNo(9);
                  setC5R3VNo(9);
                  setC5R4VNo(9);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 10 || c5R2VNo === 10 || c5R3VNo === 10 || c5R4VNo === 10){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(10);
                 setC5R2VNo(10);
                 setC5R3VNo(10);
                 setC5R4VNo(10);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(10);
                 setC5R2VNo(10);
                 setC5R3VNo(10);
                 setC5R4VNo(10);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(10);
                 setC5R2VNo(10);
                 setC5R3VNo(10);
                 setC5R4VNo(10);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(10);
                  setC5R2VNo(10);
                  setC5R3VNo(10);
                  setC5R4VNo(10);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c5R1VNo === 11 || c5R2VNo === 11 || c5R3VNo === 11 || c5R4VNo === 11){

          if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === false) {
       
            setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(11);
                 setC5R2VNo(11);
                 setC5R3VNo(11);
                 setC5R4VNo(11);
                 setSepOn2LC5(false);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
              
               } else if (sepOn3LC5 === true && sepOnLC5 === false && sepOn2LC5 === true) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(11);
                 setC5R2VNo(11);
                 setC5R3VNo(11);
                 setC5R4VNo(11);
                 setSepOn2LC5(true);
                 setSepOnLC5(false);
                 setSepOn3LC5(false)
               } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === false) {
                 setBaseNakedL05(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                   ]
                 )
                 setC5R1VNo(11);
                 setC5R2VNo(11);
                 setC5R3VNo(11);
                 setC5R4VNo(11);
                 setSepOn2LC5(false);
                 setSepOnLC5(true);
                 setSepOn3LC5(false)
                } else if (sepOn3LC5 === true && sepOnLC5 === true && sepOn2LC5 === true) {
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(11);
                  setC5R2VNo(11);
                  setC5R3VNo(11);
                  setC5R4VNo(11);
                  setSepOn2LC5(true);
                  setSepOnLC5(true);
                  setSepOn3LC5(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col06X && curY === row01X) || (curX === col06X && curY === row02X) || (curX === col06X && curY === row03X) || (curX === col06X && curY === row04X)) {
      if (c6r1MT === "L" || c6r2MT === "L" || c6r3MT === "L" || c6r4MT === "L") {
        if (c6R1VNo === 1 || c6R2VNo === 1 || c6R3VNo === 1 || c6R4VNo === 1){
          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(1);
                   setC6R2VNo(1);
                   setC6R3VNo(1);
                   setC6R4VNo(1);
                   setSepOn2LC6(false);
                   setSepOnLC6(false);
                   setSepOn3LC6(false)
                
                 } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(1);
                   setC6R2VNo(1);
                   setC6R3VNo(1);
                   setC6R4VNo(1);
                   setSepOn2LC6(true);
                   setSepOnLC6(false);
                   setSepOn3LC6(false)
                 } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                   setBaseNakedL06(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                     ]
                   )
                   setC6R1VNo(1);
                   setC6R2VNo(1);
                   setC6R3VNo(1);
                   setC6R4VNo(1);
                   setSepOn2LC6(false);
                   setSepOnLC6(true);
                   setSepOn3LC6(false)
                  } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(1);
                    setC6R2VNo(1);
                    setC6R3VNo(1);
                    setC6R4VNo(1);
                    setSepOn2LC6(true);
                    setSepOnLC6(true);
                    setSepOn3LC6(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c6R1VNo === 2 || c6R2VNo === 2 || c6R3VNo === 2 || c6R4VNo === 2){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(2);
                 setC6R2VNo(2);
                 setC6R3VNo(2);
                 setC6R4VNo(2);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(2);
                 setC6R2VNo(2);
                 setC6R3VNo(2);
                 setC6R4VNo(2);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(2);
                 setC6R2VNo(2);
                 setC6R3VNo(2);
                 setC6R4VNo(2);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(2);
                  setC6R2VNo(2);
                  setC6R3VNo(2);
                  setC6R4VNo(2);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 3 || c6R2VNo === 3 || c6R3VNo === 3 || c6R4VNo === 3){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(3);
                 setC6R2VNo(3);
                 setC6R3VNo(3);
                 setC6R4VNo(3);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(3);
                 setC6R2VNo(3);
                 setC6R3VNo(3);
                 setC6R4VNo(3);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(3);
                 setC6R2VNo(3);
                 setC6R3VNo(3);
                 setC6R4VNo(3);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(3);
                  setC6R2VNo(3);
                  setC6R3VNo(3);
                  setC6R4VNo(3);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 4 || c6R2VNo === 4 || c6R3VNo === 4 || c6R4VNo === 4){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(4);
                 setC6R2VNo(4);
                 setC6R3VNo(4);
                 setC6R4VNo(4);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(4);
                 setC6R2VNo(4);
                 setC6R3VNo(4);
                 setC6R4VNo(4);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(4);
                 setC6R2VNo(4);
                 setC6R3VNo(4);
                 setC6R4VNo(4);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(4);
                  setC6R2VNo(4);
                  setC6R3VNo(4);
                  setC6R4VNo(4);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 5 || c6R2VNo === 5 || c6R3VNo === 5 || c6R4VNo === 5){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(5);
                 setC6R2VNo(5);
                 setC6R3VNo(5);
                 setC6R4VNo(5);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(5);
                 setC6R2VNo(5);
                 setC6R3VNo(5);
                 setC6R4VNo(5);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(5);
                 setC6R2VNo(5);
                 setC6R3VNo(5);
                 setC6R4VNo(5);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(5);
                  setC6R2VNo(5);
                  setC6R3VNo(5);
                  setC6R4VNo(5);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 6 || c6R2VNo === 6 || c6R3VNo === 6 || c6R4VNo === 6){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(6);
                 setC6R2VNo(6);
                 setC6R3VNo(6);
                 setC6R4VNo(6);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(6);
                 setC6R2VNo(6);
                 setC6R3VNo(6);
                 setC6R4VNo(6);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(6);
                 setC6R2VNo(6);
                 setC6R3VNo(6);
                 setC6R4VNo(6);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(6);
                  setC6R2VNo(6);
                  setC6R3VNo(6);
                  setC6R4VNo(6);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 7 || c6R2VNo === 7 || c6R3VNo === 7 || c6R4VNo === 7){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(7);
                 setC6R2VNo(7);
                 setC6R3VNo(7);
                 setC6R4VNo(7);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(7);
                 setC6R2VNo(7);
                 setC6R3VNo(7);
                 setC6R4VNo(7);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(7);
                 setC6R2VNo(7);
                 setC6R3VNo(7);
                 setC6R4VNo(7);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(7);
                  setC6R2VNo(7);
                  setC6R3VNo(7);
                  setC6R4VNo(7);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 8 || c6R2VNo === 8 || c6R3VNo === 8 || c6R4VNo === 8){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(8);
                 setC6R2VNo(8);
                 setC6R3VNo(8);
                 setC6R4VNo(8);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(8);
                 setC6R2VNo(8);
                 setC6R3VNo(8);
                 setC6R4VNo(8);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(8);
                 setC6R2VNo(8);
                 setC6R3VNo(8);
                 setC6R4VNo(8);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(8);
                  setC6R2VNo(8);
                  setC6R3VNo(8);
                  setC6R4VNo(8);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 9 || c6R2VNo === 9 || c6R3VNo === 9 || c6R4VNo === 9){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(9);
                 setC6R2VNo(9);
                 setC6R3VNo(9);
                 setC6R4VNo(9);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(9);
                 setC6R2VNo(9);
                 setC6R3VNo(9);
                 setC6R4VNo(9);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(9);
                 setC6R2VNo(9);
                 setC6R3VNo(9);
                 setC6R4VNo(9);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(9);
                  setC6R2VNo(9);
                  setC6R3VNo(9);
                  setC6R4VNo(9);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 10 || c6R2VNo === 10 || c6R3VNo === 10 || c6R4VNo === 10){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(10);
                 setC6R2VNo(10);
                 setC6R3VNo(10);
                 setC6R4VNo(10);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(10);
                 setC6R2VNo(10);
                 setC6R3VNo(10);
                 setC6R4VNo(10);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(10);
                 setC6R2VNo(10);
                 setC6R3VNo(10);
                 setC6R4VNo(10);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(10);
                  setC6R2VNo(10);
                  setC6R3VNo(10);
                  setC6R4VNo(10);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c6R1VNo === 11 || c6R2VNo === 11 || c6R3VNo === 11 || c6R4VNo === 11){

          if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === false) {
       
            setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(11);
                 setC6R2VNo(11);
                 setC6R3VNo(11);
                 setC6R4VNo(11);
                 setSepOn2LC6(false);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
              
               } else if (sepOn3LC6 === true && sepOnLC6 === false && sepOn2LC6 === true) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(11);
                 setC6R2VNo(11);
                 setC6R3VNo(11);
                 setC6R4VNo(11);
                 setSepOn2LC6(true);
                 setSepOnLC6(false);
                 setSepOn3LC6(false)
               } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === false) {
                 setBaseNakedL06(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                   ]
                 )
                 setC6R1VNo(11);
                 setC6R2VNo(11);
                 setC6R3VNo(11);
                 setC6R4VNo(11);
                 setSepOn2LC6(false);
                 setSepOnLC6(true);
                 setSepOn3LC6(false)
                } else if (sepOn3LC6 === true && sepOnLC6 === true && sepOn2LC6 === true) {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(11);
                  setC6R2VNo(11);
                  setC6R3VNo(11);
                  setC6R4VNo(11);
                  setSepOn2LC6(true);
                  setSepOnLC6(true);
                  setSepOn3LC6(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col07X && curY === row01X) || (curX === col07X && curY === row02X) || (curX === col07X && curY === row03X) || (curX === col07X && curY === row04X)) {
      if (c7r1MT === "L" || c7r2MT === "L" || c7r3MT === "L" || c7r4MT === "L") {
        if (c7R1VNo === 1 || c7R2VNo === 1 || c7R3VNo === 1 || c7R4VNo === 1){
          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(1);
                   setC7R2VNo(1);
                   setC7R3VNo(1);
                   setC7R4VNo(1);
                   setSepOn2LC7(false);
                   setSepOnLC7(false);
                   setSepOn3LC7(false)
                
                 } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(1);
                   setC7R2VNo(1);
                   setC7R3VNo(1);
                   setC7R4VNo(1);
                   setSepOn2LC7(true);
                   setSepOnLC7(false);
                   setSepOn3LC7(false)
                 } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                   setBaseNakedL07(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(1);
                   setC7R2VNo(1);
                   setC7R3VNo(1);
                   setC7R4VNo(1);
                   setSepOn2LC7(false);
                   setSepOnLC7(true);
                   setSepOn3LC7(false)
                  } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(1);
                    setC7R2VNo(1);
                    setC7R3VNo(1);
                    setC7R4VNo(1);
                    setSepOn2LC7(true);
                    setSepOnLC7(true);
                    setSepOn3LC7(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c7R1VNo === 2 || c7R2VNo === 2 || c7R3VNo === 2 || c7R4VNo === 2){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(2);
                 setC7R2VNo(2);
                 setC7R3VNo(2);
                 setC7R4VNo(2);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(2);
                 setC7R2VNo(2);
                 setC7R3VNo(2);
                 setC7R4VNo(2);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(2);
                 setC7R2VNo(2);
                 setC7R3VNo(2);
                 setC7R4VNo(2);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(2);
                  setC7R2VNo(2);
                  setC7R3VNo(2);
                  setC7R4VNo(2);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 3 || c7R2VNo === 3 || c7R3VNo === 3 || c7R4VNo === 3){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(3);
                 setC7R2VNo(3);
                 setC7R3VNo(3);
                 setC7R4VNo(3);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(3);
                 setC7R2VNo(3);
                 setC7R3VNo(3);
                 setC7R4VNo(3);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(3);
                 setC7R2VNo(3);
                 setC7R3VNo(3);
                 setC7R4VNo(3);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(3);
                  setC7R2VNo(3);
                  setC7R3VNo(3);
                  setC7R4VNo(3);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 4 || c7R2VNo === 4 || c7R3VNo === 4 || c7R4VNo === 4){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(4);
                 setC7R2VNo(4);
                 setC7R3VNo(4);
                 setC7R4VNo(4);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(4);
                 setC7R2VNo(4);
                 setC7R3VNo(4);
                 setC7R4VNo(4);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(4);
                 setC7R2VNo(4);
                 setC7R3VNo(4);
                 setC7R4VNo(4);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(4);
                  setC7R2VNo(4);
                  setC7R3VNo(4);
                  setC7R4VNo(4);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 5 || c7R2VNo === 5 || c7R3VNo === 5 || c7R4VNo === 5){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(5);
                 setC7R2VNo(5);
                 setC7R3VNo(5);
                 setC7R4VNo(5);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(5);
                 setC7R2VNo(5);
                 setC7R3VNo(5);
                 setC7R4VNo(5);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(5);
                 setC7R2VNo(5);
                 setC7R3VNo(5);
                 setC7R4VNo(5);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(5);
                  setC7R2VNo(5);
                  setC7R3VNo(5);
                  setC7R4VNo(5);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 6 || c7R2VNo === 6 || c7R3VNo === 6 || c7R4VNo === 6){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(6);
                 setC7R2VNo(6);
                 setC7R3VNo(6);
                 setC7R4VNo(6);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(6);
                 setC7R2VNo(6);
                 setC7R3VNo(6);
                 setC7R4VNo(6);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(6);
                 setC7R2VNo(6);
                 setC7R3VNo(6);
                 setC7R4VNo(6);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(6);
                  setC7R2VNo(6);
                  setC7R3VNo(6);
                  setC7R4VNo(6);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 7 || c7R2VNo === 7 || c7R3VNo === 7 || c7R4VNo === 7){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(7);
                 setC7R2VNo(7);
                 setC7R3VNo(7);
                 setC7R4VNo(7);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(7);
                 setC7R2VNo(7);
                 setC7R3VNo(7);
                 setC7R4VNo(7);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(7);
                 setC7R2VNo(7);
                 setC7R3VNo(7);
                 setC7R4VNo(7);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(7);
                  setC7R2VNo(7);
                  setC7R3VNo(7);
                  setC7R4VNo(7);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 8 || c7R2VNo === 8 || c7R3VNo === 8 || c7R4VNo === 8){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(8);
                 setC7R2VNo(8);
                 setC7R3VNo(8);
                 setC7R4VNo(8);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(8);
                 setC7R2VNo(8);
                 setC7R3VNo(8);
                 setC7R4VNo(8);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(8);
                 setC7R2VNo(8);
                 setC7R3VNo(8);
                 setC7R4VNo(8);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(8);
                  setC7R2VNo(8);
                  setC7R3VNo(8);
                  setC7R4VNo(8);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 9 || c7R2VNo === 9 || c7R3VNo === 9 || c7R4VNo === 9){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(9);
                 setC7R2VNo(9);
                 setC7R3VNo(9);
                 setC7R4VNo(9);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(9);
                 setC7R2VNo(9);
                 setC7R3VNo(9);
                 setC7R4VNo(9);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(9);
                 setC7R2VNo(9);
                 setC7R3VNo(9);
                 setC7R4VNo(9);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(9);
                  setC7R2VNo(9);
                  setC7R3VNo(9);
                  setC7R4VNo(9);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 10 || c7R2VNo === 10 || c7R3VNo === 10 || c7R4VNo === 10){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(10);
                 setC7R2VNo(10);
                 setC7R3VNo(10);
                 setC7R4VNo(10);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(10);
                 setC7R2VNo(10);
                 setC7R3VNo(10);
                 setC7R4VNo(10);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(10);
                 setC7R2VNo(10);
                 setC7R3VNo(10);
                 setC7R4VNo(10);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(10);
                  setC7R2VNo(10);
                  setC7R3VNo(10);
                  setC7R4VNo(10);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c7R1VNo === 11 || c7R2VNo === 11 || c7R3VNo === 11 || c7R4VNo === 11){

          if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === false) {
       
            setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(11);
                 setC7R2VNo(11);
                 setC7R3VNo(11);
                 setC7R4VNo(11);
                 setSepOn2LC7(false);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
              
               } else if (sepOn3LC7 === true && sepOnLC7 === false && sepOn2LC7 === true) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(11);
                 setC7R2VNo(11);
                 setC7R3VNo(11);
                 setC7R4VNo(11);
                 setSepOn2LC7(true);
                 setSepOnLC7(false);
                 setSepOn3LC7(false)
               } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === false) {
                 setBaseNakedL07(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(11);
                 setC7R2VNo(11);
                 setC7R3VNo(11);
                 setC7R4VNo(11);
                 setSepOn2LC7(false);
                 setSepOnLC7(true);
                 setSepOn3LC7(false)
                } else if (sepOn3LC7 === true && sepOnLC7 === true && sepOn2LC7 === true) {
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(11);
                  setC7R2VNo(11);
                  setC7R3VNo(11);
                  setC7R4VNo(11);
                  setSepOn2LC7(true);
                  setSepOnLC7(true);
                  setSepOn3LC7(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col08X && curY === row01X) || (curX === col08X && curY === row02X) || (curX === col08X && curY === row03X) || (curX === col08X && curY === row04X)) {
      if (c8r1MT === "L" || c8r2MT === "L" || c8r3MT === "L" || c8r4MT === "L") {
        if (c8R1VNo === 1 || c8R2VNo === 1 || c8R3VNo === 1 || c8R4VNo === 1){
          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(1);
                   setC8R2VNo(1);
                   setC8R3VNo(1);
                   setC8R4VNo(1);
                   setSepOn2LC8(false);
                   setSepOnLC8(false);
                   setSepOn3LC8(false)
                
                 } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(1);
                   setC8R2VNo(1);
                   setC8R3VNo(1);
                   setC8R4VNo(1);
                   setSepOn2LC8(true);
                   setSepOnLC8(false);
                   setSepOn3LC8(false)
                 } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                   setBaseNakedL08(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(1);
                   setC8R2VNo(1);
                   setC8R3VNo(1);
                   setC8R4VNo(1);
                   setSepOn2LC8(false);
                   setSepOnLC8(true);
                   setSepOn3LC8(false)
                  } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(1);
                    setC8R2VNo(1);
                    setC8R3VNo(1);
                    setC8R4VNo(1);
                    setSepOn2LC8(true);
                    setSepOnLC8(true);
                    setSepOn3LC8(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c8R1VNo === 2 || c8R2VNo === 2 || c8R3VNo === 2 || c8R4VNo === 2){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(2);
                 setC8R2VNo(2);
                 setC8R3VNo(2);
                 setC8R4VNo(2);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(2);
                 setC8R2VNo(2);
                 setC8R3VNo(2);
                 setC8R4VNo(2);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(2);
                 setC8R2VNo(2);
                 setC8R3VNo(2);
                 setC8R4VNo(2);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(2);
                  setC8R2VNo(2);
                  setC8R3VNo(2);
                  setC8R4VNo(2);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 3 || c8R2VNo === 3 || c8R3VNo === 3 || c8R4VNo === 3){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(3);
                 setC8R2VNo(3);
                 setC8R3VNo(3);
                 setC8R4VNo(3);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(3);
                 setC8R2VNo(3);
                 setC8R3VNo(3);
                 setC8R4VNo(3);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(3);
                 setC8R2VNo(3);
                 setC8R3VNo(3);
                 setC8R4VNo(3);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(3);
                  setC8R2VNo(3);
                  setC8R3VNo(3);
                  setC8R4VNo(3);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 4 || c8R2VNo === 4 || c8R3VNo === 4 || c8R4VNo === 4){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(4);
                 setC8R2VNo(4);
                 setC8R3VNo(4);
                 setC8R4VNo(4);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(4);
                 setC8R2VNo(4);
                 setC8R3VNo(4);
                 setC8R4VNo(4);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(4);
                 setC8R2VNo(4);
                 setC8R3VNo(4);
                 setC8R4VNo(4);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(4);
                  setC8R2VNo(4);
                  setC8R3VNo(4);
                  setC8R4VNo(4);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 5 || c8R2VNo === 5 || c8R3VNo === 5 || c8R4VNo === 5){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(5);
                 setC8R2VNo(5);
                 setC8R3VNo(5);
                 setC8R4VNo(5);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(5);
                 setC8R2VNo(5);
                 setC8R3VNo(5);
                 setC8R4VNo(5);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(5);
                 setC8R2VNo(5);
                 setC8R3VNo(5);
                 setC8R4VNo(5);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(5);
                  setC8R2VNo(5);
                  setC8R3VNo(5);
                  setC8R4VNo(5);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 6 || c8R2VNo === 6 || c8R3VNo === 6 || c8R4VNo === 6){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(6);
                 setC8R2VNo(6);
                 setC8R3VNo(6);
                 setC8R4VNo(6);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(6);
                 setC8R2VNo(6);
                 setC8R3VNo(6);
                 setC8R4VNo(6);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(6);
                 setC8R2VNo(6);
                 setC8R3VNo(6);
                 setC8R4VNo(6);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(6);
                  setC8R2VNo(6);
                  setC8R3VNo(6);
                  setC8R4VNo(6);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 7 || c8R2VNo === 7 || c8R3VNo === 7 || c8R4VNo === 7){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(7);
                 setC8R2VNo(7);
                 setC8R3VNo(7);
                 setC8R4VNo(7);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(7);
                 setC8R2VNo(7);
                 setC8R3VNo(7);
                 setC8R4VNo(7);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(7);
                 setC8R2VNo(7);
                 setC8R3VNo(7);
                 setC8R4VNo(7);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(7);
                  setC8R2VNo(7);
                  setC8R3VNo(7);
                  setC8R4VNo(7);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 8 || c8R2VNo === 8 || c8R3VNo === 8 || c8R4VNo === 8){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(8);
                 setC8R2VNo(8);
                 setC8R3VNo(8);
                 setC8R4VNo(8);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(8);
                 setC8R2VNo(8);
                 setC8R3VNo(8);
                 setC8R4VNo(8);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(8);
                 setC8R2VNo(8);
                 setC8R3VNo(8);
                 setC8R4VNo(8);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(8);
                  setC8R2VNo(8);
                  setC8R3VNo(8);
                  setC8R4VNo(8);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 9 || c8R2VNo === 9 || c8R3VNo === 9 || c8R4VNo === 9){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(9);
                 setC8R2VNo(9);
                 setC8R3VNo(9);
                 setC8R4VNo(9);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(9);
                 setC8R2VNo(9);
                 setC8R3VNo(9);
                 setC8R4VNo(9);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(9);
                 setC8R2VNo(9);
                 setC8R3VNo(9);
                 setC8R4VNo(9);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(9);
                  setC8R2VNo(9);
                  setC8R3VNo(9);
                  setC8R4VNo(9);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 10 || c8R2VNo === 10 || c8R3VNo === 10 || c8R4VNo === 10){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(10);
                 setC8R2VNo(10);
                 setC8R3VNo(10);
                 setC8R4VNo(10);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(10);
                 setC8R2VNo(10);
                 setC8R3VNo(10);
                 setC8R4VNo(10);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(10);
                 setC8R2VNo(10);
                 setC8R3VNo(10);
                 setC8R4VNo(10);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(10);
                  setC8R2VNo(10);
                  setC8R3VNo(10);
                  setC8R4VNo(10);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c8R1VNo === 11 || c8R2VNo === 11 || c8R3VNo === 11 || c8R4VNo === 11){

          if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === false) {
       
            setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(11);
                 setC8R2VNo(11);
                 setC8R3VNo(11);
                 setC8R4VNo(11);
                 setSepOn2LC8(false);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
              
               } else if (sepOn3LC8 === true && sepOnLC8 === false && sepOn2LC8 === true) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(11);
                 setC8R2VNo(11);
                 setC8R3VNo(11);
                 setC8R4VNo(11);
                 setSepOn2LC8(true);
                 setSepOnLC8(false);
                 setSepOn3LC8(false)
               } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === false) {
                 setBaseNakedL08(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(11);
                 setC8R2VNo(11);
                 setC8R3VNo(11);
                 setC8R4VNo(11);
                 setSepOn2LC8(false);
                 setSepOnLC8(true);
                 setSepOn3LC8(false)
                } else if (sepOn3LC8 === true && sepOnLC8 === true && sepOn2LC8 === true) {
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(11);
                  setC8R2VNo(11);
                  setC8R3VNo(11);
                  setC8R4VNo(11);
                  setSepOn2LC8(true);
                  setSepOnLC8(true);
                  setSepOn3LC8(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col09X && curY === row01X) || (curX === col09X && curY === row02X) || (curX === col09X && curY === row03X) || (curX === col09X && curY === row04X)) {
      if (c9r1MT === "L" || c9r2MT === "L" || c9r3MT === "L" || c9r4MT === "L") {
        if (c9R1VNo === 1 || c9R2VNo === 1 || c9R3VNo === 1 || c9R4VNo === 1){
          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(1);
                   setC9R2VNo(1);
                   setC9R3VNo(1);
                   setC9R4VNo(1);
                   setSepOn2LC9(false);
                   setSepOnLC9(false);
                   setSepOn3LC9(false)
                
                 } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(1);
                   setC9R2VNo(1);
                   setC9R3VNo(1);
                   setC9R4VNo(1);
                   setSepOn2LC9(true);
                   setSepOnLC9(false);
                   setSepOn3LC9(false)
                 } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                   setBaseNakedL09(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(1);
                   setC9R2VNo(1);
                   setC9R3VNo(1);
                   setC9R4VNo(1);
                   setSepOn2LC9(false);
                   setSepOnLC9(true);
                   setSepOn3LC9(false)
                  } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(1);
                    setC9R2VNo(1);
                    setC9R3VNo(1);
                    setC9R4VNo(1);
                    setSepOn2LC9(true);
                    setSepOnLC9(true);
                    setSepOn3LC9(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c9R1VNo === 2 || c9R2VNo === 2 || c9R3VNo === 2 || c9R4VNo === 2){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(2);
                 setC9R2VNo(2);
                 setC9R3VNo(2);
                 setC9R4VNo(2);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(2);
                 setC9R2VNo(2);
                 setC9R3VNo(2);
                 setC9R4VNo(2);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(2);
                 setC9R2VNo(2);
                 setC9R3VNo(2);
                 setC9R4VNo(2);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(2);
                  setC9R2VNo(2);
                  setC9R3VNo(2);
                  setC9R4VNo(2);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 3 || c9R2VNo === 3 || c9R3VNo === 3 || c9R4VNo === 3){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(3);
                 setC9R2VNo(3);
                 setC9R3VNo(3);
                 setC9R4VNo(3);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(3);
                 setC9R2VNo(3);
                 setC9R3VNo(3);
                 setC9R4VNo(3);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(3);
                 setC9R2VNo(3);
                 setC9R3VNo(3);
                 setC9R4VNo(3);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(3);
                  setC9R2VNo(3);
                  setC9R3VNo(3);
                  setC9R4VNo(3);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 4 || c9R2VNo === 4 || c9R3VNo === 4 || c9R4VNo === 4){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(4);
                 setC9R2VNo(4);
                 setC9R3VNo(4);
                 setC9R4VNo(4);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(4);
                 setC9R2VNo(4);
                 setC9R3VNo(4);
                 setC9R4VNo(4);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(4);
                 setC9R2VNo(4);
                 setC9R3VNo(4);
                 setC9R4VNo(4);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(4);
                  setC9R2VNo(4);
                  setC9R3VNo(4);
                  setC9R4VNo(4);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 5 || c9R2VNo === 5 || c9R3VNo === 5 || c9R4VNo === 5){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(5);
                 setC9R2VNo(5);
                 setC9R3VNo(5);
                 setC9R4VNo(5);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(5);
                 setC9R2VNo(5);
                 setC9R3VNo(5);
                 setC9R4VNo(5);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(5);
                 setC9R2VNo(5);
                 setC9R3VNo(5);
                 setC9R4VNo(5);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(5);
                  setC9R2VNo(5);
                  setC9R3VNo(5);
                  setC9R4VNo(5);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 6 || c9R2VNo === 6 || c9R3VNo === 6 || c9R4VNo === 6){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(6);
                 setC9R2VNo(6);
                 setC9R3VNo(6);
                 setC9R4VNo(6);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(6);
                 setC9R2VNo(6);
                 setC9R3VNo(6);
                 setC9R4VNo(6);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(6);
                 setC9R2VNo(6);
                 setC9R3VNo(6);
                 setC9R4VNo(6);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(6);
                  setC9R2VNo(6);
                  setC9R3VNo(6);
                  setC9R4VNo(6);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 7 || c9R2VNo === 7 || c9R3VNo === 7 || c9R4VNo === 7){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(7);
                 setC9R2VNo(7);
                 setC9R3VNo(7);
                 setC9R4VNo(7);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(7);
                 setC9R2VNo(7);
                 setC9R3VNo(7);
                 setC9R4VNo(7);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(7);
                 setC9R2VNo(7);
                 setC9R3VNo(7);
                 setC9R4VNo(7);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(7);
                  setC9R2VNo(7);
                  setC9R3VNo(7);
                  setC9R4VNo(7);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 8 || c9R2VNo === 8 || c9R3VNo === 8 || c9R4VNo === 8){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(8);
                 setC9R2VNo(8);
                 setC9R3VNo(8);
                 setC9R4VNo(8);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(8);
                 setC9R2VNo(8);
                 setC9R3VNo(8);
                 setC9R4VNo(8);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(8);
                 setC9R2VNo(8);
                 setC9R3VNo(8);
                 setC9R4VNo(8);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(8);
                  setC9R2VNo(8);
                  setC9R3VNo(8);
                  setC9R4VNo(8);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 9 || c9R2VNo === 9 || c9R3VNo === 9 || c9R4VNo === 9){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(9);
                 setC9R2VNo(9);
                 setC9R3VNo(9);
                 setC9R4VNo(9);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(9);
                 setC9R2VNo(9);
                 setC9R3VNo(9);
                 setC9R4VNo(9);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(9);
                 setC9R2VNo(9);
                 setC9R3VNo(9);
                 setC9R4VNo(9);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(9);
                  setC9R2VNo(9);
                  setC9R3VNo(9);
                  setC9R4VNo(9);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 10 || c9R2VNo === 10 || c9R3VNo === 10 || c9R4VNo === 10){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(10);
                 setC9R2VNo(10);
                 setC9R3VNo(10);
                 setC9R4VNo(10);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(10);
                 setC9R2VNo(10);
                 setC9R3VNo(10);
                 setC9R4VNo(10);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(10);
                 setC9R2VNo(10);
                 setC9R3VNo(10);
                 setC9R4VNo(10);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(10);
                  setC9R2VNo(10);
                  setC9R3VNo(10);
                  setC9R4VNo(10);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c9R1VNo === 11 || c9R2VNo === 11 || c9R3VNo === 11 || c9R4VNo === 11){

          if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === false) {
       
            setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(11);
                 setC9R2VNo(11);
                 setC9R3VNo(11);
                 setC9R4VNo(11);
                 setSepOn2LC9(false);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
              
               } else if (sepOn3LC9 === true && sepOnLC9 === false && sepOn2LC9 === true) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(11);
                 setC9R2VNo(11);
                 setC9R3VNo(11);
                 setC9R4VNo(11);
                 setSepOn2LC9(true);
                 setSepOnLC9(false);
                 setSepOn3LC9(false)
               } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === false) {
                 setBaseNakedL09(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(11);
                 setC9R2VNo(11);
                 setC9R3VNo(11);
                 setC9R4VNo(11);
                 setSepOn2LC9(false);
                 setSepOnLC9(true);
                 setSepOn3LC9(false)
                } else if (sepOn3LC9 === true && sepOnLC9 === true && sepOn2LC9 === true) {
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(11);
                  setC9R2VNo(11);
                  setC9R3VNo(11);
                  setC9R4VNo(11);
                  setSepOn2LC9(true);
                  setSepOnLC9(true);
                  setSepOn3LC9(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col10X && curY === row01X) || (curX === col10X && curY === row02X) || (curX === col10X && curY === row03X) || (curX === col10X && curY === row04X)) {
      if (c10r1MT === "L" || c10r2MT === "L" || c10r3MT === "L" || c10r4MT === "L") {
        if (c10R1VNo === 1 || c10R2VNo === 1 || c10R3VNo === 1 || c10R4VNo === 1){
          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(1);
                   setC10R2VNo(1);
                   setC10R3VNo(1);
                   setC10R4VNo(1);
                   setSepOn2LC10(false);
                   setSepOnLC10(false);
                   setSepOn3LC10(false)
                
                 } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(1);
                   setC10R2VNo(1);
                   setC10R3VNo(1);
                   setC10R4VNo(1);
                   setSepOn2LC10(true);
                   setSepOnLC10(false);
                   setSepOn3LC10(false)
                 } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                   setBaseNakedL10(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(1);
                   setC10R2VNo(1);
                   setC10R3VNo(1);
                   setC10R4VNo(1);
                   setSepOn2LC10(false);
                   setSepOnLC10(true);
                   setSepOn3LC10(false)
                  } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(1);
                    setC10R2VNo(1);
                    setC10R3VNo(1);
                    setC10R4VNo(1);
                    setSepOn2LC10(true);
                    setSepOnLC10(true);
                    setSepOn3LC10(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c10R1VNo === 2 || c10R2VNo === 2 || c10R3VNo === 2 || c10R4VNo === 2){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(2);
                 setC10R2VNo(2);
                 setC10R3VNo(2);
                 setC10R4VNo(2);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(2);
                 setC10R2VNo(2);
                 setC10R3VNo(2);
                 setC10R4VNo(2);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(2);
                 setC10R2VNo(2);
                 setC10R3VNo(2);
                 setC10R4VNo(2);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(2);
                  setC10R2VNo(2);
                  setC10R3VNo(2);
                  setC10R4VNo(2);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 3 || c10R2VNo === 3 || c10R3VNo === 3 || c10R4VNo === 3){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(3);
                 setC10R2VNo(3);
                 setC10R3VNo(3);
                 setC10R4VNo(3);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(3);
                 setC10R2VNo(3);
                 setC10R3VNo(3);
                 setC10R4VNo(3);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(3);
                 setC10R2VNo(3);
                 setC10R3VNo(3);
                 setC10R4VNo(3);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(3);
                  setC10R2VNo(3);
                  setC10R3VNo(3);
                  setC10R4VNo(3);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 4 || c10R2VNo === 4 || c10R3VNo === 4 || c10R4VNo === 4){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(4);
                 setC10R2VNo(4);
                 setC10R3VNo(4);
                 setC10R4VNo(4);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(4);
                 setC10R2VNo(4);
                 setC10R3VNo(4);
                 setC10R4VNo(4);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(4);
                 setC10R2VNo(4);
                 setC10R3VNo(4);
                 setC10R4VNo(4);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(4);
                  setC10R2VNo(4);
                  setC10R3VNo(4);
                  setC10R4VNo(4);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 5 || c10R2VNo === 5 || c10R3VNo === 5 || c10R4VNo === 5){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(5);
                 setC10R2VNo(5);
                 setC10R3VNo(5);
                 setC10R4VNo(5);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(5);
                 setC10R2VNo(5);
                 setC10R3VNo(5);
                 setC10R4VNo(5);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(5);
                 setC10R2VNo(5);
                 setC10R3VNo(5);
                 setC10R4VNo(5);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(5);
                  setC10R2VNo(5);
                  setC10R3VNo(5);
                  setC10R4VNo(5);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 6 || c10R2VNo === 6 || c10R3VNo === 6 || c10R4VNo === 6){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(6);
                 setC10R2VNo(6);
                 setC10R3VNo(6);
                 setC10R4VNo(6);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(6);
                 setC10R2VNo(6);
                 setC10R3VNo(6);
                 setC10R4VNo(6);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(6);
                 setC10R2VNo(6);
                 setC10R3VNo(6);
                 setC10R4VNo(6);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(6);
                  setC10R2VNo(6);
                  setC10R3VNo(6);
                  setC10R4VNo(6);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 7 || c10R2VNo === 7 || c10R3VNo === 7 || c10R4VNo === 7){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(7);
                 setC10R2VNo(7);
                 setC10R3VNo(7);
                 setC10R4VNo(7);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(7);
                 setC10R2VNo(7);
                 setC10R3VNo(7);
                 setC10R4VNo(7);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(7);
                 setC10R2VNo(7);
                 setC10R3VNo(7);
                 setC10R4VNo(7);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(7);
                  setC10R2VNo(7);
                  setC10R3VNo(7);
                  setC10R4VNo(7);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 8 || c10R2VNo === 8 || c10R3VNo === 8 || c10R4VNo === 8){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(8);
                 setC10R2VNo(8);
                 setC10R3VNo(8);
                 setC10R4VNo(8);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(8);
                 setC10R2VNo(8);
                 setC10R3VNo(8);
                 setC10R4VNo(8);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(8);
                 setC10R2VNo(8);
                 setC10R3VNo(8);
                 setC10R4VNo(8);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(8);
                  setC10R2VNo(8);
                  setC10R3VNo(8);
                  setC10R4VNo(8);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 9 || c10R2VNo === 9 || c10R3VNo === 9 || c10R4VNo === 9){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(9);
                 setC10R2VNo(9);
                 setC10R3VNo(9);
                 setC10R4VNo(9);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(9);
                 setC10R2VNo(9);
                 setC10R3VNo(9);
                 setC10R4VNo(9);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(9);
                 setC10R2VNo(9);
                 setC10R3VNo(9);
                 setC10R4VNo(9);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(9);
                  setC10R2VNo(9);
                  setC10R3VNo(9);
                  setC10R4VNo(9);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 10 || c10R2VNo === 10 || c10R3VNo === 10 || c10R4VNo === 10){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(10);
                 setC10R2VNo(10);
                 setC10R3VNo(10);
                 setC10R4VNo(10);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(10);
                 setC10R2VNo(10);
                 setC10R3VNo(10);
                 setC10R4VNo(10);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(10);
                 setC10R2VNo(10);
                 setC10R3VNo(10);
                 setC10R4VNo(10);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(10);
                  setC10R2VNo(10);
                  setC10R3VNo(10);
                  setC10R4VNo(10);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c10R1VNo === 11 || c10R2VNo === 11 || c10R3VNo === 11 || c10R4VNo === 11){

          if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === false) {
       
            setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(11);
                 setC10R2VNo(11);
                 setC10R3VNo(11);
                 setC10R4VNo(11);
                 setSepOn2LC10(false);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
              
               } else if (sepOn3LC10 === true && sepOnLC10 === false && sepOn2LC10 === true) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(11);
                 setC10R2VNo(11);
                 setC10R3VNo(11);
                 setC10R4VNo(11);
                 setSepOn2LC10(true);
                 setSepOnLC10(false);
                 setSepOn3LC10(false)
               } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === false) {
                 setBaseNakedL10(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(11);
                 setC10R2VNo(11);
                 setC10R3VNo(11);
                 setC10R4VNo(11);
                 setSepOn2LC10(false);
                 setSepOnLC10(true);
                 setSepOn3LC10(false)
                } else if (sepOn3LC10 === true && sepOnLC10 === true && sepOn2LC10 === true) {
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(11);
                  setC10R2VNo(11);
                  setC10R3VNo(11);
                  setC10R4VNo(11);
                  setSepOn2LC10(true);
                  setSepOnLC10(true);
                  setSepOn3LC10(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col11X && curY === row01X) || (curX === col11X && curY === row02X) || (curX === col11X && curY === row03X) || (curX === col11X && curY === row04X)) {
      if (c11r1MT === "L" || c11r2MT === "L" || c11r3MT === "L" || c11r4MT === "L") {
        if (c11R1VNo === 1 || c11R2VNo === 1 || c11R3VNo === 1 || c11R4VNo === 1){
          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(1);
                   setC11R2VNo(1);
                   setC11R3VNo(1);
                   setC11R4VNo(1);
                   setSepOn2LC11(false);
                   setSepOnLC11(false);
                   setSepOn3LC11(false)
                
                 } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(1);
                   setC11R2VNo(1);
                   setC11R3VNo(1);
                   setC11R4VNo(1);
                   setSepOn2LC11(true);
                   setSepOnLC11(false);
                   setSepOn3LC11(false)
                 } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                   setBaseNakedL11(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                     ]
                   )
                   setC11R1VNo(1);
                   setC11R2VNo(1);
                   setC11R3VNo(1);
                   setC11R4VNo(1);
                   setSepOn2LC11(false);
                   setSepOnLC11(true);
                   setSepOn3LC11(false)
                  } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(1);
                    setC11R2VNo(1);
                    setC11R3VNo(1);
                    setC11R4VNo(1);
                    setSepOn2LC11(true);
                    setSepOnLC11(true);
                    setSepOn3LC11(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c11R1VNo === 2 || c11R2VNo === 2 || c11R3VNo === 2 || c11R4VNo === 2){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(2);
                 setC11R2VNo(2);
                 setC11R3VNo(2);
                 setC11R4VNo(2);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(2);
                 setC11R2VNo(2);
                 setC11R3VNo(2);
                 setC11R4VNo(2);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(2);
                 setC11R2VNo(2);
                 setC11R3VNo(2);
                 setC11R4VNo(2);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(2);
                  setC11R2VNo(2);
                  setC11R3VNo(2);
                  setC11R4VNo(2);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 3 || c11R2VNo === 3 || c11R3VNo === 3 || c11R4VNo === 3){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(3);
                 setC11R2VNo(3);
                 setC11R3VNo(3);
                 setC11R4VNo(3);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(3);
                 setC11R2VNo(3);
                 setC11R3VNo(3);
                 setC11R4VNo(3);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(3);
                 setC11R2VNo(3);
                 setC11R3VNo(3);
                 setC11R4VNo(3);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(3);
                  setC11R2VNo(3);
                  setC11R3VNo(3);
                  setC11R4VNo(3);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 4 || c11R2VNo === 4 || c11R3VNo === 4 || c11R4VNo === 4){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(4);
                 setC11R2VNo(4);
                 setC11R3VNo(4);
                 setC11R4VNo(4);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(4);
                 setC11R2VNo(4);
                 setC11R3VNo(4);
                 setC11R4VNo(4);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(4);
                 setC11R2VNo(4);
                 setC11R3VNo(4);
                 setC11R4VNo(4);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(4);
                  setC11R2VNo(4);
                  setC11R3VNo(4);
                  setC11R4VNo(4);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 5 || c11R2VNo === 5 || c11R3VNo === 5 || c11R4VNo === 5){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(5);
                 setC11R2VNo(5);
                 setC11R3VNo(5);
                 setC11R4VNo(5);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(5);
                 setC11R2VNo(5);
                 setC11R3VNo(5);
                 setC11R4VNo(5);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(5);
                 setC11R2VNo(5);
                 setC11R3VNo(5);
                 setC11R4VNo(5);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(5);
                  setC11R2VNo(5);
                  setC11R3VNo(5);
                  setC11R4VNo(5);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 6 || c11R2VNo === 6 || c11R3VNo === 6 || c11R4VNo === 6){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(6);
                 setC11R2VNo(6);
                 setC11R3VNo(6);
                 setC11R4VNo(6);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(6);
                 setC11R2VNo(6);
                 setC11R3VNo(6);
                 setC11R4VNo(6);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(6);
                 setC11R2VNo(6);
                 setC11R3VNo(6);
                 setC11R4VNo(6);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(6);
                  setC11R2VNo(6);
                  setC11R3VNo(6);
                  setC11R4VNo(6);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 7 || c11R2VNo === 7 || c11R3VNo === 7 || c11R4VNo === 7){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(7);
                 setC11R2VNo(7);
                 setC11R3VNo(7);
                 setC11R4VNo(7);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(7);
                 setC11R2VNo(7);
                 setC11R3VNo(7);
                 setC11R4VNo(7);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(7);
                 setC11R2VNo(7);
                 setC11R3VNo(7);
                 setC11R4VNo(7);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(7);
                  setC11R2VNo(7);
                  setC11R3VNo(7);
                  setC11R4VNo(7);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 8 || c11R2VNo === 8 || c11R3VNo === 8 || c11R4VNo === 8){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(8);
                 setC11R2VNo(8);
                 setC11R3VNo(8);
                 setC11R4VNo(8);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(8);
                 setC11R2VNo(8);
                 setC11R3VNo(8);
                 setC11R4VNo(8);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(8);
                 setC11R2VNo(8);
                 setC11R3VNo(8);
                 setC11R4VNo(8);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(8);
                  setC11R2VNo(8);
                  setC11R3VNo(8);
                  setC11R4VNo(8);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 9 || c11R2VNo === 9 || c11R3VNo === 9 || c11R4VNo === 9){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(9);
                 setC11R2VNo(9);
                 setC11R3VNo(9);
                 setC11R4VNo(9);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(9);
                 setC11R2VNo(9);
                 setC11R3VNo(9);
                 setC11R4VNo(9);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(9);
                 setC11R2VNo(9);
                 setC11R3VNo(9);
                 setC11R4VNo(9);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(9);
                  setC11R2VNo(9);
                  setC11R3VNo(9);
                  setC11R4VNo(9);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 10 || c11R2VNo === 10 || c11R3VNo === 10 || c11R4VNo === 10){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(10);
                 setC11R2VNo(10);
                 setC11R3VNo(10);
                 setC11R4VNo(10);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(10);
                 setC11R2VNo(10);
                 setC11R3VNo(10);
                 setC11R4VNo(10);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(10);
                 setC11R2VNo(10);
                 setC11R3VNo(10);
                 setC11R4VNo(10);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(10);
                  setC11R2VNo(10);
                  setC11R3VNo(10);
                  setC11R4VNo(10);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c11R1VNo === 11 || c11R2VNo === 11 || c11R3VNo === 11 || c11R4VNo === 11){

          if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === false) {
       
            setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(11);
                 setC11R2VNo(11);
                 setC11R3VNo(11);
                 setC11R4VNo(11);
                 setSepOn2LC11(false);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
              
               } else if (sepOn3LC11 === true && sepOnLC11 === false && sepOn2LC11 === true) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(11);
                 setC11R2VNo(11);
                 setC11R3VNo(11);
                 setC11R4VNo(11);
                 setSepOn2LC11(true);
                 setSepOnLC11(false);
                 setSepOn3LC11(false)
               } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === false) {
                 setBaseNakedL11(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                   ]
                 )
                 setC11R1VNo(11);
                 setC11R2VNo(11);
                 setC11R3VNo(11);
                 setC11R4VNo(11);
                 setSepOn2LC11(false);
                 setSepOnLC11(true);
                 setSepOn3LC11(false)
                } else if (sepOn3LC11 === true && sepOnLC11 === true && sepOn2LC11 === true) {
                  setBaseNakedL11(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(11);
                  setC11R2VNo(11);
                  setC11R3VNo(11);
                  setC11R4VNo(11);
                  setSepOn2LC11(true);
                  setSepOnLC11(true);
                  setSepOn3LC11(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col12X && curY === row01X) || (curX === col12X && curY === row02X) || (curX === col12X && curY === row03X) || (curX === col12X && curY === row04X)) {
      if (c12r1MT === "L" || c12r2MT === "L" || c12r3MT === "L" || c12r4MT === "L") {
        if (c12R1VNo === 1 || c12R2VNo === 1 || c12R3VNo === 1 || c12R4VNo === 1){
          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(1);
                   setC12R2VNo(1);
                   setC12R3VNo(1);
                   setC12R4VNo(1);
                   setSepOn2LC12(false);
                   setSepOnLC12(false);
                   setSepOn3LC12(false)
                
                 } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(1);
                   setC12R2VNo(1);
                   setC12R3VNo(1);
                   setC12R4VNo(1);
                   setSepOn2LC12(true);
                   setSepOnLC12(false);
                   setSepOn3LC12(false)
                 } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                   setBaseNakedL12(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(1);
                   setC12R2VNo(1);
                   setC12R3VNo(1);
                   setC12R4VNo(1);
                   setSepOn2LC12(false);
                   setSepOnLC12(true);
                   setSepOn3LC12(false)
                  } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                    setBaseNakedL12(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(1);
                    setC12R2VNo(1);
                    setC12R3VNo(1);
                    setC12R4VNo(1);
                    setSepOn2LC12(true);
                    setSepOnLC12(true);
                    setSepOn3LC12(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c12R1VNo === 2 || c12R2VNo === 2 || c12R3VNo === 2 || c12R4VNo === 2){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(2);
                 setC12R2VNo(2);
                 setC12R3VNo(2);
                 setC12R4VNo(2);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(2);
                 setC12R2VNo(2);
                 setC12R3VNo(2);
                 setC12R4VNo(2);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(2);
                 setC12R2VNo(2);
                 setC12R3VNo(2);
                 setC12R4VNo(2);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(2);
                  setC12R2VNo(2);
                  setC12R3VNo(2);
                  setC12R4VNo(2);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 3 || c12R2VNo === 3 || c12R3VNo === 3 || c12R4VNo === 3){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(3);
                 setC12R2VNo(3);
                 setC12R3VNo(3);
                 setC12R4VNo(3);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(3);
                 setC12R2VNo(3);
                 setC12R3VNo(3);
                 setC12R4VNo(3);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(3);
                 setC12R2VNo(3);
                 setC12R3VNo(3);
                 setC12R4VNo(3);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(3);
                  setC12R2VNo(3);
                  setC12R3VNo(3);
                  setC12R4VNo(3);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 4 || c12R2VNo === 4 || c12R3VNo === 4 || c12R4VNo === 4){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(4);
                 setC12R2VNo(4);
                 setC12R3VNo(4);
                 setC12R4VNo(4);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(4);
                 setC12R2VNo(4);
                 setC12R3VNo(4);
                 setC12R4VNo(4);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(4);
                 setC12R2VNo(4);
                 setC12R3VNo(4);
                 setC12R4VNo(4);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(4);
                  setC12R2VNo(4);
                  setC12R3VNo(4);
                  setC12R4VNo(4);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 5 || c12R2VNo === 5 || c12R3VNo === 5 || c12R4VNo === 5){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(5);
                 setC12R2VNo(5);
                 setC12R3VNo(5);
                 setC12R4VNo(5);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(5);
                 setC12R2VNo(5);
                 setC12R3VNo(5);
                 setC12R4VNo(5);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(5);
                 setC12R2VNo(5);
                 setC12R3VNo(5);
                 setC12R4VNo(5);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(5);
                  setC12R2VNo(5);
                  setC12R3VNo(5);
                  setC12R4VNo(5);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 6 || c12R2VNo === 6 || c12R3VNo === 6 || c12R4VNo === 6){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(6);
                 setC12R2VNo(6);
                 setC12R3VNo(6);
                 setC12R4VNo(6);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(6);
                 setC12R2VNo(6);
                 setC12R3VNo(6);
                 setC12R4VNo(6);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(6);
                 setC12R2VNo(6);
                 setC12R3VNo(6);
                 setC12R4VNo(6);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(6);
                  setC12R2VNo(6);
                  setC12R3VNo(6);
                  setC12R4VNo(6);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 7 || c12R2VNo === 7 || c12R3VNo === 7 || c12R4VNo === 7){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(7);
                 setC12R2VNo(7);
                 setC12R3VNo(7);
                 setC12R4VNo(7);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(7);
                 setC12R2VNo(7);
                 setC12R3VNo(7);
                 setC12R4VNo(7);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(7);
                 setC12R2VNo(7);
                 setC12R3VNo(7);
                 setC12R4VNo(7);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(7);
                  setC12R2VNo(7);
                  setC12R3VNo(7);
                  setC12R4VNo(7);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 8 || c12R2VNo === 8 || c12R3VNo === 8 || c12R4VNo === 8){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(8);
                 setC12R2VNo(8);
                 setC12R3VNo(8);
                 setC12R4VNo(8);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(8);
                 setC12R2VNo(8);
                 setC12R3VNo(8);
                 setC12R4VNo(8);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(8);
                 setC12R2VNo(8);
                 setC12R3VNo(8);
                 setC12R4VNo(8);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(8);
                  setC12R2VNo(8);
                  setC12R3VNo(8);
                  setC12R4VNo(8);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 9 || c12R2VNo === 9 || c12R3VNo === 9 || c12R4VNo === 9){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(9);
                 setC12R2VNo(9);
                 setC12R3VNo(9);
                 setC12R4VNo(9);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(9);
                 setC12R2VNo(9);
                 setC12R3VNo(9);
                 setC12R4VNo(9);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(9);
                 setC12R2VNo(9);
                 setC12R3VNo(9);
                 setC12R4VNo(9);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(9);
                  setC12R2VNo(9);
                  setC12R3VNo(9);
                  setC12R4VNo(9);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 10 || c12R2VNo === 10 || c12R3VNo === 10 || c12R4VNo === 10){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(10);
                 setC12R2VNo(10);
                 setC12R3VNo(10);
                 setC12R4VNo(10);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(10);
                 setC12R2VNo(10);
                 setC12R3VNo(10);
                 setC12R4VNo(10);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(10);
                 setC12R2VNo(10);
                 setC12R3VNo(10);
                 setC12R4VNo(10);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(10);
                  setC12R2VNo(10);
                  setC12R3VNo(10);
                  setC12R4VNo(10);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c12R1VNo === 11 || c12R2VNo === 11 || c12R3VNo === 11 || c12R4VNo === 11){

          if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === false) {
       
            setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(11);
                 setC12R2VNo(11);
                 setC12R3VNo(11);
                 setC12R4VNo(11);
                 setSepOn2LC12(false);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
              
               } else if (sepOn3LC12 === true && sepOnLC12 === false && sepOn2LC12 === true) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(11);
                 setC12R2VNo(11);
                 setC12R3VNo(11);
                 setC12R4VNo(11);
                 setSepOn2LC12(true);
                 setSepOnLC12(false);
                 setSepOn3LC12(false)
               } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === false) {
                 setBaseNakedL12(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(11);
                 setC12R2VNo(11);
                 setC12R3VNo(11);
                 setC12R4VNo(11);
                 setSepOn2LC12(false);
                 setSepOnLC12(true);
                 setSepOn3LC12(false)
                } else if (sepOn3LC12 === true && sepOnLC12 === true && sepOn2LC12 === true) {
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(11);
                  setC12R2VNo(11);
                  setC12R3VNo(11);
                  setC12R4VNo(11);
                  setSepOn2LC12(true);
                  setSepOnLC12(true);
                  setSepOn3LC12(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col13X && curY === row01X) || (curX === col13X && curY === row02X) || (curX === col13X && curY === row03X) || (curX === col13X && curY === row04X)) {
      if (c13r1MT === "L" || c13r2MT === "L" || c13r3MT === "L" || c13r4MT === "L") {
        if (c13R1VNo === 1 || c13R2VNo === 1 || c13R3VNo === 1 || c13R4VNo === 1){
          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(1);
                   setC13R2VNo(1);
                   setC13R3VNo(1);
                   setC13R4VNo(1);
                   setSepOn2LC13(false);
                   setSepOnLC13(false);
                   setSepOn3LC13(false)
                
                 } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(1);
                   setC13R2VNo(1);
                   setC13R3VNo(1);
                   setC13R4VNo(1);
                   setSepOn2LC13(true);
                   setSepOnLC13(false);
                   setSepOn3LC13(false)
                 } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                   setBaseNakedL13(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                     ]
                   )
                   setC13R1VNo(1);
                   setC13R2VNo(1);
                   setC13R3VNo(1);
                   setC13R4VNo(1);
                   setSepOn2LC13(false);
                   setSepOnLC13(true);
                   setSepOn3LC13(false)
                  } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(1);
                    setC13R2VNo(1);
                    setC13R3VNo(1);
                    setC13R4VNo(1);
                    setSepOn2LC13(true);
                    setSepOnLC13(true);
                    setSepOn3LC13(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c13R1VNo === 2 || c13R2VNo === 2 || c13R3VNo === 2 || c13R4VNo === 2){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(2);
                 setC13R2VNo(2);
                 setC13R3VNo(2);
                 setC13R4VNo(2);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(2);
                 setC13R2VNo(2);
                 setC13R3VNo(2);
                 setC13R4VNo(2);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(2);
                 setC13R2VNo(2);
                 setC13R3VNo(2);
                 setC13R4VNo(2);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(2);
                  setC13R2VNo(2);
                  setC13R3VNo(2);
                  setC13R4VNo(2);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 3 || c13R2VNo === 3 || c13R3VNo === 3 || c13R4VNo === 3){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(3);
                 setC13R2VNo(3);
                 setC13R3VNo(3);
                 setC13R4VNo(3);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(3);
                 setC13R2VNo(3);
                 setC13R3VNo(3);
                 setC13R4VNo(3);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(3);
                 setC13R2VNo(3);
                 setC13R3VNo(3);
                 setC13R4VNo(3);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(3);
                  setC13R2VNo(3);
                  setC13R3VNo(3);
                  setC13R4VNo(3);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 4 || c13R2VNo === 4 || c13R3VNo === 4 || c13R4VNo === 4){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(4);
                 setC13R2VNo(4);
                 setC13R3VNo(4);
                 setC13R4VNo(4);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(4);
                 setC13R2VNo(4);
                 setC13R3VNo(4);
                 setC13R4VNo(4);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(4);
                 setC13R2VNo(4);
                 setC13R3VNo(4);
                 setC13R4VNo(4);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(4);
                  setC13R2VNo(4);
                  setC13R3VNo(4);
                  setC13R4VNo(4);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 5 || c13R2VNo === 5 || c13R3VNo === 5 || c13R4VNo === 5){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(5);
                 setC13R2VNo(5);
                 setC13R3VNo(5);
                 setC13R4VNo(5);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(5);
                 setC13R2VNo(5);
                 setC13R3VNo(5);
                 setC13R4VNo(5);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(5);
                 setC13R2VNo(5);
                 setC13R3VNo(5);
                 setC13R4VNo(5);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(5);
                  setC13R2VNo(5);
                  setC13R3VNo(5);
                  setC13R4VNo(5);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 6 || c13R2VNo === 6 || c13R3VNo === 6 || c13R4VNo === 6){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(6);
                 setC13R2VNo(6);
                 setC13R3VNo(6);
                 setC13R4VNo(6);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(6);
                 setC13R2VNo(6);
                 setC13R3VNo(6);
                 setC13R4VNo(6);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(6);
                 setC13R2VNo(6);
                 setC13R3VNo(6);
                 setC13R4VNo(6);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(6);
                  setC13R2VNo(6);
                  setC13R3VNo(6);
                  setC13R4VNo(6);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 7 || c13R2VNo === 7 || c13R3VNo === 7 || c13R4VNo === 7){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(7);
                 setC13R2VNo(7);
                 setC13R3VNo(7);
                 setC13R4VNo(7);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(7);
                 setC13R2VNo(7);
                 setC13R3VNo(7);
                 setC13R4VNo(7);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(7);
                 setC13R2VNo(7);
                 setC13R3VNo(7);
                 setC13R4VNo(7);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(7);
                  setC13R2VNo(7);
                  setC13R3VNo(7);
                  setC13R4VNo(7);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 8 || c13R2VNo === 8 || c13R3VNo === 8 || c13R4VNo === 8){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(8);
                 setC13R2VNo(8);
                 setC13R3VNo(8);
                 setC13R4VNo(8);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(8);
                 setC13R2VNo(8);
                 setC13R3VNo(8);
                 setC13R4VNo(8);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(8);
                 setC13R2VNo(8);
                 setC13R3VNo(8);
                 setC13R4VNo(8);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(8);
                  setC13R2VNo(8);
                  setC13R3VNo(8);
                  setC13R4VNo(8);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 9 || c13R2VNo === 9 || c13R3VNo === 9 || c13R4VNo === 9){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(9);
                 setC13R2VNo(9);
                 setC13R3VNo(9);
                 setC13R4VNo(9);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(9);
                 setC13R2VNo(9);
                 setC13R3VNo(9);
                 setC13R4VNo(9);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(9);
                 setC13R2VNo(9);
                 setC13R3VNo(9);
                 setC13R4VNo(9);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(9);
                  setC13R2VNo(9);
                  setC13R3VNo(9);
                  setC13R4VNo(9);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 10 || c13R2VNo === 10 || c13R3VNo === 10 || c13R4VNo === 10){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(10);
                 setC13R2VNo(10);
                 setC13R3VNo(10);
                 setC13R4VNo(10);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(10);
                 setC13R2VNo(10);
                 setC13R3VNo(10);
                 setC13R4VNo(10);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(10);
                 setC13R2VNo(10);
                 setC13R3VNo(10);
                 setC13R4VNo(10);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(10);
                  setC13R2VNo(10);
                  setC13R3VNo(10);
                  setC13R4VNo(10);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c13R1VNo === 11 || c13R2VNo === 11 || c13R3VNo === 11 || c13R4VNo === 11){

          if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === false) {
       
            setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(11);
                 setC13R2VNo(11);
                 setC13R3VNo(11);
                 setC13R4VNo(11);
                 setSepOn2LC13(false);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
              
               } else if (sepOn3LC13 === true && sepOnLC13 === false && sepOn2LC13 === true) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(11);
                 setC13R2VNo(11);
                 setC13R3VNo(11);
                 setC13R4VNo(11);
                 setSepOn2LC13(true);
                 setSepOnLC13(false);
                 setSepOn3LC13(false)
               } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === false) {
                 setBaseNakedL13(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                   ]
                 )
                 setC13R1VNo(11);
                 setC13R2VNo(11);
                 setC13R3VNo(11);
                 setC13R4VNo(11);
                 setSepOn2LC13(false);
                 setSepOnLC13(true);
                 setSepOn3LC13(false)
                } else if (sepOn3LC13 === true && sepOnLC13 === true && sepOn2LC13 === true) {
                  setBaseNakedL13(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                    ]
                  )
                  setC13R1VNo(11);
                  setC13R2VNo(11);
                  setC13R3VNo(11);
                  setC13R4VNo(11);
                  setSepOn2LC13(true);
                  setSepOnLC13(true);
                  setSepOn3LC13(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } else if ((curX === col14X && curY === row01X) || (curX === col14X && curY === row02X) || (curX === col14X && curY === row03X) || (curX === col14X && curY === row04X)) {
      if (c14r1MT === "L" || c14r2MT === "L" || c14r3MT === "L" || c14r4MT === "L") {
        if (c14R1VNo === 1 || c14R2VNo === 1 || c14R3VNo === 1 || c14R4VNo === 1){
          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(1);
                   setC14R2VNo(1);
                   setC14R3VNo(1);
                   setC14R4VNo(1);
                   setSepOn2LC14(false);
                   setSepOnLC14(false);
                   setSepOn3LC14(false)
                
                 } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(1);
                   setC14R2VNo(1);
                   setC14R3VNo(1);
                   setC14R4VNo(1);
                   setSepOn2LC14(true);
                   setSepOnLC14(false);
                   setSepOn3LC14(false)
                 } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                   setBaseNakedL14(
                     [
                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(1);
                   setC14R2VNo(1);
                   setC14R3VNo(1);
                   setC14R4VNo(1);
                   setSepOn2LC14(false);
                   setSepOnLC14(true);
                   setSepOn3LC14(false)
                  } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                    setBaseNakedL14(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                      ]
                    )
                    setC14R1VNo(1);
                    setC14R2VNo(1);
                    setC14R3VNo(1);
                    setC14R4VNo(1);
                    setSepOn2LC14(true);
                    setSepOnLC14(true);
                    setSepOn3LC14(false)
                  } else {
                    alert("Already removed.")
                  }
        } else if (c14R1VNo === 2 || c14R2VNo === 2 || c14R3VNo === 2 || c14R4VNo === 2){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(2);
                 setC14R2VNo(2);
                 setC14R3VNo(2);
                 setC14R4VNo(2);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(2);
                 setC14R2VNo(2);
                 setC14R3VNo(2);
                 setC14R4VNo(2);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(2);
                 setC14R2VNo(2);
                 setC14R3VNo(2);
                 setC14R4VNo(2);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(2);
                  setC14R2VNo(2);
                  setC14R3VNo(2);
                  setC14R4VNo(2);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 3 || c14R2VNo === 3 || c14R3VNo === 3 || c14R4VNo === 3){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(3);
                 setC14R2VNo(3);
                 setC14R3VNo(3);
                 setC14R4VNo(3);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(3);
                 setC14R2VNo(3);
                 setC14R3VNo(3);
                 setC14R4VNo(3);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(3);
                 setC14R2VNo(3);
                 setC14R3VNo(3);
                 setC14R4VNo(3);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(3);
                  setC14R2VNo(3);
                  setC14R3VNo(3);
                  setC14R4VNo(3);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 4 || c14R2VNo === 4 || c14R3VNo === 4 || c14R4VNo === 4){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(4);
                 setC14R2VNo(4);
                 setC14R3VNo(4);
                 setC14R4VNo(4);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(4);
                 setC14R2VNo(4);
                 setC14R3VNo(4);
                 setC14R4VNo(4);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(4);
                 setC14R2VNo(4);
                 setC14R3VNo(4);
                 setC14R4VNo(4);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(4);
                  setC14R2VNo(4);
                  setC14R3VNo(4);
                  setC14R4VNo(4);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 5 || c14R2VNo === 5 || c14R3VNo === 5 || c14R4VNo === 5){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(5);
                 setC14R2VNo(5);
                 setC14R3VNo(5);
                 setC14R4VNo(5);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(5);
                 setC14R2VNo(5);
                 setC14R3VNo(5);
                 setC14R4VNo(5);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(5);
                 setC14R2VNo(5);
                 setC14R3VNo(5);
                 setC14R4VNo(5);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(5);
                  setC14R2VNo(5);
                  setC14R3VNo(5);
                  setC14R4VNo(5);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 6 || c14R2VNo === 6 || c14R3VNo === 6 || c14R4VNo === 6){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(6);
                 setC14R2VNo(6);
                 setC14R3VNo(6);
                 setC14R4VNo(6);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(6);
                 setC14R2VNo(6);
                 setC14R3VNo(6);
                 setC14R4VNo(6);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(6);
                 setC14R2VNo(6);
                 setC14R3VNo(6);
                 setC14R4VNo(6);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(6);
                  setC14R2VNo(6);
                  setC14R3VNo(6);
                  setC14R4VNo(6);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 7 || c14R2VNo === 7 || c14R3VNo === 7 || c14R4VNo === 7){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(7);
                 setC14R2VNo(7);
                 setC14R3VNo(7);
                 setC14R4VNo(7);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(7);
                 setC14R2VNo(7);
                 setC14R3VNo(7);
                 setC14R4VNo(7);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(7);
                 setC14R2VNo(7);
                 setC14R3VNo(7);
                 setC14R4VNo(7);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(7);
                  setC14R2VNo(7);
                  setC14R3VNo(7);
                  setC14R4VNo(7);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 8 || c14R2VNo === 8 || c14R3VNo === 8 || c14R4VNo === 8){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(8);
                 setC14R2VNo(8);
                 setC14R3VNo(8);
                 setC14R4VNo(8);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(8);
                 setC14R2VNo(8);
                 setC14R3VNo(8);
                 setC14R4VNo(8);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(8);
                 setC14R2VNo(8);
                 setC14R3VNo(8);
                 setC14R4VNo(8);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(8);
                  setC14R2VNo(8);
                  setC14R3VNo(8);
                  setC14R4VNo(8);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 9 || c14R2VNo === 9 || c14R3VNo === 9 || c14R4VNo === 9){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(9);
                 setC14R2VNo(9);
                 setC14R3VNo(9);
                 setC14R4VNo(9);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(9);
                 setC14R2VNo(9);
                 setC14R3VNo(9);
                 setC14R4VNo(9);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(9);
                 setC14R2VNo(9);
                 setC14R3VNo(9);
                 setC14R4VNo(9);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(9);
                  setC14R2VNo(9);
                  setC14R3VNo(9);
                  setC14R4VNo(9);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 10 || c14R2VNo === 10 || c14R3VNo === 10 || c14R4VNo === 10){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(10);
                 setC14R2VNo(10);
                 setC14R3VNo(10);
                 setC14R4VNo(10);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(10);
                 setC14R2VNo(10);
                 setC14R3VNo(10);
                 setC14R4VNo(10);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(10);
                 setC14R2VNo(10);
                 setC14R3VNo(10);
                 setC14R4VNo(10);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(10);
                  setC14R2VNo(10);
                  setC14R3VNo(10);
                  setC14R4VNo(10);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        } else if (c14R1VNo === 11 || c14R2VNo === 11 || c14R3VNo === 11 || c14R4VNo === 11){

          if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === false) {
       
            setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(11);
                 setC14R2VNo(11);
                 setC14R3VNo(11);
                 setC14R4VNo(11);
                 setSepOn2LC14(false);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
              
               } else if (sepOn3LC14 === true && sepOnLC14 === false && sepOn2LC14 === true) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(11);
                 setC14R2VNo(11);
                 setC14R3VNo(11);
                 setC14R4VNo(11);
                 setSepOn2LC14(true);
                 setSepOnLC14(false);
                 setSepOn3LC14(false)
               } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === false) {
                 setBaseNakedL14(
                   [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(11);
                 setC14R2VNo(11);
                 setC14R3VNo(11);
                 setC14R4VNo(11);
                 setSepOn2LC14(false);
                 setSepOnLC14(true);
                 setSepOn3LC14(false)
                } else if (sepOn3LC14 === true && sepOnLC14 === true && sepOn2LC14 === true) {
                  setBaseNakedL14(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(11);
                  setC14R2VNo(11);
                  setC14R3VNo(11);
                  setC14R4VNo(11);
                  setSepOn2LC14(true);
                  setSepOnLC14(true);
                  setSepOn3LC14(false)
                } else {
                  alert("Already removed.")
                }
               
        }
        
      } else {
        alert("Select Large Module & Proceed")
      }
    } 

  }

// remove Seperator  of the Medium module
const removeSeperator = (e)=> {

  if (curX === col01X && curY === row01X && c1R1VNo === 1) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setSepOnM1C1(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(2);
        setC1R2VNo(2);
        setSepOnM1C1(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setSepOnM1C1(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6)
          setC1R2VNo(6)
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 7) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 8) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 9) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 10) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } 	 else if (curX === col02X && curY === row01X && c2R1VNo === 1) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setSepOnM1C2(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
        setSepOnM1C2(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setSepOnM1C2(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6)
          setC2R2VNo(6)
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 7) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 8) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setSepOnM1C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setSepOnM1C3(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
        setSepOnM1C3(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
        setSepOnM1C3(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6)
          setC3R2VNo(6)
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 7) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 8) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 9) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(9);
          setC3R2VNo(9);
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setSepOnM1C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setSepOnM1C4(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
        setSepOnM1C4(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
        setSepOnM1C4(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6)
          setC4R2VNo(6)
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 7) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 8) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 9) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(9);
          setC4R2VNo(9);
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setSepOnM1C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setSepOnM1C5(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
        setSepOnM1C5(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
        setSepOnM1C5(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6)
          setC5R2VNo(6)
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 7) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 8) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(9);
          setC5R2VNo(9);
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setSepOnM1C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setSepOnM1C6(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
        setSepOnM1C6(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
        setSepOnM1C6(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6)
          setC6R2VNo(6)
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 7) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 8) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 9) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(9);
          setC6R2VNo(9);
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setSepOnM1C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setSepOnM1C7(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
        setSepOnM1C7(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
        setSepOnM1C7(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6)
          setC7R2VNo(6)
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 7) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 8) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 9) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(9);
          setC7R2VNo(9);
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setSepOnM1C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setSepOnM1C8(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setSepOnM1C8(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setSepOnM1C8(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6)
          setC8R2VNo(6)
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 7) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 8) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 9) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(9);
          setC8R2VNo(9);
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setSepOnM1C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setSepOnM1C9(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
        setSepOnM1C9(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
        setSepOnM1C9(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6)
          setC9R2VNo(6)
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 7) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 8) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 9) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(9);
          setC9R2VNo(9);
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 10) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setSepOnM1C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setSepOnM1C10(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
        setSepOnM1C10(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setSepOnM1C10(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 6) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6)
          setC10R2VNo(6)
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 7) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 8) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 9) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(9);
          setC10R2VNo(9);
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 10) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setSepOnM1C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setSepOnM1C11(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setSepOnM1C11(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setSepOnM1C11(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6)
          setC11R2VNo(6)
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 7) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 8) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 9) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(9);
          setC11R2VNo(9);
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setSepOnM1C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setSepOnM1C12(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
        setSepOnM1C12(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
        setSepOnM1C12(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6)
          setC12R2VNo(6)
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 7) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 8) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 9) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(9);
          setC12R2VNo(9);
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setSepOnM1C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setSepOnM1C13(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
        setSepOnM1C13(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
        setSepOnM1C13(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6)
          setC13R2VNo(6)
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 7) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 8) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 9) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(9);
          setC13R2VNo(9);
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setSepOnM1C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setSepOnM1C14(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
        setSepOnM1C14(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setSepOnM1C14(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6)
          setC14R2VNo(6)
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 7) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 8) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 9) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(9);
          setC14R2VNo(9);
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setSepOnM1C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col01X && curY === row02X && c1R2VNo === 1) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(1);
          setC1R2VNo(1);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(1);
          setC1R3VNo(1);
          setSepOnM2C1(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(2);
        setC1R2VNo(2);
        setSepOnM1C1(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(2);
        setC1R3VNo(2);
        setSepOnM2C1(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setSepOnM1C1(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(3);
        setC1R3VNo(3);
        setSepOnM2C1(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(4);
          setC1R3VNo(4);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(5);
          setC1R3VNo(5);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(6)
          setC1R2VNo(6)
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(6)
          setC1R3VNo(6)
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col01X && curY === row02X && c1R2VNo === 7) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setSepOnM1C1(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(7);
          setC1R3VNo(7);
          setSepOnM2C1(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col01X && curY === row02X && c1R2VNo === 8) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(8);
          setC1R3VNo(8);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 9) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(9);
          setC1R3VNo(9);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(10);
          setC1R3VNo(10);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
    if (c1r1MT === "M" && c1r2MT === "M") {
      if (sepOnM1C1 === true) {
        setBaseNakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setSepOnM1C1(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(11);
          setC1R3VNo(11);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 1) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setSepOnM1C2(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(1);
          setC2R3VNo(1);
          setSepOnM2C2(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
        setSepOnM1C2(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(2);
        setC2R3VNo(2);
        setSepOnM2C2(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setSepOnM1C2(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(3);
        setC2R3VNo(3);
        setSepOnM2C2(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(4);
          setC2R2VNo(4);
          setSepOnM1C2(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(4);
          setC2R3VNo(4);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(5);
          setC2R2VNo(5);
          setSepOnM1C2(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(5);
          setC2R3VNo(5);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(6)
          setC2R2VNo(6)
          setSepOnM1C2(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(6)
          setC2R3VNo(6)
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 7) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(7);
          setC2R2VNo(7);
          setSepOnM1C2(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(7);
          setC2R3VNo(7);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 8) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setSepOnM1C2(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(8);
          setC2R3VNo(8);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(8);
          setC2R2VNo(8);
          setSepOnM1C2(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(9);
          setC2R3VNo(9);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
          setSepOnM1C2(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(10);
          setC2R3VNo(10);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
    if (c2r1MT === "M" && c2r2MT === "M") {
      if (sepOnM1C2 === true) {
        setBaseNakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(11);
          setC2R2VNo(11);
          setSepOnM1C2(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(11);
          setC2R3VNo(11);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setSepOnM1C3(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(1);
          setC3R3VNo(1);
          setSepOnM2C3(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
        setSepOnM1C3(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(2);
        setC3R3VNo(2);
        setSepOnM2C3(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
        setSepOnM1C3(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(3);
        setC3R3VNo(3);
        setSepOnM2C3(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(4);
          setC3R2VNo(4);
          setSepOnM1C3(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(4);
          setC3R3VNo(4);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(5);
          setC3R2VNo(5);
          setSepOnM1C3(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(5);
          setC3R3VNo(5);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(6)
          setC3R2VNo(6)
          setSepOnM1C3(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(6)
          setC3R3VNo(6)
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 7) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(7);
          setC3R2VNo(7);
          setSepOnM1C3(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(7);
          setC3R3VNo(7);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 8) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setSepOnM1C3(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(8);
          setC3R3VNo(8);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 9) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(8);
          setC3R2VNo(8);
          setSepOnM1C3(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(9);
          setC3R3VNo(9);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(10);
          setC3R2VNo(10);
          setSepOnM1C3(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(10);
          setC3R3VNo(10);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
    if (c3r1MT === "M" && c3r2MT === "M") {
      if (sepOnM1C3 === true) {
        setBaseNakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(11);
          setC3R2VNo(11);
          setSepOnM1C3(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(11);
          setC3R3VNo(11);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(1);
          setC4R2VNo(1);
          setSepOnM1C4(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(1);
          setC4R3VNo(1);
          setSepOnM2C4(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
        setSepOnM1C4(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(2);
        setC4R3VNo(2);
        setSepOnM2C4(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
        setSepOnM1C4(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(3);
        setC4R3VNo(3);
        setSepOnM2C4(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(4);
          setC4R2VNo(4);
          setSepOnM1C4(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(4);
          setC4R3VNo(4);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(5);
          setC4R2VNo(5);
          setSepOnM1C4(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(5);
          setC4R3VNo(5);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(6)
          setC4R2VNo(6)
          setSepOnM1C4(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(6)
          setC4R3VNo(6)
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 7) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(7);
          setC4R2VNo(7);
          setSepOnM1C4(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(7);
          setC4R3VNo(7);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 8) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setSepOnM1C4(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(8);
          setC4R3VNo(8);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 9) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(8);
          setC4R2VNo(8);
          setSepOnM1C4(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(9);
          setC4R3VNo(9);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(10);
          setC4R2VNo(10);
          setSepOnM1C4(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(10);
          setC4R3VNo(10);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
    if (c4r1MT === "M" && c4r2MT === "M") {
      if (sepOnM1C4 === true) {
        setBaseNakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
          setC4R1VNo(11);
          setC4R2VNo(11);
          setSepOnM1C4(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(11);
          setC4R3VNo(11);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setSepOnM1C5(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(1);
          setC5R3VNo(1);
          setSepOnM2C5(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
        setSepOnM1C5(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(2);
        setC5R3VNo(2);
        setSepOnM2C5(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
        setSepOnM1C5(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(3);
        setC5R3VNo(3);
        setSepOnM2C5(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(4);
          setC5R2VNo(4);
          setSepOnM1C5(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(4);
          setC5R3VNo(4);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setSepOnM1C5(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(5);
          setC5R3VNo(5);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(6)
          setC5R2VNo(6)
          setSepOnM1C5(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(6)
          setC5R3VNo(6)
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 7) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(7);
          setC5R2VNo(7);
          setSepOnM1C5(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(7);
          setC5R3VNo(7);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 8) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setSepOnM1C5(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(8);
          setC5R3VNo(8);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(8);
          setC5R2VNo(8);
          setSepOnM1C5(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(9);
          setC5R3VNo(9);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(10);
          setC5R2VNo(10);
          setSepOnM1C5(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(10);
          setC5R3VNo(10);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
    if (c5r1MT === "M" && c5r2MT === "M") {
      if (sepOnM1C5 === true) {
        setBaseNakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(11);
          setC5R2VNo(11);
          setSepOnM1C5(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(11);
          setC5R3VNo(11);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setSepOnM1C6(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(1);
          setC6R3VNo(1);
          setSepOnM2C6(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
        setSepOnM1C6(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(2);
        setC6R3VNo(2);
        setSepOnM2C6(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
        setSepOnM1C6(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(3);
        setC6R3VNo(3);
        setSepOnM2C6(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(4);
          setC6R2VNo(4);
          setSepOnM1C6(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(4);
          setC6R3VNo(4);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(5);
          setC6R2VNo(5);
          setSepOnM1C6(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c6r2MT === "M" && c6r3MT === "M"&& c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(5);
          setC6R3VNo(5);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(6)
          setC6R2VNo(6)
          setSepOnM1C6(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(6)
          setC6R3VNo(6)
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 7) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(7);
          setC6R2VNo(7);
          setSepOnM1C6(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(7);
          setC6R3VNo(7);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 8) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setSepOnM1C6(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(8);
          setC6R3VNo(8);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 9) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setSepOnM1C6(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(9);
          setC6R3VNo(9);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(10);
          setC6R2VNo(10);
          setSepOnM1C6(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(10);
          setC6R3VNo(10);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
    if (c6r1MT === "M" && c6r2MT === "M") {
      if (sepOnM1C6 === true) {
        setBaseNakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(11);
          setC6R2VNo(11);
          setSepOnM1C6(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(11);
          setC6R3VNo(11);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(1);
          setC7R2VNo(1);
          setSepOnM1C7(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(1);
          setC7R3VNo(1);
          setSepOnM2C7(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
        setSepOnM1C7(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(2);
        setC7R3VNo(2);
        setSepOnM2C7(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
        setSepOnM1C7(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(3);
        setC7R3VNo(3);
        setSepOnM2C7(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(4);
          setC7R2VNo(4);
          setSepOnM1C7(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(4);
          setC7R3VNo(4);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setSepOnM1C7(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(5);
          setC7R3VNo(5);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(6)
          setC7R2VNo(6)
          setSepOnM1C7(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(6)
          setC7R3VNo(6)
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 7) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(7);
          setC7R2VNo(7);
          setSepOnM1C7(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(7);
          setC7R3VNo(7);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 8) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setSepOnM1C7(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(8);
          setC7R3VNo(8);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 9) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(8);
          setC7R2VNo(8);
          setSepOnM1C7(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(9);
          setC7R3VNo(9);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(10);
          setC7R2VNo(10);
          setSepOnM1C7(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(10);
          setC7R3VNo(10);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
    if (c7r1MT === "M" && c7r2MT === "M") {
      if (sepOnM1C7 === true) {
        setBaseNakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(11);
          setC7R2VNo(11);
          setSepOnM1C7(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(11);
          setC7R3VNo(11);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(1);
          setC8R2VNo(1);
          setSepOnM1C8(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(1);
          setC8R3VNo(1);
          setSepOnM2C8(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setSepOnM1C8(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(2);
        setC8R3VNo(2);
        setSepOnM2C8(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setSepOnM1C8(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(3);
        setC8R3VNo(3);
        setSepOnM2C8(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(4);
          setC8R2VNo(4);
          setSepOnM1C8(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(4);
          setC8R3VNo(4);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setSepOnM1C8(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(5);
          setC8R3VNo(5);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(6)
          setC8R2VNo(6)
          setSepOnM1C8(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(6)
          setC8R3VNo(6)
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 7) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(7);
          setC8R2VNo(7);
          setSepOnM1C8(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(7);
          setC8R3VNo(7);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 8) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setSepOnM1C8(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(8);
          setC8R3VNo(8);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 9) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(8);
          setC8R2VNo(8);
          setSepOnM1C8(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(9);
          setC8R3VNo(9);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(10);
          setC8R2VNo(10);
          setSepOnM1C8(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(10);
          setC8R3VNo(10);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
    if (c8r1MT === "M" && c8r2MT === "M") {
      if (sepOnM1C8 === true) {
        setBaseNakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(11);
          setC8R2VNo(11);
          setSepOnM1C8(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(11);
          setC8R3VNo(11);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(1);
          setC9R2VNo(1);
          setSepOnM1C9(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(1);
          setC9R3VNo(1);
          setSepOnM2C9(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
        setSepOnM1C9(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(2);
        setC9R3VNo(2);
        setSepOnM2C9(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
        setSepOnM1C9(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(3);
        setC9R3VNo(3);
        setSepOnM2C9(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(4);
          setC9R2VNo(4);
          setSepOnM1C9(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(4);
          setC9R3VNo(4);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(5);
          setC9R2VNo(5);
          setSepOnM1C9(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(5);
          setC9R3VNo(5);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(6)
          setC9R2VNo(6)
          setSepOnM1C9(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(6)
          setC9R3VNo(6)
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 7) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(7);
          setC9R2VNo(7);
          setSepOnM1C9(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(7);
          setC9R3VNo(7);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 8) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setSepOnM1C9(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(8);
          setC9R3VNo(8);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 9) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(8);
          setC9R2VNo(8);
          setSepOnM1C9(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(9);
          setC9R3VNo(9);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 10) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(10);
          setC9R2VNo(10);
          setSepOnM1C9(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(10);
          setC9R3VNo(10);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
    if (c9r1MT === "M" && c9r2MT === "M") {
      if (sepOnM1C9 === true) {
        setBaseNakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
          setC9R1VNo(11);
          setC9R2VNo(11);
          setSepOnM1C9(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(11);
          setC9R3VNo(11);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setSepOnM1C10(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(1);
          setC10R3VNo(1);
          setSepOnM2C10(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
        setSepOnM1C10(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(2);
        setC10R3VNo(2);
        setSepOnM2C10(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setSepOnM1C10(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(3);
        setC10R3VNo(3);
        setSepOnM2C10(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(4);
          setC10R2VNo(4);
          setSepOnM1C10(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(4);
          setC10R3VNo(4);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(5);
          setC10R2VNo(5);
          setSepOnM1C10(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(5);
          setC10R3VNo(5);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 6) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(6)
          setC10R2VNo(6)
          setSepOnM1C10(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(6)
          setC10R3VNo(6)
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 7) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(7);
          setC10R2VNo(7);
          setSepOnM1C10(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(7);
          setC10R3VNo(7);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 8) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setSepOnM1C10(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(8);
          setC10R3VNo(8);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 9) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(8);
          setC10R2VNo(8);
          setSepOnM1C10(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(9);
          setC10R3VNo(9);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 10) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(10);
          setC10R2VNo(10);
          setSepOnM1C10(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(10);
          setC10R3VNo(10);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
    if (c10r1MT === "M" && c10r2MT === "M") {
      if (sepOnM1C10 === true) {
        setBaseNakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(11);
          setC10R2VNo(11);
          setSepOnM1C10(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(11);
          setC10R3VNo(11);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(1);
          setC11R2VNo(1);
          setSepOnM1C11(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(1);
          setC11R3VNo(1);
          setSepOnM2C11(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setSepOnM1C11(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(2);
        setC11R3VNo(2);
        setSepOnM2C11(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setSepOnM1C11(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(3);
        setC11R3VNo(3);
        setSepOnM2C11(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(4);
          setC11R2VNo(4);
          setSepOnM1C11(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(4);
          setC11R3VNo(4);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(5);
          setC11R2VNo(5);
          setSepOnM1C11(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(5);
          setC11R3VNo(5);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(6)
          setC11R2VNo(6)
          setSepOnM1C11(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(6)
          setC11R3VNo(6)
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 7) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(7);
          setC11R2VNo(7);
          setSepOnM1C11(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(7);
          setC11R3VNo(7);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 8) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setSepOnM1C11(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(8);
          setC11R3VNo(8);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 9) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(8);
          setC11R2VNo(8);
          setSepOnM1C11(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(9);
          setC11R3VNo(9);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(10);
          setC11R2VNo(10);
          setSepOnM1C11(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(10);
          setC11R3VNo(10);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
    if (c11r1MT === "M" && c11r2MT === "M") {
      if (sepOnM1C11 === true) {
        setBaseNakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
          setC11R1VNo(11);
          setC11R2VNo(11);
          setSepOnM1C11(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(11);
          setC11R3VNo(11);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(1);
          setC12R2VNo(1);
          setSepOnM1C12(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(1);
          setC12R3VNo(1);
          setSepOnM2C12(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
        setSepOnM1C12(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(2);
        setC12R3VNo(2);
        setSepOnM2C12(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
        setSepOnM1C12(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(3);
        setC12R3VNo(3);
        setSepOnM2C12(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(4);
          setC12R2VNo(4);
          setSepOnM1C12(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(4);
          setC12R3VNo(4);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(5);
          setC12R2VNo(5);
          setSepOnM1C12(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(5);
          setC12R3VNo(5);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(6)
          setC12R2VNo(6)
          setSepOnM1C12(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(6)
          setC12R3VNo(6)
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 7) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(7);
          setC12R2VNo(7);
          setSepOnM1C12(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(7);
          setC12R3VNo(7);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 8) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setSepOnM1C12(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(8);
          setC12R3VNo(8);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 9) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(8);
          setC12R2VNo(8);
          setSepOnM1C12(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(9);
          setC12R3VNo(9);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(10);
          setC12R2VNo(10);
          setSepOnM1C12(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(10);
          setC12R3VNo(10);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
    if (c12r1MT === "M" && c12r2MT === "M") {
      if (sepOnM1C12 === true) {
        setBaseNakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
          setC12R1VNo(11);
          setC12R2VNo(11);
          setSepOnM1C12(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(11);
          setC12R3VNo(11);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(1);
          setC13R2VNo(1);
          setSepOnM1C13(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(1);
          setC13R3VNo(1);
          setSepOnM2C13(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
        setSepOnM1C13(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(2);
        setC13R3VNo(2);
        setSepOnM2C13(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
        setSepOnM1C13(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(3);
        setC13R3VNo(3);
        setSepOnM2C13(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(4);
          setC13R2VNo(4);
          setSepOnM1C13(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(4);
          setC13R3VNo(4);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(5);
          setC13R2VNo(5);
          setSepOnM1C13(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(5);
          setC13R3VNo(5);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(6)
          setC13R2VNo(6)
          setSepOnM1C13(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(6)
          setC13R3VNo(6)
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 7) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(7);
          setC13R2VNo(7);
          setSepOnM1C13(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(7);
          setC13R3VNo(7);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 8) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setSepOnM1C13(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(8);
          setC13R3VNo(8);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 9) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(8);
          setC13R2VNo(8);
          setSepOnM1C13(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(9);
          setC13R3VNo(9);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(10);
          setC13R2VNo(10);
          setSepOnM1C13(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(10);
          setC13R3VNo(10);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
    if (c13r1MT === "M" && c13r2MT === "M") {
      if (sepOnM1C13 === true) {
        setBaseNakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
          setC13R1VNo(11);
          setC13R2VNo(11);
          setSepOnM1C13(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(11);
          setC13R3VNo(11);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(1);
          setC14R2VNo(1);
          setSepOnM1C14(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(1);
          setC14R3VNo(1);
          setSepOnM2C14(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
        setSepOnM1C14(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(2);
        setC14R3VNo(2);
        setSepOnM2C14(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setSepOnM1C14(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(3);
        setC14R3VNo(3);
        setSepOnM2C14(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(4);
          setC14R2VNo(4);
          setSepOnM1C14(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(4);
          setC14R3VNo(4);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(5);
          setC14R2VNo(5);
          setSepOnM1C14(false);
       } else {
        alert("No seperator on current selection.")
      } 
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(5);
          setC14R3VNo(5);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(6)
          setC14R2VNo(6)
          setSepOnM1C14(false);
      } else {
        alert("No seperator on current selection.")
      } 
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(6)
          setC14R3VNo(6)
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 7) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(7);
          setC14R2VNo(7);
          setSepOnM1C14(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(7);
          setC14R3VNo(7);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 8) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setSepOnM1C14(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(8);
          setC14R3VNo(8);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 9) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(8);
          setC14R2VNo(8);
          setSepOnM1C14(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(9);
          setC14R3VNo(9);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
    if (c14r1MT === "M" && c14r2MT === "M") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(10);
          setC14R2VNo(10);
          setSepOnM1C14(false);
        } else {
        alert("No seperator on current selection.")
      } 
    } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(10);
          setC14R3VNo(10);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
    if (c14r1MT === "M" && c14r2MT === "M" && c14r1MT === "S") {
      if (sepOnM1C14 === true) {
        setBaseNakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
          setC14R1VNo(11);
          setC14R2VNo(11);
          setSepOnM1C14(false);
        } else {
        alert("No seperator on current selection.")
      }
    } else if (c14r2MT === "M" && c14r3MT === "M") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(11);
          setC14R3VNo(11);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(1);
          setC1R3VNo(1);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnM3C1(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(2);
        setC1R3VNo(2);
        setSepOnM2C1(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(2);
        setC1R4VNo(2);
        setSepOnM3C1(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(3);
        setC1R3VNo(3);
        setSepOnM2C1(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(3);
        setC1R4VNo(3);
        setSepOnM3C1(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(4);
          setC1R3VNo(4);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(5);
          setC1R3VNo(5);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(6)
          setC1R3VNo(6)
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(6)
          setC1R4VNo(6)
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col01X && curY === row03X && c1R3VNo === 7) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(7);
          setC1R3VNo(7);
          setSepOnM2C1(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnM3C1(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col01X && curY === row03X && c1R3VNo === 8) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(8);
          setC1R3VNo(8);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 9) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(9);
          setC1R3VNo(9);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(10);
          setC1R3VNo(10);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
    if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
      if (sepOnM2C1 === true) {
        setRow2NakedM01(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
          setC1R2VNo(11);
          setC1R3VNo(11);
          setSepOnM2C1(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col02X && curY === row03X && c2R3VNo === 1) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(1);
          setC2R3VNo(1);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnM3C2(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(2);
        setC2R3VNo(2);
        setSepOnM2C2(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(2);
        setC2R4VNo(2);
        setSepOnM3C2(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(3);
        setC2R3VNo(3);
        setSepOnM2C2(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(3);
        setC2R4VNo(3);
        setSepOnM3C2(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(4);
          setC2R3VNo(4);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(5);
          setC2R3VNo(5);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(6)
          setC2R3VNo(6)
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(6)
          setC2R4VNo(6)
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col02X && curY === row03X && c2R3VNo === 7) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(7);
          setC2R3VNo(7);
          setSepOnM2C2(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnM3C2(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col02X && curY === row03X && c2R3VNo === 8) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(8);
          setC2R3VNo(8);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(9);
          setC2R3VNo(9);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(10);
          setC2R3VNo(10);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
    if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
      if (sepOnM2C2 === true) {
        setRow2NakedM02(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(11);
          setC2R3VNo(11);
          setSepOnM2C2(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(1);
          setC3R3VNo(1);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnM3C3(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(2);
        setC3R3VNo(2);
        setSepOnM2C3(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(2);
        setC3R4VNo(2);
        setSepOnM3C3(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(3);
        setC3R3VNo(3);
        setSepOnM2C3(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(3);
        setC3R4VNo(3);
        setSepOnM3C3(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(4);
          setC3R3VNo(4);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(5);
          setC3R3VNo(5);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(6)
          setC3R3VNo(6)
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(6)
          setC3R4VNo(6)
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col03X && curY === row03X && c3R3VNo === 7) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(7);
          setC3R3VNo(7);
          setSepOnM2C3(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnM3C3(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col03X && curY === row03X && c3R3VNo === 8) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(8);
          setC3R3VNo(8);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 9) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(9);
          setC3R3VNo(9);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(10);
          setC3R3VNo(10);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
    if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
      if (sepOnM2C3 === true) {
        setRow2NakedM03(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(11);
          setC3R3VNo(11);
          setSepOnM2C3(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c3r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(1);
          setC4R3VNo(1);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnM3C4(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(2);
        setC4R3VNo(2);
        setSepOnM2C4(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(2);
        setC4R4VNo(2);
        setSepOnM3C4(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(3);
        setC4R3VNo(3);
        setSepOnM2C4(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(3);
        setC4R4VNo(3);
        setSepOnM3C4(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(4);
          setC4R3VNo(4);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(5);
          setC4R3VNo(5);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(6)
          setC4R3VNo(6)
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(6)
          setC4R4VNo(6)
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col04X && curY === row03X && c4R3VNo === 7) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(7);
          setC4R3VNo(7);
          setSepOnM2C4(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnM3C4(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col04X && curY === row03X && c4R3VNo === 8) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(8);
          setC4R3VNo(8);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 9) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(9);
          setC4R3VNo(9);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(10);
          setC4R3VNo(10);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
    if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
      if (sepOnM2C4 === true) {
        setRow2NakedM04(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
          setC4R2VNo(11);
          setC4R3VNo(11);
          setSepOnM2C4(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(1);
          setC5R3VNo(1);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnM3C5(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(2);
        setC5R3VNo(2);
        setSepOnM2C5(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(2);
        setC5R4VNo(2);
        setSepOnM3C5(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(3);
        setC5R3VNo(3);
        setSepOnM2C5(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(3);
        setC5R4VNo(3);
        setSepOnM3C5(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(4);
          setC5R3VNo(4);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(5);
          setC5R3VNo(5);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(6)
          setC5R3VNo(6)
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(6)
          setC5R4VNo(6)
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col05X && curY === row03X && c5R3VNo === 7) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(7);
          setC5R3VNo(7);
          setSepOnM2C5(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnM3C5(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col05X && curY === row03X && c5R3VNo === 8) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(8);
          setC5R3VNo(8);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(9);
          setC5R3VNo(9);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(10);
          setC5R3VNo(10);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
    if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
      if (sepOnM2C5 === true) {
        setRow2NakedM05(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(11);
          setC5R3VNo(11);
          setSepOnM2C5(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM05(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(1);
          setC6R3VNo(1);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnM3C6(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(2);
        setC6R3VNo(2);
        setSepOnM2C6(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(2);
        setC6R4VNo(2);
        setSepOnM3C6(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(3);
        setC6R3VNo(3);
        setSepOnM2C6(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(3);
        setC6R4VNo(3);
        setSepOnM3C6(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(4);
          setC6R3VNo(4);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(5);
          setC6R3VNo(5);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(6)
          setC6R3VNo(6)
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(6)
          setC6R4VNo(6)
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col06X && curY === row03X && c6R3VNo === 7) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(7);
          setC6R3VNo(7);
          setSepOnM2C6(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnM3C6(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col06X && curY === row03X && c6R3VNo === 8) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(8);
          setC6R3VNo(8);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 9) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(9);
          setC6R3VNo(9);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(10);
          setC6R3VNo(10);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
    if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
      if (sepOnM2C6 === true) {
        setRow2NakedM06(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
          setC6R2VNo(11);
          setC6R3VNo(11);
          setSepOnM2C6(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(1);
          setC7R3VNo(1);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnM3C7(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(2);
        setC7R3VNo(2);
        setSepOnM2C7(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(2);
        setC7R4VNo(2);
        setSepOnM3C7(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(3);
        setC7R3VNo(3);
        setSepOnM2C7(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(3);
        setC7R4VNo(3);
        setSepOnM3C7(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(4);
          setC7R3VNo(4);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(5);
          setC7R3VNo(5);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(6)
          setC7R3VNo(6)
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(6)
          setC7R4VNo(6)
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col07X && curY === row03X && c7R3VNo === 7) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(7);
          setC7R3VNo(7);
          setSepOnM2C7(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnM3C7(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col07X && curY === row03X && c7R3VNo === 8) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(8);
          setC7R3VNo(8);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 9) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(9);
          setC7R3VNo(9);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(10);
          setC7R3VNo(10);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
    if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
      if (sepOnM2C7 === true) {
        setRow2NakedM07(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
          setC7R2VNo(11);
          setC7R3VNo(11);
          setSepOnM2C7(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(1);
          setC8R3VNo(1);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnM3C8(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(2);
        setC8R3VNo(2);
        setSepOnM2C8(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(2);
        setC8R4VNo(2);
        setSepOnM3C8(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(3);
        setC8R3VNo(3);
        setSepOnM2C8(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(3);
        setC8R4VNo(3);
        setSepOnM3C8(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(4);
          setC8R3VNo(4);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(5);
          setC8R3VNo(5);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(6)
          setC8R3VNo(6)
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(6)
          setC8R4VNo(6)
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col08X && curY === row03X && c8R3VNo === 7) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(7);
          setC8R3VNo(7);
          setSepOnM2C8(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnM3C8(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col08X && curY === row03X && c8R3VNo === 8) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(8);
          setC8R3VNo(8);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 9) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(9);
          setC8R3VNo(9);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(10);
          setC8R3VNo(10);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
    if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
      if (sepOnM2C8 === true) {
        setRow2NakedM08(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
          setC8R2VNo(11);
          setC8R3VNo(11);
          setSepOnM2C8(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(1);
          setC9R3VNo(1);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnM3C9(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(2);
        setC9R3VNo(2);
        setSepOnM2C9(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(2);
        setC9R4VNo(2);
        setSepOnM3C9(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(3);
        setC9R3VNo(3);
        setSepOnM2C9(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(3);
        setC9R4VNo(3);
        setSepOnM3C9(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(4);
          setC9R3VNo(4);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(5);
          setC9R3VNo(5);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(6)
          setC9R3VNo(6)
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(6)
          setC9R4VNo(6)
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col09X && curY === row03X && c9R3VNo === 7) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(7);
          setC9R3VNo(7);
          setSepOnM2C9(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnM3C9(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col09X && curY === row03X && c9R3VNo === 8) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(8);
          setC9R3VNo(8);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 9) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(9);
          setC9R3VNo(9);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 10) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(10);
          setC9R3VNo(10);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
    if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
      if (sepOnM2C9 === true) {
        setRow2NakedM09(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
          setC9R2VNo(11);
          setC9R3VNo(11);
          setSepOnM2C9(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c9r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col10X && curY === row03X && c10R3VNo === 1) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(1);
          setC10R3VNo(1);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnM3C10(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(2);
        setC10R3VNo(2);
        setSepOnM2C10(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(2);
        setC10R4VNo(2);
        setSepOnM3C10(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(3);
        setC10R3VNo(3);
        setSepOnM2C10(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(3);
        setC10R4VNo(3);
        setSepOnM3C10(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(4);
          setC10R3VNo(4);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(5);
          setC10R3VNo(5);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 6) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(6)
          setC10R3VNo(6)
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(6)
          setC10R4VNo(6)
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col10X && curY === row03X && c10R3VNo === 7) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(7);
          setC10R3VNo(7);
          setSepOnM2C10(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnM3C10(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col10X && curY === row03X && c10R3VNo === 8) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(8);
          setC10R3VNo(8);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 9) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(9);
          setC10R3VNo(9);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 10) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(10);
          setC10R3VNo(10);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {
    if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
      if (sepOnM2C10 === true) {
        setRow2NakedM10(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(11);
          setC10R3VNo(11);
          setSepOnM2C10(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c10r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(1);
          setC11R3VNo(1);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnM3C11(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(2);
        setC11R3VNo(2);
        setSepOnM2C11(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(2);
        setC11R4VNo(2);
        setSepOnM3C11(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(3);
        setC11R3VNo(3);
        setSepOnM2C11(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(3);
        setC11R4VNo(3);
        setSepOnM3C11(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(4);
          setC11R3VNo(4);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(5);
          setC11R3VNo(5);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(6)
          setC11R3VNo(6)
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(6)
          setC11R4VNo(6)
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col11X && curY === row03X && c11R3VNo === 7) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(7);
          setC11R3VNo(7);
          setSepOnM2C11(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnM3C11(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col11X && curY === row03X && c11R3VNo === 8) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(8);
          setC11R3VNo(8);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 9) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(9);
          setC11R3VNo(9);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(10);
          setC11R3VNo(10);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
    if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
      if (sepOnM2C11 === true) {
        setRow2NakedM11(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(11);
          setC11R3VNo(11);
          setSepOnM2C11(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c11r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(1);
          setC12R3VNo(1);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnM3C12(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(2);
        setC12R3VNo(2);
        setSepOnM2C12(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(2);
        setC12R4VNo(2);
        setSepOnM3C12(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(3);
        setC12R3VNo(3);
        setSepOnM2C12(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(3);
        setC12R4VNo(3);
        setSepOnM3C12(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(4);
          setC12R3VNo(4);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(5);
          setC12R3VNo(5);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(6)
          setC12R3VNo(6)
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(6)
          setC12R4VNo(6)
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col12X && curY === row03X && c12R3VNo === 7) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(7);
          setC12R3VNo(7);
          setSepOnM2C12(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnM3C12(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col12X && curY === row03X && c12R3VNo === 8) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(8);
          setC12R3VNo(8);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 9) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(9);
          setC12R3VNo(9);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(10);
          setC12R3VNo(10);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
    if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
      if (sepOnM2C12 === true) {
        setRow2NakedM12(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
          setC12R2VNo(11);
          setC12R3VNo(11);
          setSepOnM2C12(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c12r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(1);
          setC13R3VNo(1);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnM3C13(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(2);
        setC13R3VNo(2);
        setSepOnM2C13(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(2);
        setC13R4VNo(2);
        setSepOnM3C13(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(3);
        setC13R3VNo(3);
        setSepOnM2C13(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(3);
        setC13R4VNo(3);
        setSepOnM3C13(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(4);
          setC13R3VNo(4);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(5);
          setC13R3VNo(5);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(6)
          setC13R3VNo(6)
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(6)
          setC13R4VNo(6)
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col13X && curY === row03X && c13R3VNo === 7) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(7);
          setC13R3VNo(7);
          setSepOnM2C13(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnM3C13(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col13X && curY === row03X && c13R3VNo === 8) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(8);
          setC13R3VNo(8);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 9) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(9);
          setC13R3VNo(9);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) { 
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(10);
          setC13R3VNo(10);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
    if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
      if (sepOnM2C13 === true) {
        setRow2NakedM13(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
          setC13R2VNo(11);
          setC13R3VNo(11);
          setSepOnM2C13(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c13r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
        [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(1);
          setC14R3VNo(1);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }
    } else if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnM3C14(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(2);
        setC14R3VNo(2);
        setSepOnM2C14(false);
      } else {
        alert("No seperator on current selection.")
      }
    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(2);
        setC14R4VNo(2);
        setSepOnM3C14(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
        [
        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(3);
        setC14R3VNo(3);
        setSepOnM2C14(false);

      } else {
        alert("No seperator on current selection.")
      }
    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(3);
        setC14R4VNo(3);
        setSepOnM3C14(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(4);
          setC14R3VNo(4);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(5);
          setC14R3VNo(5);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }

   } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(6)
          setC14R3VNo(6)
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(6)
          setC14R4VNo(6)
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }
      } else {
        alert("Select Medium Module & Proceed");
      }

  } else if (curX === col14X && curY === row03X && c14R3VNo === 7) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(7);
          setC14R3VNo(7);
          setSepOnM2C14(false);
      } else {
        alert("No seperator on current selection.")
      }
    
    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnM3C14(false);
         } else {
          alert("No seperator on current selection.")
        }
    } else {
      alert("Select Medium Module & Proceed");
    }
  
  } else if (curX === col14X && curY === row03X && c14R3VNo === 8) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(8);
          setC14R3VNo(8);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 9) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(9);
          setC14R3VNo(9);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(10);
          setC14R3VNo(10);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        }
  } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
    if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
      if (sepOnM2C14 === true) {
        setRow2NakedM14(
            [
          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(11);
          setC14R3VNo(11);
          setSepOnM2C14(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else if (c14r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(1);
          setC1R4VNo(1);
          setSepOnM3C1(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(2);
        setC1R4VNo(2);
        setSepOnM3C1(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(3);
        setC1R4VNo(3);
        setSepOnM3C1(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(4);
          setC1R4VNo(4);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(5);
          setC1R4VNo(5);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(6)
          setC1R4VNo(6)
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 7) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(7);
          setC1R4VNo(7);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 8) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(8);
          setC1R4VNo(8);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 9) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(9);
          setC1R4VNo(9);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 10) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(10);
          setC1R4VNo(10);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
    if (c1r3MT === "M" && c1r4MT === "M") {
      if (sepOnM3C1 === true) {
        setRow3NakedM01(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
          setC1R3VNo(11);
          setC1R4VNo(11);
          setSepOnM3C1(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } 	 else if (curX === col02X && curY === row04X && c2R3VNo === 1) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(1);
          setC2R4VNo(1);
          setSepOnM3C2(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 2) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(2);
        setC2R4VNo(2);
        setSepOnM3C2(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 3) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(3);
        setC2R4VNo(3);
        setSepOnM3C2(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 4) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(4);
          setC2R4VNo(4);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 5) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(5);
          setC2R4VNo(5);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 6) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(6)
          setC2R4VNo(6)
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 7) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(7);
          setC2R4VNo(7);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 8) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(8);
          setC2R4VNo(8);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 9) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(9);
          setC2R4VNo(9);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 10) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(10);
          setC2R4VNo(10);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col02X && curY === row04X && c2R3VNo === 11) {
    if (c2r3MT === "M" && c2r4MT === "M") {
      if (sepOnM3C2 === true) {
        setRow3NakedM02(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(11);
          setC2R4VNo(11);
          setSepOnM3C2(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col03X && curY === row04X && c3R3VNo === 1) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(1);
          setC3R4VNo(1);
          setSepOnM3C3(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 2) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(2);
        setC3R4VNo(2);
        setSepOnM3C3(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 3) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(3);
        setC3R4VNo(3);
        setSepOnM3C3(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 4) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(4);
          setC3R4VNo(4);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 5) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(5);
          setC3R4VNo(5);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 6) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(6)
          setC3R4VNo(6)
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 7) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(7);
          setC3R4VNo(7);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 8) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(8);
          setC3R4VNo(8);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 9) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(9);
          setC3R4VNo(9);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 10) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(10);
          setC3R4VNo(10);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col03X && curY === row04X && c3R3VNo === 11) {
    if (c3r3MT === "M" && c3r4MT === "M") {
      if (sepOnM3C3 === true) {
        setRow3NakedM03(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(11);
          setC3R4VNo(11);
          setSepOnM3C3(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col04X && curY === row04X && c4R3VNo === 1) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(1);
          setC4R4VNo(1);
          setSepOnM3C4(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 2) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(2);
        setC4R4VNo(2);
        setSepOnM3C4(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 3) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(3);
        setC4R4VNo(3);
        setSepOnM3C4(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 4) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(4);
          setC4R4VNo(4);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 5) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(5);
          setC4R4VNo(5);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 6) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(6)
          setC4R4VNo(6)
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 7) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(7);
          setC4R4VNo(7);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 8) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(8);
          setC4R4VNo(8);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 9) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(9);
          setC4R4VNo(9);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 10) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(10);
          setC4R4VNo(10);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col04X && curY === row04X && c4R3VNo === 11) {
    if (c4r3MT === "M" && c4r4MT === "M") {
      if (sepOnM3C4 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
          setC4R3VNo(11);
          setC4R4VNo(11);
          setSepOnM3C4(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col05X && curY === row04X && c5R3VNo === 1) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(1);
          setC5R4VNo(1);
          setSepOnM3C5(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 2) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(2);
        setC5R4VNo(2);
        setSepOnM3C5(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 3) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(3);
        setC5R4VNo(3);
        setSepOnM3C5(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 4) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(4);
          setC5R4VNo(4);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 5) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(5);
          setC5R4VNo(5);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 6) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(6)
          setC5R4VNo(6)
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 7) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(7);
          setC5R4VNo(7);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 8) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(8);
          setC5R4VNo(8);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 9) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(9);
          setC5R4VNo(9);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 10) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(10);
          setC5R4VNo(10);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col05X && curY === row04X && c5R3VNo === 11) {
    if (c5r3MT === "M" && c5r4MT === "M") {
      if (sepOnM3C5 === true) {
        setRow3NakedM04(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(11);
          setC5R4VNo(11);
          setSepOnM3C5(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col06X && curY === row04X && c6R3VNo === 1) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(1);
          setC6R4VNo(1);
          setSepOnM3C6(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 2) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(2);
        setC6R4VNo(2);
        setSepOnM3C6(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 3) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(3);
        setC6R4VNo(3);
        setSepOnM3C6(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 4) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(4);
          setC6R4VNo(4);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 5) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(5);
          setC6R4VNo(5);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 6) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(6)
          setC6R4VNo(6)
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 7) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(7);
          setC6R4VNo(7);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 8) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(8);
          setC6R4VNo(8);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 9) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(9);
          setC6R4VNo(9);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 10) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(10);
          setC6R4VNo(10);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col06X && curY === row04X && c6R3VNo === 11) {
    if (c6r3MT === "M" && c6r4MT === "M") {
      if (sepOnM3C6 === true) {
        setRow3NakedM06(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
          setC6R3VNo(11);
          setC6R4VNo(11);
          setSepOnM3C6(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col07X && curY === row04X && c7R3VNo === 1) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(1);
          setC7R4VNo(1);
          setSepOnM3C7(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 2) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(2);
        setC7R4VNo(2);
        setSepOnM3C7(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 3) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(3);
        setC7R4VNo(3);
        setSepOnM3C7(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 4) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(4);
          setC7R4VNo(4);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 5) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(5);
          setC7R4VNo(5);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 6) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(6)
          setC7R4VNo(6)
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 7) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(7);
          setC7R4VNo(7);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 8) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(8);
          setC7R4VNo(8);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 9) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(9);
          setC7R4VNo(9);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 10) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(10);
          setC7R4VNo(10);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col07X && curY === row04X && c7R3VNo === 11) {
    if (c7r3MT === "M" && c7r4MT === "M") {
      if (sepOnM3C7 === true) {
        setRow3NakedM07(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
          setC7R3VNo(11);
          setC7R4VNo(11);
          setSepOnM3C7(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col08X && curY === row04X && c8R3VNo === 1) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(1);
          setC8R4VNo(1);
          setSepOnM3C8(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 2) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(2);
        setC8R4VNo(2);
        setSepOnM3C8(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 3) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(3);
        setC8R4VNo(3);
        setSepOnM3C8(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 4) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(4);
          setC8R4VNo(4);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 5) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(5);
          setC8R4VNo(5);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 6) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(6)
          setC8R4VNo(6)
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 7) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(7);
          setC8R4VNo(7);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 8) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(8);
          setC8R4VNo(8);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 9) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(9);
          setC8R4VNo(9);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 10) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(10);
          setC8R4VNo(10);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col08X && curY === row04X && c8R3VNo === 11) {
    if (c8r3MT === "M" && c8r4MT === "M") {
      if (sepOnM3C8 === true) {
        setRow3NakedM08(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
          setC8R3VNo(11);
          setC8R4VNo(11);
          setSepOnM3C8(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col09X && curY === row04X && c9R3VNo === 1) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(1);
          setC9R4VNo(1);
          setSepOnM3C9(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 2) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(2);
        setC9R4VNo(2);
        setSepOnM3C9(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 3) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(3);
        setC9R4VNo(3);
        setSepOnM3C9(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 4) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(4);
          setC9R4VNo(4);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 5) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(5);
          setC9R4VNo(5);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 6) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(6)
          setC9R4VNo(6)
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 7) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(7);
          setC9R4VNo(7);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 8) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(8);
          setC9R4VNo(8);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 9) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(9);
          setC9R4VNo(9);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 10) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(10);
          setC9R4VNo(10);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col09X && curY === row04X && c9R3VNo === 11) {
    if (c9r3MT === "M" && c9r4MT === "M") {
      if (sepOnM3C9 === true) {
        setRow3NakedM09(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(11);
          setC9R4VNo(11);
          setSepOnM3C9(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col10X && curY === row04X && c10R3VNo === 1) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(1);
          setC10R4VNo(1);
          setSepOnM3C10(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 2) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(2);
        setC10R4VNo(2);
        setSepOnM3C10(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 3) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(3);
        setC10R4VNo(3);
        setSepOnM3C10(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 4) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(4);
          setC10R4VNo(4);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 5) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(5);
          setC10R4VNo(5);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 6) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(6)
          setC10R4VNo(6)
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 7) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(7);
          setC10R4VNo(7);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 8) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(8);
          setC10R4VNo(8);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 9) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(9);
          setC10R4VNo(9);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 10) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(10);
          setC10R4VNo(10);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col10X && curY === row04X && c10R3VNo === 11) {
    if (c10r3MT === "M" && c10r4MT === "M") {
      if (sepOnM3C10 === true) {
        setRow3NakedM10(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
          setC10R3VNo(11);
          setC10R4VNo(11);
          setSepOnM3C10(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col11X && curY === row04X && c11R3VNo === 1) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(1);
          setC11R4VNo(1);
          setSepOnM3C11(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 2) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(2);
        setC11R4VNo(2);
        setSepOnM3C11(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 3) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(3);
        setC11R4VNo(3);
        setSepOnM3C11(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 4) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(4);
          setC11R4VNo(4);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 5) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(5);
          setC11R4VNo(5);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 6) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(6)
          setC11R4VNo(6)
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 7) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(7);
          setC11R4VNo(7);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 8) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(8);
          setC11R4VNo(8);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 9) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(9);
          setC11R4VNo(9);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 10) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(10);
          setC11R4VNo(10);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col11X && curY === row04X && c11R3VNo === 11) {
    if (c11r3MT === "M" && c11r4MT === "M") {
      if (sepOnM3C11 === true) {
        setRow3NakedM11(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(11);
          setC11R4VNo(11);
          setSepOnM3C11(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col12X && curY === row04X && c12R3VNo === 1) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(1);
          setC12R4VNo(1);
          setSepOnM3C12(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 2) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(2);
        setC12R4VNo(2);
        setSepOnM3C12(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 3) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(3);
        setC12R4VNo(3);
        setSepOnM3C12(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 4) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(4);
          setC12R4VNo(4);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 5) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(5);
          setC12R4VNo(5);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 6) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(6)
          setC12R4VNo(6)
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 7) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(7);
          setC12R4VNo(7);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 8) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(8);
          setC12R4VNo(8);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 9) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(9);
          setC12R4VNo(9);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 10) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(10);
          setC12R4VNo(10);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col12X && curY === row04X && c12R3VNo === 11) {
    if (c12r3MT === "M" && c12r4MT === "M") {
      if (sepOnM3C12 === true) {
        setRow3NakedM12(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(11);
          setC12R4VNo(11);
          setSepOnM3C12(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col13X && curY === row04X && c13R3VNo === 1) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(1);
          setC13R4VNo(1);
          setSepOnM3C13(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 2) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(2);
        setC13R4VNo(2);
        setSepOnM3C13(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 3) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(3);
        setC13R4VNo(3);
        setSepOnM3C13(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 4) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(4);
          setC13R4VNo(4);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 5) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(5);
          setC13R4VNo(5);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 6) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(6)
          setC13R4VNo(6)
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 7) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(7);
          setC13R4VNo(7);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 8) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(8);
          setC13R4VNo(8);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 9) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(9);
          setC13R4VNo(9);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 10) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(10);
          setC13R4VNo(10);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col13X && curY === row04X && c13R3VNo === 11) {
    if (c13r3MT === "M" && c13r4MT === "M") {
      if (sepOnM3C13 === true) {
        setRow3NakedM13(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
          setC13R3VNo(11);
          setC13R4VNo(11);
          setSepOnM3C13(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  }  else if (curX === col14X && curY === row04X && c14R3VNo === 1) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(1);
          setC14R4VNo(1);
          setSepOnM3C14(false);
      } else {
        alert("No seperator on current selection.")
      }
   
    }  else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 2) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(2);
        setC14R4VNo(2);
        setSepOnM3C14(false);
      } else {
        alert("No seperator on current selection.")
      }

        
    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 3) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
        [
        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(3);
        setC14R4VNo(3);
        setSepOnM3C14(false);

      } else {
        alert("No seperator on current selection.")
      }

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 4) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(4);
          setC14R4VNo(4);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 5) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(5);
          setC14R4VNo(5);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 6) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(6)
          setC14R4VNo(6)
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        }  

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 7) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(7);
          setC14R4VNo(7);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else   {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 8) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(8);
          setC14R4VNo(8);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 9) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(9);
          setC14R4VNo(9);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 10) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(10);
          setC14R4VNo(10);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else  {
      alert("Select Medium Module & Proceed")
    }
  } else if (curX === col14X && curY === row04X && c14R3VNo === 11) {
    if (c14r3MT === "M" && c14r4MT === "M") {
      if (sepOnM3C14 === true) {
        setRow3NakedM14(
            [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(11);
          setC14R4VNo(11);
          setSepOnM3C14(false);
        } else {
          alert("No seperator on current selection.")
        } 

    } else {
      alert("Select Medium Module & Proceed")
    }
  } 

}

// middle Sepereator No-(1)
  const addSeperator = (e)=> {

      if (curX === col01X && curY === row01X && c1R1VNo === 1) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(1);
              setC1R2VNo(1);
              setSepOnM1C1(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
            setC1R2VNo(2);
            setSepOnM1C1(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(3);
            setC1R2VNo(3);
            setSepOnM1C1(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(4);
              setC1R2VNo(4);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(5);
              setC1R2VNo(5);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(6)
              setC1R2VNo(6)
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 7) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(7);
              setC1R2VNo(7);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 8) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(8);
              setC1R2VNo(8);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 9) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(9);
              setC1R2VNo(9);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 10) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(10);
              setC1R2VNo(10);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(11);
              setC1R2VNo(11);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } 	 else if (curX === col02X && curY === row01X && c2R1VNo === 1) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(1);
              setC2R2VNo(1);
              setSepOnM1C2(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(2);
            setC2R2VNo(2);
            setSepOnM1C2(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(3);
            setC2R2VNo(3);
            setSepOnM1C2(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(4);
              setC2R2VNo(4);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
              setC2R2VNo(5);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(6)
              setC2R2VNo(6)
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 7) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(7);
              setC2R2VNo(7);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 8) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(8);
              setC2R2VNo(8);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(9);
              setC2R2VNo(9);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(10);
              setC2R2VNo(10);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(11);
              setC2R2VNo(11);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(1);
              setC3R2VNo(1);
              setSepOnM1C3(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(2);
            setC3R2VNo(2);
            setSepOnM1C3(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(3);
            setC3R2VNo(3);
            setSepOnM1C3(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(4);
              setC3R2VNo(4);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
              setC3R2VNo(5);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(6)
              setC3R2VNo(6)
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 7) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(7);
              setC3R2VNo(7);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 8) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(8);
              setC3R2VNo(8);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 9) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(9);
              setC3R2VNo(9);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(10);
              setC3R2VNo(10);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(11);
              setC3R2VNo(11);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(1);
              setC4R2VNo(1);
              setSepOnM1C4(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(2);
            setC4R2VNo(2);
            setSepOnM1C4(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(3);
            setC4R2VNo(3);
            setSepOnM1C4(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(4);
              setC4R2VNo(4);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(6)
              setC4R2VNo(6)
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 7) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(7);
              setC4R2VNo(7);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 8) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(8);
              setC4R2VNo(8);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 9) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(9);
              setC4R2VNo(9);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(10);
              setC4R2VNo(10);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(11);
              setC4R2VNo(11);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(1);
              setC5R2VNo(1);
              setSepOnM1C5(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(2);
            setC5R2VNo(2);
            setSepOnM1C5(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(3);
            setC5R2VNo(3);
            setSepOnM1C5(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(4);
              setC5R2VNo(4);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
              setC5R2VNo(5);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(6)
              setC5R2VNo(6)
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 7) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(7);
              setC5R2VNo(7);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 8) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(8);
              setC5R2VNo(8);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(9);
              setC5R2VNo(9);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(10);
              setC5R2VNo(10);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(11);
              setC5R2VNo(11);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(1);
              setC6R2VNo(1);
              setSepOnM1C6(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(2);
            setC6R2VNo(2);
            setSepOnM1C6(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(3);
            setC6R2VNo(3);
            setSepOnM1C6(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(4);
              setC6R2VNo(4);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(5);
              setC6R2VNo(5);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(6)
              setC6R2VNo(6)
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 7) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(7);
              setC6R2VNo(7);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 8) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(8);
              setC6R2VNo(8);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 9) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(9);
              setC6R2VNo(9);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(10);
              setC6R2VNo(10);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(11);
              setC6R2VNo(11);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(1);
              setC7R2VNo(1);
              setSepOnM1C7(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(2);
            setC7R2VNo(2);
            setSepOnM1C7(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(3);
            setC7R2VNo(3);
            setSepOnM1C7(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(4);
              setC7R2VNo(4);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(5);
              setC7R2VNo(5);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(6)
              setC7R2VNo(6)
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 7) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(7);
              setC7R2VNo(7);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 8) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(8);
              setC7R2VNo(8);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 9) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(9);
              setC7R2VNo(9);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(10);
              setC7R2VNo(10);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(11);
              setC7R2VNo(11);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(1);
              setC8R2VNo(1);
              setSepOnM1C8(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(2);
            setC8R2VNo(2);
            setSepOnM1C8(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(3);
            setC8R2VNo(3);
            setSepOnM1C8(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(4);
              setC8R2VNo(4);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(5);
              setC8R2VNo(5);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(6)
              setC8R2VNo(6)
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 7) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(7);
              setC8R2VNo(7);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 8) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(8);
              setC8R2VNo(8);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 9) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(9);
              setC8R2VNo(9);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(10);
              setC8R2VNo(10);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(11);
              setC8R2VNo(11);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(1);
              setC9R2VNo(1);
              setSepOnM1C9(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(2);
            setC9R2VNo(2);
            setSepOnM1C9(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(3);
            setC9R2VNo(3);
            setSepOnM1C9(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(4);
              setC9R2VNo(4);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(6)
              setC9R2VNo(6)
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 7) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(7);
              setC9R2VNo(7);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 8) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(8);
              setC9R2VNo(8);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 9) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(9);
              setC9R2VNo(9);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 10) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(10);
              setC9R2VNo(10);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(11);
              setC9R2VNo(11);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(1);
              setC10R2VNo(1);
              setSepOnM1C10(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(2);
            setC10R2VNo(2);
            setSepOnM1C10(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(3);
            setC10R2VNo(3);
            setSepOnM1C10(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(4);
              setC10R2VNo(4);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(5);
              setC10R2VNo(5);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 6) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(6)
              setC10R2VNo(6)
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 7) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(7);
              setC10R2VNo(7);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 8) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(8);
              setC10R2VNo(8);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 9) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(9);
              setC10R2VNo(9);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 10) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(10);
              setC10R2VNo(10);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(11);
              setC10R2VNo(11);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(1);
              setC11R2VNo(1);
              setSepOnM1C11(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(2);
            setC11R2VNo(2);
            setSepOnM1C11(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(3);
            setC11R2VNo(3);
            setSepOnM1C11(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(4);
              setC11R2VNo(4);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(5);
              setC11R2VNo(5);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(6)
              setC11R2VNo(6)
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 7) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(7);
              setC11R2VNo(7);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            }

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 8) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(8);
              setC11R2VNo(8);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 9) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(9);
              setC11R2VNo(9);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(10);
              setC11R2VNo(10);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(11);
              setC11R2VNo(11);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(1);
              setC12R2VNo(1);
              setSepOnM1C12(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(2);
            setC12R2VNo(2);
            setSepOnM1C12(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(3);
            setC12R2VNo(3);
            setSepOnM1C12(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(4);
              setC12R2VNo(4);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(6)
              setC12R2VNo(6)
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 7) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(7);
              setC12R2VNo(7);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 8) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(8);
              setC12R2VNo(8);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 9) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(9);
              setC12R2VNo(9);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(10);
              setC12R2VNo(10);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(11);
              setC12R2VNo(11);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(1);
              setC13R2VNo(1);
              setSepOnM1C13(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(2);
            setC13R2VNo(2);
            setSepOnM1C13(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(3);
            setC13R2VNo(3);
            setSepOnM1C13(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(4);
              setC13R2VNo(4);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(5);
              setC13R2VNo(5);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(6)
              setC13R2VNo(6)
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 7) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(7);
              setC13R2VNo(7);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 8) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(8);
              setC13R2VNo(8);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            }

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 9) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(9);
              setC13R2VNo(9);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(10);
              setC13R2VNo(10);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(11);
              setC13R2VNo(11);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(1);
              setC14R2VNo(1);
              setSepOnM1C14(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(2);
            setC14R2VNo(2);
            setSepOnM1C14(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(3);
            setC14R2VNo(3);
            setSepOnM1C14(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(4);
              setC14R2VNo(4);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(6)
              setC14R2VNo(6)
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 7) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(7);
              setC14R2VNo(7);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 8) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(8);
              setC14R2VNo(8);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 9) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(9);
              setC14R2VNo(9);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(10);
              setC14R2VNo(10);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(11);
              setC14R2VNo(11);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col01X && curY === row02X && c1R2VNo === 1) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM2C1 === false) {
            setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(1);
                setC1R2VNo(1);
                setSepOnM1C1(true);
          } else {
            alert("Already added.")
          }
       
        }  else  if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(1);
              setC1R3VNo(1);
              setSepOnM2C1(true);
          } else {
            alert("Already added.")
          }
        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
            setC1R2VNo(2);
            setSepOnM1C1(true);
          } else {
            alert("Already added.")
          }
        } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
              setRow2NakedM01(
              [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(2);
              setC1R3VNo(2);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            }
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(3);
            setC1R2VNo(3);
            setSepOnM1C1(true);

          } else {
            alert("Already added.")
          }

      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(3);
            setC1R3VNo(3);
            setSepOnM2C1(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(4);
              setC1R2VNo(4);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            }
      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(4);
              setC1R3VNo(4);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(5);
              setC1R2VNo(5);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
              setC1R3VNo(5);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(6)
              setC1R2VNo(6)
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            }
      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(6)
              setC1R3VNo(6)
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 7) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(7);
              setC1R2VNo(7);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(7);
              setC1R3VNo(7);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            }

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 8) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(8);
              setC1R2VNo(8);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(8);
              setC1R3VNo(8);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 9) {
        if (c1r1MT === "M" && c1r2MT === "M" && c1r1MT === "S") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(9);
              setC1R2VNo(9);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r2MT === "M" && c1r3MT === "M") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(9);
              setC1R3VNo(9);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(10);
              setC1R2VNo(10);
              setSepOnM1C1(true);
            } else {
              alert("Already added.")
            } 

      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(10);
              setC1R3VNo(10);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          if (sepOnM1C1 === false) {
            setBaseNakedM01(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                ]
              )
              setC1R1VNo(11);
              setC1R2VNo(11);
              setSepOnM1C1(true);
            } 
      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(11);
              setC1R3VNo(11);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } 	 else if (curX === col02X && curY === row02X && c2R2VNo === 1) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(1);
              setC2R2VNo(1);
              setSepOnM1C2(true);
          } else {
            alert("Already added.")
          }
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(1);
              setC2R3VNo(1);
              setSepOnM2C2(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(2);
            setC2R2VNo(2);
            setSepOnM1C2(true);
          } else {
            alert("Already added.")
          }
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(2);
            setC2R3VNo(2);
            setSepOnM2C2(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(3);
            setC2R2VNo(3);
            setSepOnM1C2(true);

          } else {
            alert("Already added.")
          }
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(3);
            setC2R3VNo(3);
            setSepOnM2C2(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(4);
              setC2R2VNo(4);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            }
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(4);
              setC2R3VNo(4);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
              setC2R2VNo(5);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
              setC2R3VNo(5);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(6)
              setC2R2VNo(6)
              setSepOnM1C2(true);
          } else {
              alert("Already added.")
            } 
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(6)
              setC2R3VNo(6)
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 7) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(7);
              setC2R2VNo(7);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(7);
              setC2R3VNo(7);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 8) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(8);
              setC2R2VNo(8);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(8);
              setC2R3VNo(8);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(9);
              setC2R2VNo(9);
              setSepOnM1C2(true);
           } else {
              alert("Already added.")
            } 
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(9);
              setC2R3VNo(9);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(10);
              setC2R2VNo(10);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(10);
              setC2R3VNo(10);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          if (sepOnM1C2 === false) {
            setBaseNakedM02(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(11);
              setC2R2VNo(11);
              setSepOnM1C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(11);
              setC2R3VNo(11);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(1);
              setC3R2VNo(1);
              setSepOnM1C3(true);
          } else {
            alert("Already added.")
          }
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(1);
              setC3R3VNo(1);
              setSepOnM2C3(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(2);
            setC3R2VNo(2);
            setSepOnM1C3(true);
          } else {
            alert("Already added.")
          }
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(2);
            setC3R3VNo(2);
            setSepOnM2C3(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
              ]
            )
            setC3R1VNo(3);
            setC3R2VNo(3);
            setSepOnM1C3(true);

          } else {
            alert("Already added.")
          }
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(3);
            setC3R3VNo(3);
            setSepOnM2C3(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
         if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(4);
              setC3R2VNo(4);
              setSepOnM1C3(true);
          } else {
              alert("Already added.")
            }
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(4);
              setC3R3VNo(4);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
              setC3R2VNo(5);
              setSepOnM1C3(true);
           } else {
              alert("Already added.")
            } 
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
              setC3R3VNo(5);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(6)
              setC3R2VNo(6)
              setSepOnM1C3(true);
          } else {
              alert("Already added.")
            } 
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(6)
              setC3R3VNo(6)
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 7) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(7);
              setC3R2VNo(7);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(7);
              setC3R3VNo(7);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 8) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(8);
              setC3R2VNo(8);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(8);
              setC3R3VNo(8);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 9) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(9);
              setC3R2VNo(9);
              setSepOnM1C3(true);
           } else {
              alert("Already added.")
            } 
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(9);
              setC3R3VNo(9);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(10);
              setC3R2VNo(10);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(10);
              setC3R3VNo(10);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
        if (c3r1MT === "M" && c3r2MT === "M") {
          if (sepOnM1C3 === false) {
            setBaseNakedM03(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(11);
              setC3R2VNo(11);
              setSepOnM1C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(11);
              setC3R3VNo(11);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(1);
              setC4R2VNo(1);
              setSepOnM1C4(true);
          } else {
            alert("Already added.")
          }
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(1);
              setC4R3VNo(1);
              setSepOnM2C4(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(2);
            setC4R2VNo(2);
            setSepOnM1C4(true);
          } else {
            alert("Already added.")
          }

      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(2);
            setC4R3VNo(2);
            setSepOnM2C4(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
              ]
            )
            setC4R1VNo(3);
            setC4R2VNo(3);
            setSepOnM1C4(true);

          } else {
            alert("Already added.")
          }
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(3);
            setC4R3VNo(3);
            setSepOnM2C4(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(4);
              setC4R2VNo(4);
              setSepOnM1C4(true);
          } else {
              alert("Already added.")
            } 
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(4);
              setC4R3VNo(4);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
              setSepOnM1C4(true);
           } else {
              alert("Already added.")
            } 
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(5);
              setC4R3VNo(5);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(6)
              setC4R2VNo(6)
              setSepOnM1C4(true);
          } else {
              alert("Already added.")
            } 
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(6)
              setC4R3VNo(6)
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 7) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(7);
              setC4R2VNo(7);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(7);
              setC4R3VNo(7);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 8) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(8);
              setC4R2VNo(8);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(8);
              setC4R3VNo(8);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 9) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(9);
              setC4R2VNo(9);
              setSepOnM1C4(true);
           } else {
              alert("Already added.")
            } 
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(9);
              setC4R3VNo(9);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(10);
              setC4R2VNo(10);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 

      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(10);
              setC4R3VNo(10);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
        if (c4r1MT === "M" && c4r2MT === "M") {
          if (sepOnM1C4 === false) {
            setBaseNakedM04(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(11);
              setC4R2VNo(11);
              setSepOnM1C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(11);
              setC4R3VNo(11);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(1);
              setC5R2VNo(1);
              setSepOnM1C5(true);
          } else {
            alert("Already added.")
          }
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(1);
              setC5R3VNo(1);
              setSepOnM2C5(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(2);
            setC5R2VNo(2);
            setSepOnM1C5(true);
          } else {
            alert("Already added.")
          }
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(2);
            setC5R3VNo(2);
            setSepOnM2C5(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
              ]
            )
            setC5R1VNo(3);
            setC5R2VNo(3);
            setSepOnM1C5(true);

          } else {
            alert("Already added.")
          }

      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(3);
            setC5R3VNo(3);
            setSepOnM2C5(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(4);
              setC5R2VNo(4);
              setSepOnM1C5(true);
          } else {
              alert("Already added.")
            }
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(4);
              setC5R3VNo(4);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(5);
              setC5R2VNo(5);
              setSepOnM1C5(true);
           } else {
              alert("Already added.")
            } 
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(5);
              setC5R3VNo(5);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(6)
              setC5R2VNo(6)
              setSepOnM1C5(true);
          } else {
              alert("Already added.")
            } 

      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(6)
              setC5R3VNo(6)
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 7) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(7);
              setC5R2VNo(7);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(7);
              setC5R3VNo(7);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 8) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(8);
              setC5R2VNo(8);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(8);
              setC5R3VNo(8);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(9);
              setC5R2VNo(9);
              setSepOnM1C5(true);
           } else {
              alert("Already added.")
            } 
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(9);
              setC5R3VNo(9);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(10);
              setC5R2VNo(10);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 
       } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(10);
              setC5R3VNo(10);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
        if (c5r1MT === "M" && c5r2MT === "M") {
          if (sepOnM1C5 === false) {
            setBaseNakedM05(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(11);
              setC5R2VNo(11);
              setSepOnM1C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(11);
              setC5R3VNo(11);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(1);
              setC6R2VNo(1);
              setSepOnM1C6(true);
          } else {
            alert("Already added.")
          }
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(1);
              setC6R3VNo(1);
              setSepOnM2C6(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(2);
            setC6R2VNo(2);
            setSepOnM1C6(true);
          } else {
            alert("Already added.")
          }
       } else  if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(2);
            setC6R3VNo(2);
            setSepOnM2C6(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(3);
            setC6R2VNo(3);
            setSepOnM1C6(true);

          } else {
            alert("Already added.")
          }
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(3);
            setC6R3VNo(3);
            setSepOnM2C6(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(4);
              setC6R2VNo(4);
              setSepOnM1C6(true);
          } else {
              alert("Already added.")
            }
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(4);
              setC6R3VNo(4);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(5);
              setC6R2VNo(5);
              setSepOnM1C6(true);
           } else {
              alert("Already added.")
            }
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(5);
              setC6R3VNo(5);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(6)
              setC6R2VNo(6)
              setSepOnM1C6(true);
          } else {
              alert("Already added.")
            } 
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(6)
              setC6R3VNo(6)
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 7) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(7);
              setC6R2VNo(7);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 

      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(7);
              setC6R3VNo(7);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 8) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(8);
              setC6R2VNo(8);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(8);
              setC6R3VNo(8);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 9) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(9);
              setC6R2VNo(9);
              setSepOnM1C6(true);
           } else {
              alert("Already added.")
            } 
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(9);
              setC6R3VNo(9);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(10);
              setC6R2VNo(10);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            }
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(10);
              setC6R3VNo(10);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
        if (c6r1MT === "M" && c6r2MT === "M") {
          if (sepOnM1C6 === false) {
            setBaseNakedM06(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(11);
              setC6R2VNo(11);
              setSepOnM1C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(11);
              setC6R3VNo(11);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(1);
              setC7R2VNo(1);
              setSepOnM1C7(true);
          } else {
            alert("Already added.")
          }
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(1);
              setC7R3VNo(1);
              setSepOnM2C7(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(2);
            setC7R2VNo(2);
            setSepOnM1C7(true);
          } else {
            alert("Already added.")
          }
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(2);
            setC7R3VNo(2);
            setSepOnM2C7(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(3);
            setC7R2VNo(3);
            setSepOnM1C7(true);

          } else {
            alert("Already added.")
          }
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(3);
            setC7R3VNo(3);
            setSepOnM2C7(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(4);
              setC7R2VNo(4);
              setSepOnM1C7(true);
          } else {
              alert("Already added.")
            }
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(4);
              setC7R3VNo(4);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(5);
              setC7R2VNo(5);
              setSepOnM1C7(true);
           } else {
              alert("Already added.")
            } 
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(5);
              setC7R3VNo(5);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(6)
              setC7R2VNo(6)
              setSepOnM1C7(true);
          } else {
              alert("Already added.")
            } 
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(6)
              setC7R3VNo(6)
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 7) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(7);
              setC7R2VNo(7);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 

      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(7);
              setC7R3VNo(7);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 8) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(8);
              setC7R2VNo(8);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(8);
              setC7R3VNo(8);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 9) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(9);
              setC7R2VNo(9);
              setSepOnM1C7(true);
           } else {
              alert("Already added.")
            } 
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(9);
              setC7R3VNo(9);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(10);
              setC7R2VNo(10);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(10);
              setC7R3VNo(10);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
        if (c7r1MT === "M" && c7r2MT === "M") {
          if (sepOnM1C7 === false) {
            setBaseNakedM07(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(11);
              setC7R2VNo(11);
              setSepOnM1C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(11);
              setC7R3VNo(11);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(1);
              setC8R2VNo(1);
              setSepOnM1C8(true);
          } else {
            alert("Already added.")
          }
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(1);
              setC8R3VNo(1);
              setSepOnM2C8(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(2);
            setC8R2VNo(2);
            setSepOnM1C8(true);
          } else {
            alert("Already added.")
          }
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(2);
            setC8R3VNo(2);
            setSepOnM2C8(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(3);
            setC8R2VNo(3);
            setSepOnM1C8(true);

          } else {
            alert("Already added.")
          }
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(3);
            setC8R3VNo(3);
            setSepOnM2C8(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(4);
              setC8R2VNo(4);
              setSepOnM1C8(true);
          } else {
              alert("Already added.")
            }
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(4);
              setC8R3VNo(4);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(5);
              setC8R2VNo(5);
              setSepOnM1C8(true);
           } else {
              alert("Already added.")
            } 
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC8R3VNo(5);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(6)
              setC8R2VNo(6)
              setSepOnM1C8(true);
          } else {
              alert("Already added.")
            } 
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(6)
              setC8R3VNo(6)
              setSepOnM2C8(true);
          } else {
            alert("Already added.")
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 7) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(7);
              setC8R2VNo(7);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(7);
              setC8R3VNo(7);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 8) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(8);
              setC8R2VNo(8);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(8);
              setC8R3VNo(8);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 9) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(9);
              setC8R2VNo(9);
              setSepOnM1C8(true);
           } else {
              alert("Already added.")
            } 
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(9);
              setC8R3VNo(9);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(10);
              setC8R2VNo(10);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(10);
              setC8R3VNo(10);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
        if (c8r1MT === "M" && c8r2MT === "M") {
          if (sepOnM1C8 === false) {
            setBaseNakedM08(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(11);
              setC8R2VNo(11);
              setSepOnM1C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(11);
              setC8R3VNo(11);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(1);
              setC9R2VNo(1);
              setSepOnM1C9(true);
          } else {
            alert("Already added.")
          }
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(1);
              setC9R3VNo(1);
              setSepOnM2C9(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(2);
            setC9R2VNo(2);
            setSepOnM1C9(true);
          } else {
            alert("Already added.")
          }
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(2);
            setC9R3VNo(2);
            setSepOnM2C9(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(3);
            setC9R2VNo(3);
            setSepOnM1C9(true);

          } else {
            alert("Already added.")
          }
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(3);
            setC9R3VNo(3);
            setSepOnM2C9(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(4);
              setC9R2VNo(4);
              setSepOnM1C9(true);
          } else {
              alert("Already added.")
            }

      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") { 
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(4);
              setC9R3VNo(4);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(5);
              setC9R2VNo(5);
              setSepOnM1C9(true);
           } else {
              alert("Already added.")
            } 
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(5);
              setC9R3VNo(5);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(6)
              setC9R2VNo(6)
              setSepOnM1C9(true);
          } else {
              alert("Already added.")
            } 
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(6)
              setC9R3VNo(6)
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 7) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(7);
              setC9R2VNo(7);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(7);
              setC9R3VNo(7);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 8) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(8);
              setC9R2VNo(8);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(8);
              setC9R3VNo(8);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 9) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(9);
              setC9R2VNo(9);
              setSepOnM1C9(true);
           } else {
              alert("Already added.")
            } 
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(9);
              setC9R3VNo(9);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 10) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(10);
              setC9R2VNo(10);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(10);
              setC9R3VNo(10);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
        if (c9r1MT === "M" && c9r2MT === "M") {
          if (sepOnM1C9 === false) {
            setBaseNakedM09(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(11);
              setC9R2VNo(11);
              setSepOnM1C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(11);
              setC9R3VNo(11);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(1);
              setC10R2VNo(1);
              setSepOnM1C10(true);
          } else {
            alert("Already added.")
          }
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(1);
              setC10R3VNo(1);
              setSepOnM2C10(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(2);
            setC10R2VNo(2);
            setSepOnM1C10(true);
          } else {
            alert("Already added.")
          }

      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(2);
            setC10R3VNo(2);
            setSepOnM2C10(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
              ]
            )
            setC10R1VNo(3);
            setC10R2VNo(3);
            setSepOnM1C10(true);

          } else {
            alert("Already added.")
          }
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(3);
            setC10R3VNo(3);
            setSepOnM2C10(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(4);
              setC10R2VNo(4);
              setSepOnM1C10(true);
          } else {
              alert("Already added.")
            }
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(4);
              setC10R3VNo(4);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(5);
              setC10R2VNo(5);
              setSepOnM1C10(true);
           } else {
              alert("Already added.")
            } 
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(5);
              setC10R3VNo(5);
              setSepOnM2C10(true);
           } else {
            alert("Already added.")
          } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 6) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(6)
              setC10R2VNo(6)
              setSepOnM1C10(true);
          } else {
              alert("Already added.")
            } 
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(6)
              setC10R3VNo(6)
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 7) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(7);
              setC10R2VNo(7);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 
       } else  if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(7);
              setC10R3VNo(7);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 8) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(8);
              setC10R2VNo(8);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(8);
              setC10R3VNo(8);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 9) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(9);
              setC10R2VNo(9);
              setSepOnM1C10(true);
           } else {
              alert("Already added.")
            } 

      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(9);
              setC10R3VNo(9);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 10) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(10);
              setC10R2VNo(10);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(10);
              setC10R3VNo(10);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
        if (c10r1MT === "M" && c10r2MT === "M") {
          if (sepOnM1C10 === false) {
            setBaseNakedM10(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(11);
              setC10R2VNo(11);
              setSepOnM1C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(11);
              setC10R3VNo(11);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(1);
              setC11R2VNo(1);
              setSepOnM1C11(true);
          } else {
            alert("Already added.")
          }
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(1);
              setC11R3VNo(1);
              setSepOnM2C11(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(2);
            setC11R2VNo(2);
            setSepOnM1C11(true);
          } else {
            alert("Already added.")
          }
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(2);
            setC11R3VNo(2);
            setSepOnM2C11(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
              ]
            )
            setC11R1VNo(3);
            setC11R2VNo(3);
            setSepOnM1C11(true);

          } else {
            alert("Already added.")
          }
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(3);
            setC11R3VNo(3);
            setSepOnM2C11(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(4);
              setC11R2VNo(4);
              setSepOnM1C11(true);
          } else {
              alert("Already added.")
            }
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(4);
              setC11R3VNo(4);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(5);
              setC11R2VNo(5);
              setSepOnM1C11(true);
           } else {
              alert("Already added.")
            } 
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(5);
              setC11R3VNo(5);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(6)
              setC11R2VNo(6)
              setSepOnM1C11(true);
          } else {
              alert("Already added.")
            } 
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(6)
              setC11R3VNo(6)
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 7) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(7);
              setC11R2VNo(7);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(7);
              setC11R3VNo(7);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            }

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 8) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(8);
              setC11R2VNo(8);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(8);
              setC11R3VNo(8);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 9) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(9);
              setC11R2VNo(9);
              setSepOnM1C11(true);
           } else {
              alert("Already added.")
            } 
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(9);
              setC11R3VNo(9);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(10);
              setC11R2VNo(10);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(10);
              setC11R3VNo(10);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
        if (c11r1MT === "M" && c11r2MT === "M") {
          if (sepOnM1C11 === false) {
            setBaseNakedM11(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(11);
              setC11R2VNo(11);
              setSepOnM1C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(11);
              setC11R3VNo(11);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(1);
              setC12R2VNo(1);
              setSepOnM1C12(true);
          } else {
            alert("Already added.")
          }
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(1);
              setC12R3VNo(1);
              setSepOnM2C12(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(2);
            setC12R2VNo(2);
            setSepOnM1C12(true);
          } else {
            alert("Already added.")
          }
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(2);
            setC12R3VNo(2);
            setSepOnM2C12(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
              ]
            )
            setC12R1VNo(3);
            setC12R2VNo(3);
            setSepOnM1C12(true);

          } else {
            alert("Already added.")
          }
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(3);
            setC12R3VNo(3);
            setSepOnM2C12(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(4);
              setC12R2VNo(4);
              setSepOnM1C12(true);
          } else {
              alert("Already added.")
            }
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(4);
              setC12R3VNo(4);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(5);
              setC12R2VNo(5);
              setSepOnM1C12(true);
           } else {
              alert("Already added.")
            } 
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(5);
              setC12R3VNo(5);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(6)
              setC12R2VNo(6)
              setSepOnM1C12(true);
          } else {
              alert("Already added.")
            } 
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(6)
              setC12R3VNo(6)
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 7) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(7);
              setC12R2VNo(7);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(7);
              setC12R3VNo(7);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 8) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(8);
              setC12R2VNo(8);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(8);
              setC12R3VNo(8);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 9) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(9);
              setC12R2VNo(9);
              setSepOnM1C12(true);
           } else {
              alert("Already added.")
            } 
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(9);
              setC12R3VNo(9);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(10);
              setC12R2VNo(10);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(10);
              setC12R3VNo(10);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
        if (c12r1MT === "M" && c12r2MT === "M") {
          if (sepOnM1C12 === false) {
            setBaseNakedM12(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(11);
              setC12R2VNo(11);
              setSepOnM1C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(11);
              setC12R3VNo(11);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(1);
              setC13R2VNo(1);
              setSepOnM1C13(true);
          } else {
            alert("Already added.")
          }
        } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(1);
              setC13R3VNo(1);
              setSepOnM2C13(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(2);
            setC13R2VNo(2);
            setSepOnM1C13(true);
          } else {
            alert("Already added.")
          }
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(2);
            setC13R3VNo(2);
            setSepOnM2C13(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(3);
            setC13R2VNo(3);
            setSepOnM1C13(true);

          } else {
            alert("Already added.")
          }
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(3);
            setC13R3VNo(3);
            setSepOnM2C13(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(4);
              setC13R2VNo(4);
              setSepOnM1C13(true);
          } else {
              alert("Already added.")
            }
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(4);
              setC13R3VNo(4);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(5);
              setC13R2VNo(5);
              setSepOnM1C13(true);
           } else {
              alert("Already added.")
            } 
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(5);
              setC13R3VNo(5);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(6)
              setC13R2VNo(6)
              setSepOnM1C13(true);
          } else {
              alert("Already added.")
            } 
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(6)
              setC13R3VNo(6)
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 7) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(7);
              setC13R2VNo(7);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(7);
              setC13R3VNo(7);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 8) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(8);
              setC13R2VNo(8);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(8);
              setC13R3VNo(8);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 9) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(9);
              setC13R2VNo(9);
              setSepOnM1C13(true);
           } else {
              alert("Already added.")
            } 
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) { 
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(9);
              setC13R3VNo(9);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(10);
              setC13R2VNo(10);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(10);
              setC13R3VNo(10);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
        if (c13r1MT === "M" && c13r2MT === "M") {
          if (sepOnM1C13 === false) {
            setBaseNakedM13(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                ]
              )
              setC13R1VNo(11);
              setC13R2VNo(11);
              setSepOnM1C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(11);
              setC13R3VNo(11);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(1);
              setC14R2VNo(1);
              setSepOnM1C14(true);
          } else {
            alert("Already added.")
          }
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(1);
              setC14R3VNo(1);
              setSepOnM2C14(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(2);
            setC14R2VNo(2);
            setSepOnM1C14(true);
          } else {
            alert("Already added.")
          }
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(2);
            setC14R3VNo(2);
            setSepOnM2C14(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
            [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
              ]
            )
            setC14R1VNo(3);
            setC14R2VNo(3);
            setSepOnM1C14(true);

          } else {
            alert("Already added.")
          }
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(3);
            setC14R3VNo(3);
            setSepOnM2C14(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(4);
              setC14R2VNo(4);
              setSepOnM1C14(true);
          } else {
              alert("Already added.")
            }
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(4);
              setC14R3VNo(4);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(5);
              setC14R2VNo(5);
              setSepOnM1C14(true);
           } else {
              alert("Already added.")
            } 
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(5);
              setC14R3VNo(5);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(6)
              setC14R2VNo(6)
              setSepOnM1C14(true);
          } else {
              alert("Already added.")
            } 
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(6)
              setC14R3VNo(6)
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 7) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(7);
              setC14R2VNo(7);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(7);
              setC14R3VNo(7);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 8) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(8);
              setC14R2VNo(8);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(8);
              setC14R3VNo(8);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 9) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(9);
              setC14R2VNo(9);
              setSepOnM1C14(true);
           } else {
              alert("Already added.")
            } 
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(9);
              setC14R3VNo(9);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(10);
              setC14R2VNo(10);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(10);
              setC14R3VNo(10);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
        if (c14r1MT === "M" && c14r2MT === "M") {
          if (sepOnM1C14 === false) {
            setBaseNakedM14(
                [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(11);
              setC14R2VNo(11);
              setSepOnM1C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(11);
              setC14R3VNo(11);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(1);
              setC1R3VNo(1);
              setSepOnM2C1(true);
          } else {
            alert("Already added.")
          }
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(1);
              setC1R4VNo(1);
              setSepOnM3C1(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
                  setRow2NakedM01(
                  [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                    ]
                  )
                  setC1R2VNo(2);
                  setC1R3VNo(2);
                  setSepOnM2C1(true);
                } else {
                  alert("Already added.")
                }
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(2);
            setC1R4VNo(2);
            setSepOnM3C1(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(3);
            setC1R3VNo(3);
            setSepOnM2C1(true);

          } else {
            alert("Already added.")
          }
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(3);
            setC1R4VNo(3);
            setSepOnM3C1(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(4);
              setC1R3VNo(4);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            }
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(4);
              setC1R4VNo(4);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(5);
              setC1R3VNo(5);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
              setC1R4VNo(5);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(6)
              setC1R3VNo(6)
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(6)
              setC1R4VNo(6)
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 7) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(7);
              setC1R3VNo(7);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            }
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(7);
              setC1R4VNo(7);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 8) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S")  {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(8);
              setC1R3VNo(8);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(8);
              setC1R4VNo(8);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 9) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(9);
              setC1R3VNo(9);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(9);
              setC1R4VNo(9);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(10);
              setC1R3VNo(10);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            } 
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(10);
              setC1R4VNo(10);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
        if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
          if (sepOnM2C1 === false) {
            setRow2NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                ]
              )
              setC1R2VNo(11);
              setC1R3VNo(11);
              setSepOnM2C1(true);
            } else {
              alert("Already added.")
            }
      } else if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(11);
              setC1R4VNo(11);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 1) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(1);
              setC2R3VNo(1);
              setSepOnM2C2(true);
          } else {
            alert("Already added.")
          }
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(1);
              setC2R4VNo(1);
              setSepOnM3C2(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(2);
            setC2R3VNo(2);
            setSepOnM2C2(true);
          } else {
            alert("Already added.")
          }
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(2);
            setC2R4VNo(2);
            setSepOnM3C2(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
            setC2R2VNo(3);
            setC2R3VNo(3);
            setSepOnM2C2(true);

          } else {
            alert("Already added.")
          }
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(3);
            setC2R4VNo(3);
            setSepOnM3C2(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(4);
              setC2R3VNo(4);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            }
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(4);
              setC2R4VNo(4);
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(5);
              setC2R3VNo(5);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(5);
              setC2R4VNo(5);
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(6)
              setC2R3VNo(6)
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(6)
              setC2R4VNo(6)
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 7) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(7);
              setC2R3VNo(7);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(7);
              setC2R4VNo(7);
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 8) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(8);
              setC2R3VNo(8);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(8);
              setC2R4VNo(8);
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(9);
              setC2R3VNo(9);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(9);
              setC2R4VNo(9);
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(10);
              setC2R3VNo(10);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(10);
              setC2R4VNo(10);
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
        if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
          if (sepOnM2C2 === false) {
            setRow2NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
              setC2R2VNo(10);
              setC2R3VNo(10);
              setSepOnM2C2(true);
            } else {
              alert("Already added.")
            } 
      } else if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(11);
              setC2R4VNo(11);
              setSepOnM3C2(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(1);
              setC3R3VNo(1);
              setSepOnM2C3(true);
          } else {
            alert("Already added.")
          }
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(1);
              setC3R4VNo(1);
              setSepOnM3C3(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(2);
            setC3R3VNo(2);
            setSepOnM2C3(true);
          } else {
            alert("Already added.")
          }

      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(2);
            setC3R4VNo(2);
            setSepOnM3C3(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
              ]
            )
            setC3R2VNo(3);
            setC3R3VNo(3);
            setSepOnM2C3(true);

          } else {
            alert("Already added.")
          }
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(3);
            setC3R4VNo(3);
            setSepOnM3C3(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(4);
              setC3R3VNo(4);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            }
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(4);
              setC3R4VNo(4);
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(5);
              setC3R3VNo(5);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(5);
              setC3R4VNo(5);
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(6)
              setC3R3VNo(6)
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(6)
              setC3R4VNo(6)
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 7) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(7);
              setC3R3VNo(7);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(7);
              setC3R4VNo(7);
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 8) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(8);
              setC3R3VNo(8);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(8);
              setC3R4VNo(8);
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 9) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(9);
              setC3R3VNo(9);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(9);
              setC3R4VNo(9);
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(10);
              setC3R3VNo(10);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(10);
              setC3R4VNo(10);
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
        if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
          if (sepOnM2C3 === false) {
            setRow2NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(11);
              setC3R3VNo(11);
              setSepOnM2C3(true);
            } else {
              alert("Already added.")
            } 
      } else if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(11);
              setC3R4VNo(11);
              setSepOnM3C3(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(1);
              setC4R3VNo(1);
              setSepOnM2C4(true);
          } else {
            alert("Already added.")
          }
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(1);
              setC4R4VNo(1);
              setSepOnM3C4(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(2);
            setC4R3VNo(2);
            setSepOnM2C4(true);
          } else {
            alert("Already added.")
          }
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(2);
            setC4R4VNo(2);
            setSepOnM3C4(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
              ]
            )
            setC4R2VNo(3);
            setC4R3VNo(3);
            setSepOnM2C4(true);

          } else {
            alert("Already added.")
          }

      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(3);
            setC4R4VNo(3);
            setSepOnM3C4(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(4);
              setC4R3VNo(4);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            }
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(4);
              setC4R4VNo(4);
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(5);
              setC4R3VNo(5);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(5);
              setC4R4VNo(5);
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(6)
              setC4R3VNo(6)
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(6)
              setC4R4VNo(6)
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 7) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(7);
              setC4R3VNo(7);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(7);
              setC4R4VNo(7);
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 8) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(8);
              setC4R3VNo(8);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 

      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(8);
              setC4R4VNo(8);
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 9) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(9);
              setC4R3VNo(9);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(9);
              setC4R4VNo(9);
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(10);
              setC4R3VNo(10);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(10);
              setC4R4VNo(10);
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
        if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
          if (sepOnM2C4 === false) {
            setRow2NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                ]
              )
              setC4R2VNo(11);
              setC4R3VNo(11);
              setSepOnM2C4(true);
            } else {
              alert("Already added.")
            } 
      } else if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(11);
              setC4R4VNo(11);
              setSepOnM3C4(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(1);
              setC5R3VNo(1);
              setSepOnM2C5(true);
          } else {
            alert("Already added.")
          }
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(1);
              setC5R4VNo(1);
              setSepOnM3C5(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(2);
            setC5R3VNo(2);
            setSepOnM2C5(true);
          } else {
            alert("Already added.")
          }
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(2);
            setC5R4VNo(2);
            setSepOnM3C5(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
              ]
            )
            setC5R2VNo(3);
            setC5R3VNo(3);
            setSepOnM2C5(true);

          } else {
            alert("Already added.")
          }
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(3);
            setC5R4VNo(3);
            setSepOnM3C5(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(4);
              setC5R3VNo(4);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            }
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(4);
              setC5R4VNo(4);
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(5);
              setC5R3VNo(5);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(5);
              setC5R4VNo(5);
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(6)
              setC5R3VNo(6)
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(6)
              setC5R4VNo(6)
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 7) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(7);
              setC5R3VNo(7);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(7);
              setC5R4VNo(7);
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 8) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(8);
              setC5R3VNo(8);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(8);
              setC5R4VNo(8);
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(9);
              setC5R3VNo(9);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(9);
              setC5R4VNo(9);
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(10);
              setC5R3VNo(10);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(10);
              setC5R4VNo(10);
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
        if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
          if (sepOnM2C5 === false) {
            setRow2NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(11);
              setC5R3VNo(11);
              setSepOnM2C5(true);
            } else {
              alert("Already added.")
            } 
      } else if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM05(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(11);
              setC5R4VNo(11);
              setSepOnM3C5(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(1);
              setC6R3VNo(1);
              setSepOnM2C6(true);
          } else {
            alert("Already added.")
          }
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(1);
              setC6R4VNo(1);
              setSepOnM3C6(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(2);
            setC6R3VNo(2);
            setSepOnM2C6(true);
          } else {
            alert("Already added.")
          }
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(2);
            setC6R4VNo(2);
            setSepOnM3C6(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(3);
            setC6R3VNo(3);
            setSepOnM2C6(true);

          } else {
            alert("Already added.")
          }
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(3);
            setC6R4VNo(3);
            setSepOnM3C6(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(4);
              setC6R3VNo(4);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            }
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(4);
              setC6R4VNo(4);
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(5);
              setC6R3VNo(5);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(5);
              setC6R4VNo(5);
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(6)
              setC6R3VNo(6)
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(6)
              setC6R4VNo(6)
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 7) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(7);
              setC6R3VNo(7);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(7);
              setC6R4VNo(7);
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 8) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(8);
              setC6R3VNo(8);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(8);
              setC6R4VNo(8);
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 9) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(9);
              setC6R3VNo(9);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(9);
              setC6R4VNo(9);
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(10);
              setC6R3VNo(10);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(10);
              setC6R4VNo(10);
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
        if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
          if (sepOnM2C6 === false) {
            setRow2NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                ]
              )
              setC6R2VNo(11);
              setC6R3VNo(11);
              setSepOnM2C6(true);
            } else {
              alert("Already added.")
            } 
      } else if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(11);
              setC6R4VNo(11);
              setSepOnM3C6(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(1);
              setC7R3VNo(1);
              setSepOnM2C7(true);
          } else {
            alert("Already added.")
          }
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(1);
              setC7R4VNo(1);
              setSepOnM3C7(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(2);
            setC7R3VNo(2);
            setSepOnM2C7(true);
          } else {
            alert("Already added.")
          }
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(2);
            setC7R4VNo(2);
            setSepOnM3C7(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
            setC7R2VNo(3);
            setC7R3VNo(3);
            setSepOnM2C7(true);

          } else {
            alert("Already added.")
          }
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(3);
            setC7R4VNo(3);
            setSepOnM3C7(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(4);
              setC7R3VNo(4);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            }
       } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(4);
              setC7R4VNo(4);
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(5);
              setC7R3VNo(5);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(5);
              setC7R4VNo(5);
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(6)
              setC7R3VNo(6)
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 

      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(6)
              setC7R4VNo(6)
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 7) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(7);
              setC7R3VNo(7);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(7);
              setC7R4VNo(7);
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 8) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(8);
              setC7R3VNo(8);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(8);
              setC7R4VNo(8);
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 9) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(9);
              setC7R3VNo(9);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(9);
              setC7R4VNo(9);
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(10);
              setC7R3VNo(10);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(10);
              setC7R4VNo(10);
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
        if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
          if (sepOnM2C7 === false) {
            setRow2NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(11);
              setC7R3VNo(11);
              setSepOnM2C7(true);
            } else {
              alert("Already added.")
            } 
      } else if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(11);
              setC7R4VNo(11);
              setSepOnM3C7(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(1);
              setC8R3VNo(1);
              setSepOnM2C8(true);
          } else {
            alert("Already added.")
          }
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(1);
              setC8R4VNo(1);
              setSepOnM3C8(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(2);
            setC8R3VNo(2);
            setSepOnM2C8(true);
          } else {
            alert("Already added.")
          }
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(2);
            setC8R4VNo(2);
            setSepOnM3C8(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
              ]
            )
            setC8R2VNo(3);
            setC8R3VNo(3);
            setSepOnM2C8(true);

          } else {
            alert("Already added.")
          }
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(3);
            setC8R4VNo(3);
            setSepOnM3C8(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(4);
              setC8R3VNo(4);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            }
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(4);
              setC8R4VNo(4);
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(5);
              setC8R3VNo(5);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(5);
              setC8R4VNo(5);
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(6)
              setC8R3VNo(6)
              setSepOnM2C8(true);
          } else {
            alert("Already added.")
          } 
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(6)
              setC8R4VNo(6)
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 7) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(7);
              setC8R3VNo(7);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(7);
              setC8R4VNo(7);
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 8) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(8);
              setC8R3VNo(8);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(8);
              setC8R4VNo(8);
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 9) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(9);
              setC8R3VNo(9);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(9);
              setC8R4VNo(9);
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(10);
              setC8R3VNo(10);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(10);
              setC8R4VNo(10);
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
        if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
          if (sepOnM2C8 === false) {
            setRow2NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(11);
              setC8R3VNo(11);
              setSepOnM2C8(true);
            } else {
              alert("Already added.")
            } 
      } else if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(11);
              setC8R4VNo(11);
              setSepOnM3C8(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(1);
              setC9R3VNo(1);
              setSepOnM2C9(true);
          } else {
            alert("Already added.")
          }
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(1);
              setC9R4VNo(1);
              setSepOnM3C9(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(2);
            setC9R3VNo(2);
            setSepOnM2C9(true);
          } else {
            alert("Already added.")
          }
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(2);
            setC9R4VNo(2);
            setSepOnM3C9(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(3);
            setC9R3VNo(3);
            setSepOnM2C9(true);

          } else {
            alert("Already added.")
          }
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(3);
            setC9R4VNo(3);
            setSepOnM3C9(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(4);
              setC9R3VNo(4);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            }
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(4);
              setC9R4VNo(4);
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(5);
              setC9R3VNo(5);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(5);
              setC9R4VNo(5);
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(6)
              setC9R3VNo(6)
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(6)
              setC9R4VNo(6)
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 7) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(7);
              setC9R3VNo(7);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(7);
              setC9R4VNo(7);
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 8) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(8);
              setC9R3VNo(8);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(8);
              setC9R4VNo(8);
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 9) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(9);
              setC9R3VNo(9);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(9);
              setC9R4VNo(9);
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 10) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(10);
              setC9R3VNo(10);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(10);
              setC9R4VNo(10);
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
        if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
          if (sepOnM2C9 === false) {
            setRow2NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                ]
              )
              setC9R2VNo(11);
              setC9R3VNo(11);
              setSepOnM2C9(true);
            } else {
              alert("Already added.")
            } 
      } else if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(11);
              setC9R4VNo(11);
              setSepOnM3C9(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col10X && curY === row03X && c10R3VNo === 1) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(1);
              setC10R3VNo(1);
              setSepOnM2C10(true);
          } else {
            alert("Already added.")
          }
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(1);
              setC10R4VNo(1);
              setSepOnM3C10(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(2);
            setC10R3VNo(2);
            setSepOnM2C10(true);
          } else {
            alert("Already added.")
          }
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(2);
            setC10R4VNo(2);
            setSepOnM3C10(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
              ]
            )
            setC10R2VNo(3);
            setC10R3VNo(3);
            setSepOnM2C10(true);

          } else {
            alert("Already added.")
          }
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(3);
            setC10R4VNo(3);
            setSepOnM3C10(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(4);
              setC10R3VNo(4);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            }
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(4);
              setC10R4VNo(4);
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(5);
              setC10R3VNo(5);
              setSepOnM2C10(true);
           } else {
            alert("Already added.")
          } 
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(5);
              setC10R4VNo(5);
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 6) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(6)
              setC10R3VNo(6)
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(6)
              setC10R4VNo(6)
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 7) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(7);
              setC10R3VNo(7);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(7);
              setC10R4VNo(7);
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 8) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(8);
              setC10R3VNo(8);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(8);
              setC10R4VNo(8);
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 9) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(9);
              setC10R3VNo(9);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(9);
              setC10R4VNo(9);
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 10) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) {
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(10);
              setC10R3VNo(10);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(10);
              setC10R4VNo(10);
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {
        if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
          if (sepOnM2C10 === false) { 
            setRow2NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                ]
              )
              setC10R2VNo(11);
              setC10R3VNo(11);
              setSepOnM2C10(true);
            } else {
              alert("Already added.")
            } 
      } else if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(11);
              setC10R4VNo(11);
              setSepOnM3C10(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(1);
              setC11R3VNo(1);
              setSepOnM2C11(true);
          } else {
            alert("Already added.")
          }
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(1);
              setC11R4VNo(1);
              setSepOnM3C11(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(2);
            setC11R3VNo(2);
            setSepOnM2C11(true);
          } else {
            alert("Already added.")
          }
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(2);
            setC11R4VNo(2);
            setSepOnM3C11(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(3);
            setC11R3VNo(3);
            setSepOnM2C11(true);

          } else {
            alert("Already added.")
          }
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(3);
            setC11R4VNo(3);
            setSepOnM3C11(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(4);
              setC11R3VNo(4);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            }
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(4);
              setC11R4VNo(4);
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(5);
              setC11R3VNo(5);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(5);
              setC11R4VNo(5);
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(6)
              setC11R3VNo(6)
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(6)
              setC11R4VNo(6)
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 7) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(7);
              setC11R3VNo(7);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            }
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(7);
              setC11R4VNo(7);
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 8) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(8);
              setC11R3VNo(8);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(8);
              setC11R4VNo(8);
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 9) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(9);
              setC11R3VNo(9);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(9);
              setC11R4VNo(9);
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(10);
              setC11R3VNo(10);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(10);
              setC11R4VNo(10);
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
        if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
          if (sepOnM2C11 === false) {
            setRow2NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                ]
              )
              setC11R2VNo(11);
              setC11R3VNo(11);
              setSepOnM2C11(true);
            } else {
              alert("Already added.")
            } 
      } else if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(11);
              setC11R4VNo(11);
              setSepOnM3C11(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(1);
              setC12R3VNo(1);
              setSepOnM2C12(true);
          } else {
            alert("Already added.")
          }
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(1);
              setC12R4VNo(1);
              setSepOnM3C12(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(2);
            setC12R3VNo(2);
            setSepOnM2C12(true);
          } else {
            alert("Already added.")
          }
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(2);
            setC12R4VNo(2);
            setSepOnM3C12(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(3);
            setC12R3VNo(3);
            setSepOnM2C12(true);

          } else {
            alert("Already added.")
          }
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(3);
            setC12R4VNo(3);
            setSepOnM3C12(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(4);
              setC12R3VNo(4);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            }
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(4);
              setC12R4VNo(4);
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(5);
              setC12R3VNo(5);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(5);
              setC12R4VNo(5);
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(6)
              setC12R3VNo(6)
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(6)
              setC12R4VNo(6)
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 7) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(7);
              setC12R3VNo(7);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(7);
              setC12R4VNo(7);
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 8) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(8);
              setC12R3VNo(8);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(8);
              setC12R4VNo(8);
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 9) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(9);
              setC12R3VNo(9);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(9);
              setC12R4VNo(9);
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(10);
              setC12R3VNo(10);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(10);
              setC12R4VNo(10);
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
        if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
          if (sepOnM2C12 === false) {
            setRow2NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(11);
              setC12R3VNo(11);
              setSepOnM2C12(true);
            } else {
              alert("Already added.")
            } 
      } else if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(11);
              setC12R4VNo(11);
              setSepOnM3C12(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(1);
              setC13R3VNo(1);
              setSepOnM2C13(true);
          } else {
            alert("Already added.")
          }
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(1);
              setC13R4VNo(1);
              setSepOnM3C13(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(2);
            setC13R3VNo(2);
            setSepOnM2C13(true);
          } else {
            alert("Already added.")
          }
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(2);
            setC13R4VNo(2);
            setSepOnM3C13(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
              ]
            )
            setC13R2VNo(3);
            setC13R3VNo(3);
            setSepOnM2C13(true);

          } else {
            alert("Already added.")
          }
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(3);
            setC13R4VNo(3);
            setSepOnM3C13(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(4);
              setC13R3VNo(4);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            }
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(4);
              setC13R4VNo(4);
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(5);
              setC13R3VNo(5);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(5);
              setC13R4VNo(5);
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(6)
              setC13R3VNo(6)
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(6)
              setC13R4VNo(6)
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 7) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(7);
              setC13R3VNo(7);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(7);
              setC13R4VNo(7);
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 8) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(8);
              setC13R3VNo(8);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(8);
              setC13R4VNo(8);
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 9) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(9);
              setC13R3VNo(9);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(9);
              setC13R4VNo(9);
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(10);
              setC13R3VNo(10);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(10);
              setC13R4VNo(10);
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
        if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
          if (sepOnM2C13 === false) {
            setRow2NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                ]
              )
              setC13R2VNo(11);
              setC13R3VNo(11);
              setSepOnM2C13(true);
            } else {
              alert("Already added.")
            } 
      } else if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(11);
              setC13R4VNo(11);
              setSepOnM3C13(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(1);
              setC14R3VNo(1);
              setSepOnM2C14(true);
          } else {
            alert("Already added.")
          }
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(1);
              setC14R4VNo(1);
              setSepOnM3C14(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(2);
            setC14R3VNo(2);
            setSepOnM2C14(true);
          } else {
            alert("Already added.")
          }
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(2);
            setC14R4VNo(2);
            setSepOnM3C14(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
              ]
            )
            setC14R2VNo(3);
            setC14R3VNo(3);
            setSepOnM2C14(true);

          } else {
            alert("Already added.")
          }
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(3);
            setC14R4VNo(3);
            setSepOnM3C14(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(4);
              setC14R3VNo(4);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            }
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(4);
              setC14R4VNo(4);
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(5);
              setC14R3VNo(5);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(5);
              setC14R4VNo(5);
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(6)
              setC14R3VNo(6)
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(6)
              setC14R4VNo(6)
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 7) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(7);
              setC14R3VNo(7);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(7);
              setC14R4VNo(7);
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 8) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(8);
              setC14R3VNo(8);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(8);
              setC14R4VNo(8);
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 9) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(9);
              setC14R3VNo(9);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(9);
              setC14R4VNo(9);
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(10);
              setC14R3VNo(10);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(10);
              setC14R4VNo(10);
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
        if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
          if (sepOnM2C14 === false) {
            setRow2NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(11);
              setC14R3VNo(11);
              setSepOnM2C14(true);
            } else {
              alert("Already added.")
            } 
      } else if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(11);
              setC14R4VNo(11);
              setSepOnM3C14(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(1);
              setC1R4VNo(1);
              setSepOnM3C1(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(2);
            setC1R4VNo(2);
            setSepOnM3C1(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
            setC1R3VNo(3);
            setC1R4VNo(3);
            setSepOnM3C1(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(4);
              setC1R4VNo(4);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(5);
              setC1R4VNo(5);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(6)
              setC1R4VNo(6)
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 7) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(7);
              setC1R4VNo(7);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 8) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(8);
              setC1R4VNo(8);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 9) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(9);
              setC1R4VNo(9);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 10) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(10);
              setC1R4VNo(10);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
        if (c1r3MT === "M" && c1r4MT === "M") {
          if (sepOnM3C1 === false) {
            setRow3NakedM01(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(11);
              setC1R4VNo(11);
              setSepOnM3C1(true);
            } else {
              alert("Already added.")
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } 	 else if (curX === col02X && curY === row04X && c2R3VNo === 1) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(1);
              setC2R4VNo(1);
              setSepOnM3C2(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 2) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(2);
            setC2R4VNo(2);
            setSepOnM3C2(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 3) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(3);
            setC2R4VNo(3);
            setSepOnM3C2(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 4) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(4);
              setC2R4VNo(4);
              setSepOnM3C2(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 5) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(5);
              setC2R4VNo(5);
              setSepOnM3C2(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 6) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(6)
              setC2R4VNo(6)
              setSepOnM3C2(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 7) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(7);
              setC2R4VNo(7);
              setSepOnM3C2(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 8) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(8);
              setC2R4VNo(8);
              setSepOnM3C2(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 9) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(9);
              setC2R4VNo(9);
              setSepOnM3C2(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 10) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(10);
              setC2R4VNo(10);
              setSepOnM3C2(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col02X && curY === row04X && c2R3VNo === 11) {
        if (c2r3MT === "M" && c2r4MT === "M") {
          if (sepOnM3C2 === false) {
            setRow3NakedM02(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
              setC2R3VNo(11);
              setC2R4VNo(11);
              setSepOnM3C2(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col03X && curY === row04X && c3R3VNo === 1) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(1);
              setC3R4VNo(1);
              setSepOnM3C3(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 2) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(2);
            setC3R4VNo(2);
            setSepOnM3C3(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 3) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
              ]
            )
            setC3R3VNo(3);
            setC3R4VNo(3);
            setSepOnM3C3(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 4) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(4);
              setC3R4VNo(4);
              setSepOnM3C3(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 5) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(5);
              setC3R4VNo(5);
              setSepOnM3C3(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 6) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(6)
              setC3R4VNo(6)
              setSepOnM3C3(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 7) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(7);
              setC3R4VNo(7);
              setSepOnM3C3(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 8) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(8);
              setC3R4VNo(8);
              setSepOnM3C3(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 9) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(9);
              setC3R4VNo(9);
              setSepOnM3C3(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 10) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(10);
              setC3R4VNo(10);
              setSepOnM3C3(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col03X && curY === row04X && c3R3VNo === 11) {
        if (c3r3MT === "M" && c3r4MT === "M") {
          if (sepOnM3C3 === false) {
            setRow3NakedM03(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(11);
              setC3R4VNo(11);
              setSepOnM3C3(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col04X && curY === row04X && c4R3VNo === 1) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(1);
              setC4R4VNo(1);
              setSepOnM3C4(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 2) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(2);
            setC4R4VNo(2);
            setSepOnM3C4(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 3) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
              ]
            )
            setC4R3VNo(3);
            setC4R4VNo(3);
            setSepOnM3C4(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 4) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(4);
              setC4R4VNo(4);
              setSepOnM3C4(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 5) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(5);
              setC4R4VNo(5);
              setSepOnM3C4(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 6) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(6)
              setC4R4VNo(6)
              setSepOnM3C4(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 7) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(7);
              setC4R4VNo(7);
              setSepOnM3C4(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 8) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(8);
              setC4R4VNo(8);
              setSepOnM3C4(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 9) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(9);
              setC4R4VNo(9);
              setSepOnM3C4(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 10) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(10);
              setC4R4VNo(10);
              setSepOnM3C4(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col04X && curY === row04X && c4R3VNo === 11) {
        if (c4r3MT === "M" && c4r4MT === "M") {
          if (sepOnM3C4 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                ]
              )
              setC4R3VNo(11);
              setC4R4VNo(11);
              setSepOnM3C4(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col05X && curY === row04X && c5R3VNo === 1) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(1);
              setC5R4VNo(1);
              setSepOnM3C5(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 2) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(2);
            setC5R4VNo(2);
            setSepOnM3C5(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 3) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
              ]
            )
            setC5R3VNo(3);
            setC5R4VNo(3);
            setSepOnM3C5(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 4) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(4);
              setC5R4VNo(4);
              setSepOnM3C5(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 5) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(5);
              setC5R4VNo(5);
              setSepOnM3C5(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 6) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(6)
              setC5R4VNo(6)
              setSepOnM3C5(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 7) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(7);
              setC5R4VNo(7);
              setSepOnM3C5(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 8) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(8);
              setC5R4VNo(8);
              setSepOnM3C5(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 9) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(9);
              setC5R4VNo(9);
              setSepOnM3C5(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 10) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(10);
              setC5R4VNo(10);
              setSepOnM3C5(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col05X && curY === row04X && c5R3VNo === 11) {
        if (c5r3MT === "M" && c5r4MT === "M") {
          if (sepOnM3C5 === false) {
            setRow3NakedM04(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                ]
              )
              setC5R3VNo(11);
              setC5R4VNo(11);
              setSepOnM3C5(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col06X && curY === row04X && c6R3VNo === 1) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(1);
              setC6R4VNo(1);
              setSepOnM3C6(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 2) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(2);
            setC6R4VNo(2);
            setSepOnM3C6(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 3) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
              ]
            )
            setC6R3VNo(3);
            setC6R4VNo(3);
            setSepOnM3C6(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 4) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(4);
              setC6R4VNo(4);
              setSepOnM3C6(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 5) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(5);
              setC6R4VNo(5);
              setSepOnM3C6(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 6) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(6)
              setC6R4VNo(6)
              setSepOnM3C6(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 7) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(7);
              setC6R4VNo(7);
              setSepOnM3C6(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 8) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(8);
              setC6R4VNo(8);
              setSepOnM3C6(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 9) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(9);
              setC6R4VNo(9);
              setSepOnM3C6(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 10) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(10);
              setC6R4VNo(10);
              setSepOnM3C6(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col06X && curY === row04X && c6R3VNo === 11) {
        if (c6r3MT === "M" && c6r4MT === "M") {
          if (sepOnM3C6 === false) {
            setRow3NakedM06(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                ]
              )
              setC6R3VNo(11);
              setC6R4VNo(11);
              setSepOnM3C6(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col07X && curY === row04X && c7R3VNo === 1) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(1);
              setC7R4VNo(1);
              setSepOnM3C7(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 2) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(2);
            setC7R4VNo(2);
            setSepOnM3C7(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 3) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
              ]
            )
            setC7R3VNo(3);
            setC7R4VNo(3);
            setSepOnM3C7(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 4) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(4);
              setC7R4VNo(4);
              setSepOnM3C7(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 5) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(5);
              setC7R4VNo(5);
              setSepOnM3C7(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 6) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(6)
              setC7R4VNo(6)
              setSepOnM3C7(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 7) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(7);
              setC7R4VNo(7);
              setSepOnM3C7(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 8) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(8);
              setC7R4VNo(8);
              setSepOnM3C7(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 9) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(9);
              setC7R4VNo(9);
              setSepOnM3C7(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 10) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(10);
              setC7R4VNo(10);
              setSepOnM3C7(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col07X && curY === row04X && c7R3VNo === 11) {
        if (c7r3MT === "M" && c7r4MT === "M") {
          if (sepOnM3C7 === false) {
            setRow3NakedM07(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                ]
              )
              setC7R3VNo(11);
              setC7R4VNo(11);
              setSepOnM3C7(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col08X && curY === row04X && c8R3VNo === 1) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(1);
              setC8R4VNo(1);
              setSepOnM3C8(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 2) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(2);
            setC8R4VNo(2);
            setSepOnM3C8(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 3) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(3);
            setC8R4VNo(3);
            setSepOnM3C8(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 4) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(4);
              setC8R4VNo(4);
              setSepOnM3C8(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 5) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(5);
              setC8R4VNo(5);
              setSepOnM3C8(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 6) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(6)
              setC8R4VNo(6)
              setSepOnM3C8(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 7) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(7);
              setC8R4VNo(7);
              setSepOnM3C8(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 8) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(8);
              setC8R4VNo(8);
              setSepOnM3C8(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 9) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(9);
              setC8R4VNo(9);
              setSepOnM3C8(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 10) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(10);
              setC8R4VNo(10);
              setSepOnM3C8(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col08X && curY === row04X && c8R3VNo === 11) {
        if (c8r3MT === "M" && c8r4MT === "M") {
          if (sepOnM3C8 === false) {
            setRow3NakedM08(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(11);
              setC8R4VNo(11);
              setSepOnM3C8(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col09X && curY === row04X && c9R3VNo === 1) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(1);
              setC9R4VNo(1);
              setSepOnM3C9(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 2) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(2);
            setC9R4VNo(2);
            setSepOnM3C9(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 3) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(3);
            setC9R4VNo(3);
            setSepOnM3C9(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 4) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(4);
              setC9R4VNo(4);
              setSepOnM3C9(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 5) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(5);
              setC9R4VNo(5);
              setSepOnM3C9(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 6) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(6)
              setC9R4VNo(6)
              setSepOnM3C9(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 7) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(7);
              setC9R4VNo(7);
              setSepOnM3C9(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 8) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(8);
              setC9R4VNo(8);
              setSepOnM3C9(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 9) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(9);
              setC9R4VNo(9);
              setSepOnM3C9(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 10) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(10);
              setC9R4VNo(10);
              setSepOnM3C9(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col09X && curY === row04X && c9R3VNo === 11) {
        if (c9r3MT === "M" && c9r4MT === "M") {
          if (sepOnM3C9 === false) {
            setRow3NakedM09(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                ]
              )
              setC9R3VNo(11);
              setC9R4VNo(11);
              setSepOnM3C9(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col10X && curY === row04X && c10R3VNo === 1) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(1);
              setC10R4VNo(1);
              setSepOnM3C10(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 2) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(2);
            setC10R4VNo(2);
            setSepOnM3C10(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 3) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
              ]
            )
            setC10R3VNo(3);
            setC10R4VNo(3);
            setSepOnM3C10(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 4) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(4);
              setC10R4VNo(4);
              setSepOnM3C10(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 5) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(5);
              setC10R4VNo(5);
              setSepOnM3C10(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 6) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(6)
              setC10R4VNo(6)
              setSepOnM3C10(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 7) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(7);
              setC10R4VNo(7);
              setSepOnM3C10(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 8) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(8);
              setC10R4VNo(8);
              setSepOnM3C10(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 9) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(9);
              setC10R4VNo(9);
              setSepOnM3C10(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 10) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(10);
              setC10R4VNo(10);
              setSepOnM3C10(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col10X && curY === row04X && c10R3VNo === 11) {
        if (c10r3MT === "M" && c10r4MT === "M") {
          if (sepOnM3C10 === false) {
            setRow3NakedM10(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(11);
              setC10R4VNo(11);
              setSepOnM3C10(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col11X && curY === row04X && c11R3VNo === 1) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(1);
              setC11R4VNo(1);
              setSepOnM3C11(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 2) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(2);
            setC11R4VNo(2);
            setSepOnM3C11(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 3) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(3);
            setC11R4VNo(3);
            setSepOnM3C11(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 4) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(4);
              setC11R4VNo(4);
              setSepOnM3C11(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 5) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(5);
              setC11R4VNo(5);
              setSepOnM3C11(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 6) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(6)
              setC11R4VNo(6)
              setSepOnM3C11(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 7) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(7);
              setC11R4VNo(7);
              setSepOnM3C11(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 8) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(8);
              setC11R4VNo(8);
              setSepOnM3C11(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 9) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(9);
              setC11R4VNo(9);
              setSepOnM3C11(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 10) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(10);
              setC11R4VNo(10);
              setSepOnM3C11(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col11X && curY === row04X && c11R3VNo === 11) {
        if (c11r3MT === "M" && c11r4MT === "M") {
          if (sepOnM3C11 === false) {
            setRow3NakedM11(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                ]
              )
              setC11R3VNo(11);
              setC11R4VNo(11);
              setSepOnM3C11(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col12X && curY === row04X && c12R3VNo === 1) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(1);
              setC12R4VNo(1);
              setSepOnM3C12(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 2) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(2);
            setC12R4VNo(2);
            setSepOnM3C12(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 3) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(3);
            setC12R4VNo(3);
            setSepOnM3C12(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 4) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(4);
              setC12R4VNo(4);
              setSepOnM3C12(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 5) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(5);
              setC12R4VNo(5);
              setSepOnM3C12(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 6) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(6)
              setC12R4VNo(6)
              setSepOnM3C12(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 7) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(7);
              setC12R4VNo(7);
              setSepOnM3C12(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 8) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(8);
              setC12R4VNo(8);
              setSepOnM3C12(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 9) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(9);
              setC12R4VNo(9);
              setSepOnM3C12(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 10) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(10);
              setC12R4VNo(10);
              setSepOnM3C12(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col12X && curY === row04X && c12R3VNo === 11) {
        if (c12r3MT === "M" && c12r4MT === "M") {
          if (sepOnM3C12 === false) {
            setRow3NakedM12(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                ]
              )
              setC12R3VNo(11);
              setC12R4VNo(11);
              setSepOnM3C12(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col13X && curY === row04X && c13R3VNo === 1) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(1);
              setC13R4VNo(1);
              setSepOnM3C13(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 2) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(2);
            setC13R4VNo(2);
            setSepOnM3C13(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 3) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
              ]
            )
            setC13R3VNo(3);
            setC13R4VNo(3);
            setSepOnM3C13(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 4) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(4);
              setC13R4VNo(4);
              setSepOnM3C13(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 5) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(5);
              setC13R4VNo(5);
              setSepOnM3C13(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 6) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(6)
              setC13R4VNo(6)
              setSepOnM3C13(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 7) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(7);
              setC13R4VNo(7);
              setSepOnM3C13(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 8) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(8);
              setC13R4VNo(8);
              setSepOnM3C13(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 9) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(9);
              setC13R4VNo(9);
              setSepOnM3C13(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 10) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(10);
              setC13R4VNo(10);
              setSepOnM3C13(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col13X && curY === row04X && c13R3VNo === 11) {
        if (c13r3MT === "M" && c13r4MT === "M") {
          if (sepOnM3C13 === false) {
            setRow3NakedM13(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                ]
              )
              setC13R3VNo(11);
              setC13R4VNo(11);
              setSepOnM3C13(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      }  else if (curX === col14X && curY === row04X && c14R3VNo === 1) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(1);
              setC14R4VNo(1);
              setSepOnM3C14(true);
          } else {
            alert("Already added.")
          }
       
        }  else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 2) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(2);
            setC14R4VNo(2);
            setSepOnM3C14(true);
          } else {
            alert("Already added.")
          }

            
        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 3) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
            [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(3);
            setC14R4VNo(3);
            setSepOnM3C14(true);

          } else {
            alert("Already added.")
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 4) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(4);
              setC14R4VNo(4);
              setSepOnM3C14(true);
          }

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 5) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(5);
              setC14R4VNo(5);
              setSepOnM3C14(true);
           } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 6) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(6)
              setC14R4VNo(6)
              setSepOnM3C14(true);
          } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 7) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(7);
              setC14R4VNo(7);
              setSepOnM3C14(true);
            } 

        } else   {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 8) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(8);
              setC14R4VNo(8);
              setSepOnM3C14(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 9) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(9);
              setC14R4VNo(9);
              setSepOnM3C14(true);
           } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R3VNo === 10) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(10);
              setC14R4VNo(10);
              setSepOnM3C14(true);
            } 

        } else  {
          alert("Select Medium Module & Proceed")
        }
      } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
        if (c14r3MT === "M" && c14r4MT === "M") {
          if (sepOnM3C14 === false) {
            setRow3NakedM14(
                [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(11);
              setC14R4VNo(11);
              setSepOnM3C14(true);
            } 

        } else {
          alert("Select Medium Module & Proceed")
        }
      } 
   
  }

  const addRightPanel = (e)=> {

    if (curX === col01X && curY === row01X && c1R1VNo === 1) {
      if (c1r1MT === "S") {
      setBaseNaked01(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(4);
        } else if (c1r1MT === "M") {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
        } else if (c1r1MT === "L") {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(4);
          setC1R2VNo(4);
          setC1R3VNo(4);
          setC1R4VNo(4);
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
        if (c1r1MT === "S") {
          setBaseNaked01(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(7); 
        } else if (c1r1MT === "M") {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);  
          setC1R2VNo(7);
        } else if (c1r1MT === "L") {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(7);
          setC1R2VNo(7);
          setC1R3VNo(7);
          setC1R4VNo(7);
        }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
        if (c1r1MT === "S") {
          setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
        } else if (c1r1MT === "M") {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
        } else if (c1r1MT === "L") {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(5);
          setC1R2VNo(5);
          setC1R3VNo(5);
          setC1R4VNo(5);
        }
    
      } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
        alert("Already added");  
      } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {  
        alert("Already added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {
        if (c1r1MT === "S") {
          setBaseNaked01(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(8);     
        } else if (c1r1MT === "M") {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);  
          setC1R2VNo(8);  
        } else if (c1r1MT === "L") {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(8);
          setC1R2VNo(8);
          setC1R3VNo(8);
          setC1R4VNo(8);
        }

      } else if (curX === col01X && curY === row01X && c1R1VNo === 7) {
        alert("Already added");  
      } else if (curX === col01X && curY === row01X && c1R1VNo === 8) {
        alert("Already added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 9) {
        alert("Already added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 10) {
        alert("Already added");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {
        alert("Already added");
      }  else if (curX === col02X && curY === row01X && c2R1VNo === 1) {
          if (c2r1MT === "S") {
          setBaseNaked02(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(4);
          } else if (c2r1MT === "M") {
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(4);
            setC2R2VNo(4);
          } else if (c2r1MT === "L") {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(4);
            setC2R2VNo(4);
            setC2R3VNo(4);
            setC2R4VNo(4);
          }
        } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
          if (c2r1MT === "S") {
            setBaseNaked02(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(7);    
          } else if (c2r1MT === "M") {
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(7); 
            setC2R2VNo(7); 
          } else if (c2r1MT === "L") {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(7);
            setC2R2VNo(7);
            setC2R3VNo(7);
            setC2R4VNo(7);
          }
        } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
          if (c2r1MT === "S") {
          setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
            )
            setC2R1VNo(5);
          } else if (c2r1MT === "M") {
              setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
              setC2R2VNo(5);
            } else if (c2r1MT === "L") {
              setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
              setC2R1VNo(5);
              setC2R2VNo(5);
              setC2R3VNo(5);
              setC2R4VNo(5);
          }
    
        } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
          alert("Already added"); 
        } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {  
          alert("Already added");
        } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {
          if (c2r1MT === "S") {
            setBaseNaked02(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                    ]
                  )
                  setC2R1VNo(8);   
          } else if (c2r1MT === "M") {
            setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(8);   
            setC2R2VNo(8);  
          } else if (c2r1MT === "L") {
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(8);
            setC2R2VNo(8);
            setC2R3VNo(8);
            setC2R4VNo(8);
          }
        } else if (curX === col02X && curY === row01X && c2R1VNo === 7) {
          alert("Already added");  
        } else if (curX === col02X && curY === row01X && c2R1VNo === 8) {
          alert("Already added");
        } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
          alert("Already added");
        } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
          alert("Already added");
        } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
          alert("Already added");
        }  else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
              if (c3r1MT === "S") {
              setBaseNaked03(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(4);
              } else if (c3r1MT === "M") {
                setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(4);
                setC3R2VNo(4);
              } else if (c3r1MT === "L") {
                setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(4);
                setC3R2VNo(4);
                setC3R3VNo(4);
                setC3R4VNo(4);
              }
          } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
            if (c3r1MT === "S") {
              setBaseNaked03(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(7);   
              } else if (c3r1MT === "M") { 
                setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(7);   
                setC3R2VNo(7);  
              } else if (c3r1MT === "L") {
                setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(7);
                setC3R2VNo(7);
                setC3R3VNo(7);
                setC3R4VNo(7);
              }
          } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
            if (c3r1MT === "S") {
              setBaseNaked03(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
            } else if (c3r1MT === "M") { 
              setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
              setC3R2VNo(5);
            } else if (c3r1MT === "L") {
              setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(5);
              setC3R2VNo(5);
              setC3R3VNo(5);
              setC3R4VNo(5);
            }

          } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
            alert("Already added");  
          } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {  
            alert("Already added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {
            if (c3r1MT === "S") {
              setBaseNaked03(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(8);   
                      
              } else if (c3r1MT === "M") { 
                setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(8);   
                setC3R2VNo(8);   
              } else if (c3r1MT === "L") {
                setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
                setC3R1VNo(8);
                setC3R2VNo(8);
                setC3R3VNo(8);
                setC3R4VNo(8);
              }
          } else if (curX === col03X && curY === row01X && c3R1VNo === 7) {
            alert("Already added");  
          } else if (curX === col03X && curY === row01X && c3R1VNo === 8) {
            alert("Already added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 9) {
            alert("Already added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
            alert("Already added");
          } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
            alert("Already added");
          }  else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
              if (c4r1MT === "S") {
                setBaseNaked04(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(4);
              } else if (c4r1MT === "M") { 
                setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(4);
                setC4R2VNo(4);
              } else if (c4r1MT === "L") { 
                setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(4);
                setC4R2VNo(4);
                setC4R3VNo(4);
                setC4R4VNo(4);
              }
            } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
              if (c4r1MT === "S") {
                setBaseNaked04(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
                        ]
                      )
                      setC4R1VNo(7);    
              } else if (c4r1MT === "M") { 
                setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(7);  
                setC4R2VNo(7); 
              } else if (c4r1MT === "L") { 
                setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(7);
                setC4R2VNo(7);
                setC4R3VNo(7);
                setC4R4VNo(7);
              }
            } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
              if (c4r1MT === "S") {
              setBaseNaked04(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
            } else if (c4r1MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
            } else if (c4r1MT === "L") { 
              setBaseNakedL04(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                ]
              )
              setC4R1VNo(5);
              setC4R2VNo(5);
              setC4R3VNo(5);
              setC4R4VNo(5);
            }
            } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
              alert("Already added"); 
            } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {  
              alert("Already added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {
              if (c4r1MT === "S") {
                setBaseNaked04(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                        ]
                      )
                      setC4R1VNo(8);    
              } else if (c4r1MT === "M") { 
                setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(8);  
                setC4R2VNo(8);
              } else if (c4r1MT === "L") { 
                setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(8);
                setC4R2VNo(8);
                setC4R3VNo(8);
                setC4R4VNo(8);

              }
            } else if (curX === col04X && curY === row01X && c4R1VNo === 7) {
              alert("Already added");  
            } else if (curX === col04X && curY === row01X && c4R1VNo === 8) {
              alert("Already added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 9) {
              alert("Already added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
              alert("Already added");
            } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
              alert("Already added");
            }  else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
              if (c5r1MT === "S") {
              setBaseNaked05(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(4);
              } else if (c5r1MT === "M") { 
                setBaseNakedM05(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(4);
                setC5R2VNo(4);
              } else if (c5r1MT === "L") { 
                setBaseNakedL05(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                  ]
                )
                setC5R1VNo(4);
                setC5R2VNo(4);
                setC5R3VNo(4);
                setC5R4VNo(4);
              }
              } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
                if (c5r1MT === "S") {
                  setBaseNaked05(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
                          ]
                        )
                        setC5R1VNo(7);    
                } else if (c5r1MT === "M") { 
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(7);   
                  setC5R2VNo(7); 
                } else if (c5r1MT === "L") { 
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(7);
                  setC5R2VNo(7);
                  setC5R3VNo(7);
                  setC5R4VNo(7);
                }
              } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
                if (c5r1MT === "S") {
                  setBaseNaked05(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(5);
                } else if (c5r1MT === "M") { 
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(5);
                  setC5R2VNo(5);
                } else if (c5r1MT === "L") { 
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(5);
                  setC5R2VNo(5);
                  setC5R3VNo(5);
                  setC5R4VNo(5);
                }
                 
          
              } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
                alert("Already added"); 
              } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {  
                alert("Already added");
              } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {
                  if (c5r1MT === "S") {
                  setBaseNaked05(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                          ]
                        )
                        setC5R1VNo(8);  
                        
                  } else if (c5r1MT === "M") { 
                    setBaseNakedM05(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(8); 
                    setC5R2VNo(8); 
                  } else if (c5r1MT === "L") { 
                    setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
                    setC5R1VNo(8);
                    setC5R2VNo(8);
                    setC5R3VNo(8);
                    setC5R4VNo(8);
                  }
              } else if (curX === col05X && curY === row01X && c5R1VNo === 7) {
                alert("Already added");  
              } else if (curX === col05X && curY === row01X && c5R1VNo === 8) {
                alert("Already added");
              } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
                alert("Already added");
              } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
                alert("Already added");
              } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
                alert("Already added");
              }  else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
                if (c6r1MT === "S") {
                setBaseNaked06(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
                      setC6R1VNo(4);
                } else if (c6r1MT === "M") { 
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(4);
                  setC6R2VNo(4);
                } else if (c6r1MT === "L") { 
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(4);
                  setC6R2VNo(4);
                  setC6R3VNo(4);
                  setC6R4VNo(4);
                }
        } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
          if (c6r1MT === "S") {
            setBaseNaked06(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(7);   
            } else if (c6r1MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(7);    
              setC6R2VNo(7); 
            } else if (c6r1MT === "L") { 
              setBaseNakedL06(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(7);
              setC6R2VNo(7);
              setC6R3VNo(7);
              setC6R4VNo(7);
            }
        } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
          if (c6r1MT === "S") {
            setBaseNaked06(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
          } else if (c6r1MT === "M") { 
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
          } else if (c6r1MT === "L") { 
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
            setC6R3VNo(5);
            setC6R4VNo(5);
          }

    } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
      alert("Already added");  
    } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {  
      alert("Already added");
    } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {
        if (c6r1MT === "S") {
        setBaseNaked06(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                ]
              )
              setC6R1VNo(8);  
        } else if (c6r1MT === "M") { 
          setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);     
          setC6R2VNo(8);
        } else if (c6r1MT === "L") { 
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(8);
          setC6R2VNo(8);
          setC6R3VNo(8);
          setC6R4VNo(8);
        }   
    } else if (curX === col06X && curY === row01X && c6R1VNo === 7) {
      alert("Already added");  
    } else if (curX === col06X && curY === row01X && c6R1VNo === 8) {
      alert("Already added");
    } else if (curX === col06X && curY === row01X && c6R1VNo === 9) {
      alert("Already added");
    } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
      alert("Already added");
    } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
      alert("Already added");
    }  else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
        if (c7r1MT === "S") {
        setBaseNaked07(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
          } else if (c7r1MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
            setC7R2VNo(4);
          } else if (c7r1MT === "L") { 
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(4);
            setC7R2VNo(4);
            setC7R3VNo(4);
            setC7R4VNo(4);
          }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
        if (c7r1MT === "S") {
          setBaseNaked07(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
                  ]
                )
                setC7R1VNo(7);    
          } else if (c7r1MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);    
            setC7R2VNo(7);
          } else if (c7r1MT === "L") { 
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(7);
            setC7R2VNo(7);
            setC7R3VNo(7);
            setC7R4VNo(7);
          }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
        if (c7r1MT === "S") {
          setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
        } else if (c7r1MT === "M") { 
          setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
        } else if (c7r1MT === "L") { 
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
          setC7R1VNo(5);
          setC7R2VNo(5);
          setC7R3VNo(5);
          setC7R4VNo(5);
        }
  
      } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
        alert("Already added");  
      } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {  
        alert("Already added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {
        if (c7r1MT === "S") {
          setBaseNaked07(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                  ]
                )
                setC7R1VNo(8);     
          } else if (c7r1MT === "M") { 
            setBaseNakedM07(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(8);   
            setC7R2VNo(8); 
          } else if (c7r1MT === "L") { 
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )
            setC7R1VNo(8);
            setC7R2VNo(8);
            setC7R3VNo(8);
            setC7R4VNo(8);
          }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 7) {
        alert("Already added");  
      } else if (curX === col07X && curY === row01X && c7R1VNo === 8) {
        alert("Already added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 9) {
        alert("Already added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
        alert("Already added");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
        alert("Already added");
      }  else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
          if (c8r1MT === "S") {
          setBaseNaked08(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
                  ]
                )
                setC8R1VNo(4);
          } else if (c8r1MT === "M") { 
            setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
            setC8R2VNo(4);
          } else if (c8r1MT === "L") { 
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(4);
            setC8R2VNo(4);
            setC8R3VNo(4);
            setC8R4VNo(4);
          }
        } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
          if (c8r1MT === "S") {
            setBaseNaked08(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(7);    
          } else if (c8r1MT === "M") { 
            setBaseNakedM08(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(7);    
            setC8R2VNo(7);  
          } else if (c8r1MT === "L") { 
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )
            setC8R1VNo(7);
            setC8R2VNo(7);
            setC8R3VNo(7);
            setC8R4VNo(7);
          }
        } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
          if (c8r1MT === "S") {
          setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
        } else if (c8r1MT === "M") { 
          setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
        } else if (c8r1MT === "L") { 
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
          setC8R1VNo(5);
          setC8R2VNo(5);
          setC8R3VNo(5);
          setC8R4VNo(5);
        }
    
        } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
          alert("Already added"); 
        } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {  
          alert("Already added");
        } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {
          if (c8r1MT === "S") {
            setBaseNaked08(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                    ]
                  )
                  setC8R1VNo(8);   
            } else if (c8r1MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(8); 
              setC8R2VNo(8); 
            } else if (c8r1MT === "L") { 
              setBaseNakedL08(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(8);
              setC8R2VNo(8);
              setC8R3VNo(8);
              setC8R4VNo(8);
            }
        } else if (curX === col08X && curY === row01X && c8R1VNo === 7) {
          alert("Already added");  
        } else if (curX === col08X && curY === row01X && c8R1VNo === 8) {
          alert("Already added");
        } else if (curX === col08X && curY === row01X && c8R1VNo === 9) {
          alert("Already added");
        } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
          alert("Already added");
        } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
          alert("Already added");
        }  else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
          if (c9r1MT === "S") {
          setBaseNaked09(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(4);
          } else if (c9r1MT === "M") { 
            setBaseNakedM09(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(4);
            setC9R2VNo(4);
          } else if (c9r1MT === "L") { 
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(4);
            setC9R2VNo(4);
            setC9R3VNo(4);
            setC9R4VNo(4);
          }
          } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
            if (c9r1MT === "S") {
              setBaseNaked09(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(7);   
              } else if (c9r1MT === "M") { 
                setBaseNakedM09(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(7); 
                setC9R2VNo(7);
              } else if (c9r1MT === "L") { 
                setBaseNakedL09(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(7);
                setC9R2VNo(7);
                setC9R3VNo(7);
                setC9R4VNo(7);
              }
          } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
            if (c9r1MT === "S") {
            setBaseNaked09(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(5);
          } else if (c9r1MT === "M") { 
            setBaseNakedM09(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(5);
            setC9R2VNo(5);
          } else if (c9r1MT === "L") { 
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )
            setC9R1VNo(5);
            setC9R2VNo(5);
            setC9R3VNo(5);
            setC9R4VNo(5);
          }
          } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
            alert("Already added"); 
          } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {  
            alert("Already added");
          } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {
            if (c9r1MT === "S") {
              setBaseNaked09(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(8);   
              } else if (c9r1MT === "M") { 
                setBaseNakedM09(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(8); 
                setC9R2VNo(8); 
              } else if (c9r1MT === "L") { 
                setBaseNakedL09(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                  ]
                )
                setC9R1VNo(8);
                setC9R2VNo(8);
                setC9R3VNo(8);
                setC9R4VNo(8);
              }  
          } else if (curX === col09X && curY === row01X && c9R1VNo === 7) {
            alert("Already added");  
          } else if (curX === col09X && curY === row01X && c9R1VNo === 8) {
            alert("Already added");
          } else if (curX === col09X && curY === row01X && c9R1VNo === 9) {
            alert("Already added");
          } else if (curX === col09X && curY === row01X && c9R1VNo === 10) {
            alert("Already added");
          } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
            alert("Already added");
          }  else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
            if (c10r1MT === "S") {
            setBaseNaked10(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(4);
            } else if (c10r1MT === "M") { 
              setBaseNakedM10(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(4);
              setC10R2VNo(4);
            } else if (c10r1MT === "L") { 
              setBaseNakedL10(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(4);
              setC10R2VNo(4);
              setC10R3VNo(4);
              setC10R4VNo(4);
            }
            } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
              if (c10r1MT === "S") {
                setBaseNaked10(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
                        ]
                      )
                      setC10R1VNo(7);   
                } else if (c10r1MT === "M") { 
                  setBaseNakedM10(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(7);
                  setC10R2VNo(7);
                } else if (c10r1MT === "L") { 
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(7);
                  setC10R2VNo(7);
                  setC10R3VNo(7);
                  setC10R4VNo(7);
                } 
            } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
              if (c10r1MT === "S") {
              setBaseNaked10(
                [
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(5);
            } else if (c10r1MT === "M") { 
              setBaseNakedM10(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(5);
              setC10R2VNo(5);
            } else if (c10r1MT === "L") { 
              setBaseNakedL10(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(5);
              setC10R2VNo(5);
              setC10R3VNo(5);
              setC10R4VNo(5);
            } 
              
            } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
              alert("Already added");  
            } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {  
              alert("Already added");
            } else if (curX === col10X && curY === row01X && c10R1VNo === 6) {
              if (c10r1MT === "S") {
                setBaseNaked10(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                        ]
                      )
                      setC10R1VNo(8);
                } else if (c10r1MT === "M") { 
                  setBaseNakedM10(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(8); 
                  setC10R2VNo(8);  
                } else if (c10r1MT === "L") { 
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(8);
                  setC10R2VNo(8);
                  setC10R3VNo(8);
                  setC10R4VNo(8);
                }      
            } else if (curX === col10X && curY === row01X && c10R1VNo === 7) {
              alert("Already added");  
            } else if (curX === col10X && curY === row01X && c10R1VNo === 8) {
              alert("Already added");
            } else if (curX === col10X && curY === row01X && c10R1VNo === 9) {
              alert("Already added");
            } else if (curX === col10X && curY === row01X && c10R1VNo === 10) {
              alert("Already added");
            } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
              alert("Already added");
            }  else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
              if (c11r1MT === "S") {
              setBaseNaked11(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(4);
              } else if (c11r1MT === "M") { 
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(4);
                setC11R2VNo(4);
              } else if (c11r1MT === "L") { 
                setBaseNakedL11(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(4);
                setC11R2VNo(4);
                setC11R3VNo(4);
                setC11R4VNo(4);
              } 
              } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
                if (c11r1MT === "S") {
                  setBaseNaked11(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
                          ]
                        )
                        setC11R1VNo(7);   
                  } else if (c11r1MT === "M") { 
                    setBaseNakedM11(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(7);   
                    setC11R2VNo(7); 
                  } else if (c11r1MT === "L") { 
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(7);
                    setC11R2VNo(7);
                    setC11R3VNo(7);
                    setC11R4VNo(7);
                  }  
              } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
                if (c11r1MT === "S") {
                setBaseNaked11(
                  [
                    <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(5);
              } else if (c11r1MT === "M") { 
                setBaseNakedM11(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(5);
                setC11R2VNo(5);
              } else if (c11r1MT === "L") { 
                setBaseNakedL11(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
                setC11R1VNo(5);
                setC11R2VNo(5);
                setC11R3VNo(5);
                setC11R4VNo(5);
              }  
                
              } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
                alert("Already added"); 
              } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {  
                alert("Already added");
              } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {
                if (c11r1MT === "S") {
                  setBaseNaked11(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                          ]
                        )
                        setC11R1VNo(8);   
                  } else if (c11r1MT === "M") { 
                    setBaseNakedM11(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(8); 
                    setC11R2VNo(8);
                  } else if (c11r1MT === "L") { 
                    setBaseNakedL11(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(8);
                    setC11R2VNo(8);
                    setC11R3VNo(8);
                    setC11R4VNo(8);
                  }    
              } else if (curX === col11X && curY === row01X && c11R1VNo === 7) {
                alert("Already added");  
              } else if (curX === col11X && curY === row01X && c11R1VNo === 8) {
                alert("Already added");
              } else if (curX === col11X && curY === row01X && c11R1VNo === 9) {
                alert("Already added");
              } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
                alert("Already added");
              } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
                alert("Already added");
              }  else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
                if (c12r1MT === "S") {
                setBaseNaked12(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(4);
                } else if (c12r1MT === "M") { 
                  setBaseNakedM12(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(4);
                  setC12R2VNo(4);
                } else if (c12r1MT === "L") { 
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(4);
                  setC12R2VNo(4);
                  setC12R3VNo(4);
                  setC12R4VNo(4);
                } 
                } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
                  if (c12r1MT === "S") {
                    setBaseNaked12(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(7);   
                    } else if (c12r1MT === "M") { 
                      setBaseNakedM12(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(7);    
                      setC12R2VNo(7); 
                    } else if (c12r1MT === "L") { 
                      setBaseNakedL12(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(7);
                      setC12R2VNo(7);
                      setC12R3VNo(7);
                      setC12R4VNo(7);
                    }  
                } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
                  if (c12r1MT === "S") {
                  setBaseNaked12(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(5);
                } else if (c12r1MT === "M") { 
                  setBaseNakedM12(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(5);
                  setC12R2VNo(5);
                } else if (c12r1MT === "L") { 
                  setBaseNakedL12(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                    ]
                  )
                  setC12R1VNo(5);
                  setC12R2VNo(5);
                  setC12R3VNo(5);
                  setC12R4VNo(5);
                }  
            
                } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
                  alert("Already added");  
                } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {  
                  alert("Already added");
                } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {
                  if (c12r1MT === "S") {
                    setBaseNaked12(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(8);   
                    } else if (c12r1MT === "M") { 
                      setBaseNakedM12(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(8);
                      setC12R2VNo(8);
                    } else if (c12r1MT === "L") { 
                      setBaseNakedL12(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(8);
                      setC12R2VNo(8);
                      setC12R3VNo(8);
                      setC12R4VNo(8);
                    }    
                } else if (curX === col12X && curY === row01X && c12R1VNo === 7) {
                  alert("Already added");  
                } else if (curX === col12X && curY === row01X && c12R1VNo === 8) {
                  alert("Already added");
                } else if (curX === col12X && curY === row01X && c12R1VNo === 9) {
                  alert("Already added");
                } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
                  alert("Already added");
                } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
                  alert("Already added");
                }  else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
                  if (c13r1MT === "S") {
                  setBaseNaked13(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(4);
                  } else if (c13r1MT === "M") { 
                    setBaseNakedM13(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(4);
                    setC13R2VNo(4);
                  } else if (c13r1MT === "L") { 
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(4);
                    setC13R2VNo(4);
                    setC13R3VNo(4);
                    setC13R4VNo(4);
                  }  
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
                    if (c13r1MT === "S") {
                      setBaseNaked13(
                              [
                                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(7);  
                      } else if (c13r1MT === "M") { 
                        setBaseNakedM13(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(7); 
                        setC13R1VNo(7); 
                      } else if (c13r1MT === "L") { 
                        setBaseNakedL13(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(7);
                        setC13R2VNo(7);
                        setC13R3VNo(7);
                        setC13R4VNo(7);
                      }    
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
                    if (c13r1MT === "S") {
                    setBaseNaked13(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(5);
                  } else if (c13r1MT === "M") { 
                    setBaseNakedM13(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(5);
                    setC13R1VNo(5);
                  } else if (c13r1MT === "L") { 
                    setBaseNakedL13(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(5);
                    setC13R2VNo(5);
                    setC13R3VNo(5);
                    setC13R4VNo(5);
                  }  
                    
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
                    alert("Already added");  
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {  
                    alert("Already added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {
                    if (c13r1MT === "S") {
                      setBaseNaked13(
                              [
                                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(8);  
                      } else if (c13r1MT === "M") { 
                        setBaseNakedM13(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(8);  
                        setC13R2VNo(8); 
                      } else if (c13r1MT === "L") { 
                        setBaseNakedL13(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(8);
                        setC13R2VNo(8);
                        setC13R3VNo(8);
                        setC13R4VNo(8);
                      }     
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 7) {
                    alert("Already added");  
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 8) {
                    alert("Already added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 9) {
                    alert("Already added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
                    alert("Already added");
                  } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
                    alert("Already added");
                  }  else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
                    if (c14r1MT === "S") {
                    setBaseNaked14(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(4);
                        } else if (c14r1MT === "M") { 
                          setBaseNakedM14(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(4);
                          setC14R2VNo(4);
                        } else if (c14r1MT === "L") { 
                          setBaseNakedL14(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(4);
                          setC14R2VNo(4);
                          setC14R3VNo(4);
                          setC14R4VNo(4);
                        }  
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
                      if (c14r1MT === "S") {
                        setBaseNaked14(
                                [
                                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(7);  
                        } else if (c14r1MT === "M") { 
                          setBaseNakedM14(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(7);  
                          setC14R2VNo(7); 
                        } else if (c14r1MT === "L") { 
                          setBaseNakedL14(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                            ]
                          )
                          setC14R1VNo(7);
                          setC14R2VNo(7);
                          setC14R3VNo(7);
                          setC14R4VNo(7);
                        }    
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
                      if (c14r1MT === "S") {
                      setBaseNaked14(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(5);
                    } else if (c14r1MT === "M") { 
                      setBaseNakedM14(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(5);
                      setC14R2VNo(5);
                    } else if (c14r1MT === "L") { 
                      setBaseNakedL14(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(5);
                      setC14R2VNo(5);
                      setC14R3VNo(5);
                      setC14R4VNo(5);
                    }  
                      
                
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
                      alert("Already added");  
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {  
                      alert("Already added");
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {
                      if (c14r1MT === "S") {
                        setBaseNaked14(
                                [
                                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(8);  
                      } else if (c14r1MT === "M") { 
                        setBaseNakedM14(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                          ]
                        )
                        setC14R1VNo(8); 
                        setC14R2VNo(8); 
                      } else if (c14r1MT === "L") { 
                        setBaseNakedL14(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                          ]
                        )
                        setC14R1VNo(8);
                        setC14R2VNo(8);
                        setC14R3VNo(8);
                        setC14R4VNo(8);
                      }     
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 7) {
                      alert("Already added");  
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 8) {
                      alert("Already added");
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 9) {
                      alert("Already added");
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
                      alert("Already removed");
                    } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
                      alert("Already added");
                    } 	else if (curX === col01X && curY === row02X && c1R2VNo === 1) {
                      if (c1r2MT === "S") {
                      setRow2Naked01(
                             [
                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
                             ]
                           )
                           setC1R2VNo(4);
                      } else if (c1r2MT === "M" && c1r1MT === "M") { 
                        setBaseNakedM01(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(4);
                        setC1R2VNo(4);
                      } else if (c1r2MT === "M" && c1r3MT === "M") { 
                        setRow2NakedM01(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col01X,row02X,0.5]} />
                          ]
                        )
                        setC1R2VNo(4);
                        setC1R3VNo(4);
                      } else if (c1r2MT === "L") { 
                        setBaseNakedL01(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(4);
                        setC1R2VNo(4);
                        setC1R3VNo(4);
                        setC1R4VNo(4);
                      }
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
                        if (c1r2MT === "S") {
                          setRow2Naked01(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
                                  ]
                                )
                                setC1R2VNo(7);    
                              } else if (c1r2MT === "M" && c1r1MT === "M") { 
                                setBaseNakedM01(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);  
                                setC1R2VNo(7);
                              } else if (c1r2MT === "M" && c1r3MT === "M") { 
                                setRow2NakedM01(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                  ]
                                )
                                setC1R2VNo(7);   
                                setC1R3VNo(7); 
                              } else if (c1r2MT === "L") { 
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(7);
                                setC1R2VNo(7);
                                setC1R3VNo(7);
                                setC1R4VNo(7);
                              }

                      } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
                        if (c1r2MT === "S") {
                        setRow2Naked01(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                          ]
                        )
                        setC1R2VNo(5);
                      } else if (c1r2MT === "M" && c1r1MT === "M") { 
                        setBaseNakedM01(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(5);
                        setC1R2VNo(5);
                      } else if (c1r2MT === "M" && c1r3MT === "M") { 
                        setRow2NakedM01(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                          ]
                        )
                        setC1R2VNo(5);
                        setC1R3VNo(5);
                      } else if (c1r2MT === "L") { 
                        setBaseNakedL01(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(5);
                        setC1R2VNo(5);
                        setC1R3VNo(5);
                        setC1R4VNo(5);
                      }

                      } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
                        alert("Already added"); 
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {  
                        alert("Already added");
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {
                        if (c1r2MT === "S") {
                          setRow2Naked01(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                                  ]
                                )
                                setC1R2VNo(8);   
                              } else if (c1r2MT === "M" && c1r1MT === "M") { 
                                setBaseNakedM01(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(8);  
                                setC1R2VNo(8);  
                              } else if (c1r2MT === "M" && c1r3MT === "M") { 
                                setRow2NakedM01(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                  ]
                                )
                                setC1R2VNo(8); 
                                setC1R3VNo(8); 
                              } else if (c1r2MT === "L") { 
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                                setC1R1VNo(8);
                                setC1R2VNo(8);
                                setC1R3VNo(8);
                                setC1R4VNo(8);
                              }
          
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 7) {
                        alert("Already added");  
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 8) {
                        alert("Already added");
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 9) {
                        alert("Already added");
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
                        alert("Already added");
                      } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
                        alert("Already added");
                      }  else if (curX === col02X && curY === row02X && c2R2VNo === 1) {
                        if (c2r2MT === "S") {
                        setRow2Naked02(
                               [
                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
                               ]
                             )
                             setC2R2VNo(4);
                            } else if (c2r2MT === "M" && c2r1MT === "M") { 
                              setBaseNakedM02(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(4);
                              setC2R2VNo(4);
                            } else if (c2r2MT === "M" && c2r3MT === "M") { 
                              setRow2NakedM02(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col02X,row02X,0.5]} />
                                ]
                              )
                              setC2R2VNo(4);
                              setC2R3VNo(4);
                            } else if (c2r2MT === "L") { 
                              setBaseNakedL02(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                ]
                              )
                              setC2R1VNo(4);
                              setC2R2VNo(4);
                              setC2R3VNo(4);
                              setC2R4VNo(4);
                            }
      
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
                          if (c2r2MT === "S") {
                            setRow2Naked02(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                                  setC2R2VNo(7);    
                                } else if (c2r2MT === "M" && c2r1MT === "M") { 
                                  setBaseNakedM02(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                                    ]
                                  )
                                  setC2R1VNo(7); 
                                  setC2R2VNo(7); 
                                } else if (c2r2MT === "M" && c2r3MT === "M") { 
                                  setRow2NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                                  setC2R2VNo(7);  
                                  setC2R3VNo(7); 
                                } else if (c2r2MT === "L") { 
                                  setBaseNakedL02(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                    ]
                                  )
                                  setC2R1VNo(7);
                                  setC2R2VNo(7);
                                  setC2R3VNo(7);
                                  setC2R4VNo(7);
                                }
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
                          if (c2r2MT === "S") {
                          setRow2Naked02(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                            ]
                          )
                          setC2R2VNo(5);
                        } else if (c2r2MT === "M" && c2r1MT === "M") { 
                          setBaseNakedM02(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(5);
                          setC2R2VNo(5);
                        } else if (c2r2MT === "M" && c2r3MT === "M") { 
                          setRow2NakedM02(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                            ]
                          )
                          setC2R2VNo(5);
                          setC2R3VNo(5);
                        } else if (c2r2MT === "L") { 
                          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(5);
                          setC2R2VNo(5);
                          setC2R3VNo(5);
                          setC2R4VNo(5);
                        }
                    
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
                          alert("Already added");  
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {  
                          alert("Already added");
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {
                          if (c2r2MT === "S") {
                            setRow2Naked02(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                                  setC2R2VNo(8);    
                                } else if (c2r2MT === "M" && c2r1MT === "M") { 
                                  setBaseNakedM02(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                                    ]
                                  )
                                  setC2R1VNo(8);   
                                  setC2R2VNo(8);  
                                } else if (c2r2MT === "M" && c2r3MT === "M") { 
                                  setRow2NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                                  setC2R2VNo(8); 
                                  setC2R3VNo(8);   
                                } else if (c2r2MT === "L") { 
                                  setBaseNakedL02(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                    ]
                                  )
                                  setC2R1VNo(8);
                                  setC2R2VNo(8);
                                  setC2R3VNo(8);
                                  setC2R4VNo(8);
                                } 
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 7) {
                          alert("Already added");  
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 8) {
                          alert("Already added");
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
                          alert("Already added");
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
                          alert("Already added");
                        } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
                          alert("Already added");
                        }  else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
                          if (c3r2MT === "S") {
                          setRow2Naked03(
                                 [
                                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                                 ]
                               )
                               setC3R2VNo(4);
                              } else if (c3r2MT === "M" && c3r1MT === "M") { 
                                setBaseNakedM03(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col03X,row01X,0.5]} />
                                  ]
                                )
                                setC3R1VNo(4);
                                setC3R2VNo(4);
                              } else if (c3r2MT === "M" && c3r3MT === "M") { 
                                setRow2NakedM03(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col03X,row02X,0.5]} />
                                  ]
                                )
                                setC3R2VNo(4);
                                setC3R3VNo(4);
                              } else if (c3r2MT === "L") { 
                                setBaseNakedL03(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                  ]
                                )
                                setC3R1VNo(4);
                                setC3R2VNo(4);
                                setC3R3VNo(4);
                                setC3R4VNo(4);
                              }
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
                            if (c3r2MT === "S") {
                              setRow2Naked03(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
                                      ]
                                    )
                                    setC3R2VNo(7);  
                                  } else if (c3r2MT === "M" && c3r1MT === "M") { 
                                    setBaseNakedM03(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                      ]
                                    )
                                    setC3R1VNo(7);   
                                    setC3R2VNo(7);  
                                  } else if (c3r2MT === "M" && c3r3MT === "M") { 
                                    setRow2NakedM03(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                      ]
                                    )
                                    setC3R2VNo(7);  
                                    setC3R3VNo(7);
                                  } else if (c3r2MT === "L") { 
                                    setBaseNakedL03(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                      ]
                                    )
                                    setC3R1VNo(7);
                                    setC3R2VNo(7);
                                    setC3R3VNo(7);
                                    setC3R4VNo(7);
                                  }  
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
                            if (c3r2MT === "S") {
                            setRow2Naked03(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                              ]
                            )
                            setC3R2VNo(5);
                          } else if (c3r2MT === "M" && c3r1MT === "M") { 
                            setBaseNakedM03(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(5);
                            setC3R2VNo(5);
                          } else if (c3r2MT === "M" && c3r3MT === "M") { 
                            setRow2NakedM03(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                              ]
                            )
                            setC3R2VNo(5);
                            setC3R3VNo(5);
                          } else if (c3r2MT === "L") { 
                            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(5);
                            setC3R2VNo(5);
                            setC3R3VNo(5);
                            setC3R4VNo(5);
                          }
                      
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
                            alert("Already added");  
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {  
                            alert("Already added");
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {
                            if (c3r2MT === "S") {
                              setRow2Naked03(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                                      ]
                                    )
                                    setC3R2VNo(8);   
                                  } else if (c3r2MT === "M" && c3r1MT === "M") { 
                                    setBaseNakedM03(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                      ]
                                    )
                                    setC3R1VNo(8);   
                                    setC3R2VNo(8);  
                                  } else if (c3r2MT === "M" && c3r3MT === "M") { 
                                    setRow2NakedM03(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                      ]
                                    )
                                    setC3R2VNo(8);  
                                    setC3R3VNo(8);  
                                  } else if (c3r2MT === "L") { 
                                    setBaseNakedL03(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                      ]
                                    )
                                    setC3R1VNo(8);
                                    setC3R2VNo(8);
                                    setC3R3VNo(8);
                                    setC3R4VNo(8);
                                  }  
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 7) {
                            alert("Already added");  
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 8) {
                            alert("Already added");
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 9) {
                            alert("Already added");
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
                            alert("Already added");
                          } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
                            alert("Already added");
                          }  else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
                            if (c4r2MT === "S") {
                            setRow2Naked04(
                                   [
                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                                   ]
                                 )
                                 setC4R2VNo(4);
                                } else if (c4r2MT === "M" && c4r1MT === "M") { 
                                  setBaseNakedM04(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col04X,row01X,0.5]} />
                                    ]
                                  )
                                  setC4R1VNo(4);
                                  setC4R2VNo(4);
                                } else if (c4r2MT === "M" && c4r3MT === "M") { 
                                  setRow2NakedM04(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col04X,row02X,0.5]} />
                                    ]
                                  )
                                  setC4R2VNo(4);
                                  setC4R3VNo(4);
                                } else if (c4r2MT === "L") { 
                                  setBaseNakedL04(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                    ]
                                  )
                                  setC4R1VNo(4);
                                  setC4R2VNo(4);
                                  setC4R3VNo(4);
                                  setC4R4VNo(4);
                                }
                            } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
                              if (c4r2MT === "S") {
                              setRow2Naked04(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
                                      ]
                                    )
                                    setC4R2VNo(7);    
                                  } else if (c4r2MT === "M" && c4r1MT === "M") { 
                                    setBaseNakedM04(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                                      ]
                                    )
                                    setC4R1VNo(7);  
                                    setC4R2VNo(7); 
                                  } else if (c4r2MT === "M" && c4r3MT === "M") { 
                                    setRow2NakedM04(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                      ]
                                    )
                                    setC4R2VNo(7); 
                                    setC4R3VNo(7); 
                                  } else if (c4r2MT === "L") { 
                                    setBaseNakedL04(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                      ]
                                    )
                                    setC4R1VNo(7);
                                    setC4R2VNo(7);
                                    setC4R3VNo(7);
                                    setC4R4VNo(7);

                                  }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
        if (c4r2MT === "S") {
        setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(5);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(5);
        setC4R3VNo(5);
      } else if (c4r2MT === "L") { 
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(5);
        setC4R2VNo(5);
        setC4R3VNo(5);
        setC4R4VNo(5);
      }

      } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
        alert("Already added"); 
      } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {  
        alert("Already added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {
        if (c4r2MT === "S") {
          setRow2Naked04(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                  ]
                )
                setC4R2VNo(8);    
              } else if (c4r2MT === "M" && c4r1MT === "M") { 
                setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(8);  
                setC4R2VNo(8);
              } else if (c4r2MT === "M" && c4r3MT === "M") { 
                setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                  ]
                )
                setC4R2VNo(8); 
                setC4R3VNo(8); 
              } else if (c4r2MT === "L") { 
                setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
                setC4R1VNo(8);
                setC4R2VNo(8);
                setC4R3VNo(8);
                setC4R4VNo(8);
              } 
      } else if (curX === col04X && curY === row02X && c4R2VNo === 7) {
        alert("Already added");  
      } else if (curX === col04X && curY === row02X && c4R2VNo === 8) {
        alert("Already added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 9) {
        alert("Already added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
        alert("Already added");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
        alert("Already added");
      }  else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
        if (c5r2MT === "S") {
        setRow2Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(4);
            } else if (c5r2MT === "M" && c5r1MT === "M") { 
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(4);
              setC5R2VNo(4);
            } else if (c5r2MT === "M" && c5r3MT === "M") { 
              setRow2NakedM05(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col05X,row02X,0.5]} />
                ]
              )
              setC5R2VNo(4);
              setC5R3VNo(4);
            } else if (c5r2MT === "L") { 
              setBaseNakedL05(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                ]
              )
              setC5R1VNo(4);
              setC5R2VNo(4);
              setC5R3VNo(4);
              setC5R4VNo(4);
            }
        } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
          if (c5r2MT === "S") {
            setRow2Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(7);    
                } else if (c5r2MT === "M" && c5r1MT === "M") { 
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(7);   
                  setC5R2VNo(7); 
                } else if (c5r2MT === "M" && c5r3MT === "M") { 
                  setRow2NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(7);   
                  setC5R3VNo(7);  
                } else if (c5r2MT === "L") { 
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(7);
                  setC5R2VNo(7);
                  setC5R3VNo(7);
                  setC5R4VNo(7);
                }
        } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
          if (c5r2MT === "S") {
          setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(5);
        } else if (c5r2MT === "M" && c5r1MT === "M") { 
          setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
        } else if (c5r2MT === "M" && c5r3MT === "M") { 
          setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(5);
          setC5R3VNo(5);
        } else if (c5r2MT === "L") { 
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(5);
          setC5R2VNo(5);
          setC5R3VNo(5);
          setC5R4VNo(5);
        }
      
        } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
          alert("Already added"); 
        } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {  
          alert("Already added");
        } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {
          if (c5r2MT === "S") {
            setRow2Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(8);  
                } else if (c5r2MT === "M" && c5r1MT === "M") { 
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(8); 
                  setC5R2VNo(8); 
                } else if (c5r2MT === "M" && c5r3MT === "M") { 
                  setRow2NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(8);    
                  setC5R3VNo(8);  
                } else if (c5r2MT === "L") { 
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(8);
                  setC5R2VNo(8);
                  setC5R3VNo(8);
                  setC5R4VNo(8);
                }   
        } else if (curX === col05X && curY === row02X && c5R2VNo === 7) {
          alert("Already added");  
        } else if (curX === col05X && curY === row02X && c5R2VNo === 8) {
          alert("Already added");
        } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
          alert("Already added");
        } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
          alert("Already added");
        } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
          alert("Already added");
        }  else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
          if (c6r2MT === "S") {
          setRow2Naked06(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
                  ]
                )
                setC6R2VNo(4);
              } else if (c6r2MT === "M" && c6r1MT === "M") { 
                setBaseNakedM06(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col06X,row01X,0.5]} />
                  ]
                )
                setC6R1VNo(4);
                setC6R2VNo(4);
              } else if (c6r2MT === "M" && c6r3MT === "M") { 
                setRow2NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col06X,row02X,0.5]} />
                  ]
                )
                setC6R2VNo(4);
                setC6R3VNo(4);
              } else if (c6r2MT === "L") { 
                setBaseNakedL06(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                  ]
                )
                setC6R1VNo(4);
                setC6R2VNo(4);
                setC6R3VNo(4);
                setC6R4VNo(4);
              }
          } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
            if (c6r2MT === "S") {
              setRow2Naked06(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
                      ]
                    )
                    setC6R2VNo(7);    
                  } else if (c6r2MT === "M" && c6r1MT === "M") { 
                    setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(7);    
                    setC6R2VNo(7); 
                  } else if (c6r2MT === "M" && c6r3MT === "M") { 
                    setRow2NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                      ]
                    )
                    setC6R2VNo(7);    
                    setC6R3VNo(7); 
                  } else if (c6r2MT === "L") { 
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(7);
                    setC6R2VNo(7);
                    setC6R3VNo(7);
                    setC6R4VNo(7);
                  }
          } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
            if (c6r2MT === "S") {
            setRow2Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(5);
          } else if (c6r2MT === "M" && c6r1MT === "M") { 
            setBaseNakedM06(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
          } else if (c6r2MT === "M" && c6r3MT === "M") { 
            setRow2NakedM06(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
              ]
            )
            setC6R2VNo(5);
            setC6R3VNo(5);
          } else if (c6r2MT === "L") { 
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
            setC6R1VNo(5);
            setC6R2VNo(5);
            setC6R3VNo(5);
            setC6R4VNo(5);
          }

          } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
            alert("Already added"); 
          } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {  
            alert("Already added");
          } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {
            if (c6r2MT === "S") {
              setRow2Naked06(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                      ]
                    )
                    setC6R2VNo(8);   
                  } else if (c6r2MT === "M" && c6r1MT === "M") { 
                    setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(8);     
                    setC6R2VNo(8);
                  } else if (c6r2MT === "M" && c6r3MT === "M") { 
                    setRow2NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                      ]
                    )
                    setC6R2VNo(8); 
                    setC6R3VNo(8);
                  } else if (c6r2MT === "L") { 
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
                    setC6R1VNo(8);
                    setC6R2VNo(8);
                    setC6R3VNo(8);
                    setC6R4VNo(8);
                  }
          } else if (curX === col06X && curY === row02X && c6R2VNo === 7) {
            alert("Already added");  
          } else if (curX === col06X && curY === row02X && c6R2VNo === 8) {
            alert("Already added");
          } else if (curX === col06X && curY === row02X && c6R2VNo === 9) {
            alert("Already added");
          } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
            alert("Already added");
          } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
            alert("Already added");
          }  else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
            if (c7r2MT === "S") {
            setRow2Naked07(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
                    ]
                  )
                  setC7R2VNo(4);
                } else if (c7r2MT === "M" && c7r1MT === "M") { 
                  setBaseNakedM07(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(4);
                  setC7R2VNo(4);
                } else if (c7r2MT === "M" && c7r3MT === "M") { 
                  setRow2NakedM07(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col07X,row02X,0.5]} />
                    ]
                  )
                  setC7R2VNo(4);
                  setC7R3VNo(4);
                } else if (c6r2MT === "L") { 
                  setBaseNakedL07(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(4);
                  setC7R2VNo(4);
                  setC7R3VNo(4);
                  setC7R4VNo(4);
                }
            } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
              if (c7r2MT === "S") {
                setRow2Naked07(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
                        ]
                      )
                      setC7R2VNo(7); 
                    } else if (c7r2MT === "M" && c7r1MT === "M") { 
                      setBaseNakedM07(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
                      setC7R1VNo(7);    
                      setC7R2VNo(7);
                    } else if (c7r2MT === "M" && c7r3MT === "M") { 
                      setRow2NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                        ]
                      )
                      setC7R2VNo(7); 
                      setC7R3VNo(7); 
                    } else if (c7r2MT === "L") { 
                      setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
                      setC7R1VNo(7);
                      setC7R2VNo(7);
                      setC7R3VNo(7);
                      setC7R4VNo(7);
                    }  
            } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
              if (c7r2MT === "S") {
              setRow2Naked07(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(5);
            } else if (c7r2MT === "M" && c7r1MT === "M") { 
              setBaseNakedM07(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(5);
              setC7R2VNo(5);
            } else if (c7r2MT === "M" && c7r3MT === "M") { 
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(5);
              setC7R3VNo(5);
            } else if (c7r2MT === "L") { 
              setBaseNakedL07(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                ]
              )
              setC7R1VNo(5);
              setC7R2VNo(5);
              setC7R3VNo(5);
              setC7R4VNo(5);
            }
             
            } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
              alert("Already added");  
            } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {  
              alert("Already added");
            } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {
              if (c7r2MT === "S") {
                setRow2Naked07(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                        ]
                      )
                      setC7R2VNo(8);   
                    } else if (c7r2MT === "M" && c7r1MT === "M") { 
                      setBaseNakedM07(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
                      setC7R1VNo(8);   
                      setC7R2VNo(8); 
                    } else if (c7r2MT === "M" && c7r3MT === "M") { 
                      setRow2NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                        ]
                      )
                      setC7R2VNo(8);   
                      setC7R3VNo(8); 
                    } else if (c7r2MT === "L") { 
                      setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
                      setC7R1VNo(8);
                      setC7R2VNo(8);
                      setC7R3VNo(8);
                      setC7R4VNo(8);
                    }  
            } else if (curX === col07X && curY === row02X && c7R2VNo === 7) {
              alert("Already added");  
            } else if (curX === col07X && curY === row02X && c7R2VNo === 8) {
              alert("Already added");
            } else if (curX === col07X && curY === row02X && c7R2VNo === 9) {
              alert("Already added");
            } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
              alert("Already added");
            } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
              alert("Already added");
            }  else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
              if (c8r2MT === "S") {
              setRow2Naked08(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                      ]
                    )
                    setC8R2VNo(4);
                  } else if (c8r2MT === "M" && c8r1MT === "M") { 
                    setBaseNakedM08(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(4);
                    setC8R2VNo(4);
                  } else if (c8r2MT === "M" && c8r3MT === "M") { 
                    setRow2NakedM08(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col08X,row02X,0.5]} />
                      ]
                    )
                    setC8R2VNo(4);
                    setC8R3VNo(4);
                  } else if (c8r2MT === "L") { 
                    setBaseNakedL08(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(4);
                    setC8R2VNo(4);
                    setC8R3VNo(4);
                    setC8R4VNo(4);
                  }
              } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
                if (c8r2MT === "S") {
                  setRow2Naked08(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
                          ]
                        )
                        setC8R2VNo(7);   
                      } else if (c8r2MT === "M" && c8r1MT === "M") { 
                        setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                        setC8R1VNo(7);    
                        setC8R2VNo(7);  
                      } else if (c8r2MT === "M" && c8r3MT === "M") { 
                        setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                          ]
                        )
                        setC8R2VNo(7);  
                        setC8R2VNo(7);
                      } else if (c8r2MT === "L") { 
                        setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                        setC8R1VNo(7);
                        setC8R2VNo(7);
                        setC8R3VNo(7);
                        setC8R4VNo(7);
                      }
              } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
                if (c8r2MT === "S") {
                setRow2Naked08(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                  ]
                )
                setC8R2VNo(5);
              } else if (c8r2MT === "M" && c8r1MT === "M") { 
                setBaseNakedM08(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                  ]
                )
                setC8R1VNo(5);
                setC8R2VNo(5);
              } else if (c8r2MT === "M" && c8r3MT === "M") { 
                setRow2NakedM08(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                  ]
                )
                setC8R2VNo(5);
                setC8R3VNo(5);
              } else if (c8r2MT === "L") { 
                setBaseNakedL08(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                  ]
                )
                setC8R1VNo(5);
                setC8R2VNo(5);
                setC8R3VNo(5);
                setC8R4VNo(5);
              }
              } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
                alert("Already added");  
              } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {  
                alert("Already added");
              } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {
                if (c8r2MT === "S") {
                  setRow2Naked08(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                          ]
                        )
                        setC8R2VNo(8);  
                      } else if (c8r2MT === "M" && c8r1MT === "M") { 
                        setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                        setC8R1VNo(8); 
                        setC8R2VNo(8); 
                      } else if (c8r2MT === "M" && c8r3MT === "M") { 
                        setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                          ]
                        )
                        setC8R2VNo(8); 
                        setC8R3VNo(8); 
                      } else if (c8r2MT === "L") { 
                        setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                        setC8R1VNo(8);
                        setC8R2VNo(8);
                        setC8R3VNo(8);
                        setC8R4VNo(8);
                      }   
              } else if (curX === col08X && curY === row02X && c8R2VNo === 7) {
                alert("Already added");  
              } else if (curX === col08X && curY === row02X && c8R2VNo === 8) {
                alert("Already added");
              } else if (curX === col08X && curY === row02X && c8R2VNo === 9) {
                alert("Already added");
              } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
                alert("Already added");
              } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
                alert("Already added");
              }  else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
                if (c9r2MT === "S") {
                setRow2Naked09(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
                        ]
                      )
                      setC9R2VNo(4);
                    } else if (c9r2MT === "M" && c9r1MT === "M") { 
                      setBaseNakedM09(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col09X,row01X,0.5]} />
                        ]
                      )
                      setC9R1VNo(4);
                      setC9R2VNo(4);
                    } else if (c9r2MT === "M" && c9r3MT === "M") { 
                      setRow2NakedM09(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col09X,row02X,0.5]} />
                        ]
                      )
                      setC9R2VNo(4);
                      setC9R3VNo(4);
                    } else if (c9r2MT === "L") { 
                      setBaseNakedL09(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                        ]
                      )
                      setC9R1VNo(4);
                      setC9R2VNo(4);
                      setC9R3VNo(4);
                      setC9R4VNo(4);
                    }
                } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
                  if (c9r2MT === "S") {
                    setRow2Naked09(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                          setC9R2VNo(7);  
                        } else if (c9r2MT === "M" && c9r1MT === "M") { 
                          setBaseNakedM09(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(7); 
                          setC9R2VNo(7);
                        } else if (c9r2MT === "M" && c9r3MT === "M") { 
                          setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                          setC9R2VNo(7);    
                          setC9R2VNo(7);
                        } else if (c9r2MT === "L") { 
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(7);
                          setC9R2VNo(7);
                          setC9R3VNo(7);
                          setC9R4VNo(7);
                        }  
                } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
                  if (c9r2MT === "S") {
                  setRow2Naked09(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                    ]
                  )
                  setC9R2VNo(5);
                } else if (c9r2MT === "M" && c9r1MT === "M") { 
                  setBaseNakedM09(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(5);
                  setC9R2VNo(5);
                } else if (c9r2MT === "M" && c9r3MT === "M") { 
                  setRow2NakedM09(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                    ]
                  )
                  setC9R2VNo(5);
                  setC9R3VNo(5);
                } else if (c9r2MT === "L") { 
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(5);
                  setC9R2VNo(5);
                  setC9R3VNo(5);
                  setC9R4VNo(5);
                }
                  
                } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
                  alert("Already added");  
                } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {  
                  alert("Already added");
                } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {
                  if (c9r2MT === "S") {
                    setRow2Naked09(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                          setC9R2VNo(8);   
                        } else if (c9r2MT === "M" && c9r1MT === "M") { 
                          setBaseNakedM09(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(8); 
                          setC9R2VNo(8); 
                        } else if (c9r2MT === "M" && c9r3MT === "M") { 
                          setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                          setC9R2VNo(8); 
                          setC9R3VNo(8); 
                        } else if (c9r2MT === "L") { 
                          setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                          setC9R1VNo(8);
                          setC9R2VNo(8);
                          setC9R3VNo(8);
                          setC9R4VNo(8);
                        }  
                } else if (curX === col09X && curY === row02X && c9R2VNo === 7) {
                  alert("Already added");  
                } else if (curX === col09X && curY === row02X && c9R2VNo === 8) {
                  alert("Already added");
                } else if (curX === col09X && curY === row02X && c9R2VNo === 9) {
                  alert("Already added");
                } else if (curX === col09X && curY === row02X && c9R2VNo === 10) {
                  alert("Already added");
                } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
                  alert("Already added");
                }  else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
                  if (c10r2MT === "S") {
                  setRow2Naked10(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                          ]
                        )
                        setC10R2VNo(4);
                      } else if (c10r2MT === "M" && c10r1MT === "M") { 
                        setBaseNakedM10(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col10X,row01X,0.5]} />
                          ]
                        )
                        setC10R1VNo(4);
                        setC10R2VNo(4);
                      } else if (c10r2MT === "M" && c10r3MT === "M") { 
                        setRow2NakedM10(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col10X,row02X,0.5]} />
                          ]
                        )
                        setC10R2VNo(4);
                        setC10R3VNo(4);
                      } else if (c10r2MT === "L") { 
                        setBaseNakedL10(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                          ]
                        )
                        setC10R1VNo(4);
                        setC10R2VNo(4);
                        setC10R3VNo(4);
                        setC10R4VNo(4);
                      }
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
                    if (c10r2MT === "S") {
                      setRow2Naked10(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                            setC10R2VNo(7);   
                          } else if (c10r2MT === "M" && c10r1MT === "M") { 
                            setBaseNakedM10(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                          } else if (c10r2MT === "M" && c10r3MT === "M") { 
                            setRow2NakedM10(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                            setC10R2VNo(7);  
                            setC10R2VNo(7);
                          } else if (c10r2MT === "L") { 
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(7);
                            setC10R2VNo(7);
                            setC10R3VNo(7);
                            setC10R4VNo(7);
                          } 
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
                    if (c10r2MT === "S") {
                    setRow2Naked10(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                      ]
                    )
                    setC10R2VNo(5);

                  } else if (c10r2MT === "M" && c10r1MT === "M") { 
                      setBaseNakedM10(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                      ]
                                    )
                                    setC10R1VNo(5);
                                    setC10R2VNo(5);
                  } else if (c10r2MT === "M" && c10r3MT === "M") { 
                    setRow2NakedM10(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                      ]
                    )
                    setC10R2VNo(5);
                    setC10R3VNo(5);
                  } else if (c10r2MT === "L") { 
                    setBaseNakedL10(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(5);
                    setC10R2VNo(5);
                    setC10R3VNo(5);
                    setC10R4VNo(5);
                  }

                  } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
                    alert("Already added");  
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {  
                    alert("Already added");
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 6) {
                    if (c10r2MT === "S") {
                      setRow2Naked10(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                            setC10R2VNo(8);   
                          } else if (c10r2MT === "M" && c10r1MT === "M") { 
                            setBaseNakedM10(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(8); 
                            setC10R2VNo(8);  
                          } else if (c10r2MT === "M" && c10r3MT === "M") { 
                            setRow2NakedM10(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                            setC10R2VNo(8);  
                            setC10R3VNo(8); 
                          } else if (c10r2MT === "L") { 
                            setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(8);
                            setC10R2VNo(8);
                            setC10R3VNo(8);
                            setC10R4VNo(8);
                          }  
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 7) {
                    alert("Already added");  
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 8) {
                    alert("Already added");
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 9) {
                    alert("Already added");
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 10) {
                    alert("Already added");
                  } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
                    alert("Already added");
                  }  else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
                    if (c11r2MT === "S") {
                    setRow2Naked11(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                            ]
                          )
                          setC11R2VNo(4);
                        } else if (c11r2MT === "M" && c11r1MT === "M") { 
                          setBaseNakedM11(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(4);
                          setC11R2VNo(4);
                        } else if (c11r2MT === "M" && c11r3MT === "M") { 
                          setRow2NakedM11(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col11X,row02X,0.5]} />
                            ]
                          )
                          setC11R2VNo(4);
                          setC11R3VNo(4);
                        } else if (c11r2MT === "L") { 
                          setBaseNakedL11(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(4);
                          setC11R2VNo(4);
                          setC11R3VNo(4);
                          setC11R4VNo(4);
                        }
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
                      if (c11r2MT === "S") {
                        setRow2Naked11(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                              setC11R2VNo(7); 
                            } else if (c11r2MT === "M" && c11r1MT === "M") { 
                              setBaseNakedM11(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(7);   
                              setC11R2VNo(7); 
                            } else if (c11r2MT === "M" && c11r3MT === "M") { 
                              setRow2NakedM11(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                              setC11R2VNo(7);   
                              setC11R2VNo(7);
                            } else if (c11r2MT === "L") { 
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(7);
                              setC11R2VNo(7);
                              setC11R3VNo(7);
                              setC11R4VNo(7);
                            }   
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
                      if (c11r2MT === "S") {
                      setRow2Naked11(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                        ]
                      )
                      setC11R2VNo(5);
                    } else if (c11r2MT === "M" && c11r1MT === "M") { 
                      setBaseNakedM11(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                        ]
                      )
                      setC11R1VNo(5);
                      setC11R2VNo(5);
                    } else if (c11r2MT === "M" && c11r3MT === "M") { 
                      setRow2NakedM11(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                        ]
                      )
                      setC11R2VNo(5);
                      setC11R3VNo(5);
                    } else if (c11r2MT === "L") { 
                      setBaseNakedL11(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                        ]
                      )
                      setC11R1VNo(5);
                      setC11R2VNo(5);
                      setC11R3VNo(5);
                      setC11R4VNo(5);
                    }

                    } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
                      alert("Already added"); 
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {  
                      alert("Already added");
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {
                      if (c11r2MT === "S") {
                        setRow2Naked11(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                              setC11R2VNo(8);     
                            } else if (c11r2MT === "M" && c11r1MT === "M") { 
                              setBaseNakedM11(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(8); 
                              setC11R2VNo(8);
                            } else if (c11r2MT === "M" && c11r3MT === "M") { 
                              setRow2NakedM11(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                              setC11R2VNo(8); 
                              setC11R3VNo(8);   
                            } else if (c11r2MT === "L") { 
                              setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(8);
                              setC11R2VNo(8);
                              setC11R3VNo(8);
                              setC11R4VNo(8);
                            }
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 7) {
                      alert("Already added");  
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 8) {
                      alert("Already added");
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 9) {
                      alert("Already added");
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
                      alert("Already added");
                    } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
                      alert("Already added");
                    }  else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
                      if (c12r2MT === "S") {
                      setRow2Naked12(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                              ]
                            )
                            setC12R2VNo(4);
                          } else if (c12r2MT === "M" && c12r1MT === "M") { 
                            setBaseNakedM12(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(4);
                            setC12R2VNo(4);
                          } else if (c12r2MT === "M" && c12r3MT === "M") { 
                            setRow2NakedM12(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col12X,row02X,0.5]} />
                              ]
                            )
                            setC12R2VNo(4);
                            setC12R3VNo(4);
                          } else if (c12r2MT === "L") { 
                            setBaseNakedL12(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(4);
                            setC12R2VNo(4);
                            setC12R3VNo(4);
                            setC12R4VNo(4);
                          }
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
                        if (c12r2MT === "S") {
                          setRow2Naked12(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                                setC12R2VNo(7);   
                              } else if (c12r2MT === "M" && c12r1MT === "M") { 
                                setRow2NakedM12(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                          ]
                                        )
                                        setC12R2VNo(7);   
                                setC12R2VNo(7); 
                              } else if (c12r2MT === "M" && c12r3MT === "M") { 
                                setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                                setC12R2VNo(7);   
                                setC12R2VNo(7);
                              } else if (c12r2MT === "L") { 
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(7);
                                setC12R2VNo(7);
                                setC12R3VNo(7);
                                setC12R4VNo(7);
                              }
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
                        if (c12r2MT === "S") {
                            setRow2Naked12(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                              ]
                            )
                            setC12R2VNo(5);
                          } else if (c12r2MT === "M" && c12r1MT === "M") { 
                            setBaseNakedM12(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(5);
                            setC12R2VNo(5);
                          } else if (c12r2MT === "M" && c12r3MT === "M") { 
                            setRow2NakedM12(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                              ]
                            )
                            setC12R2VNo(5);
                            setC12R3VNo(5);
                          } else if (c12r2MT === "L") { 
                            setBaseNakedL12(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                              ]
                            )
                            setC12R1VNo(5);
                            setC12R2VNo(5);
                            setC12R3VNo(5);
                            setC12R4VNo(5);
                          }

                      } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
                        alert("Already added");  
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {  
                        alert("Already added");
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {
                        if (c12r2MT === "S") {
                          setRow2Naked12(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                                setC12R2VNo(8);   
                              } else if (c12r2MT === "M" && c12r1MT === "M") { 
                                setBaseNakedM12(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(8);
                                setC12R2VNo(8);
                              } else if (c12r2MT === "M" && c12r3MT === "M") { 
                                setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                                setC12R2VNo(8); 
                                setC12R3VNo(8); 
                              } else if (c12r2MT === "L") { 
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                                setC12R1VNo(8);
                                setC12R2VNo(8);
                                setC12R3VNo(8);
                                setC12R4VNo(8);
                              }  
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 7) {
                        alert("Already added");  
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 8) {
                        alert("Already added");
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 9) {
                        alert("Already added");
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
                        alert("Already added");
                      } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
                        alert("Already added");
                      }  else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
                        if (c13r2MT === "S") {
                        setRow2Naked13(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                                ]
                              )
                              setC13R2VNo(4);
                            } else if (c13r2MT === "M" && c13r1MT === "M") { 
                              setBaseNakedM13(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(4);
                              setC13R2VNo(4);
                            } else if (c13r2MT === "M" && c13r3MT === "M") { 
                              setRow2NakedM13(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col13X,row02X,0.5]} />
                                ]
                              )
                              setC13R2VNo(4);
                              setC13R3VNo(4);
                            } else if (c13r2MT === "L") { 
                              setBaseNakedL13(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                ]
                              )
                              setC13R1VNo(4);
                              setC13R2VNo(4);
                              setC13R3VNo(4);
                              setC13R4VNo(4);
                            }
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
                          if (c13r2MT === "S") {
                            setRow2Naked13(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                                  setC13R2VNo(7);   
                                } else if (c13r2MT === "M" && c13r1MT === "M") { 
                                  setBaseNakedM13(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                                  setC13R1VNo(7); 
                                  setC13R1VNo(7); 
                                } else if (c13r2MT === "M" && c13r3MT === "M") { 
                                  setRow2NakedM13(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                                  setC13R2VNo(7);    
                                  setC13R2VNo(7);
                                } else if (c13r2MT === "L") { 
                                  setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                                  setC13R1VNo(7);
                                  setC13R2VNo(7);
                                  setC13R3VNo(7);
                                  setC13R4VNo(7);
                                } 
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
                          if (c13r2MT === "S") {
                          setRow2Naked13(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                            ]
                          )
                          setC13R2VNo(5);
                        } else if (c13r2MT === "M" && c13r1MT === "M") { 
                          setBaseNakedM13(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                            ]
                          )
                          setC13R1VNo(5);
                          setC13R1VNo(5);
                        } else if (c13r2MT === "M" && c13r3MT === "M") { 
                          setRow2NakedM13(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                            ]
                          )
                          setC13R2VNo(5);
                          setC13R3VNo(5);
                        } else if (c13r2MT === "L") { 
                          setBaseNakedL13(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                            ]
                          )
                          setC13R1VNo(5);
                          setC13R2VNo(5);
                          setC13R3VNo(5);
                          setC13R4VNo(5);
                        }
                         
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
                                         alert("Already added");  
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {  
                          alert("Already added");
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {
                          if (c13r2MT === "S") {
                            setRow2Naked13(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                                  setC13R2VNo(8);  
                                } else if (c13r2MT === "M" && c13r1MT === "M") { 
                                  setBaseNakedM13(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                                  setC13R1VNo(8);  
                                  setC13R2VNo(8);
                                } else if (c13r2MT === "M" && c13r3MT === "M") { 
                                  setRow2NakedM13(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                                  setC13R2VNo(8);  
                                  setC13R3VNo(8); 
                                } else if (c13r2MT === "L") { 
                                  setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                                  setC13R1VNo(8);
                                  setC13R2VNo(8);
                                  setC13R3VNo(8);
                                  setC13R4VNo(8);
                                }   
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 7) {
                          alert("Already added");  
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 8) {
                          alert("Already added");
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 9) {
                          alert("Already added");
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
                          alert("Already added");
                        } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
                          alert("Already added");
                        }  else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
                          if (c14r2MT === "S") {
                          setRow2Naked14(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
                                  ]
                                )
                                setC14R2VNo(4);
                              } else if (c14r2MT === "M" && c14r1MT === "M") { 
                                setBaseNakedM14(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col14X,row01X,0.5]} />
                                  ]
                                )
                                setC14R1VNo(4);
                                setC14R2VNo(4);
                              } else if (c14r2MT === "M" && c14r3MT === "M") { 
                                setRow2NakedM14(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col14X,row02X,0.5]} />
                                  ]
                                )
                                setC14R2VNo(4);
                                setC14R3VNo(4);
                              } else if (c14r2MT === "L") { 
                                setBaseNakedL14(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                  ]
                                )
                                setC14R1VNo(4);
                                setC14R2VNo(4);
                                setC14R3VNo(4);
                                setC14R4VNo(4);
                              }
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
                            if (c14r2MT === "S") {
                              setRow2Naked14(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                                    setC14R2VNo(7);    
                                  } else if (c14r2MT === "M" && c14r1MT === "M") { 
                                    setBaseNakedM14(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                                    setC14R1VNo(7);  
                                    setC14R2VNo(7); 
                                  } else if (c14r2MT === "M" && c14r3MT === "M") { 
                                    setRow2NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                                    setC14R2VNo(7);   
                                    setC14R3VNo(7);
                                  } else if (c14r2MT === "L") { 
                                    setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                                    setC14R1VNo(7);
                                    setC14R2VNo(7);
                                    setC14R3VNo(7);
                                    setC14R4VNo(7);
                                  }
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
                            if (c14r2MT === "S") {
                            setRow2Naked14(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                              ]
                            )
                            setC14R2VNo(5);
                          } else if (c14r2MT === "M" && c14r1MT === "M") { 
                            setBaseNakedM14(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(5);
                            setC14R2VNo(5);
                          } else if (c14r2MT === "M" && c14r3MT === "M") { 
                            setRow2NakedM14(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                              ]
                            )
                            setC14R2VNo(5);
                            setC14R3VNo(5);
                          } else if (c14r2MT === "L") { 
                            setBaseNakedL14(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                              ]
                            )
                            setC14R1VNo(5);
                            setC14R2VNo(5);
                            setC14R3VNo(5);
                            setC14R4VNo(5);
                          }
                            
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
                            alert("Already added");  
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {  
                            alert("Already added");
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {
                            if (c14r2MT === "S") {
                              setRow2Naked14(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                                    setC14R2VNo(8);   
                                  } else if (c14r2MT === "M" && c14r1MT === "M") { 
                                    setBaseNakedM14(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                                    setC14R1VNo(8); 
                                    setC14R2VNo(8); 
                                  } else if (c14r2MT === "M" && c14r3MT === "M") { 
                                    setRow2NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                                    setC14R2VNo(8); 
                                    setC14R3VNo(8); 
                                  } else if (c14r2MT === "L") { 
                                    setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                                    setC14R1VNo(8);
                                    setC14R2VNo(8);
                                    setC14R3VNo(8);
                                    setC14R4VNo(8);
                                  }  
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 7) {
                            alert("Already added");  
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 8) {
                            alert("Already added");
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 9) {
                            alert("Already added");
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
                            alert("Already removed");
                          } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
                            alert("Already added");
                          } 	else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
                            if (c1r3MT === "S") {
                            setRow3Naked01(
                                   [
                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
                                   ]
                                 )
                                 setC1R3VNo(4);
                                } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
                                  setRow2NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col01X,row02X,0.5]} />
                                    ]
                                  )
                                  setC1R2VNo(4);
                                  setC1R3VNo(4);
                                } else if (c1r3MT === "M" && c1r4MT === "M") { 
                                  setRow3NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                                  setC1R3VNo(4);
                                  setC1R4VNo(4);
                                } else if (c1r3MT === "L") { 
                                  setBaseNakedL01(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                    ]
                                  )
                                  setC1R1VNo(4);
                                  setC1R2VNo(4);
                                  setC1R3VNo(4);
                                  setC1R4VNo(4);
                                }
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
                                    if (c1r3MT === "S") {
                                          setRow3Naked01(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
                                            ]
                                          )
                                          setC1R3VNo(7);    
                                    } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
                                      setRow2NakedM01(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                        ]
                                      )
                                      setC1R2VNo(7);   
                                      setC1R3VNo(7); 
                                    } else if (c1r3MT === "M" && c1r4MT === "M") { 
                                      setRow3NakedM01(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                        ]
                                      )
                                      setC1R3VNo(7);   
                                      setC1R4VNo(7);
                                    } else if (c1r3MT === "L") { 
                                      setBaseNakedL01(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                        ]
                                      )
                                      setC1R1VNo(7);
                                      setC1R2VNo(7);
                                      setC1R3VNo(7);
                                      setC1R4VNo(7);
                                    }

                            } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
                              if (c1r3MT === "S") {
                                  setRow3Naked01(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                                  setC1R3VNo(5);
                                } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
                                  setRow2NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                    ]
                                  )
                                  setC1R2VNo(5);
                                  setC1R3VNo(5);
                                } else if (c1r3MT === "M" && c1r4MT === "M") { 
                                  setRow3NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                                  setC1R3VNo(5);
                                  setC1R4VNo(5);
                                } else if (c1r3MT === "L") { 
                                  setBaseNakedL01(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                    ]
                                  )
                                  setC1R1VNo(5);
                                  setC1R2VNo(5);
                                  setC1R3VNo(5);
                                  setC1R4VNo(5);
                                }
                             
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
                              alert("Already added");  
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {  
                              alert("Already added");
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {
                              if (c1r3MT === "S") {
                                setRow3Naked01(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                                        ]
                                      )
                                      setC1R3VNo(8);   
                                    } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
                                      setRow2NakedM01(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                        ]
                                      )
                                      setC1R2VNo(8); 
                                      setC1R3VNo(8); 
                                    } else if (c1r3MT === "M" && c1r4MT === "M") { 
                                      setRow3NakedM01(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                        ]
                                      )
                                      setC1R3VNo(8);  
                                      setC1R4VNo(8);
                                    } else if (c1r3MT === "L") {  
                                      setBaseNakedL01(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                        ]
                                      )
                                      setC1R1VNo(8);
                                      setC1R2VNo(8);
                                      setC1R3VNo(8);
                                      setC1R4VNo(8);
                                    }  
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 7) {
                              alert("Already added");  
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 8) {
                              alert("Already added");
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 9) {
                              alert("Already added");
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
                              alert("Already added");
                            } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
                              alert("Already added");
                            }  else if (curX === col02X && curY === row03X && c2R3VNo === 1) {
                              if (c2r3MT === "S") {
                              setRow3Naked02(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
                                     ]
                                   )
                                   setC2R3VNo(4);
                                  } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
                                    setRow2NakedM02(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col02X,row02X,0.5]} />
                                      ]
                                    )
                                    setC2R2VNo(4);
                                    setC2R3VNo(4);
                                  } else if (c2r3MT === "M" && c2r4MT === "M") { 
                                    setRow3NakedM02(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col02X,row03X,0.5]} />
                                      ]
                                    )
                                    setC2R3VNo(4);
                                    setC2R4VNo(4);
                                  } else if (c2r3MT === "L") { 
                                    setBaseNakedL02(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                      ]
                                    )
                                    setC2R1VNo(4);
                                    setC2R2VNo(4);
                                    setC2R3VNo(4);
                                    setC2R4VNo(4);
                                  }
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
                                if (c2r3MT === "S") {
                                  setRow3Naked02(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
                                          ]
                                        )
                                        setC2R3VNo(7);    
                                      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
                                        setRow2NakedM02(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                          ]
                                        )
                                        setC2R2VNo(7);  
                                        setC2R3VNo(7); 
                                      } else if (c2r3MT === "M" && c2r4MT === "M") { 
                                        setRow3NakedM02(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                          ]
                                        )
                                        setC2R3VNo(7); 
                                        setC2R4VNo(7); 
                                      } else if (c2r3MT === "L") {  
                                        setBaseNakedL02(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                          ]
                                        )
                                        setC2R1VNo(7);
                                        setC2R2VNo(7);
                                        setC2R3VNo(7);
                                        setC2R4VNo(7);
                                      }
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
                                if (c2r3MT === "S") {
                                  setRow3Naked02(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                                    ]
                                  )
                                  setC2R3VNo(5);
                                } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
                                  setRow2NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                    ]
                                  )
                                  setC2R2VNo(5);
                                  setC2R3VNo(5);
                                } else if (c2r3MT === "M" && c2r4MT === "M") { 
                                  setRow3NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                    ]
                                  )
                                  setC2R3VNo(5);
                                  setC2R4VNo(5);
                                } else if (c2r3MT === "L") { 
                                  setBaseNakedL02(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                    ]
                                  )
                                  setC2R1VNo(5);
                                  setC2R2VNo(5);
                                  setC2R3VNo(5);
                                  setC2R4VNo(5);
                                }
 
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
                                alert("Already added");  
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {  
                                alert("Already added");
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {
                                if (c2r3MT === "S") {
                                  setRow3Naked02(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                                          ]
                                        )
                                        setC2R3VNo(8);    
                                      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
                                        setRow2NakedM02(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                          ]
                                        )
                                        setC2R2VNo(8); 
                                        setC2R3VNo(8);   
                                      } else if (c2r3MT === "M" && c2r4MT === "M") { 
                                        setRow3NakedM02(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                          ]
                                        )
                                        setC2R3VNo(8);    
                                        setC2R4VNo(8); 
                                      } else if (c2r3MT === "L") { 
                                        setBaseNakedL02(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                          ]
                                        )
                                        setC2R1VNo(8);
                                        setC2R2VNo(8);
                                        setC2R3VNo(8);
                                        setC2R4VNo(8);
                                      } 
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 7) {
                                alert("Already added");  
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 8) {
                                alert("Already added");
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
                                alert("Already added");
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
                                alert("Already added");
                              } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
                                alert("Already added");
                              }  else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
                                if (c3r3MT === "S") {
                                setRow3Naked03(
                                       [
                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                                       ]
                                     )
                                     setC3R3VNo(4);
                                    } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
                                      setRow2NakedM03(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col03X,row02X,0.5]} />
                                        ]
                                      )
                                      setC3R2VNo(4);
                                      setC3R3VNo(4);
                                    } else if (c3r3MT === "M" && c3r4MT === "M") { 
                                      setRow3NakedM03(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col03X,row03X,0.5]} />
                                        ]
                                      )
                                      setC3R3VNo(4);
                                      setC3R4VNo(4);
                                    } else if (c3r3MT === "L") { 
                                      setBaseNakedL03(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                        ]
                                      )
                                      setC3R1VNo(4);
                                      setC3R2VNo(4);
                                      setC3R3VNo(4);
                                      setC3R4VNo(4);
                                    }
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
                                  if (c3r3MT === "S") {
                                    setRow3Naked03(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
                                            ]
                                          )
                                          setC3R3VNo(7);   
                                        } else if (c3r3MT === "M" && c3r2MT === "M"  && c3r4MT === "S") { 
                                          setRow2NakedM03(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                            ]
                                          )
                                          setC3R2VNo(7);  
                                          setC3R3VNo(7);
                                        } else if (c3r3MT === "M" && c3r4MT === "M") { 
                                          setRow3NakedM03(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                            ]
                                          )
                                          setC3R3VNo(7);   
                                          setC3R4VNo(7); 
                                        } else if (c3r3MT === "L") { 
                                          setBaseNakedL03(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                            ]
                                          )
                                          setC3R1VNo(7);
                                          setC3R2VNo(7);
                                          setC3R3VNo(7);
                                          setC3R4VNo(7);
                                        } 
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
                                  if (c3r3MT === "S") {
                                  setRow3Naked03(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                                    ]
                                  )
                                  setC3R3VNo(5);
                                } else if (c3r3MT === "M" && c3r2MT === "M"  && c3r4MT === "S") { 
                                  setRow2NakedM03(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                    ]
                                  )
                                  setC3R2VNo(5);
                                  setC3R3VNo(5);
                                } else if (c3r3MT === "M" && c3r4MT === "M") { 
                                  setRow3NakedM03(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                    ]
                                  )
                                  setC3R3VNo(5);
                                  setC3R4VNo(5);
                                } else if (c3r3MT === "L") { 
                                  setBaseNakedL03(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                    ]
                                  )
                                  setC3R1VNo(5);
                                  setC3R2VNo(5);
                                  setC3R3VNo(5);
                                  setC3R4VNo(5);
                                }
              
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
                                  alert("Already added");  
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {  
                                  alert("Already added");
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {
                                  if (c3r3MT === "S") {
                                    setRow3Naked03(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                                            ]
                                          )
                                          setC3R3VNo(8);   
                                        } else if (c3r3MT === "M" && c3r2MT === "M"  && c3r4MT === "S") { 
                                          setRow2NakedM03(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                            ]
                                          )
                                          setC3R2VNo(8);  
                                          setC3R3VNo(8);  
                                        } else if (c3r3MT === "M" && c3r4MT === "M") { 
                                          setRow3NakedM03(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                            ]
                                          )
                                          setC3R3VNo(8);   
                                          setC3R4VNo(8); 
                                        } else if (c3r3MT === "L") { 
                                          setBaseNakedL03(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                            ]
                                          )
                                          setC3R1VNo(8);
                                          setC3R2VNo(8);
                                          setC3R3VNo(8);
                                          setC3R4VNo(8);
                                        }  
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 7) {
                                  alert("Already added");  
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 8) {
                                  alert("Already added");
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 9) {
                                  alert("Already added");
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
                                  alert("Already added");
                                } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
                                  alert("Already added");
                                }  else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
                                  if (c4r3MT === "S") {
                                  setRow3Naked04(
                                         [
                                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
                                         ]
                                       )
                                       setC4R3VNo(4);
                                      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
                                        setRow2NakedM04(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col04X,row02X,0.5]} />
                                          ]
                                        )
                                        setC4R2VNo(4);
                                        setC4R3VNo(4);
                                      } else if (c4r3MT === "M" && c4r4MT === "M") { 
                                        setRow3NakedM04(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col04X,row03X,0.5]} />
                                          ]
                                        )
                                        setC4R3VNo(4);
                                        setC4R4VNo(4);
                                      } else if (c4r3MT === "L") { 
                                        setBaseNakedL04(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                          ]
                                        )
                                        setC4R1VNo(4);
                                        setC4R2VNo(4);
                                        setC4R3VNo(4);
                                        setC4R4VNo(4);
                                      }
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
                                    if (c4r3MT === "S") {
                                      setRow3Naked04(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
                                              ]
                                            )
                                            setC4R3VNo(7);   
                                          } else if (c4r3MT === "M" && c4r2MT === "M"  && c4r4MT === "S") { 
                                            setRow2NakedM04(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                              ]
                                            )
                                            setC4R2VNo(7); 
                                            setC4R3VNo(7); 
                                          } else if (c4r3MT === "M" && c4r4MT === "M") { 
                                            setRow3NakedM04(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                              ]
                                            )
                                            setC4R3VNo(7);    
                                            setC4R4VNo(7);   
                                          } else if (c4r3MT === "L") {
                                            setBaseNakedL04(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                              ]
                                            )
                                            setC4R1VNo(7);
                                            setC4R2VNo(7);
                                            setC4R3VNo(7);
                                            setC4R4VNo(7);
                                          } 
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
                                    if (c4r3MT === "S") {
                                    setRow3Naked04(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                                      ]
                                    )
                                    setC4R3VNo(5);
                                  } else if (c4r3MT === "M" && c4r2MT === "M"  && c4r4MT === "S") { 
                                    setRow2NakedM04(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                      ]
                                    )
                                    setC4R2VNo(5);
                                    setC4R3VNo(5);
                                  } else if (c4r3MT === "M" && c4r4MT === "M") { 
                                    setRow3NakedM04(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                      ]
                                    )
                                    setC4R3VNo(5);
                                    setC4R4VNo(5);
                                  } else if (c4r3MT === "L") {
                                    setBaseNakedL04(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                      ]
                                    )
                                    setC4R1VNo(5);
                                    setC4R2VNo(5);
                                    setC4R3VNo(5);
                                    setC4R4VNo(5);
                                  }
                                   
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
                                    alert("Already added");  
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {  
                                    alert("Already added");
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {
                                    if (c4r3MT === "S") {
                                      setRow3Naked04(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                                              ]
                                            )
                                            setC4R3VNo(8);   
                                          } else if (c4r3MT === "M" && c4r2MT === "M"  && c4r4MT === "S") { 
                                            setRow2NakedM04(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                              ]
                                            )
                                            setC4R2VNo(8); 
                                            setC4R3VNo(8); 
                                          } else if (c4r3MT === "M" && c4r4MT === "M") { 
                                            setRow3NakedM04(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                              ]
                                            )
                                            setC4R3VNo(8);  
                                            setC4R4VNo(8);  
                                          } else if (c4r3MT === "L") {
                                            setBaseNakedL04(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                              ]
                                            )
                                            setC4R1VNo(8);
                                            setC4R2VNo(8);
                                            setC4R3VNo(8);
                                            setC4R4VNo(8);
                                          }  
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 7) {
                                    alert("Already added");  
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 8) {
                                    alert("Already added");
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 9) {
                                    alert("Already added");
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
                                    alert("Already added");
                                  } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
                                    alert("Already added");
                                  }  else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
                                    if (c5r3MT === "S") {
                                    setRow3Naked05(
                                           [
                                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
                                           ]
                                         )
                                         setC5R3VNo(4);
                                        } else if (c5r3MT === "M" && c5r2MT === "M"  && c5r4MT === "S") { 
                                          setRow2NakedM05(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col05X,row02X,0.5]} />
                                            ]
                                          )
                                          setC5R2VNo(4);
                                          setC5R3VNo(4);
                                        } else if (c5r3MT === "M" && c5r4MT === "M") { 
                                          setRow3NakedM05(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col05X,row03X,0.5]} />
                                            ]
                                          )
                                          setC5R3VNo(4);
                                          setC5R4VNo(4);
                                        } else if (c5r3MT === "L") {
                                          setBaseNakedL05(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                            ]
                                          )
                                          setC5R1VNo(4);
                                          setC5R2VNo(4);
                                          setC5R3VNo(4);
                                          setC5R4VNo(4);
                                        }
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
                                      if (c5r3MT === "S") {
                                        setRow3Naked05(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
                                                ]
                                              )
                                              setC5R3VNo(7);    
                                            } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
                                              setRow2NakedM05(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                                ]
                                              )
                                              setC5R2VNo(7);   
                                              setC5R3VNo(7);  
                                            } else if (c5r3MT === "M" && c5r4MT === "M") { 
                                              setRow3NakedM05(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                ]
                                              )
                                              setC5R3VNo(7);  
                                              setC5R4VNo(7); 
                                            } else if (c5r3MT === "L") {
                                              setBaseNakedL05(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                ]
                                              )
                                              setC5R1VNo(7);
                                              setC5R2VNo(7);
                                              setC5R3VNo(7);
                                              setC5R4VNo(7);
                                            }
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
                                      if (c5r3MT === "S") {
                                      setRow3Naked05(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                                        ]
                                      )
                                      setC5R3VNo(5);
                                    } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
                                      setRow2NakedM05(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                        ]
                                      )
                                      setC5R2VNo(5);
                                      setC5R3VNo(5);
                                    } else if (c5r3MT === "M" && c5r4MT === "M") { 
                                      setRow3NakedM05(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                        ]
                                      )
                                      setC5R3VNo(5);
                                      setC5R4VNo(5);
                                    } else if (c5r3MT === "L") {
                                      setBaseNakedL05(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                        ]
                                      )
                                      setC5R1VNo(5);
                                      setC5R2VNo(5);
                                      setC5R3VNo(5);
                                      setC5R4VNo(5);
                                    }
 
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
                                      alert("Already added"); 
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {  
                                      alert("Already added");
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {
                                      if (c5r3MT === "S") {
                                        setRow3Naked05(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                                                ]
                                              )
                                              setC5R3VNo(8); 
                                            } else if (c5r3MT === "M" && c5r2MT === "M"  && c5r4MT === "S") { 
                                              setRow2NakedM05(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                                ]
                                              )
                                              setC5R2VNo(8);    
                                              setC5R3VNo(8);  
                                            } else if (c5r3MT === "M" && c5r4MT === "M") { 
                                              setRow3NakedM05(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                ]
                                              )
                                              setC5R3VNo(8); 
                                              setC5R4VNo(8); 
                                            } else if (c5r3MT === "L") {
                                              setBaseNakedL05(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                ]
                                              )
                                              setC5R1VNo(8);
                                              setC5R2VNo(8);
                                              setC5R3VNo(8);
                                              setC5R4VNo(8);
                                            }    
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 7) {
                                      alert("Already added");  
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 8) {
                                      alert("Already added");
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
                                      alert("Already added");
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
                                      alert("Already added");
                                    } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
                                      alert("Already added");
                                    }  else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
                                      if (c6r3MT === "S") {
                                      setRow3Naked06(
                                             [
                                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
                                             ]
                                           )
                                           setC6R3VNo(4);
                                          } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
                                            setRow2NakedM06(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col06X,row02X,0.5]} />
                                              ]
                                            )
                                            setC6R2VNo(4);
                                            setC6R3VNo(4);
                                          } else if (c6r3MT === "M" && c6r4MT === "M") { 
                                            setRow3NakedM06(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col06X,row03X,0.5]} />
                                              ]
                                            )
                                            setC6R3VNo(4);
                                            setC6R4VNo(4);
                                          } else if (c6r3MT === "L") {
                                            setBaseNakedL06(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                              ]
                                            )
                                            setC6R1VNo(4);
                                            setC6R2VNo(4);
                                            setC6R3VNo(4);
                                            setC6R4VNo(4);
                                          }
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
                                      if (c6r3MT === "S") {
                                          setRow3Naked06(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC6R3VNo(7);  
                                              } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
                                                setRow2NakedM06(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                                  ]
                                                )
                                                setC6R2VNo(7);    
                                                setC6R3VNo(7); 
                                              } else if (c6r3MT === "M" && c6r4MT === "M") { 
                                                setRow3NakedM06(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC6R3VNo(7);  
                                                setC6R4VNo(7);  
                                              } else if (c6r3MT === "L") {
                                                setBaseNakedL06(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC6R1VNo(7);
                                                setC6R2VNo(7);
                                                setC6R3VNo(7);
                                                setC6R4VNo(7);
                                              }  
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
                                      if (c6r3MT === "S") {
                                        setRow3Naked06(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                                          ]
                                        )
                                        setC6R3VNo(5);
                                      } else if (c6r3MT === "M" && c6r2MT === "M"  && c6r4MT === "S") { 
                                        setRow2NakedM06(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                          ]
                                        )
                                        setC6R2VNo(5);
                                        setC6R3VNo(5);
                                      } else if (c6r3MT === "M" && c6r4MT === "M") { 
                                        setRow3NakedM06(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                          ]
                                        )
                                        setC6R3VNo(5);
                                        setC6R4VNo(5);
                                      } else if (c6r3MT === "L") {
                                        setBaseNakedL06(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                          ]
                                        )
                                        setC6R1VNo(5);
                                        setC6R2VNo(5);
                                        setC6R3VNo(5);
                                        setC6R4VNo(5);
                                      }
                                        
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
                                        alert("Already added");  
                                  
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {  
                                        alert("Already added");
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {
                                      if (c6r3MT === "S") {

                                          setRow3Naked06(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC6R3VNo(8);   
                                              } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
                                                setRow2NakedM06(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                                  ]
                                                )
                                                setC6R2VNo(8); 
                                                setC6R3VNo(8);
                                              } else if (c6r3MT === "M" && c6r4MT === "M") { 
                                                setRow3NakedM06(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC6R3VNo(8);
                                                setC6R4VNo(8);
                                              } else if (c6r3MT === "L") {
                                                setBaseNakedL06(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC6R1VNo(8);
                                                setC6R2VNo(8);
                                                setC6R3VNo(8);
                                                setC6R4VNo(8);
                                              }  
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 7) {
                                        alert("Already added");  
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 8) {
                                        alert("Already added");
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 9) {
                                        alert("Already added");
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
                                        alert("Already added");
                                      } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
                                        alert("Already added");
                                      }  else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
                                        if (c7r3MT === "S") {
                                        setRow3Naked07(
                                               [
                                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
                                               ]
                                             )
                                             setC7R3VNo(4);
                                            } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
                                              setRow2NakedM07(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col07X,row02X,0.5]} />
                                                ]
                                              )
                                              setC7R2VNo(4);
                                              setC7R3VNo(4);
                                            } else if (c7r3MT === "M" && c7r4MT === "M") { 
                                              setRow3NakedM07(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col07X,row03X,0.5]} />
                                                ]
                                              )
                                              setC7R3VNo(4);
                                              setC7R4VNo(4);
                                            } else if (c7r3MT === "L") {
                                              setBaseNakedL07(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                ]
                                              )
                                              setC7R1VNo(4);
                                              setC7R2VNo(4);
                                              setC7R3VNo(4);
                                              setC7R4VNo(4);
                                            }
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
                                          if (c7r3MT === "S") {
                                            setRow3Naked07(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R3VNo(7);    
                                                } else if (c7r3MT === "M" && c7r2MT === "M"  && c7r4MT === "S") { 
                                                  setRow2NakedM07(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R2VNo(7); 
                                                  setC7R3VNo(7); 
                                                } else if (c7r3MT === "M" && c7r4MT === "M") { 
                                                  setRow3NakedM07(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R3VNo(7);
                                                  setC7R4VNo(7);
                                                } else if (c7r3MT === "L") {
                                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(7);
                                                  setC7R2VNo(7);
                                                  setC7R3VNo(7);
                                                  setC7R4VNo(7);
                                                }
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
                                          if (c7r3MT === "S") {
                                              setRow3Naked07(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                                                ]
                                              )
                                              setC7R3VNo(5);
                                            } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
                                              setRow2NakedM07(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                                ]
                                              )
                                              setC7R2VNo(5);
                                              setC7R3VNo(5);
                                            } else if (c7r3MT === "M" && c7r4MT === "M") { 
                                              setRow3NakedM07(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                ]
                                              )
                                              setC7R3VNo(5);
                                              setC7R4VNo(5);
                                            } else if (c7r3MT === "L") {
                                              setBaseNakedL07(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                ]
                                              )
                                              setC7R1VNo(5);
                                              setC7R2VNo(5);
                                              setC7R3VNo(5);
                                              setC7R4VNo(5);
                                            }
   
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
                                          alert("Already added");  
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {  
                                          alert("Already added");
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {
                                          if (c7r3MT === "S") {
                                            setRow3Naked07(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R3VNo(8); 
                                                } else if (c7r3MT === "M" && c7r2MT === "M"  && c7r4MT === "S") { 
                                                  setRow2NakedM07(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R2VNo(8);   
                                                  setC7R3VNo(8); 
                                                } else if (c7r3MT === "M" && c7r4MT === "M") { 
                                                  setRow3NakedM07(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R3VNo(8);  
                                                  setC7R4VNo(8);    
                                                } else if (c7r3MT === "L") {
                                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                                                }    
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 7) {
                                          alert("Already added");  
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 8) {
                                          alert("Already added");
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 9) {
                                          alert("Already added");
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
                                          alert("Already added");
                                        } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
                                          alert("Already added");
                                        }  else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
                                          if (c8r3MT === "S") {
                                          setRow3Naked08(
                                                 [
                                                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
                                                 ]
                                               )
                                               setC8R3VNo(4);
                                              } else if (c8r3MT === "M" && c8r2MT === "M"  && c8r4MT === "S") { 
                                                setRow2NakedM08(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col08X,row02X,0.5]} />
                                                  ]
                                                )
                                                setC8R2VNo(4);
                                                setC8R3VNo(4);
                                              } else if (c8r3MT === "M" && c8r4MT === "M") { 
                                                setRow3NakedM08(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col08X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC8R3VNo(4);
                                                setC8R4VNo(4);
                                              } else if (c8r3MT === "L") {
                                                setBaseNakedL08(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC8R1VNo(4);
                                                setC8R2VNo(4);
                                                setC8R3VNo(4);
                                                setC8R4VNo(4);
                                              }
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
                                            if (c8r3MT === "S") {
                                              setRow3Naked08(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R3VNo(7); 
                                                  } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
                                                    setRow2NakedM08(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R2VNo(7);  
                                                    setC8R2VNo(7);
                                                  } else if (c8r3MT === "M" && c8r4MT === "M") { 
                                                    setRow3NakedM08(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R3VNo(7);  
                                                    setC8R4VNo(7); 
                                                  } else if (c8r3MT === "L") {
                                                    setBaseNakedL08(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R1VNo(7);
                                                    setC8R2VNo(7);
                                                    setC8R3VNo(7);
                                                    setC8R4VNo(7);
                                                  }   
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
                                            if (c8r3MT === "S") {
                                            setRow3Naked08(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                                              ]
                                            )
                                            setC8R3VNo(5);
                                          } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
                                            setRow2NakedM08(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                              ]
                                            )
                                            setC8R2VNo(5);
                                            setC8R3VNo(5);
                                          } else if (c8r3MT === "M" && c8r4MT === "M") { 
                                            setRow3NakedM08(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                              ]
                                            )
                                            setC8R3VNo(5);
                                            setC8R4VNo(5);
                                          } else if (c8r3MT === "L") {
                                            setBaseNakedL08(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                              ]
                                            )
                                            setC8R1VNo(5);
                                            setC8R2VNo(5);
                                            setC8R3VNo(5);
                                            setC8R4VNo(5);
                                          }
                                            
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
                                            alert("Already added");  
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {  
                                            alert("Already added");
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {
                                            if (c8r3MT === "S") {
                                              setRow3Naked08(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R3VNo(8); 
                                                  } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
                                                    setRow2NakedM08(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R2VNo(8); 
                                                    setC8R3VNo(8); 
                                                  } else if (c8r3MT === "M" && c8r4MT === "M") { 
                                                    setRow3NakedM08(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R3VNo(8);  
                                                    setC8R4VNo(8); 
                                                  } else if (c8r3MT === "L") {
                                                    setBaseNakedL08(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R1VNo(8);
                                                    setC8R2VNo(8);
                                                    setC8R3VNo(8);
                                                    setC8R4VNo(8);
                                                  }    
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 7) {
                                            alert("Already added");  
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 8) {
                                            alert("Already added");
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 9) {
                                            alert("Already added");
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
                                            alert("Already added");
                                          } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
                                            alert("Already added");
                                          }  else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
                  if (c9r3MT === "S") {
                  setRow3Naked09(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                          ]
                        )
                        setC9R3VNo(4);
                      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
                        setRow2NakedM09(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col09X,row02X,0.5]} />
                          ]
                        )
                        setC9R2VNo(4);
                        setC9R3VNo(4);
                      } else if (c9r3MT === "M" && c9r4MT === "M") { 
                        setRow3NakedM09(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col09X,row03X,0.5]} />
                          ]
                        )
                        setC9R3VNo(4);
                        setC9R4VNo(4);
                      } else if (c9r3MT === "L") {
                        setBaseNakedL09(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                          ]
                        )
                        setC9R1VNo(4);
                        setC9R2VNo(4);
                        setC9R3VNo(4);
                        setC9R4VNo(4);
                      }
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
                    if (c9r3MT === "S") {
                      setRow3Naked09(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                            setC9R3VNo(7);   
                          } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
                            setRow2NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                              ]
                            )
                            setC9R2VNo(7);    
                            setC9R2VNo(7);
                          } else if (c9r3MT === "M" && c9r4MT === "M") { 
                            setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                            setC9R3VNo(7); 
                            setC9R4VNo(7); 
                          } else if (c9r3MT === "L") {
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(7);
                            setC9R2VNo(7);
                            setC9R3VNo(7);
                            setC9R4VNo(7);
                          } 
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
                    if (c9r3MT === "S") {
                    setRow3Naked09(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                      ]
                    )
                    setC9R3VNo(5);
                  } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
                    setRow2NakedM09(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                      ]
                    )
                    setC9R2VNo(5);
                    setC9R3VNo(5);
                  } else if (c9r3MT === "M" && c9r4MT === "M") { 
                    setRow3NakedM09(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                      ]
                    )
                    setC9R3VNo(5);
                    setC9R4VNo(5);
                  } else if (c9r3MT === "L") {
                    setBaseNakedL09(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(5);
                    setC9R2VNo(5);
                    setC9R3VNo(5);
                    setC9R4VNo(5);
                  }
              
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
                    alert("Already added");  
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {  
                    alert("Already added");
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {
                    if (c9r3MT === "S") {
                      setRow3Naked09(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                            setC9R3VNo(8);   
                          } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
                            setRow2NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                              ]
                            )
                            setC9R2VNo(8); 
                            setC9R3VNo(8); 
                          } else if (c9r3MT === "M" && c9r4MT === "M") { 
                            setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                            setC9R3VNo(8);  
                            setC9R4VNo(8); 
                          } else if (c9r3MT === "L") {
                            setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(8);
                            setC9R2VNo(8);
                            setC9R3VNo(8);
                            setC9R4VNo(8);
                          }  
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 7) {
                    alert("Already added");  
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 8) {
                    alert("Already added");
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 9) {
                    alert("Already added");
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 10) {
                    alert("Already added");
                  } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
                    alert("Already added");
                  }  else if (curX === col10X && curY === row03X && c10R3VNo === 1) {
                    if (c10r3MT === "S") {
                    setRow3Naked10(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                            ]
                          )
                          setC10R3VNo(4);
                        } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
                          setRow2NakedM10(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col10X,row02X,0.5]} />
                            ]
                          )
                          setC10R2VNo(4);
                          setC10R3VNo(4);
                        } else if (c10r3MT === "M" && c10r4MT === "M") { 
                          setRow3NakedM10(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col10X,row03X,0.5]} />
                            ]
                          )
                          setC10R3VNo(4);
                          setC10R4VNo(4);
                        } else if (c10r3MT === "L") {
                          setBaseNakedL10(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(4);
                          setC10R2VNo(4);
                          setC10R3VNo(4);
                          setC10R4VNo(4);
                        }
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
                      if (c10r3MT === "S") {
                        setRow3Naked10(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                              setC10R3VNo(7);   
                            } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
                              setRow2NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                ]
                              )
                              setC10R2VNo(7);  
                              setC10R2VNo(7);
                            } else if (c10r3MT === "M" && c10r4MT === "M") { 
                              setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                              setC10R3VNo(7);  
                              setC10R4VNo(7);  
                            } else if (c10r3MT === "L") {
                              setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                              setC10R1VNo(7);
                              setC10R2VNo(7);
                              setC10R3VNo(7);
                              setC10R4VNo(7);
                            } 
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
                      if (c10r3MT === "S") {
                      setRow3Naked10(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                        ]
                      )
                      setC10R3VNo(5);
                    } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
                      setRow2NakedM10(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                        ]
                      )
                      setC10R2VNo(5);
                      setC10R3VNo(5);
                    } else if (c10r3MT === "M" && c10r4MT === "M") { 
                      setRow3NakedM10(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                        ]
                      )
                      setC10R3VNo(5);
                      setC10R4VNo(5);
                    } else if (c10r3MT === "L") {
                      setBaseNakedL10(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                        ]
                      )
                      setC10R1VNo(5);
                      setC10R2VNo(5);
                      setC10R3VNo(5);
                      setC10R4VNo(5);
                    }
                     
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
                      alert("Already added");  
                
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {  
                      alert("Already added");
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 6) {
                      if (c10r3MT === "S") {
                        setRow3Naked10(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                              setC10R3VNo(8);  
                            } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
                              setRow2NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                ]
                              )
                              setC10R2VNo(8);  
                              setC10R3VNo(8); 
                            } else if (c10r3MT === "M" && c10r4MT === "M") { 
                              setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                              setC10R3VNo(8); 
                              setC10R4VNo(8); 
                            } else if (c10r3MT === "L") {
                              setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                              setC10R1VNo(8);
                              setC10R2VNo(8);
                              setC10R3VNo(8);
                              setC10R4VNo(8);
                            }   
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 7) {
                      alert("Already added");  
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 8) {
                      alert("Already added");
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 9) {
                      alert("Already added");
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 10) {
                      alert("Already added");
                    } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {
                      alert("Already added");
                    }  else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
                      if (c11r3MT === "S") {
                      setRow3Naked11(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                              ]
                            )
                            setC11R3VNo(4);
                          } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
                            setRow2NakedM11(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col11X,row02X,0.5]} />
                              ]
                            )
                            setC11R2VNo(4);
                            setC11R3VNo(4);
                          } else if (c11r3MT === "M" && c11r4MT === "M") { 
                            setRow3NakedM11(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col11X,row03X,0.5]} />
                              ]
                            )
                            setC11R3VNo(4);
                            setC11R4VNo(4);
                          } else if (c11r3MT === "L") {
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(4);
                            setC11R2VNo(4);
                            setC11R3VNo(4);
                            setC11R4VNo(4);
                          }
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
                        if (c11r3MT === "S") {
                          setRow3Naked11(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                                setC11R3VNo(7);   
                              } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
                                setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                                setC11R2VNo(7);   
                                setC11R2VNo(7);
                              } else if (c11r3MT === "M" && c11r4MT === "M") { 
                                setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                                setC11R3VNo(7);  
                                setC11R4VNo(7); 
                              } else if (c11r3MT === "L") {
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(7);
                                setC11R2VNo(7);
                                setC11R3VNo(7);
                                setC11R4VNo(7);
                              } 
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
                        if (c11r3MT === "S") {
                        setRow3Naked11(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                          ]
                        )
                        setC11R3VNo(5);
                      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
                        setRow2NakedM11(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                          ]
                        )
                        setC11R2VNo(5);
                        setC11R3VNo(5);
                      } else if (c11r3MT === "M" && c11r4MT === "M") { 
                        setRow3NakedM11(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                          ]
                        )
                        setC11R3VNo(5);
                        setC11R4VNo(5);
                      } else if (c11r3MT === "L") {
                        setBaseNakedL11(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                          ]
                        )
                        setC11R1VNo(5);
                        setC11R2VNo(5);
                        setC11R3VNo(5);
                        setC11R4VNo(5);
                      }
                       
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
                        alert("Already added");  
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {  
                        alert("Already added");
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {
                        if (c11r3MT === "S") {
                          setRow3Naked11(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                                setC11R3VNo(8);   
                              } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
                                setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                                setC11R2VNo(8); 
                                setC11R3VNo(8);   
                              } else if (c11r3MT === "M" && c11r4MT === "M") { 
                                setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                                setC11R3VNo(8);
                                setC11R4VNo(8);
                              } else if (c11r3MT === "L") {
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                                setC11R1VNo(8);
                                setC11R2VNo(8);
                                setC11R3VNo(8);
                                setC11R4VNo(8);
                              }  
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 7) {
                        alert("Already added");  
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 8) {
                        alert("Already added");
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 9) {
                        alert("Already added");
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
                        alert("Already added");
                      } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
                        alert("Already added");
                      }  else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
                        if (c12r3MT === "S") {
                          setRow3Naked12(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                                ]
                              )
                              setC12R3VNo(4);
                            } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
                              setRow2NakedM12(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col12X,row02X,0.5]} />
                                ]
                              )
                              setC12R2VNo(4);
                              setC12R3VNo(4);
                            } else if (c12r3MT === "M" && c12r4MT === "M") { 
                              setRow3NakedM12(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col12X,row03X,0.5]} />
                                ]
                              )
                              setC12R3VNo(4);
                              setC12R4VNo(4);
                            } else if (c12r3MT === "L") {
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(4);
                              setC12R2VNo(4);
                              setC12R3VNo(4);
                              setC12R4VNo(4);
                            }
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
                          if (c12r3MT === "S") {
                            setRow3Naked12(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                                  setC12R3VNo(7);    
                                } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
                                  setRow2NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                    ]
                                  )
                                  setC12R2VNo(7);   
                                  setC12R2VNo(7);
                                } else if (c12r3MT === "M" && c12r4MT === "M") { 
                                  setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                                  setC12R3VNo(7); 
                                  setC12R4VNo(7); 
                                } else if (c12r3MT === "L") {
                                  setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                                  setC12R1VNo(7);
                                  setC12R2VNo(7);
                                  setC12R3VNo(7);
                                  setC12R4VNo(7);
                                }
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
                          if (c12r3MT === "S") {
                          setRow3Naked12(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                            ]
                          )
                          setC12R3VNo(5); 
                        } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
                          setRow2NakedM12(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                            ]
                          )
                          setC12R2VNo(5);
                          setC12R3VNo(5);
                        } else if (c12r3MT === "M" && c12r4MT === "M") { 
                          setRow3NakedM12(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                            ]
                          )
                          setC12R3VNo(5);
                          setC12R4VNo(5);
                        } else if (c12r3MT === "L") {
                          setBaseNakedL12(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(5);
                          setC12R2VNo(5);
                          setC12R3VNo(5);
                          setC12R4VNo(5);
                        }
                    
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
                          alert("Already added"); 
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {  
                          alert("Already added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {
                          if (c12r3MT === "S") {
                            setRow3Naked12(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                                  setC12R3VNo(8);  
                                } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
                                  setRow2NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                    ]
                                  )
                                  setC12R2VNo(8); 
                                  setC12R3VNo(8); 
                                } else if (c12r3MT === "M" && c12r4MT === "M") { 
                                  setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                                  setC12R3VNo(8);  
                                  setC12R4VNo(8); 
                                } else if (c12r3MT === "L") {
                                  setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                                  setC12R1VNo(8);
                                  setC12R2VNo(8);
                                  setC12R3VNo(8);
                                  setC12R4VNo(8);
                                }   
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 7) {
                          alert("Already added");  
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 8) {
                          alert("Already added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 9) {
                          alert("Already added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
                          alert("Already added");
                        } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
                          alert("Already added");
                        }  else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
                          if (c13r3MT === "S") {
                          setRow3Naked13(
                                  [
                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                                  ]
                                )
                                setC13R3VNo(4);
                              } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
                                setRow2NakedM13(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col13X,row02X,0.5]} />
                                  ]
                                )
                                setC13R2VNo(4);
                                setC13R3VNo(4);
                              } else if (c13r3MT === "M" && c13r4MT === "M") { 
                                setRow3NakedM13(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col13X,row03X,0.5]} />
                                  ]
                                )
                                setC13R3VNo(4);
                                setC13R4VNo(4);
                              } else if (c13r3MT === "L") {
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(4);
                                setC13R2VNo(4);
                                setC13R3VNo(4);
                                setC13R4VNo(4);
                              }
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
                            if (c13r3MT === "S") {
                              setRow3Naked13(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                                    setC13R3VNo(7);  
                                  } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
                                    setRow2NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                      ]
                                    )
                                    setC13R2VNo(7);    
                                    setC13R2VNo(7);
                                  } else if (c13r3MT === "M" && c13r4MT === "M") { 
                                    setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                                    setC13R3VNo(7);
                                    setC13R4VNo(7);
                                  } else if (c13r3MT === "L") {
                                    setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                                    setC13R1VNo(7);
                                    setC13R2VNo(7);
                                    setC13R3VNo(7);
                                    setC13R4VNo(7);
                                  }  
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
                            if (c13r3MT === "S") {
                            setRow3Naked13(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                              ]
                            )
                            setC13R3VNo(5);
                          } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
                            setRow2NakedM13(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                              ]
                            )
                            setC13R2VNo(5);
                            setC13R3VNo(5);
                          } else if (c13r3MT === "M" && c13r4MT === "M") { 
                            setRow3NakedM13(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                              ]
                            )
                            setC13R3VNo(5);
                            setC13R4VNo(5);
                          } else if (c13r3MT === "L") {
                            setBaseNakedL13(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                            )
                            setC13R1VNo(5);
                            setC13R2VNo(5);
                            setC13R3VNo(5);
                            setC13R4VNo(5);
                          }
                            
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
                            alert("Already added"); 
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {  
                            alert("Already added");
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {
                            if (c13r3MT === "S") {
                              setRow3Naked13(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                                    setC13R3VNo(8);   
                                  } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
                                    setRow2NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                      ]
                                    )
                                    setC13R2VNo(8);  
                                    setC13R3VNo(8); 
                                  } else if (c13r3MT === "M" && c13r4MT === "M") { 
                                    setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                                    setC13R3VNo(8); 
                                    setC13R4VNo(8); 
                                  } else if (c13r3MT === "L") {
                                    setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                                    setC13R1VNo(8);
                                    setC13R2VNo(8);
                                    setC13R3VNo(8);
                                    setC13R4VNo(8);
                                  }  
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 7) {
                            alert("Already added");  
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 8) {
                            alert("Already added");
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 9) {
                            alert("Already added");
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
                            alert("Already added");
                          } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
                            alert("Already added");
                          }  else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
                            if (c14r3MT === "S") {
                            setRow3Naked14(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                                    ]
                                  )
                                  setC14R3VNo(4);
                                } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
                                  setRow2NakedM14(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col14X,row02X,0.5]} />
                                    ]
                                  )
                                  setC14R2VNo(4);
                                  setC14R3VNo(4);
                                } else if (c14r3MT === "M" && c14r4MT === "M") { 
                                  setRow3NakedM14(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col14X,row03X,0.5]} />
                                    ]
                                  )
                                  setC14R3VNo(4);
                                  setC14R4VNo(4);
                                } else if (c14r3MT === "L") {
                                  setBaseNakedL14(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                    ]
                                  )
                                  setC14R1VNo(4);
                                  setC14R2VNo(4);
                                  setC14R3VNo(4);
                                  setC14R4VNo(4);
                                }
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
                              if (c14r3MT === "S") {
                                setRow3Naked14(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                                      setC14R3VNo(7);   
                                    } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
                                      setRow2NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                        ]
                                      )
                                      setC14R2VNo(7);   
                                      setC14R3VNo(7);
                                    } else if (c14r3MT === "M" && c14r4MT === "M") { 
                                      setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                                      setC14R3VNo(7); 
                                      setC14R4VNo(7); 
                                    } else if (c14r3MT === "L") {
                                      setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                                      setC14R1VNo(7);
                                      setC14R2VNo(7);
                                      setC14R3VNo(7);
                                      setC14R4VNo(7);
                                    } 
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
                              if (c14r3MT === "S") {
                              setRow3Naked14(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                                ]
                              )
                              setC14R3VNo(5);
                            } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
                              setRow2NakedM14(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                ]
                              )
                              setC14R2VNo(5);
                              setC14R3VNo(5);
                            } else if (c14r3MT === "M" && c14r4MT === "M") { 
                              setRow3NakedM14(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                ]
                              )
                              setC14R3VNo(5);
                              setC14R4VNo(5);
                            } else if (c14r3MT === "L") {
                              setBaseNakedL14(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                              )
                              setC14R1VNo(5);
                              setC14R2VNo(5);
                              setC14R3VNo(5);
                              setC14R4VNo(5);
                            }
                        
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
                              alert("Already added");  
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {  
                              alert("Already added");
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {
                              if (c14r3MT === "S") {
                                setRow3Naked14(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                                      setC14R3VNo(8);  
                                    } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
                                      setRow2NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                        ]
                                      )
                                      setC14R2VNo(8); 
                                      setC14R3VNo(8); 
                                    } else if (c14r3MT === "M" && c14r4MT === "M") { 
                                      setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                                      setC14R3VNo(8);    
                                      setC14R4VNo(8); 
                                    } else if (c14r3MT === "L") {
                                      setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                                      setC14R1VNo(8);
                                      setC14R2VNo(8);
                                      setC14R3VNo(8);
                                      setC14R4VNo(8);
                                    }   
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 7) {
                              alert("Already added");  
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 8) {
                              alert("Already added");
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 9) {
                              alert("Already added");
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
                              alert("Already removed");
                            } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
                              alert("Already added");
                            } 	else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
                              if (c1r4MT === "S") {
                              setRow4Naked01(
                                     [
                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
                                     ]
                                   )
                                   setC1R4VNo(4);
                                  } else if (c1r4MT === "M" && c1r3MT === "M") { 
                                    setRow3NakedM01(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col01X,row03X,0.5]} />
                                      ]
                                    )
                                    setC1R3VNo(4);
                                    setC1R4VNo(4);
                                  } else if (c1r4MT === "L") {
                                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(4);
                                    setC1R2VNo(4);
                                    setC1R3VNo(4);
                                    setC1R4VNo(4);
                                  } 
                                
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
                                    if (c1r4MT === "S") {
                                        setRow4Naked01(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
                                          ]
                                        )
                                        setC1R4VNo(7);  
                                      } else if (c1r4MT === "M" && c1r3MT === "M") { 
                                        setRow3NakedM01(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                          ]
                                        )
                                        setC1R3VNo(7);   
                                        setC1R4VNo(7);
                                      } else if (c1r4MT === "L") {
                                        setBaseNakedL01(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                          ]
                                        )
                                        setC1R1VNo(7);
                                        setC1R2VNo(7);
                                        setC1R3VNo(7);
                                        setC1R4VNo(7);
                                      }
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
                                if (c1r4MT === "S") {
                                  setRow4Naked01(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                                    ]
                                  )
                                  setC1R4VNo(5);
                                  } else if (c1r4MT === "M" && c1r3MT === "M") { 
                                    setRow3NakedM01(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                      ]
                                    )
                                    setC1R3VNo(5);
                                    setC1R4VNo(5);
                                  } else if (c1r4MT === "L") {
                                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(5);
                                    setC1R2VNo(5);
                                    setC1R3VNo(5);
                                    setC1R4VNo(5);
                                  }
                          
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
                                alert("Already added");  
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {  
                                alert("Already added");
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {
                                if (c1r4MT === "S") {
                                  setRow4Naked01(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                                          ]
                                        )
                                        setC1R4VNo(8);   
                                      } else if (c1r4MT === "M" && c1r3MT === "M") { 
                                        setRow3NakedM01(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                          ]
                                        )
                                        setC1R3VNo(8);  
                                        setC1R4VNo(8);
                                      } else if (c1r4MT === "L") {
                                        setBaseNakedL01(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                          ]
                                        )
                                        setC1R1VNo(8);
                                        setC1R2VNo(8);
                                        setC1R3VNo(8);
                                        setC1R4VNo(8);
                                      }  
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 7) {
                                alert("Already added");  
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 8) {
                                alert("Already added");
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 9) {
                                alert("Already added");
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 10) {
                                alert("Already added");
                              } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
                                alert("Already added");
                              }  else if (curX === col02X && curY === row04X && c2R4VNo === 1) {
                                if (c2r4MT === "S") {
                                setRow4Naked02(
                                       [
                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                                       ]
                                     )
                                     setC2R4VNo(4);
                                    } else if (c2r4MT === "M" && c2r3MT === "M") { 
                                      setRow3NakedM02(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col02X,row03X,0.5]} />
                                        ]
                                      )
                                      setC2R3VNo(4);
                                      setC2R4VNo(4);
                                    } else if (c2r4MT === "L") {
                                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(4);
                                      setC2R2VNo(4);
                                      setC2R3VNo(4);
                                      setC2R4VNo(4);
                                    }
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 2) {
                                  if (c2r4MT === "S") {
                                    setRow4Naked02(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
                                            ]
                                          )
                                          setC2R4VNo(7);  
                                        } else if (c2r4MT === "M" && c2r3MT === "M") { 
                                          setRow3NakedM02(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                            ]
                                          )
                                          setC2R3VNo(7); 
                                          setC2R4VNo(7); 
                                        } else if (c2r4MT === "L") {
                                          setBaseNakedL02(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                            ]
                                          )
                                          setC2R1VNo(7);
                                          setC2R2VNo(7);
                                          setC2R3VNo(7);
                                          setC2R4VNo(7);
                                        }  
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 3) {
                                  if (c2r4MT === "S") {
                                  setRow4Naked02(
                                    [
                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                                    ]
                                  )
                                  setC2R4VNo(5);
                                } else if (c2r4MT === "M" && c2r3MT === "M") { 
                                  setRow3NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                    ]
                                  )
                                  setC2R3VNo(5);
                                  setC2R4VNo(5);
                                } else if (c2r4MT === "L") {
                                  setBaseNakedL02(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                    ]
                                  )
                                  setC2R1VNo(5);
                                  setC2R2VNo(5);
                                  setC2R3VNo(5);
                                  setC2R4VNo(5);
                                }
                            
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 4) {
                                  alert("Already added"); 
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 5) {  
                                  alert("Already added");
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 6) {
                                  if (c2r4MT === "S") {
                                    setRow4Naked02(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                                            ]
                                          )
                                          setC2R4VNo(8); 
                                        } else if (c2r4MT === "M" && c2r3MT === "M") { 
                                          setRow3NakedM02(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                            ]
                                          )
                                          setC2R3VNo(8);    
                                          setC2R4VNo(8); 
                                        } else if (c2r4MT === "L") {
                                          setBaseNakedL02(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                            ]
                                          )
                                          setC2R1VNo(8);
                                          setC2R2VNo(8);
                                          setC2R3VNo(8);
                                          setC2R4VNo(8);
                                        }    
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 7) {
                                  alert("Already added");  
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 8) {
                                  alert("Already added");
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 9) {
                                  alert("Already added");
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 10) {
                                  alert("Already added");
                                } else if (curX === col02X && curY === row04X && c2R4VNo === 11) {
                                  alert("Already added");
                                }  else if (curX === col03X && curY === row04X && c3R4VNo === 1) {
                                  if (c3r4MT === "S") {
                                  setRow4Naked03(
                                         [
                                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                                         ]
                                       )
                                       setC3R4VNo(4);
                                      } else if (c3r4MT === "M" && c3r3MT === "M") { 
                                        setRow3NakedM03(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col03X,row03X,0.5]} />
                                          ]
                                        )
                                        setC3R3VNo(4);
                                        setC3R4VNo(4);
                                      } else if (c3r4MT === "L") {
                                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(4);
                                        setC3R2VNo(4);
                                        setC3R3VNo(4);
                                        setC3R4VNo(4);
                                      }
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 2) {
                                    if (c3r4MT === "S") {
                                      setRow4Naked03(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
                                              ]
                                            )
                                            setC3R4VNo(7);    
                                          } else if (c3r4MT === "M" && c3r3MT === "M") { 
                                            setRow3NakedM03(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                              ]
                                            )
                                            setC3R3VNo(7);   
                                            setC3R4VNo(7); 
                                          } else if (c3r4MT === "L") {
                                            setBaseNakedL03(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                              ]
                                            )
                                            setC3R1VNo(7);
                                            setC3R2VNo(7);
                                            setC3R3VNo(7);
                                            setC3R4VNo(7);
                                          }
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 3) {
                                    if (c3r4MT === "S") {
                                    setRow4Naked03(
                                      [
                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                                      ]
                                      )
                                      setC3R4VNo(5);
                                    } else if (c3r4MT === "M" && c3r3MT === "M") { 
                                      setRow3NakedM03(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                        ]
                                      )
                                      setC3R3VNo(5);
                                      setC3R4VNo(5);
                                    } else if (c3r4MT === "L") {
                                      setBaseNakedL03(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                        ]
                                      )
                                      setC3R1VNo(5);
                                      setC3R2VNo(5);
                                      setC3R3VNo(5);
                                      setC3R4VNo(5);
                                    }
                                    
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 4) {
                                    alert("Already added");  
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 5) {  
                                    alert("Already added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 6) {
                                    if (c3r4MT === "S") {
                                      setRow4Naked03(
                                              [
                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                                              ]
                                            )
                                            setC3R4VNo(8);  
                                          } else if (c3r4MT === "M" && c3r3MT === "M") { 
                                            setRow3NakedM03(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                              ]
                                            )
                                            setC3R3VNo(8);   
                                            setC3R4VNo(8); 
                                          } else if (c3r4MT === "L") {
                                            setBaseNakedL03(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                              ]
                                            )
                                            setC3R1VNo(8);
                                            setC3R2VNo(8);
                                            setC3R3VNo(8);
                                            setC3R4VNo(8);
                                          }   
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 7) {
                                    alert("Already added");  
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 8) {
                                    alert("Already added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 9) {
                                    alert("Already added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 10) {
                                    alert("Already added");
                                  } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {
                                    alert("Already added");
                                  }  else if (curX === col04X && curY === row04X && c4R4VNo === 1) {
                                    if (c4r4MT === "S") {
                                    setRow4Naked04(
                                           [
                                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
                                           ]
                                         )
                                         setC4R4VNo(4);
                                        } else if (c4r4MT === "M" && c4r3MT === "M") { 
                                          setRow3NakedM04(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col04X,row03X,0.5]} />
                                            ]
                                          )
                                          setC4R3VNo(4);
                                          setC4R4VNo(4);
                                        } else if (c4r4MT === "L") {
                                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(4);
                                          setC4R2VNo(4);
                                          setC4R3VNo(4);
                                          setC4R4VNo(4);
                                        }
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 2) {
                                      if (c4r4MT === "S") {
                                        setRow4Naked04(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
                                                ]
                                              )
                                              setC4R4VNo(7); 
                                            } else if (c4r4MT === "M" && c4r3MT === "M") { 
                                              setRow3NakedM04(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                                ]
                                              )
                                              setC4R3VNo(7);    
                                              setC4R4VNo(7); 
                                            } else if (c4r4MT === "L") {
                                              setBaseNakedL04(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                ]
                                              )
                                              setC4R1VNo(7);
                                              setC4R2VNo(7);
                                              setC4R3VNo(7);
                                              setC4R4VNo(7);
                                            }
                                        
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 3) {
                                      if (c4r4MT === "S") {
                                        setRow4Naked04(
                                        [
                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                                        ]
                                          )
                                          setC4R4VNo(5);
                                        } else if (c4r4MT === "M" && c4r3MT === "M") { 
                                          setRow3NakedM04(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                            ]
                                          )
                                          setC4R3VNo(5);
                                          setC4R4VNo(5);
                                        } else if (c4r4MT === "L") {
                                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(5);
                                          setC4R2VNo(5);
                                          setC4R3VNo(5);
                                          setC4R4VNo(5);
                                        }

                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 4) {
                                      alert("Already added");  
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 5) {  
                                      alert("Already added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 6) {
                                      if (c4r4MT === "S") {
                                        setRow4Naked04(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                                                ]
                                              )
                                              setC4R4VNo(8); 
                                            } else if (c4r4MT === "M" && c4r3MT === "M") { 
                                              setRow3NakedM04(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                                ]
                                              )
                                              setC4R3VNo(8);  
                                              setC4R4VNo(8);  
                                            } else if (c4r4MT === "L") {
                                              setBaseNakedL04(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                ]
                                              )
                                              setC4R1VNo(8);
                                              setC4R2VNo(8);
                                              setC4R3VNo(8);
                                              setC4R4VNo(8);
                                            }    
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 7) {
                                      alert("Already added");  
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 8) {
                                      alert("Already added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 9) {
                                      alert("Already added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 10) {
                                      alert("Already added");
                                    } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {
                                      alert("Already added");
                                    }  else if (curX === col05X && curY === row04X && c5R4VNo === 1) {
                                      if (c5r4MT === "S") {
                                      setRow4Naked05(
                                             [
                                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
                                             ]
                                           )
                                           setC5R4VNo(4);
                                          } else if (c5r4MT === "M" && c5r3MT === "M") { 
                                            setRow3NakedM05(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col05X,row03X,0.5]} />
                                              ]
                                            )
                                            setC5R3VNo(4);
                                            setC5R4VNo(4);
                                          } else if (c5r4MT === "L") {
                                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(4);
                                            setC5R2VNo(4);
                                            setC5R3VNo(4);
                                            setC5R4VNo(4);
                                          }
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 2) {
                                        if (c5r4MT === "S") {
                                          setRow4Naked05(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
                                                  ]
                                                )
                                                setC5R4VNo(7);   
                                              } else if (c5r4MT === "M" && c5r3MT === "M") { 
                                                setRow3NakedM05(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC5R3VNo(7);  
                                                setC5R4VNo(7); 
                                              } else if (c5r4MT === "L") {
                                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(7);
                                                setC5R2VNo(7);
                                                setC5R3VNo(7);
                                                setC5R4VNo(7);
                                              } 
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 3) {
                                        if (c5r4MT === "S") {
                                        setRow4Naked05(
                                          [
                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                                          ]
                                            )
                                            setC5R4VNo(5);
                                          } else if (c5r4MT === "M" && c5r3MT === "M") { 
                                            setRow3NakedM05(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                              ]
                                            )
                                            setC5R3VNo(5);
                                            setC5R4VNo(5);
                                          } else if (c5r4MT === "L") {
                                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(5);
                                            setC5R2VNo(5);
                                            setC5R3VNo(5);
                                            setC5R4VNo(5);
                                          } 
                                        
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 4) {
                                        alert("Already added");  
                                  
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 5) {  
                                        alert("Already added");
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 6) {
                                        if (c5r4MT === "S") {
                                          setRow4Naked05(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                                                  ]
                                                )
                                                setC5R4VNo(8);  
                                              } else if (c5r4MT === "M" && c5r3MT === "M") { 
                                                setRow3NakedM05(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC5R3VNo(8); 
                                                setC5R4VNo(8); 
                                              } else if (c5r4MT === "L") {
                                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                                              }    
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 7) {
                                        alert("Already added");  
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 8) {
                                        alert("Already added");
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 9) {
                                        alert("Already added");
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 10) {
                                        alert("Already added");
                                      } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {
                                        alert("Already added");
                                      }  else if (curX === col06X && curY === row04X && c6R4VNo === 1) {
                                        if (c6r4MT === "S") {
                                        setRow4Naked06(
                                               [
                                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
                                               ]
                                             )
                                             setC6R4VNo(4);
                                            } else if (c6r4MT === "M" && c6r3MT === "M") { 
                                              setRow3NakedM06(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col06X,row03X,0.5]} />
                                                ]
                                              )
                                              setC6R3VNo(4);
                                              setC6R4VNo(4);
                                            } else if (c6r4MT === "L") {
                                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(4);
                                              setC6R2VNo(4);
                                              setC6R3VNo(4);
                                              setC6R4VNo(4);
                                            } 
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 2) {
                                          if (c6r4MT === "S") {
                                            setRow4Naked06(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
                                                    ]
                                                  )
                                                  setC6R4VNo(7);  
                                                } else if (c6r4MT === "M" && c6r3MT === "M") { 
                                        
                                                  setRow3NakedM06(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC6R3VNo(7);  
                                                  setC6R4VNo(7);  
                                                } else if (c6r4MT === "L") {
                                                  setBaseNakedL06(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC6R1VNo(7);
                                                  setC6R2VNo(7);
                                                  setC6R3VNo(7);
                                                  setC6R4VNo(7);
                                                }   
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 3) {
                                          if (c6r4MT === "S") {
                                          setRow4Naked06(
                                            [
                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                                            ]
                                            )
                                            setC6R4VNo(5);
                                          } else if (c6r4MT === "M" && c6r3MT === "M") { 
                                            setRow3NakedM06(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                              ]
                                            )
                                            setC6R3VNo(5);
                                            setC6R4VNo(5);
                                          } else if (c6r4MT === "L") {
                                            setBaseNakedL06(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                              ]
                                            )
                                            setC6R1VNo(5);
                                            setC6R2VNo(5);
                                            setC6R3VNo(5);
                                            setC6R4VNo(5);
                                          } 
                                        
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 4) {
                                          alert("Already added");  
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 5) {  
                                          alert("Already added");
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 6) {
                                          if (c6r4MT === "S") {
                                            setRow4Naked06(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                                                    ]
                                                  )
                                                  setC6R4VNo(8);     
                                                } else if (c6r4MT === "M" && c6r3MT === "M") { 
                                                  setRow3NakedM06(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC6R3VNo(8);
                                                  setC6R4VNo(8);
                                                } else if (c6r4MT === "L") {
                                                  setBaseNakedL06(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC6R1VNo(8);
                                                  setC6R2VNo(8);
                                                  setC6R3VNo(8);
                                                  setC6R4VNo(8);
                                                } 
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 7) {
                                          alert("Already added");  
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 8) {
                                          alert("Already added");
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 9) {
                                          alert("Already added");
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 10) {
                                          alert("Already added");
                                        } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {
                                          alert("Already added");
                                        }  else if (curX === col07X && curY === row04X && c7R4VNo === 1) {
                                          if (c7r4MT === "S") {
                                          setRow4Naked07(
                                                 [
                                                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                                                 ]
                                               )
                                               setC7R4VNo(4);
                                              } else if (c7r4MT === "M" && c7r3MT === "M") { 
                                                setRow3NakedM07(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col07X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC7R3VNo(4);
                                                setC7R4VNo(4);
                                              } else if (c7r4MT === "L") {
                                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(4);
                                                setC7R2VNo(4);
                                                setC7R3VNo(4);
                                                setC7R4VNo(4);
                                              } 
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 2) {
                                            if (c7r4MT === "S") {
                                              setRow4Naked07(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R4VNo(7);   
                                                  } else if (c7r4MT === "M" && c7r3MT === "M") { 
                                                    setRow3NakedM07(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R3VNo(7);
                                                    setC7R4VNo(7);
                                                  } else if (c7r4MT === "L") {
                                                    setBaseNakedL07(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R1VNo(7);
                                                    setC7R2VNo(7);
                                                    setC7R3VNo(7);
                                                    setC7R4VNo(7);
                                                  }  
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 3) {
                                            if (c7r4MT === "S") {
                                              setRow4Naked07(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                                                ]
                                              )
                                              setC7R4VNo(5);                                            
                                            } else if (c7r4MT === "M" && c7r3MT === "M") { 
                                              setRow3NakedM07(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                ]
                                              )
                                              setC7R3VNo(5);
                                              setC7R4VNo(5);
                                            } else if (c7r4MT === "L") {
                                              setBaseNakedL07(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                ]
                                              )
                                              setC7R1VNo(5);
                                              setC7R2VNo(5);
                                              setC7R3VNo(5);
                                              setC7R4VNo(5);
                                            } 
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 4) {
                                            alert("Already added");  
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 5) {  
                                            alert("Already added");
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 6) {
                                            if (c7r4MT === "S") {
                                              setRow4Naked07(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R4VNo(8);   
                                                  } else if (c7r4MT === "M" && c7r3MT === "M") { 
                                                    setRow3NakedM07(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R3VNo(8);  
                                                    setC7R4VNo(8); 
                                                  } else if (c7r4MT === "L") {
                                                    setBaseNakedL07(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R1VNo(8);
                                                    setC7R2VNo(8);
                                                    setC7R3VNo(8);
                                                    setC7R4VNo(8);
                                                  }   
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 7) {
                                            alert("Already added");  
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 8) {
                                            alert("Already added");
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 9) {
                                            alert("Already added");
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 10) {
                                            alert("Already added");
                                          } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {
                                            alert("Already added");
                                          }  else if (curX === col08X && curY === row04X && c8R4VNo === 1) {
                                            if (c8r4MT === "S") {
                                            setRow4Naked08(
                                                   [
                                                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
                                                   ]
                                                 )
                                                 setC8R4VNo(4);
                                                } else if (c8r4MT === "M" && c8r3MT === "M") { 
                                                  setRow3NakedM08(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col08X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R3VNo(4);
                                                  setC8R4VNo(4);
                                                } else if (c8r4MT === "L") {
                                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(4);
                                                  setC8R2VNo(4);
                                                  setC8R3VNo(4);
                                                  setC8R4VNo(4);
                                                } 
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 2) {
                                              if (c8r4MT === "S") {
                                                setRow4Naked08(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
                                                        ]
                                                      )
                                                      setC8R4VNo(7); 
                                                    } else if (c8r4MT === "M" && c8r3MT === "M") { 
                                                      setRow3NakedM08(
                                                        [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC8R3VNo(7);  
                                                      setC8R4VNo(7); 
                                                    } else if (c8r4MT === "L") {
                                                      setBaseNakedL08(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC8R1VNo(7);
                                                      setC8R2VNo(7);
                                                      setC8R3VNo(7);
                                                      setC8R4VNo(7);
                                                    }    
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 3) {
                                              if (c8r4MT === "S") {
                                              setRow4Naked08(
                                                [
                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                                                ]
                                                )
                                                setC8R4VNo(5);
                                                } else if (c8r4MT === "M" && c8r3MT === "M") { 
                                                  setRow3NakedM08(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R3VNo(5);
                                                  setC8R4VNo(5);
                                                } else if (c8r4MT === "L") {
                                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(5);
                                                  setC8R2VNo(5);
                                                  setC8R3VNo(5);
                                                  setC8R4VNo(5);
                                                } 
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 4) {
                                              alert("Already added");  
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 5) {  
                                              alert("Already added");
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 6) {
                                              if (c8r4MT === "S") {
                                                setRow4Naked08(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                                                        ]
                                                      )
                                                      setC8R4VNo(8);   
                                                    } else if (c8r4MT === "M" && c8r3MT === "M") { 
                                                      setRow3NakedM08(
                                                        [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC8R3VNo(8);  
                                                      setC8R4VNo(8); 
                                                    } else if (c8r4MT === "L") {
                                                      setBaseNakedL08(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC8R1VNo(8);
                                                      setC8R2VNo(8);
                                                      setC8R3VNo(8);
                                                      setC8R4VNo(8);
                                                    }   
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 7) {
                                              alert("Already added");  
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 8) {
                                              alert("Already added");
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 9) {
                                              alert("Already added");
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 10) {
                                              alert("Already added");
                                            } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {
                                              alert("Already added");
                                            }  else if (curX === col09X && curY === row04X && c9R4VNo === 1) {
                                              if (c9r4MT === "S") {
                                              setRow4Naked09(
                                                     [
                                                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
                                                     ]
                                                   )
                                                   setC9R4VNo(4);
                                                  } else if (c9r4MT === "M" && c9r3MT === "M") { 
                                                    setRow3NakedM09(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col09X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R3VNo(4);
                                                    setC9R4VNo(4);
                                                  } else if (c9r4MT === "L") {
                                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(4);
                                                    setC9R2VNo(4);
                                                    setC9R3VNo(4);
                                                    setC9R4VNo(4);
                                                  } 
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 2) {
                                                if (c9r4MT === "S") {
                                                  setRow4Naked09(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
                                                          ]
                                                        )
                                                        setC9R4VNo(7);   
                                                      } else if (c9r4MT === "M" && c9r3MT === "M") { 
                                                        setRow3NakedM09(
                                                          [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                          ]
                                                        )
                                                        setC9R3VNo(7); 
                                                        setC9R4VNo(7); 
                                                      } else if (c9r4MT === "L") {
                                                        setBaseNakedL09(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC9R1VNo(7);
                                                        setC9R2VNo(7);
                                                        setC9R3VNo(7);
                                                        setC9R4VNo(7);
                                                      }  
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 3) {
                                                if (c9r4MT === "S") {
                                                setRow4Naked09(
                                                  [
                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                                                  ]
                                                 )
                                                  setC9R4VNo(5);
                                                } else if (c9r4MT === "M" && c9r3MT === "M") { 
                                                  setRow3NakedM09(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC9R3VNo(5);
                                                  setC9R4VNo(5);
                                                } else if (c9r4MT === "L") {
                                                  setBaseNakedL09(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC9R1VNo(5);
                                                  setC9R2VNo(5);
                                                  setC9R3VNo(5);
                                                  setC9R4VNo(5);
                                                } 
      
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 4) {
                                                alert("Already added");  
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 5) {  
                                                alert("Already added");
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 6) {
                                                if (c9r4MT === "S") {
                                                  setRow4Naked09(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                                                          ]
                                                        )
                                                        setC9R4VNo(8);  
                                                      } else if (c9r4MT === "M" && c9r3MT === "M") { 
                                                        setRow3NakedM09(
                                                          [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                          ]
                                                        )
                                                        setC9R3VNo(8);  
                                                        setC9R4VNo(8); 
                                                      } else if (c9r4MT === "L") {
                                                        setBaseNakedL09(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC9R1VNo(8);
                                                        setC9R2VNo(8);
                                                        setC9R3VNo(8);
                                                        setC9R4VNo(8);
                                                      }    
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 7) {
                                                alert("Already added");  
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 8) {
                                                alert("Already added");
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 9) {
                                                alert("Already added");
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 10) {
                                                alert("Already added");
                                              } else if (curX === col09X && curY === row04X && c9R4VNo === 11) {
                                                alert("Already added");
                                              }  else if (curX === col10X && curY === row04X && c10R4VNo === 1) {
                                                if (c10r4MT === "S") {
                                                setRow4Naked10(
                                                       [
                                                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
                                                       ]
                                                     )
                                                     setC10R4VNo(4);
                                                    } else if (c10r4MT === "M" && c10r3MT === "M") { 
                                                      setRow3NakedM10(
                                                        [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col10X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R3VNo(4);
                                                      setC10R4VNo(4);
                                                    } else if (c10r4MT === "L") {
                                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(4);
                                                      setC10R2VNo(4);
                                                      setC10R3VNo(4);
                                                      setC10R4VNo(4);
                                                    } 
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 2) {
                                                  if (c10r4MT === "S") {
                                                    setRow4Naked10(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
                                                            ]
                                                          )
                                                          setC10R4VNo(7);  
                                                        } else if (c10r4MT === "M" && c10r3MT === "M") { 
                                                          setRow3NakedM10(
                                                            [
                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                            ]
                                                          )
                                                          setC10R3VNo(7);  
                                                          setC10R4VNo(7); 
                                                        } else if (c10r4MT === "L") {
                                                          setBaseNakedL10(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC10R1VNo(7);
                                                          setC10R2VNo(7);
                                                          setC10R3VNo(7);
                                                          setC10R4VNo(7);
                                                        }   
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 3) {
                                                  if (c10r4MT === "S") {
                                                  setRow4Naked10(
                                                    [
                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                                                    ]
                                                  )
                                                  setC10R4VNo(5);
                                                } else if (c10r4MT === "M" && c10r3MT === "M") { 
                                                  setRow3NakedM10(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC10R3VNo(5);
                                                  setC10R4VNo(5);
                                                } else if (c10r4MT === "L") {
                                                  setBaseNakedL10(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC10R1VNo(5);
                                                  setC10R2VNo(5);
                                                  setC10R3VNo(5);
                                                  setC10R4VNo(5);
                                                } 

                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 4) {
                                                  alert("Already added");  
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 5) {  
                                                  alert("Already added");
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 6) {
                                                  if (c10r4MT === "S") {
                                                    setRow4Naked10(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                                                            ]
                                                          )
                                                          setC10R4VNo(8);  
                                                        } else if (c10r4MT === "M" && c10r3MT === "M") { 
                                                          setRow3NakedM10(
                                                            [
                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                            ]
                                                          )
                                                          setC10R3VNo(8); 
                                                          setC10R4VNo(8); 
                                                        } else if (c10r4MT === "L") {
                                                          setBaseNakedL10(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC10R1VNo(8);
                                                          setC10R2VNo(8);
                                                          setC10R3VNo(8);
                                                          setC10R4VNo(8);
                                                        }    
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 7) {
                                                  alert("Already added");  
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 8) {
                                                  alert("Already added");
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 9) {
                                                  alert("Already added");
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 10) {
                                                  alert("Already added");
                                                } else if (curX === col10X && curY === row04X && c10R4VNo === 11) {
                                                  alert("Already added");
                                                }  else if (curX === col11X && curY === row04X && c11R4VNo === 1) {                                  
                                                  if (c11r4MT === "S") {
                                                  setRow4Naked11(
                                                         [
                                                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
                                                         ]
                                                       )
                                                       setC11R4VNo(4);
                                                      } else if (c11r4MT === "M" && c11r3MT === "M") { 
                                                        setRow3NakedM11(
                                                          [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col11X,row03X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R3VNo(4);
                                                        setC11R4VNo(4);
                                                      } else if (c11r4MT === "L") {
                                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(4);
                                                        setC11R2VNo(4);
                                                        setC11R3VNo(4);
                                                        setC11R4VNo(4);
                                                      } 
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 2) {
                                                    if (c11r4MT === "S") {
                                                      setRow4Naked11(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
                                                              ]
                                                            )
                                                            setC11R4VNo(7);  
                                                          } else if (c11r4MT === "M" && c11r3MT === "M") { 
                                                            setRow3NakedM11(
                                                              [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC11R3VNo(7);  
                                                            setC11R4VNo(7); 
                                                          } else if (c11r4MT === "L") {
                                                            setBaseNakedL11(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC11R1VNo(7);
                                                            setC11R2VNo(7);
                                                            setC11R3VNo(7);
                                                            setC11R4VNo(7);
                                                          }   
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 3) {
                                                    if (c11r4MT === "S") {
                                                    setRow4Naked11(
                                                      [
                                                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                                                      ]
                                                    )
                                                    setC11R4VNo(5);
                                                  } else if (c11r4MT === "M" && c11r3MT === "M") { 
                                                    setRow3NakedM11(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC11R3VNo(5);
                                                    setC11R4VNo(5);
                                                  } else if (c11r4MT === "L") {
                                                    setBaseNakedL11(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC11R1VNo(5);
                                                    setC11R2VNo(5);
                                                    setC11R3VNo(5);
                                                    setC11R4VNo(5);
                                                  } 

                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 4) {
                                                    alert("Already added");  
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 5) {  
                                                    alert("Already added");
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 6) {
                                                    if (c11r4MT === "S") {
                                                      setRow4Naked11(
                                                              [
                                                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                                                              ]
                                                            )
                                                            setC11R4VNo(8);  
                                                          } else if (c11r4MT === "M" && c11r3MT === "M") { 
                                                            setRow3NakedM11(
                                                              [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC11R3VNo(8);
                                                            setC11R4VNo(8);
                                                          } else if (c11r4MT === "L") {
                                                            setBaseNakedL11(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC11R1VNo(8);
                                                            setC11R2VNo(8);
                                                            setC11R3VNo(8);
                                                            setC11R4VNo(8);
                                                          }    
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 7) {
                                                    alert("Already added");  
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 8) {
                                                    alert("Already added");
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 9) {
                                                    alert("Already added");
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 10) {
                                                    alert("Already added");
                                                  } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {
                                                    alert("Already added");
                                                  }  else if (curX === col12X && curY === row04X && c12R4VNo === 1) {
                                                    if (c12r4MT === "S") {
                                                    setRow4Naked12(
                                                           [
                                                             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                                                           ]
                                                         )
                                                         setC12R4VNo(4);
                                                        } else if (c12r4MT === "M" && c12r3MT === "M") { 
                                                          setRow3NakedM12(
                                                            [
                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col12X,row03X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R3VNo(4);
                                                          setC12R4VNo(4);
                                                        } else if (c12r4MT === "L") {
                                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(4);
                                                          setC12R2VNo(4);
                                                          setC12R3VNo(4);
                                                          setC12R4VNo(4);
                                                        } 
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 2) {
                                                      if (c12r4MT === "S") {
                                                        setRow4Naked12(
                                                                [
                                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
                                                                ]
                                                              )
                                                              setC12R4VNo(7);   
                                                            } else if (c12r4MT === "M" && c12r3MT === "M") { 
                                                              setRow3NakedM12(
                                                                [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                                ]
                                                              )
                                                              setC12R3VNo(7); 
                                                              setC12R4VNo(7); 
                                                            } else if (c12r4MT === "L") {
                                                              setBaseNakedL12(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC12R1VNo(7);
                                                              setC12R2VNo(7);
                                                              setC12R3VNo(7);
                                                              setC12R4VNo(7);
                                                            }  
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 3) {
                                                      if (c12r4MT === "S") {
                                                      setRow4Naked12(
                                                        [
                                                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                                                        ]
                                                      )
                                                      setC12R4VNo(5);
                                                    } else if (c12r4MT === "M" && c12r3MT === "M") { 
                                                      setRow3NakedM12(
                                                        [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC12R3VNo(5);
                                                      setC12R4VNo(5);
                                                    } else if (c12r4MT === "L") {
                                                      setBaseNakedL12(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC12R1VNo(5);
                                                      setC12R2VNo(5);
                                                      setC12R3VNo(5);
                                                      setC12R4VNo(5);
                                                    } 

                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 4) {
                                                      alert("Already added");  
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 5) {  
                                                      alert("Already added");
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 6) {
                                                      if (c12r4MT === "S") {
                                                        setRow4Naked12(
                                                                [
                                                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                                                                ]
                                                              )
                                                              setC12R4VNo(8);   
                                                            } else if (c12r4MT === "M" && c12r3MT === "M") { 
                                                              setRow3NakedM12(
                                                                [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                                ]
                                                              )
                                                              setC12R3VNo(8);  
                                                              setC12R4VNo(8); 
                                                            } else if (c12r4MT === "L") {
                                                              setBaseNakedL12(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC12R1VNo(8);
                                                              setC12R2VNo(8);
                                                              setC12R3VNo(8);
                                                              setC12R4VNo(8);
                                                            }   
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 7) {
                                                      alert("Already added");  
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 8) {
                                                      alert("Already added");
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 9) {
                                                      alert("Already added");
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 10) {
                                                      alert("Already added");
                                                    } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {
                                                      alert("Already added");
                                                    }  else if (curX === col13X && curY === row04X && c13R4VNo === 1) {
                                                      if (c13r4MT === "S") {
                                                      setRow4Naked13(
                                                             [
                                                               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                                                             ]
                                                           )
                                                           setC13R4VNo(4);
                                                          } else if (c13r4MT === "M" && c13r3MT === "M") { 
                                                            setRow3NakedM13(
                                                              [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col13X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R3VNo(4);
                                                            setC13R4VNo(4);
                                                          } else if (c13r4MT === "L") {
                                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(4);
                                                            setC13R2VNo(4);
                                                            setC13R3VNo(4);
                                                            setC13R4VNo(4);
                                                          } 
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 2) {
                                                        if (c13r4MT === "S") {
                                                          setRow4Naked13(
                                                                  [
                                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
                                                                  ]
                                                                )
                                                                setC13R4VNo(7);    
                                                              } else if (c13r4MT === "M" && c13r3MT === "M") { 
                                                                setRow3NakedM13(
                                                                  [
                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                                  ]
                                                                )
                                                                setC13R3VNo(7);
                                                                setC13R4VNo(7);
                                                              } else if (c13r4MT === "L") {
                                                                setBaseNakedL13(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC13R1VNo(7);
                                                                setC13R2VNo(7);
                                                                setC13R3VNo(7);
                                                                setC13R4VNo(7);
                                                              } 
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 3) {
                                                        if (c13r4MT === "S") {
                                                        setRow4Naked13(
                                                          [
                                                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                                                          ]
                                                            )
                                                            setC13R4VNo(5);
                                                          } else if (c13r4MT === "M" && c13r3MT === "M") { 
                                                            setRow3NakedM13(
                                                              [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R3VNo(5);
                                                            setC13R4VNo(5);
                                                          } else if (c13r4MT === "L") {
                                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(5);
                                                            setC13R2VNo(5);
                                                            setC13R3VNo(5);
                                                            setC13R4VNo(5);
                                                          } 
  
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 4) {
                                                        alert("Already added");  
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 5) {  
                                                        alert("Already added");
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 6) {
                                                        if (c13r4MT === "S") {
                                                          setRow4Naked13(
                                                                  [
                                                                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                                                                  ]
                                                                )
                                                                setC13R4VNo(8); 
                                                              } else if (c13r4MT === "M" && c13r3MT === "M") { 
                                                                setRow3NakedM13(
                                                                  [
                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                                  ]
                                                                )
                                                                setC13R3VNo(8); 
                                                                setC13R4VNo(8); 
                                                              } else if (c13r4MT === "L") {
                                                                setBaseNakedL13(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC13R1VNo(8);
                                                                setC13R2VNo(8);
                                                                setC13R3VNo(8);
                                                                setC13R4VNo(8);
                                                              }     
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 7) {
                                                        alert("Already added");  
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 8) {
                                                        alert("Already added");
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 9) {
                                                        alert("Already added");
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 10) {
                                                        alert("Already added");
                                                      } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {
                                                        alert("Already added");
                                                      }  else if (curX === col14X && curY === row04X && c14R4VNo === 1) {
                                                        if (c14r4MT === "S") {
                                                        setRow4Naked14(
                                                               [
                                                                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
                                                               ]
                                                             )
                                                             setC14R4VNo(4);
                                                            } else if (c14r4MT === "M" && c14r3MT === "M") { 
                                                              setRow3NakedM14(
                                                                [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}}  position={[col14X,row03X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R3VNo(4);
                                                              setC14R4VNo(4);
                                                            } else if (c14r4MT === "L") {
                                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(4);
                                                              setC14R2VNo(4);
                                                              setC14R3VNo(4);
                                                              setC14R4VNo(4);

                                                            } 
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 2) {
                                                          if (c14r4MT === "S") {
                                                            setRow4Naked14(
                                                                    [
                                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC14R4VNo(7);  
                                                                } else if (c14r4MT === "M" && c14r3MT === "M") { 
                                                                  setRow3NakedM14(
                                                                    [
                                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC14R3VNo(7); 
                                                                  setC14R4VNo(7); 
                                                                } else if (c14r4MT === "L") {
                                                                  setBaseNakedL14(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC14R1VNo(7);
                                                                  setC14R2VNo(7);
                                                                  setC14R3VNo(7);
                                                                  setC14R4VNo(7);
                                                                }   
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 3) {
                                                          if (c14r4MT === "S") {
                                                          setRow4Naked14(
                                                            [
                                                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                                                            ]
                                                            )
                                                            setC14R4VNo(5);
                                                          } else if (c14r4MT === "M" && c14r3MT === "M") { 
                                                            setRow3NakedM14(
                                                              [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC14R3VNo(5);
                                                            setC14R4VNo(5);
                                                          } else if (c14r4MT === "L") {
                                                            setBaseNakedL14(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC14R1VNo(5);
                                                            setC14R2VNo(5);
                                                            setC14R3VNo(5);
                                                            setC14R4VNo(5);
                                                          } 

                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 4) {
                                                          alert("Already added");
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 5) {  
                                                          alert("Already added");
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 6) {
                                                          if (c14r4MT === "S") {
                                                            setRow4Naked14(
                                                                    [
                                                                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC14R4VNo(8);    
                                                                } else if (c14r4MT === "M" && c14r3MT === "M") { 
                                                                  setRow3NakedM14(
                                                                    [
                                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC14R3VNo(8);    
                                                                  setC14R4VNo(8); 
                                                                } else if (c14r4MT === "L") {
                                                                  setBaseNakedL14(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC14R1VNo(8);
                                                                  setC14R2VNo(8);
                                                                  setC14R3VNo(8);
                                                                  setC14R4VNo(8);
                                                                }  
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 7) {
                                                          alert("Already added");  
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 8) {
                                                          alert("Already added");
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 9) {
                                                          alert("Already added");
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 10) {
                                                          alert("Already removed");
                                                        } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
                                                          alert("Already added");
                                                        }
  
  }

  // removing right panel
  const removeRightPanel = (e)=> {
   
      if (curX === col01X && curY === row01X && c1R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row01X && c1R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row01X && c1R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 4) {
        if (c1r1MT === "S") {
            setBaseNaked01(
              [
                <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(1);
          } else if (c1r1MT === "M" && c1r2MT === "M") { 
            setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(1);
            setC1R2VNo(1);
          } else if (c1r1MT === "L") { 
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(1);
            setC1R2VNo(1);
            setC1R3VNo(1);
            setC1R4VNo(1);
          }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 5) {
        if (c1r1MT === "S") {
        setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
      } else if (c1r1MT === "M" && c1r2MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
      } else if (c1r1MT === "L") { 
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setC1R3VNo(3);
        setC1R4VNo(3);
      }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col01X && curY === row01X && c1R1VNo === 7) {
        if (c1r1MT === "S") {
          setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(2);
          } else if (c1r1MT === "M" && c1r2MT === "M") { 
            setBaseNakedM01(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
            setC1R2VNo(2);
          } else if (c1r1MT === "L") { 
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )
            setC1R1VNo(2);
            setC1R2VNo(2);
            setC1R3VNo(2);
            setC1R4VNo(2);
          }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 8) {
        if (c1r1MT === "S") {
  setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
      } else if (c1r1MT === "M" && c1r2MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r1MT === "L") { 
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 9) {
        if (c1r1MT === "S") {
  setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
      } else if (c1r1MT === "M" && c1r2MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r1MT === "L") { 
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 10) {
        if (c1r1MT === "S") {
  setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
      } else if (c1r1MT === "M" && c1r2MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r1MT === "L") { 
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row01X && c1R1VNo === 11) {
        if (c1r1MT === "S") {
  setBaseNaked01(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
      } else if (c1r1MT === "M" && c1r2MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r1MT === "L") { 
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      }     else if (curX === col02X && curY === row01X && c2R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row01X && c2R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row01X && c2R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 4) {
        if (c2r1MT === "S") {
        setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(1);
      } else if (c2r1MT === "M" && c2r2MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(1);
        setC2R2VNo(1);
      } else if (c2r1MT === "L") { 
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(1);
        setC2R2VNo(1);
        setC2R3VNo(1);
        setC2R4VNo(1);
      }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 5) {
        if (c2r1MT === "S") {
        setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
      } else if (c2r1MT === "M" && c2r2MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
      } else if (c2r1MT === "L") { 
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setC2R3VNo(3);
        setC2R4VNo(3);
      }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col02X && curY === row01X && c2R1VNo === 7) {
        if (c2r1MT === "S") {
  setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
      } else if (c2r1MT === "M" && c2r2MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
      } else if (c2r1MT === "L") { 
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
        setC2R3VNo(2);
        setC2R4VNo(2);
      }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 8) {
        if (c2r1MT === "S") {
  setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
      } else if (c2r1MT === "M" && c2r2MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r1MT === "L") { 
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
        if (c2r1MT === "S") {
  setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
      } else if (c2r1MT === "M" && c2r2MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r1MT === "L") { 
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
        if (c2r1MT === "S") {
  setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
      } else if (c2r1MT === "M" && c2r2MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r1MT === "L") { 
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
        if (c2r1MT === "S") {
  setBaseNaked02(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
      } else if (c2r1MT === "M" && c2r2MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r1MT === "L") { 
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      }     else if (curX === col03X && curY === row01X && c3R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row01X && c3R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row01X && c3R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 4) {
        if (c3r1MT === "S") {
        setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(1);
      } else if (c3r1MT === "M" && c3r2MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(1);
        setC3R2VNo(1);
      } else if (c3r1MT === "L") { 
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(1);
        setC3R2VNo(1);
        setC3R3VNo(1);
        setC3R4VNo(1);
      }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 5) {
        if (c3r1MT === "S") {
        setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
      } else if (c3r1MT === "M" && c3r2MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);

      } else if (c3r1MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
        setC3R3VNo(3);
        setC3R4VNo(3);
      }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col03X && curY === row01X && c3R1VNo === 7) {
        if (c3r1MT === "S") {
  setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
      } else if (c3r1MT === "M" && c3r2MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
      } else if (c3r1MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
        setC3R3VNo(2);
        setC3R4VNo(2);
      }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 8) {
        if (c3r1MT === "S") {
  setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
      } else if (c3r1MT === "M" && c3r2MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
      } else if (c3r1MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 9) {
        if (c3r1MT === "S") {
  setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
      } else if (c3r1MT === "M" && c3r2MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
      } else if (c3r1MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
        if (c3r1MT === "S") {
  setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
      } else if (c3r1MT === "M" && c3r2MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);

      } else if (c3r1MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
        if (c3r1MT === "S") {
  setBaseNaked03(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
      } else if (c3r1MT === "M" && c3r2MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
      } else if (c3r1MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      }     else if (curX === col04X && curY === row01X && c4R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row01X && c4R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row01X && c4R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 4) {
        if (c4r1MT === "S") {
        setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
        setC4R2VNo(1);
      } else if (c4r1MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
        setC4R2VNo(1);
        setC4R3VNo(1);
        setC4R4VNo(1);
      }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 5) {
        if (c4r1MT === "S") {
        setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
      } else if (c4r1MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
        setC4R3VNo(3);
        setC4R4VNo(3);
      }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col04X && curY === row01X && c4R1VNo === 7) {
        if (c4r1MT === "S") {
  setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
      } else if (c4r1MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
        setC4R3VNo(2);
        setC4R4VNo(2);
      }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 8) {
        if (c4r1MT === "S") {
  setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r1MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 9) {
        if (c4r1MT === "S") {
  setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r1MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
        if (c4r1MT === "S") {
  setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r1MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
        if (c4r1MT === "S") {
  setBaseNaked04(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
      } else if (c4r1MT === "M" && c4r2MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r1MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      }     else if (curX === col05X && curY === row01X && c5R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row01X && c5R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row01X && c5R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 4) {
        if (c5r1MT === "S") {
        setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(1);
      } else if (c5r1MT === "M" && c5r2MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(1);
        setC5R2VNo(1);
      } else if (c5r1MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(1);
        setC5R2VNo(1);
        setC5R3VNo(1);
        setC5R4VNo(1);
      }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 5) {
        if (c5r1MT === "S") {
        setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
      } else if (c5r1MT === "M" && c5r2MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
      } else if (c5r1MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
        setC5R3VNo(3);
        setC5R4VNo(3);
      }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col05X && curY === row01X && c5R1VNo === 7) {
        if (c5r1MT === "S") {
  setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
      } else if (c5r1MT === "M" && c5r2MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
      } else if (c5r1MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
        setC5R3VNo(2);
        setC5R4VNo(2);
      }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 8) {
        if (c5r1MT === "S") {
  setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
      } else if (c5r1MT === "M" && c5r2MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r1MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
        if (c5r1MT === "S") {
  setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
      } else if (c5r1MT === "M" && c5r2MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r1MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
        if (c5r1MT === "S") {
  setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
      } else if (c5r1MT === "M" && c5r2MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r1MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
        if (c5r1MT === "S") {
  setBaseNaked05(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
      } else if (c5r1MT === "M" && c5r2MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r1MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      }     else if (curX === col06X && curY === row01X && c6R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row01X && c6R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row01X && c6R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 4) {
        if (c6r1MT === "S") {
        setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(1);
        } else if (c6r1MT === "M" && c6r2MT === "M") { 
          setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
        } else if (c6r1MT === "L") {
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
          setC6R1VNo(1);
          setC6R2VNo(1);
          setC6R3VNo(1);
          setC6R4VNo(1);
        }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 5) {
        if (c6r1MT === "S") {
        setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
      } else if (c6r1MT === "M" && c6r2MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
      } else if (c6r1MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
        setC6R3VNo(3);
        setC6R4VNo(3);
      }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col06X && curY === row01X && c6R1VNo === 7) {
        if (c6r1MT === "S") {
  setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
      } else if (c6r1MT === "M" && c6r2MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
      } else if (c6r1MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
        setC6R3VNo(2);
        setC6R4VNo(2);
      }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 8) {
        if (c6r1MT === "S") {
  setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
      } else if (c6r1MT === "M" && c6r2MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r1MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 9) {
        if (c6r1MT === "S") {
  setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
      } else if (c6r1MT === "M" && c6r2MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r1MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
        if (c6r1MT === "S") {
  setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
      } else if (c6r1MT === "M" && c6r2MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r1MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
        if (c6r1MT === "S") {
  setBaseNaked06(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
      } else if (c6r1MT === "M" && c6r2MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r1MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      }     else if (curX === col07X && curY === row01X && c7R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row01X && c7R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row01X && c7R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 4) {
        if (c7r1MT === "S") {
        setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(1);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(1);
        setC7R2VNo(1);
      } else if (c7r1MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(1);
        setC7R2VNo(1);
        setC7R3VNo(1);
        setC7R4VNo(1);
      }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 5) {
        if (c7r1MT === "S") {
        setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
      } else if (c7r1MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
        setC7R3VNo(3);
        setC7R4VNo(3);
      }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col07X && curY === row01X && c7R1VNo === 7) {
        if (c7r1MT === "S") {
  setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
      } else if (c7r1MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
        setC7R3VNo(2);
        setC7R4VNo(2);
      }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 8) {
        if (c7r1MT === "S") {
  setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r1MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 9) {
        if (c7r1MT === "S") {
  setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r1MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
        if (c7r1MT === "S") {
  setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r1MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
        if (c7r1MT === "S") {
  setBaseNaked07(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
      } else if (c7r1MT === "M" && c7r2MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r1MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      }     else if (curX === col08X && curY === row01X && c8R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row01X && c8R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row01X && c8R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 4) {
        if (c8r1MT === "S") {
        setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
      } else if (c8r1MT === "M" && c8r2MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
        setC8R2VNo(1);
      } else if (c8r1MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
        setC8R2VNo(1);
        setC8R3VNo(1);
        setC8R4VNo(1);
      }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 5) {
        if (c8r1MT === "S") {
        setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
      } else if (c8r1MT === "M" && c8r2MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
      } else if (c8r1MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setC8R3VNo(3);
        setC8R4VNo(3);
      }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col08X && curY === row01X && c8R1VNo === 7) {
        if (c8r1MT === "S") {
  setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
      } else if (c8r1MT === "M" && c8r2MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
      } else if (c8r1MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setC8R3VNo(2);
        setC8R4VNo(2);
      }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 8) {
        if (c8r1MT === "S") {
  setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
      } else if (c8r1MT === "M" && c8r2MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r1MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 9) {
        if (c8r1MT === "S") {
  setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
      } else if (c8r1MT === "M" && c8r2MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r1MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
        if (c8r1MT === "S") {
  setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
      } else if (c8r1MT === "M" && c8r2MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r1MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
        if (c8r1MT === "S") {
  setBaseNaked08(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
      } else if (c8r1MT === "M" && c8r2MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r1MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      }     else if (curX === col09X && curY === row01X && c9R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row01X && c9R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row01X && c9R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col09X && curY === row01X && c9R1VNo === 4) {
        if (c9r1MT === "S") {
        setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
        setC9R2VNo(1);
      } else if (c9r1MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
        setC9R2VNo(1);
        setC9R3VNo(1);
        setC9R4VNo(1);
      }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 5) {
        if (c9r1MT === "S") {
        setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
      } else if (c9r1MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
        setC9R3VNo(3);
        setC9R4VNo(3);
      }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col09X && curY === row01X && c9R1VNo === 7) {
        if (c9r1MT === "S") {
  setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
      } else if (c9r1MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
        setC9R3VNo(2);
        setC9R4VNo(2);
      }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 8) {
        if (c9r1MT === "S") {
          setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r1MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 9) {
        if (c9r1MT === "S") {
  setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r1MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 10) {
        if (c9r1MT === "S") {
  setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r1MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
        if (c9r1MT === "S") {
  setBaseNaked09(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
      } else if (c9r1MT === "M" && c9r2MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r1MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      }     else if (curX === col10X && curY === row01X && c10R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row01X && c10R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row01X && c10R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col10X && curY === row01X && c10R1VNo === 4) {
        if (c10r1MT === "S") {
        setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(1);
      } else if (c10r1MT === "M" && c10r2MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(1);
        setC10R2VNo(1);
      } else if (c10r1MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(1);
        setC10R2VNo(1);
        setC10R3VNo(1);
        setC10R4VNo(1);
      }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 5) {
        if (c10r1MT === "S") {
        setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
      } else if (c10r1MT === "M" && c10r2MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
      } else if (c10r1MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setC10R3VNo(3);
        setC10R4VNo(3);
      }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col10X && curY === row01X && c10R1VNo === 7) {
        if (c10r1MT === "S") {
  setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
      } else if (c10r1MT === "M" && c10r2MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
      } else if (c10r1MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
        setC10R3VNo(2);
        setC10R4VNo(2);
      }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 8) {
        if (c10r1MT === "S") {
  setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
      } else if (c10r1MT === "M" && c10r2MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r1MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 9) {
        if (c10r1MT === "S") {
  setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
      } else if (c10r1MT === "M" && c10r2MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r1MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 10) {
        if (c10r1MT === "S") {
  setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
      } else if (c10r1MT === "M" && c10r2MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r1MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
        if (c10r1MT === "S") {
  setBaseNaked10(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
      } else if (c10r1MT === "M" && c10r2MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r1MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      }     else if (curX === col11X && curY === row01X && c11R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row01X && c11R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row01X && c11R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 4) {
        if (c11r1MT === "S") {
        setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
      } else if (c11r1MT === "M" && c11r2MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
      } else if (c11r1MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
        setC11R3VNo(1);
        setC11R4VNo(1);
      }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 5) {
        if (c11r1MT === "S") {
        setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
      } else if (c11r1MT === "M" && c11r2MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
      } else if (c11r1MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
      }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col11X && curY === row01X && c11R1VNo === 7) {
        if (c11r1MT === "S") {
  setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
      } else if (c11r1MT === "M" && c11r2MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
      } else if (c11r1MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setC11R3VNo(2);
        setC11R4VNo(2);
      }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 8) {
        if (c11r1MT === "S") {
  setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
      } else if (c11r1MT === "M" && c11r2MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r1MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 9) {
        if (c11r1MT === "S") {
  setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
      } else if (c11r1MT === "M" && c11r2MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r1MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
        if (c11r1MT === "S") {
  setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
      } else if (c11r1MT === "M" && c11r2MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r1MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
        if (c11r1MT === "S") {
  setBaseNaked11(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
      } else if (c11r1MT === "M" && c11r2MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r1MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      }     else if (curX === col12X && curY === row01X && c12R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row01X && c12R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row01X && c12R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 4) {
        if (c12r1MT === "S") {
        setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
        setC12R2VNo(1);
      } else if (c12r1MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
        setC12R2VNo(1);
        setC12R3VNo(1);
        setC12R4VNo(1);
      }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 5) {
        if (c12r1MT === "S") {
        setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
      } else if (c12r1MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
        setC12R3VNo(3);
        setC12R4VNo(3);
      }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col12X && curY === row01X && c12R1VNo === 7) {
        if (c12r1MT === "S") {
  setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
      } else if (c12r1MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
        setC12R3VNo(2);
        setC12R4VNo(2);
      }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 8) {
        if (c12r1MT === "S") {
  setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r1MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 9) {
        if (c12r1MT === "S") {
  setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r1MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
        if (c12r1MT === "S") {
  setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r1MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
        if (c12r1MT === "S") {
  setBaseNaked12(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
      } else if (c12r1MT === "M" && c12r2MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r1MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      }     else if (curX === col13X && curY === row01X && c13R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row01X && c13R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row01X && c13R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 4) {
        if (c13r1MT === "S") {
        setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(1);
      } else if (c13r1MT === "M" && c13r2MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(1);
        setC13R2VNo(1);
      } else if (c13r1MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(1);
        setC13R2VNo(1);
        setC13R3VNo(1);
        setC13R4VNo(1);
      }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 5) {
        if (c13r1MT === "S") {
        setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
      } else if (c13r1MT === "M" && c13r2MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
      } else if (c13r1MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
        setC13R3VNo(3);
        setC13R4VNo(3);
      }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col13X && curY === row01X && c13R1VNo === 7) {
        if (c13r1MT === "S") {
  setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
      } else if (c13r1MT === "M" && c13r2MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
      } else if (c13r1MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
        setC13R3VNo(2);
        setC13R4VNo(2);
      }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 8) {
        if (c13r1MT === "S") {
  setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
      } else if (c13r1MT === "M" && c13r2MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r1MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 9) {
        if (c13r1MT === "S") {
  setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
      } else if (c13r1MT === "M" && c13r2MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r1MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
        if (c13r1MT === "S") {
  setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
      } else if (c13r1MT === "M" && c13r2MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r1MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
        if (c13r1MT === "S") {
  setBaseNaked13(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
      } else if (c13r1MT === "M" && c13r2MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r1MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      }     else if (curX === col14X && curY === row01X && c14R1VNo === 1) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row01X && c14R1VNo === 2) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row01X && c14R1VNo === 3) {
          alert("Already removed");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 4) {
        if (c14r1MT === "S") {
        setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
      } else if (c14r1MT === "M" && c14r2MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
      } else if (c14r1MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
        setC14R3VNo(1);
        setC14R4VNo(1);
      }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 5) {
        if (c14r1MT === "S") {
        setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
      } else if (c14r1MT === "M" && c14r2MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
      } else if (c14r1MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setC14R3VNo(3);
        setC14R4VNo(3);
      }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 6) {
     alert("Already removed");
      } else if (curX === col14X && curY === row01X && c14R1VNo === 7) {
        if (c14r1MT === "S") {
  setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
      } else if (c14r1MT === "M" && c14r2MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
      } else if (c14r1MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
        setC14R3VNo(2);
        setC14R4VNo(2);
      }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 8) {
        if (c14r1MT === "S") {
  setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
      } else if (c14r1MT === "M" && c14r2MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r1MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 9) {
        if (c14r1MT === "S") {
  setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
      } else if (c14r1MT === "M" && c14r2MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r1MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
        if (c14r1MT === "S") {
  setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
      } else if (c14r1MT === "M" && c14r2MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r1MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
        if (c14r1MT === "S") {
  setBaseNaked14(
          [
            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
      } else if (c14r1MT === "M" && c14r2MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r1MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } 	else if (curX === col01X && curY === row02X && c1R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row02X && c1R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row02X && c1R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 4) {
        if (c1r2MT === "S") {
        setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(1);
      } else if (c1r2MT === "M" && c1r1MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(1);
        setC1R2VNo(1);

      } else if (c1r2MT === "M" && c1r3MT === "M") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(1);
        setC1R3VNo(1);
      } else if (c1r2MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(1);
        setC1R2VNo(1);
        setC1R3VNo(1);
        setC1R4VNo(1);
      }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 5) {
        if (c1r2MT === "S") {
        setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(3);
      } else if (c1r2MT === "M" && c1r1MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);

      } else if (c1r2MT === "M" && c1r3MT === "M") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(3);
        setC1R3VNo(3);
      } else if (c1r2MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setC1R3VNo(3);
        setC1R4VNo(3);
      }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col01X && curY === row02X && c1R2VNo === 7) {
        if (c1r2MT === "S") {
  setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(2);
      } else if (c1r2MT === "M" && c1r1MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(2);
        setC1R2VNo(2);
      } else if (c1r2MT === "M" && c1r3MT === "M") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(2);
        setC1R3VNo(2);
      } else if (c1r2MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(2);
        setC1R2VNo(2);
        setC1R3VNo(2);
        setC1R4VNo(2);
      }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 8) {
        if (c1r2MT === "S") {
  setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r1MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r3MT === "M") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r2MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 9) {
        if (c1r2MT === "S") {
  setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r1MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r3MT === "M") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r2MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
        if (c1r2MT === "S") {
  setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r1MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r3MT === "M") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r2MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
        if (c1r2MT === "S") {
  setRow2Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r1MT === "M") { 
        setBaseNakedM01(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
      } else if (c1r2MT === "M" && c1r3MT === "M") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r2MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      }     else if (curX === col02X && curY === row02X && c2R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row02X && c2R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row02X && c2R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 4) {
        if (c2r2MT === "S") {
          setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(1);
        } else if (c2r2MT === "M" && c2r1MT === "M") { 
          setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
        } else if (c2r2MT === "M" && c2r3MT === "M") { 
          setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(1);
          setC2R2VNo(1);
        } else if (c2r2MT === "L") {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
        }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 5) {
        if (c2r2MT === "S") {
        setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(3);
      } else if (c2r2MT === "M" && c2r1MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
      } else if (c2r2MT === "M" && c2r3MT === "M") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(3);
        setC2R3VNo(3);
      } else if (c2r2MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setC2R3VNo(3);
        setC2R4VNo(3);
      }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col02X && curY === row02X && c2R2VNo === 7) {
        if (c2r2MT === "S") {
  setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(2);
      } else if (c2r2MT === "M" && c2r1MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
      } else if (c2r2MT === "M" && c2r3MT === "M") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(2);
        setC2R3VNo(2);
      } else if (c2r2MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
        setC2R3VNo(2);
        setC2R4VNo(2);
      }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 8) {
        if (c2r2MT === "S") {
  setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r1MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r3MT === "M") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r2MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
        if (c2r2MT === "S") {
        setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r1MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r3MT === "M") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r2MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
        if (c2r2MT === "S") {
  setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r1MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r3MT === "M") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r2MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
        if (c2r2MT === "S") {
  setRow2Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r1MT === "M") { 
        setBaseNakedM02(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
      } else if (c2r2MT === "M" && c2r3MT === "M") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r2MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      }     else if (curX === col03X && curY === row02X && c3R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row02X && c3R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row02X && c3R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 4) {
        if (c3r2MT === "S") {
        setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(1);
        } else if (c3r2MT === "M" && c3r1MT === "M") { 
          setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
        } else if (c3r2MT === "M" && c3r3MT === "M") { 
          setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
          setC3R2VNo(1);
          setC3R3VNo(1);
        } else if (c3r2MT === "L") {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
        }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 5) {
        if (c3r2MT === "S") {
        setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(3);
      } else if (c3r2MT === "M" && c3r1MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
      } else if (c3r2MT === "M" && c3r3MT === "M") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(3);
        setC3R3VNo(3);
      } else if (c3r2MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
        setC3R3VNo(3);
        setC3R4VNo(3);
      }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col03X && curY === row02X && c3R2VNo === 7) {
        if (c3r2MT === "S") {
  setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(2);
      } else if (c3r2MT === "M" && c3r1MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
      } else if (c3r2MT === "M" && c3r3MT === "M") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(2);
        setC3R3VNo(2);
      } else if (c3r2MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
        setC3R3VNo(2);
        setC3R4VNo(2);
      }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 8) {
        if (c3r2MT === "S") {
  setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r1MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r3MT === "M") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r2MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 9) {
        if (c3r2MT === "S") {
  setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r1MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r3MT === "M") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r2MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
        if (c3r2MT === "S") {
  setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r1MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r3MT === "M") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r2MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
        if (c3r2MT === "S") {
  setRow2Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r1MT === "M") { 
        setBaseNakedM03(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
      } else if (c3r2MT === "M" && c3r3MT === "M") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r2MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      }     else if (curX === col04X && curY === row02X && c4R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row02X && c4R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row02X && c4R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 4) {
        if (c4r2MT === "S") {
        setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(1);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
        setC4R2VNo(1);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(1);
        setC4R3VNo(1);
      } else if (c4r2MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
        setC4R2VNo(1);
        setC4R3VNo(1);
        setC4R4VNo(1);
      }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 5) {
        if (c4r2MT === "S") {
        setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(3);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(3);
        setC4R3VNo(3);
      } else if (c4r2MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
        setC4R3VNo(3);
        setC4R4VNo(3);
      }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col04X && curY === row02X && c4R2VNo === 7) {
        if (c4r2MT === "S") {
  setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(2);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(2);
        setC4R3VNo(2);
      } else if (c4r2MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
        setC4R3VNo(2);
        setC4R4VNo(2);
      }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 8) {
        if (c4r2MT === "S") {
  setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r2MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 9) {
        if (c4r2MT === "S") {
  setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r2MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
        if (c4r2MT === "S") {
  setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r2MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
        if (c4r2MT === "S") {
  setRow2Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r1MT === "M") { 
        setBaseNakedM04(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
      } else if (c4r2MT === "M" && c4r3MT === "M") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r2MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      }     else if (curX === col05X && curY === row02X && c5R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row02X && c5R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row02X && c5R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 4) {
        if (c5r2MT === "S") {
        setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(1);
        } else if (c5r2MT === "M" && c5r1MT === "M") { 
          setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
        } else if (c5r2MT === "M" && c5r3MT === "M") { 
          setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
          setC5R2VNo(1);
          setC5R3VNo(1);
        } else if (c5r2MT === "L") {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
        }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 5) {
        if (c5r2MT === "S") {
        setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(3);
      } else if (c5r2MT === "M" && c5r1MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
      } else if (c5r2MT === "M" && c5r3MT === "M") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(3);
        setC5R3VNo(3);
      } else if (c5r2MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
        setC5R3VNo(3);
        setC5R4VNo(3);
      }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col05X && curY === row02X && c5R2VNo === 7) {
        if (c5r2MT === "S") {
  setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(2);
      } else if (c5r2MT === "M" && c5r1MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
      } else if (c5r2MT === "M" && c5r3MT === "M") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(2);
        setC5R3VNo(2);
      } else if (c5r2MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
        setC5R3VNo(2);
        setC5R4VNo(2);
      }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 8) {
        if (c5r2MT === "S") {
        setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r1MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r3MT === "M") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r2MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
        if (c5r2MT === "S") {
        setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r1MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r3MT === "M") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r2MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
        if (c5r2MT === "S") {
  setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r1MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r3MT === "M") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r2MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
        if (c5r2MT === "S") {
  setRow2Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r1MT === "M") { 
        setBaseNakedM05(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
      } else if (c5r2MT === "M" && c5r3MT === "M") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r2MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      }     else if (curX === col06X && curY === row02X && c6R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row02X && c6R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row02X && c6R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 4) {
        if (c6r2MT === "S") {
        setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(1);
      } else if (c6r2MT === "M" && c6r1MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(1);
        setC6R2VNo(1);
      } else if (c6r2MT === "M" && c6r3MT === "M") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(1);
        setC6R3VNo(1);
      } else if (c6r2MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(1);
        setC6R2VNo(1);
        setC6R3VNo(1);
        setC6R4VNo(1);
      }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 5) {
        if (c6r2MT === "S") {
        setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(3);
      } else if (c6r2MT === "M" && c6r1MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
      } else if (c6r2MT === "M" && c6r3MT === "M") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(3);
        setC6R3VNo(3);
      } else if (c6r2MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
        setC6R3VNo(3);
        setC6R4VNo(3);
      }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col06X && curY === row02X && c6R2VNo === 7) {
        if (c6r2MT === "S") {
  setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(2);
      } else if (c6r2MT === "M" && c6r1MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
      } else if (c6r2MT === "M" && c6r3MT === "M") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(2);
        setC6R3VNo(2);
      } else if (c6r2MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
        setC6R3VNo(2);
        setC6R4VNo(2);
      }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 8) {
        if (c6r2MT === "S") {
  setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r1MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r3MT === "M") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r2MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 9) {
        if (c6r2MT === "S") {
  setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r1MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r3MT === "M") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r2MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
        if (c6r2MT === "S") {
  setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r1MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r3MT === "M") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r2MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
        if (c6r2MT === "S") {
  setRow2Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r1MT === "M") { 
        setBaseNakedM06(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
      } else if (c6r2MT === "M" && c6r3MT === "M") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r2MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      }     else if (curX === col07X && curY === row02X && c7R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row02X && c7R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row02X && c7R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 4) {
        if (c7r2MT === "S") {
        setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(1);
      } else if (c7r2MT === "M" && c7r1MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(1);
        setC7R2VNo(1);
      } else if (c7r2MT === "M" && c7r3MT === "M") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(1);
        setC7R3VNo(1);
      } else if (c7r2MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(1);
        setC7R2VNo(1);
        setC7R3VNo(1);
        setC7R4VNo(1);
      }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 5) {
        if (c7r2MT === "S") {
        setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(3);
      } else if (c7r2MT === "M" && c7r1MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
      } else if (c7r2MT === "M" && c7r3MT === "M") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(3);
        setC7R3VNo(3);
      } else if (c7r2MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
        setC7R3VNo(3);
        setC7R4VNo(3);
      }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col07X && curY === row02X && c7R2VNo === 7) {
        if (c7r2MT === "S") {
  setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(2);
      } else if (c7r2MT === "M" && c7r1MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
      } else if (c7r2MT === "M" && c7r3MT === "M") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(2);
        setC7R3VNo(2);
      } else if (c7r2MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
        setC7R3VNo(2);
        setC7R4VNo(2);
      }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 8) {
        if (c7r2MT === "S") {
  setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r1MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r3MT === "M") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r2MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 9) {
        if (c7r2MT === "S") {
  setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r1MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r3MT === "M") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r2MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
        if (c7r2MT === "S") {
  setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r1MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r3MT === "M") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r2MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
        if (c7r2MT === "S") {
  setRow2Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r1MT === "M") { 
        setBaseNakedM07(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
      } else if (c7r2MT === "M" && c7r3MT === "M") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r2MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      }     else if (curX === col08X && curY === row02X && c8R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row02X && c8R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row02X && c8R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 4) {
        if (c8r2MT === "S") {
        setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(1);
      } else if (c8r2MT === "M" && c8r1MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
        setC8R2VNo(1);
      } else if (c8r2MT === "M" && c8r3MT === "M") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(1);
        setC8R3VNo(1);
      } else if (c8r2MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
        setC8R2VNo(1);
        setC8R3VNo(1);
        setC8R4VNo(1);
      }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 5) {
        if (c8r2MT === "S") {
        setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(3);
      } else if (c8r2MT === "M" && c8r1MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
      } else if (c8r2MT === "M" && c8r3MT === "M") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(3);
        setC8R3VNo(3);
      } else if (c8r2MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setC8R3VNo(3);
        setC8R4VNo(3);
      }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col08X && curY === row02X && c8R2VNo === 7) {
        if (c8r2MT === "S") {
  setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(2);
      } else if (c8r2MT === "M" && c8r1MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
      } else if (c8r2MT === "M" && c8r3MT === "M") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(2);
        setC8R3VNo(2);
      } else if (c8r2MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setC8R3VNo(2);
        setC8R4VNo(2);
      }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 8) {
        if (c8r2MT === "S") {
  setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r1MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r3MT === "M") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r2MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 9) {
        if (c8r2MT === "S") {
  setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r1MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r3MT === "M") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r2MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
        if (c8r2MT === "S") {
  setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r1MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r3MT === "M") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r2MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
        if (c8r2MT === "S") {
  setRow2Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r1MT === "M") { 
        setBaseNakedM08(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
      } else if (c8r2MT === "M" && c8r3MT === "M") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r2MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      }     else if (curX === col09X && curY === row02X && c9R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row02X && c9R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row02X && c9R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col09X && curY === row02X && c9R2VNo === 4) {
        if (c9r2MT === "S") {
        setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(1);
      } else if (c9r2MT === "M" && c9r1MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
        setC9R2VNo(1);
      } else if (c9r2MT === "M" && c9r3MT === "M") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(1);
        setC9R3VNo(1);
      } else if (c9r2MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
        setC9R2VNo(1);
        setC9R3VNo(1);
        setC9R4VNo(1);
      }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 5) {
        if (c9r2MT === "S") {
        setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(3);
      } else if (c9r2MT === "M" && c9r1MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
      } else if (c9r2MT === "M" && c9r3MT === "M") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(3);
        setC9R3VNo(3);
      } else if (c9r2MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
        setC9R3VNo(3);
        setC9R4VNo(3);
      }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col09X && curY === row02X && c9R2VNo === 7) {
        if (c9r2MT === "S") {
  setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(2);
      } else if (c9r2MT === "M" && c9r1MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
      } else if (c9r2MT === "M" && c9r3MT === "M") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(2);
        setC9R3VNo(2);
      } else if (c9r2MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
        setC9R3VNo(2);
        setC9R4VNo(2);
      }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 8) {
        if (c9r2MT === "S") {
  setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r1MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r3MT === "M") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r2MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 9) {
        if (c9r2MT === "S") {
  setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r1MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r3MT === "M") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r2MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 10) {
        if (c9r2MT === "S") {
  setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r1MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r3MT === "M") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r2MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
        if (c9r2MT === "S") {
  setRow2Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r1MT === "M") { 
        setBaseNakedM09(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
      } else if (c9r2MT === "M" && c9r3MT === "M") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r2MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      }     else if (curX === col10X && curY === row02X && c10R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row02X && c10R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row02X && c10R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col10X && curY === row02X && c10R2VNo === 4) {
        if (c10r2MT === "S") {
        setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(1);
        } else if (c10r2MT === "M" && c10r1MT === "M") { 
          setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
        } else if (c10r2MT === "M" && c10r3MT === "M") { 
          setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
          setC10R2VNo(1);
          setC10R3VNo(1);
        } else if (c10r2MT === "L") {
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
          setC10R1VNo(1);
          setC10R2VNo(1);
          setC10R3VNo(1);
          setC10R4VNo(1);
        }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 5) {
        if (c10r2MT === "S") {
        setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(3);
      } else if (c10r2MT === "M" && c10r1MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
      } else if (c10r2MT === "M" && c10r3MT === "M") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(3);
        setC10R3VNo(3);
      } else if (c10r2MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setC10R3VNo(3);
        setC10R4VNo(3);
      }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col10X && curY === row02X && c10R2VNo === 7) {
        if (c10r2MT === "S") {
  setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(2);
      } else if (c10r2MT === "M" && c10r1MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
      } else if (c10r2MT === "M" && c10r3MT === "M") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(2);
        setC10R3VNo(2);
      } else if (c10r2MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
        setC10R3VNo(2);
        setC10R4VNo(2);
      }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 8) {
        if (c10r2MT === "S") {
  setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r1MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r3MT === "M") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r2MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 9) {
        if (c10r2MT === "S") {
  setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r1MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r3MT === "M") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r2MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 10) {
        if (c10r2MT === "S") {
  setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r1MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r3MT === "M") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r2MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
        if (c10r2MT === "S") {
  setRow2Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r1MT === "M") { 
        setBaseNakedM10(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
      } else if (c10r2MT === "M" && c10r3MT === "M") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r2MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      }     else if (curX === col11X && curY === row02X && c11R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row02X && c11R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row02X && c11R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 4) {
        if (c11r2MT === "S") {
        setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(1);
      } else if (c11r2MT === "M" && c11r1MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
      } else if (c11r2MT === "M" && c11r3MT === "M") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(1);
        setC11R3VNo(1);
      } else if (c11r2MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
        setC11R3VNo(1);
        setC11R4VNo(1);
      }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 5) {
        if (c11r2MT === "S") {
        setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(3);
      } else if (c11r2MT === "M" && c11r1MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
      } else if (c11r2MT === "M" && c11r3MT === "M") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(3);
        setC11R3VNo(3);
      } else if (c11r2MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
      }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col11X && curY === row02X && c11R2VNo === 7) {
        if (c11r2MT === "S") {
  setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(2);
      } else if (c11r2MT === "M" && c11r1MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
      } else if (c11r2MT === "M" && c11r3MT === "M") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(2);
        setC11R3VNo(2);
      } else if (c11r2MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setC11R3VNo(2);
        setC11R4VNo(2);
      }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 8) {
        if (c11r2MT === "S") {
  setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r1MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r3MT === "M") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r2MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 9) {
        if (c11r2MT === "S") {
        setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r1MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r3MT === "M") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r2MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
        if (c11r2MT === "S") {
  setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r1MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r3MT === "M") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r2MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
        if (c11r2MT === "S") {
  setRow2Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r1MT === "M") { 
        setBaseNakedM11(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
      } else if (c11r2MT === "M" && c11r3MT === "M") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r2MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      }     else if (curX === col12X && curY === row02X && c12R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row02X && c12R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row02X && c12R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 4) {
        if (c12r2MT === "S") {
        setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(1);
      } else if (c12r2MT === "M" && c12r1MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
        setC12R2VNo(1);
      } else if (c12r2MT === "M" && c12r3MT === "M") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(1);
        setC12R3VNo(1);
      } else if (c12r2MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
        setC12R2VNo(1);
        setC12R3VNo(1);
        setC12R4VNo(1);
      }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 5) {
        if (c12r2MT === "S") {
        setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(3);
      } else if (c12r2MT === "M" && c12r1MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
      } else if (c12r2MT === "M" && c12r3MT === "M") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(3);
        setC12R3VNo(3);
      } else if (c12r2MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
        setC12R3VNo(3);
        setC12R4VNo(3);
      }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col12X && curY === row02X && c12R2VNo === 7) {
        if (c12r2MT === "S") {
  setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(2);
      } else if (c12r2MT === "M" && c12r1MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
      } else if (c12r2MT === "M" && c12r3MT === "M") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(2);
        setC12R3VNo(2);
      } else if (c12r2MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
        setC12R3VNo(2);
        setC12R4VNo(2);
      }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 8) {
        if (c12r2MT === "S") {
  setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r1MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r3MT === "M") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r2MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 9) {
        if (c12r2MT === "S") {
  setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r1MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r3MT === "M") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r2MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
        if (c12r2MT === "S") {
  setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r1MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r3MT === "M") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r2MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
        if (c12r2MT === "S") {
  setRow2Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r1MT === "M") { 
        setBaseNakedM12(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
      } else if (c12r2MT === "M" && c12r3MT === "M") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r2MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      }     else if (curX === col13X && curY === row02X && c13R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row02X && c13R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row02X && c13R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 4) {
        if (c13r2MT === "S") {
        setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(1);
      } else if (c13r2MT === "M" && c13r1MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(1);
        setC13R2VNo(1);
      } else if (c13r2MT === "M" && c13r3MT === "M") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(1);
        setC13R3VNo(1);
      } else if (c13r2MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(1);
        setC13R2VNo(1);
        setC13R3VNo(1);
        setC13R4VNo(1);
      }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 5) {
        if (c13r2MT === "S") {
        setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(3);
      } else if (c13r2MT === "M" && c13r1MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
      } else if (c13r2MT === "M" && c13r3MT === "M") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(3);
        setC13R3VNo(3);
      } else if (c13r2MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
        setC13R3VNo(3);
        setC13R4VNo(3);
      }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col13X && curY === row02X && c13R2VNo === 7) {
        if (c13r2MT === "S") {
  setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(2);
      } else if (c13r2MT === "M" && c13r1MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
      } else if (c13r2MT === "M" && c13r3MT === "M") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(2);
        setC13R3VNo(2);
      } else if (c13r2MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
        setC13R3VNo(2);
        setC13R4VNo(2);
      }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 8) {
        if (c13r2MT === "S") {
  setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r1MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r3MT === "M") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r2MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 9) {
        if (c13r2MT === "S") {
  setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r1MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r3MT === "M") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r2MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
        if (c13r2MT === "S") {
  setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r1MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r3MT === "M") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r2MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
        if (c13r2MT === "S") {
  setRow2Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r1MT === "M") { 
        setBaseNakedM13(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
      } else if (c13r2MT === "M" && c13r3MT === "M") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r2MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      }     else if (curX === col14X && curY === row02X && c14R2VNo === 1) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row02X && c14R2VNo === 2) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row02X && c14R2VNo === 3) {
          alert("Already removed");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 4) {
        if (c14r2MT === "S") {
        setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(1);
      } else if (c14r2MT === "M" && c14r1MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
      } else if (c14r2MT === "M" && c14r3MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(1);
        setC14R3VNo(1);
      } else if (c14r2MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
        setC14R3VNo(1);
        setC14R4VNo(1);
      }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 5) {
        if (c14r2MT === "S") {
        setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(3);
      } else if (c14r2MT === "M" && c14r1MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
      } else if (c14r2MT === "M" && c14r3MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(3);
        setC14R3VNo(3);
      } else if (c14r2MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setC14R3VNo(3);
        setC14R4VNo(3);
      }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 6) {
     alert("Already removed");
      } else if (curX === col14X && curY === row02X && c14R2VNo === 7) {
        if (c14r2MT === "S") {
  setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(2);
      } else if (c14r2MT === "M" && c14r1MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
      } else if (c14r2MT === "M" && c14r3MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(2);
        setC14R3VNo(2);
      } else if (c14r2MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
        setC14R3VNo(2);
        setC14R4VNo(2);
      }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 8) {
        if (c14r2MT === "S") {
  setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r1MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r3MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r2MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 9) {
        if (c14r2MT === "S") {
  setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r1MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r3MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r2MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
        if (c14r2MT === "S") {
  setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r1MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r3MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r2MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
        if (c14r2MT === "S") {
  setRow2Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r1MT === "M") { 
        setBaseNakedM14(
          [
            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
      } else if (c14r2MT === "M" && c14r3MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r2MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } 	else if (curX === col01X && curY === row03X && c1R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row03X && c1R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row03X && c1R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 4) {
        if (c1r3MT === "S") {
        setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(1);
      } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(1);
        setC1R3VNo(1);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(1);
        setC1R4VNo(1);
      } else if (c1r3MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(1);
        setC1R2VNo(1);
        setC1R3VNo(1);
        setC1R4VNo(1);
      }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 5) {
        if (c1r3MT === "S") {
        setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(3);
      } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(3);
        setC1R3VNo(3);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(3);
        setC1R4VNo(3);
      } else if (c1r3MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setC1R3VNo(3);
        setC1R4VNo(3);
      }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col01X && curY === row03X && c1R3VNo === 7) {
        if (c1r3MT === "S") {
  setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(2);
      } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(2);
        setC1R3VNo(2);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(2);
        setC1R4VNo(2);
      } else if (c1r3MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(2);
        setC1R2VNo(2);
        setC1R3VNo(2);
        setC1R4VNo(2);
      }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 8) {
        if (c1r3MT === "S") {
  setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r3MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 9) {
        if (c1r3MT === "S") {
  setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r3MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
        if (c1r3MT === "S") {
  setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r3MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
        if (c1r3MT === "S") {
  setRow3Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r2MT === "M" && c1r4MT === "S") { 
        setRow2NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
          ]
        )
        setC1R2VNo(6);
        setC1R3VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r3MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      }     else if (curX === col02X && curY === row03X && c2R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row03X && c2R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row03X && c2R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 4) {
        if (c2r3MT === "S") {
        setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(1);
        } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
          setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
          setC2R2VNo(1);
          setC2R2VNo(1);
        } else if (c2r3MT === "M" && c2r4MT === "M") { 
          setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
          setC2R3VNo(1);
          setC2R4VNo(1);
        } else if (c2r3MT === "L") {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(1);
          setC2R2VNo(1);
          setC2R3VNo(1);
          setC2R4VNo(1);
        }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 5) {
        if (c2r3MT === "S") {
        setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(3);
      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(3);
        setC2R3VNo(3);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(3);
        setC2R4VNo(3);
      } else if (c2r3MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setC2R3VNo(3);
        setC2R4VNo(3);
      }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col02X && curY === row03X && c2R3VNo === 7) {
        if (c2r3MT === "S") {
  setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(2);
      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(2);
        setC2R3VNo(2);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(2);
        setC2R4VNo(2);
      } else if (c2r3MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
        setC2R3VNo(2);
        setC2R4VNo(2);
      }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 8) {
        if (c2r3MT === "S") {
  setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r3MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
        if (c2r3MT === "S") {
  setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r3MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
        if (c2r3MT === "S") {
  setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r3MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
        if (c2r3MT === "S") {
  setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r2MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(6);
        setC2R3VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r3MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      }     else if (curX === col03X && curY === row03X && c3R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row03X && c3R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row03X && c3R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 4) {
        if (c3r3MT === "S") {
        setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(1);
            } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
              setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                ]
              )
              setC3R2VNo(1);
              setC3R3VNo(1);
            } else if (c3r3MT === "M" && c3r4MT === "M") { 
              setRow3NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                ]
              )
              setC3R3VNo(1);
              setC3R4VNo(1);
            } else if (c3r3MT === "L") {
              setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
              setC3R1VNo(1);
              setC3R2VNo(1);
              setC3R3VNo(1);
              setC3R4VNo(1);
            }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 5) {
        if (c3r3MT === "S") {
        setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(3);
      } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(3);
        setC3R3VNo(3);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(3);
        setC3R4VNo(3);
      } else if (c3r3MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
        setC3R3VNo(3);
        setC3R4VNo(3);
      }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col03X && curY === row03X && c3R3VNo === 7) {
        if (c3r3MT === "S") {
  setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(2);
      } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(2);
        setC3R3VNo(2);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(2);
        setC3R4VNo(2);
      } else if (c3r3MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
        setC3R3VNo(2);
        setC3R4VNo(2);
      }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 8) {
        if (c3r3MT === "S") {
  setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r3MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 9) {
        if (c3r3MT === "S") {
  setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r3MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
        if (c3r3MT === "S") {
  setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r3MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
        if (c3r3MT === "S") {
  setRow3Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r2MT === "M" && c3r4MT === "S") { 
        setRow2NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
          ]
        )
        setC3R2VNo(6);
        setC3R3VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r3MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      }     else if (curX === col04X && curY === row03X && c4R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row03X && c4R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row03X && c4R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 4) {
        if (c4r3MT === "S") {
        setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(1);
      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(1);
        setC4R3VNo(1);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(1);
        setC4R4VNo(1);
      } else if (c4r3MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
        setC4R2VNo(1);
        setC4R3VNo(1);
        setC4R4VNo(1);
      }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 5) {
        if (c4r3MT === "S") {
        setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(3);
      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(3);
        setC4R3VNo(3);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(3);
        setC4R4VNo(3);
      } else if (c4r3MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
        setC4R3VNo(3);
        setC4R4VNo(3);
      }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col04X && curY === row03X && c4R3VNo === 7) {
        if (c4r3MT === "S") {
  setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(2);
      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(2);
        setC4R3VNo(2);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(2);
        setC4R4VNo(2);
      } else if (c4r3MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
        setC4R3VNo(2);
        setC4R4VNo(2);
      }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 8) {
        if (c4r3MT === "S") {
  setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r3MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 9) {
        if (c4r3MT === "S") {
  setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r3MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
        if (c4r3MT === "S") {
  setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r3MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
        if (c4r3MT === "S") {
  setRow3Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r2MT === "M" && c4r4MT === "S") { 
        setRow2NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
          ]
        )
        setC4R2VNo(6);
        setC4R3VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r3MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      }     else if (curX === col05X && curY === row03X && c5R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row03X && c5R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row03X && c5R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 4) {
        if (c5r3MT === "S") {
        setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(1);
      } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(1);
        setC5R3VNo(1);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(1);
        setC5R4VNo(1);
      } else if (c5r3MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(1);
        setC5R2VNo(1);
        setC5R3VNo(1);
        setC5R4VNo(1);
      }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 5) {
        if (c5r3MT === "S") {
        setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(3);
      } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(3);
        setC5R3VNo(3);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(3);
        setC5R4VNo(3);
      } else if (c5r3MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(3);
        setC5R2VNo(3);
        setC5R3VNo(3);
        setC5R4VNo(3);
      }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col05X && curY === row03X && c5R3VNo === 7) {
        if (c5r3MT === "S") {
  setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(2);
      } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(2);
        setC5R3VNo(2);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(2);
        setC5R4VNo(2);
      } else if (c5r3MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
        setC5R3VNo(2);
        setC5R4VNo(2);
      }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 8) {
        if (c5r3MT === "S") {
  setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r3MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
        if (c5r3MT === "S") {
  setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r3MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
        if (c5r3MT === "S") {
  setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r3MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
        if (c5r3MT === "S") {
  setRow3Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r2MT === "M" && c5r4MT === "S") { 
        setRow2NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
          ]
        )
        setC5R2VNo(6);
        setC5R3VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r3MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      }     else if (curX === col06X && curY === row03X && c6R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row03X && c6R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row03X && c6R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 4) {
        if (c6r3MT === "S") {
        setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(1);
      } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(1);
        setC6R3VNo(1);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(1);
        setC6R4VNo(1);
      } else if (c6r3MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(1);
        setC6R2VNo(1);
        setC6R3VNo(1);
        setC6R4VNo(1);
      }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 5) {
        if (c6r3MT === "S") {
        setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(3);
      } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(3);
        setC6R3VNo(3);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(3);
        setC6R4VNo(3);
      } else if (c6r3MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
        setC6R3VNo(3);
        setC6R4VNo(3);
      }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col06X && curY === row03X && c6R3VNo === 7) {
        if (c6r3MT === "S") {
  setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(2);
      } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(2);
        setC6R3VNo(2);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(2);
        setC6R4VNo(2);
      } else if (c6r3MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
        setC6R3VNo(2);
        setC6R4VNo(2);
      }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 8) {
        if (c6r3MT === "S") {
  setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r3MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 9) {
        if (c6r3MT === "S") {
  setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r3MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
        if (c6r3MT === "S") {
  setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r3MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
        if (c6r3MT === "S") {
  setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r2MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
          ]
        )
        setC6R2VNo(6);
        setC6R3VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r3MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      }     else if (curX === col07X && curY === row03X && c7R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row03X && c7R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row03X && c7R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 4) {
        if (c7r3MT === "S") {
        setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(1);
      } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(1);
        setC7R3VNo(1);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(1);
        setC7R4VNo(1);
      } else if (c7r3MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(1);
        setC7R2VNo(1);
        setC7R3VNo(1);
        setC7R4VNo(1);
      }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 5) {
        if (c7r3MT === "S") {
        setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(3);
      } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(3);
        setC7R3VNo(3);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(3);
        setC7R4VNo(3);
      } else if (c7r3MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
        setC7R3VNo(3);
        setC7R4VNo(3);
      }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col07X && curY === row03X && c7R3VNo === 7) {
        if (c7r3MT === "S") {
  setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(2);
      } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(2);
        setC7R3VNo(2);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(2);
        setC7R4VNo(2);
      } else if (c7r3MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
        setC7R3VNo(2);
        setC7R4VNo(2);
      }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 8) {
        if (c7r3MT === "S") {
  setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r3MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 9) {
        if (c7r3MT === "S") {
  setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r3MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
        if (c7r3MT === "S") {
  setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r3MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
        if (c7r3MT === "S") {
  setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r2MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(6);
        setC7R3VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r3MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      }     else if (curX === col08X && curY === row03X && c8R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row03X && c8R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row03X && c8R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col08X && curY === row03X && c8R3VNo === 4) {
        if (c8r3MT === "S") {
        setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(1);
      } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(1);
        setC8R3VNo(1);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(1);
        setC8R4VNo(1);
      } else if (c8r3MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
        setC8R2VNo(1);
        setC8R3VNo(1);
        setC8R4VNo(1);
      }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 5) {
        if (c8r3MT === "S") {
        setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(3);
      } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(3);
        setC8R3VNo(3);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(3);
        setC8R4VNo(3);
      } else if (c8r3MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setC8R3VNo(3);
        setC8R4VNo(3);
      }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col08X && curY === row03X && c8R3VNo === 7) {
        if (c8r3MT === "S") {
  setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(2);
      } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(2);
        setC8R3VNo(2);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(2);
        setC8R4VNo(2);
      } else if (c8r3MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setC8R3VNo(2);
        setC8R4VNo(2);
      }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 8) {
        if (c8r3MT === "S") {
  setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r3MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 9) {
        if (c8r3MT === "S") {
  setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r3MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
        if (c8r3MT === "S") {
  setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r3MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
        if (c8r3MT === "S") {
  setRow3Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r2MT === "M" && c8r4MT === "S") { 
        setRow2NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
          ]
        )
        setC8R2VNo(6);
        setC8R3VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r3MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      }     else if (curX === col09X && curY === row03X && c9R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row03X && c9R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row03X && c9R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col09X && curY === row03X && c9R3VNo === 4) {
        if (c9r3MT === "S") {
        setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(1);
      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(1);
        setC9R3VNo(1);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(1);
        setC9R4VNo(1);
      } else if (c9r3MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
        setC9R2VNo(1);
        setC9R3VNo(1);
        setC9R4VNo(1);
      }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 5) {
        if (c9r3MT === "S") {
        setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(3);
      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(3);
        setC9R3VNo(3);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(3);
        setC9R4VNo(3);
      } else if (c9r3MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
        setC9R3VNo(3);
        setC9R4VNo(3);
      }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col09X && curY === row03X && c9R3VNo === 7) {
        if (c9r3MT === "S") {
  setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(2);
      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(2);
        setC9R3VNo(2);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(2);
        setC9R4VNo(2);
      } else if (c9r3MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
        setC9R3VNo(2);
        setC9R4VNo(2);
      }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 8) {
        if (c9r3MT === "S") {
  setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r3MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 9) {
        if (c9r3MT === "S") {
  setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r3MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 10) {
        if (c9r3MT === "S") {
  setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r3MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
        if (c9r3MT === "S") {
  setRow3Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r2MT === "M" && c9r4MT === "S") { 
        setRow2NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
          ]
        )
        setC9R2VNo(6);
        setC9R3VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r3MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      }     else if (curX === col10X && curY === row03X && c10R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row03X && c10R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row03X && c10R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col10X && curY === row03X && c10R3VNo === 4) {
        if (c10r3MT === "S") {
        setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(1);
      } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(1);
        setC10R3VNo(1);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(1);
        setC10R4VNo(1);
      } else if (c10r3MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(1);
        setC10R2VNo(1);
        setC10R3VNo(1);
        setC10R4VNo(1);
      }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 5) {
        if (c10r3MT === "S") {
        setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(3);
      } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(3);
        setC10R3VNo(3);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(3);
        setC10R4VNo(3);
      } else if (c10r3MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setC10R3VNo(3);
        setC10R4VNo(3);
      }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col10X && curY === row03X && c10R3VNo === 7) {
        if (c10r3MT === "S") {
  setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(2);
      } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(2);
        setC10R3VNo(2);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(2);
        setC10R4VNo(2);
      } else if (c10r3MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
        setC10R3VNo(2);
        setC10R4VNo(2);
      }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 8) {
        if (c10r3MT === "S") {
  setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);
      } else if (c10r3MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 9) {
        if (c10r3MT === "S") {
  setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);

      } else if (c10r3MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 10) {
        if (c10r3MT === "S") {
  setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r2MT === "M" && c10r4MT === "S") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);
      } else if (c10r3MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {
        if (c10r3MT === "S") {
  setRow3Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
          ]
        )
        setC10R2VNo(6);
        setC10R3VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);
      } else if (c10r3MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      }     else if (curX === col11X && curY === row03X && c11R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row03X && c11R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row03X && c11R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 4) {
        if (c11r3MT === "S") {
        setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(1);
      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(1);
        setC11R3VNo(1);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(1);
        setC11R4VNo(1);
      } else if (c11r3MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
        setC11R3VNo(1);
        setC11R4VNo(1);
      }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 5) {
        if (c11r3MT === "S") {
        setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(3);
      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(3);
        setC11R3VNo(3);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(3);
        setC11R4VNo(3);
      } else if (c11r3MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
      }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col11X && curY === row03X && c11R3VNo === 7) {
        if (c11r3MT === "S") {
  setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(2);
      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(2);
        setC11R3VNo(2);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(2);
        setC11R4VNo(2);
      } else if (c11r3MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setC11R3VNo(2);
        setC11R4VNo(2);
      }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 8) {
        if (c11r3MT === "S") {
  setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r3MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 9) {
        if (c11r3MT === "S") {
  setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r3MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
        if (c11r3MT === "S") {
  setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r3MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
        if (c11r3MT === "S") {
  setRow3Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r2MT === "M" && c11r4MT === "S") { 
        setRow2NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
          ]
        )
        setC11R2VNo(6);
        setC11R3VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r3MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      }     else if (curX === col12X && curY === row03X && c12R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row03X && c12R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row03X && c12R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 4) {
        if (c12r3MT === "S") {
        setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(1);
      } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(1);
        setC12R3VNo(1);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(1);
        setC12R4VNo(1);
      } else if (c12r3MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
        setC12R2VNo(1);
        setC12R3VNo(1);
        setC12R4VNo(1);
      }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 5) {
        if (c12r3MT === "S") {
        setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(3);
      } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(3);
        setC12R3VNo(3);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(3);
        setC12R4VNo(3);
      } else if (c12r3MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
        setC12R3VNo(3);
        setC12R4VNo(3);
      }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col12X && curY === row03X && c12R3VNo === 7) {
        if (c12r3MT === "S") {
  setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(2);
      } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(2);
        setC12R3VNo(2);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(2);
        setC12R4VNo(2);
      } else if (c12r3MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
        setC12R3VNo(2);
        setC12R4VNo(2);
      }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 8) {
        if (c12r3MT === "S") {
  setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r3MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 9) {
        if (c12r3MT === "S") {
  setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r3MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
        if (c12r3MT === "S") {
  setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r3MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
        if (c12r3MT === "S") {
  setRow3Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r2MT === "M" && c12r4MT === "S") { 
        setRow2NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
          ]
        )
        setC12R2VNo(6);
        setC12R3VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r3MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      }     else if (curX === col13X && curY === row03X && c13R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row03X && c13R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row03X && c13R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 4) {
        if (c13r3MT === "S") {
        setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(1);
      } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(1);
        setC13R3VNo(1);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(1);
        setC13R4VNo(1);
      } else if (c13r3MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(1);
        setC13R2VNo(1);
        setC13R3VNo(1);
        setC13R4VNo(1);
      }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 5) {
        if (c13r3MT === "S") {
        setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(3);
      } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(3);
        setC13R3VNo(3);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(3);
        setC13R4VNo(3);
      } else if (c13r3MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
        setC13R3VNo(3);
        setC13R4VNo(3);
      }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col13X && curY === row03X && c13R3VNo === 7) {
        if (c13r3MT === "S") {
  setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(2);
      } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(2);
        setC13R3VNo(2);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(2);
        setC13R4VNo(2);
      } else if (c13r3MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
        setC13R3VNo(2);
        setC13R4VNo(2);
      }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 8) {
        if (c13r3MT === "S") {
  setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r3MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 9) {
        if (c13r3MT === "S") {
  setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r3MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
        if (c13r3MT === "S") {
  setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r3MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
        if (c13r3MT === "S") {
  setRow3Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r2MT === "M" && c13r4MT === "S") { 
        setRow2NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
          ]
        )
        setC13R2VNo(6);
        setC13R3VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r3MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      }     else if (curX === col14X && curY === row03X && c14R3VNo === 1) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row03X && c14R3VNo === 2) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row03X && c14R3VNo === 3) {
          alert("Already removed");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 4) {
        if (c14r3MT === "S") {
        setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(1);
      } else if (c14r3MT === "M" && c14r2MT === "M") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(1);
        setC14R3VNo(1);
      } else if (c14r3MT === "M" && c14r4MT === "M" && c14r4MT === "S") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(1);
        setC14R4VNo(1);
      } else if (c14r3MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
        setC14R3VNo(1);
        setC14R4VNo(1);
      }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 5) {
        if (c14r3MT === "S") {
        setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(3);
      } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(3);
        setC14R3VNo(3);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(3);
        setC14R4VNo(3);
      } else if (c14r3MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setC14R3VNo(3);
        setC14R4VNo(3);
      }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 6) {
     alert("Already removed");
      } else if (curX === col14X && curY === row03X && c14R3VNo === 7) {
        if (c14r3MT === "S") {
  setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(2);
      } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(2);
        setC14R3VNo(2);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(2);
        setC14R4VNo(2);
      } else if (c14r3MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
        setC14R3VNo(2);
        setC14R4VNo(2);
      }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 8) {
        if (c14r3MT === "S") {
  setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r3MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 9) {
        if (c14r3MT === "S") {
  setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r3MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
        if (c14r3MT === "S") {
  setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r3MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
        if (c14r3MT === "S") {
  setRow3Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r2MT === "M" && c14r4MT === "S") { 
        setRow2NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
          ]
        )
        setC14R2VNo(6);
        setC14R3VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r3MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } 	else if (curX === col01X && curY === row04X && c1R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row04X && c1R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col01X && curY === row04X && c1R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 4) {
        if (c1r4MT === "S") {
        setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(1);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(1);
        setC1R4VNo(1);
      } else if (c1r4MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(1);
        setC1R2VNo(1);
        setC1R3VNo(1);
        setC1R4VNo(1);
      } 
      } else if (curX === col01X && curY === row04X && c1R4VNo === 5) {
        if (c1r4MT === "S") {
        setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(3);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(3);
        setC1R4VNo(3);
      } else if (c1r4MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(3);
        setC1R2VNo(3);
        setC1R3VNo(3);
        setC1R4VNo(3);
      }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col01X && curY === row04X && c1R4VNo === 7) {
        if (c1r4MT === "S") {
  setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(2);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(2);
        setC1R4VNo(2);
      } else if (c1r4MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(2);
        setC1R2VNo(2);
        setC1R3VNo(2);
        setC1R4VNo(2);
      }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 8) {
        if (c1r4MT === "S") {
  setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r4MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 9) {
        if (c1r4MT === "S") {
  setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r4MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 10) {
        if (c1r4MT === "S") {
  setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r4MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
        if (c1r4MT === "S") {
  setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(6);
      } else if (c1r3MT === "M" && c1r4MT === "M") { 
        setRow3NakedM01(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
          ]
        )
        setC1R3VNo(6);
        setC1R4VNo(6);
      } else if (c1r4MT === "L") {
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )
        setC1R1VNo(6);
        setC1R2VNo(6);
        setC1R3VNo(6);
        setC1R4VNo(6);
      }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row04X && c2R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col02X && curY === row04X && c2R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 4) {
        if (c2r4MT === "S") {
        setRow4Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
          ]
        )
        setC2R4VNo(1);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(1);
        setC2R4VNo(1);
      } else if (c2r4MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(1);
        setC2R2VNo(1);
        setC2R3VNo(1);
        setC2R4VNo(1);
      }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 5) {
        if (c2r4MT === "S") {
        setRow4Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
          ]
        )
        setC2R4VNo(3);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(3);
        setC2R4VNo(3);
      } else if (c2r4MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(3);
        setC2R2VNo(3);
        setC2R3VNo(3);
        setC2R4VNo(3);
      }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col02X && curY === row04X && c2R4VNo === 7) {
        if (c2r4MT === "S") {
  setRow4Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
          ]
        )
        setC2R4VNo(2);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(2);
        setC2R4VNo(2);
      } else if (c2r4MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(2);
        setC2R2VNo(2);
        setC2R3VNo(2);
        setC2R4VNo(2);
      }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 8) {
        if (c2r4MT === "S") {
  setRow4Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
          ]
        )
        setC2R4VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r4MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 9) {
        if (c2r4MT === "S") {
  setRow4Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
          ]
        )
        setC2R4VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r4MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 10) {
        if (c2r4MT === "S") {
  setRow4Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
          ]
        )
        setC2R4VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r4MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      } else if (curX === col02X && curY === row04X && c2R4VNo === 11) {
        if (c2r4MT === "S") {
  setRow4Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
          ]
        )
        setC2R4VNo(6);
      } else if (c2r3MT === "M" && c2r4MT === "M") { 
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(6);
        setC2R4VNo(6);
      } else if (c2r4MT === "L") {
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )
        setC2R1VNo(6);
        setC2R2VNo(6);
        setC2R3VNo(6);
        setC2R4VNo(6);
      }
      }     else if (curX === col03X && curY === row04X && c3R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row04X && c3R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col03X && curY === row04X && c3R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 4) {
        if (c3r4MT === "S") {
        setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setC3R4VNo(1);
        } else if (c3r3MT === "M" && c3r4MT === "M") { 
          setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
          setC3R3VNo(1);
          setC3R4VNo(1);
        } else if (c3r4MT === "L") {
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
          setC3R1VNo(1);
          setC3R2VNo(1);
          setC3R3VNo(1);
          setC3R4VNo(1);
        }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 5) {
        if (c3r4MT === "S") {
        setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setC3R4VNo(3);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(3);
        setC3R4VNo(3);
      } else if (c3r4MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(3);
        setC3R2VNo(3);
        setC3R3VNo(3);
        setC3R4VNo(3);
      }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col03X && curY === row04X && c3R4VNo === 7) {
        if (c3r4MT === "S") {
  setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setC3R4VNo(2);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(2);
        setC3R4VNo(2);
      } else if (c3r4MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(2);
        setC3R2VNo(2);
        setC3R3VNo(2);
        setC3R4VNo(2);
      }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 8) {
        if (c3r4MT === "S") {
  setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setC3R4VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r4MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 9) {
        if (c3r4MT === "S") {
  setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setC3R4VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r4MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 10) {
        if (c3r4MT === "S") {
  setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setC3R4VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r4MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {
        if (c3r4MT === "S") {
  setRow4Naked03(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
          ]
        )
        setC3R4VNo(6);
      } else if (c3r3MT === "M" && c3r4MT === "M") { 
        setRow3NakedM03(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
          ]
        )
        setC3R3VNo(6);
        setC3R4VNo(6);
      } else if (c3r4MT === "L") {
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )
        setC3R1VNo(6);
        setC3R2VNo(6);
        setC3R3VNo(6);
        setC3R4VNo(6);
      }
      }     else if (curX === col04X && curY === row04X && c4R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row04X && c4R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col04X && curY === row04X && c4R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 4) {
        if (c4r4MT === "S") {
        setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setC4R4VNo(1);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(1);
        setC4R4VNo(1);
      } else if (c4r4MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(1);
        setC4R2VNo(1);
        setC4R3VNo(1);
        setC4R4VNo(1);
      }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 5) {
        if (c4r4MT === "S") {
        setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setC4R4VNo(3);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(3);
        setC4R4VNo(3);
      } else if (c4r4MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(3);
        setC4R2VNo(3);
        setC4R3VNo(3);
        setC4R4VNo(3);
      }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col04X && curY === row04X && c4R4VNo === 7) {
        if (c4r4MT === "S") {
  setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setC4R4VNo(2);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(2);
        setC4R4VNo(2);
      } else if (c4r4MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(2);
        setC4R2VNo(2);
        setC4R3VNo(2);
        setC4R4VNo(2);
      }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 8) {
        if (c4r4MT === "S") {
  setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setC4R4VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r4MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 9) {
        if (c4r4MT === "S") {
  setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setC4R4VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r4MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 10) {
        if (c4r4MT === "S") {
  setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setC4R4VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r4MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {
        if (c4r4MT === "S") {
  setRow4Naked04(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
          ]
        )
        setC4R4VNo(6);
      } else if (c4r3MT === "M" && c4r4MT === "M") { 
        setRow3NakedM04(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
          ]
        )
        setC4R3VNo(6);
        setC4R4VNo(6);
      } else if (c4r4MT === "L") {
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )
        setC4R1VNo(6);
        setC4R2VNo(6);
        setC4R3VNo(6);
        setC4R4VNo(6);
      }
      }     else if (curX === col05X && curY === row04X && c5R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row04X && c5R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col05X && curY === row04X && c5R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 4) {
        if (c5r4MT === "S") {
        setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setC5R4VNo(1);
        } else if (c5r3MT === "M" && c5r4MT === "M") { 
          setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(1);
          setC5R4VNo(1);
        } else if (c5r4MT === "L") {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(1);
          setC5R2VNo(1);
          setC5R3VNo(1);
          setC5R4VNo(1);
        }
      } else if (curX === col05X && curY === row04X && c5R4VNo === 5) {
        if (c5r4MT === "S") {
        setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setC5R4VNo(3);
        } else if (c5r3MT === "M" && c5r4MT === "M") { 
          setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
          setC5R3VNo(3);
          setC5R4VNo(3);
        } else if (c5r4MT === "L") {
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
          setC5R1VNo(3);
          setC5R2VNo(3);
          setC5R3VNo(3);
          setC5R4VNo(3);
        }
      } else if (curX === col05X && curY === row04X && c5R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col05X && curY === row04X && c5R4VNo === 7) {
        if (c5r4MT === "S") {
  setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setC5R4VNo(2);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(2);
        setC5R4VNo(2);
      } else if (c5r4MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(2);
        setC5R2VNo(2);
        setC5R3VNo(2);
        setC5R4VNo(2);
      }
      } else if (curX === col05X && curY === row04X && c5R4VNo === 8) {
        if (c5r4MT === "S") {
  setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setC5R4VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r4MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row04X && c5R4VNo === 9) {
        if (c5r4MT === "S") {
  setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setC5R4VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r4MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row04X && c5R4VNo === 10) {
        if (c5r4MT === "S") {
  setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setC5R4VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r4MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {
        if (c5r4MT === "S") {
  setRow4Naked05(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
          ]
        )
        setC5R4VNo(6);
      } else if (c5r3MT === "M" && c5r4MT === "M") { 
        setRow3NakedM05(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
          ]
        )
        setC5R3VNo(6);
        setC5R4VNo(6);
      } else if (c5r4MT === "L") {
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )
        setC5R1VNo(6);
        setC5R2VNo(6);
        setC5R3VNo(6);
        setC5R4VNo(6);
      }
      }     else if (curX === col06X && curY === row04X && c6R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row04X && c6R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col06X && curY === row04X && c6R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 4) {
        if (c6r4MT === "S") {
        setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setC6R4VNo(1);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(1);
        setC6R4VNo(1);
      } else if (c6r4MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(1);
        setC6R2VNo(1);
        setC6R3VNo(1);
        setC6R4VNo(1);
      }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 5) {
        if (c6r4MT === "S") {
        setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setC6R4VNo(3);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(3);
        setC6R4VNo(3);
      } else if (c6r4MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(3);
        setC6R2VNo(3);
        setC6R3VNo(3);
        setC6R4VNo(3);
      }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col06X && curY === row04X && c6R4VNo === 7) {
        if (c6r4MT === "S") {
  setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setC6R4VNo(2);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(2);
        setC6R4VNo(2);
      } else if (c6r4MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(2);
        setC6R2VNo(2);
        setC6R3VNo(2);
        setC6R4VNo(2);
      }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 8) {
        if (c6r4MT === "S") {
  setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setC6R4VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r4MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 9) {
        if (c6r4MT === "S") {
  setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setC6R4VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r4MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 10) {
        if (c6r4MT === "S") {
  setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setC6R4VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r4MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {
        if (c6r4MT === "S") {
  setRow4Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
          ]
        )
        setC6R4VNo(6);
      } else if (c6r3MT === "M" && c6r4MT === "M") { 
        setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
          ]
        )
        setC6R3VNo(6);
        setC6R4VNo(6);
      } else if (c6r4MT === "L") {
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )
        setC6R1VNo(6);
        setC6R2VNo(6);
        setC6R3VNo(6);
        setC6R4VNo(6);
      }
      }     else if (curX === col07X && curY === row04X && c7R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row04X && c7R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col07X && curY === row04X && c7R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 4) {
        if (c7r4MT === "S") {
        setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setC7R4VNo(1);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(1);
        setC7R4VNo(1);
      } else if (c7r4MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(1);
        setC7R2VNo(1);
        setC7R3VNo(1);
        setC7R4VNo(1);
      }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 5) {
        if (c7r4MT === "S") {
        setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setC7R4VNo(3);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(3);
        setC7R4VNo(3);
      } else if (c7r4MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(3);
        setC7R2VNo(3);
        setC7R3VNo(3);
        setC7R4VNo(3);
      }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col07X && curY === row04X && c7R4VNo === 7) {
        if (c7r4MT === "S") {
  setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setC7R4VNo(2);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(2);
        setC7R4VNo(2);
      } else if (c7r4MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(2);
        setC7R2VNo(2);
        setC7R3VNo(2);
        setC7R4VNo(2);
      }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 8) {
        if (c7r4MT === "S") {
  setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setC7R4VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r4MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 9) {
        if (c7r4MT === "S") {
  setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setC7R4VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r4MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 10) {
        if (c7r4MT === "S") {
  setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setC7R4VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r4MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {
        if (c7r4MT === "S") {
  setRow4Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
          ]
        )
        setC7R4VNo(6);
      } else if (c7r3MT === "M" && c7r4MT === "M") { 
        setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(6);
        setC7R4VNo(6);
      } else if (c7r4MT === "L") {
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )
        setC7R1VNo(6);
        setC7R2VNo(6);
        setC7R3VNo(6);
        setC7R4VNo(6);
      }
      }     else if (curX === col08X && curY === row04X && c8R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row04X && c8R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col08X && curY === row04X && c8R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 4) {
        if (c8r4MT === "S") {
        setRow4Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
          ]
        )
        setC8R4VNo(1);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(1);
        setC8R4VNo(1);
      } else if (c8r4MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(1);
        setC8R2VNo(1);
        setC8R3VNo(1);
        setC8R4VNo(1);
      }
      } else if (curX === col08X && curY === row04X && c8R4VNo === 5) {
        if (c8r4MT === "S") {
        setRow4Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
          ]
        )
        setC8R4VNo(3);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(3);
        setC8R4VNo(3);
      } else if (c8r4MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(3);
        setC8R2VNo(3);
        setC8R3VNo(3);
        setC8R4VNo(3);
      }
      } else if (curX === col08X && curY === row04X && c8R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col08X && curY === row04X && c8R4VNo === 7) {
        if (c8r4MT === "S") {
  setRow4Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
          ]
        )
        setC8R4VNo(2);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(2);
        setC8R4VNo(2);
      } else if (c8r4MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(2);
        setC8R2VNo(2);
        setC8R3VNo(2);
        setC8R4VNo(2);
      }
      } else if (curX === col08X && curY === row04X && c8R4VNo === 8) {
        if (c8r4MT === "S") {
  setRow4Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
          ]
        )
        setC8R4VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r4MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row04X && c8R4VNo === 9) {
        if (c8r4MT === "S") {
  setRow4Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
          ]
        )
        setC8R4VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r4MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row04X && c8R4VNo === 10) {
        if (c8r4MT === "S") {
  setRow4Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
          ]
        )
        setC8R4VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r4MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {
        if (c8r4MT === "S") {
  setRow4Naked08(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
          ]
        )
        setC8R4VNo(6);
      } else if (c8r3MT === "M" && c8r4MT === "M") { 
        setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(6);
        setC8R4VNo(6);
      } else if (c8r4MT === "L") {
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )
        setC8R1VNo(6);
        setC8R2VNo(6);
        setC8R3VNo(6);
        setC8R4VNo(6);
      }
      }     else if (curX === col09X && curY === row04X && c9R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row04X && c9R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col09X && curY === row04X && c9R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col09X && curY === row04X && c9R4VNo === 4) {
        if (c9r4MT === "S") {
        setRow4Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
          ]
        )
        setC9R4VNo(1);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(1);
        setC9R4VNo(1);
      } else if (c9r4MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(1);
        setC9R2VNo(1);
        setC9R3VNo(1);
        setC9R4VNo(1);
      }
      } else if (curX === col09X && curY === row04X && c9R4VNo === 5) {
        if (c9r4MT === "S") {
        setRow4Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
          ]
        )
        setC9R4VNo(3);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(3);
        setC9R4VNo(3);
      } else if (c9r4MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(3);
        setC9R2VNo(3);
        setC9R3VNo(3);
        setC9R4VNo(3);
      }
      } else if (curX === col09X && curY === row04X && c9R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col09X && curY === row04X && c9R4VNo === 7) {
        if (c9r4MT === "S") {
  setRow4Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
          ]
        )
        setC9R4VNo(2);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(2);
        setC9R4VNo(2);
      } else if (c9r4MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(2);
        setC9R2VNo(2);
        setC9R3VNo(2);
        setC9R4VNo(2);
      }
      } else if (curX === col09X && curY === row04X && c9R4VNo === 8) {
        if (c9r4MT === "S") {
  setRow4Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
          ]
        )
        setC9R4VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r4MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row04X && c9R4VNo === 9) {
        if (c9r4MT === "S") {
  setRow4Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
          ]
        )
        setC9R4VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r4MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row04X && c9R4VNo === 10) {
        if (c9r4MT === "S") {
  setRow4Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
          ]
        )
        setC9R4VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r4MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      } else if (curX === col09X && curY === row04X && c9R4VNo === 11) {
        if (c9r4MT === "S") {
  setRow4Naked09(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
          ]
        )
        setC9R4VNo(6);
      } else if (c9r3MT === "M" && c9r4MT === "M") { 
        setRow3NakedM09(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
          ]
        )
        setC9R3VNo(6);
        setC9R4VNo(6);
      } else if (c9r4MT === "L") {
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )
        setC9R1VNo(6);
        setC9R2VNo(6);
        setC9R3VNo(6);
        setC9R4VNo(6);
      }
      }     else if (curX === col10X && curY === row04X && c10R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row04X && c10R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col10X && curY === row04X && c10R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col10X && curY === row04X && c10R4VNo === 4) {
        if (c10r4MT === "S") {
        setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setC10R4VNo(1);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(1);
        setC10R4VNo(1);
      } else if (c10r4MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(1);
        setC10R2VNo(1);
        setC10R3VNo(1);
        setC10R4VNo(1);
      }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 5) {
        if (c10r4MT === "S") {
        setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setC10R4VNo(3);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(3);
        setC10R4VNo(3);
      } else if (c10r4MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(3);
        setC10R2VNo(3);
        setC10R3VNo(3);
        setC10R4VNo(3);
      }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col10X && curY === row04X && c10R4VNo === 7) {
        if (c10r4MT === "S") {
  setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setC10R4VNo(2);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(2);
        setC10R4VNo(2);
      } else if (c10r4MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(2);
        setC10R2VNo(2);
        setC10R3VNo(2);
        setC10R4VNo(2);
      }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 8) {
        if (c10r4MT === "S") {
  setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setC10R4VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);
      } else if (c10r4MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 9) {
        if (c10r4MT === "S") {
  setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setC10R4VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);
      } else if (c10r4MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 10) {
        if (c10r4MT === "S") {
  setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setC10R4VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);
      } else if (c10r4MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      } else if (curX === col10X && curY === row04X && c10R4VNo === 11) {
        if (c10r4MT === "S") {
  setRow4Naked10(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
          ]
        )
        setC10R4VNo(6);
      } else if (c10r3MT === "M" && c10r4MT === "M") { 
        setRow3NakedM10(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
          ]
        )
        setC10R3VNo(6);
        setC10R4VNo(6);
      } else if (c10r4MT === "L") {
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )
        setC10R1VNo(6);
        setC10R2VNo(6);
        setC10R3VNo(6);
        setC10R4VNo(6);
      }
      }     else if (curX === col11X && curY === row04X && c11R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row04X && c11R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col11X && curY === row04X && c11R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 4) {
        if (c11r4MT === "S") {
        setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setC11R4VNo(1);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(1);
        setC11R4VNo(1);
      } else if (c11r4MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(1);
        setC11R2VNo(1);
        setC11R3VNo(1);
        setC11R4VNo(1);
      }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 5) {
        if (c11r4MT === "S") {
        setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setC11R4VNo(3);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(3);
        setC11R4VNo(3);
      } else if (c11r4MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(3);
        setC11R2VNo(3);
        setC11R3VNo(3);
        setC11R4VNo(3);
      }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col11X && curY === row04X && c11R4VNo === 7) {
        if (c11r4MT === "S") {
  setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setC11R4VNo(2);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(2);
        setC11R4VNo(2);
      } else if (c11r4MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(2);
        setC11R2VNo(2);
        setC11R3VNo(2);
        setC11R4VNo(2);
      }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 8) {
        if (c11r4MT === "S") {
  setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setC11R4VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r4MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 9) {
        if (c11r4MT === "S") {
  setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setC11R4VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r4MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 10) {
        if (c11r4MT === "S") {
  setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setC11R4VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r4MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {
        if (c11r4MT === "S") {
  setRow4Naked11(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
          ]
        )
        setC11R4VNo(6);
      } else if (c11r3MT === "M" && c11r4MT === "M") { 
        setRow3NakedM11(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
          ]
        )
        setC11R3VNo(6);
        setC11R4VNo(6);
      } else if (c11r4MT === "L") {
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )
        setC11R1VNo(6);
        setC11R2VNo(6);
        setC11R3VNo(6);
        setC11R4VNo(6);
      }
      }     else if (curX === col12X && curY === row04X && c12R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row04X && c12R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col12X && curY === row04X && c12R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 4) {
        if (c12r4MT === "S") {
        setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setC12R4VNo(1);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(1);
        setC12R4VNo(1);
      } else if (c12r4MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(1);
        setC12R2VNo(1);
        setC12R3VNo(1);
        setC12R4VNo(1);
      }
      } else if (curX === col12X && curY === row04X && c12R4VNo === 5) {
        if (c12r4MT === "S") {
        setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setC12R4VNo(3);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(3);
        setC12R4VNo(3);
      } else if (c12r4MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(3);
        setC12R2VNo(3);
        setC12R3VNo(3);
        setC12R4VNo(3);
      }
      } else if (curX === col12X && curY === row04X && c12R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col12X && curY === row04X && c12R4VNo === 7) {
        if (c12r4MT === "S") {
  setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setC12R4VNo(2);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(2);
        setC12R4VNo(2);
      } else if (c12r4MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(2);
        setC12R2VNo(2);
        setC12R3VNo(2);
        setC12R4VNo(2);
      }
      } else if (curX === col12X && curY === row04X && c12R4VNo === 8) {
        if (c12r4MT === "S") {
  setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setC12R4VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r4MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row04X && c12R4VNo === 9) {
        if (c12r4MT === "S") {
  setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setC12R4VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r4MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row04X && c12R4VNo === 10) {
        if (c12r4MT === "S") {
  setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setC12R4VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r4MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {
        if (c12r4MT === "S") {
  setRow4Naked12(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
          ]
        )
        setC12R4VNo(6);
      } else if (c12r3MT === "M" && c12r4MT === "M") { 
        setRow3NakedM12(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
          ]
        )
        setC12R3VNo(6);
        setC12R4VNo(6);
      } else if (c12r4MT === "L") {
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )
        setC12R1VNo(6);
        setC12R2VNo(6);
        setC12R3VNo(6);
        setC12R4VNo(6);
      }
      }     else if (curX === col13X && curY === row04X && c13R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row04X && c13R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col13X && curY === row04X && c13R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 4) {
        if (c13r4MT === "S") {
        setRow4Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
          ]
        )
        setC13R4VNo(1);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(1);
        setC13R4VNo(1);
      } else if (c13r4MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(1);
        setC13R2VNo(1);
        setC13R3VNo(1);
        setC13R4VNo(1);
      }
      } else if (curX === col13X && curY === row04X && c13R4VNo === 5) {
        if (c13r4MT === "S") {
        setRow4Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
          ]
        )
        setC13R4VNo(3);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(3);
        setC13R4VNo(3);
      } else if (c13r4MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(3);
        setC13R2VNo(3);
        setC13R3VNo(3);
        setC13R4VNo(3);
      }
      } else if (curX === col13X && curY === row04X && c13R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col13X && curY === row04X && c13R4VNo === 7) {
        if (c13r4MT === "S") {
  setRow4Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
          ]
        )
        setC13R4VNo(2);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(2);
        setC13R4VNo(2);
      } else if (c13r4MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(2);
        setC13R2VNo(2);
        setC13R3VNo(2);
        setC13R4VNo(2);
      }
      } else if (curX === col13X && curY === row04X && c13R4VNo === 8) {
        if (c13r4MT === "S") {
  setRow4Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
          ]
        )
        setC13R4VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r4MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row04X && c13R4VNo === 9) {
        if (c13r4MT === "S") {
  setRow4Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
          ]
        )
        setC13R4VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r4MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row04X && c13R4VNo === 10) {
        if (c13r4MT === "S") {
  setRow4Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
          ]
        )
        setC13R4VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r4MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {
        if (c13r4MT === "S") {
  setRow4Naked13(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
          ]
        )
        setC13R4VNo(6);
      } else if (c13r3MT === "M" && c13r4MT === "M") { 
        setRow3NakedM13(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
          ]
        )
        setC13R3VNo(6);
        setC13R4VNo(6);
      } else if (c13r4MT === "L") {
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )
        setC13R1VNo(6);
        setC13R2VNo(6);
        setC13R3VNo(6);
        setC13R4VNo(6);
      }
      }     else if (curX === col14X && curY === row04X && c14R4VNo === 1) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row04X && c14R4VNo === 2) {
        alert("Already removed");     
      } else if (curX === col14X && curY === row04X && c14R4VNo === 3) {
          alert("Already removed");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 4) {
        if (c14r4MT === "S") {
        setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setC14R4VNo(1);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(1);
        setC14R4VNo(1);
      } else if (c14r4MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(1);
        setC14R2VNo(1);
        setC14R3VNo(1);
        setC14R4VNo(1);
      }
      } else if (curX === col14X && curY === row04X && c14R4VNo === 5) {
        if (c14r4MT === "S") {
        setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setC14R4VNo(3);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(3);
        setC14R4VNo(3);
      } else if (c14r4MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(3);
        setC14R2VNo(3);
        setC14R3VNo(3);
        setC14R4VNo(3);
      }
      } else if (curX === col14X && curY === row04X && c14R4VNo === 6) {
     alert("Already removed");
      } else if (curX === col14X && curY === row04X && c14R4VNo === 7) {
        if (c14r4MT === "S") {
  setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setC14R4VNo(2);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(2);
        setC14R4VNo(2);
      } else if (c14r4MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(2);
        setC14R2VNo(2);
        setC14R3VNo(2);
        setC14R4VNo(2);
      }
      } else if (curX === col14X && curY === row04X && c14R4VNo === 8) {
        if (c14r4MT === "S") {
  setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setC14R4VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r4MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row04X && c14R4VNo === 9) {
        if (c14r4MT === "S") {
  setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setC14R4VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r4MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row04X && c14R4VNo === 10) {
        if (c14r4MT === "S") {
  setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setC14R4VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r4MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
        if (c14r4MT === "S") {
  setRow4Naked14(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
          ]
        )
        setC14R4VNo(6);
      } else if (c14r3MT === "M" && c14r4MT === "M") { 
        setRow3NakedM14(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(6);
        setC14R4VNo(6);
      } else if (c14r4MT === "L") {
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )
        setC14R1VNo(6);
        setC14R2VNo(6);
        setC14R3VNo(6);
        setC14R4VNo(6);
      }
      }
   
  }

  const addRoundHandle = (e)=> {

      if (curX === col01X && curY === row01X && c1R1VNo === 11) {
    
        if (c1r1MT === "S") {  
          setBaseNaked01(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(9);
                    noHandleCount = noHandleCount - 1;
          } else if (c1r1MT === "M") {  
               setBaseNakedM01(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                    ]
                  )
                  setC1R1VNo(9);
                  setC1R2VNo(9);
                  noHandleCount = noHandleCount - 1;
                 
          } else if (c1r1MT === "L") {  
             alert("Round Handle cannot be added to the Large Door.");
          }
    
      } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
    
        if (c2r1MT === "S") {  
          setBaseNaked02(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                     ]
                   )
                   setC2R1VNo(9);
                   noHandleCount = noHandleCount - 1;
         } else if (c2r1MT === "M") {  
          setBaseNakedM02(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                   ]
                 )
                 setC2R1VNo(9);
                 setC2R2VNo(9);
                 noHandleCount = noHandleCount - 1;
         } else if (c2r1MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
         }	           
         
      } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
    
        if (c3r1MT === "S") {  
          setBaseNaked03(
                    [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />      
                    ]
                  )
                  setC3R1VNo(9);
                  noHandleCount = noHandleCount - 1;
        } else if (c3r1MT === "M") {  
        setBaseNakedM03(
                  [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />      
                  ]
                )
                setC3R1VNo(9);
                setC3R2VNo(9);
                noHandleCount = noHandleCount - 1;
        } else if (c3r1MT === "L") {  
           alert("Round Handle cannot be added to the Large Door.");
        }
       
      } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
        if (c4r1MT === "S") {  
          setBaseNaked04(
                   [
                     <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />       
                 ]
                 )
                 setC4R1VNo(9);
                 noHandleCount = noHandleCount - 1;
       } else if (c4r1MT === "M") {  
               setBaseNakedM04(
                 [
                   <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />       
               ]
               )
               setC4R1VNo(9);
               setC4R2VNo(9);
               noHandleCount = noHandleCount - 1;
       } else if (c4r1MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
       }

      } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
    
        if (c5r1MT === "S") {  
          setBaseNaked05(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />       
                     ]
                    )
                    setC5R1VNo(9);
                    noHandleCount = noHandleCount - 1;
          } else if (c5r1MT === "M") {  
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />       
                   ]
                  )
                  setC5R1VNo(9);
                  setC5R2VNo(9);
                  noHandleCount = noHandleCount - 1;
          } else if (c5r1MT === "L") {  
             alert("Round Handle cannot be added to the Large Door.");
          }
          
        
      } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
    
        if (c6r1MT === "S") {  
          setBaseNaked06(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />     
                     ]
                   )
                   setC6R1VNo(9);
                   noHandleCount = noHandleCount - 1;
         } else if (c6r1MT === "M") {  
                setBaseNakedM06(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />     
                   ]
                 )
                 setC6R1VNo(9);
                 setC6R2VNo(9);
                 noHandleCount = noHandleCount - 1;
         } else if (c6r1MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
         }
       
      } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
    
        if (c7r1MT === "S") {  
          setBaseNaked07(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(9);
                   noHandleCount = noHandleCount - 1;
         } else if (c7r1MT === "M") {  
               setBaseNakedM07(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                   ]
                 )
                 setC7R1VNo(9);
                 setC7R2VNo(9);
                 noHandleCount = noHandleCount - 1;
         } else if (c7r1MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
         }
      
      } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
     
        if (c8r1MT === "S") {  
          setBaseNaked08(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                     ]
                   )
                   setC8R1VNo(9);
                   noHandleCount = noHandleCount - 1;
         } else if (c8r1MT === "M") {  
                setBaseNakedM08(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                   ]
                 )
                 setC8R1VNo(9);
                 setC8R2VNo(9);
                 noHandleCount = noHandleCount - 1;
         } else if (c8r1MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
         }
       
      } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
    
        if (c9r1MT === "S") {  
          setBaseNaked09(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                     ]
                   )
                   setC9R1VNo(9);
                   noHandleCount = noHandleCount - 1;
         } else if (c9r1MT === "M") {  
                setBaseNakedM09(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                   ]
                 )
                 setC9R1VNo(9);
                 setC9R2VNo(9);
                 noHandleCount = noHandleCount - 1;
         } else if (c9r1MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
         }   
        
      } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
        if (c10r1MT === "S") {  
          setBaseNaked10(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                     ]
                   )
                   setC10R1VNo(9);
                   noHandleCount = noHandleCount - 1;
         } else if (c10r1MT === "M") {  
                 setBaseNakedM10(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                   ]
                 )
                 setC10R1VNo(9);
                 setC10R2VNo(9);
                 noHandleCount = noHandleCount - 1;
         } else if (c10r1MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
         }	           
      
      } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
    
        if (c11r1MT === "S") {  
          setBaseNaked11(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(9);
                    noHandleCount = noHandleCount - 1;
          } else if (c11r1MT === "M") {  
                  setBaseNakedM11(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(9);
                  setC11R2VNo(9);
                  noHandleCount = noHandleCount - 1;
          } else if (c11r1MT === "L") {  
             alert("Round Handle cannot be added to the Large Door.");
          }
      
      } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
        if (c12r1MT === "S") {  
          setBaseNaked12(
                     [
                       <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(9);
                   noHandleCount = noHandleCount - 1;
         } else if (c12r1MT === "M") {  
                 setBaseNakedM12(
                   [
                     <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                   ]
                 )
                 setC12R1VNo(9);
                 setC12R2VNo(9);
                 noHandleCount = noHandleCount - 1;
         } else if (c12r1MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
         }
    
      } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
    
        if (c13r1MT === "S") {  
          setBaseNaked13(
                  [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} /> 
                  ]
                )
                setC13R1VNo(9);
                noHandleCount = noHandleCount - 1;
        } else if (c13r1MT === "M") {  
              setBaseNakedM13(
                [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} /> 
                ]
              )
              setC13R1VNo(9);
              setC13R2VNo(9);
              noHandleCount = noHandleCount - 1;
           
        } else if (c13r1MT === "L") {  
           alert("Round Handle cannot be added to the Large Door.");
        }
       
      } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
        if (c14r1MT === "S") {  
          setBaseNaked14(
                  [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                  ]
                )
                setC14R1VNo(9);
                noHandleCount = noHandleCount - 1;
        } else if (c14r1MT === "M") {  
             setBaseNakedM14(
                [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(9);
              setC14R2VNo(9);
              noHandleCount = noHandleCount - 1;
           
        } else if (c14r1MT === "L") {  
           alert("Round Handle cannot be added to the Large Door.");
        }
    
      } 	else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                 ]
               )
               setC1R2VNo(9);
               noHandleCount = noHandleCount - 1;
              } else if (c1r1MT === "M" && c1r2MT === "M") { 
                setBaseNakedM01(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                  ]
                )
                setC1R1VNo(9);
                setC1R2VNo(9);
                noHandleCount = noHandleCount - 1;
              } else if (c1r2MT === "M" && c1r3MT === "M") {    
           setRow2NakedM01(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
               ]
             )
             setC1R2VNo(9);
             setC1R3VNo(9);
             noHandleCount = noHandleCount - 1;
          
       } else if (c1r2MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
       }
  
      } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
        if (c2r2MT === "S") {  
          setRow2Naked02(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                 ]
               )
               setC2R2VNo(9);
               noHandleCount = noHandleCount - 1;
              } else if (c2r1MT === "M" && c2r2MT === "M") { 
                setBaseNakedM02(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                  ]
                )
                setC2R1VNo(9);
                setC2R2VNo(9);
                noHandleCount = noHandleCount - 1;
              } else if (c2r2MT === "M" && c2r3MT === "M") {    
           setRow2NakedM02(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
               ]
             )
             setC2R2VNo(9);
             setC2R3VNo(9);
             noHandleCount = noHandleCount - 1;
       } else if (c2r2MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
       }
    } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
      if (c3r2MT === "S") {  
        setRow2Naked03(
               [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />      
               ]
             )
             setC3R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c3r1MT === "M" && c3r2MT === "M") { 
              setBaseNakedM03(
                [
                   <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />      
                ]
              )
              setC3R1VNo(9);
              setC3R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c3r2MT === "M" && c3r3MT === "M") {  
          setRow2NakedM03(
             [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />      
             ]
           )
           setC3R2VNo(9);
           setC3R3VNo(9);
           noHandleCount = noHandleCount - 1;
        
     } else if (c3r2MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
     
    } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
  
      if (c4r2MT === "S") {  
        setRow2Naked04(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />       
             ]
             )
             setC4R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c4r1MT === "M" && c4r2MT === "M") { 
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />       
              ]
              )
              setC4R1VNo(9);
              setC4R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c4r2MT === "M" && c4r3MT === "M") {   
           setRow2NakedM04(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />       
           ]
           )
           setC4R2VNo(9);
           setC4R3VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c4r2MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
     
    } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
  
      if (c5r2MT === "S") {  
        setRow2Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />       
               ]
              )
              setC5R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c5r1MT === "M" && c5r2MT === "M") { 
              setBaseNakedM05(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />       
               ]
              )
              setC5R1VNo(9);
              setC5R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c5r2MT === "M" && c5r3MT === "M") {   
           setRow2NakedM05(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />       
             ]
            )
            setC5R2VNo(9);
            setC5R3VNo(9);
            noHandleCount = noHandleCount - 1;
      } else if (c5r2MT === "L") {  
         alert("Round Handle cannot be added to the Large Door.");
      }
      
    } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
  
      if (c6r2MT === "S") {  
        setRow2Naked06(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />     
               ]
             )
             setC6R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c6r1MT === "M" && c6r2MT === "M") { 
              setBaseNakedM06(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />     
                ]
              )
              setC6R1VNo(9);
              setC6R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c6r2MT === "M" && c6r3MT === "M") {    
          setRow2NakedM06(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />     
             ]
           )
           setC6R2VNo(9);
           setC6R3VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c6r2MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
     
    } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
  
      if (c7r2MT === "S") {  
        setRow2Naked07(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
              ]
            )
                setC7R2VNo(9);
                noHandleCount = noHandleCount - 1;
              } else if (c7r1MT === "M" && c7r2MT === "M") { 
                setBaseNakedM07(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                  ]
                )
                setC7R1VNo(9);
                setC7R2VNo(9);
                noHandleCount = noHandleCount - 1;
              } else if (c7r2MT === "M" && c7r3MT === "M") {     
               
              setRow2NakedM07(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                ]
              )
              setC7R2VNo(9);
              setC7R3VNo(9);
              noHandleCount = noHandleCount - 1;
        } else if (c7r2MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
        }
    
    } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
  
      if (c8r2MT === "S") {  
        setRow2Naked08(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
               ]
             )
             setC8R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c8r1MT === "M" && c8r2MT === "M") { 
              setBaseNakedM08(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                ]
              )
              setC8R1VNo(9);
              setC8R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c8r2MT === "M" && c8r3MT === "M") { 
          setRow2NakedM08(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
             ]
           )
           setC8R2VNo(9);
           setC8R3VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c8r2MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
     
    } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
  
      if (c9r2MT === "S") {  
        setRow2Naked09(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
               ]
             )
             setC9R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c9r1MT === "M" && c9r2MT === "M") { 
              setBaseNakedM09(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                ]
              )
              setC9R1VNo(9);
              setC9R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c9r2MT === "M" && c9r3MT === "M") {    
             setRow2NakedM09(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
             ]
           )
           setC9R2VNo(9);
           setC9R3VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c9r2MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }	       
      
    } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
      if (c10r2MT === "S") {  
        setRow2Naked10(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
               ]
             )
             setC10R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c10r1MT === "M" && c10r2MT === "M") { 
              setBaseNakedM10(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                ]
              )
              setC10R1VNo(9);
              setC10R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c10r2MT === "M" && c10r3MT === "M") {   
            setRow2NakedM10(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
             ]
           )
           setC10R2VNo(9);
           setC10R3VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c10r2MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
    
    } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {
      if (c11r2MT === "S") {  
        setRow2Naked11(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
               ]
             )
             setC11R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c11r1MT === "M" && c11r2MT === "M") { 
              setBaseNakedM11(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                ]
              )
              setC11R1VNo(9);
              setC11R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c11r2MT === "M" && c11r3MT === "M") {     
            setRow2NakedM11(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
             ]
           )
           setC11R2VNo(9);
           setC11R3VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c11r2MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
    
    } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
      if (c12r2MT === "S") {  
        setRow2Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                ]
              )
              setC12R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c12r1MT === "M" && c12r2MT === "M") { 
              setBaseNakedM12(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                ]
              )
              setC12R1VNo(9);
              setC12R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c12r2MT === "M" && c12r3MT === "M") {   
             setRow2NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(9);
            setC12R3VNo(9);
            noHandleCount = noHandleCount - 1;
      } else if (c12r2MT === "L") {  
         alert("Round Handle cannot be added to the Large Door.");
      }
        
    } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
      if (c13r2MT === "S") {  
        setRow2Naked13(
              [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} /> 
              ]
            )
            setC13R2VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c13r1MT === "M" && c13r2MT === "M") { 
            setBaseNakedM13(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
              ]
            )
            setC13R1VNo(9);
            setC13R2VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c13r2MT === "M" && c13r3MT === "M") {   
           setRow2NakedM13(
            [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} /> 
            ]
          )
          setC13R2VNo(9);
          setC13R3VNo(9);
      } else if (c13r2MT === "L") {  
         alert("Round Handle cannot be added to the Large Door.");
      }
      
    } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {
      if (c14r2MT === "S") {  
        setRow2Naked14(
               [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(9);
             noHandleCount = noHandleCount - 1;
            } else if (c14r1MT === "M" && c14r2MT === "M") { 
              setBaseNakedM14(
                [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                ]
              )
              setC14R1VNo(9);
              setC14R2VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c14r2MT === "M" && c14r3MT === "M") {
            setRow2NakedM14(
               [
                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
               ]
             )
             setC14R2VNo(9);
             setC14R3VNo(9);
             noHandleCount = noHandleCount - 1;
       } else if (c14r2MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
       }

  } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {
    
    if (c1r3MT === "S") {  
      setRow3Naked01(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(9);
           noHandleCount = noHandleCount - 1;
          } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
            setRow2NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
              ]
            )
            setC1R2VNo(9);
            setC1R3VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c1r3MT === "M" && c1r4MT === "M") { 
         setRow3NakedM01(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
           ]
         )
         setC1R3VNo(9);
         setC1R4VNo(9);
         noHandleCount = noHandleCount - 1;
     } else if (c1r3MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }

  } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
    if (c2r3MT === "S") {  
      setRow3Naked02(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
        setRow2NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
          ]
        )
        setC2R2VNo(9);
        setC2R3VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c2r3MT === "M" && c2r4MT === "M") {  
      setRow3NakedM02(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
        ]
      )
      setC2R3VNo(9);
      setC2R4VNo(9);
      noHandleCount = noHandleCount - 1;
  } else if (c2r3MT === "L") {  
     alert("Round Handle cannot be added to the Large Door.");
  }
  
  } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {

    if (c3r3MT === "S") {  
      setRow3Naked03(
           [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />      
           ]
         )
         setC3R3VNo(9);
         noHandleCount = noHandleCount - 1;
        } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
          setRow2NakedM03(
            [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />      
            ]
          )
          setC3R2VNo(9);
          setC3R3VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c3r3MT === "M" && c3r4MT === "M") {  
       setRow3NakedM03(
         [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />      
         ]
       )
       setC3R3VNo(9);
       setC3R4VNo(9);
       noHandleCount = noHandleCount - 1;
   } else if (c3r3MT === "L") {  
      alert("Round Handle cannot be added to the Large Door.");
   }
   
  } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
    if (c4r3MT === "S") {  
      setRow3Naked04(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />       
         ]
         )
         setC4R3VNo(9);
         noHandleCount = noHandleCount - 1;
        } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
          setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />       
          ]
          )
          setC4R2VNo(9);
          setC4R3VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c4r3MT === "M" && c4r4MT === "M") {
       setRow3NakedM04(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />       
       ]
       )
       setC4R3VNo(9);
       setC4R4VNo(9);
       noHandleCount = noHandleCount - 1;
   } else if (c4r3MT === "L") {  
      alert("Round Handle cannot be added to the Large Door.");
   }
   
  } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {

    if (c5r3MT === "S") {  
      setRow3Naked05(
           [
             <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />       
          ]
         )
         setC5R3VNo(9);
         noHandleCount = noHandleCount - 1;
        } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
          setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />       
           ]
          )
          setC5R2VNo(9);
          setC5R3VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c5r3MT === "M" && c5r4MT === "M") {  
          setRow3NakedM05(
         [
           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />       
        ]
       )
       setC5R3VNo(9);
       setC5R4VNo(9);
       noHandleCount = noHandleCount - 1;
   } else if (c5r3MT === "L") {  
      alert("Round Handle cannot be added to the Large Door.");
   }	     
    
  } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
    if (c6r3MT === "S") {  
      setRow3Naked06(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />     
          ]
        )
        setC6R3VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
        setRow2NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />     
          ]
        )
        setC6R2VNo(9);
        setC6R3VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c6r3MT === "M" && c6r4MT === "M") {   
        setRow3NakedM06(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />     
        ]
      )
      setC6R3VNo(9);
      setC6R4VNo(9);
      noHandleCount = noHandleCount - 1;
  } else if (c6r3MT === "L") {  
     alert("Round Handle cannot be added to the Large Door.");
  } 
   
  } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {

    if (c7r3MT === "S") {  
      setRow3Naked07(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
        setRow2NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
          ]
        )
        setC7R2VNo(9);
        setC7R3VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c7r3MT === "M" && c7r4MT === "M") {  
       setRow3NakedM07(
        [
          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
        ]
      )
      setC7R3VNo(9);
      setC7R4VNo(9);
      noHandleCount = noHandleCount - 1;
  } else if (c7r3MT === "L") {  
     alert("Round Handle cannot be added to the Large Door.");
  } 
  
  } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
          if (c8r3MT === "S") {  
            setRow3Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(9);
              setC8R3VNo(9);
              noHandleCount = noHandleCount - 1;
            } else if (c8r3MT === "M" && c8r4MT === "M") {  
          setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(9);
            setC8R4VNo(9);
            noHandleCount = noHandleCount - 1;
        } else if (c8r3MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
        }
   
  } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
          if (c9r3MT === "S") {  
            setRow3Naked09(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
            setRow2NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
              ]
            )
            setC9R2VNo(9);
            setC9R3VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c9r3MT === "M" && c9r4MT === "M") {   
          setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
          setC9R3VNo(9);
          setC9R4VNo(9);
          noHandleCount = noHandleCount - 1;
      } else if (c9r3MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
      }	  
    
  } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {

              if (c10r3MT === "S") {  
                setRow3Naked10(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                  ]
                )
                setC10R3VNo(9);
                noHandleCount = noHandleCount - 1;
              } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                setRow2NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(9);
                setC10R3VNo(9);
                noHandleCount = noHandleCount - 1;
              } else if (c10r3MT === "M" && c10r4MT === "M") {   
              setRow3NakedM10(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                ]
              )
              setC10R3VNo(9);
              setC10R4VNo(9);
              noHandleCount = noHandleCount - 1;
          } else if (c10r3MT === "L") {  
              alert("Round Handle cannot be added to the Large Door.");
          }
  
  } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
          if (c11r3MT === "S") {  
            setRow3Naked11(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
            setRow2NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
              ]
            )
            setC11R2VNo(9);
            setC11R3VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c11r3MT === "M" && c11r4MT === "M") { 
        
          setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
          setC11R3VNo(9);
          setC11R4VNo(9);
          noHandleCount = noHandleCount - 1;
      } else if (c11r3MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
      }	  
  
  } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
          if (c12r3MT === "S") {  
            setRow3Naked12(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
            setRow2NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
              ]
            )
            setC12R2VNo(9);
            setC12R3VNo(9);
            noHandleCount = noHandleCount - 1;
          } else if (c12r3MT === "M" && c12r4MT === "M") { 
          setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
          setC12R3VNo(9);
          setC12R4VNo(9);
          noHandleCount = noHandleCount - 1;
      } else if (c12r3MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
      }

  } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
      if (c13r3MT === "S") {  
            setRow3Naked13(
            [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} /> 
            ]
          )
          setC13R3VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
          setRow2NakedM13(
            [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} /> 
            ]
          )
          setC13R2VNo(9);
          setC13R3VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c13r3MT === "M" && c13r4MT === "M") {  
            setRow3NakedM13(
          [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} /> 
          ]
        )
        setC13R3VNo(9);
        setC13R4VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c13r3MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
      }	  
    
  } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
        if (c14r3MT === "S") {  
        
          setRow3Naked14(
            [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
          setRow2NakedM14(
            [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
          setC14R2VNo(9);
          setC14R3VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c14r3MT === "M" && c14r4MT === "M") {  
          setRow3NakedM14(
          [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(9);
        setC14R4VNo(9);
        noHandleCount = noHandleCount - 1;
      } else if (c14r3MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
      }
    } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
    
      if (c1r4MT === "S") {  
        setRow4Naked01(
               [
                 <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
               ]
             )
             setC1R4VNo(9);
             noHandleCount = noHandleCount - 1;
       } else if (c1r4MT === "M") {  
           setRow3NakedM01(
             [
               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
             ]
           )
           setC1R3VNo(9);
           setC1R4VNo(9);
           noHandleCount = noHandleCount - 1;
       } else if (c1r4MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
       }
  
    } else if (curX === col02X && curY === row04X && c2R4VNo === 11) {
      if (c2r4MT === "S") {  
        setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
            ]
          )
          setC2R4VNo(9);
          noHandleCount = noHandleCount - 1;
    } else if (c2r4MT === "M") {  
        setRow3NakedM02(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
          ]
        )
        setC2R3VNo(9);
        setC2R4VNo(9);
        noHandleCount = noHandleCount - 1;
    } else if (c2r4MT === "L") {  
       alert("Round Handle cannot be added to the Large Door.");
    }
    
    } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {
  
      if (c3r4MT === "S") {  
        setRow4Naked03(
             [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />      
             ]
           )
           setC3R4VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c3r4MT === "M") {  
         setRow3NakedM03(
           [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />      
           ]
         )
         setC3R3VNo(9);
         setC3R4VNo(9);
         noHandleCount = noHandleCount - 1;
     } else if (c3r4MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
     
    } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {
      if (c4r4MT === "S") {  
        setRow4Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />       
           ]
           )
           setC4R4VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c4r4MT === "M") {  
         setRow3NakedM04(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />       
         ]
         )
         setC4R3VNo(9);
         setC4R4VNo(9);
         noHandleCount = noHandleCount - 1;
     } else if (c4r4MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }
     
    } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {
  
      if (c5r4MT === "S") {  
        setRow4Naked05(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />       
            ]
           )
           setC5R4VNo(9);
           noHandleCount = noHandleCount - 1;
     } else if (c5r4MT === "M") {  
            setRow3NakedM05(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />       
          ]
         )
         setC5R3VNo(9);
         setC5R4VNo(9);
         noHandleCount = noHandleCount - 1;
     } else if (c5r4MT === "L") {  
        alert("Round Handle cannot be added to the Large Door.");
     }	     
      
    } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {
      if (c6r4MT === "S") {  
        setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />     
            ]
          )
          setC6R4VNo(9);
          noHandleCount = noHandleCount - 1;
    } else if (c6r4MT === "M") {  
          setRow3NakedM06(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />     
          ]
        )
        setC6R3VNo(9);
        setC6R4VNo(9);
        noHandleCount = noHandleCount - 1;
    } else if (c6r4MT === "L") {  
       alert("Round Handle cannot be added to the Large Door.");
    } 
     
    } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {
  
      if (c7r4MT === "S") {  
        setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
            ]
          )
          setC7R4VNo(9);
          noHandleCount = noHandleCount - 1;
    } else if (c7r4MT === "M") {  
         setRow3NakedM07(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
          ]
        )
        setC7R3VNo(9);
        setC7R4VNo(9);
        noHandleCount = noHandleCount - 1;
    } else if (c7r4MT === "L") {  
       alert("Round Handle cannot be added to the Large Door.");
    } 
    
    } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {
            if (c8r4MT === "S") {  
              setRow4Naked08(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                  ]
                )
                setC8R4VNo(9);
                noHandleCount = noHandleCount - 1;
          } else if (c8r4MT === "M") {  
            setRow3NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(9);
              setC8R4VNo(9);
              noHandleCount = noHandleCount - 1;
          } else if (c8r4MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
          }
     
    } else if (curX === col09X && curY === row04X && c9R4VNo === 11) {
            if (c9r4MT === "S") {  
              setRow4Naked09(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                ]
              )
              setC9R4VNo(9);
              noHandleCount = noHandleCount - 1;
        } else if (c9r4MT === "M") {  
            setRow3NakedM09(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
              ]
            )
            setC9R3VNo(9);
            setC9R4VNo(9);
            noHandleCount = noHandleCount - 1;
        } else if (c9r4MT === "L") {  
            alert("Round Handle cannot be added to the Large Door.");
        }	  
      
    } else if (curX === col10X && curY === row04X && c10R4VNo === 11) {
  
                if (c10r4MT === "S") {  
                  setRow4Naked10(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                    ]
                  )
                  setC10R4VNo(9);
                  noHandleCount = noHandleCount - 1;
            } else if (c10r4MT === "M") {  
                setRow3NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                  ]
                )
                setC10R3VNo(9);
                setC10R4VNo(9);
                noHandleCount = noHandleCount - 1;
            } else if (c10r4MT === "L") {  
                alert("Round Handle cannot be added to the Large Door.");
            }
    
    } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {
            if (c11r4MT === "S") {  
              setRow4Naked11(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                ]
              )
              setC11R4VNo(9);
              noHandleCount = noHandleCount - 1;
        } else if (c11r4MT === "M") {  
          
            setRow3NakedM11(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
              ]
            )
            setC11R3VNo(9);
            setC11R4VNo(9);
            noHandleCount = noHandleCount - 1;
        } else if (c11r4MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
        }	  
    
    } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {
            if (c12r4MT === "S") {  
              setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(9);
              noHandleCount = noHandleCount - 1;
        } else if (c12r4MT === "M") {  
            setRow3NakedM12(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
              ]
            )
            setC12R3VNo(9);
            setC12R4VNo(9);
            noHandleCount = noHandleCount - 1;
        } else if (c12r4MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
        }
  
    } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {
        if (c13r4MT === "S") {  
              setRow4Naked13(
              [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} /> 
              ]
            )
            setC13R4VNo(9);
            noHandleCount = noHandleCount - 1;
        } else if (c13r4MT === "M") {  
              setRow3NakedM13(
            [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} /> 
            ]
          )
          setC13R3VNo(9);
          setC13R4VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c13r4MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
        }	  
      
    } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
          if (c14r4MT === "S") {  
          
            setRow4Naked14(
              [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
              ]
            )
            setC14R4VNo(9);
            noHandleCount = noHandleCount - 1;
        } else if (c14r4MT === "M") {  
            setRow3NakedM14(
            [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
          setC14R3VNo(9);
          setC14R4VNo(9);
          noHandleCount = noHandleCount - 1;
        } else if (c14r4MT === "L") {  
          alert("Round Handle cannot be added to the Large Door.");
        }
  
  } else {
      alert("You cannot add handle at this stage.")	
  }

    
}

    const removeRoundHandle = (e)=> {

        if (curX === col01X && curY === row01X  && c1R1VNo === 9) {
      
          if (c1r1MT === "S") {  
            setBaseNaked01(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                        setC1R1VNo(11);
                        noHandleCount = noHandleCount + 1;
            } else if (c1r1MT === "M") {  
                setBaseNakedM01(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                        ]
                      )
                      setC1R1VNo(11);
                      setC1R2VNo(11);
                      noHandleCount = noHandleCount + 1;
            } else if (c1r1MT === "L") {  
              alert("The Large Doors have no round Handles/knobs.");
            }	  
        
      
        } else if (curX === col02X && curY === row01X && c2R1VNo === 9) {
      
          if (c2r1MT === "S") {  
            setBaseNaked02(
                      [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(11);
                        noHandleCount = noHandleCount + 1;
            } else if (c2r1MT === "M") {  
                setBaseNakedM02(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                        ]
                      )
                      setC2R1VNo(11);
                      setC2R2VNo(11);
                      noHandleCount = noHandleCount + 1;
            } else if (c2r1MT === "L") {  
              alert("The Large Doors have no round Handles/knobs.");
            }
        
        } else if (curX === col03X && curY === row01X   && c3R1VNo === 9) {
          if (c3r1MT === "S") {  
                setBaseNaked03(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />      
                          ]
                        )
                        setC3R1VNo(11);
                        noHandleCount = noHandleCount + 1;
            } else if (c3r1MT === "M") {  
                setBaseNakedM03(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />      
                        ]
                      )
                      setC3R1VNo(11);
                      setC3R2VNo(11);
                      noHandleCount = noHandleCount + 1;
            } else if (c3r1MT === "L") {  
              alert("The Large Doors have no round Handles/knobs.");
            }	  
        
         
        } else if (curX === col04X && curY === row01X   && c4R1VNo === 9) {
      
                if (c4r1MT === "S") {  
                  setBaseNaked04(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />       
                          ]
                          )
                          setC4R1VNo(1);
                          noHandleCount = noHandleCount + 1;
              } else if (c4r1MT === "M") {  
                  setBaseNakedM04(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />       
                        ]
                        )
                        setC4R1VNo(11);
                        setC4R2VNo(11);
                        noHandleCount = noHandleCount + 1;
              } else if (c4r1MT === "L") {  
                alert("The Large Doors have no round Handles/knobs.");
              }
         
        } else if (curX === col05X && curY === row01X && c5R1VNo === 9) {
                if (c5r1MT === "S") {  
                  setBaseNaked05(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />       
                            ]
                          )
                          setC5R1VNo(11);
                          noHandleCount = noHandleCount + 1;
              } else if (c5r1MT === "M") {  
                    setBaseNakedM05(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />       
                          ]
                        )
                        setC5R1VNo(11);
                        setC5R2VNo(11);
                        noHandleCount = noHandleCount + 1;
              } else if (c5r1MT === "L") {  
                  alert("The Large Doors have no round Handles/knobs.");
              }
          
        } else if (curX === col06X && curY === row01X  && c6R1VNo === 9) {
      
              if (c6r1MT === "S") {  
                setBaseNaked06(
                          [
                            <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />     
                          ]
                        )
                        setC6R1VNo(1);
                        noHandleCount = noHandleCount + 1;
            } else if (c6r1MT === "M") {  
                  setBaseNakedM06(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />     
                        ]
                      )
                      setC6R1VNo(11);
                      setC6R2VNo(11);
                      noHandleCount = noHandleCount + 1;
            } else if (c6r1MT === "L") {  
                alert("The Large Doors have no round Handles/knobs.");
            }
         
        } else if (curX === col07X && curY === row01X  && c7R1VNo === 9) {
      
          if (c7r1MT === "S") {  
            setBaseNaked07(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                       ]
                     )
                     setC7R1VNo(11);
                     noHandleCount = noHandleCount + 1;
         } else if (c7r1MT === "M") {  
             setBaseNakedM07(
                     [
                       <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                     ]
                   )
                   setC7R1VNo(11);
                   setC7R2VNo(11);
                   noHandleCount = noHandleCount + 1;
         } else if (c7r1MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }	           
        
        } else if (curX === col08X && curY === row01X  && c8R1VNo === 9) {
      
          if (c8r1MT === "S") {  
            setBaseNaked08(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                        ]
                      )
                      setC8R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c8r1MT === "M") {  
               setBaseNakedM08(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(11);
                    setC8R2VNo(11);
                    noHandleCount = noHandleCount + 1;
          } else if (c8r1MT === "L") {  
             alert("The Large Doors have no round Handles/knobs.");
          }
         
        } else if (curX === col09X && curY === row01X  && c9R1VNo === 9) {
          if (c9r1MT === "S") {  
            setBaseNaked09(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                        ]
                      )
                      setC9R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c9r1MT === "M") {  
               setBaseNakedM09(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(11);
                    setC9R2VNo(11);
                    noHandleCount = noHandleCount + 1;
          } else if (c9r1MT === "L") {  
             alert("The Large Doors have no round Handles/knobs.");
          }
     
        } else if (curX === col10X && curY === row01X  && c10R1VNo === 9) {
      
          if (c10r1MT === "S") {  
            setBaseNaked10(
                         [
                           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                         ]
                       )
                       setC10R1VNo(11);
                       noHandleCount = noHandleCount + 1;
           } else if (c10r1MT === "M") {  
                setBaseNakedM10(
                       [
                         <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                       ]
                     )
                     setC10R1VNo(11);
                     setC10R2VNo(11);
                     noHandleCount = noHandleCount + 1;
           } else if (c10r1MT === "L") {  
              alert("The Large Doors have no round Handles/knobs.");
           }
        
        } else if (curX === col11X && curY === row01X  && c11R1VNo === 9) {
          if (c11r1MT === "S") {  
            setBaseNaked11(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                        ]
                      )
                      setC11R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c11r1MT === "M") {  
               setBaseNakedM11(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(11);
                    setC11R2VNo(11);
                    noHandleCount = noHandleCount + 1;
          } else if (c11r1MT === "L") {  
             alert("The Large Doors have no round Handles/knobs.");
          }
           
        } else if (curX === col12X && curY === row01X  && c12R1VNo === 9) {
          if (c12r1MT === "S") {  
            setBaseNaked12(
                       [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                       ]
                     )
                     setC12R1VNo(1);
                     noHandleCount = noHandleCount + 1;
         } else if (c12r1MT === "M") {  
              setBaseNakedM12(
                     [
                       <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                     ]
                   )
                   setC12R1VNo(11);
                   setC12R2VNo(11);
                   noHandleCount = noHandleCount + 1;
         } else if (c12r1MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }
        } else if (curX === col13X && curY === row01X  && c13R1VNo === 9) {
          if (c13r1MT === "S") {  
            setBaseNaked13(
                     [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} /> 
                     ]
                   )
                   setC13R1VNo(1);
                   noHandleCount = noHandleCount + 1;
         } else if (c13r1MT === "M") {  
             setBaseNakedM13(
                   [
                       <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} /> 
                   ]
                 )
                 setC13R1VNo(11);
                 setC13R2VNo(11);
                 noHandleCount = noHandleCount + 1;
         } else if (c13r1MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }	  
                      
        } else if (curX === col14X && curY === row01X  && c14R1VNo === 9) {
          if (c14r1MT === "S") {  
            setBaseNaked14(
                     [
                        <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                     ]
                   )
                   setC14R1VNo(11);
                   noHandleCount = noHandleCount + 1;
         } else if (c14r1MT === "M") {  
             setBaseNakedM14(
                   [
                       <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                   ]
                 )
                 setC14R1VNo(11);
                 setC14R2VNo(11);
                 noHandleCount = noHandleCount + 1;
         } else if (c14r1MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }	  
         
              
        } 	else if (curX === col01X && curY === row02X  && c1R2VNo === 9) {
      
                if (c1r2MT === "S") {  
                  setRow2Naked01(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                          ]
                        )
                        setC1R2VNo(11);
                        noHandleCount = noHandleCount + 1;
                      } else if (c1r1MT === "M" && c1r2MT === "M") { 
                        setBaseNakedM01(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(11);
                        setC1R2VNo(11);
                        noHandleCount = noHandleCount + 1;
                      } else if (c1r2MT === "M" && c1r3MT === "M") {  
                  setRow2NakedM01(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                        ]
                      )
                      setC1R2VNo(11);
                      setC1R3VNo(11);
                      noHandleCount = noHandleCount + 1;
              } else if (c1r2MT === "L") {  
                alert("The Large Doors have no round Handles/knobs.");
              }	  
        
      } else if (curX === col02X && curY === row02X && c2R2VNo === 9) {
          if (c2r2MT === "S") {  
              setRow2Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c2r1MT === "M" && c2r2MT === "M") { 
                    setBaseNakedM02(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(11);
                    setC2R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c2r2MT === "M" && c2r3MT === "M") {     
                  setRow2NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(11);
                  setC2R3VNo(11);
                  noHandleCount = noHandleCount + 1;
          } else if (c2r2MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
          }
       
      } else if (curX === col03X && curY === row02X   && c3R2VNo === 9) {
                if (c3r2MT === "S") {  
                  setRow2Naked03(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />      
                          ]
                        )
                        setC3R2VNo(11);
                        noHandleCount = noHandleCount + 1;
                      } else if (c3r1MT === "M" && c3r2MT === "M") { 
                        setBaseNakedM03(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />      
                          ]
                        )
                        setC3R1VNo(11);
                        setC3R2VNo(11);
                        noHandleCount = noHandleCount + 1;
                      } else if (c3r2MT === "M" && c3r3MT === "M") {      
                      setRow2NakedM03(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />      
                        ]
                      )
                      setC3R2VNo(11);
                      setC3R3VNo(11);
                      noHandleCount = noHandleCount + 1;
              } else if (c3r2MT === "L") {  
                alert("The Large Doors have no round Handles/knobs.");
              }
         
      } else if (curX === col04X && curY === row02X   && c4R2VNo === 9) {
            if (c4r2MT === "S") {  
                setRow2Naked04(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />       
                      ]
                      )
                      setC4R2VNo(1);
                      noHandleCount = noHandleCount + 1;
                    } else if (c4r1MT === "M" && c4r2MT === "M") { 
                      setBaseNakedM04(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />       
                      ]
                      )
                      setC4R1VNo(11);
                      setC4R2VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c4r2MT === "M" && c4r3MT === "M") {      
                  setRow2NakedM04(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />       
                    ]
                    )
                    setC4R2VNo(11);
                    setC4R3VNo(11);
                    noHandleCount = noHandleCount + 1;
            } else if (c4r2MT === "L") {  
              alert("The Large Doors have no round Handles/knobs.");
            }	  
      
      } else if (curX === col05X && curY === row02X && c5R2VNo === 9) {
              if (c5r2MT === "S") {  
                setRow2Naked05(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />       
                        ]
                      )
                      setC5R2VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c5r1MT === "M" && c5r2MT === "M") { 
                      setBaseNakedM05(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />       
                        ]
                      )
                      setC5R1VNo(11);
                      setC5R2VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c5r2MT === "M" && c5r3MT === "M") {       
                  setRow2NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />       
                      ]
                    )
                    setC5R2VNo(11);
                    setC5R3VNo(11);
                    noHandleCount = noHandleCount + 1;
            } else if (c5r2MT === "L") {  
                alert("The Large Doors have no round Handles/knobs.");
            }
      } else if (curX === col06X && curY === row02X  && c6R2VNo === 9) {
        if (c6r2MT === "S") {  
          setRow2Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />     
                    ]
                  )
                  setC6R2VNo(1);
                  noHandleCount = noHandleCount + 1;
                } else if (c6r1MT === "M" && c6r2MT === "M") { 
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />     
                    ]
                  )
                  setC6R1VNo(11);
                  setC6R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c6r2MT === "M" && c6r3MT === "M") {      
                setRow2NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />     
                  ]
                )
                setC6R2VNo(11);
                setC6R3VNo(11);
                noHandleCount = noHandleCount + 1;
        } else if (c6r2MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }
       
      } else if (curX === col07X && curY === row02X  && c7R2VNo === 9) {
    
        if (c7r2MT === "S") {  
          setRow2Naked07(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                    ]
                  )
                  setC7R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c7r1MT === "M" && c7r2MT === "M") { 
                  setBaseNakedM07(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(11);
                  setC7R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c7r2MT === "M" && c7r3MT === "M") {    
                 setRow2NakedM07(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                  ]
                )
                setC7R2VNo(11);
                setC7R3VNo(11);
                noHandleCount = noHandleCount + 1;
        } else if (c7r2MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }
      
      } else if (curX === col08X && curY === row02X  && c8R2VNo === 9) {
    
        if (c8r2MT === "S") {  
          setRow2Naked08(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                     ]
                   )
                   setC8R2VNo(11);
                   noHandleCount = noHandleCount + 1;
                  } else if (c8r1MT === "M" && c8r2MT === "M") { 
                    setBaseNakedM08(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(11);
                    setC8R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c8r2MT === "M" && c8r3MT === "M") {        
                 setRow2NakedM08(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                   ]
                 )
                 setC8R2VNo(11);
                 setC8R3VNo(11);
                 noHandleCount = noHandleCount + 1;
         } else if (c8r2MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }
       
      } else if (curX === col09X && curY === row02X  && c9R2VNo === 9) {
        if (c9r2MT === "S") {  
          setRow2Naked09(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                    ]
                  )
                  setC9R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c9r1MT === "M" && c9r2MT === "M") { 
                  setBaseNakedM09(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
                  setC9R1VNo(11);
                  setC9R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c9r2MT === "M" && c9r3MT === "M") {        
               setRow2NakedM09(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                  ]
                )
                setC9R2VNo(11);
                setC9R3VNo(11);
                noHandleCount = noHandleCount + 1;
        } else if (c9r2MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }
        
      } else if (curX === col10X && curY === row02X  && c10R2VNo === 9) {
    
        if (c10r2MT === "S") {  
          setRow2Naked10(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                    ]
                  )
                  setC10R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c10r1MT === "M" && c10r2MT === "M") { 
                  setBaseNakedM10(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
                  setC10R1VNo(11);
                  setC10R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c10r2MT === "M" && c10r3MT === "M") {       
                setRow2NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(11);
                setC10R3VNo(11);
                noHandleCount = noHandleCount + 1;
        } else if (c10r2MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }	          
      
      } else if (curX === col11X && curY === row02X  && c11R2VNo === 9) {
    
        if (c11r2MT === "S") {  
          setRow2Naked11(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                    ]
                  )
                  setC11R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c11r1MT === "M" && c11r2MT === "M") { 
                  setBaseNakedM11(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                    ]
                  )
                  setC11R1VNo(11);
                  setC11R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c11r2MT === "M" && c11r3MT === "M") {     
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(11);
                setC11R3VNo(11);
                noHandleCount = noHandleCount + 1;
        } else if (c11r2MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }	          
      
      } else if (curX === col12X && curY === row02X  && c12R2VNo === 9) {
        if (c12r2MT === "S") {  
          setRow2Naked12(
                     [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                     ]
                   )
                   setC12R2VNo(1);
                   noHandleCount = noHandleCount + 1;
                  } else if (c12r1MT === "M" && c12r2MT === "M") { 
                    setBaseNakedM12(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(11);
                    setC12R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c12r2MT === "M" && c12r3MT === "M") {     
                  setRow2NakedM12(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                   ]
                 )
                 setC12R2VNo(11);
                 setC12R3VNo(11);
                 noHandleCount = noHandleCount + 1;
         } else if (c12r2MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }
      } else if (curX === col13X && curY === row02X  && c13R2VNo === 9) {
    
        if (c13r2MT === "S") {  
          setRow2Naked13(
                   [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} /> 
                   ]
                 )
                 setC13R2VNo(1);
                 noHandleCount = noHandleCount + 1;
                } else if (c13r1MT === "M" && c13r2MT === "M") { 
                  setBaseNakedM13(
                    [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} /> 
                    ]
                  )
                  setC13R1VNo(11);
                  setC13R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c13r2MT === "M" && c13r3MT === "M") {         
               setRow2NakedM13(
                 [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} /> 
                 ]
               )
               setC13R2VNo(11);
               setC13R3VNo(11);
               noHandleCount = noHandleCount + 1;
         } else if (c13r2MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }
      } else if (curX === col14X && curY === row02X  && c14R2VNo === 9) {
    
        if (c14r2MT === "S") {  
          setRow2Naked14(
                   [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                   ]
                 )
                 setC14R2VNo(11);
                 noHandleCount = noHandleCount + 1;
                } else if (c14r1MT === "M" && c14r2MT === "M") { 
                  setBaseNakedM14(
                    [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                    ]
                  )
                  setC14R1VNo(11);
                  setC14R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c14r2MT === "M" && c14r3MT === "M") {      
              setRow2NakedM14(
                 [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                 ]
               )
               setC14R2VNo(11);
               setC14R3VNo(11);
               noHandleCount = noHandleCount + 1;
         } else if (c14r2MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
         }
        
      }  	else if (curX === col01X && curY === row03X  && c1R3VNo === 9) {
      
            if (c1r3MT === "S") {  
              setRow3Naked01(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                      ]
                    )
                    setC1R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                    setRow2NakedM01(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                      ]
                    )
                    setC1R2VNo(11);
                    setC1R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c1r3MT === "M" && c1r4MT === "M") {      
                  setRow3NakedM01(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                    ]
                  )
                  setC1R3VNo(11);
                  setC1R4VNo(11);
                  noHandleCount = noHandleCount + 1;
            } else if (c1r3MT === "L") {  
              alert("The Large Doors have no round Handles/knobs.");
            }
  
    } else if (curX === col02X && curY === row03X && c2R3VNo === 9) {
            if (c2r3MT === "S") {  
              setRow3Naked02(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                    ]
                  )
                  setC2R3VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                  setRow2NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(11);
                  setC2R3VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c2r3MT === "M" && c2r4MT === "M") {     
                setRow3NakedM02(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                  ]
                )
                setC2R3VNo(11);
                setC2R4VNo(11);
                noHandleCount = noHandleCount + 1;
          } else if (c2r3MT === "L") {  
              alert("The Large Doors have no round Handles/knobs.");
          }	
    
    } else if (curX === col03X && curY === row03X   && c3R3VNo === 9) {
          if (c3r3MT === "S") {  
            setRow3Naked03(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />      
                    ]
                  )
                  setC3R3VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                  setRow2NakedM03(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />      
                    ]
                  )
                  setC3R2VNo(11);
                  setC3R3VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c3r3MT === "M" && c3r4MT === "M") {      
                setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />      
                  ]
                )
                setC3R3VNo(11);
                setC3R4VNo(11);
                noHandleCount = noHandleCount + 1;
          } else if (c3r3MT === "L") {  
            alert("The Large Doors have no round Handles/knobs.");
          }
  
    } else if (curX === col04X && curY === row03X   && c4R3VNo === 9) {
      if (c4r3MT === "S") {  
        setRow3Naked04(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />       
               ]
               )
               setC4R3VNo(1);
               noHandleCount = noHandleCount + 1;
              } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />       
                ]
                )
                setC4R2VNo(11);
                setC4R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c4r3MT === "M" && c4r4MT === "M") {       
             setRow3NakedM04(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />       
             ]
             )
             setC4R3VNo(11);
             setC4R4VNo(11);
             noHandleCount = noHandleCount + 1;
       } else if (c4r3MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
       }	  
       
    } else if (curX === col05X && curY === row03X && c5R3VNo === 9) {
      if (c5r3MT === "S") {  
        setRow3Naked05(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />       
                ]
               )
               setC5R3VNo(11);
               noHandleCount = noHandleCount + 1;
              } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                setRow2NakedM05(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />       
                  ]
                )
                setC5R2VNo(11);
                setC5R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c5r3MT === "M" && c5r4MT === "M") {    
           setRow3NakedM05(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />       
              ]
             )
             setC5R3VNo(11);
             setC5R4VNo(11);
             noHandleCount = noHandleCount + 1;
       } else if (c5r3MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
       }	
      
    } else if (curX === col06X && curY === row03X  && c6R3VNo === 9) {
      if (c6r3MT === "S") {  
        setRow3Naked06(
                  [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />     
                  ]
                )
                setC6R3VNo(1);
                noHandleCount = noHandleCount + 1;
              } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                setRow2NakedM06(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />     
                  ]
                )
                setC6R2VNo(11);
		            setC6R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c6r3MT === "M" && c6r4MT === "M") {       
            setRow3NakedM06(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />     
                ]
              )
              setC6R3VNo(11);
              setC6R4VNo(11);
              noHandleCount = noHandleCount + 1;
        } else if (c6r3MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }	 
        
     
    } else if (curX === col07X && curY === row03X  && c7R3VNo === 9) {
  
      if (c7r3MT === "S") {  
        setRow3Naked07(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                 ]
               )
               setC7R3VNo(11);
               noHandleCount = noHandleCount + 1;
              } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                setRow2NakedM07(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                  ]
                )
                setC7R2VNo(11);
                setC7R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c7r3MT === "M" && c7r4MT === "M") {      
         setRow3NakedM07(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
               ]
             )
             setC7R3VNo(11);
             setC7R4VNo(11);
             noHandleCount = noHandleCount + 1;
       } else if (c7r3MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
       }	  
    } else if (curX === col08X && curY === row03X  && c8R3VNo === 9) {
      if (c8r3MT === "S") {  
        setRow3Naked08(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                ]
              )
              setC8R3VNo(11);
              noHandleCount = noHandleCount + 1;
            } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
              setRow2NakedM08(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                ]
              )
              setC8R2VNo(11);
              setC8R3VNo(11);
              noHandleCount = noHandleCount + 1;
            } else if (c8r3MT === "M" && c8r4MT === "M") {       
         setRow3NakedM08(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
              ]
            )
            setC8R3VNo(11);
            setC8R4VNo(11);
            noHandleCount = noHandleCount + 1;
      } else if (c8r3MT === "L") {  
         alert("The Large Doors have no round Handles/knobs.");
      }	
       
    } else if (curX === col09X && curY === row03X  && c9R3VNo === 9) {
  
      if (c9r3MT === "S") {  
        setRow3Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                 ]
               )
               setC9R3VNo(11);
               noHandleCount = noHandleCount + 1;
              } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                setRow2NakedM09(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                  ]
                )
                setC9R2VNo(11);
                setC9R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c9r3MT === "M" && c9r4MT === "M") {      
          setRow3NakedM09(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
               ]
             )
             setC9R3VNo(11);
             setC9R4VNo(11);
             noHandleCount = noHandleCount + 1;
       } else if (c9r3MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
       }
      
    } else if (curX === col10X && curY === row03X  && c10R3VNo === 9) {
      if (c10r3MT === "S") {  
        setRow3Naked10(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                 ]
               )
               setC10R3VNo(11);
               noHandleCount = noHandleCount + 1;
              } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                setRow2NakedM10(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                  ]
                )
                setC10R2VNo(11);
                setC10R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c10r3MT === "M" && c10r4MT === "M") {      
         
             setRow3NakedM10(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
               ]
             )
             setC10R3VNo(11);
             setC10R4VNo(11);
             noHandleCount = noHandleCount + 1;
       } else if (c10r3MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
       }	         
    
    } else if (curX === col11X && curY === row03X  && c11R3VNo === 9) {
      if (c11r3MT === "S") {  
        setRow3Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                 ]
               )
               setC11R3VNo(11);
               noHandleCount = noHandleCount + 1;
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                setRow2NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                  ]
                )
                setC11R2VNo(11);
                setC11R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c11r3MT === "M" && c11r4MT === "M") {       
           setRow3NakedM11(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
               ]
             )
             setC11R3VNo(11);
             setC11R4VNo(11);
             noHandleCount = noHandleCount + 1;
       } else if (c11r3MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
       }	
    
    } else if (curX === col12X && curY === row03X  && c12R3VNo === 9) {
  
      if (c12r3MT === "S") {  
        setRow3Naked12(
                 [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                 ]
               )
               setC12R3VNo(1);
               noHandleCount = noHandleCount + 1;
              } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                setRow2NakedM12(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                  ]
                )
                setC12R2VNo(11);
                setC12R3VNo(11);
                noHandleCount = noHandleCount + 1;
              } else if (c12r3MT === "M" && c12r4MT === "M") {        
             setRow3NakedM12(
               [
                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
               ]
             )
             setC12R3VNo(11);
             setC12R4VNo(11);
             noHandleCount = noHandleCount + 1;
       } else if (c12r3MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
       }	  
       
        
    } else if (curX === col13X && curY === row03X  && c13R3VNo === 9) {
      if (c13r3MT === "S") {  
        setRow3Naked13(
                [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} /> 
                ]
              )
              setC13R3VNo(1);
              noHandleCount = noHandleCount + 1;
            } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
              setRow2NakedM13(
                [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} /> 
                ]
              )
              setC13R2VNo(11);
              setC13R3VNo(11);
              noHandleCount = noHandleCount + 1;
            } else if (c13r3MT === "M" && c13r4MT === "M") {       
             setRow3NakedM13(
              [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} /> 
              ]
            )
            setC13R3VNo(11);
            setC13R4VNo(11);
            noHandleCount = noHandleCount + 1;
        } else if (c13r3MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }	
    } else if (curX === col14X && curY === row03X  && c14R3VNo === 9) {
      if (c14r3MT === "S") {  
        setRow3Naked14(
                [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(11);
              noHandleCount = noHandleCount + 1;
            } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
              setRow2NakedM14(
                [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(11);
              setC14R3VNo(11);
              noHandleCount = noHandleCount + 1;
            } else if (c14r3MT === "M" && c14r4MT === "M") {     
              setRow3NakedM14(
              [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(11);
            setC14R4VNo(11);
            noHandleCount = noHandleCount + 1;
        } else if (c14r3MT === "L") {  
           alert("The Large Doors have no round Handles/knobs.");
        }	  
        
      }  	else if (curX === col01X && curY === row04X  && c1R4VNo === 9) {
      
        if (c1r4MT === "S") {  
             setRow4Naked01(
          [
            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
          ]
        )
        setC1R4VNo(11);
        noHandleCount = noHandleCount + 1;
        } else if (c1r4MT === "M") {      
              setRow3NakedM01(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                ]
              )
              setC1R3VNo(11);
              setC1R4VNo(11);
              noHandleCount = noHandleCount + 1;
        } else if (c1r4MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
        }

} else if (curX === col02X && curY === row04X && c2R4VNo === 9) {
        if (c2r4MT === "S") {  
          setRow4Naked02(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                ]
              )
              setC2R4VNo(11);
              noHandleCount = noHandleCount + 1;
      } else if (c2r4MT === "M") {      
            setRow3NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
              ]
            )
            setC2R3VNo(11);
            setC2R4VNo(11);
            noHandleCount = noHandleCount + 1;
      } else if (c2r4MT === "L") {  
          alert("The Large Doors have no round Handles/knobs.");
      }	

} else if (curX === col03X && curY === row04X   && c3R4VNo === 9) {
      if (c3r4MT === "S") {  
        setRow4Naked03(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />      
                ]
              )
              setC3R4VNo(11);
              noHandleCount = noHandleCount + 1;
      } else if (c3r4MT === "M") {      
            setRow3NakedM03(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />      
              ]
            )
            setC3R3VNo(11);
            setC3R4VNo(11);
            noHandleCount = noHandleCount + 1;
      } else if (c3r4MT === "L") {  
        alert("The Large Doors have no round Handles/knobs.");
      }

} else if (curX === col04X && curY === row04X   && c4R4VNo === 9) {
  if (c4r4MT === "S") {  
    setRow4Naked04(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />       
           ]
           )
           setC4R4VNo(1);
           noHandleCount = noHandleCount + 1;
   } else if (c4r4MT === "M") {      
         setRow3NakedM04(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />       
         ]
         )
         setC4R3VNo(11);
         setC4R4VNo(11);
         noHandleCount = noHandleCount + 1;
   } else if (c4r4MT === "L") {  
      alert("The Large Doors have no round Handles/knobs.");
   }	  
   
} else if (curX === col05X && curY === row04X && c5R4VNo === 9) {
  if (c5r4MT === "S") {  
    setRow4Naked05(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />       
            ]
           )
           setC5R4VNo(11);
           noHandleCount = noHandleCount + 1;
   } else if (c5r4MT === "M") {      
       setRow3NakedM05(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />       
          ]
         )
         setC5R3VNo(11);
         setC5R4VNo(11);
         noHandleCount = noHandleCount + 1;
   } else if (c5r4MT === "L") {  
      alert("The Large Doors have no round Handles/knobs.");
   }	
  
} else if (curX === col06X && curY === row04X  && c6R4VNo === 9) {
  if (c6r4MT === "S") {  
    setRow4Naked06(
              [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />     
              ]
            )
            setC6R4VNo(1);
            noHandleCount = noHandleCount + 1;
    } else if (c6r4MT === "M") {      
        setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />     
            ]
          )
          setC6R3VNo(11);
          setC6R4VNo(11);
          noHandleCount = noHandleCount + 1;
    } else if (c6r4MT === "L") {  
       alert("The Large Doors have no round Handles/knobs.");
    }	 
         
} else if (curX === col07X && curY === row04X  && c7R4VNo === 9) {

  if (c7r4MT === "S") {  
    setRow4Naked07(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
             ]
           )
           setC7R4VNo(11);
           noHandleCount = noHandleCount + 1;
   } else if (c7r4MT === "M") {      
     setRow3NakedM07(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
           ]
         )
         setC7R3VNo(11);
         setC7R4VNo(11);
         noHandleCount = noHandleCount + 1;
   } else if (c7r4MT === "L") {  
      alert("The Large Doors have no round Handles/knobs.");
   }	  
} else if (curX === col08X && curY === row04X  && c8R4VNo === 9) {
  if (c8r4MT === "S") {  
    setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
            ]
          )
          setC8R4VNo(11);
          noHandleCount = noHandleCount + 1;
  } else if (c8r4MT === "M") {      
     setRow3NakedM08(
          [
            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
          ]
        )
        setC8R3VNo(11);
        setC8R4VNo(11);
        noHandleCount = noHandleCount + 1;
  } else if (c8r4MT === "L") {  
     alert("The Large Doors have no round Handles/knobs.");
  }	
   
} else if (curX === col09X && curY === row04X  && c9R4VNo === 9) {

  if (c9r4MT === "S") {  
    setRow4Naked09(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
             ]
           )
           setC9R4VNo(11);
           noHandleCount = noHandleCount + 1;
   } else if (c9r4MT === "M") {      
      setRow3NakedM09(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
           ]
         )
         setC9R3VNo(11);
         setC9R4VNo(11);
         noHandleCount = noHandleCount + 1;
   } else if (c9r4MT === "L") {  
      alert("The Large Doors have no round Handles/knobs.");
   }
  
} else if (curX === col10X && curY === row04X  && c10R4VNo === 9) {
  if (c10r4MT === "S") {  
    setRow4Naked10(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
             ]
           )
           setC10R4VNo(11);
           noHandleCount = noHandleCount + 1;
   } else if (c10r4MT === "M") {      
     
         setRow3NakedM10(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
           ]
         )
         setC10R3VNo(11);
         setC10R4VNo(11);
         noHandleCount = noHandleCount + 1;
   } else if (c10r4MT === "L") {  
      alert("The Large Doors have no round Handles/knobs.");
   }	         

} else if (curX === col11X && curY === row04X  && c11R4VNo === 9) {
  if (c11r4MT === "S") {  
    setRow4Naked11(
             [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
             ]
           )
           setC11R4VNo(11);
           noHandleCount = noHandleCount + 1;
   } else if (c11r4MT === "M") {      
       setRow3NakedM11(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
           ]
         )
         setC11R3VNo(11);
         setC11R4VNo(11);
         noHandleCount = noHandleCount + 1;
   } else if (c11r4MT === "L") {  
      alert("The Large Doors have no round Handles/knobs.");
   }	

} else if (curX === col12X && curY === row04X  && c12R4VNo === 9) {

  if (c12r4MT === "S") {  
    setRow4Naked12(
             [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
             ]
           )
           setC12R4VNo(1);
           noHandleCount = noHandleCount + 1;
   } else if (c12r4MT === "M") {      
         setRow3NakedM12(
           [
             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
           ]
         )
         setC12R3VNo(11);
         setC12R4VNo(11);
         noHandleCount = noHandleCount + 1;
   } else if (c12r4MT === "L") {  
      alert("The Large Doors have no round Handles/knobs.");
   }	  
   
} else if (curX === col13X && curY === row04X  && c13R4VNo === 9) {
  if (c13r4MT === "S") {  
    setRow4Naked13(
            [
                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} /> 
            ]
          )
          setC13R4VNo(1);
          noHandleCount = noHandleCount + 1;
    } else if (c13r4MT === "M") {      
         setRow3NakedM13(
          [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} /> 
          ]
        )
        setC13R3VNo(11);
        setC13R4VNo(11);
        noHandleCount = noHandleCount + 1;
    } else if (c13r4MT === "L") {  
       alert("The Large Doors have no round Handles/knobs.");
    }	
} else if (curX === col14X && curY === row04X  && c14R4VNo === 9) {
  if (c14r4MT === "S") {  
    setRow4Naked14(
            [
               <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          )
          setC14R4VNo(11);
          noHandleCount = noHandleCount + 1;
    } else if (c14r4MT === "M") {      
          setRow3NakedM14(
          [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
          ]
        )
        setC14R3VNo(11);
        setC14R4VNo(11);
        noHandleCount = noHandleCount + 1;
    } else if (c14r4MT === "L") {  
       alert("The Large Doors have no round Handles/knobs.");
    }	
  }  else {
      alert("You cannot remove handle at this stage")	
  }
 
}
 
  const addLongHandle = (e)=> {
 
          if (curX === col01X && curY === row01X && c1R1VNo === 11) {
            if (c1r1MT === "S") {  
              setBaseNaked01(
                           [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                           ]
                         )
                         setC1R1VNo(10);
                         noHandleCount = noHandleCount - 1;
              } else if (c1r1MT === "M") {  
                  setBaseNakedM01(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(10);
                          setC1R2VNo(10);
                          noHandleCount = noHandleCount - 1;
              } else if (c1r1MT === "L") {  
                setBaseNakedL01(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                  ]
                )

                setC1R1VNo(10);
                setC1R2VNo(10);
                setC1R3VNo(10);
                setC1R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }	  
         
             
        
          } else if (curX === col02X && curY === row01X && c2R1VNo === 11) {
        
            if (c2r1MT === "S") {  
              setBaseNaked02(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(10);
                          noHandleCount = noHandleCount - 1;
            } else if (c2r1MT === "M") {  
                setBaseNakedM02(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(10);
                        setC2R2VNo(10);
                        noHandleCount = noHandleCount - 1;
            
            } else if (c2r1MT === "L") {  
              setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )

              setC2R1VNo(10);
              setC2R2VNo(10);
              setC2R3VNo(10);
              setC2R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }	  
          
          } else if (curX === col03X && curY === row01X && c3R1VNo === 11) {
        
            if (c3r1MT === "S") {  
              setBaseNaked03(
                           [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />     
                           ]
                         )
                         setC3R1VNo(10);
                         noHandleCount = noHandleCount - 1;
           } else if (c3r1MT === "M") {  
                setBaseNakedM03(
                         [
                           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />     
                         ]
                       )
                       setC3R1VNo(10);
                       setC3R2VNo(10);
                       noHandleCount = noHandleCount - 1;
           } else if (c3r1MT === "L") {  
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
              ]
            )

            setC3R1VNo(10);
            setC3R2VNo(10);
            setC3R3VNo(10);
            setC3R4VNo(10);
            noHandleCount = noHandleCount - 1;
           }
             
           
          } else if (curX === col04X && curY === row01X && c4R1VNo === 11) {
        
        
            if (c4r1MT === "S") {  
              setBaseNaked04(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />    
                          ]
                          )
                          setC4R1VNo(10);
                          noHandleCount = noHandleCount - 1;
            } else if (c4r1MT === "M") {  
                 setBaseNakedM04(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />    
                        ]
                        )
                        setC4R1VNo(10);
                        setC4R2VNo(10);
                        noHandleCount = noHandleCount - 1;
            
            } else if (c4r1MT === "L") {  
              setBaseNakedL04(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                ]
              )

              setC4R1VNo(10);
              setC4R2VNo(10);
              setC4R3VNo(10);
              setC4R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }
           
          } else if (curX === col05X && curY === row01X && c5R1VNo === 11) {
        
            if (c5r1MT === "S") {  
              setBaseNaked05(
                           [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />     
                          ]
                         )
                         setC5R1VNo(10);
                         noHandleCount = noHandleCount - 1;
           } else if (c5r1MT === "M") {  
                setBaseNakedM05(
                         [
                           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />     
                        ]
                       )
                       setC5R1VNo(10);
                       setC5R2VNo(10);
                       noHandleCount = noHandleCount - 1;
           } else if (c5r1MT === "L") {  
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
            )

            setC5R1VNo(10);
            setC5R2VNo(10);
            setC5R3VNo(10);
            setC5R4VNo(10);
            noHandleCount = noHandleCount - 1;
           }	  
           
            
          } else if (curX === col06X && curY === row01X && c6R1VNo === 11) {
        
            if (c6r1MT === "S") {  
              setBaseNaked06(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                            ]
                          )
                          setC6R1VNo(10);
                          noHandleCount = noHandleCount - 1;
            } else if (c6r1MT === "M") {  
                 setBaseNakedM06(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                          ]
                        )
                        setC6R1VNo(10);
                        setC6R2VNo(10);
                        noHandleCount = noHandleCount - 1;
            
            } else if (c6r1MT === "L") {  
              setBaseNakedL06(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                ]
              )

              setC6R1VNo(10);
              setC6R2VNo(10);
              setC6R3VNo(10);
              setC6R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }	  
            
           
          } else if (curX === col07X && curY === row01X && c7R1VNo === 11) {
                if (c7r1MT === "S") {  
      
                  setBaseNaked07(
                    [
                      <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(10);
                  noHandleCount = noHandleCount - 1;
                  } else if (c7r1MT === "M") {  
                      setBaseNakedM07(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                ]
                              )
                              setC7R1VNo(10);
                              setC7R2VNo(10);
                              noHandleCount = noHandleCount - 1;

                  } else if (c7r1MT === "L") {  
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
    
                    setC7R1VNo(10);
                    setC7R2VNo(10);
                    setC7R3VNo(10);
                    setC7R4VNo(10);
                    noHandleCount = noHandleCount - 1;
                  }	  
     
          } else if (curX === col08X && curY === row01X && c8R1VNo === 11) {
              if (c8r1MT === "S") {  
                setBaseNaked08(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                          setC8R1VNo(10);
                          noHandleCount = noHandleCount - 1;
                } else if (c8r1MT === "M") {  
                      setBaseNakedM08(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                              ]
                            )
                            setC8R1VNo(10);
                            setC8R2VNo(10);
                            noHandleCount = noHandleCount - 1;
                
                } else if (c8r1MT === "L") {  
                  setBaseNakedL08(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                  )
  
                  setC8R1VNo(10);
                  setC8R2VNo(10);
                  setC8R3VNo(10);
                  setC8R4VNo(10);
                  noHandleCount = noHandleCount - 1;
                }	  
          } else if (curX === col09X && curY === row01X && c9R1VNo === 11) {
            if (c9r1MT === "S") {  
              setBaseNaked09(
                           [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                           ]
                         )
                         setC9R1VNo(10);
                         noHandleCount = noHandleCount - 1;
           } else if (c9r1MT === "M") {  
                setBaseNakedM09(
                         [
                           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                         ]
                       )
                       setC9R1VNo(10);
                       setC9R2VNo(10);  
                       noHandleCount = noHandleCount - 1;
           } else if (c9r1MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )

            setC9R1VNo(10);
            setC9R2VNo(10);
            setC9R3VNo(10);
            setC9R4VNo(10);
            noHandleCount = noHandleCount - 1;
           }	
          } else if (curX === col10X && curY === row01X && c10R1VNo === 11) {
            if (c10r1MT === "S") {  
              setBaseNaked10(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(10);
                          noHandleCount = noHandleCount - 1;
                        
            } else if (c10r1MT === "M") {  
                 setBaseNakedM10(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                          ]
                        )
                        setC10R1VNo(10);
                        setC10R2VNo(10);   
                        noHandleCount = noHandleCount - 1;
            } else if (c10r1MT === "L") {  
              setBaseNakedL10(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                ]
              )

              setC10R1VNo(10);
              setC10R2VNo(10);
              setC10R3VNo(10);
              setC10R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }
         
              
          } else if (curX === col11X && curY === row01X && c11R1VNo === 11) {
        
            if (c11r1MT === "S") {  
              setBaseNaked11(
                            [
                              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(10);
                          noHandleCount = noHandleCount - 1;
            } else if (c11r1MT === "M") {  
                  setBaseNakedM11(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                          ]
                        )
                        setC11R1VNo(10);
                        setC11R2VNo(10);
                        noHandleCount = noHandleCount - 1;
            } else if (c11r1MT === "L") {  
              setBaseNakedL11(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                ]
              )

              setC11R1VNo(10);
              setC11R2VNo(10);
              setC11R3VNo(10);
              setC11R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }	
          
          } else if (curX === col12X && curY === row01X && c12R1VNo === 11) {
            if (c12r1MT === "S") {  
              setBaseNaked12(
                           [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                           ]
                         )
                            setC12R1VNo(10);
                            noHandleCount = noHandleCount - 1;
              } else if (c12r1MT === "M") {  
                      setBaseNakedM12(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(10);
                          setC12R2VNo(10);
                          noHandleCount = noHandleCount - 1;
              } else if (c12r1MT === "L") {  
                setBaseNakedL12(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                  ]
                )

                setC12R1VNo(10);
                setC12R2VNo(10);
                setC12R3VNo(10);
                setC12R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }	    
          } else if (curX === col13X && curY === row01X && c13R1VNo === 11) {
            if (c13r1MT === "S") {  
              setBaseNaked13(
                         [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                         ]
                       )
                       setC13R1VNo(10);
                       noHandleCount = noHandleCount - 1;
           } else if (c13r1MT === "M") {  
                 setBaseNakedM13(
                       [
                           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                       ]
                     )
                     setC13R1VNo(10);
                     setC13R2VNo(10);
                     noHandleCount = noHandleCount - 1;
           } else if (c13r1MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )

            setC13R1VNo(10);
            setC13R2VNo(10);
            setC13R3VNo(10);
            setC13R4VNo(10);
            noHandleCount = noHandleCount - 1;
           }	
           
          } else if (curX === col14X && curY === row01X && c14R1VNo === 11) {
            if (c14r1MT === "S") {  
              setBaseNaked14(
                         [
                             <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                         ]
                       )
                       setC14R1VNo(10);
                       noHandleCount = noHandleCount - 1;
           } else if (c14r1MT === "M") {  
               setBaseNakedM14(
                       [
                           <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                       ]
                     )
                     setC14R1VNo(10);
                     setC14R2VNo(10);
                     noHandleCount = noHandleCount - 1;
           } else if (c14r1MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )

            setC14R1VNo(10);
            setC14R2VNo(10);
            setC14R3VNo(10);
            setC14R4VNo(10);
            noHandleCount = noHandleCount - 1;
           }	
           
          } else if (curX === col01X && curY === row02X && c1R2VNo === 11) {
            if (c1r2MT === "S") {  
              setRow2Naked01(
                         [
                           <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                         ]
                       )
                       setC1R2VNo(10);
                       noHandleCount = noHandleCount - 1;
                      } else if (c1r1MT === "M" && c1r2MT === "M") { 
                        setBaseNakedM01(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                          ]
                        )
                        setC1R1VNo(10);
                        setC1R2VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c1r2MT === "M" && c1r3MT === "M") {   
               setRow2NakedM01(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                       ]
                     )
                     setC1R2VNo(10);
                     setC1R3VNo(10);
                     noHandleCount = noHandleCount - 1;
           } else if (c1r2MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )

            setC1R1VNo(10);
            setC1R2VNo(10);
            setC1R3VNo(10);
            setC1R4VNo(10);
            noHandleCount = noHandleCount - 1;
           }	
      
        } else if (curX === col02X && curY === row02X && c2R2VNo === 11) {
      
          if (c2r2MT === "S") {  
            setRow2Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c2r1MT === "M" && c2r2MT === "M") { 
                    setBaseNakedM02(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(10);
                    setC2R2VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c2r2MT === "M" && c2r3MT === "M") {   
            setRow2NakedM02(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                    ]
                  )
                  setC2R2VNo(10);
                  setC2R3VNo(10);
                  noHandleCount = noHandleCount - 1;
        } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )

          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
          noHandleCount = noHandleCount - 1;
        }	
        
        } else if (curX === col03X && curY === row02X && c3R2VNo === 11) {
                  if (c3r2MT === "S") {  
                    setRow2Naked03(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />     
                            ]
                          )
                          setC3R2VNo(10);
                          noHandleCount = noHandleCount - 1;
                        } else if (c3r1MT === "M" && c3r2MT === "M") { 
                          setBaseNakedM03(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />     
                            ]
                          )
                          setC3R1VNo(10);
                          setC3R2VNo(10);
                          noHandleCount = noHandleCount - 1;
                        } else if (c3r2MT === "M" && c3r3MT === "M") {     
                    setRow2NakedM03(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />     
                          ]
                        )
                        setC3R2VNo(10);
                        setC3R3VNo(10);
                        noHandleCount = noHandleCount - 1;
              } else if (c3r2MT === "L") {  
                setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
    
                setC3R1VNo(10);
                setC3R2VNo(10);
                setC3R3VNo(10);
                setC3R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }
         
        } else if (curX === col04X && curY === row02X && c4R2VNo === 11) {
                    if (c4r2MT === "S") {  
                      setRow2Naked04(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />    
                            ]
                            )
                            setC4R2VNo(10);
                            noHandleCount = noHandleCount - 1;
                          } else if (c4r1MT === "M" && c4r2MT === "M") { 
                            setBaseNakedM04(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />    
                            ]
                            )
                            setC4R1VNo(10);
                            setC4R2VNo(10);
                            noHandleCount = noHandleCount - 1;
                          } else if (c4r2MT === "M" && c4r3MT === "M") {   
                    setRow2NakedM04(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />    
                          ]
                          )
                          setC4R2VNo(10);
                          setC4R3VNo(10);
                          noHandleCount = noHandleCount - 1;
                } else if (c4r2MT === "L") {  
                  setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
    
                  setC4R1VNo(10);
                  setC4R2VNo(10);
                  setC4R3VNo(10);
                  setC4R4VNo(10);
                  noHandleCount = noHandleCount - 1;
                }	  
       
         
        } else if (curX === col05X && curY === row02X && c5R2VNo === 11) {
                  if (c5r2MT === "S") {  
                    setRow2Naked05(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />     
                            ]
                            )
                            setC5R2VNo(10);
                            noHandleCount = noHandleCount - 1;
                          } else if (c5r1MT === "M" && c5r2MT === "M") { 
                            setBaseNakedM05(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />     
                             ]
                            )
                            setC5R1VNo(10);
                            setC5R2VNo(10);
                            noHandleCount = noHandleCount - 1;
                          } else if (c5r2MT === "M" && c5r3MT === "M") {  
                  setRow2NakedM05(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />     
                          ]
                          )
                          setC5R2VNo(10);
                          setC5R3VNo(10);
                          noHandleCount = noHandleCount - 1;
                } else if (c5r2MT === "L") {  
                  setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
      
                  setC5R1VNo(10);
                  setC5R2VNo(10);
                  setC5R3VNo(10);
                  setC5R4VNo(10);
                  noHandleCount = noHandleCount - 1;
                }	  
                
          
        } else if (curX === col06X && curY === row02X && c6R2VNo === 11) {
                    if (c6r2MT === "S") {  
                      setRow2Naked06(
                                [
                                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                                ]
                              )
                              setC6R2VNo(10);
                              noHandleCount = noHandleCount - 1;
                            } else if (c6r1MT === "M" && c6r2MT === "M") { 
                              setBaseNakedM06(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(10);
                              setC6R2VNo(10);
                              noHandleCount = noHandleCount - 1;
                            } else if (c6r2MT === "M" && c6r3MT === "M") { 
                            setRow2NakedM06(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                              ]
                            )
                            setC6R2VNo(10);
                            setC6R3VNo(10);
                            noHandleCount = noHandleCount - 1;
                  } else if (c6r2MT === "L") {  
                    setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
      
                    setC6R1VNo(10);
                    setC6R2VNo(10);
                    setC6R3VNo(10);
                    setC6R4VNo(10);
                    noHandleCount = noHandleCount - 1;
                  }	  
                  
         
        } else if (curX === col07X && curY === row02X && c7R2VNo === 11) {
          if (c7r2MT === "S") {  
            setRow2Naked07(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                       ]
                     )
                     setC7R2VNo(10);
                     noHandleCount = noHandleCount - 1;
                    } else if (c7r1MT === "M" && c7r2MT === "M") { 
                      setBaseNakedM07(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
                      setC7R1VNo(10);
                      setC7R2VNo(10);
                      noHandleCount = noHandleCount - 1;
                    } else if (c7r2MT === "M" && c7r3MT === "M") {   
                   setRow2NakedM07(
                     [
                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                     ]
                   )
                   setC7R2VNo(10);
                   setC7R3VNo(10);
                   noHandleCount = noHandleCount - 1;
         } else if (c7r2MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )

          setC7R1VNo(10);
          setC7R2VNo(10);
          setC7R3VNo(10);
          setC7R4VNo(10);
          noHandleCount = noHandleCount - 1;
         }
        
        } else if (curX === col08X && curY === row02X && c8R2VNo === 11) {
            if (c8r2MT === "S") {  
                        setRow2Naked08(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                          ]
                        )
                        setC8R2VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c8r1MT === "M" && c8r2MT === "M") { 
                        setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                        setC8R1VNo(10);
                        setC8R2VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c8r2MT === "M" && c8r3MT === "M") {     
                      setRow2NakedM08(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                        ]
                      )
                      setC8R2VNo(10);
                      setC8R3VNo(10);
                      noHandleCount = noHandleCount - 1;
            } else if (c8r2MT === "L") {  
              setBaseNakedL08(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                ]
              )

              setC8R1VNo(10);
              setC8R2VNo(10);
              setC8R3VNo(10);
              setC8R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }
         
        } else if (curX === col09X && curY === row02X && c9R2VNo === 11) {
                if (c9r2MT === "S") {  
                            setRow2Naked09(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                              ]
                            )
                            setC9R2VNo(10);
                            noHandleCount = noHandleCount - 1;
                          } else if (c9r1MT === "M" && c9r2MT === "M") { 
                            setBaseNakedM09(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                            setC9R1VNo(10);
                            setC9R2VNo(10);  
                            noHandleCount = noHandleCount - 1;
                          } else if (c9r2MT === "M" && c9r3MT === "M") {      
                          setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                          setC9R2VNo(10);
                          setC9R3VNo(10);
                          noHandleCount = noHandleCount - 1;
                } else if (c9r2MT === "L") {  
                  setBaseNakedL09(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                    ]
                  )
      
                  setC9R1VNo(10);
                  setC9R2VNo(10);
                  setC9R3VNo(10);
                  setC9R4VNo(10);
                  noHandleCount = noHandleCount - 1;
                }	  
          
        } else if (curX === col10X && curY === row02X && c10R2VNo === 11) {
                if (c10r2MT === "S") {  
                            setRow2Naked10(
                              [
                                <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                            setC10R2VNo(10);
                            noHandleCount = noHandleCount - 1;
                          } else if (c10r1MT === "M" && c10r2MT === "M") { 
                            setBaseNakedM10(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                            setC10R1VNo(10);
                            setC10R2VNo(10);  
                            noHandleCount = noHandleCount - 1; 
                          } else if (c10r2MT === "M" && c10r3MT === "M") { 
                            
                        setRow2NakedM10(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                            ]
                          )
                          setC10R2VNo(10);
                          setC10R3VNo(10);
                          noHandleCount = noHandleCount - 1;
                } else if (c10r2MT === "L") {  
                  setBaseNakedL10(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                    ]
                  )
    
                  setC10R1VNo(10);
                  setC10R2VNo(10);
                  setC10R3VNo(10);
                  setC10R4VNo(10);
                  noHandleCount = noHandleCount - 1;
                }	  

        } else if (curX === col11X && curY === row02X && c11R2VNo === 11) {

               if (c11r2MT === "S") {  
                          setRow2Naked11(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                            ]
                          )
                          setC11R2VNo(10);
                          noHandleCount = noHandleCount - 1;
                        } else if (c11r1MT === "M" && c11r2MT === "M") { 
                          setBaseNakedM11(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(10);
                          setC11R2VNo(10);
                          noHandleCount = noHandleCount - 1;
                        } else if (c11r2MT === "M" && c11r3MT === "M") {   
                      setRow2NakedM11(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                          ]
                        )
                        setC11R2VNo(10);
                        setC11R3VNo(10);
                        noHandleCount = noHandleCount - 1;
              } else if (c11r2MT === "L") {  
                setBaseNakedL11(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                  ]
                )
  
                setC11R1VNo(10);
                setC11R2VNo(10);
                setC11R3VNo(10);
                setC11R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }	  
 
        
        } else if (curX === col12X && curY === row02X && c12R2VNo === 11) {
      
              if (c12r2MT === "S") {  
                        setRow2Naked12(
                            [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                            ]
                          )
                          setC12R2VNo(10);
                          noHandleCount = noHandleCount - 1;
                        } else if (c12r1MT === "M" && c12r2MT === "M") { 
                          setBaseNakedM12(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                            ]
                          )
                          setC12R1VNo(10);
                          setC12R2VNo(10);
                          noHandleCount = noHandleCount - 1;
                        } else if (c12r2MT === "M" && c12r3MT === "M") {    
                  setRow2NakedM12(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                          ]
                        )
                        setC12R2VNo(10);
                        setC12R3VNo(10);
                        noHandleCount = noHandleCount - 1;
              } else if (c12r2MT === "L") {  
                setBaseNakedL12(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                  ]
                )

                setC12R1VNo(10);
                setC12R2VNo(10);
                setC12R3VNo(10);
                setC12R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }
        } else if (curX === col13X && curY === row02X && c13R2VNo === 11) {
              if (c13r2MT === "S") {  
                        setRow2Naked13(
                          [
                              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                          ]
                        )
                        setC13R2VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c13r1MT === "M" && c13r2MT === "M") { 
                        setBaseNakedM13(
                          [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(10);
                        setC13R2VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c13r2MT === "M" && c13r3MT === "M") {      
                setRow2NakedM13(
                        [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                        ]
                      )
                      setC13R2VNo(10);
                      setC13R3VNo(10);
                      noHandleCount = noHandleCount - 1;
              } else if (c13r2MT === "L") {  
                setBaseNakedL13(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                  ]
                )
    
                setC13R1VNo(10);
                setC13R2VNo(10);
                setC13R3VNo(10);
                setC13R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }
      
        } else if (curX === col14X && curY === row02X && c14R2VNo === 11) {

            if (c14r2MT === "S") {  
                      setRow2Naked14(
                        [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                        ]
                      )
                      setC14R2VNo(10);
                      noHandleCount = noHandleCount - 1;
                    } else if (c14r1MT === "M" && c14r2MT === "M") { 
                      setBaseNakedM14(
                        [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(10);
                      setC14R2VNo(10);
                      noHandleCount = noHandleCount - 1;
                    } else if (c14r2MT === "M" && c14r3MT === "M") {   
              setRow2NakedM14(
                      [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                      ]
                    )
                    setC14R2VNo(10);
                    setC14R3VNo(10);
                    noHandleCount = noHandleCount - 1;
            } else if (c14r2MT === "L") {  
              setBaseNakedL14(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                ]
              )
  
              setC14R1VNo(10);
              setC14R2VNo(10);
              setC14R3VNo(10);
              setC14R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }	  
  
      } else if (curX === col01X && curY === row03X && c1R3VNo === 11) {

              if (c1r3MT === "S") {  
                        setRow3Naked01(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                          ]
                        )
                        setC1R3VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                        setRow2NakedM01(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                          ]
                        )
                        setC1R2VNo(10);
                        setC1R3VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c1r3MT === "M" && c1r4MT === "M") {  
                  setRow3NakedM01(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                        ]
                      )
                      setC1R3VNo(10);
                      setC1R4VNo(10);
                      noHandleCount = noHandleCount - 1;
              } else if (c1r3MT === "L") {  
                setBaseNakedL01(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                  ]
                )

                setC1R1VNo(10);
                setC1R2VNo(10);
                setC1R3VNo(10);
                setC1R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }	  
 
      } else if (curX === col02X && curY === row03X && c2R3VNo === 11) {
 
              if (c2r3MT === "S") {  
                      setRow3Naked02(
                          [
                            <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                          ]
                        )
                        setC2R3VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                        setRow2NakedM02(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                          ]
                        )
                        setC2R2VNo(10);
                        setC2R3VNo(10);
                        noHandleCount = noHandleCount - 1;
                      } else if (c2r3MT === "M" && c2r4MT === "M") {  
                  setRow3NakedM02(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                        ]
                      )
                      setC2R3VNo(10);
                      setC2R4VNo(10);
                      noHandleCount = noHandleCount - 1;
              } else if (c2r3MT === "L") {  
                setBaseNakedL02(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                  ]
                )
  
                setC2R1VNo(10);
                setC2R2VNo(10);
                setC2R3VNo(10);
                setC2R4VNo(10);
                noHandleCount = noHandleCount - 1;
              }	  
  
      
      } else if (curX === col03X && curY === row03X && c3R3VNo === 11) {
            if (c3r3MT === "S") {  
              setRow3Naked03(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />     
                        ]
                      )
                      setC3R3VNo(10);
                      noHandleCount = noHandleCount - 1;
                    } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                      setRow2NakedM03(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />     
                        ]
                      )
                      setC3R2VNo(10);
                      setC3R3VNo(10);
                      noHandleCount = noHandleCount - 1;
                    } else if (c3r3MT === "M" && c3r4MT === "M") {  
                setRow3NakedM03(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />     
                      ]
                    )
                    setC3R3VNo(10);
                    setC3R4VNo(10);
                    noHandleCount = noHandleCount - 1;
            } else if (c3r3MT === "L") {  
              setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
  
              setC3R1VNo(10);
              setC3R2VNo(10);
              setC3R3VNo(10);
              setC3R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }	  
        
       
      } else if (curX === col04X && curY === row03X && c4R3VNo === 11) {
           if (c4r3MT === "S") {  
                    setRow3Naked04(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />    
                    ]
                    )
                    setC4R3VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                    setRow2NakedM04(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />    
                    ]
                    )
                    setC4R2VNo(10);
                    setC4R3VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c4r3MT === "M" && c4r4MT === "M") {  
                  
            
                  setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />    
                  ]
                  )
                  setC4R3VNo(10);
                  setC4R4VNo(10);
                  noHandleCount = noHandleCount - 1;
          } else if (c4r3MT === "L") {  
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
              ]
            )

            setC4R1VNo(10);
            setC4R2VNo(10);
            setC4R3VNo(10);
            setC4R4VNo(10);
            noHandleCount = noHandleCount - 1;
          }
       
      } else if (curX === col05X && curY === row03X && c5R3VNo === 11) {
    
          if (c5r3MT === "S") {  
                  setRow3Naked05(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />     
                    ]
                    )
                    setC5R3VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                    setRow2NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />     
                    ]
                    )
                    setC5R2VNo(10);
                    setC5R3VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c5r3MT === "M" && c5r4MT === "M") {  
            setRow3NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />     
                  ]
                  )
                  setC5R3VNo(10);
                  setC5R4VNo(10);
                  noHandleCount = noHandleCount - 1;
          } else if (c5r3MT === "L") {  
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
            )

            setC5R1VNo(10);
            setC5R2VNo(10);
            setC5R3VNo(10);
            setC5R4VNo(10);
            noHandleCount = noHandleCount - 1;
          }	  
  
        
      } else if (curX === col06X && curY === row03X && c6R3VNo === 11) {
             if (c6r3MT === "S") {  
                      setRow3Naked06(
                        [
                          <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                        ]
                      )
                      setC6R3VNo(10);
                      noHandleCount = noHandleCount - 1;
                    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                      setRow2NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                        ]
                      )
                      setC6R2VNo(10);
                      setC6R3VNo(10);
                      noHandleCount = noHandleCount - 1;
                    } else if (c6r3MT === "M" && c6r4MT === "M") { 
              setRow3NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                      ]
                    )
                    setC6R3VNo(10);
                    setC6R4VNo(10);
                    noHandleCount = noHandleCount - 1;
            } else if (c6r3MT === "L") {  
              setBaseNakedL06(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                ]
              )

              setC6R1VNo(10);
              setC6R2VNo(10);
              setC6R3VNo(10);
              setC6R4VNo(10);
              noHandleCount = noHandleCount - 1;
            }	  

      } else if (curX === col07X && curY === row03X && c7R3VNo === 11) {
    
        if (c7r3MT === "S") {  
          setRow3Naked07(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                   ]
                 )
                 setC7R3VNo(10);
                 noHandleCount = noHandleCount - 1;
                } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                  setRow2NakedM07(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                    ]
                  )
                  setC7R2VNo(10);
                  setC7R3VNo(10);
                  noHandleCount = noHandleCount - 1;
                } else if (c7r3MT === "M" && c7r4MT === "M") { 
         setRow3NakedM07(
                 [
                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                 ]
               )
               setC7R3VNo(10);
               setC7R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c7r3MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )

        setC7R1VNo(10);
        setC7R2VNo(10);
        setC7R3VNo(10);
        setC7R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	
      
      } else if (curX === col08X && curY === row03X && c8R3VNo === 11) {
        if (c8r3MT === "S") {  
          setRow3Naked08(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                    ]
                  )
                  setC8R3VNo(10);
                  noHandleCount = noHandleCount - 1;
                } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                  setRow2NakedM08(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                    ]
                  )
                  setC8R2VNo(10);
                  setC8R3VNo(10);
                  noHandleCount = noHandleCount - 1;
                } else if (c8r3MT === "M" && c8r4MT === "M") { 
         setRow3NakedM08(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                  ]
                )
                setC8R3VNo(10);
                setC8R4VNo(10);
                noHandleCount = noHandleCount - 1;
        } else if (c8r3MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )

          setC8R1VNo(10);
          setC8R2VNo(10);
          setC8R3VNo(10);
          setC8R4VNo(10);
          noHandleCount = noHandleCount - 1;
        }
  
       
      } else if (curX === col09X && curY === row03X && c9R3VNo === 11) {
        if (c9r3MT === "S") {  
          setRow3Naked09(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                     ]
                   )
                   setC9R3VNo(10);
                   noHandleCount = noHandleCount - 1;
                  } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                    setRow2NakedM09(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                      ]
                    )
                    setC9R2VNo(10);
                    setC9R3VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c9r3MT === "M" && c9r4MT === "M") {   
          setRow3NakedM09(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                   ]
                 )
                 setC9R3VNo(10);
                 setC9R4VNo(10);
                 noHandleCount = noHandleCount - 1;
         } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )

          setC9R1VNo(10);
          setC9R2VNo(10);
          setC9R3VNo(10);
          setC9R4VNo(10);
          noHandleCount = noHandleCount - 1;
         }
          
      } else if (curX === col10X && curY === row03X && c10R3VNo === 11) {
        if (c10r3MT === "S") {  
          setRow3Naked10(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                     ]
                   )
                   setC10R3VNo(10);
                   noHandleCount = noHandleCount - 1;
                  } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                    setRow2NakedM10(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                      ]
                    )
                    setC10R2VNo(10);
                    setC10R3VNo(10);
                    noHandleCount = noHandleCount - 1;
                  } else if (c10r3MT === "M" && c10r4MT === "M") {  
          setRow3NakedM10(
                   [
                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                   ]
                 )
                 setC10R3VNo(10);
                 setC10R4VNo(10);
                 noHandleCount = noHandleCount - 1;
         } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )

          setC10R1VNo(10);
          setC10R2VNo(10);
          setC10R3VNo(10);
          setC10R4VNo(10);
          noHandleCount = noHandleCount - 1;
         }	  
         
 
      
      } else if (curX === col11X && curY === row03X && c11R3VNo === 11) {
    
        if (c11r3MT === "S") {  
          setRow3Naked11(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                    ]
                  )
                  setC11R3VNo(10);
                  noHandleCount = noHandleCount - 1;
        } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
          setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
          setC11R2VNo(10);
          setC11R3VNo(10);
          noHandleCount = noHandleCount - 1;
        } else if (c11r3MT === "M" && c11r4MT === "M") {  
         setRow3NakedM11(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                  ]
                )
                setC11R3VNo(10);
                setC11R4VNo(10);
                noHandleCount = noHandleCount - 1;
        } else if (c11r3MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )

          setC11R1VNo(10);
          setC11R2VNo(10);
          setC11R3VNo(10);
          setC11R4VNo(10);
          noHandleCount = noHandleCount - 1;
        }	
      
      } else if (curX === col12X && curY === row03X && c12R3VNo === 11) {
        if (c12r3MT === "S") {  
          setRow3Naked12(
                   [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                   ]
                 )
                 setC12R3VNo(10);
                 noHandleCount = noHandleCount - 1;
                } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                  setRow2NakedM12(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                    ]
                  )
                  setC12R2VNo(10);
                  setC12R3VNo(10);
                  noHandleCount = noHandleCount - 1;
                } else if (c12r3MT === "M" && c12r4MT === "M") {  
         setRow3NakedM12(
                 [
                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                 ]
               )
               setC12R3VNo(10);
               setC12R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c12r3MT === "L") {  
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )

        setC12R1VNo(10);
        setC12R2VNo(10);
        setC12R3VNo(10);
        setC12R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	  
       

      } else if (curX === col13X && curY === row03X && c13R3VNo === 11) {
        if (c13r3MT === "S") {  
          setRow3Naked13(
                 [
                     <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                 ]
               )
               setC13R3VNo(10);
               noHandleCount = noHandleCount - 1;
              } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                setRow2NakedM13(
                  [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                  ]
                )
                setC13R2VNo(10);
                setC13R3VNo(10);
                noHandleCount = noHandleCount - 1;
              } else if (c13r3MT === "M" && c13r4MT === "M") {   
         setRow3NakedM13(
               [
                   <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
               ]
             )
             setC13R3VNo(10);
             setC13R4VNo(10);
             noHandleCount = noHandleCount - 1;
       } else if (c13r3MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )

        setC13R1VNo(10);
        setC13R2VNo(10);
        setC13R3VNo(10);
        setC13R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	  
       
    
        
      } else if (curX === col14X && curY === row03X && c14R3VNo === 11) {
        if (c14r3MT === "S") {  
          setRow3Naked14(
                [
                    <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                ]
              )
              setC14R3VNo(10);
              noHandleCount = noHandleCount - 1;
            } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
              setRow2NakedM14(
                [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                ]
              )
              setC14R2VNo(10);
              setC14R3VNo(10);
              noHandleCount = noHandleCount - 1;
            } else if (c14r3MT === "M" && c14r4MT === "M") {  
        setRow3NakedM14(
              [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
              ]
            )
            setC14R3VNo(10);
            setC14R4VNo(10);
            noHandleCount = noHandleCount - 1;
      } else if (c14r3MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )

        setC14R1VNo(10);
        setC14R2VNo(10);
        setC14R3VNo(10);
        setC14R4VNo(10);
        noHandleCount = noHandleCount - 1;
      }	
       
      } else if (curX === col01X && curY === row04X && c1R4VNo === 11) {
        
        if (c1r4MT === "S") {  
          setRow4Naked01(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                 ]
               )
               setC1R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c1r4MT === "M") {  
         setRow3NakedM01(
                               [
                                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                               ]
                             )
                             setC1R3VNo(10);
                             setC1R4VNo(10);
                             noHandleCount = noHandleCount - 1;
       } else if (c1r4MT === "L") {  
        setBaseNakedL01(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
          ]
        )

        setC1R1VNo(10);
        setC1R2VNo(10);
        setC1R3VNo(10);
        setC1R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	
  
    } else if (curX === col02X && curY === row04X && c2R4VNo === 11) {
      if (c2r4MT === "S") {  
        setRow4Naked02(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                 ]
               )
               setC2R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c2r4MT === "M") {  
         setRow3NakedM02(
                               [
                                 <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                               ]
                             )
                             setC2R3VNo(10);
                             setC2R4VNo(10);
                             noHandleCount = noHandleCount - 1;
       } else if (c2r4MT === "L") {  
        setBaseNakedL02(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
          ]
        )

        setC2R1VNo(10);
        setC2R2VNo(10);
        setC2R3VNo(10);
        setC2R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }
    
    } else if (curX === col03X && curY === row04X && c3R4VNo === 11) {
      if (c3r4MT === "S") {  
        setRow4Naked03(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />     
                 ]
               )
               setC3R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c3r4MT === "M") {  
        setRow3NakedM03(
                             [
                               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />     
                             ]
                           )
                           setC3R3VNo(10);
                           setC3R4VNo(10);
                           noHandleCount = noHandleCount - 1;
       } else if (c3r4MT === "L") {  
        setBaseNakedL03(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
        )

        setC3R1VNo(10);
        setC3R2VNo(10);
        setC3R3VNo(10);
        setC3R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }

    } else if (curX === col04X && curY === row04X && c4R4VNo === 11) {

      if (c4r4MT === "S") {  
        setRow4Naked04(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />    
               ]
               )
               setC4R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c4r4MT === "M") {  
       setRow3NakedM04(
                           [
                             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />    
                         ]
                         )
                         setC4R3VNo(10);
                         setC4R4VNo(10);
                         noHandleCount = noHandleCount - 1;
       } else if (c4r4MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )

        setC4R1VNo(10);
        setC4R2VNo(10);
        setC4R3VNo(10);
        setC4R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	
     
    } else if (curX === col05X && curY === row04X && c5R4VNo === 11) {
 
      if (c5r4MT === "S") {  
        setRow4Naked05(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />     
               ]
              )
              setC5R4VNo(10);
              noHandleCount = noHandleCount - 1;
      } else if (c5r4MT === "M") {  
      setRow3NakedM05(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />     
                        ]
                        )
                        setC5R3VNo(10);
                        setC5R4VNo(10);
                        noHandleCount = noHandleCount - 1;
      } else if (c5r4MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )

        setC5R1VNo(10);
        setC5R2VNo(10);
        setC5R3VNo(10);
        setC5R4VNo(10);
        noHandleCount = noHandleCount - 1;
      }	
      
    } else if (curX === col06X && curY === row04X && c6R4VNo === 11) {
  
      if (c6r4MT === "S") {  
        setRow4Naked06(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                ]
              )
              setC6R4VNo(10);
              noHandleCount = noHandleCount - 1;
      } else if (c6r4MT === "M") {  
       setRow3NakedM06(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                            ]
                          )
                          setC6R3VNo(10);
                          setC6R4VNo(10);
                          noHandleCount = noHandleCount - 1;
      } else if (c6r4MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )

        setC6R1VNo(10);
        setC6R2VNo(10);
        setC6R3VNo(10);
        setC6R4VNo(10);
        noHandleCount = noHandleCount - 1;
      }
     
    } else if (curX === col07X && curY === row04X && c7R4VNo === 11) {
  
      if (c7r4MT === "S") {  
        setRow4Naked07(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                 ]
               )
               setC7R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c7r4MT === "M") {  
         setRow3NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                        ]
                      )
                      setC7R3VNo(10);
                      setC7R4VNo(10);
                      noHandleCount = noHandleCount - 1;
       } else if (c7r4MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )

        setC7R1VNo(10);
        setC7R2VNo(10);
        setC7R3VNo(10);
        setC7R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	
    
    } else if (curX === col08X && curY === row04X && c8R4VNo === 11) {
  
      if (c8r4MT === "S") {  
        setRow4Naked08(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                 ]
               )
               setC8R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c8r4MT === "M") {  
         setRow3NakedM08(
                         [
                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                         ]
                       )
                       setC8R3VNo(10);
                       setC8R4VNo(10);
                       noHandleCount = noHandleCount - 1;
       } else if (c8r4MT === "L") {  
        setBaseNakedL08(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
          ]
        )

        setC8R1VNo(10);
        setC8R2VNo(10);
        setC8R3VNo(10);
        setC8R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	  
       
     
    } else if (curX === col09X && curY === row04X && c9R4VNo === 11) {
      if (c9r4MT === "S") {  
        setRow4Naked09(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                 ]
               )
               setC9R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c9r4MT === "M") {  
        setRow3NakedM09(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                          ]
                        )
                        setC9R3VNo(10);
                        setC9R4VNo(10);
                        noHandleCount = noHandleCount - 1;
       } else if (c9r4MT === "L") {  
        setBaseNakedL09(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
          ]
        )

        setC9R1VNo(10);
        setC9R2VNo(10);
        setC9R3VNo(10);
        setC9R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	
      
    } else if (curX === col10X && curY === row04X && c10R4VNo === 11) {
      if (c10r4MT === "S") {  
        setRow4Naked10(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                ]
              )
              setC10R4VNo(10);
              noHandleCount = noHandleCount - 1;
      } else if (c10r4MT === "M") {  
      setRow3NakedM10(
                         [
                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                         ]
                       )
                       setC10R3VNo(10);
                       setC10R4VNo(10);
                       noHandleCount = noHandleCount - 1;
      } else if (c10r4MT === "L") {  
        setBaseNakedL10(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
          ]
        )

        setC10R1VNo(10);
        setC10R2VNo(10);
        setC10R3VNo(10);
        setC10R4VNo(10);
        noHandleCount = noHandleCount - 1;
      }
   
    } else if (curX === col11X && curY === row04X && c11R4VNo === 11) {
      if (c11r4MT === "S") {  
        setRow4Naked11(
                 [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                 ]
               )
               setC11R4VNo(10);
               noHandleCount = noHandleCount - 1;
       } else if (c11r4MT === "M") {  
        setRow3NakedM11(
                         [
                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                         ]
                       )
                       setC11R3VNo(10);
                       setC11R4VNo(10);
                       noHandleCount = noHandleCount - 1;
       } else if (c11r4MT === "L") {  
        setBaseNakedL11(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
          ]
        )

        setC11R1VNo(10);
        setC11R2VNo(10);
        setC11R3VNo(10);
        setC11R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }	  
       
    
    } else if (curX === col12X && curY === row04X && c12R4VNo === 11) {
      if (c12r4MT === "S") {  
        setRow4Naked12(
                [
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                ]
              )
              setC12R4VNo(10);
              noHandleCount = noHandleCount - 1;
      } else if (c12r4MT === "M") {  
       setRow3NakedM12(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                       ]
                     )
                     setC12R3VNo(10);
                     setC12R4VNo(10);
                     noHandleCount = noHandleCount - 1;
      } else if (c12r4MT === "L") {  
        setBaseNakedL12(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
          ]
        )

        setC12R1VNo(10);
        setC12R2VNo(10);
        setC12R3VNo(10);
        setC12R4VNo(10);
        noHandleCount = noHandleCount - 1;
      }	  
      
       
    } else if (curX === col13X && curY === row04X && c13R4VNo === 11) {
      if (c13r4MT === "S") {  
        setRow4Naked13(
               [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
               ]
             )
             setC13R4VNo(10);
             noHandleCount = noHandleCount - 1;
       } else if (c13r4MT === "M") {  
        setRow3NakedM13(
                      [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                      ]
                    )
                    setC13R3VNo(10);
                    setC13R4VNo(10);
                    noHandleCount = noHandleCount - 1;
       } else if (c13r4MT === "L") {  
        setBaseNakedL13(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
          ]
        )

        setC13R1VNo(10);
        setC13R2VNo(10);
        setC13R3VNo(10);
        setC13R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }
    } else if (curX === col14X && curY === row04X && c14R4VNo === 11) {
      if (c14r4MT === "S") {  
        setRow4Naked14(
               [
                   <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
               ]
             )
             setC14R4VNo(10);
             noHandleCount = noHandleCount - 1;
       } else if (c14r4MT === "M") {  
       setRow3NakedM14(
                     [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                     ]
                   )
                   setC14R3VNo(10);
                   setC14R4VNo(10);
                   noHandleCount = noHandleCount - 1;
       } else if (c14r4MT === "L") {  
        setBaseNakedL14(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
          ]
        )

        setC14R1VNo(10);
        setC14R2VNo(10);
        setC14R3VNo(10);
        setC14R4VNo(10);
        noHandleCount = noHandleCount - 1;
       }
    }  else  {
        alert("You can't add handle at this stage");	
    }
  
}    

     const removeLongHandle = (e)=> {

        if (curX === col01X && curY === row01X && c1R1VNo === 10) {
          if (c1r1MT === "S") {  
            setBaseNaked01(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
                        ]
                      )
                      setC1R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c1r1MT === "M") {  
          setBaseNakedM01(
                           [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                           ]
                         )
                         setC1R1VNo(11);
                         setC1R2VNo(11);
                         noHandleCount = noHandleCount + 1;
          } else if (c1r1MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )

            setC1R1VNo(11);
            setC1R2VNo(11);
            setC1R3VNo(11);
            setC1R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }
      
        } else if (curX === col02X && curY === row01X && c2R1VNo === 10) {
          if (c2r1MT === "S") {  
            setBaseNaked02(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
                       ]
                     )
                     setC2R1VNo(11);
                     noHandleCount = noHandleCount + 1;
         } else if (c2r1MT === "M") {  
                       setBaseNakedM02(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                          ]
                        )
                        setC2R1VNo(11);
                        setC2R2VNo(11);
                        noHandleCount = noHandleCount + 1;
         } else if (c2r1MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )

          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }
          
        } else if (curX === col03X && curY === row01X && c3R1VNo === 10) {
          if (c3r1MT === "S") {  
            setBaseNaked03(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
                        ]
                      )
                      setC3R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c3r1MT === "M") {  
             setBaseNakedM03(
                           [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                           ]
                         )
                         setC3R1VNo(11);
                         setC3R2VNo(11);
                         noHandleCount = noHandleCount + 1;
          } else if (c3r1MT === "L") {  
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
              ]
            )

            setC3R1VNo(11);
            setC3R2VNo(11);
            setC3R3VNo(11);
            setC3R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }
               
        } else if (curX === col04X && curY === row01X && c4R1VNo === 10) {
          if (c4r1MT === "S") {  
            setBaseNaked04(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
                        ]
                      )
                      setC4R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c4r1MT === "M") {  
              setBaseNakedM04(
                           [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                           ]
                         )
                         setC4R1VNo(11);
                         setC4R2VNo(11);
                         noHandleCount = noHandleCount + 1;
          } else if (c4r1MT === "L") {  
            setBaseNakedL04(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
              ]
            )

            setC4R1VNo(11);
            setC4R2VNo(11);
            setC4R3VNo(11);
            setC4R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	  
          
        
        } else if (curX === col05X && curY === row01X && c5R1VNo === 10) {
      
          if (c5r1MT === "S") {  
            setBaseNaked05(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
                        ]
                      )
                      setC5R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c5r1MT === "M") {  
               setBaseNakedM05(
                           [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                           ]
                         )
                         setC5R1VNo(11);
                         setC5R2VNo(11);
                         noHandleCount = noHandleCount + 1;
          } else if (c5r1MT === "L") {  
            setBaseNakedL05(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
            )

            setC5R1VNo(11);
            setC5R2VNo(11);
            setC5R3VNo(11);
            setC5R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	  
          
          
        } else if (curX === col06X && curY === row01X && c6R1VNo === 10) {
          if (c6r1MT === "S") {  
            setBaseNaked06(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
                       ]
                     )
                     setC6R1VNo(11);
                     noHandleCount = noHandleCount + 1;
         } else if (c6r1MT === "M") {  
               setBaseNakedM06(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                          ]
                        )
                        setC6R1VNo(11);
                        setC6R2VNo(11);
                        noHandleCount = noHandleCount + 1;
         } else if (c6r1MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )

          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	   
         
        } else if (curX === col07X && curY === row01X && c7R1VNo === 10) {
          if (c7r1MT === "S") {  
            setBaseNaked07(
                         [
                           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
                         ]
                       )
                       setC7R1VNo(11);
                       noHandleCount = noHandleCount + 1;
           } else if (c7r1MT === "M") {  
                 setBaseNakedM07(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                            ]
                          )
                          setC7R1VNo(11);
                          setC7R2VNo(11);
                          noHandleCount = noHandleCount + 1;
           } else if (c7r1MT === "L") {  
            setBaseNakedL07(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
              ]
            )

            setC7R1VNo(11);
            setC7R2VNo(11);
            setC7R3VNo(11);
            setC7R4VNo(11);
            noHandleCount = noHandleCount + 1;
           }
         
        
        } else if (curX === col08X && curY === row01X && c8R1VNo === 10) {
      
          if (c8r1MT === "S") {  
            setBaseNaked08(
                         [
                           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
                         ]
                       )
                       setC8R1VNo(11);
                       noHandleCount = noHandleCount + 1;
           } else if (c8r1MT === "M") {  
                 setBaseNakedM08(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                          setC8R1VNo(11);
                          setC8R2VNo(11);
                          noHandleCount = noHandleCount + 1;
           } else if (c8r1MT === "L") {  
            setBaseNakedL08(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
              ]
            )

            setC8R1VNo(11);
            setC8R2VNo(11);
            setC8R3VNo(11);
            setC8R4VNo(11);
            noHandleCount = noHandleCount + 1;
           }
      
        } else if (curX === col09X && curY === row01X && c9R1VNo === 10) {
          if (c9r1MT === "S") {  
            setBaseNaked09(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
                        ]
                      )
                      setC9R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c9r1MT === "M") {  
                setBaseNakedM09(
                           [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                           ]
                         )
                         setC9R1VNo(11);
                         setC9R2VNo(11);
                         noHandleCount = noHandleCount + 1;
          } else if (c9r1MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )

            setC9R1VNo(11);
            setC9R2VNo(11);
            setC9R3VNo(11);
            setC9R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	            
        
          
        } else if (curX === col10X && curY === row01X && c10R1VNo === 10) {
      
          if (c10r1MT === "S") {  
            setBaseNaked10(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
                        ]
                      )
                      setC10R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c10r1MT === "M") {  
              setBaseNakedM10(
                           [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                           ]
                         )
                         setC10R1VNo(11);
                         setC10R2VNo(11);
                         noHandleCount = noHandleCount + 1;
          } else if (c10r1MT === "L") {  
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
              ]
            )

            setC10R1VNo(11);
            setC10R2VNo(11);
            setC10R3VNo(11);
            setC10R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	  
          
      
        } else if (curX === col11X && curY === row01X && c11R1VNo === 10) {
      
          if (c11r1MT === "S") {  
            setBaseNaked11(
                         [
                           <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
                         ]
                       )
                       setC11R1VNo(11);
                       noHandleCount = noHandleCount + 1;
           } else if (c11r1MT === "M") {  
                setBaseNakedM11(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                            ]
                          )
                          setC11R1VNo(11);
                          setC11R2VNo(11);
                          noHandleCount = noHandleCount + 1;
           } else if (c11r1MT === "L") {  
            setBaseNakedL11(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
              ]
            )

            setC11R1VNo(11);
            setC11R2VNo(11);
            setC11R3VNo(11);
            setC11R4VNo(11);
            noHandleCount = noHandleCount + 1;
           }	  
           
        
        } else if (curX === col12X && curY === row01X && c12R1VNo === 10) {
          if (c12r1MT === "S") {  
            setBaseNaked12(
                        [
                          <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
                        ]
                      )
                      setC12R1VNo(11);
                      noHandleCount = noHandleCount + 1;
          } else if (c12r1MT === "M") {  
              setBaseNakedM12(
                           [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                           ]
                         )
                         setC12R1VNo(11);
                         setC12R2VNo(11);
                         noHandleCount = noHandleCount + 1;
          } else if (c12r1MT === "L") {  
            setBaseNakedL12(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
              ]
            )

            setC12R1VNo(11);
            setC12R2VNo(11);
            setC12R3VNo(11);
            setC12R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	
        
      
        } else if (curX === col13X && curY === row01X && c13R1VNo === 10) {
      
          if (c13r1MT === "S") {  
            setBaseNaked13(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
                       ]
                     )
                     setC13R1VNo(11);
                     noHandleCount = noHandleCount + 1;
         } else if (c13r1MT === "M") {  
             setBaseNakedM13(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                          ]
                        )
                        setC13R1VNo(11);
                        setC13R2VNo(11);
                        noHandleCount = noHandleCount + 1;
         } else if (c13r1MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )

          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	  
         
      
        } else if (curX === col14X && curY === row01X && c14R1VNo === 10) {
          if (c14r1MT === "S") {  
            setBaseNaked14(
                       [
                         <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
                       ]
                     )
                     setC14R1VNo(11);
                     noHandleCount = noHandleCount + 1;
         } else if (c14r1MT === "M") {  
               setBaseNakedM14(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                          ]
                        )
                        setC14R1VNo(11);
                        setC14R2VNo(11);
                        noHandleCount = noHandleCount + 1;
         } else if (c14r1MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )

          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	  
         
       } 	else if (curX === col01X && curY === row02X && c1R2VNo === 10) {
        if (c1r2MT === "S") {  
          setRow2Naked01(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
                       ]
                     )
                     setC1R2VNo(11);
                     noHandleCount = noHandleCount + 1;
                    } else if (c1r1MT === "M" && c1r2MT === "M") { 
                      setBaseNakedM01(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                        ]
                      )
                      setC1R1VNo(11);
                      setC1R2VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c1r2MT === "M" && c1r3MT === "M") {    
               setRow2NakedM01(
                         [
                           <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                         ]
                       )
                       setC1R2VNo(11);
                       setC1R3VNo(11);
                       noHandleCount = noHandleCount + 1;
          } else if (c1r2MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )

            setC1R1VNo(11);
            setC1R2VNo(11);
            setC1R3VNo(11);
            setC1R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	
     
       } else if (curX === col02X && curY === row02X && c2R2VNo === 10) {
     
        if (c2r2MT === "S") {  
          setRow2Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c2r1MT === "M" && c2r2MT === "M") { 
                    setBaseNakedM02(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(11);
                    setC2R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c2r2MT === "M" && c2r3MT === "M") {     
                   
               setRow2NakedM02(
                        [
                          <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                        ]
                      )
                      setC2R2VNo(11);
                      setC2R3VNo(11);
                      noHandleCount = noHandleCount + 1;
         } else if (c2r2MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )

          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	  
         
     
       } else if (curX === col03X && curY === row02X && c3R2VNo === 10) {
        if (c3r2MT === "S") {  
          setRow2Naked03(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
                     ]
                   )
                   setC3R2VNo(11);
                   noHandleCount = noHandleCount + 1;
                  } else if (c3r1MT === "M" && c3r2MT === "M") { 
                    setBaseNakedM03(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                      ]
                    )
                    setC3R1VNo(11);
                    setC3R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c3r2MT === "M" && c3r3MT === "M") {   
               setRow2NakedM03(
                  [
                    <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                  ]
                )
                setC3R2VNo(11);
                setC3R3VNo(11);
                noHandleCount = noHandleCount + 1;
        } else if (c3r2MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )

          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          noHandleCount = noHandleCount + 1;
        }	  
     
       } else if (curX === col04X && curY === row02X && c4R2VNo === 10) {
        if (c4r2MT === "S") {  
          setRow2Naked04(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
                    ]
                  )
                  setC4R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c4r1MT === "M" && c4r2MT === "M") { 
                  setBaseNakedM04(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
                  setC4R1VNo(11);
                  setC4R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c4r2MT === "M" && c4r3MT === "M") {   
             setRow2NakedM04(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                      ]
                    )
                    setC4R2VNo(11);
                    setC4R3VNo(11);
                    noHandleCount = noHandleCount + 1;
       } else if (c4r2MT === "L") {  
        setBaseNakedL04(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
          ]
        )

        setC4R1VNo(11);
        setC4R2VNo(11);
        setC4R3VNo(11);
        setC4R4VNo(11);
        noHandleCount = noHandleCount + 1;
       }	         
       
       } else if (curX === col05X && curY === row02X && c5R2VNo === 10) {
     
        if (c5r2MT === "S") {  
          setRow2Naked05(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
                    ]
                  )
                  setC5R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c5r1MT === "M" && c5r2MT === "M") { 
                  setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
                  setC5R1VNo(11);
                  setC5R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c5r2MT === "M" && c5r3MT === "M") {      
            setRow2NakedM05(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                      ]
                    )
                    setC5R2VNo(11);
                    setC5R3VNo(11);
                    noHandleCount = noHandleCount + 1;
       } else if (c5r2MT === "L") {  
        setBaseNakedL05(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
          ]
        )

        setC5R1VNo(11);
        setC5R2VNo(11);
        setC5R3VNo(11);
        setC5R4VNo(11);
        noHandleCount = noHandleCount + 1;
       }	         
         
       } else if (curX === col06X && curY === row02X && c6R2VNo === 10) {
     
        if (c6r2MT === "S") {  
          setRow2Naked06(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
                    ]
                  )
                  setC6R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c6r1MT === "M" && c6r2MT === "M") { 
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(11);
                  setC6R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c6r2MT === "M" && c6r3MT === "M") {      
             setRow2NakedM06(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                      ]
                    )
                    setC6R2VNo(11);
                    setC6R3VNo(11);
                    noHandleCount = noHandleCount + 1;
       } else if (c6r2MT === "L") {  
        setBaseNakedL06(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
          ]
        )

        setC6R1VNo(11);
        setC6R2VNo(11);
        setC6R3VNo(11);
        setC6R4VNo(11);
        noHandleCount = noHandleCount + 1;
       }	         
        
       } else if (curX === col07X && curY === row02X && c7R2VNo === 10) {
     
        if (c7r2MT === "S") {  
          setRow2Naked07(
                    [
                      <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
                    ]
                  )
                  setC7R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c7r1MT === "M" && c7r2MT === "M") { 
                  setBaseNakedM07(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(11);
                  setC7R2VNo(11);
                  noHandleCount = noHandleCount + 1;
                } else if (c7r2MT === "M" && c7r3MT === "M") {  
              setRow2NakedM07(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                      ]
                    )
                    setC7R2VNo(11);
                    setC7R3VNo(11);
                    noHandleCount = noHandleCount + 1;
       } else if (c7r2MT === "L") {  
        setBaseNakedL07(
          [
            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
          ]
        )

        setC7R1VNo(11);
        setC7R2VNo(11);
        setC7R3VNo(11);
        setC7R4VNo(11);
        noHandleCount = noHandleCount + 1;
       }	
       
       } else if (curX === col08X && curY === row02X && c8R2VNo === 10) {
     
        if (c8r2MT === "S") {  
          setRow2Naked08(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
                     ]
                   )
                   setC8R2VNo(11);
                   noHandleCount = noHandleCount + 1;
                  } else if (c8r1MT === "M" && c8r2MT === "M") { 
                    setBaseNakedM08(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                      ]
                    )
                    setC8R1VNo(11);
                    setC8R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c8r2MT === "M" && c8r3MT === "M") {   
                setRow2NakedM08(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                       ]
                     )
                     setC8R2VNo(11);
                     setC8R3VNo(11);
                     noHandleCount = noHandleCount + 1;
        } else if (c8r2MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )

          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          noHandleCount = noHandleCount + 1;
        }	          
     
       } else if (curX === col09X && curY === row02X && c9R2VNo === 10) {
     
        if (c9r2MT === "S") {  
          setRow2Naked09(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
                      ]
                    )
                    setC9R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c9r1MT === "M" && c9r2MT === "M") { 
                    setBaseNakedM09(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                      ]
                    )
                    setC9R1VNo(11);
                    setC9R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c9r2MT === "M" && c9r3MT === "M") { 
                setRow2NakedM09(
                        [
                          <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                        ]
                      )
                      setC9R2VNo(11);
                      setC9R3VNo(11);
                      noHandleCount = noHandleCount + 1;
         } else if (c9r2MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )

          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
         
       } else if (curX === col10X && curY === row02X && c10R2VNo === 10) {
     
        if (c10r2MT === "S") {  
          setRow2Naked10(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
                      ]
                    )
                    setC10R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c10r1MT === "M" && c10r2MT === "M") { 
                    setBaseNakedM10(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                      ]
                    )
                    setC10R1VNo(11);
                    setC10R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c10r2MT === "M" && c10r3MT === "M") {  
               setRow2NakedM10(
                        [
                          <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                        ]
                      )
                      setC10R2VNo(11);
                      setC10R3VNo(11);
                      noHandleCount = noHandleCount + 1;
         } else if (c10r2MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )

          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
     
       } else if (curX === col11X && curY === row02X && c11R2VNo === 10) {
     
        if (c11r2MT === "S") {  
          setRow2Naked11(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
                     ]
                   )
                   setC11R2VNo(11);
                   noHandleCount = noHandleCount + 1;
                  } else if (c11r1MT === "M" && c11r2MT === "M") { 
                    setBaseNakedM11(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                      ]
                    )
                    setC11R1VNo(11);
                    setC11R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c11r2MT === "M" && c11r3MT === "M") {  
              setRow2NakedM11(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                       ]
                     )
                     setC11R2VNo(11);
                     setC11R3VNo(11);
                     noHandleCount = noHandleCount + 1;
        } else if (c11r2MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )

          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          noHandleCount = noHandleCount + 1;
        }	          
       
       } else if (curX === col12X && curY === row02X && c12R2VNo === 10) {
     
        if (c12r2MT === "S") {  
          setRow2Naked12(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
                      ]
                    )
                    setC12R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c12r1MT === "M" && c12r2MT === "M") { 
                    setBaseNakedM12(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                      ]
                    )
                    setC12R1VNo(11);
                    setC12R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c12r2MT === "M" && c12r3MT === "M") {  
               
                     setRow2NakedM12(
                        [
                          <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                        ]
                      )
                      setC12R2VNo(11);
                      setC12R3VNo(11);
                      noHandleCount = noHandleCount + 1;
         } else if (c12r2MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )

          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
     
       } else if (curX === col13X && curY === row02X && c13R2VNo === 10) {
     
        if (c13r2MT === "S") {  
          setRow2Naked13(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
                     ]
                   )
                   setC13R2VNo(11);
                   noHandleCount = noHandleCount + 1;
                  } else if (c13r1MT === "M" && c13r2MT === "M") { 
                    setBaseNakedM13(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                      ]
                    )
                    setC13R1VNo(11);
                    setC13R2VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c13r2MT === "M" && c13r3MT === "M") {   
               setRow2NakedM13(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                       ]
                     )
                     setC13R2VNo(11);
                     setC13R3VNo(11);
                     noHandleCount = noHandleCount + 1;
        } else if (c13r2MT === "L") {  
          setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )

          setC13R1VNo(11);
          setC13R2VNo(11);
          setC13R3VNo(11);
          setC13R4VNo(11);
          noHandleCount = noHandleCount + 1;
        }	          
     
       } else if (curX === col14X && curY === row02X && c14R2VNo === 10) {
        if (c14r2MT === "S") {  
          setRow2Naked14(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
                       ]
                     )
                     setC14R2VNo(11);
                     noHandleCount = noHandleCount + 1;
                    } else if (c14r1MT === "M" && c14r2MT === "M") { 
                      setBaseNakedM14(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                        ]
                      )
                      setC14R1VNo(11);
                      setC14R2VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c14r2MT === "M" && c14r3MT === "M") {  
                  setRow2NakedM14(
                         [
                           <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                         ]
                       )
                       setC14R2VNo(11);
                       setC14R3VNo(11);
                       noHandleCount = noHandleCount + 1;
          } else if (c14r2MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
  
            setC14R1VNo(11);
            setC14R2VNo(11);
            setC14R3VNo(11);
            setC14R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	  
          
        
      } 	else if (curX === col01X && curY === row03X && c1R3VNo === 10) {
        if (c1r3MT === "S") {  
          setRow3Naked01(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
                       ]
                     )
                     setC1R3VNo(11);
                     noHandleCount = noHandleCount + 1;
                    } else if (c1r2MT === "M" && c1r3MT === "M" && c1r4MT === "S") { 
                      setRow2NakedM01(
                        [
                          <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                        ]
                      )
                      setC1R2VNo(11);
                      setC1R3VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c1r3MT === "M" && c1r4MT === "M") {   
                 setRow3NakedM01(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                       ]
                     )
                     setC1R3VNo(11);
                     setC1R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c1r3MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )

            setC1R1VNo(11);
            setC1R2VNo(11);
            setC1R3VNo(11);
            setC1R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }
     
       } else if (curX === col02X && curY === row03X && c2R3VNo === 10) {
     
        if (c2r3MT === "S") {  
          setRow3Naked02(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
                      ]
                    )
                    setC2R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c2r2MT === "M" && c2r3MT === "M" && c2r4MT === "S") { 
                    setRow2NakedM02(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(11);
                    setC2R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c2r3MT === "M" && c2r4MT === "M") { 
                   setRow3NakedM02(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                      ]
                    )
                    setC2R3VNo(11);
                    setC2R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c2r3MT === "L") {  
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )

          setC2R1VNo(11);
          setC2R2VNo(11);
          setC2R3VNo(11);
          setC2R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	
     
       } else if (curX === col03X && curY === row03X && c3R3VNo === 10) {
     
        if (c3r3MT === "S") {  
          setRow3Naked03(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
                      ]
                    )
                    setC3R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c3r2MT === "M" && c3r3MT === "M" && c3r4MT === "S") { 
                    setRow2NakedM03(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                      ]
                    )
                    setC3R2VNo(11);
                    setC3R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c3r3MT === "M" && c3r4MT === "M") {  
                  setRow3NakedM03(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                      ]
                    )
                    setC3R3VNo(11);
                    setC3R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c3r3MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )

          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }
     
       } else if (curX === col04X && curY === row03X && c4R3VNo === 10) {
        if (c4r3MT === "S") {  
          setRow3Naked04(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
                      ]
                    )
                    setC4R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c4r2MT === "M" && c4r3MT === "M" && c4r4MT === "S") { 
                    setRow2NakedM04(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                      ]
                    )
                    setC4R2VNo(11);
                    setC4R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c4r3MT === "M" && c4r4MT === "M") {  
                   setRow3NakedM04(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                      ]
                    )
                    setC4R3VNo(11);
                    setC4R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c4r3MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )

          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	
       
       } else if (curX === col05X && curY === row03X && c5R3VNo === 10) {
     
        if (c5r3MT === "S") {  
          setRow3Naked05(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
                      ]
                    )
                    setC5R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c5r2MT === "M" && c5r3MT === "M" && c5r4MT === "S") { 
                    setRow2NakedM05(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                      ]
                    )
                    setC5R2VNo(11);
                    setC5R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c5r3MT === "M" && c5r4MT === "M") {  
                    setRow3NakedM05(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                      ]
                    )
                    setC5R3VNo(11);
                    setC5R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c5r3MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )

          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
         
       } else if (curX === col06X && curY === row03X && c6R3VNo === 10) {
        if (c6r3MT === "S") {  
          setRow3Naked06(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
                       ]
                     )
                     setC6R3VNo(11);
                     noHandleCount = noHandleCount + 1;
                    } else if (c6r2MT === "M" && c6r3MT === "M" && c6r4MT === "S") { 
                      setRow2NakedM06(
                        [
                          <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                        ]
                      )
                      setC6R2VNo(11);
                      setC6R3VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c6r3MT === "M" && c6r4MT === "M") {  
                    setRow3NakedM06(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                       ]
                     )
                     setC6R3VNo(11);
                     setC6R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c6r3MT === "L") {  
            setBaseNakedL06(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
              ]
            )
  
            setC6R1VNo(11);
            setC6R2VNo(11);
            setC6R3VNo(11);
            setC6R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	            
        
       } else if (curX === col07X && curY === row03X && c7R3VNo === 10) {
     
        if (c7r3MT === "S") {  
          setRow3Naked07(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
                      ]
                    )
                    setC7R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c7r2MT === "M" && c7r3MT === "M" && c7r4MT === "S") { 
                    setRow2NakedM07(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                      ]
                    )
                    setC7R2VNo(11);
                    setC7R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c7r3MT === "M" && c7r4MT === "M") { 
                   setRow3NakedM07(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                      ]
                    )
                    setC7R3VNo(11);
                    setC7R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c7r3MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )

          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
       
       } else if (curX === col08X && curY === row03X && c8R3VNo === 10) {
     
        if (c8r3MT === "S") {  
          setRow3Naked08(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
                      ]
                    )
                    setC8R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c8r2MT === "M" && c8r3MT === "M" && c8r4MT === "S") { 
                    setRow2NakedM08(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                      ]
                    )
                    setC8R2VNo(11);
                    setC8R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c8r3MT === "M" && c8r4MT === "M") { 
                    setRow3NakedM08(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                      ]
                    )
                    setC8R3VNo(11);
                    setC8R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c8r3MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )

          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
     
       } else if (curX === col09X && curY === row03X && c9R3VNo === 10) {
     
        if (c9r3MT === "S") {  
          setRow3Naked09(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
                      ]
                    )
                    setC9R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c9r2MT === "M" && c9r3MT === "M" && c9r4MT === "S") { 
                    setRow2NakedM09(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                      ]
                    )
                    setC9R2VNo(11);
                    setC9R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c9r3MT === "M" && c9r4MT === "M") { 
                  setRow3NakedM09(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                      ]
                    )
                    setC9R3VNo(11);
                    setC9R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c9r3MT === "L") {  
          setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )

          setC9R1VNo(11);
          setC9R2VNo(11);
          setC9R3VNo(11);
          setC9R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
         
       } else if (curX === col10X && curY === row03X && c10R3VNo === 10) {
     
        if (c10r3MT === "S") {  
          setRow3Naked10(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
                      ]
                    )
                    setC10R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c10r2MT === "M" && c10r3MT === "M" && c10r4MT === "S") { 
                    setRow2NakedM10(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                      ]
                    )
                    setC10R2VNo(11);
                    setC10R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c10r3MT === "M" && c10r4MT === "M") {  
                  setRow3NakedM10(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                      ]
                    )
                    setC10R3VNo(11);
                    setC10R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c10r3MT === "L") {  
          setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )

          setC10R1VNo(11);
          setC10R2VNo(11);
          setC10R3VNo(11);
          setC10R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
     
       } else if (curX === col11X && curY === row03X && c11R3VNo === 10) {
     
        if (c11r3MT === "S") {  
          setRow3Naked11(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
                      ]
                    )
                    setC11R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c11r2MT === "M" && c11r3MT === "M" && c11r4MT === "S") { 
                    setRow2NakedM11(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                      ]
                    )
                    setC11R2VNo(11);
                    setC11R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c11r3MT === "M" && c11r4MT === "M") {  
                  setRow3NakedM11(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                      ]
                    )
                    setC11R3VNo(11);
                    setC11R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c11r3MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )

          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
       
       } else if (curX === col12X && curY === row03X && c12R3VNo === 10) {
     
        if (c12r3MT === "S") {  
          setRow3Naked12(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
                     ]
                   )
                   setC12R3VNo(11);
                   noHandleCount = noHandleCount + 1;
                  } else if (c12r2MT === "M" && c12r3MT === "M" && c12r4MT === "S") { 
                    setRow2NakedM12(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                      ]
                    )
                    setC12R2VNo(11);
                    setC12R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c12r3MT === "M" && c12r4MT === "M") {  
         setRow3NakedM12(
                     [
                       <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                     ]
                   )
                   setC12R3VNo(11);
                   setC12R4VNo(11);
                   noHandleCount = noHandleCount + 1;
        } else if (c12r3MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )

          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          noHandleCount = noHandleCount + 1;
        }	          
     
       } else if (curX === col13X && curY === row03X && c13R3VNo === 10) {
     
        if (c13r3MT === "S") {  
          setRow3Naked13(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
                       ]
                     )
                     setC13R3VNo(11);
                     noHandleCount = noHandleCount + 1;
                    } else if (c13r2MT === "M" && c13r3MT === "M" && c13r4MT === "S") { 
                      setRow2NakedM13(
                        [
                          <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                        ]
                      )
                      setC13R2VNo(11);
                      setC13R3VNo(11);
                      noHandleCount = noHandleCount + 1;
                    } else if (c13r3MT === "M" && c13r4MT === "M") {  
             setRow3NakedM13(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                       ]
                     )
                     setC13R3VNo(11);
                     setC13R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c13r3MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
  
            setC13R1VNo(11);
            setC13R2VNo(11);
            setC13R3VNo(11);
            setC13R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	  
          
     
       } else if (curX === col14X && curY === row03X && c14R3VNo === 10) {
        if (c14r3MT === "S") {  
          setRow3Naked14(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
                      ]
                    )
                    setC14R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c14r2MT === "M" && c14r3MT === "M" && c14r4MT === "S") { 
                    setRow2NakedM14(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                      ]
                    )
                    setC14R2VNo(11);
                    setC14R3VNo(11);
                    noHandleCount = noHandleCount + 1;
                  } else if (c14r3MT === "M" && c14r4MT === "M") {  
            setRow3NakedM14(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                      ]
                    )
                    setC14R3VNo(11);
                    setC14R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c14r3MT === "L") {  
          setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )

          setC14R1VNo(11);
          setC14R2VNo(11);
          setC14R3VNo(11);
          setC14R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
       
      }  	else if (curX === col01X && curY === row03X && c1R4VNo === 10) {
        
        if (c1r4MT === "S") {  
          setRow4Naked01(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
                       ]
                     )
                     setC1R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c1r4MT === "M") {  
             setRow3NakedM01(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                       ]
                     )
                     setC1R3VNo(11);
                     setC1R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c1r4MT === "L") {  
            setBaseNakedL01(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
              ]
            )

            setC1R1VNo(11);
            setC1R2VNo(11);
            setC1R3VNo(11);
            setC1R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }
       } else if (curX === col02X && curY === row04X && c2R4VNo === 10) {
     
        if (c2r4MT === "S") {  
   
          setRow4Naked02(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
                       ]
                     )
                     setC2R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c2r4MT === "M") {  
            setRow3NakedM02(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                       ]
                     )
                     setC2R3VNo(11);
                     setC2R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c2r4MT === "L") {  
            setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
  
            setC2R1VNo(11);
            setC2R2VNo(11);
            setC2R3VNo(11);
            setC2R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	                     
     
       } else if (curX === col03X && curY === row04X && c3R4VNo === 10) {
     
        if (c3r4MT === "S") {  
          setRow4Naked03(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
                      ]
                    )
                    setC3R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c3r4MT === "M") {  
            setRow3NakedM03(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                      ]
                    )
                    setC3R3VNo(11);
                    setC3R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c3r4MT === "L") {  
          setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )

          setC3R1VNo(11);
          setC3R2VNo(11);
          setC3R3VNo(11);
          setC3R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }
     
       } else if (curX === col04X && curY === row04X && c4R4VNo === 10) {
        if (c4r4MT === "S") {  
          setRow4Naked04(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
                      ]
                    )
                    setC4R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c4r4MT === "M") {  
            setRow3NakedM04(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                      ]
                    )
                    setC4R3VNo(11);
                    setC4R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c4r4MT === "L") {  
          setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )

          setC4R1VNo(11);
          setC4R2VNo(11);
          setC4R3VNo(11);
          setC4R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
       
       } else if (curX === col05X && curY === row04X && c5R4VNo === 10) {
     
        if (c5r4MT === "S") {  
          setRow4Naked05(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
                      ]
                    )
                    setC5R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c5r4MT === "M") {  
           setRow3NakedM05(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                      ]
                    )
                    setC5R3VNo(11);
                    setC5R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c5r4MT === "L") {  
          setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )

          setC5R1VNo(11);
          setC5R2VNo(11);
          setC5R3VNo(11);
          setC5R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
         
       } else if (curX === col06X && curY === row04X && c6R4VNo === 10) {
     
        if (c6r4MT === "S") {  
          setRow4Naked06(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
                      ]
                    )
                    setC6R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c6r4MT === "M") {  
           setRow3NakedM06(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                      ]
                    )
                    setC6R3VNo(11);
                    setC6R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c6r4MT === "L") {  
          setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )

          setC6R1VNo(11);
          setC6R2VNo(11);
          setC6R3VNo(11);
          setC6R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
        
       } else if (curX === col07X && curY === row04X && c7R4VNo === 10) {
     
        if (c7r4MT === "S") {  
          setRow4Naked07(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
                      ]
                    )
                    setC7R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c7r4MT === "M") {  
           setRow3NakedM07(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                      ]
                    )
                    setC7R3VNo(11);
                    setC7R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c7r4MT === "L") {  
          setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )

          setC7R1VNo(11);
          setC7R2VNo(11);
          setC7R3VNo(11);
          setC7R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
       
       } else if (curX === col08X && curY === row04X && c8R4VNo === 10) {
     
        if (c8r4MT === "S") {  
          setRow4Naked08(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
                     ]
                   )
                   setC8R4VNo(11);
                   noHandleCount = noHandleCount + 1;
        } else if (c8r4MT === "M") {  
           setRow3NakedM08(
                     [
                       <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                     ]
                   )
                   setC8R3VNo(11);
                   setC8R4VNo(11);
                   noHandleCount = noHandleCount + 1;
        } else if (c8r4MT === "L") {  
          setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )

          setC8R1VNo(11);
          setC8R2VNo(11);
          setC8R3VNo(11);
          setC8R4VNo(11);
          noHandleCount = noHandleCount + 1;
        }	          
     
       } else if (curX === col09X && curY === row04X && c9R4VNo === 10) {
     
        if (c9r4MT === "S") {  
          setRow4Naked09(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
                       ]
                     )
                     setC9R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c9r4MT === "M") {  
               setRow3NakedM09(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                       ]
                     )
                     setC9R3VNo(11);
                     setC9R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c9r4MT === "L") {  
            setBaseNakedL09(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
              ]
            )

            setC9R1VNo(11);
            setC9R2VNo(11);
            setC9R3VNo(11);
            setC9R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	
         
       } else if (curX === col10X && curY === row04X && c10R4VNo === 10) {
     
        if (c10r4MT === "S") {  
          setRow4Naked10(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
                       ]
                     )
                     setC10R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c10r4MT === "M") {  
              setRow3NakedM10(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                       ]
                     )
                     setC10R3VNo(11);
                     setC10R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c10r4MT === "L") {  
            setBaseNakedL10(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
              ]
            )

            setC10R1VNo(11);
            setC10R2VNo(11);
            setC10R3VNo(11);
            setC10R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	            
     
       } else if (curX === col11X && curY === row04X && c11R4VNo === 10) {
     
        if (c11r4MT === "S") {  
          setRow4Naked11(
                      [
                        <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
                      ]
                    )
                    setC11R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c11r4MT === "M") {  
             setRow3NakedM11(
                      [
                        <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                      ]
                    )
                    setC11R3VNo(11);
                    setC11R4VNo(11);
                    noHandleCount = noHandleCount + 1;
         } else if (c11r4MT === "L") {  
          setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )

          setC11R1VNo(11);
          setC11R2VNo(11);
          setC11R3VNo(11);
          setC11R4VNo(11);
          noHandleCount = noHandleCount + 1;
         }	           
       
       } else if (curX === col12X && curY === row04X && c12R4VNo === 10) {
        if (c12r4MT === "S") {  
          setRow4Naked12(
                     [
                       <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
                     ]
                   )
                   setC12R4VNo(11);
                   noHandleCount = noHandleCount + 1;
        } else if (c12r4MT === "M") {  
           setRow3NakedM12(
                     [
                       <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                     ]
                   )
                   setC12R3VNo(11);
                   setC12R4VNo(11);
                   noHandleCount = noHandleCount + 1;
        } else if (c12r4MT === "L") {  
          setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )

          setC12R1VNo(11);
          setC12R2VNo(11);
          setC12R3VNo(11);
          setC12R4VNo(11);
          noHandleCount = noHandleCount + 1;
        }	  
              
       } else if (curX === col13X && curY === row04X && c13R4VNo === 10) {
     
        if (c13r4MT === "S") {  
          setRow4Naked13(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
                       ]
                     )
                     setC13R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c13r4MT === "M") {  
             setRow3NakedM13(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                       ]
                     )
                     setC13R3VNo(11);
                     setC13R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c13r4MT === "L") {  
            setBaseNakedL13(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
              ]
            )
  
            setC13R1VNo(11);
            setC13R2VNo(11);
            setC13R3VNo(11);
            setC13R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	  
          
       } else if (curX === col14X && curY === row04X && c14R4VNo === 10) {
        if (c14r4MT === "S") {  
          setRow4Naked14(
                       [
                         <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
                       ]
                     )
                     setC14R3VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c14r4MT === "M") {  
              setRow3NakedM14(
                       [
                         <Model10MUp  customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                       ]
                     )
                     setC14R3VNo(11);
                     setC14R4VNo(11);
                     noHandleCount = noHandleCount + 1;
          } else if (c14r4MT === "L") {  
            setBaseNakedL14(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
              ]
            )
  
            setC14R1VNo(11);
            setC14R2VNo(11);
            setC14R3VNo(11);
            setC14R4VNo(11);
            noHandleCount = noHandleCount + 1;
          }	            
        
      } else {
        alert("You can't remove handle at this stage");
      }
    }

   
  


  const showRemoveBox = (event) => {
    setRemoveBox(false);
    setAddBox(true);
  }

  const showAddBox = (event) => {
    setRemoveBox(true);
    setAddBox(false);
  }

  const showModules = (event) => {
    if (isWallFixed === true) {
      setModulesBox(!modulesBox);
      setDimentionBox(true);
      setCustomizeBox(true);
      setColorBox(true);
      setCheckoutBox(true);
      if (showHideModules ==="./images/arrow.png") {
        showHideModules ="./images/arrow-down.png";
      } else {
        showHideModules ="./images/arrow.png";
      }
      showHideCustomize = "./images/arrow.png";
      selectColorShowHide = "./images/arrow.png";
      checkoutshowHide = "./images/arrow.png";
      
    } else {
      alert("Please fix or confirm the room space and proceed.")
    }

  }

  const restAndFixRoomAgain = (event) => {
    if (window.confirm("This will re-set all your changes and let you specify Flo space again.") === true) {
      window.location.reload();
    }
  }

const hideDoors = (e)=> {
  
  if (curX === col01X && curY === row01X) {
    if (c1R1VNo === 9 || c1R1VNo === 10 || c1R1VNo === 11) {
      doorRemoved = true;
      if (c1R1VNo === 9) {
        doorTypec1r1 = 9;
      } else if (c1R1VNo === 10) {
        doorTypec1r1 = 10;
      } else {
        doorTypec1r1 = 11;
      }
      if (c1r1MT === "M" && c1r2MT === "M") {
        
        if (sepOnM1C1 === true) {
          setBaseNakedM01(
                              [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(8);
                            setC1R2VNo(8);
        } else {
          setBaseNakedM01(
                              [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                              ]
                            )
                            setC1R1VNo(8);
                            setC1R2VNo(8);
        }
      } else if (c1r1MT === "L") {
        
        if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);
        } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);


        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);

        } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);
        } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);
        } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
          setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                            ]
                          )
                          setC1R1VNo(8);
                          setC1R2VNo(8);
                          setC1R3VNo(8);
                          setC1R4VNo(8);
        }
      } else if (c1r1MT === "S") {
        doorRemoved = false;
        alert("Select Medium or Large Module and proceed.")
      } 

    } else {
      doorRemoved = false;
      alert("You cannot do this as no doors are associated with this module.");
    }

  } else if (curX === col02X && curY === row01X) {
    if (c2R1VNo === 9 || c2R1VNo === 10 || c2R1VNo === 11) {
      doorRemoved = true;
      if (c2R1VNo === 9) {
        doorTypec2r1 = 9;
      } else if (c2R1VNo === 10) {
        doorTypec2r1 = 10;
      } else {
        doorTypec2r1 = 11;
      }
      if (c2r1MT === "M" && c2r2MT === "M") {
       
        if (sepOnM1C2 === true) {
          setBaseNakedM02(
                              [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(8);
                            setC2R2VNo(8);
        } else {
          setBaseNakedM02(
                              [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                              ]
                            )
                            setC2R1VNo(8);
                            setC2R2VNo(8);
        }
      } else if (c2r1MT === "L") {
        
        if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);
        } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {setBaseNakedL01(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);


        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);

        } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);
        } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);
        } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
          setBaseNakedL02(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                            ]
                          )
                          setC2R1VNo(8);
                          setC2R2VNo(8);
                          setC2R3VNo(8);
                          setC2R4VNo(8);
        }
            } else if (c2r1MT === "S") {
              doorRemoved = false;
                alert("Select Medium or Large Module and proceed.")
            }
      } else {
        doorRemoved = false;
        alert("You cannot do this as no doors are associated with this module.");
      }
    } else if (curX === col03X && curY === row01X) {
      if (c3R1VNo === 9 || c3R1VNo === 10 || c3R1VNo === 11) {
        doorRemoved = true;
        if (c3R1VNo === 9) {
          doorTypec3r1 = 9;
        } else if (c3R1VNo === 10) {
          doorTypec3r1 = 10;
        } else {
          doorTypec3r1 = 11;
        }
        if (c3r1MT === "M" && c3r2MT === "M") {
         
          if (sepOnM1C3 === true) {
            setBaseNakedM03(
                                [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(8);
                              setC3R2VNo(8);
          } else {
            setBaseNakedM03(
                                [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                ]
                              )
                              setC3R1VNo(8);
                              setC3R2VNo(8);
          }
        } else if (c3r1MT === "L") {
        
          if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);
          } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);


          } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);

          } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);
          } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);
          } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);
          } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);
          } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
            setBaseNakedL03(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                              ]
                            )
                            setC3R1VNo(8);
                            setC3R2VNo(8);
                            setC3R3VNo(8);
                            setC3R4VNo(8);
          }
              } else if (c3r1MT === "S") {
                doorRemoved = false;
                  alert("Select Medium or Large Module and proceed.")
              }
        } else {
          doorRemoved = false;
          alert("You cannot do this as no doors are associated with this module.");
        }
      } else if (curX === col04X && curY === row01X) {
        if (c4R1VNo === 9 || c4R1VNo === 10 || c4R1VNo === 11) {
          doorRemoved = true;
          if (c4r1MT === "M" && c4r2MT === "M") {
            if (c4R1VNo === 9) {
              doorTypec4r1 = 9;
            } else if (c4R1VNo === 10) {
              doorTypec4r1 = 10;
            } else {
              doorTypec4r1 = 11;
            }
            if (sepOnM1C4 === true) {
              setBaseNakedM04(
                                  [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                                  ]
                                )
                                setC4R1VNo(8);
                                setC4R2VNo(8);
            } else {
              setBaseNakedM04(
                                  [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                                  ]
                                )
                                setC4R1VNo(8);
                                setC4R2VNo(8);
            }
          } else if (c4r1MT === "L") {
           
            if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
            } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
  
  
            } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
  
            } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
            } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
            } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
            } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
            } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
              setBaseNakedL04(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                ]
                              )
                              setC4R1VNo(8);
                              setC4R2VNo(8);
                              setC4R3VNo(8);
                              setC4R4VNo(8);
            }
                } else if (c4r1MT === "S") {
                  doorRemoved = false;
                    alert("Select Medium or Large Module and proceed.")
                }
          } else {
            doorRemoved = false;
            alert("You cannot do this as no doors are associated with this module.");
          }
        } else if (curX === col05X && curY === row01X) {
          if (c5R1VNo === 9 || c5R1VNo === 10 || c5R1VNo === 11) {
            doorRemoved = true;
            if (c5R1VNo === 9) {
              doorTypec5r1 = 9;
            } else if (c5R1VNo === 10) {
              doorTypec5r1 = 10;
            } else {
              doorTypec5r1 = 11;
            }
            if (c5r1MT === "M" && c5r2MT === "M") {
             
              if (sepOnM1C5 === true) {
                setBaseNakedM05(
                                    [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                                    ]
                                  )
                                  setC5R1VNo(8);
                                  setC5R2VNo(8);
              } else {
                setBaseNakedM05(
                                    [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                                    ]
                                  )
                                  setC5R1VNo(8);
                                  setC5R2VNo(8);
              }
            } else if (c5r1MT === "L") {
              
              if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
              } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
    
    
              } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
    
              } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
              } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
              } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
              } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
              } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                                setC5R1VNo(8);
                                setC5R2VNo(8);
                                setC5R3VNo(8);
                                setC5R4VNo(8);
              }
                  } else if (c5r1MT === "S") {
                    doorRemoved = false;
                      alert("Select Medium or Large Module and proceed.")
                  }
            } else {
              doorRemoved = false;
              alert("You cannot do this as no doors are associated with this module.");
            }
          } else if (curX === col06X && curY === row01X) {
            if (c6R1VNo === 9 || c6R1VNo === 10 || c6R1VNo === 11) {
              doorRemoved = true;
              if (c6R1VNo === 9) {
                doorTypec6r1 = 9;
              } else if (c6R1VNo === 10) {
                doorTypec6r1 = 10;
              } else {
                doorTypec6r1 = 11;
              }
              if (c6r1MT === "M" && c6r2MT === "M") {
                
                if (sepOnM1C6 === true) {
                  setBaseNakedM06(
                                      [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                                      ]
                                    )
                                    setC6R1VNo(8);
                                    setC6R2VNo(8);
                } else {
                  setBaseNakedM06(
                                      [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                      ]
                                    )
                                    setC6R1VNo(8);
                                    setC6R2VNo(8);
                }
              } else if (c6r1MT === "L") {
                
                if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
                  setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
                } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
      
      
                } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
                  setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
      
                } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
                  setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
                } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
                  setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
                } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
                  setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
                } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
                  setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
                } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
                  setBaseNakedL06(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                    ]
                                  )
                                  setC6R1VNo(8);
                                  setC6R2VNo(8);
                                  setC6R3VNo(8);
                                  setC6R4VNo(8);
                }
                    } else if (c6r1MT === "S") {
                      doorRemoved = false;
                        alert("Select Medium or Large Module and proceed.")
                    }
              } else {
                doorRemoved = false;
                alert("You cannot do this as no doors are associated with this module.");
              }
            } else if (curX === col07X && curY === row01X) {
              if (c7R1VNo === 9 || c7R1VNo === 10 || c7R1VNo === 11) {
                doorRemoved = true;
                if (c7R1VNo === 9) {
                  doorTypec7r1 = 9;
                } else if (c7R1VNo === 10) {
                  doorTypec7r1 = 10;
                } else {
                  doorTypec7r1 = 11;
                }
                if (c7r1MT === "M" && c7r2MT === "M") {
                 
                  if (sepOnM1C7 === true) {
                    setBaseNakedM07(
                                        [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                                        ]
                                      )
                                      setC7R1VNo(8);
                                      setC7R2VNo(8);
                  } else {
                    setBaseNakedM07(
                                        [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                        ]
                                      )
                                      setC7R1VNo(8);
                                      setC7R2VNo(8);
                  }
                } else if (c7r1MT === "L") {
                  
                  if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
                    setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
                  } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
        
        
                  } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
                    setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
        
                  } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
                    setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
                  } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
                    setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
                  } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
                    setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
                  } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
                    setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
                  } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
                    setBaseNakedL07(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                      ]
                                    )
                                    setC7R1VNo(8);
                                    setC7R2VNo(8);
                                    setC7R3VNo(8);
                                    setC7R4VNo(8);
                  }
                      } else if (c7r1MT === "S") {
                        doorRemoved = false;
                          alert("Select Medium or Large Module and proceed.")
                      }
                } else {
                  doorRemoved = false;
                  alert("You cannot do this as no doors are associated with this module.");
                }
              } else if (curX === col08X && curY === row01X) {
                if (c8R1VNo === 9 || c8R1VNo === 10 || c8R1VNo === 11) {
                  doorRemoved = true;
                  if (c8R1VNo === 9) {
                    doorTypec8r1 = 9;
                  } else if (c8R1VNo === 10) {
                    doorTypec8r1 = 10;
                  } else {
                    doorTypec8r1 = 11;
                  }
                  if (c8r1MT === "M" && c8r2MT === "M") {
                    
                    if (sepOnM1C8 === true) {
                      setBaseNakedM08(
                                          [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                                          ]
                                        )
                                        setC8R1VNo(8);
                                        setC8R2VNo(8);
                    } else {
                      setBaseNakedM08(
                                          [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                          ]
                                        )
                                        setC8R1VNo(8);
                                        setC8R2VNo(8);
                    }
                  } else if (c8r1MT === "L") {
                   
                    if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
                      setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
                    } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
          
          
                    } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
                      setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
          
                    } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
                      setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
                    } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
                      setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
                    } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
                      setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
                    } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
                      setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
                    } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
                      setBaseNakedL08(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                      setC8R1VNo(8);
                                      setC8R2VNo(8);
                                      setC8R3VNo(8);
                                      setC8R4VNo(8);
                    }
                        } else if (c8r1MT === "S") {
                          doorRemoved = false;
                            alert("Select Medium or Large Module and proceed.")
                        }
                  } else {
                    doorRemoved = false;
                    alert("You cannot do this as no doors are associated with this module.");
                  }
                } else if (curX === col09X && curY === row01X) {
                  if (c9R1VNo === 9 || c9R1VNo === 10 || c9R1VNo === 11) {
                    doorRemoved = true;
                    if (c9R1VNo === 9) {
                      doorTypec9r1 = 9;
                    } else if (c9R1VNo === 10) {
                      doorTypec9r1 = 10;
                    } else {
                      doorTypec9r1 = 11;
                    }
                    if (c9r1MT === "M" && c9r2MT === "M") {
                     
                      if (sepOnM1C9 === true) {
                        setBaseNakedM09(
                                            [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                                            ]
                                          )
                                          setC9R1VNo(8);
                                          setC9R2VNo(8);
                      } else {
                        setBaseNakedM09(
                                            [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                                            ]
                                          )
                                          setC9R1VNo(8);
                                          setC9R2VNo(8);
                      }
                    } else if (c9r1MT === "L") {
                      
                      if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
                        setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
                      } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
            
            
                      } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
                        setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
            
                      } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
                        setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
                      } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
                        setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
                      } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
                        setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
                      } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
                        setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
                      } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
                        setBaseNakedL09(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                          ]
                                        )
                                        setC9R1VNo(8);
                                        setC9R2VNo(8);
                                        setC9R3VNo(8);
                                        setC9R4VNo(8);
                      }
                          } else if (c9r1MT === "S") {
                            doorRemoved = false;
                              alert("Select Medium or Large Module and proceed.")
                          }
                    } else {
                      doorRemoved = false;
                      alert("You cannot do this as no doors are associated with this module.");
                    }
                  } else if (curX === col10X && curY === row01X) {
                    if (c10R1VNo === 9 || c10R1VNo === 10 || c10R1VNo === 11) {
                      doorRemoved = true;
                      if (c10R1VNo === 9) {
                        doorTypec10r1 = 9;
                      } else if (c10R1VNo === 10) {
                        doorTypec10r1 = 10;
                      } else {
                        doorTypec10r1 = 11;
                      }
                      if (c10r1MT === "M" && c10r2MT === "M") {
                       
                        if (sepOnM1C10 === true) {
                          setBaseNakedM10(
                                              [
                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                                              ]
                                            )
                                            setC10R1VNo(8);
                                            setC10R2VNo(8);
                        } else {
                          setBaseNakedM10(
                                              [
                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                              ]
                                            )
                                            setC10R1VNo(8);
                                            setC10R2VNo(8);
                        }
                      } else if (c10r1MT === "L") {
                       
                        if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
                          setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
                        } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
              
              
                        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
                          setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
              
                        } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
                          setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
                        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
                          setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
                        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
                          setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
                        } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
                          setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
                        } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
                          setBaseNakedL10(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                            ]
                                          )
                                          setC10R1VNo(8);
                                          setC10R2VNo(8);
                                          setC10R3VNo(8);
                                          setC10R4VNo(8);
                        }
                            } else if (c10r1MT === "S") {
                              doorRemoved = false;
                                alert("Select Medium or Large Module and proceed.")
                            }
                      } else {
                        doorRemoved = false;
                        alert("You cannot do this as no doors are associated with this module.");
                      }
                    } else if (curX === col11X && curY === row01X) {
                      if (c11R1VNo === 9 || c11R1VNo === 10 || c11R1VNo === 11) {
                        doorRemoved = true;
                        if (c11R1VNo === 9) {
                          doorTypec11r1 = 9;
                        } else if (c11R1VNo === 10) {
                          doorTypec11r1 = 10;
                        } else {
                          doorTypec11r1 = 11;
                        }
                        if (c11r1MT === "M" && c11r2MT === "M") {
                          
                          if (sepOnM1C11 === true) {
                            setBaseNakedM11(
                                                [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                                                ]
                                              )
                                              setC11R1VNo(8);
                                              setC11R2VNo(8);
                          } else {
                            setBaseNakedM11(
                                                [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                                ]
                                              )
                                              setC11R1VNo(8);
                                              setC11R2VNo(8);
                          }
                        } else if (c11r1MT === "L") {
                          
                          if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
                            setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                          } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                
                
                          } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
                            setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                
                          } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
                            setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                          } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
                            setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                          } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
                            setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                          } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
                            setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                          } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
                            setBaseNakedL11(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                              ]
                                            )
                                            setC11R1VNo(8);
                                            setC11R2VNo(8);
                                            setC11R3VNo(8);
                                            setC11R4VNo(8);
                          }
                              } else if (c11r1MT === "S") {
                                doorRemoved = false;
                                  alert("Select Medium or Large Module and proceed.")
                              }
                        } else {
                          doorRemoved = false;
                          alert("You cannot do this as no doors are associated with this module.");
                        }
                      } else if (curX === col12X && curY === row01X) {
                        if (c12R1VNo === 9 || c12R1VNo === 10 || c12R1VNo === 11) {
                          doorRemoved = true;
                          if (c12R1VNo === 9) {
                            doorTypec12r1 = 9;
                          } else if (c12R1VNo === 10) {
                            doorTypec12r1 = 10;
                          } else {
                            doorTypec12r1 = 11;
                          }
                          if (c12r1MT === "M" && c12r2MT === "M") {
                           
                            if (sepOnM1C12 === true) {
                              setBaseNakedM12(
                                                  [
                                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC12R1VNo(8);
                                                setC12R2VNo(8);
                            } else {
                              setBaseNakedM12(
                                                  [
                                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC12R1VNo(8);
                                                setC12R2VNo(8);
                            }
                          } else if (c12r1MT === "L") {
                           
                            if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
                              setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                            } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                  
                  
                            } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
                              setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                  
                            } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
                              setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                            } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
                              setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                            } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
                              setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                            } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
                              setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                            } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
                              setBaseNakedL12(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                ]
                                              )
                                              setC12R1VNo(8);
                                              setC12R2VNo(8);
                                              setC12R3VNo(8);
                                              setC12R4VNo(8);
                            }
                                } else if (c12r1MT === "S") {
                                  doorRemoved = false;
                                    alert("Select Medium or Large Module and proceed.")
                                }
                          } else {
                            doorRemoved = false;
                            alert("You cannot do this as no doors are associated with this module.");
                          }
                        } else if (curX === col13X && curY === row01X) {
                          if (c13R1VNo === 9 || c13R1VNo === 10 || c13R1VNo === 11) {
                            doorRemoved = true;
                            if (c13R1VNo === 9) {
                              doorTypec13r1 = 9;
                            } else if (c13R1VNo === 10) {
                              doorTypec13r1 = 10;
                            } else {
                              doorTypec13r1 = 11;
                            }
                            if (c13r1MT === "M" && c13r2MT === "M") {
                              
                              if (sepOnM1C13 === true) {
                                setBaseNakedM13(
                                                    [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC13R1VNo(8);
                                                  setC13R2VNo(8);
                              } else {
                                setBaseNakedM13(
                                                    [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC13R1VNo(8);
                                                  setC13R2VNo(8);
                              }
                            } else if (c13r1MT === "L") {
                              
                              if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
                                setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                              } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                    
                    
                              } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
                                setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                    
                              } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
                                setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                              } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
                                setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                              } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
                                setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                              } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
                                setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                              } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
                                setBaseNakedL13(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC13R1VNo(8);
                                                setC13R2VNo(8);
                                                setC13R3VNo(8);
                                                setC13R4VNo(8);
                              }
                                  } else if (c13r1MT === "S") {
                                    doorRemoved = false;
                                      alert("Select Medium or Large Module and proceed.")
                                  }
                            } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                          } else if (curX === col14X && curY === row01X) {
                            if (c14R1VNo === 9 || c14R1VNo === 10 || c14R1VNo === 11) {
                              doorRemoved = true;
                              if (c14R1VNo === 9) {
                                doorTypec14r1 = 9;
                              } else if (c14R1VNo === 10) {
                                doorTypec14r1 = 10;
                              } else {
                                doorTypec14r1 = 11;
                              }
                              if (c14r1MT === "M" && c14r2MT === "M") {
                               
                                if (sepOnM1C14 === true) {
                                  setBaseNakedM14(
                                                      [
                                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                } else {
                                  setBaseNakedM14(
                                                      [
                                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                }
                              } else if (c14r1MT === "L") {
                                
                                if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
                                  setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                                } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                      
                      
                                } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
                                  setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                      
                                } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
                                  setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                                } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
                                  setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                                } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
                                  setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                                } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
                                  setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                                } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
                                  setBaseNakedL14(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC14R1VNo(8);
                                                  setC14R2VNo(8);
                                                  setC14R3VNo(8);
                                                  setC14R4VNo(8);
                                }
                                    } else if (c14r1MT === "S") {
                                      doorRemoved = false;
                                        alert("Select Medium or Large Module and proceed.")
                                    }
                              } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
            } else if (curX === col01X && curY === row02X) {
              if (c1R2VNo === 9 || c1R2VNo === 10 || c1R2VNo === 11) {
                doorRemoved = true;
                if (c1R2VNo === 9) {
                  doorTypec1r2 = 9;
                } else if (c1R2VNo === 10) {
                  doorTypec1r2 = 10;
                } else {
                  doorTypec1r2 = 11;
                }
                if (c1r1MT === "M" && c1r2MT === "M") {
                    
                  if (sepOnM1C1 === true) {
                    setBaseNakedM01(
                                        [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
                                        ]
                                      )
                                      setC1R1VNo(8);
                                      setC1R2VNo(8);
                  } else {
                    setBaseNakedM01(
                                        [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                                        ]
                                      )
                                      setC1R1VNo(8);
                                      setC1R2VNo(8);
                  }
           } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                  if (sepOnM1C1 === true) {
                    setRow2NakedM01(
                                        [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                                        ]
                                      )
                                      setC1R2VNo(8);
                                      setC1R3VNo(8);
                  } else {
                    setRow2NakedM01(
                                        [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                        ]
                                      )
                                      setC1R2VNo(8);
                                      setC1R3VNo(8);
                  }
                } else if (c1r2MT === "L") {
                 
                  if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
                  } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
          
          
                  } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
          
                  } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
                  } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
                  } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
                  } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
                  } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
                    setBaseNakedL01(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                      ]
                                    )
                                    setC1R1VNo(8);
                                    setC1R2VNo(8);
                                    setC1R3VNo(8);
                                    setC1R4VNo(8);
                  }
                } else if (c1r2MT === "S") {
                  doorRemoved = false;
                    alert("Select Medium or Large Module and proceed.")
                }
              } else {
                doorRemoved = false;
                alert("You cannot do this as no doors are associated with this module.");
              }
            }  else if (curX === col02X && curY === row02X) {
                if (c2R2VNo === 9 || c2R2VNo === 10 || c2R2VNo === 11) {
                  doorRemoved = true;
                  if (c2R2VNo === 9) {
                    doorTypec2r2 = 9;
                  } else if (c2R2VNo === 10) {
                    doorTypec2r2 = 10;
                  } else {
                    doorTypec2r2 = 11;
                  }
                  if (c2r1MT === "M" && c2r2MT === "M") {
                   
                    if (sepOnM1C2 === true) {
                      setBaseNakedM02(
                                          [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                                          ]
                                        )
                                        setC2R1VNo(8);
                                        setC2R2VNo(8);
                    } else {
                      setBaseNakedM02(
                                          [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                                          ]
                                        )
                                        setC2R1VNo(8);
                                        setC2R2VNo(8);
                    }
            } else  if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                    if (sepOnM1C2 === true) {
                      setRow2NakedM02(
                                          [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                                          ]
                                        )
                                        setC2R2VNo(8);
                                        setC2R3VNo(8);
                    } else {
                      setRow2NakedM02(
                                          [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                          ]
                                        )
                                        setC2R2VNo(8);
                                        setC2R3VNo(8);
                    }
                  } else if (c2r2MT === "L") {
                    
                    if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
                    } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {setBaseNakedL01(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
          
          
                    } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
          
                    } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
                    } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
                    } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
                    } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
                    } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
                      setBaseNakedL02(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                        ]
                                      )
                                      setC2R1VNo(8);
                                      setC2R2VNo(8);
                                      setC2R3VNo(8);
                                      setC2R4VNo(8);
                    }
                        } else if (c2r2MT === "S") {
                          doorRemoved = false;
                            alert("Select Medium or Large Module and proceed.")
                        }
                  } else {
                    doorRemoved = false;
                    alert("You cannot do this as no doors are associated with this module.");
                  }
                } else if (curX === col03X && curY === row02X) {
                  if (c3R2VNo === 9 || c3R2VNo === 10 || c3R2VNo === 11) {
                    doorRemoved = true;
                    if (c3R2VNo === 9) {
                      doorTypec3r2 = 9;
                    } else if (c3R2VNo === 10) {
                      doorTypec3r2 = 10;
                    } else {
                      doorTypec3r2 = 11;
                    }
                    if (c3r1MT === "M" && c3r2MT === "M") {
                     
                      if (sepOnM1C3 === true) {
                        setBaseNakedM03(
                                            [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                                            ]
                                          )
                                          setC3R1VNo(8);
                                          setC3R2VNo(8);
                      } else {
                        setBaseNakedM03(
                                            [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                                            ]
                                          )
                                          setC3R1VNo(8);
                                          setC3R2VNo(8);
                      }
            } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                      if (sepOnM1C3 === true) {
                        setRow2NakedM03(
                                            [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                                            ]
                                          )
                                          setC3R2VNo(8);
                                          setC3R3VNo(8);
                      } else {
                        setRow2NakedM03(
                                            [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                            ]
                                          )
                                          setC3R2VNo(8);
                                          setC3R3VNo(8);
                      }
                    } else if (c3r2MT === "L") {
                      
                      if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                      } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
            
            
                      } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
            
                      } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                      } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                      } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                      } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                      } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
                        setBaseNakedL03(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                          ]
                                        )
                                        setC3R1VNo(8);
                                        setC3R2VNo(8);
                                        setC3R3VNo(8);
                                        setC3R4VNo(8);
                      }
                          } else if (c3r2MT === "S") {
                            doorRemoved = false;
                              alert("Select Medium or Large Module and proceed.")
                          }
                    } else {
                      doorRemoved = false;
                      alert("You cannot do this as no doors are associated with this module.");
                    }
                  } else if (curX === col04X && curY === row02X) {
                    if (c4R2VNo === 9 || c4R2VNo === 10 || c4R2VNo === 11) {
                      doorRemoved = true;
                      if (c4R2VNo === 9) {
                        doorTypec4r2 = 9;
                      } else if (c4R2VNo === 10) {
                        doorTypec4r2 = 10;
                      } else {
                        doorTypec4r2 = 11;
                      }
                      if (c4r1MT === "M" && c4r2MT === "M") {
                        
                        if (sepOnM1C4 === true) {
                          setBaseNakedM04(
                                              [
                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                                              ]
                                            )
                                            setC4R1VNo(8);
                                            setC4R2VNo(8);
                        } else {
                          setBaseNakedM04(
                                              [
                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                                              ]
                                            )
                                            setC4R1VNo(8);
                                            setC4R2VNo(8);
                        }
            } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                        if (sepOnM1C4 === true) {
                          setRow2NakedM04(
                                              [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                                              ]
                                            )
                                            setC4R2VNo(8);
                                            setC4R3VNo(8);
                        } else {
                          setRow2NakedM04(
                                              [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                              ]
                                            )
                                            setC4R2VNo(8);
                                            setC4R3VNo(8);
                        }
                      } else if (c4r2MT === "L") {
                       
                        if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
                        } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
              
              
                        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
              
                        } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
                        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
                        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
                        } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
                        } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
                          setBaseNakedL04(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                            ]
                                          )
                                          setC4R1VNo(8);
                                          setC4R2VNo(8);
                                          setC4R3VNo(8);
                                          setC4R4VNo(8);
                        }
                            } else if (c4r2MT === "S") {
                              doorRemoved = false;
                                alert("Select Medium or Large Module and proceed.")
                            }
                      } else {
                        doorRemoved = false;
                        alert("You cannot do this as no doors are associated with this module.");
                      }
                    } else if (curX === col05X && curY === row02X) {
                      if (c5R2VNo === 9 || c5R2VNo === 10 || c5R2VNo === 11) {
                        doorRemoved = true;
                        if (c5R2VNo === 9) {
                          doorTypec5r2 = 9;
                        } else if (c5R2VNo === 10) {
                          doorTypec5r2 = 10;
                        } else {
                          doorTypec5r2 = 11;
                        }
                        if (c5r1MT === "M" && c5r2MT === "M") {
                          
                          if (sepOnM1C5 === true) {
                            setBaseNakedM05(
                                                [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                                                ]
                                              )
                                              setC5R1VNo(8);
                                              setC5R2VNo(8);
                          } else {
                            setBaseNakedM05(
                                                [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                                                ]
                                              )
                                              setC5R1VNo(8);
                                              setC5R2VNo(8);
                          }
            } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                          if (sepOnM1C5 === true) {
                            setRow2NakedM05(
                                                [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                                                ]
                                              )
                                              setC5R2VNo(8);
                                              setC5R3VNo(8);
                          } else {
                            setRow2NakedM05(
                                                [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                                                ]
                                              )
                                              setC5R2VNo(8);
                                              setC5R3VNo(8);
                          }
                        } else if (c5r2MT === "L") {
                          
                          if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                          } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                
                
                          } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                
                          } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                          } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                          } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                          } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                          } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
                            setBaseNakedL05(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                              ]
                                            )
                                            setC5R1VNo(8);
                                            setC5R2VNo(8);
                                            setC5R3VNo(8);
                                            setC5R4VNo(8);
                          }
                              } else if (c5r2MT === "S") {
                                doorRemoved = false;
                                  alert("Select Medium or Large Module and proceed.")
                              }
                        } else {
                          doorRemoved = false;
                          alert("You cannot do this as no doors are associated with this module.");
                        }
                      } else if (curX === col06X && curY === row02X) {
                        if (c6R2VNo === 9 || c6R2VNo === 10 || c6R2VNo === 11) {
                          doorRemoved = true;
                          if (c6R2VNo === 9) {
                            doorTypec6r2 = 9;
                          } else if (c6R2VNo === 10) {
                            doorTypec6r2 = 10;
                          } else {
                            doorTypec6r2 = 11;
                          }
                          if (c6r1MT === "M" && c6r2MT === "M") {
                           
                            if (sepOnM1C6 === true) {
                              setBaseNakedM06(
                                                  [
                                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC6R1VNo(8);
                                                setC6R2VNo(8);
                            } else {
                              setBaseNakedM06(
                                                  [
                                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC6R1VNo(8);
                                                setC6R2VNo(8);
                            }
            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                            if (sepOnM1C6 === true) {
                              setRow2NakedM06(
                                                  [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                                                  ]
                                                )
                                                setC6R2VNo(8);
                                                setC6R3VNo(8);
                            } else {
                              setRow2NakedM06(
                                                  [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                                  ]
                                                )
                                                setC6R2VNo(8);
                                                setC6R3VNo(8);
                            }
                          } else if (c6r2MT === "L") {
                            
                            if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                            } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                  
                  
                            } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                  
                            } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                            } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                            } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                            } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                            } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
                              setBaseNakedL06(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                ]
                                              )
                                              setC6R1VNo(8);
                                              setC6R2VNo(8);
                                              setC6R3VNo(8);
                                              setC6R4VNo(8);
                            }
                                } else if (c6r2MT === "S") {
                                  doorRemoved = false;
                                    alert("Select Medium or Large Module and proceed.")
                                }
                          } else {
                            doorRemoved = false;
                            alert("You cannot do this as no doors are associated with this module.");
                          }
                        } else if (curX === col07X && curY === row02X) {
                          if (c7R2VNo === 9 || c7R2VNo === 10 || c7R2VNo === 11) {
                            doorRemoved = true;
                            if (c7R2VNo === 9) {
                              doorTypec7r2 = 9;
                            } else if (c7R2VNo === 10) {
                              doorTypec7r2 = 10;
                            } else {
                              doorTypec7r2 = 11;
                            }
                            if (c7r1MT === "M" && c7r2MT === "M") {
                              
                              if (sepOnM1C7 === true) {
                                setBaseNakedM07(
                                                    [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                              } else {
                                setBaseNakedM07(
                                                    [
                                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                              }
              } else  if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                              if (sepOnM1C7 === true) {
                                setRow2NakedM07(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                              } else {
                                setRow2NakedM07(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                              }
                            } else if (c7r2MT === "L") {
                             
                              if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                              } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                    
                    
                              } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                    
                              } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                              } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                              } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                              } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                              } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
                                setBaseNakedL07(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC7R1VNo(8);
                                                setC7R2VNo(8);
                                                setC7R3VNo(8);
                                                setC7R4VNo(8);
                              }
                                  } else if (c7r2MT === "S") {
                                    doorRemoved = false;
                                      alert("Select Medium or Large Module and proceed.")
                                  }
                            } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                          } else if (curX === col08X && curY === row02X) {
                            if (c8R2VNo === 9 || c8R2VNo === 10 || c8R2VNo === 11) {
                              doorRemoved = true;
                              if (c8R2VNo === 9) {
                                doorTypec8r2 = 9;
                              } else if (c8R2VNo === 10) {
                                doorTypec8r2 = 10;
                              } else {
                                doorTypec8r2 = 11;
                              }
                              if (c8r1MT === "M" && c8r2MT === "M") {
                               
                                if (sepOnM1C8 === true) {
                                  setBaseNakedM08(
                                                      [
                                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R1VNo(8);
                                                    setC8R2VNo(8);
                                } else {
                                  setBaseNakedM08(
                                                      [
                                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R1VNo(8);
                                                    setC8R2VNo(8);
                                }
              } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                if (sepOnM1C8 === true) {
                                  setRow2NakedM08(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R2VNo(8);
                                                    setC8R3VNo(8);
                                } else {
                                  setRow2NakedM08(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R2VNo(8);
                                                    setC8R3VNo(8);
                                }
                              } else if (c8r2MT === "L") {
                                
                                if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                      
                      
                                } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                      
                                } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                }
                                    } else if (c8r2MT === "S") {
                                      doorRemoved = false;
                                        alert("Select Medium or Large Module and proceed.")
                                    }
                              } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
                            } else if (curX === col09X && curY === row02X) {
                              if (c9R2VNo === 9 || c9R2VNo === 10 || c9R2VNo === 11) {
                                doorRemoved = true;
                                if (c9R2VNo === 9) {
                                  doorTypec9r2 = 9;
                                } else if (c9R2VNo === 10) {
                                  doorTypec9r2 = 10;
                                } else {
                                  doorTypec9r2 = 11;
                                }
                                if (c9r1MT === "M" && c9r2MT === "M") {
                                 
                                  if (sepOnM1C9 === true) {
                                    setBaseNakedM09(
                                                        [
                                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC9R1VNo(8);
                                                      setC9R2VNo(8);
                                  } else {
                                    setBaseNakedM09(
                                                        [
                                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC9R1VNo(8);
                                                      setC9R2VNo(8);
                                  }
              } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                                  if (sepOnM1C9 === true) {
                                    setRow2NakedM09(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                                                        ]
                                                      )
                                                      setC9R2VNo(8);
                                                      setC9R3VNo(8);
                                  } else {
                                    setRow2NakedM09(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                                        ]
                                                      )
                                                      setC9R2VNo(8);
                                                      setC9R3VNo(8);
                                  }
                                } else if (c9r2MT === "L") {
                                 
                                  if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                  } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                        
                        
                                  } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                        
                                  } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                  } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                  } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                  } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                  } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
                                    setBaseNakedL09(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC9R1VNo(8);
                                                    setC9R2VNo(8);
                                                    setC9R3VNo(8);
                                                    setC9R4VNo(8);
                                  }
                                      } else if (c9r2MT === "S") {
                                        doorRemoved = false;
                                          alert("Select Medium or Large Module and proceed.")
                                      }
                                } else {
                                  doorRemoved = false;
                                  alert("You cannot do this as no doors are associated with this module.");
                                }
                              } else if (curX === col10X && curY === row02X) {
                                if (c10R2VNo === 9 || c10R2VNo === 10 || c10R2VNo === 11) {
                                  doorRemoved = true;
                                  if (c10R2VNo === 9) {
                                    doorTypec10r2 = 9;
                                  } else if (c10R2VNo === 10) {
                                    doorTypec10r2 = 10;
                                  } else {
                                    doorTypec10r2 = 11;
                                  }
                                  if (c10r1MT === "M" && c10r2MT === "M") {
                                   
                                    if (sepOnM1C10 === true) {
                                      setBaseNakedM10(
                                                          [
                                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC10R1VNo(8);
                                                        setC10R2VNo(8);
                                    } else {
                                      setBaseNakedM10(
                                                          [
                                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC10R1VNo(8);
                                                        setC10R2VNo(8);
                                    }
              } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                                    if (sepOnM1C10 === true) {
                                      setRow2NakedM10(
                                                          [
                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                                                          ]
                                                        )
                                                        setC10R2VNo(8);
                                                        setC10R3VNo(8);
                                    } else {
                                      setRow2NakedM10(
                                                          [
                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                                          ]
                                                        )
                                                        setC10R2VNo(8);
                                                        setC10R3VNo(8);
                                    }
                                  } else if (c10r2MT === "L") {
                                    
                                    if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                                    } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                          
                          
                                    } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                          
                                    } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                                    } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                                    } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                                    } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                                    } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
                                      setBaseNakedL10(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC10R1VNo(8);
                                                      setC10R2VNo(8);
                                                      setC10R3VNo(8);
                                                      setC10R4VNo(8);
                                    }
                                        } else if (c10r2MT === "S") {
                                          doorRemoved = false;
                                            alert("Select Medium or Large Module and proceed.")
                                        }
                                  } else {
                                    doorRemoved = false;
                                    alert("You cannot do this as no doors are associated with this module.");
                                  }
                                } else if (curX === col11X && curY === row02X) {
                                  if (c11R2VNo === 9 || c11R2VNo === 10 || c11R2VNo === 11) {
                                    doorRemoved = true;
                                    if (c11R2VNo === 9) {
                                      doorTypec11r2 = 9;
                                    } else if (c11R2VNo === 10) {
                                      doorTypec11r2 = 10;
                                    } else {
                                      doorTypec11r2 = 11;
                                    }
                                    if (c11r1MT === "M" && c11r2MT === "M") {
                                     
                                      if (sepOnM1C11 === true) {
                                        setBaseNakedM11(
                                                            [
                                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC11R1VNo(8);
                                                          setC11R2VNo(8);
                                      } else {
                                        setBaseNakedM11(
                                                            [
                                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC11R1VNo(8);
                                                          setC11R2VNo(8);
                                      }
              } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                                      if (sepOnM1C11 === true) {
                                        setRow2NakedM11(
                                                            [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                                                            ]
                                                          )
                                                          setC11R2VNo(8);
                                                          setC11R3VNo(8);
                                      } else {
                                        setRow2NakedM11(
                                                            [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                                            ]
                                                          )
                                                          setC11R2VNo(8);
                                                          setC11R3VNo(8);
                                      }
                                    } else if (c11r2MT === "L") {
                                      
                                      if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                                      } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                            
                            
                                      } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                            
                                      } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                                      } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                                      } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                                      } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                                      } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
                                        setBaseNakedL11(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC11R1VNo(8);
                                                        setC11R2VNo(8);
                                                        setC11R3VNo(8);
                                                        setC11R4VNo(8);
                                      }
                                          } else if (c11r2MT === "S") {
                                            doorRemoved = false;
                                              alert("Select Medium or Large Module and proceed.")
                                          }
                                    } else {
                                      doorRemoved = false;
                                      alert("You cannot do this as no doors are associated with this module.");
                                    }
                                  } else if (curX === col12X && curY === row02X) {
                                    if (c12R2VNo === 9 || c12R2VNo === 10 || c12R2VNo === 11) {
                                      doorRemoved = true;
                                      if (c12R2VNo === 9) {
                                        doorTypec12r2 = 9;
                                      } else if (c12R2VNo === 10) {
                                        doorTypec12r2 = 10;
                                      } else {
                                        doorTypec12r2 = 11;
                                      }
                                      if (c12r1MT === "M" && c12r2MT === "M") {
                                        
                                        if (sepOnM1C12 === true) {
                                          setBaseNakedM12(
                                                              [
                                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC12R1VNo(8);
                                                            setC12R2VNo(8);
                                        } else {
                                          setBaseNakedM12(
                                                              [
                                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC12R1VNo(8);
                                                            setC12R2VNo(8);
                                        }
                } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                        if (sepOnM1C12 === true) {
                                          setRow2NakedM12(
                                                              [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                                                              ]
                                                            )
                                                            setC12R2VNo(8);
                                                            setC12R3VNo(8);
                                        } else {
                                          setRow2NakedM12(
                                                              [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                                              ]
                                                            )
                                                            setC12R2VNo(8);
                                                            setC12R3VNo(8);
                                        }
                                      } else if (c12r2MT === "L") {
                                        
                                        if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                                        } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                              
                              
                                        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                              
                                        } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                                        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                                        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                                        } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                                        } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
                                          setBaseNakedL12(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC12R1VNo(8);
                                                          setC12R2VNo(8);
                                                          setC12R3VNo(8);
                                                          setC12R4VNo(8);
                                        }
                                            } else if (c12r2MT === "S") {
                                              doorRemoved = false;
                                                alert("Select Medium or Large Module and proceed.")
                                            }
                                      } else {
                                        doorRemoved = false;
                                        alert("You cannot do this as no doors are associated with this module.");
                                      }
                                    } else if (curX === col13X && curY === row02X) {
                                      if (c13R2VNo === 9 || c13R2VNo === 10 || c13R2VNo === 11) {
                                        doorRemoved = true;
                                        if (c13R2VNo === 9) {
                                          doorTypec13r2 = 9;
                                        } else if (c13R2VNo === 10) {
                                          doorTypec13r2 = 10;
                                        } else {
                                          doorTypec13r2 = 11;
                                        }
                                        if (c13r1MT === "M" && c13r2MT === "M") {
                                         
                                          if (sepOnM1C13 === true) {
                                            setBaseNakedM13(
                                                                [
                                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC13R1VNo(8);
                                                              setC13R2VNo(8);
                                          } else {
                                            setBaseNakedM13(
                                                                [
                                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC13R1VNo(8);
                                                              setC13R2VNo(8);
                                          }
                } else  if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                          if (sepOnM1C13 === true) {
                                            setRow2NakedM13(
                                                                [
                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                                                                ]
                                                              )
                                                              setC13R2VNo(8);
                                                              setC13R3VNo(8);
                                          } else {
                                            setRow2NakedM13(
                                                                [
                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                                                ]
                                                              )
                                                              setC13R2VNo(8);
                                                              setC13R3VNo(8);
                                          }
                                        } else if (c13r2MT === "L") {
                                          
                                          if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                          } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                
                                
                                          } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                
                                          } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                          } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                          } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                          } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                          } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
                                            setBaseNakedL13(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC13R1VNo(8);
                                                            setC13R2VNo(8);
                                                            setC13R3VNo(8);
                                                            setC13R4VNo(8);
                                          }
                                              } else if (c13r2MT === "S") {
                                                doorRemoved = false;
                                                  alert("Select Medium or Large Module and proceed.")
                                              }
                                        } else {
                                          doorRemoved = false;
                                          alert("You cannot do this as no doors are associated with this module.");
                                        }
                                      } else if (curX === col14X && curY === row02X) {
                                        if (c14R2VNo === 9 || c14R2VNo === 10 || c14R2VNo === 11) {
                                          doorRemoved = true;
                                          if (c14R2VNo === 9) {
                                            doorTypec14r2 = 9;
                                          } else if (c14R2VNo === 10) {
                                            doorTypec14r2 = 10;
                                          } else {
                                            doorTypec14r2 = 11;
                                          }
                                          if (c14r1MT === "M" && c14r2MT === "M") {
                                            
                                            if (sepOnM1C14 === true) {
                                              setBaseNakedM14(
                                                                  [
                                                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC14R1VNo(8);
                                                                setC14R2VNo(8);
                                            } else {
                                              setBaseNakedM14(
                                                                  [
                                                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC14R1VNo(8);
                                                                setC14R2VNo(8);
                                            }
                  } else  if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                            if (sepOnM1C14 === true) {
                                              setRow2NakedM14(
                                                                  [
                                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                                                                  ]
                                                                )
                                                                setC14R2VNo(8);
                                                                setC14R3VNo(8);
                                            } else {
                                              setRow2NakedM14(
                                                                  [
                                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                                  ]
                                                                )
                                                                setC14R2VNo(8);
                                                                setC14R3VNo(8);
                                            }
                                          } else if (c14r2MT === "L") {
                                            
                                            if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                            } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                  
                                  
                                            } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                  
                                            } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                            } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                            } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                            } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                            } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
                                              setBaseNakedL14(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC14R1VNo(8);
                                                              setC14R2VNo(8);
                                                              setC14R3VNo(8);
                                                              setC14R4VNo(8);
                                            }
                                                } else if (c14r2MT === "S") {
                                                  doorRemoved = false;
                                                    alert("Select Medium or Large Module and proceed.")
                                                }
                                          } else {
                                            doorRemoved = false;
                                            alert("You cannot do this as no doors are associated with this module.");
                                          }
                        }  else if (curX === col01X && curY === row03X) {
                          if (c1R3VNo === 9 || c1R3VNo === 10 || c1R3VNo === 11) {
                            doorRemoved = true;
                            if (c1R3VNo === 9) {
                              doorTypec1r3 = 9;
                            } else if (c1R3VNo === 10) {
                              doorTypec1r3 = 10;
                            } else {
                              doorTypec1r3 = 11;
                            }
                            if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {

                                  if (sepOnM1C1 === true) {
                                    setRow2NakedM01(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
                                                        ]
                                                      )
                                                      setC1R2VNo(8);
                                                      setC1R3VNo(8);
                                  } else {
                                    setRow2NakedM01(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                                        ]
                                                      )
                                                      setC1R2VNo(8);
                                                      setC1R3VNo(8);
                                  }
                            } else if (c1r3MT === "M" && c1r4MT === "M") {
                                  if (sepOnM1C1 === true) {
                                    setRow3NakedM01(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC1R3VNo(8);
                                                      setC1R4VNo(8);
                                  } else {
                                    setRow3NakedM01(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC1R3VNo(8);
                                                      setC1R4VNo(8);
                                  }
                                } else if (c1r3MT === "L") {
                                  if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
                                    setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                  } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                          
                          
                                  } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
                                    setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                          
                                  } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
                                    setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                  } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
                                    setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                  } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
                                    setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                  } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
                                    setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                  } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
                                    setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R1VNo(8);
                                                    setC1R2VNo(8);
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                  }
                                } else if (c1r3MT === "S") {
                                  doorRemoved = false;
                                    alert("Select Medium or Large Module and proceed.")
                                }
                              } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
                        }  else if (curX === col02X && curY === row03X) {
                          if (c2R3VNo === 9 || c2R3VNo === 10 || c2R3VNo === 11) {
                            doorRemoved = true;
                            if (c2R3VNo === 9) {
                              doorTypec2r3 = 9;
                            } else if (c2R3VNo === 10) {
                              doorTypec2r3 = 10;
                            } else {
                              doorTypec2r3 = 11;
                            }
                            if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                             if (sepOnM1C2 === true) {
                               setRow2NakedM02(
                                                   [
                                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                                                   ]
                                                 )
                                                 setC2R2VNo(8);
                                                 setC2R3VNo(8);
                             } else {
                               setRow2NakedM02(
                                                   [
                                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                                   ]
                                                 )
                                                 setC2R2VNo(8);
                                                 setC2R3VNo(8);
                             }
                     } else if (c2r3MT === "M" && c2r4MT === "M") {
                             if (sepOnM1C2 === true) {
                               setRow3NakedM02(
                                                   [
                                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                                                   ]
                                                 )
                                                 setC2R3VNo(8);
                                                 setC2R4VNo(8);
                             } else {
                               setRow3NakedM02(
                                                   [
                                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                                   ]
                                                 )
                                                 setC2R3VNo(8);
                                                 setC2R4VNo(8);
                             }
                           } else if (c2r3MT === "L") {
                             if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
                               setBaseNakedL02(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                             } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {setBaseNakedL01(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                   
                   
                             } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
                               setBaseNakedL02(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                   
                             } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
                               setBaseNakedL02(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                             } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
                               setBaseNakedL02(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                             } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
                               setBaseNakedL02(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                             } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
                               setBaseNakedL02(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                             } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
                               setBaseNakedL02(
                                                 [
                                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                 ]
                                               )
                                               setC2R1VNo(8);
                                               setC2R2VNo(8);
                                               setC2R3VNo(8);
                                               setC2R4VNo(8);
                             }
                                 } else if (c2r3MT === "S") {
                                  doorRemoved = false;
                                     alert("Select Medium or Large Module and proceed.")
                                 }
                           } else {
                            doorRemoved = false;
                            alert("You cannot do this as no doors are associated with this module.");
                          }
                        } else if (curX === col03X && curY === row03X) {
                          if (c3R3VNo === 9 || c3R3VNo === 10 || c3R3VNo === 11) {
                            doorRemoved = true;
                            if (c3R3VNo === 9) {
                              doorTypec3r3 = 9;
                            } else if (c3R3VNo === 10) {
                              doorTypec3r3 = 10;
                            } else {
                              doorTypec3r3 = 11;
                            }
                            if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                               if (sepOnM1C3 === true) {
                                 setRow2NakedM03(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC2R2VNo(8);
                                                   setC3R3VNo(8);
                               } else {
                                 setRow2NakedM03(
                                                     [
                                                         <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC3R2VNo(8);
                                                   setC3R3VNo(8);
                               }
                     } else if (c3r3MT === "M" && c3r4MT === "M") {
                               if (sepOnM1C3 === true) {
                                 setRow3NakedM03(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC3R3VNo(8);
                                                   setC3R4VNo(8);
                               } else {
                                 setRow3NakedM03(
                                                     [
                                                         <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC3R3VNo(8);
                                                   setC3R4VNo(8);
                               }
                             } else if (c3r3MT === "L") {
                               if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
                                 setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                               } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                     
                     
                               } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
                                 setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                     
                               } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
                                 setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                               } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
                                 setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                               } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
                                 setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                               } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
                                 setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                               } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
                                 setBaseNakedL03(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC3R1VNo(8);
                                                 setC3R2VNo(8);
                                                 setC3R3VNo(8);
                                                 setC3R4VNo(8);
                               }
                                   } else if (c3r3MT === "S") {
                                    doorRemoved = false;
                                       alert("Select Medium or Large Module and proceed.")
                                   }
                             } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                        } else if (curX === col04X && curY === row03X) {
                          if (c4R3VNo === 9 || c4R3VNo === 10 || c4R3VNo === 11) {
                            doorRemoved = true;
                            if (c4R3VNo === 9) {
                              doorTypec4r3 = 9;
                            } else if (c4R3VNo === 10) {
                              doorTypec4r3 = 10;
                            } else {
                              doorTypec4r3 = 11;
                            }
                            if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                               if (sepOnM1C4 === true) {
                                 setRow2NakedM04(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC4R2VNo(8);
                                                   setC4R3VNo(8);
                               } else {
                                 setRow2NakedM04(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC4R2VNo(8);
                                                   setC4R3VNo(8);
                               }
                   } else  if (c4r3MT === "M" && c4r4MT === "M" ) {
                               if (sepOnM1C4 === true) {
                                 setRow3NakedM04(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC4R3VNo(8);
                                                   setC4R4VNo(8);
                               } else {
                                 setRow3NakedM04(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC4R3VNo(8);
                                                   setC4R4VNo(8);
                               }
                             } else if (c4r3MT === "L") {
                               if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
                                 setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                               } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                     
                     
                               } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
                                 setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                     
                               } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
                                 setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                               } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
                                 setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                               } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
                                 setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                               } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
                                 setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                               } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
                                 setBaseNakedL04(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC4R1VNo(8);
                                                 setC4R2VNo(8);
                                                 setC4R3VNo(8);
                                                 setC4R4VNo(8);
                               }
                                   } else if (c4r3MT === "S") {
                                    doorRemoved = false;
                                       alert("Select Medium or Large Module and proceed.")
                                   }
                             } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                        } else if (curX === col05X && curY === row03X) {
                          if (c5R3VNo === 9 || c5R3VNo === 10 || c5R3VNo === 11) {
                            doorRemoved = true;
                            if (c5R3VNo === 9) {
                              doorTypec5r3 = 9;
                            } else if (c5R3VNo === 10) {
                              doorTypec5r3 = 10;
                            } else {
                              doorTypec5r3 = 11;
                            }
                            if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                              if (sepOnM1C5 === true) {
                                setRow2NakedM05(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC5R2VNo(8);
                                                  setC5R3VNo(8);
                              } else {
                                setRow2NakedM05(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC5R2VNo(8);
                                                  setC5R3VNo(8);
                              }
                  } else  if (c5r3MT === "M" && c5r4MT === "M") {
                              if (sepOnM1C5 === true) {
                                setRow3NakedM05(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC5R3VNo(8);
                                                  setC5R4VNo(8);
                              } else {
                                setRow3NakedM05(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC5R3VNo(8);
                                                  setC5R4VNo(8);
                              }
                            } else if (c5r3MT === "L") {
                              if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                              } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                    
                    
                              } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                    
                              } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                              } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                              } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                              } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                              } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
                                setBaseNakedL05(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC5R1VNo(8);
                                                setC5R2VNo(8);
                                                setC5R3VNo(8);
                                                setC5R4VNo(8);
                              }
                                  } else if (c5r3MT === "S") {
                                    doorRemoved = false;
                                      alert("Select Medium or Large Module and proceed.")
                                  }
                            } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                        } else if (curX === col06X && curY === row03X) {
                          if (c6R3VNo === 9 || c6R3VNo === 10 || c6R3VNo === 11) {
                            doorRemoved = true;
                            if (c6R3VNo === 9) {
                              doorTypec6r3 = 9;
                            } else if (c6R3VNo === 10) {
                              doorTypec6r3 = 10;
                            } else {
                              doorTypec6r3 = 11;
                            }
                            if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                               if (sepOnM1C6 === true) {
                                 setRow2NakedM06(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC6R2VNo(8);
                                                   setC6R3VNo(8);
                               } else {
                                 setRow2NakedM06(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC6R2VNo(8);
                                                   setC6R3VNo(8);
                               }
                 } else  if (c6r3MT === "M" && c6r4MT === "M") {
                               if (sepOnM1C6 === true) {
                                 setRow3NakedM06(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC6R3VNo(8);
                                                   setC6R4VNo(8);
                               } else {
                                 setRow3NakedM06(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC6R3VNo(8);
                                                   setC6R4VNo(8);
                               }
                             } else if (c6r3MT === "L") {
                               if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
                                 setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                               } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                     
                     
                               } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
                                 setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                     
                               } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
                                 setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                               } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
                                 setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                               } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
                                 setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                               } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
                                 setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                               } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
                                 setBaseNakedL06(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC6R1VNo(8);
                                                 setC6R2VNo(8);
                                                 setC6R3VNo(8);
                                                 setC6R4VNo(8);
                               }
                                   } else if (c6r3MT === "S") {
                                    doorRemoved = false;
                                       alert("Select Medium or Large Module and proceed.")
                                   }
                             } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                        } else if (curX === col07X && curY === row03X) {
                          if (c7R3VNo === 9 || c7R3VNo === 10 || c7R3VNo === 11) {
                            doorRemoved = true;
                            if (c7R3VNo === 9) {
                              doorTypec7r3 = 9;
                            } else if (c7R3VNo === 10) {
                              doorTypec7r3 = 10;
                            } else {
                              doorTypec7r3 = 11;
                            }
                            if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                                if (sepOnM1C7 === true) {
                                  setRow2NakedM07(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R2VNo(8);
                                                    setC7R3VNo(8);
                                } else {
                                  setRow2NakedM07(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R2VNo(8);
                                                    setC7R3VNo(8);
                                }
                } else   if (c7r3MT === "M" && c7r4MT === "M") {
                                if (sepOnM1C7 === true) {
                                  setRow3NakedM07(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R3VNo(8);
                                                    setC7R4VNo(8);
                                } else {
                                  setRow3NakedM07(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC7R3VNo(8);
                                                    setC7R4VNo(8);
                                }
                              } else if (c7r3MT === "L") {
                                if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                                } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                      
                      
                                } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                      
                                } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                                } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                                } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                                } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                                } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
                                  setBaseNakedL07(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC7R1VNo(8);
                                                  setC7R2VNo(8);
                                                  setC7R3VNo(8);
                                                  setC7R4VNo(8);
                                }
                                    } else if (c7r3MT === "S") {
                                      doorRemoved = false;
                                        alert("Select Medium or Large Module and proceed.")
                                    }
                              } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
                        } else if (curX === col08X && curY === row03X) {
                          if (c8R3VNo === 9 || c8R3VNo === 10 || c8R3VNo === 11) {
                            doorRemoved = true;
                            if (c8R3VNo === 9) {
                              doorTypec8r3 = 9;
                            } else if (c8R3VNo === 10) {
                              doorTypec8r3 = 10;
                            } else {
                              doorTypec8r3 = 11;
                            }
                            if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                if (sepOnM1C8 === true) {
                                  setRow2NakedM08(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R2VNo(8);
                                                    setC8R3VNo(8);
                                } else {
                                  setRow2NakedM08(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R2VNo(8);
                                                    setC8R3VNo(8);
                                }
              } else  if (c8r3MT === "M" && c8r4MT === "M") {
                                if (sepOnM1C8 === true) {
                                  setRow3NakedM08(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R3VNo(8);
                                                    setC8R4VNo(8);
                                } else {
                                  setRow3NakedM08(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC8R3VNo(8);
                                                    setC8R4VNo(8);
                                }
                              } else if (c8r3MT === "L") {
                                if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                      
                      
                                } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                      
                                } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
                                  setBaseNakedL08(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC8R1VNo(8);
                                                  setC8R2VNo(8);
                                                  setC8R3VNo(8);
                                                  setC8R4VNo(8);
                                }
                                    } else if (c8r3MT === "S") {
                                      doorRemoved = false;
                                        alert("Select Medium or Large Module and proceed.")
                                    }
                              } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
                        } else if (curX === col09X && curY === row03X) {
                          if (c9R3VNo === 9 || c9R3VNo === 10 || c9R3VNo === 11) {
                            doorRemoved = true;
                            if (c9R3VNo === 9) {
                              doorTypec9r3 = 9;
                            } else if (c9R3VNo === 10) {
                              doorTypec9r3 = 10;
                            } else {
                              doorTypec9r3 = 11;
                            }
                            if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                               if (sepOnM1C9 === true) {
                                 setRow2NakedM09(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC9R2VNo(8);
                                                   setC9R3VNo(8);
                               } else {
                                 setRow2NakedM09(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC9R2VNo(8);
                                                   setC9R3VNo(8);
                               }
           } else  if (c9r3MT === "M" && c9r4MT === "M") {
                               if (sepOnM1C9 === true) {
                                 setRow3NakedM09(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC9R3VNo(8);
                                                   setC9R4VNo(8);
                               } else {
                                 setRow3NakedM09(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC9R3VNo(8);
                                                   setC9R4VNo(8);
                               }
                             } else if (c9r3MT === "L") {
                               if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
                                 setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                               } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                     
                     
                               } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
                                 setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                     
                               } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
                                 setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                               } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
                                 setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                               } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
                                 setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                               } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
                                 setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                               } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
                                 setBaseNakedL09(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC9R1VNo(8);
                                                 setC9R2VNo(8);
                                                 setC9R3VNo(8);
                                                 setC9R4VNo(8);
                               }
                                   } else if (c9r3MT === "S") {
                                    doorRemoved = false;
                                       alert("Select Medium or Large Module and proceed.")
                                   }
                             } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                        } else if (curX === col10X && curY === row03X) {
                          if (c10R3VNo === 9 || c10R3VNo === 10 || c10R3VNo === 11) {
                            doorRemoved = true;
                            if (c10R3VNo === 9) {
                              doorTypec10r3 = 9;
                            } else if (c10R3VNo === 10) {
                              doorTypec10r3 = 10;
                            } else {
                              doorTypec10r3 = 11;
                            }
                            if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                               if (sepOnM1C10 === true) {
                                 setRow2NakedM10(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC10R2VNo(8);
                                                   setC10R3VNo(8);
                               } else {
                                 setRow2NakedM10(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                                     ]
                                                   )
                                                   setC10R2VNo(8);
                                                   setC10R3VNo(8);
                               }
         } else if (c10r3MT === "M" && c10r4MT === "M") {
                               if (sepOnM1C10 === true) {
                                 setRow3NakedM10(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC10R3VNo(8);
                                                   setC10R4VNo(8);
                               } else {
                                 setRow3NakedM10(
                                                     [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                     ]
                                                   )
                                                   setC10R3VNo(8);
                                                   setC10R4VNo(8);
                               }
                             } else if (c10r3MT === "L") {
                               if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
                                 setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                               } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                     
                     
                               } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
                                 setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                     
                               } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
                                 setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                               } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
                                 setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                               } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
                                 setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                               } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
                                 setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                               } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
                                 setBaseNakedL10(
                                                   [
                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                   ]
                                                 )
                                                 setC10R1VNo(8);
                                                 setC10R2VNo(8);
                                                 setC10R3VNo(8);
                                                 setC10R4VNo(8);
                               }
                                   } else if (c10r3MT === "S") {
                                    doorRemoved = false;
                                       alert("Select Medium or Large Module and proceed.")
                                   }
                             } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                        } else if (curX === col11X && curY === row03X) {
                          if (c11R3VNo === 9 || c11R3VNo === 10 || c11R3VNo === 11) {
                            doorRemoved = true;
                            if (c11R3VNo === 9) {
                              doorTypec11r3 = 9;
                            } else if (c11R3VNo === 10) {
                              doorTypec11r3 = 10;
                            } else {
                              doorTypec11r3 = 11;
                            }
                            if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                              if (sepOnM1C11 === true) {
                                setRow2NakedM11(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC11R2VNo(8);
                                                  setC11R3VNo(8);
                              } else {
                                setRow2NakedM11(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                                    ]
                                                  )
                                                  setC11R2VNo(8);
                                                  setC11R3VNo(8);
                              }
        } else  if (c11r3MT === "M" && c11r4MT === "M") {
                              if (sepOnM1C11 === true) {
                                setRow3NakedM11(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC11R3VNo(8);
                                                  setC11R4VNo(8);
                              } else {
                                setRow3NakedM11(
                                                    [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC11R3VNo(8);
                                                  setC11R4VNo(8);
                              }
                            } else if (c11r3MT === "L") {
                              if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
                                setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                              } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                    
                    
                              } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
                                setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                    
                              } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
                                setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                              } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
                                setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                              } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
                                setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                              } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
                                setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                              } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
                                setBaseNakedL11(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                  ]
                                                )
                                                setC11R1VNo(8);
                                                setC11R2VNo(8);
                                                setC11R3VNo(8);
                                                setC11R4VNo(8);
                              }
                                  } else if (c11r3MT === "S") {
                                    doorRemoved = false;
                                      alert("Select Medium or Large Module and proceed.")
                                  }
                            } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                        } else if (curX === col12X && curY === row03X) {
                          if (c12R3VNo === 9 || c12R3VNo === 10 || c12R3VNo === 11) {
                            doorRemoved = true;
                            if (c12R3VNo === 9) {
                              doorTypec12r3 = 9;
                            } else if (c12R3VNo === 10) {
                              doorTypec12r3 = 10;
                            } else {
                              doorTypec12r3 = 11;
                            }
                            if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                 if (sepOnM1C12 === true) {
                                   setRow2NakedM12(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                                                       ]
                                                     )
                                                     setC12R2VNo(8);
                                                     setC12R3VNo(8);
                                 } else {
                                   setRow2NakedM12(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                                       ]
                                                     )
                                                     setC12R2VNo(8);
                                                     setC12R3VNo(8);
                                 }
          } else if (c12r3MT === "M" && c12r4MT === "M") {
                                 if (sepOnM1C12 === true) {
                                   setRow3NakedM12(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                                                       ]
                                                     )
                                                     setC12R3VNo(8);
                                                     setC12R4VNo(8);
                                 } else {
                                   setRow3NakedM12(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                       ]
                                                     )
                                                     setC12R3VNo(8);
                                                     setC12R4VNo(8);
                                 }
                               } else if (c12r3MT === "L") {
                                 if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
                                   setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                                 } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                       
                       
                                 } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
                                   setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                       
                                 } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
                                   setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                                 } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
                                   setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                                 } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
                                   setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                                 } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
                                   setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                                 } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
                                   setBaseNakedL12(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC12R1VNo(8);
                                                   setC12R2VNo(8);
                                                   setC12R3VNo(8);
                                                   setC12R4VNo(8);
                                 }
                                     } else if (c12r3MT === "S") {
                                      doorRemoved = false;
                                         alert("Select Medium or Large Module and proceed.")
                                     }
                               } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
                        } else if (curX === col13X && curY === row03X) {
                          if (c13R3VNo === 9 || c13R3VNo === 10 || c13R3VNo === 11) {
                            doorRemoved = true;
                            if (c13R3VNo === 9) {
                              doorTypec13r3 = 9;
                            } else if (c13R3VNo === 10) {
                              doorTypec13r3 = 10;
                            } else {
                              doorTypec13r3 = 11;
                            }
                            if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                 if (sepOnM1C13 === true) {
                                   setRow2NakedM13(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                                                       ]
                                                     )
                                                     setC13R2VNo(8);
                                                     setC13R3VNo(8);
                                 } else {
                                   setRow2NakedM13(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                                       ]
                                                     )
                                                     setC13R2VNo(8);
                                                     setC13R3VNo(8);
                                 }
       } else if (c13r3MT === "M" && c13r4MT === "M") {
                                 if (sepOnM1C13 === true) {
                                   setRow3NakedM13(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                                                       ]
                                                     )
                                                     setC13R3VNo(8);
                                                     setC13R4VNo(8);
                                 } else {
                                   setRow3NakedM13(
                                                       [
                                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                       ]
                                                     )
                                                     setC13R3VNo(8);
                                                     setC13R4VNo(8);
                                 }
                               } else if (c13r3MT === "L") {
                                 if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
                                   setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                 } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                       
                       
                                 } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
                                   setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                       
                                 } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
                                   setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                 } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
                                   setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                 } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
                                   setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                 } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
                                   setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                 } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
                                   setBaseNakedL13(
                                                     [
                                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                     ]
                                                   )
                                                   setC13R1VNo(8);
                                                   setC13R2VNo(8);
                                                   setC13R3VNo(8);
                                                   setC13R4VNo(8);
                                 }
                                     } else if (c13r3MT === "S") {
                                      doorRemoved = false;
                                         alert("Select Medium or Large Module and proceed.")
                                     }
                               } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
                        } else if (curX === col14X && curY === row03X) {
                            if (c14R3VNo === 9 || c14R3VNo === 10 || c14R3VNo === 11) {
                              doorRemoved = true;
                              if (c14R3VNo === 9) {
                                doorTypec14r3 = 9;
                              } else if (c14R3VNo === 10) {
                                doorTypec14r3 = 10;
                              } else {
                                doorTypec14r3 = 11;
                              }
                              if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                  if (sepOnM1C14 === true) {
                                    setRow2NakedM14(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                                                        ]
                                                      )
                                                      setC14R2VNo(8);
                                                      setC14R3VNo(8);
                                  } else {
                                    setRow2NakedM14(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                        ]
                                                      )
                                                      setC14R2VNo(8);
                                                      setC14R3VNo(8);
                                  }
                              } else  if (c14r3MT === "M" && c14r4MT === "M") {
                                  if (sepOnM1C14 === true) {
                                    setRow3NakedM14(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC14R3VNo(8);
                                                      setC14R4VNo(8);
                                  } else {
                                    setRow3NakedM14(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC14R3VNo(8);
                                                      setC14R4VNo(8);
                                  }
                                } else if (c14r3MT === "L") {
                                    if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
                                      setBaseNakedL14(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC14R1VNo(8);
                                                      setC14R2VNo(8);
                                                      setC14R3VNo(8);
                                                      setC14R4VNo(8);
                                    } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {setBaseNakedL14(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC14R1VNo(8);
                                                      setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                        
                        
                                    } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
                                    setBaseNakedL14(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                        
                                    } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
                                    setBaseNakedL14(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                      setC14R4VNo(8);
                                    } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
                                    setBaseNakedL14(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                                    } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
                                    setBaseNakedL14(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                                    } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
                                    setBaseNakedL14(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                                  } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
                                    setBaseNakedL14(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC14R1VNo(8);
                                                    setC14R2VNo(8);
                                                    setC14R3VNo(8);
                                                    setC14R4VNo(8);
                                  }
                                } else if (c14r3MT === "S") {
                                  doorRemoved = false;
                                  alert("Select Medium or Large Module and proceed.")
                                }
                              } else {
                                doorRemoved = false;
                                alert("You cannot do this as no doors are associated with this module.");
                              }
                          }  else if (curX === col01X && curY === row04X) {
                            if (c1R4VNo === 9 || c1R4VNo === 10 || c1R4VNo === 11) {
                              doorRemoved = true;
                              if (c1R4VNo === 9) {
                                doorTypec1r4 = 9;
                              } else if (c1R4VNo === 10) {
                                doorTypec1r4 = 10;
                              } else {
                                doorTypec1r4 = 11;
                              }
                          if (c1r3MT === "M" && c1r4MT === "M") {
                                if (sepOnM1C1 === true) {
                                  setRow3NakedM01(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                } else {
                                  setRow3NakedM01(
                                                      [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC1R3VNo(8);
                                                    setC1R4VNo(8);
                                }
                              } else if (c1r4MT === "L") {
                                if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === false) {
                                  setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                                } else if (sepOnLC1 === false && sepOn2LC1 === false && sepOn3LC1 === true) {setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                        
                        
                                } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === false) {
                                  setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                        
                                } else if (sepOnLC1 === false && sepOn2LC1 === true && sepOn3LC1 === true) {
                                  setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                                } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === false) {
                                  setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                                } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === true) {
                                  setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                                } else if (sepOnLC1 === true && sepOn2LC1 === false && sepOn3LC1 === false) {
                                  setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                                } else if (sepOnLC1 === true && sepOn2LC1 === true && sepOn3LC1 === true) {
                                  setBaseNakedL01(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
                                                    ]
                                                  )
                                                  setC1R1VNo(8);
                                                  setC1R2VNo(8);
                                                  setC1R3VNo(8);
                                                  setC1R4VNo(8);
                                }
                              } else if (c1r4MT === "S") {
                                doorRemoved = false;
                                  alert("Select Medium or Large Module and proceed.")
                              }
                            } else {
                              doorRemoved = false;
                              alert("You cannot do this as no doors are associated with this module.");
                            }
                          }  else if (curX === col02X && curY === row04X) {
                              if (c2R4VNo === 9 || c2R4VNo === 10 || c2R4VNo === 11) {
                                doorRemoved = true;
                                if (c2R4VNo === 9) {
                                  doorTypec2r4 = 9;
                                } else if (c2R4VNo === 10) {
                                  doorTypec2r4 = 10;
                                } else {
                                  doorTypec2r4 = 11;
                                }
                                 if (c2r3MT === "M" && c2r4MT === "M") {
                                  if (sepOnM1C2 === true) {
                                    setRow3NakedM02(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC2R3VNo(8);
                                                      setC2R4VNo(8);
                                  } else {
                                    setRow3NakedM02(
                                                        [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC2R3VNo(8);
                                                      setC2R4VNo(8);
                                  }
                                } else if (c2r4MT === "L") {
                                  if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === false) {
                                    setBaseNakedL02(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                                  } else if (sepOnLC2 === false && sepOn2LC2 === false && sepOn3LC2 === true) {setBaseNakedL01(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                        
                        
                                  } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === false) {
                                    setBaseNakedL02(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                        
                                  } else if (sepOnLC2 === false && sepOn2LC2 === true && sepOn3LC2 === true) {
                                    setBaseNakedL02(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                                  } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === false) {
                                    setBaseNakedL02(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                                  } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === true) {
                                    setBaseNakedL02(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                                  } else if (sepOnLC2 === true && sepOn2LC2 === false && sepOn3LC2 === false) {
                                    setBaseNakedL02(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                                  } else if (sepOnLC2 === true && sepOn2LC2 === true && sepOn3LC2 === true) {
                                    setBaseNakedL02(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                                                      ]
                                                    )
                                                    setC2R1VNo(8);
                                                    setC2R2VNo(8);
                                                    setC2R3VNo(8);
                                                    setC2R4VNo(8);
                                  }
                                      } else if (c2r4MT === "S") {
                                        doorRemoved = false;
                                          alert("Select Medium or Large Module and proceed.")
                                      }
                                } else {
                                  doorRemoved = false;
                                  alert("You cannot do this as no doors are associated with this module.");
                                }
                              } else if (curX === col03X && curY === row04X) {
                                if (c3R4VNo === 9 || c3R4VNo === 10 || c3R4VNo === 11) {
                                  doorRemoved = true;
                                  if (c3R4VNo === 9) {
                                    doorTypec3r4 = 9;
                                  } else if (c3R4VNo === 10) {
                                    doorTypec3r4 = 10;
                                  } else {
                                    doorTypec3r4 = 11;
                                  }
                                if (c3r3MT === "M" && c3r4MT === "M") {
                                    if (sepOnM1C3 === true) {
                                      setRow3NakedM03(
                                                          [
                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                                                          ]
                                                        )
                                                        setC3R3VNo(8);
                                                        setC3R4VNo(8);
                                    } else {
                                      setRow3NakedM03(
                                                          [
                                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                                                          ]
                                                        )
                                                        setC3R3VNo(8);
                                                        setC3R4VNo(8);
                                    }
                                  } else if (c3r4MT === "L") {
                                    if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === false) {
                                      setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                                    } else if (sepOnLC3 === false && sepOn2LC3 === false && sepOn3LC3 === true) {setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                          
                          
                                    } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === false) {
                                      setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                          
                                    } else if (sepOnLC3 === false && sepOn2LC3 === true && sepOn3LC3 === true) {
                                      setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                                    } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === false) {
                                      setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                                    } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === true) {
                                      setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                                    } else if (sepOnLC3 === true && sepOn2LC3 === false && sepOn3LC3 === false) {
                                      setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                                    } else if (sepOnLC3 === true && sepOn2LC3 === true && sepOn3LC3 === true) {
                                      setBaseNakedL03(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                                                        ]
                                                      )
                                                      setC3R1VNo(8);
                                                      setC3R2VNo(8);
                                                      setC3R3VNo(8);
                                                      setC3R4VNo(8);
                                    }
                                        } else if (c3r4MT === "S") {
                                          doorRemoved = false;
                                            alert("Select Medium or Large Module and proceed.")
                                        }
                                  } else {
                                    doorRemoved = false;
                                    alert("You cannot do this as no doors are associated with this module.");
                                  }
                                } else if (curX === col04X && curY === row04X) {
                                  if (c4R4VNo === 9 || c4R4VNo === 10 || c4R4VNo === 11) {
                                    doorRemoved = true;
                                    if (c4R4VNo === 9) {
                                      doorTypec4r4 = 9;
                                    } else if (c4R4VNo === 10) {
                                      doorTypec4r4 = 10;
                                    } else {
                                      doorTypec4r4 = 11;
                                    }
                                   if (c4r3MT === "M" && c4r4MT === "M" ) {
                                      if (sepOnM1C4 === true) {
                                        setRow3NakedM04(
                                                            [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                                                            ]
                                                          )
                                                          setC4R3VNo(8);
                                                          setC4R4VNo(8);
                                      } else {
                                        setRow3NakedM04(
                                                            [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                                                            ]
                                                          )
                                                          setC4R3VNo(8);
                                                          setC4R4VNo(8);
                                      }
                                    } else if (c4r4MT === "L") {
                                      if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === false) {
                                        setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                                      } else if (sepOnLC4 === false && sepOn2LC4 === false && sepOn3LC4 === true) {setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                            
                            
                                      } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === false) {
                                        setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                            
                                      } else if (sepOnLC4 === false && sepOn2LC4 === true && sepOn3LC4 === true) {
                                        setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                                      } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === false) {
                                        setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                                      } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === true) {
                                        setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                                      } else if (sepOnLC4 === true && sepOn2LC4 === false && sepOn3LC4 === false) {
                                        setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                                      } else if (sepOnLC4 === true && sepOn2LC4 === true && sepOn3LC4 === true) {
                                        setBaseNakedL04(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                                                          ]
                                                        )
                                                        setC4R1VNo(8);
                                                        setC4R2VNo(8);
                                                        setC4R3VNo(8);
                                                        setC4R4VNo(8);
                                      }
                                          } else if (c4r4MT === "S") {
                                            doorRemoved = false;
                                              alert("Select Medium or Large Module and proceed.")
                                          }
                                    } else {
                                      doorRemoved = false;
                                      alert("You cannot do this as no doors are associated with this module.");
                                    }
                                  } else if (curX === col05X && curY === row04X) {
                                    if (c5R4VNo === 9 || c5R4VNo === 10 || c5R4VNo === 11) {
                                      doorRemoved = true;
                                      if (c5R4VNo === 9) {
                                        doorTypec5r4 = 9;
                                      } else if (c5R4VNo === 10) {
                                        doorTypec5r4 = 10;
                                      } else {
                                        doorTypec5r4 = 11;
                                      }
                                     if (c5r3MT === "M" && c5r4MT === "M") {
                                        if (sepOnM1C5 === true) {
                                          setRow3NakedM05(
                                                              [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC5R3VNo(8);
                                                            setC5R4VNo(8);
                                        } else {
                                          setRow3NakedM05(
                                                              [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC5R3VNo(8);
                                                            setC5R4VNo(8);
                                        }
                                      } else if (c5r4MT === "L") {
                                        if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === false) {
                                          setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                                        } else if (sepOnLC5 === false && sepOn2LC5 === false && sepOn3LC5 === true) {setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                              
                              
                                        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === false) {
                                          setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                              
                                        } else if (sepOnLC5 === false && sepOn2LC5 === true && sepOn3LC5 === true) {
                                          setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                                        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === false) {
                                          setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                                        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === true) {
                                          setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                                        } else if (sepOnLC5 === true && sepOn2LC5 === false && sepOn3LC5 === false) {
                                          setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                                        } else if (sepOnLC5 === true && sepOn2LC5 === true && sepOn3LC5 === true) {
                                          setBaseNakedL05(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                                                            ]
                                                          )
                                                          setC5R1VNo(8);
                                                          setC5R2VNo(8);
                                                          setC5R3VNo(8);
                                                          setC5R4VNo(8);
                                        }
                                            } else if (c5r4MT === "S") {
                                              doorRemoved = false;
                                                alert("Select Medium or Large Module and proceed.")
                                            }
                                      } else {
                                        doorRemoved = false;
                                        alert("You cannot do this as no doors are associated with this module.");
                                      }
                                    } else if (curX === col06X && curY === row04X) {
                                      if (c6R4VNo === 9 || c6R4VNo === 10 || c6R4VNo === 11) {
                                        doorRemoved = true;
                                        if (c6R4VNo === 9) {
                                          doorTypec6r4 = 9;
                                        } else if (c6R4VNo === 10) {
                                          doorTypec6r4 = 10;
                                        } else {
                                          doorTypec6r4 = 11;
                                        }
                                      if (c6r3MT === "M" && c6r4MT === "M") {
                                          if (sepOnM1C6 === true) {
                                            setRow3NakedM06(
                                                                [
                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                                                                ]
                                                              )
                                                              setC6R3VNo(8);
                                                              setC6R4VNo(8);
                                          } else {
                                            setRow3NakedM06(
                                                                [
                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                                                ]
                                                              )
                                                              setC6R3VNo(8);
                                                              setC6R4VNo(8);
                                          }
                                        } else if (c6r4MT === "L") {
                                          if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === false) {
                                            setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                          } else if (sepOnLC6 === false && sepOn2LC6 === false && sepOn3LC6 === true) {setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                
                                
                                          } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === false) {
                                            setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                
                                          } else if (sepOnLC6 === false && sepOn2LC6 === true && sepOn3LC6 === true) {
                                            setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                          } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === false) {
                                            setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                          } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === true) {
                                            setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                          } else if (sepOnLC6 === true && sepOn2LC6 === false && sepOn3LC6 === false) {
                                            setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                          } else if (sepOnLC6 === true && sepOn2LC6 === true && sepOn3LC6 === true) {
                                            setBaseNakedL06(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                                                              ]
                                                            )
                                                            setC6R1VNo(8);
                                                            setC6R2VNo(8);
                                                            setC6R3VNo(8);
                                                            setC6R4VNo(8);
                                          }
                                              } else if (c6r4MT === "S") {
                                                doorRemoved = false;
                                                  alert("Select Medium or Large Module and proceed.")
                                              }
                                        } else {
                                          doorRemoved = false;
                                          alert("You cannot do this as no doors are associated with this module.");
                                        }
                                      } else if (curX === col07X && curY === row04X) {
                                        if (c7R4VNo === 9 || c7R4VNo === 10 || c7R4VNo === 11) {
                                          doorRemoved = true;
                                          if (c7R4VNo === 9) {
                                            doorTypec7r4 = 9;
                                          } else if (c7R4VNo === 10) {
                                            doorTypec7r4 = 10;
                                          } else {
                                            doorTypec7r4 = 11;
                                          }
                                        if (c7r3MT === "M" && c7r4MT === "M") {
                                            if (sepOnM1C7 === true) {
                                              setRow3NakedM07(
                                                                  [
                                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                                                                  ]
                                                                )
                                                                setC7R3VNo(8);
                                                                setC7R4VNo(8);
                                            } else {
                                              setRow3NakedM07(
                                                                  [
                                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                                  ]
                                                                )
                                                                setC7R3VNo(8);
                                                                setC7R4VNo(8);
                                            }
                                          } else if (c7r4MT === "L") {
                                            if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === false) {
                                              setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                            } else if (sepOnLC7 === false && sepOn2LC7 === false && sepOn3LC7 === true) {setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                  
                                  
                                            } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === false) {
                                              setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                  
                                            } else if (sepOnLC7 === false && sepOn2LC7 === true && sepOn3LC7 === true) {
                                              setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                            } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === false) {
                                              setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                            } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === true) {
                                              setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                            } else if (sepOnLC7 === true && sepOn2LC7 === false && sepOn3LC7 === false) {
                                              setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                            } else if (sepOnLC7 === true && sepOn2LC7 === true && sepOn3LC7 === true) {
                                              setBaseNakedL07(
                                                                [
                                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                                                                ]
                                                              )
                                                              setC7R1VNo(8);
                                                              setC7R2VNo(8);
                                                              setC7R3VNo(8);
                                                              setC7R4VNo(8);
                                            }
                                                } else if (c7r4MT === "S") {
                                                  doorRemoved = false;
                                                    alert("Select Medium or Large Module and proceed.")
                                                }
                                          } else {
                                            doorRemoved = false;
                                            alert("You cannot do this as no doors are associated with this module.");
                                          }
                                        } else if (curX === col08X && curY === row04X) {
                                          if (c8R4VNo === 9 || c8R4VNo === 10 || c8R4VNo === 11) {
                                            doorRemoved = true;
                                            if (c8R4VNo === 9) {
                                              doorTypec8r4 = 9;
                                            } else if (c8R4VNo === 10) {
                                              doorTypec8r4 = 10;
                                            } else {
                                              doorTypec8r4 = 11;
                                            }
                                          if (c8r3MT === "M" && c8r4MT === "M") {
                                              if (sepOnM1C8 === true) {
                                                setRow3NakedM08(
                                                                    [
                                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC8R3VNo(8);
                                                                  setC8R4VNo(8);
                                              } else {
                                                setRow3NakedM08(
                                                                    [
                                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC8R3VNo(8);
                                                                  setC8R4VNo(8);
                                              }
                                            } else if (c8r4MT === "L") {
                                              if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === false) {
                                                setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                              } else if (sepOnLC8 === false && sepOn2LC8 === false && sepOn3LC8 === true) {setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                    
                                    
                                              } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === false) {
                                                setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                    
                                              } else if (sepOnLC8 === false && sepOn2LC8 === true && sepOn3LC8 === true) {
                                                setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                              } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === false) {
                                                setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                              } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === true) {
                                                setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                              } else if (sepOnLC8 === true && sepOn2LC8 === false && sepOn3LC8 === false) {
                                                setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                              } else if (sepOnLC8 === true && sepOn2LC8 === true && sepOn3LC8 === true) {
                                                setBaseNakedL08(
                                                                  [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                                                                  ]
                                                                )
                                                                setC8R1VNo(8);
                                                                setC8R2VNo(8);
                                                                setC8R3VNo(8);
                                                                setC8R4VNo(8);
                                              }
                                                  } else if (c8r4MT === "S") {
                                                    doorRemoved = false;
                                                      alert("Select Medium or Large Module and proceed.")
                                                  }
                                            } else {
                                              doorRemoved = false;
                                              alert("You cannot do this as no doors are associated with this module.");
                                            }
                                          } else if (curX === col09X && curY === row04X) {
                                            if (c9R4VNo === 9 || c9R4VNo === 10 || c9R4VNo === 11) {
                                              doorRemoved = true;
                                              if (c9R4VNo === 9) {
                                                doorTypec9r4 = 9;
                                              } else if (c9R4VNo === 10) {
                                                doorTypec9r4 = 10;
                                              } else {
                                                doorTypec9r4 = 11;
                                              }
                                            if (c9r3MT === "M" && c9r4MT === "M") {
                                                if (sepOnM1C9 === true) {
                                                  setRow3NakedM09(
                                                                      [
                                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC9R3VNo(8);
                                                                    setC9R4VNo(8);
                                                } else {
                                                  setRow3NakedM09(
                                                                      [
                                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC9R3VNo(8);
                                                                    setC9R4VNo(8);
                                                }
                                              } else if (c9r4MT === "L") {
                                                if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === false) {
                                                  setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                                } else if (sepOnLC9 === false && sepOn2LC9 === false && sepOn3LC9 === true) {setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                      
                                      
                                                } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === false) {
                                                  setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                      
                                                } else if (sepOnLC9 === false && sepOn2LC9 === true && sepOn3LC9 === true) {
                                                  setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                                } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === false) {
                                                  setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                                } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === true) {
                                                  setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                                } else if (sepOnLC9 === true && sepOn2LC9 === false && sepOn3LC9 === false) {
                                                  setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                                } else if (sepOnLC9 === true && sepOn2LC9 === true && sepOn3LC9 === true) {
                                                  setBaseNakedL09(
                                                                    [
                                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                                                                    ]
                                                                  )
                                                                  setC9R1VNo(8);
                                                                  setC9R2VNo(8);
                                                                  setC9R3VNo(8);
                                                                  setC9R4VNo(8);
                                                }
                                                    } else if (c9r4MT === "S") {
                                                      doorRemoved = false;
                                                        alert("Select Medium or Large Module and proceed.")
                                                    }
                                              } else {
                                                doorRemoved = false;
                                                alert("You cannot do this as no doors are associated with this module.");
                                              }
                                            } else if (curX === col10X && curY === row04X) {
                                              if (c10R4VNo === 9 || c10R4VNo === 10 || c10R4VNo === 11) {
                                                doorRemoved = true;
                                                if (c10R4VNo === 9) {
                                                  doorTypec10r4 = 9;
                                                } else if (c10R4VNo === 10) {
                                                  doorTypec10r4 = 10;
                                                } else {
                                                  doorTypec10r4 = 11;
                                                }
                                              if (c10r3MT === "M" && c10r4MT === "M") {
                                                  if (sepOnM1C10 === true) {
                                                    setRow3NakedM10(
                                                                        [
                                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC10R3VNo(8);
                                                                      setC10R4VNo(8);
                                                  } else {
                                                    setRow3NakedM10(
                                                                        [
                                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC10R3VNo(8);
                                                                      setC10R4VNo(8);
                                                  }
                                                } else if (c10r4MT === "L") {
                                                  if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === false) {
                                                    setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                                  } else if (sepOnLC10 === false && sepOn2LC10 === false && sepOn3LC10 === true) {setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                        
                                        
                                                  } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === false) {
                                                    setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                        
                                                  } else if (sepOnLC10 === false && sepOn2LC10 === true && sepOn3LC10 === true) {
                                                    setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                                  } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === false) {
                                                    setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                                  } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === true) {
                                                    setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                                  } else if (sepOnLC10 === true && sepOn2LC10 === false && sepOn3LC10 === false) {
                                                    setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                                  } else if (sepOnLC10 === true && sepOn2LC10 === true && sepOn3LC10 === true) {
                                                    setBaseNakedL10(
                                                                      [
                                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                                                      ]
                                                                    )
                                                                    setC10R1VNo(8);
                                                                    setC10R2VNo(8);
                                                                    setC10R3VNo(8);
                                                                    setC10R4VNo(8);
                                                  }
                                                      } else if (c10r4MT === "S") {
                                                        doorRemoved = false;
                                                          alert("Select Medium or Large Module and proceed.")
                                                      }
                                                } else {
                                                  doorRemoved = false;
                                                  alert("You cannot do this as no doors are associated with this module.");
                                                }
                                              } else if (curX === col11X && curY === row04X) {
                                                if (c11R4VNo === 9 || c11R4VNo === 10 || c11R4VNo === 11) {
                                                  doorRemoved = true;
                                                  if (c11R4VNo === 9) {
                                                    doorTypec11r4 = 9;
                                                  } else if (c11R4VNo === 10) {
                                                    doorTypec11r4 = 10;
                                                  } else {
                                                    doorTypec11r4 = 11;
                                                  }
                                                  if (c11r3MT === "M" && c11r4MT === "M") {
                                                    if (sepOnM1C11 === true) {
                                                      setRow3NakedM11(
                                                                          [
                                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC11R3VNo(8);
                                                                        setC11R4VNo(8);
                                                    } else {
                                                      setRow3NakedM11(
                                                                          [
                                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC11R3VNo(8);
                                                                        setC11R4VNo(8);
                                                    }
                                                  } else if (c11r4MT === "L") {
                                                    if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === false) {
                                                      setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                                    } else if (sepOnLC11 === false && sepOn2LC11 === false && sepOn3LC11 === true) {setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                          
                                          
                                                    } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === false) {
                                                      setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                          
                                                    } else if (sepOnLC11 === false && sepOn2LC11 === true && sepOn3LC11 === true) {
                                                      setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                                    } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === false) {
                                                      setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                                    } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === true) {
                                                      setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                                    } else if (sepOnLC11 === true && sepOn2LC11 === false && sepOn3LC11 === false) {
                                                      setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                                    } else if (sepOnLC11 === true && sepOn2LC11 === true && sepOn3LC11 === true) {
                                                      setBaseNakedL11(
                                                                        [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                                                        ]
                                                                      )
                                                                      setC11R1VNo(8);
                                                                      setC11R2VNo(8);
                                                                      setC11R3VNo(8);
                                                                      setC11R4VNo(8);
                                                    }
                                                        } else if (c11r4MT === "S") {
                                                          doorRemoved = false;
                                                            alert("Select Medium or Large Module and proceed.")
                                                        }
                                                  } else {
                                                    doorRemoved = false;
                                                    alert("You cannot do this as no doors are associated with this module.");
                                                  }
                                                } else if (curX === col12X && curY === row04X) {
                                                  if (c12R4VNo === 9 || c12R4VNo === 10 || c12R4VNo === 11) {
                                                    doorRemoved = true;
                                                    if (c12R4VNo === 9) {
                                                      doorTypec12r4 = 9;
                                                    } else if (c12R4VNo === 10) {
                                                      doorTypec12r4 = 10;
                                                    } else {
                                                      doorTypec12r4 = 11;
                                                    }
                                                   if (c12r3MT === "M" && c12r4MT === "M") {
                                                      if (sepOnM1C12 === true) {
                                                        setRow3NakedM12(
                                                                            [
                                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC12R3VNo(8);
                                                                          setC12R4VNo(8);
                                                      } else {
                                                        setRow3NakedM12(
                                                                            [
                                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC12R3VNo(8);
                                                                          setC12R4VNo(8);
                                                      }
                                                    } else if (c12r4MT === "L") {
                                                      if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === false) {
                                                        setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                                      } else if (sepOnLC12 === false && sepOn2LC12 === false && sepOn3LC12 === true) {setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                            
                                            
                                                      } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === false) {
                                                        setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                            
                                                      } else if (sepOnLC12 === false && sepOn2LC12 === true && sepOn3LC12 === true) {
                                                        setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                                      } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === false) {
                                                        setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                                      } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === true) {
                                                        setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                                      } else if (sepOnLC12 === true && sepOn2LC12 === false && sepOn3LC12 === false) {
                                                        setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                                      } else if (sepOnLC12 === true && sepOn2LC12 === true && sepOn3LC12 === true) {
                                                        setBaseNakedL12(
                                                                          [
                                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                                                          ]
                                                                        )
                                                                        setC12R1VNo(8);
                                                                        setC12R2VNo(8);
                                                                        setC12R3VNo(8);
                                                                        setC12R4VNo(8);
                                                      }
                                                          } else if (c12r4MT === "S") {
                                                            doorRemoved = false;
                                                              alert("Select Medium or Large Module and proceed.")
                                                          }
                                                    } else {
                                                      doorRemoved = false;
                                                      alert("You cannot do this as no doors are associated with this module.");
                                                    }
                                                  } else if (curX === col13X && curY === row04X) {
                                                    if (c13R4VNo === 9 || c13R4VNo === 10 || c13R4VNo === 11) {
                                                      doorRemoved = true;
                                                      if (c13R4VNo === 9) {
                                                        doorTypec13r4 = 9;
                                                      } else if (c13R4VNo === 10) {
                                                        doorTypec13r4 = 10;
                                                      } else {
                                                        doorTypec13r4 = 11;
                                                      }
                                                    if (c13r3MT === "M" && c13r4MT === "M") {
                                                        if (sepOnM1C13 === true) {
                                                          setRow3NakedM13(
                                                                              [
                                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC13R3VNo(8);
                                                                            setC13R4VNo(8);
                                                        } else {
                                                          setRow3NakedM13(
                                                                              [
                                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC13R3VNo(8);
                                                                            setC13R4VNo(8);
                                                        }
                                                      } else if (c13r4MT === "L") {
                                                        if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === false) {
                                                          setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                                        } else if (sepOnLC13 === false && sepOn2LC13 === false && sepOn3LC13 === true) {setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                              
                                              
                                                        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === false) {
                                                          setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                              
                                                        } else if (sepOnLC13 === false && sepOn2LC13 === true && sepOn3LC13 === true) {
                                                          setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                                        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === false) {
                                                          setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                                        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === true) {
                                                          setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                                        } else if (sepOnLC13 === true && sepOn2LC13 === false && sepOn3LC13 === false) {
                                                          setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                                        } else if (sepOnLC13 === true && sepOn2LC13 === true && sepOn3LC13 === true) {
                                                          setBaseNakedL13(
                                                                            [
                                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                                                            ]
                                                                          )
                                                                          setC13R1VNo(8);
                                                                          setC13R2VNo(8);
                                                                          setC13R3VNo(8);
                                                                          setC13R4VNo(8);
                                                        }
                                                            } else if (c13r4MT === "S") {
                                                                doorRemoved = false;
                                                                alert("Select Medium or Large Module and proceed.")
                                                            }
                                                      } else {
                                                        doorRemoved = false;
                                                        alert("You cannot do this as no doors are associated with this module.");
                                                      }
                                                    } else if (curX === col14X && curY === row04X) {
                                                      if (c14R4VNo === 9 || c14R4VNo === 10 || c14R4VNo === 11) {
                                                        doorRemoved = true;
                                                        if (c14R4VNo === 9) {
                                                          doorTypec14r4 = 9;
                                                        } else if (c14R4VNo === 10) {
                                                          doorTypec14r4 = 10;
                                                        } else {
                                                          doorTypec14r4 = 11;
                                                        }
                                                       if (c14r3MT === "M" && c14r4MT === "M") {
                                                          if (sepOnM1C14 === true) {
                                                            setRow3NakedM14(
                                                                                [
                                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                                                                                ]
                                                                              )
                                                                              setC14R3VNo(8);
                                                                              setC14R4VNo(8);
                                                          } else {
                                                            setRow3NakedM14(
                                                                                [
                                                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                                                ]
                                                                              )
                                                                              setC14R3VNo(8);
                                                                              setC14R4VNo(8);
                                                          }
                                                        } else if (c14r4MT === "L") {
                                                          if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === false) {
                                                            setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                          } else if (sepOnLC14 === false && sepOn2LC14 === false && sepOn3LC14 === true) {setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                
                                                
                                                          } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === false) {
                                                            setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                
                                                          } else if (sepOnLC14 === false && sepOn2LC14 === true && sepOn3LC14 === true) {
                                                            setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                          } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === false) {
                                                            setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                          } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === true) {
                                                            setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                          } else if (sepOnLC14 === true && sepOn2LC14 === false && sepOn3LC14 === false) {
                                                            setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                          } else if (sepOnLC14 === true && sepOn2LC14 === true && sepOn3LC14 === true) {
                                                            setBaseNakedL14(
                                                                              [
                                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                                                              ]
                                                                            )
                                                                            setC14R1VNo(8);
                                                                            setC14R2VNo(8);
                                                                            setC14R3VNo(8);
                                                                            setC14R4VNo(8);
                                                          }
                                                              } else if (c14r4MT === "S") {
                                                                  doorRemoved = false;
                                                                  alert("Select Medium or Large Module and proceed.")
                                                                  
                                                              }
                                                        } else {
                                                          doorRemoved = false;
                                                          alert("You cannot do this as no doors are associated with this module.");
                                                        }
                                      } 

}

let smallBaseV1 = (e) => {
  topPlates = topPlates + 1;
  bottomPlates = bottomPlates + 1;
  columns = columns + 4;
  sideConnector1 = sideConnector1 + 2;
  plateLateralTopCover = plateLateralTopCover + 4;
  backPlateTopCover = backPlateTopCover + 4;
  foot = foot + 2;
  knowb1TopColCover = knowb1TopColCover + 4;
  knob2TopDoorPin = knob2TopDoorPin + 4;
  rubberPads = rubberPads + 4;
}

let smallBaseUpV1 = (e) => {
  topPlates = topPlates + 1;
  columns = columns + 4;
  sideConnector1 = sideConnector1 + 1;
  plateLateralTopCover = plateLateralTopCover + 2;
  backPlateTopCover = backPlateTopCover + 2;
  //knowb1TopColCover = knowb1TopColCover + 4;
  knob2TopDoorPin = knob2TopDoorPin + 2;
}

let smallUpSideV1 = (e) => {
  topPlates = topPlates + 1;
  columns = columns + 2;
  //sideConnector1 = sideConnector1 + 4;
  plateLateralTopCover = plateLateralTopCover + 1;
  backPlateTopCover = backPlateTopCover + 2;
  //knowb1TopColCover = knowb1TopColCover + 4;
  knob2TopDoorPin = knob2TopDoorPin + 2;
}

let smallBaseRightV1 = (e) => {
  topPlates = topPlates + 1;
  bottomPlates = bottomPlates + 1;
  columns = columns + 2;
  //sideConnector1 = sideConnector1 + 4;
  plateLateralTopCover = plateLateralTopCover + 2;
  backPlateTopCover = backPlateTopCover + 4;
  foot = foot + 1;
  knowb1TopColCover = knowb1TopColCover + 2;
  knob2TopDoorPin = knob2TopDoorPin + 4;
  rubberPads = rubberPads + 2;
}

let mediumBaseV1 = (e) => {
  topPlates = topPlates + 1;
  bottomPlates = bottomPlates + 1;
  columns = columns + 8;
  sideConnector1 = sideConnector1 + 3;
  plateLateralTopCover = plateLateralTopCover + 4;
  backPlateTopCover = backPlateTopCover + 6;
  foot = foot + 2;
  knowb1TopColCover = knowb1TopColCover + 4;
  knob2TopDoorPin = knob2TopDoorPin + 4;
  rubberPads = rubberPads + 4;
}

let mediumBaseUpV1 = (e) => {
  topPlates = topPlates + 1;
  columns = columns + 8;
  sideConnector1 = sideConnector1 + 2;
  plateLateralTopCover = plateLateralTopCover + 2;
  backPlateTopCover = backPlateTopCover + 4;
  //knowb1TopColCover = knowb1TopColCover + 4;
  knob2TopDoorPin = knob2TopDoorPin + 2;
}

let mediumUpSideV1 = (e) => {
  topPlates = topPlates + 1;
  columns = columns + 4;
  //sideConnector1 = sideConnector1 + 6;
  plateLateralTopCover = plateLateralTopCover + 1;
  backPlateTopCover = backPlateTopCover + 4;
  //knowb1TopColCover = knowb1TopColCover + 4;
  knob2TopDoorPin = knob2TopDoorPin + 2;
}

let mediumBaseRightV1 = (e) => {
  topPlates = topPlates + 1;
  bottomPlates = bottomPlates + 1;
  columns = columns + 4;
  //sideConnector1 = sideConnector1 + 6;
  plateLateralTopCover = plateLateralTopCover + 2;
  backPlateTopCover = backPlateTopCover + 6;
  foot = foot + 1;
  knowb1TopColCover = knowb1TopColCover + 2;
  knob2TopDoorPin = knob2TopDoorPin + 4;
  rubberPads = rubberPads + 2;
}

let largeBaseV1 = (e) => {
  topPlates = topPlates + 1;
  bottomPlates = bottomPlates + 1;
  columns = columns + 16;
  sideConnector1 = sideConnector1 + 5;
  plateLateralTopCover = plateLateralTopCover + 4;
  backPlateTopCover = backPlateTopCover + 10;
  foot = foot + 2;
  knowb1TopColCover = knowb1TopColCover + 4;
  knob2TopDoorPin = knob2TopDoorPin + 4;
  rubberPads = rubberPads + 4;
}

let largeBaseRightV1 = (e) => {
  topPlates = topPlates + 1;
  bottomPlates = bottomPlates + 1;
  columns = columns + 8;
  //sideConnector1 = sideConnector1 + 10;
  plateLateralTopCover = plateLateralTopCover + 2;
  backPlateTopCover = backPlateTopCover + 10;
  foot = foot + 1;
  knowb1TopColCover = knowb1TopColCover + 2;
  knob2TopDoorPin = knob2TopDoorPin + 4;
  rubberPads = rubberPads + 2;
}

let smallBaseV2 = (e) => {
  smallBaseV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
}

let smallBaseUpV2 = (e) => {
  smallBaseUpV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
}

let smallUpSideV2 = (e) => {
  smallUpSideV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
}

let smallBaseRightV2 = (e) => {
  smallBaseRightV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
}

let mediumBaseV2 = (e) => {
  mediumBaseV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
}

let mediumBaseUpV2 = (e) => {
  mediumBaseUpV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
}

let mediumUpSideV2 = (e) => {
  mediumUpSideV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
}

let mediumBaseRightV2 = (e) => {
  mediumBaseRightV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
}

let largeBaseV2 = (e) => {
  largeBaseV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
}

let largeBaseRightV2 = (e) => {
  largeBaseRightV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
}

let smallBaseV3 = (e) => {
  smallBaseV1();
  leftSidePlates = leftSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseUpV3 = (e) => {
  smallBaseUpV1();
  leftSidePlates = leftSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallUpSideV3 = (e) => {
  smallUpSideV1();
  
}

let smallBaseRightV3 = (e) => {
  smallBaseRightV1();
  
}

let mediumBaseV3 = (e) => {
  mediumBaseV1();
  leftSidePlates = leftSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumBaseUpV3 = (e) => {
  mediumBaseUpV1();
  leftSidePlates = leftSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumUpSideV3 = (e) => {
  mediumUpSideV1();

}

let mediumBaseRightV3 = (e) => {
  mediumBaseRightV1();
 
}

let largeBaseV3 = (e) => {
  largeBaseV1();
  leftSidePlates = leftSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
}

let largeBaseRightV3 = (e) => {
  largeBaseRightV1();
  plateLateralTopCover = plateLateralTopCover - 4;
}

let smallBaseV4 = (e) => {
  smallBaseV1();
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseUpV4 = (e) => {
  smallBaseUpV1();
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallUpSideV4 = (e) => {
  smallUpSideV1();
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseRightV4 = (e) => {
  smallBaseRightV1();
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let mediumBaseV4 = (e) => {
  mediumBaseV1();
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}
let mediumBaseUpV4 = (e) => {
  mediumBaseUpV1();
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumUpSideV4 = (e) => {
  mediumUpSideV1();
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumBaseRightV4 = (e) => {
  mediumBaseRightV1();
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let largeBaseV4 = (e) => {
  largeBaseV1();
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
}

let largeBaseRightV4 = (e) => {
  largeBaseRightV1();
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
}

let smallBaseV5 = (e) => {
  smallBaseV1();
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let smallBaseUpV5 = (e) => {
  smallBaseUpV1();
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let smallUpSideV5 = (e) => {
  smallUpSideV1();
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseRightV5 = (e) => {
  smallBaseRightV1();
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let mediumBaseV5 = (e) => {
  mediumBaseV1();
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 4;
}
let mediumBaseUpV5 = (e) => {
  mediumBaseUpV1();
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 4;
}

let mediumUpSideV5 = (e) => {
  mediumUpSideV1();
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumBaseRightV5 = (e) => {
  mediumBaseRightV1();
  rightSidePlates = rightSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let largeBaseV5 = (e) => {
  largeBaseV1();
  leftSidePlates = leftSidePlates + 4;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 8;
}

let largeBaseRightV5 = (e) => {
  largeBaseRightV1();
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
}

let smallBaseV6 = (e) => {
  smallBaseV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseUpV6 = (e) => {
  smallBaseUpV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallUpSideV6 = (e) => {
  smallUpSideV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
}

let smallBaseRightV6 = (e) => {
  smallBaseRightV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
}

let mediumBaseV6 = (e) => {
  mediumBaseV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
  
}

let mediumBaseUpV6 = (e) => {
  mediumBaseUpV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumUpSideV6 = (e) => {
  mediumUpSideV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
}

let mediumBaseRightV6 = (e) => {
  mediumBaseRightV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
}

let largeBaseV6 = (e) => {
  largeBaseV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  leftSidePlates = leftSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
}

let largeBaseRightV6 = (e) => {
  largeBaseRightV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
}

let smallBaseV7 = (e) => {
  smallBaseV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseUpV7 = (e) => {
  smallBaseUpV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallUpSideV7 = (e) => {
  smallUpSideV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseRightV7 = (e) => {
  smallBaseRightV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let mediumBaseV7 = (e) => {
  mediumBaseV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumBaseUpV7 = (e) => {
  mediumBaseUpV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumUpSideV7 = (e) => {
  mediumUpSideV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumBaseRightV7 = (e) => {
  mediumBaseRightV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
}

let largeBaseV7 = (e) => {
  largeBaseV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  rightSidePlates = rightSidePlates + 4; 
  plateLateralTopCover = plateLateralTopCover - 4;
}

let largeBaseRightV7 = (e) => {
  largeBaseRightV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  rightSidePlates = rightSidePlates + 4; 
  plateLateralTopCover = plateLateralTopCover - 4;
}

let smallBaseV8 = (e) => {
  smallBaseV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let smallBaseUpV8 = (e) => {
  smallBaseUpV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
}

let smallUpSideV8 = (e) => {
  smallUpSideV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}

let smallBaseRightV8 = (e) => {
  smallBaseRightV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
}


let mediumBaseV8 = (e) => {
  mediumBaseV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
}

let mediumBaseUpV8 = (e) => {
  mediumBaseUpV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
}
let mediumUpSideV8 = (e) => {
  mediumUpSideV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
}

let mediumBaseRightV8 = (e) => {
  mediumBaseRightV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
}

let largeBaseV8 = (e) => {
  largeBaseV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  leftSidePlates = leftSidePlates + 4;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 8;
}

let largeBaseRightV8 = (e) => {
  largeBaseRightV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  rightSidePlates = rightSidePlates + 4; 
  plateLateralTopCover = plateLateralTopCover - 4;
}

let smallBaseV9 = (e) => {
  smallBaseV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;

}

let smallBaseUpV9 = (e) => {
  smallBaseUpV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallUpSideV9 = (e) => {
  smallUpSideV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallBaseRightV9 = (e) => {
  smallBaseRightV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}


let mediumBaseV9 = (e) => {
  mediumBaseV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseUpV9 = (e) => {
  mediumBaseUpV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumUpSideV9 = (e) => {
  mediumUpSideV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseRightV9 = (e) => {
  mediumBaseRightV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let largeBaseV9 = (e) => {
  largeBaseV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  leftSidePlates = leftSidePlates + 4;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 8;
  mediumDoors = mediumDoors + 4;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let largeBaseRightV9 = (e) => {
  largeBaseRightV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 4;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  roundHandles = roundHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1; 
}

let smallBaseV10 = (e) => {
  smallBaseV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;

}

let smallBaseUpV10 = (e) => {
  smallBaseUpV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallUpSideV10 = (e) => {
  smallUpSideV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallBaseRightV10 = (e) => {
  smallBaseRightV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseV10 = (e) => {
  mediumBaseV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseUpV10 = (e) => {
  mediumBaseUpV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumUpSideV10 = (e) => {
  mediumUpSideV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseRightV10 = (e) => {
  mediumBaseRightV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let largeBaseV10 = (e) => {
  largeBaseV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  leftSidePlates = leftSidePlates + 4;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 8;
  mediumDoors = mediumDoors + 4;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let largeBaseRightV10 = (e) => {
  largeBaseRightV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 4;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  longHandles = longHandles + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallBaseV11 = (e) => {
  smallBaseV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallBaseUpV11 = (e) => {
  smallBaseUpV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  leftSidePlates = leftSidePlates + 1;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 2;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallUpSideV11 = (e) => {
  smallUpSideV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let smallBaseRightV11 = (e) => {
  smallBaseRightV1();
  backplates = backplates + 2;
  backPlateTopCover = backPlateTopCover - 2;
  rightSidePlates = rightSidePlates + 1;
  plateLateralTopCover = plateLateralTopCover - 1;
  smallDoors =  smallDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseV11 = (e) => {
  mediumBaseV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseUpV11 = (e) => {
  mediumBaseUpV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  leftSidePlates = leftSidePlates + 2;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumUpSideV11 = (e) => {
  mediumUpSideV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let mediumBaseRightV11 = (e) => {
  mediumBaseRightV1();
  backplates = backplates + 4;
  backPlateTopCover = backPlateTopCover - 4;
  rightSidePlates = rightSidePlates + 2; 
  plateLateralTopCover = plateLateralTopCover - 2;
  mediumDoors = mediumDoors + 2;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let largeBaseV11 = (e) => {
  largeBaseV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  leftSidePlates = leftSidePlates + 4;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 8;
  mediumDoors = mediumDoors + 4;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

let largeBaseRightV11 = (e) => {
  largeBaseRightV1();
  backplates = backplates + 8;
  backPlateTopCover = backPlateTopCover - 8;
  rightSidePlates = rightSidePlates + 4;
  plateLateralTopCover = plateLateralTopCover - 4;
  mediumDoors = mediumDoors + 4;
  knob2TopDoorPin = knob2TopDoorPin - 2;
  doorTopCover = doorTopCover + 2;
  doorBottomCover = doorBottomCover + 2;
  screws = screws + 2;
  doorStopper = doorStopper + 1;
}

//UPCOME

const calculatePriceAndWeight = (e)=> {
  let currentPrice = 0;
  let currentWeight = 0;

  topPlates = 0;
  bottomPlates = 0;
  columns = 0;
  sideConnector1 = 0;
  plateLateralTopCover = 0;
  backPlateTopCover = 0;
  foot = 0;
  knowb1TopColCover = 0;
  knob2TopDoorPin = 0;
  rubberPads = 0;
  backplates = 0;
  leftSidePlates = 0;
  rightSidePlates = 0;
  smallDoors = 0;
  
  doorTopCover = 0;
  doorBottomCover = 0;
  roundHandles = 0;
  screws = 0;
  doorStopper = 0;
  longHandles = 0;
  mediumDoors = 0;
  middleSeperators = 0;

  let nextM1 = false;
  let nextM2 = false;
  let nextM3 = false;
  let nextM4 = false;
  let nextM5 = false;
  let nextM6 = false;
  let nextM7 = false;
  let nextM8 = false;
  let nextM9 = false;
  let nextM10 = false;
  let nextM11 = false;
  let nextM12 = false;
  let nextM13 = false;
  let nextM14 = false;

  let nextL1 = false;
  let nextL2 = false;
  let nextL3 = false;
  let nextL4 = false;
  let nextL5 = false;
  let nextL6 = false;
  let nextL7 = false;
  let nextL8 = false;
  let nextL9 = false;
  let nextL10 = false;
  let nextL11 = false;
  let nextL12 = false;
  let nextL13 = false;
  let nextL14 = false;

  if (col01Row01 === true) {
    if (c1R1VNo === 1) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_V1;
        smallBaseV1();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 1;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V1;
        mediumBaseV1();
        nextM1 = true;
        RCol1Row1 = 11;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_V1;
        largeBaseV1();
        nextL1 = true;
        RCol1Row1 = 21;
      }
    } else if (c1R1VNo === 2) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_V2;
        smallBaseV2();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 2;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V2;
        mediumBaseV2();
        nextM1 = true;
        RCol1Row1 = 12;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_V2;
        largeBaseV2();
        nextL1 = true;
        RCol1Row1 = 22;
      }
    } else if (c1R1VNo === 3) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_V3;
        smallBaseV3();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 3;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V3;
        mediumBaseV3();
        nextM1 = true;
        RCol1Row1 = 13;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_V3;
        largeBaseV3();
        nextL1 = true;
        RCol1Row1 = 23;
      }
    } else if (c1R1VNo === 4) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_V4;
        smallBaseV4();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 4;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V4;
        mediumBaseV4();
        nextM1 = true;
        RCol1Row1 = 14;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_V4;
        largeBaseV4();
        nextL1 = true;
        RCol1Row1 = 24;
      }
    } else if (c1R1VNo === 5) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_V5;
        smallBaseV5();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 5;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V5;
        mediumBaseV5();
        nextM1 = true;
        RCol1Row1 = 15;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_V5;
        largeBaseV5();
        nextL1 = true;
        RCol1Row1 = 25;
      }
    } else if (c1R1VNo === 6) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_V6;
        smallBaseV6();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 6;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V6;
        mediumBaseV6();
        nextM1 = true;
        RCol1Row1 = 16;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_V6;
        largeBaseV6();
        nextL1 = true;
        RCol1Row1 = 26;
      }
    } else if (c1R1VNo === 7) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_V7;
        smallBaseV7();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 7;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V7;
        mediumBaseV7();
        nextM1 = true;
        RCol1Row1 = 17;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_V7;
        largeBaseV7();
        nextL1 = true;
        RCol1Row1 = 27;
      }
    } else if (c1R1VNo === 8) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_V8;
        smallBaseV8();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 8;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V8;
        mediumBaseV8();
        nextM1 = true;
        RCol1Row1 = 18;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_V8;
        largeBaseV8();
        nextL1 = true;
        RCol1Row1 = 28;
      }
    } else if (c1R1VNo === 9) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_V9;
        smallBaseV9();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 9;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V9;
        mediumBaseV9();
        nextM1 = true;
        RCol1Row1 = 19;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_V9;
        largeBaseV9();
        nextL1 = true;
        RCol1Row1 = 29;
      }
    } else if (c1R1VNo === 10) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_V10;
        smallBaseV10();
        nextM1 = false;
        nextL1 = false;
        RCol1Row1 = 10;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V10;
        mediumBaseV10();
        nextM1 = true;
        RCol1Row1 = 20;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_V10;
        largeBaseV10();
        nextL1 = true;
        RCol1Row1 = 30;
      }
    } else if (c1R1VNo === 11) {
      if (c1r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_V11;
        smallBaseV11();
        nextM1 = false;
        nextL1 = false;
      } else if (c1r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_V11;
        mediumBaseV11();
        nextM1 = true;
        
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_V11;
        largeBaseV11();
        nextL1 = true;
      }
    }
  }
  if (col02Row01 === true) {
    if (c2R1VNo === 1) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 1;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM2 = true;
        RCol2Row1 = 11;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL2 = true;
        RCol2Row1 = 21;
      }
    } else if (c2R1VNo === 2) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 2;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM2 = true;
        RCol2Row1 = 12;
      } else if (c1r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL2 = true;
        RCol2Row1 = 22;
      }
    } else if (c2R1VNo === 3) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 3;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM2 = true;
        RCol2Row1 = 13;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL2 = true;
        RCol2Row1 = 23;
      }
    } else if (c2R1VNo === 4) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 4;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM2 = true;
        RCol2Row1 = 14;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL2 = true;
        RCol2Row1 = 24;
      }
    } else if (c2R1VNo === 5) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 5;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM2 = true;
        RCol2Row1 = 15;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL2 = true;
        RCol2Row1 = 25;
      }
    } else if (c2R1VNo === 6) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 6;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM2 = true;
        RCol2Row1 = 16;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL2 = true;
        RCol2Row1 = 26;
      }
    } else if (c2R1VNo === 7) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 7;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM2 = true;
        RCol2Row1 = 17;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL2 = true;
        RCol2Row1 = 27;
      }
    } else if (c2R1VNo === 8) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 8;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM2 = true;
        RCol2Row1 = 18;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL2 = true;
        RCol2Row1 = 28;
      }
    } else if (c2R1VNo === 9) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 9;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM2 = true;
        RCol2Row1 = 19;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL2 = true;
        RCol2Row1 = 29;
      }
    } else if (c2R1VNo === 10) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM2 = false;
        nextL2 = false;
        RCol2Row1 = 10;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM2 = true;
        RCol2Row1 = 20;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL2 = true;
        RCol2Row1 = 30;
      }
    } else if (c2R1VNo === 11) {
      if (c2r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM2 = false;
        nextL2 = false;
      } else if (c2r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM2 = true;
      } else if (c2r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL2 = true;
      }
    }
  }

  if (col03Row01 === true) {
    if (c3R1VNo === 1) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 1;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM3 = true;
        RCol3Row1 = 11;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL3 = true;
        RCol3Row1 = 21;
      }
    } else if (c3R1VNo === 2) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 2;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM3 = true;
        RCol3Row1 = 12;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL3 = true;
        RCol3Row1 = 22;
      }
    } else if (c3R1VNo === 3) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 3;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM3 = true;
        RCol3Row1 = 13;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL3 = true;
        RCol3Row1 = 23;
      }
    } else if (c3R1VNo === 4) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 4;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM3 = true;
        RCol3Row1 = 14;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL3 = true;
        RCol3Row1 = 24;
      }
    } else if (c3R1VNo === 5) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 5;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM3 = true;
        RCol3Row1 = 15;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL3 = true;
        RCol3Row1 = 25;
      }
    } else if (c3R1VNo === 6) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 6;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM3 = true;
        RCol3Row1 = 16;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL3 = true;
        RCol3Row1 = 26;
      }
    } else if (c3R1VNo === 7) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 7;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM3 = true;
        RCol3Row1 = 17;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL3 = true;
        RCol3Row1 = 27;
      }
    } else if (c3R1VNo === 8) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 8;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM3 = true;
        RCol3Row1 = 18;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL3 = true;
        RCol3Row1 = 28;
      }
    } else if (c3R1VNo === 9) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 9;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM3 = true;
        RCol3Row1 = 19;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL3 = true;
        RCol3Row1 = 29;
      }
    } else if (c3R1VNo === 10) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM3 = false;
        nextL3 = false;
        RCol3Row1 = 10;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM3 = true;
        RCol3Row1 = 20;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL3 = true;
        RCol3Row1 = 30;
      }
    } else if (c3R1VNo === 11) {
      if (c3r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM3 = false;
        nextL3 = false;
      } else if (c3r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM3 = true;
      } else if (c3r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL3 = true;
      }
    }
  }
  if (col04Row01 === true) {
    if (c4R1VNo === 1) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 1;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM4 = true;
        RCol4Row1 = 11;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL4 = true;
        RCol4Row1 = 21;
      }
    } else if (c4R1VNo === 2) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 2;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM4 = true;
        RCol4Row1 = 12;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL4 = true;
        RCol4Row1 = 22;
      }
    } else if (c4R1VNo === 3) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 3;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM4 = true;
        RCol4Row1 = 13;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL4 = true;
        RCol4Row1 = 23;
      }
    } else if (c4R1VNo === 4) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 4;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM4 = true;
        RCol4Row1 = 14;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL4 = true;
        RCol4Row1 = 24;
      }
    } else if (c4R1VNo === 5) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 5;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM4 = true;
        RCol4Row1 = 15;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL4 = true;
        RCol4Row1 = 25;
      }
    } else if (c4R1VNo === 6) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 6;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM4 = true;
        RCol4Row1 = 16;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL4 = true;
        RCol4Row1 = 26;
      }
    } else if (c4R1VNo === 7) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 7;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM4 = true;
        RCol4Row1 = 17;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL4 = true;
        RCol4Row1 = 27;
      }
    } else if (c4R1VNo === 8) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 8;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM4 = true;
        RCol4Row1 = 18;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL4 = true;
        RCol4Row1 = 28;
      }
    } else if (c4R1VNo === 9) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 9;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM4 = true;
        RCol4Row1 = 19;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL4 = true;
        RCol4Row1 = 29;
      }
    } else if (c4R1VNo === 10) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM4 = false;
        nextL4 = false;
        RCol4Row1 = 10;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM3 = true;
        RCol4Row1 = 20;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL4 = true;
        RCol4Row1 = 30;
      }
    } else if (c4R1VNo === 11) {
      if (c4r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM4 = false;
        nextL4 = false;
      } else if (c4r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c4r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL4 = true;
      }
    }
  }

  if (col05Row01 === true) {
    if (c5R1VNo === 1) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 1;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM5 = true;
        RCol5Row1 = 11;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL5 = true;
        RCol5Row1 = 21;
      }
    } else if (c5R1VNo === 2) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 2;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM5 = true;
        RCol5Row1 = 12;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL5 = true;
        RCol5Row1 = 22;
      }
    } else if (c5R1VNo === 3) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 3;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM5 = true;
        RCol5Row1 = 13;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL5 = true;
        RCol5Row1 = 23;
      }
    } else if (c5R1VNo === 4) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 4;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM5 = true;
        RCol5Row1 = 14;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL5 = true;
        RCol5Row1 = 24;
      }
    } else if (c5R1VNo === 5) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 5;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM5 = true;
        RCol5Row1 = 15;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL5 = true;
        RCol5Row1 = 25;
      }
    } else if (c5R1VNo === 6) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 6;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM5 = true;
        RCol5Row1 = 16;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL5 = true;
        RCol5Row1 = 26;
      }
    } else if (c5R1VNo === 7) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 7;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM5 = true;
        RCol5Row1 = 17;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL5 = true;
        RCol5Row1 = 27;
      }
    } else if (c5R1VNo === 8) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 8;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM5 = true;
        RCol5Row1 = 18;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL5 = true;
        RCol5Row1 = 28;
      }
    } else if (c5R1VNo === 9) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 9;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM5 = true;
        RCol5Row1 = 19;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL5 = true;
        RCol5Row1 = 29;
      }
    } else if (c5R1VNo === 10) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM5 = false;
        nextL5 = false;
        RCol5Row1 = 10;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM5 = true;
        RCol5Row1 = 20;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL5 = true;
        RCol5Row1 = 30;
      }
    } else if (c5R1VNo === 11) {
      if (c5r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM5 = false;
        nextL5 = false;
      } else if (c5r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c5r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL5 = true;
      }
    }
  }

  if (col06Row01 === true) {
    if (c6R1VNo === 1) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 1;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM6 = true;
        RCol6Row1 = 11;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL6 = true;
        RCol6Row1 = 21;
      }
    } else if (c6R1VNo === 2) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 2;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM6 = true;
        RCol6Row1 = 12;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL6 = true;
        RCol6Row1 = 22;
      }
    } else if (c6R1VNo === 3) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 3;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM6 = true;
        RCol6Row1 = 13;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL6 = true;
        RCol6Row1 = 23;
      }
    } else if (c6R1VNo === 4) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 4;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM6 = true;
        RCol6Row1 = 14;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL6 = true;
        RCol6Row1 = 24;
      }
    } else if (c6R1VNo === 5) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 5;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM6 = true;
        RCol6Row1 = 15;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL6 = true;
        RCol6Row1 = 25;
      }
    } else if (c6R1VNo === 6) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 6;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM6 = true;
        RCol6Row1 = 16;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL6 = true;
        RCol6Row1 = 26;
      }
    } else if (c6R1VNo === 7) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 7;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM6 = true;
        RCol6Row1 = 17;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL6 = true;
        RCol6Row1 = 27;
      }
    } else if (c6R1VNo === 8) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 8;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM6 = true;
        RCol6Row1 = 18;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL6 = true;
        RCol6Row1 = 28;
      }
    } else if (c6R1VNo === 9) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 9;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM6 = true;
        RCol6Row1 = 19;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL6 = true;
        RCol6Row1 = 29;
      }
    } else if (c6R1VNo === 10) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM6 = false;
        nextL6 = false;
        RCol6Row1 = 10;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM6 = true;
        RCol6Row1 = 20;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL6 = true;
        RCol6Row1 = 30;
      }
    } else if (c6R1VNo === 11) {
      if (c6r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM6 = false;
        nextL6 = false;
      } else if (c6r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c6r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL6 = true;
      }
    }
  }

  if (col07Row01 === true) {
    if (c7R1VNo === 1) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 1;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM7 = true;
        RCol7Row1 = 11;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL7 = true;
        RCol7Row1 = 21;
      }
    } else if (c7R1VNo === 2) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 2;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM7 = true;
        RCol7Row1 = 12;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL7 = true;
        RCol7Row1 = 22;
      }
    } else if (c7R1VNo === 3) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 3;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM7 = true;
        RCol7Row1 = 13;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL7 = true;
        RCol7Row1 = 23;
      }
    } else if (c7R1VNo === 4) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 4;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM7 = true;
        RCol7Row1 = 14;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL7 = true;
      }
    } else if (c7R1VNo === 5) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 5;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM7 = true;
        RCol7Row1 = 15;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL7 = true;
        RCol7Row1 = 25;
      }
    } else if (c7R1VNo === 6) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 6;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM7 = true;
        RCol7Row1 = 16;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL7 = true;
        RCol7Row1 = 26;
      }
    } else if (c7R1VNo === 7) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 7;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM7 = true;
        RCol7Row1 = 17;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL7 = true;
        RCol7Row1 = 27;
      }
    } else if (c7R1VNo === 8) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 8;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM7 = true;
        RCol7Row1 = 18;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL7 = true;
        RCol7Row1 = 28;
      }
    } else if (c7R1VNo === 9) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 9;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM7 = true;
        RCol7Row1 = 19;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL7 = true;
        RCol7Row1 = 29;
      }
    } else if (c7R1VNo === 10) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM7 = false;
        nextL7 = false;
        RCol7Row1 = 10;
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM7 = true;
        RCol7Row1 = 20;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL7 = true;
        RCol7Row1 = 30;
      }
    } else if (c7R1VNo === 11) {
      if (c7r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM7 = false;
        nextL7 = false;
        
      } else if (c7r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c7r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL7 = true;
      }
    }
  }

  if (col08Row01 === true) {
    if (c8R1VNo === 1) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 1;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM8 = true;
        RCol8Row1 = 11;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL8 = true;
        RCol8Row1 = 21;
      }
    } else if (c8R1VNo === 2) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 2;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM8 = true;
        RCol8Row1 = 12;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL8 = true;
        RCol8Row1 = 22;
      }
    } else if (c8R1VNo === 3) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 3;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM8 = true;
        RCol8Row1 = 13;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL8 = true;
        RCol8Row1 = 23;
      }
    } else if (c8R1VNo === 4) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 4;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM8 = true;
        RCol8Row1 = 14;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL8 = true;
        RCol8Row1 = 24;
      }
    } else if (c8R1VNo === 5) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 5;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM8 = true;
        RCol8Row1 = 15;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL8 = true;
        RCol8Row1 = 25;
      }
    } else if (c8R1VNo === 6) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 6;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM8 = true;
        RCol8Row1 = 16;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL8 = true;
        RCol8Row1 = 26;
      }
    } else if (c8R1VNo === 7) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 7;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM8 = true;
        RCol8Row1 = 17;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL8 = true;
        RCol8Row1 = 27;
      }
    } else if (c8R1VNo === 8) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 8;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM8 = true;
        RCol8Row1 = 18;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL8 = true;
        RCol8Row1 = 28;
      }
    } else if (c8R1VNo === 9) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 9;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM8 = true;
        RCol8Row1 = 19;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL8 = true;
        RCol8Row1 = 29;
      }
    } else if (c8R1VNo === 10) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM8 = false;
        nextL8 = false;
        RCol8Row1 = 10;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM8 = true;
        RCol8Row1 = 20;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL8 = true;
        RCol8Row1 = 30;
      }
    } else if (c8R1VNo === 11) {
      if (c8r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM8 = false;
        nextL8 = false;
      } else if (c8r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c8r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL8 = true;
      }
    }
  }

  if (col09Row01 === true) {
    if (c9R1VNo === 1) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 1;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM9 = true;
        RCol9Row1 = 11;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL9 = true;
        RCol9Row1 = 21;
      }
    } else if (c9R1VNo === 2) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 2;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM9 = true;
        RCol9Row1 = 12;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL9 = true;
        RCol9Row1 = 13;
      }
    } else if (c9R1VNo === 3) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 3;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM9 = true;
        RCol9Row1 = 13;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL9 = true;
        RCol9Row1 = 23;
      }
    } else if (c9R1VNo === 4) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 4;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM9 = true;
        RCol9Row1 = 14;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL9 = true;
        RCol9Row1 = 24;
      }
    } else if (c9R1VNo === 5) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 5;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM9 = true;
        RCol9Row1 = 15;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL9 = true;
        RCol9Row1 = 25;
      }
    } else if (c9R1VNo === 6) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 6;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM9 = true;
        RCol9Row1 = 16;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL9 = true;
        RCol9Row1 = 26;
      }
    } else if (c9R1VNo === 7) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 7;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM9 = true;
        RCol9Row1 = 17;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL9 = true;
        RCol9Row1 = 27;
      }
    } else if (c9R1VNo === 8) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 8;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM9 = true;
        RCol9Row1 = 18;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL9 = true;
        RCol9Row1 = 28;
      }
    } else if (c9R1VNo === 9) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 9;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM9 = true;
        RCol9Row1 = 19;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL9 = true;
        RCol9Row1 = 29;
      }
    } else if (c9R1VNo === 10) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM9 = false;
        nextL9 = false;
        RCol9Row1 = 10;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM9 = true;
        RCol9Row1 = 20;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL9 = true;
        RCol9Row1 = 30;
      }
    } else if (c9R1VNo === 11) {
      if (c9r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM9 = false;
        nextL9 = false;
      } else if (c9r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c9r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL9 = true;
      }
    }
  }
  if (col10Row01 === true) {
    if (c10R1VNo === 1) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 1;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM10 = true;
        RCol10Row1 = 11;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL10 = true;
        RCol10Row1 = 21;
      }
    } else if (c10R1VNo === 2) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 2;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM10 = true;
        RCol10Row1 = 12;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL10 = true;
        RCol10Row1 = 22;
      }
    } else if (c10R1VNo === 3) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 3;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        mediumBaseRightV3();
        nextM10 = true;
        RCol10Row1 = 13;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL10 = true;
        RCol10Row1 = 23;
      }
    } else if (c10R1VNo === 4) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 4;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM10 = true;
        RCol10Row1 = 14;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL10 = true;
        RCol10Row1 = 24;
      }
    } else if (c10R1VNo === 5) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 5;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM10 = true;
        RCol10Row1 = 15;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL10 = true;
        RCol10Row1 = 25;
      }
    } else if (c10R1VNo === 6) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 6;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM10 = true;
        RCol10Row1 = 16;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL10 = true;
        RCol10Row1 = 26;
      }
    } else if (c10R1VNo === 7) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 7;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM10 = true;
        RCol10Row1 = 17;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL10 = true;
        RCol10Row1 = 27;
      }
    } else if (c10R1VNo === 8) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 8;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM10 = true;
        RCol10Row1 = 18;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL10 = true;
        RCol10Row1 = 28;
      }
    } else if (c10R1VNo === 9) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 9;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM10 = true;
        RCol10Row1 = 19;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL10 = true;
        RCol10Row1 = 29;
      }
    } else if (c10R1VNo === 10) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM10 = false;
        nextL10 = false;
        RCol10Row1 = 10;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM10 = true;
        RCol10Row1 = 20;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL10 = true;
        RCol10Row1 = 30;
      }
    } else if (c10R1VNo === 11) {
      if (c10r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM10 = false;
        nextL10 = false;
      } else if (c10r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c10r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL10 = true;
      }
    }
  }

  if (col11Row01 === true) {
    if (c11R1VNo === 1) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 1;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM11 = true;
        RCol11Row1 = 11;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL11 = true;
        RCol11Row1 = 21;
      }
    } else if (c11R1VNo === 2) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 2;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM11 = true;
        RCol11Row1 = 12;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL11 = true;
        RCol11Row1 = 21;
      }
    } else if (c11R1VNo === 3) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 3;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM11 = true;
        RCol11Row1 = 13;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL11 = true;
        RCol11Row1 = 23;
      }
    } else if (c11R1VNo === 4) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 4;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM11 = true;
        RCol11Row1 = 14;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL11 = true;
        RCol11Row1 = 24;
      }
    } else if (c11R1VNo === 5) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 5;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM11 = true;
        RCol11Row1 = 15;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL11 = true;
        RCol11Row1 = 25;
      }
    } else if (c11R1VNo === 6) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 6;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM11 = true;
        RCol11Row1 = 16;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL11 = true;
        RCol11Row1 = 26;
      }
    } else if (c11R1VNo === 7) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 7;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM11 = true;
        RCol11Row1 = 17;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL11 = true;
        RCol11Row1 = 27;
      }
    } else if (c11R1VNo === 8) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 8;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM11 = true;
        RCol11Row1 = 18;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL11 = true;
        RCol11Row1 = 28;
      }
    } else if (c11R1VNo === 9) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 9;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM11 = true;
        RCol11Row1 = 19;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL11 = true;
        RCol11Row1 = 29;
      }
    } else if (c11R1VNo === 10) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM11 = false;
        nextL11 = false;
        RCol11Row1 = 10;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM11 = true;
        RCol11Row1 = 20;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL11 = true;
        RCol11Row1 = 30;
      }
    } else if (c11R1VNo === 11) {
      if (c11r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM11 = false;
        nextL11 = false;
      } else if (c11r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c11r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL11 = true;
      }
    }
  }

  if (col12Row01 === true) {
    if (c12R1VNo === 1) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 1;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM12 = true;
        RCol12Row1 = 11;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL12 = true;
        RCol12Row1 = 21;
      }
    } else if (c12R1VNo === 2) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 2;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM12 = true;
        RCol12Row1 = 12;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL12 = true;
        RCol12Row1 = 22;
      }
    } else if (c12R1VNo === 3) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 3;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM12 = true;
        RCol12Row1 = 13;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL12 = true;
        RCol12Row1 = 23;
      }
    } else if (c12R1VNo === 4) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 4;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM12 = true;
        RCol12Row1 = 14;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL12 = true;
        RCol12Row1 = 24;
      }
    } else if (c12R1VNo === 5) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 5;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM12 = true;
        RCol12Row1 = 15;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL12 = true;
        RCol12Row1 = 25;
      }
    } else if (c12R1VNo === 6) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 6;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM12 = true;
        RCol12Row1 = 16;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL12 = true;
        RCol12Row1 = 26;
      }
    } else if (c12R1VNo === 7) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 7;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM12 = true;
        RCol12Row1 = 17;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL12 = true;
        RCol12Row1 = 27;
      }
    } else if (c12R1VNo === 8) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 8;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM12 = true;
        RCol12Row1 = 18;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL12 = true;
        RCol12Row1 = 28;
      }
    } else if (c12R1VNo === 9) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 9;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM12 = true;
        RCol12Row1 = 19;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL12 = true;
        RCol12Row1 = 29;
      }
    } else if (c12R1VNo === 10) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM12 = false;
        nextL12 = false;
        RCol12Row1 = 10;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM12 = true;
        RCol12Row1 = 20;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL12 = true;
        RCol12Row1 = 30;
      }
    } else if (c12R1VNo === 11) {
      if (c12r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM12 = false;
        nextL12 = false;
      } else if (c12r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c12r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL12 = true;
      }
    }
  }

  if (col13Row01 === true) {
    if (c13R1VNo === 1) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 1;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM13 = true;
        RCol13Row1 = 11;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL13 = true;
        RCol13Row1 = 21;
      }
    } else if (c13R1VNo === 2) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 2;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM13 = true;
        RCol13Row1 = 12;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL13 = true;
        RCol13Row1 = 22;
      }
    } else if (c13R1VNo === 3) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 3;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM13 = true;
        RCol13Row1 = 13;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL13 = true;
        RCol13Row1 = 23;
      }
    } else if (c13R1VNo === 4) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 4;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM13 = true;
        RCol13Row1 = 14;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL13 = true;
        RCol13Row1 = 24;
      }
    } else if (c13R1VNo === 5) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 5;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM13 = true;
        RCol13Row1 = 15;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL13 = true;
        RCol13Row1 = 25;
      }
    } else if (c13R1VNo === 6) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 6;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM13 = true;
        RCol13Row1 = 16;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL13 = true;
        RCol13Row1 = 26;
      }
    } else if (c13R1VNo === 7) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 7;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM13 = true;
        RCol13Row1 = 17;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL13 = true;
        RCol13Row1 = 27;
      }
    } else if (c13R1VNo === 8) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 8;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM13 = true;
        RCol13Row1 = 18;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL13 = true;
        RCol13Row1 = 28;
      }
    } else if (c13R1VNo === 9) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 9;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM13 = true;
        RCol13Row1 = 19;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        largeBaseRightV9();
        nextL13 = true;
        RCol13Row1 = 29;
      }
    } else if (c13R1VNo === 10) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM13 = false;
        nextL13 = false;
        RCol13Row1 = 10;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM13 = true;
        RCol13Row1 = 20;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL13 = true;
        RCol13Row1 = 30;
      }
    } else if (c13R1VNo === 11) {
      if (c13r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM13 = false;
        nextL13 = false;
      } else if (c13r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c13r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL13 = true;
      }
    }
  }

  if (col14Row01 === true) {
    if (c14R1VNo === 1) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V1;
        smallBaseRightV1();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 1;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V1;
        mediumBaseRightV1();
        nextM14 = true;
        RCol14Row1 = 11;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V1;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V1;
        largeBaseRightV1();
        nextL14 = true;
        RCol14Row1 = 21;
      }
    } else if (c14R1VNo === 2) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V2;
        smallBaseRightV2();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 2;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V2;
        mediumBaseRightV2();
        nextM14 = true;
        RCol14Row1 = 12;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V2;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V2;
        largeBaseRightV2();
        nextL14 = true;
        RCol14Row1 = 22;
      }
    } else if (c14R1VNo === 3) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 3;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V3;
        smallBaseRightV3();
        nextM14 = true;
        RCol14Row1 = 13;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V3;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V3;
        largeBaseRightV3();
        nextL14 = true;
        RCol14Row1 = 23;
      }
    } else if (c14R1VNo === 4) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V4;
        smallBaseRightV4();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 4;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V4;
        mediumBaseRightV4();
        nextM14 = true;
        RCol14Row1 = 14;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V4;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V4;
        largeBaseRightV4();
        nextL14 = true;
        RCol14Row1 = 24;
      }
    } else if (c14R1VNo === 5) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V5;
        smallBaseRightV5();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 5;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V5;
        mediumBaseRightV5();
        nextM14 = true;
        RCol14Row1 = 15;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V5;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V5;
        largeBaseRightV5();
        nextL14 = true;
        RCol14Row1 = 25;
      }
    } else if (c14R1VNo === 6) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V6;
        smallBaseRightV6();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 6;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V6;
        mediumBaseRightV6();
        nextM14 = true;
        RCol14Row1 = 16;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V6;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V6;
        largeBaseRightV6();
        nextL14 = true;
        RCol14Row1 = 26;
      }
    } else if (c14R1VNo === 7) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V7;
        smallBaseRightV7();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 7;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V7;
        mediumBaseRightV7();
        nextM14 = true;
        RCol14Row1 = 17;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V7;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V7;
        largeBaseRightV7();
        nextL14 = true;
        RCol14Row1 = 27;
      }
    } else if (c14R1VNo === 8) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V8;
        smallBaseRightV8();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 8;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V8;
        mediumBaseRightV8();
        nextM14 = true;
        RCol14Row1 = 18;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V8;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V8;
        largeBaseRightV8();
        nextL14 = true;
        RCol14Row1 = 28;
      }
    } else if (c14R1VNo === 9) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V9;
        smallBaseRightV9();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 9;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V9;
        mediumBaseRightV9();
        nextM14 = true;
        RCol14Row1 = 19;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V9;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V9;
        nextL14 = true;
        RCol14Row1 = 29;
      }
    } else if (c14R1VNo === 10) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V10;
        smallBaseRightV10();
        nextM14 = false;
        nextL14 = false;
        RCol14Row1 = 10;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V10;
        mediumBaseRightV10();
        nextM14 = true;
        RCol14Row1 = 20;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V10;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V10;
        largeBaseRightV10();
        nextL14 = true;
        RCol14Row1 = 30;
      }
    } else if (c14R1VNo === 11) {
      if (c14r1MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_BASE_RIGHT_V11;
        smallBaseRightV11();
        nextM14 = false;
        nextL14 = false;
      } else if (c14r1MT === "M") {
        currentPrice = currentPrice + PRICE_MEDIUM_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_MEDIUM_BASE_RIGHT_V11;
        mediumBaseRightV11();
        nextM4 = true;
      } else if (c14r1MT === "L") {
        currentPrice = currentPrice + PRICE_LARGE_BASE_RIGHT_V11;
        currentWeight = currentWeight + WEIGHT_LARGE_BASE_RIGHT_V11;
        largeBaseRightV11();
        nextL14 = true;
      }
    }
  }
// DOWNCOME
  if (col01Row02 === true) {
    if (c1R2VNo === 1) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
        smallBaseUpV1();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 1;
      } else if (c1r2MT === "M") {
	if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 11;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  nextM1 = true;
    RCol1Row2 = 11;
	}
        
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 21;
      }
    } else if (c1R2VNo === 2) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
        smallBaseUpV2();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 2;
      } else if (c1r2MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 12;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  nextM1 = true;
    RCol1Row2 = 12;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 22;
      }
    } else if (c1R2VNo === 3) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
        smallBaseUpV3();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 3;
      } else if (c1r2MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 13;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  nextM1 = true;
    RCol1Row2 = 13;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 23;
      }
    } else if (c1R2VNo === 4) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
        smallBaseUpV4();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 4;
      } else if (c1r2MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 14;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  nextM1 = true;
    RCol1Row2 = 14;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 24;
      }
    } else if (c1R2VNo === 5) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
        smallBaseUpV5();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 5;
      } else if (c1r2MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 15;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  nextM1 = true;
    RCol1Row2 = 15;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 25;
      }
    } else if (c1R2VNo === 6) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
        smallBaseUpV6();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 6;
      } else if (c1r2MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 16;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  nextM1 = true;
    RCol1Row2 = 16;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 26;
      }
    } else if (c1R2VNo === 7) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
        smallBaseUpV7();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 7;
      } else if (c1r2MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 17;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  nextM1 = true;
    RCol1Row2 = 17;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 27;
      }
    } else if (c1R2VNo === 8) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
        smallBaseUpV8();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 8;
      } else if (c1r2MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 18;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  nextM1 = true;
    RCol1Row2 = 18;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 28;
      }
    } else if (c1R2VNo === 9) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
        smallBaseUpV9();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 9;
      } else if (c1r2MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 19;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  nextM1 = true;
    RCol1Row2 = 19;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 29;
      }
    } else if (c1R2VNo === 10) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
        smallBaseUpV10();
        nextM1 = false;
        nextL1 = false;
        RCol1Row2 = 10;
      } else if (c1r2MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row2 = 20;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  nextM1 = true;
    RCol1Row2 = 20;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
        RCol1Row2 = 30;
      }
    } else if (c1R2VNo === 11) {
      if (c1r2MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
        smallBaseUpV11();
        nextM1 = false;
        nextL1 = false;
      } else if (c1r2MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  nextM1 = true;
	}
      } else if (c1r2MT === "L") {
        nextL1 = true;
      }
    }
  }

  if (col01Row03 === true) {
    if (c1R3VNo === 1) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
        smallBaseUpV1();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 1;
      } else if (c1r3MT === "M") {
	if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 11;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  nextM1 = true;
    RCol1Row3 = 11;
	}
        
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 21;
      }
    } else if (c1R3VNo === 2) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
        smallBaseUpV2();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 2;
      } else if (c1r3MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 12;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  nextM1 = true;
    RCol1Row3 = 12;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 22;
      }
    } else if (c1R3VNo === 3) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
        smallBaseUpV3();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 3;
      } else if (c1r3MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 13;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  nextM1 = true;
    RCol1Row3 = 13;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 23;
      }
    } else if (c1R3VNo === 4) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
        smallBaseUpV4();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 4;
      } else if (c1r3MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 14;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  nextM1 = true;
    RCol1Row3 = 14;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 24;
      }
    } else if (c1R3VNo === 5) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
        smallBaseUpV5();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 5;
      } else if (c1r3MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 15;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  nextM1 = true;
    RCol1Row3 = 15;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 25;
      }
    } else if (c1R3VNo === 6) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
        smallBaseUpV6();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 6;
      } else if (c1r3MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 16;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  nextM1 = true;
    RCol1Row3 = 16;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 26;
      }
    } else if (c1R3VNo === 7) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
        smallBaseUpV7();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 7;
      } else if (c1r3MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 17;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  nextM1 = true;
    RCol1Row3 = 17;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 27;
      }
    } else if (c1R3VNo === 8) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
        smallBaseUpV8();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 8;
      } else if (c1r3MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 18;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  nextM1 = true;
    RCol1Row3 = 18;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 28;
      }
    } else if (c1R3VNo === 9) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
        smallBaseUpV9();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 9;
      } else if (c1r3MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 19;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  nextM1 = true;
    RCol1Row3 = 19;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 29;
      }
    } else if (c1R3VNo === 10) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
        smallBaseUpV10();
        nextM1 = false;
        nextL1 = false;
        RCol1Row3 = 10;
      } else if (c1r3MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row3 = 20;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  nextM1 = true;
    RCol1Row3 = 20;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
        RCol1Row3 = 30;
      }
    } else if (c1R3VNo === 11) {
      if (c1r3MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
        smallBaseUpV11();
        nextM1 = false;
        nextL1 = false;
      } else if (c1r3MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  nextM1 = true;
	}
      } else if (c1r3MT === "L") {
        nextL1 = true;
      }
    }
  }

  if (col01Row04 === true) {
    if (c1R4VNo === 1) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
        smallBaseUpV1();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 1;
      } else if (c1r4MT === "M") {
	if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 11;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  nextM1 = true;
    RCol1Row4 = 11;
	}
        
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 21;
      }
    } else if (c1R4VNo === 2) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
        smallBaseUpV2();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 2;
      } else if (c1r4MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 12;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  nextM1 = true;
    RCol1Row4 = 12;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 22;
      }
    } else if (c1R4VNo === 3) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
        smallBaseUpV3();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 3;
      } else if (c1r4MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 13;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  nextM1 = true;
    RCol1Row4 = 13;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 23;
      }
    } else if (c1R4VNo === 4) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
        smallBaseUpV4();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 4;
      } else if (c1r4MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 14;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  nextM1 = true;
    RCol1Row4 = 14;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 24;
      }
    } else if (c1R4VNo === 5) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
        smallBaseUpV5();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 5;
      } else if (c1r4MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 15;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  nextM1 = true;
    RCol1Row4 = 15;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 25;
      }
    } else if (c1R4VNo === 6) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
        smallBaseUpV6();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 6;
      } else if (c1r4MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 16;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  nextM1 = true;
    RCol1Row4 = 16;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 26;
      }
    } else if (c1R4VNo === 7) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
        smallBaseUpV7();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 7;
      } else if (c1r4MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 17;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  nextM1 = true;
    RCol1Row4 = 17;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 27;
      }
    } else if (c1R4VNo === 8) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
        smallBaseUpV8();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 8;
      } else if (c1r4MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 18;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  nextM1 = true;
    RCol1Row4 = 18;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 28;
      }
    } else if (c1R4VNo === 9) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
        smallBaseUpV9();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 9;
      } else if (c1r4MT === "M") {
       if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 19;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  nextM1 = true;
    RCol1Row4 = 19;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 29;
      }
    } else if (c1R4VNo === 10) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
        smallBaseUpV10();
        nextM1 = false;
        nextL1 = false;
        RCol1Row4 = 10;
      } else if (c1r4MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
    RCol1Row4 = 20;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  nextM1 = true;
    RCol1Row4 = 20;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
        RCol1Row4 = 30;
      }
    } else if (c1R4VNo === 11) {
      if (c1r4MT === "S") { 
        currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
        smallBaseUpV11();
        nextM1 = false;
        nextL1 = false;
      } else if (c1r4MT === "M") {
        if (nextM1 === true) {
	  nextM1 = false;
        } else {
	  currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
          currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  nextM1 = true;
	}
      } else if (c1r4MT === "L") {
        nextL1 = true;
      }
    }
  }

  if (col02Row02 === true) {
    if (c2R2VNo === 1) {
      if (c2r2MT === "S") { 
          if (col01Row02 === true) {
                  currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
                  currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
                  smallUpSideV1();
                  RCol2Row2 = 1;
          } else {
            currentPrice = currentPrice + PRICE_SMALL_UP_V1;
                  currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
                  smallBaseUpV1();
                  RCol2Row2 = 1;
          }
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
        if (nextM2 === true) {
          nextM2 = false;
          RCol2Row2 = 11;
          } else {
              if (col01Row02 === true) {
                currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
                      currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
                      mediumUpSideV1();
              nextM2 = true;
              RCol2Row2 = 11;
              } else {
              currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
                    currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
                    mediumBaseUpV1();
                nextM2 = true;
                RCol2Row2 = 11;
              }
        }
        
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 21;
      }
    } else if (c2R2VNo === 2) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
                currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
                currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
                smallUpSideV2();
                RCol2Row2 = 2;
        } else {
          currentPrice = currentPrice + PRICE_SMALL_UP_V2;
                currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
                smallBaseUpV2();
                RCol2Row2 = 2;
        }
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
        if (nextM2 === true) {
        nextM2 = false;
        RCol2Row2 = 12;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
        nextM2 = true;
        RCol2Row2 = 12;
        } else {
        currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
              currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
              mediumBaseUpV2();
          nextM2 = true;
          RCol2Row2 = 12;
        }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 22;
      }
    } else if (c2R2VNo === 3) {
      if (c2r2MT === "S") { 
       if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol2Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol2Row2 = 3;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 13;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM2 = true;
    RCol2Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM2 = true;
      RCol2Row2 = 13;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 23;
      }
    } else if (c2R2VNo === 4) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol2Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol2Row2 = 4;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 14;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
            RCol2Row2 = 14;
		nextM2 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM2 = true;
      RCol2Row2 = 14;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 24;
      }
    } else if (c2R2VNo === 5) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol2Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol2Row2 = 5;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 15;
        } else {
	 if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM2 = true;
    RCol2Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM2 = true;
      RCol2Row2 = 15;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 25;
      }
    } else if (c2R2VNo === 6) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol2Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol2Row2 = 6;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 16;
        } else {
	 if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM2 = true;
    RCol2Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM2 = true;
      RCol2Row2 = 16;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 26;
      }
    } else if (c2R2VNo === 7) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol2Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol2Row2 = 7;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 17;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM2 = true;
    RCol2Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM2 = true;
      RCol2Row2 = 17;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 27;
      }
    } else if (c2R2VNo === 8) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol2Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol2Row2 = 8;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 18;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM2 = true;
    RCol2Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM2 = true;
      RCol2Row2 = 18;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 28;
      }
    } else if (c2R2VNo === 9) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol2Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol2Row2 = 9;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 19;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM2 = true;
    RCol2Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM2 = true;
      RCol2Row2 = 19;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 29;
      }
    } else if (c2R2VNo === 10) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol2Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol2Row2 = 10;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row2 = 20;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM2 = true;
    RCol2Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM2 = true;
      RCol2Row2 = 20;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
        RCol2Row2 = 30;
      }
    } else if (c2R2VNo === 11) {
      if (c2r2MT === "S") { 
        if (col01Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r2MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
        } else {
	  if (col01Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM2 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM2 = true;
	  }
	}
      } else if (c2r2MT === "L") {
        nextL2 = true;
      }
    }
  }

  if (col03Row02 === true) {
    if (c3R2VNo === 1) {
      if (c3r2MT === "S") { 
	 if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol3Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol3Row2 = 1;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
	if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 11;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM3 = true;
    RCol3Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM3 = true;
      RCol3Row2 = 11;
	  }
	}
        
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 21;
      }
    } else if (c3R2VNo === 2) {
      if (c3r2MT === "S") { 
	if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol3Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol3Row2 = 2;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 12;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM3 = true;
    RCol3Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM3 = true;
      RCol3Row2 = 12;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 22;
      }
    } else if (c3R2VNo === 3) {
      if (c3r2MT === "S") { 
       if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol3Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol3Row2 = 3;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 13;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM3 = true;
    RCol3Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM3 = true;
      RCol3Row2 = 13;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 23;
      }
    } else if (c3R2VNo === 4) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol3Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol3Row2 = 4;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 14;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM3 = true;
    RCol3Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM3 = true;
      RCol3Row2 = 14;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 24;
      }
    } else if (c3R2VNo === 5) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol3Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol3Row2 = 5;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 15;
        } else {
	 if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM3 = true;
    RCol3Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM3 = true;
      RCol3Row2 = 15;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 25;
      }
    } else if (c3R2VNo === 6) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol3Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol3Row2 = 6;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 16;
        } else {
	 if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM3 = true;
    RCol3Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM3 = true;
      RCol3Row2 = 16;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 26;
      }
    } else if (c3R2VNo === 7) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol3Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol3Row2 = 7;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 17;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM3 = true;
    RCol3Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM3 = true;
      RCol3Row2 = 17;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 27;
      }
    } else if (c3R2VNo === 8) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol3Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol3Row2 = 8;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 18;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM3 = true;
    RCol3Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM3 = true;
      RCol3Row2 = 18;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 28;
      }
    } else if (c3R2VNo === 9) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol3Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol3Row2 = 9;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 19;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM3 = true;
    RCol3Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM3 = true;
      RCol3Row2 = 19;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 29;
      }
    } else if (c3R2VNo === 10) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol3Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol3Row2 = 10;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row2 = 20;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM3 = true;
    RCol3Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM3 = true;
      RCol3Row2 = 20;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
        RCol3Row2 = 30;
      }
    } else if (c3R2VNo === 11) {
      if (c3r2MT === "S") { 
        if (col02Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r2MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
        } else {
	  if (col02Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM3 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM3 = true;
	  }
	}
      } else if (c3r2MT === "L") {
        nextL3 = true;
      }
    }
  }

  if (col04Row02 === true) {
    if (c4R2VNo === 1) {
      if (c4r2MT === "S") { 
	 if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol4Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol4Row2 = 1;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
	if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 11;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM4 = true;
    RCol4Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM4 = true;
      RCol4Row2 = 11;
	  }
	}
        
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 21;
      }
    } else if (c4R2VNo === 2) {
      if (c4r2MT === "S") { 
	if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol4Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol4Row2 = 2;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 12;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM4 = true;
    RCol4Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM4 = true;
      RCol4Row2 = 12;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 22;
      }
    } else if (c4R2VNo === 3) {
      if (c4r2MT === "S") { 
       if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol4Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol4Row2 = 3;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 13;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM4 = true;
    RCol4Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM4 = true;
      RCol4Row2 = 13;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 23;
      }
    } else if (c4R2VNo === 4) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol4Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol4Row2 = 4;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 14;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM4 = true;
    RCol4Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM4 = true;
      RCol4Row2 = 14;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 24;
      }
    } else if (c4R2VNo === 5) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol4Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol4Row2 = 5;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 15;
        } else {
	 if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM4 = true;
    RCol4Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM4 = true;
      RCol4Row2 = 15;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 25;
      }
    } else if (c4R2VNo === 6) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol4Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol4Row2 = 6;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 16;
        } else {
	 if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM4 = true;
    RCol4Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM4 = true;
      RCol4Row2 = 16;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 26;
      }
    } else if (c4R2VNo === 7) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol4Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol4Row2 = 7;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 17;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM4 = true;
    RCol4Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM4 = true;
      RCol4Row2 = 17;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 27;
      }
    } else if (c4R2VNo === 8) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol4Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol4Row2 = 8;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 18;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM4 = true;
    RCol4Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM4 = true;
      RCol4Row2 = 18;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 28;
      }
    } else if (c4R2VNo === 9) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol4Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol4Row2 = 9;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 19;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM4 = true;
    RCol4Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM4 = true;
      RCol4Row2 = 19;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 29;
      }
    } else if (c4R2VNo === 10) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol4Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol4Row2 = 10;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row2 = 20;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM4 = true;
    RCol4Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM4 = true;
      RCol4Row2 = 20;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
        RCol4Row2 = 30;
      }
    } else if (c4R2VNo === 11) {
      if (c4r2MT === "S") { 
        if (col03Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r2MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
        } else {
	  if (col03Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM4 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM4 = true;
	  }
	}
      } else if (c4r2MT === "L") {
        nextL4 = true;
      }
    }
  }

  if (col05Row02 === true) {
    if (c5R2VNo === 1) {
      if (c5r2MT === "S") { 
	 if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol5Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol5Row2 = 1;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
	if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 11;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM5 = true;
    RCol5Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM5 = true;
      RCol5Row2 = 11;
	  }
	}
        
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 21;
      }
    } else if (c5R2VNo === 2) {
      if (c5r2MT === "S") { 
	if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol5Row2 = 2;
	} else {
		      currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol5Row2 = 2;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 12;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM5 = true;
    RCol5Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM5 = true;
      RCol5Row2 = 12;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 22;
      }
    } else if (c5R2VNo === 3) {
      if (c5r2MT === "S") { 
       if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol5Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol5Row2 = 3;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 13;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM5 = true;
    RCol5Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM5 = true;
      RCol5Row2 = 13;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 23;
      }
    } else if (c5R2VNo === 4) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol5Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol5Row2 = 4;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 14;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM5 = true;
    RCol5Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM5 = true;
      RCol5Row2 = 14;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 24;
      }
    } else if (c5R2VNo === 5) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol5Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol5Row2 = 5;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 15;
        } else {
	 if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM5 = true;
    RCol5Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM5 = true;
      RCol5Row2 = 15;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 25;
      }
    } else if (c5R2VNo === 6) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol5Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol5Row2 = 6;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 16;
        } else {
	 if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM5 = true;
    RCol5Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM5 = true;
      RCol5Row2 = 16;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 26;
      }
    } else if (c5R2VNo === 7) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol5Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol5Row2 = 7;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 17;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM5 = true;
    RCol5Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM5 = true;
      RCol5Row2 = 17;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 27;
      }
    } else if (c5R2VNo === 8) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol5Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol5Row2 = 8;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 18;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM5 = true;
    RCol5Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM5 = true;
      RCol5Row2 = 18;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 28;
      }
    } else if (c5R2VNo === 9) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol5Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol5Row2 = 9;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 19;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM5 = true;
    RCol5Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM5 = true;
      RCol5Row2 = 19;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 29;
      }
    } else if (c5R2VNo === 10) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol5Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol5Row2 = 10;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row2 = 20;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM5 = true;
    RCol5Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM5 = true;
      RCol5Row2 = 20;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
        RCol5Row2 = 30;
      }
    } else if (c5R2VNo === 11) {
      if (c5r2MT === "S") { 
        if (col04Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r2MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
        } else {
	  if (col04Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM5 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM5 = true;
	  }
	}
      } else if (c5r2MT === "L") {
        nextL5 = true;
      }
    }
  }

  if (col06Row02 === true) {
    if (c6R2VNo === 1) {
      if (c6r2MT === "S") { 
	 if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol6Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol6Row2 = 1;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
	if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 11;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM6 = true;
    RCol6Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM6 = true;
      RCol6Row2 = 11;
	  }
	}
        
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 21;
      }
    } else if (c6R2VNo === 2) {
      if (c6r2MT === "S") { 
	if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol6Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol6Row2 = 2;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 12;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM6 = true;
    RCol6Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM6 = true;
      RCol6Row2 = 12;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 22;
      }
    } else if (c6R2VNo === 3) {
      if (c6r2MT === "S") { 
       if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol6Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol6Row2 = 3;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 13;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM6 = true;
    RCol6Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM6 = true;
      RCol6Row2 = 13;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 23;
      }
    } else if (c6R2VNo === 4) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol6Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol6Row2 = 4;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 14;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM6 = true;
    RCol6Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM6 = true;
      RCol6Row2 = 14;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 24;
      }
    } else if (c6R2VNo === 5) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol6Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol6Row2 = 5;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 15;
        } else {
	 if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM6 = true;
    RCol6Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM6 = true;
      RCol6Row2 = 15;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 25;
      }
    } else if (c6R2VNo === 6) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol6Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol6Row2 = 6;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 16;
        } else {
	 if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM6 = true;
    RCol6Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM6 = true;
      RCol6Row2 = 16;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 26;
      }
    } else if (c6R2VNo === 7) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol6Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol6Row2 = 7;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 17;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM6 = true;
    RCol6Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM6 = true;
      RCol6Row2 = 17;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 27;
      }
    } else if (c6R2VNo === 8) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol6Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol6Row2 = 8;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 18;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM6 = true;
    RCol6Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM6 = true;
      RCol6Row2 = 18;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 28;
      }
    } else if (c6R2VNo === 9) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol6Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol6Row2 = 9;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 19;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM6 = true;
    RCol6Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM6 = true;
      RCol6Row2 = 19;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 29;
      }
    } else if (c6R2VNo === 10) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol6Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol6Row2 = 10;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row2 = 20;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM6 = true;
    RCol6Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM6 = true;
      RCol6Row2 = 20;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
        RCol6Row2 = 30;
      }
    } else if (c6R2VNo === 11) {
      if (c6r2MT === "S") { 
        if (col05Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r2MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
        } else {
	  if (col05Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM6 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM6 = true;
	  }
	}
      } else if (c6r2MT === "L") {
        nextL6 = true;
      }
    }
  }

  if (col07Row02 === true) {
    if (c7R2VNo === 1) {
      if (c7r2MT === "S") { 
	 if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol7Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol7Row2 = 1;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
	if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 11;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM7 = true;
    RCol7Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM7 = true;
      RCol7Row2 = 11;
	  }
	}
        
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 21;
      }
    } else if (c7R2VNo === 2) {
      if (c7r2MT === "S") { 
	if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol7Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol7Row2 = 2;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 12;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM7 = true;
    RCol7Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM7 = true;
      RCol7Row2 = 12;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 22;
      }
    } else if (c7R2VNo === 3) {
      if (c7r2MT === "S") { 
       if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol7Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol7Row2 = 3;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 13;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM7 = true;
    RCol7Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM7 = true;
      RCol7Row2 = 13;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 23;
      }
    } else if (c7R2VNo === 4) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol7Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol7Row2 = 4;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 14;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM7 = true;
    RCol7Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM7 = true;
      RCol7Row2 = 14;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 24;
      }
    } else if (c7R2VNo === 5) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol7Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol7Row2 = 5;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 15;
        } else {
	 if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM7 = true;
    RCol7Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM7 = true;
      RCol7Row2 = 15;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 25;
      }
    } else if (c7R2VNo === 6) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol7Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol7Row2 = 6;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 16;
        } else {
	 if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM7 = true;
    RCol7Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM7 = true;
      RCol7Row2 = 16;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 26;
      }
    } else if (c7R2VNo === 7) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol7Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol7Row2 = 7;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 17;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM7 = true;
    RCol7Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM7 = true;
      RCol7Row2 = 17;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 27;
      }
    } else if (c7R2VNo === 8) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol7Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol7Row2 = 8;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 18;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM7 = true;
    RCol7Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM7 = true;
      RCol7Row2 = 18;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
      }
    } else if (c7R2VNo === 9) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol7Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol7Row2 = 9;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 19;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM7 = true;
    RCol7Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM7 = true;
      RCol7Row2 = 19;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 29;
      }
    } else if (c7R2VNo === 10) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol7Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol7Row2 = 10;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row2 = 20;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM7 = true;
    RCol7Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM7 = true;
      RCol7Row2 = 20;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
        RCol7Row2 = 30;
      }
    } else if (c7R2VNo === 11) {
      if (c7r2MT === "S") { 
        if (col06Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r2MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
        } else {
	  if (col06Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM7 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM7 = true;
	  }
	}
      } else if (c7r2MT === "L") {
        nextL7 = true;
      }
    }
  }

  if (col08Row02 === true) {
    if (c8R2VNo === 1) {
      if (c8r2MT === "S") { 
	 if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol8Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol8Row2 = 1;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
	if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 11;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM8 = true;
    RCol8Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM8 = true;
      RCol8Row2 = 11;
	  }
	}
        
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 21;
      }
    } else if (c8R2VNo === 2) {
      if (c8r2MT === "S") { 
	if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol8Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol8Row2 = 2;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 12;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM8 = true;
    RCol8Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM8 = true;
      RCol8Row2 = 12;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 22;
      }
    } else if (c8R2VNo === 3) {
      if (c8r2MT === "S") { 
       if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol8Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol8Row2 = 3;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 13;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM8 = true;
    RCol8Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM8 = true;
      RCol8Row2 = 13;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 23;
      }
    } else if (c8R2VNo === 4) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol8Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol8Row2 = 4;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 14;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM8 = true;
    RCol8Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM8 = true;
      RCol8Row2 = 14;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 24;
      }
    } else if (c8R2VNo === 5) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol8Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol8Row2 = 5;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 15;
        } else {
	 if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM8 = true;
    RCol8Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM8 = true;
      RCol8Row2 = 15;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 25;
      }
    } else if (c8R2VNo === 6) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol8Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol8Row2 = 6;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 16;
        } else {
	 if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM8 = true;
    RCol8Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM8 = true;
      RCol8Row2 = 16;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 26;
      }
    } else if (c8R2VNo === 7) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol8Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol8Row2 = 7;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 17;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM8 = true;
    RCol8Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM8 = true;
      RCol8Row2 = 17;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 27;
      }
    } else if (c8R2VNo === 8) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol8Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol8Row2 = 8;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 18;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM8 = true;
    RCol8Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM8 = true;
      RCol8Row2 = 18;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 28;
      }
    } else if (c8R2VNo === 9) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol8Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol8Row2 = 9;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 19;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM8 = true;
    RCol8Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM8 = true;
      RCol8Row2 = 19;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 29;
      }
    } else if (c8R2VNo === 10) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol8Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol8Row2 = 10;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row2 = 20;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM8 = true;
    RCol8Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM8 = true;
      RCol8Row2 = 20;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
        RCol8Row2 = 30;
      }
    } else if (c8R2VNo === 11) {
      if (c8r2MT === "S") { 
        if (col07Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r2MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
        } else {
	  if (col07Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM8 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM8 = true;
	  }
	}
      } else if (c8r2MT === "L") {
        nextL8 = true;
      }
    }
  }

  if (col09Row02 === true) {
    if (c9R2VNo === 1) {
      if (c9r2MT === "S") { 
	 if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol9Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol9Row2 = 1;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
	if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 11;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM9 = true;
    RCol9Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM9 = true;
      RCol9Row2 = 11;
	  }
	}
        
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 21;
      }
    } else if (c9R2VNo === 2) {
      if (c9r2MT === "S") { 
	if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol9Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol9Row2 = 2;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 12;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM9 = true;
    RCol9Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM9 = true;
      RCol9Row2 = 12;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 22;
      }
    } else if (c9R2VNo === 3) {
      if (c9r2MT === "S") { 
       if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol9Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol9Row2 = 3;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 13;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM9 = true;
    RCol9Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM9 = true;
      RCol9Row2 = 13;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 23;
      }
    } else if (c9R2VNo === 4) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol9Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol9Row2 = 4;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 14;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM9 = true;
    RCol9Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM9 = true;
      RCol9Row2 = 14;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 24;
      }
    } else if (c9R2VNo === 5) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol9Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol9Row2 = 5;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 15;
        } else {
	 if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM9 = true;
    RCol9Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM9 = true;
      RCol9Row2 = 15;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 25;
      }
    } else if (c9R2VNo === 6) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol9Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol9Row2 = 6;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 16;
        } else {
	 if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM9 = true;
    RCol9Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM9 = true;
      RCol9Row2 = 16;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 26;
      }
    } else if (c9R2VNo === 7) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol9Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol9Row2 = 7;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 17;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM9 = true;
    RCol9Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM9 = true;
      RCol9Row2 = 17;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 27;
      }
    } else if (c9R2VNo === 8) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol9Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol9Row2 = 8;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 18;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM9 = true;
    RCol9Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM9 = true;
      RCol9Row2 = 18;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 28;
      }
    } else if (c9R2VNo === 9) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol9Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol9Row2 = 9;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 19;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM9 = true;
    RCol9Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM9 = true;
      RCol9Row2 = 19;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 29;
      }
    } else if (c9R2VNo === 10) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol9Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol9Row2 = 10;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row2 = 20;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM9 = true;
    RCol9Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM9 = true;
      RCol9Row2 = 20;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
        RCol9Row2 = 30;
      }
    } else if (c9R2VNo === 11) {
      if (c9r2MT === "S") { 
        if (col08Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r2MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
        } else {
	  if (col08Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM9 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM9 = true;
	  }
	}
      } else if (c9r2MT === "L") {
        nextL9 = true;
      }
    }
  }

  if (col10Row02 === true) {
    if (c10R2VNo === 1) {
      if (c10r2MT === "S") { 
	 if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol10Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol10Row2 = 1;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
	if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 11;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM10 = true;
    RCol10Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM10 = true;
      RCol10Row2 = 11;
	  }
	}
        
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 21;
      }
    } else if (c10R2VNo === 2) {
      if (c10r2MT === "S") { 
	if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol10Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol10Row2 = 2;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 12;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM10 = true;
    RCol10Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM10 = true;
      RCol10Row2 = 12;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 22;
      }
    } else if (c10R2VNo === 3) {
      if (c10r2MT === "S") { 
       if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol10Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol10Row2 = 3;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 13;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM10 = true;
    RCol10Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM10 = true;
      RCol10Row2 = 13;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 23;
      }
    } else if (c10R2VNo === 4) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol10Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol10Row2 = 4;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 14;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM10 = true;
    RCol10Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM10 = true;
      RCol10Row2 = 14;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 24;
      }
    } else if (c10R2VNo === 5) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol10Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol10Row2 = 5;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 15;
        } else {
	 if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM10 = true;
    RCol10Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM10 = true;
      RCol10Row2 = 15;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 25;
      }
    } else if (c10R2VNo === 6) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol10Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol10Row2 = 6;
	}
  //NEXT-START - smallBaseUpV9()
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 16;
        } else {
	 if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM10 = true;
    RCol10Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM10 = true;
      RCol10Row2 = 16;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 26;
      }
    } else if (c10R2VNo === 7) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol10Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol10Row2 = 7;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 17;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM10 = true;
    RCol10Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM10 = true;
      RCol10Row2 = 17;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 27;
      }
    } else if (c10R2VNo === 8) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol10Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol10Row2 = 8;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 18;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM10 = true;
    RCol10Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM10 = true;
      RCol10Row2 = 18;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 28;
      }
    } else if (c10R2VNo === 9) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol10Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol10Row2 = 9;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 19;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM10 = true;
    RCol10Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM10 = true;
      RCol10Row2 = 19;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 29;
      }
    } else if (c10R2VNo === 10) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol10Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol10Row2 = 10;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row2 = 20;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM10 = true;
    RCol10Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM10 = true;
      RCol10Row2 = 20;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
        RCol10Row2 = 30;
      }
    } else if (c10R2VNo === 11) {
      if (c10r2MT === "S") { 
        if (col09Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r2MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
        } else {
	  if (col09Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM10 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM10 = true;
	  }
	}
      } else if (c10r2MT === "L") {
        nextL10 = true;
      }
    }
  }

  if (col11Row02 === true) {
    if (c11R2VNo === 1) {
      if (c11r2MT === "S") { 
	 if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol11Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol11Row2 = 1;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
	if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 11;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM11 = true;
    RCol11Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM11 = true;
      RCol11Row2 = 11;
	  }
	}
        
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 21;
      }
    } else if (c11R2VNo === 2) {
      if (c11r2MT === "S") { 
	if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol11Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol11Row2 = 2;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 12;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM11 = true;
    RCol11Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM11 = true;
      RCol11Row2 = 12;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 22;
      }
    } else if (c11R2VNo === 3) {
      if (c11r2MT === "S") { 
       if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol11Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol11Row2 = 3;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 13;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM11 = true;
    RCol11Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM11 = true;
      RCol11Row2 = 13;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 23;
      }
    } else if (c11R2VNo === 4) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol11Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol11Row2 = 4;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 14;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV1();
		nextM11 = true;
    RCol11Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM11 = true;
      RCol11Row2 = 14;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 24;
      }
    } else if (c11R2VNo === 5) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol11Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol11Row2 = 5;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 15;
        } else {
	 if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM11 = true;
    RCol11Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM11 = true;
      RCol11Row2 = 15;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 25;
      }
    } else if (c11R2VNo === 6) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol11Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol11Row2 = 6;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 16;
        } else {
	 if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM11 = true;
    RCol11Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM11 = true;
      RCol11Row2 = 16;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 26;
      }
    } else if (c11R2VNo === 7) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol11Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol11Row2 = 7;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 17;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM11 = true;
    RCol11Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM11 = true;
      RCol11Row2 = 17;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 27;
      }
    } else if (c11R2VNo === 8) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol11Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol11Row2 = 8;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 18;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM11 = true;
    RCol11Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM11 = true;
      RCol11Row2 = 18;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 28;
      }
    } else if (c11R2VNo === 9) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol11Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol11Row2 = 9;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 19;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM11 = true;
    RCol11Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM11 = true;
      RCol11Row2 = 19;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 29;
      }
    } else if (c11R2VNo === 10) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol11Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol11Row2 = 10;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row2 = 20;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM11 = true;
    RCol11Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM11 = true;
      RCol11Row2 = 20;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
        RCol11Row2 = 30;
      }
    } else if (c11R2VNo === 11) {
      if (c11r2MT === "S") { 
        if (col10Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r2MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
        } else {
	  if (col10Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM11 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM11 = true;
	  }
	}
      } else if (c11r2MT === "L") {
        nextL11 = true;
      }
    }
  }

  if (col12Row02 === true) {
    if (c12R2VNo === 1) {
      if (c12r2MT === "S") { 
	 if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol12Row2 = 1;
	} else {
		      currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol12Row2 = 1;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
	if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 11;
        } else {
	  if (col11Row02 === true) {
	  	      currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		        nextM12 = true;
            RCol12Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM12 = true;
      RCol12Row2 = 11;
	  }
	}
        
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 21;
      }
    } else if (c12R2VNo === 2) {
      if (c12r2MT === "S") { 
	if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol12Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol12Row2 = 2;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 12;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM12 = true;
    RCol12Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM12 = true;
      RCol12Row2 = 12;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 22;
      }
    } else if (c12R2VNo === 3) {
      if (c12r2MT === "S") { 
       if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol12Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol12Row2 = 3;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 13;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM12 = true;
    RCol12Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM12 = true;
      RCol12Row2 = 13;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 23;
      }
    } else if (c12R2VNo === 4) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol12Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol12Row2 = 4;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 14;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM12 = true;
    RCol12Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM12 = true;
      RCol12Row2 = 14;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 24;
      }
    } else if (c12R2VNo === 5) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol12Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol12Row2 = 5;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 15;
        } else {
	 if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM12 = true;
    RCol12Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM12 = true;
      RCol12Row2 = 15;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 25;
      }
    } else if (c12R2VNo === 6) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol12Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol12Row2 = 6;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 16;
        } else {
	 if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM12 = true;
    RCol12Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM12 = true;
      RCol12Row2 = 16;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 26;
      }
    } else if (c12R2VNo === 7) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol12Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol12Row2 = 7;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 17;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM12 = true;
    RCol12Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM12 = true;
      RCol12Row2 = 17;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 27;
      }
    } else if (c12R2VNo === 8) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol12Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol12Row2 = 8;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 18;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM12 = true;
    RCol12Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM12 = true;
      RCol12Row2 = 18;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 28;
      }
    } else if (c12R2VNo === 9) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol12Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol12Row2 = 9;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 19;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM12 = true;
    RCol12Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM12 = true;
      RCol12Row2 = 19;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 29;
      }
    } else if (c12R2VNo === 10) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol12Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol12Row2 = 10;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row2 = 20;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM12 = true;
    RCol12Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM12 = true;
      RCol12Row2 = 20;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
        RCol12Row2 = 30;
      }
    } else if (c12R2VNo === 11) {
      if (c12r2MT === "S") { 
        if (col11Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r2MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
        } else {
	  if (col11Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM12 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM12 = true;
	  }
	}
      } else if (c12r2MT === "L") {
        nextL12 = true;
      }
    }
  }

  if (col13Row02 === true) {
    if (c13R2VNo === 1) {
      if (c13r2MT === "S") { 
	 if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol13Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol13Row2 = 1;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
	if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 11;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM13 = true;
    RCol13Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM13 = true;
      RCol13Row2 = 11;
	  }
	}
        
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 21;
      }
    } else if (c13R2VNo === 2) {
      if (c13r2MT === "S") { 
	if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol13Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol13Row2 = 2;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 12;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM13 = true;
    RCol13Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM13 = true;
      RCol13Row2 = 12;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 22;
      }
    } else if (c13R2VNo === 3) {
      if (c13r2MT === "S") { 
       if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol13Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol13Row2 = 3;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 13;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM13 = true;
    RCol13Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM13 = true;
      RCol13Row2 = 13;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 23;
      }
    } else if (c13R2VNo === 4) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol13Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol13Row2 = 4;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 14;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM13 = true;
    RCol13Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM13 = true;
      RCol13Row2 = 14;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 24;
      }
    } else if (c13R2VNo === 5) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol13Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol13Row2 = 5;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 15;
        } else {
	 if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM13 = true;
    RCol13Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM13 = true;
      RCol13Row2 = 15;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 25;
      }
    } else if (c13R2VNo === 6) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol13Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol13Row2 = 6;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 16;
        } else {
	 if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM13 = true;
    RCol13Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM13 = true;
      RCol13Row2 = 16;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 26;
      }
    } else if (c13R2VNo === 7) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol13Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol13Row2 = 7;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 17;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM13 = true;
    RCol13Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM13 = true;
      RCol13Row2 = 17;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 27;
      }
    } else if (c13R2VNo === 8) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol13Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol13Row2 = 8;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 18;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM13 = true;
    RCol13Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM13 = true;
      RCol13Row2 = 18;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 28;
      }
    } else if (c13R2VNo === 9) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol13Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol13Row2 = 9;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 19;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM13 = true;
    RCol13Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM13 = true;
      RCol13Row2 = 19;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 29;
      }
    } else if (c13R2VNo === 10) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol13Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol13Row2 = 10;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row2 = 20;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM13 = true;
    RCol13Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM13 = true;
      RCol13Row2 = 20;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
        RCol13Row2 = 30;
      }
    } else if (c13R2VNo === 11) {
      if (c13r2MT === "S") { 
        if (col12Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r2MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
        } else {
	  if (col12Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM13 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM13 = true;
	  }
	}
      } else if (c13r2MT === "L") {
        nextL13 = true;
      }
    }
  }

  if (col14Row02 === true) {
    if (c14R2VNo === 1) {
      if (c14r2MT === "S") { 
	 if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol14Row2 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol14Row2 = 1;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
	if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 11;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM14 = true;
    RCol14Row2 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM14 = true;
      RCol14Row2 = 11;
	  }
	}
        
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 21;
      }
    } else if (c14R2VNo === 2) {
      if (c14r2MT === "S") { 
	if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol14Row2 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol14Row2 = 2;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 12;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM14 = true;
    RCol14Row2 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM14 = true;
      RCol14Row2 = 12;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 22;
      }
    } else if (c14R2VNo === 3) {
      if (c14r2MT === "S") { 
       if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol14Row2 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol14Row2 = 3;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 13;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM14 = true;
    RCol14Row2 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM14 = true;
      RCol14Row2 = 13;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 23;
      }
    } else if (c14R2VNo === 4) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol14Row2 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol14Row2 = 4;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 14;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM14 = true;
    RCol14Row2 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM14 = true;
      RCol14Row2 = 14;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 24;
      }
    } else if (c14R2VNo === 5) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol14Row2 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol14Row2 = 5;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 15;
        } else {
	 if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM14 = true;
    RCol14Row2 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM14 = true;
      RCol14Row2 = 15;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 25;
      }
    } else if (c14R2VNo === 6) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol14Row2 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol14Row2 = 6;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 16;
        } else {
	 if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM14 = true;
    RCol14Row2 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM14 = true;
      RCol14Row2 = 16;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 26;
      }
    } else if (c14R2VNo === 7) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol14Row2 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol14Row2 = 7;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 17;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM14 = true;
    RCol14Row2 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM14 = true;
      RCol14Row2 = 17;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 27;
      }
    } else if (c14R2VNo === 8) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol14Row2 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol14Row2 = 8;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 18;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM14 = true;
    RCol14Row2 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM14 = true;
      RCol14Row2 = 18;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 28;
      }
    } else if (c14R2VNo === 9) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol14Row2 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol14Row2 = 9;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 19;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM14 = true;
    RCol14Row2 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM14 = true;
      RCol14Row2 = 19;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 29;
      }
    } else if (c14R2VNo === 10) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol14Row2 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol14Row2 = 10;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row2 = 20;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM14 = true;
    RCol14Row2 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM14 = true;
      RCol14Row2 = 20;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
        RCol14Row2 = 30;
      }
    } else if (c14R2VNo === 11) {
      if (c14r2MT === "S") { 
        if (col13Row02 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r2MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
        } else {
	  if (col13Row02 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM14 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM14 = true;
	  }
	}
      } else if (c14r2MT === "L") {
        nextL14 = true;
      }
    }
  }

  if (col02Row03 === true) {
    if (c2R3VNo === 1) {
      if (c2r3MT === "S") { 
	 if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol2Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol2Row3 = 1;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
	if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 11;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM2 = true;
    RCol2Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM2 = true;
      RCol2Row3 = 11;
	  }
	}
        
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 21;
      }
    } else if (c2R3VNo === 2) {
      if (c2r3MT === "S") { 
	if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol2Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol2Row3 = 2;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 12;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM2 = true;
    RCol2Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM2 = true;
      RCol2Row3 = 12;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 22;
      }
    } else if (c2R3VNo === 3) {
      if (c2r3MT === "S") { 
       if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol2Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol2Row3 = 3;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 13;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM2 = true;
    RCol2Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM2 = true;
      RCol2Row3 = 13;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 23;
      }
    } else if (c2R3VNo === 4) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol2Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol2Row3 = 4;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 14;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM2 = true;
    RCol2Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM2 = true;
      RCol2Row3 = 14;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 24;
      }
    } else if (c2R3VNo === 5) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol2Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol2Row3 = 5;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 15;
        } else {
	 if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM2 = true;
    RCol2Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM2 = true;
      RCol2Row3 = 15;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 25;
      }
    } else if (c2R3VNo === 6) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol2Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol2Row3 = 6;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 16;
        } else {
	 if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM2 = true;
    RCol2Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM2 = true;
      RCol2Row3 = 16;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 26;
      }
    } else if (c2R3VNo === 7) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol2Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol2Row3 = 7;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 17;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM2 = true;
    RCol2Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM2 = true;
      RCol2Row3 = 17;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 27;
      }
    } else if (c2R3VNo === 8) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol2Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol2Row3 = 8;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 18;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM2 = true;
    RCol2Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM2 = true;
      RCol2Row3 = 18;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 28;
      }
    } else if (c2R3VNo === 9) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol2Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol2Row3 = 9;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 19;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM2 = true;
    RCol2Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM2 = true;
      RCol2Row3 = 19;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 29;
      }
    } else if (c2R3VNo === 10) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol2Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol2Row3 = 10;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row3 = 20;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM2 = true;
    RCol2Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM2 = true;
      RCol2Row3 = 20;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
        RCol2Row3 = 30;
      }
    } else if (c2R3VNo === 11) {
      if (c2r3MT === "S") { 
        if (col01Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r3MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
        } else {
	  if (col01Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM2 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM2 = true;
	  }
	}
      } else if (c2r3MT === "L") {
        nextL2 = true;
      }
    }
  }  if (col03Row03 === true) {
    if (c3R3VNo === 1) {
      if (c3r3MT === "S") { 
	 if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol3Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol3Row3 = 1;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
	if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 11;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM3 = true;
    RCol3Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM3 = true;
      RCol3Row3 = 11;
	  }
	}
        
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 21;
      }
    } else if (c3R3VNo === 2) {
      if (c3r3MT === "S") { 
	if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol3Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol3Row3 = 2;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 12;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM3 = true;
    RCol3Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM3 = true;
      RCol3Row3 = 12;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 22;
      }
    } else if (c3R3VNo === 3) {
      if (c3r3MT === "S") { 
       if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol3Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol3Row3 = 3;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 13;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM3 = true;
    RCol3Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM3 = true;
      RCol3Row3 = 13;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 23;
      }
    } else if (c3R3VNo === 4) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol3Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol3Row3 = 4;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 14;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM3 = true;
    RCol3Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM3 = true;
      RCol3Row3 = 14;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 24;
      }
    } else if (c3R3VNo === 5) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol3Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol3Row3 = 5;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 15;
        } else {
	 if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM3 = true;
    RCol3Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM3 = true;
      RCol3Row3 = 15;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 25;
      }
    } else if (c3R3VNo === 6) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol3Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol3Row3 = 6;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 16;
        } else {
	 if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM3 = true;
    RCol3Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM3 = true;
      RCol3Row3 = 16;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 26;
      }
    } else if (c3R3VNo === 7) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol3Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol3Row3 = 7;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 17;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM3 = true;
    RCol3Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM3 = true;
      RCol3Row3 = 17;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 27;
      }
    } else if (c3R3VNo === 8) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol3Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol3Row3 = 8;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 18;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM3 = true;
    RCol3Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM3 = true;
      RCol3Row3 = 18;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 28;
      }
    } else if (c3R3VNo === 9) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol3Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol3Row3 = 9;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 19;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM3 = true;
    RCol3Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM3 = true;
      RCol3Row3 = 19;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 29;
      }
    } else if (c3R3VNo === 10) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol3Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol3Row3 = 10;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row3 = 20;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM3 = true;
    RCol3Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM3 = true;
      RCol3Row3 = 20;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
        RCol3Row3 = 30;
      }
    } else if (c3R3VNo === 11) {
      if (c3r3MT === "S") { 
        if (col02Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r3MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
        } else {
	  if (col02Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM3 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM3 = true;
	  }
	}
      } else if (c3r3MT === "L") {
        nextL3 = true;
      }
    }
  }

  if (col04Row03 === true) {
    if (c4R3VNo === 1) {
      if (c4r3MT === "S") { 
	 if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol4Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol4Row3 = 1;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
	if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 11;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM4 = true;
    RCol4Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM4 = true;
      RCol4Row3 = 11;
	  }
	}
        
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 21;
      }
    } else if (c4R3VNo === 2) {
      if (c4r3MT === "S") { 
	if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol4Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol4Row3 = 2;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 12;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM4 = true;
    RCol4Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM4 = true;
      RCol4Row3 = 12;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 22;
      }
    } else if (c4R3VNo === 3) {
      if (c4r3MT === "S") { 
       if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol4Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol4Row3 = 3;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 13;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM4 = true;
    RCol4Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM4 = true;
      RCol4Row3 = 13;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 23;
      }
    } else if (c4R3VNo === 4) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol4Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol4Row3 = 4;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 14;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM4 = true;
    RCol4Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM4 = true;
      RCol4Row3 = 14;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 24;
      }
    } else if (c4R3VNo === 5) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol4Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol4Row3 = 5;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 15;
        } else {
	 if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM4 = true;
    RCol4Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM4 = true;
      RCol4Row3 = 15;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 25;
      }
    } else if (c4R3VNo === 6) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol4Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol4Row3 = 6;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 16;
        } else {
	 if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM4 = true;
    RCol4Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM4 = true;
      RCol4Row3 = 16;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 26;
      }
    } else if (c4R3VNo === 7) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol4Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol4Row3 = 7;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 17;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM4 = true;
    RCol4Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM4 = true;
      RCol4Row3 = 17;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 27;
      }
    } else if (c4R3VNo === 8) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol4Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol4Row3 = 8;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 18;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM4 = true;
    RCol4Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM4 = true;
      RCol4Row3 = 18;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 28;
      }
    } else if (c4R3VNo === 9) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol4Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol4Row3 = 9;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 19;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM4 = true;
    RCol4Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM4 = true;
      RCol4Row3 = 19;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 29;
      }
    } else if (c4R3VNo === 10) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol4Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol4Row3 = 10;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row3 = 20;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM4 = true;
    RCol4Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM4 = true;
      RCol4Row3 = 20;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
        RCol4Row3 = 30;
      }
    } else if (c4R3VNo === 11) {
      if (c4r3MT === "S") { 
        if (col03Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r3MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
        } else {
	  if (col03Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM4 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM4 = true;
	  }
	}
      } else if (c4r3MT === "L") {
        nextL4 = true;
      }
    }
  }

  if (col05Row03 === true) {
    if (c5R3VNo === 1) {
      if (c5r3MT === "S") { 
	 if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol5Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol5Row3 = 1;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
	if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 11;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM5 = true;
    RCol5Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM5 = true;
      RCol5Row3 = 11;
	  }
	}
        
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 21;
      }
    } else if (c5R3VNo === 2) {
      if (c5r3MT === "S") { 
	if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol5Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol5Row3 = 2;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 12;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM5 = true;
    RCol5Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM5 = true;
      RCol5Row3 = 12;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 22;
      }
    } else if (c5R3VNo === 3) {
      if (c5r3MT === "S") { 
       if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol5Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol5Row3 = 3;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 13;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM5 = true;
    RCol5Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM5 = true;
      RCol5Row3 = 13;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 23;
      }
    } else if (c5R3VNo === 4) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol5Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol5Row3 = 4;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 14;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM5 = true;
    RCol5Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM5 = true;
      RCol5Row3 = 14;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 24;
      }
    } else if (c5R3VNo === 5) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol5Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol5Row3 = 5;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 15;
        } else {
	 if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM5 = true;
    RCol5Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM5 = true;
      RCol5Row3 = 15;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 25;
      }
    } else if (c5R3VNo === 6) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol5Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol5Row3 = 6;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 16;
        } else {
	 if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM5 = true;
    RCol5Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM5 = true;
      RCol5Row3 = 16;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 26;
      }
    } else if (c5R3VNo === 7) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol5Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol5Row3 = 7;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 17;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM5 = true;
    RCol5Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM5 = true;
      RCol5Row3 = 17;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 27;
      }
    } else if (c5R3VNo === 8) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol5Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol5Row3 = 8;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 18;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM5 = true;
    RCol5Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM5 = true;
      RCol5Row3 = 18;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 28;
      }
    } else if (c5R3VNo === 9) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol5Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol5Row3 = 9;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 19;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM5 = true;
    RCol5Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM5 = true;
      RCol5Row3 = 19;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 29;
      }
    } else if (c5R3VNo === 10) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol5Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol5Row3 = 10;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row3 = 20;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM5 = true;
    RCol5Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM5 = true;
      RCol5Row3 = 20;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
        RCol5Row3 = 30;
      }
    } else if (c5R3VNo === 11) {
      if (c5r3MT === "S") { 
        if (col04Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r3MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
        } else {
	  if (col04Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM5 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM5 = true;
	  }
	}
      } else if (c5r3MT === "L") {
        nextL5 = true;
      }
    }
  }

  if (col06Row03 === true) {
    if (c6R3VNo === 1) {
      if (c6r3MT === "S") { 
	 if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol6Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol6Row3 = 1;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
	if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 11;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM6 = true;
    RCol6Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM6 = true;
      RCol6Row3 = 11;
	  }
	}
        
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 21;
      }
    } else if (c6R3VNo === 2) {
      if (c6r3MT === "S") { 
	if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol6Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol6Row3 = 2;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 12;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM6 = true;
    RCol6Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM6 = true;
      RCol6Row3 = 12;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 22;
      }
    } else if (c6R3VNo === 3) {
      if (c6r3MT === "S") { 
       if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol6Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol6Row3 = 3;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 13;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM6 = true;
    RCol6Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM6 = true;
      RCol6Row3 = 13;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 23;
      }
    } else if (c6R3VNo === 4) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol6Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol6Row3 = 4;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 14;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM6 = true;
    RCol6Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM6 = true;
      RCol6Row3 = 14;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 24;
      }
    } else if (c6R3VNo === 5) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol6Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol6Row3 = 5;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 15;
        } else {
	 if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM6 = true;
    RCol6Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM6 = true;
      RCol6Row3 = 15;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 25;
      }
    } else if (c6R3VNo === 6) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol6Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol6Row3 = 6;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 16;
        } else {
	 if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM6 = true;
    RCol6Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM6 = true;
      RCol6Row3 = 16;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 26;
      }
    } else if (c6R3VNo === 7) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol6Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol6Row3 = 7;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 17;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM6 = true;
    RCol6Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM6 = true;
      RCol6Row3 = 17;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 27;
      }
    } else if (c6R3VNo === 8) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol6Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol6Row3 = 8;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 18;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM6 = true;
    RCol6Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM6 = true;
      RCol6Row3 = 18;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 28;
      }
    } else if (c6R3VNo === 9) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol6Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol6Row3 = 9;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 19;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM6 = true;
    RCol6Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM6 = true;
      RCol6Row3 = 19;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 29;
      }
    } else if (c6R3VNo === 10) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol6Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol6Row3 = 10;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row3 = 20;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM6 = true;
    RCol6Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM6 = true;
      RCol6Row3 = 20;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
        RCol6Row3 = 30;
      }
    } else if (c6R3VNo === 11) {
      if (c6r3MT === "S") { 
        if (col05Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r3MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
        } else {
	  if (col05Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM6 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM6 = true;
	  }
	}
      } else if (c6r3MT === "L") {
        nextL6 = true;
      }
    }
  }

  if (col07Row03 === true) {
    if (c7R3VNo === 1) {
      if (c7r3MT === "S") { 
	 if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol7Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol7Row3 = 1;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
	if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 11;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM7 = true;
    RCol7Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM7 = true;
      RCol7Row3 = 11;
	  }
	}
        
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 21;
      }
    } else if (c7R3VNo === 2) {
      if (c7r3MT === "S") { 
	if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol7Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol7Row3 = 2;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 12;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM7 = true;
    RCol7Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM7 = true;
      RCol7Row3 = 12;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 22;
      }
    } else if (c7R3VNo === 3) {
      if (c7r3MT === "S") { 
       if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol7Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol7Row3 = 3;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 13;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM7 = true;
    RCol7Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM7 = true;
      RCol7Row3 = 13;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 23;
      }
    } else if (c7R3VNo === 4) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol7Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol7Row3 = 4;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 14;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM7 = true;
    RCol7Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM7 = true;
      RCol7Row3 = 14;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 24;
      }
    } else if (c7R3VNo === 5) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol7Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol7Row3 = 5;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 15;
        } else {
	 if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM7 = true;
    RCol7Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM7 = true;
      RCol7Row3 = 15;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 25;
      }
    } else if (c7R3VNo === 6) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol7Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol7Row3 = 6;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 16;
        } else {
	 if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM7 = true;
    RCol7Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM7 = true;
      RCol7Row3 = 16;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 26;
      }
    } else if (c7R3VNo === 7) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol7Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol7Row3 = 7;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 17;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM7 = true;
    RCol7Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM7 = true;
      RCol7Row3 = 17;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 27;
      }
    } else if (c7R3VNo === 8) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol7Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol7Row3 = 8;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 18;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM7 = true;
    RCol7Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM7 = true;
      RCol7Row3 = 18;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 28;
      }
    } else if (c7R3VNo === 9) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol7Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol7Row3 = 9;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 19;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM7 = true;
    RCol7Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM7 = true;
      RCol7Row3 = 19;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 29;
      }
    } else if (c7R3VNo === 10) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol7Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol7Row3 = 10;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row3 = 20;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM7 = true;
    RCol7Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM7 = true;
      RCol7Row3 = 20;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
        RCol7Row3 = 30;
      }
    } else if (c7R3VNo === 11) {
      if (c7r3MT === "S") { 
        if (col06Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r3MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
        } else {
	  if (col06Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM7 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM7 = true;
	  }
	}
      } else if (c7r3MT === "L") {
        nextL7 = true;
      }
    }
  }

  if (col08Row03 === true) {
    if (c8R3VNo === 1) {
      if (c8r3MT === "S") { 
	 if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol8Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol8Row3 = 1;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
	if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 11;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM8 = true;
    RCol8Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM8 = true;
      RCol8Row3 = 11;
	  }
	}
        
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 21;
      }
    } else if (c8R3VNo === 2) {
      if (c8r3MT === "S") { 
	if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol8Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol8Row3 = 2;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 12;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM8 = true;
    RCol8Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM8 = true;
      RCol8Row3 = 12;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 22;
      }
    } else if (c8R3VNo === 3) {
      if (c8r3MT === "S") { 
       if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol8Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol8Row3 = 3;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 13;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM8 = true;
    RCol8Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM8 = true;
      RCol8Row3 = 13;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 23;
      }
    } else if (c8R3VNo === 4) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol8Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol8Row3 = 4;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 14;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM8 = true;
    RCol8Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM8 = true;
      RCol8Row3 = 14;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 24;
      }
    } else if (c8R3VNo === 5) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol8Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol8Row3 = 5;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 15;
        } else {
	 if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM8 = true;
    RCol8Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM8 = true;
      RCol8Row3 = 15;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 25;
      }
    } else if (c8R3VNo === 6) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol8Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol8Row3 = 6;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 16;
        } else {
	 if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM8 = true;
    RCol8Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM8 = true;
      RCol8Row3 = 16;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 26;
      }
    } else if (c8R3VNo === 7) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol8Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol8Row3 = 7;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 17;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM8 = true;
    RCol8Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM8 = true;
      RCol8Row3 = 17;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 27;
      }
    } else if (c8R3VNo === 8) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol8Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol8Row3 = 8;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 18;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM8 = true;
    RCol8Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM8 = true;
      RCol8Row3 = 18;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 28;
      }
    } else if (c8R3VNo === 9) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol8Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol8Row3 = 9;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 19;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM8 = true;
    RCol8Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM8 = true;
      RCol8Row3 = 19;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 29;
      }
    } else if (c8R3VNo === 10) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol8Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol8Row3 = 10;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row3 = 20;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM8 = true;
    RCol8Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM8 = true;
      RCol8Row3 = 20;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
        RCol8Row3 = 30;
      }
    } else if (c8R3VNo === 11) {
      if (c8r3MT === "S") { 
        if (col07Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r3MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
        } else {
	  if (col07Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM8 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM8 = true;
	  }
	}
      } else if (c8r3MT === "L") {
        nextL8 = true;
      }
    }
  }

  if (col09Row03 === true) {
    if (c9R3VNo === 1) {
      if (c9r3MT === "S") { 
	 if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol9Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol9Row3 = 1;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
	if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 11;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM9 = true;
    RCol9Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM9 = true;
      RCol9Row3 = 11;
	  }
	}
        
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 21;
      }
    } else if (c9R3VNo === 2) {
      if (c9r3MT === "S") { 
	if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol9Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol9Row3 = 2;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 12;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM9 = true;
    RCol9Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM9 = true;
      RCol9Row3 = 12;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 22;
      }
    } else if (c9R3VNo === 3) {
      if (c9r3MT === "S") { 
       if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol9Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol9Row3 = 3;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 13;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM9 = true;
    RCol9Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM9 = true;
      RCol9Row3 = 13;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 23;
      }
    } else if (c9R3VNo === 4) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol9Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol9Row3 = 4;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 14;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM9 = true;
    RCol9Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM9 = true;
      RCol9Row3 = 14;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 24;
      }
    } else if (c9R3VNo === 5) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol9Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol9Row3 = 5;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 15;
        } else {
	 if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM9 = true;
    RCol9Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM9 = true;
      RCol9Row3 = 15;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 25;
      }
    } else if (c9R3VNo === 6) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol9Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol9Row3 = 6;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 16;
        } else {
	 if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM9 = true;
    RCol9Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM9 = true;
      RCol9Row3 = 16;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 26;
      }
    } else if (c9R3VNo === 7) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol9Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol9Row3 = 7;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 17;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM9 = true;
    RCol9Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM9 = true;
      RCol9Row3 = 17;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 27;
      }
    } else if (c9R3VNo === 8) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol9Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol9Row3 = 8;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 18;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM9 = true;
    RCol9Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM9 = true;
      RCol9Row3 = 18;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 28;
      }
    } else if (c9R3VNo === 9) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol9Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol9Row3 = 9;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 19;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM9 = true;
    RCol9Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM9 = true;
      RCol9Row3 = 19;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 29;
      }
    } else if (c9R3VNo === 10) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol9Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol9Row3 = 10;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row3 = 20;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM9 = true;
    RCol9Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM9 = true;
      RCol9Row3 = 20;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
        RCol9Row3 = 30;
      }
    } else if (c9R3VNo === 11) {
      if (c9r3MT === "S") { 
        if (col08Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r3MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
        } else {
	  if (col08Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM9 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM9 = true;
	  }
	}
      } else if (c9r3MT === "L") {
        nextL9 = true;
      }
    }
  }

  if (col10Row03 === true) {
    if (c10R3VNo === 1) {
      if (c10r3MT === "S") { 
	 if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol10Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol10Row3 = 1;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
	if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 11;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM10 = true;
    RCol10Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM10 = true;
      RCol10Row3 = 11;
	  }
	}
        
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 21;
      }
    } else if (c10R3VNo === 2) {
      if (c10r3MT === "S") { 
	if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol10Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol10Row3 = 2;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 12;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM10 = true;
    RCol10Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM10 = true;
      RCol10Row3 = 12;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 22;
      }
    } else if (c10R3VNo === 3) {
      if (c10r3MT === "S") { 
       if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol10Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol10Row3 = 3;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 13;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM10 = true;
    RCol10Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM10 = true;
      RCol10Row3 = 13;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 23;
      }
    } else if (c10R3VNo === 4) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol10Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol10Row3 = 4;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 14;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM10 = true;
    RCol10Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM10 = true;
      RCol10Row3 = 14;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 24;
      }
    } else if (c10R3VNo === 5) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol10Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol10Row3 = 5;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 15;
        } else {
	 if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM10 = true;
    RCol10Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM10 = true;
      RCol10Row3 = 15;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 25;
      }
    } else if (c10R3VNo === 6) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol10Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol10Row3 = 6;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 16;
        } else {
	 if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM10 = true;
    RCol10Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM10 = true;
      RCol10Row3 = 16;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 26;
      }
    } else if (c10R3VNo === 7) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol10Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol10Row3 = 7;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 17;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM10 = true;
    RCol10Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM10 = true;
      RCol10Row3 = 17;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 27;
      }
    } else if (c10R3VNo === 8) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol10Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol10Row3 = 8;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 18;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM10 = true;
    RCol10Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM10 = true;
      RCol10Row3 = 18;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 28;
      }
    } else if (c10R3VNo === 9) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol10Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol10Row3 = 9;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 19;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM10 = true;
    RCol10Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM10 = true;
      RCol10Row3 = 19;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 29;
      }
    } else if (c10R3VNo === 10) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol10Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol10Row3 = 10;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row3 = 20;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM10 = true;
    RCol10Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM10 = true;
      RCol10Row3 = 20;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
        RCol10Row3 = 30;
      }
    } else if (c10R3VNo === 11) {
      if (c10r3MT === "S") { 
        if (col09Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r3MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
        } else {
	  if (col09Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM10 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM10 = true;
	  }
	}
      } else if (c10r3MT === "L") {
        nextL10 = true;
      }
    }
  }

  if (col11Row03 === true) {
    if (c11R3VNo === 1) {
      if (c11r3MT === "S") { 
	 if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol11Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol11Row3 = 1;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
	if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 11;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM11 = true;
    RCol11Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM11 = true;
      RCol11Row3 = 11;
	  }
	}
        
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 21;
      }
    } else if (c11R3VNo === 2) {
      if (c11r3MT === "S") { 
	if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol11Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol11Row3 = 2;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 12;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM11 = true;
    RCol11Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM11 = true;
      RCol11Row3 = 12;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 22;
      }
    } else if (c11R3VNo === 3) {
      if (c11r3MT === "S") { 
       if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol11Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol11Row3 = 3;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 13;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM11 = true;
    RCol11Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM11 = true;
      RCol11Row3 = 13;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 23;
      }
    } else if (c11R3VNo === 4) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol11Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol11Row3 = 4;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 14;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM11 = true;
    RCol11Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM11 = true;
      RCol11Row3 = 14;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 24;
      }
    } else if (c11R3VNo === 5) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol11Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol11Row3 = 5;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 15;
        } else {
	 if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM11 = true;
    RCol11Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM11 = true;
      RCol11Row3 = 15;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 25;
      }
    } else if (c11R3VNo === 6) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol11Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol11Row3 = 6;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 16;
        } else {
	 if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM11 = true;
    RCol11Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM11 = true;
      RCol11Row3 = 16;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 26;
      }
    } else if (c11R3VNo === 7) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol11Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol11Row3 = 7;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 17;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM11 = true;
    RCol11Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM11 = true;
      RCol11Row3 = 17;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 27;
      }
    } else if (c11R3VNo === 8) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol11Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol11Row3 = 8;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 18;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM11 = true;
    RCol11Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM11 = true;
      RCol11Row3 = 18;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 28;
      }
    } else if (c11R3VNo === 9) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol11Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol11Row3 = 9;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 19;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM11 = true;
    RCol11Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM11 = true;
      RCol11Row3 = 19;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 29;
      }
    } else if (c11R3VNo === 10) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol11Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol11Row3 = 10;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row3 = 20;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM11 = true;
    RCol11Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM11 = true;
      RCol11Row3 = 20;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
        RCol11Row3 = 30;
      }
    } else if (c11R3VNo === 11) {
      if (c11r3MT === "S") { 
        if (col10Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r3MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
        } else {
	  if (col10Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM11 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM11 = true;
	  }
	}
      } else if (c11r3MT === "L") {
        nextL11 = true;
      }
    }
  }

  if (col12Row03 === true) {
    if (c12R3VNo === 1) {
      if (c12r3MT === "S") { 
	 if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol12Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol12Row3 = 1;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
	if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 11;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM12 = true;
    RCol12Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM12 = true;
      RCol12Row3 = 11;
	  }
	}
        
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 21;
      }
    } else if (c12R3VNo === 2) {
      if (c12r3MT === "S") { 
	if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol12Row3 = 2;
	} else {
		      currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol12Row3 = 2;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 12;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM12 = true;
    RCol12Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM12 = true;
      RCol12Row3 = 12;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 22;
      }
    } else if (c12R3VNo === 3) {
      if (c12r3MT === "S") { 
       if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol12Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol12Row3 = 3;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 13;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM12 = true;
    RCol12Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM12 = true;
      RCol12Row3 = 13;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 23;
      }
    } else if (c12R3VNo === 4) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol12Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol12Row3 = 4;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 14;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM12 = true;
    RCol12Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM12 = true;
      RCol12Row3 = 14;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 24;
      }
    } else if (c12R3VNo === 5) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol12Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol12Row3 = 5;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 15;
        } else {
	 if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM12 = true;
    RCol12Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM12 = true;
      RCol12Row3 = 15;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 25;
      }
    } else if (c12R3VNo === 6) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol12Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol12Row3 = 6;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 16;
        } else {
	 if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM12 = true;
    RCol12Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM12 = true;
      RCol12Row3 = 16;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 26;
      }
    } else if (c12R3VNo === 7) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol12Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol12Row3 = 7;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 17;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM12 = true;
    RCol12Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM12 = true;
      RCol12Row3 = 17;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 27;
      }
    } else if (c12R3VNo === 8) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol12Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol12Row3 = 8;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 18;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV7();
		nextM12 = true;
    RCol12Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM12 = true;
      RCol12Row3 = 18;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 28;
      }
    } else if (c12R3VNo === 9) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol12Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol12Row3 = 9;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 19;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM12 = true;
    RCol12Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM12 = true;
      RCol12Row3 = 19;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 29;
      }
    } else if (c12R3VNo === 10) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol12Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol12Row3 = 10;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row3 = 20;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM12 = true;
    RCol12Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM12 = true;
      RCol12Row3 = 20;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
        RCol12Row3 = 30;
      }
    } else if (c12R3VNo === 11) {
      if (c12r3MT === "S") { 
        if (col11Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r3MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
        } else {
	  if (col11Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM12 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM12 = true;
	  }
	}
      } else if (c12r3MT === "L") {
        nextL12 = true;
      }
    }
  }

  if (col13Row03 === true) {
    if (c13R3VNo === 1) {
      if (c13r3MT === "S") { 
	 if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol13Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol13Row3 = 1;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
	if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 11;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM13 = true;
    RCol13Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM13 = true;
      RCol13Row3 = 11;
	  }
	}
        
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 21;
      }
    } else if (c13R3VNo === 2) {
      if (c13r3MT === "S") { 
	if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol13Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol13Row3 = 2;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 12;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM13 = true;
    RCol13Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM13 = true;
      RCol13Row3 = 12;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 22;
      }
    } else if (c13R3VNo === 3) {
      if (c13r3MT === "S") { 
       if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol13Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol13Row3 = 3;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 13;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM13 = true;
    RCol13Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM13 = true;
      RCol13Row3 = 13;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 23;
      }
    } else if (c13R3VNo === 4) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol13Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol13Row3 = 4;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 14;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM13 = true;
    RCol13Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM13 = true;
      RCol13Row3 = 14;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 24;
      }
    } else if (c13R3VNo === 5) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol13Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol13Row3 = 5;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 15;
        } else {
	 if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM13 = true;
    RCol13Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM13 = true;
      RCol13Row3 = 15;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 25;
      }
    } else if (c13R3VNo === 6) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol13Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol13Row3 = 6;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 16;
        } else {
	 if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM13 = true;
    RCol13Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM13 = true;
      RCol13Row3 = 16;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 26;
      }
    } else if (c13R3VNo === 7) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol13Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol13Row3 = 7;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 17;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM13 = true;
    RCol13Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM13 = true;
      RCol13Row3 = 17;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 27;
      }
    } else if (c13R3VNo === 8) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol13Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol13Row3 = 8;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 18;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM13 = true;
    RCol13Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM13 = true;
      RCol13Row3 = 18;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 28;
      }
    } else if (c13R3VNo === 9) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol13Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol13Row3 = 9;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 19;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM13 = true;
    RCol13Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM13 = true;
      RCol13Row3 = 19;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 29;
      }
    } else if (c13R3VNo === 10) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol13Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol13Row3 = 10;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row3 = 20;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM13 = true;
    RCol13Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM13 = true;
      RCol13Row3 = 20;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
        RCol13Row3 = 30;
      }
    } else if (c13R3VNo === 11) {
      if (c13r3MT === "S") { 
        if (col12Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r3MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
        } else {
	  if (col12Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM13 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM13 = true;
	  }
	}
      } else if (c13r3MT === "L") {
        nextL13 = true;
      }
    }
  }

  if (col14Row03 === true) {
    if (c14R3VNo === 1) {
      if (c14r3MT === "S") { 
	 if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol14Row3 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol14Row3 = 1;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
	if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 11;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM14 = true;
    RCol14Row3 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM14 = true;
      RCol14Row3 = 11;
	  }
	}
        
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 21;
      }
    } else if (c14R3VNo === 2) {
      if (c14r3MT === "S") { 
	if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol14Row3 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol14Row3 = 2;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 12;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM14 = true;
    RCol14Row3 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM14 = true;
      RCol14Row3 = 12;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 22;
      }
    } else if (c14R3VNo === 3) {
      if (c14r3MT === "S") { 
       if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol14Row3 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol14Row3 = 3;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 13;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM14 = true;
    RCol14Row3 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM14 = true;
      RCol14Row3 = 13;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 23;
      }
    } else if (c14R3VNo === 4) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol14Row3 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol14Row3 = 41;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 14;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM14 = true;
    RCol14Row3 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM14 = true;
      RCol14Row3 = 14;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 24;
      }
    } else if (c14R3VNo === 5) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol14Row3 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol14Row3 = 5;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 15;
        } else {
	 if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM14 = true;
    RCol14Row3 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM14 = true;
      RCol14Row3 = 15;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 25;
      }
    } else if (c14R3VNo === 6) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol14Row3 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol14Row3 = 6;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 16;
        } else {
	 if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM14 = true;
    RCol14Row3 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM14 = true;
      RCol14Row3 = 16;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 26;
      }
    } else if (c14R3VNo === 7) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol14Row3 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol14Row3 = 7;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 17;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM14 = true;
    RCol14Row3 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM14 = true;
      RCol14Row3 = 17;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 27;
      }
    } else if (c14R3VNo === 8) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol14Row3 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol14Row3 = 8;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 18;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM14 = true;
    RCol14Row3 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM14 = true;
      RCol14Row3 = 18;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 28;
      }
    } else if (c14R3VNo === 9) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol14Row3 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol14Row3 = 9;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 19;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM14 = true;
    RCol14Row3 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM14 = true;
      RCol14Row3 = 19;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 29;
      }
    } else if (c14R3VNo === 10) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol14Row3 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol14Row3 = 10;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row3 = 20;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM14 = true;
    RCol14Row3 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM14 = true;
      RCol14Row3 = 20;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
        RCol14Row3 = 30;
      }
    } else if (c14R3VNo === 11) {
      if (c14r3MT === "S") { 
        if (col13Row03 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r3MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
        } else {
	  if (col13Row03 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM14 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM14 = true;
	  }
	}
      } else if (c14r3MT === "L") {
        nextL14 = true;
      }
    }
  }

  if (col02Row04 === true) {
    if (c2R4VNo === 1) {
      if (c2r4MT === "S") { 
	 if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol2Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol2Row4 = 1;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
	if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 11;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM2 = true;
    RCol2Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM2 = true;
      RCol2Row4 = 11;
	  }
	}
        
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 21;
      }
    } else if (c2R4VNo === 2) {
      if (c2r4MT === "S") { 
	if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol2Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol2Row4 = 2;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 12;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM2 = true;
    RCol2Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM2 = true;
      RCol2Row4 = 12;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 22;
      }
    } else if (c2R4VNo === 3) {
      if (c2r4MT === "S") { 
       if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol2Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol2Row4 = 3;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 13;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM2 = true;
    RCol2Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM2 = true;
      RCol2Row4 = 13;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 23;
      }
    } else if (c2R4VNo === 4) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol2Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol2Row4 = 4;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 14;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM2 = true;
    RCol2Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM2 = true;
      RCol2Row4 = 14;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 24;
      }
    } else if (c2R4VNo === 5) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol2Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol2Row4 = 5;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 15;
        } else {
	 if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM2 = true;
    RCol2Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM2 = true;
      RCol2Row4 = 15;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 25;
      }
    } else if (c2R4VNo === 6) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol2Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol2Row4 = 6;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 16;
        } else {
	 if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM2 = true;
    RCol2Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM2 = true;
      RCol2Row4 = 16;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 26;
      }
    } else if (c2R4VNo === 7) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol2Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol2Row4 = 7;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 17;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM2 = true;
    RCol2Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM2 = true;
      RCol2Row4 = 17;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 27;
      }
    } else if (c2R4VNo === 8) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol2Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol2Row4 = 8;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 18;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM2 = true;
    RCol2Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM2 = true;
      RCol2Row4 = 18;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 28;
      }
    } else if (c2R4VNo === 9) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol2Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol2Row4 = 9;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
       if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 19;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM2 = true;
    RCol2Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM2 = true;
      RCol2Row4 = 19;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 29;
      }
    } else if (c2R4VNo === 10) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol2Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol2Row4 = 10;
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
    RCol2Row4 = 20;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM2 = true;
    RCol2Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM2 = true;
      RCol2Row4 = 20;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
        RCol2Row4 = 30;
      }
    } else if (c2R4VNo === 11) {
      if (c2r4MT === "S") { 
        if (col01Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM2 = false;
        nextL2 = false;
      } else if (c2r4MT === "M") {
        if (nextM2 === true) {
	  nextM2 = false;
        } else {
	  if (col01Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM2 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM2 = true;
	  }
	}
      } else if (c2r4MT === "L") {
        nextL2 = true;
      }
    }
  }
  if (col03Row04 === true) {
    if (c3R4VNo === 1) {
      if (c3r4MT === "S") { 
	 if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol3Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol3Row4 = 1;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
	if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 11;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM3 = true;
    RCol3Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM3 = true;
      RCol3Row4 = 11;
	  }
	}
        
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 21;
      }
    } else if (c3R4VNo === 2) {
      if (c3r4MT === "S") { 
	if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol3Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol3Row4 = 2;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 12;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM3 = true;
    RCol3Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM3 = true;
      RCol3Row4 = 12;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 22;
      }
    } else if (c3R4VNo === 3) {
      if (c3r4MT === "S") { 
       if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol3Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol3Row4 = 3;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 13;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM3 = true;
    RCol3Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM3 = true;
      RCol3Row4 = 13;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 23;
      }
    } else if (c3R4VNo === 4) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol3Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol3Row4 = 4;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 14;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM3 = true;
    RCol3Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM3 = true;
      RCol3Row4 = 14;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 24;
      }
    } else if (c3R4VNo === 5) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol3Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol3Row4 = 5;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 15;
        } else {
	 if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM3 = true;
    RCol3Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM3 = true;
      RCol3Row4 = 15;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 25;
      }
    } else if (c3R4VNo === 6) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol3Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol3Row4 = 6;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 16;
        } else {
	 if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM3 = true;
    RCol3Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM3 = true;
      RCol3Row4 = 16;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 26;
      }
    } else if (c3R4VNo === 7) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol3Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol3Row4 = 7;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 17;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM3 = true;
    RCol3Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM3 = true;
      RCol3Row4 = 17;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 27;
      }
    } else if (c3R4VNo === 8) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol3Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol3Row4 = 8;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 18;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM3 = true;
    RCol3Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM3 = true;
      RCol3Row4 = 18;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 28;
      }
    } else if (c3R4VNo === 9) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol3Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol3Row4 = 9;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
       if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 19;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM3 = true;
    RCol3Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM3 = true;
      RCol3Row4 = 19;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 29;
      }
    } else if (c3R4VNo === 10) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol3Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol3Row4 = 10;
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
    RCol3Row4 = 20;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM3 = true;
    RCol3Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM3 = true;
      RCol3Row4 = 20;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
        RCol3Row4 = 30;
      }
    } else if (c3R4VNo === 11) {
      if (c3r4MT === "S") { 
        if (col02Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM3 = false;
        nextL3 = false;
      } else if (c3r4MT === "M") {
        if (nextM3 === true) {
	  nextM3 = false;
        } else {
	  if (col02Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM3 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM3 = true;
	  }
	}
      } else if (c3r4MT === "L") {
        nextL3 = true;
      }
    }
  }

  if (col04Row04 === true) {
    if (c4R4VNo === 1) {
      if (c4r4MT === "S") { 
	 if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol4Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol4Row4 = 1;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
	if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 11;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM4 = true;
    RCol4Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM4 = true;
      RCol4Row4 = 11;
	  }
	}
        
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 21;
      }
    } else if (c4R4VNo === 2) {
      if (c4r4MT === "S") { 
	if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol4Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol4Row4 = 2;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 12;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM4 = true;
    RCol4Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM4 = true;
      RCol4Row4 = 12;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 22;
      }
    } else if (c4R4VNo === 3) {
      if (c4r4MT === "S") { 
       if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol4Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol4Row4 = 3;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 13;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM4 = true;
    RCol4Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM4 = true;
      RCol4Row4 = 13;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 23;
      }
    } else if (c4R4VNo === 4) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol4Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol4Row4 = 4;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 14;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM4 = true;
    RCol4Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM4 = true;
      RCol4Row4 = 14;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 24;
      }
    } else if (c4R4VNo === 5) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol4Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol4Row4 = 5;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 15;
        } else {
	 if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM4 = true;
    RCol4Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM4 = true;
      RCol4Row4 = 15;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 25;
      }
    } else if (c4R4VNo === 6) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol4Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol4Row4 = 6;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 16;
        } else {
	 if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM4 = true;
    RCol4Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM4 = true;
      RCol4Row4 = 16;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 26;
      }
    } else if (c4R4VNo === 7) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol4Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol4Row4 = 7;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 17;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM4 = true;
    RCol4Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM4 = true;
      RCol4Row4 = 17;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 27;
      }
    } else if (c4R4VNo === 8) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol4Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol4Row4 = 8;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 18;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM4 = true;
    RCol4Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM4 = true;
      RCol4Row4 = 18;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 28;
      }
    } else if (c4R4VNo === 9) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol4Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol4Row4 = 9;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
       if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 19;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM4 = true;
    RCol4Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM4 = true;
      RCol4Row4 = 19;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 29;
      }
    } else if (c4R4VNo === 10) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol4Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol4Row4 = 10;
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
    RCol4Row4 = 20;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM4 = true;
    RCol4Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM4 = true;
      RCol4Row4 = 20;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
        RCol4Row4 = 30;
      }
    } else if (c4R4VNo === 11) {
      if (c4r4MT === "S") { 
        if (col03Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM4 = false;
        nextL4 = false;
      } else if (c4r4MT === "M") {
        if (nextM4 === true) {
	  nextM4 = false;
        } else {
	  if (col03Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM4 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM4 = true;
	  }
	}
      } else if (c4r4MT === "L") {
        nextL4 = true;
      }
    }
  }

  if (col05Row04 === true) {
    if (c5R4VNo === 1) {
      if (c5r4MT === "S") { 
	 if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol5Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol5Row4 = 1;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
	if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 11;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM5 = true;
    RCol5Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM5 = true;
      RCol5Row4 = 11;
	  }
	}
        
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 21;
      }
    } else if (c5R4VNo === 2) {
      if (c5r4MT === "S") { 
	if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol5Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol5Row4 = 2;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 12;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM5 = true;
    RCol5Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM5 = true;
      RCol5Row4 = 12;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 22;
      }
    } else if (c5R4VNo === 3) {
      if (c5r4MT === "S") { 
       if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol5Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol5Row4 = 3;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 13;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM5 = true;
    RCol5Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM5 = true;
      RCol5Row4 = 13;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 23;
      }
    } else if (c5R4VNo === 4) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol5Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol5Row4 = 4;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 14;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM5 = true;
    RCol5Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM5 = true;
      RCol5Row4 = 14;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 24;
      }
    } else if (c5R4VNo === 5) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol5Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol5Row4 = 5;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 15;
        } else {
	 if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM5 = true;
    RCol5Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM5 = true;
      RCol5Row4 = 15;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 25;
      }
    } else if (c5R4VNo === 6) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol5Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol5Row4 = 6;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 16;
        } else {
	 if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM5 = true;
    RCol5Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM5 = true;
      RCol5Row4 = 16;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 26;
      }
    } else if (c5R4VNo === 7) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol5Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol5Row4 = 7;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 17;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM5 = true;
    RCol5Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM5 = true;
      RCol5Row4 = 17;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 27;
      }
    } else if (c5R4VNo === 8) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol5Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol5Row4 = 8;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 18;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM5 = true;
    RCol5Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM5 = true;
      RCol5Row4 = 18;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 28;
      }
    } else if (c5R4VNo === 9) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol5Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol5Row4 = 9;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
       if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 19;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM5 = true;
    RCol5Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM5 = true;
      RCol5Row4 = 19;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 29;
      }
    } else if (c5R4VNo === 10) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol5Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol5Row4 = 10;
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
    RCol5Row4 = 20;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM5 = true;
    RCol5Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM5 = true;
      RCol5Row4 = 20;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
        RCol5Row4 = 30;
      }
    } else if (c5R4VNo === 11) {
      if (c5r4MT === "S") { 
        if (col04Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM5 = false;
        nextL5 = false;
      } else if (c5r4MT === "M") {
        if (nextM5 === true) {
	  nextM5 = false;
        } else {
	  if (col04Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM5 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM5 = true;
	  }
	}
      } else if (c5r4MT === "L") {
        nextL5 = true;
      }
    }
  }

  if (col06Row04 === true) {
    if (c6R4VNo === 1) {
      if (c6r4MT === "S") { 
	 if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol6Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol6Row4 = 1;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
	if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 11;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM6 = true;
    RCol6Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM6 = true;
      RCol6Row4 = 11;
	  }
	}
        
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 21;
      }
    } else if (c6R4VNo === 2) {
      if (c6r4MT === "S") { 
	if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol6Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol6Row4 = 2;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 12;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM6 = true;
    RCol6Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM6 = true;
      RCol6Row4 = 12;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 22;
      }
    } else if (c6R4VNo === 3) {
      if (c6r4MT === "S") { 
       if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol6Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol6Row4 = 3;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 13;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM6 = true;
    RCol6Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM6 = true;
      RCol6Row4 = 13;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 23;
      }
    } else if (c6R4VNo === 4) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol6Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol6Row4 = 4;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 14;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM6 = true;
    RCol6Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM6 = true;
      RCol6Row4 = 14;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 24;
      }
    } else if (c6R4VNo === 5) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol6Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol6Row4 = 5;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 15;
        } else {
	 if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM6 = true;
    RCol6Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM6 = true;
      RCol6Row4 = 15;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 25;
      }
    } else if (c6R4VNo === 6) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol6Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol6Row4 = 6;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 16;
        } else {
	 if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM6 = true;
    RCol6Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM6 = true;
      RCol6Row4 = 16;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 26;
      }
    } else if (c6R4VNo === 7) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol6Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol6Row4 = 7;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 17;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM6 = true;
    RCol6Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM6 = true;
      RCol6Row4 = 17;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 27;
      }
    } else if (c6R4VNo === 8) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol6Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol6Row4 = 8;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 18;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM6 = true;
    RCol6Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM6 = true;
      RCol6Row4 = 18;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 28;
      }
    } else if (c6R4VNo === 9) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol6Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol6Row4 = 9;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
       if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 19;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM6 = true;
    RCol6Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM6 = true;
      RCol6Row4 = 19;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 29;
      }
    } else if (c6R4VNo === 10) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol6Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol6Row4 = 10;
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
    RCol6Row4 = 20;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM6 = true;
    RCol6Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM6 = true;
      RCol6Row4 = 20;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
        RCol6Row4 = 30;
      }
    } else if (c6R4VNo === 11) {
      if (c6r4MT === "S") { 
        if (col05Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM6 = false;
        nextL6 = false;
      } else if (c6r4MT === "M") {
        if (nextM6 === true) {
	  nextM6 = false;
        } else {
	  if (col05Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM6 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM6 = true;
	  }
	}
      } else if (c6r4MT === "L") {
        nextL6 = true;
      }
    }
  }

  if (col07Row04 === true) {
    if (c7R4VNo === 1) {
      if (c7r4MT === "S") { 
	 if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol7Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol7Row4 = 1;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
	if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 11;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM7 = true;
    RCol7Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM7 = true;
      RCol7Row4 = 11;
	  }
	}
        
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 21;
      }
    } else if (c7R4VNo === 2) {
      if (c7r4MT === "S") { 
	if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol7Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol7Row4 = 2;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 12;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM7 = true;
    RCol7Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM7 = true;
      RCol7Row4 = 12;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 22;
      }
    } else if (c7R4VNo === 3) {
      if (c7r4MT === "S") { 
       if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol7Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol7Row4 = 3;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 13;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM7 = true;
    RCol7Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM7 = true;
      RCol7Row4 = 13;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 23;
      }
    } else if (c7R4VNo === 4) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol7Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol7Row4 = 4;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 14;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM7 = true;
    RCol7Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM7 = true;
      RCol7Row4 = 14;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 24;
      }
    } else if (c7R4VNo === 5) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol7Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol7Row4 = 5;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 15;
        } else {
	 if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM7 = true;
    RCol7Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM7 = true;
      RCol7Row4 = 15;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 25;
      }
    } else if (c7R4VNo === 6) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol7Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol7Row4 = 6;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 16;
        } else {
	 if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM7 = true;
    RCol7Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM7 = true;
      RCol7Row4 = 16;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 26;
      }
    } else if (c7R4VNo === 7) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol7Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol7Row4 = 7;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 17;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM7 = true;
    RCol7Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM7 = true;
      RCol7Row4 = 17;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 27;
      }
    } else if (c7R4VNo === 8) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol7Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol7Row4 = 8;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 18;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM7 = true;
    RCol7Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM7 = true;
      RCol7Row4 = 18;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 28;
      }
    } else if (c7R4VNo === 9) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol7Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol7Row4 = 9;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
       if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 19;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM7 = true;
    RCol7Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM7 = true;
      RCol7Row4 = 19;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 29;
      }
    } else if (c7R4VNo === 10) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol7Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol7Row4 = 10;
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
    RCol7Row4 = 20;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM7 = true;
    RCol7Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM7 = true;
      RCol7Row4 = 20;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
        RCol7Row4 = 30;
      }
    } else if (c7R4VNo === 11) {
      if (c7r4MT === "S") { 
        if (col06Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM7 = false;
        nextL7 = false;
      } else if (c7r4MT === "M") {
        if (nextM7 === true) {
	  nextM7 = false;
        } else {
	  if (col06Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM7 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM7 = true;
	  }
	}
      } else if (c7r4MT === "L") {
        nextL7 = true;
      }
    }
  }

  if (col08Row04 === true) {
    if (c8R4VNo === 1) {
      if (c8r4MT === "S") { 
	 if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol8Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol8Row4 = 1;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
	if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 11;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM8 = true;
    RCol8Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM8 = true;
      RCol8Row4 = 11;
	  }
	}
        
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 21;
      }
    } else if (c8R4VNo === 2) {
      if (c8r4MT === "S") { 
	if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol8Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol8Row4 = 2;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 12;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM8 = true;
    RCol8Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM8 = true;
      RCol8Row4 = 12;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 22;
      }
    } else if (c8R4VNo === 3) {
      if (c8r4MT === "S") { 
       if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol8Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol8Row4 = 3;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 13;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM8 = true;
    RCol8Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM8 = true;
      RCol8Row4 = 13;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 23;
      }
    } else if (c8R4VNo === 4) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol8Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol8Row4 = 4;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 14;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM8 = true;
    RCol8Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM8 = true;
      RCol8Row4 = 14;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 24;
      }
    } else if (c8R4VNo === 5) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol8Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol8Row4 = 5;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 15;
        } else {
	 if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM8 = true;
    RCol8Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM8 = true;
      RCol8Row4 = 15;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 25;
      }
    } else if (c8R4VNo === 6) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol8Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol8Row4 = 6;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 16;
        } else {
	 if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM8 = true;
    RCol8Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM8 = true;
      RCol8Row4 = 16;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 26;
      }
    } else if (c8R4VNo === 7) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol8Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol8Row4 = 7;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 17;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM8 = true;
    RCol8Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM8 = true;
      RCol8Row4 = 17;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 27;
      }
    } else if (c8R4VNo === 8) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol8Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol8Row4 = 8;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 18;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM8 = true;
    RCol8Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM8 = true;
      RCol8Row4 = 18;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 28;
      }
    } else if (c8R4VNo === 9) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol8Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol8Row4 = 9;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
       if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 19;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM8 = true;
    RCol8Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM8 = true;
      RCol8Row4 = 19;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 29;
      }
    } else if (c8R4VNo === 10) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol8Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol8Row4 = 10;
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
    RCol8Row4 = 20;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM8 = true;
    RCol8Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM8 = true;
      RCol8Row4 = 20;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
        RCol8Row4 = 30;
      }
    } else if (c8R4VNo === 11) {
      if (c8r4MT === "S") { 
        if (col07Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM8 = false;
        nextL8 = false;
      } else if (c8r4MT === "M") {
        if (nextM8 === true) {
	  nextM8 = false;
        } else {
	  if (col07Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM8 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM8 = true;
	  }
	}
      } else if (c8r4MT === "L") {
        nextL8 = true;
      }
    }
  }


  if (col09Row04 === true) {
    if (c9R4VNo === 1) {
      if (c9r4MT === "S") { 
	 if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol9Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol9Row4 = 1;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
	if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 11;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM9 = true;
    RCol9Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM9 = true;
      RCol9Row4 = 11;
	  }
	}
        
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 21;
      }
    } else if (c9R4VNo === 2) {
      if (c9r4MT === "S") { 
	if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol9Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol9Row4 = 2;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 12;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM9 = true;
    RCol9Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM9 = true;
      RCol9Row4 = 12;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 22;
      }
    } else if (c9R4VNo === 3) {
      if (c9r4MT === "S") { 
       if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol9Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol9Row4 = 3;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 13;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM9 = true;
    RCol9Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM9 = true;
      RCol9Row4 = 13;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 23;
      }
    } else if (c9R4VNo === 4) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol9Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol9Row4 = 4;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 14;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM9 = true;
    RCol9Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM9 = true;
      RCol9Row4 = 14;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 24;
      }
    } else if (c9R4VNo === 5) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol9Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol9Row4 = 5;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 15;
        } else {
	 if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM9 = true;
    RCol9Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM9 = true;
      RCol9Row4 = 15;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 25;
      }
    } else if (c9R4VNo === 6) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol9Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol9Row4 = 6;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 16;
        } else {
	 if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM9 = true;
    RCol9Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM9 = true;
      RCol9Row4 = 16;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 26;
      }
    } else if (c9R4VNo === 7) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol9Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol9Row4 = 7;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 17;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM9 = true;
    RCol9Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM9 = true;
      RCol9Row4 = 17;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 27;
      }
    } else if (c9R4VNo === 8) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol9Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol9Row4 = 8;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 18;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM9 = true;
    RCol9Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM9 = true;
      RCol9Row4 = 18;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 28;
      }
    } else if (c9R4VNo === 9) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol9Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol9Row4 = 9;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
       if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 19;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM9 = true;
    RCol9Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM9 = true;
      RCol9Row4 = 19;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 29;
      }
    } else if (c9R4VNo === 10) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol9Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol9Row4 = 10;
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
    RCol9Row4 = 20;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM9 = true;
    RCol9Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM9 = true;
      RCol9Row4 = 20;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
        RCol9Row4 = 30;
      }
    } else if (c9R4VNo === 11) {
      if (c9r4MT === "S") { 
        if (col08Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM9 = false;
        nextL9 = false;
      } else if (c9r4MT === "M") {
        if (nextM9 === true) {
	  nextM9 = false;
        } else {
	  if (col08Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM9 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM9 = true;
	  }
	}
      } else if (c9r4MT === "L") {
        nextL9 = true;
      }
    }
  }


  if (col10Row04 === true) {
    if (c10R4VNo === 1) {
      if (c10r4MT === "S") { 
	 if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol10Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol10Row4 = 1;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
	if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 11;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM10 = true;
    RCol10Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM10 = true;
      RCol10Row4 = 11;
	  }
	}
        
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 21;
      }
    } else if (c10R4VNo === 2) {
      if (c10r4MT === "S") { 
	if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol10Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol10Row4 = 2;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 12;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM10 = true;
    RCol10Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM10 = true;
      RCol10Row4 = 12;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 22;
      }
    } else if (c10R4VNo === 3) {
      if (c10r4MT === "S") { 
       if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol10Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol10Row4 = 3;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 13;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM10 = true;
    RCol10Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM10 = true;
      RCol10Row4 = 13;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 23;
      }
    } else if (c10R4VNo === 4) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol10Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol10Row4 = 4;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 14;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM10 = true;
    RCol10Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM10 = true;
      RCol10Row4 = 14;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 24;
      }
    } else if (c10R4VNo === 5) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol10Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol10Row4 = 5;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 15;
        } else {
	 if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM10 = true;
    RCol10Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM10 = true;
      RCol10Row4 = 15;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 25;
      }
    } else if (c10R4VNo === 6) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol10Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol10Row4 = 6;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 16;
        } else {
	 if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM10 = true;
    RCol10Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM10 = true;
      RCol10Row4 = 16;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 26;
      }
    } else if (c10R4VNo === 7) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol10Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol10Row4 = 7;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 17;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM10 = true;
    RCol10Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM10 = true;
      RCol10Row4 = 17;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 27;
      }
    } else if (c10R4VNo === 8) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol10Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol10Row4 = 8;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 18;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM10 = true;
    RCol10Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM10 = true;
      RCol10Row4 = 18;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 28;
      }
    } else if (c10R4VNo === 9) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol10Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol10Row4 = 9;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
       if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 19;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM10 = true;
    RCol10Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM10 = true;
      RCol10Row4 = 19;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 29;
      }
    } else if (c10R4VNo === 10) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol10Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol10Row4 = 10;
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
    RCol10Row4 = 20;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM10 = true;
    RCol10Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM10 = true;
      RCol10Row4 = 20;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
        RCol10Row4 = 30;
      }
    } else if (c10R4VNo === 11) {
      if (c10r4MT === "S") { 
        if (col09Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM10 = false;
        nextL10 = false;
      } else if (c10r4MT === "M") {
        if (nextM10 === true) {
	  nextM10 = false;
        } else {
	  if (col09Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM10 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM10 = true;
	  }
	}
      } else if (c10r4MT === "L") {
        nextL10 = true;
      }
    }
  }

  if (col11Row04 === true) {
    if (c11R4VNo === 1) {
      if (c11r4MT === "S") { 
	 if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol11Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol11Row4 = 1;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
	if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 11;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM11 = true;
    RCol11Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM11 = true;
      RCol11Row4 = 11;
	  }
	}
        
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 21;
      }
    } else if (c11R4VNo === 2) {
      if (c11r4MT === "S") { 
	if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol11Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol11Row4 = 2;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 12;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM11 = true;
    RCol11Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM11 = true;
      RCol11Row4 = 12;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 22;
      }
    } else if (c11R4VNo === 3) {
      if (c11r4MT === "S") { 
       if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol11Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol11Row4 = 3;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 13;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM11 = true;
    RCol11Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM11 = true;
      RCol11Row4 = 13;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 23;
      }
    } else if (c11R4VNo === 4) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol11Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol11Row4 = 4;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 14;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM11 = true;
    RCol11Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM11 = true;
      RCol11Row4 = 14;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 24;
      }
    } else if (c11R4VNo === 5) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol11Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol11Row4 = 5;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 15;
        } else {
	 if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM11 = true;
    RCol11Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM11 = true;
      RCol11Row4 = 15;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 25;
      }
    } else if (c11R4VNo === 6) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol11Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol11Row4 = 6;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 16;
        } else {
	 if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM11 = true;
    RCol11Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM11 = true;
      RCol11Row4 = 16;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 26;
      }
    } else if (c11R4VNo === 7) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol11Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol11Row4 = 7;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 17;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM11 = true;
    RCol11Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM11 = true;
      RCol11Row4 = 17;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 27;
      }
    } else if (c11R4VNo === 8) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol11Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol11Row4 = 8;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 18;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM11 = true;
    RCol11Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM11 = true;
      RCol11Row4 = 18;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 28;
      }
    } else if (c11R4VNo === 9) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol11Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol11Row4 = 9;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
       if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 19;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM11 = true;
    RCol11Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM11 = true;
      RCol11Row4 = 19;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 29;
      }
    } else if (c11R4VNo === 10) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol11Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol11Row4 = 10;
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
    RCol11Row4 = 20;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM11 = true;
    RCol11Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM11 = true;
      RCol11Row4 = 20;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
        RCol11Row4 = 30;
      }
    } else if (c11R4VNo === 11) {
      if (c11r4MT === "S") { 
        if (col10Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM11 = false;
        nextL11 = false;
      } else if (c11r4MT === "M") {
        if (nextM11 === true) {
	  nextM11 = false;
        } else {
	  if (col10Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM11 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM11 = true;
	  }
	}
      } else if (c11r4MT === "L") {
        nextL11 = true;
      }
    }
  }

  if (col12Row04 === true) {
    if (c12R4VNo === 1) {
      if (c12r4MT === "S") { 
	 if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol12Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol12Row4 = 1;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
	if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 11;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM12 = true;
    RCol12Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM12 = true;
      RCol12Row4 = 11;
	  }
	}
        
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 21;
      }
    } else if (c12R4VNo === 2) {
      if (c12r4MT === "S") { 
	if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol12Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol12Row4 = 2;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 12;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM12 = true;
    RCol12Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM12 = true;
      RCol12Row4 = 12;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 22;
      }
    } else if (c12R4VNo === 3) {
      if (c12r4MT === "S") { 
       if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol12Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol12Row4 = 3;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 13;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM12 = true;
    RCol12Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM12 = true;
      RCol12Row4 = 13;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 23;
      }
    } else if (c12R4VNo === 4) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol12Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol12Row4 = 4;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 14;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM12 = true;
    RCol12Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM12 = true;
      RCol12Row4 = 14;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 24;
      }
    } else if (c12R4VNo === 5) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol12Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol12Row4 = 5;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 15;
        } else {
	 if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM12 = true;
    RCol12Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM12 = true;
      RCol12Row4 = 15;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 25;
      }
    } else if (c12R4VNo === 6) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol12Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol12Row4 = 6;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 16;
        } else {
	 if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM12 = true;
    RCol12Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM12 = true;
      RCol12Row4 = 16;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 26;
      }
    } else if (c12R4VNo === 7) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol12Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol12Row4 = 7;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 17;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM12 = true;
    RCol12Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM12 = true;
      RCol12Row4 = 17;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 27;
      }
    } else if (c12R4VNo === 8) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol12Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol12Row4 = 8;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 18;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM12 = true;
    RCol12Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM12 = true;
      RCol12Row4 = 18;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 28;
      }
    } else if (c12R4VNo === 9) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol12Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol12Row4 = 9;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
       if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 19;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM12 = true;
    RCol12Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM12 = true;
      RCol12Row4 = 19;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
      }
    } else if (c12R4VNo === 10) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol12Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol12Row4 = 10;
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
    RCol12Row4 = 20;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM12 = true;
    RCol12Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM12 = true;
      RCol12Row4 = 20;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
        RCol12Row4 = 30;
      }
    } else if (c12R4VNo === 11) {
      if (c12r4MT === "S") { 
        if (col11Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM12 = false;
        nextL12 = false;
      } else if (c12r4MT === "M") {
        if (nextM12 === true) {
	  nextM12 = false;
        } else {
	  if (col11Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM12 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM12 = true;
	  }
	}
      } else if (c12r4MT === "L") {
        nextL12 = true;
      }
    }
  }

  if (col13Row04 === true) {
    if (c13R4VNo === 1) {
      if (c13r4MT === "S") { 
	 if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol13Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol13Row4 = 1;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
	if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 11;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM13 = true;
    RCol13Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM13 = true;
      RCol13Row4 = 11;
	  }
	}
        
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 21;
      }
    } else if (c13R4VNo === 2) {
      if (c13r4MT === "S") { 
	if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol13Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol13Row4 = 2;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 12;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM13 = true;
    RCol13Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM13 = true;
      RCol13Row4 = 12;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 22;
      }
    } else if (c13R4VNo === 3) {
      if (c13r4MT === "S") { 
       if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol13Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol13Row4 = 3;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 13;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM13 = true;
    RCol13Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM13 = true;
      RCol13Row4 = 13;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 23;
      }
    } else if (c13R4VNo === 4) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol13Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol13Row4 = 4;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 14;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM13 = true;
    RCol13Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM13 = true;
      RCol13Row4 = 14;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 24;
      }
    } else if (c13R4VNo === 5) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol13Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol13Row4 = 5;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 15;
        } else {
	 if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM13 = true;
    RCol13Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM13 = true;
      RCol13Row4 = 15;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 25;
      }
    } else if (c13R4VNo === 6) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol13Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol13Row4 = 6;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 16;
        } else {
	 if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM13 = true;
    RCol13Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM13 = true;
      RCol13Row4 = 16;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 26;
      }
    } else if (c13R4VNo === 7) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol13Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol13Row4 = 7;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 17;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM13 = true;
    RCol13Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM13 = true;
      RCol13Row4 = 17;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 27;
      }
    } else if (c13R4VNo === 8) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol13Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol13Row4 = 8;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 18;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM13 = true;
    RCol13Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM13 = true;
      RCol13Row4 = 18;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 28;
      }
    } else if (c13R4VNo === 9) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol13Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol13Row4 = 9;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
       if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 19;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM13 = true;
    RCol13Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM13 = true;
      RCol13Row4 = 19;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 29;
      }
    } else if (c13R4VNo === 10) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol13Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol13Row4 = 10;
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
    RCol13Row4 = 20;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM13 = true;
    RCol13Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM13 = true;
      RCol13Row4 = 20;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
        RCol13Row4 = 30;
      }
    } else if (c13R4VNo === 11) {
      if (c13r4MT === "S") { 
        if (col12Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM13 = false;
        nextL13 = false;
      } else if (c13r4MT === "M") {
        if (nextM13 === true) {
	  nextM13 = false;
        } else {
	  if (col12Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM13 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM13 = true;
	  }
	}
      } else if (c13r4MT === "L") {
        nextL13 = true;
      }
    }
  }

  if (col14Row04 === true) {
    if (c14R4VNo === 1) {
      if (c14r4MT === "S") { 
	 if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V1;
          smallUpSideV1();
          RCol14Row4 = 1;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V1;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V1;
          smallBaseUpV1();
          RCol14Row4 = 1;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
	if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 11;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V1;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V1;
            mediumUpSideV1();
		nextM14 = true;
    RCol14Row4 = 11;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V1;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V1;
          mediumBaseUpV1();
	  	nextM14 = true;
      RCol14Row4 = 11;
	  }
	}
        
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 21;
      }
    } else if (c14R4VNo === 2) {
      if (c14r4MT === "S") { 
	if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V2;
          smallUpSideV2();
          RCol14Row4 = 2;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V2;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V2;
          smallBaseUpV2();
          RCol14Row4 = 2;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 12;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V2;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V2;
            mediumUpSideV2();
		nextM14 = true;
    RCol14Row4 = 12;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V2;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V2;
          mediumBaseUpV2();
	  	nextM14 = true;
      RCol14Row4 = 12;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 22;
      }
    } else if (c14R4VNo === 3) {
      if (c14r4MT === "S") { 
       if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V3;
          smallUpSideV3();
          RCol14Row4 = 3;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V3;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V3;
          smallBaseUpV3();
          RCol14Row4 = 3;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 13;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V3;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V3;
            mediumUpSideV3();
		nextM14 = true;
    RCol14Row4 = 13;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V3;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V3;
          mediumBaseUpV3();
	  	nextM14 = true;
      RCol14Row4 = 13;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 23;
      }
    } else if (c14R4VNo === 4) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V4;
          smallUpSideV4();
          RCol14Row4 = 4;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V4;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V4;
          smallBaseUpV4();
          RCol14Row4 = 4;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 14;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V4;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V4;
            mediumUpSideV4();
		nextM14 = true;
    RCol14Row4 = 14;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V4;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V4;
          mediumBaseUpV4();
	  	nextM14 = true;
      RCol14Row4 = 14;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 24;
      }
    } else if (c14R4VNo === 5) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V5;
          smallUpSideV5();
          RCol14Row4 = 5;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V5;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V5;
          smallBaseUpV5();
          RCol14Row4 = 5;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 15;
        } else {
	 if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V5;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V5;
            mediumUpSideV5();
		nextM14 = true;
    RCol14Row4 = 15;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V5;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V5;
          mediumBaseUpV5();
	  	nextM14 = true;
      RCol14Row4 = 15;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 25;
      }
    } else if (c14R4VNo === 6) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V6;
          smallUpSideV6();
          RCol14Row4 = 6;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V6;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V6;
          smallBaseUpV6();
          RCol14Row4 = 6;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 16;
        } else {
	 if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V6;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V6;
            mediumUpSideV6();
		nextM14 = true;
    RCol14Row4 = 16;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V6;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V6;
          mediumBaseUpV6();
	  	nextM14 = true;
      RCol14Row4 = 16;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 26;
      }
    } else if (c14R4VNo === 7) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V7;
          smallUpSideV7();
          RCol14Row4 = 7;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V7;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V7;
          smallBaseUpV7();
          RCol14Row4 = 7;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 17;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V7;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V7;
            mediumUpSideV7();
		nextM14 = true;
    RCol14Row4 = 17;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V7;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V7;
          mediumBaseUpV7();
	  	nextM14 = true;
      RCol14Row4 = 17;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 27;
      }
    } else if (c14R4VNo === 8) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V8;
          smallUpSideV8();
          RCol14Row4 = 8;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V8;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V8;
          smallBaseUpV8();
          RCol14Row4 = 8;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 18;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V8;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V8;
            mediumUpSideV8();
		nextM14 = true;
    RCol14Row4 = 18;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V8;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V8;
          mediumBaseUpV8();
	  	nextM14 = true;
      RCol14Row4 = 18;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 28;
      }
    } else if (c14R4VNo === 9) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V9;
          smallUpSideV9();
          RCol14Row4 = 9;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V9;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V9;
          smallBaseUpV9();
          RCol14Row4 = 9;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
       if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 19;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V9;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V9;
            mediumUpSideV9();
		nextM14 = true;
    RCol14Row4 = 19;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V9;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V9;
          mediumBaseUpV9();
	  	nextM14 = true;
      RCol14Row4 = 19;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
        RCol14Row4 = 29;
      }
    } else if (c14R4VNo === 10) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V10;
          smallUpSideV10();
          RCol14Row4 = 10;
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V10;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V10;
          smallBaseUpV10();
          RCol14Row4 = 10;
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
    RCol14Row4 = 20;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V10;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V10;
            mediumUpSideV10();
		nextM14 = true;
    RCol14Row4 = 20;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V10;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V10;
          mediumBaseUpV10();
	  	nextM14 = true;
      RCol14Row4 = 20;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
      }
    } else if (c14R4VNo === 11) {
      if (c14r4MT === "S") { 
        if (col13Row04 === true) {
        	currentPrice = currentPrice + PRICE_SMALL_UP_SIDE_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_SIDE_V11;
          smallUpSideV11();
	} else {
		currentPrice = currentPrice + PRICE_SMALL_UP_V11;
        	currentWeight = currentWeight + WEIGHT_SMALL_UP_V11;
          smallBaseUpV11();
	}
        nextM14 = false;
        nextL14 = false;
      } else if (c14r4MT === "M") {
        if (nextM14 === true) {
	  nextM14 = false;
        } else {
	  if (col13Row04 === true) {
	  	currentPrice = currentPrice + PRICE_MEDIUM_UP_SIDE_V11;
          	currentWeight = currentWeight + WEIGHT_MEDIUM_UP_SIDE_V11;
            mediumUpSideV11();
		nextM14 = true;
	  } else {
		currentPrice = currentPrice + PRICE_MEDIUM_UP_V11;
        	currentWeight = currentWeight + WEIGHT_MEDIUML_UP_V11;
          mediumBaseUpV11();
	  	nextM14 = true;
	  }
	}
      } else if (c14r4MT === "L") {
        nextL14 = true;
      }
    }
  }

  if (sepOnM1C1 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol1Row1 = RCol1Row1 + 0.1;
    RCol1Row2 = RCol1Row2 + 0.1;
  }

  if (sepOnM1C2 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol2Row1 = RCol2Row1 + 0.1;
    RCol2Row2 = RCol2Row2 + 0.1;
  }

  if (sepOnM1C3 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol3Row1 = RCol3Row1 + 0.1;
    RCol3Row2 = RCol3Row2 + 0.1;
  }

  if (sepOnM1C4 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol4Row1 = RCol4Row1 + 0.1;
    RCol4Row2 = RCol4Row2 + 0.1;
  }

  if (sepOnM1C5 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol5Row1 = RCol5Row1 + 0.1;
    RCol5Row2 = RCol5Row2 + 0.1;
  }

  if (sepOnM1C6 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol6Row1 = RCol6Row1 + 0.1;
    RCol6Row2 = RCol6Row2 + 0.1;
  }

  if (sepOnM1C7 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol7Row1 = RCol7Row1 + 0.1;
    RCol7Row2 = RCol7Row2 + 0.1;
  }

  if (sepOnM1C8 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol8Row1 = RCol8Row1 + 0.1;
    RCol8Row2 = RCol8Row2 + 0.1;
  }

  if (sepOnM1C9 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol9Row1 = RCol9Row1 + 0.1;
    RCol9Row2 = RCol9Row2 + 0.1;
  }

  if (sepOnM1C10 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol10Row1 = RCol10Row1 + 0.1;
    RCol10Row2 = RCol10Row2 + 0.1;
  }

  if (sepOnM1C11 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol11Row1 = RCol11Row1 + 0.1;
    RCol11Row2 = RCol11Row2 + 0.1;
  }

  if (sepOnM1C12 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol12Row1 = RCol12Row1 + 0.1;
    RCol12Row2 = RCol12Row2 + 0.1;
  }

  if (sepOnM1C13 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol13Row1 = RCol13Row1 + 0.1;
    RCol13Row2 = RCol13Row2 + 0.1;
  }

  if (sepOnM1C14 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol14Row1 = RCol14Row1 + 0.1;
    RCol14Row2 = RCol14Row2 + 0.1;
  }

  if (sepOnM2C14 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol14Row2 = RCol14Row2 + 0.1;
    RCol14Row3 = RCol14Row3 + 0.1;
  }

  if (sepOnM2C1 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol1Row2 = RCol1Row2 + 0.1;
    RCol1Row3 = RCol1Row3 + 0.1;
  }

  if (sepOnM2C2 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol2Row2 = RCol2Row2 + 0.1;
    RCol2Row3 = RCol2Row3 + 0.1;
  }

  if (sepOnM2C3 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol3Row2 = RCol3Row2 + 0.1;
    RCol3Row3 = RCol3Row3 + 0.1;
  }

  if (sepOnM2C4 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol4Row2 = RCol4Row2 + 0.1;
    RCol4Row3 = RCol4Row3 + 0.1;
  }

  if (sepOnM2C5 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol5Row2 = RCol5Row2 + 0.1;
    RCol5Row3 = RCol5Row3 + 0.1;
  }

  if (sepOnM2C6 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol6Row2 = RCol6Row2 + 0.1;
    RCol6Row3 = RCol6Row3 + 0.1;
  }

  if (sepOnM2C7 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol7Row2 = RCol7Row2 + 0.1;
    RCol7Row3 = RCol7Row3 + 0.1;
  }

  if (sepOnM2C8 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol8Row2 = RCol8Row2 + 0.1;
    RCol8Row3 = RCol8Row3 + 0.1;
  }

  if (sepOnM2C9 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol9Row2 = RCol9Row2 + 0.1;
    RCol9Row3 = RCol9Row3 + 0.1;
  }

  if (sepOnM2C10 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol10Row2 = RCol10Row2 + 0.1;
    RCol10Row3 = RCol10Row3 + 0.1;
  }

  if (sepOnM2C11 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol11Row2 = RCol11Row2 + 0.1;
    RCol11Row3 = RCol11Row3 + 0.1;
  }

  if (sepOnM2C12 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol12Row2 = RCol12Row2 + 0.1;
    RCol12Row3 = RCol12Row3 + 0.1;
  }

  if (sepOnM2C13 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol13Row2 = RCol13Row2 + 0.1;
    RCol13Row3 = RCol13Row3 + 0.1;
  }

  if (sepOnM3C1 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol1Row3 = RCol1Row3 + 0.1;
    RCol1Row4 = RCol1Row4 + 0.1;
  }

  if (sepOnM3C2 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol2Row3 = RCol2Row3 + 0.1;
    RCol2Row4 = RCol2Row4 + 0.1;
  }

  if (sepOnM3C3 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol3Row3 = RCol3Row3 + 0.1;
    RCol3Row4 = RCol3Row4 + 0.1;
  }

  if (sepOnM3C4 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol4Row3 = RCol4Row3 + 0.1;
    RCol4Row4 = RCol4Row4 + 0.1;
  }

  if (sepOnM3C5 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol5Row3 = RCol5Row3 + 0.1;
    RCol5Row4 = RCol5Row4 + 0.1;
  }

  if (sepOnM3C6 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol6Row3 = RCol6Row3 + 0.1;
    RCol6Row4 = RCol6Row4 + 0.1;
  }

  if (sepOnM3C7 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol7Row3 = RCol7Row3 + 0.1;
    RCol7Row4 = RCol7Row4 + 0.1;
  }

  if (sepOnM3C8 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol8Row3 = RCol8Row3 + 0.1;
    RCol8Row4 = RCol8Row4 + 0.1;
  }

  if (sepOnM3C9 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol9Row3 = RCol9Row3 + 0.1;
    RCol9Row4 = RCol9Row4 + 0.1;
  }

  if (sepOnM3C10 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol10Row3 = RCol10Row3 + 0.1;
    RCol10Row4 = RCol10Row4 + 0.1;
  }

  if (sepOnM3C11 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol11Row3 = RCol11Row3 + 0.1;
    RCol11Row4 = RCol11Row4 + 0.1;
  }

  if (sepOnM3C12 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol12Row3 = RCol12Row3 + 0.1;
    RCol12Row4 = RCol12Row4 + 0.1;
  }

  if (sepOnM3C13 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol13Row3 = RCol13Row3 + 0.1;
    RCol13Row4 = RCol13Row4 + 0.1;
  }

  if (sepOnM3C14 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol14Row3 = RCol14Row3 + 0.1;
    RCol14Row4 = RCol14Row4 + 0.1;
  }

  if (sepOnLC1 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol1Row1 = RCol1Row1 + 0.1;
    RCol1Row2 = RCol1Row2 + 0.1;
    RCol1Row3 = RCol1Row3 + 0.1;
    RCol1Row4 = RCol1Row4 + 0.1;
  }

  if (sepOnLC2 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol2Row1 = RCol2Row1 + 0.1;
    RCol2Row2 = RCol2Row2 + 0.1;
    RCol2Row3 = RCol2Row3 + 0.1;
    RCol2Row4 = RCol2Row4 + 0.1;
  }

  if (sepOnLC3 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol3Row1 = RCol3Row1 + 0.1;
    RCol3Row2 = RCol3Row2 + 0.1;
    RCol3Row3 = RCol3Row3 + 0.1;
    RCol3Row4 = RCol3Row4 + 0.1;
  }

  if (sepOnLC4 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol4Row1 = RCol4Row1 + 0.1;
    RCol4Row2 = RCol4Row2 + 0.1;
    RCol4Row3 = RCol4Row3 + 0.1;
    RCol4Row4 = RCol4Row4 + 0.1;
  }

  if (sepOnLC5 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol5Row1 = RCol5Row1 + 0.1;
    RCol5Row2 = RCol5Row2 + 0.1;
    RCol5Row3 = RCol5Row3 + 0.1;
    RCol5Row4 = RCol5Row4 + 0.1;
  }

  if (sepOnLC6 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol6Row1 = RCol6Row1 + 0.1;
    RCol6Row2 = RCol6Row2 + 0.1;
    RCol6Row3 = RCol6Row3 + 0.1;
    RCol6Row4 = RCol6Row4 + 0.1;
  }

  if (sepOnLC7 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol7Row1 = RCol7Row1 + 0.1;
    RCol7Row2 = RCol7Row2 + 0.1;
    RCol7Row3 = RCol7Row3 + 0.1;
    RCol7Row4 = RCol7Row4 + 0.1;
  }

  if (sepOnLC8 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol8Row1 = RCol8Row1 + 0.1;
    RCol8Row2 = RCol8Row2 + 0.1;
    RCol8Row3 = RCol8Row3 + 0.1;
    RCol8Row4 = RCol8Row4 + 0.1;
  }

  if (sepOnLC9 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol9Row1 = RCol9Row1 + 0.1;
    RCol9Row2 = RCol9Row2 + 0.1;
    RCol9Row3 = RCol9Row3 + 0.1;
    RCol9Row4 = RCol9Row4 + 0.1;
  }

  if (sepOnLC10 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol10Row1 = RCol10Row1 + 0.1;
    RCol10Row2 = RCol10Row2 + 0.1;
    RCol10Row3 = RCol10Row3 + 0.1;
    RCol10Row4 = RCol10Row4 + 0.1;
  }

  if (sepOnLC11 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol11Row1 = RCol11Row1 + 0.1;
    RCol11Row2 = RCol11Row2 + 0.1;
    RCol11Row3 = RCol11Row3 + 0.1;
    RCol11Row4 = RCol11Row4 + 0.1;
  }

  if (sepOnLC12 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol1Row1 = RCol1Row1 + 0.1;
    RCol1Row2 = RCol1Row2 + 0.1;
    RCol1Row3 = RCol1Row3 + 0.1;
    RCol1Row4 = RCol1Row4 + 0.1;
  }

  if (sepOnLC13 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol13Row1 = RCol13Row1 + 0.1;
    RCol13Row2 = RCol13Row2 + 0.1;
    RCol13Row3 = RCol13Row3 + 0.1;
    RCol13Row4 = RCol13Row4 + 0.1;
  }

  if (sepOnLC14 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol14Row1 = RCol14Row1 + 0.1;
    RCol14Row2 = RCol14Row2 + 0.1;
    RCol14Row3 = RCol14Row3 + 0.1;
    RCol14Row4 = RCol14Row4 + 0.1;
  }

  if (sepOn2LC1 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol1Row1 = RCol1Row1 + 0.3;
    RCol1Row2 = RCol1Row2 + 0.3;
    RCol1Row3 = RCol1Row3 + 0.3;
    RCol1Row4 = RCol1Row4 + 0.3;
  }

  if (sepOn2LC2 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol2Row1 = RCol2Row1 + 0.3;
    RCol2Row2 = RCol2Row2 + 0.3;
    RCol2Row3 = RCol2Row3 + 0.3;
    RCol2Row4 = RCol2Row4 + 0.3;
  }

  if (sepOn2LC3 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol3Row1 = RCol3Row1 + 0.3;
    RCol3Row2 = RCol3Row2 + 0.3;
    RCol3Row3 = RCol3Row3 + 0.3;
    RCol3Row4 = RCol3Row4 + 0.3;
  }

  if (sepOn2LC4 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol4Row1 = RCol4Row1 + 0.3;
    RCol4Row2 = RCol4Row2 + 0.3;
    RCol4Row3 = RCol4Row3 + 0.3;
    RCol4Row4 = RCol4Row4 + 0.3;
  }
  if (sepOn2LC5 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol5Row1 = RCol5Row1 + 0.3;
    RCol5Row2 = RCol5Row2 + 0.3;
    RCol5Row3 = RCol5Row3 + 0.3;
    RCol5Row4 = RCol5Row4 + 0.3;
  }

  if (sepOn2LC6 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol6Row1 = RCol6Row1 + 0.3;
    RCol6Row2 = RCol6Row2 + 0.3;
    RCol6Row3 = RCol6Row3 + 0.3;
    RCol6Row4 = RCol6Row4 + 0.3;
  }

  if (sepOn2LC7 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol7Row1 = RCol7Row1 + 0.3;
    RCol7Row2 = RCol7Row2 + 0.3;
    RCol7Row3 = RCol7Row3 + 0.3;
    RCol7Row4 = RCol7Row4 + 0.3;
  }

  if (sepOn2LC8 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol8Row1 = RCol8Row1 + 0.3;
    RCol8Row2 = RCol8Row2 + 0.3;
    RCol8Row3 = RCol8Row3 + 0.3;
    RCol8Row4 = RCol8Row4 + 0.3;
  }

  if (sepOn2LC9 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol9Row1 = RCol9Row1 + 0.3;
    RCol9Row2 = RCol9Row2 + 0.3;
    RCol9Row3 = RCol9Row3 + 0.3;
    RCol9Row4 = RCol9Row4 + 0.3;
  }

  if (sepOn2LC10 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol10Row1 = RCol10Row1 + 0.3;
    RCol10Row2 = RCol10Row2 + 0.3;
    RCol10Row3 = RCol10Row3 + 0.3;
    RCol10Row4 = RCol10Row4 + 0.3;
  }

  if (sepOn2LC11 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol11Row1 = RCol11Row1 + 0.3;
    RCol11Row2 = RCol11Row2 + 0.3;
    RCol11Row3 = RCol11Row3 + 0.3;
    RCol11Row4 = RCol11Row4 + 0.3;
  }

  if (sepOn2LC12 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol12Row1 = RCol12Row1 + 0.3;
    RCol12Row2 = RCol12Row2 + 0.3;
    RCol12Row3 = RCol12Row3 + 0.3;
    RCol12Row4 = RCol12Row4 + 0.3;
  }

  if (sepOn2LC13 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol13Row1 = RCol13Row1 + 0.3;
    RCol13Row2 = RCol13Row2 + 0.3;
    RCol13Row3 = RCol13Row3 + 0.3;
    RCol13Row4 = RCol13Row4 + 0.3;
  }

  if (sepOn2LC14 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol14Row1 = RCol14Row1 + 0.3;
    RCol14Row2 = RCol14Row2 + 0.3;
    RCol14Row3 = RCol14Row3 + 0.3;
    RCol14Row4 = RCol14Row4 + 0.3;
  }

  if (sepOn3LC1 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol1Row1 = RCol1Row1 + 0.5;
    RCol1Row2 = RCol1Row2 + 0.5;
    RCol1Row3 = RCol1Row3 + 0.5;
    RCol1Row4 = RCol1Row4 + 0.5;
  }

  if (sepOn3LC2 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol2Row1 = RCol2Row1 + 0.5;
    RCol2Row2 = RCol2Row2 + 0.5;
    RCol2Row3 = RCol2Row3 + 0.5;
    RCol2Row4 = RCol2Row4 + 0.5;
  }

  if (sepOn3LC3 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol3Row1 = RCol3Row1 + 0.5;
    RCol3Row2 = RCol3Row2 + 0.5;
    RCol3Row3 = RCol3Row3 + 0.5;
    RCol3Row4 = RCol3Row4 + 0.5;
  }

  if (sepOn3LC4 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol4Row1 = RCol4Row1 + 0.5;
    RCol4Row2 = RCol4Row2 + 0.5;
    RCol4Row3 = RCol4Row3 + 0.5;
    RCol4Row4 = RCol4Row4 + 0.5;
  }

  if (sepOn3LC5 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol5Row1 = RCol5Row1 + 0.5;
    RCol5Row2 = RCol5Row2 + 0.5;
    RCol5Row3 = RCol5Row3 + 0.5;
    RCol5Row4 = RCol5Row4 + 0.5;
  }

  if (sepOn3LC6 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol6Row1 = RCol6Row1 + 0.5;
    RCol6Row2 = RCol6Row2 + 0.5;
    RCol6Row3 = RCol6Row3 + 0.5;
    RCol6Row4 = RCol6Row4 + 0.5;
  }

  if (sepOn3LC7 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol7Row1 = RCol7Row1 + 0.5;
    RCol7Row2 = RCol7Row2 + 0.5;
    RCol7Row3 = RCol7Row3 + 0.5;
    RCol7Row4 = RCol7Row4 + 0.5;
  }

  if (sepOn3LC8 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol8Row1 = RCol8Row1 + 0.5;
    RCol8Row2 = RCol8Row2 + 0.5;
    RCol8Row3 = RCol8Row3 + 0.5;
    RCol8Row4 = RCol8Row4 + 0.5;
  }

  if (sepOn3LC9 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol9Row1 = RCol9Row1 + 0.5;
    RCol9Row2 = RCol9Row2 + 0.5;
    RCol9Row3 = RCol9Row3 + 0.5;
    RCol9Row4 = RCol9Row4 + 0.5;
  }

  if (sepOn3LC10 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol10Row1 = RCol10Row1 + 0.5;
    RCol10Row2 = RCol10Row2 + 0.5;
    RCol10Row3 = RCol10Row3 + 0.5;
    RCol10Row4 = RCol10Row4 + 0.5;
  }

  if (sepOn3LC11 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol11Row1 = RCol11Row1 + 0.5;
    RCol11Row2 = RCol11Row2 + 0.5;
    RCol11Row3 = RCol11Row3 + 0.5;
    RCol11Row4 = RCol11Row4 + 0.5;
  }

  if (sepOn3LC12 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol12Row1 = RCol12Row1 + 0.5;
    RCol12Row2 = RCol12Row2 + 0.5;
    RCol12Row3 = RCol12Row3 + 0.5;
    RCol12Row4 = RCol12Row4 + 0.5;
  }

  if (sepOn3LC13 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol13Row1 = RCol13Row1 + 0.5;
    RCol13Row2 = RCol13Row2 + 0.5;
    RCol13Row3 = RCol13Row3 + 0.5;
    RCol13Row4 = RCol13Row4 + 0.5;
  }

  if (sepOn3LC14 === true) {
    currentPrice = currentPrice + PRICE_SHELF_SEPERATOR;
    currentWeight = currentWeight + WEIGHT_SHELF_SEPERATOR;
    middleSeperators = middleSeperators + 1;
    RCol14Row1 = RCol14Row1 + 0.5;
    RCol14Row2 = RCol14Row2 + 0.5;
    RCol14Row3 = RCol14Row3 + 0.5;
    RCol14Row4 = RCol14Row4 + 0.5;
  }

  let PriceRemainder = currentPrice % 50;
  currentPrice = currentPrice - PriceRemainder;
  currentWeight = currentWeight /1000;
  setPrice(currentPrice);
  setWeight(currentWeight);
  priceX = currentPrice;
  weightX = currentWeight;

}

const showDoors = (e)=> {
  doorRemoved = false;
  if (curX === col01X && curY === row01X) {
    if (c1R1VNo === 8) {
      if (doorTypec1r1 === 9) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(9);
          setC1R2VNo(9);
        } else if (c1r1MT === "L") {
          alert("Round Handle cannot be added to the Large Door.");
        } else if (c1r1MT === "S") {
            alert("Select Medium or Large Module and proceed.");
        } 
      } else if (doorTypec1r1 === 10) {
        if (c1r1MT === "M" && c1r2MT === "M") {
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(10);
          setC1R2VNo(10);
        } else if (c1r1MT === "L") {
          setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )

          setC1R1VNo(10);
          setC1R2VNo(10);
          setC1R3VNo(10);
          setC1R4VNo(10);
        } else if (c1r1MT === "S") {
            alert("Select Medium or Large Module and proceed.");
        } 
      } else {
        if (c1r1MT === "M" && c1r2MT === "M") {
       
          setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
          setC1R1VNo(11);
          setC1R2VNo(11);
          setC1r1MT("M");
          setC1r2MT("M");
        } else if (c1r1MT === "L") {
          setBaseNakedL01(
                 [
                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                   ]
               )
             setC1R1VNo(11);
             setC1R2VNo(11);
             setC1R3VNo(11);
             setC1R4VNo(11);
             setC1r1MT("L")
             setC1r2MT("L")
             setC1r3MT("L")
             setC1r4MT("L")
        } else if (c1r1MT === "S") {
          alert("Select Medium or Large Module and proceed.");
        } 
      }
     
    } else {
      alert("You cannot do this as no doors were associated with this module.");
    }


  } else if (curX === col02X && curY === row01X) {
    if (c2R1VNo === 8) {
      if (doorTypec2r1 === 9) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(9);
          setC2R2VNo(9);
        } else if (c2r1MT === "L") {
          alert("Round Handle cannot be added to the Large Door.");
        } else if (c2r1MT === "S") {
            alert("Select Medium or Large Module and proceed.");
        } 
      } else if (doorTypec2r1 === 10) {
        if (c2r1MT === "M" && c2r2MT === "M") {
          setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
          setC2R1VNo(10);
          setC2R2VNo(10);
        } else if (c2r1MT === "L") {
          setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )

          setC2R1VNo(10);
          setC2R2VNo(10);
          setC2R3VNo(10);
          setC2R4VNo(10);
        } else if (c2r1MT === "S") {
            alert("Select Medium or Large Module and proceed.");
        } 
      } else {
        if (c2r1MT === "M" && c2r2MT === "M") {
          setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
            setC2R1VNo(11);
            setC2R2VNo(11);
            setC2r1MT("M");
            setC2r2MT("M");
        } else if (c2r1MT === "L") {
          setBaseNakedL02(
                [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                  ]
              )
            setC2R1VNo(11);
            setC2R2VNo(11);
            setC2R3VNo(11);
            setC2R4VNo(11);
            setC2r1MT("L")
            setC2r2MT("L")
            setC2r3MT("L")
            setC2r4MT("L")
        } else if (c2r1MT === "S") {
          alert("Select Medium or Large Module and proceed.");
        } 
      }
    } else {
      alert("You cannot do this as no doors were associated with this module.");
    }
    } else if (curX === col03X && curY === row01X) {
      if (c3R1VNo === 8) {
        if (doorTypec3r1 === 9) {
          if (c3r1MT === "M" && c3r2MT === "M") {
            setBaseNakedM03(
              [
                 <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />      
              ]
            )
            setC3R1VNo(9);
            setC3R2VNo(9);
          } else if (c3r1MT === "L") {
            alert("Round Handle cannot be added to the Large Door.");
          } else if (c3r1MT === "S") {
              alert("Select Medium or Large Module and proceed.");
          } 
        } else if (doorTypec3r1 === 10) {
          if (c3r1MT === "M" && c3r2MT === "M") {
            setBaseNakedM03(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />     
              ]
            )
            setC3R1VNo(10);
            setC3R2VNo(10);
          } else if (c3r1MT === "L") {
            setBaseNakedL03(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
              ]
            )

            setC3R1VNo(10);
            setC3R2VNo(10);
            setC3R3VNo(10);
            setC3R4VNo(10);
          } else if (c3r1MT === "S") {
              alert("Select Medium or Large Module and proceed.");
          } 
        } else {
          if (c3r1MT === "M" && c3r2MT === "M") {
            setBaseNakedM03(
                [
                < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />        
                ]
              )
              setC3R1VNo(11);
              setC3R2VNo(11);
              setC3r1MT("M");
              setC3r2MT("M");
           } else if (c3r1MT === "L") {
          setBaseNakedL03(
               [
                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                 ]
             )
           setC3R1VNo(11);
           setC3R2VNo(11);
           setC3R3VNo(11);
           setC3R4VNo(11);
           setC3r1MT("L")
           setC3r2MT("L")
           setC3r3MT("L")
           setC3r4MT("L")
              } else if (c3r1MT === "S") {
                alert("Select Medium or Large Module and proceed.");
              } 
        }
       
            } else {
              alert("You cannot do this as no doors were associated with this module.");
            }
      } else if (curX === col04X && curY === row01X) {
        if (c4R1VNo === 8) {
          if (doorTypec4r1 === 9) {
            if (c4r1MT === "M" && c4r2MT === "M") {
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />       
              ]
              )
              setC4R1VNo(9);
              setC4R2VNo(9);
            } else if (c4r1MT === "L") {
              alert("Round Handle cannot be added to the Large Door.");
            } else if (c4r1MT === "S") {
                alert("Select Medium or Large Module and proceed.");
            }
          } else if (doorTypec4r1 === 10) {
            if (c4r1MT === "M" && c4r2MT === "M") {
              setBaseNakedM04(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />    
              ]
              )
              setC4R1VNo(10);
              setC4R2VNo(10);
            } else if (c4r1MT === "L") {
              setBaseNakedL04(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                ]
              )

              setC4R1VNo(10);
              setC4R2VNo(10);
              setC4R3VNo(10);
              setC4R4VNo(10);
            } else if (c4r1MT === "S") {
                alert("Select Medium or Large Module and proceed.");
            }
          } else {
            if (c4r1MT === "M" && c4r2MT === "M") {
              setBaseNakedM04(
                    [
                    < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />          
                  ]
                  )
              setC4R1VNo(11);
              setC4R2VNo(11);
              setC4r1MT("M");
              setC4r2MT("M");
          } else if (c4r1MT === "L") {
                  setBaseNakedL04(
                    [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                      ]
                  )
                setC4R1VNo(11);
                setC4R2VNo(11);
                setC4R3VNo(11);
                setC4R4VNo(11);
                setC4r1MT("L")
                setC4r2MT("L")
                setC4r3MT("L")
                setC4r4MT("L")
              } else if (c4r1MT === "S") {
                alert("Select Medium or Large Module and proceed.");
              } 
          }
      
            } else {
              alert("You cannot do this as no doors were associated with this module.");
            }
        } else if (curX === col05X && curY === row01X) {
          if (c5R1VNo === 8) {
            if (doorTypec5r1 === 9) {
              if (c5r1MT === "M" && c5r2MT === "M") {
                setBaseNakedM05(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />       
                 ]
                )
                setC5R1VNo(9);
                setC5R2VNo(9);
              } else if (c5r1MT === "L") {
                alert("Round Handle cannot be added to the Large Door.");
              } else if (c5r1MT === "S") {
                  alert("Select Medium or Large Module and proceed.");
              } 
            } else if (doorTypec5r1 === 10) {
              if (c5r1MT === "M" && c5r2MT === "M") {
                setBaseNakedM05(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />     
                 ]
                )
                setC5R1VNo(10);
                setC5R2VNo(10);
              } else if (c5r1MT === "L") {
                setBaseNakedL05(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                  ]
                )
    
                setC5R1VNo(10);
                setC5R2VNo(10);
                setC5R3VNo(10);
                setC5R4VNo(10);
              } else if (c5r1MT === "S") {
                  alert("Select Medium or Large Module and proceed.");
              } 
            } else {
              if (c5r1MT === "M" && c5r2MT === "M") {
                setBaseNakedM05(
                        [
                        < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />         
                      ]
                      )
                  setC5R1VNo(11);
                  setC5R2VNo(11);
                  setC5r1MT("M");
                  setC5r2MT("M");
              } else if (c5r1MT === "L") {
                      setBaseNakedL05(
                      [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                        ]
                    )
                  setC5R1VNo(11);
                  setC5R2VNo(11);
                  setC5R3VNo(11);
                  setC5R4VNo(11);
                  setC5r1MT("L")
                  setC5r2MT("L")
                  setC5r3MT("L")
                  setC5r4MT("L")
                } else if (c5r1MT === "S") {
                      alert("Select Medium or Large Module and proceed.");
                    } 
            }
           
                } else {
                  alert("You cannot do this as no doors were associated with this module.");
                }
          } else if (curX === col06X && curY === row01X) {
            if (c6R1VNo === 8) {
              if (doorTypec6r1 === 9) {
                if (c6r1MT === "M" && c6r2MT === "M") {
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />     
                    ]
                  )
                  setC6R1VNo(9);
                  setC6R2VNo(9);
                } else if (c6r1MT === "L") {
                  alert("Round Handle cannot be added to the Large Door.");
                } else if (c6r1MT === "S") {
                    alert("Select Medium or Large Module and proceed.");
                } 
              } else if (doorTypec6r1 === 10) {
                if (c6r1MT === "M" && c6r2MT === "M") {
                  setBaseNakedM06(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
                  setC6R1VNo(10);
                  setC6R2VNo(10);
                } else if (c6r1MT === "L") {
                  setBaseNakedL06(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                    ]
                  )
    
                  setC6R1VNo(10);
                  setC6R2VNo(10);
                  setC6R3VNo(10);
                  setC6R4VNo(10);
                } else if (c6r1MT === "S") {
                    alert("Select Medium or Large Module and proceed.");
                } 
              } else {
                if (c6r1MT === "M" && c6r2MT === "M") {
                  setBaseNakedM06(
                            [
                            < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />        
                            ]
                          )
                      setC6R1VNo(11);
                      setC6R2VNo(11);
                      setC6r1MT("M");
                      setC6r2MT("M");
                    } else if (c6r1MT === "L") {
                                setBaseNakedL06(
                              [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                            )
                          setC6R1VNo(11);
                          setC6R2VNo(11);
                          setC6R3VNo(11);
                          setC6R4VNo(11);
                          setC6r1MT("L")
                          setC6r2MT("L")
                          setC6r3MT("L")
                  setC6r4MT("L")
                  } else if (c6r1MT === "S") {
                    alert("Select Medium or Large Module and proceed.");
                  } 
              }
            
                  } else {
                    alert("You cannot do this as no doors were associated with this module.");
                  }
            } else if (curX === col07X && curY === row01X) {
              if (c7R1VNo === 8) {
                if (doorTypec7r1 === 9) {
                  if (c7r1MT === "M" && c7r2MT === "M") {
                    setBaseNakedM07(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(9);
                    setC7R2VNo(9);
                  } else if (c7r1MT === "L") {
                    alert("Round Handle cannot be added to the Large Door.");
                  } else if (c7r1MT === "S") {
                      alert("Select Medium or Large Module and proceed.");
                  } 
                } else if (doorTypec7r1 === 10) {
                  if (c7r1MT === "M" && c7r2MT === "M") {
                    setBaseNakedM07(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
                    setC7R1VNo(10);
                    setC7R2VNo(10);
                  } else if (c7r1MT === "L") {
                    setBaseNakedL07(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                      ]
                    )
    
                    setC7R1VNo(10);
                    setC7R2VNo(10);
                    setC7R3VNo(10);
                    setC7R4VNo(10);
                  } else if (c7r1MT === "S") {
                      alert("Select Medium or Large Module and proceed.");
                  } 
                } else {
                  if (c7r1MT === "M" && c7r2MT === "M") {
                    setBaseNakedM07(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                    ]
                  )
                  setC7R1VNo(11);
                  setC7R2VNo(11);
                  setC7r1MT("M");
                  setC7r2MT("M");
                } else if (c7r1MT === "L") {
                  setBaseNakedL07(
                        [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                          ]
                      )
                    setC7R1VNo(11);
                    setC7R2VNo(11);
                    setC7R3VNo(11);
                    setC7R4VNo(11);
                    setC7r1MT("L")
                    setC7r2MT("L")
                    setC7r3MT("L")
                    setC7r4MT("L")
                      } else if (c7r1MT === "S") {
                        alert("Select Medium or Large Module and proceed.");
                      } 
                }

                    } else {
                      alert("You cannot do this as no doors were associated with this module.");
                    }
              } else if (curX === col08X && curY === row01X) {
                if (c8R1VNo === 8) {
                  if (doorTypec8r1 === 9) {
                    if (c8r1MT === "M" && c8r2MT === "M") {
                      setBaseNakedM08(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                        ]
                      )
                      setC8R1VNo(9);
                      setC8R2VNo(9);
                    } else if (c8r1MT === "L") {
                      alert("Round Handle cannot be added to the Large Door.");
                    } else if (c8r1MT === "S") {
                        alert("Select Medium or Large Module and proceed.");
                    } 
                  } else if (doorTypec8r1 === 10) {
                    if (c8r1MT === "M" && c8r2MT === "M") {
                      setBaseNakedM08(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                        ]
                      )
                      setC8R1VNo(10);
                      setC8R2VNo(10);
                    } else if (c8r1MT === "L") {
                      setBaseNakedL08(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                        ]
                      )
      
                      setC8R1VNo(10);
                      setC8R2VNo(10);
                      setC8R3VNo(10);
                      setC8R4VNo(10);
                    } else if (c8r1MT === "S") {
                        alert("Select Medium or Large Module and proceed.");
                    } 
                  } else {
                    if (c8r1MT === "M" && c8r2MT === "M") {
                      setBaseNakedM08(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                      setC8R1VNo(11);
                      setC8R2VNo(11);
                      setC8r1MT("M");
                      setC8r2MT("M");
                    } else if (c8r1MT === "L") {
                                      setBaseNakedL08(
                                [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                  ]
                              )
                            setC8R1VNo(11);
                            setC8R2VNo(11);
                            setC8R3VNo(11);
                            setC8R4VNo(11);
                            setC8r1MT("L")
                            setC8r2MT("L")
                            setC8r3MT("L")
                            setC8r4MT("L")
                          } else if (c8r1MT === "S") {
                            alert("Select Medium or Large Module and proceed.");
                          } 
                  }
                  
                      } else {
                        alert("You cannot do this as no doors were associated with this module.");
                      }
                } else if (curX === col09X && curY === row01X) {
                  if (c9R1VNo === 8) {
                    if (doorTypec9r1 === 9) {
                      if (c9r1MT === "M" && c9r2MT === "M") {
                        setBaseNakedM09(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                          ]
                        )
                        setC9R1VNo(9);
                        setC9R2VNo(9);
                      } else if (c9r1MT === "L") {
                        alert("Round Handle cannot be added to the Large Door.");
                      } else if (c9r1MT === "S") {
                          alert("Select Medium or Large Module and proceed.");
                      } 
                    } else if (doorTypec9r1 === 10) {
                      if (c9r1MT === "M" && c9r2MT === "M") {
                        setBaseNakedM09(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                          ]
                        )
                        setC9R1VNo(10);
                        setC9R2VNo(10);  
                      } else if (c9r1MT === "L") {
                        setBaseNakedL09(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                          ]
                        )
            
                        setC9R1VNo(10);
                        setC9R2VNo(10);
                        setC9R3VNo(10);
                        setC9R4VNo(10);
                      } else if (c9r1MT === "S") {
                          alert("Select Medium or Large Module and proceed.");
                      } 
                    } else {
                      if (c9r1MT === "M" && c9r2MT === "M") {
                        setBaseNakedM09(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                        setC9R1VNo(11);
                        setC9R2VNo(11);
                        setC9r1MT("M");
                        setC9r2MT("M");
                      } else if (c9r1MT === "L") {
                                      setBaseNakedL09(
                              [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                ]
                            )
                          setC9R1VNo(11);
                          setC9R2VNo(11);
                          setC9R3VNo(11);
                          setC9R4VNo(11);
                          setC9r1MT("L")
                          setC9r2MT("L")
                          setC9r3MT("L")
                          setC9r4MT("L")
                            } else if (c9r1MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                            }
                    }
                 
                  } else {
                    alert("You cannot do this as no doors were associated with this module.");
                  }
                  } else if (curX === col10X && curY === row01X) {
                    if (c10R1VNo === 8) {
                      if (doorTypec10r1 === 9) {
                        if (c10r1MT === "M" && c10r2MT === "M") {
                          setBaseNakedM10(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(9);
                          setC10R2VNo(9);
                        } else if (c10r1MT === "L") {
                          alert("Round Handle cannot be added to the Large Door.");
                        } else if (c10r1MT === "S") {
                            alert("Select Medium or Large Module and proceed.");
                        } 
                      } else if (doorTypec10r1 === 10) {
                        if (c10r1MT === "M" && c10r2MT === "M") {
                          setBaseNakedM10(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
                          setC10R1VNo(10);
                          setC10R2VNo(10);  
                        } else if (c10r1MT === "L") {
                          setBaseNakedL10(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                            ]
                          )
            
                          setC10R1VNo(10);
                          setC10R2VNo(10);
                          setC10R3VNo(10);
                          setC10R4VNo(10);
                        } else if (c10r1MT === "S") {
                            alert("Select Medium or Large Module and proceed.");
                        } 
                      } else {
                        if (c10r1MT === "M" && c10r2MT === "M") {
                          setBaseNakedM10(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                            ]
                            )
                        setC10R1VNo(11);
                        setC10R2VNo(11);
                        setC10r1MT("M");
                        setC10r2MT("M");
                        } else if (c10r1MT === "L") {
                              setBaseNakedL10(
                              [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                            )
                          setC10R1VNo(11);
                          setC10R2VNo(11);
                          setC10R3VNo(11);
                          setC10R4VNo(11);

                          setC10r1MT("L")
                          setC10r2MT("L")
                          setC10r3MT("L")
                          setC10r4MT("L")
                            } else if (c10r1MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                            }
                      }
                     
                    } else {
                      alert("You cannot do this as no doors were associated with this module.");
                    }
                    } else if (curX === col11X && curY === row01X) {
                      if (c11R1VNo === 8) {
                        if (doorTypec11r1 === 9) {
                          if (c11r1MT === "M" && c11r2MT === "M") {
                            setBaseNakedM11(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(9);
                            setC11R2VNo(9);
                          } else if (c11r1MT === "L") {
                            alert("Round Handle cannot be added to the Large Door.");
                          } else if (c11r1MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                          } 
                        } else if (doorTypec11r1 === 10) {
                          if (c11r1MT === "M" && c11r2MT === "M") {
                            setBaseNakedM11(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
                            setC11R1VNo(10);
                            setC11R2VNo(10);
                          } else if (c11r1MT === "L") {
                            setBaseNakedL11(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                              ]
                            )
              
                            setC11R1VNo(10);
                            setC11R2VNo(10);
                            setC11R3VNo(10);
                            setC11R4VNo(10);
                          } else if (c11r1MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                          } 
                        } else {
                          if (c11r1MT === "M" && c11r2MT === "M") {
                                                setBaseNakedM11(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                              setC11R1VNo(11);
                              setC11R2VNo(11);
                              setC11r1MT("M");
                              setC11r2MT("M");
                            } else if (c11r1MT === "L") {
                            setBaseNakedL11(
                                  [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                    ]
                                )
                              setC11R1VNo(11);
                              setC11R2VNo(11);
                              setC11R3VNo(11);
                              setC11R4VNo(11);
                              setC11r1MT("L")
                              setC11r2MT("L")
                              setC11r3MT("L")
                              setC11r4MT("L")
                            } else if (c11r1MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                            }
                        }
                        
                        } else {
                          alert("You cannot do this as no doors were associated with this module.");
                        }
                      } else if (curX === col12X && curY === row01X) {
                        if (c12R1VNo === 8) {
                          if (doorTypec12r1 === 9) {
                            if (c12r1MT === "M" && c12r2MT === "M") {
                              setBaseNakedM12(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(9);
                              setC12R2VNo(9);
                            } else if (c12r1MT === "L") {
                              alert("Round Handle cannot be added to the Large Door.");
                            } else if (c12r1MT === "S") {
                                alert("Select Medium or Large Module and proceed.");
                            }
                          } else if (doorTypec12r1 === 10) {
                            if (c12r1MT === "M" && c12r2MT === "M") {
                              setBaseNakedM12(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
                              setC12R1VNo(10);
                              setC12R2VNo(10);
                            } else if (c12r1MT === "L") {
                              setBaseNakedL12(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                ]
                              )
              
                              setC12R1VNo(10);
                              setC12R2VNo(10);
                              setC12R3VNo(10);
                              setC12R4VNo(10);
                            } else if (c12r1MT === "S") {
                                alert("Select Medium or Large Module and proceed.");
                            }
                          } else {
                            if (c12r1MT === "M" && c12r2MT === "M") {
                              setBaseNakedM12(
                                 [
                                   <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />  
                                 ]
                               )
                               setC12R1VNo(11);
                               setC12R2VNo(11);
                               setC12r1MT("M");
                               setC12r2MT("M");
                           } else if (c12r1MT === "L") {
       
                               setBaseNakedL12(
                                   [
                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                     ]
                                 )
                               setC12R1VNo(11);
                               setC12R2VNo(11);
                               setC12R3VNo(11);
                               setC12R4VNo(11);
                               setC12r1MT("L")
                               setC12r2MT("L")
                               setC12r3MT("L")
                               setC12r4MT("L")
                            } else if (c12r1MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                            } 
                          }
                       
                              } else {
                                alert("You cannot do this as no doors were associated with this module.");
                              }
                        } else if (curX === col13X && curY === row01X) {
                          if (c13R1VNo === 8) {
                            if (doorTypec13r1 === 9) {
                              if (c13r1MT === "M" && c13r2MT === "M") {
                                setBaseNakedM13(
                                  [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} /> 
                                  ]
                                )
                                setC13R1VNo(9);
                                setC13R2VNo(9);
                              } else if (c13r1MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c13r1MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec13r1 === 10) {
                              if (c13r1MT === "M" && c13r2MT === "M") {
                                setBaseNakedM13(
                                  [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                                setC13R1VNo(10);
                                setC13R2VNo(10);
                              } else if (c13r1MT === "L") {
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                    
                                setC13R1VNo(10);
                                setC13R2VNo(10);
                                setC13R3VNo(10);
                                setC13R4VNo(10);
                              } else if (c13r1MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c13r1MT === "M" && c13r2MT === "M") {
                                setBaseNakedM13(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />  
                                      ]
                                    )
                                  setC13R1VNo(11);
                                  setC13R2VNo(11);
                                  setC13r1MT("M");
                                  setC13r2MT("M");
                              } else if (c13r1MT === "L") {
                               setBaseNakedL13(
                                    [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                  )
                                setC13R1VNo(11);
                                setC13R2VNo(11);
                                setC13R3VNo(11);
                                setC13R4VNo(11);
  
                                setC13r1MT("L")
                                setC13r2MT("L")
                                setC13r3MT("L")
                                setC13r4MT("L")
                            } else if (c13r1MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                            } 
                            }
                         
                          } else {
                            alert("You cannot do this as no doors were associated with this module.");
                          }
                          } else if (curX === col14X && curY === row01X) {
                            if (c14R1VNo === 8) {
                              if (doorTypec14r1 === 9) {
                                if (c14r1MT === "M" && c14r2MT === "M") {
                                  setBaseNakedM14(
                                    [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                    ]
                                  )
                                  setC14R1VNo(9);
                                  setC14R2VNo(9);
                                } else if (c14r1MT === "L") {
                                  alert("Round Handle cannot be added to the Large Door.");
                                } else if (c14r1MT === "S") {
                                    alert("Select Medium or Large Module and proceed.");
                                } 
                              } else if (doorTypec14r1 === 10) {
                                if (c14r1MT === "M" && c14r2MT === "M") {
                                  setBaseNakedM14(
                                    [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                    ]
                                  )
                                  setC14R1VNo(10);
                                  setC14R2VNo(10);
                                } else if (c14r1MT === "L") {
                                  setBaseNakedL14(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                    ]
                                  )
                      
                                  setC14R1VNo(10);
                                  setC14R2VNo(10);
                                  setC14R3VNo(10);
                                  setC14R4VNo(10);
                                } else if (c14r1MT === "S") {
                                    alert("Select Medium or Large Module and proceed.");
                                } 
                              } else {
                                if (c14r1MT === "M" && c14r2MT === "M") {
                                  setBaseNakedM14(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />  
                                    ]
                                  )
                                  setC14R1VNo(11);
                                  setC14R2VNo(11);
                                  setC14r1MT("M");
                                  setC14r2MT("M");
                                } else if (c14r1MT === "L") {
                                   setBaseNakedL14(
                                    [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                  )
                                setC14R1VNo(11);
                                setC14R2VNo(11);
                                setC14R3VNo(11);
                                setC14R4VNo(11);
  
                                setC14r1MT("L")
                                setC14r2MT("L")
                                setC14r3MT("L")
                                setC14r4MT("L")
                                      } else if (c14r1MT === "S") {
                                        alert("Select Medium or Large Module and proceed.");
                                      } 
                              }
                             
                    } else {
                      alert("You cannot do this as no doors were associated with this module.");
                    }
            } else if (curX === col01X && curY === row02X) {
              if (c1R2VNo === 8) {
                if (doorTypec1r2 === 9) {
                  if (c1r1MT === "M" && c1r2MT === "M") {
                    setBaseNakedM01(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(9);
                    setC1R2VNo(9);
                  } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                    setRow2NakedM01(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                      ]
                    )
                    setC1R2VNo(9);
                    setC1R3VNo(9);
                  } else if (c1r2MT === "L") {
                    alert("Round Handle cannot be added to the Large Door.");
                  } else if (c1r2MT === "S") {
                      alert("Select Medium or Large Module and proceed.");
                  } 
                } else if (doorTypec1r2 === 10) {
                  if (c1r1MT === "M" && c1r2MT === "M") {
                    setBaseNakedM01(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
                    setC1R1VNo(10);
                    setC1R2VNo(10);
                  } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                    setRow2NakedM01(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                      ]
                    )
                    setC1R2VNo(10);
                    setC1R3VNo(10);
                  } else if (c1r2MT === "L") {
                    setBaseNakedL01(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                      ]
                    )
        
                    setC1R1VNo(10);
                    setC1R2VNo(10);
                    setC1R3VNo(10);
                    setC1R4VNo(10);
                  } else if (c1r2MT === "S") {
                      alert("Select Medium or Large Module and proceed.");
                  } 
                } else {
                  if (c1r1MT === "M" && c1r2MT === "M") {
                    setBaseNakedM01(
                     [
                       <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
                     ]
                   )
                   setC1R1VNo(11);
                   setC1R2VNo(11);
                   setC1r1MT("M");
                   setC1r2MT("M");
                 } else if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                    setRow2NakedM01(
                       [
                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                       ]
                     )
                 setC1R2VNo(11);
                 setC1R3VNo(11);
                 setC1r2MT("M");
                 setC1r3MT("M");
                 } else if (c1r2MT === "L") {
                   setBaseNakedL01(
                       [
                           <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                         ]
                     )
                   setC1R1VNo(11);
                   setC1R2VNo(11);
                   setC1R3VNo(11);
                   setC1R4VNo(11);
                   setC1r1MT("L")
                   setC1r2MT("L")
                   setC1r3MT("L")
                   setC1r4MT("L")
                 } else if (c1r2MT === "S") {
                   alert("Select Medium or Large Module and proceed.");
                 } 
                }
               
              } else {
                alert("You cannot do this as no doors were associated with this module.");
              }
            }  else if (curX === col02X && curY === row02X) {
              if (c2R2VNo === 8) {
                if (doorTypec2r2 === 9) {
                  if (c2r1MT === "M" && c2r2MT === "M") {
                    setBaseNakedM02(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(9);
                    setC2R2VNo(9);
                  } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                    setRow2NakedM02(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(9);
                    setC2R3VNo(9);
                  } else if (c2r2MT === "L") {
                    alert("Round Handle cannot be added to the Large Door.");
                  } else if (c2r2MT === "S") {
                      alert("Select Medium or Large Module and proceed.");
                  } 
                } else if (doorTypec2r2 === 10) {
                  if (c2r1MT === "M" && c2r2MT === "M") {
                    setBaseNakedM02(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
                    setC2R1VNo(10);
                    setC2R2VNo(10);
                  } else if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                    setRow2NakedM02(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                      ]
                    )
                    setC2R2VNo(10);
                    setC2R3VNo(10);
                  } else if (c2r2MT === "L") {
                    setBaseNakedL02(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                      ]
                    )
          
                    setC2R1VNo(10);
                    setC2R2VNo(10);
                    setC2R3VNo(10);
                    setC2R4VNo(10);
                  } else if (c2r2MT === "S") {
                      alert("Select Medium or Large Module and proceed.");
                  } 
                } else {
                  if (c2r1MT === "M" && c2r2MT === "M") {
                    setBaseNakedM02(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                          ]
                          )
                      setC2R1VNo(11);
                      setC2R2VNo(11);
                      setC2r1MT("M");
                      setC2r2MT("M");
                  } else  if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                    setRow2NakedM02(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                        ]
                      )
                    setC2R2VNo(11);
                    setC2R3VNo(11);
                    setC2r2MT("M");
                    setC2r3MT("M");
                  } else if (c2r2MT === "L") {
                    setBaseNakedL02(
                          [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                            ]
                        )
                      setC2R1VNo(11);
                      setC2R2VNo(11);
                      setC2R3VNo(11);
                      setC2R4VNo(11);
                      setC2r1MT("L")
                      setC2r2MT("L")
                      setC2r3MT("L")
                      setC2r4MT("L")
                        } else if (c2r2MT === "S") {
                          alert("Select Medium or Large Module and proceed.");
                        } 
                }
                 
                      } else {
                        alert("You cannot do this as no doors were associated with this module.");
                      }
                } else if (curX === col03X && curY === row02X) {
                  if (c3R2VNo === 8) {
                    if (doorTypec3r2 === 9) {
                      if (c3r1MT === "M" && c3r2MT === "M") {
                        setBaseNakedM03(
                          [
                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />      
                          ]
                        )
                        setC3R1VNo(9);
                        setC3R2VNo(9);
                      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                        setRow2NakedM03(
                          [
                             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />      
                          ]
                        )
                        setC3R2VNo(9);
                        setC3R3VNo(9);
                      } else if (c3r2MT === "L") {
                        alert("Round Handle cannot be added to the Large Door.");
                      } else if (c3r2MT === "S") {
                          alert("Select Medium or Large Module and proceed.");
                      }
                    } else if (doorTypec3r2 === 10) {
                      if (c3r1MT === "M" && c3r2MT === "M") {
                        setBaseNakedM03(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />     
                          ]
                        )
                        setC3R1VNo(10);
                        setC3R2VNo(10);
                      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                        setRow2NakedM03(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />     
                          ]
                        )
                        setC3R2VNo(10);
                        setC3R3VNo(10);
                      } else if (c3r2MT === "L") {
                        setBaseNakedL03(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                          ]
                        )
            
                        setC3R1VNo(10);
                        setC3R2VNo(10);
                        setC3R3VNo(10);
                        setC3R4VNo(10);
                      } else if (c3r2MT === "S") {
                          alert("Select Medium or Large Module and proceed.");
                      }
                    } else {
                      if (c3r1MT === "M" && c3r2MT === "M") {
                        setBaseNakedM03(
                              [
                              < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />        
                              ]
                            )
                            setC3R1VNo(11);
                            setC3R2VNo(11);
                            setC3r1MT("M");
                            setC3r2MT("M");
                      } else if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                        setRow2NakedM03(
                            [
                            < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />        
                            ]
                          )
                          setC3R2VNo(11);
                          setC3R3VNo(11);
                          setC3r2MT("M");
                          setC3r3MT("M");
                      } else if (c3r2MT === "L") {
                        setBaseNakedL03(
                            [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                              ]
                          )
                        setC3R1VNo(11);
                        setC3R2VNo(11);
                        setC3R3VNo(11);
                        setC3R4VNo(11);
                        setC3r1MT("L")
                        setC3r2MT("L")
                        setC3r3MT("L")
                        setC3r4MT("L")
                            } else if (c3r2MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                            } 
                    }
                    
                        } else {
                          alert("You cannot do this as no doors were associated with this module.");
                        }
                  } else if (curX === col04X && curY === row02X) {
                    if (c4R2VNo === 8) {
                      if (doorTypec4r2 === 9) {
                        if (c4r1MT === "M" && c4r2MT === "M") {
                          setBaseNakedM04(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />       
                          ]
                          )
                          setC4R1VNo(9);
                          setC4R2VNo(9);
                        } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                          setRow2NakedM04(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />       
                          ]
                          )
                          setC4R2VNo(9);
                          setC4R3VNo(9);
                        } else if (c4r2MT === "L") {
                          alert("Round Handle cannot be added to the Large Door.");
                        } else if (c4r2MT === "S") {
                            alert("Select Medium or Large Module and proceed.");
                        }
                      } else if (doorTypec4r2 === 10) {
                        if (c4r1MT === "M" && c4r2MT === "M") {
                          setBaseNakedM04(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />    
                          ]
                          )
                          setC4R1VNo(10);
                          setC4R2VNo(10);
                        } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                          setRow2NakedM04(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />    
                          ]
                          )
                          setC4R2VNo(10);
                          setC4R3VNo(10);
                        } else if (c4r2MT === "L") {
                          setBaseNakedL04(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                            ]
                          )
            
                          setC4R1VNo(10);
                          setC4R2VNo(10);
                          setC4R3VNo(10);
                          setC4R4VNo(10);
                        } else if (c4r2MT === "S") {
                            alert("Select Medium or Large Module and proceed.");
                        }
                      } else {
                        if (c4r1MT === "M" && c4r2MT === "M") {
                          setBaseNakedM04(
                                 [
                                 < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />          
                               ]
                               )
                           setC4R1VNo(11);
                           setC4R2VNo(11);
                           setC4r1MT("M");
                           setC4r2MT("M");
                         } else if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                          setRow2NakedM04(
                             [
                             < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />          
                           ]
                           )
                           setC4R2VNo(11);
                           setC4R3VNo(11);
                           setC4r2MT("M");
                           setC4r3MT("M");
                         } else if (c4r2MT === "L") {
                            setBaseNakedL04(
                               [
                                   <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                 ]
                             )
                           setC4R1VNo(11);
                           setC4R2VNo(11);
                           setC4R3VNo(11);
                           setC4R4VNo(11);
                           setC4r1MT("L")
                           setC4r2MT("L")
                           setC4r3MT("L")
                           setC4r4MT("L")
                        } else if (c4r2MT === "S") {
                          alert("Select Medium or Large Module and proceed.");
                        } 
                      }
                      
                          } else {
                            alert("You cannot do this as no doors were associated with this module.");
                          }
                    } else if (curX === col05X && curY === row02X) {
                      if (c5R2VNo === 8) {
                        if (doorTypec5r2 === 9) {
                          if (c5r1MT === "M" && c5r2MT === "M") {
                            setBaseNakedM05(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />       
                             ]
                            )
                            setC5R1VNo(9);
                            setC5R2VNo(9);
                          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                            setRow2NakedM05(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />       
                             ]
                            )
                            setC5R2VNo(9);
                            setC5R3VNo(9);
                          } else if (c5r2MT === "L") {
                            alert("Round Handle cannot be added to the Large Door.");
                          } else if (c5r2MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                          } 
                        } else if (doorTypec5r2 === 10) {
                          if (c5r1MT === "M" && c5r2MT === "M") {
                            setBaseNakedM05(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />     
                             ]
                            )
                            setC5R1VNo(10);
                            setC5R2VNo(10);
                          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                            setRow2NakedM05(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />     
                            ]
                            )
                            setC5R2VNo(10);
                            setC5R3VNo(10);
                          } else if (c5r2MT === "L") {
                            setBaseNakedL05(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                              ]
                            )
                
                            setC5R1VNo(10);
                            setC5R2VNo(10);
                            setC5R3VNo(10);
                            setC5R4VNo(10);
                          } else if (c5r2MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                          } 
                        } else {
                       
                          if (c5r1MT === "M" && c5r2MT === "M") {
                            setBaseNakedM05(
                                  [
                                  < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />         
                                ]
                                )
                            setC5R1VNo(11);
                            setC5R2VNo(11);
                            setC5r1MT("M");
                            setC5r2MT("M");
                          } else if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                             setRow2NakedM05(
                              [
                              < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />         
                            ]
                            )
                            setC5R2VNo(11);
                            setC5R3VNo(11);
                            setC5r2MT("M");
                            setC5r3MT("M");
                          } else if (c5r2MT === "L") {
                             setBaseNakedL05(
                                [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                  ]
                              )
                            setC5R1VNo(11);
                            setC5R2VNo(11);
                            setC5R3VNo(11);
                            setC5R4VNo(11);
                            setC5r1MT("L")
                            setC5r2MT("L")
                            setC5r3MT("L")
                            setC5r4MT("L")
                                } else if (c5r2MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                                } 
                        }
                       
                            } else {
                              alert("You cannot do this as no doors were associated with this module.");
                            }
                      } else if (curX === col06X && curY === row02X) {
                        if (c6R2VNo === 8) {
                          if (doorTypec6r2 === 9) {
                            if (c6r1MT === "M" && c6r2MT === "M") {
                              setBaseNakedM06(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />     
                                ]
                              )
                              setC6R1VNo(9);
                              setC6R2VNo(9);
                            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                              setRow2NakedM06(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />     
                                ]
                              )
                              setC6R2VNo(9);
                              setC6R3VNo(9);
                            } else if (c6r2MT === "L") {
                              alert("Round Handle cannot be added to the Large Door.");
                            } else if (c6r2MT === "S") {
                                alert("Select Medium or Large Module and proceed.");
                            } 
                          } else if (doorTypec6r2 === 10) {
                            if (c6r1MT === "M" && c6r2MT === "M") {
                              setBaseNakedM06(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                              setC6R1VNo(10);
                              setC6R2VNo(10);
                            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                              setRow2NakedM06(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                ]
                              )
                              setC6R2VNo(10);
                              setC6R3VNo(10);
                            } else if (c6r2MT === "L") {
                              setBaseNakedL06(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                ]
                              )
                
                              setC6R1VNo(10);
                              setC6R2VNo(10);
                              setC6R3VNo(10);
                              setC6R4VNo(10);
                            } else if (c6r2MT === "S") {
                                alert("Select Medium or Large Module and proceed.");
                            } 
                          } else {
                            if (c6r1MT === "M" && c6r2MT === "M") {
                              setBaseNakedM06(
                                  [
                                  < Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />        
                                  ]
                                )
                            setC6R1VNo(11);
                            setC6R2VNo(11);
                            setC6r1MT("M");
                            setC6r2MT("M");
                            } else if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                              setRow2NakedM06(
                                  [
                                  < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />         
                                ]
                                )
                                setC6R2VNo(11);
                                setC6R3VNo(11);
                                setC6r2MT("M");
                                setC6r3MT("M");
                            } else if (c6r2MT === "L") {
                              setBaseNakedL06(
                                    [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                      ]
                                  )
                                setC6R1VNo(11);
                                setC6R2VNo(11);
                                setC6R3VNo(11);
                                setC6R4VNo(11);
                                setC6r1MT("L")
                                setC6r2MT("L")
                                setC6r3MT("L")
                                setC6r4MT("L")
                            } else if (c6r2MT === "S") {
                              alert("Select Medium or Large Module and proceed.");
                            } 
                          }
                          
                              } else {
                                alert("You cannot do this as no doors were associated with this module.");
                              }
                        } else if (curX === col07X && curY === row02X) {
                          if (c7R2VNo === 8) {
                            if (doorTypec7r2 === 9) {
                              if (c7r1MT === "M" && c7r2MT === "M") {
                                setBaseNakedM07(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(9);
                                setC7R2VNo(9);
                              } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                                setRow2NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                  ]
                                )
                                setC7R2VNo(9);
                                setC7R3VNo(9);
                              } else if (c7r2MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c7r2MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec7r2 === 10) {
                              if (c7r1MT === "M" && c7r2MT === "M") {
                                setBaseNakedM07(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(10);
                                setC7R2VNo(10);
                              } else if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                                setRow2NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                  ]
                                )
                                setC7R2VNo(10);
                                setC7R3VNo(10);
                              } else if (c7r2MT === "L") {
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                      
                                setC7R1VNo(10);
                                setC7R2VNo(10);
                                setC7R3VNo(10);
                                setC7R4VNo(10);
                              } else if (c7r2MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c7r1MT === "M" && c7r2MT === "M") {
                                setBaseNakedM07(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                                setC7R1VNo(11);
                                setC7R2VNo(11);
                                setC7r1MT("M");
                                setC7r2MT("M");
                              } else  if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                               setRow2NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                  ]
                                  )
                                setC7R2VNo(11);
                                setC7R3VNo(11);
                                setC7r2MT("M");
                                setC7r3MT("M");
                              } else if (c7r2MT === "L") {
                                setBaseNakedL07(
                                    [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                      ]
                                  )
                                setC7R1VNo(11);
                                setC7R2VNo(11);
                                setC7R3VNo(11);
                                setC7R4VNo(11);
                                setC7r1MT("L")
                                setC7r2MT("L")
                                setC7r3MT("L")
                                setC7r4MT("L")
                                    } else if (c7r2MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                    } 
                            }
                            
                                } else {
                                  alert("You cannot do this as no doors were associated with this module.");
                                }
                          } else if (curX === col08X && curY === row02X) {
                            if (c8R2VNo === 8) {
                              if (doorTypec8r2 === 9) {
                                if (c8r1MT === "M" && c8r2MT === "M") {
                                  setBaseNakedM08(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                    ]
                                  )
                                  setC8R1VNo(9);
                                  setC8R2VNo(9);
                                } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                  setRow2NakedM08(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                    ]
                                  )
                                  setC8R2VNo(9);
                                  setC8R3VNo(9);
                                } else if (c8r2MT === "L") {
                                  alert("Round Handle cannot be added to the Large Door.");
                                } else if (c8r2MT === "S") {
                                    alert("Select Medium or Large Module and proceed.");
                                } 
                              } else if (doorTypec8r2 === 10) {
                                if (c8r1MT === "M" && c8r2MT === "M") {
                                  setBaseNakedM08(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                    ]
                                  )
                                  setC8R1VNo(10);
                                  setC8R2VNo(10);
                                } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                  setRow2NakedM08(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                    ]
                                  )
                                  setC8R2VNo(10);
                                  setC8R3VNo(10);
                                } else if (c8r2MT === "L") {
                                  setBaseNakedL08(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                    ]
                                  )
                    
                                  setC8R1VNo(10);
                                  setC8R2VNo(10);
                                  setC8R3VNo(10);
                                  setC8R4VNo(10);
                                } else if (c8r2MT === "S") {
                                    alert("Select Medium or Large Module and proceed.");
                                } 
                              } else {
                                if (c8r1MT === "M" && c8r2MT === "M") {
                                  setBaseNakedM08(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                                        ]
                                      )
                                  setC8R1VNo(11);
                                  setC8R2VNo(11);
                                  setC8r1MT("M");
                                  setC8r2MT("M");
                                } else if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                  setRow2NakedM08(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                        ]
                                      )
                                    setC8R2VNo(11);
                                    setC8R3VNo(11);
                                    setC8r2MT("M");
                                    setC8r3MT("M");
                                } else if (c8r2MT === "L") {
                                  setBaseNakedL08(
                                        [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                          ]
                                      )
                                    setC8R1VNo(11);
                                    setC8R2VNo(11);
                                    setC8R3VNo(11);
                                    setC8R4VNo(11);
                                    setC8r1MT("L")
                                    setC8r2MT("L")
                                    setC8r3MT("L")
                                    setC8r4MT("L")
                                      } else if (c8r2MT === "S") {
                                        alert("Select Medium or Large Module and proceed.");
                                      } 
                              }
                              
                            } else {
                              alert("You cannot do this as no doors were associated with this module.");
                            }
                            } else if (curX === col09X && curY === row02X) {
                              if (c9R2VNo === 8) {
                                if (doorTypec9r2 === 9) {
                                  if (c9r1MT === "M" && c9r2MT === "M") {
                                    setBaseNakedM09(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                                      ]
                                    )
                                    setC9R1VNo(9);
                                    setC9R2VNo(9);
                                  } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                                    setRow2NakedM09(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                      ]
                                    )
                                    setC9R2VNo(9);
                                    setC9R3VNo(9);
                                  } else if (c9r2MT === "L") {
                                    alert("Round Handle cannot be added to the Large Door.");
                                  } else if (c9r2MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                  } 
                                } else if (doorTypec9r2 === 10) {
                                  if (c9r1MT === "M" && c9r2MT === "M") {
                                    setBaseNakedM09(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                                      ]
                                    )
                                    setC9R1VNo(10);
                                    setC9R2VNo(10);  
                                  } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                                    setRow2NakedM09(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                      ]
                                    )
                                    setC9R2VNo(10);
                                    setC9R3VNo(10);
                                  } else if (c9r2MT === "L") {
                                    setBaseNakedL09(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                      ]
                                    )
                        
                                    setC9R1VNo(10);
                                    setC9R2VNo(10);
                                    setC9R3VNo(10);
                                    setC9R4VNo(10);
                                  } else if (c9r2MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                  } 
                                } else {
                                  if (c9r1MT === "M" && c9r2MT === "M") {
                                    setBaseNakedM09(
                                           [
                                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                                           ]
                                         )
                                       setC9R1VNo(11);
                                       setC9R2VNo(11);
                                       setC9r1MT("M");
                                       setC9r2MT("M");
                                   } else if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                                     setRow2NakedM09(
                                         [
                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                         ]
                                         )
                                     setC9R2VNo(11);
                                     setC9R3VNo(11);
                                     setC9r2MT("M");
                                     setC9r3MT("M");
                                   } else if (c9r2MT === "L") {
                                     setBaseNakedL09(
                                         [
                                             <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                           ]
                                       )
                                     setC9R1VNo(11);
                                     setC9R2VNo(11);
                                     setC9R3VNo(11);
                                     setC9R4VNo(11);
                                     setC9r1MT("L")
                                     setC9r2MT("L")
                                     setC9r3MT("L")
                                     setC9r4MT("L")
                                         } else if (c9r2MT === "S") {
                                           alert("Select Medium or Large Module and proceed.");
                                         } 
                                }
                               
                                    } else {
                                      alert("You cannot do this as no doors were associated with this module.");
                                    }
                              } else if (curX === col10X && curY === row02X) {
                                if (c10R2VNo === 8) {
                                  if (doorTypec10r2 === 9) {
                                    if (c10r1MT === "M" && c10r2MT === "M") {
                                      setBaseNakedM10(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                        ]
                                      )
                                      setC10R1VNo(9);
                                      setC10R2VNo(9);
                                    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                                      setRow2NakedM10(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                        ]
                                      )
                                      setC10R2VNo(9);
                                      setC10R3VNo(9);
                                    } else if (c10r2MT === "L") {
                                      alert("Round Handle cannot be added to the Large Door.");
                                    } else if (c10r2MT === "S") {
                                        alert("Select Medium or Large Module and proceed.");
                                    } 
                                  } else if (doorTypec10r2 === 10) {
                                    if (c10r1MT === "M" && c10r2MT === "M") {
                                      setBaseNakedM10(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                        ]
                                      )
                                      setC10R1VNo(10);
                                      setC10R2VNo(10);  
                                    } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                                      setRow2NakedM10(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                        ]
                                      )
                                      setC10R2VNo(10);
                                      setC10R3VNo(10);
                                    } else if (c10r2MT === "L") {
                                      setBaseNakedL10(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                        ]
                                      )
                        
                                      setC10R1VNo(10);
                                      setC10R2VNo(10);
                                      setC10R3VNo(10);
                                      setC10R4VNo(10);
                                    } else if (c10r2MT === "S") {
                                        alert("Select Medium or Large Module and proceed.");
                                    } 
                                  } else {
                                    if (c10r1MT === "M" && c10r2MT === "M") {
                                      setBaseNakedM10(
                                           [
                                             <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                           ]
                                           )
                                       setC10R1VNo(11);
                                       setC10R2VNo(11);
                                       setC10r1MT("M");
                                       setC10r2MT("M");
                                   } else if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                                     setRow2NakedM10(
                                         [
                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                         ]
                                       )
                                       setC10R2VNo(11);
                                       setC10R3VNo(11);
                                       setC10r2MT("M");
                                       setC10r3MT("M");
                                   } else if (c10r2MT === "L") {
                                     setBaseNakedL10(
                                           [
                                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                             ]
                                         )
                                       setC10R1VNo(11);
                                       setC10R2VNo(11);
                                       setC10R3VNo(11);
                                       setC10R4VNo(11);
 
                                       setC10r1MT("L")
                                       setC10r2MT("L")
                                       setC10r3MT("L")
                                       setC10r4MT("L")
                                         } else if (c10r2MT === "S") {
                                           alert("Select Medium or Large Module and proceed.");
                                         } 
                                  }
                                  
                                      } else {
                                        alert("You cannot do this as no doors were associated with this module.");
                                      }
                                } else if (curX === col11X && curY === row02X) {
                                  if (c11R2VNo === 8) {
                                    if (doorTypec11r2 === 9) {
                                      if (c11r1MT === "M" && c11r2MT === "M") {
                                        setBaseNakedM11(
                                          [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                          ]
                                        )
                                        setC11R1VNo(9);
                                        setC11R2VNo(9);
                                      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                                        setRow2NakedM11(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                          ]
                                        )
                                        setC11R2VNo(9);
                                        setC11R3VNo(9);
                                      } else if (c11r2MT === "L") {
                                        alert("Round Handle cannot be added to the Large Door.");
                                      } else if (c11r2MT === "S") {
                                          alert("Select Medium or Large Module and proceed.");
                                      } 
                                    } else if (doorTypec11r2 === 10) {
                                      if (c11r1MT === "M" && c11r2MT === "M") {
                                        setBaseNakedM11(
                                          [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                          ]
                                        )
                                        setC11R1VNo(10);
                                        setC11R2VNo(10);
                                      } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                                        setRow2NakedM11(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                          ]
                                        )
                                        setC11R2VNo(10);
                                        setC11R3VNo(10);
                                      } else if (c11r2MT === "L") {
                                        setBaseNakedL11(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                          ]
                                        )
                          
                                        setC11R1VNo(10);
                                        setC11R2VNo(10);
                                        setC11R3VNo(10);
                                        setC11R4VNo(10);
                                      } else if (c11r2MT === "S") {
                                          alert("Select Medium or Large Module and proceed.");
                                      } 
                                    } else {
                                      if (c11r1MT === "M" && c11r2MT === "M") {
                                        setBaseNakedM11(
                                          [
                                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                          ]
                                        )
                                        setC11R1VNo(11);
                                        setC11R2VNo(11);
                                        setC11r1MT("M");
                                        setC11r2MT("M");
                                    } else if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                                          setRow2NakedM11(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                        ]
                                      )
                                    setC11R2VNo(11);
                                    setC11R3VNo(11);
                                    setC11r2MT("M");
                                    setC11r3MT("M");
                                    } else if (c11r2MT === "L") {
                                        setBaseNakedL11(
                                            [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                              ]
                                          )
                                        setC11R1VNo(11);
                                        setC11R2VNo(11);
                                        setC11R3VNo(11);
                                        setC11R4VNo(11);
                                        setC11r1MT("L")
                                        setC11r2MT("L")
                                        setC11r3MT("L")
                                        setC11r4MT("L")
                                              } else if (c11r2MT === "S") {
                                                alert("Select Medium or Large Module and proceed.");
                                              } 
                                    }
                                   
                                  } else {
                                    alert("You cannot do this as no doors were associated with this module.");
                                  }
                                  } else if (curX === col12X && curY === row02X) {
                                    if (c12R2VNo === 8) {
                                      if (doorTypec12r2 === 9) {
                                        if (c12r1MT === "M" && c12r2MT === "M") {
                                          setBaseNakedM12(
                                            [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                            ]
                                          )
                                          setC12R1VNo(9);
                                          setC12R2VNo(9);
                                        } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                          setRow2NakedM12(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                            ]
                                          )
                                          setC12R2VNo(9);
                                          setC12R3VNo(9);
                                        } else if (c12r2MT === "L") {
                                          alert("Round Handle cannot be added to the Large Door.");
                                        } else if (c12r2MT === "S") {
                                            alert("Select Medium or Large Module and proceed.");
                                        } 
                                      } else if (doorTypec12r2 === 10) {
                                        if (c12r1MT === "M" && c12r2MT === "M") {
                                          setBaseNakedM12(
                                            [
                                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                            ]
                                          )
                                          setC12R1VNo(10);
                                          setC12R2VNo(10);
                                        } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                          setRow2NakedM12(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                            ]
                                          )
                                          setC12R2VNo(10);
                                          setC12R3VNo(10);
                                        } else if (c12r2MT === "L") {
                                          setBaseNakedL12(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                            ]
                                          )
                          
                                          setC12R1VNo(10);
                                          setC12R2VNo(10);
                                          setC12R3VNo(10);
                                          setC12R4VNo(10);
                                        } else if (c12r2MT === "S") {
                                            alert("Select Medium or Large Module and proceed.");
                                        } 
                                      } else {
                                        if (c12r1MT === "M" && c12r2MT === "M") {
                                          setBaseNakedM12(
                                              [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />  
                                              ]
                                            )
                                            setC12R1VNo(11);
                                            setC12R2VNo(11);
                                            setC12r1MT("M");
                                            setC12r2MT("M");
                                          } else if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                          setRow2NakedM12(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />  
                                                  ]
                                                )
                                              setC12R2VNo(11);
                                              setC12R3VNo(11);
                                              setC12r2MT("M");
                                              setC12r3MT("M");
                                        } else if (c12r2MT === "L") {
                                          setBaseNakedL12(
                                                [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                  ]
                                              )
                                            setC12R1VNo(11);
                                            setC12R2VNo(11);
                                            setC12R3VNo(11);
                                            setC12R4VNo(11);
                                            setC12r1MT("L")
                                            setC12r2MT("L")
                                            setC12r3MT("L")
                                            setC12r4MT("L")
                                              } else if (c12r2MT === "S") {
                                                alert("Select Medium or Large Module and proceed.");
                                              } 
                                      }
                                      
                                          } else {
                                            alert("You cannot do this as no doors were associated with this module.");
                                          }
                                    } else if (curX === col13X && curY === row02X) {
                                      if (c13R2VNo === 8) {
                                        if (doorTypec13r2 === 9) {
                                          if (c13r1MT === "M" && c13r2MT === "M") {
                                            setBaseNakedM13(
                                              [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                              ]
                                            )
                                            setC13R1VNo(9);
                                            setC13R2VNo(9);
                                          } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                            setRow2NakedM13(
                                              [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} /> 
                                              ]
                                            )
                                            setC13R2VNo(9);
                                            setC13R3VNo(9);
                                          } else if (c13r2MT === "L") {
                                            alert("Round Handle cannot be added to the Large Door.");
                                          } else if (c13r2MT === "S") {
                                              alert("Select Medium or Large Module and proceed.");
                                          }
                                        } else if (doorTypec13r2 === 10) {
                                          if (c13r1MT === "M" && c13r2MT === "M") {
                                            setBaseNakedM13(
                                              [
                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                              ]
                                            )
                                            setC13R1VNo(10);
                                            setC13R2VNo(10);
                                          } else if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                            setRow2NakedM13(
                                              [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                              ]
                                            )
                                            setC13R2VNo(10);
                                            setC13R3VNo(10);
                                          } else if (c13r2MT === "L") {
                                            setBaseNakedL13(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                              ]
                                            )
                                
                                            setC13R1VNo(10);
                                            setC13R2VNo(10);
                                            setC13R3VNo(10);
                                            setC13R4VNo(10);
                                          } else if (c13r2MT === "S") {
                                              alert("Select Medium or Large Module and proceed.");
                                          }
                                        } else {
                                          if (c13r1MT === "M" && c13r2MT === "M") {
                                            setBaseNakedM13(
                                                [
                                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />  
                                                ]
                                              )
                                            setC13R1VNo(11);
                                            setC13R2VNo(11);
                                            setC13r1MT("M");
                                            setC13r2MT("M");
                                        } else  if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                               setRow2NakedM13(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />  
                                          ]
                                        )
                                        setC13R1VNo(11);
                                        setC13R3VNo(11);
                                        setC13r2MT("M");
                                        setC13r3MT("M");
                                            } else if (c13r2MT === "L") {
                                              setBaseNakedL13(
                                                  [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                    ]
                                                )
                                              setC13R1VNo(11);
                                              setC13R2VNo(11);
                                              setC13R3VNo(11);
                                              setC13R4VNo(11);
    
                                              setC13r1MT("L")
                                              setC13r2MT("L")
                                              setC13r3MT("L")
                                              setC13r4MT("L")
                                                  } else if (c13r2MT === "S") {
                                                    alert("Select Medium or Large Module and proceed.");
                                                  } 
                                        }
                                       
                                      } else {
                                        alert("You cannot do this as no doors were associated with this module.");
                                      }
                                      } else if (curX === col14X && curY === row02X) {
                                        if (c14R2VNo === 8) {
                                          if (doorTypec14r2 === 9) {
                                            if (c14r1MT === "M" && c14r2MT === "M") {
                                              setBaseNakedM14(
                                                [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                                ]
                                              )
                                              setC14R1VNo(9);
                                              setC14R2VNo(9);
                                            } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                              setRow2NakedM14(
                                                [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                ]
                                              )
                                              setC14R2VNo(9);
                                              setC14R3VNo(9);
                                            } else if (c14r2MT === "L") {
                                              alert("Round Handle cannot be added to the Large Door.");
                                            } else if (c14r2MT === "S") {
                                                alert("Select Medium or Large Module and proceed.");
                                            } 
                                          } else if (doorTypec14r2 === 10) {
                                            if (c14r1MT === "M" && c14r2MT === "M") {
                                              setBaseNakedM14(
                                                [
                                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                                ]
                                              )
                                              setC14R1VNo(10);
                                              setC14R2VNo(10);
                                            } else if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                              setRow2NakedM14(
                                                [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                                ]
                                              )
                                              setC14R2VNo(10);
                                              setC14R3VNo(10);
                                            } else if (c14r2MT === "L") {
                                              setBaseNakedL14(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                ]
                                              )
                                  
                                              setC14R1VNo(10);
                                              setC14R2VNo(10);
                                              setC14R3VNo(10);
                                              setC14R4VNo(10);
                                            } else if (c14r2MT === "S") {
                                                alert("Select Medium or Large Module and proceed.");
                                            } 
                                          } else {
                                            if (c14r1MT === "M" && c14r2MT === "M") {
                                              setBaseNakedM14(
                                              [
                                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />  
                                              ]
                                            )
                                            setC14R1VNo(11);
                                            setC14R2VNo(11);
                                            setC14r1MT("M");
                                            setC14r2MT("M");
                                          } else  if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                               setRow2NakedM14(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />  
                                          ]
                                        )
                                        setC14R2VNo(11);
                                        setC14R3VNo(11);
                                        setC14r2MT("M");
                                        setC14r3MT("M");
                                            } else if (c14r2MT === "L") {
                                              setBaseNakedL14(
                                              [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                ]
                                            )
                                          setC14R1VNo(11);
                                          setC14R2VNo(11);
                                          setC14R3VNo(11);
                                          setC14R4VNo(11);
  
                                          setC14r1MT("L")
                                          setC14r2MT("L")
                                          setC14r3MT("L")
                                          setC14r4MT("L")
                                                  } else if (c14r2MT === "S") {
                                                    alert("Select Medium or Large Module and proceed.");
                                                  } 
                                          }
                                         
                            } else {
                              alert("You cannot do this as no doors were associated with this module.");
                            } 
                        }  else if (curX === col01X && curY === row03X) {
                          if (c1R3VNo === 8) {
                            if (doorTypec1r3 === 9) {
                              if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                                setRow2NakedM01(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                  ]
                                )
                                setC1R2VNo(9);
                                setC1R3VNo(9);
                              } else if (c1r3MT === "M" && c1r4MT === "M") {
                                setRow3NakedM01(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                  ]
                                )
                                setC1R3VNo(9);
                                setC1R4VNo(9);
                              } else if (c1r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c1r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec1r3 === 10) {
                              if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                                setRow2NakedM01(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                  ]
                                )
                                setC1R2VNo(10);
                                setC1R3VNo(10);
                              } else if (c1r3MT === "M" && c1r4MT === "M") {
                                setRow3NakedM01(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                  ]
                                )
                                setC1R3VNo(10);
                                setC1R4VNo(10);
                              } else if (c1r3MT === "L") {
                                setBaseNakedL01(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                  ]
                                )
                
                                setC1R1VNo(10);
                                setC1R2VNo(10);
                                setC1R3VNo(10);
                                setC1R4VNo(10);
                              } else if (c1r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c1r2MT === "M" && c1r3MT === "M" && c1r1MT === "S") {
                                setRow2NakedM01(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
                                      ]
                                    )
                                setC1R2VNo(11);
                                setC1R3VNo(11);
                                setC1r2MT("M");
                                setC1r3MT("M");
                              } else if (c1r3MT === "M" && c1r4MT === "M") {
                                    setRow3NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                                  setC1R3VNo(11);
                                  setC1R4VNo(11);
                                  setC1r3MT("M");
                                  setC1r4MT("M");
                                  } else if (c1r3MT === "L") {
                                    setBaseNakedL01(
                                        [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                          ]
                                      )
                                    setC1R1VNo(11);
                                    setC1R2VNo(11);
                                    setC1R3VNo(11);
                                    setC1R4VNo(11);
                                    setC1r1MT("L")
                                    setC1r2MT("L")
                                    setC1r3MT("L")
                                    setC1r4MT("L")
                                  } else if (c1r3MT === "S") {
                                    alert("Select Medium or Large Module and proceed.");
                                  } 
                            }
                           
                              } else {
                                alert("You cannot do this as no doors were associated with this module.");
                              }
                        }  else if (curX === col02X && curY === row03X) {
                          if (c2R3VNo === 8) {
                            if (doorTypec2r3 === 9) {
                              if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                                setRow2NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                  ]
                                )
                                setC2R2VNo(9);
                                setC2R3VNo(9);
                              } else if (c2r3MT === "M" && c2r4MT === "M") {
                                setRow3NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                  ]
                                )
                                setC2R3VNo(9);
                                setC2R4VNo(9);
                              } else if (c2r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c2r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec2r3 === 10) {
                              if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                                setRow2NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                  ]
                                )
                                setC2R2VNo(10);
                                setC2R3VNo(10);
                              } else if (c2r3MT === "M" && c2r4MT === "M") {
                                setRow3NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                  ]
                                )
                                setC2R3VNo(10);
                                setC2R4VNo(10);
                              } else if (c2r3MT === "L") {
                                setBaseNakedL02(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                  ]
                                )
                  
                                setC2R1VNo(10);
                                setC2R2VNo(10);
                                setC2R3VNo(10);
                                setC2R4VNo(10);
                              } else if (c2r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c2r2MT === "M" && c2r3MT === "M" && c2r1MT === "S") {
                                setRow2NakedM02(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                                   ]
                                 )
                               setC2R2VNo(11);
                               setC2R3VNo(11);
                               setC2r2MT("M");
                               setC2r3MT("M");
                              } else if (c2r3MT === "M" && c2r4MT === "M") {
                                 setRow3NakedM02(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                     ]
                                   )
                                 setC2R3VNo(11);
                                 setC2R4VNo(11);
                                 setC2r3MT("M");
                                 setC2r4MT("M");
                              } else if (c2r3MT === "L") {
                                setBaseNakedL02(
                                         [
                                             <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                           ]
                                       )
                                     setC2R1VNo(11);
                                     setC2R2VNo(11);
                                     setC2R3VNo(11);
                                     setC2R4VNo(11);
                                     setC2r1MT("L")
                                     setC2r2MT("L")
                                     setC2r3MT("L")
                                     setC2r4MT("L")
                                    } else if (c2r3MT === "S") {
                                     alert("Select Medium or Large Module and proceed.");
                                   } 
                            }
                            
                              } else {
                                alert("You cannot do this as no doors were associated with this module.");
                              }
                        } else if (curX === col03X && curY === row03X) {
                          if (c3R3VNo === 8) {
                            if (doorTypec3r3 === 9) {
                              if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                                setRow2NakedM03(
                                  [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />      
                                  ]
                                )
                                setC3R2VNo(9);
                                setC3R3VNo(9);
                              } else if (c3r3MT === "M" && c3r4MT === "M") {
                                setRow3NakedM03(
                                  [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />      
                                  ]
                                )
                                setC3R3VNo(9);
                                setC3R4VNo(9);
                              } else if (c3r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c3r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec3r3 === 10) {
                              if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                                setRow2NakedM03(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />     
                                  ]
                                )
                                setC3R2VNo(10);
                                setC3R3VNo(10);
                              } else if (c3r3MT === "M" && c3r4MT === "M") {
                                setRow3NakedM03(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />     
                                  ]
                                )
                                setC3R3VNo(10);
                                setC3R4VNo(10);
                              } else if (c3r3MT === "L") {
                                setBaseNakedL03(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                  ]
                                )
                    
                                setC3R1VNo(10);
                                setC3R2VNo(10);
                                setC3R3VNo(10);
                                setC3R4VNo(10);
                              } else if (c3r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c3r2MT === "M" && c3r3MT === "M" && c3r1MT === "S") {
                                setRow2NakedM03(
                                 [
                                 < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />        
                                 ]
                               )
                               setC3R2VNo(11);
                               setC3R3VNo(11);
                               setC3r2MT("M");
                               setC3r3MT("M");
                             } else if (c3r3MT === "M" && c3r4MT === "M") {
                                setRow3NakedM03(
                                   [
                                   < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />        
                                   ]
                                 )
                                 setC3R3VNo(11);
                                 setC3R4VNo(11);
                                 setC3r3MT("M");
                                 setC3r4MT("M");
                              } else if (c3r3MT === "L") {
                               setBaseNakedL03(
                                       [
                                           <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                         ]
                                     )
                                   setC3R1VNo(11);
                                   setC3R2VNo(11);
                                   setC3R3VNo(11);
                                   setC3R4VNo(11);
                                   setC3r1MT("L")
                                   setC3r2MT("L")
                                   setC3r3MT("L")
                                   setC3r4MT("L")
                                    } else if (c3r3MT === "S") {
                                     alert("Select Medium or Large Module and proceed.");
                                   } 
                            }
                           
                                } else {
                                  alert("You cannot do this as no doors were associated with this module.");
                                }
                        } else if (curX === col04X && curY === row03X) {
                          if (c4R3VNo === 8) {
                            if (doorTypec4r3 === 9) {
                              if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                                setRow2NakedM04(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />       
                                ]
                                )
                                setC4R2VNo(9);
                                setC4R3VNo(9);
                              } else if (c4r3MT === "M" && c4r4MT === "M") {
                                setRow3NakedM04(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />       
                                ]
                                )
                                setC4R3VNo(9);
                                setC4R4VNo(9);
                              } else if (c4r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c4r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else if (doorTypec4r3 === 10) {
                              if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                                setRow2NakedM04(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />    
                                ]
                                )
                                setC4R2VNo(10);
                                setC4R3VNo(10);
                              } else if (c4r3MT === "M" && c4r4MT === "M") {
                                setRow3NakedM04(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />    
                                ]
                                )
                                setC4R3VNo(10);
                                setC4R4VNo(10);
                              } else if (c4r3MT === "L") {
                                setBaseNakedL04(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                  ]
                                )
                    
                                setC4R1VNo(10);
                                setC4R2VNo(10);
                                setC4R3VNo(10);
                                setC4R4VNo(10);
                              } else if (c4r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else {
                              if (c4r2MT === "M" && c4r3MT === "M" && c4r1MT === "S") {
                                setRow2NakedM04(
                                [
                                < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />          
                              ]
                              )
                              setC4R2VNo(11);
                              setC4R3VNo(11);
                              setC4r2MT("M");
                              setC4r3MT("M");
                            } else  if (c4r3MT === "M" && c4r4MT === "M" ) {
                                setRow3NakedM04(
                                  [
                                  < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />          
                                ]
                                )
                              setC4R3VNo(11);
                              setC4R4VNo(11);
                              setC4r3MT("M");
                              setC4r4MT("M");
                              } else if (c4r3MT === "L") {
                                setBaseNakedL04(
                                      [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                        ]
                                    )
                                  setC4R1VNo(11);
                                  setC4R2VNo(11);
                                  setC4R3VNo(11);
                                  setC4R4VNo(11);
                                  setC4r1MT("L")
                                  setC4r2MT("L")
                                  setC4r3MT("L")
                                  setC4r4MT("L")
                                    } else if (c4r3MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                    } 
                            }
                           
                                } else {
                                  alert("You cannot do this as no doors were associated with this module.");
                                }
                        } else if (curX === col05X && curY === row03X) {
                          if (c5R3VNo === 8) {
                            if (doorTypec5r3 === 9) {
                              if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                                setRow2NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />       
                                 ]
                                )
                                setC5R2VNo(9);
                                setC5R3VNo(9);
                              } else if (c5r3MT === "M" && c5r4MT === "M") {
                                setRow3NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />       
                                 ]
                                )
                                setC5R3VNo(9);
                                setC5R4VNo(9);
                              } else if (c5r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c5r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec5r3 === 10) {
                              if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                                setRow2NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />     
                                ]
                                )
                                setC5R2VNo(10);
                                setC5R3VNo(10);
                              } else if (c5r3MT === "M" && c5r4MT === "M") {
                                setRow3NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />     
                                ]
                                )
                                setC5R3VNo(10);
                                setC5R4VNo(10);
                              } else if (c5r3MT === "L") {
                                setBaseNakedL05(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                  ]
                                )
                    
                                setC5R1VNo(10);
                                setC5R2VNo(10);
                                setC5R3VNo(10);
                                setC5R4VNo(10);
                              } else if (c5r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c5r2MT === "M" && c5r3MT === "M" && c5r1MT === "S") {
                                setRow2NakedM05(
                                    [
                                    < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />         
                                  ]
                                  )
                                  setC5R2VNo(11);
                                  setC5R3VNo(11);
                                  setC5r2MT("M");
                                  setC5r3MT("M");
                              } else  if (c5r3MT === "M" && c5r4MT === "M") {
                                setRow3NakedM05(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                                  ]
                                )
                                setC5R3VNo(11);
                                setC5R4VNo(11);
                                setC5r3MT("M");
                                setC5r4MT("M");
                              } else if (c5r3MT === "L") {
                                setBaseNakedL05(
                                    [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                      ]
                                  )
                                setC5R1VNo(11);
                                setC5R2VNo(11);
                                setC5R3VNo(11);
                                setC5R4VNo(11);
                                setC5r1MT("L")
                                setC5r2MT("L")
                                setC5r3MT("L")
                                setC5r4MT("L")
                                    } else if (c5r3MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                    } 
                            }
                           
                                } else {
                                  alert("You cannot do this as no doors were associated with this module.");
                                }
                        } else if (curX === col06X && curY === row03X) {
                          if (c6R3VNo === 8) {
                            if (doorTypec6r3 === 9) {
                              if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                                setRow2NakedM06(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />     
                                  ]
                                )
                                setC6R2VNo(9);
                                setC6R3VNo(9);
                              } else if (c6r3MT === "M" && c6r4MT === "M") {
                                setRow3NakedM06(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />     
                                  ]
                                )
                                setC6R3VNo(9);
                                setC6R4VNo(9);
                              } else if (c6r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c6r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else if (doorTypec6r3 === 10) {
                              if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                                setRow2NakedM06(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                                  ]
                                )
                                setC6R2VNo(10);
                                setC6R3VNo(10);
                              } else if (c6r3MT === "M" && c6r4MT === "M") {
                                setRow3NakedM06(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                  ]
                                )
                                setC6R3VNo(10);
                                setC6R4VNo(10);
                              } else if (c6r3MT === "L") {
                                setBaseNakedL06(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                  ]
                                )
                  
                                setC6R1VNo(10);
                                setC6R2VNo(10);
                                setC6R3VNo(10);
                                setC6R4VNo(10);
                              } else if (c6r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else {
                              if (c6r2MT === "M" && c6r3MT === "M" && c6r1MT === "S") {
                                setRow2NakedM06(
                                  [
                                  < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />        
                                  ]
                                )
                                setC6R2VNo(11);
                                setC6R3VNo(11);
                                setC6r2MT("M");
                                setC6r3MT("M");
                              } else  if (c6r3MT === "M" && c6r4MT === "M") {
                                 setRow3NakedM06(
                                    [
                                    < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />        
                                    ]
                                  )
                                setC6R3VNo(11);
                                setC6R4VNo(11);
                                setC6r3MT("M");
                                setC6r4MT("M");
                               } else if (c6r3MT === "L") {
                                setBaseNakedL06(
                                    [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                      ]
                                  )
                                setC6R1VNo(11);
                                setC6R2VNo(11);
                                setC6R3VNo(11);
                                setC6R4VNo(11);
                                setC6r1MT("L")
                                setC6r2MT("L")
                                setC6r3MT("L")
                                setC6r4MT("L")
                                     } else if (c6r3MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                    } 
                            }
                           
                                } else {
                                  alert("You cannot do this as no doors were associated with this module.");
                                }
                        } else if (curX === col07X && curY === row03X) {
                          if (c7R3VNo === 8) {
                            if (doorTypec7r3 === 9) {
                              if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                                setRow2NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                  ]
                                )
                                setC7R2VNo(9);
                                setC7R3VNo(9);
                              } else if (c7r3MT === "M" && c7r4MT === "M") {
                                setRow3NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                  ]
                                )
                                setC7R3VNo(9);
                                setC7R4VNo(9);
                              } else if (c7r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c7r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec7r3 === 10) {
                              if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                                setRow2NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                  ]
                                )
                                setC7R2VNo(10);
                                setC7R3VNo(10);
                              } else if (c7r3MT === "M" && c7r4MT === "M") {
                                setRow3NakedM07(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                  ]
                                )
                                setC7R3VNo(10);
                                setC7R4VNo(10);
                              } else if (c7r3MT === "L") {
                                setBaseNakedL07(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                                )
                        
                                setC7R1VNo(10);
                                setC7R2VNo(10);
                                setC7R3VNo(10);
                                setC7R4VNo(10);
                              } else if (c7r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c7r2MT === "M" && c7r3MT === "M" && c7r1MT === "S") {
                                setRow2NakedM07(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                                ]
                                )
                              setC7R2VNo(11);
                              setC7R3VNo(11);
                              setC7r2MT("M");
                              setC7r3MT("M");
                        } else   if (c7r3MT === "M" && c7r4MT === "M") {
                             setRow3NakedM07(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                              ]
                            )
                            setC7R3VNo(11);
                            setC7R4VNo(11);
                            setC7r3MT("M");
                            setC7r4MT("M");
                          } else if (c7r3MT === "L") {
                            setBaseNakedL07(
                                [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                  ]
                              )
                            setC7R1VNo(11);
                            setC7R2VNo(11);
                            setC7R3VNo(11);
                            setC7R4VNo(11);
                            setC7r1MT("L")
                            setC7r2MT("L")
                            setC7r3MT("L")
                            setC7r4MT("L")
                                } else if (c7r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                                } 
                            }
                           
                                  } else {
                                    alert("You cannot do this as no doors were associated with this module.");
                                  }
                        } else if (curX === col08X && curY === row03X) {
                          if (c8R3VNo === 8) {
                            if (doorTypec8r3 === 9) {
                              if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                setRow2NakedM08(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                  ]
                                )
                                setC8R2VNo(9);
                                setC8R3VNo(9);
                              } else if (c8r3MT === "M" && c8r4MT === "M") {
                                setRow3NakedM08(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                  ]
                                )
                                setC8R3VNo(9);
                                setC8R4VNo(9);
                              } else if (c8r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c8r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else if (doorTypec8r3 === 10) {
                              if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                setRow2NakedM08(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                  ]
                                )
                                setC8R2VNo(10);
                                setC8R3VNo(10);
                              } else if (c8r3MT === "M" && c8r4MT === "M") {
                                setRow3NakedM08(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                  ]
                                )
                                setC8R3VNo(10);
                                setC8R4VNo(10);
                              } else if (c8r3MT === "L") {
                                setBaseNakedL08(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                  ]
                                )
                      
                                setC8R1VNo(10);
                                setC8R2VNo(10);
                                setC8R3VNo(10);
                                setC8R4VNo(10);
                              } else if (c8r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else {
                              if (c8r2MT === "M" && c8r3MT === "M" && c8r1MT === "S") {
                                setRow2NakedM08(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                                   ]
                                 )
                               setC8R2VNo(11);
                               setC8R3VNo(11);
                               setC8r2MT("M");
                               setC8r3MT("M");
                             } else  if (c8r3MT === "M" && c8r4MT === "M") {
                               setRow3NakedM08(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                   ]
                                 )
                               setC8R3VNo(11);
                               setC8R4VNo(11);
                               setC8r3MT("M");
                               setC8r4MT("M");
                             } else if (c8r3MT === "L") {
                               setBaseNakedL08(
                                       [
                                           <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                         ]
                                     )
                                   setC8R1VNo(11);
                                   setC8R2VNo(11);
                                   setC8R3VNo(11);
                                   setC8R4VNo(11);
                                   setC8r1MT("L")
                                   setC8r2MT("L")
                                   setC8r3MT("L")
                                   setC8r4MT("L")
                                   } else if (c8r3MT === "S") {
                                     alert("Select Medium or Large Module and proceed.");
                                   } 
                            }
                           
                                  } else {
                                    alert("You cannot do this as no doors were associated with this module.");
                                  }
                        } else if (curX === col09X && curY === row03X) {
                          if (c9R3VNo === 8) {
                            if (doorTypec9r3 === 9) {
                              if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                                setRow2NakedM09(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                  ]
                                )
                                setC9R2VNo(9);
                                setC9R3VNo(9);
                              } else if (c9r3MT === "M" && c9r4MT === "M") {
                                setRow3NakedM09(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                  ]
                                )
                                setC9R3VNo(9);
                                setC9R4VNo(9);
                              } else if (c9r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c9r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec9r3 === 10) {
                              if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                                setRow2NakedM09(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                  ]
                                )
                                setC9R2VNo(10);
                                setC9R3VNo(10);
                              } else if (c9r3MT === "M" && c9r4MT === "M") {
                                setRow3NakedM09(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                  ]
                                )
                                setC9R3VNo(10);
                                setC9R4VNo(10);
                              } else if (c9r3MT === "L") {
                                setBaseNakedL09(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                  ]
                                )
                      
                                setC9R1VNo(10);
                                setC9R2VNo(10);
                                setC9R3VNo(10);
                                setC9R4VNo(10);
                              } else if (c9r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c9r2MT === "M" && c9r3MT === "M" && c9r1MT === "S") {
                                setRow2NakedM09(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                                   ]
                                   )
                               setC9R2VNo(11);
                               setC9R3VNo(11);
                               setC9r2MT("M");
                               setC9r3MT("M");
                             } else  if (c9r3MT === "M" && c9r4MT === "M") {
                                setRow3NakedM09(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                   ]
                                 )
                               setC9R3VNo(11);
                               setC9R4VNo(11);
                               setC9r3MT("M");
                               setC9r4MT("M");
                              } else if (c9r3MT === "L") {
                                                   setBaseNakedL09(
                                   [
                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                     ]
                                 )
                               setC9R1VNo(11);
                               setC9R2VNo(11);
                               setC9R3VNo(11);
                               setC9R4VNo(11);
                               setC9r1MT("L")
                               setC9r2MT("L")
                               setC9r3MT("L")
                               setC9r4MT("L")
                                    } else if (c9r3MT === "S") {
                                     alert("Select Medium or Large Module and proceed.");
                                   } 
                            }
                            
                                } else {
                                  alert("You cannot do this as no doors were associated with this module.");
                                }
                        } else if (curX === col10X && curY === row03X) {
                          if (c10R3VNo === 8) {
                            if (doorTypec10r3 === 9) {
                              if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                                setRow2NakedM10(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                  ]
                                )
                                setC10R2VNo(9);
                                setC10R3VNo(9);
                              } else if (c10r3MT === "M" && c10r4MT === "M") {
                                setRow3NakedM10(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                  ]
                                )
                                setC10R3VNo(9);
                                setC10R4VNo(9);
                              } else if (c10r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c10r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else if (doorTypec10r3 === 10) {
                              if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                                setRow2NakedM10(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                  ]
                                )
                                setC10R2VNo(10);
                                setC10R3VNo(10);
                              } else if (c10r3MT === "M" && c10r4MT === "M") {
                              } else if (c10r3MT === "M" && c10r4MT === "M") {  
                                setRow3NakedM10(
                                         [
                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                         ]
                                       )
                                       setC10R3VNo(10);
                                       setC10R4VNo(10);
                              } else if (c10r3MT === "L") {
                                setBaseNakedL10(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                  ]
                                )
                      
                                setC10R1VNo(10);
                                setC10R2VNo(10);
                                setC10R3VNo(10);
                                setC10R4VNo(10);
                              } else if (c10r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              }
                            } else {
                              if (c10r2MT === "M" && c10r3MT === "M" && c10r1MT === "S") {
                                setRow2NakedM10(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                   ]
                                 )
                                 setC10R2VNo(11);
                                 setC10R3VNo(11);
                                 setC10r2MT("M");
                                 setC10r3MT("M");
                              } else if (c10r3MT === "M" && c10r4MT === "M") {
                                 setRow3NakedM10(
                                     [
                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                     ]
                                   )
                                   setC10R3VNo(11);
                                   setC10R4VNo(11);
                                   setC10r3MT("M");
                                   setC10r4MT("M");
                              } else if (c10r3MT === "L") {
                                 setBaseNakedL10(
                                   [
                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                     ]
                                 )
                               setC10R1VNo(11);
                               setC10R2VNo(11);
                               setC10R3VNo(11);
                               setC10R4VNo(11);
 
                               setC10r1MT("L")
                               setC10r2MT("L")
                               setC10r3MT("L")
                               setC10r4MT("L")
                                    } else if (c10r3MT === "S") {
                                     alert("Select Medium or Large Module and proceed.");
                                   } 
                            }
                           
                                } else {
                                  alert("You cannot do this as no doors were associated with this module.");
                                }
                        } else if (curX === col11X && curY === row03X) {
                          if (c11R3VNo === 8) {
                            if (doorTypec11r3 === 9) {
                              if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                                setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                                setC11R2VNo(9);
                                setC11R3VNo(9);
                              } else if (c11r3MT === "M" && c11r4MT === "M") {
                                setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                                setC11R3VNo(9);
                                setC11R4VNo(9);
                              } else if (c11r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c11r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec11r3 === 10) {
                              if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                                setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                                setC11R2VNo(10);
                                setC11R3VNo(10);
                              } else if (c11r3MT === "M" && c11r4MT === "M") {
                                setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                                setC11R3VNo(10);
                                setC11R4VNo(10);
                              } else if (c11r3MT === "L") {
                                setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                      
                                setC11R1VNo(10);
                                setC11R2VNo(10);
                                setC11R3VNo(10);
                                setC11R4VNo(10);
                              } else if (c11r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c11r2MT === "M" && c11r3MT === "M" && c11r1MT === "S") {
                                setRow2NakedM11(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                      ]
                                    )
                                  setC11R2VNo(11);
                                  setC11R3VNo(11);
                                  setC11r2MT("M");
                                  setC11r3MT("M");
                                  } else  if (c11r3MT === "M" && c11r4MT === "M") {
                                                        setRow3NakedM11(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                      ]
                                    )
                                  setC11R3VNo(11);
                                  setC11R4VNo(11);
                                  setC11r3MT("M");
                                  setC11r4MT("M");
                                                      } else if (c11r3MT === "L") {
                                                        setBaseNakedL11(
                                        [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                          ]
                                      )
                                    setC11R1VNo(11);
                                    setC11R2VNo(11);
                                    setC11R3VNo(11);
                                    setC11R4VNo(11);
                                    setC11r1MT("L")
                                    setC11r2MT("L")
                                    setC11r3MT("L")
                                    setC11r4MT("L")
                                  } else if (c11r3MT === "S") {
                                    alert("Select Medium or Large Module and proceed.");
                                  } 
                            }
                           
                      } else {
                        alert("You cannot do this as no doors were associated with this module.");
                      }
                        } else if (curX === col12X && curY === row03X) {
                          if (c12R3VNo === 8) {
                            if (doorTypec12r3 === 9) {
                              if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                                setC12R2VNo(9);
                                setC12R3VNo(9);
                              } else if (c12r3MT === "M" && c12r4MT === "M") {
                                setRow3NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                  ]
                                )
                                setC12R3VNo(9);
                                setC12R4VNo(9);
                              } else if (c12r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c12r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec12r3 === 10) {
                              if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                                setC12R2VNo(10);
                                setC12R3VNo(10);
                              } else if (c12r3MT === "M" && c12r4MT === "M") {
                                setRow3NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                  ]
                                )
                                setC12R3VNo(10);
                                setC12R4VNo(10);
                              } else if (c12r3MT === "L") {
                                setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        
                                setC12R1VNo(10);
                                setC12R2VNo(10);
                                setC12R3VNo(10);
                                setC12R4VNo(10);
                              } else if (c12r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c12r2MT === "M" && c12r3MT === "M" && c12r1MT === "S") {
                                setRow2NakedM12(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />  
                                ]
                              )
                            setC12R2VNo(11);
                            setC12R3VNo(11);
                            setC12r2MT("M");
                            setC12r3MT("M");
                          } else if (c12r3MT === "M" && c12r4MT === "M") {
                                setRow3NakedM12(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />  
                                ]
                              )
                            setC12R3VNo(11);
                            setC12R4VNo(11);
                            setC12r3MT("M");
                            setC12r4MT("M");
                             } else if (c12r3MT === "L") {
                                                    setBaseNakedL12(
                                  [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                )
                              setC12R1VNo(11);
                              setC12R2VNo(11);
                              setC12R3VNo(11);
                              setC12R4VNo(11);
                              setC12r1MT("L")
                              setC12r2MT("L")
                              setC12r3MT("L")
                              setC12r4MT("L")
                                   } else if (c12r3MT === "S") {
                                    alert("Select Medium or Large Module and proceed.");
                                  } 
                            }
                            
                          } else {
                            alert("You cannot do this as no doors were associated with this module.");
                          }
                        } else if (curX === col13X && curY === row03X) {
                          if (c13R3VNo === 8) {
                            if (doorTypec13r3 === 9) {
                              if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                setRow2NakedM13(
                                  [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} /> 
                                  ]
                                )
                                setC13R2VNo(9);
                                setC13R3VNo(9);
                              } else if (c13r3MT === "M" && c13r4MT === "M") {
                                setRow3NakedM13(
                                  [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} /> 
                                  ]
                                )
                                setC13R3VNo(9);
                                setC13R4VNo(9);
                              } else if (c13r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c13r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec13r3 === 10) {
                              if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                setRow2NakedM13(
                                  [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                  ]
                                )
                                setC13R2VNo(10);
                                setC13R3VNo(10);
                              
                              } else if (c13r3MT === "M" && c13r4MT === "M") {   
                                setRow3NakedM13(
                                      [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                                    setC13R3VNo(10);
                                    setC13R4VNo(10);
                              } else if (c13r3MT === "L") {
                                setBaseNakedL13(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                  ]
                                )
                        
                                setC13R1VNo(10);
                                setC13R2VNo(10);
                                setC13R3VNo(10);
                                setC13R4VNo(10);
                              } else if (c13r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c13r2MT === "M" && c13r3MT === "M" && c13r1MT === "S") {
                                setRow2NakedM13(
                                   [
                                     <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />  
                                   ]
                                 )
                                 setC13R1VNo(11);
                                 setC13R3VNo(11);
                                 setC13r2MT("M");
                                 setC13r3MT("M");
                           } else if (c13r3MT === "M" && c13r4MT === "M") {
                                setRow3NakedM13(
                             [
                               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />  
                             ]
                           )
                           setC13R3VNo(11);
                           setC13R4VNo(11);
                           setC13r3MT("M");
                           setC13r4MT("M");
                              } else if (c13r3MT === "L") {
                                setBaseNakedL13(
                                   [
                                       <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                     ]
                                 )
                               setC13R1VNo(11);
                               setC13R2VNo(11);
                               setC13R3VNo(11);
                               setC13R4VNo(11);

                               setC13r1MT("L")
                               setC13r2MT("L")
                               setC13r3MT("L")
                               setC13r4MT("L")
                                    } else if (c13r3MT === "S") {
                                     alert("Select Medium or Large Module and proceed.");
                                   } 
                            }
                            
                                  } else {
                                    alert("You cannot do this as no doors were associated with this module.");
                                  }
                        } else if (curX === col14X && curY === row03X) {
                          if (c14R3VNo === 8) {
                            if (doorTypec14r3 === 9) {
                              if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                setRow2NakedM14(
                                  [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                  ]
                                )
                                setC14R2VNo(9);
                                setC14R3VNo(9);
                              } else if (c14r3MT === "M" && c14r4MT === "M") {
                                setRow3NakedM14(
                                  [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                  ]
                                )
                                setC14R3VNo(9);
                                setC14R4VNo(9);
                              } else if (c14r3MT === "L") {
                                alert("Round Handle cannot be added to the Large Door.");
                              } else if (c14r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else if (doorTypec14r3 === 10) {
                              if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                setRow2NakedM14(
                                  [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                  ]
                                )
                                setC14R2VNo(10);
                                setC14R3VNo(10);
                              } else if (c14r3MT === "M" && c14r4MT === "M") {
                                setRow3NakedM14(
                                  [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                  ]
                                )
                                setC14R3VNo(10);
                                setC14R4VNo(10);
                              } else if (c14r3MT === "L") {
                                setBaseNakedL14(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                  ]
                                )
                        
                                setC14R1VNo(10);
                                setC14R2VNo(10);
                                setC14R3VNo(10);
                                setC14R4VNo(10);
                              } else if (c14r3MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                              } 
                            } else {
                              if (c14r2MT === "M" && c14r3MT === "M" && c14r1MT === "S") {
                                setRow2NakedM14(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />  
                                  ]
                                )
                                setC14R2VNo(11);
                                setC14R3VNo(11);
                                setC14r2MT("M");
                                setC14r3MT("M");
                            } else  if (c14r3MT === "M" && c14r4MT === "M") {
                                setRow3NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />  
                                      ]
                                    )
                                    setC14R3VNo(11);
                                    setC14R4VNo(11);
                                    setC14r3MT("M");
                                    setC14r4MT("M");
                              } else if (c14r3MT === "L") {
                                  setBaseNakedL14(
                                  [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                    ]
                                )
                              setC14R1VNo(11);
                              setC14R2VNo(11);
                              setC14R3VNo(11);
                              setC14R4VNo(11);

                              setC14r1MT("L")
                              setC14r2MT("L")
                              setC14r3MT("L")
                              setC14r4MT("L")
                              } else if (c14r3MT === "S") {
                                alert("Select Medium or Large Module and proceed.");
                              } 
                            }
                             
                              } else {
                                alert("You cannot do this as no doors were associated with this module.");
                              }
                          }  else if (curX === col01X && curY === row04X) {
                            if (c1R4VNo === 8) {
                              if (doorTypec1r4 === 9) {
                                if (c1r3MT === "M" && c1r4MT === "M") {
                                  setRow3NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                                  setC1R3VNo(9);
                                  setC1R4VNo(9);
                                } else if (c1r4MT === "L") {
                                  alert("Round Handle cannot be added to the Large Door.");
                                } else if (c1r4MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                                } 
                              } else if (doorTypec1r4 === 10) {
                                if (c1r3MT === "M" && c1r4MT === "M") {
                                  setRow3NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                                  setC1R3VNo(10);
                                  setC1R4VNo(10);
                                } else if (c1r4MT === "L") {
                                  setBaseNakedL01(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                    ]
                                  )
                          
                                  setC1R1VNo(10);
                                  setC1R2VNo(10);
                                  setC1R3VNo(10);
                                  setC1R4VNo(10);
                                } else if (c1r4MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                                } 
                              } else {
                                if (c1r3MT === "M" && c1r4MT === "M") {
                                  setRow3NakedM01(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
                                    ]
                                  )
                                  setC1R3VNo(11);
                                  setC1R4VNo(11);
                                  setC1r3MT("M");
                                  setC1r4MT("M");
                                } else if (c1r4MT === "L") {
                                                      setBaseNakedL01(
                                    [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
                                      ]
                                  )
                                setC1R1VNo(11);
                                setC1R2VNo(11);
                                setC1R3VNo(11);
                                setC1R4VNo(11);
                                setC1r1MT("L")
                                setC1r2MT("L")
                                setC1r3MT("L")
                                setC1r4MT("L")
                                } else if (c1r4MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                                } 
                              }
                          
                            } else {
                              alert("You cannot do this as no doors were associated with this module.");
                            }
                          }  else if (curX === col02X && curY === row04X) {
                            if (c2R4VNo === 8) {
                              if (doorTypec2r4 === 9) {
                                if (c2r3MT === "M" && c2r4MT === "M") {
                                  setRow3NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                    ]
                                  )
                                  setC2R3VNo(9);
                                  setC2R4VNo(9);
                                } else if (c2r4MT === "L") {
                                  alert("Round Handle cannot be added to the Large Door.");
                                } else if (c2r4MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                                } 
                              } else if (doorTypec2r4 === 10) {
                                if (c2r3MT === "M" && c2r4MT === "M") {
                                  setRow3NakedM02(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                    ]
                                  )
                                  setC2R3VNo(10);
                                  setC2R4VNo(10);
                                } else if (c2r4MT === "L") {
                                  setBaseNakedL02(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                    ]
                                  )
                          
                                  setC2R1VNo(10);
                                  setC2R2VNo(10);
                                  setC2R3VNo(10);
                                  setC2R4VNo(10);
                                } else if (c2r4MT === "S") {
                                  alert("Select Medium or Large Module and proceed.");
                                } 
                              } else {
                                if (c2r3MT === "M" && c2r4MT === "M") {
                                  setRow3NakedM02(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                                  ]
                                )
                              setC2R3VNo(11);
                              setC2R4VNo(11);
                              setC2r3MT("M");
                              setC2r4MT("M");
                                } else if (c2r4MT === "L") {
                                                              setBaseNakedL02(
                                          [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                                            ]
                                        )
                                      setC2R1VNo(11);
                                      setC2R2VNo(11);
                                      setC2R3VNo(11);
                                      setC2R4VNo(11);
                                      setC2r1MT("L")
                                      setC2r2MT("L")
                                      setC2r3MT("L")
                                      setC2r4MT("L")
                                      } else if (c2r4MT === "S") {
                                        alert("Select Medium or Large Module and proceed.");
                                      } 
                              }
                                
                              } else {
                                alert("You cannot do this as no doors were associated with this module.");
                              }
                              } else if (curX === col03X && curY === row04X) {
                                if (c3R4VNo === 8) {
                                  if (doorTypec3r4 === 9) {
                                    if (c3r3MT === "M" && c3r4MT === "M") {
                                      setRow3NakedM03(
                                        [
                                           <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />      
                                        ]
                                      )
                                      setC3R3VNo(9);
                                      setC3R4VNo(9);
                                    } else if (c3r4MT === "L") {
                                      alert("Round Handle cannot be added to the Large Door.");
                                    } else if (c3r4MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                    } 
                                  } else if (doorTypec3r4 === 10) {
                                    if (c3r3MT === "M" && c3r4MT === "M") {
                                      setRow3NakedM03(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />     
                                        ]
                                      )
                                      setC3R3VNo(10);
                                      setC3R4VNo(10);
                                    } else if (c3r4MT === "L") {
                                      setBaseNakedL03(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                        ]
                                      )
                              
                                      setC3R1VNo(10);
                                      setC3R2VNo(10);
                                      setC3R3VNo(10);
                                      setC3R4VNo(10);
                                    } else if (c3r4MT === "S") {
                                      alert("Select Medium or Large Module and proceed.");
                                    } 
                                  } else {
                                    if (c3r3MT === "M" && c3r4MT === "M") {
                                      setRow3NakedM03(
                                        [
                                        < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />        
                                        ]
                                      )
                                      setC3R3VNo(11);
                                      setC3R4VNo(11);
                                      setC3r3MT("M");
                                      setC3r4MT("M");
                                                                    } else if (c3r4MT === "L") {
                                                                    setBaseNakedL03(
                                                [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                                                  ]
                                              )
                                            setC3R1VNo(11);
                                            setC3R2VNo(11);
                                            setC3R3VNo(11);
                                            setC3R4VNo(11);
                                            setC3r1MT("L")
                                            setC3r2MT("L")
                                            setC3r3MT("L")
                                            setC3r4MT("L")
                                          } else if (c3r4MT === "S") {
                                            alert("Select Medium or Large Module and proceed.");
                                          } 
                                  }
                                 
                                      } else {
                                        alert("You cannot do this as no doors were associated with this module.");
                                      }
                                } else if (curX === col04X && curY === row04X) {
                                  if (c4R4VNo === 8) {
                                    if (doorTypec4r4 === 9) {
                                      if (c4r3MT === "M" && c4r4MT === "M") {
                                        setRow3NakedM04(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />       
                                        ]
                                        )
                                        setC4R3VNo(9);
                                        setC4R4VNo(9);
                                      } else if (c4r4MT === "L") {
                                        alert("Round Handle cannot be added to the Large Door.");
                                      } else if (c4r4MT === "S") {
                                        alert("Select Medium or Large Module and proceed.");
                                      } 
                                    } else if (doorTypec4r4 === 10) {
                                      if (c4r3MT === "M" && c4r4MT === "M") {
                                        setRow3NakedM04(
                                          [
                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />    
                                        ]
                                        )
                                        setC4R3VNo(10);
                                        setC4R4VNo(10);
                                      } else if (c4r4MT === "L") {
                                        setBaseNakedL04(
                                          [
                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                          ]
                                        )
                                
                                        setC4R1VNo(10);
                                        setC4R2VNo(10);
                                        setC4R3VNo(10);
                                        setC4R4VNo(10);
                                      } else if (c4r4MT === "S") {
                                        alert("Select Medium or Large Module and proceed.");
                                      } 
                                    } else {
                                      if (c4r3MT === "M" && c4r4MT === "M" ) {
                                        setRow3NakedM04(
                                               [
                                               < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />          
                                             ]
                                             )
                                           setC4R3VNo(11);
                                           setC4R4VNo(11);
                                           setC4r3MT("M");
                                           setC4r4MT("M");
                                                                           } else if (c4r4MT === "L") {
                                                                           setBaseNakedL04(
                                                     [
                                                         <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                                                       ]
                                                   )
                                                 setC4R1VNo(11);
                                                 setC4R2VNo(11);
                                                 setC4R3VNo(11);
                                                 setC4R4VNo(11);
                                                 setC4r1MT("L")
                                                 setC4r2MT("L")
                                                 setC4r3MT("L")
                                                 setC4r4MT("L")
                                           } else if (c4r4MT === "S") {
                                             alert("Select Medium or Large Module and proceed.");
                                           } 
                                    }
                                  
                                        } else {
                                          alert("You cannot do this as no doors were associated with this module.");
                                        }
                                  } else if (curX === col05X && curY === row04X) {
                                    if (c5R4VNo === 8) {
                                      if (doorTypec5r4 === 9) {
                                        if (c5r3MT === "M" && c5r4MT === "M") {
                                          setRow3NakedM05(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />       
                                           ]
                                          )
                                          setC5R3VNo(9);
                                          setC5R4VNo(9);
                                        } else if (c5r4MT === "L") {
                                          alert("Round Handle cannot be added to the Large Door.");
                                        } else if (c5r4MT === "S") {
                                          alert("Select Medium or Large Module and proceed.");
                                        }
                                      } else if (doorTypec5r4 === 10) {
                                        if (c5r3MT === "M" && c5r4MT === "M") {
                                          setRow3NakedM05(
                                            [
                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />     
                                          ]
                                          )
                                          setC5R3VNo(10);
                                          setC5R4VNo(10);
                                        } else if (c5r4MT === "L") {
                                          setBaseNakedL05(
                                            [
                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                            ]
                                          )
                                  
                                          setC5R1VNo(10);
                                          setC5R2VNo(10);
                                          setC5R3VNo(10);
                                          setC5R4VNo(10);
                                        } else if (c5r4MT === "S") {
                                          alert("Select Medium or Large Module and proceed.");
                                        }
                                      } else {
                                        if (c5r3MT === "M" && c5r4MT === "M") {
                                          setRow3NakedM05(
                                              [
                                              < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />         
                                            ]
                                            )
                                          setC5R3VNo(11);
                                          setC5R4VNo(11);
                                          setC5r3MT("M");
                                          setC5r4MT("M");
                                        } else if (c5r4MT === "L") {
                                                setBaseNakedL05(
                                                    [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                                                      ]
                                                  )
                                                setC5R1VNo(11);
                                                setC5R2VNo(11);
                                                setC5R3VNo(11);
                                                setC5R4VNo(11);
                                                setC5r1MT("L")
                                                setC5r2MT("L")
                                                setC5r3MT("L")
                                                setC5r4MT("L")
                                              } else if (c5r4MT === "S") {
                                                alert("Select Medium or Large Module and proceed.");
                                              } 
                                      }
                                    
                                          } else {
                                            alert("You cannot do this as no doors were associated with this module.");
                                          }
                                    } else if (curX === col06X && curY === row04X) {
                                      if (c6R4VNo === 8) {
                                        if (doorTypec6r4 === 9) {
                                          if (c6r3MT === "M" && c6r4MT === "M") {
                                            setRow3NakedM06(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />     
                                              ]
                                            )
                                            setC6R3VNo(9);
                                            setC6R4VNo(9);
                                          } else if (c6r4MT === "L") {
                                            alert("Round Handle cannot be added to the Large Door.");
                                          } else if (c6r4MT === "S") {
                                            alert("Select Medium or Large Module and proceed.");
                                          } 
                                        } else if (doorTypec6r4 === 10) {
                                          if (c6r3MT === "M" && c6r4MT === "M") {
                                            setRow3NakedM06(
                                              [
                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                                              ]
                                            )
                                            setC6R3VNo(10);
                                            setC6R4VNo(10);
                                          } else if (c6r4MT === "L") {
                                            setBaseNakedL06(
                                              [
                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                              ]
                                            )
                                    
                                            setC6R1VNo(10);
                                            setC6R2VNo(10);
                                            setC6R3VNo(10);
                                            setC6R4VNo(10);
                                          } else if (c6r4MT === "S") {
                                            alert("Select Medium or Large Module and proceed.");
                                          } 
                                        } else {
                                          if (c6r3MT === "M" && c6r4MT === "M") {
                                            setRow3NakedM06(
                                               [
                                               < Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />        
                                               ]
                                             )
                                           setC6R3VNo(11);
                                           setC6R4VNo(11);
                                           setC6r3MT("M");
                                           setC6r4MT("M");
                                         } else if (c6r4MT === "L") {
                                                   setBaseNakedL06(
                                                     [
                                                         <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                                                       ]
                                                   )
                                                 setC6R1VNo(11);
                                                 setC6R2VNo(11);
                                                 setC6R3VNo(11);
                                                 setC6R4VNo(11);
                                                 setC6r1MT("L")
                                                 setC6r2MT("L")
                                                 setC6r3MT("L")
                                                 setC6r4MT("L")
                                               } else if (c6r4MT === "S") {
                                                 alert("Select Medium or Large Module and proceed.");
                                               } 
                                        }
                                     
                                            } else {
                                              alert("You cannot do this as no doors were associated with this module.");
                                            }
                                      } else if (curX === col07X && curY === row04X) {
                                        if (c7R4VNo === 8) {
                                          if (doorTypec7r4 === 9) {
                                            if (c7r3MT === "M" && c7r4MT === "M") {
                                              setRow3NakedM07(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                ]
                                              )
                                              setC7R3VNo(9);
                                              setC7R4VNo(9);
                                            } else if (c7r4MT === "L") {
                                              alert("Round Handle cannot be added to the Large Door.");
                                            } else if (c7r4MT === "S") {
                                              alert("Select Medium or Large Module and proceed.");
                                            }
                                          } else if (doorTypec7r4 === 10) {
                                            if (c7r3MT === "M" && c7r4MT === "M") {
                                              setRow3NakedM07(
                                                [
                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                ]
                                              )
                                              setC7R3VNo(10);
                                              setC7R4VNo(10);
                                            } else if (c7r4MT === "L") {
                                              setBaseNakedL07(
                                                [
                                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                ]
                                              )
                                      
                                              setC7R1VNo(10);
                                              setC7R2VNo(10);
                                              setC7R3VNo(10);
                                              setC7R4VNo(10);
                                            } else if (c7r4MT === "S") {
                                              alert("Select Medium or Large Module and proceed.");
                                            }
                                          } else {
                                            if (c7r3MT === "M" && c7r4MT === "M") {
                                              setRow3NakedM07(
                                                       [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                                                       ]
                                                     )
                                                     setC7R3VNo(11);
                                                     setC7R4VNo(11);
                                                     setC7r3MT("M");
                                                     setC7r4MT("M");
                                                   } else if (c7r4MT === "L") {
                                                         setBaseNakedL07(
                                                             [
                                                                 <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                                                               ]
                                                           )
                                                         setC7R1VNo(11);
                                                         setC7R2VNo(11);
                                                         setC7R3VNo(11);
                                                         setC7R4VNo(11);
                                                         setC7r1MT("L")
                                                         setC7r2MT("L")
                                                         setC7r3MT("L")
                                                         setC7r4MT("L")
                                                 } else if (c7r4MT === "S") {
                                                   alert("Select Medium or Large Module and proceed.");
                                                 } 
                                          }
                                       
                                              } else {
                                                alert("You cannot do this as no doors were associated with this module.");
                                              }
                                        } else if (curX === col08X && curY === row04X) {
                                          if (c8R4VNo === 8) {
                                            if (doorTypec8r4 === 9) {
                                              if (c8r3MT === "M" && c8r4MT === "M") {
                                                setRow3NakedM08(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC8R3VNo(9);
                                                setC8R4VNo(9);
                                              } else if (c8r4MT === "L") {
                                                alert("Round Handle cannot be added to the Large Door.");
                                              } else if (c8r4MT === "S") {
                                                alert("Select Medium or Large Module and proceed.");
                                              } 
                                            } else if (doorTypec8r4 === 10) {
                                              if (c8r3MT === "M" && c8r4MT === "M") {
                                                setRow3NakedM08(
                                                  [
                                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                  ]
                                                )
                                                setC8R3VNo(10);
                                                setC8R4VNo(10);
                                              } else if (c8r4MT === "L") {
                                                setBaseNakedL08(
                                                  [
                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                  ]
                                                )
                                        
                                                setC8R1VNo(10);
                                                setC8R2VNo(10);
                                                setC8R3VNo(10);
                                                setC8R4VNo(10);
                                              } else if (c8r4MT === "S") {
                                                alert("Select Medium or Large Module and proceed.");
                                              } 
                                            } else {
                                              if (c8r3MT === "M" && c8r4MT === "M") {
                                                setRow3NakedM08(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                                                    ]
                                                  )
                                                setC8R3VNo(11);
                                                setC8R4VNo(11);
                                                setC8r3MT("M");
                                                setC8r4MT("M");
                                              } else if (c8r4MT === "L") {
                                                 setBaseNakedL08(
                                                        [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                                                          ]
                                                      )
                                                    setC8R1VNo(11);
                                                    setC8R2VNo(11);
                                                    setC8R3VNo(11);
                                                    setC8R4VNo(11);
                                                    setC8r1MT("L")
                                                    setC8r2MT("L")
                                                    setC8r3MT("L")
                                                    setC8r4MT("L")
                                                    } else if (c8r4MT === "S") {
                                                      alert("Select Medium or Large Module and proceed.");
                                                    } 
                                            }
                                          
                                                } else {
                                                  alert("You cannot do this as no doors were associated with this module.");
                                                }
                                          } else if (curX === col09X && curY === row04X) {
                                            if (c9R4VNo === 8) {
                                              if (doorTypec9r4 === 9) {
                                                if (c9r3MT === "M" && c9r4MT === "M") {
                                                  setRow3NakedM09(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC9R3VNo(9);
                                                  setC9R4VNo(9);
                                                } else if (c9r4MT === "L") {
                                                  alert("Round Handle cannot be added to the Large Door.");
                                                } else if (c9r4MT === "S") {
                                                  alert("Select Medium or Large Module and proceed.");
                                                } 
                                              } else if (doorTypec9r4 === 10) {
                                                if (c9r3MT === "M" && c9r4MT === "M") {
                                                  setRow3NakedM09(
                                                    [
                                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                    ]
                                                  )
                                                  setC9R3VNo(10);
                                                  setC9R4VNo(10);
                                                } else if (c9r4MT === "L") {
                                                  setBaseNakedL09(
                                                    [
                                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                    ]
                                                  )
                                          
                                                  setC9R1VNo(10);
                                                  setC9R2VNo(10);
                                                  setC9R3VNo(10);
                                                  setC9R4VNo(10);
                                                } else if (c9r4MT === "S") {
                                                  alert("Select Medium or Large Module and proceed.");
                                                } 
                                              } else {
                                                if (c9r3MT === "M" && c9r4MT === "M") {
                                                  setRow3NakedM09(
                                                     [
                                                       <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                                                     ]
                                                   )
                                                 setC9R3VNo(11);
                                                 setC9R4VNo(11);
                                                 setC9r3MT("M");
                                                 setC9r4MT("M");
                                                                                           } else if (c9r4MT === "L") {
                                                                                             setBaseNakedL09(
                                                           [
                                                               <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                                                             ]
                                                         )
                                                       setC9R1VNo(11);
                                                       setC9R2VNo(11);
                                                       setC9R3VNo(11);
                                                       setC9R4VNo(11);
                                                       setC9r1MT("L")
                                                       setC9r2MT("L")
                                                       setC9r3MT("L")
                                                       setC9r4MT("L")
                                                     } else if (c9r4MT === "S") {
                                                       alert("Select Medium or Large Module and proceed.");
                                                     } 
                                              }
                                   
                                                  } else {
                                                    alert("You cannot do this as no doors were associated with this module.");
                                                  }
                                            } else if (curX === col10X && curY === row04X) {
                                              if (c10R4VNo === 8) {
                                                if (doorTypec10r4 === 9) {
                                                  if (c10r3MT === "M" && c10r4MT === "M") {
                                                    setRow3NakedM10(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC10R3VNo(9);
                                                    setC10R4VNo(9);
                                                  } else if (c10r4MT === "L") {
                                                    alert("Round Handle cannot be added to the Large Door.");
                                                  } else if (c10r4MT === "S") {
                                                    alert("Select Medium or Large Module and proceed.");
                                                  }
                                                } else if (doorTypec10r4 === 10) {
                                                  if (c10r3MT === "M" && c10r4MT === "M") {
                                                    setRow3NakedM10(
                                                      [
                                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                      ]
                                                    )
                                                    setC10R3VNo(10);
                                                    setC10R4VNo(10);
                                                  } else if (c10r4MT === "L") {
                                                    setBaseNakedL10(
                                                      [
                                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                      ]
                                                    )
                                            
                                                    setC10R1VNo(10);
                                                    setC10R2VNo(10);
                                                    setC10R3VNo(10);
                                                    setC10R4VNo(10);
                                                  } else if (c10r4MT === "S") {
                                                    alert("Select Medium or Large Module and proceed.");
                                                  }
                                                } else {
                                                  if (c10r3MT === "M" && c10r4MT === "M") {
                                                    setRow3NakedM10(
                                                       [
                                                         <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                                       ]
                                                     )
                                                     setC10R3VNo(11);
                                                     setC10R4VNo(11);
                                                     setC10r3MT("M");
                                                     setC10r4MT("M");
                                                             } else if (c10r4MT === "L") {
                                                               setBaseNakedL10(
                                                                 [
                                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                                                   ]
                                                               )
                                                             setC10R1VNo(11);
                                                             setC10R2VNo(11);
                                                             setC10R3VNo(11);
                                                             setC10R4VNo(11);
 
                                                             setC10r1MT("L")
                                                             setC10r2MT("L")
                                                             setC10r3MT("L")
                                                             setC10r4MT("L")
                                                       } else if (c10r4MT === "S") {
                                                         alert("Select Medium or Large Module and proceed.");
                                                       } 
                                                }
                                             
                                                    } else {
                                                      alert("You cannot do this as no doors were associated with this module.");
                                                    }
                                              } else if (curX === col11X && curY === row04X) {
                                                if (c11R4VNo === 8) {
                                                  if (doorTypec11r4 === 9) {
                                                    if (c11r3MT === "M" && c11r4MT === "M") {
                                                      setRow3NakedM11(
                                                        [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC11R3VNo(9);
                                                      setC11R4VNo(9);
                                                    } else if (c11r4MT === "L") {
                                                      alert("Round Handle cannot be added to the Large Door.");
                                                    } else if (c11r4MT === "S") {
                                                      alert("Select Medium or Large Module and proceed.");
                                                    }
                                                  } else if (doorTypec11r4 === 10) {
                                                    if (c11r3MT === "M" && c11r4MT === "M") {
                                                      setRow3NakedM11(
                                                        [
                                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                        ]
                                                      )
                                                      setC11R3VNo(10);
                                                      setC11R4VNo(10);
                                                    } else if (c11r4MT === "L") {
                                                      setBaseNakedL11(
                                                        [
                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                        ]
                                                      )
                                              
                                                      setC11R1VNo(10);
                                                      setC11R2VNo(10);
                                                      setC11R3VNo(10);
                                                      setC11R4VNo(10);
                                                    } else if (c11r4MT === "S") {
                                                      alert("Select Medium or Large Module and proceed.");
                                                    }
                                                  } else {
                                                    if (c11r3MT === "M" && c11r4MT === "M") {
                                                      setRow3NakedM11(
                                                            [
                                                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                                            ]
                                                          )
                                                        setC11R3VNo(11);
                                                        setC11R4VNo(11);
                                                        setC11r3MT("M");
                                                        setC11r4MT("M");
                                                            } else if (c11r4MT === "L") {
                                                              setBaseNakedL11(
                                                                [
                                                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                                                  ]
                                                              )
                                                            setC11R1VNo(11);
                                                            setC11R2VNo(11);
                                                            setC11R3VNo(11);
                                                            setC11R4VNo(11);
                                                            setC11r1MT("L")
                                                            setC11r2MT("L")
                                                            setC11r3MT("L")
                                                            setC11r4MT("L")
                                                          } else if (c11r4MT === "S") {
                                                            alert("Select Medium or Large Module and proceed.");
                                                          } 
                                                  }
                                                  
                                                      } else {
                                                        alert("You cannot do this as no doors were associated with this module.");
                                                      }
                                                } else if (curX === col12X && curY === row04X) {
                                                  if (c12R4VNo === 8) {
                                                    if (doorTypec12r4 === 9) {
                                                      if (c12r3MT === "M" && c12r4MT === "M") {
                                                        setRow3NakedM12(
                                                          [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                          ]
                                                        )
                                                        setC12R3VNo(9);
                                                        setC12R4VNo(9);
                                                      } else if (c12r4MT === "L") {
                                                        alert("Round Handle cannot be added to the Large Door.");
                                                      } else if (c12r4MT === "S") {
                                                        alert("Select Medium or Large Module and proceed.");
                                                      } 
                                                    } else if (doorTypec12r4 === 10) {
                                                      if (c12r3MT === "M" && c12r4MT === "M") {
                                                        setRow3NakedM12(
                                                          [
                                                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                                          ]
                                                        )
                                                        setC12R3VNo(10);
                                                        setC12R4VNo(10);
                                                      } else if (c12r4MT === "L") {
                                                        setBaseNakedL12(
                                                          [
                                                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                          ]
                                                        )
                                                
                                                        setC12R1VNo(10);
                                                        setC12R2VNo(10);
                                                        setC12R3VNo(10);
                                                        setC12R4VNo(10);
                                                      } else if (c12r4MT === "S") {
                                                        alert("Select Medium or Large Module and proceed.");
                                                      } 
                                                    } else {
                                                      if (c12r3MT === "M" && c12r4MT === "M") {
                                                        setRow3NakedM12(
                                                           [
                                                             <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />  
                                                           ]
                                                         )
                                                       setC12R3VNo(11);
                                                       setC12R4VNo(11);
                                                       setC12r3MT("M");
                                                       setC12r4MT("M");
                                                               } else if (c12r4MT === "L") {
                                                                 setBaseNakedL12(
                                                                 [
                                                                     <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                                                   ]
                                                               )
                                                             setC12R1VNo(11);
                                                             setC12R2VNo(11);
                                                             setC12R3VNo(11);
                                                             setC12R4VNo(11);
                                                             setC12r1MT("L")
                                                             setC12r2MT("L")
                                                             setC12r3MT("L")
                                                             setC12r4MT("L")
                                                           } else if (c12r4MT === "S") {
                                                             alert("Select Medium or Large Module and proceed.");
                                                           }
                                                    }
 
                                                        } else {
                                                          alert("You cannot do this as no doors were associated with this module.");
                                                        }
                                                  } else if (curX === col13X && curY === row04X) {
                                                    if (c13R4VNo === 8) {
                                                      if (doorTypec13r4 === 9) {
                                                        if (c13r3MT === "M" && c13r4MT === "M") {
                                                          setRow3NakedM13(
                                                            [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} /> 
                                                            ]
                                                          )
                                                          setC13R3VNo(9);
                                                          setC13R4VNo(9);
                                                        } else if (c13r4MT === "L") {
                                                          alert("Round Handle cannot be added to the Large Door.");
                                                        } else if (c13r4MT === "S") {
                                                          alert("Select Medium or Large Module and proceed.");
                                                        } 
                                                      } else if (doorTypec13r4 === 10) {
                                                        if (c13r3MT === "M" && c13r4MT === "M") {
                                                          setRow3NakedM13(
                                                            [
                                                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                                            ]
                                                          )
                                                          setC13R3VNo(10);
                                                          setC13R4VNo(10);
                                                        } else if (c13r4MT === "L") {
                                                          setBaseNakedL13(
                                                            [
                                                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                            ]
                                                          )
                                                  
                                                          setC13R1VNo(10);
                                                          setC13R2VNo(10);
                                                          setC13R3VNo(10);
                                                          setC13R4VNo(10);
                                                        } else if (c13r4MT === "S") {
                                                          alert("Select Medium or Large Module and proceed.");
                                                        } 
                                                      } else {
                                                        if (c13r3MT === "M" && c13r4MT === "M") {
                                                          setRow3NakedM13(
                                                             [
                                                               <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />  
                                                             ]
                                                           )
                                                           setC13R3VNo(11);
                                                           setC13R4VNo(11);
                                                           setC13r3MT("M");
                                                           setC13r4MT("M");
                                                                 } else if (c13r4MT === "L") {
                                                                   setBaseNakedL13(
                                                                     [
                                                                         <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                                                       ]
                                                                   )
                                                                 setC13R1VNo(11);
                                                                 setC13R2VNo(11);
                                                                 setC13R3VNo(11);
                                                                 setC13R4VNo(11);
 
                                                                 setC13r1MT("L")
                                                                 setC13r2MT("L")
                                                                 setC13r3MT("L")
                                                                 setC13r4MT("L")
                                                             } else if (c13r4MT === "S") {
                                                               alert("Select Medium or Large Module and proceed.");
                                                             } 
                                                      }
                                                   
                                                          } else {
                                                            alert("You cannot do this as no doors were associated with this module.");
                                                          }
                                                    } else if (curX === col14X && curY === row04X) {
                                                      if (c14R4VNo === 8) {
                                                        if (doorTypec14r4 === 9) {
                                                          if (c14r3MT === "M" && c14r4MT === "M") {
                                                            setRow3NakedM14(
                                                              [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC14R3VNo(9);
                                                            setC14R4VNo(9);
                                                          } else if (c14r4MT === "L") {
                                                            alert("Round Handle cannot be added to the Large Door.");
                                                          } else if (c14r4MT === "S") {
                                                            alert("Select Medium or Large Module and proceed.");
                                                          }
                                                        } else if (doorTypec14r4 === 10) {
                                                          if (c14r3MT === "M" && c14r4MT === "M") {
                                                            setRow3NakedM14(
                                                              [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                                              ]
                                                            )
                                                            setC14R3VNo(10);
                                                            setC14R4VNo(10);
                                                          } else if (c14r4MT === "L") {
                                                            setBaseNakedL14(
                                                              [
                                                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                              ]
                                                            )
                                                    
                                                            setC14R1VNo(10);
                                                            setC14R2VNo(10);
                                                            setC14R3VNo(10);
                                                            setC14R4VNo(10);
                                                          } else if (c14r4MT === "S") {
                                                            alert("Select Medium or Large Module and proceed.");
                                                          }
                                                        } else {
                                                          if (c14r3MT === "M" && c14r4MT === "M") {
                                                            setRow3NakedM14(
                                                                [
                                                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />  
                                                                ]
                                                              )
                                                              setC14R3VNo(11);
                                                              setC14R4VNo(11);
                                                              setC14r3MT("M");
                                                              setC14r4MT("M");
                                                                } else if (c14r4MT === "L") {
                                                                    setBaseNakedL14(
                                                                      [
                                                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                                                        ]
                                                                    )
                                                                  setC14R1VNo(11);
                                                                  setC14R2VNo(11);
                                                                  setC14R3VNo(11);
                                                                  setC14R4VNo(11);
  
                                                                  setC14r1MT("L")
                                                                  setC14r2MT("L")
                                                                  setC14r3MT("L")
                                                                  setC14r4MT("L")
                                                                } else if (c14r4MT === "S") {
                                                                  alert("Select Medium or Large Module and proceed.");
                                                                } 
                                                        }
           
                                                            } else {
                                                              alert("You cannot do this as no doors were associated with this module.");
                                                            }
  }

}

let heightCalculate = (e) => {
  if (col01Row04 === true || col02Row04 === true || col03Row04 === true || col04Row04 === true || col05Row04 === true || col06Row04 === true || col07Row04 === true || col08Row04 === true || col09Row04 === true || col10Row04 === true || col11Row04 === true || col12Row04 === true || col13Row04 === true || col14Row04 === true) {
    setFloHeight(1.729);
    flodepth = 0.43;

  } else  if (col01Row03 === true || col02Row03 === true || col03Row03 === true || col04Row03 === true || col05Row03 === true || col06Row03 === true || col07Row03 === true || col08Row03 === true || col09Row03 === true || col10Row03 === true || col11Row03 === true || col12Row03 === true || col13Row03 === true || col14Row03 === true) {
    setFloHeight(1.31);
    flodepth = 0.43;

  } else  if (col01Row02 === true || col02Row02 === true || col03Row02 === true || col04Row02 === true || col05Row02 === true || col06Row02 === true || col07Row02 === true || col08Row02 === true || col09Row02 === true || col10Row02 === true || col11Row02 === true || col12Row02 === true || col13Row02 === true || col14Row02 === true) {
    setFloHeight(0.891);
    flodepth = 0.43;

  } else  if (col01Row01 === true || col02Row01 === true || col03Row01 === true || col04Row01 === true || col05Row01 === true || col06Row01 === true || col07Row01 === true || col08Row01 === true || col09Row01 === true || col10Row01 === true || col11Row01 === true || col12Row01 === true || col13Row01 === true || col14Row01 === true) {
    setFloHeight(0.472);
    flodepth = 0.43;

  } else {
    setFloHeight(0);
    flodepth = 0;
  }
}

let updatePriceWieght = (e) => {
  calculatePriceAndWeight();
  heightCalculate();
}

let checkOutShow = (e) => {
  if (isWallFixed === true) {
        setCheckoutBox(!checkoutBox);
        setDimentionBox(true);
        setModulesBox(true);
        setCustomizeBox(true);
        setColorBox(true);

        calculatePriceAndWeight();
        heightCalculate();

        if (checkoutshowHide ==="./images/arrow.png") {
          checkoutshowHide ="./images/arrow-down.png";
        } else {
          checkoutshowHide ="./images/arrow.png";
        }
        fixRoomHideShow = "./images/arrow.png";
        showHideModules ="./images/arrow.png";
        showHideCustomize = "./images/arrow.png";
        selectColorShowHide = "./images/arrow.png";
      } else {
        alert("Please fix or confirm the room space and proceed.")
      }

}

let showHideDoorsForSeperators = (event) => {
  if (showHideDoors === "HIDE DOORS") {
    hideDoors();
    setShowHideDoors("SHOW DOORS");
  } else {
    showDoors();
    setShowHideDoors("HIDE DOORS");
  }
  updatePriceWieght();
}

let showHideAddRemComBox = (e) => {
  setAddRemoveBox(!addRemoveBox);
  setAddRemSepBox(true);
  setAddRemHandleBox(true);
  if (expandAddRemoveBox ==="./images/arrow.png") {
    expandAddRemoveBox ="./images/arrow-down.png";
  } else {
    expandAddRemoveBox ="./images/arrow.png";
  } 
  expandRemSepBox ="./images/arrow.png";
  expandRemHandleBox = "./images/arrow.png"; 
  updatePriceWieght();
}

//showHideAddRemSepBox
let showHideAddRemSepBox = (e) => {
  setAddRemSepBox(!addRemSepBox);
  setAddRemoveBox(true);
  setAddRemHandleBox(true);
  if (expandRemSepBox ==="./images/arrow.png") {
    expandRemSepBox ="./images/arrow-down.png";
  } else {
    expandRemSepBox ="./images/arrow.png";
  } 
  expandAddRemoveBox ="./images/arrow.png";
  expandRemHandleBox = "./images/arrow.png"; 
  updatePriceWieght();
}

let showHideAddRemHandlesBox = (e) => {
  setAddRemHandleBox(!addRemHandleBox);
  setAddRemoveBox(true);
  setAddRemSepBox(true);
  if (expandRemHandleBox ==="./images/arrow.png") {
    expandRemHandleBox ="./images/arrow-down.png";
  } else {
    expandRemHandleBox ="./images/arrow.png";
  } 
  expandAddRemoveBox ="./images/arrow.png";
  expandRemSepBox = "./images/arrow.png"; 
  updatePriceWieght();
}

let showHideCustomizeBox = (e) => {
  if (isWallFixed === true) {
  setCustomizeBox(!customizeBox);
  setDimentionBox(true);
  setModulesBox(true);
  setColorBox(true);
  setCheckoutBox(true);
  if (showHideCustomize ==="./images/arrow.png") {
    showHideCustomize ="./images/arrow-down.png";
  } else {
    showHideCustomize ="./images/arrow.png";
  } 
  
      fixRoomHideShow = "./images/arrow.png";
        showHideModules ="./images/arrow.png";

        selectColorShowHide = "./images/arrow.png";
        checkoutshowHide = "./images/arrow.png";
      } else {
        alert("Please fix or confirm the room space and proceed.")
      }
  updatePriceWieght();
}



let showHideColorBox = (event) => {
  setColorBox(!colorBox);
  setCustomizeBox(true);
  setDimentionBox(true);
  setModulesBox(true);
  setCheckoutBox(true);
  if (selectColorShowHide ==="./images/arrow.png") {
    selectColorShowHide ="./images/arrow-down.png";
  } else {
        selectColorShowHide ="./images/arrow.png";
  }

  fixRoomHideShow = "./images/arrow.png";
  showHideModules ="./images/arrow.png";
  showHideCustomize ="./images/arrow.png";
  checkoutshowHide = "./images/arrow.png";
  updatePriceWieght();
}


let showHideColorChangeBox = (e) => {
  //setColorBox(!colorBox);
  if (colorBox === true) {
    setColorBox(false);
  } else {
    setColorBox(true);
  }
  
  setDimentionBox(true);
  setModulesBox(true);
  setCustomizeBox(true);
  setColorBox(true);
  setCheckoutBox(true);
  if (selectColorShowHide ==="./images/arrow.png") {
    selectColorShowHide ="./images/arrow-down.png";
      } else {
        selectColorShowHide ="./images/arrow.png";
      }

      fixRoomHideShow = "./images/arrow.png";
        showHideModules ="./images/arrow.png";
        showHideCustomize = "./images/arrow.png";

        checkoutshowHide = "./images/arrow.png";
    updatePriceWieght();
}


const getScreenshot = (e) => {
  var node = document.getElementById('screenshot');
    html2canvas(document.body, {
        allowTaint: true,
        logging:true,
        onrendered: function(canvas) {
        let imagestring = canvas.toDataURL("image/png");
        let link = document.createElement('a');
    	  link.download = 'my-design-screenhot.png';
    	  link.href = imagestring;
    	  link.click();
        }
    });
}


let  createCheckout = (e) => {
  //updatePriceWieght();
  //getScreenshot();
 
  sendMails();
  
}

let saveScreenshot = (e) => {
  updatePriceWieght();
  setTimeout(function() {
    window.print();
  }, 0)
}

const extraTryToShop = (e) => {
  if (noHandleCount === 0) {
    if (designCompleted === false) {
      alert("If you are ready to purchase, Click on Checkout Button. ");
    } else {
      //window.location.replace(urlPath);
      window.open(urlPath, "_blank");
    }
    //
  } else {
    alert("Your design is incomplete. Doors without Handles or knobs are not allowed. Find and fix handles or knobs before proceed with the checkout. ");
  }
}

let showPrice = priceX.toFixed(2);
let showWeight = Math.round(weightX);
let sidePlatesAll = leftSidePlates + rightSidePlates;

let imgUrl = "";

let message = "Design ID:- " + designID + "\n"
              + "Price: Rs." + showPrice.toString() + "\n" + "Weight:" + showWeight.toString() + " Kg" + "\n" 
              + "----------------------------------------------------------" + "\n"
              + "The List of Components" + "\n"
              + "----------------------------------------------------------" + "\n"
              + "TOP PLATES:----> " + topPlates.toString()  + "\n"
              + "BOTTOM PLATES:----> " + bottomPlates.toString()  + "\n"
              + "COLUMNS----> " + columns.toString()  + "\n"
              + "SIDE CONNECTOR -1:----> " + sideConnector1.toString()  + "\n"
              + "PLATE LATERAL TOP COVER:----> " + plateLateralTopCover.toString()  + "\n"
              + "BACK PLATE TOP COVER:----> " + backPlateTopCover.toString()  + "\n"
              + "FOOT BASE:----> " + foot.toString()  + "\n"
              + "KNOB 1 TOP COLUMN COVER:----> " + knowb1TopColCover.toString()  + "\n"
              + "KNOB 2 TOP DOOR PIN----> " + knob2TopDoorPin.toString()  + "\n"
              + "RUBBER PADS----> " + rubberPads.toString()  + "\n"
              + "BACK PLATES:----> " + backplates.toString()  + "\n"
              + "SIDE PLATES (LEFT/RIGHT):----> " + sidePlatesAll.toString()  + "\n"
              + "SMALL DOORS:----> " + smallDoors.toString()  + "\n"
              + "MEDIUM DOORS:----> " + mediumDoors.toString()  + "\n"
              + "DOOR TOP COVERS LR:----> " + doorTopCover.toString()  + "\n"
              + "DOOR BOTTOM COVERS LR:----> " + doorBottomCover.toString()  + "\n"
              + "SCREWS:----> " + screws.toString()  + "\n"
              + "DOOR STOPPER----> " + doorStopper.toString()  + "\n"
              + "LONG HANDLES:----> " + longHandles.toString()  + "\n"
              + "ROUND HANDLES:----> " + roundHandles.toString()  + "\n"
              + "MIDDLE PLATES:----> " + middleSeperators.toString() + "\n" 
              + "----------------------------------------------------------" + "\n"
              + "Colors of the Main Components" + "\n"
              + "----------------------------------------------------------" + "\n"
              + "Frame with top Plate:----> " + bodyColor + "\n" 
              + "Back Plates:----> " + bodyColor + "\n" 
              + "Side Plates:----> " + bodyColor + "\n" 
              + "Inner Middle Plates:----> " + bodyColor + "\n" 
              + "Base Plate with Feet:----> " + BaseColor + "\n" 
              + "Left Doors:----> " + leftDoorColor + "\n" 
              + "Right Doors:----> " + rightDoorCOlor + "\n" 
              + "Left Handles/Knobs:----> " + leftHandleColor + "\n" 
              + "Right Handles/Knobs:----> " + rightHandleColor + "\n" 
              + "----------------------------------------------------------" + "\n"
              + "Below you see the Design Structure in Digital Format. Use the Guideline for Recreation"  + "\n" 
              + "Dimensions:------------------------------ " + "\n" 
              + "[Room Length:----> " + inputWidth + " M ] " + "[Room Height:----> " + inputHeight + " M ] " + "[Room Width:----> " + inputDepth + " M ]" + "\n"
              + "[Flo Length:----> " + floWidth.toFixed(2) + " M ] " + "[Flo Height:----> " + floHeight.toFixed(2) + " M ]" + "\n"
              + "Digital Structural Chart of Your Design:" + "\n" 
              + "[ " + RCol1Row4.toFixed(1) + " ]" + "[ " + RCol2Row4.toFixed(1) + " ]" + "[ " + RCol3Row4.toFixed(1) + " ]" + "[ " + RCol4Row4.toFixed(1) + " ]" + "[ " + RCol5Row4.toFixed(1) + " ]" + "[ " + RCol6Row4.toFixed(1) + " ]" + "[ " + RCol7Row4.toFixed(1) + " ]" + "[ " + RCol8Row4.toFixed(1) + " ]" + "[ " + RCol9Row4.toFixed(1) + " ]" + "[ " + RCol10Row4.toFixed(1) + " ]" + "[ " + RCol11Row4.toFixed(1) + " ]" + "[ " + RCol12Row4.toFixed(1) + " ]" + "[ " + RCol13Row4.toFixed(1) + " ]" + "[ " + RCol14Row4.toFixed(1) + " ]" + "\n" 
              + "[ " + RCol1Row3.toFixed(1) + " ]" + "[ " + RCol2Row3.toFixed(1) + " ]" + "[ " + RCol3Row3.toFixed(1) + " ]" + "[ " + RCol4Row3.toFixed(1) + " ]" + "[ " + RCol5Row3.toFixed(1) + " ]" + "[ " + RCol6Row3.toFixed(1) + " ]" + "[ " + RCol7Row3.toFixed(1) + " ]" + "[ " + RCol8Row3.toFixed(1) + " ]" + "[ " + RCol9Row3.toFixed(1) + " ]" + "[ " + RCol10Row3.toFixed(1) + " ]" + "[ " + RCol11Row3.toFixed(1) + " ]" + "[ " + RCol12Row3.toFixed(1) + " ]" + "[ " + RCol13Row3.toFixed(1) + " ]" + "[ " + RCol14Row3.toFixed(1) + " ]" + "\n"
              + "[ " + RCol1Row2.toFixed(1) + " ]" + "[ " + RCol2Row2.toFixed(1) + " ]" + "[ " + RCol3Row2.toFixed(1) + " ]" + "[ " + RCol4Row2.toFixed(1) + " ]" + "[ " + RCol5Row2.toFixed(1) + " ]" + "[ " + RCol6Row2.toFixed(1) + " ]" + "[ " + RCol7Row2.toFixed(1) + " ]" + "[ " + RCol8Row2.toFixed(1) + " ]" + "[ " + RCol9Row2.toFixed(1) + " ]" + "[ " + RCol10Row2.toFixed(1) + " ]" + "[ " + RCol11Row2.toFixed(1) + " ]" + "[ " + RCol12Row2.toFixed(1) + " ]" + "[ " + RCol13Row2.toFixed(1) + " ]" + "[ " + RCol14Row2.toFixed(1) + " ]" + "\n"
              + "[ " + RCol1Row1.toFixed(1) + " ]" + "[ " + RCol2Row1.toFixed(1) + " ]" + "[ " + RCol3Row1.toFixed(1) + " ]" + "[ " + RCol4Row1.toFixed(1) + " ]" + "[ " + RCol5Row1.toFixed(1) + " ]" + "[ " + RCol6Row1.toFixed(1) + " ]" + "[ " + RCol7Row1.toFixed(1) + " ]" + "[ " + RCol8Row1.toFixed(1) + " ]" + "[ " + RCol9Row1.toFixed(1) + " ]" + "[ " + RCol10Row1.toFixed(1) + " ]" + "[ " + RCol11Row1.toFixed(1) + " ]" + "[ " + RCol12Row1.toFixed(1) + " ]" + "[ " + RCol13Row1.toFixed(1) + " ]" + "[ " + RCol14Row1.toFixed(1) + " ]" 

const form = useRef();


  const sendMails = (e) => {
  if (doorRemoved === false) {
    
  if (noHandleCount === 0) {
    if (designCompleted === false) {
        e.preventDefault();
                  
      if (priceX === 0) {
        alert("No Product creation has been done yet.");
      } else {
        if (window.confirm("If you want to place a purchase order for your new design click -OK- and then you will be redirected to our Online Shop. Otherwise you can just -Cancel-") === true) {

          let designData = new FormData();
          designData.append('design_no', designID)
          designData.append('price', priceX)
          designData.append('floweight', weightX)
          designData.append('roomlength', inputWidth)
          designData.append('roomheight', inputHeight)
          designData.append('roomwidth', inputDepth)
          designData.append('flolength', floWidth)
          designData.append('floheight', floHeight)
          designData.append('c1r1', RCol1Row1)
          designData.append('c1r2', RCol1Row2)
          designData.append('c1r3', RCol1Row3)
          designData.append('c1r4', RCol1Row4)
          designData.append('c2r1', RCol2Row1)
          designData.append('c2r2', RCol2Row2)
          designData.append('c2r3', RCol2Row3)
          designData.append('c2r4', RCol2Row4)
          designData.append('c3r1', RCol3Row1)
          designData.append('c3r2', RCol3Row2)
          designData.append('c3r3', RCol3Row3)
          designData.append('c3r4', RCol3Row4)
          designData.append('c4r1', RCol4Row1)
          designData.append('c4r2', RCol4Row2)
          designData.append('c4r3', RCol4Row3)
          designData.append('c4r4', RCol4Row4)
          designData.append('c5r1', RCol5Row1)
          designData.append('c5r2', RCol5Row2)
          designData.append('c5r3', RCol5Row3)
          designData.append('c5r4', RCol5Row4)
          designData.append('c6r1', RCol6Row1)
          designData.append('c6r2', RCol6Row2)
          designData.append('c6r3', RCol6Row3)
          designData.append('c6r4', RCol6Row4)
          designData.append('c7r1', RCol7Row1)
          designData.append('c7r2', RCol7Row2)
          designData.append('c7r3', RCol7Row3)
          designData.append('c7r4', RCol7Row4)
          designData.append('c8r1', RCol8Row1)
          designData.append('c8r2', RCol8Row2)
          designData.append('c8r3', RCol8Row3)
          designData.append('c8r4', RCol8Row4)
          designData.append('c9r1', RCol9Row1)
          designData.append('c9r2', RCol9Row2)
          designData.append('c9r3', RCol9Row3)
          designData.append('c9r4', RCol9Row4)
          designData.append('c10r1', RCol10Row1)
          designData.append('c10r2', RCol10Row2)
          designData.append('c10r3', RCol10Row3)
          designData.append('c10r4', RCol10Row4)
          designData.append('c11r1', RCol11Row1)
          designData.append('c11r2', RCol11Row2)
          designData.append('c11r3', RCol11Row3)
          designData.append('c11r4', RCol11Row4)
          designData.append('c12r1', RCol12Row1)
          designData.append('c12r2', RCol12Row2)
          designData.append('c12r3', RCol12Row3)
          designData.append('c12r4', RCol12Row4)
          designData.append('c13r1', RCol13Row1)
          designData.append('c13r2', RCol13Row2)
          designData.append('c13r3', RCol13Row3)
          designData.append('c13r4', RCol13Row4)
          designData.append('c14r1', RCol14Row1)
          designData.append('c14r2', RCol14Row2)
          designData.append('c14r3', RCol14Row3)
          designData.append('c14r4', RCol14Row4)
          designData.append('maincolor', bodyColor)
          designData.append('basecolor', BaseColor)
          designData.append('leftdoorcolor', leftDoorColor)
          designData.append('rightdoorcolor', rightDoorCOlor)
          designData.append('lefthandlecolor', leftHandleColor)
          designData.append('righthandlecolor', rightHandleColor)
      
          axios({
              method: 'post',
              url: 'https://api.phoenix.lk/flodataread/api/savedata.php',
              data: designData,
              
          })
          .then(function (response) {
              //handle success
              //dataSaved = true;
              //console.log(response)
              //alert('Data Successfully Sent & Added.');  
              emailjs.sendForm('service_h3zzdoj', 'template_9269pep', form.current, '-ZUNCMWigVViqPG5E')
              .then((result) => {
                let formData = new FormData();
                formData.append('name', product_name)
                formData.append('added_by', 'admin')
                formData.append('user_id', 9)
                formData.append('category_id', 56)
                formData.append('brand_id', 1)
                formData.append('photos', '3150')
                formData.append('thumbnail_img', '3150')
                formData.append('video_provider', 'youtube')
                formData.append('tags', 'customer designed FLO product')
                formData.append('description', '<p>Here, you are about to purchase your own design of FLO Modular Storage System you just created using Phoenix FLO Configurator. You can order more than one unit of your own design.</p>')
  
                formData.append('unit_price', priceX)
                formData.append('variant_product', 0)
                formData.append('attributes', '[]')
                formData.append('choice_options', '[]')
                formData.append('colors', '[]')
  
                formData.append('todays_deal', 0)
                formData.append('published', 1)
                formData.append('approved', 1)
                formData.append('stock_visibility_state', 'quantity')
                formData.append('cash_on_delivery', 0)
                formData.append('featured', 0)
                formData.append('seller_featured', 0)
                formData.append('current_stock', 10)
                formData.append('unit', 'Pc')
                formData.append('weight', weightX)
                formData.append('min_qty', 1)
                formData.append('low_stock_quantity', 1)
                formData.append('discount', 0)
                formData.append('discount_type', 'amount')
  
                formData.append('shipping_type', 'free')
                formData.append('shipping_cost', 0)
  
                formData.append('is_quantity_multiplied', 0)
                formData.append('num_of_sale', 0)
                formData.append('meta_title', 'Phoenix Flo product designed by the customer')
                formData.append('meta_description', 'Here, you are about to purchase your own design of FLO Modular Storage System you just created using Phoenix Flo Configurator. You can order more than one unit of your custom product.')
                formData.append('meta_img', '3150')
  
                formData.append('slug', slugtext)
                formData.append('earn_point', 0)
                formData.append('rating', 0)
                formData.append('digital', 0)
                formData.append('auction_product', 0)
                formData.append('wholesale_product', 0)
                
                axios({
                    method: 'post',
                    url: 'https://api.phoenix.lk/floproducts/api/floproduct.php',
                    data: formData,
                    
                })
                .then(function (response) {
                    //handle success
                    //console.log(response)
                    //alert('New Entry in the Online Shop is successfully created. Wait until you will be relocated.');  
                    //window.location.replace(urlPath);
                    window.open(urlPath, "_blank");
                })
                .catch(function (response) {
                    //handle error
                    console.log(response)
                  });
                }, (error) => {
                    alert("Purchase Order not submitted.");
                });
                designCompleted = true;
      
          })
          .catch(function (response) {
              //handle error
              //dataSaved = false;
              alert("Something went wrong. Try again or contact Phoenix Customer care.");
              console.log(response)
              
          });
          
          
          
          //setTimeout(function() {
            //window.print();
          //}, 0)
          
        } 
        //else {
          //alert("You can just get an screenshot of your design.");
          //setTimeout(function() {
            //window.print();
          //}, 0)
        //}
      } 
    } else {
      if (window.confirm("You have already completed your design. Do you want to create another new design?") === true) {
      window.location.reload();
      }
    }
  } else {
    alert("Your design is incomplete. Doors without Handles or knobs are not allowed. Find and fix handles or knobs before proceed with the checkout. ");
  }
  } else {
    alert("Check whether you have re-fixed the doors you already removed while adding shelves before proceed with the checkout.");
  }      
};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const findDesignReloader = (e) => {
    const reloaderurl = "https://reloader.phoenix.lk/";
    window.open(reloaderurl, "_blank");
  }
  const jsutSaveDesign = (e) => {
    if (doorRemoved === false) {
    
      if (noHandleCount === 0) {
        if (designCompleted === false) {
          if (priceX === 0) {
            alert("No Product creation has been done yet.");
          } else {
          
    
              let designData = new FormData();
              designData.append('design_no', designID)
              designData.append('price', priceX)
              designData.append('floweight', weightX)
              designData.append('roomlength', inputWidth)
              designData.append('roomheight', inputHeight)
              designData.append('roomwidth', inputDepth)
              designData.append('flolength', floWidth)
              designData.append('floheight', floHeight)
              designData.append('c1r1', RCol1Row1)
              designData.append('c1r2', RCol1Row2)
              designData.append('c1r3', RCol1Row3)
              designData.append('c1r4', RCol1Row4)
              designData.append('c2r1', RCol2Row1)
              designData.append('c2r2', RCol2Row2)
              designData.append('c2r3', RCol2Row3)
              designData.append('c2r4', RCol2Row4)
              designData.append('c3r1', RCol3Row1)
              designData.append('c3r2', RCol3Row2)
              designData.append('c3r3', RCol3Row3)
              designData.append('c3r4', RCol3Row4)
              designData.append('c4r1', RCol4Row1)
              designData.append('c4r2', RCol4Row2)
              designData.append('c4r3', RCol4Row3)
              designData.append('c4r4', RCol4Row4)
              designData.append('c5r1', RCol5Row1)
              designData.append('c5r2', RCol5Row2)
              designData.append('c5r3', RCol5Row3)
              designData.append('c5r4', RCol5Row4)
              designData.append('c6r1', RCol6Row1)
              designData.append('c6r2', RCol6Row2)
              designData.append('c6r3', RCol6Row3)
              designData.append('c6r4', RCol6Row4)
              designData.append('c7r1', RCol7Row1)
              designData.append('c7r2', RCol7Row2)
              designData.append('c7r3', RCol7Row3)
              designData.append('c7r4', RCol7Row4)
              designData.append('c8r1', RCol8Row1)
              designData.append('c8r2', RCol8Row2)
              designData.append('c8r3', RCol8Row3)
              designData.append('c8r4', RCol8Row4)
              designData.append('c9r1', RCol9Row1)
              designData.append('c9r2', RCol9Row2)
              designData.append('c9r3', RCol9Row3)
              designData.append('c9r4', RCol9Row4)
              designData.append('c10r1', RCol10Row1)
              designData.append('c10r2', RCol10Row2)
              designData.append('c10r3', RCol10Row3)
              designData.append('c10r4', RCol10Row4)
              designData.append('c11r1', RCol11Row1)
              designData.append('c11r2', RCol11Row2)
              designData.append('c11r3', RCol11Row3)
              designData.append('c11r4', RCol11Row4)
              designData.append('c12r1', RCol12Row1)
              designData.append('c12r2', RCol12Row2)
              designData.append('c12r3', RCol12Row3)
              designData.append('c12r4', RCol12Row4)
              designData.append('c13r1', RCol13Row1)
              designData.append('c13r2', RCol13Row2)
              designData.append('c13r3', RCol13Row3)
              designData.append('c13r4', RCol13Row4)
              designData.append('c14r1', RCol14Row1)
              designData.append('c14r2', RCol14Row2)
              designData.append('c14r3', RCol14Row3)
              designData.append('c14r4', RCol14Row4)
              designData.append('maincolor', bodyColor)
              designData.append('basecolor', BaseColor)
              designData.append('leftdoorcolor', leftDoorColor)
              designData.append('rightdoorcolor', rightDoorCOlor)
              designData.append('lefthandlecolor', leftHandleColor)
              designData.append('righthandlecolor', rightHandleColor)
          
              axios({
                method: 'post',
                url: 'https://api.phoenix.lk/flodataread/api/savedata.php',
                data: designData,
                
            })
            .then(function (response) {
              alert("Your Design is just saved. You can reload it anytime.")
            })
            .catch(function (response) {
                //handle error
                //dataSaved = false;
                alert("Something went wrong. Try again or contact Phoenix Customer care.");
                console.log(response)
                
            });  
              designCompleted = true;

            }

        } else {
          if (window.confirm("You have already completed your design. Do you want to create another new design?") === true) {
          window.location.reload();
          }
        }

      } else {
          alert("Your design is incomplete. Doors without Handles or knobs are not allowed. Find and fix handles or knobs before proceed with the checkout. ");
      }
    } else {
          alert("Check whether you have re-fixed the doors you already removed while adding shelves before proceed with the checkout.");
    }     
  }

  
//onChange={(e) => setInputDepth(e.target.value)}

//const transform = useRef();
// <>
//<TransformControls ref={transform} matrix>
// </TransformControls>
// </>          
  return (
    <div className="row">
      <div  className="canvas-card">
        <div className="top-bar">
            <div className="row">
              <div className="title-image-box">
                <img src="./images/title-image.png" alt="Flo Logo" width="100%"/>
              </div>
             
              <div className="navigatorX">
                <table>
                  <tr>
                    <td>
                        <div className="layerReset">
                          <div className="reset-all">
                            <button className="image-buttons" onClick={restAndFixRoomAgain}><img src="./images/reset-button.png" alt="joystick up" width="70px"/></button>
                          </div>
                          <div className="remove-walls">
                            <button className="image-buttons" onClick={onOffWalls}><img src={wallshowhideimg} alt="joystick up" width="70px"/></button>
                          </div>
                        </div>
                    </td>
                    <td>
                    <h4 className="white-Title">Navigation</h4>
                  <div className="navigator">
                    <div className="layerAb">
                      <div className="up-arrow" onClick={moveUp}>
                        <img src={navigatorUpArrow} alt="joystick up" width="40px"/>
                      </div>
                      <div className="left-arrow" onClick={moveLeft}>
                        <img src={navigatorLeftArrow} alt="joystick left" width="40px"/>
                      </div>
                      <div className="right-arrow" onClick={moveRight}>
                        <img src={navigatorRightArrow} alt="joystick right" width="40px"/>
                      </div>
                      <div className="down-arrow" onClick={moveDown}>
                        <img src={navigatorDownArrow} alt="joystick up" width="40px"/>
                      </div>
                    </div>
                  
                  </div>
                    </td>
                    
                  </tr>
                </table>
              </div>
              <div className="navigatorX">
                  <div className="navigator">
                    <button className="image-buttons"  onClick={openModal}><img src="./images/help-button.png" alt="Flo Logo" width="70px"/></button>
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        contentLabel="User Documentation"
                      >
                        <table>
                          <tr>
                            <td><h2>Your Comprehensive Guide to Using Phoenix FLO Configurator</h2></td>
                            <td><button className="button2" onClick={closeModal}>Close</button></td>
                          </tr>
                        </table>
                        <ul>
                          <li>For the best experience of the FLO configurator, we suggest accessing via a web browser (Google Chrome is recommended). You may find some limitations if using this on a phone. </li>
                          <li>Getting started is easy!
                            <ul type="circle">
                              <li>Once you specify your space by clicking <img src="./images/proceed.png" alt="" height="25px"/> button – you are good to get going! 
                              </li>
                              <li>Flow through the various customizations to configure your own FLO Modular Storage System</li>
                            </ul>
                          </li>
                          <li>A few TIPS to make your journey with us easier:
                          <ul type="circle">
                              <li>Once you add your units, to move through units and customize, use the <img src="./images/navigation.png" alt="" height="60px"/> button to navigate to the desired unit to add your customization. 
                              <br></br><i><b>OR use the keyboard</b></i><br></br>Use the keyboard left, right and up, down arrows to navigate. But note that each time you click out of the room space, you will need to click back in to activate the keyboard toggle options.
                              </li>
                              <li>Adding units vertically: <br></br>By default units will add next to each other horizontally. To add units vertically, see that the orientation is set to Portrait. You can toggle between landscape and portrait using the <img src="./images/orientation.png" alt="" height="25px"/> button, or <b>by clicking the space bar</b> when in the room environment.
                              </li>
                              <li>Use your mouse to navigate through the space 360°</li>
                              <li>You can at any time view your work space with and without walls by clicking the <img src="./images/remove-walls.png" alt="" height="45px"/> button and <img src="./images/show-walls.png" alt="" height="45px"/> button on the top of the workspace.</li>
                              <li>To configure your shelves, you can hide and show doors at any time using the <img src="./images/Open-Doors.png" alt="" height="45px"/> and <img src="./images/Close-Doors.png" alt="" height="45px"/> buttons.</li>
                              <li>The <img src="./images/reset-button.png" alt="" height="45px"/> button will reset the entire workspace back to default. Do this if you want to start over from scratch.</li>
                              <li><img src="./images/warning.png" alt="" height="25px"/> If you change the size of the room mid-way through, your current configuration will reset.</li>
                              <li><img src="./images/warning.png" alt="" height="25px"/> You cannot remove modules in a way that creates a gap in the unit. You can only remove modules from right to left, or top to bottom.</li>
                              <li><img src="./images/warning.png" alt="" height="25px"/> Your construction will always start from the left of the space and you cannot move the entire unit around the space
                              .</li>
                            </ul>
                          </li>
                          <li>You can customize your Flo in 3 steps: It is recommended that you follow the order of the customization steps
                          <ul type="circle">
                              <li><b>STEP 01: Adding or Removing Main Components:</b><br></br>
                              When you open “Customize your Flo” section you see this <img src="./images/adding.png" alt="" height="45px"/> label where you can add main components <span>(Back Panel, Side Panels, Doors etc.)</span> In order to remove any component, click on <img src="./images/selectremove.png" alt="" height="45px"/>button and select the desired component.
                              </li>
                              <li><b>Adding or Removing Inner (Middle, Top, Bottom) Plates: </b><br></br>
                              This section is applicable only for Medium or Large Modules only. First hide doors if available and use buttons for medium or large units separately. <img src="./images/Add-Middle-Plate.png" alt="" height="45px"/> Gray buttons for adding Plates and <img src="./images/remove-mid-plate.png" alt="" height="45px"/> Red buttons for removing plates.  It’s always a good practice to add inner plates once you finish adding all the main components.
                              </li>
                              <li><b>Adding or Removing Handles or Knobs: </b><br></br>
                              We suggest adding knobs or Handles as the final step of your customization before changing colors.
                              </li>
                          </ul>
                          </li>
                          <li>On completion of your Flo design, then you can decide colors for components separately.
                            <ul type="circle">
                              <li> Select the Coloring zones first and then select the relevant colors to be applied. After the appropriate selections are done, you can apply color changes just clicking on the <img src="./images/changecolor.png" alt="" height="25px"/> button.</li>
                            </ul>
                          </li>
                          <li>As the final step you can check Pricing, Dimensions and Weight of your own Flo product and then place your order on Phoenix Online Shop.
                            <ul type="circle">
                              <li>
                                When you click on <img src="./images/checkout.png" alt="" height="45px"/> Button, it sends your purchase order to Phoenix Online Shop. The shop itself automatically creates a new product entry and you will be redirected to the product page where you can complete the order and make payments.
                                <br></br>
                                <b>Note:</b><i> If you have added doors to any Module, you cannot proceed with the checkout until you add Handles or Knobs. Large Modules always come with Long Handles only.</i>
                              </li>
                              <li>
                                Your Design will further be verified by the Phoenix. Later. you can use our design reloader application (<a href="https://reloader.phoenix.lk/" target="_blank">reloader.phoenix.lk</a>) to re-check your design again. Just enter your full Design ID and relaod your design there.
                              </li>
                              <li>
                                <b>Always Remember to pick up your Design ID which appears below the Checkout (Your Flo) Details section</b> 
                              </li>
                            </ul>
                          </li>
                        </ul>
                      
                        <table>
                          <tr>
                            <td><button className="button2" onClick={closeModal}>Close</button></td>
                            <td></td>
                          </tr>
                        </table>
                        
                    </Modal>

                  </div>
                </div>
            </div>
          </div>
          <div className="canvasArea" id="screenshot">
          <Canvas style={{ background: "#595757" }} dpr={window.devicePixelRatio} tabIndex='0' onKeyDown={handleKeyDown} >
            <Suspense fallback={<Loader />}>
              <OrbitControls makeDefault />
                <Physics gravity={[0, -10, 0]} size={100} tolerance={0.001} iterations={5} broadphase={"Naive"} step={1/60} shouldInvalidate={true} children allowSleep={false} axisIndex={0} defaultContactMaterial={1e6} id="screenshot">
                  <WideWall x={inputWidth} y={0.1} z={inputHeight} position={[0,posy,0]} rotation={[Math.PI / 2, 0, 0]} visible={wallvisible}/>
                  <SideWalls x={inputHeight} y={0.1} z={inputDepth} position={[-posx,posy,posz]} rotation={[0, 0, Math.PI / 2]} visible={wallvisible}/>
                  <SideWalls x={inputHeight} y={0.1} z={inputDepth} position={[posx,posy,posz]} rotation={[0, 0, Math.PI / 2]} visible={wallvisible}/>
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn, seperatorOn:seperatorOn }} position={[2,200,-300]}/>
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn, seperatorOn:seperatorOn }} position={[3,200,-300]}/>                          
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn}} position={[4,200,-300]}/> 
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn}} position={[-1,200,-300]}/> 
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3 }} position={[-2,200,-300]}/>

                  
                  <Pointer position={[curX+0.2,curY-0.2, 0.53]} rotation={[0, 0, 0]} visible={rightArrrow}/> 
                  <Pointer position={[curX+0.2,curY-0.2, 0.53]} rotation={[0, Math.PI, 0]} visible={leftArrrow}/> 
                  <Pointer position={[curX+0.4,curY+0.2, 0.53]} rotation={[0, 0, Math.PI / 2]} visible={upArrrow}/>   
                  <Pointer position={[curX-0.2,curY+0.2, 0.53]} rotation={[0, 0, -Math.PI / 2]} visible={downArrrow}/>    
                  
                 
                              {[...baseNaked01]}
                              {[...baseNaked02]}
                              {[...baseNaked03]}
                              {[...baseNaked04]}
                              {[...baseNaked05]}
                              {[...baseNaked06]}
                              {[...baseNaked07]}
                              {[...baseNaked08]}
                              {[...baseNaked09]}
                              {[...baseNaked10]}
                              {[...baseNaked11]}
                              {[...baseNaked12]}
                              {[...baseNaked13]}
                              {[...baseNaked14]}

                              {[...row2Naked01]}
                              {[...row2Naked02]}
                              {[...row2Naked03]}
                              {[...row2Naked04]}
                              {[...row2Naked05]}
                              {[...row2Naked06]}
                              {[...row2Naked07]}
                              {[...row2Naked08]}
                              {[...row2Naked09]}
                              {[...row2Naked10]}
                              {[...row2Naked11]}
                              {[...row2Naked12]}
                              {[...row2Naked13]}
                              {[...row2Naked14]}

                              {[...row3Naked01]}
                              {[...row3Naked02]}
                              {[...row3Naked03]}
                              {[...row3Naked04]}
                              {[...row3Naked05]}
                              {[...row3Naked06]}
                              {[...row3Naked07]}
                              {[...row3Naked08]}
                              {[...row3Naked09]}
                              {[...row3Naked10]}
                              {[...row3Naked11]}
                              {[...row3Naked12]}
                              {[...row3Naked13]}
                              {[...row3Naked14]}

                              {[...baseNakedM01]}
                              {[...baseNakedM02]}
                              {[...baseNakedM03]}
                              {[...baseNakedM04]}
                              {[...baseNakedM05]}
                              {[...baseNakedM06]}
                              {[...baseNakedM07]}
                              {[...baseNakedM08]}
                              {[...baseNakedM09]}
                              {[...baseNakedM10]}
                              {[...baseNakedM11]}
                              {[...baseNakedM12]}
                              {[...baseNakedM13]}
                              {[...baseNakedM14]}

                              {[...row2NakedM01]}
                              {[...row2NakedM02]}
                              {[...row2NakedM03]}
                              {[...row2NakedM04]}
                              {[...row2NakedM05]}
                              {[...row2NakedM06]}
                              {[...row2NakedM07]}
                              {[...row2NakedM08]}
                              {[...row2NakedM09]}
                              {[...row2NakedM10]}
                              {[...row2NakedM11]}
                              {[...row2NakedM12]}
                              {[...row2NakedM13]}
                              {[...row2NakedM14]}


                              {[...row4Naked01]}
                              {[...row4Naked02]}
                              {[...row4Naked03]}
                              {[...row4Naked04]}
                              {[...row4Naked05]}
                              {[...row4Naked06]}
                              {[...row4Naked07]}
                              {[...row4Naked08]}
                              {[...row4Naked09]}
                              {[...row4Naked10]}
                              {[...row4Naked11]}
                              {[...row4Naked12]}
                              {[...row4Naked13]}
                              {[...row4Naked14]}

                              {[...row3NakedM01]}
                              {[...row3NakedM02]}
                              {[...row3NakedM03]}
                              {[...row3NakedM04]}
                              {[...row3NakedM05]}
                              {[...row3NakedM06]}
                              {[...row3NakedM07]}
                              {[...row3NakedM08]}
                              {[...row3NakedM09]}
                              {[...row3NakedM10]}
                              {[...row3NakedM11]}
                              {[...row3NakedM12]}
                              {[...row3NakedM13]}
                              {[...row3NakedM14]}

                              {[...baseNakedL01]}
                              {[...baseNakedL02]}
                              {[...baseNakedL03]}
                              {[...baseNakedL04]}
                              {[...baseNakedL05]}
                              {[...baseNakedL06]}
                              {[...baseNakedL07]}
                              {[...baseNakedL08]}
                              {[...baseNakedL09]}
                              {[...baseNakedL10]}
                              {[...baseNakedL11]}
                              {[...baseNakedL12]}
                              {[...baseNakedL13]}
                              {[...baseNakedL14]}

      

                  <Floor x={inputDepth} y={0.05} z={inputWidth} position={[0,0,posz]} rotation={[0, Math.PI / 2, 0]}/>       
                  <Plane  />
                </Physics>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
            </Suspense>
          </Canvas>
          </div>
          <div className="status-bar">
            <table className="tablefontsize">
              <tr>
                <td>FLO Length: {floWidth.toFixed(2)} Meters</td>
                <td>FLO Height: {floHeight.toFixed(2)} Meters</td>
                <td>FLO width: {flodepth.toFixed(2)} Meters</td>
                <td>FLO Weight: {Math.round(weightX)} Kg</td>
                <td><button className="button" value="#ff635a" onClick={saveScreenshot}>GET YOUR SCREENSHOT</button></td>
              </tr>
            </table>
          </div>
      </div>
      <div className="right-panel-card">
          <div className="row">
              <div className="brand-statement-txt">
              <p><span class="brand-content-span">Specify</span> Your Space:</p>
              </div>
              <div className="segments">
              <button className="image-buttons" onClick={restAndFixRoomAgain}><img src={fixRoomHideShow} alt="expand" width="40px"/></button>
              </div>
          </div>
            <div className={`dimensionsOn ${dimentionBox ? "dimensionsOff" : ""}`}>
               
                    <div className="dimensions">
                      <table>
                        <tr>
                          <td><img src="./images/Length.png" alt="Small Module" width="50px"/></td>
                          <td>                           
                              <div>
                                  <input className="input" type="number" min="0" max="11" name="roomwidth" maxlength="2" size="2" value={inputWidth} onChange={(e) => setInputWidth(e.target.value)}/>
                              </div>
                          </td>
                          <td><img src="./images/M.png" alt="Meter" width="20px"/></td> 
                          <td><img src="./images/blank.png" alt="joystick blank" width="10px"/></td>     
                          <td><img src="./images/Height.png" alt="Small Module" width="50px"/></td>    
                          <td><div><input className="input" type="number" min="0" max="5" name="roomheight" maxlength="2" size="2" value={inputHeight} 
                                    onChange={(e) => setInputHeight(e.target.value)} /></div></td>
                          <td><img src="./images/M.png" alt="Meter" width="20px"/></td> 
                          <td><img src="./images/blank.png" alt="joystick blank" width="10px"/></td> 
                          <td><img src="./images/Width.png" alt="Small Module" width="50px"/></td>
                          <td><div><input className="input" type="number" min="0" max="10" name="roomdepth" maxlength="2" size="2" value={inputDepth} 
                                      onChange={(e) => setInputDepth(e.target.value)} /></div></td>
                          <td><img src="./images/M.png" alt="Meter" width="20px"/></td> 
                        </tr>
                      </table>
                    </div>
                    <div className="layerAbFtO">
                      <div className="ftLabels">
                      <table>
                        <tr>
                          <td><img src="./images/blank.png" alt="joystick blank" width="55px" height="10px"/></td>
                          <td>                      
                          <label className="labelstyle">{(inputWidth * 3.28084).toFixed(2)}</label>
                          </td>
                          <td><img src="./images/Ft.png" alt="Small Module" width="20px"/></td> 
                          <td><img src="./images/blank.png" alt="joystick blank" width="15px" height="10px"/></td>     
                          <td><img src="./images/blank.png" alt="joystick blank" width="60px" height="10px"/></td>    
                          <td><label className="labelstyle">{(inputHeight * 3.28084).toFixed(2)}</label></td>
                          <td><img src="./images/Ft.png" alt="Small Module" width="20px"/></td> 
                          <td><img src="./images/blank.png" alt="joystick blank" width="10px" height="10px"/></td>     
                          <td><img src="./images/blank.png" alt="joystick blank" width="52px" height="10px"/></td> 
                          <td><label className="labelstyle">{(inputDepth * 3.28084).toFixed(2)}</label></td>
                          <td><td><img src="./images/Ft.png" alt="Small Module" width="20px"/></td> </td> 
                        </tr>
                      </table>

                      </div>
                      

                    </div>
                    
                  
                <div className="segments">
                  <button className="button" onClick={fixRoom}>FIX WALLS & PROCEED</button>
  
                </div>
            </div>
            <div className="segments">
                <img src="./images/belt.png" alt="Small Module" width="100%"/>
                </div>
            <div className="row">
              <div className="brand-statement-txt">
                <p>Select <span class="brand-content-span">your Flo:</span></p>
              </div>
              <div className="segments">
              <button className="image-buttons" onClick={showModules}><img src={showHideModules} alt="expand" width="40px"/></button>
              </div> 
            </div>
            <div className={`modulesOn ${modulesBox ? "modulesOff" : ""}`}>
          
            <table>
              <tr>
                <th><div className="brand-statement-txt"><span class="brand-content-span">Add or Remove Flo Modules</span> </div></th>
              
              </tr>
             
              <tr>
                <td>
                <table>
                  <tr>
                  <td><img src="./images/blank.png" alt="joystick blank" width="15px"/></td>
                  <td><button className="image-buttons" onClick={addSmallModules}><img src={addSmallDoorsBtn} alt="Small Module" width="70px"/></button></td>
                  <td><img src="./images/blank.png" alt="joystick blank" width="20px"/></td>
                  <td> <button className="image-buttons" onClick={addMediumModules}><img src={addMediumDoorsBtn} alt="Medium Module" width="70px"/></button></td>
                  <td><img src="./images/blank.png" alt="joystick blank" width="20px"/></td>
                  <td> <button className="image-buttons" onClick={addLargeodules}> <img src={addLargeDoorsBtn} alt="Large Module" width="70px"/></button></td>                  
                  <td><img src="./images/blank.png" alt="joystick blank" width="20px"/></td>
                  <td> <button className="image-buttons" onClick={removeBaseUp}> <img src={removeDoorsBtn} alt="Large Module" width="70px"/></button></td> 
                  <td><img src="./images/blank.png" alt="joystick blank" width="15px"/></td>
                  </tr>
                </table>
                </td>

              </tr>
              <tr>
                <td>
                <div><button className="button" onClick={setPlacement}>CHANGE ORIENTATION: {placeUpRight}</button></div>
                </td>
              </tr>
            </table>
          </div>     
                          
              
          <div className="segments">
            <img src="./images/belt.png" alt="Small Module" width="100%"/>
          </div>
        <div className="row">
              <div className="brand-statement-txt">
                <p><span class="brand-content-span">Customize</span> Your Flo:</p>
              </div>
              <div className="segments">
                <button className="image-buttons" onClick={showHideCustomizeBox}><img src={showHideCustomize} alt="expand" width="40px"/></button>
              </div>
              
        </div>
        <div className={`customizeOn ${customizeBox ? "customizeOff" : ""}`}>   
            <div className="row">
                  <div className="brand-statement-txt">
                    <p><span class="brand-content-span">Add or Remove</span> Main Components:</p>
                  </div>
                  <div className="segments">
                    <button className="image-buttons" onClick={showHideAddRemComBox}><img src={expandAddRemoveBox} alt="expand" width="40px"/></button>
                  </div>
                  
            </div>
            <div className={`addRemovepartsOn ${addRemoveBox ? "addRemovepartsOff" : ""}`}>
                <div className={`addpartsOn ${addBox ? "addpartsOff" : ""}`}>
                    <table>
                       <tr>
                            <td><div className="brand-statement-txt"><span class="brand-content-span">Add Main Components</span> </div></td>
                          
                          </tr>
                    </table>
                   
                      <table>
                            <tr>
                                <td><button className="image-buttons"><img src="./images/adding.png" alt="Adding" width="80px"/></button></td>                      
                                <td><button className="image-buttons" onClick={showRemoveBox}><img src="./images/select-remove-orange.png" alt="Select remove" width="80px"/></button></td>
                                <td><button className="image-buttons"><img src="./images/blank.png" alt="joystick blank" width="80px"/></button></td> 
                            </tr>
                      </table>
                    
                        <table>
                          
                   
                        <tr>
                          <td>
                            <table>
                           
                              <tr>
                                <td><button className="image-buttons" onClick={addBackPanel}><img src={backPaneladdImage} alt="Back Panel" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={addLeftPanel}><img src={leftPaneladdImage} alt="Left Panel" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={addRightPanel}><img src="./images/Right-Panel-Grey.png" alt="Right Panel" width="80px"/></button></td>
                              </tr>
                              <tr>
                                <td><button className="image-buttons" onClick={addSmallDoors}><img src="./images/Small-Doors-Grey.png" alt="Small Door" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={addMediumDoors}><img src="./images/Medium-Doors-Grey.png" alt="Medium Door" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={addLargeDoors}><img src="./images/Long-Doors-Grey.png" alt="Large Door" width="80px"/></button></td>
                              </tr>
                            </table>
                          </td>

                        </tr>
                      </table>

                </div>
                
              <div className={`removepartsOn ${removeBox ? "removepartsOff" : ""}`}>
                    <table>
                            <tr>
                            <td><div className="brand-statement-txt"><span class="brand-content-span">Remove Main Components</span> </div></td>
                          </tr>
                    </table>
                   
                    <table>
                              <tr>
                                <td><button className="image-buttons" onClick={showAddBox}><img src="./images/select-add-Orange.png" alt="Select Add" width="80px"/></button></td>
                                <td><button className="image-buttons"><img src="./images/removing.png" alt="removing" width="80px"/></button></td>
                                <td><button className="image-buttons"><img src="./images/blank.png" alt="joystick blank" width="80px"/></button></td> 
                              </tr>
                    </table>
                    
                    <table>
                          
                          
                        <tr>
                          <td>
                            <table>
                              <tr>
                                <td><button className="image-buttons" onClick={removeBackPanel}><img src="./images/Back-Panel-Grey-Remove.png" alt="Back Panel" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={removeLeftPanel}><img src="./images/Left-Panel-Grey-Remove.png" alt="Left Panel" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={removeRightPanel}><img src="./images/Right-Panel-Grey-Remove.png" alt="Right Panel" width="80px"/></button></td>
                              </tr>
                              <tr>
                              <td><button className="image-buttons" onClick={removeSmallDoors}><img src="./images/Small-Doors-Grey-Remove.png" alt="Small Door" width="80px"/></button></td>
                              <td><button className="image-buttons" onClick={removeMediumDoors}><img src="./images/Medium-Doors-Grey-Remove.png" alt="Medium Door" width="80px"/></button></td>
                              <td><button className="image-buttons" onClick={removeLargeDoors}><img src="./images/Long-Doors-Grey-Remove.png" alt="Large Door" width="80px"/></button></td>
                              </tr>
                            </table>
                          </td>

                        </tr>
                      </table>
             
              </div>
            </div>
              <div className="segments">
                  <img src="./images/belt.png" alt="Small Module" width="100%"/>
              </div>
                    <div className="row">
                        <div className="brand-statement-txt">
                          <p><span class="brand-content-span">Add or Remove</span> Shelves:</p>
                        </div>
                        <div className="segments">
                          <button className="image-buttons" onClick={showHideAddRemSepBox}><img src={expandRemSepBox} alt="expand" width="40px"/></button>
                        </div>
                        
                  </div>
                    
                    <div className={`addRemSepOn ${addRemSepBox ? "addRemSepOff" : ""}`}>
                            <table>
                                  <tr>
                                    <td>
                                      <div className="brand-statement-txt2">See Inside Modules:</div>
                                    </td>
                                    <td><button className="image-buttons" onClick={hideDoors}><img src="./images/Open-Doors.png" alt="Open Doors" width="80px"/></button></td>
                                    <td><button className="image-buttons" onClick={showDoors}><img src="./images/Close-Doors.png" alt="Close Doors" width="80px"/></button></td>
                                  </tr>
                              </table>
                          <table>
                              <tr>
                                <td></td>
                                <td><div className="brand-statement-txt"><span class="brand-content-span">Medium</span></div> </td>
                                <td><div className="brand-statement-txt"><span class="brand-content-span">Large</span></div></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td><div className="add-remove">Add</div></td>
                                <td><button className="image-buttons" onClick={addSeperator}><img src="./images/Add-Middle-Plate.png" alt="Add Middle Plate" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={addSeperatorTop}><img src="./images/Add-Top-Plate.png" alt="Add Top Plate" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={addSeperatorMiddle}><img src="./images/Add-Middle-Plate.png" alt="Add Middle Plate" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={addSeperatorBottom}><img src="./images/Add-Bottom-Plate.png" alt="Close Doors" width="80px"/></button></td>
                              </tr>
        
                              <tr>
                                <td><div className="add-remove">Remove</div></td>
                                <td><button className="image-buttons" onClick={removeSeperator}><img src="./images/remove-mid-plate.png" alt="Remove Middle Plate" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={removeeperatorTop}><img src="./images/remove-top-plate.png" alt="Remove Top Plate" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={removeSeperatorMiddle}><img src="./images/remove-mid-plate.png" alt="Remove Middle Plate" width="80px"/></button></td>
                                <td><button className="image-buttons" onClick={removeSeperatorBottom}><img src="./images/remove-bottom-plate.png" alt="Remove Bottom Plate" width="80px"/></button></td>
                              </tr>
                              
                          </table>

                    </div>
              <div className="segments">
                  <img src="./images/belt.png" alt="Small Module" width="100%"/>
              </div>
                <div className="row">
                        <div className="brand-statement-txt">
                          <p><span class="brand-content-span">Add or Remove</span> Handles or Knobs:</p>
                        </div>
                        <div className="segments">
                          <button className="image-buttons" onClick={showHideAddRemHandlesBox}><img src={expandRemHandleBox} alt="expand" width="40px"/></button>
                        </div>
                        
                  </div>
              <div className={`addRemHandleOn ${addRemHandleBox ? "addRemHandleOff" : ""}`}>
              <table>
                        <tr>
                            <td><div className="brand-statement-txt"><span class="brand-content-span">Add Handles & Knobs</span> </div></td>
                            <td><div className="brand-statement-txt"><span class="brand-content-span">Remove Handles & Knobs</span> </div></td>
                          </tr>
                    </table>
              
                    <table>
                      
                        <tr>                            
                          <td><button className="image-buttons" onClick={addRoundHandle}><img src="./images/Round-Handle-Grey.png" alt="Round Knob" width="80px"/></button></td>
                          <td><button className="image-buttons" onClick={addLongHandle}><img src="./images/Long-Handle-Grey.png" alt="Long Handle" width="80px"/></button></td>
                          <td><button className="image-buttons" onClick={removeRoundHandle}><img src="./images/Round-Handle-Grey-Remove.png" alt="Round Knob" width="80px"/></button></td>
                          <td><button className="image-buttons" onClick={removeLongHandle}><img src="./images/Long-Handle-Grey-Remove.png" alt="Long Handle" width="80px"/></button></td>
                        </tr>
                    </table>
                 
              </div>
                   
        </div>
        <div className="segments">
                <img src="./images/belt.png" alt="Small Module" width="100%"/>
        </div>
      <div className="row">
              <div className="brand-statement-txt">
                <p><span class="brand-content-span">Select</span> Your Flo Colors:</p>
              </div>
              <div className="segments">
                <button className="image-buttons" onClick={showHideColorBox}><img src={selectColorShowHide} alt="expand" width="40px"/></button>
              </div>    
        </div>
      <div className={`colorOn ${colorBox ? "colorOff" : ""}`}>
          <div className="brand-statement-txt"><span class="brand-content-span">Select Your Color Zone</span></div>
            <div classname="segments">
              <table>
                <tr>
                  <td><button className="image-buttons" onClick={selectBaseZone}><img src="./images/color-bottomBase.png" alt="Bottom Base" width="70px"/></button></td> 
                  <td><button className="image-buttons" onClick={selectBackZone}><img src="./images/color-Rack-Panels.png" alt="BRack & Panels" width="70px"/></button></td>
                  <td><button className="image-buttons" onClick={selectLDoorZone}><img src="./images/color-left-Doors.png" alt="Left Doors" width="70px"/></button></td>
                  <td><button className="image-buttons" onClick={selectRDoorZone}><img src="./images/color-Right-Doors.png" alt="Right Doors" width="70px"/></button></td>
                  <td><button className="image-buttons" onClick={selectLHandleZone}><img src="./images/colorHandles.png" alt="Left Handles" width="70px"/></button></td>
                  <td><button className="image-buttons" onClick={selectRHandleZone}><img src="./images/color-right-handles.png" alt="Right Handles" width="70px"/></button></td>
                </tr>
              </table>
            </div>

            <div className="brand-statement-txt">
              <p><span class="brand-content-span">Select Your Color to be Applied on:</span> {curZone}</p>
            </div>

            <div classname="segments">
              <button className="image-buttons" onClick={selectWood}><img src="./images/Wood-Colour.png" alt="Wood" width="70px" /></button>
              <button className="image-buttons" onClick={selectFog}><img src="./images/Fog-Colour.png" alt="Fog" width="70px" /></button>
              <button className="image-buttons" onClick={selectSnow}><img src="./images/Snow-Colour.png" alt="Snow" width="70px" /></button>
              <div className={`nightOn ${ showNightBox ? "nightOff" : ""}`}>
              <button className="image-buttons" onClick={selectNight}><img src="./images/Night-Colour.png" alt="Night" width="70px"/></button>
              </div>
            </div>
            <div className={`second4ColorOn ${second4ColorBox ? "second4ColorOff" : ""}`}>
                <div classname="segments">
                <button className="image-buttons" onClick={selectGrass}><img src="./images/Grass-Colour.png" alt="Grass" width="70px"/></button>
                  <button className="image-buttons" onClick={selectChili}><img src="./images/Chili-Colour.png" alt="Chili" width="70px"/></button>
                  <button className="image-buttons" onClick={selectOBlue}><img src="./images/Ocean-Blue-Colour.png" alt="Ocean Blue" width="70px"/></button>
                  <button className="image-buttons" onClick={selectGreenG}><img src="./images/Green-Galle-Colour.png" alt="Green Galle" width="70px"/></button>
                </div>
            </div>
              <div className="brand-statement-txt">
                <p><span class="brand-content-span">Your Current Color Selected:</span> {selColorName}</p>
                <p><button className="button" onClick={changeColor}>CHANGE COLOR</button></p>
              </div>
        </div>
        <div className="segments">
                <img src="./images/belt.png" alt="Small Module" width="100%"/>
        </div>
          <div className="row">
              <div className="segments">
                <div className="brand-statement-txt">
                  <p>Your Flo</p>
                </div>
              </div>
              <div className="segments">
              <button className="image-buttons" onClick={checkOutShow}><img src={checkoutshowHide} alt="expand" width="40px"/></button>
              </div>
          </div>
          <div className={`checkoutOn ${checkoutBox ? "checkoutOff" : ""}`}>
              <div className="checkoutBlock">
                <table>
                  <tr>
                    <td>
                      <div className="layerResetlayerCechoutLeft"><img src="./images/height-weight-lablel.png" width="200px" alt=""/>
                        <div className="weigstHeight">
                          <h4>Your Flo Width:</h4>   
                          <h4>{floWidth.toFixed(2)} Meters</h4>                   
                        </div>
                      </div>
                      <div className="layerResetlayerCechoutLeft"><img src="./images/height-weight-lablel.png" width="200px" alt=""/>
                        <div className="weigstHeight">
                            <h4>Your Flo Height:</h4>   
                            <h4>{floHeight.toFixed(2)} Meters</h4>                   
                          </div>
                      </div>
                      <div className="layerResetlayerCechoutLeft"><img src="./images/height-weight-lablel.png" width="200px" alt=""/>
                          <div className="weigstHeight">
                            <h4>Your Flo Weight:</h4>   
                            <h4>{Math.round(weightX)} Kg</h4>                   
                          </div>
                      </div>
                    </td>
                    <td>
                      <div className="layerResetlayerCechoutRight">
                        <div className="totalpriceimg"><img src="./images/total-price.png" width="90px" alt=""/></div>
                        <div className="totalpriceimgPh"><img src="./images/price-placeholder.png" width="90px" alt=""/></div>
                        <div className="totalpricetxt">
                            <h3>Rs.</h3>   
                            <h3>{priceX}</h3>                   
                          </div>
                        <div className="chekoutbtnimg"><button onClick={sendMails} className="image-buttons"><img src="./images/checkout.png" width="90px" alt=""/></button></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="segments">
                      <img src="./images/belt.png" alt="Small Module" width="100%"/>
                      </div>
                    </td>
                    <td>
                      <div className="segments">
                      <img src="./images/belt.png" alt="Small Module" width="100%"/>
                      </div>
                    </td>
                  </tr>
                  <tr>

                    <td><div className="segments">If blocked by the browser:</div></td>
                    <td><div><button className="button" onClick={extraTryToShop}>FIND YOUR PRODUCT IN SHOP</button></div></td>
                  </tr>
                  <tr>
                    <td>
                        <div className="brand-statement-txt">
                          <p><span class="brand-content-span1">Your Design ID:</span></p>
                        </div>
                    </td>
                    <td>
                        <div className="brand-statement-txt">
                          <p>{designID}</p>
                       </div>
                    </td>
                  </tr>
                  <tr>
                    <td><div><button className="button" onClick={jsutSaveDesign}>JUST SAVE YOUR DESIGN</button></div></td>
                    <td><div><button className="button" onClick={findDesignReloader}>FIND FLO DESIGN RELOADER</button></div></td>
                  </tr>
                </table>
               
                <div className="componentlist">
                  <form enctype="multipart/form-data" ref={form}  method="post" onSubmit={sendMails}>
                    <input type="file" name="my_file"/> 
                    <textarea name="message"  value={message}/>
                    <input type="submit" value="Send" />
                  </form>
                </div>
              </div>
              <div className="segments">
                <img src="./images/belt.png" alt="Small Module" width="100%"/>
              </div>
              
          </div>
          

      </div>
    </div>
    
    
    );
  }
  
  export default App;
